import { useState } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { useLivekitRoom } from "contexts/LivekitRoom/LivekitRoomContext";
import { useUser } from "contexts/User/UserContext";
import { useTranslation } from "i18n";
import { config } from "styles";
import { run } from "utils";
import { displayUser } from "utils/display";
import { notifier } from "utils/notifier";

import { DraggableToEdges } from "./DraggableToEdges";
import { LivekitRoomTranscript } from "./LivekitRoomTranscript";
import { LivekitRoomViewer } from "./LivekitRoomViewer/LivekitRoomViewer";

type CallPopupSize = "SMALL" | "MEDIUM" | "LARGE";

export const CallPopup = () => {
  const t = useTranslation();
  const { user, hasAccessToGatekeeperFeature } = useUser();
  const { currentRoom, endScreen, closeCurrentRoom } = useLivekitRoom();
  const [size, setSize] = useState<CallPopupSize>("MEDIUM");
  const [showTranscript, setShowTranscript] = useState(false);

  if (!currentRoom && endScreen) return <>{endScreen.node}</>;

  if (!currentRoom) return null;

  const otherExpectedParticipant = currentRoom.expectedParticipants.find(
    (p) => p.uuid !== user?.uuid,
  );

  const maxWidthExpression = {
    SMALL: "300px",

    // A bit more than the default patient sidebar size to not hide too much of
    // the left side of the page (where doctors will write the note for the
    // ongoing appointment), but never smaller than the minimized width.
    MEDIUM: `max(300px, ${config.width["patient-sidebar"] + 100}px)`,

    // To account for the 16px inset on both sides.
    LARGE: "calc(100vw - 32px)",
  }[size];

  const maxHeightExpression = {
    SMALL: "350px",

    // As high as possible without hiding the top of the right
    // sidebar, but never smaller than the minimized height.
    MEDIUM: "max(350px, calc(100vh - 300px))",

    // To account for the 16px inset on both sides.
    LARGE: "calc(100vh - 32px)",
  }[size];

  return (
    <div className="fixed z-modal pointer-events-none inset-16">
      <DraggableToEdges
        snapMode="intendedEdge"
        initialEdge={{ x: "right", y: "bottom" }}
        className={classNames(
          "absolute pointer-events-auto rounded shadow-mid overflow-hidden cursor-move",
        )}
      >
        <div className="flex-col" style={{ maxWidth: maxWidthExpression }}>
          <LivekitRoomViewer
            url={currentRoom.url}
            token={currentRoom.token}
            onClose={closeCurrentRoom}
            onMissingPermissions={(type) =>
              notifier.error({
                user: run(() => {
                  switch (type) {
                    case "CAMERA":
                      return t("livekit_room.missing_permissions.camera");
                    case "MICROPHONE":
                      return t("livekit_room.missing_permissions.microphone");
                    case "SCREEN":
                      return t("livekit_room.missing_permissions.screen");
                  }
                }),
              })
            }
            minimized={size === "SMALL"}
            maxWidth={maxWidthExpression}
            maxHeight={maxHeightExpression}
            emptyRoomLabel={
              size !== "SMALL" && otherExpectedParticipant
                ? t("livekit_room.waiting_for", {
                    user: displayUser(otherExpectedParticipant),
                  })
                : undefined
            }
            toggleTranscript={
              hasAccessToGatekeeperFeature("VIDEO_CALL_SPEECH_TO_TEXT")
                ? () => setShowTranscript((current) => !current)
                : undefined
            }
          />
          <div className="absolute inset-tr-12 flex z-[1000] space-x-2">
            {size !== "SMALL" && (
              <ClickableIcon
                name="reduce"
                className="p-3 text-white drop-shadow"
                onClick={() => setSize(size === "LARGE" ? "MEDIUM" : "SMALL")}
              />
            )}
            {size !== "LARGE" && (
              <ClickableIcon
                name="expand"
                className="p-3 text-white drop-shadow"
                onClick={() => setSize(size === "SMALL" ? "MEDIUM" : "LARGE")}
              />
            )}
          </div>

          {showTranscript &&
            hasAccessToGatekeeperFeature("VIDEO_CALL_SPEECH_TO_TEXT") && (
              <div style={{ maxWidth: maxWidthExpression }}>
                <LivekitRoomTranscript roomUuid={currentRoom.uuid} />
              </div>
            )}
        </div>
      </DraggableToEdges>
    </div>
  );
};
