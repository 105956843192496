import { useEffect } from "react";
import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { LabelWrapper } from "components/Form/Label/LabelWrapper";
import { FormMedicalConditionLabel } from "components/Form/MedicalCodes/FormMedicalConditionLabel";
import { IcdCodePickerWithDatesValue } from "components/Form/MedicalCodes/IcdCodePickerWithDatesAndStatus";
import { Icon } from "components/Icon/Icon";
import { Link } from "components/Link/Link";
import { useDoctor } from "contexts/User/UserContext";
import {
  GranularDateTimeFragment,
  NoteSectionNormalizationFragment,
  StartNoteSectionNormalization,
  UpdateNoteSectionNormalization,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useStorageState } from "hooks/useStorageState";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { GranularDateTime } from "types";
import { notifier } from "utils/notifier";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { FormVitalLabel, VitalLabelInput } from "./FormVitalLabel";
import { displayVitalType, displayVitalUnit } from "./utils";

type NoteSectionNormalizationInput = {
  medicalHistoryLabels: IcdCodePickerWithDatesValue;
  vitalLabels: VitalLabelInput[];
};

gql`
  mutation UpdateNoteSectionNormalization(
    $uuid: UUID!
    $input: UpdateNoteSectionNormalizationInput!
  ) {
    updateNoteSectionNormalization(uuid: $uuid, normalizationInput: $input) {
      noteSectionNormalization {
        ...NoteSectionNormalization
      }
    }
  }

  mutation StartNoteSectionNormalization($uuid: UUID!) {
    startNoteSectionNormalization(uuid: $uuid) {
      noteSectionNormalization {
        ...NoteSectionNormalization
      }
    }
  }
`;

export const NoteNormalisationComposer = ({
  currentNormalization,
}: {
  currentNormalization: NoteSectionNormalizationFragment;
}) => {
  const [updateNoteSectionNormalization, updating] = useMutation(
    UpdateNoteSectionNormalization,
    {
      onSuccess: () => {
        notifier.success(t("note_normalization.marked_as_annotated"));
      },
    },
  );

  const [startNoteSectionNormalization] = useMutation(
    StartNoteSectionNormalization,
  );

  const [inStorageMedicalHistoryLabels, setInStorageMedicalHistoryLabels] =
    useStorageState<IcdCodePickerWithDatesValue>(
      `noteSectionNormalization-medicalHistory-${currentNormalization.uuid}`,
      currentNormalization.medicalHistoryAnnotation.map(
        ({ code, start, end, status, familyMember }) => ({
          uuid: ephemeralUuidV4(),
          icdCode: code,
          startDate: granularDateToInputDate(start) ?? null,
          endDate: granularDateToInputDate(end) ?? null,
          status,
          familyMember: familyMember ?? null,
        }),
      ),
    );

  const [inStorageVitalLabels, setInStorageVitalLabels] = useStorageState<
    VitalLabelInput[]
  >(
    `noteSectionNormalization-vital-${currentNormalization.uuid}`,
    currentNormalization.vitalAnnotation.map(
      ({ type, value, unit, textualRepresentation }) => ({
        uuid: ephemeralUuidV4(),
        type,
        value: value.toString(),
        unit: unit ?? undefined,
        textualRepresentation,
      }),
    ),
  );

  // We have to add an UUID field to the storage state since previous values will not have one.
  useEffect(() => {
    setInStorageMedicalHistoryLabels((prev) =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      prev.map((v) => ({ ...v, uuid: v.uuid ?? ephemeralUuidV4() })),
    );
  }, [setInStorageMedicalHistoryLabels]);

  useEffect(() => {
    setInStorageVitalLabels(
      currentNormalization.vitalAnnotation.map(
        ({ type, value, unit, textualRepresentation }) => ({
          uuid: ephemeralUuidV4(),
          type,
          value: value.toString(),
          unit: unit ?? undefined,
          textualRepresentation,
        }),
      ),
    );
  }, [setInStorageVitalLabels, currentNormalization.vitalAnnotation]);

  const { user, hasRole } = useDoctor();

  const t = useTranslation();

  return (
    <>
      <div className="flex items-center space-x-20">
        <Link to={`/${routes.NOTE_NORMALIZATION}`}>
          <Icon name="arrow" />
        </Link>
        <h1 className="text-primary-dark text-24 font-bold">
          {t("note_normalization.note_header")}
        </h1>
      </div>
      <div className="flex-col flex-fill p-20 space-y-20 overflow-auto ">
        <Form<NoteSectionNormalizationInput>
          initialValues={{
            medicalHistoryLabels: inStorageMedicalHistoryLabels,
            vitalLabels: inStorageVitalLabels,
          }}
          onSubmit={async ({ medicalHistoryLabels, vitalLabels }) => {
            if (
              !currentNormalization.labeler?.uuid &&
              !currentNormalization.isLabeled
            ) {
              await startNoteSectionNormalization({
                uuid: currentNormalization.uuid,
              });
            } else {
              if (vitalLabels.some(({ value }) => isNaN(Number(value)))) {
                notifier.error({ user: "The value entered is not a number" });
                return;
              }
              await updateNoteSectionNormalization({
                uuid: currentNormalization.uuid,
                input: {
                  label: {
                    medicalHistoryLabels: medicalHistoryLabels.map(
                      ({
                        icdCode,
                        startDate,
                        endDate,
                        status,
                        familyMember,
                      }) => ({
                        code: icdCode,
                        start: startDate,
                        end: endDate,
                        status,
                        familyMember,
                      }),
                    ),
                    vitalLabels: vitalLabels.map(
                      ({ type, value, unit, textualRepresentation }) => ({
                        type,
                        value: Number(value),
                        unit,
                        textualRepresentation: textualRepresentation.isBlank()
                          ? `${displayVitalType(
                              t,
                              type,
                            ).upperFirst()} ${value} ${
                              unit !== undefined
                                ? displayVitalUnit(t, unit)
                                : ""
                            }`
                          : textualRepresentation,
                      }),
                    ),
                  },
                },
              });
            }
          }}
        >
          <div className="flex items-center justify-between my-10">
            <h1 className="text-primary-dark text-24 font-bold">
              {t("note_normalization.write_note_header")}
            </h1>
            <FormState<NoteSectionNormalizationInput>>
              {({ values }) => (
                <Submit
                  loading={updating}
                  label={
                    currentNormalization.isLabeled
                      ? t("note_normalization.annotation_completed")
                      : currentNormalization.labeler
                      ? t("note_normalization.mark_as_annotated")
                      : t("note_normalization.start_annotation")
                  }
                  className="mt-10"
                  disabled={shouldDisableSubmit({
                    currentNormalization,
                    values,
                    currentDoctorUuid: user.uuid,
                    canEditAllSections: hasRole("NABLA_ADMINISTRATOR"),
                  })}
                />
              )}
            </FormState>
          </div>
          <div className="rounded bg-white p-10 border flex items-start space-x-14 flex-fill space-y-10">
            <div className="flex-col my-10 ml-10 flex-fill max-w-[25%]">
              <LabelWrapper
                label={t("note_normalization.section_content")}
                wrapperClassName="flex-fill"
                error=""
                hint=""
                useDiv
              >
                {currentNormalization.content.split("\n").map((word, i) => (
                  <div key={i}>{word}</div>
                ))}
              </LabelWrapper>
            </div>
            <div className="flex-col flex-fill">
              {currentNormalization.type === "vitals" ? (
                <FormVitalLabel
                  vitals={inStorageVitalLabels}
                  setVitals={setInStorageVitalLabels}
                />
              ) : (
                <FormMedicalConditionLabel
                  name="medicalHistoryLabels"
                  locale={currentNormalization.locale}
                  disabled={
                    currentNormalization.labeler?.uuid !== user.uuid &&
                    !hasRole("NABLA_ADMINISTRATOR")
                  }
                  onSetValue={setInStorageMedicalHistoryLabels}
                  includeFamilyMembers
                />
              )}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

const shouldDisableSubmit = ({
  currentNormalization,
  values,
  currentDoctorUuid,
  canEditAllSections,
}: {
  currentNormalization?: NoteSectionNormalizationFragment;
  values: NoteSectionNormalizationInput;
  currentDoctorUuid: UUID;
  canEditAllSections: boolean;
}) =>
  (currentNormalization?.labeler?.uuid &&
    currentDoctorUuid !== currentNormalization.labeler.uuid &&
    !canEditAllSections) ||
  (currentNormalization?.isLabeled &&
    currentNormalization.medicalHistoryAnnotation.length ===
      values.medicalHistoryLabels.length &&
    currentNormalization.medicalHistoryAnnotation.every(
      ({ code, start, end, status, familyMember }, i) =>
        code === values.medicalHistoryLabels[i].icdCode &&
        status === values.medicalHistoryLabels[i].status &&
        familyMember === values.medicalHistoryLabels[i].familyMember &&
        granularDateToInputDate(start)?.dateTime.getTime() ===
          values.medicalHistoryLabels[i].startDate?.dateTime.getTime() &&
        granularDateToInputDate(end)?.dateTime.getTime() ===
          values.medicalHistoryLabels[i].endDate?.dateTime.getTime(),
    ) &&
    currentNormalization.vitalAnnotation.length === values.vitalLabels.length &&
    currentNormalization.vitalAnnotation.every(
      ({ type, value, unit, textualRepresentation }, i) =>
        type === values.vitalLabels[i].type &&
        value.toString() === values.vitalLabels[i].value &&
        unit === values.vitalLabels[i].unit &&
        textualRepresentation === values.vitalLabels[i].textualRepresentation,
    ));

const granularDateToInputDate = (
  date: Maybe<GranularDateTimeFragment>,
): GranularDateTime | undefined => {
  if (!date) return undefined;
  return {
    dateTime: date.dateTime,
    granularity: date.granularity,
  };
};
