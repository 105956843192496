import { useState } from "react";
import gql from "graphql-tag";

import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Spinner } from "components/Spinner/Spinner";
import { Table } from "components/Table/Table";
import { AllAppKeys, DeleteAppKey } from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { AppKeyComposer } from "./AppKeyComposer";

gql`
  # schema = ORGANIZATION_USER
  fragment AppKey on AppKey {
    uuid
    name
    description
    value
    createdAt
  }

  query AllAppKeys {
    appKeys {
      ...AppKey
    }
  }

  mutation DeleteAppKey($uuid: UUID!) {
    deleteAppKey(uuid: $uuid)
  }
`;

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

type ComposerState = { mode: "edit"; appKeyUuid: UUID };

export const AppKeys = ({
  isAddingKey,
  onKeyComposerClosed,
}: {
  isAddingKey: boolean;
  onKeyComposerClosed: () => void;
}) => {
  const t = useTranslation();
  const { data, loading } = useQuery(AllAppKeys);
  const [deleteAppKey] = useMutation(DeleteAppKey);

  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>(undefined);
  const [composerState, setComposerState] = useState<ComposerState>();
  const [showingDetails, setShowingDetails] = useState(false);

  return (
    <>
      {(composerState || isAddingKey) && (
        <AppKeyComposer
          onClose={() => {
            setComposerState(undefined);
            onKeyComposerClosed();
          }}
          appKey={
            composerState?.mode === "edit"
              ? data?.find((appKey) => appKey.uuid === composerState.appKeyUuid)
              : undefined
          }
        />
      )}
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("app_keys.delete_the_mobile_sdk_api_key")}
          suffix={t("developers.server_keys.app_keys.to_delete_this_key")}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}

      <div className="mt-24">
        {loading ? (
          <Spinner />
        ) : data ? (
          <>
            <Table
              elements={data}
              onShowDetails={(show) => setShowingDetails(show)}
              fieldHeaders={[
                t("developers.server_keys.app_keys.name"),
                t("developers.server_keys.app_keys.description"),
                t("developers.server_keys.app_keys.value"),
                t("developers.server_keys.app_keys.created_at"),
              ]}
              fields={(appKey) => [
                <span key={`name-${appKey.uuid}`} className="truncate">
                  {appKey.name}
                </span>,
                appKey.description,
                appKey.value,
                <span key={`date-${appKey.uuid}`} className="whitespace-nowrap">
                  {appKey.createdAt.format("date")}
                </span>,
              ]}
              menuItems={(appKey) => [
                {
                  icon: "edit",
                  text: t("developers.server_keys.app_keys.edit"),
                  onClick: (close: () => void) => {
                    close();
                    setComposerState({
                      mode: "edit",
                      appKeyUuid: appKey.uuid,
                    });
                  },
                },
                {
                  icon: "trash",
                  text: t("developers.server_keys.app_keys.permanently_delete"),
                  className: "text-danger",
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      onConfirm: () =>
                        deleteAppKey(
                          { uuid: appKey.uuid },
                          {
                            onSuccess: (_, client) => {
                              notifier.success(
                                t("app_keys.mobile_sdk_api_key_deleted"),
                              );
                              setConfirmationModalState(undefined);
                              client.remove("AppKey", appKey.uuid);
                            },
                          },
                        ),
                    });
                  },
                },
              ]}
            />
            {!showingDetails && (
              <div className="float-right my-16">
                {data.length}{" "}
                {t("developers.server_keys.app_keys.mobile_sdk_api_keys")}
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
