import { useTranslation } from "i18n";

import { SpeechToTextFromMic } from "./SpeechToTextFromMic";

export const SpeechToText = () => {
  const t = useTranslation();
  return (
    <div className="flex-fill h-full bg-blue-overlay flex items-center justify-center p-32 space-x-32">
      <div className="w-1/2 flex-col bg-white h-full rounded border p-20">
        <div className="text-primary-dark text-18 mb-32">
          {t("speech_to_text.from_mic")}
        </div>
        <SpeechToTextFromMic />
      </div>
    </div>
  );
};
