import gql from "graphql-tag";

import { Query } from "components/Query/Query";
import { GetLivekitRoomTranscript } from "generated/provider";
import { useTranslation } from "i18n";

gql`
  query GetLivekitRoomTranscript($uuid: UUID!) {
    livekitRoom(uuid: $uuid) {
      room {
        uuid
        transcript {
          ...Transcript
        }
      }
    }
  }
`;

export const LivekitRoomTranscript = ({ roomUuid }: { roomUuid: UUID }) => {
  const t = useTranslation();
  return (
    <Query query={GetLivekitRoomTranscript} variables={{ uuid: roomUuid }}>
      {({ room: { transcript } }) => (
        <div className="bg-white rounded-b h-[180px] px-20 py-10">
          <div className="overflow-y-auto flex-col-reverse items-start max-h-[160px]">
            {[...(transcript?.items ?? [])].reverse().map((item) => (
              <div key={item.uuid} className="flex">
                <div className="font-semibold ml-4">{item.speakerTag} :</div>
                <div className="flex-fill">{item.text}</div>
              </div>
            ))}
            <div className="text-18 mb-12 mt-10">
              {t("livekit_room_transcript.transcript")}
            </div>
          </div>
        </div>
      )}
    </Query>
  );
};
