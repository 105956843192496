import { gql } from "@apollo/client";
import { useNavigate } from "react-router";

import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { Switch } from "components/Form/Switch/Switch";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Table } from "components/Table/Table";
import { useUser } from "contexts/User/UserContext";
import {
  RecordedConversationsWithNoteAnnotation,
  RecordedConversationsWithNoteAnnotationData,
} from "generated/provider";
import { useRerender } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { displayTimeElapsed } from "utils/date";
import { displayDoctor } from "utils/display";
import { language, setLanguage } from "utils/intl";

gql`
  fragment NoteAnnotation on NoteAnnotation {
    uuid
    createdAt
    annotatingDoctor {
      ...DoctorSummary
    }
    chiefComplaint
    allergies
    assessment
    appointments
    familyHistory
    historyIllness
    imagingResults
    labResults
    medicalHistory
    medications
    physicalExam
    plan
    prescription
    surgicalHistory
    symptoms
    vaccines
    vitals
    diagnostic
    socialHistory
  }

  query RecordedConversationsWithNoteAnnotation(
    $cursor: TimeAndUuidCursorInput
    $filter: RecordedConversationFilter
  ) {
    recordedConversations(filter: $filter) {
      recordedConversations(page: { cursor: $cursor, numberOfItems: 40 }) {
        data {
          ...RecordedConversation
          annotatedNotes {
            ...NoteAnnotation
          }
        }
        hasMore
        nextCursor {
          fromTime
          fromUuid
        }
      }
      totalCount
    }
  }
`;

const RecordedConversationHeaders = [
  "RECORDING_DATE",
  "AUDIO_DURATION",
  "RECORDING_DOCTOR",
  "ANNOTATION_BY_DOCTOR_1",
  "ANNOTATION_BY_DOCTOR_2",
  "ANNOTATION_BY_ENG",
  "NOTES",
] as const;

type RecordedConversationWithAnnotatedNote =
  RecordedConversationsWithNoteAnnotationData["recordedConversations"]["data"][0];

export const NoteAnnotationTable = () => {
  const t = useTranslation();
  const { hasRole } = useUser();
  const navigate = useNavigate();
  const rerender = useRerender();
  const notesByDoctors = (
    recordedConversation: RecordedConversationWithAnnotatedNote,
  ) =>
    recordedConversation.annotatedNotes.filter((note) =>
      note.annotatingDoctor
        ? !note.annotatingDoctor.roles.includes("NABLA_ADMINISTRATOR")
        : false,
    );
  const notesByEngs = (
    recordedConversation: RecordedConversationWithAnnotatedNote,
  ) =>
    recordedConversation.annotatedNotes.filter((note) =>
      note.annotatingDoctor
        ? note.annotatingDoctor.roles.includes("NABLA_ADMINISTRATOR")
        : false,
    );

  return (
    <div className="flex-col flex-fill overflow-auto bg-light-blue">
      <PaginatedQuery
        query={RecordedConversationsWithNoteAnnotation}
        selector={(d) => d.recordedConversations}
        variables={{
          filter: { transcriptStates: ["DONE"], forNoteAnnotation: true },
        }}
      >
        {(recordedConversationsData, utils) => (
          <div className="flex-col flex-fill p-32">
            <div className="lg:flex sm:flex-col sm:space-y-32 items-center sm:items-start mb-32 justify-between w-full">
              <div className="flex-col">
                <h1 className="text-primary-dark text-24 font-bold">
                  {t("note_annotation.transcribed_consultations")}
                </h1>
                <div>
                  {recordedConversationsData.totalCount}{" "}
                  {t("note_annotation.consultation")}
                </div>
                <Switch
                  label="English"
                  checked={language === "en"}
                  onChange={(checked) => {
                    setLanguage(checked ? "en" : "fr");
                    rerender();
                  }}
                />
              </div>
            </div>
            <Table<
              typeof RecordedConversationHeaders[number],
              RecordedConversationWithAnnotatedNote
            >
              elements={recordedConversationsData.recordedConversations.data}
              emptyPlaceholder={
                <div className="text-18 flex items-center text-primary-dark p-24 justify-center text-center h-[180px]">
                  {t("recorded_conversations.placeholder_table")}
                </div>
              }
              doRowAction={(fragment) => ({
                mode: "navigate",
                to: `/${routes.NOTE_ANNOTATION}/${fragment.uuid}`,
              })}
              fieldHeaders={RecordedConversationHeaders.map(
                (it) =>
                  ({
                    RECORDING_DATE: t(
                      "recorded_conversations.recorded_at_table",
                    ),
                    AUDIO_DURATION: t("note_annotation.audio_duration"),
                    RECORDING_DOCTOR: t("note_annotation.consultation_doctor"),
                    ANNOTATION_BY_DOCTOR_1: t("note_annotation.doctor_1"),
                    ANNOTATION_BY_DOCTOR_2: t("note_annotation.doctor_2"),
                    ANNOTATION_BY_ENG: t("note_annotation.eng"),
                    NOTES: t("note_annotation.see_notes"),
                  }[it]),
              )}
              fields={(fragment) => [
                fragment.createdAt.format("dateAndTime"),
                displayTimeElapsed(
                  fragment.audios
                    .map((it) =>
                      it.metadata.__typename === "AudioMetadata" &&
                      it.metadata.durationMs
                        ? it.metadata.durationMs
                        : 0,
                    )
                    .reduce((partialSum, val) => partialSum + val, 0) / 1000,
                ),
                displayDoctor(fragment.author),
                notesByDoctors(fragment).length > 0
                  ? displayDoctor(notesByDoctors(fragment)[0].annotatingDoctor)
                  : "-",
                notesByDoctors(fragment).length > 1
                  ? displayDoctor(notesByDoctors(fragment)[1].annotatingDoctor)
                  : "-",
                notesByEngs(fragment).length > 0
                  ? displayDoctor(notesByEngs(fragment)[0].annotatingDoctor)
                  : "-",
                hasRole("NABLA_ADMINISTRATOR") ? (
                  <Button
                    label={t("note_annotation.see_notes")}
                    onClick={(e) => {
                      navigate(
                        `/${routes.NOTE_ANNOTATION}/${fragment.uuid}/comparison`,
                      );
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  "-"
                ),
              ]}
            />
            <div className="flex-center">
              <NextPageButton {...utils} />
            </div>
          </div>
        )}
      </PaginatedQuery>
    </div>
  );
};
