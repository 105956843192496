import { useDoctor } from "contexts/User/UserContext";
import { hasSignature } from "utils/signature";

export const useUserForPrescriptions = () => {
  const { user } = useDoctor();
  const {
    subOrganization: { locale },
    rpps,
    adeli,
    nationalProviderIdentifier,
    prescriptionAddress,
    signature,
  } = user;
  const hasAllRequiredFields =
    prescriptionAddress &&
    hasSignature(signature) &&
    ((locale === "FRENCH" && (rpps || adeli || nationalProviderIdentifier)) ||
      (locale !== "FRENCH" && nationalProviderIdentifier));

  return {
    ...user,
    hasAllRequiredFields,
  };
};
