import classNames from "classnames";

import { useField } from "hooks/useField";

import { LabelWrapper, NamedLabelWrapperProps } from "../Label/LabelWrapper";

type SingleChoicePillsProps<O> = {
  options: readonly O[];
  getOptionLabel: (o: O) => string;
  disabled?: boolean;
  currentValue: O;
  className?: string;
  onChange?: (o: O) => void;
};

export const SingleChoicePills = <O extends unknown>({
  disabled,
  options,
  getOptionLabel,
  currentValue,
  onChange,
  className,
}: SingleChoicePillsProps<O>) => (
  <div className={className}>
    {options.map((option) => (
      <button
        type="button"
        key={getOptionLabel(option)}
        className={classNames(
          "rounded px-12 py-8 flex items-center text-12 font-semibold",
          currentValue === option ? "bg-primary text-white" : "bg-grey-100",
        )}
        onClick={() => onChange?.(option)}
        disabled={disabled}
      >
        {getOptionLabel(option)}
      </button>
    ))}
  </div>
);

export const FormSingleChoicePills = <O extends string>({
  name,
  label,
  hint,
  wrapperClassName,
  onChange,

  getOptionLabel,
  disabled,
  options,
  className,
}: Omit<SingleChoicePillsProps<O>, "currentValue"> &
  NamedLabelWrapperProps) => {
  const [{ value, disabled: fieldDisabled }, { error }, { setValue }] =
    useField<O>({ name, disabled });

  return (
    <LabelWrapper
      name={name}
      label={label}
      error={error}
      wrapperClassName={classNames(wrapperClassName, "w-auto")}
      hint={hint}
    >
      <SingleChoicePills
        options={options}
        getOptionLabel={getOptionLabel}
        currentValue={value}
        disabled={fieldDisabled}
        onChange={(val) => {
          onChange?.(val);
          setValue(val);
        }}
        className={className}
      />
    </LabelWrapper>
  );
};
