import { createContext, ReactNode, useContext } from "react";

import { Participant } from "./types";

export type LivekitRoom = {
  uuid: UUID;
  url: string;
  token: string;
  // The participants which are expected to take part in the call, including
  // the current user. Will be used to display waiting messages.
  expectedParticipants: Participant[];
};

export type JoinRoomOptions = {
  room: LivekitRoom;
  // Component displayed when "close" button is clicked or when `closeCurrentRoom` is called.
  endScreen: ReactNode;
};

export const LivekitRoomContext = createContext<{
  currentRoom: LivekitRoom | undefined;
  endScreen:
    | {
        node: ReactNode;
        close: () => void;
      }
    | undefined;
  joinRoom: (options: JoinRoomOptions) => void;
  closeCurrentRoom: () => void;
  dismissCurrentRoom: () => void;
} | null>(null);
LivekitRoomContext.displayName = "LivekitRoomContext";

export const useLivekitRoom = () => {
  const context = useContext(LivekitRoomContext);
  if (!context) throw new Error("LivekitRoomContext not available");
  return context;
};
