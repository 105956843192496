import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import {
  AvailabilityOccurrenceFragment,
  DeleteAvailabilityOccurrence,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  mutation DeleteAvailabilityOccurrence(
    $availabilityUuid: UUID!
    $input: DeleteAvailabilityOccurrenceInput
  ) {
    deleteAvailabilityOccurrence(
      availabilityUuid: $availabilityUuid
      input: $input
    ) {
      availability {
        ...Availability
      }
    }
  }
`;

export const AvailabilityOccurrenceDeletionModal = ({
  availabilityOccurrence,
  onHide,
}: {
  availabilityOccurrence: AvailabilityOccurrenceFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();
  const [deleteAvailabilityOccurrence] = useMutation(
    DeleteAvailabilityOccurrence,
    {
      onSuccess: () => {
        notifier.success(
          t(
            "scheduling.calendar.availability_occurrence_deletion_modal.availability_deleted",
          ),
        );
        // TODO(@ruben): Update cache of availability occurrences query
        onHide();
      },
    },
  );

  return (
    <Modal onHide={onHide} className="flex-col space-y-32">
      <div className="text-primary-dark text-24 font-bold">
        {t(
          "availability_occurrence_deletion_modal.delete_an_availability_slot",
        )}
      </div>
      <div className="text-body text-16 font-normal text-center">
        {t(
          "availability_occurrence_deletion_modal.do_you_want_to_delete_this_availability_slot_and_all_its_upcoming_occurrences_or_only_the_selected",
        )}
      </div>
      <div className="flex-col w-full space-y-12">
        <Button
          danger
          label={t(
            "availability_occurrence_deletion_modal.delete_only_this_slot",
          )}
          onClick={() =>
            deleteAvailabilityOccurrence({
              availabilityUuid: availabilityOccurrence.parent.uuid,
              input: {
                specificOccurrence: availabilityOccurrence.startAt,
                applyToAllSubsequentOccurrences: false,
              },
            })
          }
        />
        <Button
          className="border-none"
          secondary
          label={t(
            "availability_occurrence_deletion_modal.delete_all_future_slots",
          )}
          onClick={() =>
            deleteAvailabilityOccurrence({
              availabilityUuid: availabilityOccurrence.parent.uuid,
              input: {
                specificOccurrence: availabilityOccurrence.startAt,
                applyToAllSubsequentOccurrences: true,
              },
            })
          }
        />
      </div>
    </Modal>
  );
};
