import {
  FamilyMemberDegreeKnownValue,
  FamilyMemberDegreeKnownValues,
  TextSectionContentCategory,
} from "generated/provider";
import { staticT as t } from "i18n";
import { DraftMentionedItem, NoteSection, Nullable } from "types";
import { displayFamilyMemberDegree } from "utils/display";
import { mapEnumValue } from "utils/enum";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { NavigationState, NoteSectionWithComposerFields } from "./types";

export const getSectionTitle = (
  category: TextSectionContentCategory | "DIAGNOSE",
) =>
  mapEnumValue(category, {
    MEDICAL_HISTORY: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.medical_history",
    ),
    SURGERY_HISTORY: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.surgery_history",
    ),
    FAMILY_HISTORY: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.family_history",
    ),
    FREE_TEXT: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.free_text",
    ),
    EXAMINATION: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.examination",
    ),
    MOTIVE: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.chief_complaint",
    ),
    MEDICATION: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.medications",
    ),
    ALLERGY: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.allergies",
    ),
    VACCINE: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.vaccines",
    ),
    SOCIAL_HISTORY: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.social_history",
    ),
    HISTORY_OF_ILLNESS: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.history_of_illness",
    ),
    LABS: t("inboxes.qa_experience.ehr_composer.note_composer.utils.labs"),
    VITALS: t("inboxes.qa_experience.ehr_composer.note_composer.utils.vitals"),
    ASSESSMENT: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.assessment",
    ),
    SCORES: t("inboxes.qa_experience.ehr_composer.note_composer.utils.scores"),
    PLAN: t("inboxes.qa_experience.ehr_composer.note_composer.utils.plan"),
    DIAGNOSE: t("form.icd_codes.title"),
    OBSERVATIONS: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.observations",
    ),
    default: t(
      "inboxes.qa_experience.ehr_composer.note_composer.utils.unspecified_section",
    ),
  });

export const newRegularEntityToMentionedItem = (
  entityType:
    | "SYMPTOM"
    | "ALLERGY"
    | "MEDICATION"
    | "VACCINATION"
    | "MEDICAL_HISTORY"
    | "PROCEDURE",
  currentValue: string,
): DraftMentionedItem => {
  let draftedItem: DraftMentionedItem["draftedItem"];
  switch (entityType) {
    case "SYMPTOM":
      draftedItem = {
        __typename: "Symptom",
        uuid: ephemeralUuidV4(),
        name: currentValue,
      };
      break;
    case "MEDICATION":
      draftedItem = {
        __typename: "MedicationStatement",
        uuid: ephemeralUuidV4(),
        name: currentValue,
      };
      break;
    case "VACCINATION":
      draftedItem = {
        __typename: "VaccinationStatement",
        uuid: ephemeralUuidV4(),
        type: currentValue,
        description: "",
      };
      break;
    case "ALLERGY":
      draftedItem = {
        __typename: "PatientAllergy",
        uuid: ephemeralUuidV4(),
        type: currentValue,
      };
      break;
    case "MEDICAL_HISTORY":
      draftedItem = {
        __typename: "Condition",
        uuid: ephemeralUuidV4(),
        type: currentValue,
        familyMember: null,
      };
      break;
    case "PROCEDURE":
      draftedItem = {
        __typename: "Procedure",
        uuid: ephemeralUuidV4(),
        name: currentValue,
        comment: null,
      };
      break;
  }
  return {
    __typename: "DraftMentionedItem",
    draftedItem,
  };
};

export const DEFAULT_FAMILY_MEMBER: FamilyMemberDegreeKnownValue = "OTHER";

export const newFamilyHistoryToMentionedItem = (
  text: string,
  familyMember: FamilyMemberDegreeKnownValue,
): DraftMentionedItem => ({
  __typename: "DraftMentionedItem",
  draftedItem: {
    __typename: "Condition",
    uuid: ephemeralUuidV4(),
    type: text,
    familyMember,
  },
});

export const cleanAndSubmitFamilyMembersOptions = (
  text: string,
): Nullable<DraftMentionedItem> => {
  const match = text.match(/(.*):\s?(.*)$/u);
  if (match === null) return null;
  const [, familyDegreeString, condition] = match;
  const familyDegree = FamilyMemberDegreeKnownValues.find(
    (d) =>
      displayFamilyMemberDegree(d).toLowerCase() ===
      familyDegreeString.toLowerCase(),
  );
  const trimmedCondition = condition.trimOrNull();
  if (familyDegree && trimmedCondition) {
    return newFamilyHistoryToMentionedItem(trimmedCondition, familyDegree);
  }
};

export const createEmptySection = (
  category: TextSectionContentCategory | "DIAGNOSE",
  navigationState?: NavigationState,
): NoteSectionWithComposerFields => {
  const base = {
    navigationState: navigationState ?? "NAVIGATED_ON_CREATION",
    uuid: ephemeralUuidV4(),
    __typename: "PatientNoteSection",
  };
  if (category === "DIAGNOSE") {
    return {
      ...base,
      content: {
        __typename: "DiagnoseSectionContent",
        icd10Codes: [],
      },
    };
  }
  return {
    ...base,
    content: {
      __typename: "TextSectionContent",
      textWithMentions: { text: "", mentions: [] },
      category,
    },
  };
};

export const createTextSection = (
  textContent: string,
): NoteSectionWithComposerFields => {
  const base = {
    navigationState: "NAVIGATED_ON_CREATION" as NavigationState,
    uuid: ephemeralUuidV4(),
    __typename: "PatientNoteSection",
  };
  return {
    ...base,
    content: {
      __typename: "TextSectionContent",
      textWithMentions: { text: textContent, mentions: [] },
      category: "FREE_TEXT",
    },
  };
};

export const createEmptySections = (
  categories: (TextSectionContentCategory | "DIAGNOSE")[],
) => categories.map((category) => createEmptySection(category, "NEUTRAL"));

export const createComposerSections = (
  sections: NoteSection[],
): NoteSectionWithComposerFields[] =>
  sections.map((it) => ({
    ...it,
    navigationState: "NEUTRAL",
  }));
