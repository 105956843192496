import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Slider } from "components/Slider/Slider";
import { Spinner } from "components/Spinner/Spinner";
import { TranscriptFragment } from "generated/provider";
import { useRerender } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { displayTimeElapsed } from "utils/date";

import styles from "./audio.module.css";

export const Audio = ({
  src,
  transcript,
  className,
  durationHint,
  playbackRate,
}: {
  src: string;
  transcript?: TranscriptFragment | null;
  className?: string;
  durationHint?: number;
  playbackRate?: number;
}) => {
  const t = useTranslation();
  const audioRef = useRef<HTMLAudioElement>(null);
  const rerender = useRerender();
  const [showTranscript, setShowTranscript] = useState(false);

  const safeDuration =
    audioRef.current?.duration.let((it) =>
      Number.isFinite(it) ? it : durationHint,
    ) ??
    durationHint ??
    0;

  useEffect(() => {
    if (audioRef.current && audioRef.current.readyState >= 1) return;
    const intervalId = setInterval(rerender, 300);
    return () => clearInterval(intervalId);
  }, [rerender]);

  useEffect(() => {
    if (audioRef.current && playbackRate) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  return (
    <div className={classNames(styles.container, className)}>
      <div className="p-10 flex items-center">
        <audio
          ref={audioRef}
          src={src}
          onPlay={rerender}
          onPause={rerender}
          onLoadedData={rerender}
          onCanPlay={rerender}
          onTimeUpdate={rerender}
        />
        {/* https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState */}
        {audioRef.current && audioRef.current.readyState >= 1 ? (
          <PlayPauseIcon audio={audioRef.current} />
        ) : (
          <Spinner inline small className="my-4" />
        )}
        <div
          className={classNames(
            "ml-16 w-40",
            audioRef.current && !audioRef.current.paused
              ? "text-primary"
              : "text-primary-dark",
          )}
        >
          {displayTimeElapsed(audioRef.current?.currentTime ?? 0)}
        </div>
        <div className="ml-16 flex-fill flex items-center">
          {audioRef.current && (
            <>
              <Slider
                min={0}
                max={safeDuration}
                step="any"
                value={audioRef.current.currentTime}
                onChange={(value) => {
                  audioRef.current!.currentTime = value;
                }}
                className="flex-fill"
              />
              <div className="ml-20 w-36">
                {displayTimeElapsed(safeDuration)}
              </div>
            </>
          )}
        </div>
        {transcript && (
          <button
            className="ml-12 text-14"
            onClick={() => setShowTranscript(!showTranscript)}
          >
            {showTranscript
              ? t("audio.audio.hide_transcript")
              : t("audio.audio.see_transcript")}
          </button>
        )}
      </div>
      {transcript && showTranscript && (
        <div className="border-t bg-grey-100 py-8 px-12 flex-col">
          {transcript.ongoingProcessing ? (
            <div className="flex items-center space-x-4">
              <Spinner small inline />
              <span>{t("audio.the_transcript_is_being_created")}</span>
            </div>
          ) : (
            transcript.items.map((it) => <span key={it.uuid}>{it.text}</span>)
          )}
        </div>
      )}
    </div>
  );
};

export const PlayPauseIcon = ({ audio }: { audio: HTMLAudioElement }) => (
  <ClickableIcon
    name={audio.paused ? "player" : "pause"}
    className="text-primary py-4 px-0"
    onClick={() => (audio.paused ? audio.play() : audio.pause())}
  />
);
