import { SelectProps } from "components/Form/Select/Select";

export const playgroundColors = {
  backgroundColor: "#0F202D",
  lightBackground: "#172b3a",
  color: "rgba(255, 255, 255, 0.6)",
};

export const selectStyleProps: Partial<SelectProps<unknown>> = {
  className: "border-0 rounded-0",
  style: {
    backgroundColor: playgroundColors.backgroundColor,
    color: playgroundColors.color,
    height: 34,
  },
  optionsStyle: { padding: 0, border: 0, borderRadius: 0 },
  CustomOption: ({ label, selected, active }) => (
    <div
      className="px-10 py-6"
      style={{
        color: playgroundColors.color,
        backgroundColor: selected
          ? playgroundColors.backgroundColor
          : playgroundColors.lightBackground,
        fontWeight: active ? 500 : 400,
      }}
    >
      {label}
    </div>
  ),
};
