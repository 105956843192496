import { useState } from "react";
import gql from "graphql-tag";

import { FieldArray } from "components/Form/FieldArray/FieldArray";
import { FormInput } from "components/Form/Input/FormInput";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { FormModal } from "components/Modal/FormModal";
import { useExperience } from "contexts/Experience/ExperienceContext";
import { CreatePoll, PollFragment } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

import { VoteForPollModal } from "./VoteForPollModal";

gql`
  fragment Poll on Poll {
    uuid
    eventTime
    title
    items {
      uuid
      title
      voters {
        ...DoctorSummary
      }
    }
  }
  mutation CreatePoll($experienceUuid: UUID!, $input: CreatePollInput!) {
    createPoll(experienceUuid: $experienceUuid, input: $input) {
      poll {
        ...Poll
      }
    }
  }
`;

type FormValues = {
  title: string;
  propositions: string[];
};

export const PollModalCreationButton = () => {
  const t = useTranslation();
  const { uuid } = useExperience();
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [newlyCreatedPoll, setNewlyCreatedPoll] = useState<PollFragment>();
  const [createPoll, creatingPoll] = useMutation(CreatePoll, {
    onSuccess: (v) => {
      setShowCreationModal(false);
      setNewlyCreatedPoll(v.poll);
    },
  });

  return (
    <>
      <ClickableIcon
        name="reporting"
        disabled={creatingPoll}
        onClick={() => setShowCreationModal(true)}
      />
      {showCreationModal && (
        <FormModal<FormValues>
          title={t("composer_parts.poll_modal_creation_button.create_a_poll")}
          initialValues={{
            title: "",
            propositions: [""],
          }}
          validationSchema={{
            title: "required",
          }}
          onSubmit={({ title, propositions }) =>
            createPoll({
              experienceUuid: uuid,
              input: {
                title,
                propositions: propositions.mapNotNull((p) => p.trimOrNull()),
              },
            })
          }
          onHide={() => setShowCreationModal(false)}
          submitLabel={t(
            "composer_parts.poll_modal_creation_button.create_the_poll",
          )}
        >
          <FormInput
            name="title"
            label={t("composer_parts.poll_modal_creation_button.poll_title")}
          />
          <FieldArray<{ propositions: string[] }, string> name="propositions">
            {(sourcesHelper, values) => (
              <div className="space-y-8">
                {values.propositions.map((_, index) => (
                  <div key={index} className="flex items-center space-x-12">
                    <FormInput
                      name={`propositions[${index}]`}
                      label={`${t(
                        "composer_parts.poll_modal_creation_button.proposal",
                      )} ${index + 1}`}
                      onPaste={(e) => {
                        const value = e.clipboardData.getData("text");
                        if (e.currentTarget.value) return; // Avoid overrides
                        e.preventDefault();
                        const [actual, ...actionsToAdd] = value
                          .split("\n")
                          .mapNotNull((s) => s.trimOrNull());
                        sourcesHelper.update(index, actual);
                        actionsToAdd.forEach((a) => sourcesHelper.push(a));
                      }}
                    />
                    <ClickableIcon
                      className="mt-20"
                      name="trash"
                      onClick={() => sourcesHelper.remove(index)}
                    />
                  </div>
                ))}
                <div
                  className="border border-dashed flex justify-center mt-20"
                  style={{ height: 50 }}
                >
                  <button
                    type="button"
                    className="flex items-center"
                    onClick={() => sourcesHelper.push("")}
                  >
                    <Icon name="add" />
                    <div className="ml-8">
                      {t(
                        "composer_parts.poll_modal_creation_button.add_a_proposal",
                      )}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
        </FormModal>
      )}
      {newlyCreatedPoll && (
        <VoteForPollModal
          poll={newlyCreatedPoll}
          onHide={() => setNewlyCreatedPoll(undefined)}
          submitLabel={t("composer_parts.poll_modal_creation_button.my_vote")}
        />
      )}
    </>
  );
};
