import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useSearchParams } from "react-router-dom";

import { useLoggedInAuth } from "auth/AuthContext";
import { Background } from "components/Background/Backgound";
import { Switch } from "components/Form/Switch/Switch";
import { Icon } from "components/Icon/Icon";
import { useDoctor } from "contexts/User/UserContext";
import { UpdateGoogleCalendarSync } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { SUB_ORGANIZATION_ID_SEARCH_PARAM } from "utils/environment";
import { notifier } from "utils/notifier";
import { makePath, makeSearch } from "utils/url";

gql`
  mutation UpdateGoogleCalendarSync($googleCalendarSyncEnabled: Boolean) {
    updateGoogleCalendarSync(
      googleCalendarSyncEnabled: $googleCalendarSyncEnabled
    ) {
      doctor {
        uuid
        googleCalendarSyncEnabled
      }
    }
  }
`;

const QUERY_AUTHORIZATION_KEY = "authorization";
const QUERY_AUTHORIZATION_VALUE_SUCCESS = "success";

const QUERY_SETTING_KEY = "setting";
const QUERY_SETTING_VALUE_CALENDAR_SYNC = "calendar_sync";

export const DoctorGoogleCalendarSync = () => {
  const t = useTranslation();
  const { user } = useDoctor();
  const auth = useLoggedInAuth();
  const [searchParams] = useSearchParams();
  const [updateSettings] = useMutation(UpdateGoogleCalendarSync, {
    onSuccess: () =>
      notifier.success(
        t("settings.google_calendar.doctor_google_calendar_sync_success"),
      ),
  });
  const [loading, setLoading] = useState(false);
  const updateSettingsHelper = useSyncRef(
    (googleCalendarSyncEnabled: boolean) =>
      updateSettings({
        googleCalendarSyncEnabled,
      }),
  );
  const wrapLoadAndErrorBehavior = useSyncRef((promise: Promise<any>) => {
    setLoading(true);
    return promise
      .catch(() => {
        notifier.error({
          user: t("settings.google_calendar.doctor_google_calendar_sync_error"),
        });
      })
      .finally(() => setLoading(false));
  });

  const fullAuthorizationFlow = async (enable: boolean) => {
    if (enable && "startGoogleAuthorization" in auth) {
      const result = await auth.startGoogleAuthorization({
        requestCalendarEventsAccess: true,
        redirectPath: `${routes.PREFERENCES}/${
          routes.GOOGLE_CALENDAR_SYNC
        }${makeSearch({
          [QUERY_AUTHORIZATION_KEY]: QUERY_AUTHORIZATION_VALUE_SUCCESS,
          [QUERY_SETTING_KEY]: QUERY_SETTING_VALUE_CALENDAR_SYNC,
        })}`,
      });

      switch (result.kind) {
        case "SUCCESS":
          return updateSettingsHelper.current(enable);
        case "AUTHORIZATION_REQUIRED":
          window.location.href = result.authorizationUrl;
          break;
        case "FAILURE":
          // Already notified by startGoogleAuthorization.
          break;
      }
    } else if (enable && user.email !== null) {
      // The user is not currently logged in using account tokens.
      const result = await auth.startAccountLoginWithGoogle({
        emailLoginHint: user.email,
        requestCalendarEventsAccess: true,
        redirectPath: makePath(window.location.pathname, {
          [QUERY_AUTHORIZATION_KEY]: QUERY_AUTHORIZATION_VALUE_SUCCESS,
          [QUERY_SETTING_KEY]: QUERY_SETTING_VALUE_CALENDAR_SYNC,

          // To automatically pick the right identity after redirection.
          [SUB_ORGANIZATION_ID_SEARCH_PARAM]: user.subOrganization.uuid,
        }),
      });

      if (result.kind === "FAILURE") return; // Already notified by startAccountLoginWithGoogle.
      // eslint-disable-next-line require-atomic-updates
      window.location.href = result.authenticationUrl;
    } else {
      // No need to check Google to disable calendar sync
      return updateSettingsHelper.current(enable);
    }
  };

  useEffect(() => {
    if (
      searchParams.get(QUERY_AUTHORIZATION_KEY) ===
        QUERY_AUTHORIZATION_VALUE_SUCCESS &&
      searchParams.get(QUERY_SETTING_KEY) === QUERY_SETTING_VALUE_CALENDAR_SYNC
    ) {
      void wrapLoadAndErrorBehavior.current(updateSettingsHelper.current(true));
    }
  }, [searchParams, updateSettingsHelper, wrapLoadAndErrorBehavior]);

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("settings.google_calendar.doctor_google_calendar_sync.title")}
        </h1>
      </div>

      <div className="flex-col w-full items-start space-y-12 p-32 bg-white border rounded shadow-sm-outlined">
        {user.hasGoogleCalendarSyncAuthorization ? (
          <Switch
            disabled={loading}
            checked={user.googleCalendarSyncEnabled ?? false}
            label={t("settings.google_calendar.doctor_google_calendar_sync")}
            onChange={(value) =>
              wrapLoadAndErrorBehavior.current(fullAuthorizationFlow(value))
            }
          />
        ) : (
          <div className="flew-row flex-center">
            <text className="">
              {t(
                "settings.google_calendar.doctor_google_calendar_sync_sign_in",
              )}
            </text>
            <button
              className="ml-8 w-auto h-auto flex-row flex-center p-8
                 bg-white font-semibold text-14 text-primary text-center rounded-sm
                 border border-primary hover:opacity-70"
              onClick={() =>
                wrapLoadAndErrorBehavior.current(fullAuthorizationFlow(true))
              }
            >
              <Icon name="google" size={18} className="mr-24" />
              {t("login.google_login_button.login_with_google")}
            </button>
          </div>
        )}
      </div>
    </Background>
  );
};
