import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Separator } from "components/Separator/Separator";
import { AvailabilityOccurrenceFragment } from "generated/provider";
import { useMountDate } from "hooks";
import { useTranslation } from "i18n";

export const AvailabilityCard = ({
  availabilityOccurrence,
  style,
  lowerOpacityForPastEvents,
  otherAction,
  onClose,
}: {
  availabilityOccurrence: AvailabilityOccurrenceFragment;
  style?: CSSProperties;
  lowerOpacityForPastEvents?: boolean;
  otherAction?: ReactNode;
  onClose?: () => void;
}) => {
  const t = useTranslation();
  const now = useMountDate();

  return (
    <div
      key={availabilityOccurrence.startAt.toString()}
      style={style}
      className={classNames("flex-col text-body", {
        "opacity-50":
          lowerOpacityForPastEvents &&
          availabilityOccurrence.endAt.isBefore(now),
      })}
    >
      <div className="flex items-center m-16">
        <div className="font-medium text-16 text-primary-dark">
          {t("scheduling.availability_card.availability")}
        </div>
        <div className="flex ml-auto">
          {otherAction}
          <ClickableIcon
            className="py-0 px-2"
            name="close"
            onClick={onClose}
            size={20}
          />
        </div>
      </div>
      <Separator />
      <div className="flex text-primary-dark m-16">
        <Icon name="calendar" size={20} />
        <div className="flex-row ml-4">
          <div className="text-14 font-medium">
            {availabilityOccurrence.startAt.format("monthDay")}{" "}
            {availabilityOccurrence.startAt.format("monthAndYear")}
          </div>
          <div className="text-14">
            {availabilityOccurrence.startAt.format("time")}{" "}
            {t("scheduling.availability_card.to")}{" "}
            {availabilityOccurrence.endAt.format("time")}
          </div>
        </div>
      </div>
    </div>
  );
};
