import classNames from "classnames";
import gql from "graphql-tag";
import { useParams } from "react-router";

import { Button } from "components/Button/Button";
import { Label } from "components/Form/Label/Label";
import { Switch } from "components/Form/Switch/Switch";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Link } from "components/Link/Link";
import { Query } from "components/Query/Query";
import { useUser } from "contexts/User/UserContext";
import {
  NoteAnnotationFragment,
  RecordedConversationWithAnnotatedNote,
} from "generated/provider";
import { useRerender } from "hooks/useRerender";
import { TFunction, useTranslation } from "i18n";
import { routes } from "routes";
import { displayDoctor } from "utils/display";
import { localURL } from "utils/file";
import { language, setLanguage } from "utils/intl";

gql`
  query RecordedConversationWithAnnotatedNote($uuid: UUID!) {
    recordedConversation(uuid: $uuid) {
      recordedConversation {
        audios {
          ...FileUpload
          transcript {
            uuid
            items {
              uuid
              text
              speakerTag
            }
          }
        }
        annotatedNotes {
          ...NoteAnnotation
        }
        ...RecordedConversation
      }
    }
  }
`;

export const RecordedConversationWithAnnotatedNotes = () => {
  const { uuid } = useParams();
  const t = useTranslation();
  const { user, hasRole } = useUser();
  const rerender = useRerender();

  return (
    <Query query={RecordedConversationWithAnnotatedNote} variables={{ uuid }}>
      {(data) => {
        const audios = data.recordedConversation.audios.sortAsc((audio) =>
          new Date(audio.createdAt.toString()).getDate(),
        );
        const notes = data.recordedConversation.annotatedNotes.sortAsc((note) =>
          note.createdAt.toString(),
        );
        return (
          <div className="flex-col flex-fill overflow-auto bg-light-blue p-20 space-x-20">
            <div className="flex items-center space-x-20 mb-20">
              <Link to={`/${routes.NOTE_ANNOTATION}`}>
                <Icon name="arrow" />
              </Link>
              <h1 className="text-primary-dark text-24 font-bold">
                {t("note_annotation.consultation_header")}
              </h1>
            </div>
            <Switch
              label="English"
              checked={language === "en"}
              onChange={(checked) => {
                setLanguage(checked ? "en" : "fr");
                rerender();
              }}
            />
            <div className="flex items-center justify-between my-10">
              <Label
                label={t("note_annotation.annotated_notes_header")}
                useDiv
              />

              <div className="flex items-center space-x-8">
                {hasRole("NABLA_ADMINISTRATOR") && (
                  <ClickableIcon
                    name="download"
                    onClick={() => {
                      notes.forEach((note) => {
                        const toSave = noteToString(t, note);
                        const blob = new Blob([toSave], {
                          type: "text/plain",
                        });
                        const url = localURL(blob);
                        const link = document.createElement("a");
                        link.download = `${uuid}_${note.uuid}.md`;
                        link.href = url;
                        link.click();
                      });
                    }}
                  />
                )}
                <Button
                  label={t("note_annotation.add_a_note")}
                  to={`/${routes.NOTE_ANNOTATION}/${uuid}/new`}
                />
              </div>
            </div>
            <div className="flex-col space-y-10">
              {notes.length > 0
                ? notes.map((note) => {
                    const enabled =
                      user?.uuid === note.annotatingDoctor?.uuid ||
                      hasRole("NABLA_ADMINISTRATOR");
                    return (
                      <Link
                        to={
                          enabled
                            ? `/${routes.NOTE_ANNOTATION}/${uuid}/${routes.ANNOTATED_NOTE}/${note.uuid}`
                            : `/${routes.NOTE_ANNOTATION}/${uuid}`
                        }
                        className={classNames(
                          "rounded border bg-white p-10 hover:bg-grey-100",
                          enabled ? "" : "bg-grey-100",
                        )}
                        key={note.uuid}
                      >
                        {t("note_annotation.at")}{" "}
                        {note.createdAt.format("dateAndTime")}{" "}
                        {t("note_annotation.by")}{" "}
                        {displayDoctor(note.annotatingDoctor)}
                      </Link>
                    );
                  })
                : t("note_annotation.no_notes")}
            </div>
            <Label
              label={t("note_annotation.transcript_header")}
              useDiv
              wrapperClassName="my-10"
            />
            <div className="border rounded bg-white p-20">
              {audios.map((a) => (
                <div className="mt-10" key={a.uuid}>
                  {a.transcript?.items.map((item) => (
                    <div key={item.uuid}>
                      {item.speakerTag} {item.text}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

const noteToString = (t: TFunction, note: NoteAnnotationFragment) => `## ${t(
  "note_annotation.chief_complaint",
)} 

${note.chiefComplaint}
  
## ${t("note_annotation.symptoms")} 

${note.symptoms}

## ${t("note_annotation.history_illness")} 

${note.historyIllness}

## ${t("note_annotation.medical_history")} 

${note.medicalHistory}

## ${t("note_annotation.social_history")} 

${note.socialHistory}

## ${t("note_annotation.family_history")} 

${note.familyHistory}

## ${t("note_annotation.surgical_history")} 

${note.surgicalHistory}

## ${t("note_annotation.medications")} 

${note.medications}

## ${t("note_annotation.allergies")} 

${note.allergies}

## ${t("note_annotation.vitals")} 

${note.vitals}

## ${t("note_annotation.vaccines")} 

${note.vaccines}

## ${t("note_annotation.physical_exam")} 

${note.physicalExam}

## ${t("note_annotation.lab_results")} 

${note.labResults}

## ${t("note_annotation.imaging_results")} 

${note.imagingResults}

## ${t("note_annotation.assessment")} 

${note.assessment}

## ${t("note_annotation.plan")} 

${note.plan}

## ${t("note_annotation.prescription")} 

${note.prescription}

## ${t("note_annotation.appointments")} 

${note.appointments}

## ${t("note_annotation.diagnostic")} 

${note.diagnostic}
`;
