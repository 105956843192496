import { DRAFT_KEY } from "consts";
import {
  Bill,
  EHRInput,
  FileMessageInput,
  NoteAnnotationInput,
  NoteDraft,
  TextWithMentions,
} from "types";

import { createAtom } from "./createAtom";

const MAX_NOTES_KEPT_PER_PATIENT = 3;

type Draft = {
  // Experience
  messageText?: string;
  medias?: FileMessageInput[];
  livekitMessageInput?: boolean;
  questionsSet?: string[];

  // Patient
  ehrInput?: EHRInput;

  // Notes
  notes?: { [key: string]: NoteDraft | undefined };

  // Bills
  bills?: { [key: string]: Bill | undefined };

  // Comment
  newComment?: TextWithMentions;

  // noteAnnotation
  noteAnnotation?: NoteAnnotationInput;
};
type Store = { [key: string]: Draft | undefined };

export const useDraft = createAtom(
  { store: JSON.parse(storage.getItem(DRAFT_KEY) ?? "{}") as Store },
  ({ set, get }) => ({
    setDraft: (contextEntityUuid: UUID, newValue: Partial<Draft>) => {
      if (
        newValue.notes &&
        Object.keys(newValue.notes).length > MAX_NOTES_KEPT_PER_PATIENT
      ) {
        const filteredValues = Object.entries(newValue.notes)
          .sortDesc((it) => it[1]?.updatedAt.getTime() ?? 0)
          .slice(0, MAX_NOTES_KEPT_PER_PATIENT);

        newValue.notes = Object.fromEntries(filteredValues);
      }
      const newStore = {
        ...get().store,
        [contextEntityUuid]: { ...get().store[contextEntityUuid], ...newValue },
      };
      set({ store: newStore });
      const textOnlyStore = Object.entries(newStore).reduce<Store>(
        (acc, [uuid, value]) => {
          if (!value) return acc;
          acc[uuid] = {
            messageText: value.messageText,
            medias: [],
            livekitMessageInput: value.livekitMessageInput,
            questionsSet: value.questionsSet,
            ehrInput: value.ehrInput,
            notes: value.notes,
            bills: value.bills,
            newComment: value.newComment,
            noteAnnotation: value.noteAnnotation,
          };
          return acc;
        },
        {},
      );
      storage.setItem(DRAFT_KEY, JSON.stringify(textOnlyStore));
    },
  }),
);

export const useDraftSetter = () => useDraft(({ setDraft }) => setDraft);

export const useExperienceHasDraft = (draftKey: string) =>
  useDraft(({ store }) => {
    const draft = store[draftKey];
    if (!draft) return false;

    return (
      (draft.messageText && draft.messageText.trim() !== "") ||
      draft.medias?.isNotEmpty() ||
      draft.livekitMessageInput ||
      draft.questionsSet?.isNotEmpty()
    );
  });
