import { useEffect, useState } from "react";

import { useSyncRef } from "hooks/useSyncRef";

import { LocalAudioFile } from "./type";

const DB_NAME = "AudioDraftDB";
const STORE_NAME = "audioDrafts";

export const useAudioDrafts = () => {
  const [db, setDb] = useState<IDBDatabase>();
  const [drafts, setDrafts] = useState<LocalAudioFile[] | undefined>(undefined);

  const launchUpdateDraftsFromDB = (_db: IDBDatabase) => {
    const transaction = _db.transaction([STORE_NAME], "readonly");
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAll();
    request.onsuccess = (event: any) => {
      const retrievedDrafts = event.target.result as LocalAudioFile[];
      setDrafts(retrievedDrafts);
    };
    transaction.commit();
  };
  const launchUpdateDraftsFromDBRef = useSyncRef(launchUpdateDraftsFromDB);

  useEffect(() => {
    const request = indexedDB.open(DB_NAME);
    request.onsuccess = (it) => {
      const target = it.target as IDBOpenDBRequest;
      setDb(target.result);
      launchUpdateDraftsFromDBRef.current(target.result);
    };
    request.onupgradeneeded = (event) => {
      // If first time we open the DB, create an object store
      const target = event.target as IDBOpenDBRequest;
      target.result.createObjectStore(STORE_NAME);
    };
  }, [setDb, launchUpdateDraftsFromDBRef]);

  const readWriteOperation = (op: (store: IDBObjectStore) => IDBRequest) =>
    new Promise<void>((resolve, reject) => {
      if (db) {
        const transaction = db.transaction([STORE_NAME], "readwrite");
        transaction.onerror = (event) =>
          reject((event.target as IDBRequest).error);
        const store = transaction.objectStore(STORE_NAME);
        const request = op(store);
        request.onsuccess = () => {
          launchUpdateDraftsFromDB(db);
          resolve();
        };
        transaction.commit();
      } else {
        reject(Error("Tried to make an operation on uninitialized DB"));
      }
    });

  return {
    drafts,
    createOrUpdateDraft: (draft: LocalAudioFile) =>
      readWriteOperation((store) => store.put(draft, draft.localName)),
    deleteAllDrafts: () => readWriteOperation((store) => store.clear()),
    deleteDraft: (draft: LocalAudioFile) =>
      readWriteOperation((store) => store.delete(draft.localName)),
  };
};
