import { RefObject } from "react";
import { useFormikContext } from "formik";
import gql from "graphql-tag";

import { useMediaWindows } from "atoms/useMediaWindows";
import { PillButton } from "components/Button/PillButton";
import { prescriptionAllowedMentions } from "components/EHRComposer/Prescriptions/prescriptionAllowedMentions";
import { NablaPrescriptionPreview } from "components/EHRComposer/Prescriptions/PrescriptionPreview";
import { useCanSendPrescription } from "components/EHRComposer/Prescriptions/useCanSendPrescription";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  CreateNablaPrescription,
  DeletePrescription,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { FileMessageInput, TextWithMentions } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { getTextWithMentionsInput } from "utils/notes";
import { notifier } from "utils/notifier";

import { PatientTimelineItem } from "../PatientTimelineItem";
import { usePatientTimelineLocalItemState } from "../PatientTimelineLocalStateContext";
import { PatientPrescriptionItemWarning } from "./PatientPrescriptionItemWarning";
import { useUploadPatientPdfFileForTimelineItem } from "./useUploadPatientPdfFileForTimelineItem";

gql`
  mutation CreateNablaPrescription($input: CreateNablaPrescriptionInput!) {
    createNablaPrescription(input: $input) {
      prescription {
        ...NablaPrescription
      }
    }
  }

  mutation DeletePrescription($prescriptionUuid: UUID!) {
    deleteNablaPrescription(prescriptionUuid: $prescriptionUuid) {
      prescriptionUuid
    }
  }
`;

type FormData = {
  title: string;
  description: TextWithMentions;
};

type FormDataPlainText = {
  title: string;
  description: string;
};

export const NablaPrescriptionItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"NablaPrescription">;
}) => {
  const t = useTranslation();
  const { expandOtherItem } = usePatientTimelineLocalItemState(item.uuid);
  const [deletePrescription] = useMutation(DeletePrescription, {
    onSuccess: ({ prescriptionUuid }, client) =>
      client.remove("NablaPrescription", prescriptionUuid),
  });
  const { showMedia } = useMediaWindows();
  const { pdfGenerator, pdfPreviewRef } =
    useUploadPatientPdfFileForTimelineItem("PRESCRIPTION");

  const openPreview = async () => {
    if (item.prescriptionFileUpload) {
      showMedia(item.prescriptionFileUpload);
    } else {
      showMedia({
        blob: (await pdfGenerator(item.title)).file as Blob,
        title: item.title,
      });
    }
  };

  const canSendPrescriptionErrors = useCanSendPrescription();
  const fileGenerator: () => Promise<FileMessageInput> = () => {
    if (item.prescriptionFileUpload) {
      return Promise.resolve(item.prescriptionFileUpload);
    }
    // In previous versions, PDF were not uploaded on the server
    // So we need to generate them on the fly in this case
    return pdfGenerator(item.title);
  };

  return (
    <>
      <DeletionConfirmationModal
        suffix={t("patient_view.patient_document.delete_suffix")}
        onConfirm={async (closeDeletionModal) => {
          await deletePrescription({ prescriptionUuid: item.uuid });
          notifier.success(
            t("patient_view.patient_prescription.delete_success"),
          );
          closeDeletionModal();
        }}
      >
        {(openDeletionModal) => (
          <PatientTimelineItem<"NablaPrescription", FormDataPlainText, FormData>
            item={item}
            withoutEditMenuItem
            sendFileAttachmentWarning={!!canSendPrescriptionErrors}
            withFaxOptions={
              // Fax for Nabla prescriptions is only supported for recent versions
              // i.e. items with uploaded files
              item.prescriptionFileUpload
                ? {
                    documentType: "NABLA_PRESCRIPTION",
                    fileUuid: item.prescriptionFileUpload.uuid,
                  }
                : undefined
            }
            getFileAttachment={fileGenerator}
            toDraft={({ title, description }) => ({
              title,
              description: { text: description, mentions: [] },
            })}
            fromDraft={({ title, description }) => ({
              title,
              description: description.text,
            })}
            additionalMenuItems={[
              {
                text: t("patient_view.patient_prescription.preview"),
                icon: "filePreview",
                onClick: (closeMenu: () => void) => {
                  closeMenu();
                  openPreview();
                },
              },
              {
                text: t("patient_view.item.delete"),
                icon: "trash",
                className: "text-danger",
                onClick: (closeMenu: () => void) => {
                  closeMenu();
                  openDeletionModal();
                },
              },
            ]}
            initialValues={{
              title: item.title.trimOrNull() ?? t("patient_view.prescription"),
              description: item.textWithMentions.text,
            }}
            validationSchema={{
              title: "required",
            }}
            footerChildren={
              item.appointment
                ? item.appointment.let((appointment) => (
                    <PillButton
                      key={appointment.uuid}
                      label={
                        appointment.title.trimOrNull() ??
                        t("patient_view.default_title.appointment")
                      }
                      leftIcon="hyperlink"
                      onClick={() => expandOtherItem(appointment.uuid)}
                    />
                  ))
                : undefined
            }
          >
            <div>
              <PatientPrescriptionItemWarning
                errorTitle={t("patient_view.patient_prescription.error.title")}
                errorPersonalInfo={t(
                  "patient_view.patient_prescription.error.missing_info",
                )}
              />
              <div className="py-20 px-72">
                <FormTextArea
                  name="description"
                  placeholder={t(
                    "patient_view.patient_prescription.placeholder",
                  )}
                  className="flex-fill justify-between h-full"
                  disabled
                />
              </div>
            </div>
          </PatientTimelineItem>
        )}
      </DeletionConfirmationModal>
      <div className="hidden">
        <NablaPrescriptionPreview
          previewRef={pdfPreviewRef}
          textWithMentions={item.textWithMentions}
        />
      </div>
    </>
  );
};

export const NewNablaPrescriptionItem = ({
  temporaryUuid,
  appointmentUuid,
  prescriptionTemplate,
}: {
  temporaryUuid: UUID;
  appointmentUuid?: UUID;
  prescriptionTemplate?: { title: string; text: string };
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { expandOtherItem } = usePatientTimelineLocalItemState(temporaryUuid);

  const [createPrescription] = useMutation(CreateNablaPrescription);

  const { pdfPreviewRef, uploadPatientPdfFile } =
    useUploadPatientPdfFileForTimelineItem("PRESCRIPTION");

  const canSendPrescriptionErrors = useCanSendPrescription();

  return (
    <PatientTimelineItem<"NablaPrescription", FormDataPlainText, FormData>
      item={{ temporaryUuid, __typename: "NablaPrescription" }}
      initialValues={{
        title: prescriptionTemplate?.title ?? t("patient_view.prescription"),
        description: prescriptionTemplate?.text ?? "",
      }}
      validationSchema={{
        title: "required",
        description: "required",
      }}
      toDraft={({ title, description }) => ({
        title,
        description: { text: description, mentions: [] },
      })}
      fromDraft={({ title, description }) => ({
        title,
        description: description.text,
      })}
      onSubmit={async ({ title, description }) => {
        const pdfFile = await uploadPatientPdfFile(title);
        if (!pdfFile) return;

        if (canSendPrescriptionErrors) return;

        const prescriptionData = await createPrescription({
          input: {
            title,
            patientUuid: patient.uuid,
            textWithMentions: getTextWithMentionsInput(
              { text: description, mentions: [] },
              prescriptionAllowedMentions,
            ),
            upload: pdfFile,
            appointmentUuid,
          },
        });
        if (!prescriptionData) return;

        notifier.success(t("patient_view.patient_prescription.create_success"));
        expandOtherItem(prescriptionData.prescription.uuid);
      }}
    >
      <div>
        <PatientPrescriptionItemWarning
          errorTitle={t("patient_view.patient_prescription.error.title")}
          errorPersonalInfo={t(
            "patient_view.patient_prescription.error.missing_info",
          )}
        />
        <div className="py-20 px-72">
          <FormTextArea
            name="description"
            placeholder={t("patient_view.patient_prescription.placeholder")}
            className="flex-fill justify-between h-full w-full text-14 p-0 border-none"
          />
        </div>
      </div>
      <div className="hidden">
        <NablaPrescriptionEditionPreview pdfPreviewRef={pdfPreviewRef} />
      </div>
    </PatientTimelineItem>
  );
};

export const NablaPrescriptionEditionPreview = ({
  pdfPreviewRef,
}: {
  pdfPreviewRef: RefObject<HTMLDivElement>;
}) => {
  const { values } = useFormikContext<FormData>();

  return (
    <NablaPrescriptionPreview
      previewRef={pdfPreviewRef}
      textWithMentions={{ text: String(values.description), mentions: [] }}
    />
  );
};
