import { createContext, useContext } from "react";

import {
  EmptyObject,
  MessageFragment,
  NewTimelineItemFragment,
  TimelineItemFragment,
} from "generated/provider";
import { FileMessageInput, ReplyMessage } from "types";

// If you change any of these types, change the storage key as items won't be
// backwards-compatible.
export type MessageContentInput =
  | FileMessageInput
  | { text: string }
  | { livekitRoom: { _: EmptyObject } }
  | { questionsSet: string[] };

export type MessageInput = {
  content: MessageContentInput;
  replyMessage?: ReplyMessage;
  isForwarded?: boolean;
  batchIdentifier?: string;
};
export type SendingItem<MessageType extends unknown = MessageFragment> = {
  percentageProgress?: number;
  failed?: boolean;
  inputContent: MessageContentInput;
  batchId?: UUID;
} & MessageType;

export type ExperienceStore<MessageType extends unknown = MessageFragment> = {
  sendingItems: SendingItem<MessageType>[];
  allItemsLoaded?: boolean;
};
export type Store = { [key: string]: ExperienceStore | undefined };

export type AddItemInCache = {
  experienceUuid: UUID;
  item: TimelineItemFragment | NewTimelineItemFragment;
};

export const MessagesContext = createContext<{
  store: Store;
  addItemInCache: (params: AddItemInCache) => void;
  sendMessage: (
    experienceUuid: UUID,
    input: MessageInput,
    batchId?: UUID,
  ) => void;
  retrySendMessage: (experienceUuid: UUID, localUUID: UUID) => void;
  deleteFailedMessage: (experienceUuid: UUID, localUUID: UUID) => void;
} | null>(null);
MessagesContext.displayName = "MessagesContext";

export const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!context) throw new Error("MessagesProvider not available");
  return context;
};
