import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import { useShadowMode } from "atoms/useShadowMode";
import {
  PublicPatientFragment,
  SubOrganizationSummaryFragment,
  UserSummaryFragment,
} from "generated/provider";
import { AccessProps } from "types";
import { getBgColor, isDef } from "utils";

import { getFontSize } from "./utils";
import styles from "./avatar.module.css";

export type SubOrganizationAvatarFragment = Pick<
  SubOrganizationSummaryFragment,
  "__typename" | "displayName"
> & {
  organization: Pick<
    SubOrganizationSummaryFragment["organization"],
    "__typename" | "displayName"
  >;
  avatar: Pick<
    NonNullable<SubOrganizationSummaryFragment["avatar"]>,
    "__typename" | "urlV2"
  > | null;
};

export type AvatarProps = {
  user:
    | UserSummaryFragment
    | PublicPatientFragment
    | AccessProps["user"]
    // avatarUrl url is used for preview in FormAvatar
    | (SubOrganizationAvatarFragment & { avatarUrl?: string | null })
    | null
    | undefined;
  className?: string;
  style?: CSSProperties;
  size?: number;
  title?: string;
  withConnectedStatus?: boolean;
  squared?: boolean;
};

export const Avatar = ({
  user,
  className,
  style,
  size = 30,
  title,
  withConnectedStatus = false,
  squared = false,
}: AvatarProps) => {
  const { shadowMode } = useShadowMode();
  const sharedProps: Omit<
    ConnectedWrapperProps,
    "children" | "bgClassName" | "style"
  > = {
    title,
    className,
    size,
    squared,
    connectedDot:
      withConnectedStatus &&
      isDef(user) &&
      user.__typename === "Doctor" &&
      user.lastConnectedAt !== null &&
      user.lastConnectedAt.secondsFromNow() < 300,
  };
  const sharedStyle = {
    height: size,
    width: size,
    fontSize: getFontSize(size),
    ...style,
  };

  if (user?.__typename === "SubOrganization") {
    return (
      <KnownUserAvatar
        uuid=""
        lastName=""
        firstName={user.displayName ?? user.organization.displayName}
        avatarUrl={user.avatarUrl ?? user.avatar?.urlV2.url ?? null}
        {...sharedProps}
        style={sharedStyle}
      />
    );
  }

  if (user?.__typename === "Doctor") {
    return <KnownUserAvatar {...user} {...sharedProps} style={sharedStyle} />;
  }

  if (user?.__typename === "CopilotApiUser") {
    return (
      <KnownUserAvatar
        uuid={user.uuid}
        firstName={user.firstName ?? undefined}
        lastName={user.lastName ?? undefined}
        avatarUrl={null}
        {...sharedProps}
        style={sharedStyle}
      />
    );
  }

  if (
    !user ||
    (user.__typename !== "Patient" && user.__typename !== "PublicPatient") ||
    shadowMode
  ) {
    return (
      <TextAvatar
        bgClassName="bg-grey-200"
        children="?"
        {...sharedProps}
        style={sharedStyle}
      />
    );
  }

  return (
    <KnownUserAvatar
      uuid={user.uuid}
      firstName={user.__typename === "Patient" ? user.firstName : undefined}
      lastName={user.__typename === "Patient" ? user.lastName : undefined}
      username={user.username}
      avatarUrl={user.avatarUrl}
      {...sharedProps}
      style={sharedStyle}
    />
  );
};

const KnownUserAvatar = ({
  uuid,
  firstName,
  lastName,
  username,
  avatarUrl,
  squared,
  ...wrapperProps
}: Omit<ConnectedWrapperProps, "children" | "bgClassName"> & {
  uuid: UUID;
  firstName?: string;
  lastName?: string;
  username?: string;
  avatarUrl: string | null;
}) => {
  const firstLetterOrEmpty = (x: string | undefined) =>
    x?.slice(0, 1).toUpperCase() ?? "";

  // Using || because first/last name may be empty string instead of null/undefined
  const displayInitials =
    `${firstLetterOrEmpty(firstName)}${firstLetterOrEmpty(lastName)}` ||
    firstLetterOrEmpty(username);

  if (!avatarUrl) {
    return (
      <TextAvatar
        squared={squared}
        bgClassName={getBgColor(uuid)}
        {...wrapperProps}
      >
        {displayInitials}
      </TextAvatar>
    );
  }

  return (
    <ConnectedWrapper bgClassName="" squared={squared} {...wrapperProps}>
      <img
        src={avatarUrl}
        alt={displayInitials}
        className={classNames(
          "h-full w-full overflow-hidden object-cover",
          squared ? "rounded" : "rounded-full",
        )}
      />
    </ConnectedWrapper>
  );
};

const TextAvatar = ({ children, ...wrapperProps }: ConnectedWrapperProps) => (
  <ConnectedWrapper {...wrapperProps}>
    <div className="overflow-hidden">{children}</div>
  </ConnectedWrapper>
);

type ConnectedWrapperProps = {
  title: string | undefined;
  className: string | undefined;
  bgClassName: string;
  size: number;
  style: CSSProperties;
  connectedDot: boolean;
  children: ReactNode;
  squared: boolean;
};

const ConnectedWrapper = ({
  children,
  className,
  bgClassName,
  style,
  title,
  size,
  connectedDot,
  squared,
}: ConnectedWrapperProps) => (
  <div
    className={classNames(className, bgClassName, styles.avatar, {
      rounded: squared,
    })}
    style={style}
    title={title}
  >
    {children}
    {connectedDot && (
      <div
        className="absolute rounded-full border border-white bg-success"
        style={{
          height: size * (9 / 36),
          width: size * (9 / 36),
          bottom: 1,
          right: 1,
        }}
      />
    )}
  </div>
);
