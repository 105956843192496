import { gql } from "@apollo/client";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { BackMobile } from "components/Mobile/BackMobile";
import { ShareButton } from "components/Share/ShareButton";
import {
  InnerTagClose,
  RemoveOrConfirmTagRow,
} from "components/Tag/RemoveOrConfirmTag";
import { TagPill } from "components/Tag/TagPill";
import { AddTagRow, TagsMenu, TagsMenuItem } from "components/Tag/TagsMenu";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useMessages } from "contexts/Messages/MessagesContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  AddTagToQAExperience,
  AllTags,
  MarkQAExperienceAsClosed,
  RemoveTagFromQAExperience,
  ReopenQAExperience as ReopenQAExperienceMutation,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { getQAInboxRoute } from "utils";
import { defaultOrigin } from "utils/environment";

import { useTagsListOverflowing } from "../../hooks/useTagsListOverflowing";
import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";
import { useQASidePanel } from "../../QASidePanelContext/QASidePanelContext";
import { ExperienceAssignmentMenu } from "./ExperienceAssignmentMenu";
import { MLTagPill } from "./MLTags";
import { QAExperienceName } from "./QAExperienceName";
import styles from "./QAExperienceHeader.module.css";

gql`
  query AllTags {
    allTags {
      tags {
        ...Tag
      }
    }
  }

  mutation AddTagToQAExperience($uuid: UUID!, $tagUuid: UUID!) {
    addTagToQAExperience(experienceUuid: $uuid, tagUuid: $tagUuid) {
      experience {
        uuid
        tags {
          ...ExperienceTag
        }
      }
    }
  }

  mutation RemoveTagFromQAExperience($uuid: UUID!, $tagUuid: UUID!) {
    removeTagFromQAExperience(experienceUuid: $uuid, tagUuid: $tagUuid) {
      experience {
        uuid
        tags {
          ...ExperienceTag
        }
      }
    }
  }

  mutation MarkQAExperienceAsClosed($uuid: UUID!) {
    markExperienceAsClosed(experienceUuid: $uuid) {
      experience {
        uuid
        isClosed
        itemsV2(pagination: { numberOfItems: 3 }) {
          data {
            ...TimelineItem
          }
        }
      }
    }
  }

  mutation ReopenQAExperience($uuid: UUID!) {
    reopenQAExperience(experienceUuid: $uuid) {
      experience {
        uuid
        isClosed
      }
    }
  }
`;

export const QAExperienceHeader = () => {
  const t = useTranslation();
  const { user, hasPermission, hasAccessToGatekeeperFeature } = useDoctor();
  const { experience } = useQAExperience();
  const { sidePanelState, setSidePanelState, closeSidePanel } =
    useQASidePanel();
  const isDesktop = useIsDesktop();
  const [removeTagFromExperience, removing] = useMutation(
    RemoveTagFromQAExperience,
  );
  const [addTagToExperience, adding] = useMutation(AddTagToQAExperience);
  const { data } = useQuery(AllTags);

  const { tagsListRef, tagOverflowClassName } = useTagsListOverflowing(
    experience.tags,
  );

  const validAndSortedTags = experience.tags
    .filter((tag) => tag.status === "VALID")
    .sortAsc((tag) => tag.updatedAt.getTime());

  const mlSuggestedAndSortedTags = experience.tags
    .filter(
      (tag) =>
        tag.taggedBy.__typename === "MLModelInput" &&
        tag.status === "SUGGESTION",
    )
    .sortDesc((tag) => tag.updatedAt.getTime());

  const tagsMenuList = data
    ? data.tags
        .map((tag) => {
          const experienceTag = experience.tags.find(
            (expTag) => expTag.type.uuid === tag.uuid,
          );
          return experienceTag
            ? {
                type: tag,
                status: experienceTag.status,
                updatedAt: experienceTag.updatedAt.getTime(),
              }
            : { type: tag };
        })
        .sortAsc((tag) => tag.type.name)
        .sortDesc((tag) => tag.updatedAt ?? 0)
        .sortAsc((tag) =>
          tag.status === "SUGGESTION"
            ? 0
            : tag.status === "VALID"
            ? 1
            : tag.status === "DISMISSED"
            ? 3
            : 2,
        )
    : null;

  const addTag = (tag: TagsMenuItem) => {
    addTagToExperience({ uuid: experience.uuid, tagUuid: tag.type.uuid });
  };

  const removeTag = (tag: TagsMenuItem) => {
    removeTagFromExperience({ uuid: experience.uuid, tagUuid: tag.type.uuid });
  };

  const { addItemInCache } = useMessages();
  const [close, closing] = useMutation(MarkQAExperienceAsClosed, {
    onSuccess: (result) => {
      result.experience.itemsV2.data.forEach((item) => {
        addItemInCache({ experienceUuid: experience.uuid, item });
      });
    },
  });
  const [reopen, reopening] = useMutation(ReopenQAExperienceMutation);

  return (
    <div className="bg-white border-b px-16 pt-8 pb-[9px]">
      <div className="flex-fill flex items-center justify-between gap-10 lg:gap-20">
        <BackMobile fallback={getQAInboxRoute(experience, user.uuid)} />
        <QAExperienceName {...experience} />
        <div
          className={classNames(
            "ml-auto flex items-center",
            isDesktop ? "space-x-8" : "space-x-4",
          )}
        >
          {hasAccessToGatekeeperFeature("MESSAGE_INBOX") && (
            <div>
              {experience.todoMessagesCount}{" "}
              {t("inboxes.qa_experience.qa_experience_header.unresolved_count")}
            </div>
          )}
          <TooltipWrapper
            label={t(
              "inboxes.qa_experience.qa_experience_header.qa_experience_header.share",
            )}
          >
            <ShareButton
              position={["bottom-right", "bottom", "bottom-left"]}
              messageInput={{
                content: {
                  text: `Hello, ${t(
                    "qa_experience_header.can_you_look_at_this_conversation_please",
                  )}: ${defaultOrigin}${getQAInboxRoute(experience)}`,
                },
              }}
              icon="reply"
              size={20}
              className="mirrored py-2 rounded-[6px] border h-28 w-28 hover:bg-grey-100"
              successNotification={t(
                "inboxes.qa_experience.qa_experience_header.qa_experience_header.conversation_shared",
              )}
            />
          </TooltipWrapper>
          {!hasAccessToGatekeeperFeature("MESSAGE_INBOX") && (
            <>
              <ExperienceAssignmentMenu
                disabled={!hasPermission("ANSWER_QA_EXPERIENCE")}
              />
              <button
                className={classNames(
                  "flex items-center space-x-8 pl-4 h-28 bg-white py-4 rounded-[6px] border hover:bg-grey-100",
                  isDesktop ? "pr-8" : "pr-4",
                )}
                disabled={closing || reopening}
                onClick={async () => {
                  if (experience.isClosed) {
                    await reopen({ uuid: experience.uuid });
                  } else {
                    await close({
                      uuid: experience.uuid,
                    });
                  }
                }}
              >
                <Icon name={experience.isClosed ? "drawer" : "archive"} />
                {isDesktop && (
                  <div className="text-12 text-grey-300">
                    {experience.isClosed
                      ? t(
                          "inboxes.qa_experience.qa_experience_header.qa_experience_header.reopen",
                        )
                      : t(
                          "inboxes.qa_experience.qa_experience_header.qa_experience_header.close",
                        )}
                  </div>
                )}
              </button>
            </>
          )}
          {!isDesktop && (
            <ClickableIcon
              name="profile"
              size={20}
              className={classNames(
                "rounded-[6px] border h-28 w-28 hover:bg-grey-100",
                {
                  "text-primary": sidePanelState?.mode === "PATIENT",
                },
              )}
              onClick={() => {
                if (sidePanelState?.mode === "PATIENT") {
                  closeSidePanel();
                } else {
                  setSidePanelState({
                    mode: "PATIENT",
                    showAdminForm: false,
                  });
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="hidden tablet:flex tablet:flex-fill mt-6 justify-between items-center">
        <div className="flex flex-fill items-center">
          <div
            ref={tagsListRef}
            className={classNames(
              "flex gap-4 overflow-auto",
              styles.tags,
              tagOverflowClassName,
            )}
          >
            {validAndSortedTags.map((tag) => (
              <TagPill
                key={tag.type.uuid}
                tag={tag.type}
                rightInnerElement={
                  <InnerTagClose onClick={() => removeTag(tag)} />
                }
              />
            ))}
            {mlSuggestedAndSortedTags.map((tag) => (
              <MLTagPill key={tag.type.uuid} tag={tag.type} />
            ))}
          </div>
          {tagsMenuList && (
            <TagsMenu
              tagsList={tagsMenuList}
              displayTag={(tag) =>
                !tag.status || tag.status === "DISMISSED" ? (
                  <AddTagRow
                    tag={tag}
                    addTag={addTag}
                    disabled={adding || !hasPermission("ANSWER_QA_EXPERIENCE")}
                  />
                ) : (
                  <RemoveOrConfirmTagRow
                    key={tag.type.uuid}
                    tag={tag}
                    removeTag={removeTag}
                    addTag={addTag}
                    isML={(tagItem) => tagItem.status === "SUGGESTION"}
                    disabled={
                      removing ||
                      adding ||
                      !hasPermission("ANSWER_QA_EXPERIENCE")
                    }
                  />
                )
              }
              addTagLabel={
                validAndSortedTags.concat(mlSuggestedAndSortedTags).isEmpty()
                  ? t(
                      "inboxes.qa_experience.qa_experience_header.qa_experience_header.add_a_tag",
                    )
                  : undefined
              }
              className={
                validAndSortedTags.concat(mlSuggestedAndSortedTags).isEmpty()
                  ? undefined
                  : "ml-4"
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
