import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { Spinner } from "components/Spinner/Spinner";
import { ParsedGraphQLError } from "graphql-client/errors";

export const QueryResult = <Data extends unknown>({
  loading,
  error,
  data,
  children,
}: {
  data: Data | undefined;
  error: ParsedGraphQLError | undefined;
  loading: boolean;
  children: (data: Data) => JSX.Element | null;
}): JSX.Element | null => {
  if (loading) return <Spinner />;
  if (data) return children(data);
  if (error) return <ErrorPage error={error} />;
  return null;
};
