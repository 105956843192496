import { Media } from "atoms/useMediaWindows";
import { useTranslation } from "i18n";

import { getMediaType } from "../mediaWindowsModel";
import { DicomMediaContent } from "./DicomMediaContent";
import { ImageMediaContent } from "./ImageMediaContent";
import { AbstractMediaContent } from "./mediaContentModel";
import { PdfMediaContent } from "./PdfMediaContent";
import { VideoMediaContent } from "./VideoMediaContent";

const getMediaContent: (media: Media) => AbstractMediaContent | null = (
  media,
) => {
  const mediaType = getMediaType(media);

  switch (mediaType) {
    case "IMAGE":
      return ImageMediaContent;
    case "DICOM":
      return DicomMediaContent;
    case "VIDEO":
      return VideoMediaContent;
    case "PDF":
      return PdfMediaContent;
    default:
      return null;
  }
};

export const MediaContent = ({ media }: { media: Media }) => {
  const t = useTranslation();

  const SelectedMediaContent = getMediaContent(media);

  return (
    <>
      {SelectedMediaContent ? (
        <SelectedMediaContent media={media} />
      ) : (
        <div>
          {t("media_windows.this_media_cannot_be_viewed_in_full_screen")}
        </div>
      )}
    </>
  );
};
