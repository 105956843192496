import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Anonymize } from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";

gql`
  query Anonymize($text: String!, $language: String!) {
    anonymizeMessage(query: { text: $text, language: $language }) {
      anonymizedText
      reverseMappings {
        initialValue
        key
      }
    }
  }
`;

export const Anonymization = () => {
  const [anonymize, { loading, data }] = useLazyQuery(Anonymize);
  return (
    <div className="flex-fill bg-blue-overlay overflow-auto">
      <div className="flex-col p-18 space-y-16 lg:items-center">
        <span className="text-primary-dark title">Anonymization</span>
        <Form<{ text: string; language: string }>
          className="bg-white p-16 rounded-item space-y-16 lg:w-1/2"
          initialValues={{ text: "", language: "fr" }}
          onSubmit={(values) => {
            anonymize(values);
            return Promise.resolve();
          }}
        >
          <FormTextArea
            minRows={5}
            maxRows={10}
            name="text"
            placeholder="Enter a message"
            disabled={loading}
          />
          <FormSelect
            label="Language"
            name="language"
            options={["fr", "en"]}
            getOptionLabel={(languageCode) =>
              languageCode === "fr" ? "Français" : "English"
            }
          />
          <Submit loading={loading} label="Anonymize" />
          <div className="font-semibold mt-24 uppercase">
            Transcript Anonymisé
          </div>
          {data && (
            <div className="flex-col">
              {data.anonymizedText.split("\n").map((it, index) => (
                <div key={index}>{it}</div>
              ))}
            </div>
          )}
          <div className="font-semibold mt-24 uppercase">Mapping</div>
          {data && (
            <div className="flex-col">
              {data.reverseMappings.map((it, index) => (
                <div key={index}>
                  {it.key} - {it.initialValue}
                </div>
              ))}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};
