import { NoteSectionWithComposerFields } from "./types";

export type NavigationStatus = "NAVIGATION_ALLOWED" | "NAVIGATION_NOT_ALLOWED";

export const useNavigationBetweenSections = (
  sections: NoteSectionWithComposerFields[],
  onChange: (newSections: NoteSectionWithComposerFields[]) => void,
) => ({
  handleExitUp: (currentSectionIndex: number): NavigationStatus => {
    const newIndex = previousTextSectionIndex(sections, currentSectionIndex);
    if (newIndex === undefined) return "NAVIGATION_NOT_ALLOWED";
    onChange(
      sections.map((section, indexSection) => {
        if (newIndex === indexSection) {
          return {
            ...section,
            navigationState: "NAVIGATED_USING_ARROW_UP",
          };
        }
        if (currentSectionIndex === indexSection) {
          return {
            ...section,
            navigationState: "NEUTRAL",
          };
        }
        return section;
      }),
    );
    return "NAVIGATION_ALLOWED";
  },
  handleExitDown: (currentSectionIndex: number): NavigationStatus => {
    const newIndex = nextTextSectionIndex(sections, currentSectionIndex);
    if (newIndex === undefined) return "NAVIGATION_NOT_ALLOWED";
    onChange(
      sections.map((section, indexSection) => {
        if (newIndex === indexSection) {
          return {
            ...section,
            navigationState: "NAVIGATED_USING_ARROW_DOWN",
          };
        }
        if (currentSectionIndex === indexSection) {
          return {
            ...section,
            navigationState: "NEUTRAL",
          };
        }
        return section;
      }),
    );
    return "NAVIGATION_ALLOWED";
  },
  handleBlur: (currentSectionIndex: number) => {
    onChange(
      sections.map((section, indexSection) => {
        if (currentSectionIndex === indexSection) {
          return {
            ...section,
            navigationState: "NEUTRAL",
          };
        }
        return section;
      }),
    );
  },
});

const nextTextSectionIndex = (
  sections: NoteSectionWithComposerFields[],
  currentIndex: number,
) => {
  let i = currentIndex + 1;
  while (i < sections.length) {
    if (sections[i].content.__typename === "TextSectionContent") return i;
    i++;
  }
  return undefined;
};

const previousTextSectionIndex = (
  sections: NoteSectionWithComposerFields[],
  currentIndex: number,
) => {
  let i = currentIndex - 1;
  while (i >= 0) {
    if (sections[i].content.__typename === "TextSectionContent") return i;
    i--;
  }
  return undefined;
};
