import { useState } from "react";

import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { MessageContentInput } from "contexts/Messages/MessagesContext";
import { useGetMessageContent } from "contexts/Messages/utils";
import { useDoctor } from "contexts/User/UserContext";
import {
  AddDoctorToExperience,
  CreateQAExperienceWithMessages,
  CreateQAExperienceWithMessagesData,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import {
  useMaybeQAExperience,
  useQAExperience,
} from "../../QAExperienceContext/QAExperienceContext";

const useSendButtonsUtils = () => {
  const { setReplyMessage } = useMaybeQAExperience();

  const {
    mediaMessages,
    messageText,
    livekitRoomInput,
    questionsSet,
    clearDraft,
  } = useExperienceDraft();

  const canSendMessage =
    !mediaMessages.isEmpty() ||
    messageText.isNotBlank() ||
    livekitRoomInput !== false ||
    questionsSet.isNotEmpty();

  const consumeMessagesToSend = (): [MessageContentInput][] => {
    const messages: [MessageContentInput][] = [];

    if (messageText.isNotBlank()) messages.push([{ text: messageText.trim() }]);
    if (livekitRoomInput !== false) {
      messages.push([{ livekitRoom: { _: "EMPTY" } }]);
    }
    mediaMessages.forEach((media) => messages.push([media]));
    if (questionsSet.isNotEmpty()) messages.push([{ questionsSet }]);

    clearDraft();
    setReplyMessage(undefined);

    return messages;
  };

  return {
    canSendMessage,
    consumeMessagesToSend,
  };
};

export const useSendExistingExperienceButtonsUtils = () => {
  const { instantScroll, sendMessage, isInExperience, experience } =
    useQAExperience();
  const { user } = useDoctor();
  const { canSendMessage, consumeMessagesToSend } = useSendButtonsUtils();

  const [addDoctorToExperience, joiningExperience] = useMutation(
    AddDoctorToExperience,
  );

  const sendMessageAndAttachments = () => {
    const batchId = ephemeralUuidV4();
    consumeMessagesToSend().forEach(([messageContentInput]) => {
      sendMessage(messageContentInput, batchId);
    });
    instantScroll();
  };

  const sendMessageAndAddDoctorIfNeeded = () => {
    if (isInExperience) {
      sendMessageAndAttachments();
    } else {
      addDoctorToExperience(
        { experienceUuid: experience.uuid, doctorUuid: user.uuid },
        { onSuccess: () => sendMessageAndAttachments() },
      );
    }
  };

  return {
    canSendMessage,
    consumeMessagesToSend,
    sendMessageAndAddDoctorIfNeeded,
    joiningExperience,
  };
};

export const useSendNewExperienceButtonUtils = () => {
  const { patient, instantScroll } = useMaybeQAExperience();
  const { consumeMessagesToSend, ...sendButtonsUtils } = useSendButtonsUtils();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [createQAExperienceWithMessages, createExperienceLoading] = useMutation(
    CreateQAExperienceWithMessages,
  );
  const getMessageContent = useGetMessageContent();

  const createExperienceAndSendMessages = async (
    onSuccess?: (result: CreateQAExperienceWithMessagesData) => void,
  ) => {
    setUploadLoading(true);

    const batchId = ephemeralUuidV4();
    const initialMessages = (
      await Promise.all(
        consumeMessagesToSend().map(async ([messageContentInput]) => {
          const content = await getMessageContent({
            content: messageContentInput,
          });
          if (!content) return null;
          return {
            content,
            batchIdentifier: batchId,
            isForwarded: false,
          };
        }),
      )
    ).filterNotNull();
    setUploadLoading(false);

    await createQAExperienceWithMessages(
      { patientUuid: patient.uuid, initialMessages },
      { onSuccess },
    );
    instantScroll();
  };

  return {
    createExperienceAndSendMessages,
    uploadLoading,
    createExperienceLoading,
    ...sendButtonsUtils,
  };
};
