import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import {
  FormAvatar,
  FormAvatarValue,
} from "components/Form/Dropzone/FormAvatar";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormPhoneInput } from "components/Form/Input/FormPhoneInput";
import { Input } from "components/Form/Input/Input";
import { FormTitleSelect } from "components/Form/Select/FormTitleSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { FormRow } from "components/FormRow/FormRow";
import { useDoctor } from "contexts/User/UserContext";
import { UpdateDoctorProfile } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  mutation UpdateDoctorProfile($payload: UpdateDoctorProfileInput!) {
    updateDoctorProfile(payload: $payload) {
      doctor {
        ...DoctorSummary
        ...UserPersonalInformation
      }
    }
  }

  fragment UserPersonalInformation on Doctor {
    uuid
    title
    bio
    phone
    rpps
    adeli
    amNumber
    bioAnalysesEmail
    gmcNumber
    nationalProviderIdentifier
    appointmentAddress {
      ...Address
    }
    prescriptionAddress {
      ...Address
    }
    signature {
      ...Signature
    }
    explicitTimezone
    timezone
    subOrganization {
      uuid
      locale
    }
  }

  query DoctorTitles {
    doctorTitles {
      titles
    }
  }
`;

type FormValues = {
  avatarPicture: FormAvatarValue;
  lastName: string;
  firstName: string;
  title: string;
  bio: string;
  phone: string | undefined;
  prefix: string;
};

export const PersonalInformationGeneral = () => {
  const t = useTranslation();
  const { user, email } = useDoctor();
  const [updateDoctorProfile] = useMutation(UpdateDoctorProfile);

  return (
    <Form<FormValues>
      className="flex-fill flex-col flex-col overflow-y-auto space-y-24"
      initialValues={{
        avatarPicture: { avatar: user.avatarUrl } as FormAvatarValue,
        lastName: user.lastName,
        firstName: user.firstName,
        title: user.title ?? "",
        bio: user.bio ?? "",
        phone: user.phone ?? "",
        prefix: user.prefix ?? "",
      }}
      validationSchema={{
        firstName: "required",
        lastName: "required",
      }}
      onSubmit={async ({
        avatarPicture,
        phone,
        title,
        bio,
        prefix,
        ...values
      }) =>
        updateDoctorProfile(
          {
            payload: {
              avatarPicture: (await avatarPicture.upload?.()) ?? null,
              title,
              bio,
              prefix: prefix.trimOrNull(),
              phone: phone?.isNotEmpty() ? phone : undefined,
              ...values,
            },
          },
          {
            onSuccess: () => {
              notifier.success(t("preferences.info.changes_saved"));
            },
          },
        )
      }
    >
      <FormAvatar name="avatarPicture" forUser={user} className="self-start" />
      <FormRow>
        <FormInput
          name="firstName"
          label={t("preferences.info.first_name")}
          placeholder={t("preferences.info.first_name")}
        />
        <FormInput
          name="lastName"
          label={t("preferences.info.last_name")}
          placeholder={t("preferences.info.last_name")}
        />
      </FormRow>
      <FormRow>
        <FormTitleSelect />
        <FormInput
          name="prefix"
          label={t("preferences.info.prefix")}
          placeholder="Dr, Pr, ..."
        />
      </FormRow>
      <FormRow>
        <Input disabled value={email ?? ""} name="email" label="Email" />
        <FormPhoneInput
          label={t("preferences.info.phone")}
          name="phone"
          placeholder={t("preferences.info.phone")}
        />
      </FormRow>
      <FormRow>
        <FormTextArea
          name="bio"
          label={t("preferences.info.biography")}
          placeholder={t("preferences.info.biography_placeholder")}
        />
      </FormRow>
      <Submit
        label={t("preferences.preferences_form.save")}
        className="mt-44 mr-auto"
      />
    </Form>
  );
};
