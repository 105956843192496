import gql from "graphql-tag";

import { FormInput } from "components/Form/Input/FormInput";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { FormModal } from "components/Modal/FormModal";
import {
  AppKeyFragment,
  CreateAppKey,
  UpdateAppKey,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = ORGANIZATION_USER
  mutation CreateAppKey($appKeyInput: CreateAppKeyInput!) {
    createAppKey(appKeyInput: $appKeyInput) {
      appKey {
        ...AppKey
      }
    }
  }

  mutation UpdateAppKey($uuid: UUID!, $appKeyInput: UpdateAppKeyInput!) {
    updateAppKey(uuid: $uuid, appKeyInput: $appKeyInput) {
      appKey {
        ...AppKey
      }
    }
  }
`;

type FormValues = {
  name: string;
  description: string;
};

export const AppKeyComposer = ({
  appKey,
  onClose,
}: {
  appKey?: AppKeyFragment;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const [createAppKey] = useMutation(CreateAppKey, {
    refetchQueries: ["AllAppKeys"],
    onSuccess: () => {
      notifier.success(
        t("developers.server_keys.app_key_composer.mobile_sdk_api_key_created"),
      );
      onClose();
    },
  });
  const [updateAppKey] = useMutation(UpdateAppKey, {
    onSuccess: () => {
      notifier.success(
        t("developers.server_keys.app_key_composer.mobile_sdk_api_key_updated"),
      );
      onClose();
    },
  });

  return (
    <FormModal<FormValues>
      title={
        appKey
          ? t("app_key_composer.edit_the_mobile_sdk_api_key")
          : t("app_key_composer.add_a_new_mobile_sdk_api_key")
      }
      submitLabel={
        appKey
          ? t("developers.server_keys.app_key_composer.save")
          : t("developers.server_keys.app_key_composer.create")
      }
      onHide={onClose}
      className="flex-col w-full"
      initialValues={{
        name: appKey?.name ?? "",
        description: appKey?.description ?? "",
      }}
      onSubmit={({ name, description }) => {
        const appKeyInput = {
          name,
          description,
        };

        if (!appKey) return createAppKey({ appKeyInput });
        return updateAppKey({ uuid: appKey.uuid, appKeyInput });
      }}
    >
      <FormInput
        name="name"
        label={t("developers.server_keys.app_key_composer.name")}
        placeholder={t("developers.server_keys.app_key_composer.name")}
        wrapperClassName="flex-fill"
      />
      <FormTextArea
        name="description"
        label={t("developers.server_keys.app_key_composer.description")}
        placeholder={t("developers.server_keys.app_key_composer.description")}
        wrapperClassName="flex-fill"
      />
    </FormModal>
  );
};
