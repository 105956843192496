import { PatientTimelineItemFragment } from "generated/provider";
import { isKnownUnionValue } from "utils/apollo";

import { KnownUnionValue } from "./apollo";
import { DistributiveOmit } from "./utils";

type PatientTimelineKnownItemFragment =
  KnownUnionValue<PatientTimelineItemFragment>;

export type PatientTimelineKnownItemTypes =
  PatientTimelineKnownItemFragment["__typename"];

export type PatientTimelineItemMetadata = NonNullable<
  PatientTimelineKnownItemFragment["patientTimelineItemMetadata"]
>;

/**
 * Valid timeline items are items which:
 * - Have a `__typename` that was known about at compile time.
 * - Have a non-null `patientTimelineItemMetadata` field.
 */
export type PatientTimelineValidItemFragment = DistributiveOmit<
  PatientTimelineKnownItemFragment,
  "patientTimelineItemMetadata"
> & {
  patientTimelineItemMetadata: PatientTimelineItemMetadata;
};

export const filterValidItems = (items: PatientTimelineItemFragment[]) =>
  items.filter(
    (item): item is PatientTimelineValidItemFragment =>
      isKnownUnionValue("PatientTimelineItem", item) &&
      !!item.patientTimelineItemMetadata,
  );

export type PatientTimelineValidItemFragmentOfType<
  T extends PatientTimelineKnownItemTypes,
> = Extract<PatientTimelineValidItemFragment, { __typename: T }>;
