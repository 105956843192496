import gql from "graphql-tag";

import { useShadowMode } from "atoms/useShadowMode";
import { AvatarDuo } from "components/Avatar/AvatarDuo";
// TODO: Move to hooks if we get a lesser structure rigide between hooks and context
import { useTypingIndicators } from "components/ConversationContent/Timeline/TypingIndicators/useTypingIndicators";
import { useDoctor } from "contexts/User/UserContext";
import {
  ExperienceSummaryFragment,
  MarkExperienceAsUnseen,
  PatientSummaryFragment,
  TimelineItemFragment,
  UserSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { DistributiveOmit } from "types";
import { showExperienceAsNotSeen } from "utils";
import { getKnownUnionValue } from "utils/apollo";
import {
  displayDoctor,
  displayExperienceTitle,
  SHADOW_USERNAME,
} from "utils/display";
import { getEventText } from "utils/experience";

import { InboxRow, InboxRowProps } from "./InboxRow";

gql`
  mutation MarkExperienceAsUnseen($uuid: UUID!) {
    markExperienceAsUnseen(experienceUuid: $uuid) {
      experience {
        uuid
        seenStatus
      }
    }
  }
`;

export const ConversationLink = <Experience extends ExperienceSummaryFragment>({
  experience,
  patient,
  hideAvatar,
  getTime = (e) => getKnownUnionValue("TimelineItem", e.lastItem)?.eventTime,
  getItemForSummary = (e) => e.lastItem,
  isInExperience,
  ...inboxRowProps
}: DistributiveOmit<
  InboxRowProps,
  "left" | "title" | "subtitle" | "notSeen" | "time" | "moreActions"
> & {
  experience: Experience;
  patient: PatientSummaryFragment | undefined;
  hideAvatar?: boolean;
  getTime?: (e: Experience) => ISOString | undefined;
  getItemForSummary?: (e: Experience) => TimelineItemFragment | null;
  isInExperience: boolean;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const [markAsUnread] = useMutation(MarkExperienceAsUnseen, {
    variables: { uuid: experience.uuid },
  });

  return (
    <InboxRow
      left={
        !hideAvatar && (
          <AvatarDuo
            doctors={experience.allDoctors}
            omitUser
            patient={patient}
            className="mr-12"
            withConnectedStatus
          />
        )
      }
      title={displayExperienceTitle(experience, user.uuid)}
      time={getTime(experience)}
      subtitle={
        <ConversationSummary
          experience={experience}
          item={getItemForSummary(experience)}
        />
      }
      notSeen={showExperienceAsNotSeen(user, experience)}
      moreActions={
        isInExperience
          ? [
              {
                text: t("inbox_row.conversation_link.mark_as_unread"),
                onClick: (close: () => void) => {
                  markAsUnread();
                  close();
                },
              },
            ]
          : []
      }
      {...inboxRowProps}
    />
  );
};

export const ConversationSummary = ({
  experience,
  item,
}: {
  experience: ExperienceSummaryFragment;
  item: TimelineItemFragment | null;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const { shadowMode } = useShadowMode();
  const { patientsIndicators, doctorsIndicators } =
    useTypingIndicators(experience);

  // This could be a bit off if a conversation grow to 3 and then
  // come back to 2 after delete or removed form the conversation
  // Let's wait to see if we want to handle 1-1 conversation
  // very specifically like workplace
  const omitOtherDoctorName =
    experience.type !== "SINGLE_PATIENT_CONVERSATION" &&
    experience.allDoctors.length === 2;
  const addSummaryPrefix = (
    author: UserSummaryFragment | null,
    content: string,
  ) => {
    if (author && author.__typename !== "Doctor") return content;
    if (omitOtherDoctorName) return content;
    const prefix =
      author?.uuid === user.uuid
        ? t("inbox_row.conversation_link.you")
        : displayDoctor(author, "FIRST_NAME");
    return t("inbox_row.conversation_link.summary_prefix", { prefix, content });
  };

  const typingDoctor = doctorsIndicators.at(-1)?.doctor;
  const typingPatient = patientsIndicators.at(-1)?.patient;

  return (
    <>
      {typingPatient
        ? "..."
        : typingDoctor
        ? addSummaryPrefix(typingDoctor, "...")
        : item
        ? item.__typename === "Message"
          ? addSummaryPrefix(
              item.sender,
              item.content.__typename === "TextMessageContent"
                ? shadowMode && experience.patient?.username
                  ? item.content.text.replaceAll(
                      experience.patient.username,
                      SHADOW_USERNAME,
                    )
                  : item.content.text
                : item.content.__typename === "DeletedMessageContent"
                ? t("inbox_row.conversation_link.deleted_message")
                : item.content.__typename === "FileMessageContent"
                ? t("inbox_row.conversation_link.file_sent")
                : item.content.__typename === "LivekitRoomMessageContent"
                ? t("inbox_row.conversation_link.livekit_room_sent")
                : t("inbox_row.conversation_link.media_sent"),
            )
          : item.__typename === "Poll"
          ? t("inbox_row.conversation_link.poll_updated")
          : item.__typename === "ExperienceEvent"
          ? getEventText(item)
          : null
        : t("inbox_row.conversation_link.new")}
    </>
  );
};
