import { useState } from "react";
import gql from "graphql-tag";
import { Range } from "react-date-range";

import { Query } from "components/Query/Query";
import {
  SpecialSubOrgStatsDashboardQuery,
  TimeRangeInput,
} from "generated/organizationuser";

import { Filter, FILTERS } from "./filterType";
import { StatsTimeRange } from "./StatsTimeRange";
import styles from "./statsDashboardHistory.module.css";

gql`
  # schema = ORGANIZATION_USER
  query SpecialSubOrgStatsDashboardQuery($page: TimeRangeInput) {
    subOrgStatsDashboard(page: $page) {
      sections {
        title
        description
        items {
          name
          value
        }
      }
    }
  }
`;

export const StatsDashboardSubOrg = () => {
  const [selectedRange, setSelectedRange] = useState<TimeRangeInput>();
  const [selectedPill, setSelectedPill] = useState<Filter>(FILTERS[0]);
  const [customRange, setCustomRange] = useState<Range>({
    key: "selection",
  });

  return (
    <div className="flex-col flex-start space-y-8">
      <StatsTimeRange
        selectedPill={selectedPill}
        setSelectedPill={setSelectedPill}
        customRange={customRange}
        setCustomRange={setCustomRange}
        setSelectedRange={setSelectedRange}
      />
      <Query
        // @graphql-schema-usage-ignore: This component is only rendered when `hasAccessToOrganizationUserApi`.
        query={SpecialSubOrgStatsDashboardQuery}
        variables={{ page: selectedRange }}
      >
        {(stats) => (
          <div className="flex-col flex-start space-y-8">
            <div className="flex-fill flex-col space-y-16">
              <div className={styles.histogramGrid}>
                {stats.sections.map((section, i) => (
                  <div
                    key={`suborg-stat-${i}`}
                    className="bg-white border rounded shadow-sm-outlined p-20 flex-col space-y-12"
                  >
                    <div className="font-bold text-12">
                      {section.title.toUpperCase()}
                    </div>
                    {section.items
                      .filter((item) => item.value > 0)
                      .map((item, j: number) => (
                        <div
                          key={`suborg-stat-${i}-${j}`}
                          className="flex justify-between items-start"
                        >
                          <div className="w-1/2">{item.name}</div>
                          <div className="w-1/2">{item.value}</div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Query>
    </div>
  );
};
