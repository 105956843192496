import classNames from "classnames";
import gql from "graphql-tag";

import { EHRSuggestion } from "components/EHRSuggestion/EHRSuggestion";
import {
  EHRSuggestionFragment,
  SetConditionExtractionStatus,
  SetContraceptionExtractionStatus,
  SetMedicationStatementExtractionStatus,
  SetPatientAllergyExtractionStatus,
  SetProcedureExtractionStatus,
  SetSymptomExtractionStatus,
  SetVaccinationStatementExtractionStatus,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useMediaQuery } from "hooks/useMediaQuery";
import { isKnownUnionValue, knownUnionValueFilter } from "utils/apollo";

gql`
  mutation SetContraceptionExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setContraceptionExtractionStatus(uuid: $uuid, status: $status) {
      contraception {
        uuid
        extractionStatus
      }
    }
  }

  mutation SetConditionExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setConditionExtractionStatus(uuid: $uuid, status: $status) {
      condition {
        uuid
        extractionStatus
      }
    }
  }

  mutation SetSymptomExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setSymptomExtractionStatus(uuid: $uuid, status: $status) {
      symptom {
        ...Symptom
      }
    }
  }

  mutation SetMedicationStatementExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setMedicationStatementExtractionStatus(uuid: $uuid, status: $status) {
      medicationStatement {
        uuid
        extractionStatus
      }
    }
  }

  mutation SetPatientAllergyExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setPatientAllergyExtractionStatus(uuid: $uuid, status: $status) {
      patientAllergy {
        uuid
        extractionStatus
      }
    }
  }

  mutation SetVaccinationStatementExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setVaccinationStatementExtractionStatus(uuid: $uuid, status: $status) {
      vaccinationStatement {
        uuid
        extractionStatus
      }
    }
  }

  mutation SetProcedureExtractionStatus(
    $uuid: UUID!
    $status: ExtractionStatus!
  ) {
    setProcedureExtractionStatus(uuid: $uuid, status: $status) {
      procedure {
        uuid
        extractionStatus
      }
    }
  }
`;

export const MessageEHRSuggestions = ({
  suggestions,
  wrapperClassname,
}: {
  suggestions: EHRSuggestionFragment[];
  wrapperClassname?: string;
}) => {
  const [updateContraception, updatingContraception] = useMutation(
    SetContraceptionExtractionStatus,
  );
  const [updateMedication, updatingMedication] = useMutation(
    SetMedicationStatementExtractionStatus,
  );
  const [updateCondition, updatingCondition] = useMutation(
    SetConditionExtractionStatus,
  );
  const [updatePatientAllergy, updatingPatientAllergy] = useMutation(
    SetPatientAllergyExtractionStatus,
  );
  const [updateVaccination, updatingVaccination] = useMutation(
    SetVaccinationStatementExtractionStatus,
  );
  const [updateSymptom, updatingSymptom] = useMutation(
    SetSymptomExtractionStatus,
  );
  const [updateProcedure, updatingProcedure] = useMutation(
    SetProcedureExtractionStatus,
  );
  const isLargeScreen = useMediaQuery("(min-width: 1200px)");
  const suggestionsToDisplay = suggestions
    .filter(knownUnionValueFilter("EHRSuggestion"))
    .filter((it) => it.extractionStatus === "PROPOSED");
  if (!isLargeScreen || suggestionsToDisplay.isEmpty()) return null;

  return (
    <div
      style={{ width: 220, maxWidth: "40%" }}
      className={classNames(
        "flex-col space-y-12 mr-36 mb-12",
        wrapperClassname,
      )}
    >
      {suggestionsToDisplay.mapNotNull((suggestion) => {
        if (!isKnownUnionValue("EHRSuggestion", suggestion)) return null;
        const uuid = suggestion.uuid;

        switch (suggestion.__typename) {
          case "PatientAllergy":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingPatientAllergy}
                onValid={() =>
                  updatePatientAllergy({ uuid, status: "ACCEPTED" })
                }
                onDelete={() =>
                  updatePatientAllergy({ uuid, status: "REJECTED" })
                }
              />
            );
          case "Condition":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingCondition}
                onValid={() => updateCondition({ uuid, status: "ACCEPTED" })}
                onDelete={() => updateCondition({ uuid, status: "REJECTED" })}
              />
            );
          case "Contraception":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingContraception}
                onValid={() =>
                  updateContraception({ uuid, status: "ACCEPTED" })
                }
                onDelete={() =>
                  updateContraception({ uuid, status: "REJECTED" })
                }
              />
            );
          case "Symptom":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingSymptom}
                onValid={() => updateSymptom({ uuid, status: "ACCEPTED" })}
                onDelete={() => updateSymptom({ uuid, status: "REJECTED" })}
              />
            );
          case "MedicationStatement":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingMedication}
                onValid={() => updateMedication({ uuid, status: "ACCEPTED" })}
                onDelete={() => updateMedication({ uuid, status: "REJECTED" })}
              />
            );
          case "VaccinationStatement":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingVaccination}
                onValid={() => updateVaccination({ uuid, status: "ACCEPTED" })}
                onDelete={() => updateVaccination({ uuid, status: "REJECTED" })}
              />
            );
          case "Procedure":
            return (
              <EHRSuggestion
                key={uuid}
                suggestion={suggestion}
                disabled={updatingProcedure}
                onValid={() => updateProcedure({ uuid, status: "ACCEPTED" })}
                onDelete={() => updateProcedure({ uuid, status: "REJECTED" })}
              />
            );
        }
      })}
    </div>
  );
};
