import { RefObject } from "react";
import { useFormikContext } from "formik";

import { useMediaWindows } from "atoms/useMediaWindows";
import {
  DocumentPreview,
  DocumentPreviewSection,
} from "components/Document/DocumentPreview";
import { FilePreview } from "components/Form/Dropzone/FilePreview";
import {
  FormDropzone,
  FormDropzoneValue,
  LocalUpload,
} from "components/Form/Dropzone/FormDropzone";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  CreatePatientDocument,
  DeleteDocument,
  ExperienceItemSummaryFragment,
  FileUploadFragment,
  UpdatePatientDocument,
  UploadInput,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { FileMessageInput } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { notifier } from "utils/notifier";

import { ExistingItemPillButton } from "../ItemPillButton";
import { PatientTimelineItem } from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";
import { usePatientTimelineLocalItemState } from "../PatientTimelineLocalStateContext";
import { useUploadPatientPdfFileForTimelineItem } from "./useUploadPatientPdfFileForTimelineItem";

type FormData = {
  title: string;
  description: string;
  plainTextContent: string;
};

type FormContext = {
  sendAfterCreate: boolean;
};

export const PatientDocumentItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"PatientDocument">;
}) => {
  const t = useTranslation();
  const { itemState } = usePatientTimelineLocalItemState(item.uuid);
  const [updatePatientDocument] = useMutation(UpdatePatientDocument);
  const [deletePatientDocument] = useMutation(DeleteDocument, {
    onSuccess: ({ patientDocumentUuid }, client) =>
      client.remove("PatientDocument", patientDocumentUuid),
  });
  const { pdfPreviewRef, pdfGenerator, uploadPatientPdfFile } =
    useUploadPatientPdfFileForTimelineItem("PATIENT_DOCUMENT");

  const { showMedia } = useMediaWindows();

  const fileUpload = item.fileUpload;
  const fileGenerator: () => Promise<FileMessageInput> = () => {
    if (
      isBlankDocument &&
      itemState.mode === "EXPANDED" &&
      itemState.isEditing
    ) {
      return pdfGenerator(item.title);
    }
    return Promise.resolve(fileUpload);
  };

  const isBlankDocument = !!item.plainTextContent;

  const children: (
    | ExperienceItemSummaryFragment
    | { __typename: "Appointment"; uuid: UUID; title: string }
  )[] = Object.assign([], item.attachedTo);
  if (item.savedFrom !== null) children.push(item.savedFrom);

  return (
    <DeletionConfirmationModal
      suffix={t("patient_view.patient_document.delete_suffix")}
      onConfirm={async (closeDeletionModal) => {
        await deletePatientDocument({ uuid: item.uuid });
        notifier.success(t("patient_view.patient_document.delete_success"));
        closeDeletionModal();
      }}
    >
      {(openDeletionModal) => (
        <PatientTimelineItem<"PatientDocument", FormData, FormData>
          item={item}
          getFileAttachment={fileGenerator}
          withFaxOptions={
            isBlankDocument
              ? {
                  documentType: "DOCUMENT",
                  getPreview: (previewRef, comment) => (
                    <BlankDocumentPdfPreview
                      pdfPreviewRef={previewRef}
                      comment={comment}
                      data={{
                        title: item.title,
                        plainTextContent: item.plainTextContent ?? "",
                        description: item.description ?? "",
                      }}
                    />
                  ),
                }
              : {
                  documentType: "DOCUMENT",
                  fileUuid: fileUpload.uuid,
                }
          }
          additionalMenuItems={[
            {
              text: t("patient_view.patient_document.preview"),
              icon: "filePreview",
              onClick: (closeMenu: () => void) => {
                fileGenerator().then((media) => {
                  if ("file" in media) {
                    showMedia({
                      blob: media.file as Blob,
                      title: media.file.name,
                    });
                  } else {
                    showMedia(media);
                  }
                });
                closeMenu();
              },
            },
            {
              text: t("patient_view.item.delete"),
              icon: "trash",
              className: "text-danger",
              onClick: (closeMenu: () => void) => {
                closeMenu();
                openDeletionModal();
              },
            },
          ]}
          initialValues={{
            title:
              item.title.trimOrNull() ??
              t("patient_view.default_title.patient_document"),
            description: item.description ?? "",
            plainTextContent: item.plainTextContent ?? "",
          }}
          validationSchema={{
            title: "required",
          }}
          footerChildren={
            children.isNotEmpty()
              ? children.map((child) => (
                  <ExistingItemPillButton key={child.uuid} item={child} />
                ))
              : undefined
          }
          onSubmit={async ({ title, description, plainTextContent }) => {
            let upload: UploadInput | undefined;
            if (isBlankDocument) {
              upload = (await uploadPatientPdfFile(title)) ?? undefined;
            }

            await updatePatientDocument({
              uuid: item.uuid,
              input: { title, description, plainTextContent, upload },
            });
            notifier.success(t("patient_view.patient_document.update_success"));
          }}
        >
          <PatientTimelineItemContentWithPadding className="space-y-20">
            {((itemState.mode === "EXPANDED" && itemState.isEditing) ||
              item.description ||
              item.plainTextContent) && (
              <DocumentTextArea isBlankDocument={isBlankDocument} />
            )}
            {!isBlankDocument && (
              <div>
                <FileUploadCard upload={item.fileUpload} />
              </div>
            )}
          </PatientTimelineItemContentWithPadding>
          {isBlankDocument && (
            <BlankDocumentPdfPreview pdfPreviewRef={pdfPreviewRef} />
          )}
        </PatientTimelineItem>
      )}
    </DeletionConfirmationModal>
  );
};

export const NewPatientDocumentItem = ({
  temporaryUuid,
  appointmentUuid,
  shouldUploadDocument,
  documentTemplate,
}: {
  temporaryUuid: UUID;
  appointmentUuid?: UUID;
  shouldUploadDocument?: boolean;
  documentTemplate?: { title: string; text: string };
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { expandOtherItem, attachItemToMessage } =
    usePatientTimelineLocalItemState(temporaryUuid);

  const { pdfPreviewRef, uploadPatientPdfFile } =
    useUploadPatientPdfFileForTimelineItem("PATIENT_DOCUMENT");

  const [createPatientDocument, loading] = useMutation(CreatePatientDocument);

  const initialUpload: FormDropzoneValue = {
    purpose: "PATIENT_DOCUMENT",
    upload: undefined,
  };

  return (
    <PatientTimelineItem<
      "PatientDocument",
      FormData & { upload: FormDropzoneValue },
      FormData,
      FormContext
    >
      item={{ temporaryUuid, __typename: "PatientDocument" }}
      initialValues={{
        title:
          documentTemplate?.title ??
          t("patient_view.default_title.patient_document"),
        description: "",
        plainTextContent: documentTemplate?.text ?? "",
        upload: initialUpload,
      }}
      validationSchema={{
        title: "required",
      }}
      validate={({ upload, plainTextContent }) => ({
        ...(shouldUploadDocument &&
          !upload.upload && {
            upload: t("document_composer.please_select_a_document_to_upload"),
          }),
        ...(!shouldUploadDocument &&
          !plainTextContent && {
            plainTextContent: t(
              "document_composer.please_write_document_content",
            ),
          }),
      })}
      toDraft={({ title, description, plainTextContent }) => ({
        title,
        description,
        plainTextContent,
      })}
      fromDraft={({ title, description, plainTextContent }) => ({
        title,
        description,
        plainTextContent,
        upload: initialUpload, // Local file uploads can't be saved in local storage.
      })}
      submitButton={{
        label: attachItemToMessage
          ? t("patient_view.save_and_send")
          : t("patient_view.save"),
      }}
      onSubmit={async (
        { title, description, plainTextContent, upload },
        _actions,
        context,
      ) => {
        // The file needs to be uploaded before creating the document.
        let uploadInput = upload.upload
          ? await (upload.upload as LocalUpload).getInput()
          : null;

        if (!shouldUploadDocument) {
          uploadInput = await uploadPatientPdfFile(title);
        }
        if (!uploadInput) return;

        const patientDocumentData = await createPatientDocument({
          patientUuid: patient.uuid,
          appointmentUuid,
          title,
          description,
          plainTextContent,
          visibleToPatient: true,
          category: "MISC",
          upload: uploadInput,
        });
        if (!patientDocumentData) return;

        if (attachItemToMessage && (!context || context.sendAfterCreate)) {
          attachItemToMessage(patientDocumentData.document.fileUpload);
        } else {
          expandOtherItem(patientDocumentData.document.uuid);
        }

        notifier.success(t("patient_view.patient_document.create_success"));
      }}
      additionalSubmitButtons={
        attachItemToMessage
          ? [
              {
                text: t("patient_view.save"),
                context: { sendAfterCreate: false },
              },
            ]
          : []
      }
    >
      <PatientTimelineItemContentWithPadding className="space-y-20">
        <DocumentTextArea isBlankDocument={!shouldUploadDocument} />
        {shouldUploadDocument && (
          <div>
            {/* TODO(@liautaud): Unify with the `FileUploadCard`. */}
            <FormDropzone
              name="upload"
              maxSize={50_000_000}
              autofocus
              preventUnloadOnPending={!loading}
              className="rounded border border-[#cacaca] overflow-hidden max-w-[500px] h-[180px]"
              placeholder={
                <>
                  <Icon name="uploadCloud" className="mr-10" size={20} />
                  {t(
                    "document_composer.drop_or_upload_a_document_from_your_computer",
                  )}
                </>
              }
            />
          </div>
        )}
      </PatientTimelineItemContentWithPadding>
      {!shouldUploadDocument && (
        <BlankDocumentPdfPreview pdfPreviewRef={pdfPreviewRef} />
      )}
    </PatientTimelineItem>
  );
};

const FileUploadCard = ({ upload }: { upload: FileUploadFragment }) => {
  // TODO(@liautaud): Update the media window component to match the Figma.
  const { showMedia } = useMediaWindows();

  return (
    <div
      className="rounded border overflow-hidden max-w-[500px] cursor-zoom-in"
      onClick={() => showMedia(upload)}
    >
      <div className="flex items-center bg-grey-100 px-14">
        <h4 className="text-13 text-grey-400 font-medium py-14 leading-none flex-fill truncate">
          {upload.fileName}
        </h4>
      </div>
      <FilePreview
        className="h-[150px] pointer-events-none w-full"
        name={upload.fileName}
        type={upload.mimeType}
        url={upload.urlV2.url}
      />
    </div>
  );
};

const BlankDocumentPdfPreview = ({
  pdfPreviewRef,
  comment,
  data = undefined,
}: {
  pdfPreviewRef: RefObject<HTMLDivElement>;
  comment?: string;
  data?: FormData;
}) => {
  const t = useTranslation();
  const { values } = useFormikContext<FormData>();

  return (
    <div className="hidden">
      <DocumentPreview
        previewRef={pdfPreviewRef}
        defaultSignature={t("pdf_preview.default_signature.document")}
      >
        <DocumentPreviewSection
          className="mt-24 whitespace-pre"
          title={data ? data.title : values.title}
        >
          {data ? data.plainTextContent : values.plainTextContent}
        </DocumentPreviewSection>
        {comment && (
          <DocumentPreviewSection
            className="mt-24"
            title={t("pdf_preview.fax_comment")}
          >
            <div>{comment}</div>
          </DocumentPreviewSection>
        )}
      </DocumentPreview>
    </div>
  );
};

const DocumentTextArea = ({
  isBlankDocument,
}: {
  isBlankDocument: boolean;
}) => {
  const t = useTranslation();
  const label = isBlankDocument
    ? undefined
    : t("patient_view.patient_document.description");
  const placeholder = isBlankDocument
    ? t("patient_view.patient_document.write")
    : t("patient_view.patient_document.add_a_description");

  return (
    <FormTextArea
      name={isBlankDocument ? "plainTextContent" : "description"}
      label={label}
      placeholder={placeholder}
      className="flex-fill justify-between h-full"
      minRows={2}
    />
  );
};
