// eslint-disable-next-line no-restricted-imports
import { Trans } from "react-i18next";

import type { TranslationProps } from "./generated";

/**
 * The default `Trans` component is too flexible. This wrapper + the codegen
 * enforce a simple safe api: Always render as a Fragment and inject JSX nodes
 * via a map with explicit names.
 */
export const Translation = ({ k, components, ...rest }: TranslationProps) => {
  // The `Trans` component directly accepts JSX elements instead of components,
  // which is annoying because it prevents us from checking at the type-level
  // that we only pass elements which are ready to receive `children`. This
  // is in particular not the case for our `<Icon>` element, which breaks if
  // passed a `children` property. To work around this problem, we accept
  // `React.ComponentType` instead, and convert to JSX elements here.
  const elements: Record<string, JSX.Element> = {};
  for (const [key, Component] of Object.entries(components)) {
    elements[key] = <Component />;
  }
  return <Trans i18nKey={k} as="Fragment" components={elements} {...rest} />;
};
