import {
  CSSProperties,
  ReactNode,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";

import { AutoPagesOptions } from "./usePDFPreview";

const HEIGHT = 1121; // A little bit less than 841.89 * 96 / 72
const RATIO = 21 / 29.7;

export const PDFPreview = ({
  previewRef,
  children,
  className,
  documentClassName,
  scale = 0.5,
  style,
  autoPagesOptions,
}: {
  previewRef: RefObject<HTMLDivElement> | null; // Null allows for view only
  children: ReactNode;
  documentClassName?: string;
  className?: string;
  scale?: number;
  style?: CSSProperties;
  autoPagesOptions?: AutoPagesOptions;
}) => {
  const [viewPage, setViewPage] = useState(0);
  const nbPages =
    Array.isArray(children) && !autoPagesOptions ? children.length : 1;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const translate = ((1 / scale - 1) * 100) / 2;

  useEffect(() => {
    setViewPage((current) => Math.min(current, nbPages - 1));
  }, [nbPages]);

  useLayoutEffect(() => {
    wrapperRef.current!.scrollTop = viewPage * (HEIGHT * scale);
  }, [viewPage, scale]);

  return (
    <div
      className={classNames(
        "flex-fill flex-col items-center overflow-auto",
        className,
      )}
    >
      {nbPages > 1 && (
        <div className="mb-12 flex items-center">
          <ClickableIcon
            name="chevron"
            onClick={() => setViewPage(viewPage - 1)}
            disabled={viewPage === 0}
            rotate={180}
          />
          <div>Page {viewPage + 1}</div>
          <ClickableIcon
            name="chevron"
            onClick={() => setViewPage(viewPage + 1)}
            disabled={viewPage >= nbPages - 1}
          />
        </div>
      )}
      <div
        ref={wrapperRef}
        className="border overflow-hidden"
        style={{
          paddingTop: (autoPagesOptions?.margin[0] ?? 0) * scale,
          paddingLeft: (autoPagesOptions?.margin[1] ?? 0) * scale,
          paddingBottom: (autoPagesOptions?.margin[2] ?? 0) * scale,
          paddingRight: (autoPagesOptions?.margin[3] ?? 0) * scale,
          // + 2 for border
          height: autoPagesOptions ? undefined : HEIGHT * scale + 2,
          width: HEIGHT * scale * RATIO + 2,
        }}
      >
        <div
          style={{
            transform: `scale(${scale}) translateY(-${translate}%) translateX(-${translate}%)`,
          }}
        >
          <div
            ref={previewRef}
            className={documentClassName}
            style={{
              height: autoPagesOptions ? undefined : HEIGHT * nbPages,
              width:
                HEIGHT * RATIO -
                (autoPagesOptions
                  ? autoPagesOptions.margin[1] + autoPagesOptions.margin[3]
                  : 0),
              fontFamily: "Helvetica",
              ...style,
            }}
          >
            {Array.isArray(children) && !autoPagesOptions
              ? children.map((page, index) => (
                  <div key={index} style={{ height: HEIGHT }}>
                    {index > 0 && <div className="html2pdf__page-break" />}
                    {page}
                  </div>
                ))
              : children}
          </div>
        </div>
      </div>
    </div>
  );
};
