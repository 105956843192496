import { Suspense, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Icon } from "components/Icon/Icon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import {
  KnownTimelineItem,
  useLoadedExperience,
} from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  ExperienceEventFragment,
  RescheduleOutdatedMessage,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { ReplyMessage as ReplyMessageType } from "types";
import { isKnownUnionValue } from "utils/apollo";
import { getEventText } from "utils/experience";

import { useMarkExperienceAsSeen } from "../useMarkExperienceAsSeen";
import { ChatBubbles, SeenTuple, SentIndicator } from "./ChatBubbles";
import {
  BatchOrTimelineItem,
  MessageFragmentWithImageContent,
} from "./MessageContainer/ImageBatchPreview";
import { MessageContainer } from "./MessageContainer/MessageContainer";
import { PollMessage } from "./MessageContainer/PollMessage";
import { TypingIndicators } from "./TypingIndicators/TypingIndicators";

gql`
  fragment SeenState on Experience {
    uuid
    seenStatus
    patientSeenUntil
    doctorsSeenUntil {
      seenUntil
      doctor {
        ...DoctorSummary
      }
    }
  }

  mutation RescheduleOutdatedMessage($uuid: UUID!) {
    rescheduleOutdatedMessage(scheduledMessageUuid: $uuid) {
      experience {
        ...ScheduleMessages
      }
    }
  }
`;

export const Timeline = ({
  onAvatarClick,
  search,
  compact,
  onCreatePatientTimelineItem,
}: {
  onAvatarClick: ((uuid: UUID) => void) | undefined;
  search?: string;
  compact?: boolean;
  onCreatePatientTimelineItem?: (uuid: UUID) => void;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const {
    items: timelineItems,
    sendingItems,
    retrySendMessage,
    deleteFailedMessage,
    scrollIfCloseToBottom,
    experience,
    scheduledMessages,
    type,
    DoctorMessageActions,
    textAreaRef,
    setReplyMessage,
    readonly,
  } = useLoadedExperience();
  useMarkExperienceAsSeen();
  const [focusedMessageUuid, setFocusedMessageUuid] = useState<UUID>();
  const [rescheduleOutdatedMessage] = useMutation(RescheduleOutdatedMessage);

  const horizontalPaddingClassName = compact ? "px-14" : "px-16";

  useEffect(
    () => scrollIfCloseToBottom(),
    [timelineItems.length, sendingItems.length, scrollIfCloseToBottom],
  );

  const onReply = useCallback(
    (replyMessage: ReplyMessageType) => {
      setReplyMessage(replyMessage);
      textAreaRef.current?.focus();
    },
    [setReplyMessage, textAreaRef],
  );

  const toggleFocusedMessageUuid = useCallback((uuid: UUID) => {
    setFocusedMessageUuid((current) => (current === uuid ? undefined : uuid));
  }, []);

  const getSender = (item: BatchOrTimelineItem) =>
    item.__typename === "Message" &&
    isKnownUnionValue("User", item.sender) &&
    item.sender.uuid;

  const items = itemsWithBatchedImages(timelineItems);

  const showSendingItemsTime =
    sendingItems.isNotEmpty() &&
    (items.isEmpty() ||
      shouldDisplayMessageEventTime(
        items.at(-1)!.eventTime,
        sendingItems[0].eventTime,
      ));

  const seenUntilTuples = experience.doctorsSeenUntil
    .mapNotNull(({ doctor, seenUntil }): SeenTuple | null =>
      doctor.uuid === user.uuid ? null : { user: doctor, until: seenUntil },
    )
    .concatIf(experience.type === "SINGLE_PATIENT_CONVERSATION", {
      user: experience.patient!,
      until: experience.patientSeenUntil,
    });

  return (
    <>
      <div className={classNames("flex-col", horizontalPaddingClassName)}>
        {items.map((item, i) => {
          const showTime =
            i === 0 ||
            focusedMessageUuid === item.uuid ||
            shouldDisplayMessageEventTime(
              items[i - 1].eventTime,
              item.eventTime,
            );
          const isLastItem = i === items.length - 1;
          const usersSeenUntilHere = seenUntilTuples.filter(
            ({ until }) =>
              until.isAfterOrEqual(item.eventTime) &&
              (isLastItem || until.isBefore(items[i + 1].eventTime)),
          );

          if (
            item.__typename !== "ImageBatch" &&
            !isKnownUnionValue("TimelineItem", item)
          ) {
            return null;
          }

          return (
            <Suspense key={item.uuid}>
              <span id={`timeline-${item.uuid}`}>
                {showTime && <TimelineTime time={item.eventTime} />}
                {item.__typename === "Poll" ? (
                  <PollMessage poll={item} />
                ) : item.__typename === "Message" ||
                  item.__typename === "ImageBatch" ? (
                  <MessageContainer
                    message={item}
                    userUuid={user.uuid}
                    experienceUuid={experience.uuid}
                    experienceType={type}
                    isFirstOfGroup={
                      i === 0 ||
                      getSender(items[i - 1]) !== getSender(item) ||
                      showTime
                    }
                    toggleFocusedMessageUuid={toggleFocusedMessageUuid}
                    onAvatarClick={onAvatarClick}
                    onReply={onReply}
                    DoctorMessageActions={DoctorMessageActions}
                    readonly={readonly}
                    search={search}
                    onCreatePatientTimelineItem={onCreatePatientTimelineItem}
                  />
                ) : getEventText(item) ? (
                  <div
                    className={classNames(
                      "text-center text-12",
                      i === 0 ||
                        items[i - 1].__typename !== "ExperienceEvent" ||
                        showTime
                        ? "mt-12"
                        : "mt-8",
                    )}
                  >
                    {item.content.__typename === "DoctorJoinedExperience" ||
                    item.content.__typename === "ExperienceRenamed" ||
                    item.content.__typename ===
                      "PatientCompletedQuestionsSet" ? (
                      getEventText(item)
                    ) : (
                      <ProviderOnlyEventText event={item} />
                    )}
                  </div>
                ) : null}
                {usersSeenUntilHere.isNotEmpty() ? (
                  <ChatBubbles tuples={usersSeenUntilHere} />
                ) : isLastItem &&
                  item.__typename === "Message" &&
                  item.sender?.__typename === "Doctor" &&
                  item.sender.uuid === user.uuid ? (
                  <SentIndicator sent />
                ) : null}
              </span>
            </Suspense>
          );
        })}
        <TypingIndicators />
        {showSendingItemsTime && (
          <TimelineTime time={sendingItems[0].eventTime} />
        )}
        {sendingItems.map((item, index) => (
          <Suspense key={item.uuid}>
            <MessageContainer
              message={item}
              userUuid={user.uuid}
              experienceUuid={experience.uuid}
              experienceType={type}
              isFirstOfGroup={
                items.isEmpty() ||
                (index === 0 &&
                  (showSendingItemsTime ||
                    getSender(items.at(-1)!) !== getSender(item)))
              }
              toggleFocusedMessageUuid={toggleFocusedMessageUuid}
              onAvatarClick={onAvatarClick}
              localProps={{
                sending: !item.failed,
                failed: item.failed,
                retrySendMessage,
                deleteFailedMessage,
                percentageProgress: item.percentageProgress,
              }}
              onCreatePatientTimelineItem={onCreatePatientTimelineItem}
            />
          </Suspense>
        ))}
        {sendingItems
          .at(-1)
          ?.let(
            (it) =>
              it.content.__typename === "TextMessageContent" && (
                <SentIndicator sent={false} />
              ),
          )}
        <div className="h-32" />
      </div>
      {scheduledMessages.isNotEmpty() && (
        <div
          className={classNames(
            "mt-auto pb-16 bg-grey-100 flex-col",
            horizontalPaddingClassName,
          )}
        >
          <TimelineTime
            prefix={t("conversation_content.timeline.timeline.scheduled_")}
            time={scheduledMessages[0].scheduledAt}
          />
          {scheduledMessages[0].isOutdated && (
            <div className="mt-12 flex justify-center items-center text-danger">
              <Icon name="warning" />
              <div className="mx-8">
                {t("conversation_content.timeline.timeline.schedule_cancelled")}
              </div>
              <button
                className="underline text-primary"
                onClick={() =>
                  rescheduleOutdatedMessage({ uuid: scheduledMessages[0].uuid })
                }
              >
                {t("conversation_content.timeline.timeline.keep_this_message")}
              </button>
            </div>
          )}
          {scheduledMessages.map((it, i) => (
            <Suspense key={it.uuid}>
              {focusedMessageUuid === it.uuid && (
                <TimelineTime
                  prefix={t("conversation_content.timeline.timeline.sent_")}
                  time={it.createdAt}
                />
              )}
              <MessageContainer
                isScheduled
                message={{
                  __typename: "Message",
                  uuid: it.uuid,
                  content: {
                    __typename: "TextMessageContent",
                    text: it.textContent,
                  },
                  senderType: "DOCTOR",
                  eventTime: it.createdAt,
                  isForwarded: false,
                  reactions: [],
                  clientIdentifier: null,
                  batchIdentifier: null,
                  replyMessage: null,
                  sender: it.author,
                  extractedEHRSuggestions: [],
                  handledAt: null,
                  assignedDoctor: null,
                }}
                userUuid={user.uuid}
                experienceUuid={experience.uuid}
                experienceType={type}
                isFirstOfGroup={i === 0}
                toggleFocusedMessageUuid={toggleFocusedMessageUuid}
                onAvatarClick={onAvatarClick}
                readonly={readonly}
                onCreatePatientTimelineItem={onCreatePatientTimelineItem}
              />
            </Suspense>
          ))}
        </div>
      )}
    </>
  );
};

const shouldDisplayMessageEventTime = (
  previousDate: ISOString,
  currentDate: ISOString,
) =>
  previousDate.getDate().toLocaleDateString() !==
    currentDate.getDate().toLocaleDateString() ||
  currentDate.getTime() - previousDate.getTime() > 3_600_000;

const TimelineTime = ({
  prefix,
  time,
}: {
  prefix?: string;
  time: ISOString;
}) => (
  <div className="mt-20 text-center font-bold text-[11px] uppercase">
    {prefix}
    {time.format({ relative: "weekWithTime" })}
  </div>
);

const ProviderOnlyEventText = ({
  event,
}: {
  event: ExperienceEventFragment;
}) => {
  const t = useTranslation();
  return (
    <div className="flex justify-center">
      <TooltipWrapper
        label={t("conversation_content.timeline.timeline.visible")}
        position="bottom"
      >
        <Icon name="eyeSlash" />
      </TooltipWrapper>
      <span>{getEventText(event)}</span>
    </div>
  );
};

const currentBatchToBatchedItem = (
  currentBatch: MessageFragmentWithImageContent[],
): BatchOrTimelineItem[] =>
  currentBatch.length > 1
    ? [
        {
          __typename: "ImageBatch",
          messages: currentBatch,
          uuid: currentBatch[0].batchIdentifier!,
          eventTime: currentBatch[0].eventTime,
          batchIdentifier: currentBatch[0].batchIdentifier!,
          sender: currentBatch[0].sender,
          senderType: currentBatch[0].senderType,
        },
      ]
    : currentBatch;

const itemsWithBatchedImages = (
  items: KnownTimelineItem[],
): BatchOrTimelineItem[] => {
  const batchedItems: BatchOrTimelineItem[] = [];
  let currentBatch: MessageFragmentWithImageContent[] = [];
  for (const item of items) {
    if (
      item.__typename === "Message" &&
      item.content.__typename === "ImageMessageContent" &&
      item.batchIdentifier
    ) {
      if (
        currentBatch.isEmpty() ||
        (item.batchIdentifier &&
          item.batchIdentifier === currentBatch[0].batchIdentifier)
      ) {
        currentBatch.push(item as MessageFragmentWithImageContent);
      } else {
        batchedItems.push(...currentBatchToBatchedItem(currentBatch));
        currentBatch = [item as MessageFragmentWithImageContent];
      }
    } else {
      batchedItems.push(...currentBatchToBatchedItem(currentBatch));
      batchedItems.push(item);
      currentBatch = [];
    }
  }

  batchedItems.push(...currentBatchToBatchedItem(currentBatch));
  return batchedItems;
};
