import { useEffect, useState } from "react";

const getLocalSpeechRecognizer = (locale: string) => {
  if ("webkitSpeechRecognition" in window) {
    const SpeechRecognition = window.webkitSpeechRecognition;
    const recognizer = new SpeechRecognition();
    recognizer.lang = locale;
    recognizer.continuous = true;
    recognizer.interimResults = true;
    return recognizer;
  }
  return undefined;
};

export type TranscriptItem = {
  speaker: "DOCTOR" | "PATIENT";
  text: string;
};

export const useLocalSpeechToText = (locale: "en-US" | "fr-FR" | "pt-BR") => {
  const [transcriptItems, setTranscriptItems] = useState<TranscriptItem[]>([]);
  const [offset, setOffset] = useState(0);
  const [transcribing, setTranscribing] = useState(false);
  const [recognizer, setRecognizer] = useState(
    getLocalSpeechRecognizer(locale),
  );

  useEffect(() => {
    if (!recognizer) return;
    if (locale !== recognizer.lang) {
      recognizer.stop();
      setRecognizer(getLocalSpeechRecognizer(locale));
    }
  }, [locale, recognizer]);

  const onTranscriptResult = (
    results: SpeechRecognitionResultList,
    speaker: "DOCTOR" | "PATIENT",
  ) => {
    // Apparently we can't iterate normally on a SpeechRecognitionResultList
    const newTranscriptItems: TranscriptItem[] = [];
    for (const result of results) {
      const transcriptItem = {
        text: `${result.item(0).transcript.trim()} `,
        speaker,
      };
      if (transcriptItem.text !== "") newTranscriptItems.push(transcriptItem);
    }
    setTranscriptItems(
      transcriptItems.slice(0, offset).concat(newTranscriptItems),
    );
  };
  const startTranscribing = (speaker: "DOCTOR" | "PATIENT") => {
    if (recognizer) {
      recognizer.start();
      recognizer.onresult = (e: any) => {
        onTranscriptResult(e.results, speaker);
      };
      setTranscribing(true);
    }
  };
  const stopTranscribing = () => {
    if (recognizer) {
      recognizer.stop();
      setTranscribing(false);
      setOffset(transcriptItems.length);
    }
  };

  return {
    available: recognizer !== undefined,
    transcriptItems,
    transcribing,
    startTranscribing,
    stopTranscribing,
  };
};
