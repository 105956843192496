import { useDraft } from "atoms/useDraft";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  PatientTimelinePillType,
  PatientTimelinePillTypeKnownValues,
} from "generated/provider";
import { useInitialValue } from "hooks";
import { EHRInput, EHRInputType } from "types";
import { isKnownValue } from "utils/enum";

export const useEHRInput = () => {
  const { store, setDraft } = useDraft();
  const { patient } = usePatient();
  const ehrInput = store[patient.uuid]?.ehrInput;
  const setEHRInput = (payload: Partial<EHRInput>) =>
    setDraft(patient.uuid, { ehrInput: { ...ehrInput, ...payload } });

  const ehrInputWithDefaults = {
    type: ehrInput?.type,
    title: ehrInput?.title ?? "",
    content: ehrInput?.content ?? { text: "", mentions: [] },
    description: ehrInput?.description ?? "",
    familyMember: ehrInput?.familyMember ?? "MOTHER",
    isPrescriptionTemplate: ehrInput?.isPrescriptionTemplate ?? false,
    isPrescriptionSignatureRx: ehrInput?.isPrescriptionSignatureRx ?? false,
    isPrescriptionCheckboxChecked:
      ehrInput?.isPrescriptionCheckboxChecked ?? false,
    atomicInput: ehrInput?.atomicInput ?? "",
    dataPointValue: ehrInput?.dataPointValue ?? "",
    dataPointType: ehrInput?.dataPointType ?? "WEIGHT_KG",
    onPatientInfoRequiredForPrescription:
      ehrInput?.onPatientInfoRequiredForPrescription,
  };

  return {
    ehrInput: ehrInputWithDefaults,
    initialEHRInput: useInitialValue(ehrInputWithDefaults),
    setEHRInput,
    resetEHRInput: () =>
      setEHRInput({
        type: undefined,
        title: undefined,
        content: undefined,
        familyMember: undefined,
        description: undefined,
        isPrescriptionTemplate: undefined,
        isPrescriptionSignatureRx: undefined,
        isPrescriptionCheckboxChecked: undefined,
        atomicInput: undefined,
        onPatientInfoRequiredForPrescription: undefined,
      }),
  };
};

export const getEhrInputTypeForFilter = (
  filter: PatientTimelinePillType | undefined,
): EHRInputType => {
  if (!filter) return "NOTE";
  if (!isKnownValue(filter, PatientTimelinePillTypeKnownValues)) return "NOTE";

  switch (filter) {
    case "HISTORY_OR_COMMENTS":
    case "NOTES":
      return "NOTE";
    case "DOCUMENTS":
      return "DOCUMENT";
    case "PMH":
      return "MEDICAL";
    case "MEDICATION":
      return "MEDICATION";
    case "OBSERVATIONS":
      return "DATA_POINT";
    case "PREVENTION":
      return "VACCINE";
    case "TASK":
      return "TASK";
    case "DATAPOINTS":
      return "DATA_POINT";
    case "ALLERGY":
      return "ALLERGY";
    case "MEDICAL_ATCD":
    case "FAMILY_ATCD":
    case "SURGICAL_ATCD":
      return "MEDICAL";
    case "VACCINE":
      return "VACCINE";
  }
};
