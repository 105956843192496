import { ActivityLogFragment } from "generated/provider";
import { staticT as t } from "i18n";

export const ACTIVITY_LOG_TYPES = [
  "practitioner.registered",
  "practitioner.deleted",
  "practitioner.invited",
  "practitioner.info.update",
  "practitioner.mfa.update",
  "practitioner.password.failure",
  "practitioner.mfa.failure",
  "practitioner.password.update",
  "practitioner.availability.create",
  "practitioner.availability.delete",
  "practitioner.availability.update",
  "organization.tags.create",
  "organization.tags.update",
  "organization.tags.delete",
  "organization.webhooks.create",
  "organization.webhooks.update",
  "organization.webhooks.delete",
  "organization.serviceHours.update",
  "organization.update.branding",
  "organization.appKeys.create",
  "organization.appKeys.update",
  "organization.appKeys.delete",
  "organization.serverKeys.create",
  "organization.serverKeys.update",
  "organization.serverKeys.delete",
  "organization.note_template.create",
  "organization.note_template.update",
  "organization.note_template.delete",
  "mlmodel.training",
  "patient.delete",
  "practitioner.sendMessageToPatient",
  "patient.ehr.allergy.update",
  "patient.ehr.allergy.create",
  "patient.ehr.allergy.delete",
  "patient.ehr.condition.update",
  "patient.ehr.condition.create",
  "patient.ehr.condition.delete",
  "patient.ehr.contraception.update",
  "patient.ehr.contraception.create",
  "patient.ehr.contraception.delete",
  "patient.ehr.datapoint.update",
  "patient.ehr.datapoint.create",
  "patient.ehr.datapoint.delete",
  "patient.ehr.document.update",
  "patient.ehr.document.create",
  "patient.ehr.document.delete",
  "patient.ehr.lifestyle.update",
  "patient.ehr.lifestyle.create",
  "patient.ehr.lifestyle.delete",
  "patient.ehr.meal.update",
  "patient.ehr.meal.create",
  "patient.ehr.meal.delete",
  "patient.ehr.medication.update",
  "patient.ehr.medication.create",
  "patient.ehr.medication.delete",
  "patient.ehr.note.update",
  "patient.ehr.note.create",
  "patient.ehr.note.delete",
  "patient.ehr.prescription.update",
  "patient.ehr.prescription.create",
  "patient.ehr.prescription.delete",
  "patient.ehr.procedure.update",
  "patient.ehr.procedure.create",
  "patient.ehr.procedure.delete",
  "patient.ehr.symptom.update",
  "patient.ehr.symptom.create",
  "patient.ehr.symptom.delete",
  "patient.ehr.timedactivity.update",
  "patient.ehr.timedactivity.create",
  "patient.ehr.timedactivity.delete",
  "patient.ehr.untimedactivity.update",
  "patient.ehr.untimedactivity.create",
  "patient.ehr.untimedactivity.delete",
  "patient.ehr.vaccination.update",
  "patient.ehr.vaccination.create",
  "patient.ehr.vaccination.delete",
];

export const activityLogToDisplayString = (
  activityLog: ActivityLogFragment,
) => {
  if (!ACTIVITY_LOG_TYPES.includes(activityLog.type)) return null;
  const jsonData = JSON.parse(activityLog.data);
  const email: string = jsonData.hasOwnProperty("email")
    ? jsonData.email ??
      t("settings.activity_log_events.types.unknown_practitioner")
    : t("settings.activity_log_events.types.unknown_practitioner");
  const uuid: string = jsonData.hasOwnProperty("uuid")
    ? jsonData.uuid ?? t("settings.activity_log_events.types.unknown")
    : t("settings.activity_log_events.types.unknown");
  const invitedEmail: string =
    jsonData.hasOwnProperty("invited") &&
    jsonData.invited.hasOwnProperty("email")
      ? jsonData.invited.email ??
        t("settings.activity_log_events.types.unknown_practitioner")
      : t("settings.activity_log_events.types.unknown_practitioner");
  const invitedByEmail: string =
    jsonData.hasOwnProperty("invitedBy") &&
    jsonData.invitedBy.hasOwnProperty("email")
      ? jsonData.invitedBy.email ??
        t("settings.activity_log_events.types.unknown_practitioner")
      : t("settings.activity_log_events.types.unknown_practitioner");
  const beforeUuid: string =
    jsonData.hasOwnProperty("before") && jsonData.before.hasOwnProperty("uuid")
      ? jsonData.before.uuid ?? t("settings.activity_log_events.types.unknown")
      : t("settings.activity_log_events.types.unknown");
  const beforeEmail: string =
    jsonData.hasOwnProperty("before") && jsonData.before.hasOwnProperty("email")
      ? jsonData.before.email ??
        t("settings.activity_log_events.types.unknown_practitioner")
      : t("settings.activity_log_events.types.unknown_practitioner");
  const beforeValue: string =
    jsonData.hasOwnProperty("before") && jsonData.before.hasOwnProperty("value")
      ? jsonData.before.value ?? t("settings.activity_log_events.types.unknown")
      : t("settings.activity_log_events.types.unknown");
  const appKeyValue: string =
    jsonData.hasOwnProperty("appKey") && jsonData.appKey.hasOwnProperty("value")
      ? jsonData.appKey.value ?? t("settings.activity_log_events.types.unknown")
      : t("settings.activity_log_events.types.unknown");
  const serverKeyValue: string =
    jsonData.hasOwnProperty("serverKey") &&
    jsonData.serverKey.hasOwnProperty("value")
      ? jsonData.serverKey.value ??
        t("settings.activity_log_events.types.unknown")
      : t("settings.activity_log_events.types.unknown");
  const patientUuid: string =
    jsonData.hasOwnProperty("patient") &&
    jsonData.patient.hasOwnProperty("uuid")
      ? jsonData.patient.uuid ?? t("settings.activity_log_events.types.unknown")
      : t("settings.activity_log_events.types.unknown");
  const doctorSenderEmail: string =
    jsonData.hasOwnProperty("doctorSender") &&
    jsonData.doctorSender.hasOwnProperty("email")
      ? jsonData.doctorSender.email ??
        t("settings.activity_log_events.types.unknown_practitioner")
      : t("settings.activity_log_events.types.unknown_practitioner");
  const doctorEmail: string =
    jsonData.hasOwnProperty("doctor") && jsonData.doctor.hasOwnProperty("email")
      ? jsonData.doctor.email ??
        t("settings.activity_log_events.types.unknown_practitioner")
      : t("settings.activity_log_events.types.unknown_practitioner");

  switch (activityLog.type) {
    case "practitioner.registered":
      return `${email} ${t("settings.activity_log_events.types.joined")}`;
    case "practitioner.deleted":
      return `${email} ${t("settings.activity_log_events.types.was_deleted")}`;
    case "practitioner.invited":
      return `${invitedByEmail} ${t(
        "settings.activity_log_events.types.invited",
      )} ${invitedEmail}`;
    case "practitioner.info.update":
      return `${beforeEmail} ${t("types.modified_their_profile")}`;
    case "practitioner.mfa.update":
      return `${email} ${t("types.modified_their_mfa_options")}`;
    case "practitioner.password.failure":
      return `${email} ${t("types.entered_a_wrong_password")}`;
    case "practitioner.mfa.failure":
      return `${email} ${t("types.failed_at_mfa_step")}`;
    case "practitioner.password.update":
      return `${email} ${t("types.updated_their_password")}`;
    case "practitioner.availability.create":
      return `${email} ${t("types.created_an_availability_slot")}`;
    case "practitioner.availability.delete":
      return `${email} ${t("types.deleted_an_availability_slot")}`;
    case "practitioner.availability.update":
      return `${beforeEmail} ${t("types.updated_an_availability_slot")}`;
    case "organization.tags.create":
      return `${t(
        "settings.activity_log_events.types.the_tag_type",
      )} ${uuid} ${t("types.was_created")}`;
    case "organization.tags.update":
      return `${t(
        "settings.activity_log_events.types.the_tag_type",
      )} ${beforeUuid} ${t("types.was_updated")}`;
    case "organization.tags.delete":
      return `${t(
        "settings.activity_log_events.types.the_tag_type",
      )} ${uuid} ${t("types.was_deleted")}`;
    case "organization.webhooks.create":
      return `${t(
        "settings.activity_log_events.types.the_webhook",
      )} ${uuid} ${t("types.was_created")}`;
    case "organization.webhooks.update":
      return `${t(
        "settings.activity_log_events.types.the_webhook",
      )} ${beforeUuid} ${t("types.was_updated")}`;
    case "organization.webhooks.delete":
      return `${t(
        "settings.activity_log_events.types.the_webhook",
      )} ${uuid} ${t("types.was_deleted")}`;
    case "organization.serviceHours.update":
      return t("types.the_service_hours_were_updated");
    case "organization.update.branding":
      return t("types.the_organization_branding_was_updated");
    case "organization.appKeys.create":
      return `${t(
        "settings.activity_log_events.types.the_app_key",
      )} ${appKeyValue} ${t("types.was_created")}`;
    case "organization.appKeys.update":
      return `${t(
        "settings.activity_log_events.types.the_app_key",
      )} ${beforeValue} ${t("types.was_updated")}`;
    case "organization.appKeys.delete":
      return `${t(
        "settings.activity_log_events.types.the_app_key",
      )} ${appKeyValue} ${t("types.was_deleted")}`;
    case "organization.serverKeys.create":
      return `${t("types.the_server_key")} ${serverKeyValue} ${t(
        "settings.activity_log_events.types.was_created",
      )}`;
    case "organization.serverKeys.update":
      return `${t(
        "settings.activity_log_events.types.the_server_key",
      )} ${beforeValue} ${t("types.was_updated")}`;
    case "organization.serverKeys.delete":
      return t("settings.activity_log_events.types.server_key_was_deleted", {
        value: serverKeyValue,
      });
    case "organization.note_template.create":
      return `${t(
        "settings.activity_log_events.types.the_video_consultation_template",
      )} ${uuid} ${t("types.was_created")}`;
    case "organization.note_template.update":
      return `${t(
        "settings.activity_log_events.types.the_video_consultation_template",
      )} ${uuid} ${t("types.was_updated")}`;
    case "organization.note_template.delete":
      return `${t(
        "settings.activity_log_events.types.the_video_consultation_template",
      )} ${uuid} ${t("types.was_deleted")}`;
    case "mlmodel.training":
      return `${t("settings.activity_log_events.types.ml_model")} ${uuid} ${t(
        "types.was_trained",
      )}`;
    case "patient.delete":
      return `${doctorEmail} ${t(
        "settings.activity_log_events.types.deleted",
      )} ${t("types.the_patient")} ${patientUuid}`;
    case "practitioner.sendMessageToPatient":
      return `${doctorSenderEmail} ${t(
        "types.sent_a_message_to_patient",
      )} ${patientUuid}`;
    case "patient.ehr.allergy.update":
    case "patient.ehr.allergy.create":
    case "patient.ehr.allergy.delete":
    case "patient.ehr.condition.update":
    case "patient.ehr.condition.create":
    case "patient.ehr.condition.delete":
    case "patient.ehr.contraception.update":
    case "patient.ehr.contraception.create":
    case "patient.ehr.contraception.delete":
    case "patient.ehr.datapoint.update":
    case "patient.ehr.datapoint.create":
    case "patient.ehr.datapoint.delete":
    case "patient.ehr.document.update":
    case "patient.ehr.document.create":
    case "patient.ehr.document.delete":
    case "patient.ehr.lifestyle.update":
    case "patient.ehr.lifestyle.create":
    case "patient.ehr.lifestyle.delete":
    case "patient.ehr.meal.update":
    case "patient.ehr.meal.create":
    case "patient.ehr.meal.delete":
    case "patient.ehr.medication.update":
    case "patient.ehr.medication.create":
    case "patient.ehr.medication.delete":
    case "patient.ehr.note.update":
    case "patient.ehr.note.create":
    case "patient.ehr.note.delete":
    case "patient.ehr.prescription.update":
    case "patient.ehr.prescription.create":
    case "patient.ehr.prescription.delete":
    case "patient.ehr.procedure.update":
    case "patient.ehr.procedure.create":
    case "patient.ehr.procedure.delete":
    case "patient.ehr.symptom.update":
    case "patient.ehr.symptom.create":
    case "patient.ehr.symptom.delete":
    case "patient.ehr.timedactivity.update":
    case "patient.ehr.timedactivity.create":
    case "patient.ehr.timedactivity.delete":
    case "patient.ehr.untimedactivity.update":
    case "patient.ehr.untimedactivity.create":
    case "patient.ehr.untimedactivity.delete":
    case "patient.ehr.vaccination.update":
    case "patient.ehr.vaccination.create":
    case "patient.ehr.vaccination.delete":
      return `${t("types.medical_record_of_patient_")} ${patientUuid} ${t(
        "settings.activity_log_events.types.was_updated",
      )}`;
  }
  return t("settings.activity_log_events.types.unknown_log");
};
