import { RouteProps } from "react-router";
import { Route } from "react-router-dom";

import { usePageTitleAndFavicon } from "utils/title-and-favicon";

export const PublicRoute = (props: RouteProps) => {
  // Always use the Nabla title and favicon for public routes.
  usePageTitleAndFavicon({ whiteLabelTitle: null, whiteLabelFaviconUrl: null });

  return <Route {...props} />;
};
