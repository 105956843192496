import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { NotFound } from "components/ErrorPage/NotFound";
import { Query } from "components/Query/Query";
import {
  EmptyTemplatesPlaceholder,
  TemplateSettingsForm,
} from "components/TemplateSettingsForm/TemplateSettingsForm";
import { useUser } from "contexts/User/UserContext";
import {
  CreateDocumentTemplate,
  DeleteDocumentTemplate,
  DocumentTemplateFragment,
  DocumentTemplates,
  UpdateDocumentTemplate,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import {
  addDocumentTemplateInCache,
  removeDocumentTemplateInCache,
} from "utils/apollo";
import { notifier } from "utils/notifier";

gql`
  query DocumentTemplates {
    documentTemplates {
      ...DocumentTemplate
    }
  }

  mutation CreateDocumentTemplate(
    $documentTemplateInput: DocumentTemplateInput!
  ) {
    createDocumentTemplate(input: $documentTemplateInput) {
      template {
        ...DocumentTemplate
      }
    }
  }

  mutation UpdateDocumentTemplate(
    $uuid: UUID!
    $documentTemplateInput: DocumentTemplateInput!
  ) {
    updateDocumentTemplate(uuid: $uuid, input: $documentTemplateInput) {
      template {
        ...DocumentTemplate
      }
    }
  }

  mutation DeleteDocumentTemplate($uuid: UUID!) {
    deleteDocumentTemplate(uuid: $uuid) {
      documentTemplateUuid
    }
  }
`;

export const TemplatesSettingsDocuments = ({
  newTemplate,
  onHideNewTemplate,
}: {
  newTemplate: boolean;
  onHideNewTemplate: () => void;
}) => {
  const { hasPermission } = useUser();
  const t = useTranslation();

  if (!hasPermission("EDIT_DOCUMENT_TEMPLATES")) return <NotFound />;

  return (
    <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
      {newTemplate && (
        <DocumentTemplateForm template={undefined} onHide={onHideNewTemplate} />
      )}
      <Query query={DocumentTemplates}>
        {(templates) =>
          templates.isEmpty() && !newTemplate ? (
            <EmptyTemplatesPlaceholder
              title={t("settings.templates.documents.empty_state_title")}
              subtitle={t("settings.templates.documents.empty_state_subtitle")}
            />
          ) : (
            <>
              {templates.map((template) => (
                <DocumentTemplateForm
                  key={template.uuid}
                  template={template}
                  onHide={onHideNewTemplate}
                />
              ))}
            </>
          )
        }
      </Query>
    </Background>
  );
};

const DocumentTemplateForm = ({
  template,
  onHide,
}: {
  template?: DocumentTemplateFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();

  const [createDocumentTemplate, createDocumentTemplateLoading] = useMutation(
    CreateDocumentTemplate,
    {
      onSuccess: (output, client) => {
        addDocumentTemplateInCache(client, output.template);
        onHide();
        notifier.success(t("settings.templates.documents.created_notifier"));
      },
    },
  );

  const [updateDocumentTemplate, updateDocumentTemplateLoading] = useMutation(
    UpdateDocumentTemplate,
    {
      onSuccess: () =>
        notifier.success(t("settings.templates.documents.updated_notifier")),
    },
  );

  const { refetch } = useQuery(DocumentTemplates);

  const [deleteDocumentTemplate] = useMutation(DeleteDocumentTemplate, {
    onSuccess: (output, client) => {
      removeDocumentTemplateInCache(client, output.documentTemplateUuid);
      refetch();
      notifier.success(t("settings.templates.documents.deleted_notifier"));
    },
  });

  return (
    <TemplateSettingsForm
      template={template}
      onHide={onHide}
      icon={{
        name: "document",
        colorsClassNames: "text-green-300 bg-green-100",
      }}
      titlePlaceholder={t("settings.templates.documents.title_placeholder")}
      textPlaceholder={t("settings.templates.documents.text_placeholder")}
      submitLabel={t("settings.templates.documents.form_save")}
      deleteLabel={t("settings.templates.documents.form_delete")}
      deletionConfirmationModal={{
        title: t("settings.templates.documents.delete_document_template_title"),
        suffix: t(
          "settings.templates.documents.delete_document_template_suffix",
        ),
      }}
      onSubmit={(values) => {
        const input = {
          title: values.title,
          text: values.text,
        };
        return template === undefined
          ? createDocumentTemplate({
              documentTemplateInput: input,
            })
          : updateDocumentTemplate({
              uuid: template.uuid,
              documentTemplateInput: input,
            });
      }}
      loading={createDocumentTemplateLoading || updateDocumentTemplateLoading}
      onDelete={(uuid) => deleteDocumentTemplate({ uuid })}
    />
  );
};
