import classNames from "classnames";

import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { NoteSection } from "types";

import { getMentionedItemLabel } from "../MentionEditor/display";

export const NoteMentionsInfo = ({
  sections,
  className,
}: {
  sections: NoteSection[];
  className?: string;
}) => {
  const t = useTranslation();

  const mentions = sections.flatMapNotNull((section) =>
    section.content.__typename === "TextSectionContent"
      ? section.content.textWithMentions.mentions
      : null,
  );

  const draftMentionedItemLabels = mentions.flatMapNotNull((mention) =>
    mention.mentionedItem?.__typename === "DraftMentionedItem"
      ? getMentionedItemLabel(mention.mentionedItem)
      : null,
  );

  if (draftMentionedItemLabels.isEmpty()) return null;

  return (
    <div
      className={classNames(
        className,
        "flex items-top space-x-8 px-12 py-8 border border-primary rounded bg-primary-100",
      )}
    >
      <Icon name="info" className="text-primary mt-2" />
      <div className="text-primary flex flex-fill">
        {t("note_mentions_info.content")}
        {draftMentionedItemLabels.join(", ")}
      </div>
    </div>
  );
};
