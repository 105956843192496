import gql from "graphql-tag";

import { TextArea, TextAreaProps } from "components/Form/TextArea/TextArea";
import {
  useExperienceDraft,
  useMaybeExperience,
} from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import { UpdateIsTyping } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { insertText } from "utils";

gql`
  mutation UpdateIsTyping(
    $experienceUuid: UUID!
    $isTyping: Boolean!
    $hideToPatient: Boolean
  ) {
    setTyping(
      experienceUuid: $experienceUuid
      isTyping: $isTyping
      hideToPatient: $hideToPatient
    ) {
      experience {
        ...Typing
      }
    }
  }
`;

export type MessageInputProps = Omit<
  TextAreaProps,
  "name" | "value" | "ref"
> & {
  disableMaxRows?: boolean;
  prefillValue?: string;
  skipSetTyping?: boolean;
  disabled?: boolean;
};

export const MessageInput = ({
  disableMaxRows,
  prefillValue = "",
  skipSetTyping,
  onInput,
  onKeyDown,
  onHeightChange,
  style,
  disabled,
  ...props
}: MessageInputProps) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const { uuid, experience, textAreaRef, scrollIfCloseToBottom } =
    useMaybeExperience();
  const { messageText } = useExperienceDraft(prefillValue);
  const [setTyping, settingTyping] = useMutation(UpdateIsTyping, {
    skipIfImpersonation: true,
    onError: () => undefined, // Don't notify user
  });

  return (
    <TextArea
      name="messageInput"
      inputRef={textAreaRef}
      minRows={1}
      disabled={disabled}
      maxRows={
        disableMaxRows
          ? undefined
          : // Grow until ~ half of the screen (56 = header, 24 = mobile line height)
            Math.round((window.innerHeight - 56) / 24 / 2)
      }
      value={messageText}
      placeholder={t("composer_parts.message_input.enter_your_message")}
      style={{ ...style, textRendering: "geometricPrecision" }}
      onHeightChange={(height, meta) => {
        onHeightChange?.(height, meta);
        scrollIfCloseToBottom();
      }}
      onKeyDown={(e) => {
        if (e.altKey && e.key === "Enter") {
          // On Chrome & Firefox for Mac, alt+enter is ignored by html textarea
          // We simulate an insert line like on many other platforms (WhatsApp, WorkChat) & Safari
          insertText("\n");
          e.preventDefault();
        } else {
          onKeyDown?.(e);
        }
      }}
      onInput={(e) => {
        onInput?.(e);

        if (settingTyping || skipSetTyping) return;
        if (!uuid) return; // Experiences that are not yet created in the backend.

        const currentDoctorTyping = experience?.typingDoctors.find(
          (value) => value.doctor.uuid === user.uuid,
        );
        if (
          !currentDoctorTyping ||
          currentDoctorTyping.typingAt.secondsFromNow() > 20 ||
          !e.currentTarget.value
        ) {
          setTyping({
            experienceUuid: uuid,
            isTyping: !!e.currentTarget.value,
          });
        }
      }}
      {...props}
    />
  );
};
