import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { CreatePatientDocument, FileUploadFragment } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

export const SaveAsDocumentButton = ({
  experienceUuid,
  patientUuid,
  fileUpload,
  onCreatePatientTimelineItem,
}: {
  experienceUuid: UUID;
  patientUuid: UUID;
  fileUpload: FileUploadFragment;
  onCreatePatientTimelineItem: (uuid: UUID) => void;
}) => {
  const t = useTranslation();
  const [createPatientDocument] = useMutation(CreatePatientDocument);

  return (
    <TooltipWrapper
      label={t(
        "conversation_content.timeline.message_container.save_as_document_button.tooltip_button",
      )}
    >
      <ClickableIcon
        name="floppyDisk"
        className="invisible group-hover:visible p-4 hover:bg-grey-100 rounded"
        onClick={() =>
          createPatientDocument({
            upload: { uuid: fileUpload.uuid },
            patientUuid,
            title: fileUpload.fileName,
            realizedAt: null,
            description: null,
            visibleToPatient: false,
            category: "MISC",
            experienceUuid,
          }).then((response) => {
            if (response === undefined) return;
            onCreatePatientTimelineItem(response.document.uuid);
          })
        }
      />
    </TooltipWrapper>
  );
};
