import { useState } from "react";
import gql from "graphql-tag";

import { useAuth } from "auth/AuthContext";
import { Background } from "components/Background/Backgound";
import { Switch } from "components/Form/Switch/Switch";
import { ControlledConfirmationModal } from "components/Modal/ControlledConfirmationModal";
import { Query } from "components/Query/Query";
import { GetSecuritySettings, UpdateMfaEnforced } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  query GetSecuritySettings {
    me {
      doctor {
        uuid
        subOrganization {
          uuid
          mfaEnforced
        }
      }
    }
  }

  mutation UpdateMfaEnforced($mfaEnforced: Boolean!) {
    updateOrganizationSettings(input: { mfaEnforced: $mfaEnforced }) {
      doctor {
        uuid
        subOrganization {
          uuid
          mfaEnforced
        }
      }
    }
  }
`;

export const Security = () => {
  const t = useTranslation();
  const auth = useAuth();

  const [tentativeMfaEnforced, setTentativeMfaEnforced] = useState<
    boolean | undefined
  >(undefined);

  const [updateMfaEnforced] = useMutation(UpdateMfaEnforced, {
    onSuccess: (result) => {
      notifier.success(
        result.doctor.subOrganization.mfaEnforced
          ? t("settings.security.mfa_enforced.enabled_success")
          : t("settings.security.mfa_enforced.disabled_success"),
      );

      if (
        result.doctor.subOrganization.mfaEnforced &&
        auth.state === "LOGGED_IN"
      ) {
        void auth.logout();
      }
    },
  });

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <ControlledConfirmationModal
        show={tentativeMfaEnforced !== undefined}
        onHide={() => setTentativeMfaEnforced(undefined)}
        onConfirm={async (close) => {
          await updateMfaEnforced({ mfaEnforced: tentativeMfaEnforced! });
          close();
        }}
        cta={{
          danger: true,
          label: tentativeMfaEnforced
            ? t("settings.security.enable_mfa")
            : t("settings.security.disable_mfa"),
        }}
      >
        <h2 className="title text-16 leading-normal">
          {tentativeMfaEnforced
            ? t("settings.security.mfa_enforced.enable_confirm")
            : t("settings.security.mfa_enforced.disable_confirm")}
        </h2>
      </ControlledConfirmationModal>

      <h1 className="text-primary-dark text-24 font-bold">
        {t("settings.security.title")}
      </h1>
      <Query query={GetSecuritySettings}>
        {({
          doctor: {
            subOrganization: { mfaEnforced },
          },
        }) => (
          <div className="flex-col bg-white rounded border p-32">
            <div className="flex justify-between">
              <div className="flex-col">
                <div className="font-bold text-18 text-primary-dark">
                  {t("settings.security.mfa_enforced.title")}
                </div>
                <div className="font-normal text-14 text-body">
                  {t("settings.security.mfa_enforced.subtitle")}
                </div>
              </div>
              <Switch
                checked={mfaEnforced}
                onChange={(it) => setTentativeMfaEnforced(it)}
              />
            </div>
          </div>
        )}
      </Query>
    </Background>
  );
};
