import { useCallback, useState } from "react";
import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { Select } from "components/Form/Select/Select";
import { Icon } from "components/Icon/Icon";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Query } from "components/Query/Query";
import { Table } from "components/Table/Table";
import { useUser } from "contexts/User/UserContext";
import {
  AllDoctors,
  DoctorSummaryFragment,
  RecordedConversationFragment,
  RecordedConversations,
  TranscriptState,
  UpdateRecordedConversationAssignee,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { run } from "utils";
import { displayTimeElapsed } from "utils/date";
import { displayDoctor } from "utils/display";
import { getLanguageFromLocale } from "utils/intl";

gql`
  fragment RecordedConversation on RecordedConversation {
    uuid
    locale
    audios {
      ...FileUpload
      transcript {
        uuid
        final
        updatedAt
        firstVerifiedBy {
          ...DoctorSummary
        }
        firstTranscribedBy {
          ...DoctorSummary
        }
      }
    }
    consent {
      ...FileUpload
    }
    author {
      ...DoctorSummary
    }
    createdAt
    updatedAt
    note
    assignee {
      ...DoctorSummary
    }

    transcriptState
    forNoteAnnotation
  }

  query RecordedConversations(
    $cursor: TimeAndUuidCursorInput
    $filter: RecordedConversationFilter
  ) {
    recordedConversations(filter: $filter) {
      recordedConversations(page: { cursor: $cursor, numberOfItems: 40 }) {
        data {
          ...RecordedConversation
        }
        hasMore
        nextCursor {
          fromTime
          fromUuid
        }
      }
      totalCount
    }
  }

  mutation UpdateRecordedConversationAssignee($uuid: UUID!, $doctorUuid: UUID) {
    updateRecordedConversationAssignee(uuid: $uuid, doctorUuid: $doctorUuid) {
      recordedConversation {
        ...RecordedConversation
      }
    }
  }
`;

const TableHeaders = [
  "DATE",
  "BY",
  "LANGUAGE",
  "AUDIO_DURATION",
  "CONSENT",
  "NOTE",
  "TRANSCRIPT",
  "VERIFICATION",
  "ASSIGNEE",
] as const;

type FilterDoctorAssignment =
  | { __typename: "All" }
  | { __typename: "Unassigned" }
  | { __typename: "Doctor"; doctor: DoctorSummaryFragment };

type RecordedConversationsFilter = {
  transcriptStates: TranscriptState[];
  assignment: FilterDoctorAssignment;
};

export const RecordedConversationsTable = () => {
  const t = useTranslation();
  const { hasPermission } = useUser();
  const [updateAssignee, updating] = useMutation(
    UpdateRecordedConversationAssignee,
  );
  const [recordedConversationsFilter, setRecordedConversationFilter] =
    useState<RecordedConversationsFilter>({
      transcriptStates: ["WORK_NEEDED", "DONE", "EMPTY"],
      assignment: { __typename: "All" },
    });

  const updatePartialFilter = useCallback(
    (partial: Partial<RecordedConversationsFilter>) =>
      setRecordedConversationFilter({
        ...recordedConversationsFilter,
        ...partial,
      }),
    [recordedConversationsFilter, setRecordedConversationFilter],
  );

  const TranscriptStateCheckbox = ({
    name,
    label,
    transcriptState,
  }: {
    name: string;
    label: string;
    transcriptState: TranscriptState;
  }) => (
    <CheckBox
      name={name}
      checked={recordedConversationsFilter.transcriptStates.includes(
        transcriptState,
      )}
      onChange={() => {
        recordedConversationsFilter.transcriptStates.includes(transcriptState)
          ? updatePartialFilter({
              transcriptStates:
                recordedConversationsFilter.transcriptStates.filter(
                  (state) => state !== transcriptState,
                ),
            })
          : updatePartialFilter({
              transcriptStates: [
                ...recordedConversationsFilter.transcriptStates,
                transcriptState,
              ],
            });
      }}
      label={label}
    />
  );

  return (
    <PaginatedQuery
      query={RecordedConversations}
      variables={{
        filter: {
          transcriptStates: recordedConversationsFilter.transcriptStates,
          assignment:
            recordedConversationsFilter.assignment.__typename === "All"
              ? null
              : recordedConversationsFilter.assignment.__typename ===
                "Unassigned"
              ? { assignedDoctorUuid: null }
              : {
                  assignedDoctorUuid:
                    recordedConversationsFilter.assignment.doctor.uuid,
                },
        },
      }}
      selector={(d) => d.recordedConversations}
    >
      {(data, utils) => (
        <div className="flex-col flex-fill p-32">
          <div className="lg:flex sm:flex-col sm:space-y-32 items-center sm:items-start mb-32 justify-between w-full">
            <div className="flex-col">
              <h1 className="text-primary-dark text-24 font-bold">
                {t("recorded_conversations.header")}
              </h1>
              <div>{data.totalCount} conversations.</div>
            </div>
            <div className="flex items-center sm:w-full space-x-16">
              {hasPermission("EDIT_RECORDED_CONVERSATIONS_ASSIGNEE") && (
                <>
                  <div>{t("recorded_conversations.filter")}</div>
                  <Query
                    query={AllDoctors}
                    variables={{ filter: {} }}
                    noSpinner
                  >
                    {({ data: doctorData, loading }) => (
                      <Select<FilterDoctorAssignment>
                        wrapperClassName="max-w-[200px]"
                        loading={loading}
                        name="assigneeFilter"
                        getOptionId={(option) =>
                          option.__typename === "All"
                            ? "All"
                            : option.__typename === "Unassigned"
                            ? "Unassigned"
                            : option.doctor.uuid
                        }
                        getOptionLabel={(option) =>
                          option.__typename === "All"
                            ? "All"
                            : option.__typename === "Unassigned"
                            ? "Unassigned"
                            : displayDoctor(option.doctor)
                        }
                        value={recordedConversationsFilter.assignment}
                        options={[
                          { __typename: "All" },
                          { __typename: "Unassigned" },
                          ...(
                            doctorData?.doctors.sortAsc((it) => it.firstName) ??
                            []
                          ).map(
                            (it) =>
                              ({
                                __typename: "Doctor",
                                doctor: it,
                              } as FilterDoctorAssignment),
                          ),
                        ]}
                        onChange={(value) =>
                          updatePartialFilter({ assignment: value })
                        }
                      />
                    )}
                  </Query>
                </>
              )}
              <Button
                className="lg:max-w-[320px]"
                label={t("recorded_conversations.add")}
                to={`/${routes.RECORDED_CONVERSATIONS}/new`}
              />
            </div>
          </div>
          {hasPermission("VIEW_ANY_RECORDED_CONVERSATION") && (
            <div className="flex space-x-32">
              <TranscriptStateCheckbox
                name="done"
                label="Done"
                transcriptState="DONE"
              />
              <TranscriptStateCheckbox
                name="workNeeded"
                label="Work needed"
                transcriptState="WORK_NEEDED"
              />
              <TranscriptStateCheckbox
                name="empty"
                label="Empty"
                transcriptState="EMPTY"
              />
            </div>
          )}

          <Table<typeof TableHeaders[number], RecordedConversationFragment>
            elements={data.recordedConversations.data}
            emptyPlaceholder={
              <div className="text-18 flex items-center text-primary-dark p-24 justify-center text-center h-[180px]">
                {t("recorded_conversations.placeholder_table")}
              </div>
            }
            doRowAction={(fragment) => ({
              mode: "navigate",
              to: `/${routes.RECORDED_CONVERSATIONS}/${fragment.uuid}`,
            })}
            fieldHeaders={TableHeaders.map(
              (it) =>
                ({
                  CONSENT: t("recorded_conversations.consentement_table"),
                  BY: t("recorded_conversations.by_table"),
                  DATE: t("recorded_conversations.recorded_at_table"),
                  LANGUAGE: t("recorded_conversations.language_table"),
                  AUDIO_DURATION: "Duration",
                  NOTE: t("recorded_conversations.note_table"),
                  AUDIO: t("recorded_conversations.audio_table"),
                  TRANSCRIPT: t("recorded_conversations.transcript"),
                  VERIFICATION: t("recorded_conversations.verification"),
                  ASSIGNEE: t("recorded_conversations.transcriber"),
                }[it]),
            )}
            fields={(fragment) => [
              fragment.createdAt.format("dateAndTime"),
              displayDoctor(fragment.author),
              getLanguageFromLocale(fragment.locale),
              displayTimeElapsed(
                fragment.audios
                  .map((it) =>
                    it.metadata.__typename === "AudioMetadata" &&
                    it.metadata.durationMs
                      ? it.metadata.durationMs
                      : 0,
                  )
                  .reduce((partialSum, val) => partialSum + val, 0) / 1000,
              ),
              <Icon
                key="consent"
                className={fragment.consent ? "text-success" : "text-danger"}
                name={fragment.consent ? "check" : "close"}
              />,
              <Icon
                key="note"
                className={fragment.note ? "text-success" : "text-danger"}
                name={fragment.note ? "check" : "close"}
              />,
              run(() => {
                const transcribers = fragment.audios
                  .map((audio) =>
                    audio.transcript?.firstTranscribedBy?.let((it) =>
                      displayDoctor(it),
                    ),
                  )
                  .distinct();
                return (
                  <div className="flex space-x-12">
                    <Icon
                      key="transcript"
                      className={run(() => {
                        switch (fragment.transcriptState) {
                          case "DONE":
                          case "VERIFIED":
                            return "text-success";
                          case "WORK_NEEDED":
                            return "text-orange";
                          case "EMPTY":
                            return "text-danger";
                        }
                      })}
                      name={run(() => {
                        switch (fragment.transcriptState) {
                          case "DONE":
                          case "VERIFIED":
                            return "check";
                          case "WORK_NEEDED":
                            return "change";
                          case "EMPTY":
                            return "close";
                          default:
                            return "close";
                        }
                      })}
                    />
                    <div>{transcribers.join(",")}</div>
                  </div>
                );
              }),
              run(() => {
                const verifiers = fragment.audios
                  .map((audio) =>
                    audio.transcript?.firstVerifiedBy?.let((it) =>
                      displayDoctor(it),
                    ),
                  )
                  .distinct();
                return (
                  <div className="flex space-x-12">
                    <Icon
                      key="transcript"
                      className={run(() => {
                        switch (fragment.transcriptState) {
                          case "VERIFIED":
                            return "text-success";
                          case "DONE":
                            return "text-orange";
                          case "WORK_NEEDED":
                          case "EMPTY":
                            return "text-danger";
                        }
                      })}
                      name={run(() => {
                        switch (fragment.transcriptState) {
                          case "VERIFIED":
                            return "check";
                          case "DONE":
                            return "change";
                          case "WORK_NEEDED":
                          case "EMPTY":
                            return "close";
                          default:
                            return "close";
                        }
                      })}
                    />
                    <div>{verifiers.join(",")}</div>
                  </div>
                );
              }),
              <div
                key="transcriber"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                className="max-w-[200px]"
              >
                {hasPermission("EDIT_RECORDED_CONVERSATIONS_ASSIGNEE") ? (
                  <Query
                    query={AllDoctors}
                    variables={{ filter: {} }}
                    noSpinner
                  >
                    {({ data: doctorData, loading }) => (
                      <Select<DoctorSummaryFragment | null>
                        wrapperClassName="max-w-[200px]"
                        loading={loading}
                        name="transcriber"
                        disabled={
                          !hasPermission(
                            "EDIT_RECORDED_CONVERSATIONS_ASSIGNEE",
                          ) || updating
                        }
                        getOptionLabel={(option) =>
                          option ? displayDoctor(option) : " - "
                        }
                        value={fragment.assignee}
                        options={[
                          null,
                          ...(doctorData?.doctors.sortAsc(
                            (it) => it.firstName,
                          ) ?? []),
                        ]}
                        onChange={(value) => {
                          updateAssignee({
                            uuid: fragment.uuid,
                            doctorUuid: value?.uuid ?? null,
                          });
                        }}
                      />
                    )}
                  </Query>
                ) : fragment.assignee ? (
                  displayDoctor(fragment.assignee)
                ) : (
                  ""
                )}
              </div>,
            ]}
          />
          {utils.nextPage && (
            <NextPageButton
              nextPage={utils.nextPage}
              fetchingMore={utils.fetchingMore}
            />
          )}
        </div>
      )}
    </PaginatedQuery>
  );
};
