import { useCallback, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useNavigate, useParams } from "react-router";

import { Background } from "components/Background/Backgound";
import { Composer } from "components/ConversationComposer/Composer";
import { ConversationContent } from "components/ConversationContent/ConversationContent";
import { ProfilePanel } from "components/Doctor/ProfilePanel";
import { QueryResult } from "components/Query/QueryResult";
import { SlidingCard } from "components/SlidingCard/SlidingCard";
import { ExperienceProvider } from "contexts/Experience/ExperienceProvider";
import { useDoctor } from "contexts/User/UserContext";
import { DoctorsExperience, QAExperienceFragment } from "generated/provider";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { routes } from "routes";
import { getQAInboxRoute } from "utils";

import { Header } from "./Header/Header";
import { ListPanel } from "./InformationPanel/ListPanel";

gql`
  query DoctorsExperience($uuid: UUID!, $cursor: DateTime) {
    experience(uuid: $uuid) {
      experience {
        ...ExperienceContent
      }
    }
  }
`;

type PanelState =
  | { mode: "CLOSED" }
  | { mode: "LIST" }
  | { mode: "PROFILE"; uuid: UUID };

export const DoctorsConversation = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const { user } = useDoctor();
  const [panelState, setPanelState] = useState<PanelState>({ mode: "CLOSED" });
  const { data, previousData, loading, error, nextPage, fetchingMore } =
    usePaginatedQuery(DoctorsExperience, {
      variables: { uuid },
      selector: (d) => d.experience.itemsV3,
    });
  useEffect(() => {
    if (!data) return;
    const experience = data.experience;
    const maybeUserHasSentMessage =
      experience.itemsV3.hasMore ||
      experience.itemsV3.data.some(
        (i) =>
          i?.__typename === "Message" &&
          i.sender?.__typename === "Doctor" &&
          i.sender.uuid === user.uuid,
      );
    if (
      isDesktop &&
      experience.allDoctors.length === 2 &&
      !maybeUserHasSentMessage &&
      !previousData
    ) {
      setPanelState({
        mode: "PROFILE",
        uuid: experience.allDoctors.find((d) => d.uuid !== user.uuid)!.uuid,
      });
    }
    if (experience.type === "SINGLE_PATIENT_CONVERSATION") {
      // Redirect if the path is for the wrong experience type.
      navigate(
        getQAInboxRoute(
          experience as unknown as QAExperienceFragment,
          user.uuid,
        ),
      );
    }
    if (experience.type === "MULTI_PATIENTS_CONVERSATION") {
      // Redirect if the path is for the wrong experience type.
      navigate(`${routes.GROUP_CHAT_BASE}/${experience.uuid}`);
    }
  }, [isDesktop, user.uuid, navigate, data, previousData]);

  const openProfile = useCallback((doctorUuid: UUID) => {
    setPanelState({ mode: "PROFILE", uuid: doctorUuid });
  }, []);

  const displayedProfile =
    panelState.mode === "PROFILE" &&
    data?.experience.allDoctors.find((d) => d.uuid === panelState.uuid);

  const panelContent = displayedProfile ? (
    <ProfilePanel
      doctorSummary={displayedProfile}
      back={() => setPanelState({ mode: "LIST" })}
      close={() => setPanelState({ mode: "CLOSED" })}
    />
  ) : panelState.mode === "CLOSED" || !data ? null : (
    // Display List when profile not found
    <ListPanel
      close={() => setPanelState({ mode: "CLOSED" })}
      openProfile={openProfile}
    />
  );

  const panelOpened = panelContent !== null;

  return (
    <Background className="flex-fill overflow-hidden flex">
      <ExperienceProvider
        key={uuid} // Get a new context for each experience
        uuid={uuid}
        type={data?.experience.type ?? "PROVIDER_ONLY_CONVERSATION"}
        data={data}
        fetchingMore={fetchingMore}
        nextPage={nextPage}
      >
        {(isDesktop || !panelOpened) && (
          <div className="flex-fill flex-col items-center">
            <QueryResult data={data} error={error} loading={loading}>
              {() => (
                <>
                  <Header
                    panelOpened={panelOpened}
                    togglePanel={() =>
                      setPanelState({
                        mode: panelState.mode === "CLOSED" ? "LIST" : "CLOSED",
                      })
                    }
                  />
                  <ConversationContent
                    wrapperStyle={{ maxWidth: "1000px" }}
                    onAvatarClick={openProfile}
                  />
                </>
              )}
            </QueryResult>
            <Composer withAudio withPolls sendOnCmdEnter />
          </div>
        )}
        {isDesktop ? (
          <SlidingCard cardClassName="border-l bg-white">
            {panelContent}
          </SlidingCard>
        ) : panelOpened ? (
          <div className="w-full h-full bg-white flex-col overflow-auto">
            {panelContent}
          </div>
        ) : null}
      </ExperienceProvider>
    </Background>
  );
};
