import stringify from "fast-json-stable-stringify";

import { language } from "./intl";

export const isValidNumericalInput = (value: string) =>
  value.isNotBlank() && !isNaN(parseNumber(value));

export const isValidPositiveInt = (value: string) =>
  value.isNotBlank() && !isNaN(parseInt(value)) && parseInt(value) >= 0;

export const numericalInputFromValidInput = (value: string) =>
  parseNumber(value);

export const parseNumber = (value: string) =>
  Number(language === "en" ? value : value.replace(",", "."));

export const isValidBloodPressure = (bloodPressure: string) =>
  bloodPressure.includes("/") &&
  bloodPressure.split("/").length === 2 &&
  bloodPressure.split("/").every((val) => isValidNumericalInput(val));

export const bloodPressureValuesFromValidInput = (bloodPressure: string) => ({
  systolicBloodPressure: parseNumber(bloodPressure.split("/")[0]),
  diastolicBloodPressure: parseNumber(bloodPressure.split("/")[1]),
});

export const imperialLengthRE =
  /^((?<feet>\d+)')?((?<inches>\d{1,2})("|''))?$/u;

export const isValidImperialLength = (imperialLength: string) =>
  imperialLength.isNotBlank() && imperialLengthRE.test(imperialLength);

export const isValidHexColor = (color: string) =>
  color.startsWith("#") &&
  color.length === 7 &&
  color.match(/^#(?:[0-9a-fA-F]{6})$/u);

export const diffPayload = <
  Payload extends Record<string, unknown>,
  BaseValue extends Payload,
>(
  payload: Payload,
  baseValue: BaseValue,
): Partial<Payload> =>
  Object.fromEntries(
    payload
      .toEntries()
      .filter(([key, value]) => stringify(value) !== stringify(baseValue[key])),
  ) as Partial<Payload>;
