import { Button } from "components/Button/Button";
import { Input } from "components/Form/Input/Input";
import { Label } from "components/Form/Label/Label";
import { Select } from "components/Form/Select/Select";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import {
  NoteVitalType,
  NoteVitalTypeKnownValues,
  NoteVitalUnit,
  NoteVitalUnitKnownValues,
} from "generated/provider";
import { useTranslation } from "i18n";
import { ephemeralUuidV4 } from "utils/stackoverflow";

export type VitalLabelInput = {
  uuid: UUID;
  type: NoteVitalType;
  value: string;
  unit?: NoteVitalUnit;
  textualRepresentation: string;
};
export const FormVitalLabel = ({
  vitals,
  setVitals,
}: {
  vitals: VitalLabelInput[];
  setVitals: (newValue: VitalLabelInput[]) => void;
}) => {
  const t = useTranslation();

  const updateVital = (newValue: VitalLabelInput) =>
    setVitals(
      vitals.map((vital) => (vital.uuid === newValue.uuid ? newValue : vital)),
    );

  return (
    <div className="flex-col space-y-8">
      <Label label={t("form_vital_label.vitals")} useDiv />
      {vitals.map((vital) => (
        <FormVitalLab
          key={vital.uuid}
          vital={vital}
          updateVital={updateVital}
          removeVital={() =>
            setVitals(vitals.filter((it) => it.uuid !== vital.uuid))
          }
        />
      ))}
      <Button
        small
        className="mr-auto"
        label={t("form_vital_label.add")}
        onClick={() =>
          setVitals(
            vitals.concat({
              uuid: ephemeralUuidV4(),
              type: "HEIGHT",
              value: "",
              unit: undefined,
              textualRepresentation: "",
            }),
          )
        }
      />
    </div>
  );
};

const FormVitalLab = ({
  vital,
  updateVital,
  removeVital,
}: {
  vital: VitalLabelInput;
  updateVital: (newValue: VitalLabelInput) => void;
  removeVital: () => void;
}) => (
  <div className="flex space-x-8">
    <Select
      name="type"
      wrapperClassName="w-[230px]"
      options={NoteVitalTypeKnownValues}
      value={vital.type}
      onChange={(newType) => updateVital({ ...vital, type: newType })}
    />
    <Input
      name="value"
      wrapperClassName="w-[180px]"
      type="number"
      value={vital.value}
      onChange={(e) => {
        const newValue = e.currentTarget.value;
        updateVital({
          ...vital,
          value: newValue,
        });
      }}
    />
    <Select
      name="unit"
      wrapperClassName="w-[230px]"
      options={NoteVitalUnitKnownValues}
      value={vital.unit}
      onChange={(newUnit) => updateVital({ ...vital, unit: newUnit })}
      isClearable
    />
    <Input
      name="textualRepresentation"
      wrapperClassName="w-[200px]"
      value={vital.textualRepresentation}
      onChange={(e) => {
        const newValue = e.currentTarget.value;
        updateVital({
          ...vital,
          textualRepresentation: newValue,
        });
      }}
    />
    <ClickableIcon name="trash" onClick={removeVital} />
  </div>
);
