import {
  FamilyMemberDegreeKnownValue,
  HealthDataPointFragment,
  SimpleHealthDataPointType,
} from "generated/provider";
import { staticT } from "i18n";
import { IconName } from "icon-library";
import {
  HealthDataPointFragmentWithKnownUnionValue,
  HealthDataPointKnownValue,
  isHealthDataPointWithKnownUnionValue,
} from "types";

import {
  imperialLengthRE,
  numericalInputFromValidInput,
  parseNumber,
} from "./form";
import {
  celsiusToFahrenheit,
  cmToFeetInches,
  fahrenheitToCelsius,
  FeetInches,
  feetInchesToCm,
  kgToPounds,
  poundsToKg,
  roundNumber,
} from "./measure-conversions";
import { measurementSystem } from "./measurementSystem";

const ROUNDING_DECIMAL_DISPLAY = 1;

export const displayHealthDataPoint = (
  dataPoint: HealthDataPointFragmentWithKnownUnionValue,
) =>
  `${displayDataPointValue(dataPoint)} ${getHealthDataPointEnumValue(
    dataPoint,
    getHealthDataPointUnitMap(),
  )}`;

export const displayHealthDataPointName = (type: HealthDataPointKnownValue) =>
  getHealthDataPointNameMap()[type];

export const displayHealthDataPointUnit = (type: HealthDataPointKnownValue) =>
  getHealthDataPointUnitMap()[type];

export const getHealthDataPointIcon = (
  dataPoint: HealthDataPointFragmentWithKnownUnionValue,
) => getHealthDataPointEnumValue(dataPoint, HealthDataPointIcon);

export const getHealthDataPointEnumValue = <V extends unknown>(
  dataPoint: HealthDataPointFragmentWithKnownUnionValue,
  labels: { [key in HealthDataPointKnownValue]: V },
): V => {
  if (dataPoint.value.__typename === "BloodPressureValue") {
    return labels.BLOOD_PRESSURE_MMHG;
  }
  return labels[dataPoint.value.type];
};

export const getHealthDataPointName = (dataPoint: HealthDataPointFragment) =>
  isHealthDataPointWithKnownUnionValue(dataPoint)
    ? getHealthDataPointEnumValue(dataPoint, getHealthDataPointNameMap())
    : "";

export const displayDataPointValue = (
  dataPoint: HealthDataPointFragmentWithKnownUnionValue,
) => {
  if (dataPoint.value.__typename === "BloodPressureValue") {
    return `${roundNumber(
      dataPoint.value.systolicBloodPressure,
      ROUNDING_DECIMAL_DISPLAY,
    )}/${roundNumber(
      dataPoint.value.diastolicBloodPressure,
      ROUNDING_DECIMAL_DISPLAY,
    )}`;
  }
  if (dataPoint.value.type === "WEIGHT_KG") {
    return displayWeightQuantity(dataPoint.value.quantity).toLocaleString(
      staticT("utils.data_points.engb"),
    );
  }
  if (dataPoint.value.type === "TEMPERATURE_CELSIUS") {
    return displayTemperatureQuantity(dataPoint.value.quantity).toLocaleString(
      staticT("utils.data_points.engb"),
    );
  }
  if (
    dataPoint.value.type === "HEIGHT_CM" ||
    dataPoint.value.type === "WAIST_SIZE_CM"
  ) {
    return displayLengthQuantity(dataPoint.value.quantity).toLocaleString(
      staticT("utils.data_points.engb"),
    );
  }

  return roundNumber(
    dataPoint.value.quantity,
    ROUNDING_DECIMAL_DISPLAY,
  ).toLocaleString(staticT("utils.data_points.engb"));
};

const displayWeightQuantity = (quantityInKg: number) => {
  switch (measurementSystem) {
    case "METRIC":
      return roundNumber(quantityInKg, ROUNDING_DECIMAL_DISPLAY);
    case "IMPERIAL":
      return roundNumber(kgToPounds(quantityInKg), ROUNDING_DECIMAL_DISPLAY);
  }
};

const displayLengthQuantity = (quantityInCm: number) => {
  switch (measurementSystem) {
    case "METRIC":
      return roundNumber(quantityInCm, ROUNDING_DECIMAL_DISPLAY);
    case "IMPERIAL":
      const { feet, inches } = cmToFeetInches(quantityInCm);
      return `${feet}'${Math.round(inches)}"`;
  }
};

const displayTemperatureQuantity = (quantityInCelsius: number) => {
  switch (measurementSystem) {
    case "METRIC":
      return roundNumber(quantityInCelsius, ROUNDING_DECIMAL_DISPLAY);
    case "IMPERIAL":
      return roundNumber(
        celsiusToFahrenheit(quantityInCelsius),
        ROUNDING_DECIMAL_DISPLAY,
      );
  }
};

export const convertDataPointToMetricSystemIfNeeded = (
  quantity: string,
  type: HealthDataPointKnownValue,
) => {
  switch (measurementSystem) {
    case "METRIC":
      return numericalInputFromValidInput(quantity);
    case "IMPERIAL":
      switch (type) {
        case "WEIGHT_KG":
          return poundsToKg(numericalInputFromValidInput(quantity));
        case "TEMPERATURE_CELSIUS":
          return fahrenheitToCelsius(numericalInputFromValidInput(quantity));
        case "WAIST_SIZE_CM":
        case "HEIGHT_CM":
          const feetInches = imperialLengthValuesFromValidInput(quantity);
          return feetInchesToCm(feetInches);
        case "BLOOD_PRESSURE_MMHG":
        case "GLYCEMIA_GRAM_PER_LITER":
        case "HEART_RATE_BPM":
        case "NUM_STEPS":
        case "O2_SATURATION_PERCENTAGE":
          return numericalInputFromValidInput(quantity);
      }
  }
};

const HealthDataPointIcon: {
  [key in HealthDataPointKnownValue]: IconName;
} = {
  WEIGHT_KG: "weight",
  HEIGHT_CM: "humanHeight",
  GLYCEMIA_GRAM_PER_LITER: "bloodSugar",
  WAIST_SIZE_CM: "waistSize",
  NUM_STEPS: "run",
  BLOOD_PRESSURE_MMHG: "artery",
  TEMPERATURE_CELSIUS: "fever",
  O2_SATURATION_PERCENTAGE: "percentage",
  HEART_RATE_BPM: "heartBeat",
};

export const getHealthDataPointNameMap = (): {
  [key in HealthDataPointKnownValue]: string;
} => ({
  WEIGHT_KG: staticT("utils.data_points.weight"),
  HEIGHT_CM: staticT("utils.data_points.height"),
  GLYCEMIA_GRAM_PER_LITER: staticT("utils.data_points.glycemia"),
  WAIST_SIZE_CM: staticT("utils.data_points.waist_size"),
  NUM_STEPS: staticT("utils.data_points.number_of_steps"),
  BLOOD_PRESSURE_MMHG: staticT("utils.data_points.blood_pressure"),
  TEMPERATURE_CELSIUS: staticT("utils.data_points.temperature"),
  O2_SATURATION_PERCENTAGE: staticT("utils.data_points.o_saturation"),
  HEART_RATE_BPM: staticT("utils.data_points.heart_rate"),
});

export const getHealthDataPointUnitMap = (): {
  [key in HealthDataPointKnownValue]: string;
} => {
  const weightUnit = () => {
    switch (measurementSystem) {
      case "METRIC":
        return staticT("utils.data_points.kg");
      case "IMPERIAL":
        return staticT("utils.data_points.lbs");
    }
  };

  const lengthUnit = () => {
    switch (measurementSystem) {
      case "METRIC":
        return staticT("utils.data_points.cm");
      case "IMPERIAL":
        return staticT("utils.data_points.feet_inches");
    }
  };

  const temperatureUnit = () => {
    switch (measurementSystem) {
      case "METRIC":
        return staticT("utils.data_points.c");
      case "IMPERIAL":
        return staticT("utils.data_points.fahrenheit");
    }
  };

  return {
    WEIGHT_KG: weightUnit(),
    HEIGHT_CM: lengthUnit(),
    GLYCEMIA_GRAM_PER_LITER: staticT("utils.data_points.gl"),
    WAIST_SIZE_CM: lengthUnit(),
    NUM_STEPS: staticT("utils.data_points.stepsday"),
    BLOOD_PRESSURE_MMHG: staticT("utils.data_points.mmhg"),
    TEMPERATURE_CELSIUS: temperatureUnit(),
    O2_SATURATION_PERCENTAGE: staticT("utils.data_points.percentage"),
    HEART_RATE_BPM: staticT("utils.data_points.bpm"),
  };
};

export const imperialLengthValuesFromValidInput = (
  imperialLength: string,
): FeetInches => {
  const groups = imperialLength.match(imperialLengthRE)!.groups!;
  return {
    // TS types are wrong for regex groups
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    feet: parseNumber(groups.feet ?? "0"),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    inches: parseNumber(groups.inches ?? "0"),
  };
};

export const shouldBeImperialLengthFormat = (
  type?:
    | HealthDataPointKnownValue
    | FamilyMemberDegreeKnownValue
    | SimpleHealthDataPointType,
) =>
  (type === "WAIST_SIZE_CM" || type === "HEIGHT_CM") &&
  measurementSystem === "IMPERIAL";
