import { useCallback, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "auth/AuthContext";
import { ChoosePasswordForm } from "auth/components/ChoosePasswordForm";
import { ExpiredLinkButton } from "auth/components/ExpiredLinkButton";
import { InvalidLinkError } from "auth/components/InvalidLinkError";
import { VerifyMfaForm } from "auth/components/VerifyMfaForm";
import { buildVerifyMfaPayload } from "auth/utils/mfa";
import {
  parseOrganizationCreationFrontendData,
  scheduleOrganizationCreation,
} from "auth/utils/signup";
import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import {
  PublicScreenImage,
  PublicScreenWrapper,
} from "components/PublicScreenWrapper/PublicScreenWrapper";
import { Redirect } from "components/Routes/Redirect";
import { Spinner } from "components/Spinner/Spinner";
import { AppErrorCode } from "errors/generated";
import {
  ChooseAccountCredentialsUnauthenticated,
  IsEmailVerificationCodeValid,
  IsEmailVerificationCodeValidData,
  NablaProductKnownValues,
  ResendEmailVerificationCodeAfterExpiration,
} from "generated/unauth-account";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { trackEvent } from "tracking";
import { KnownUnionValue } from "types";
import { run } from "utils";
import { isKnownUnionValue } from "utils/apollo";
import { trackConversion } from "utils/attribution";
import { getKnownValue } from "utils/enum";
import { getLanguageFromLocale, setLanguage } from "utils/intl";
import { notifier } from "utils/notifier";

gql`
  # schema = UNAUTHENTICATED_ACCOUNT
  query IsEmailVerificationCodeValid(
    $email: String!
    $emailVerificationToken: String!
  ) {
    isEmailVerificationCodeValid(
      email: $email
      emailVerificationToken: $emailVerificationToken
    ) {
      email
      locale
      mfaState {
        ... on SetupMfaState {
          supportedMethods {
            ... on TotpMfaMethod {
              isSetup
            }
            ... on SmsMfaMethod {
              isSetup
              phone
              mfaBySmsAntiAbuseToken
            }
          }
        }
      }
      intent {
        ... on VerificationCodeIntentNewAccount {
          product
          frontendData
        }
        ... on VerificationCodeIntentUserInitiatedReset {
          _
        }
        ... on VerificationCodeIntentSuperuserInitiatedReset {
          _
        }
      }
    }
  }

  mutation ResendEmailVerificationCodeAfterExpiration(
    $email: String!
    $emailVerificationToken: String!
  ) {
    resendEmailVerificationCodeAfterExpiration(
      email: $email
      emailVerificationToken: $emailVerificationToken
    ) {
      _
    }
  }

  mutation ChooseAccountCredentialsUnauthenticated(
    $email: String!
    $emailVerificationToken: String!
    $newPassword: String!
    $verifyMfaCode: String
  ) {
    chooseAccountCredentials(
      email: $email
      emailVerificationToken: $emailVerificationToken
      mfa: { verify: { code: $verifyMfaCode } }
      newPassword: $newPassword
    ) {
      jwtTokens {
        accessToken
        refreshToken
      }
    }
  }
`;

export const ChooseCredentials = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("emailVerificationToken");
  const organizationName = searchParams.get("organizationName");
  const isLinkValid = !!email && !!token;

  const [resendEmailVerificationCodeAfterExpiration] = useMutation(
    ResendEmailVerificationCodeAfterExpiration,
  );

  const { data, error, loading } = useQuery(IsEmailVerificationCodeValid, {
    variables: { email, emailVerificationToken: token },
    skip: !isLinkValid,
    requestContext: { regionByAccountEmail: email! },
  });

  useEffect(() => {
    if (!data) return;
    setLanguage(getLanguageFromLocale(data.locale));
  }, [data]);

  const product =
    (data && data.intent?.__typename === "VerificationCodeIntentNewAccount"
      ? getKnownValue(data.intent.product, NablaProductKnownValues)
      : null) ?? "CARE_PLATFORM";

  const image: PublicScreenImage = data
    ? run(() => {
        switch (product) {
          case "CARE_PLATFORM":
            return "CARE_PLATFORM";
          case "COPILOT_CLINIC":
          case "COPILOT_API":
            return "COPILOT";
        }
      })
    : "NONE";

  return (
    <PublicScreenWrapper image={image}>
      <EnvironmentBanner />

      {!isLinkValid ? (
        <InvalidLinkError />
      ) : loading ? (
        <Spinner />
      ) : error?.code === AppErrorCode.ALREADY_USED_VERIFICATION_CODE ? (
        <Redirect to={routes.LOGIN} />
      ) : error?.code === AppErrorCode.EXPIRED_VERIFICATION_CODE ? (
        <ExpiredLinkButton
          email={email}
          onClick={async () => {
            const didResend = await resendEmailVerificationCodeAfterExpiration(
              { email, emailVerificationToken: token },
              { requestContext: { regionByAccountEmail: email } },
            );
            return didResend ? "SUCCESS" : "ERROR";
          }}
        />
      ) : data && isKnownUnionValue("VerificationCodeIntent", data.intent) ? (
        <ValidLinkForm
          email={email}
          token={token}
          intent={data.intent}
          organizationName={organizationName}
          mfaState={data.mfaState}
        />
      ) : (
        <InvalidLinkError />
      )}
    </PublicScreenWrapper>
  );
};

type ChooseCredentialsStep =
  | { name: "CHANGE_PASSWORD"; initialPassword: string }
  | { name: "VERIFY_MFA"; chosenPassword: string };

type KnownIntent = KnownUnionValue<IsEmailVerificationCodeValidData["intent"]>;

const ValidLinkForm = ({
  email,
  token,
  organizationName,
  mfaState,
  intent,
}: {
  email: string;
  token: string;
  intent: KnownIntent;
  organizationName: string | null;
  mfaState: IsEmailVerificationCodeValidData["mfaState"];
}) => {
  const t = useTranslation();
  const verifyMfaPayload = buildVerifyMfaPayload(email, mfaState);
  const needsMfaVerification = !!verifyMfaPayload;

  const { accountLoginWithTokens } = useAuth();
  const [chooseAccountCredentials] = useMutation(
    ChooseAccountCredentialsUnauthenticated,
    {
      requestContext: { regionByAccountEmail: email },
      throwOnError: true,
    },
  );

  const frontendData =
    intent.__typename === "VerificationCodeIntentNewAccount"
      ? intent.frontendData
      : null;

  const onSubmit = useCallback(
    async (
      password: string,
      mfaCode?: string,
    ): Promise<"SUCCESS" | "ERROR"> => {
      try {
        const {
          jwtTokens: { accessToken, refreshToken },
        } = await chooseAccountCredentials({
          email,
          emailVerificationToken: token,
          newPassword: password,
          verifyMfaCode: mfaCode,
        });

        if (intent.__typename === "VerificationCodeIntentNewAccount") {
          trackEvent({ name: "Sign Up" });
          trackConversion("SignupConfirmed");
        }

        if (frontendData) {
          const payload = parseOrganizationCreationFrontendData(frontendData);
          if (payload) await scheduleOrganizationCreation(email, payload);
        }

        accountLoginWithTokens({
          accountAccessToken: accessToken,
          accountRefreshToken: refreshToken,
        });

        return "SUCCESS";
      } catch (e) {
        notifier.error({
          user: t("choose_credentials.error"),
          sentry: { exception: e },
        });
        return "ERROR";
      }
    },
    [
      t,
      accountLoginWithTokens,
      chooseAccountCredentials,
      email,
      token,
      intent.__typename,
      frontendData,
    ],
  );

  const [step, setStep] = useState<ChooseCredentialsStep>({
    name: "CHANGE_PASSWORD",
    initialPassword: "",
  });

  switch (step.name) {
    case "CHANGE_PASSWORD":
      return (
        <div className="flex-col w-full max-w-[450px]">
          <ChangePasswordForm
            intent={intent}
            organizationName={organizationName}
            initialPassword={step.initialPassword}
            needsMfaVerification={needsMfaVerification}
            onSubmit={async (password) => {
              if (needsMfaVerification) {
                setStep({ name: "VERIFY_MFA", chosenPassword: password });
              } else {
                await onSubmit(password);
              }
            }}
          />
        </div>
      );

    case "VERIFY_MFA":
      return (
        <div className="flex-col w-full max-w-[450px]">
          <VerifyMfaForm
            {...verifyMfaPayload!}
            onGoBack={() =>
              setStep({
                name: "CHANGE_PASSWORD",
                initialPassword: step.chosenPassword,
              })
            }
            onSubmit={(code) => onSubmit(step.chosenPassword, code)}
          />
        </div>
      );
  }
};

const ChangePasswordForm = ({
  intent,
  organizationName,
  initialPassword,
  needsMfaVerification,
  onSubmit,
}: {
  intent: KnownIntent;
  organizationName: string | null;
  initialPassword: string;
  needsMfaVerification: boolean;
  onSubmit: (password: string) => Promise<void>;
}) => {
  const t = useTranslation();

  return (
    <div className="flex-col">
      <h1 className="title mb-14">
        {run(() => {
          switch (intent.__typename) {
            case "VerificationCodeIntentNewAccount":
              return t("choose_credentials.new_account_title");
            case "VerificationCodeIntentUserInitiatedReset":
            case "VerificationCodeIntentSuperuserInitiatedReset":
              return t("choose_credentials.reset_password_title");
          }
        })}
      </h1>
      <div className="text-16 text-center mb-32 leading-normal">
        {run(() => {
          switch (intent.__typename) {
            case "VerificationCodeIntentNewAccount":
              return t("choose_credentials.new_account_subtitle", {
                organizationName: organizationName ?? "Nabla",
              });
            case "VerificationCodeIntentUserInitiatedReset":
              return t("choose_credentials.reset_password_subtitle_user", {
                organizationName: organizationName ?? "Nabla",
              });
            case "VerificationCodeIntentSuperuserInitiatedReset":
              return t("choose_credentials.reset_password_subtitle_superuser", {
                organizationName: organizationName ?? "Nabla",
              });
          }
        })}
        {needsMfaVerification &&
          ` ${t("choose_credentials.needs_mfa_subtitle")}`}
      </div>

      <ChoosePasswordForm
        initialPassword={initialPassword}
        onSubmit={onSubmit}
      />
    </div>
  );
};
