import { AudioMessageRecorder } from "components/composer-parts/AudioMessageRecorder";
import { ExperienceComposerBottomSafeArea } from "components/composer-parts/ExperienceComposerBottomSafeArea";
import { ExperienceEmojiPicker } from "components/composer-parts/ExperienceEmojiPicker";
import { Gallery } from "components/composer-parts/Gallery";
import { MediaPicker } from "components/composer-parts/MediaPicker";
import { MessageInput } from "components/composer-parts/MessageInput";
import { PollModalCreationButton } from "components/composer-parts/PollModalCreationButton";
import { ReplyMessageBanner } from "components/composer-parts/ReplyMessageBanner";
import {
  useExperience,
  useExperienceDraft,
} from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import { AddDoctorToExperience } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { ephemeralUuidV4 } from "utils/stackoverflow";

export const Composer = ({
  withAudio,
  withPolls,
  sendOnCmdEnter,
  joinExperienceOnSend,
}: {
  withAudio?: boolean;
  withPolls?: boolean;
  sendOnCmdEnter?: boolean;
  joinExperienceOnSend?: boolean;
}) => {
  const t = useTranslation();
  const { experience, sendMessage, setReplyMessage, isInExperience } =
    useExperience();
  const { user } = useDoctor();
  const isDesktop = useIsDesktop();

  const [addDoctorToExperience, joiningExperience] = useMutation(
    AddDoctorToExperience,
  );
  const {
    mediaMessages,
    setMediaMessages,
    messageText,
    setMessageText: setText,
  } = useExperienceDraft();

  const messageIsEmpty = mediaMessages.isEmpty() && messageText.isBlank();

  const send = () => {
    const batchId = ephemeralUuidV4();
    if (messageText.isNotBlank()) {
      sendMessage({ text: messageText.trim() }, batchId);
      setText("");
    }
    mediaMessages.forEach((media) => sendMessage(media, batchId));
    setMediaMessages([]);
    setReplyMessage(undefined);
  };

  const addDoctorIfNeededAndSendMessage = () => {
    if (isInExperience || !joinExperienceOnSend) {
      send();
    } else if (experience) {
      addDoctorToExperience(
        { experienceUuid: experience.uuid, doctorUuid: user.uuid },
        { onSuccess: () => send() },
      );
    }
  };

  return (
    <div className="w-full">
      <Gallery />
      <div className="bg-white p-16 pb-8 flex-col">
        <ReplyMessageBanner className="mb-12" />
        <div className="flex-col relative">
          <MessageInput
            className="pr-32"
            onInput={(e) => setText(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (
                isDesktop &&
                sendOnCmdEnter &&
                e.key === "Enter" &&
                (e.metaKey || e.ctrlKey) &&
                !messageIsEmpty
              ) {
                e.preventDefault();
                addDoctorIfNeededAndSendMessage();
              }
            }}
          />
          <ExperienceEmojiPicker className="absolute inset-br-6" />
        </div>
        <div className="flex items-center space-x-12 relative">
          <MediaPicker className="p-8 pl-4" />
          {withAudio && <AudioMessageRecorder buttonClassName="p-8" />}
          {withPolls && <PollModalCreationButton />}
          <button
            className="button-link ml-auto text-14 font-medium"
            disabled={messageIsEmpty || joiningExperience}
            onClick={() => addDoctorIfNeededAndSendMessage()}
          >
            {t("doctors_conversation.composer.send")}
          </button>
        </div>
        <ExperienceComposerBottomSafeArea />
      </div>
    </div>
  );
};
