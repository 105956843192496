import gql from "graphql-tag";

import { Avatar } from "components/Avatar/Avatar";
import { MoreMenu } from "components/Menu/MoreMenu";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { useMessages } from "contexts/Messages/MessagesContext";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { DeleteDocument, PatientDocumentFragment } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useDownload } from "hooks/useDownload";
import { useTranslation } from "i18n";
import { MediaContent } from "singletons";
import { displayUser } from "utils/display";

import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";
import { SearchPage } from "../components/SearchPage";

gql`
  mutation DeleteDocument($uuid: UUID!) {
    deletePatientDocument(uuid: $uuid) {
      patientDocumentUuid
      patient {
        uuid
      }
    }
  }
`;

export const Document = ({
  document,
  back,
  onUpdateSuccess,
}: {
  document: PatientDocumentFragment;
  back: () => void;
  onUpdateSuccess?: (document: PatientDocumentFragment) => void;
}) => {
  const t = useTranslation();
  const { experience } = useQAExperience();
  const { ehrComposerState, setEHRComposerState } = usePatient();
  const [deleteDocument] = useMutation(DeleteDocument);
  const [download, downloading] = useDownload();
  const { sendMessage } = useMessages();

  return (
    <SearchPage
      title={<div className="flex-col">{document.title}</div>}
      subtitle={(document.realizedAt?.dateTime ?? document.createdAt).format(
        "date",
      )}
      back={back}
    >
      <article className="flex-fill w-full overflow-auto bg-white space-y-10">
        <div className="flex items-center space-x-4 w-full">
          <Avatar user={document.author} className="mr-8" size={24} />
          <div className="flex-col text-14">
            <span className="text-primary-dark font-medium">
              {displayUser(document.author)}
            </span>
            <span className="font-medium">
              {document.createdAt.format({ relative: "week" }).upperFirst()}
            </span>
          </div>
          <DeletionConfirmationModal
            title={t(
              "inboxes.qa_side_panel.patient_information_panel.document.delete_the_document",
            )}
            suffix={t(
              "inboxes.qa_side_panel.patient_information_panel.document.delete_this_document",
            )}
            onConfirm={(close) => {
              deleteDocument(
                { uuid: document.uuid },
                {
                  onSuccess: () => {
                    close();
                    back();
                  },
                },
              );
            }}
          >
            {(showDeleteConfirmationModal) => (
              <ConfirmationModal
                title={t("document.send_the_document_to_the_patient")}
                body={
                  <p className="mt-10">
                    {t(
                      "document.the_document_will_be_sent_as_an_attachment_in_the_current_conversation",
                    )}
                  </p>
                }
                cta={{
                  label: t(
                    "inboxes.qa_side_panel.patient_information_panel.document.send",
                  ),
                }}
                onConfirm={(closeModal) => {
                  document.fileUpload.let((content) =>
                    sendMessage(experience.uuid, {
                      content,
                    }),
                  );
                  closeModal();
                }}
              >
                {(showSendConfirmationModal) => (
                  <MoreMenu
                    className="ml-auto"
                    items={[
                      {
                        icon: "edit",
                        text: t(
                          "inboxes.qa_side_panel.patient_information_panel.document.comment",
                        ),
                        onClick: (close) => {
                          close();
                          setEHRComposerState({
                            mode: "document-edit",
                            document,
                            onSuccess: onUpdateSuccess,
                          });
                        },
                        hidden: !!ehrComposerState,
                      },
                      {
                        text: t(
                          "inboxes.qa_side_panel.patient_information_panel.document.download",
                        ),
                        onClick: () =>
                          document.url.let((url) =>
                            download(url, document.title),
                          ),
                        disable: { if: downloading },
                        icon: "download",
                      },
                      {
                        text: t(
                          "inboxes.qa_side_panel.patient_information_panel.document.send_to_patient",
                        ),
                        onClick: (close) => {
                          close();
                          showSendConfirmationModal();
                        },
                        icon: "send",
                      },
                      {
                        icon: "trash",
                        text: t(
                          "inboxes.qa_side_panel.patient_information_panel.document.delete",
                        ),
                        className: "text-danger",
                        onClick: (close) => {
                          close();
                          showDeleteConfirmationModal();
                        },
                      },
                    ]}
                  />
                )}
              </ConfirmationModal>
            )}
          </DeletionConfirmationModal>
        </div>
        <div className="text-primary-dark whitespace-pre-wrap mb-20 space-y-12">
          <span className="text-18 font-medium">{document.title}</span>
          {document.description && (
            <div className="flex my-12 text-primary-dark">
              {document.description}
            </div>
          )}
          {document.url && document.mimeType && (
            <MediaContent
              media={{
                ...document,
                url: document.url,
                mimeType: document.mimeType,
              }}
            />
          )}
        </div>
      </article>
    </SearchPage>
  );
};
