import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import {
  FormDropzone,
  FormDropzoneValue,
} from "components/Form/Dropzone/FormDropzone";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { Modal } from "components/Modal/Modal";
import { Spinner } from "components/Spinner/Spinner";
import { useUser } from "contexts/User/UserContext";
import {
  CreateRecordedConversation,
  CreateRecordedConversationData,
  RecordedConversation as RecordedConversationQuery,
  RecordedConversationFragment,
  RecordedConversations,
  RecordedConversationsData,
  UpdateRecordedConversation,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useUploadFile } from "graphql-client/useUploadFile";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { GraphQLClient } from "types";

import { FormAudioRecorder, FormAudioRecorderValue } from "./FormAudioRecorder";
import { useAudioDrafts } from "./useAudioDrafts";

gql`
  query RecordedConversation($uuid: UUID!) {
    recordedConversation(uuid: $uuid) {
      recordedConversation {
        audios {
          ...FileUpload
          transcript {
            uuid
            items {
              uuid
              text
              speakerTag
            }
          }
        }
        ...RecordedConversation
      }
    }
  }

  mutation CreateRecordedConversation($input: RecordedConversationInput!) {
    createRecordedConversation(input: $input) {
      recordedConversation {
        audios {
          transcript {
            items {
              text
              speakerTag
            }
          }
        }
        ...RecordedConversation
      }
    }
  }

  mutation UpdateRecordedConversation(
    $uuid: UUID!
    $input: RecordedConversationInput!
  ) {
    updateRecordedConversation(uuid: $uuid, input: $input) {
      recordedConversation {
        ...RecordedConversation
      }
    }
  }
`;

type FormValues = {
  consent: FormDropzoneValue;
  audio: FormAudioRecorderValue;
  note: string;
  forNoteAnnotation: boolean;
};

export const NewRecordedConversation = () => <RecordedConversationForm />;

export const RecordedConversation = () => {
  const { uuid } = useParams();
  const { data, loading } = useQuery(RecordedConversationQuery, {
    variables: {
      uuid,
    },
  });
  if (loading || !data) return <Spinner />;
  return <RecordedConversationForm initialValue={data.recordedConversation} />;
};

const RecordedConversationForm = ({
  initialValue,
}: {
  initialValue?: RecordedConversationFragment;
}) => {
  const { hasRole } = useUser();
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [createRecordedConversation] = useMutation(CreateRecordedConversation);
  const [updateRecordedConversation] = useMutation(UpdateRecordedConversation);
  const uploadFile = useUploadFile();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const t = useTranslation();
  const { drafts, deleteAllDrafts, createOrUpdateDraft, deleteDraft } =
    useAudioDrafts();
  const [initialDrafts, setInitialDrafts] = useState(drafts);
  const { hasPermission } = useUser();
  // "drafts" is undefined at first, the first DB query will be asynchronous.
  useEffect(() => {
    if (initialDrafts === undefined && drafts !== undefined) {
      setInitialDrafts(drafts);
    }
  }, [initialDrafts, drafts]);

  const onHideModal = () => {
    setShowThankYouModal(false);
    navigate(`/${routes.RECORDED_CONVERSATIONS}`);
  };

  return (
    <div className="sm:bg-white flex-col items-center flex-fill lg:p-32">
      <Modal
        show={showThankYouModal}
        onHide={onHideModal}
        title={t("recorded_conversations.thank_you")}
      >
        <div className="my-32">{t("recorded_conversations.thanks")}</div>
        <Button
          label={t("recorded_conversation.close")}
          onClick={onHideModal}
        />
      </Modal>
      <Form<FormValues>
        className="flex-col items-center px-20 py-32 flex-fill overflow-y-auto lg:border lg:rounded lg:bg-white lg:w-[640px]"
        validateOnMount
        initialValues={{
          consent: {
            purpose: "PATIENT_DOCUMENT",
            upload: initialValue?.consent ?? undefined, // can be null
          },
          audio: {
            files: initialValue?.audios ?? initialDrafts ?? [],
            recording: false,
          },
          note: initialValue?.note ?? "",
          forNoteAnnotation: initialValue?.forNoteAnnotation ?? false,
        }}
        validate={(values) => {
          if (values.audio.recording) {
            return { audio: t("recorded_conversations.recording") };
          }
          if (values.audio.files.isEmpty()) {
            return { audio: t("recorded_conversations.recording") };
          }
          return {};
        }}
        onSubmit={async (values, { resetForm }) => {
          let consentUuid;
          if (values.consent.upload && "getInput" in values.consent.upload) {
            const uploadRes = await values.consent.upload.getInput();
            if (!uploadRes) {
              throw new Error(t("recorded_conversations.upload_fail"));
            }
            consentUuid = uploadRes.uuid;
          } else {
            consentUuid = values.consent.upload?.uuid;
          }

          const audioInputsUuids = await Promise.all(
            values.audio.files.map(async (it) => {
              if (it.__typename === "LocalAudioFile") {
                return uploadFile({
                  purpose: "AUDIO_CONVERSATION",
                  file: it.file,
                }).then((res) => res?.uuid);
              }
              return it.uuid;
            }),
          );
          if (audioInputsUuids.some((it) => !it)) {
            throw new Error(t("recorded_conversations.audio_upload_fail"));
          }
          const payload = {
            audioUuids: audioInputsUuids.filterNotNull(),
            consentUuid,
            note: values.note,
            locale: initialValue?.locale ?? "FRENCH",
            forNoteAnnotation: values.forNoteAnnotation,
          };
          if (initialValue) {
            await updateRecordedConversation({
              uuid: initialValue.uuid,
              input: payload,
            });
            navigate(`/${routes.RECORDED_CONVERSATIONS}`);
          } else {
            await createRecordedConversation(
              {
                input: payload,
              },
              {
                onSuccess: (
                  res: CreateRecordedConversationData,
                  client: GraphQLClient,
                ) => {
                  deleteAllDrafts();
                  client.update({
                    query: RecordedConversations,
                    write: (current: RecordedConversationsData) => {
                      current.recordedConversations.data.unshift(
                        res.recordedConversation,
                      );
                    },
                  });
                  setShowThankYouModal(true);
                },
              },
            );
            resetForm();
          }
        }}
      >
        <FormState<FormValues>>
          {({ isValid, isSubmitting }) => (
            <>
              {initialDrafts?.isNotEmpty() && (
                <div className="border border-primary text-primary w-full p-24 rounded mb-32 flex items-center">
                  <Icon name="info" className="mr-12" />
                  {t("recorded_conversation.audio_restored")}
                </div>
              )}
              <div className="w-full text-primary-dark text-18 font-medium">
                {t("recorded_conversations.consent_header")}
              </div>
              {(hasRole("NABLA_ADMINISTRATOR") ||
                hasRole("NABLA_MEDICAL_STAFF")) && (
                <FormDropzone
                  className="mt-20  text-16 rounded border border-solid border-body"
                  placeholder={t("recorded_conversations.pics")}
                  name="consent"
                  maxSize={100_000_000}
                  height={140}
                />
              )}
              <div className="w-full text-primary-dark text-18 font-medium mt-40">
                {t("recorded_conversations.records_header")}
              </div>
              <FormAudioRecorder
                name="audio"
                createOrUpdateDraft={createOrUpdateDraft}
                deleteDraft={deleteDraft}
              />
              <div className="w-full text-16">
                {!isDesktop && (
                  <div>{t("recorded_conversations.no_note_mobile")}</div>
                )}
                <div className="w-full text-primary-dark text-18 font-medium">
                  {t("recorded_conversations.note_headers")}
                </div>
                <FormTextArea name="note" className="mb-12" />
              </div>
              {hasPermission("VIEW_ANY_NOTE_ANNOTATION") && (
                <FormCheckbox
                  name="forNoteAnnotation"
                  label={t("recorded_conversations.good_for_note_annotation")}
                  className="my-10 w-full"
                />
              )}
              <Submit
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
                className="mt-auto"
                label={t("recorded_conversations.sent")}
              />
            </>
          )}
        </FormState>
      </Form>
    </div>
  );
};
