import { ReactNode, RefObject } from "react";

import { Avatar } from "components/Avatar/Avatar";
import { useUserForPrescriptions } from "components/EHRComposer/Prescriptions/useUserForPrescriptions";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { SexKnownValues, SupportedLocaleKnownValues } from "generated/provider";
import { useTranslation } from "i18n";
import { run } from "utils";
import { now } from "utils/date";
import { displayAge, displayDoctor, displaySex } from "utils/display";
import { isKnownValue } from "utils/enum";
import { getSignatureSrc } from "utils/signature";

export const DocumentPreview = ({
  previewRef,
  className,
  children,
  defaultSignature,
}: {
  previewRef: RefObject<HTMLDivElement> | null;
  className?: string;
  children: ReactNode;
  defaultSignature?: string;
}) => {
  const t = useTranslation();
  const user = useUserForPrescriptions();
  const { patient } = usePatient();
  const signatureSrc = getSignatureSrc(user.signature);

  const locale = user.subOrganization.locale;

  if (!isKnownValue(locale, SupportedLocaleKnownValues)) return null;

  const text = (en: string, fr: string, pt: string) =>
    ({ ENGLISH: en, FRENCH: fr, PORTUGUESE: pt }[locale]);

  return (
    <PDFPreview
      previewRef={previewRef}
      className={className}
      documentClassName="flex-col p-[50px] pb-30 text-black"
      autoPagesOptions={{ margin: [50, 50, 50, 50] }}
    >
      <div className="flex-fill flex-col">
        <div className="flex items-center space-x-16">
          <Avatar
            user={user.subOrganization}
            squared
            size={44}
            className={
              user.subOrganization.avatar
                ? undefined
                : "bg-grey-200 text-primary-dark"
            }
          />
          <div className="text-16 font-bold">
            {user.subOrganization.displayName ??
              user.subOrganization.organization.displayName}
          </div>
        </div>
        <div className="mt-12 font-medium">
          {displayDoctor(user, "WITH_PREFIX")}
        </div>
        <div className="text-10">
          {user.prescriptionAddress &&
            text(
              `${user.prescriptionAddress.address}, ${user.prescriptionAddress.city}, ${user.prescriptionAddress.zipCode}`,
              `${user.prescriptionAddress.address} ${user.prescriptionAddress.zipCode} ${user.prescriptionAddress.city}`,
              `${user.prescriptionAddress.address}, ${user.prescriptionAddress.city}, ${user.prescriptionAddress.zipCode}`,
            )}
        </div>
        {run(() => {
          switch (user.locale) {
            case "FRENCH":
              return user.rpps || user.adeli ? (
                <>
                  {user.rpps && (
                    <div className="text-10">Numéro RPPS: {user.rpps}</div>
                  )}
                  {user.adeli && (
                    <div className="text-10">Numéro Adeli: {user.adeli}</div>
                  )}
                </>
              ) : (
                <div className="text-10">
                  Numéro d’Assurance Maladie : {user.nationalProviderIdentifier}
                </div>
              );
            case "ENGLISH":
            default:
              return (
                <div className="text-10">{user.nationalProviderIdentifier}</div>
              );
          }
        })}
        <DocumentPreviewSection
          className="mt-24"
          title={t("pdf_preview.patient_details")}
        >
          <div className="font-medium">
            {patient.firstName} {patient.lastName}
          </div>
          <div className="text-10">
            {isKnownValue(patient.sex, SexKnownValues)
              ? `${displaySex(patient.sex)}, `
              : null}
            {displayAge(patient.birthDate)}
          </div>
          {patient.address && patient.city && patient.postcode && (
            <div className="text-10">
              {text(
                `${patient.address}, ${patient.city}, ${patient.postcode}`,
                `${patient.address} ${patient.postcode} ${patient.city}`,
                `${patient.address}, ${patient.city}, ${patient.postcode}`,
              )}
            </div>
          )}
        </DocumentPreviewSection>
        {children}
        <DocumentPreviewSection
          className="mt-24"
          title={t("pdf_preview.signature")}
        >
          <div>Date: {now().format("date")}</div>
          {signatureSrc && (
            <img
              src={signatureSrc}
              alt="signature"
              className="self-start object-contain"
              style={{ height: 50 }}
            />
          )}
        </DocumentPreviewSection>
        <div className="mt-24 border-t pt-12">
          {user.subOrganization.whiteLabelDocumentSignature ?? defaultSignature}
        </div>
      </div>
    </PDFPreview>
  );
};

export const DocumentPreviewSection = ({
  title,
  children,
  className,
}: {
  title: string;
  children: ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <div className="border-t bg-grey-100 px-8 py-4 font-medium uppercase text-body">
      {title}
    </div>
    <div className="pt-12 px-8 flex-col">{children}</div>
  </div>
);
