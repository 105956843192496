import gql from "graphql-tag";

import {
  CreateSubOrganization,
  SubOrganizationsQuery,
  SubOrganizationSummaryFragment,
  UpdateSubOrganization,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { notifier } from "utils/notifier";

import { BaseSubOrganizationsComposer } from "../../../shared/Organizations/BaseSubOrganizationComposer";

gql`
  # schema = ORGANIZATION_USER
  mutation CreateSubOrganization($displayName: String!) {
    createSubOrganization(displayName: $displayName) {
      subOrganization {
        ...SubOrganizationSummary
      }
    }
  }

  mutation UpdateSubOrganization(
    $uuid: UUID!
    $input: UpdateSubOrganizationInput!
  ) {
    updateSubOrganization(uuid: $uuid, input: $input) {
      subOrganization {
        ...SubOrganizationSummary
      }
    }
  }
`;

export const SubOrganizationsComposer = ({
  subOrganization,
  onClose,
}: {
  subOrganization?: SubOrganizationSummaryFragment;
  onClose: () => void;
}) => {
  const [createSubOrganization] = useMutation(CreateSubOrganization, {
    onSuccess: (newSubOrg, client) => {
      client.update({
        query: SubOrganizationsQuery,
        variables: {},
        write: (d) => {
          d.subOrganizations.data.unshift(newSubOrg.subOrganization);
        },
      });
      notifier.success("Sub-organization created");
      onClose();
    },
  });
  const [updateSubOrganization] = useMutation(UpdateSubOrganization, {
    onSuccess: () => {
      notifier.success("Sub-organization updated");
      onClose();
    },
  });

  return (
    <BaseSubOrganizationsComposer
      uuid={subOrganization?.uuid}
      displayName={subOrganization?.displayName ?? undefined}
      createSubOrganization={(displayName) =>
        createSubOrganization({
          displayName,
        }).then()
      }
      updateSubOrganization={({ uuid, input }) =>
        updateSubOrganization({ uuid, input }).then()
      }
      onClose={onClose}
    />
  );
};
