import { Maybe } from "base-types";
import {
  ConditionFragment,
  DoctorSummaryFragment,
  FamilyMemberDegreeKnownValues,
  MentionableInNoteFragment,
  PatientTimelineEventFragment,
  PatientTimelinePillType,
  PatientTimelinePillTypeKnownValues,
} from "generated/provider";
import { staticT as t } from "i18n";
import { colors } from "styles";
import { isHealthDataPointWithKnownUnionValue } from "types";
import { isKnownUnionValue } from "utils/apollo";
import {
  getHealthDataPointEnumValue,
  getHealthDataPointNameMap,
} from "utils/data-points";
import { displayFamilyMemberDegree } from "utils/display";
import { isKnownValue } from "utils/enum";
import { displayContraception } from "utils/medical-facts";

export type TimelineEventItem = {
  category: PatientTimelinePillType | undefined;
  summary: string;
  label: { plural: string; singular?: string } | undefined;
  color: string | undefined;
  defaultDescription?: string;
};

export type PatientViewNavigationState = {
  openNewChatWidget?: boolean;
  preventFocus?: boolean;
} | null;

export const getSourceNote = (
  timelineEvent: Maybe<PatientTimelineEventFragment>,
): MentionableInNoteFragment["sourceNote"] => {
  if (
    !timelineEvent ||
    !isKnownUnionValue("EhrEventContent", timelineEvent.content)
  ) {
    return null;
  }

  switch (timelineEvent.content.__typename) {
    case "PatientAllergyCreatedOrAccepted":
      return timelineEvent.content.patientAllergy.sourceNote;
    case "ProcedureCreatedOrAccepted":
      return timelineEvent.content.procedure.sourceNote;
    case "MedicationCreatedOrAccepted":
      return timelineEvent.content.medicationStatement.sourceNote;
    case "DataPointCreated":
      return timelineEvent.content.dataPoint.sourceNote;
    case "VaccineCreated":
      return timelineEvent.content.vaccinationStatement.sourceNote;
    case "ConditionCreatedOrAccepted":
      return timelineEvent.content.condition.sourceNote;

    case "NoteCreated":
    case "TaskCreated":
    case "DocumentCreated":
    case "ExperienceCreated":
    case "ContraceptionCreatedOrApproved":
    case "SymptomCreated":
      return null;
  }
};

export const getUpdatedAt = (
  timelineEvent: Maybe<PatientTimelineEventFragment>,
): string => {
  if (
    !timelineEvent ||
    !isKnownUnionValue("EhrEventContent", timelineEvent.content)
  ) {
    return "";
  }

  switch (timelineEvent.content.__typename) {
    case "PatientAllergyCreatedOrAccepted":
      return timelineEvent.content.patientAllergy.updatedAt.format("date");
    case "ProcedureCreatedOrAccepted":
      return timelineEvent.content.procedure.updatedAt.format("date");
    case "MedicationCreatedOrAccepted":
      return timelineEvent.content.medicationStatement.updatedAt.format("date");
    case "DataPointCreated":
      return timelineEvent.content.dataPoint.updatedAt.format("date");
    case "VaccineCreated":
      return timelineEvent.content.vaccinationStatement.updatedAt.format(
        "date",
      );
    case "ConditionCreatedOrAccepted":
      return timelineEvent.content.condition.updatedAt.format("date");

    case "NoteCreated":
    case "TaskCreated":
    case "DocumentCreated":
    case "ExperienceCreated":
    case "ContraceptionCreatedOrApproved":
    case "SymptomCreated":
      return "";
  }
};

export const getUpdatingDoctor = (
  timelineEvent: Maybe<PatientTimelineEventFragment>,
): Maybe<DoctorSummaryFragment> => {
  if (
    !timelineEvent ||
    !isKnownUnionValue("EhrEventContent", timelineEvent.content)
  ) {
    return null;
  }

  switch (timelineEvent.content.__typename) {
    case "PatientAllergyCreatedOrAccepted":
      return timelineEvent.content.patientAllergy.updatedBy;
    case "ProcedureCreatedOrAccepted":
      return timelineEvent.content.procedure.updatedBy;
    case "MedicationCreatedOrAccepted":
      return timelineEvent.content.medicationStatement.updatedBy;
    case "DataPointCreated":
      return timelineEvent.content.dataPoint.updatedBy;
    case "VaccineCreated":
      return timelineEvent.content.vaccinationStatement.updatedBy;
    case "ConditionCreatedOrAccepted":
      return timelineEvent.content.condition.updatedBy;
    case "NoteCreated":
    case "TaskCreated":
    case "DocumentCreated":
    case "ExperienceCreated":
    case "ContraceptionCreatedOrApproved":
    case "SymptomCreated":
      return null;
  }
};

export const getTimelineEventInfo = (
  timelineEvent: Maybe<PatientTimelineEventFragment>,
): TimelineEventItem => {
  if (
    !timelineEvent ||
    !isKnownUnionValue("EhrEventContent", timelineEvent.content)
  ) {
    return {
      category: undefined,
      label: undefined,
      color: colors.grey,
      summary: "",
    };
  }

  switch (timelineEvent.content.__typename) {
    case "NoteCreated":
      return {
        category: "NOTES",
        summary: timelineEvent.content.patientNote.title,
        ...getTimeLineEventTypeInfo("NOTES"),
      };

    case "ConditionCreatedOrAccepted":
      return {
        category: "PMH",
        summary: timelineEvent.content.condition.type,
        ...getTimeLineEventTypeInfo("PMH"),
        defaultDescription: getConditionDefaultDescription(
          timelineEvent.content.condition,
        ),
      };

    case "PatientAllergyCreatedOrAccepted":
      return {
        category: "PMH",
        summary: timelineEvent.content.patientAllergy.type,
        defaultDescription: t("inboxes.qa_side_panel.utils.allergy"),
        ...getTimeLineEventTypeInfo("PMH"),
      };

    case "ProcedureCreatedOrAccepted":
      return {
        category: "PMH",
        summary: timelineEvent.content.procedure.name,
        defaultDescription: t("utils.surgical_procedure"),
        ...getTimeLineEventTypeInfo("PMH"),
      };

    case "TaskCreated":
      return {
        category: "TASK",
        summary: timelineEvent.content.task.title,
        ...getTimeLineEventTypeInfo("TASK"),
      };

    case "DocumentCreated":
      return {
        category: "DOCUMENTS",
        summary: timelineEvent.content.patientDocument.title,
        ...getTimeLineEventTypeInfo("DOCUMENTS"),
      };

    case "ExperienceCreated":
      return {
        category: "HISTORY_OR_COMMENTS",
        summary:
          timelineEvent.content.experience.title ??
          t("inboxes.qa_side_panel.utils.chat"),
        ...getTimeLineEventTypeInfo("HISTORY_OR_COMMENTS"),
      };

    case "ContraceptionCreatedOrApproved":
      return {
        category: "MEDICATION",
        summary: displayContraception(timelineEvent.content.contraception),
        defaultDescription: t("inboxes.qa_side_panel.utils.birth_control"),
        ...getTimeLineEventTypeInfo("MEDICATION"),
      };

    case "MedicationCreatedOrAccepted":
      return {
        category: "MEDICATION",
        summary: timelineEvent.content.medicationStatement.display,
        ...getTimeLineEventTypeInfo("MEDICATION"),
        defaultDescription: t("inboxes.qa_side_panel.utils.medication"),
      };

    case "DataPointCreated":
      return {
        category: "OBSERVATIONS",
        summary: isHealthDataPointWithKnownUnionValue(
          timelineEvent.content.dataPoint,
        )
          ? getHealthDataPointEnumValue(
              timelineEvent.content.dataPoint,
              getHealthDataPointNameMap(),
            )
          : "",
        ...getTimeLineEventTypeInfo("OBSERVATIONS"),
      };

    case "SymptomCreated":
      return {
        category: undefined,
        label: {
          plural: t("inboxes.qa_side_panel.utils.symptoms"),
          singular: t("inboxes.qa_side_panel.utils.symptom"),
        },
        color: colors.orange,
        summary: timelineEvent.content.symptom.name,
        defaultDescription: t("inboxes.qa_side_panel.utils.symptom"),
      };

    case "VaccineCreated":
      return {
        category: "PREVENTION",
        summary: timelineEvent.content.vaccinationStatement.type,
        defaultDescription: t("inboxes.qa_side_panel.utils.vaccine"),
        ...getTimeLineEventTypeInfo("PREVENTION"),
      };
  }
};

export const getConditionDefaultDescription = (
  condition: ConditionFragment,
): string => {
  const familyMember = condition.familyMember;
  if (familyMember) {
    if (!isKnownValue(familyMember, FamilyMemberDegreeKnownValues)) {
      return "Unknown family member";
    }

    return t("inboxes.qa_side_panel.utils.family_history", {
      familyMember: displayFamilyMemberDegree(familyMember).lowerFirst(),
    });
  }
  return t("inboxes.qa_side_panel.utils.medical_history");
};

export const getTimeLineEventTypeInfo = (
  type: undefined | PatientTimelinePillType,
): {
  label: { plural: string; singular?: string } | undefined;
  color: string | undefined;
} => {
  if (type === undefined) {
    return {
      label: {
        plural: t("inboxes.qa_side_panel.utils.all"),
      },
      color: undefined,
    };
  }

  if (!isKnownValue(type, PatientTimelinePillTypeKnownValues)) {
    return { label: undefined, color: colors.grey };
  }

  switch (type) {
    case "HISTORY_OR_COMMENTS":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.chats"),
          singular: t("inboxes.qa_side_panel.utils.chat"),
        },
        color: colors.brown,
      };

    case "NOTES":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.notes"),
          singular: t("inboxes.qa_side_panel.utils.note"),
        },
        color: colors.yellow,
      };

    case "DOCUMENTS":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.documents"),
          singular: t("inboxes.qa_side_panel.utils.document"),
        },
        color: colors.grey,
      };
    case "PMH":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.pmh"),
          singular: t("inboxes.qa_side_panel.utils.pmh"),
        },
        color: colors.orange,
      };

    case "MEDICATION":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.meds"),
          singular: t("inboxes.qa_side_panel.utils.med"),
        },
        color: colors.pink,
      };

    case "PREVENTION":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.prevention"),
          singular: t("inboxes.qa_side_panel.utils.prevention"),
        },
        color: colors.primary,
      };

    case "OBSERVATIONS":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.observations"),
          singular: t("inboxes.qa_side_panel.utils.observation"),
        },
        color: colors.blue,
      };

    case "TASK":
      return {
        label: {
          plural: t("inboxes.qa_side_panel.utils.tasks"),
          singular: t("inboxes.qa_side_panel.utils.task"),
        },
        color: colors.danger,
      };
    case "MEDICAL_ATCD":
    case "FAMILY_ATCD":
    case "SURGICAL_ATCD":
    case "VACCINE":
    case "ALLERGY":
    case "DATAPOINTS":
      return { label: undefined, color: colors.grey };
  }
};
