import { ReactNode, useState } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { EHRSuggestion } from "components/EHRSuggestion/EHRSuggestion";
import { Switch } from "components/Form/Switch/Switch";
import { TextArea } from "components/Form/TextArea/TextArea";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { useDoctor } from "contexts/User/UserContext";
import { useRerender } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { language, setLanguage } from "utils/intl";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import background from "./bg.png";
import { displayPrescriptionsDraft, displayReport } from "./display";
import laurent from "./laurent.png";
import { PrescriptionModal } from "./PrescriptionModal";
import { TranscriptItems } from "./TranscriptItems";
import { useLiveFactExtraction } from "./useLiveFactExtraction";

const TimelineItemBlock = ({
  children,
  date,
}: {
  children: ReactNode;
  date: ISOString;
}) => (
  <>
    <div className="py-6 relative flex items-center">
      <div
        className="absolute -left-6 rounded-full bg-white border"
        style={{ height: 13, width: 13 }}
      />
      <div className="ml-14 text-12">
        {date.format({ exception: "d MMM yyyy" })}
      </div>
    </div>
    <div className={classNames("flex-col border-l space-y-8 pl-12 mb-16")}>
      {children}
    </div>
  </>
);

export const LiveSpeechAnalysisPage = () => {
  const t = useTranslation();
  const isChrome = /chrome/iu.test(navigator.userAgent);

  return isChrome ? (
    <LiveSpeechAnalysis />
  ) : (
    <div className="flex-fill bg-blue-overlay overflow-auto justify-center flex">
      <div className="mt-30 text-center text-14">
        {t("live_speech_analysis.this_demo_only_works_on_chrome_browser")}
      </div>
    </div>
  );
};

const LiveSpeechAnalysis = () => {
  const t = useTranslation();
  const [isDoctorSpeaking, setIsDoctorSpeaking] = useState(false);
  const {
    available,
    suggestions,
    updateSuggestionStatus,
    startTranscribing,
    stopTranscribing,
    transcribing,
    transcriptItems,
  } = useLiveFactExtraction(language);

  const [initialPrescriptionText, setInitialPrescriptionText] = useState<
    string | undefined
  >();
  const rerender = useRerender();

  const report = displayReport(suggestions);

  const { user } = useDoctor();
  return (
    <div className="flex-fill h-full bg-blue-overlay flex-col">
      {initialPrescriptionText !== undefined && (
        <PrescriptionModal
          onHide={() => setInitialPrescriptionText(undefined)}
          initialText={initialPrescriptionText}
        />
      )}
      <div className="flex-fill flex overflow-hidden">
        <div style={{ width: "60%" }}>
          <Background
            style={{
              backgroundImage: `url('${background}')`,
              backgroundSize: "cover",
            }}
            className="flex-col items-start justify-between h-full p-24"
          >
            {!available && (
              <div className="bg-white-overlay-800 p-20 rounded">
                {t(
                  "live_speech_analysis.sorry_this_demo_only_works_on_speechtotext_compatible_browsers",
                )}
              </div>
            )}
            <div className="bg-white-overlay-800 p-20 rounded mt-20">
              <TranscriptItems items={transcriptItems} />
            </div>
            <div className="flex w-full mt-auto items-end">
              <div className="flex-col items-center">
                <div
                  className="flex-center mt-12 mb-28"
                  style={{ width: 80, height: 80 }}
                >
                  {transcribing ? (
                    <ClickableIcon
                      name="stop"
                      className="bg-danger text-white rounded-full recording-pulse"
                      style={{ height: 66, width: 66 }}
                      onClick={stopTranscribing}
                    />
                  ) : (
                    <ClickableIcon
                      name="micOn"
                      disabled={transcribing}
                      color="white"
                      size={24}
                      className="w-full h-full rounded-full text-14 font-medium bg-danger"
                      onClick={() => {
                        startTranscribing(
                          isDoctorSpeaking ? "DOCTOR" : "PATIENT",
                        );
                      }}
                    />
                  )}
                </div>
                <div className="bg-white-overlay-800 rounded p-16 flex-col space-y-8">
                  <Switch
                    label="Doctor"
                    disabled={transcribing}
                    checked={isDoctorSpeaking}
                    onChange={(checked) => {
                      setIsDoctorSpeaking(checked);
                    }}
                  />
                  <Switch
                    label="En"
                    disabled={transcribing}
                    checked={language === "en"}
                    onChange={(checked) => {
                      setLanguage(checked ? "en" : "fr");
                      rerender();
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url('${laurent}')`,
                  width: 150,
                  height: 150,
                  backgroundSize: "cover",
                }}
                className="border-white border rounded mx-24"
              />

              <div
                className="ml-auto bg-white-overlay-300 flex-col space-y-6 p-6 rounded"
                style={{ width: 260 }}
              >
                {suggestions
                  .filter((it) => it.status === "PROPOSED")
                  .map((it) => {
                    const prescribedMedication =
                      it.suggestion.__typename === "MedicationStatement" &&
                      it.speaker === "DOCTOR"
                        ? it.suggestion
                        : undefined;

                    return prescribedMedication ? (
                      <div className="p-12 rounded bg-white shadow-light flex border">
                        <Icon
                          className="rounded-full p-4 h-20 w-20 text-white"
                          name="books"
                          size={8}
                          style={{ background: "blue" }}
                        />
                        <div className="flex-fill flex-col ml-8 mr-8">
                          <div className="text-primary-dark font-regular text-14">
                            PRESCRIRE: "{prescribedMedication.name}" ?
                          </div>
                        </div>
                        <ClickableIcon
                          className="rounded-full bg-grey-100 h-20 w-20 border ml-4 "
                          onClick={() => updateSuggestionStatus(it, "REJECTED")}
                          name="trash"
                          size={10}
                        />
                        <ClickableIcon
                          className="rounded-full bg-primary h-20 w-20 border text-white ml-4"
                          onClick={() => {
                            updateSuggestionStatus(it, "ACCEPTED");
                          }}
                          name="check"
                          size={10}
                        />
                      </div>
                    ) : (
                      <EHRSuggestion
                        key={it.startPosition}
                        suggestion={it.suggestion}
                        onValid={() => updateSuggestionStatus(it, "ACCEPTED")}
                        onDelete={() => updateSuggestionStatus(it, "REJECTED")}
                        showICD10
                      />
                    );
                  })}
              </div>
            </div>
          </Background>
        </div>
        <div
          style={{ width: "40%" }}
          className="bg-white flex-col overflow-y-auto"
        >
          <div
            className="p-20"
            style={{
              background:
                "linear-gradient(180deg, #F5F6FA 5.08%, #FFFFFF 100%)",
            }}
          >
            <div className="mt-20 bg-white rounded shadow-lg p-20 flex-col items-center relative group">
              <Avatar
                user={{
                  __typename: "PublicPatient",
                  uuid: t(
                    "labs.live_speech_analysis.live_speech_analysis.anna_wood",
                  ),
                  username: t(
                    "labs.live_speech_analysis.live_speech_analysis.anna_wood",
                  ),
                  avatarUrl: null,
                }}
                size={44}
                style={{ top: -22 }}
                className="absolute"
              />
              <div className="font-medium text-primary-dark mt-12">
                {t("labs.live_speech_analysis.live_speech_analysis.anna_wood")}
              </div>
              <div className="font-regular text-14">
                {t("live_speech_analysis.yo_female_kg")}
              </div>
            </div>
          </div>
          <div className="p-20">
            <div className="text-primary-dark font-medium mb-12">
              {t("labs.live_speech_analysis.live_speech_analysis.summary")}
            </div>
            <div className="flex">
              <Avatar user={user} />
              <TextArea
                className="rounded border text-body text-14 ml-12 flex-fill"
                value={report}
              />
            </div>
            <Button
              className="ml-auto mt-8"
              style={{ height: 44 }}
              label={t(
                "labs.live_speech_analysis.live_speech_analysis.prescribe",
              )}
              onClick={() =>
                setInitialPrescriptionText(
                  displayPrescriptionsDraft(suggestions),
                )
              }
            />
          </div>
          <div className="flex-col border-t p-20 flex-fill">
            <div className="text-primary-dark font-medium mb-12">
              {t("labs.live_speech_analysis.live_speech_analysis.history")}
            </div>
            {suggestions.count((it) => it.status === "ACCEPTED") > 0 && (
              <TimelineItemBlock date={new Date().toISOString()}>
                {suggestions
                  .filter((it) => it.status === "ACCEPTED")
                  .filter(
                    (it) =>
                      it.speaker === "PATIENT" ||
                      it.suggestion.__typename !== "MedicationStatement",
                  )
                  .map((it) => (
                    <EHRSuggestion
                      key={it.startPosition}
                      suggestion={it.suggestion}
                      disabled={false}
                      showICD10
                    />
                  ))}
              </TimelineItemBlock>
            )}
            <TimelineItemBlock date={new Date().toISOString().minusDays(10)}>
              <EHRSuggestion
                suggestion={{
                  __typename: "MedicationStatement",
                  name: "Spasfon",
                  uuid: ephemeralUuidV4(),
                  description: "",
                  display: "",
                  createdAt: new Date().toISOString(),
                  extractionStatus: "ACCEPTED",
                  status: "UNKNOWN",
                  updatedAt: new Date().toISOString(),
                  updatedBy: null,
                  activeIngredients: [],
                }}
              />
            </TimelineItemBlock>
          </div>
        </div>
      </div>
    </div>
  );
};
