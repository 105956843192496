import { useState } from "react";

import { useMediaWindows } from "atoms/useMediaWindows";
import { Button } from "components/Button/Button";
import { FilePreview } from "components/Form/Dropzone/FilePreview";
import { HighlightText } from "components/HighlightTrext/HighlightText";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { EditTemplateModal } from "components/TemplateForms/EditTemplateModal";
import { useDeleteTemplate } from "components/TemplateForms/useDeleteTemplate";
import {
  FileUploadFragment,
  MixedQAInboxSearchVariables,
} from "generated/provider";
import { useTranslation } from "i18n";
import { TemplateResult } from "types";
import { copyToClipBoard } from "utils";

import { SearchPage } from "./SearchPage";

export const Template = ({
  template,
  fromSearch,
  onTextContentSelected,
  onFileSelected,
  back,
}: {
  template: TemplateResult;
  fromSearch: MixedQAInboxSearchVariables;
  onTextContentSelected: ((value: string) => void) | null;
  onFileSelected?: (file: FileUploadFragment) => void;
  back: () => void;
}) => {
  const t = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const deleteTemplate = useDeleteTemplate(back);
  const { showMedia } = useMediaWindows();

  return (
    <SearchPage
      title={t("inboxes.qa_side_panel.template.template")}
      back={back}
    >
      <div className="text-primary-dark font-medium">
        <HighlightText text={template.title} search={fromSearch.freeText} />
      </div>
      {template.content && (
        <div className="whitespace-pre-wrap text-primary-dark text-14">
          <HighlightText text={template.content} search={fromSearch.freeText} />
        </div>
      )}
      {template.fileUpload && (
        <div
          className="max-h-[250px] mt-16 rounded border-grey-200 border bg-grey-100 hover:cursor-pointer"
          onClick={() => {
            if (template.fileUpload) {
              showMedia({
                ...template.fileUpload,
                title: template.title,
              });
            }
          }}
        >
          <FilePreview
            className="h-[150px] pointer-events-none w-full"
            name={template.fileUpload.fileName}
            type={template.fileUpload.mimeType}
            url={template.fileUpload.urlV2.url}
            isContained
          />
        </div>
      )}
      <div className="mt-16 flex space-x-12">
        <ClickableIcon name="edit" onClick={() => setShowEdit(true)} />
        <DeletionConfirmationModal
          suffix={t("inboxes.qa_side_panel.template.this_template")}
          onConfirm={() => deleteTemplate(template.uuid)}
        >
          {(open) => <ClickableIcon name="trash" onClick={() => open()} />}
        </DeletionConfirmationModal>
      </div>
      <Button
        label={
          onTextContentSelected
            ? t("template.use_this_template_in_my_answer")
            : t("inboxes.qa_side_panel.template.copy_this_template")
        }
        className="mt-24"
        onClick={() => {
          if (template.content) {
            onTextContentSelected
              ? onTextContentSelected(template.content)
              : copyToClipBoard(
                  template.content,
                  t("inboxes.qa_side_panel.template.template_copied"),
                );
          } else if (template.fileUpload) {
            onFileSelected?.(template.fileUpload);
          }
        }}
      />
      {showEdit && (
        <EditTemplateModal
          template={template}
          onHide={() => setShowEdit(false)}
        />
      )}
    </SearchPage>
  );
};
