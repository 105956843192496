import classNames from "classnames";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { AsyncDoctorFormSelect } from "components/Form/Select/AsyncDoctorFormSelect";
import { AsyncPatientFormSelect } from "components/Form/Select/AsyncPatientFormSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { Icon } from "components/Icon/Icon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { TaskPriorityKnownValues } from "generated/provider";
import { useGraphQLClient } from "graphql-client/GraphQLClientContext";
import { staticT, useTranslation } from "i18n";
import { displayTaskPriorityKnownValue } from "utils/display";

import { FormTaskFilterValues, taskFilterCount, TaskStatus } from "./utils";

const TaskStatusKnownValues: TaskStatus[] = ["TODO", "COMPLETED", "ALL"];

export const TasksFilterMenu = ({
  initialFilter,
  onFilterSelected,
}: {
  initialFilter: FormTaskFilterValues;
  onFilterSelected: (filter: FormTaskFilterValues) => void;
}) => {
  const t = useTranslation();
  const filterCount = taskFilterCount(initialFilter);
  const client = useGraphQLClient().graphQLClients.PROVIDER;

  return (
    <UncontrolledPopover
      position={["bottom-left", "bottom-right"]}
      className="mt-10 p-20 flex-col overflow-hidden"
      style={{ maxHeight: "min(500px, 80%)", width: 330 }}
      fullWidthOnMobile
      allowScrolling
      content={(close) => (
        <div className="flex-col">
          <Form<FormTaskFilterValues>
            className="space-y-16 w-full"
            initialValues={initialFilter}
            onSubmit={(values) => {
              client.evictQuery("taskSearch", "filter");
              onFilterSelected(values);
              close();
              return Promise.resolve();
            }}
          >
            <FormSelect
              name="status"
              wrapperClassName="flex-fill"
              options={TaskStatusKnownValues}
              placeholder={t("tasks.tasks_filter_menu.select")}
              label={t("tasks.tasks_filter_menu.status")}
              getOptionLabel={(s) => displayTaskStatus(s)}
            />
            <AsyncPatientFormSelect
              name="patient"
              wrapperClassName="flex-fill justify-between h-full"
              isClearable
            />
            <FormSelect
              name="priority"
              isClearable
              wrapperClassName="flex-fill"
              options={TaskPriorityKnownValues}
              placeholder={t("tasks.tasks_filter_menu.select")}
              label={t("tasks.tasks_filter_menu.priority")}
              getOptionLabel={(s) => displayTaskPriorityKnownValue(s)}
            />
            <AsyncDoctorFormSelect
              name="assignedDoctor"
              getVariables={(s) => ({
                filter: { permissions: ["VIEW_TASKS"], freeTextSearch: s },
              })}
              wrapperClassName="flex-fill"
              label={t("tasks.tasks_filter_menu.assignee")}
              isClearable
            />
            <div className="flex items-center justify-between">
              <button
                onClick={() => {
                  onFilterSelected({
                    status: "TODO",
                    patient: undefined,
                    assignedDoctor: undefined,
                    priority: undefined,
                  });
                  close();
                }}
              >
                <span className="text-body text-14 font-normal underline">
                  {t("tasks.tasks_filter_menu.reset_filters")}
                </span>
              </button>
              <Submit label={t("tasks.tasks_filter_menu.apply")} />
            </div>
          </Form>
        </div>
      )}
    >
      {({ setTarget }) => (
        <button
          onClick={setTarget}
          className={classNames(
            "px-12 py-8 rounded bg-white border lg:mr-12",
            initialFilter.status !== "TODO" ||
              initialFilter.patient !== undefined ||
              initialFilter.assignedDoctor !== undefined ||
              initialFilter.priority !== undefined
              ? "text-primary"
              : "",
          )}
        >
          <div className="flex items-center">
            <Icon name="filter" size={18} className="mr-10" />
            <span className="font-medium text-14">
              {t("tasks.tasks_filter_menu.filter", { count: filterCount })}
            </span>
          </div>
        </button>
      )}
    </UncontrolledPopover>
  );
};

const displayTaskStatus = (status: TaskStatus) => {
  switch (status) {
    case "TODO":
      return staticT("tasks.tasks_filter_menu.to_do");
    case "COMPLETED":
      return staticT("tasks.tasks_filter_menu.completed");
    case "ALL":
      return staticT("tasks.tasks_filter_menu.all");
  }
};
