import { useEffect } from "react";
import gql from "graphql-tag";

import { useAutoCompleteModel } from "atoms/useAutoCompleteModel/useAutoCompleteModel";
import { useDoctor } from "contexts/User/UserContext";
import { LatestAutocompleteExpressionSet } from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";

gql`
  query LatestAutocompleteExpressionSet($currentSetUuid: UUID) {
    latestAutoCompleteExpressionSet(latestSeenByClient: $currentSetUuid) {
      expressionSet {
        uuid
        expressions
      }
    }
  }
`;

export const useFetchAutocompleteData = () => {
  const { hasPermission } = useDoctor();
  const hasRightPermissions = hasPermission("VIEW_MEDICAL_DATA");

  const { updateAutocompleteData, currentExpressionSetUuid } =
    useAutoCompleteModel();

  const [lazyLatestAutocompleteExpressionSet] = useLazyQuery(
    LatestAutocompleteExpressionSet,
    {
      onSuccess: (data) => {
        data.expressionSet?.let(({ uuid, expressions }) =>
          updateAutocompleteData({
            uuid,
            expressions: JSON.parse(expressions),
          }),
        );
      },
    },
  );

  useEffect(() => {
    if (!hasRightPermissions) return;

    const timeOutId = setTimeout(() => {
      lazyLatestAutocompleteExpressionSet({
        currentSetUuid: currentExpressionSetUuid(),
      });
    }, 10_000);
    return () => clearTimeout(timeOutId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
