import { lazy, Suspense } from "react";

import { Media } from "atoms/useMediaWindows";
import { Spinner } from "components/Spinner/Spinner";

import { isRemoteMedia } from "../mediaWindowsModel";
import { AbstractMediaContent } from "./mediaContentModel";
import { ZoomableMediaContentWrapper } from "./ZoomableMediaContentWrapper";

const DicomViewer = lazy(() => import("components/DicomViewer/DicomViewer"));

export const DicomMediaContent: AbstractMediaContent = ({
  media,
}: {
  media: Media;
}) => {
  if (!isRemoteMedia(media)) {
    // Handle blob media is needed
    return null;
  }

  const backgroundElement = <div className="absolute inset-0 bg-black" />;

  return (
    <ZoomableMediaContentWrapper backgroundElement={backgroundElement}>
      <Suspense fallback={<Spinner className="my-20" />}>
        <DicomViewer className="w-full flex-fill" src={media.url} />
      </Suspense>
    </ZoomableMediaContentWrapper>
  );
};
