import { useEffect, useState } from "react";
import gql from "graphql-tag";

import {
  EHRSuggestionFragment,
  ExtractFactsWithSuggestions,
} from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";
import { getLanguageCode } from "utils/intl";

import { TranscriptItem, useLocalSpeechToText } from "./useLocalSpeechToText";

gql`
  query ExtractFactsWithSuggestions(
    $text: String!
    $writtenAt: DateTime!
    $language: String!
  ) {
    extractFacts(
      query: {
        text: $text
        writtenAt: $writtenAt
        language: $language
        withGPT3PostProcessing: false
      }
    ) {
      suggestions {
        suggestion {
          ...EHRSuggestion
        }
        textStartPosition
      }
      jsonReport
    }
  }
`;

export type SuggestionWithStatus = {
  startPosition: number;
  suggestion: EHRSuggestionFragment;
  status: "ACCEPTED" | "REJECTED" | "PROPOSED";
  speaker: "DOCTOR" | "PATIENT";
};

type LiveExtractionState = {
  suggestions: SuggestionWithStatus[];
  lastQuerySent:
    | {
        text: string;
        offset: number;
        speaker: "DOCTOR" | "PATIENT";
      }
    | undefined;
};

const itemsFromSameSpeakerInLastNItems = (
  items: TranscriptItem[],
  N: number,
) => {
  const speaker = items.at(-1)?.speaker;
  if (speaker === undefined) return [];
  return items.slice(-N).filter((it) => it.speaker === speaker);
};

const mergeChronologies = (
  oldChronology: SuggestionWithStatus[],
  newChronology: SuggestionWithStatus[],
) => {
  // First retrieve who is who.
  const newSuggestionToOldEquivalent = new Map();
  const oldSuggestionsWithNewEquivalent = new Set();
  oldChronology.forEach((oldSuggestion) => {
    newChronology.forEach((newSuggestion) => {
      const samePosition =
        oldSuggestion.startPosition === newSuggestion.startPosition;
      const closeToOldAccepted =
        oldSuggestion.status === "ACCEPTED" &&
        Math.abs(newSuggestion.startPosition - oldSuggestion.startPosition) <
          5 &&
        oldSuggestion.suggestion.__typename ===
          newSuggestion.suggestion.__typename;

      if (samePosition || closeToOldAccepted) {
        newSuggestionToOldEquivalent.set(newSuggestion, oldSuggestion);
        oldSuggestionsWithNewEquivalent.add(oldSuggestion);
      }
    });
  });

  // Then produce the new chronology
  const mergedChronology = newChronology.map((newSuggestion) => {
    const oldEquivalent = newSuggestionToOldEquivalent.get(newSuggestion);
    if (oldEquivalent) {
      return {
        ...oldEquivalent,
        suggestion: newSuggestion.suggestion,
      };
    }
    return newSuggestion;
  });
  // But keep the accepted ones even if they are gone.
  const previouslyAcceptedButGone = oldChronology.filter(
    (it) =>
      it.status === "ACCEPTED" && !oldSuggestionsWithNewEquivalent.has(it),
  );
  return mergedChronology
    .concat(previouslyAcceptedButGone)
    .sortAsc((it) => it.startPosition);
};

export const useLiveFactExtraction = (language: string) => {
  const [state, setState] = useState<LiveExtractionState>({
    suggestions: [],
    lastQuerySent: undefined,
  });
  const {
    available,
    transcriptItems,
    transcribing,
    startTranscribing,
    stopTranscribing,
  } = useLocalSpeechToText(getLanguageCode(language));

  const [extractFacts, { loading }] = useLazyQuery(
    ExtractFactsWithSuggestions,
    {
      onSuccess: (newData) => {
        const query = state.lastQuerySent!;
        const onlyNewSuggestions: SuggestionWithStatus[] =
          newData.suggestions.map((newSuggestion) => ({
            status: "PROPOSED",
            startPosition: newSuggestion.textStartPosition + query.offset,
            suggestion: newSuggestion.suggestion,
            speaker: query.speaker,
          }));

        const mergedChronology = mergeChronologies(
          state.suggestions,
          state.suggestions
            .filter((it) => it.startPosition < query.offset)
            .concat(onlyNewSuggestions),
        );
        setState((prev) => ({
          ...prev,
          suggestions: mergedChronology,
        }));
      },
    },
  );

  useEffect(() => {
    const lastItems = itemsFromSameSpeakerInLastNItems(transcriptItems, 2);
    const allPreviousItems = transcriptItems.slice(0, -lastItems.length);

    const newPotentialQueryText = lastItems.map((it) => it.text).join("");
    const previousText = allPreviousItems.map((it) => it.text).join("");
    if (
      !loading &&
      transcriptItems.length > 0 &&
      state.lastQuerySent?.text !== newPotentialQueryText
    ) {
      extractFacts({
        language,
        writtenAt: new Date().toISOString(),
        text: newPotentialQueryText,
      });
      setState((prev) => ({
        ...prev,
        lastQuerySent: {
          text: newPotentialQueryText,
          offset: previousText.length,
          speaker: transcriptItems.at(-1)!.speaker,
        },
      }));
    }
  }, [extractFacts, loading, transcriptItems, state, language]);

  return {
    available,
    suggestions: state.suggestions,
    transcriptItems,
    startTranscribing,
    stopTranscribing,
    transcribing,
    updateSuggestionStatus: (
      suggestion: SuggestionWithStatus,
      newStatus: SuggestionWithStatus["status"],
    ) => {
      const newSuggestions = state.suggestions.map((it) => {
        if (it.startPosition === suggestion.startPosition) {
          return { ...it, status: newStatus };
        }
        return it;
      });
      setState((prev) => ({
        ...prev,
        suggestions: newSuggestions,
      }));
    },
  };
};
