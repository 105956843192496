import { Maybe } from "base-types";
import { SignatureFragment } from "generated/provider";

export const getSignatureSrc = (signature: Maybe<SignatureFragment>) => {
  if (!signature) return undefined;
  if (signature.__typename === "SignatureSVG") {
    return signature.svg ?? undefined;
  }
  if (signature.__typename === "SignatureFile") {
    return signature.file?.urlV2.url;
  }
  return undefined;
};

export const hasSignature = (signature: Maybe<SignatureFragment>) =>
  signature &&
  ((signature.__typename === "SignatureSVG" && signature.svg) ||
    (signature.__typename === "SignatureFile" && signature.file));
