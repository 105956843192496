import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { FormState } from "components/Form/Form/FormState";
import { AsyncDoctorFormSelect } from "components/Form/Select/AsyncDoctorFormSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { InfoTooltip } from "components/Tooltip/InfoTooltip";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  CreateTask,
  DeleteTask,
  DoctorSummaryFragment,
  SetTaskStatus,
  TaskPriority,
  TaskPriorityKnownValues,
  UpdateTask,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { DistributiveOmit } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { displayTaskPriorityKnownValue } from "utils/display";
import { notifier } from "utils/notifier";

import { ExistingItemPillButton } from "../ItemPillButton";
import {
  PatientTimelineItem,
  PatientTimelineItemProps,
} from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";
import { usePatientTimelineLocalItemState } from "../PatientTimelineLocalStateContext";

type FormData = {
  title: string;
  description: string;
  priority: TaskPriority | undefined;
  assignedDoctor: DoctorSummaryFragment | undefined;
  isPatientTask: boolean;
};

type FormDraft = {
  title: string;
  description: string;
  priority: TaskPriority | null;
  assignedDoctor: DoctorSummaryFragment | null; // TODO(@liautaud): Store UUIDs instead.
  isPatientTask: boolean;
};

export const TaskItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"Task">;
}) => {
  const t = useTranslation();
  const [setTaskStatus, taskStatusLoading] = useMutation(SetTaskStatus, {
    onSuccess: (_, client) => client.evictQuery("taskSearch", "ALL"),
  });
  const [updateTask] = useMutation(UpdateTask, {
    onSuccess: (_, client) => client.evictQuery("taskSearch", "ALL"),
  });
  const [deleteTask] = useMutation(DeleteTask, {
    onSuccess: ({ taskUuid }, client) => client.remove("Task", taskUuid),
  });

  const taskUuid = item.uuid;
  const patientUuid = item.patientTimelineItemMetadata.patient.uuid;

  return (
    <DeletionConfirmationModal
      suffix={t("patient_view.task.delete_suffix")}
      onConfirm={async (closeDeletionModal) => {
        await deleteTask({ taskUuid });
        notifier.success(t("patient_view.task.delete_success"));
        closeDeletionModal();
      }}
    >
      {(openDeletionModal) => (
        <BaseTaskItem
          item={item}
          assignedDoctors={item.assignedDoctor ? [item.assignedDoctor] : []}
          actionButton={
            item.isCompleted
              ? {
                  label: t("patient_view.task.mark_todo_button"),
                  leftIcon: "future",
                  secondary: true,
                  loading: taskStatusLoading,
                  onClick: () =>
                    void setTaskStatus({ taskUuid, isCompleted: false }),
                  mode: "HOVER",
                }
              : {
                  label: item.isPatientTask
                    ? t("patient_view.task.mark_patient_done_button")
                    : t("patient_view.task.mark_done_button"),
                  leftIcon: "check",
                  loading: taskStatusLoading,
                  onClick: () =>
                    void setTaskStatus({ taskUuid, isCompleted: true }),
                  mode: "HOVER",
                }
          }
          additionalMenuItems={[
            {
              text: t("patient_view.item.delete"),
              icon: "trash",
              className: "text-danger",
              onClick: (closeMenu) => {
                closeMenu();
                openDeletionModal();
              },
            },
          ]}
          footerChildren={
            item.medicalOrder ? (
              <ExistingItemPillButton item={item} />
            ) : undefined
          }
          initialValues={{
            title:
              item.title.trimOrNull() ?? t("patient_view.default_title.task"),
            description: item.description ?? "",
            priority: item.priority ?? undefined,
            assignedDoctor: item.assignedDoctor ?? undefined,
            isPatientTask: item.isPatientTask,
          }}
          onSubmit={async ({
            title,
            description,
            priority,
            assignedDoctor,
            isPatientTask,
          }) => {
            await updateTask({
              input: {
                taskUuid,
                patientUuid,
                title,
                description,
                priority: priority ?? null,
                assignedDoctorUuid: isPatientTask
                  ? null
                  : assignedDoctor?.uuid ?? null,
                isPatientTask,
              },
            });
            notifier.success(t("patient_view.task.update_success"));
          }}
        />
      )}
    </DeletionConfirmationModal>
  );
};

export const NewTaskItem = ({
  temporaryUuid,
  medicalOrderUuid,
}: {
  temporaryUuid: UUID;
  medicalOrderUuid?: UUID;
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { expandOtherItem } = usePatientTimelineLocalItemState(temporaryUuid);

  const [createTask] = useMutation(CreateTask, {
    onSuccess: (_, client) => client.evictQuery("taskSearch", "ALL"),
  });

  return (
    <BaseTaskItem
      item={{ temporaryUuid, __typename: "Task" }}
      initialValues={{
        title: t("patient_view.default_title.task"),
        description: "",
        priority: undefined,
        assignedDoctor: undefined,
        isPatientTask: false,
      }}
      onSubmit={async ({
        title,
        description,
        priority,
        assignedDoctor,
        isPatientTask,
      }) => {
        const taskData = await createTask({
          input: {
            patientUuid: patient.uuid,
            title,
            description,
            priority: priority ?? null,
            assignedDoctorUuid: isPatientTask
              ? null
              : assignedDoctor?.uuid ?? null,
            isPatientTask,
            medicalOrderUuid: medicalOrderUuid ?? null,
          },
        });
        if (!taskData) return;

        notifier.success(t("patient_view.task.create_success"));
        expandOtherItem(taskData.task.uuid);
      }}
    />
  );
};

const BaseTaskItem = (
  props: DistributiveOmit<
    PatientTimelineItemProps<"Task", FormData, FormDraft>,
    "validationSchema" | "toDraft" | "fromDraft" | "children"
  >,
) => {
  const t = useTranslation();
  return (
    <PatientTimelineItem<"Task", FormData, FormDraft>
      validationSchema={{
        title: "required",
      }}
      toDraft={({
        title,
        description,
        priority,
        assignedDoctor,
        isPatientTask,
      }) => ({
        title,
        description,
        priority: priority ?? null,
        assignedDoctor: assignedDoctor ?? null,
        isPatientTask,
      })}
      fromDraft={({
        title,
        description,
        priority,
        assignedDoctor,
        isPatientTask,
      }) => ({
        title,
        description,
        priority: priority ?? undefined,
        assignedDoctor: assignedDoctor ?? undefined,
        isPatientTask,
      })}
      {...props}
    >
      <PatientTimelineItemContentWithPadding className="space-y-20">
        <FormTextArea
          name="description"
          label={t("tasks.task_composer.description")}
          placeholder={t("task_composer.add_a_description")}
          className="flex-fill justify-between h-full"
        />
        <FormSelect
          name="priority"
          isClearable
          wrapperClassName="flex-fill"
          options={TaskPriorityKnownValues}
          placeholder={t("tasks.task_composer.select")}
          label={t("tasks.task_composer.priority")}
          getOptionLabel={(s) => displayTaskPriorityKnownValue(s)}
        />
        <FormState<FormData>>
          {({ values, setFieldValue }) => (
            <>
              <AsyncDoctorFormSelect
                name="assignedDoctor"
                isClearable
                wrapperClassName="flex-fill"
                label={t("tasks.task_composer.assignee")}
                getVariables={(s) => ({
                  filter: { permissions: ["VIEW_TASKS"], freeTextSearch: s },
                })}
                disabled={values.isPatientTask}
              />
              <div className="flex items-center space-x-6">
                <FormCheckbox
                  name="isPatientTask"
                  label={t("tasks.task_composer.patient_task")}
                  onChange={(checked) => {
                    if (checked) setFieldValue("assignedDoctor", undefined);
                  }}
                />
                <InfoTooltip
                  label={t("tasks.task_composer.patient_task_tooltip")}
                  position="top"
                />
              </div>
            </>
          )}
        </FormState>
      </PatientTimelineItemContentWithPadding>
    </PatientTimelineItem>
  );
};
