import { CSSProperties, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Avatar } from "components/Avatar/Avatar";
import { NextPageButton } from "components/Button/NextPageButton";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import {
  GetGroupConversationPaginatedPatient,
  RemovePatientFromGroupConversation,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useTranslation } from "i18n";
import { displayPatient } from "utils/display";
import { notifier } from "utils/notifier";

import { AddPatientComposer } from "./AddPatientComposer";

gql`
  query GetGroupConversationPaginatedPatient($uuid: UUID!, $cursor: String) {
    experience(uuid: $uuid) {
      experience {
        uuid
        patients(page: { cursor: $cursor, numberOfItems: 20 }) {
          data {
            ...PatientSummary
          }
          hasMore
          nextCursor
        }
      }
    }
  }

  mutation RemovePatientFromGroupConversation(
    $experienceUuid: UUID!
    $patientUuid: UUID!
  ) {
    removePatientFromExperience(
      experienceUuid: $experienceUuid
      patientUuid: $patientUuid
    ) {
      experience {
        uuid
      }
    }
  }
`;

export const GroupConversationSideSection = ({
  className,
  style,
  experienceUuid,
}: {
  className?: string;
  style?: CSSProperties;
  experienceUuid: UUID;
}) => {
  const t = useTranslation();
  const { data, nextPage, fetchingMore } = usePaginatedQuery(
    GetGroupConversationPaginatedPatient,
    {
      variables: { uuid: experienceUuid },
      selector: (d) => d.experience.patients,
    },
  );

  const [showAddPatientComposer, setShowAddPatientComposer] = useState(false);
  const [removePatient] = useMutation(RemovePatientFromGroupConversation);

  return (
    <>
      {showAddPatientComposer && (
        <AddPatientComposer
          experienceUuid={experienceUuid}
          onClose={() => setShowAddPatientComposer(false)}
        />
      )}
      <div
        className={classNames("flex-col flex-fill bg-grey-100 p-16", className)}
        style={style}
      >
        <TopSafeArea />
        <div className="flex items-center justify-between text-18 mb-24">
          <span className="font-bold text-black">
            {t("view.community.group_conversation.participants")}
          </span>
          <ClickableIcon
            name="add"
            size={24}
            className="border rounded py-6 px-8"
            onClick={() => setShowAddPatientComposer(true)}
          />
        </div>
        <div className="flex-col gap-16">
          {data?.experience.patients.data.map((patient) => (
            <div key={patient.uuid} className="flex gap-12 items-center group">
              <Avatar size={24} user={patient} />
              <div className="text-16 flex-shrink font-medium text-black truncate mr-auto">
                {displayPatient(patient)}
              </div>
              <ConfirmationModal
                body={t(
                  "view.community.group_conversation.delete_patient_body",
                )}
                cta={{
                  label: t(
                    "view.community.group_conversation.delete_patient_cta",
                  ),
                  danger: true,
                }}
                title={t(
                  "view.community.group_conversation.delete_patient_title",
                  { patient: displayPatient(patient) },
                )}
                onConfirm={(hide) => {
                  removePatient(
                    {
                      experienceUuid,
                      patientUuid: patient.uuid,
                    },
                    {
                      refetchQueries: ["GetGroupConversationPaginatedPatient"],
                      onSuccess: () => {
                        notifier.success(
                          t(
                            "view.community.group_conversation.patient_deleted",
                          ),
                        );
                      },
                    },
                  ).then(() => hide());
                }}
              >
                {(show) => (
                  <ClickableIcon
                    className="invisible group-hover:visible p-6 rounded hover:bg-grey-200"
                    name="close"
                    onClick={() => show()}
                  />
                )}
              </ConfirmationModal>
            </div>
          ))}
        </div>
        <NextPageButton nextPage={nextPage} fetchingMore={fetchingMore} />
      </div>
    </>
  );
};
