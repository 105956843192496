/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import { FieldFunctionOptions, makeReference, Reference } from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

import { Maybe } from "base-types";
import { parseCacheId } from "graphql-client/parseCacheId";
import { GraphQLClient } from "types";
import { PatientTimelineItemMetadata } from "types/patient-timeline";

/**
 * Automatic cache updates for the patient timeline.
 *
 * The helpers in this file allow us to automatically re-sort the cached output
 * of the patient timeline queries whenever we detect any change to the cached
 * metadata of any type that can appear in the patient timeline.
 */
export const onBeforeItemMetadataCacheUpdate = (
  after: Maybe<NormalizedItemMetadataFragment>,
  { userContext, storageArgs, readField }: FieldFunctionOptions,
) => {
  const itemCacheId = storageArgs.at(-2)!;
  const itemUuid = parseCacheId(itemCacheId).uuid;

  // Cannot rely on the `incoming` value provided by the merge function as the
  // before state in the cache because the `incoming` value is sometimes null
  // even if the value already exists in the cache for some reason.
  const before = readField("patientTimelineItemMetadata", {
    __ref: itemCacheId,
  }) as Maybe<NormalizedItemMetadataFragment> | undefined;

  const beforeWithPatient = before
    ? { ...before, patientUuid: readField("uuid", before.patient) as UUID }
    : null;
  const afterWithPatient = after
    ? { ...after, patientUuid: readField("uuid", after.patient) as UUID }
    : null;

  if (
    beforeWithPatient?.time === afterWithPatient?.time &&
    beforeWithPatient?.status === afterWithPatient?.status &&
    beforeWithPatient?.patientUuid === afterWithPatient?.patientUuid
  ) {
    return;
  }

  userContext.pendingTimelineItemUpdates ??= [];
  (userContext.pendingTimelineItemUpdates as TimelineItemUpdate[]).push({
    itemCacheId,
    itemUuid,
    before: beforeWithPatient,
    after: afterWithPatient,
  });
};

export const onBeforeItemsQueryCacheUpdate = ({
  userContext,
}: FieldFunctionOptions) => {
  // When fetching `todoTimelineItems` or `doneTimelineItems`, Apollo will first
  // call `onBeforeItemMetadataCacheUpdate` on all the individual timeline items,
  // and then this function (all with a shared `userContext`). Since the backend
  // is already returning sorted items, it would be pointless and expensive to
  // sort the cached output of `todoTimelineItems` and `doneTimelineItems` again,
  // so we just ignore all the currently pending updates.
  delete userContext.pendingTimelineItemUpdates;
};

export const onAfterCacheUpdate = (
  client: GraphQLClient,
  userContext: { pendingTimelineItemUpdates?: TimelineItemUpdate[] },
) => {
  const updates = userContext.pendingTimelineItemUpdates;
  if (!updates) return;

  // Use cache.modify to directly update the normalized references.
  client.cache.modify({
    id: "ROOT_QUERY",
    fields: {
      // Called once for every to-do query currently in the cache.
      todoTimelineItems(value, { storeFieldName, readField }) {
        const { variables } = parseCacheKey(storeFieldName);
        if (!variables.patientUuid) return value;
        return {
          ...value,
          data: applyUpdates({
            type: "TODO",
            updates,
            prevItems: value.data,
            patientUuid: variables.patientUuid,
            readField,
          }),
        };
      },

      // Called once for every done query currently in the cache.
      doneTimelineItems(value, { storeFieldName, readField }) {
        const { variables } = parseCacheKey(storeFieldName);
        if (!variables.patientUuid) return value;
        return {
          ...value,
          data: applyUpdates({
            type: "DONE",
            updates,
            prevItems: value.data,
            patientUuid: variables.patientUuid,
            readField,
          }),
        };
      },
    },
  });
};

// ----- Helpers.

type NormalizedItemMetadataFragment = Omit<
  PatientTimelineItemMetadata,
  "patient"
> & {
  patient: { __ref: string };
};

type NormalizedItemMetadataFragmentWithPatientUuid = Omit<
  NormalizedItemMetadataFragment,
  "patient"
> & {
  patientUuid: UUID;
};

type TimelineItemUpdate = {
  itemCacheId: string;
  itemUuid: UUID;
  before: Maybe<NormalizedItemMetadataFragmentWithPatientUuid>;
  after: Maybe<NormalizedItemMetadataFragmentWithPatientUuid>;
};

const parseCacheKey = (
  storeFieldName: string,
): { field: string; variables: Record<string, any> } => {
  const [field, variables] = storeFieldName.split("(", 2);
  return {
    field,
    variables: variables ? JSON.parse(variables.slice(0, -1)) : {},
  };
};

const applyUpdates = ({
  type,
  patientUuid,
  updates,
  prevItems,
  readField,
}: {
  type: "TODO" | "DONE";
  patientUuid: UUID;
  updates: TimelineItemUpdate[];
  prevItems: Reference[];
  readField: ReadFieldFunction;
}): Reference[] => {
  const itemsToRemove = updates.filter(
    ({ before }) =>
      before?.status === type && before.patientUuid === patientUuid,
  );
  const itemsToAdd = updates.filter(
    ({ after }) => after?.status === type && after.patientUuid === patientUuid,
  );
  if (itemsToRemove.isEmpty() && itemsToAdd.isEmpty()) return prevItems;

  const idsToRemove = new Set(itemsToRemove.map((it) => it.itemCacheId));
  const newItems = prevItems.filter(({ __ref }) => !idsToRemove.has(__ref));
  const newItemsTimeAndUuids = newItems.map((it): [ISOString, UUID] => [
    // If the item is currently in the timeline, it has non-null metadata.
    readField("time", readField("patientTimelineItemMetadata", it))!,
    readField("uuid", it)!,
  ]);

  itemsToAdd.forEach((it) => {
    const index = newItemsTimeAndUuids.insertionIndex(
      [it.after!.time, it.itemUuid],

      // Sort by DESC time and then by UUID.
      ([aTime, aUuid], [bTime, bUuid]) =>
        bTime.getTime() - aTime.getTime() || aUuid.localeCompare(bUuid),
    );
    newItems.splice(index, 0, makeReference(it.itemCacheId));
  });

  return newItems;
};
