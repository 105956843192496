import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { ApiLogs } from "../../shared/Developers/ApiLogs/ApiLogs";
import { MlModels } from "../Settings/MlModels/MlModels";
import { DevGettingStarted } from "./DevGettingStarted/DevGettingStarted";
import { FactExtraction } from "./FactExtraction/FactExtraction";
import { SDKSetupGuide } from "./SDKSetupGuide/SDKSetupGuide";
import { ApiKeys } from "./ServerKeys/ApiKeys";
import { DoctorWebhookEvents } from "./WebhookEvents/DoctorWebhookEvents";
import { DoctorWebhooks } from "./Webhooks/DoctorWebhooks";

export const Developers = () => {
  const t = useTranslation();
  return (
    <SidebarPage
      baseRoute={routes.DEVELOPERS}
      title={t("developers.developers.developers")}
      items={[
        {
          to: routes.DEV_GETTING_STARTED,
          icon: "home",
          label: t(
            "developers.dev_getting_started.dev_getting_started.getting_started",
          ),
          Component: DevGettingStarted,
          hasAccess: ({ hasRole }) => hasRole("NABLA_ADMINISTRATOR"),
        },
        {
          to: routes.SETUP_GUIDE,
          icon: "mobile",
          label: t(
            "developers.sdk_setup_guide.sdk_setup_guide.sample_app_guide",
          ),
          Component: SDKSetupGuide,
          hasAccess: (accessor) => accessor.hasPermission("EDIT_API_KEYS"),
        },
        {
          to: routes.API_KEYS,
          icon: "lock",
          label: t("developers.server_keys.api_keys.api_keys"),
          Component: ApiKeys,
          hasAccess: (accessor) => accessor.hasPermission("VIEW_API_KEYS"),
        },
        {
          to: routes.API_LOGS,
          icon: "clipboard",
          label: t("developers.developers.api_logs"),
          Component: ApiLogs,
        },
        {
          to: routes.WEBHOOKS,
          icon: "uploadCloud",
          label: t("developers.developers.webhooks"),
          Component: DoctorWebhooks,
        },
        {
          to: routes.WEBHOOK_EVENTS,
          icon: "history",
          label: t("developers.developers.webhook_events"),
          Component: DoctorWebhookEvents,
        },
        {
          to: routes.ML_MODELS,
          icon: "machineLearning",
          label: t("developers.developers.machine_learning_models"),
          Component: MlModels,
        },
        {
          to: routes.MEDICAL_TEXT_STRUCTURATION,
          icon: "faceId",
          label: t("developers.developers.fact_extraction"),
          Component: FactExtraction,
        },
      ]}
      withRedirect
    />
  );
};
