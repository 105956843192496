import { Icon } from "components/Icon/Icon";
import { ThinSideBar } from "components/ThinSideBar/ThinSideBar";
import { useUser } from "contexts/User/UserContext";
import { useIsOnboardingModalVisible } from "hooks/useStorageState";
import { AccountMenuItem, MainSidebarItem } from "types";

export const MainSidebar = ({
  mainSidebarItems,
  accountMenuItems,
}: {
  mainSidebarItems: MainSidebarItem[];
  accountMenuItems: AccountMenuItem[];
}) => {
  const { showOnboardingModal, ...accessProps } = useUser();
  const selectedItems = mainSidebarItems
    .filter((item) => item.hasAccess(accessProps))
    .map((item) => ({
      ...item,
      useCount: () => item.useCount?.(accessProps),
    }));
  const setIsOnboardingModalVisible = useIsOnboardingModalVisible()[1];

  return accessProps.type === "DOCTOR" ||
    accessProps.type === "COPILOT_API_USER" ? (
    <ThinSideBar
      items={selectedItems}
      subOrganization={accessProps.user.subOrganization}
      accountMenuItems={accountMenuItems}
      bottomItems={
        <>
          {showOnboardingModal && (
            <button
              title="Welcome Guide"
              className="self-center sm:hidden flex-col sm:pl-12 lg:pt-8 flex-center text-sidebar-100"
              onClick={() => setIsOnboardingModalVisible(true)}
            >
              <Icon
                name="helpCircle"
                size={32}
                className="rounded-full hover:bg-white hover:bg-opacity-10"
              />
            </button>
          )}
        </>
      }
    />
  ) : null;
};
