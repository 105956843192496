import { useMemo } from "react";

import { ConversationContent } from "components/ConversationContent/ConversationContent";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { usePatientTimelineLocalState } from "components/Patient/Timeline/PatientTimelineLocalStateContext";
import { DoctorMessageActionsType } from "contexts/Experience/ExperienceContext";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { ExperienceResult } from "types";
import { copyToClipBoard, getQAInboxRoute } from "utils";

import { QAExperienceProvider } from "../../QAExperienceContext/QAExperienceProvider";
import { useQASidePanel } from "../../QASidePanelContext/QASidePanelContext";
import { SearchPage } from "./SearchPage";

export const Experience = ({
  experience,
  onContentSelected,
  back,
  search,
}: {
  experience: ExperienceResult;
  onContentSelected: ((value: string) => void) | null;
  back: () => void;
  search?: string;
}) => {
  const t = useTranslation();
  const { setIsTimelineOpen } = useQASidePanel();
  const { expandItem, setScrollToActivityDisabled } =
    usePatientTimelineLocalState();
  const contentSelectedRef = useSyncRef(onContentSelected);
  const DoctorMessageActions = useMemo(
    (): DoctorMessageActionsType =>
      ({ message }) => {
        const action = contentSelectedRef.current
          ? contentSelectedRef.current
          : (value: string) => {
              copyToClipBoard(
                value,
                t("inboxes.qa_side_panel.experience.message_copied"),
              );
            };
        const content = message.content;
        if (content.__typename !== "TextMessageContent") return null;
        return (
          <ClickableIcon
            name="copy"
            className="sticky top-0 bottom-0 invisible group-hover:visible"
            onClick={() => action(content.text)}
          />
        );
      },
    [contentSelectedRef, t],
  );

  return (
    <SearchPage
      title={experience.title ?? t("patient_view.default_title.experience")}
      rightTitleElement={
        <ClickableIcon
          name="expand"
          className="p-4"
          to={getQAInboxRoute(experience)}
        />
      }
      subtitle={experience.createdAt.format("date")}
      back={back}
      skipScrollContainer
    >
      <QAExperienceProvider
        uuid={experience.uuid}
        DoctorMessageActions={DoctorMessageActions}
        readonly
      >
        <ConversationContent
          search={search}
          scrollClassName="lg:pl-20 lg:pr-44"
          onCreatePatientTimelineItem={(itemUuid) => {
            setIsTimelineOpen(true);
            expandItem(itemUuid);
            setTimeout(() => {
              setScrollToActivityDisabled(false);
            }, 800);
          }}
        />
      </QAExperienceProvider>
      <BottomSafeArea />
    </SearchPage>
  );
};
