import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import {
  GetOrganizationPatientCustomFields,
  PatientFieldFragment,
  PersistOrganizationPatientCustomFields,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { usePreventUnload } from "hooks/usePreventUnload";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";
import { ephemeralUuidV4 } from "utils/stackoverflow";
import { slugify } from "utils/string";

gql`
  query GetOrganizationPatientCustomFields {
    me {
      doctor {
        uuid
        subOrganization {
          uuid
          patientFields {
            ...PatientField
          }
        }
      }
    }
  }

  mutation PersistOrganizationPatientCustomFields(
    $input: OrganizationSettingsInput!
  ) {
    updateOrganizationSettings(input: $input) {
      doctor {
        uuid
        subOrganization {
          uuid
          patientFields {
            ...PatientField
          }
        }
      }
    }
  }

  fragment PatientField on PatientField {
    uuid
    fieldKey
    displayName
  }
`;

type PatientCustomField = {
  uuid: string;
  displayName: string;
  fieldKey: string;
};

export const PatientCustomFields = () => {
  const t = useTranslation();
  const { data } = useQuery(GetOrganizationPatientCustomFields);
  const [isCreating, setIsCreating] = useState(false);
  usePreventUnload(isCreating);

  const [saveFields, loading] = useMutation(
    PersistOrganizationPatientCustomFields,
    {
      onSuccess() {
        notifier.success(t("settings.patient_custom_fields.updated"));
      },
    },
  );

  const saveOrganizationPatientCustomsFields = (
    allFields: PatientFieldFragment[],
    savedField: PatientCustomField,
  ) => {
    const isUpdate = allFields.some((f) => f.uuid === savedField.uuid);
    const input = {
      patientFields: allFields.map((existingField) => ({
        updatePatientField: {
          uuid: existingField.uuid,
          displayName:
            savedField.uuid === existingField.uuid
              ? savedField.displayName
              : existingField.displayName,
        },
      })),
    };

    if (isUpdate) return saveFields({ input });

    return saveFields({
      input: {
        patientFields: [
          {
            createPatientField: {
              fieldKey: savedField.fieldKey,
              displayName: savedField.displayName,
            },
          },
          ...input.patientFields,
        ],
      },
    });
  };

  const deleteOrganizationPatientCustomFields = (
    allFields: PatientFieldFragment[],
    deletedFieldUuid: string,
  ) => {
    const input = {
      patientFields: allFields
        .filter((f) => f.uuid !== deletedFieldUuid)
        .map((f) => ({
          updatePatientField: {
            uuid: f.uuid,
            displayName: f.displayName,
          },
        })),
    };
    return saveFields({ input });
  };

  return (
    <Background className="flex-fill flex-col overflow-auto p-16 lg:p-44">
      <div className="flex items-center space-x-16">
        <h1 className="flex-fill text-primary-dark text-24 font-bold">
          {t("settings.patient_custom_fields.title")}
        </h1>
        <Button
          label={t("settings.patient_custom_fields.new_button")}
          disabled={isCreating}
          onClick={() => setIsCreating(true)}
        />
      </div>
      <p>{t("settings.patient_custom_fields.subtitle")}</p>
      {isCreating && (
        <PatientCustomFieldForm
          isNew
          disableButtons={loading}
          patientCustomField={{
            uuid: ephemeralUuidV4(),
            displayName: "",
            fieldKey: "",
          }}
          onCreate={(savedField: PatientCustomField) =>
            saveOrganizationPatientCustomsFields(
              data?.doctor.subOrganization.patientFields ?? [],
              savedField,
            ).then((res) => {
              if (res) setIsCreating(false);
            })
          }
          onDelete={(_) => {
            setIsCreating(false);
            return Promise.resolve();
          }}
        />
      )}
      {data?.doctor.subOrganization.patientFields.map((field) => (
        <PatientCustomFieldForm
          isNew={false}
          disableButtons={loading}
          patientCustomField={{
            uuid: field.uuid,
            displayName: field.displayName,
            fieldKey: field.fieldKey,
          }}
          key={field.uuid}
          onCreate={async (savedField: PatientCustomField) => {
            await saveOrganizationPatientCustomsFields(
              data.doctor.subOrganization.patientFields,
              savedField,
            );
          }}
          onDelete={async (deletedFieldUuid: string) => {
            await deleteOrganizationPatientCustomFields(
              data.doctor.subOrganization.patientFields,
              deletedFieldUuid,
            );
          }}
        />
      ))}
    </Background>
  );
};

export const PatientCustomFieldForm = ({
  patientCustomField,
  isNew,
  disableButtons,
  onDelete,
  onCreate,
}: {
  patientCustomField: PatientCustomField;
  isNew: boolean;
  disableButtons: boolean;
  onDelete: (deletedFieldUuid: string) => Promise<void>;
  onCreate: (savedField: PatientCustomField) => Promise<void>;
}) => {
  const t = useTranslation();
  const [field] = useState(patientCustomField);
  const [fieldKeyTouched, setFieldKeyTouched] = useState(false);

  return (
    <Form<PatientCustomField>
      className="mt-24 flex-col space-y-24"
      initialValues={field}
      validationSchema={{ fieldKey: "required", displayName: "required" }}
      onSubmit={async ({ displayName, fieldKey }) => {
        await onCreate({
          uuid: patientCustomField.uuid,
          displayName,
          fieldKey,
        });
      }}
    >
      <FormState<PatientCustomField>>
        {({ setFieldValue, resetForm }) => (
          <div className="bg-white rounded border p-32 space-y-20 ">
            <div className="grid grid-cols-2 gap-x-24">
              <FormInput
                name="displayName"
                label={t("settings.patient_custom_fields.display_name")}
                minLength={1}
                required
                onInput={(event) => {
                  if (isNew && !fieldKeyTouched) {
                    const fieldKey = slugify(event.currentTarget.value, "_");
                    setFieldValue("fieldKey", fieldKey);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Escape") resetForm();
                }}
              />
              <FormInput
                name="fieldKey"
                label={
                  isNew
                    ? t("settings.patient_custom_fields.key_name_creation")
                    : t("settings.patient_custom_fields.key_name")
                }
                disabled={!isNew}
                minLength={1}
                required
                inlineError="left"
                validate={(fieldKey) => {
                  if (!/^[a-z]+([-_]?[a-z0-9])*$/u.test(fieldKey)) {
                    return t("settings.patient_custom_fields.field_key_format");
                  }
                }}
                onInput={() => setFieldKeyTouched(true)}
                onKeyDown={(e) => {
                  if (e.key === "Escape") resetForm();
                }}
              />
            </div>
            <div className="mt-20 flex justify-between">
              <DeletionConfirmationModal
                suffix={t(
                  "settings.patient_custom_fields.delete_confirmation",
                  {
                    title: patientCustomField.fieldKey,
                  },
                )}
                onConfirm={async (close) => {
                  await onDelete(patientCustomField.uuid);
                  close();
                }}
              >
                {(openConfirmationModal) => (
                  <Button
                    danger
                    secondary
                    disabled={disableButtons}
                    label={t("settings.patient_custom_fields.delete_button")}
                    onClick={() =>
                      isNew
                        ? onDelete(patientCustomField.uuid)
                        : openConfirmationModal()
                    }
                  />
                )}
              </DeletionConfirmationModal>
              <Submit
                requiresDirty
                disabled={disableButtons}
                label={t("settings.patient_custom_fields.save_button")}
              />
            </div>
          </div>
        )}
      </FormState>
    </Form>
  );
};
