import { Maybe } from "base-types";
import { staticT as t } from "i18n";

import { notifier } from "./notifier";

export const tryToOpenOrganizationExternalConsole = (
  url: Maybe<string>,
  patient: { externalId: Maybe<string> },
) => {
  if (!url) return;
  if (url.includes("{external_id}")) {
    if (patient.externalId) {
      window.open(url.replace("{external_id}", patient.externalId), "_blank");
    } else {
      notifier.error({ user: t("patients.navigation.externalid_is_missing") });
    }
  } else {
    window.open(url, "_blank");
  }
};

export const canOpenOrganizationExternalConsole = (
  url: Maybe<string>,
  patient: { externalId: Maybe<string> },
) => url && (!url.includes("{external_id}") || patient.externalId);
