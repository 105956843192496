import { useState } from "react";
import classNames from "classnames";

import { UploadInput } from "generated/provider";
import { FilePurpose, useUploadFile } from "graphql-client/useUploadFile";
import { useField } from "hooks/useField";
import { useTranslation } from "i18n";
import { localURL } from "utils/file";

import { CommonFormDropzoneProps, Dropzone } from "./Dropzone";

export type FormImageValue = {
  image: string | File | undefined | null;
  upload?: () => Promise<UploadInput | null>;
};

export const FormImage = ({
  name,
  disabled,
  className,
  purpose,
  ...props
}: Omit<
  CommonFormDropzoneProps,
  "label" | "accept" | "maxSize" | "children"
> & {
  purpose: FilePurpose;
}) => {
  const t = useTranslation();
  const [{ value, disabled: disabledField }, { error }, { setValue }] =
    useField<FormImageValue>({ name, disabled });
  const uploadFile = useUploadFile();
  const [progress, setProgress] = useState<number | null>(null);
  const label = value.image
    ? t("form.dropzone.form_avatar.change_image")
    : t("form.dropzone.form_avatar.add_image");
  const imageUrl = value.image
    ? typeof value.image === "string"
      ? value.image
      : localURL(value.image)
    : null;

  return (
    <Dropzone
      multiple={false}
      name={name}
      error={error}
      disabled={disabledField}
      accept="image/*"
      maxSize={5_000_000}
      className={classNames("w-auto", className)}
      {...props}
      onDrop={(files) => {
        setValue({
          image: files[0],
          upload: async () => {
            setProgress(0);
            return uploadFile({
              purpose,
              file: files[0],
              onProgress: setProgress,
            }).finally(() => setProgress(null));
          },
        });
      }}
    >
      {(isDragActive) => (
        <div className="flex items-center">
          {imageUrl && (
            <img
              src={imageUrl}
              className="w-[60px] h-[60px] object-contain mr-12"
              alt=""
            />
          )}
          <div className="border rounded px-12 py-10 flex items-center font-semibold">
            {isDragActive
              ? t("form.dropzone.form_avatar.release")
              : progress === null
              ? label
              : `${t("form.dropzone.form_avatar.upload")} ${progress}%`}
          </div>
        </div>
      )}
    </Dropzone>
  );
};
