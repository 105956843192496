import { Fragment, useEffect } from "react";
import classNames from "classnames";
import { To } from "history";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AvatarList } from "components/Avatar/AvatarList";
import { NextPageButton } from "components/Button/NextPageButton";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { ConversationLink } from "components/InboxRow/ConversationLink";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { TagPill } from "components/Tag/TagPill";
import { useDoctor } from "contexts/User/UserContext";
import { ExperienceTagFragment } from "generated/provider";
import {
  ExtendedQAInboxType,
  useExtendedQAInbox,
} from "hooks/useExtendedQAInbox";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { useTagsListOverflowing } from "../hooks/useTagsListOverflowing";

export const InboxSidebarContent = ({
  type,
  emptyLabel,
  selectedExperienceUuid,
  experienceTo,
}: {
  type: ExtendedQAInboxType;
  emptyLabel: string;
  selectedExperienceUuid: UUID | null;
  experienceTo: (uuid: UUID) => To;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { items, error, loading, ...utils } = useExtendedQAInbox(type);
  const { hasAccessToGatekeeperFeature } = useDoctor();

  useEffect(() => {
    if (!searchParams.has(routes.OPEN_FIRST_CONVERSATION)) return;
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(routes.OPEN_FIRST_CONVERSATION);
    if (selectedExperienceUuid) {
      setSearchParams(newSearchParams, { replace: true });
    } else if (items.isNotEmpty()) {
      navigate(
        `${routes.QA_INBOX}/${items[0].uuid}?${newSearchParams.toString()}`,
        {
          replace: true,
        },
      );
    }
    // Items loading or empty
  }, [items, searchParams, navigate, selectedExperienceUuid, setSearchParams]);

  return (
    <div className="flex-fill flex-col items-center overflow-auto">
      {items.isEmpty() ? (
        <div className="flex-fill flex-center">
          {loading ? (
            t("inboxes.inbox_sidebar_content.loading")
          ) : error ? (
            <ErrorPage error={error} />
          ) : (
            emptyLabel
          )}
        </div>
      ) : (
        items.map((item) => (
          <Fragment key={item.uuid}>
            <ConversationLink
              to={experienceTo(item.uuid)}
              experience={item}
              patient={item.patient!}
              highlighted={item.uuid === selectedExperienceUuid}
              hideAvatar
              getItemForSummary={(e) => e.lastHumanItem}
              getTime={(e) => e.lastModified}
              isInExperience={item.allDoctors.some((d) => d.uuid === user.uuid)}
            >
              <div className="mt-4 flex items-center">
                <Tags tags={item.tags} />
                {!hasAccessToGatekeeperFeature("MESSAGE_INBOX") && (
                  <div className="flex items-center space-x-6">
                    {item.isClosed ? (
                      <AvatarList users={item.allDoctors} size={22} />
                    ) : item.assignedDoctors.isNotEmpty() ? (
                      <AvatarList users={item.assignedDoctors} size={22} />
                    ) : null}
                  </div>
                )}
              </div>
            </ConversationLink>
            <div
              className="bg-grey-200 h-1 last:invisible"
              style={{ width: "87%" }}
            />
          </Fragment>
        ))
      )}
      <NextPageButton {...utils} />
      <BottomSafeArea />
    </div>
  );
};

const Tags = ({ tags }: { tags: ExperienceTagFragment[] }) => {
  const { tagsListRef, tagOverflowClassName } = useTagsListOverflowing(tags);

  return (
    <div
      ref={tagsListRef}
      className={classNames(
        "flex-fill flex space-x-4 overflow-x-auto",
        tagOverflowClassName,
      )}
    >
      {tags
        .filter((tag) => tag.status !== "DISMISSED")
        .sortAsc((tag) => tag.type.uuid)
        .map((tag) => (
          <TagPill
            key={tag.type.uuid}
            tag={tag.type}
            isMLTag={
              tag.taggedBy.__typename === "MLModelInput" &&
              tag.status === "SUGGESTION"
            }
          />
        ))}
    </div>
  );
};
