import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { useFormState } from "hooks/useFormState";
import { useTranslation } from "i18n";

import { Button, FormButtonProps } from "./Button";

export const Reset = ({
  warnOnDirty,
  ...rest
}: { warnOnDirty?: boolean } & Omit<FormButtonProps, "onClick">) => {
  const { resetForm, dirty } = useFormState();
  const t = useTranslation();
  return (
    <ConfirmationModal
      cta={{
        label: t("form.reset_confirm.label"),
        danger: true,
      }}
      body={
        <>
          <h3 className="title text-20 text-left">
            {t("form.reset_confirm.title")}
          </h3>
          <p className="text-left">{t("form.reset_confirm.subtitle")}</p>
        </>
      }
      onConfirm={(close) => {
        resetForm();
        close();
      }}
    >
      {(openConfirmationModal) => (
        <Button
          {...rest}
          onClick={() =>
            warnOnDirty && dirty ? openConfirmationModal() : resetForm()
          }
        />
      )}
    </ConfirmationModal>
  );
};
