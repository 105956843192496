import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Query } from "components/Query/Query";
import {
  GetSubOrganizationExternalConsoleUrl,
  UpdateSubOrganizationExternalConsoleUrl,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  query GetSubOrganizationExternalConsoleUrl {
    me {
      doctor {
        uuid
        subOrganization {
          uuid
          externalConsoleUrl
        }
      }
    }
  }

  mutation UpdateSubOrganizationExternalConsoleUrl($url: String) {
    updateOrganizationSettings(input: { externalConsoleUrl: { value: $url } }) {
      doctor {
        uuid
        subOrganization {
          uuid
          externalConsoleUrl
        }
      }
    }
  }
`;

export const LinkToBackOffice = () => {
  const t = useTranslation();
  const [updateSubOrganizationExternalConsoleUrl] = useMutation(
    UpdateSubOrganizationExternalConsoleUrl,
    {
      onSuccess: () => {
        notifier.success(t("settings.org_backoffice.url_updated"));
      },
    },
  );
  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44">
      <h1 className="text-primary-dark text-24 font-bold">
        {t("settings.org_backoffice.link_to_bo_title")}
      </h1>
      <div>{t("settings.org_backoffice.link_to_bo_description")}</div>
      <Query query={GetSubOrganizationExternalConsoleUrl}>
        {(data) => (
          <Form<{ url: string }>
            initialValues={{
              url: data.doctor.subOrganization.externalConsoleUrl ?? "",
            }}
            className="w-full mt-28"
            onSubmit={async (values) =>
              updateSubOrganizationExternalConsoleUrl({
                url: values.url.trimOrNull(),
              })
            }
          >
            <FormInput
              label={t("settings.org_backoffice.url_pattern")}
              name="url"
              placeholder="https://<your_backoffice.com>"
            />
            <div className="pt-8">
              <span>{t("settings.org_backoffice.url_helper")}</span>{" "}
              <a
                className="underline"
                href="https://docs.nabla.com/reference/the-patient-object"
                target="_blank"
                rel="noreferrer"
              >
                {t("settings.org_backooffice.external_id_documentation")}
              </a>
            </div>
            <Submit
              className="mt-20"
              label={t("settings.org_backoffice.submit_label")}
            />
          </Form>
        )}
      </Query>
    </Background>
  );
};
