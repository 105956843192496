import { ChangeEventHandler, useState } from "react";
import { Link } from "react-router-dom";

import { Audio } from "components/Audio/Audio";
import { Submit } from "components/Button/Submit";
import { ButtonGroup } from "components/Form/ButtonGroup/ButtonGroup";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { Label } from "components/Form/Label/Label";
import { Switch } from "components/Form/Switch/Switch";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Icon } from "components/Icon/Icon";
import {
  CreateNoteAnnotation,
  FileUploadFragment,
  NoteAnnotationFragment,
  RecordedConversationFragment,
  UpdateNoteAnnotation,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useRerender } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { NoteAnnotationInput } from "types";
import { run } from "utils";
import { language, setLanguage } from "utils/intl";

import {
  AudioSpeed,
  AudioSpeeds,
} from "../RecordedConversation/TranscriptAnnotation";
import { useNoteAnnotationDraft } from "./useNoteAnnotationDraft";

export const NoteAnnotationComposer = ({
  recordedConversationUuid,
  audios,
  showThankYou,
  currentNote,
}: {
  recordedConversationUuid: UUID;
  audios: RecordedConversationFragment["audios"];
  showThankYou: () => void;
  currentNote?: NoteAnnotationFragment;
}) => {
  const [createNoteAnnotation, creating] = useMutation(CreateNoteAnnotation, {
    onSuccess: (_, client) =>
      client.evict({
        entityName: "RecordedConversation",
        uuid: recordedConversationUuid,
        field: "annotatedNotes",
      }),
  });
  const [updateNoteAnnotation, updating] = useMutation(UpdateNoteAnnotation);

  const t = useTranslation();

  const {
    setNoteAnnotation,
    noteAnnotationInputWithDefaults,
    removeNoteAnnotation,
  } = useNoteAnnotationDraft(recordedConversationUuid, currentNote);
  const rerender = useRerender();

  return (
    <>
      <div className="flex items-center space-x-20">
        <Link to={`/${routes.NOTE_ANNOTATION}/${recordedConversationUuid}`}>
          <Icon name="arrow" />
        </Link>
        <h1 className="text-primary-dark text-24 font-bold">
          {t("note_annotation.note_header")}
        </h1>
      </div>
      <Switch
        label="En"
        checked={language === "en"}
        onChange={(checked) => {
          setLanguage(checked ? "en" : "fr");
          rerender();
        }}
      />
      <Label label={t("note_annotation.the_audios")} useDiv />
      <div className="flex items-center overflow-hidden grid grid-cols-4 gap-10">
        {audios.map((a) => (
          <AudioPlayer key={a.uuid} audio={a} />
        ))}
      </div>
      <div className="flex-col flex-fill p-20 space-y-20 overflow-auto ">
        <Form<NoteAnnotationInput>
          initialValues={noteAnnotationInputWithDefaults}
          onSubmit={({
            chiefComplaint,
            allergies,
            assessment,
            appointments,
            familyHistory,
            historyIllness,
            labResults,
            imagingResults,
            medicalHistory,
            medications,
            physicalExam,
            plan,
            prescription,
            surgicalHistory,
            symptoms,
            vaccines,
            vitals,
            diagnostic,
            socialHistory,
          }) => {
            const input = {
              chiefComplaint:
                chiefComplaint.trimOrNull() ??
                t("note_annotation.chief_complaint_not_mentioned"),
              allergies:
                allergies.trimOrNull() ??
                t("note_annotation.no_mentioned_allergy"),
              assessment:
                assessment.trimOrNull() ??
                t("note_annotation.no_mentioned_assessment"),
              appointments:
                appointments.trimOrNull() ??
                t("note_annotation.no_mentioned_appointment"),
              familyHistory:
                familyHistory.trimOrNull() ??
                t("note_annotation.no_fam_history_mentioned"),
              historyIllness:
                historyIllness.trimOrNull() ??
                t("note_annotation.no_mentioned_history_illness"),
              labResults:
                labResults.trimOrNull() ??
                t("note_annotation.no_lab_result_mentioned"),
              imagingResults:
                imagingResults.trimOrNull() ??
                t("note_annotation.no_imaging_result_mentioned"),
              medicalHistory:
                medicalHistory.trimOrNull() ??
                t("note_annotation.no_medical_history_mentioned"),
              medications:
                medications.trimOrNull() ??
                t("note_annotation.no_medication_mentioned"),
              physicalExam:
                physicalExam.trimOrNull() ??
                t("note_annotation.no_physical_exam_mentioned"),
              plan: plan.trimOrNull() ?? t("note_annotation.no_plan_mentioned"),
              prescription:
                prescription.trimOrNull() ??
                t("note_annotation.no_prescription_mentioned"),
              surgicalHistory:
                surgicalHistory.trimOrNull() ??
                t("note_annotation.no_surgical_history_mentioned"),
              symptoms:
                symptoms.trimOrNull() ??
                t("note_annotation.no_symptom_mentioned"),
              vaccines:
                vaccines.trimOrNull() ??
                t("note_annotation.no_vaccine_mentioned"),
              vitals:
                vitals.trimOrNull() ?? t("note_annotation.no_vitals_mentioned"),
              diagnostic:
                diagnostic.trimOrNull() ??
                t("note_annotation.no_diagnostic_mentioend"),
              socialHistory:
                socialHistory.trimOrNull() ??
                t("note_annotation.no_social_history_mentioned"),
            };
            currentNote
              ? updateNoteAnnotation({
                  uuid: currentNote.uuid,
                  input,
                })
              : createNoteAnnotation({
                  recordedConversationUuid,
                  input,
                });
            removeNoteAnnotation();
            showThankYou();
          }}
        >
          <div className="flex items-center justify-between my-10">
            <h1 className="text-primary-dark text-24 font-bold">
              {t("note_annotation.write_note_header")}
            </h1>
            <FormState<NoteAnnotationInput>>
              {({ values }) => (
                <Submit
                  loading={creating || updating}
                  label={
                    currentNote
                      ? t("note_annotation.update_note")
                      : t("note_annotation.create_note")
                  }
                  className="mt-10"
                  disabled={
                    currentNote &&
                    currentNote.chiefComplaint === values.chiefComplaint &&
                    currentNote.allergies === values.allergies &&
                    currentNote.assessment === values.assessment &&
                    currentNote.appointments === values.appointments &&
                    currentNote.familyHistory === values.familyHistory &&
                    currentNote.historyIllness === values.historyIllness &&
                    currentNote.imagingResults === values.imagingResults &&
                    currentNote.labResults === values.labResults &&
                    currentNote.medicalHistory === values.medicalHistory &&
                    currentNote.medications === values.medications &&
                    currentNote.physicalExam === values.physicalExam &&
                    currentNote.plan === values.plan &&
                    currentNote.prescription === values.prescription &&
                    currentNote.surgicalHistory === values.surgicalHistory &&
                    currentNote.symptoms === values.symptoms &&
                    currentNote.vaccines === values.vaccines &&
                    currentNote.vitals === values.vitals &&
                    currentNote.diagnostic === values.diagnostic &&
                    currentNote.socialHistory === values.socialHistory
                  }
                />
              )}
            </FormState>
          </div>
          <div className="rounded bg-white p-10 border flex items-start flex-fill">
            <div className="flex-col flex-fill">
              <NoteAnnotationField
                name="chiefComplaint"
                label={t("note_annotation.chief_complaint")}
                onChange={(e) =>
                  setNoteAnnotation({ chiefComplaint: e.target.value })
                }
                placeholder={t("note_annotation.chief_complaint_not_mentioned")}
              />
              <NoteAnnotationField
                name="symptoms"
                label={t("note_annotation.symptoms")}
                onChange={(e) =>
                  setNoteAnnotation({ symptoms: e.target.value })
                }
                placeholder={t("note_annotation.no_symptom_mentioned")}
              />
              <NoteAnnotationField
                name="historyIllness"
                label={t("note_annotation.history_illness")}
                onChange={(e) =>
                  setNoteAnnotation({ historyIllness: e.target.value })
                }
                placeholder={t("note_annotation.no_mentioned_history_illness")}
              />
              <NoteAnnotationField
                name="medicalHistory"
                label={t("note_annotation.medical_history")}
                onChange={(e) =>
                  setNoteAnnotation({ medicalHistory: e.target.value })
                }
                placeholder={t("note_annotation.no_medical_history_mentioned")}
              />
              <NoteAnnotationField
                name="socialHistory"
                label={t("note_annotation.social_history")}
                onChange={(e) =>
                  setNoteAnnotation({ socialHistory: e.target.value })
                }
                placeholder={t("note_annotation.no_social_history_mentioned")}
              />
              <NoteAnnotationField
                name="familyHistory"
                label={t("note_annotation.family_history")}
                onChange={(e) =>
                  setNoteAnnotation({ familyHistory: e.target.value })
                }
                placeholder={t("note_annotation.no_fam_history_mentioned")}
              />
              <NoteAnnotationField
                name="surgicalHistory"
                label={t("note_annotation.surgical_history")}
                onChange={(e) =>
                  setNoteAnnotation({ surgicalHistory: e.target.value })
                }
                placeholder={t("note_annotation.no_surgical_history_mentioned")}
              />
            </div>
            <div className="flex-col flex-fill">
              <NoteAnnotationField
                name="medications"
                label={t("note_annotation.medications")}
                onChange={(e) =>
                  setNoteAnnotation({ medications: e.target.value })
                }
                placeholder={t("note_annotation.no_medication_mentioned")}
              />
              <NoteAnnotationField
                name="allergies"
                label={t("note_annotation.allergies")}
                onChange={(e) =>
                  setNoteAnnotation({ allergies: e.target.value })
                }
                placeholder={t("note_annotation.no_mentioned_allergy")}
              />
              <NoteAnnotationField
                name="vitals"
                label={t("note_annotation.vitals")}
                onChange={(e) => setNoteAnnotation({ vitals: e.target.value })}
                placeholder={t("note_annotation.no_symptom_mentioned")}
              />
              <NoteAnnotationField
                name="vaccines"
                label={t("note_annotation.vaccines")}
                onChange={(e) =>
                  setNoteAnnotation({ vaccines: e.target.value })
                }
                placeholder={t("note_annotation.no_vaccine_mentioned")}
              />
              <NoteAnnotationField
                name="physicalExam"
                label={t("note_annotation.physical_exam")}
                onChange={(e) =>
                  setNoteAnnotation({ physicalExam: e.target.value })
                }
                placeholder={t("note_annotation.no_physical_exam_mentioned")}
              />
              <NoteAnnotationField
                name="labResults"
                label={t("note_annotation.lab_results")}
                onChange={(e) =>
                  setNoteAnnotation({ labResults: e.target.value })
                }
                placeholder={t("note_annotation.no_lab_result_mentioned")}
              />
              <NoteAnnotationField
                name="imagingResults"
                label={t("note_annotation.imaging_results")}
                onChange={(e) =>
                  setNoteAnnotation({ imagingResults: e.target.value })
                }
                placeholder={t("note_annotation.no_imaging_result_mentioned")}
              />
            </div>
            <div className="flex-col flex-fill">
              <NoteAnnotationField
                name="assessment"
                label={t("note_annotation.assessment")}
                onChange={(e) =>
                  setNoteAnnotation({ assessment: e.target.value })
                }
                placeholder={t("note_annotation.no_mentioned_assessment")}
              />
              <NoteAnnotationField
                name="plan"
                label={t("note_annotation.plan")}
                onChange={(e) => setNoteAnnotation({ plan: e.target.value })}
                placeholder={t("note_annotation.no_plan_mentioned")}
              />
              <NoteAnnotationField
                name="prescription"
                label={t("note_annotation.prescription")}
                onChange={(e) =>
                  setNoteAnnotation({ prescription: e.target.value })
                }
                placeholder={t("note_annotation.no_prescription_mentioned")}
              />
              <NoteAnnotationField
                name="appointments"
                label={t("note_annotation.appointments")}
                onChange={(e) =>
                  setNoteAnnotation({ appointments: e.target.value })
                }
                placeholder={t("note_annotation.no_mentioned_appointment")}
              />
              <NoteAnnotationField
                name="diagnostic"
                label={t("note_annotation.diagnostic")}
                onChange={(e) =>
                  setNoteAnnotation({ diagnostic: e.target.value })
                }
                placeholder={t("note_annotation.no_diagnostic_mentioend")}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

const NoteAnnotationField = ({
  name,
  label,
  onChange,
  placeholder,
}: {
  name: string;
  label: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
}) => (
  <FormTextArea
    name={name}
    label={label}
    className="border-0 py-0 text-body"
    wrapperClassName="text-primary"
    minRows={1}
    onChange={onChange}
    placeholder={placeholder}
  />
);

const AudioPlayer = ({ audio }: { audio: FileUploadFragment }) => {
  const [audioSpeed, setAudioSpeed] = useState<AudioSpeed>("Normal");
  const playBackRate = run(() => {
    switch (audioSpeed) {
      case "Slow":
        return 0.75;
      case "Normal":
        return 1.0;
      case "Fast":
        return 1.25;
    }
  });

  return (
    <div className="flex-col space-y-10">
      <ButtonGroup
        wrapperClassName="w-auto"
        buttonClassName="h-44"
        options={AudioSpeeds}
        getOptionLabel={(o) => o}
        value={audioSpeed}
        onChange={(newValue) => setAudioSpeed(newValue)}
      />
      <Audio
        className="bg-white"
        src={audio.urlV2.url}
        durationHint={
          audio.metadata.__typename === "AudioMetadata"
            ? audio.metadata.durationMs?.let((duration) => duration / 1000)
            : undefined
        }
        playbackRate={playBackRate}
      />
    </div>
  );
};
