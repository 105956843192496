import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { Redirect } from "components/Routes/Redirect";
import { Separator } from "components/Separator/Separator";
import { useDoctor } from "contexts/User/UserContext";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { StatsDashboardHistory } from "./History";
import { StatsDashboardLive } from "./Live";
import { StatsDashboardSubOrg } from "./SubOrg";

export const StatsDashboard = () => {
  const t = useTranslation();
  const { hasAccessToOrganizationUserApi, hasAccessToGatekeeperFeature } =
    useDoctor();
  const hasAccessToSubOrgStats =
    hasAccessToGatekeeperFeature("SPECIAL_SUB_ORG_STATS") &&
    hasAccessToOrganizationUserApi;

  const queryParam = useMatch(
    `${routes.STATS}/:tab*`,
  )?.params.tab.toUpperCase();

  const tab =
    queryParam && ["HISTORY", "LIVE", "SUBORG"].includes(queryParam)
      ? queryParam
      : "UNKNOWN";

  if (tab === "UNKNOWN") return <Redirect to={routes.STATS_HISTORY} />;
  if (tab === "SUBORG" && !hasAccessToSubOrgStats) {
    return <Redirect to={routes.STATS_HISTORY} />;
  }

  return (
    <Background className="flex-fill flex-col overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold  pt-16 lg:pt-24">
          {t("stats_dashboard.stats_dashboard.analytics")}
        </h1>

        <div className="flex justify-between">
          <div className="flex">
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  tab === "HISTORY",
              })}
              to={routes.STATS_HISTORY}
            >
              {t("stats_dashboard.tabs.history")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  tab === "LIVE",
              })}
              to={routes.STATS_LIVE}
            >
              {t("stats_dashboard.tabs.live")}
            </Link>
            {hasAccessToSubOrgStats && (
              <Link
                className={classNames("mr-24 hover:cursor-pointer py-16", {
                  "text-primary font-semibold border-b-2 border-primary":
                    tab === "SUBORG",
                })}
                to={routes.STATS_SUBORG}
              >
                {t("stats_dashboard.tabs.suborg")}
              </Link>
            )}
          </div>
        </div>
      </div>
      <Separator />
      <div className="flex-col p-16 lg:px-44">
        {tab === "HISTORY" && <StatsDashboardHistory />}
        {tab === "LIVE" && <StatsDashboardLive />}
        {hasAccessToSubOrgStats && tab === "SUBORG" && <StatsDashboardSubOrg />}
      </div>
    </Background>
  );
};
