import { useState } from "react";
import classNames from "classnames";

import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { useUser } from "contexts/User/UserContext";
import {
  CustomizeExperience,
  ExperienceSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

type Props = Pick<ExperienceSummaryFragment, "uuid" | "title"> & {
  onTitleUpdate?: (newTitle: string) => void;
};

export const QAExperienceName = ({ title, uuid, onTitleUpdate }: Props) => {
  const t = useTranslation();
  const [edit, setEdit] = useState(false);
  const { hasPermission } = useUser();
  const [customizeExperience] = useMutation(CustomizeExperience, {
    onSuccess: (result) => {
      setEdit(false);
      onTitleUpdate && result.experience.title?.let(onTitleUpdate);
    },
  });

  return (
    <div className="flex items-center h-32 leading-normal flex-fill">
      {edit ? (
        <Form<{ title: string }>
          initialValues={{ title: title ?? "" }}
          className="flex items-center w-full"
          onSubmit={({ title: newTitle }) =>
            customizeExperience({ uuid, input: { title: newTitle } })
          }
        >
          <FormInput
            name="title"
            wrapperClassName="flex-fill"
            className="py-4 px-8 -ml-[3px] !h-auto font-medium"
            thinGray
            autoFocus
          />
          <ClickableIcon
            name="check"
            size={20}
            type="submit"
            onClick={(e) => e.stopPropagation()}
          />
          <ClickableIcon
            name="close"
            className="pl-0"
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              setEdit(false);
            }}
          />
        </Form>
      ) : (
        <button
          className="flex max-w-full items-center space-x-8 py-4 px-8 -ml-2 group hover:visible hover:bg-grey-100 rounded"
          onClick={(e) => {
            e.stopPropagation();
            setEdit(true);
          }}
          disabled={!hasPermission("EDIT_PATIENT_EXPERIENCE")}
        >
          <div
            className={classNames(
              "items-center font-medium truncate flex-fill",
              {
                "text-primary-dark": title,
              },
            )}
          >
            {title?.trimOrNull() ?? t("patient_view.default_title.experience")}
          </div>
          <Icon name="edit" className="invisible group-hover:visible" />
        </button>
      )}
    </div>
  );
};
