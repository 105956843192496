import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useTranslation } from "i18n";

export const CallButton = ({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) => {
  const t = useTranslation();

  return (
    <TooltipWrapper
      label={t("call_button.call_button.make_a_video_call")}
      position="left"
      className={className}
    >
      <ClickableIcon
        name="videoOn"
        className="text-primary"
        onClick={() => onClick()}
      />
    </TooltipWrapper>
  );
};
