import { enUS, fr } from "date-fns/locale";
import { DateRange, Range } from "react-date-range";

import { useUser } from "contexts/User/UserContext";
import { colors } from "styles";

import "./reactDateRangeOverrides.css";

export const DateRangeSelector = ({
  value,
  onChange,
}: {
  value: Range;
  onChange: (range: Range) => void;
}) => {
  const { user, isDesktop } = useUser();
  const calendarStartDayIndex =
    user?.subOrganization.calendarStartOfWeek === "MONDAY" ? 1 : 0;

  const locale = user?.locale === "ENGLISH" ? enUS : fr;

  return (
    <DateRange
      ranges={[
        {
          ...value,
          startDate: value.startDate ?? new Date(),
          endDate: value.endDate ?? new Date(),
        },
      ]}
      onChange={(v) => onChange(v.selection)}
      editableDateInputs
      direction="horizontal"
      months={isDesktop ? 2 : 1}
      weekStartsOn={calendarStartDayIndex}
      locale={locale}
      rangeColors={[colors.primary]}
    />
  );
};
