import { Maybe } from "base-types";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { useFocusAfterAnimation } from "hooks";
import { useTranslation } from "i18n";

export type TemplateFormValues = { title: string; content: Maybe<string> };

export const TemplateForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues: TemplateFormValues;
  onSubmit: (values: TemplateFormValues) => Promise<any>;
}) => {
  const t = useTranslation();
  const inputRef = useFocusAfterAnimation();

  return (
    <Form<TemplateFormValues>
      initialValues={initialValues}
      validationSchema={{ content: "required", title: "required" }}
      onSubmit={(values) => onSubmit(values)}
      className="w-full flex-fill flex-col space-y-24"
    >
      <FormInput
        inputRef={inputRef}
        name="title"
        label={t("template_forms.template_form.title")}
        placeholder={t("template_forms.template_form.enter_a_title")}
      />
      <FormTextArea
        name="content"
        wrapperClassName="flex-fill"
        className="flex-fill"
        label={t("template_forms.template_form.content")}
        placeholder={t("template_forms.template_form.content")}
      />
      <Submit
        className="mt-36"
        label={t("template_forms.template_form.save")}
      />
    </Form>
  );
};
