import { AppFlavor } from "../platform";

// TODO delete with care-platform clean
const WEBHOOK_EVENT_TYPES_CP = [
  "appointment.created",
  "appointment.finalized",
  "appointment.cancelled",
  "conversation.created",
  "conversation.reopened",
  "conversation.closed",
  "conversation.locked",
  "conversation.unlocked",
  "conversation.message.created",
  "live_audio.transcript_item.created",
  "live_audio.transcript_item.updated",
  "live_audio.fact_extraction.created",
  "task.created",
  "task.updated",
  "task.deleted",
  "medical_order.created",
  "medical_order.updated",
  "medical_order.deleted",
];
const WEBHOOK_EVENT_TYPES = [
  "generate_note.succeeded",
  "generate_note.failed",
  "transcribe.succeeded",
  "transcribe.failed",
];

export const webhookEventTypes = (flavor: AppFlavor): string[] => {
  switch (flavor) {
    case "CARE_PLATFORM":
      return WEBHOOK_EVENT_TYPES_CP;
    case "COPILOT_API":
      return WEBHOOK_EVENT_TYPES;
  }
};
