import { Button } from "components/Button/Button";
import { useLoadedExperience } from "contexts/Experience/ExperienceContext";
import { useLivekitRoom } from "contexts/LivekitRoom/LivekitRoomContext";
import { LivekitRoomFragment } from "generated/provider";
import { useTranslation } from "i18n";

import { EventMessage } from "./ActionRequestMessage";

export const LivekitRoomMessage = ({
  livekitRoom: { uuid, status },
}: {
  livekitRoom: LivekitRoomFragment;
}) => {
  const t = useTranslation();
  const { joinRoom } = useLivekitRoom();

  const { experience } = useLoadedExperience();
  const expectedParticipants = experience.patient
    ? [experience.patient]
    : experience.allDoctors;

  return (
    <div className="flex-col">
      <EventMessage
        label={
          experience.patient
            ? t(
                "conversation_content.timeline.message_container.livekit_room_message.video_consultation",
              )
            : t(
                "conversation_content.timeline.message_container.livekit_room_message.video_call",
              )
        }
        icon="videoOn"
      />

      {/* TODO(@liautaud): Doesn't match the Figma spec. */}
      {status.__typename === "LivekitRoomOpenStatus" && (
        <Button
          label={t(
            "conversation_content.timeline.message_container.livekit_room_message.join",
          )}
          onClick={() =>
            joinRoom({
              room: {
                uuid,
                url: status.url,
                token: status.token,
                expectedParticipants,
              },
              endScreen: undefined,
            })
          }
        />
      )}
      {status.__typename === "LivekitRoomClosedStatus" && (
        <Button
          label={t(
            "conversation_content.timeline.message_container.livekit_room_message.finished",
          )}
          disabled
        />
      )}
    </div>
  );
};
