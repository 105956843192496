import {
  AudioRecorder,
  AudioRecorderProps,
} from "components/AudioRecorder/AudioRecorder";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useTranslation } from "i18n";

export const AudioMessageRecorder = (
  props: Omit<AudioRecorderProps, "onSubmit" | "submitLabel">,
) => {
  const t = useTranslation();
  const { mediaMessages, setMediaMessages } = useExperienceDraft();

  return (
    <AudioRecorder
      submitLabel={t("composer_parts.audio_message_recorder.done")}
      onSubmit={(file) => setMediaMessages(mediaMessages.concat({ file }))}
      allowScrolling
      {...props}
    />
  );
};
