import classNames from "classnames";
import gql from "graphql-tag";

import { AvatarList } from "components/Avatar/AvatarList";
import { DoctorsListPopover } from "components/Doctor/DoctorsListPopover";
import { Query } from "components/Query/Query";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDoctor } from "contexts/User/UserContext";
import { ConnectedDoctors, DoctorSummaryFragment } from "generated/provider";
import { useGraphQLClient } from "graphql-client/GraphQLClientContext";
import { useTargetState } from "hooks";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";

gql`
  query ConnectedDoctors {
    doctorSearch(filter: { isConnected: true }) {
      totalCount
      doctors(pagination: { numberOfItems: -1 }) {
        ...DoctorSummary
      }
    }
  }
`;

export const Footer = () => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const { isSocketReady } = useGraphQLClient();
  const [target, setTarget] = useTargetState();
  const { user } = useDoctor();
  const isNotSelfDoctor = (doctor: DoctorSummaryFragment) =>
    user.uuid !== doctor.uuid;

  if (!isDesktop) return null;

  return (
    <div
      className="px-24 flex items-center justify-between bg-white border-t relative cursor-pointer hover:bg-grey-100 active:bg-white"
      style={{ height: 64 }}
      onClick={setTarget}
    >
      <Query query={ConnectedDoctors} pollInterval={3 * 60_000}>
        {({ doctors }) => (
          <>
            {doctors.count(isNotSelfDoctor) ? (
              <AvatarList
                users={doctors.filter(isNotSelfDoctor)}
                totalCount={doctors.count(isNotSelfDoctor)}
                word={t("footer.other_practitioner", {
                  count: doctors.count(isNotSelfDoctor),
                })}
                extra={t("doctors_conversation.footer.online")}
              />
            ) : (
              <span>{t("footer.no_other_practitioner_online")}</span>
            )}
            {target && (
              <DoctorsListPopover
                target={target}
                doctors={doctors.filter(isNotSelfDoctor)}
                position="top-left"
                className="-mb-4 ml-24"
                onClose={() => setTarget(undefined)}
              />
            )}
          </>
        )}
      </Query>
      <TooltipWrapper
        className="ml-12"
        position="top"
        // TODO: make sure the message is not hidden behind the chat
        label={
          isSocketReady
            ? t("footer.you_are_connected_to_the_service")
            : t("footer.the_connection_seems_to_be_faulty")
        }
      >
        <div
          className={classNames(
            "rounded-full w-8 h-8",
            isSocketReady ? "bg-success" : "bg-danger",
          )}
        />
      </TooltipWrapper>
    </div>
  );
};
