import { z } from "zod";

import { SchemaType } from "base-types";

import {
  AnyEndpointDefinition,
  AnyGqlEndpointDefinition,
  AnySocketEndpointDefinition,
} from "./types";

// ------ REST endpoints.

const jwtRefreshDefinition = {
  path: "jwt/refresh",
  requestDataSchema: z.object({
    refresh_token: z.string(),
  }),
  responseSchema: z.object({
    access_token: z.string(),
    refresh_token: z.string(),
  }),
};

const jwtLogoutDefinition = {
  path: "jwt/logout",
  requestDataSchema: z.object({
    refresh_token: z.string(),
  }),
  responseSchema: z.object({}),
};

/**
 * REST endpoints exposed by the backend.
 * Keep in sync with the OpenAPI files in `documentation/api`.
 */
export const REST_ENDPOINTS = {
  ACCOUNT_LOGIN: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    path: "jwt/login",
    requestDataSchema: z.object({
      email: z.string(),
      password: z.string(),
      otp: z.string().optional(),
    }),
    responseSchema: z.object({
      access_token: z.string().nullable(),
      refresh_token: z.string().nullable(),
      mfa_required: z.boolean().nullable(),
      is_totp_mfa_available: z.boolean().nullable(),
      mfa_phone: z.string().nullable(),
      mfa_by_sms_anti_abuse_token: z.string().nullable(),
    }),
  },

  ACCOUNT_REFRESH: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    ...jwtRefreshDefinition,
  },

  ACCOUNT_LOGOUT: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    ...jwtLogoutDefinition,
  },

  ACCOUNT_GOOGLE_AUTHENTICATION_URL: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    path: "jwt/google/authentication_url",
    requestParamsSchema: z.object({
      login_hint: z.string().optional(),
      redirect_path: z.string().optional(),
      request_calendar_events_access: z.boolean().optional(),
    }),
    responseSchema: z.object({
      authentication_url: z.string(),
    }),
  },

  ACCOUNT_GOOGLE_SUBMIT_OAUTH_CODE: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    path: "jwt/google/submit_oauth_code",
    requestDataSchema: z.object({
      state: z.string(),
      code: z.string(),
    }),
    responseSchema: z.object({
      email: z.string(),
      google_session_data_handle: z.string(),
    }),
  },

  ACCOUNT_GOOGLE_COMPLETE_LOGIN: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    path: "jwt/google/complete_login",
    requestDataSchema: z.object({
      google_session_data_handle: z.string(),
    }),
    responseSchema: z.object({
      access_token: z.string(),
      refresh_token: z.string(),
    }),
  },

  ACCOUNT_GOOGLE_AUTHORIZATION_URL: {
    apiKind: "ACCOUNT",
    authenticationKind: "AUTHENTICATED_AS_ACCOUNT",
    path: "jwt/google/authorization_url",
    requestParamsSchema: z.object({
      redirect_path: z.string().optional(),
      request_calendar_events_access: z.boolean().optional(),
    }),
    responseSchema: z.object({
      required_authorization_url: z.string().nullable(),
    }),
  },

  ACCOUNT_GOOGLE_COMPLETE_AUTHORIZATION: {
    apiKind: "ACCOUNT",
    authenticationKind: "AUTHENTICATED_AS_ACCOUNT",
    path: "jwt/google/complete_authorization",
    requestDataSchema: z.object({
      state: z.string(),
      code: z.string(),
    }),
    responseSchema: z.object({}),
  },

  PROVIDER_REFRESH: {
    apiKind: "PROVIDER",
    authenticationKind: "UNAUTHENTICATED_WITH_ORGANIZATION",
    ...jwtRefreshDefinition,
  },

  PROVIDER_LOGOUT: {
    apiKind: "PROVIDER",
    authenticationKind: "UNAUTHENTICATED_WITH_ORGANIZATION",
    ...jwtLogoutDefinition,
  },

  PROVIDER_REDEEM_ONE_TIME_LOGIN_JWT: {
    apiKind: "PROVIDER",
    authenticationKind: "UNAUTHENTICATED_WITH_ORGANIZATION",
    path: "jwt/redeem_one_time_login_jwt",
    requestDataSchema: z.object({
      one_time_login_jwt: z.string(),
    }),
    responseSchema: z.object({
      access_token: z.string(),
      refresh_token: z.string(),
    }),
  },

  PROVIDER_UPLOAD: {
    apiKind: "PROVIDER",
    authenticationKind: "AUTHENTICATED_AS_DOCTOR",
    path: "upload",
    requestDataSchema: z.instanceof(FormData),
    responseSchema: z.array(z.string().uuid()),
  },

  SUPERUSER_UPLOAD: {
    apiKind: "SUPERUSER",
    authenticationKind: "AUTHENTICATED_AS_SUPERUSER",
    path: "upload",
    requestDataSchema: z.instanceof(FormData),
    responseSchema: z.array(z.string().uuid()),
  },
} satisfies Record<string, AnyEndpointDefinition>;

// ------ WebSocket endpoints.

export const SOCKET_ENDPOINTS = {
  PROVIDER_SPEECH_TO_TEXT: {
    apiKind: "PROVIDER",
    authenticationKind: "AUTHENTICATED_AS_DOCTOR",
    path: "speech2text",
    isSocket: true,
  },
} satisfies Record<string, AnySocketEndpointDefinition>;

// ----- GraphQL endpoints.

/**
 * GraphQL endpoints exposed by the backend for each GraphQL schema type.
 * Keep in sync with `server/graphql/core/src/main/kotlin/ace/graphql/core/GraphQLSchemaKind.kt`.
 */
export const GRAPHQL_ENDPOINTS = {
  ACCOUNT: {
    apiKind: "ACCOUNT",
    authenticationKind: "AUTHENTICATED_AS_ACCOUNT",
    path: "graphql/authenticated",
  },

  UNAUTHENTICATED_ACCOUNT: {
    apiKind: "ACCOUNT",
    authenticationKind: "UNAUTHENTICATED_ALMIGHTY",
    path: "graphql/unauthenticated",
  },

  SUPERUSER: {
    apiKind: "SUPERUSER",
    authenticationKind: "AUTHENTICATED_AS_SUPERUSER",
    path: "graphql/authenticated",
  },

  PROVIDER: {
    apiKind: "PROVIDER",
    authenticationKind: "AUTHENTICATED_AS_DOCTOR",
    path: "graphql/authenticated",
    withSocket: true,
  },

  COPILOT_API_USER: {
    apiKind: "COPILOT_API_USER",
    authenticationKind: "AUTHENTICATED_AS_COPILOT_API_USER",
    path: "graphql/copilot-api-user/authenticated",
  },

  ORGANIZATION_USER: {
    apiKind: "ORGANIZATION_USER",
    authenticationKind: "AUTHENTICATED_WITH_ACCESS_TO_ORGANIZATION_USER_API",
    path: "graphql/authenticated",
  },
} satisfies Record<SchemaType, AnyGqlEndpointDefinition>;

export type GqlSchemaAuthenticationKind<T extends SchemaType> =
  typeof GRAPHQL_ENDPOINTS[T]["authenticationKind"];
