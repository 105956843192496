import { Icon } from "components/Icon/Icon";
import { IconName } from "icon-library";

export const EventMessage = ({
  label,
  status,
  icon,
  onClick,
}: {
  label: string;
  icon: IconName;
  status?: string;
  onClick?: () => void;
}) => (
  <div
    className="mt-4 bg-white rounded border p-12 flex items-center cursor-pointer"
    onClick={onClick}
  >
    <div className="h-24 w-24 rounded-full flex-center bg-primary">
      <Icon name={icon} className="text-white" />
    </div>
    <div className="flex-col ml-12">
      <div className="flex text-primary-dark font-medium">{label}</div>
      {status}
    </div>
  </div>
);
