import { Fragment, useState } from "react";
import gql from "graphql-tag";
import { useLocation, useNavigate } from "react-router-dom";

import { NextPageButton } from "components/Button/NextPageButton";
import { Input } from "components/Form/Input/Input";
import { DoctorRowOption } from "components/Form/Select/DoctorRowOption";
import { FormMultiSelect } from "components/Form/Select/FormMultiSelect";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { ConversationLink } from "components/InboxRow/ConversationLink";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { FormModal } from "components/Modal/FormModal";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Query } from "components/Query/Query";
import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { useDoctor } from "contexts/User/UserContext";
import {
  AllDoctors,
  AllDoctorsData,
  CreateDoctorConversation,
  GetDoctorsConversations,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useDebounceSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { routes } from "routes";
import {
  addDoctorConversationInCache,
  doctorsToChatWithFilter,
} from "utils/apollo";
import { displayUser } from "utils/display";

import { DoctorsConversation } from "./DoctorsConversation";
import { Footer } from "./Footer";

gql`
  query GetDoctorsConversations($freeTextSearch: String, $cursor: DateTime) {
    doctorExperienceSearch(filter: { freeTextSearch: $freeTextSearch }) {
      experiences(
        page: { numberOfItems: 20, from: $cursor }
        orderField: RECENTLY_UPDATED
      ) {
        hasMore
        nextCursor
        data {
          ...ExperienceSummary
        }
      }
    }
  }

  mutation CreateDoctorConversation($doctorUuids: [UUID!]!) {
    createExperience(doctorUuids: $doctorUuids) {
      experience {
        ...ExperienceSummary
      }
    }
  }
`;

type FormValues = {
  selectedDoctors: AllDoctorsData["doctors"][0][];
};

export const DoctorsConversations = () => {
  const t = useTranslation();
  const { hasPermission, user } = useDoctor();
  const [showCreateExperience, setShowCreateExperience] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [createDoctorConversation] = useMutation(CreateDoctorConversation);
  const { search, inputProps } = useDebounceSearch();

  return (
    <SidebarPage
      baseRoute={routes.CONVERSATION_BASE}
      title={t("doctors_conversation.title")}
      topRight={
        hasPermission("EDIT_EXPERIENCES") ? (
          <ClickableIcon
            name="add"
            className="action-icon p-4"
            onClick={() => setShowCreateExperience(true)}
          />
        ) : null
      }
      items={[]}
      otherRoutes={[{ to: "/:uuid", Component: DoctorsConversation }]}
      footer={<Footer />}
    >
      <Input
        {...inputProps}
        placeholder={t("doctors_conversation.doctors_conversations.search")}
        wrapperClassName="mb-12 px-20"
        className="h-32"
        leftInnerElement="search"
        autoComplete="off"
        rightInnerElement={
          inputProps.value ? (
            <ClickableIcon
              name="close"
              size={12}
              onClick={() =>
                inputProps.onChange({ currentTarget: { value: "" } })
              }
            />
          ) : null
        }
      />
      <PaginatedQuery
        query={GetDoctorsConversations}
        variables={{ freeTextSearch: search }}
        selector={(data) => data.experiences}
      >
        {({ experiences }, utils) => (
          <div className="flex-fill flex-col items-center overflow-auto">
            {experiences.data.isEmpty() ? (
              <div className="py-12 text-center">
                {search
                  ? t("doctors_conversation.doctors_conversations.no_results")
                  : t(
                      "doctors_conversation.doctors_conversations.no_conversations",
                    )}
              </div>
            ) : (
              experiences.data
                .filterNotNull()
                .sortDesc((e) => e.lastModified.getTime())
                .map((e) => (
                  <Fragment key={e.uuid}>
                    <ConversationLink
                      key={e.uuid}
                      to={`${routes.CONVERSATION_BASE}/${e.uuid}`}
                      experience={e}
                      patient={undefined}
                      highlighted={location.pathname.includes(e.uuid)}
                      isInExperience
                    />
                    <div
                      className="bg-grey-200 h-1 last:invisible"
                      style={{ width: "87%" }}
                    />
                  </Fragment>
                ))
            )}
            <NextPageButton {...utils} />
            <BottomSafeArea />
          </div>
        )}
      </PaginatedQuery>
      {showCreateExperience && (
        <FormModal<FormValues>
          onHide={() => setShowCreateExperience(false)}
          title={t("doctors_conversation.create_a_new_practitioners_chat")}
          initialValues={{ selectedDoctors: [] }}
          submitLabel={t("doctors_conversation.doctors_conversations.create")}
          validationSchema={{ selectedDoctors: "notEmpty" }}
          onSubmit={({ selectedDoctors }) =>
            createDoctorConversation(
              { doctorUuids: selectedDoctors.map((doc) => doc.uuid) },
              {
                onSuccess: ({ experience }, client) => {
                  setShowCreateExperience(false);
                  addDoctorConversationInCache(client, experience);
                  navigate(`${routes.CONVERSATION_BASE}/${experience.uuid}`);
                },
              },
            )
          }
        >
          <Query
            query={AllDoctors}
            variables={{ filter: doctorsToChatWithFilter }}
            noSpinner
          >
            {({ loading, data }) => (
              <FormMultiSelect
                name="selectedDoctors"
                placeholder={t("doctors_conversation.select_practitioners")}
                loading={loading}
                options={
                  data?.doctors.filter((d) => d.uuid !== user.uuid) ?? []
                }
                getOptionLabel={displayUser}
                CustomOption={(props) => <DoctorRowOption {...props} />}
              />
            )}
          </Query>
        </FormModal>
      )}
    </SidebarPage>
  );
};
