import { useEffect } from "react";
import gql from "graphql-tag";

import { DoctorFilter } from "components/DoctorSelect/DoctorSelect";
import { useDoctor } from "contexts/User/UserContext";
import {
  AvailableAppointmentsInTimeRange,
  TimeRangeInput,
} from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";

import { appointmentToCalendarEvent } from "../types";

gql`
  fragment CalendarAppointment on Appointment {
    uuid
    doctor {
      ...DoctorSummary
      aperoId
    }
    startAt
    endAt
    description
    state {
      ...AppointmentState
    }
    location {
      ... on PhysicalAppointmentLocation {
        address {
          ...Address
        }
      }
      ... on RemoteAppointmentLocation {
        livekitRoom {
          ...LivekitRoom
        }
      }
    }
    patient {
      ...PatientSummary
      aperoId
      sex
      birthDate
      phoneV2
      email
    }
  }

  query AvailableAppointmentsInTimeRange(
    $page: TimeRangeInput!
    $filter: AppointmentFilter
  ) {
    appointmentsInTimeRange(page: $page, filter: $filter) {
      data {
        ...CalendarAppointment
      }
    }
  }
`;

export const useAppointmentsInTimeRange = (
  page: TimeRangeInput,
  selectedFilter: DoctorFilter,
) => {
  const { hasPermission } = useDoctor();

  const [fetch, { data, loading, error }] = useLazyQuery(
    AvailableAppointmentsInTimeRange,
  );

  useEffect(() => {
    if (hasPermission("VIEW_APPOINTMENTS")) {
      fetch({
        page,
        filter: {
          doctorUuid: selectedFilter.uuid,
        },
      });
    }
  }, [fetch, hasPermission, selectedFilter, page]);

  const appointments = data?.data.map(appointmentToCalendarEvent) ?? [];
  return { appointments, loading, error };
};
