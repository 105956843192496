import { Fragment, useState } from "react";
import gql from "graphql-tag";
import { round } from "lodash";
import { Range } from "react-date-range";

import { Maybe } from "base-types";
import { Icon } from "components/Icon/Icon";
import { Query } from "components/Query/Query";
import { TagPill } from "components/Tag/TagPill";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import {
  StatsDashboardQuery,
  TagFragment,
  TimeRangeInput,
} from "generated/provider";
import { useTranslation } from "i18n";
import { sum } from "utils";
import { displayBigInteger } from "utils/display";

import { Filter, FILTERS } from "./filterType";
import { StatsTimeRange } from "./StatsTimeRange";
import styles from "./statsDashboardHistory.module.css";

gql`
  query StatsDashboardQuery($page: TimeRangeInput) {
    statsDashboard(page: $page) {
      sections {
        title
        items {
          title
          description
          content {
            ... on StatsIntContent {
              intValue: value
              growthRate
            }
            ... on StatsFloatContent {
              floatValue: value
              growthRate
            }
            ... on StatsDistributionContent {
              items {
                name
                value
              }
            }
            ... on TagsDistributionContent {
              items {
                tag {
                  ...Tag
                }
                value
              }
            }
          }
        }
      }
    }
  }
`;

const defaultAmountOfShownItems = 10;

export const StatsDashboardHistory = () => {
  const [selectedRange, setSelectedRange] = useState<TimeRangeInput>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedPill, setSelectedPill] = useState<Filter>(FILTERS[0]);
  const [customRange, setCustomRange] = useState<Range>({
    key: "selection",
  });
  return (
    <div className="flex-col flex-start space-y-8">
      <StatsTimeRange
        selectedPill={selectedPill}
        setSelectedPill={setSelectedPill}
        customRange={customRange}
        setCustomRange={setCustomRange}
        setSelectedRange={setSelectedRange}
      />
      <Query query={StatsDashboardQuery} variables={{ page: selectedRange }}>
        {(stats) => (
          <>
            {stats.sections.map((section) => (
              <Fragment key={section.title}>
                <h2 className="font-bold text-18 text-primary-dark">
                  {section.title}
                </h2>
                <div className="flex-fill flex-col space-y-16">
                  <div className={styles.statsGrid}>
                    {section.items.map((sectionItem) => {
                      switch (sectionItem.content.__typename) {
                        case "StatsIntContent":
                          return (
                            <NumericContentCard
                              key={sectionItem.title}
                              title={sectionItem.title}
                              description={sectionItem.description}
                              value={sectionItem.content.intValue}
                              growthRate={sectionItem.content.growthRate}
                            />
                          );
                        case "StatsFloatContent":
                          return (
                            <NumericContentCard
                              key={sectionItem.title}
                              title={sectionItem.title}
                              description={sectionItem.description}
                              value={sectionItem.content.floatValue}
                              growthRate={sectionItem.content.growthRate}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                  <div className={styles.histogramGrid}>
                    {section.items.mapNotNull((sectionItem) => {
                      switch (sectionItem.content.__typename) {
                        case "TagsDistributionContent":
                          return (
                            <TagsHistogram
                              title={sectionItem.title}
                              description={sectionItem.description}
                              values={sectionItem.content.items}
                              canBeExpanded={
                                sectionItem.content.items.length >
                                defaultAmountOfShownItems
                              }
                              isExpanded={isExpanded}
                              expand={() => setIsExpanded(!isExpanded)}
                              key={sectionItem.title}
                            />
                          );
                        case "StatsDistributionContent":
                          return (
                            <HorizontalHistogram
                              title={sectionItem.title}
                              description={sectionItem.description}
                              values={sectionItem.content.items}
                              key={sectionItem.title}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
              </Fragment>
            ))}
          </>
        )}
      </Query>
    </div>
  );
};

const TagsHistogram = ({
  title,
  description,
  values,
  canBeExpanded,
  isExpanded,
  expand,
}: {
  title: string;
  description: string;
  values: { tag: TagFragment; value: Float }[];
  canBeExpanded: boolean;
  isExpanded: boolean;
  expand: () => void;
}) => {
  const t = useTranslation();

  const totalValue = sum(values.map((value) => value.value));
  const shownValues = isExpanded
    ? values
    : values.slice(0, defaultAmountOfShownItems);

  const shownMaxValue = Math.max(...shownValues.map((item) => item.value));
  return (
    <div className="bg-white border rounded shadow-sm-outlined p-20 flex-col space-y-12">
      <div className="flex justify-between items-start">
        <span className="font-bold text-12 flex-shrink">
          {title.toUpperCase()}
        </span>
        <TooltipWrapper label={description} position="left">
          <Icon name="info" />
        </TooltipWrapper>
      </div>

      {values.length ? (
        <div className="grid grid-cols-[auto,1fr] gap-y-10 gap-x-24">
          {shownValues.map((item, itemIndex) => (
            <Fragment key={itemIndex}>
              <TagPill tag={item.tag} className="place-self-end" />
              <TagHistogramBar
                value={item.value}
                totalValue={totalValue}
                maxValue={shownMaxValue}
              />
            </Fragment>
          ))}
          <div>
            {canBeExpanded && (
              <button
                className="button-link text-center"
                onClick={(e) => {
                  expand();
                  e.stopPropagation();
                }}
              >
                {isExpanded
                  ? t("stats_dashboard.stats_dashboard.see_less")
                  : t("stats_dashboard.stats_dashboard.see_more")}
              </button>
            )}
          </div>
          <TagHistogramScale maxValue={shownMaxValue} />
        </div>
      ) : (
        <span className="text-black">
          {t("stats_dashboard.stats_dashboard.no_tags")}
        </span>
      )}
    </div>
  );
};

const TagHistogramBar = ({
  value,
  totalValue,
  maxValue,
}: {
  value: Float;
  totalValue: Float;
  maxValue: Float;
}) => {
  const t = useTranslation();

  return (
    <div className="flex-col h-24 flex-grow">
      <div className="py-1 flex justify-start flex-grow">
        <TooltipWrapper
          label={`${value.toLocaleString(
            t("stats_dashboard.stats_dashboard.engb"),
          )} (${round((value / totalValue) * 100, 1).toLocaleString(
            t("stats_dashboard.stats_dashboard.engb"),
          )}%)`}
          position="right"
          style={{
            width: value ? `max(1px, ${(value / maxValue) * 100}%)` : 0,
          }}
          className="bg-primary-300 rounded-sm hover:bg-primary/50 transition-background duration-200"
        />
      </div>
    </div>
  );
};

const TagHistogramScale = ({ maxValue }: { maxValue: Float }) => {
  const scaleStepNb = Math.min(maxValue, 12);
  const scaleStep = maxValue / scaleStepNb;

  return (
    <div className="flex h-24 flex-grow">
      <div>0</div>
      {Array.from({ length: scaleStepNb }).map((_, index) => (
        <div key={index} className="flex-1 text-right">
          {displayBigInteger((index + 1) * scaleStep)}
        </div>
      ))}
    </div>
  );
};

const NumericContentCard = ({
  title,
  description,
  value,
  growthRate,
}: {
  title: string;
  description: string;
  value: Int | Float;
  growthRate: Maybe<Float>;
}) => {
  const t = useTranslation();

  return (
    <div className="bg-white border rounded shadow-sm-outlined p-20 flex-col space-y-4">
      <div className="flex justify-between items-start">
        <span className="font-bold text-12 flex-shrink">
          {title.toUpperCase()}
        </span>
        <TooltipWrapper label={description} position="left">
          <Icon name="info" />
        </TooltipWrapper>
      </div>

      <span className="text-primary-dark font-bold text-24">
        {value.toLocaleString(t("stats_dashboard.stats_dashboard.engb"), {
          maximumFractionDigits: 1,
        })}
      </span>

      {growthRate !== null && (
        <div className="flex items-center space-x-8">
          <Icon
            name={growthRate === 0 ? "equal" : "arrow"}
            className="p-4 bg-grey-100 rounded-full text-body"
            size={20}
            rotate={growthRate === 0 ? 0 : growthRate > 0 ? 135 : 225}
          />
          <span className="font-medium text-body text-12">
            {growthRate <= 0 ? "" : "+"}
            {(growthRate * 100).toLocaleString(
              t("stats_dashboard.stats_dashboard.engb"),
              {
                maximumFractionDigits: 1,
              },
            )}
            %
          </span>
        </div>
      )}
    </div>
  );
};

const HorizontalHistogram = ({
  title,
  description,
  values,
}: {
  title: string;
  description: string;
  values: { name: string; value: Float }[];
}) => {
  const maxValue = Math.max(...values.map((item) => item.value));
  return (
    <div className="bg-white border rounded shadow-sm-outlined p-20 flex-col space-y-12 lg:max-h-[400px] min-h-[250px]">
      <div className="flex justify-between items-start">
        <span className="font-bold text-12 flex-shrink">
          {title.toUpperCase()}
        </span>
        <TooltipWrapper label={description} position="left">
          <Icon name="info" />
        </TooltipWrapper>
      </div>

      <div className="flex flex-fill">
        <HorizontalHistogramScale maxValue={maxValue < 1 ? 1 : maxValue} />
        <div className="flex flex-fill">
          {values.map((item, itemIndex) => (
            <HorizontalHistogramBucket
              name={item.name}
              value={item.value}
              maxValue={maxValue}
              key={itemIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const HorizontalHistogramScale = ({ maxValue }: { maxValue: Float }) => {
  const t = useTranslation();
  const oneThirdOfMaxValue = maxValue / 3;
  return (
    <div className="flex-col">
      {[3, 2, 1].map((i) => (
        <div className="flex-col h-1/4 justify-center" key={i}>
          <div>
            {maxValue > 1
              ? displayBigInteger(i * oneThirdOfMaxValue)
              : (i * oneThirdOfMaxValue).toLocaleString(
                  t("stats_dashboard.stats_dashboard.engb"),
                  {
                    maximumFractionDigits: 1,
                  },
                )}
          </div>
        </div>
      ))}
    </div>
  );
};

const HorizontalHistogramBucket = ({
  name,
  value,
  maxValue,
}: {
  name: string;
  value: Float;
  maxValue: Float;
}) => {
  const t = useTranslation();
  return (
    <div className="flex-col" style={{ width: `${(1 / 24) * 100}%` }}>
      <div className="px-1 flex-col justify-end flex-grow">
        <TooltipWrapper
          label={value.toLocaleString(
            t("stats_dashboard.stats_dashboard.engb"),
          )}
          position="top"
          style={{
            height: value ? `max(1px, ${(value / maxValue) * 100}%)` : 0,
          }}
          className="bg-primary-300 rounded-sm hover:bg-primary/50 transition-background duration-200"
        />
      </div>
      <div className="text-center">{name.toLowerCase()}</div>
    </div>
  );
};
