import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";

export const PatientNoDeviceWarning = () => {
  const t = useTranslation();

  return (
    <div className="border-b px-16 py-10 bg-warning-100 text-warning-300 leading-snug flex items-center space-x-16">
      <Icon name="warning" size={25} />
      <span className="flex-fill">
        {t("patient_view.experience.no_device_warning")}
      </span>
    </div>
  );
};
