// eslint-disable-next-line no-restricted-imports
import i18n from "i18next";
// eslint-disable-next-line no-restricted-imports
import { initReactI18next, useI18nTranslation } from "react-i18next";

import en from "./common/en.json";
import fr from "./common/fr.json";
import pt from "./common/pt.json";
import type { TFunction } from "./generated";

export { TFunction };

// export needed for HMR
export const resources = {
  en: { common: en },
  fr: { common: fr },
  pt: { common: pt },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: "common",
    resources,
    lng: navigator.language.startsWith("fr")
      ? "fr"
      : navigator.language.startsWith("pt")
      ? "pt"
      : "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
    returnEmptyString: false,
  });

export const staticT: TFunction = i18n.t.bind(i18n);
export const useTranslation = () => useI18nTranslation().t as TFunction;
export const changeLanguage = i18n.changeLanguage;
export const getCurrentLanguage = () => i18n.language as LOCALE;
export type LOCALE = keyof typeof resources;

// This allows the json files to be hot replaced
// The downside is that changes to this file will require manual reload
// See https://vitejs.dev/guide/api-hmr.html#hot-accept-cb
if (import.meta.hot) {
  let lng: string;
  import.meta.hot.on("vite:beforeUpdate", () => {
    lng = i18n.resolvedLanguage;
  });

  import.meta.hot.accept((mod) => {
    if (!mod) return;
    Object.keys(mod.resources).forEach((lang) => {
      Object.keys(mod.resources[lang]).forEach((namespace) => {
        i18n.addResourceBundle(
          lang,
          namespace,
          mod.resources[lang][namespace],
          true,
          true,
        );
      });
    });
    i18n.changeLanguage(lng);
  });
}
