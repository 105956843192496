import { useState } from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

import { Button } from "components/Button/Button";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { Redirect } from "components/Routes/Redirect";
import { Separator } from "components/Separator/Separator";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { ServerKeys } from "../../../shared/Developers/ServerKeys/ServerKeys";
import { AppKeys } from "./AppKeys";

const SERVER_API_KEYS = `${routes.DEVELOPERS}/${routes.API_KEYS}/server`;
const SDK_API_KEYS = `${routes.DEVELOPERS}/${routes.API_KEYS}/sdk`;

export const ApiKeys = () => {
  const t = useTranslation();
  const [addingApiKey, setAddingApiKey] = useState(false);
  const isDesktop = useIsDesktop();

  const apiKeyType = useMatch(
    `${routes.DEVELOPERS}/${routes.API_KEYS}/:apiKeyType*`,
  )?.params.apiKeyType.toLocaleLowerCase();

  if (!apiKeyType?.match(/server|sdk/u)) {
    return <Redirect to={SERVER_API_KEYS} />;
  }

  return (
    <div className="flex-col flex-fill bg-light-blue overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold pt-16 lg:pt-24">
          {t("developers.server_keys.api_keys.api_keys")}
        </h1>
        <div>{t("api_keys.you_can_create_2_types_of_keys")}</div>
        <div className="flex justify-between">
          <div className="flex">
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  apiKeyType === "server",
              })}
              to={SERVER_API_KEYS}
            >
              {t("developers.server_keys.server_keys.server_api_keys_title")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  apiKeyType === "sdk",
              })}
              to={SDK_API_KEYS}
            >
              {t("developers.server_keys.app_keys.mobile_sdk_api_keys_title")}
            </Link>
          </div>
          <Button
            label={
              isDesktop
                ? apiKeyType === "server"
                  ? t("developers.server_keys.server_keys.add_server_api_key")
                  : t("app_keys.add_mobile_sdk_api_key")
                : t("developers.server_keys.api_keys.add_short")
            }
            onClick={() => setAddingApiKey(true)}
            className="mt-auto ml-auto my-12"
          />
        </div>
      </div>
      <Separator />
      <div className="flex-col p-16 lg:px-44">
        {apiKeyType === "server" && (
          <ServerKeys
            appFlavor="CARE_PLATFORM"
            isAddingKey={addingApiKey}
            onKeyComposerClosed={() => setAddingApiKey(false)}
          />
        )}
        {apiKeyType === "sdk" && (
          <AppKeys
            isAddingKey={addingApiKey}
            onKeyComposerClosed={() => setAddingApiKey(false)}
          />
        )}
      </div>

      <BottomSafeArea />
    </div>
  );
};
