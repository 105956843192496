import gql from "graphql-tag";

import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { Label } from "components/Form/Label/Label";
import { FormModal } from "components/Modal/FormModal";
import { TagPill } from "components/Tag/TagPill";
import {
  AllTagsBackOffice,
  BackofficeTagFragment,
  CreateTag,
  UpdateTag,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";
import { ephemeralUuidV4 } from "utils/stackoverflow";

gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(createInput: $input) {
      tag {
        ...BackofficeTag
      }
    }
  }

  mutation UpdateTag($uuid: UUID!, $input: UpdateTagInput!) {
    updateTag(uuid: $uuid, updateInput: $input) {
      tag {
        ...BackofficeTag
      }
    }
  }
`;

type FormValues = {
  name: string;
  color: string;
  unpredictable: boolean;
};

export const TagComposer = ({
  tag,
  onHide,
}: {
  tag?: BackofficeTagFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();

  const [createTag] = useMutation(CreateTag, {
    onSuccess(result, client) {
      client.update({
        query: AllTagsBackOffice,
        write: (data) => {
          data.tags.push(result.tag);
        },
      });
      notifier.success(t("tags_management.tag_composer.tag_created"));
      onHide();
    },
  });
  const [updateTag] = useMutation(UpdateTag, {
    onSuccess: () => {
      onHide();
      notifier.success(t("tags_management.tag_composer.tag_updated"));
    },
  });

  return (
    <FormModal<FormValues>
      title={
        tag
          ? t("tags_management.tag_composer.update_tag")
          : t("tags_management.tag_composer.add_tag")
      }
      submitLabel={
        tag
          ? t("tags_management.tag_composer.save")
          : t("tags_management.tag_composer.add")
      }
      initialValues={
        tag
          ? { ...tag, unpredictable: !tag.mlPredictable }
          : {
              name: "",
              color: "body",
              unpredictable: false,
            }
      }
      onHide={onHide}
      validationSchema={{
        name: "required",
        color: "required",
      }}
      onSubmit={(values) =>
        tag
          ? updateTag({
              uuid: tag.uuid,
              input: {
                name: values.name,
                color: values.color,
                mlPredictable: !values.unpredictable,
              },
            })
          : createTag({
              input: {
                name: values.name,
                color: values.color,
                mlPredictable: !values.unpredictable,
              },
            })
      }
    >
      <FormInput name="name" label={t("tags_management.tag_composer.name")} />
      <FormInput
        name="color"
        label={t("tags_management.tag_composer.color")}
        wrapperClassName="flex-fill"
      />
      <div className="flex items-center space-x-12">
        <FormCheckbox
          name="unpredictable"
          label={t(
            "tag_composer.do_not_automatically_suggest_this_tag_among_conversation_tags",
          )}
          className="flex-fill"
        />
      </div>
      <div className="flex items-center">
        <Label label="Preview:" useDiv wrapperClassName="mb-0" />
        <FormState<{
          name: string;
          displayFr: string;
          displayEn: string;
          color: string;
        }>>
          {({ values }) => (
            <TagPill
              tag={{
                __typename: "TagType",
                uuid: ephemeralUuidV4(),
                ...values,
              }}
            />
          )}
        </FormState>
      </div>
    </FormModal>
  );
};
