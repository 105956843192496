import { DateTime } from "luxon";

import { DateTimeGranularityKnownValues } from "generated/provider";
import { staticT as t } from "i18n";
import { GranularDateTime } from "types";

import { isKnownValue } from "./enum";

export const now = (): ISOString => new Date().toISOString();

// Parses a date in the current locale's date format into a `LocalDate`.
export const parseLocalDate = (localeFormattedDate: string) =>
  DateTime.fromFormat(localeFormattedDate, t("common.date_format"), {
    zone: "utc",
    setZone: false,
  }).toISODate() as unknown as LocalDate;

export const parseLocalDateFromIso = (isoFormattedDate: string) =>
  DateTime.fromISO(isoFormattedDate, {
    zone: "utc",
    setZone: false,
  }).toISODate() as unknown as LocalDate;

export const displayTimeElapsed = (intSeconds: number) =>
  // Takes a number and returns a string of format H:MM:SS or MM:SS or M:SS
  new Date(1000 * intSeconds)
    .toISOString()
    .slice(intSeconds >= 60 * 60 ? 12 : intSeconds >= 10 * 60 ? 14 : 15, 19);

export const displayGranularDateTime = (
  granularDateTime: GranularDateTime | null | undefined,
) => {
  if (!granularDateTime) return "-?-";

  const { dateTime, granularity } = granularDateTime;
  if (!isKnownValue(granularity, DateTimeGranularityKnownValues)) {
    return dateTime.format("date");
  }

  return dateTime.format(
    ({ YEAR: { exception: "y" }, MONTH: "monthAndYear", DAY: "date" } as const)[
      granularity
    ],
  );
};

export const displayGranularDateRange = (
  startedAt?: GranularDateTime | null,
  endedAt?: GranularDateTime | null,
) => {
  if (displayGranularDateTime(startedAt) === displayGranularDateTime(endedAt)) {
    return displayGranularDateTime(startedAt);
  }

  if (startedAt && endedAt) {
    if (startedAt.granularity === "DAY") {
      return t("utils.date.from_to_day", {
        from: displayGranularDateTime(startedAt),
        to: displayGranularDateTime(endedAt),
      });
    }

    return t("utils.date.from_to", {
      from: displayGranularDateTime(startedAt),
      to: displayGranularDateTime(endedAt),
    });
  }

  if (startedAt) {
    if (startedAt.granularity === "DAY") {
      return t("utils.date.since_day", {
        date: displayGranularDateTime(startedAt),
      });
    }

    return t("utils.date.since", {
      date: displayGranularDateTime(startedAt),
    });
  }

  if (endedAt) {
    if (endedAt.granularity === "DAY") {
      return t("utils.date.until_day", {
        date: displayGranularDateTime(endedAt),
      });
    }

    return t("utils.date.until", {
      date: displayGranularDateTime(endedAt),
    });
  }

  return undefined;
};

export const durationFromSeconds = (seconds: number) =>
  seconds <= 60
    ? `${seconds.toFixed(2)} ${t("utils.date.seconds")}`
    : seconds <= 360
    ? `${(seconds / 60).toFixed(2)} ${t("utils.date.minutes")}`
    : `${(seconds / 3600).toFixed(2)} ${t("utils.date.hours")}`;
