import { useState } from "react";

import { SchemaType } from "base-types";
import { Query, QueryProps } from "components/Query/Query";
import { useDebounce } from "hooks/useDebounce";

import { FormMultiSelect, FormMultiSelectProps } from "./FormMultiSelect";

export type AsyncFormSelectProps<
  Option,
  Data,
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
> = {
  getOptions: (data: Data | undefined) => Option[];
  getVariables: (search: string) => Variables;
  multiSelectProps: Omit<
    FormMultiSelectProps<Option>,
    "options" | "loading" | "onSearchChanged"
  >;
  queryProps: Omit<
    QueryProps<Data, Variables, VariablesRequired, Schema, true>,
    "variables" | "children" | "noSpinner"
  >;
};

export const AsyncFormMultiSelect = <
  Option,
  Data,
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
>({
  getOptions,
  getVariables,
  multiSelectProps,
  queryProps,
}: AsyncFormSelectProps<
  Option,
  Data,
  Variables,
  VariablesRequired,
  Schema
>) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200);
  const variables = getVariables(debouncedSearch);

  return (
    // @ts-ignore(@liautaud): I have zero idea why this doesn't type-check.
    //  I've already wasted 3+ hours on this, and I'm confident the public
    //  interface is correct (`queryProps` is `QueryProps` except `variables`,
    //  `children` and `noSpinner` so there should be everything).
    <Query<Data, Variables, VariablesRequired, Schema, true>
      variables={variables}
      noSpinner
      {...queryProps}
    >
      {({ data, loading }) => (
        <FormMultiSelect<Option>
          options={getOptions(data)}
          loading={loading}
          onSearchChanged={(s) => {
            if (s !== undefined) setSearch(s);
          }}
          {...multiSelectProps}
        />
      )}
    </Query>
  );
};
