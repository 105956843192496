import { ReactNode, useCallback, useMemo, useState } from "react";

import { PartialResultError } from "./errors";
import { GraphQLClientContext } from "./GraphQLClientContext";
import { useSchemaClient } from "./useSchemaClient";

export const GraphQLClientProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [partialResultErrors, setPartialResultErrors] = useState<
    PartialResultError[]
  >([]);
  const addPartialResultError = useCallback(
    (error: PartialResultError) =>
      setPartialResultErrors((current) => current.concat(error)),
    [],
  );

  const { client: accountClient, isSocketReady: isAccountSocketReady } =
    useSchemaClient({
      schemaType: "ACCOUNT",
      addPartialResultError,
    });
  const { client: providerClient, isSocketReady: isProviderSocketReady } =
    useSchemaClient({
      schemaType: "PROVIDER",
      addPartialResultError,
    });
  const { client: superuserClient, isSocketReady: isSuperUserSocketReady } =
    useSchemaClient({
      schemaType: "SUPERUSER",
      addPartialResultError,
    });
  const {
    client: copilotApiUserClient,
    isSocketReady: isCopilotApiUserSocketReady,
  } = useSchemaClient({
    schemaType: "COPILOT_API_USER",
    addPartialResultError,
  });
  const {
    client: organizationUserClient,
    isSocketReady: isOrganizationUserSocketReady,
  } = useSchemaClient({
    schemaType: "ORGANIZATION_USER",
    addPartialResultError,
  });
  const { client: unauthenticatedAccountClient } = useSchemaClient({
    schemaType: "UNAUTHENTICATED_ACCOUNT",
    addPartialResultError,
  });

  const graphQLClients = useMemo(
    () => ({
      ACCOUNT: accountClient,
      PROVIDER: providerClient,
      SUPERUSER: superuserClient,
      COPILOT_API_USER: copilotApiUserClient,
      ORGANIZATION_USER: organizationUserClient,
      UNAUTHENTICATED_ACCOUNT: unauthenticatedAccountClient,
    }),
    [
      accountClient,
      providerClient,
      superuserClient,
      copilotApiUserClient,
      organizationUserClient,
      unauthenticatedAccountClient,
    ],
  );

  return (
    <GraphQLClientContext.Provider
      value={{
        graphQLClients,
        partialResultErrors,
        clearPartialResultErrors: useCallback(
          () => setPartialResultErrors([]),
          [],
        ),
        isSocketReady:
          isAccountSocketReady ||
          isProviderSocketReady ||
          isSuperUserSocketReady ||
          isCopilotApiUserSocketReady ||
          isOrganizationUserSocketReady,
      }}
    >
      {children}
    </GraphQLClientContext.Provider>
  );
};
