import { lazy, Suspense } from "react";

import { Media } from "atoms/useMediaWindows";
import { Spinner } from "components/Spinner/Spinner";

import { isRemoteMedia } from "../mediaWindowsModel";
import { AbstractMediaContent } from "./mediaContentModel";

const PDFViewer = lazy(() => import("components/PDFViewer/PDFViewer"));

export const PdfMediaContent: AbstractMediaContent = ({
  media,
}: {
  media: Media;
}) => (
  <Suspense fallback={<Spinner className="my-20" />}>
    <PDFViewer
      src={isRemoteMedia(media) ? media.url : media.blob}
      className="flex-fill w-full"
      withControls
      highlights={media.highlights}
      fitContent
    />
  </Suspense>
);
