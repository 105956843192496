import { Avatar } from "components/Avatar/Avatar";
import { NoteSectionsViewer } from "components/Form/Editor/NoteSections/NoteSectionsViewer";
import { EditMenu } from "components/Menu/EditMenu";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import { usePDFPreview } from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  DeleteEHRItemFromTimelineEvent,
  PatientNoteFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";
import { downloadFile } from "utils/file";
import { noteSectionsFromPatientNoteSection } from "utils/notes";

import { SearchPage } from "../components/SearchPage";

export const Note = ({
  timelineEventUuid,
  note,
  back,
  search,
  onUpdateSuccess,
}: {
  timelineEventUuid: UUID;
  note: PatientNoteFragment;
  back: () => void;
  search?: string;
  onUpdateSuccess?: (note: PatientNoteFragment) => void;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  return (
    <SearchPage
      title={note.title}
      subtitle={note.createdAt.format("date")}
      back={back}
    >
      <article className="flex-fill w-full overflow-auto bg-white space-y-10">
        <div className="flex items-center space-x-4 w-full">
          <Avatar
            user={
              note.source === "QUESTIONNAIRE"
                ? user.subOrganization
                : note.author
            }
            className="mr-8"
            size={24}
          />
          <div className="flex-col text-14">
            <span className="text-primary-dark font-medium">
              {note.source === "QUESTIONNAIRE"
                ? t(
                    "inboxes.qa_side_panel.patient_information_panel.note.from_questionnaire",
                  )
                : displayDoctor(note.author)}
            </span>
            <span className="font-medium text-body">
              {note.createdAt.format({ relative: "week" }).upperFirst()}
            </span>
          </div>
          <NoteEditMenu
            note={note}
            back={back}
            timelineEventUuid={timelineEventUuid}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
        <NoteSectionsViewer
          title={note.title}
          sections={noteSectionsFromPatientNoteSection(note.sections)}
          highlightSearch={search}
        />
      </article>
      <BottomSafeArea />
    </SearchPage>
  );
};

export const NoteEditMenu = ({
  timelineEventUuid,
  note,
  back,
  onUpdateSuccess,
}: {
  timelineEventUuid: UUID;
  note: PatientNoteFragment;
  back: () => void;
  onUpdateSuccess?: (note: PatientNoteFragment) => void;
}) => {
  const t = useTranslation();
  const [deleteTimelineItem] = useMutation(DeleteEHRItemFromTimelineEvent);
  const { ehrComposerState, setEHRComposerState } = usePatient();
  const { previewRef, generatePdf } = usePDFPreview();

  return (
    <div className="relative flex ml-auto self-start mr-8">
      <EditMenu
        edit={{
          hidden: !!ehrComposerState,
          onClick: () =>
            setEHRComposerState({
              mode: "note-edit",
              note,
              onSuccess: onUpdateSuccess,
            }),
        }}
        otherActions={[
          {
            icon: "download",
            text: t(
              "inboxes.qa_side_panel.patient_information_panel.note.export_as_pdf",
            ),
            onClick: async () =>
              downloadFile(
                await generatePdf(),
                `${note.patient.firstName}_${note.patient.lastName}_${note.title}.pdf`,
              ),
          },
        ]}
        remove={{
          condition: { type: "ehr", author: note.author },
          title: t(
            "inboxes.qa_side_panel.patient_information_panel.note.delete_the_note",
          ),
          suffix: t(
            "inboxes.qa_side_panel.patient_information_panel.note.delete_this_note",
          ),
          onConfirm: (close) =>
            deleteTimelineItem(
              { uuid: timelineEventUuid },
              {
                onSuccess: (_, client) => {
                  close();
                  back();
                  client.remove("PatientNote", note.uuid);
                },
              },
            ),
        }}
      />
      <div className="hidden">
        <PDFPreview previewRef={previewRef}>
          <div className="p-40">
            <NoteSectionsViewer
              title={note.title}
              sections={noteSectionsFromPatientNoteSection(note.sections)}
            />
          </div>
        </PDFPreview>
      </div>
    </div>
  );
};
