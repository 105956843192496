import {
  NoteVitalType,
  NoteVitalTypeKnownValues,
  NoteVitalUnit,
  NoteVitalUnitKnownValues,
} from "generated/provider";
import { TFunction } from "i18n";
import { isKnownValue } from "utils/enum";

export const toTranslationKeyName = (
  t: TFunction,
  sectionType: string,
): string => {
  if (sectionType === "chiefComplaint") {
    return t("note_normalization.chief_complaint");
  }
  if (sectionType === "symptoms") return t("note_normalization.symptoms");
  if (sectionType === "historyIllness") {
    return t("note_normalization.history_illness");
  }
  if (sectionType === "medicalHistory") {
    return t("note_normalization.medical_history");
  }
  if (sectionType === "socialHistory") {
    return t("note_normalization.social_history");
  }
  if (sectionType === "familyHistory") {
    return t("note_normalization.family_history");
  }
  if (sectionType === "surgicalHistory") {
    return t("note_normalization.surgical_history");
  }
  if (sectionType === "medications") return t("note_normalization.medications");
  if (sectionType === "allergies") return t("note_normalization.allergies");
  if (sectionType === "vitals") return t("note_normalization.vitals");
  if (sectionType === "vaccines") return t("note_normalization.vaccines");
  if (sectionType === "physicalExam") {
    return t("note_normalization.physical_exam");
  }
  if (sectionType === "labResults") return t("note_normalization.lab_results");
  if (sectionType === "imagingResults") {
    return t("note_normalization.imaging_results");
  }
  if (sectionType === "assessment") return t("note_normalization.assessment");
  if (sectionType === "plan") return t("note_normalization.plan");
  if (sectionType === "prescription") {
    return t("note_normalization.prescription");
  }
  if (sectionType === "appointments") {
    return t("note_normalization.appointments");
  }
  if (sectionType === "diagnostic") return t("note_normalization.diagnostic");
  return t("note_normalization.unknown_section_name");
};

export const sectionTypes = [
  "chiefComplaint",
  "symptoms",
  "historyIllness",
  "medicalHistory",
  "socialHistory",
  "familyHistory",
  "surgicalHistory",
  "medications",
  "allergies",
  "vitals",
  "vaccines",
  "physicalExam",
  "labResults",
  "imagingResults",
  "assessment",
  "plan",
  "prescription",
  "appointments",
  "diagnostic",
] as const;

export type SectionType = typeof sectionTypes[number];

export const displayVitalType = (
  t: TFunction,
  vitalType: NoteVitalType,
): string => {
  if (!isKnownValue(vitalType, NoteVitalTypeKnownValues)) return vitalType;
  switch (vitalType) {
    case "HEIGHT":
      return t("utils.height");
    case "WEIGHT":
      return t("utils.weight");
    case "SYSTOLIC_BLOOD_PRESSURE":
      return t("utils.systolic_blood_pressure");
    case "DIASTOLIC_BLOOD_PRESSURE":
      return t("utils.diastolic_blood_pressure");
    case "TEMPERATURE":
      return t("utils.temperature");
    case "HEART_RATE":
      return t("utils.heart_rate");
    case "RESPIRATION_RATE":
      return t("utils.respiration_rate");
    case "OXYGEN_SATURATION":
      return t("utils.oxygen_saturation");
    case "BMI":
      return t("utils.bmi");
    case "HEAD_CIRCUMFERENCE":
      return t("utils.head_circumference");
    case "WAIST_CIRCUMFERENCE":
      return t("utils.waist_circumference");
    case "PEAK_FLOW":
      return t("utils.peak_flow");
    case "OTHER":
      return t("utils.other");
  }
};

export const displayVitalUnit = (
  t: TFunction,
  vitalUnit: NoteVitalUnit,
): string => {
  if (!isKnownValue(vitalUnit, NoteVitalUnitKnownValues)) return vitalUnit;
  switch (vitalUnit) {
    case "METER":
      return "m";
    case "CENTIMETER":
      return "cm";
    case "FOOT":
      return "ft";
    case "POUND":
      return "lbs";
    case "KILOGRAM":
      return "kg";
    case "MILLIMETERS_OF_MERCURY":
      return "mmHg";
    case "CENTIMETERS_OF_MERCURY":
      return "cmHg";
    case "CELSIUS":
      return "°C";
    case "FAHRENHEIT":
      return "°F";
    case "BEATS_PER_MINUTE":
      return "BPM";
    case "BREATHS_PER_MINUTE":
      return t("utils.breaths_per_minute");
    case "LITER_PER_MINUTE":
      return "L/m";
    case "OTHER":
      return "OTHER";
  }
};
