import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { Input } from "components/Form/Input/Input";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Spinner } from "components/Spinner/Spinner";
import { useUser } from "contexts/User/UserContext";
import {
  DayOfTheWeekKnownValue,
  DayOfTheWeekKnownValues,
  GetServiceHours,
  ServiceHoursAutomaticMessageTypeKnownValue,
  ServiceHoursAutomaticMessageTypeKnownValues,
  SubOrganizationServiceHoursFragment,
  UpdateServiceHours,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { omitTypename } from "graphql-client/utils";
import { useFormState } from "hooks/useFormState";
import { staticT, useTranslation } from "i18n";
import { routes } from "routes";
import { getKnownValue } from "utils/enum";
import { notifier } from "utils/notifier";

gql`
  fragment SubOrganizationServiceHours on SubOrganization {
    ...SubOrganizationSummary
    serviceHours {
      automaticMessage
      automaticUnavailableMessage
      automaticMessageType

      monday {
        startAt
        endAt
      }
      tuesday {
        startAt
        endAt
      }
      wednesday {
        startAt
        endAt
      }
      thursday {
        startAt
        endAt
      }
      friday {
        startAt
        endAt
      }
      saturday {
        startAt
        endAt
      }
      sunday {
        startAt
        endAt
      }
    }
  }

  query GetServiceHours {
    me {
      doctor {
        uuid
        subOrganization {
          ...SubOrganizationServiceHours
        }
      }
    }
  }

  mutation UpdateServiceHours($input: OrganizationServiceHoursSettingsInput!) {
    updateOrganizationServiceHoursSettings(input: $input) {
      subOrganization {
        ...SubOrganizationServiceHours
      }
    }
  }
`;

export const ServiceHours = () => {
  const t = useTranslation();
  const { loading, data: serviceHoursData } = useQuery(GetServiceHours);

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("settings.service_hours.service_hours.personalized_autoreplies")}
        </h1>
        <div>
          {t(
            "service_hours.set_up_automated_replies_define_your_opening_hours_and_the_messages_you_want_your_patients_to",
          )}
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : serviceHoursData ? (
        <div className="flex-col space-y-24">
          <ServiceHoursItem
            organizationServiceHoursFragment={
              serviceHoursData.doctor.subOrganization
            }
          />
        </div>
      ) : null}
    </Background>
  );
};

type DayServiceHour = { startAt: DayTime; endAt: DayTime };

type FormValues = {
  automaticMessage: string;
  automaticUnavailableMessage: string;
  automaticMessageType: ServiceHoursAutomaticMessageTypeKnownValue;
  monday: DayServiceHour | null;
  tuesday: DayServiceHour | null;
  wednesday: DayServiceHour | null;
  thursday: DayServiceHour | null;
  friday: DayServiceHour | null;
  saturday: DayServiceHour | null;
  sunday: DayServiceHour | null;
};

export const ServiceHoursItem = ({
  organizationServiceHoursFragment,
}: {
  organizationServiceHoursFragment: SubOrganizationServiceHoursFragment;
}) => {
  const t = useTranslation();
  const [updateServiceHours, updating] = useMutation(UpdateServiceHours, {
    onSuccess: () =>
      notifier.success(
        t("settings.service_hours.service_hours.service_hours_updated"),
      ),
  });
  const serviceHours = organizationServiceHoursFragment.serviceHours;
  const { user, timezone } = useUser();

  if (!user) return null;

  return (
    <div className="bg-white border rounded shadow-sm-outlined p-32">
      <Form<FormValues>
        initialValues={{
          automaticMessage: serviceHours.automaticMessage ?? "",
          automaticUnavailableMessage:
            serviceHours.automaticUnavailableMessage ?? "",
          automaticMessageType:
            getKnownValue(
              serviceHours.automaticMessageType,
              ServiceHoursAutomaticMessageTypeKnownValues,
            ) ?? "NEW_CONVERSATION",
          monday: serviceHours.monday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.monday))
            : null,
          tuesday: serviceHours.tuesday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.tuesday))
            : null,
          wednesday: serviceHours.wednesday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.wednesday))
            : null,
          thursday: serviceHours.thursday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.thursday))
            : null,
          friday: serviceHours.friday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.friday))
            : null,
          saturday: serviceHours.saturday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.saturday))
            : null,
          sunday: serviceHours.sunday
            ? alignDayServiceHourToMinute(omitTypename(serviceHours.sunday))
            : null,
        }}
        onSubmit={(values) =>
          updateServiceHours({
            input: {
              automaticMessage: values.automaticMessage.trimOrNull(),
              automaticUnavailableMessage:
                values.automaticUnavailableMessage.trimOrNull(),
              automaticMessageType: values.automaticMessageType,
              serviceHours: [
                { dayOfWeek: "MONDAY", range: values.monday ?? null },
                { dayOfWeek: "TUESDAY", range: values.tuesday ?? null },
                { dayOfWeek: "WEDNESDAY", range: values.wednesday ?? null },
                { dayOfWeek: "THURSDAY", range: values.thursday ?? null },
                { dayOfWeek: "FRIDAY", range: values.friday ?? null },
                { dayOfWeek: "SATURDAY", range: values.saturday ?? null },
                { dayOfWeek: "SUNDAY", range: values.sunday ?? null },
              ],
            },
          })
        }
        className="flex items-start"
      >
        <div className="flex-col space-y-20 w-1/2 border-r pr-32">
          {DayOfTheWeekKnownValues.map((day) => (
            <DayServiceHours day={day} key={day} />
          ))}
        </div>
        <div className="flex-col flex-fill pl-32 space-y-18">
          <FormSelect
            name="automaticMessageType"
            label={t(
              "settings.service_hours.service_hours.automated_reply_type",
            )}
            options={ServiceHoursAutomaticMessageTypeKnownValues}
            getOptionLabel={(o) => {
              switch (o) {
                case "NEW_CONVERSATION":
                  return t(
                    "settings.service_hours.service_hours.new_conversation",
                  );
                case "NEW_MESSAGE":
                  return t(
                    "service_hours.new_message_in_existing_conversation",
                  );
              }
            }}
          />
          <FormState<FormValues>>
            {({ values }) => (
              <>
                <FormTextArea
                  name="automaticMessage"
                  label={t(
                    "service_hours.automatic_message_during_service_hours",
                  )}
                  disabled={isAutomaticMessageTextAreaDisabled(values)}
                />
                <FormTextArea
                  name="automaticUnavailableMessage"
                  label={t(
                    "service_hours.automatic_response_outside_of_service_hours",
                  )}
                  wrapperClassName="mt-20"
                  disabled={isAutomaticMessageTextAreaDisabled(values)}
                />
              </>
            )}
          </FormState>
          <div className="ml-auto mt-20 flex-col space-y-12">
            <Button
              label={t("settings.service_hours.service_hours.save")}
              type="submit"
              loading={updating}
              style={{ width: 144 }}
            />
          </div>
        </div>
      </Form>
      <div className="mt-20">
        {t("settings.service_hours.hours_are_set_for_the_tz", {
          timezone,
        })}
        <Link
          to={`${routes.SETTINGS}/${routes.REGION_SETTINGS}`}
          className="underline ml-8"
        >
          {t("service_hours.change_the_timezone")}
        </Link>
      </div>
    </div>
  );
};

export const DayServiceHours = ({ day }: { day: DayOfTheWeekKnownValue }) => {
  const t = useTranslation();
  const { values, setFieldValue } = useFormState<FormValues>();
  const dayKey = dayKeyFromEnum(day);

  return (
    <div className="flex items-center space-x-8 flex-fill">
      <div className="flex items-center w-1/3 space-x-8">
        <CheckBox
          name={day}
          checked={!!values[dayKey]}
          onChange={(value) => {
            if (value) {
              setFieldValue(dayKey, { startAt: "08:00", endAt: "20:00" });
            } else {
              setFieldValue(dayKey, null);
            }
          }}
        />
        <div className="text-primary-dark mt-8 text-14">{dayName(day)}</div>
      </div>
      <div className="flex items-center flex-fill space-x-8 ml-8">
        <div className="flex items-center w-1/3">
          {values[dayKey] ? (
            <FormInput
              name={`${dayKey}.startAt`}
              className="text-12 bg-white border"
            />
          ) : (
            <Input
              value={t("settings.service_hours.service_hours.unavailable")}
              disabled
              name={`${dayKey}.startAt`}
              className="text-12"
            />
          )}
        </div>
        <div className="flex items-center flex-fill">
          <div className="mr-auto">-</div>
        </div>
        <div className="flex items-center w-1/3">
          {values[dayKey] ? (
            <FormInput
              name={`${dayKey}.endAt`}
              className="text-12 bg-white border"
            />
          ) : (
            <Input
              value={t("settings.service_hours.service_hours.unavailable")}
              disabled
              name={`${dayKey}.endAt`}
              className="text-12"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const dayName = (day: DayOfTheWeekKnownValue) => {
  switch (day) {
    case "MONDAY":
      return staticT("settings.service_hours.service_hours.monday");
    case "TUESDAY":
      return staticT("settings.service_hours.service_hours.tuesday");
    case "WEDNESDAY":
      return staticT("settings.service_hours.service_hours.wednesday");
    case "THURSDAY":
      return staticT("settings.service_hours.service_hours.thursday");
    case "FRIDAY":
      return staticT("settings.service_hours.service_hours.friday");
    case "SATURDAY":
      return staticT("settings.service_hours.service_hours.saturday");
    case "SUNDAY":
      return staticT("settings.service_hours.service_hours.sunday");
  }
};

const dayKeyFromEnum = (day: DayOfTheWeekKnownValue) => {
  switch (day) {
    case "MONDAY":
      return "monday";
    case "TUESDAY":
      return "tuesday";
    case "WEDNESDAY":
      return "wednesday";
    case "THURSDAY":
      return "thursday";
    case "FRIDAY":
      return "friday";
    case "SATURDAY":
      return "saturday";
    case "SUNDAY":
      return "sunday";
  }
};

const isAutomaticMessageTextAreaDisabled = (values: FormValues) =>
  values.monday === null &&
  values.tuesday === null &&
  values.wednesday === null &&
  values.thursday === null &&
  values.friday === null &&
  values.saturday === null &&
  values.sunday === null;

const alignDayServiceHourToMinute = (
  dayServiceHour: DayServiceHour,
): DayServiceHour => ({
  startAt: dayServiceHour.startAt.slice(0, 5),
  endAt: dayServiceHour.endAt.slice(0, 5),
});
