import { useMemo } from "react";
import classNames from "classnames";

import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { FileUploadFragment } from "generated/provider";
import { useIsDesktop } from "hooks/useMediaQuery";
import { config } from "styles";
import { run } from "utils";

import { useAppendToQAComposer } from "../QAExperience/useAppendToQAComposer";
import { useQASidePanel } from "../QASidePanelContext/QASidePanelContext";
import { PatientInformationPanel } from "./PatientInformationPanel/PatientInformationPanel";
import { Search } from "./SearchPanel/Search";

export const QASidePanel = ({
  experienceUuid,
}: {
  experienceUuid: UUID | null;
}) => {
  const { patient } = usePatient();
  const { sidePanelState, closeSidePanel } = useQASidePanel();
  const isDesktop = useIsDesktop();
  const appendToQAComposer = useAppendToQAComposer();
  const { setMediaMessages } = useExperienceDraft();

  const onContentSelected = useMemo(
    () =>
      appendToQAComposer
        ? (value: string) => {
            appendToQAComposer(value);
            closeSidePanel();
          }
        : null,
    [appendToQAComposer, closeSidePanel],
  );

  const onFileSelected = (fileUpload: FileUploadFragment) => {
    setMediaMessages([fileUpload]);
  };

  if (!sidePanelState) return null;

  return (
    <div
      className={classNames("flex-col bg-white", {
        "border-l": window.innerWidth > config.width["patient-sidebar"],
        "z-side-panel absolute inset-y-0 right-0 shadow-sm-outlined":
          !isDesktop,
      })}
      style={{ width: `min(100%, ${config.width["patient-sidebar"]}px)` }}
    >
      {run(() => {
        switch (sidePanelState.mode) {
          case "LIBRARY":
            return (
              <Search
                experienceUuid={experienceUuid}
                onContentSelected={onContentSelected}
                onFileSelected={onFileSelected}
              />
            );
          case "PATIENT":
            return (
              <PatientInformationPanel
                patient={patient}
                onContentSelected={onContentSelected}
              />
            );
        }
      })}
    </div>
  );
};
