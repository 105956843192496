import { MouseEventHandler } from "react";
import gql from "graphql-tag";

import { AssignmentPopover } from "components/Assignment/AssignmentPopover";
import { AvatarList } from "components/Avatar/AvatarList";
import { Icon } from "components/Icon/Icon";
import {
  AssignToQAExperience as AssignToQAExperienceMutation,
  DoctorSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTargetState } from "hooks";
import { useTranslation } from "i18n";

import { Maybe } from "../../../../../base-types";
import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";

gql`
  mutation AssignToQAExperience(
    $experienceUuid: UUID!
    $doctorUuids: [UUID!]!
  ) {
    assignToQAExperience(
      experienceUuid: $experienceUuid
      doctorUuids: $doctorUuids
    ) {
      experience {
        uuid
        assignedDoctors {
          ...DoctorSummary
        }
      }
    }
  }
`;

export const ExperienceAssignmentMenu = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  const { experience } = useQAExperience();
  const [assign, assigning] = useMutation(AssignToQAExperienceMutation);
  const [target, setTarget] = useTargetState();

  return (
    <>
      <DoctorInput
        currentDoctors={experience.assignedDoctors}
        disabled={assigning || disabled}
        onClick={setTarget}
      />
      {target && (
        <AssignmentPopover
          assignedDoctors={experience.assignedDoctors}
          assign={(selectedDoctorUuids) => {
            assign({
              doctorUuids: selectedDoctorUuids,
              experienceUuid: experience.uuid,
            });
          }}
          target={target}
          setTarget={setTarget}
        />
      )}
    </>
  );
};

const DoctorInput = ({
  currentDoctors,
  disabled,
  onClick,
}: {
  currentDoctors: Maybe<DoctorSummaryFragment[]>;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const t = useTranslation();
  return (
    <button
      data-test="assignment-button"
      className="h-28 flex items-center rounded-[6px] pl-6 pr-4 text-grey-300 text-12 space-x-2 hover:bg-grey-100 caret-transparent border"
      onClick={onClick}
      disabled={disabled}
    >
      {currentDoctors?.isNotEmpty() && (
        <AvatarList size={20} users={currentDoctors} />
      )}
      <span>
        {currentDoctors?.isNotEmpty()
          ? ""
          : t("inboxes.qa_experience.conversation_unassigned")}
      </span>
      <Icon
        name="chevron"
        rotate={90}
        className="hover:opacity-80 ml-auto"
        tabIndex={-1}
      />
    </button>
  );
};
