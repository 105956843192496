import { Media, useMediaWindows } from "atoms/useMediaWindows";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDownload } from "hooks/useDownload";
import { useTranslation } from "i18n";
import { downloadFile } from "utils/file";

import { isRemoteMedia } from "./mediaWindowsModel";

export const MediaHeader = ({ media }: { media: Media }) => {
  const t = useTranslation();
  const [download, downloading] = useDownload();
  const { closeMedia } = useMediaWindows();

  return (
    <div className="flex items-center bg-grey-100 px-14">
      <h4 className="text-13 text-grey-400 font-medium py-14 leading-none flex-fill truncate">
        {"title" in media ? media.title : media.fileName}
      </h4>
      <div className="flex ml-auto space-x-6">
        <TooltipWrapper
          label={t("media_windows.download_the_document")}
          position="left"
        >
          <ClickableIcon
            name={downloading ? "change" : "download"}
            className="hover:bg-grey-200 rounded-sm p-2"
            iconClassName={downloading ? "animate-spin" : ""}
            onClick={() => {
              if (isRemoteMedia(media)) {
                download(
                  media.url,
                  "title" in media ? media.title : media.fileName,
                );
              } else {
                downloadFile(media.blob, media.title);
              }
            }}
          />
        </TooltipWrapper>
        <ClickableIcon
          name="close"
          className="hover:bg-grey-200 rounded-sm p-2"
          onClick={() => closeMedia()}
        />
      </div>
    </div>
  );
};
