import { useEffect } from "react";

import { useDoctor } from "contexts/User/UserContext";
import { TypingFragment } from "generated/provider";
import { useRerender } from "hooks/useRerender";

export const useTypingIndicators = (
  { typingDoctors, typingPatients }: TypingFragment,
  onTypingChange?: () => void,
) => {
  const { user } = useDoctor();
  const rerender = useRerender();
  const doctorsIndicators = typingDoctors.filter(
    (d) => user.uuid !== d.doctor.uuid && d.typingAt.secondsFromNow() < 30,
  );

  const patientsIndicators = typingPatients.filter(
    (p) => user.uuid !== p.patient.uuid && p.typingAt.secondsFromNow() < 30,
  );

  const nbIndicators = doctorsIndicators.length + patientsIndicators.length;

  useEffect(() => {
    if (!nbIndicators) return;
    onTypingChange?.();
    const intervalId = setInterval(rerender, 2000);
    return () => clearInterval(intervalId);
  }, [onTypingChange, rerender, nbIndicators]);

  return { doctorsIndicators, patientsIndicators };
};
