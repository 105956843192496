import { createContext, useContext } from "react";

import { ExperienceResult } from "types";

export type ChatWidgets = {
  openChatWidget: (
    targetExperience: Pick<
      ExperienceResult,
      "uuid" | "isClosed" | "assignedDoctors"
    >,
  ) => void;
  openNewChatWidget: () => void;
  closeNewChatWidget: () => void;
};

export const ChatWidgetsContext = createContext<ChatWidgets | null>(null);

ChatWidgetsContext.displayName = "ChatWidgetsContext";

export const useChatWidgets = () => {
  const context = useContext(ChatWidgetsContext);
  if (!context) throw new Error("No ChatWidgetsContextProvider");
  return context;
};
