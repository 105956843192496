import gql from "graphql-tag";

import { AllUpcomingAppointments } from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import { useRerenderAt } from "hooks/useRerender";
import { appointmentImminentAt } from "utils/appointment";

gql`
  query AllUpcomingAppointments {
    me {
      doctor {
        ...AllUpcomingAppointments
      }
    }
  }
`;

export const useImminentAppointments = () => {
  const { data } = useQuery(AllUpcomingAppointments);
  const appointments = data?.doctor.allUpcomingAppointments ?? [];

  useRerenderAt(
    appointments
      .map((appointment) => appointmentImminentAt(appointment))
      .filter((startAt) => startAt.isFuture())
      .immutableSort()
      .at(0),
  );

  return appointments.filter(
    (appointment) =>
      appointmentImminentAt(appointment).isPast() &&
      appointment.state.__typename === "AppointmentStateScheduled",
  );
};
