import { useState } from "react";
import { Link, useMatch } from "react-router-dom";

import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { getPatientViewRoute } from "utils";
import { displayUser } from "utils/display";

import { useImminentAppointments } from "./useImminentAppointments";

export const AppointmentReminders = () => {
  const t = useTranslation();
  const currentPatientView = useMatch(`${routes.PATIENT_LIST}/:patientUuid`);
  const appointments = useImminentAppointments();
  const [hiddenAppointments, setHiddenAppointments] = useState<UUID[]>([]);

  return (
    <div className="fixed inset-bl-20 flex-col space-y-20 z-modal">
      {appointments
        .filter(
          (appointment) =>
            !hiddenAppointments.includes(appointment.uuid) &&
            currentPatientView?.params.patientUuid !== appointment.patient.uuid,
        )
        .map((appointment) => (
          <div
            key={appointment.uuid}
            className="bg-white border rounded shadow-sm-outlined flex items-start"
            style={{ width: 350 }}
          >
            <div className="bg-primary-50 rounded-full text-primary m-10 p-10">
              <Icon
                name={
                  appointment.location.__typename ===
                  "RemoteAppointmentLocation"
                    ? "videoOn"
                    : "medicalOffice"
                }
                size={20}
              />
            </div>
            <div className="flex-col items-start flex-fill my-10 mr-10">
              <span className="text-primary-dark">
                {t(
                  "appointment_reminders.appointment_reminders.appointment_with_",
                )}

                <Link
                  className="font-semibold hover:underline"
                  to={getPatientViewRoute(appointment.patient)}
                >
                  {displayUser(appointment.patient)}
                </Link>
              </span>
              <span className="text-14">
                {appointment.startAt.isBeforeToday() ? (
                  <>
                    {appointment.startAt.format({
                      exception: "dd MMMM yyyy",
                    })}{" "}
                    {t("scheduling.list.appointment_list.at")}{" "}
                    {appointment.startAt.format("time")}
                  </>
                ) : (
                  appointment.startAt.format({
                    relative: "timeOrDate",
                  })
                )}
              </span>
              <Button
                label={t("appointment_reminders.appointment_reminders.join")}
                className="mt-12"
                to={getPatientViewRoute(appointment.patient, [appointment])}
              />
            </div>
            <div className="flex-col">
              <ClickableIcon
                name="close"
                className="flex-fill p-14"
                onClick={() =>
                  setHiddenAppointments((previousUuids) => [
                    ...previousUuids.without(appointment.uuid),
                    appointment.uuid,
                  ])
                }
              />
            </div>
          </div>
        ))}
    </div>
  );
};
