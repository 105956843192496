import { useEffect, useRef, useState } from "react";

import { useDisplayTimeElapsed } from "hooks/useDisplayTimeElapsed";
import { useSyncRef } from "hooks/useSyncRef";

import { now } from "./date";

const TIMESLICE = 5000; // Data will be sent from the microphone recorder at
// least every 5 seconds, allowing to save it.

const blobsToAudioFile = (blobs: Blob[]) =>
  new File(blobs, `${now().format("file")}.m4a`, {
    type: "audio/mp4",
  });

export const useMicrophoneRecorder = ({
  onFinalFileProduced,
  onIntermediaryFileProduced,
}: {
  onFinalFileProduced: (
    file: File,
    recordingStartDate: Date,
    recordingUuid: UUID,
  ) => Promise<void> | void;
  onIntermediaryFileProduced?: (
    file: File,
    recordingStartDate: Date,
    recordingUuid: UUID,
  ) => void;
}) => {
  const recordingStartDateRef = useRef(new Date());
  const [recorder, setRecorder] = useState<any>(null);
  const onFinalFileProducedRef = useSyncRef(onFinalFileProduced);
  const onIntermediaryFileProducedRef = useSyncRef(onIntermediaryFileProduced);
  const timeEllapsed = useDisplayTimeElapsed({
    from: recordingStartDateRef.current.toISOString(),
  });

  const startRecording = (
    recordingUuid: UUID,
    deviceId: string | undefined,
  ) => {
    navigator.mediaDevices
      .getUserMedia({ audio: deviceId ? { deviceId } : true })
      .then((microphone) => {
        const context = new AudioContext();
        const output = context.createMediaStreamDestination();
        context.createMediaStreamSource(microphone).connect(output);
        const newRecorder = new MediaRecorder(output.stream);
        const chunks: Blob[] = [];
        newRecorder.ondataavailable = (e: BlobEvent) => {
          chunks.push(e.data);
          onIntermediaryFileProducedRef.current?.(
            blobsToAudioFile(chunks),
            recordingStartDateRef.current,
            recordingUuid,
          );
        };
        newRecorder.onstop = () => {
          const file = blobsToAudioFile(chunks);
          microphone.getAudioTracks().forEach((t) => t.stop());
          onFinalFileProducedRef.current(
            file,
            recordingStartDateRef.current,
            recordingUuid,
          );
          setRecorder(null);
        };
        setRecorder(newRecorder);
        recordingStartDateRef.current = new Date();
        newRecorder.start(TIMESLICE);
      });
  };

  // In case this hook is unmounted (navigation to another page typically)
  // stop recording.
  const recorderRef = useSyncRef(recorder);
  useEffect(() => () => recorderRef.current?.stop(), [recorderRef]);

  return {
    startRecording,
    stopRecording: recorder ? () => recorder.stop() : undefined,
    initializing: !recorder,
    timeEllapsed: recorder ? timeEllapsed : undefined,
  };
};
