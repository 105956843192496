import { useState } from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

import { Button } from "components/Button/Button";
import { Query } from "components/Query/Query";
import { Redirect } from "components/Routes/Redirect";
import { Separator } from "components/Separator/Separator";
import { useUser } from "contexts/User/UserContext";
import { AppointmentTypes } from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { SchedulingSettingsCategories } from "./SchedulingSettingsCategories";
import { SchedulingSettingsConsents } from "./SchedulingSettingsConsents";
import { SchedulingSettingsPayment } from "./SchedulingSettingsPayment";
import { SchedulingSettingsTypes } from "./SchedulingSettingsTypes";

export const SchedulingSettings = () => {
  const t = useTranslation();
  const [newType, setNewType] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const { hasPermission, hasAccessToGatekeeperFeature } = useUser();
  const canShowTypesAndCategories = hasPermission(
    "EDIT_APPOINTMENT_CATEGORIES",
  );
  const canShowPayment =
    hasAccessToGatekeeperFeature("SCHEDULING_PAYMENT") &&
    hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS");

  const queryParam = useMatch(
    `${routes.SETTINGS}/${routes.SCHEDULING_SETTINGS}/:schedulingSettingsTab*`,
  )?.params.schedulingSettingsTab.toUpperCase();
  const schedulingSettingsTab =
    queryParam &&
    ["TYPES", "CATEGORIES", "CONSENTS", "PAYMENT"].includes(queryParam)
      ? queryParam
      : "TYPES";

  if (
    (["TYPES", "CATEGORIES"].includes(schedulingSettingsTab) &&
      !canShowTypesAndCategories) ||
    (["PAYMENT"].includes(schedulingSettingsTab) && !canShowPayment)
  ) {
    return <Redirect to={routes.SCHEDULING_SETTINGS_CONSENTS} />;
  }

  return (
    <div className="flex-col flex-fill bg-light-blue overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold pt-16 lg:pt-24 ">
          {t("settings.scheduling.title")}
        </h1>

        <div className="flex justify-between">
          <div className="flex">
            {canShowTypesAndCategories && (
              <Link
                className={classNames("mr-24 hover:cursor-pointer py-16", {
                  "text-primary font-semibold border-b-2 border-primary":
                    schedulingSettingsTab === "TYPES",
                })}
                to={routes.SCHEDULING_SETTINGS_TYPES}
              >
                {t("settings.scheduling.types.title")}
              </Link>
            )}
            {canShowTypesAndCategories && (
              <Link
                className={classNames("mr-24 hover:cursor-pointer py-16", {
                  "text-primary font-semibold border-b-2 border-primary":
                    schedulingSettingsTab === "CATEGORIES",
                })}
                to={routes.SCHEDULING_SETTINGS_CATEGORIES}
              >
                {t("settings.scheduling.categories.title")}
              </Link>
            )}
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  schedulingSettingsTab === "CONSENTS",
              })}
              to={routes.SCHEDULING_SETTINGS_CONSENTS}
            >
              {t("settings.scheduling.consents.title")}
            </Link>
            {canShowPayment && (
              <Link
                className={classNames("mr-24 hover:cursor-pointer py-16", {
                  "text-primary font-semibold border-b-2 border-primary":
                    schedulingSettingsTab === "PAYMENT",
                })}
                to={routes.SCHEDULING_SETTINGS_PAYMENT}
              >
                {t("settings.scheduling.price.evaluation.title")}
              </Link>
            )}
          </div>
          <div>
            <div className="flex space-x-8 flex-shrink ml-auto py-4">
              {schedulingSettingsTab === "CATEGORIES" && (
                <Query query={AppointmentTypes}>
                  {(types) => (
                    <Button
                      label={t("settings.scheduling.new_category")}
                      onClick={() => {
                        setNewCategory(true);
                      }}
                      disabled={types.isEmpty()}
                    />
                  )}
                </Query>
              )}
              {schedulingSettingsTab === "TYPES" && (
                <Button
                  label={t("settings.scheduling.new_type")}
                  onClick={() => {
                    setNewType(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="flex-col p-16 lg:px-44">
        {schedulingSettingsTab === "TYPES" && (
          <SchedulingSettingsTypes
            newType={newType}
            onHideNewType={() => setNewType(false)}
          />
        )}
        {schedulingSettingsTab === "CATEGORIES" && (
          <SchedulingSettingsCategories
            newCategory={newCategory}
            onHideNewCategory={() => setNewCategory(false)}
          />
        )}
        {schedulingSettingsTab === "CONSENTS" && <SchedulingSettingsConsents />}
        {schedulingSettingsTab === "PAYMENT" && <SchedulingSettingsPayment />}
      </div>
    </div>
  );
};
