import {
  MentionedItemInput,
  PatientNoteSectionFragment,
  PatientNoteSectionInput,
  TextWithMentionsInput,
} from "generated/provider";
import { omitTypename } from "graphql-client/utils";
import { TFunction } from "i18n";
import {
  AllowedMentionList,
  DraftMentionedItem,
  MentionedItem,
  NoteSection,
  TextWithMentions,
} from "types";

import { isKnownUnionValue } from "./apollo";
import { now } from "./date";
import { notifier } from "./notifier";

export const noteSectionsFromPatientNoteSection = (
  gqlSections: PatientNoteSectionFragment[],
): NoteSection[] =>
  gqlSections.mapNotNull((section) => {
    if (!isKnownUnionValue("PatientNoteSectionContent", section.content)) {
      return null;
    }
    return { uuid: section.uuid, content: section.content };
  });

export const isContentEqual = (
  sections1: NoteSection[],
  sections2: NoteSection[],
) => {
  if (sections1.length !== sections2.length) return false;
  for (const [index, section1] of sections1.entries()) {
    const content1 = section1.content;
    const content2 = sections2[index].content;

    switch (content1.__typename) {
      case "TextSectionContent":
        if (content2.__typename !== "TextSectionContent") return false;
        if (content1.textWithMentions.text !== content2.textWithMentions.text) {
          return false;
        }
        if (
          content1.textWithMentions.mentions.map((it) => it.uuid).join("") !==
          content2.textWithMentions.mentions.map((it) => it.uuid).join("")
        ) {
          return false;
        }
        break;
      case "DiagnoseSectionContent":
        if (content2.__typename !== "DiagnoseSectionContent") return false;
        if (content1.icd10Codes.join(" ") !== content2.icd10Codes.join(" ")) {
          return false;
        }
        break;
    }
  }
  return true;
};

export const getSectionInput: (
  section: NoteSection,
) => PatientNoteSectionInput = (section: NoteSection) => {
  switch (section.content.__typename) {
    case "TextSectionContent":
      return {
        textSection: {
          textWithMentions: getTextWithMentionsInput(
            section.content.textWithMentions,
          ),
          category: section.content.category,
        },
        diagnoseSection: null,
      };
    case "DiagnoseSectionContent":
      return {
        textSection: null,
        diagnoseSection: {
          icd10Codes: section.content.icd10Codes,
        },
      };
  }
};

const actualMentionedItem = (mentionedItem: MentionedItem) => {
  if (mentionedItem.__typename === "DraftMentionedItem") {
    return mentionedItem.draftedItem;
  }
  return mentionedItem;
};

export const getTextWithMentionsInput = (
  richText: TextWithMentions,
  allowedMentions?: AllowedMentionList,
  dateTime = now(),
): TextWithMentionsInput => {
  // Replace every unsupported mentions by "unsupported mention"
  // This code should be useless though, since if they are not
  // supported, the editor should not have supported them in the
  // first place

  const mentionsToReplace = allowedMentions
    ? richText.mentions.filter(
        (mention) =>
          !mention.mentionedItem ||
          !allowedMentions[
            actualMentionedItem(mention.mentionedItem).__typename
          ],
      )
    : [];

  let newText = richText.text;
  mentionsToReplace.forEach(
    (mentionToReplace) =>
      (newText = newText.replace(
        `[_NABLA_MENTION_](_NABLA_MENTION_:${mentionToReplace.mentionIdentifier})`,
        "Unsupported mention",
      )),
  );

  return {
    text: newText,
    mentions: richText.mentions.mapNotNull((mention) => {
      if (
        allowedMentions &&
        mention.mentionedItem &&
        !allowedMentions[actualMentionedItem(mention.mentionedItem).__typename]
      ) {
        return null;
      }
      return (
        getMentionedItemInput(mention.mentionedItem, dateTime)?.let((it) => ({
          mentionIdentifier: mention.mentionIdentifier,
          mentionedItem: it,
          displayString: mention.displayString,
        })) ?? null
      );
    }),
  };
};

const getMentionedItemInput = (
  item: MentionedItem | null,
  dateTime: ISOString,
): MentionedItemInput | null => {
  if (!item) return null;
  if (item.__typename === "DraftMentionedItem") {
    return getDraftMentionedItemInput(item, dateTime);
  }
  if (!isKnownUnionValue("MentionedItem", item)) return null;
  switch (item.__typename) {
    case "MentionedPatientDocument":
      if (
        !isKnownUnionValue("MaybePatientDocument", item.maybePatientDocument)
      ) {
        return null;
      }
      switch (item.maybePatientDocument.__typename) {
        case "DeletedPatientDocument":
        case "InaccessiblePatientDocument":
          return null;
        case "PatientDocument":
          return { document: { uuid: item.maybePatientDocument.uuid } };
      }
    case "HealthDataPoint":
      return {
        datapoint: {
          uuid: item.uuid,
        },
      };
    case "Symptom":
      return {
        symptom: {
          uuid: item.uuid,
        },
      };
    case "Condition":
      return {
        condition: {
          uuid: item.uuid,
        },
      };
    case "PatientAllergy":
      return {
        allergy: {
          uuid: item.uuid,
        },
      };
    case "MedicationStatement":
      return {
        medication: {
          uuid: item.uuid,
        },
      };
    case "VaccinationStatement":
      return {
        vaccination: {
          uuid: item.uuid,
        },
      };
    case "Procedure":
      return {
        procedure: {
          uuid: item.uuid,
        },
      };
    case "PubliclyListedDrug":
      return { publiclyListedDrug: { cip13: item.cip13 } };
    case "MentionedDoctor":
      if (!isKnownUnionValue("MaybeDoctor", item.maybeDoctor)) return null;
      switch (item.maybeDoctor.__typename) {
        case "DeletedDoctor":
        case "InaccessibleDoctor":
          return null;
        case "Doctor":
          return { doctor: { uuid: item.maybeDoctor.uuid } };
      }
    case "PatientNote":
      return { note: { uuid: item.uuid } };
    case "MentionedPatient":
      if (!isKnownUnionValue("MaybePublicPatient", item.maybePublicPatient)) {
        return null;
      }
      switch (item.maybePublicPatient.__typename) {
        case "DeletedPatient":
          return null;
        case "PublicPatient":
          return { patient: { uuid: item.maybePublicPatient.uuid } };
      }
    case "DeletedProcedure":
      return { deletedProcedure: {} };
    case "DeletedSymptom":
      return { deletedSymptom: {} };
    case "DeletedCondition":
      return { deletedCondition: {} };
    case "DeletedDatapoint":
      return { deletedDatapoint: {} };
    case "DeletedMedication":
      return { deletedMedication: {} };
    case "DeletedVaccination":
      return { deletedVaccination: {} };
    case "DeletedAllergy":
      return { deletedAllergy: {} };
    case "Experience":
      return null;
  }
};

const getDraftMentionedItemInput = (
  draftMentionedItem: DraftMentionedItem,
  dateTime: ISOString,
): MentionedItemInput | null => {
  const item = draftMentionedItem.draftedItem;
  switch (item.__typename) {
    case "Symptom":
      return { newSymptom: { name: item.name } };
    case "Condition":
      return {
        newCondition: {
          name: item.type,
          familyMember: item.familyMember,
        },
      };
    case "PatientAllergy":
      return { newAllergy: { name: item.type } };
    case "MedicationStatement":
      return { newMedication: { name: item.name } };
    case "VaccinationStatement":
      return { newVaccination: { type: item.type } };
    case "Procedure":
      return { newProcedure: { name: item.name } };
    case "HealthDataPoint":
      if (!isKnownUnionValue("HealthDataPointValue", item.value)) return null;
      switch (item.value.__typename) {
        case "BloodPressureValue":
          return {
            newDataPoint: {
              dataPoint: {
                bloodPressureInput: omitTypename(item.value),
              },
              dateTime,
            },
          };
        case "SimpleHealthDataPointValue":
          return {
            newDataPoint: {
              dataPoint: {
                simpleDataPointInput: omitTypename(item.value),
              },
              dateTime,
            },
          };
      }
  }
};

// Unfortunately there is currently no way to predict the number of items
// inserted from backend response
// TODO: return number of items created from backend to make things easier.
export const notifyNoteCreationOrUpdate = (
  t: TFunction, // Translation engine, named "t" everywhere.
  sections: NoteSection[],
  isUpdate: boolean,
) => {
  const numDraftMentions = sections
    .mapNotNull((it) => {
      if (it.content.__typename !== "TextSectionContent") return null;
      return it.content;
    })
    .flatMap((it) => it.textWithMentions.mentions)
    .count((it) => it.mentionedItem?.__typename === "DraftMentionedItem");

  notifier.success(
    isUpdate
      ? t(
          "inboxes.qa_experience.ehr_composer.note_composer.note_composer.note_updated_with_medical_records",
          { count: numDraftMentions },
        )
      : t(
          "inboxes.qa_experience.ehr_composer.note_composer.note_composer.note_published_with_medical_records",
          { count: numDraftMentions },
        ),
  );
};
