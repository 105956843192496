import { IcdCodePicker } from "components/Form/MedicalCodes/IcdCodePicker";
import { NoteSection } from "types";

import { NoteMentionPill } from "../MentionEditor/MentionPill";
import { RichText } from "../RichText/RichText";
import { getSectionTitle } from "./utils";

type NoteSectionsViewerProps = {
  title: string | null; // Title not used in Note card export
  sections: NoteSection[];
  highlightSearch?: string;
};

// A lightweight version of note sections.
export const NoteSectionsViewer = ({
  title,
  sections,
  highlightSearch,
}: NoteSectionsViewerProps) => (
  <div className="flex-col space-y-20 text-black">
    {title && <div className="font-medium text-16">{title}</div>}
    {sections.map((section, index) =>
      section.content.__typename === "DiagnoseSectionContent" ? (
        <IcdCodePicker
          key={index}
          value={section.content.icd10Codes}
          onChange={() => void 0}
          disabled
        />
      ) : (
        <div className="flex-col legacy-page-break-avoid" key={index}>
          {section.content.category !== "FREE_TEXT" && (
            <div className="font-medium">
              {getSectionTitle(section.content.category)}
            </div>
          )}
          <RichText
            source={section.content.textWithMentions}
            Mention={NoteMentionPill}
            hasMarkdown
            highlightSearch={highlightSearch}
          />
        </div>
      ),
    )}
  </div>
);
