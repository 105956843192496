import { useState } from "react";
import { gql } from "@apollo/client";
import classNames from "classnames";
import { round } from "lodash";

import { Avatar } from "components/Avatar/Avatar";
import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { Label } from "components/Form/Label/Label";
import { TextArea } from "components/Form/TextArea/TextArea";
import { Modal } from "components/Modal/Modal";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Table } from "components/Table/Table";
import {
  GetMLModels,
  MLModelFragment,
  MLModelState as MLModelStateEnum,
  TagPatientText,
  TrainNewTaggingModel,
} from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";
import { useMutation } from "graphql-client/useMutation";
import { useIsDesktop } from "hooks/useMediaQuery";
import { staticT, useTranslation } from "i18n";
import { Translation } from "i18n/Translation";
import { addMLModelInCache } from "utils/apollo";

gql`
  fragment MLModel on MLModel {
    uuid
    type
    state
    createdAt
    updatedAt
    trainingFinishedAt
    metrics {
      type
      value
    }
  }

  query GetMLModels($cursor: String) {
    getMLModels(page: { cursor: $cursor, numberOfItems: 30 }) {
      data {
        ...MLModel
      }
      nextCursor
      hasMore
    }
  }

  query TagPatientText($modelUuid: UUID!, $patientText: String!) {
    tagDialog(
      input: {
        modelUuid: $modelUuid
        dialog: [{ sender: PATIENT, message: $patientText }]
      }
    ) {
      tags
    }
  }

  mutation TrainNewTaggingModel {
    trainNewTaggingModel {
      model {
        ...MLModel
      }
    }
  }
`;

const elapsedTimeFormat = (elapsedTime: number) => {
  const hours = Math.floor(elapsedTime / 3600);
  const minutes = Math.floor((elapsedTime - hours * 3600) / 60);
  const seconds = Math.ceil(elapsedTime - hours * 3600 - minutes * 60);
  return hours > 0
    ? `${hours}h ${minutes}mn`
    : minutes > 0
    ? `${minutes} minute${minutes > 1 ? "s" : ""}`
    : `${seconds} ${staticT("settings.ml_models.ml_models.second")}${
        seconds > 1 ? "s" : ""
      }`;
};

const TestModal = ({
  model,
  onHide,
}: {
  model: MLModelFragment;
  onHide: () => void;
}) => {
  const [text, setText] = useState("");
  const [tagPatientText, { loading: loadingResults, data }] =
    useLazyQuery(TagPatientText);
  const t = useTranslation();

  return (
    <Modal onHide={onHide} title={`Test model ${model.uuid.slice(0, 8)}`}>
      <div className="flex-col items-start w-full mt-16">
        <Label label={t("settings.ml_models.ml_models.situation")} useDiv />
        <div className="flex items-end mt-12">
          <Avatar user={undefined} />
          <span className="text-primary-dark font-medium ml-12">
            {t("settings.ml_models.ml_models.new_patient_says")}
          </span>
        </div>
        <div className="pl-40 mt-12 w-full">
          <TextArea
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
          <Button
            loading={loadingResults}
            disabled={loadingResults}
            onClick={() =>
              tagPatientText({ modelUuid: model.uuid, patientText: text })
            }
            label={t("settings.ml_models.ml_models.tag")}
            className="ml-auto mt-12"
            style={{ width: 120 }}
          />
        </div>
        <Label label={t("settings.ml_models.ml_models.results")} useDiv />
        {data?.tags.isNotEmpty() ? (
          <ul>
            {data.tags.map((tag) => (
              <li key={tag}>{tag}</li>
            ))}
          </ul>
        ) : (
          <div>{t("settings.ml_models.ml_models.no_results")}</div>
        )}
      </div>
    </Modal>
  );
};

export const MlModels = () => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const [retrain] = useMutation(TrainNewTaggingModel, {
    onSuccess: ({ model }, client) => {
      addMLModelInCache(client, model, /* atTheBeginning =*/ true);
    },
  });
  const [modelBeingTested, setModelBeingTested] = useState<
    MLModelFragment | undefined
  >(undefined);
  const [hideButton, setHideButton] = useState(false);

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      {modelBeingTested && (
        <TestModal
          model={modelBeingTested}
          onHide={() => setModelBeingTested(undefined)}
        />
      )}
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("settings.ml_models.ml_models.machine_learning_models")}
        </h1>
        <div>
          <Translation
            k="settings.ml_models.ml_models.machine_learning_explanation"
            components={{ p: ({ children }) => <p children={children} /> }}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex-col flex-1">
          {!hideButton && (
            <Button
              className={isDesktop ? "ml-auto" : "mt-12"}
              label={t("settings.ml_models.ml_models.retrain")}
              onClick={() => retrain()}
            />
          )}
        </div>
      </div>
      <PaginatedQuery query={GetMLModels}>
        {(data, utils) => (
          <>
            <Table
              elements={data.data}
              fieldHeaders={[
                {
                  id: "uuid",
                  name: t("settings.ml_models.ml_models.uuid"),
                  sortable: false,
                },
                {
                  id: "status",
                  name: t("settings.ml_models.ml_models.status"),
                  sortable: false,
                  noWrap: true,
                },
                {
                  id: "type",
                  name: t("settings.ml_models.ml_models.type"),
                  sortable: false,
                  noWrap: true,
                },
                {
                  id: "creation",
                  name: t("settings.ml_models.ml_models.creation"),
                  sortable: false,
                  noWrap: true,
                },
                {
                  id: "trainingTime",
                  name: t("settings.ml_models.ml_models.training_time"),
                  sortable: false,
                  noWrap: true,
                },
                {
                  id: "microF1",
                  name: t("settings.ml_models.ml_models.micro_f"),
                  sortable: false,
                  noWrap: true,
                },
              ]}
              fields={(model) => [
                <div key={model.uuid} style={{ minWidth: 170 }}>
                  {model.uuid}
                </div>,
                <MLModelState key={model.uuid} state={model.state} />,
                model.type,
                model.createdAt.format({ relative: "timeOrDate" }),
                model.trainingFinishedAt
                  ? `${elapsedTimeFormat(
                      model.trainingFinishedAt.secondsFrom(model.createdAt),
                    )}`
                  : "-",
                model.metrics
                  .find((m) => m.type === "MICRO_F1")
                  ?.let((metric) => round(metric.value, 2)) ?? "-",
              ]}
              menuItems={(model) => [
                {
                  icon: "program",
                  text: t("settings.ml_models.ml_models.test"),
                  onClick: (close: () => any) => {
                    setModelBeingTested(model);
                    close();
                  },
                },
              ]}
              onShowDetails={(show) => setHideButton(show)}
            />
            <NextPageButton {...utils} />
          </>
        )}
      </PaginatedQuery>
    </Background>
  );
};

const MLModelState = ({ state }: { state: MLModelStateEnum }) => (
  <div className={classNames("flex items-center font-medium")}>
    <span>{state.toUpperCase()}</span>
    <StateBadge state={state} />
  </div>
);

export const StateBadge = ({ state }: { state: MLModelStateEnum }) => (
  <div
    className={classNames("rounded-full mx-8", {
      "bg-green": state === "READY",
      "bg-orange animate-ping": state === "TRAINING",
      "bg-danger": state === "ERROR",
      "bg-grey": state === "PENDING",
    })}
    style={{ height: 12, width: 12 }}
  />
);
