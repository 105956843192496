import { useState } from "react";
import classNames from "classnames";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { IconName } from "icon-library";

type FormValues = {
  title: string;
  text: string;
};

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

export const EmptyTemplatesPlaceholder = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => (
  <div className="flex-col w-full flex-center space-y-4 my-80">
    <div className="text-primary-dark font-bold text-18">{title}</div>
    <div>{subtitle}</div>
  </div>
);

export const TemplateSettingsForm = ({
  template,
  onHide,
  icon,
  titlePlaceholder,
  textPlaceholder,
  submitLabel,
  deleteLabel,
  deletionConfirmationModal,
  onSubmit,
  loading,
  onDelete,
}: {
  template?: { uuid: UUID; title: string; text: string };
  icon: { name: IconName; colorsClassNames: string };
  titlePlaceholder: string;
  textPlaceholder: string;
  submitLabel: string;
  deleteLabel: string;
  deletionConfirmationModal: { title: string; suffix: string };
  onSubmit: (values: FormValues) => Promise<any>;
  loading: boolean;
  onDelete: (uuid: UUID) => Promise<any>;
  onHide: () => void;
}) => {
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>(undefined);

  return (
    <>
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={deletionConfirmationModal.title}
          suffix={deletionConfirmationModal.suffix}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <Form<FormValues>
        className="flex-fill bg-white rounded border"
        initialValues={{
          title: template?.title ?? "",
          text: template?.text ?? "",
        }}
        validationSchema={{
          title: "required",
          text: "required",
        }}
        onSubmit={onSubmit}
      >
        <div className="flex items-center h-64 space-x-16 border-b pt-1 px-16">
          <div
            className={classNames(
              "rounded-[11px] w-32 h-32 flex-center",
              icon.colorsClassNames,
            )}
          >
            <Icon size={20} name={icon.name} />
          </div>
          <div className="mr-48 flex-fill leading-[20px]">
            <FormInput
              inlineError="bottom"
              name="title"
              className="bg-transparent w-full !text-primary-dark"
              placeholder={titlePlaceholder}
            />
          </div>
        </div>
        <div className="flex items-center space-x-16 border-b pt-1 px-16">
          <div className="ml-48 m-48 mt-20 mb-20 flex-fill leading-[20px]">
            <FormTextArea
              name="text"
              className="border-0 bg-transparent w-full !text-primary-dark"
              placeholder={textPlaceholder}
            />
          </div>
        </div>
        <div className="flex-col space-y-20 p-28 ml-36 mr-36 ">
          <div className="flex justify-between">
            <Button
              label={deleteLabel}
              danger
              secondary
              onClick={() => {
                template === undefined
                  ? onHide()
                  : setConfirmationModalState({
                      onConfirm: (close) => {
                        close();
                        return onDelete(template.uuid);
                      },
                    });
              }}
            />
            <FormState<FormValues>>
              {({ values }) => (
                <Submit
                  label={submitLabel}
                  loading={loading}
                  disabled={
                    template !== undefined &&
                    template.title === values.title &&
                    template.text === values.text
                  }
                />
              )}
            </FormState>
          </div>
        </div>
      </Form>
    </>
  );
};
