import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "auth/AuthContext";
import { Select } from "components/Form/Select/Select";
import { REDIRECT_TO_KEY } from "consts";
import { useSchemaHttpLink } from "graphql-client/useSchemaHttpLink";
import { getConfig } from "utils/environment";

import { PlaygroundButton } from "./PlaygroundButton";
import { playgroundColors, selectStyleProps } from "./playgroundStyles";

const introspectionEnabled = getConfig({
  dev: true,
  pr: true,
  staging: true,
  preprod: false,
  prod: false,
});

const AUTH_ENDPOINT = "provider/graphql/authenticated";

export const PlaygroundApp = () => {
  const navigate = useNavigate();

  // We are outside the GraphQL context, so we can't use useLogout.
  const auth = useAuth();
  const isLoggedIn = auth.state === "LOGGED_IN";

  // The request context for authenticated schemas comes from the `AuthContext`.
  const authenticatedHttpLink = useSchemaHttpLink("PROVIDER", "web-playground");

  const [endpoint, setEndpoint] = useState(AUTH_ENDPOINT);
  const endpoints = isLoggedIn ? [AUTH_ENDPOINT] : [];

  useEffect(() => {
    const node = document.getElementById("favicon") as HTMLLinkElement;
    node.href =
      "https://cdn.jsdelivr.net/npm/graphql-playground-react/build/favicon.png";
    document.title = "GraphQL playground";
  }, []);

  useEffect(() => {
    if (!introspectionEnabled) return;
    // The npm package (graphql-playground-react) is not compatible with Vite,
    // so we lazy-load a compiled version from a CDN. It's a full IDE with Prettier
    // and many other things (2MB without gzip), so it should be lazy loaded anyway.
    import(
      // @ts-ignore
      // eslint-disable-next-line import/no-unresolved
      "https://cdn.jsdelivr.net/npm/graphql-playground-react/build/static/js/middleware.js"
    ).then(() => {
      // @ts-ignore
      window.GraphQLPlayground.init(
        document.getElementById("playground-root"),
        {
          // Types: https://github.com/graphql/graphql-playground/blob/master/packages/graphql-playground-react/src/components/PlaygroundWrapper.tsx
          // But sometimes wrong: https://github.com/prisma-labs/graphql-playground/issues/981
          createApolloLink: () => ({
            link: authenticatedHttpLink,
          }),
          settings: { "schema.polling.enable": false },
          setTitle: false,
        },
      );
    });
  }, [endpoint, authenticatedHttpLink]);

  return (
    <>
      <div
        className="flex px-10 py-6 space-x-6"
        style={{ background: playgroundColors.lightBackground }}
      >
        <Select
          {...selectStyleProps}
          name="endpoint"
          wrapperClassName="flex-fill"
          options={endpoints}
          value={endpoint}
          onChange={setEndpoint}
        />
        {isLoggedIn ? (
          <PlaygroundButton
            onClick={() => {
              auth.logout().then(() => navigate("login"));
            }}
            label="Logout"
          />
        ) : (
          <PlaygroundButton
            onClick={() => {
              storage.setItem(REDIRECT_TO_KEY, window.location.pathname);
              navigate("login");
            }}
            label="Login"
          />
        )}
      </div>
      <div
        id="playground-root"
        className="flex-fill"
        style={{ backgroundColor: playgroundColors.backgroundColor }}
      >
        <div
          className="h-full flex-center"
          style={{ color: playgroundColors.color }}
        >
          {introspectionEnabled
            ? "Loading..."
            : "Not available for this environment"}
        </div>
      </div>
    </>
  );
};
