import { HTMLAttributes } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { DoctorSummaryFragment } from "generated/provider";
import { displayDoctor, displayDoctorTitle } from "utils/display";

import styles from "./userRow.module.css";

export const DoctorRow = ({
  doctor,
  nameClassName,
  className,
  mode,
  ...props
}: {
  doctor: DoctorSummaryFragment;
  nameClassName?: string;
  mode?: "FIRST_NAME" | "WITH_PREFIX";
} & HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames(styles.user, className)} {...props}>
    <Avatar user={doctor} />
    <div className="flex-fill flex-col">
      <div className={classNames(styles.name, nameClassName)}>
        {displayDoctor(doctor, mode)}
      </div>
      <div className="text-11 truncate">{displayDoctorTitle(doctor)}</div>
    </div>
  </div>
);
