import { RefObject } from "react";
import gql from "graphql-tag";

import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  CreateCondition,
  CreateMedicationStatement,
  CreatePatientAllergy,
  CreateProcedure,
  CreateSymptom,
  CreateVaccinationStatement,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { EHRAtomicInputType } from "types";
import { notifier } from "utils/notifier";

import { FooterWrapper } from "./FooterWrapper";
import { useEHRInput } from "./useEHRInput";

gql`
  mutation CreateSymptom($patientUuid: UUID!, $name: String!) {
    createSymptom(patientUuid: $patientUuid, input: { name: $name }) {
      symptom {
        ...Symptom
      }
    }
  }

  mutation CreateMedicationStatement(
    $patientUuid: UUID!
    $name: String!
    $description: String!
    $status: MedicationStatus!
    $dosage: String
    $startedAt: GranularDateTimeInput
    $endedAt: GranularDateTimeInput
  ) {
    createMedicationStatement(
      patientUuid: $patientUuid
      input: {
        name: $name
        description: $description
        status: $status
        dosage: $dosage
        startedAt: $startedAt
        endedAt: $endedAt
      }
    ) {
      medicationStatement {
        ...MedicationStatement
      }
    }
  }

  mutation CreateVaccinationStatement(
    $patientUuid: UUID!
    $type: String!
    $description: String!
    $status: VaccinationStatus!
    $administratedAt: GranularDateTimeInput
    $programmedAt: GranularDateTimeInput
  ) {
    createVaccinationStatement(
      patientUuid: $patientUuid
      input: {
        type: $type
        description: $description
        status: $status
        administratedAt: $administratedAt
        programmedAt: $programmedAt
      }
    ) {
      vaccinationStatement {
        ...VaccinationStatement
      }
    }
  }

  mutation CreatePatientAllergy(
    $patientUuid: UUID!
    $input: CreatePatientAllergyInput!
  ) {
    createPatientAllergy(patientUuid: $patientUuid, input: $input) {
      patientAllergy {
        ...PatientAllergy
      }
    }
  }

  mutation CreateCondition($patientUuid: UUID!, $input: CreateConditionInput!) {
    createCondition(patientUuid: $patientUuid, input: $input) {
      condition {
        ...Condition
      }
    }
  }

  mutation CreateProcedure($patientUuid: UUID!, $input: CreateProcedureInput!) {
    createProcedure(patientUuid: $patientUuid, input: $input) {
      procedure {
        ...Procedure
      }
    }
  }
`;

export const EHRAtomicInput = ({
  type,
  inputRef,
}: {
  type: EHRAtomicInputType;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { initialEHRInput, setEHRInput, resetEHRInput } = useEHRInput();
  const { patient, setEHRComposerState } = usePatient();

  const [createMedication] = useMutation(CreateMedicationStatement, {
    onSuccess: () => {
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.ehr_atomic_input.medication_added",
        ),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });
  const [createVaccination] = useMutation(CreateVaccinationStatement, {
    onSuccess: () => {
      notifier.success(
        t("inboxes.qa_experience.ehr_composer.ehr_atomic_input.vaccine_added"),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });
  const [createSymptom] = useMutation(CreateSymptom, {
    onSuccess: () => {
      notifier.success(
        t("inboxes.qa_experience.ehr_composer.ehr_atomic_input.symptom_added"),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });
  const [createPatientAllergy] = useMutation(CreatePatientAllergy, {
    onSuccess: () => {
      notifier.success(
        t("inboxes.qa_experience.ehr_composer.ehr_atomic_input.allergy_added"),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });

  const [createCondition] = useMutation(CreateCondition, {
    onSuccess: () => {
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.ehr_atomic_input.condition_added",
        ),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });

  const [createProcedure] = useMutation(CreateProcedure, {
    onSuccess: () => {
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.ehr_atomic_input.procedure_added",
        ),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });

  return (
    <Form<{ text: string }>
      initialValues={{ text: initialEHRInput.atomicInput }}
      validationSchema={{ text: "required" }}
      onSubmit={({ text }) => {
        switch (type) {
          case "SYMPTOM":
            return createSymptom({ patientUuid: patient.uuid, name: text });
          case "VACCINE":
            return createVaccination({
              patientUuid: patient.uuid,
              type: text,
              description: "",
              status: "UNKNOWN",
            });
          case "MEDICATION":
            return createMedication({
              patientUuid: patient.uuid,
              name: text,
              description: "",
              status: "UNKNOWN",
            });
          case "ALLERGY":
            return createPatientAllergy({
              patientUuid: patient.uuid,
              input: { type: text },
            });
          case "SURGICAL":
            return createProcedure({
              patientUuid: patient.uuid,
              input: {
                name: text,
                comment: "",
              },
            });
          case "MEDICAL":
            return createCondition({
              patientUuid: patient.uuid,
              input: {
                type: text,
                description: "",
              },
            });
        }
      }}
      className="flex-fill flex-col"
    >
      <FormInput
        name="text"
        autoFocus
        inputRef={inputRef}
        wrapperClassName="my-auto"
        onChange={(e) => setEHRInput({ atomicInput: e.target.value })}
        placeholder={
          {
            ALLERGY: t(
              "inboxes.qa_experience.ehr_composer.ehr_atomic_input.allergy",
            ),
            MEDICATION: t(
              "inboxes.qa_experience.ehr_composer.ehr_atomic_input.medicine",
            ),
            SURGICAL: t(
              "inboxes.qa_experience.ehr_composer.ehr_atomic_input.surgery_history",
            ),
            MEDICAL: t(
              "inboxes.qa_experience.ehr_composer.ehr_atomic_input.medical_history",
            ),
            VACCINE: t(
              "inboxes.qa_experience.ehr_composer.ehr_atomic_input.vaccine",
            ),
            SYMPTOM: t(
              "inboxes.qa_experience.ehr_composer.ehr_atomic_input.symptom",
            ),
            PRESCRIPTION: t("patient_view.prescription"),
          }[type]
        }
      />
      <FooterWrapper
        submitLabel={t(
          "inboxes.qa_experience.ehr_composer.ehr_atomic_input.create",
        )}
        inputRef={inputRef}
      />
    </Form>
  );
};
