import { useDraft } from "atoms/useDraft";
import { NoteAnnotationFragment } from "generated/provider";
import { NoteAnnotationInput } from "types";

export const useNoteAnnotationDraft = (
  recordedConversationUuid: UUID,
  noteAnnotation?: NoteAnnotationFragment,
) => {
  const { store, setDraft } = useDraft();

  const draftUuid = noteAnnotation
    ? noteAnnotation.uuid
    : recordedConversationUuid;

  const noteAnnotationDraft: NoteAnnotationInput | undefined = noteAnnotation
    ? store[noteAnnotation.uuid]?.noteAnnotation ?? noteAnnotation
    : store[recordedConversationUuid]?.noteAnnotation;

  const noteAnnotationInputWithDefaults: NoteAnnotationInput = {
    chiefComplaint: noteAnnotationDraft?.chiefComplaint ?? "",
    allergies: noteAnnotationDraft?.allergies ?? "",
    assessment: noteAnnotationDraft?.assessment ?? "",
    appointments: noteAnnotationDraft?.appointments ?? "",
    familyHistory: noteAnnotationDraft?.familyHistory ?? "",
    historyIllness: noteAnnotationDraft?.historyIllness ?? "",
    imagingResults: noteAnnotationDraft?.imagingResults ?? "",
    labResults: noteAnnotationDraft?.labResults ?? "",
    medicalHistory: noteAnnotationDraft?.medicalHistory ?? "",
    medications: noteAnnotationDraft?.medications ?? "",
    physicalExam: noteAnnotationDraft?.physicalExam ?? "",
    plan: noteAnnotationDraft?.plan ?? "",
    prescription: noteAnnotationDraft?.prescription ?? "",
    surgicalHistory: noteAnnotationDraft?.surgicalHistory ?? "",
    symptoms: noteAnnotationDraft?.symptoms ?? "",
    vaccines: noteAnnotationDraft?.vaccines ?? "",
    vitals: noteAnnotationDraft?.vitals ?? "",
    diagnostic: noteAnnotationDraft?.diagnostic ?? "",
    socialHistory: noteAnnotationDraft?.socialHistory ?? "",
  };

  const setNoteAnnotation = (payload: Partial<NoteAnnotationInput>) => {
    setDraft(draftUuid, {
      noteAnnotation: {
        ...(noteAnnotationDraft ?? noteAnnotationInputWithDefaults),
        ...payload,
      },
    });
  };

  const removeNoteAnnotation = () => {
    setDraft(draftUuid, {
      noteAnnotation: undefined,
    });
  };

  return {
    setNoteAnnotation,
    noteAnnotationInputWithDefaults,
    removeNoteAnnotation,
  };
};
