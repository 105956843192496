import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { RECENT_EMOJIS } from "consts";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useStorageState } from "hooks/useStorageState";
import { useTranslation } from "i18n";
import { stableObjectEntries } from "utils";

import allEmojis from "./emojis.json";

export const EmojiPicker = ({
  className,
  onSelect,
}: {
  onSelect: (emoji: string) => void;
  className?: string;
}) => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const { recentEmojis, saveEmoji } = useRecentEmojis();

  // Small touch devices have custom keyboard with emojis
  if (!isDesktop && "ontouchstart" in window) return null;

  return (
    <UncontrolledPopover
      position={["top-right", "bottom-right"]}
      allowScrolling
      content={(close) => (
        <div
          className="bg-white rounded border p-12 flex-col space-y-12 overflow-auto"
          style={{ width: 300, height: 300 }}
        >
          {[
            ...(recentEmojis.isEmpty()
              ? []
              : [["recent", recentEmojis] as const]),
            ...stableObjectEntries(allEmojis),
          ].map(([category, emojis]) => (
            <div key={category} className="flex-col items-start">
              <div className="mb-4 label text-14">
                {
                  {
                    recent: t("emoji_picker.emoji_picker.recently_used"),
                    smileys: t("emoji_picker.emoji_picker.smileys"),
                    animals: t("emoji_picker.emoji_picker.animals__nature"),
                    food: t("emoji_picker.emoji_picker.food__drinks"),
                    travel: t("emoji_picker.emoji_picker.travel__places"),
                    activities: t("emoji_picker.emoji_picker.activities"),
                    objects: t("emoji_picker.emoji_picker.objects"),
                    symbols: t("emoji_picker.emoji_picker.symbols"),
                    flags: t("emoji_picker.emoji_picker.flags"),
                  }[category]
                }
              </div>
              <div className="flex-wrap gap-x-4 gap-y-2 text-18">
                {emojis.map((emoji) => (
                  <button
                    key={emoji}
                    onClick={() => {
                      onSelect(emoji);
                      saveEmoji(emoji);
                      close();
                    }}
                  >
                    {emoji}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    >
      {({ setTarget, opened }) => (
        <ClickableIcon
          name="happy"
          onClick={setTarget}
          className={classNames(className, { "text-primary": opened })}
        />
      )}
    </UncontrolledPopover>
  );
};

const useRecentEmojis = () => {
  const [recentEmojis, setRecentEmoji] = useStorageState<string[]>(
    RECENT_EMOJIS,
    [],
  );

  return {
    recentEmojis,
    saveEmoji: (emoji: string) => {
      const newList = [emoji].concat(recentEmojis).distinct().slice(0, 10);
      setRecentEmoji(newList);
    },
  };
};
