import gql from "graphql-tag";
import { useParams } from "react-router";

import { Query } from "components/Query/Query";
import { NoteSectionNormalization } from "generated/provider";

import { NoteNormalisationComposer } from "./NoteSectionNormalizationComposer";

gql`
  fragment NoteSectionNormalization on NoteSectionNormalization {
    uuid
    createdAt
    type
    content
    medicalHistoryAnnotation {
      code
      start {
        dateTime
        granularity
      }
      end {
        dateTime
        granularity
      }
      status
      familyMember
    }
    vitalAnnotation {
      type
      value
      unit
      textualRepresentation
    }
    isLabeled
    labeler {
      ...DoctorSummary
    }
    locale
  }

  query NoteSectionNormalization($uuid: UUID!) {
    noteSectionNormalization(uuid: $uuid) {
      noteSectionNormalization {
        ...NoteSectionNormalization
      }
    }
  }
`;

export const NoteNormalizationPage = () => {
  const { noteSectionUuid } = useParams();

  return (
    <div className="bg-light-blue flex items-start justify-between flex-fill overflow-auto">
      <div className="flex-col flex-fill p-20 space-y-20">
        <Query
          query={NoteSectionNormalization}
          variables={{ uuid: noteSectionUuid }}
        >
          {(data) => (
            <NoteNormalisationComposer
              currentNormalization={data.noteSectionNormalization}
            />
          )}
        </Query>
      </div>
    </div>
  );
};
