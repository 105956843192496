import { differenceInYears } from "date-fns";

import { shadowMode } from "atoms/useShadowMode";
import { NablaRegion, NablaRegionKnownValues } from "generated/account";
import {
  DoctorRole,
  DoctorSummaryFragment,
  ExperienceSummaryFragment,
  FamilyMemberDegreeKnownValue,
  MessageSenderType,
  PatientSummaryFragment,
  QuestionsSetFormState,
  SexKnownValue,
  SupportedLocaleKnownValue,
  TaskPriority,
  TaskPriorityKnownValue,
  TaskPriorityKnownValues,
  UserSummaryFragment,
} from "generated/provider";
import { staticT as t } from "i18n";

import { isKnownUnionValue } from "./apollo";
import { isKnownValue } from "./enum";

export const pluralize = (quantity: number, word: string, plural?: string) =>
  plural
    ? `${quantity} ${quantity > 1 ? plural : word}`
    : `${quantity} ${word}${quantity > 1 ? "s" : ""}`;

export const displayEnumValue = <E extends string>(
  value: E | "FUTURE_VALUE",
  labels: { [key in E]: string },
): string => {
  if (Object.keys(labels).includes(value)) {
    // @ts-ignore
    return labels[value];
  }
  return `[${t("utils.display.unknown")}] 🔄`;
};

export const SHADOW_USERNAME = "xxxxx";
export const SHADOW_FIRST_NAME = "Xxxxx";
export const SHADOW_LAST_NAME = "Xxxxxxx";
export const SHADOW_NAME = `${SHADOW_FIRST_NAME} ${SHADOW_LAST_NAME}`;
export const SHADOW_NAME_FOR_INPUT = t("utils.display.name_replaced_on_send");

export const displayPatient = (
  patient: { username: string; firstName?: string; lastName?: string } | null,
) => {
  if (!patient) return deletedPatient;
  return patient.firstName?.isNotEmpty() && patient.lastName?.isNotEmpty()
    ? displayFullName(patient)
    : patient.username.isNotEmpty()
    ? displayUsername(patient)
    : displayFirstOrLastName(patient);
};

export const deletedPatient = t("utils.display.deleted_patient");

export const displayFullName = (
  patient: { firstName?: string; lastName?: string } | null,
) => {
  if (!patient) return deletedPatient;
  if (!patient.firstName || !patient.lastName) {
    throw new Error("firstName and lastName should not be null");
  }
  return shadowMode()
    ? SHADOW_NAME
    : `${patient.firstName} ${patient.lastName}`;
};

export const displayUsername = (
  patient: { username: string } | null,
): string => {
  if (!patient) return deletedPatient;
  return shadowMode() ? SHADOW_USERNAME : patient.username;
};

export const displayFirstOrLastName = (
  patient: { firstName?: string; lastName?: string } | null,
) => {
  if (!patient) return deletedPatient;
  if (shadowMode()) return SHADOW_NAME;
  if (patient.firstName?.isNotEmpty()) return patient.firstName;
  if (patient.lastName?.isNotEmpty()) return patient.lastName;
  return "";
};

export const displayPatientForInput = (patient: {
  username: string;
  firstName?: string;
}) =>
  shadowMode()
    ? SHADOW_NAME_FOR_INPUT
    : patient.firstName
    ? patient.firstName
    : patient.username;

export const getDefaultMessageIntro = (patient: {
  username: string;
  firstName?: string;
}) => `${t("utils.display.hello")} ${displayPatientForInput(patient)}, `;

export const displayUser = (
  user: UserSummaryFragment | null,
  mode?: "FIRST_NAME",
) => {
  if (!user) return t("utils.display.deleted_user");
  if (user.__typename === "Doctor") return displayDoctor(user, mode);
  if (!isKnownUnionValue("User", user)) return "Unknown user";
  return displayPatient(user);
};

export const displayMessageSender = (message: {
  sender: UserSummaryFragment | null;
  senderType: MessageSenderType;
}) =>
  message.senderType === "SYSTEM"
    ? t("utils.display.system_message")
    : displayUser(message.sender);

export const displayAge = (birthDate: LocalDate | null) => {
  if (!birthDate) return "-";
  const parsedBirthDate = birthDate.toDateAtUtcMidnight();
  const years = differenceInYears(new Date(), parsedBirthDate);
  return t("utils.display.years_old", { count: years });
};

export const displaySex = (sex: SexKnownValue) => {
  switch (sex) {
    case "MALE":
      return t("utils.display.male");
    case "FEMALE":
      return t("utils.display.female");
    case "OTHER":
      return t("utils.display.other");
  }
};

export const displayDoctor = (
  doctor: DoctorSummaryFragment | null,
  mode?: "FIRST_NAME" | "WITH_PREFIX",
) => {
  if (!doctor) return t("utils.display.deleted_practitioner");
  if (mode === "FIRST_NAME") return doctor.firstName;
  const deactivated =
    "deactivated" in doctor && doctor.deactivated
      ? ` (${t("utils.display.deactivated")})`
      : "";
  const prefix =
    mode === "WITH_PREFIX" && doctor.prefix ? `${doctor.prefix} ` : "";
  return `${prefix}${doctor.firstName} ${doctor.lastName}${deactivated}`;
};

// Util to to format a phone number.
// Note that this only supports french number starting with 33 and will display
// the raw number in all other cases.
export const displayPhoneNumber = (raw: string): string => {
  if (!raw.startsWith("33")) return raw;
  return raw.replace(/33(\d)(\d{2})(\d{2})(\d{2})(\d{2})/u, "0$1 $2 $3 $4 $5");
};

export const sortUsers = ({
  doctors,
  patient,
}: {
  doctors: DoctorSummaryFragment[];
  patient?: PatientSummaryFragment | null;
}) => {
  const sortedDoctors = doctors.sortAsc((a) => a.uuid);
  return [patient ?? [], sortedDoctors].flat();
};

export const displayExperienceTitle = (
  experience: ExperienceSummaryFragment,
  userUuid: UUID,
) => {
  if (experience.type === "SINGLE_PATIENT_CONVERSATION") {
    return experience.title
      ? `${displayPatient(experience.patient)} ∙ ${experience.title}`
      : displayPatient(experience.patient);
  }
  if (experience.title) return experience.title;
  return displayExperienceParticipants(experience, userUuid);
};

const MAX_DISPLAY_NAMES = 4;
export const displayExperienceParticipants = (
  experience: ExperienceSummaryFragment,
  userUuid: UUID,
) => {
  const participants = sortUsers({
    patient: experience.patient,
    doctors: experience.allDoctors.filter((doc) => doc.uuid !== userUuid),
  });

  if (participants.isEmpty()) return t("utils.display.empty");

  if (participants.length === 1) return displayUser(participants[0]);

  const names = participants
    .slice(0, MAX_DISPLAY_NAMES)
    .map((p) => displayUser(p, "FIRST_NAME"))
    .join(", ");

  if (participants.length <= MAX_DISPLAY_NAMES) return names;
  return `${names}, +${participants.length - MAX_DISPLAY_NAMES}`;
};

export const displayDoctorRole = (role: DoctorRole) =>
  displayEnumValue(role, {
    NABLA_MEDICAL_STAFF: t("utils.display.practitioner"),
    NABLA_ADMINISTRATOR: t("utils.display.administrator"),
    REVIEWER: t("utils.display.reviewer"),
    LABELLER: t("utils.display.labeller"),
  });

export const displayPublicDoctorRole = (role: DoctorRole) =>
  displayEnumValue(role, {
    NABLA_MEDICAL_STAFF: t("utils.display.practitioner"),
    NABLA_ADMINISTRATOR: t("utils.display.administrator"),
    REVIEWER: t("utils.display.reviewer"),
    LABELLER: t("utils.display.labeller"),
  });

export const displayDoctorTitle = (doctor: DoctorSummaryFragment) =>
  doctor.title ??
  (doctor.roles.isNotEmpty()
    ? displayDoctorRole(doctor.roles[0])
    : t("utils.display.no_role"));

export const displayFamilyMemberDegree = (
  familyMemberDegree: FamilyMemberDegreeKnownValue,
) =>
  ({
    MOTHER: t("utils.display.mother"),
    FATHER: t("utils.display.father"),
    BROTHER: t("utils.display.brother"),
    SISTER: t("utils.display.sister"),
    SON: t("utils.display.son"),
    DAUGHTER: t("utils.display.daughter"),
    GRANDMOTHER: t("utils.display.grandmother"),
    GRANDFATHER: t("utils.display.grandfather"),
    AUNT: t("utils.display.aunt"),
    UNCLE: t("utils.display.uncle"),
    OTHER: t("utils.display.other"),
  }[familyMemberDegree]);

export const displayTaskPriority = (taskPriority: TaskPriority) => {
  if (!isKnownValue(taskPriority, TaskPriorityKnownValues)) return null;
  return displayTaskPriorityKnownValue(taskPriority);
};

export const displayTaskPriorityKnownValue = (
  taskPriority: TaskPriorityKnownValue,
) => {
  switch (taskPriority) {
    case "URGENT":
      return t("utils.display.urgent");
    case "HIGH":
      return t("utils.display.high");
    case "MEDIUM":
      return t("utils.display.medium");
    case "LOW":
      return t("utils.display.low");
  }
};

export const displayTaskColor = (taskPriority: TaskPriority) => {
  if (!isKnownValue(taskPriority, TaskPriorityKnownValues)) return null;

  switch (taskPriority) {
    case "URGENT":
      return "text-danger";
    case "HIGH":
      return "text-warning-300";
    case "MEDIUM":
      return "text-blue-300";
    case "LOW":
      return "text-green";
  }
};

// Shorten integers to K M B format.
export const displayBigInteger = (bigNumber: number) => {
  if (bigNumber < 1000) return bigNumber.toFixed(0);
  if (bigNumber < 1000000) return `${(bigNumber / 1000).toFixed(1)}K`;
  if (bigNumber < 1000000000) return `${(bigNumber / 1000000).toFixed(1)}M`;
  return `${(bigNumber / 1000000000).toFixed(1)}B`;
};

export const displayQuestionsSetState = (state: QuestionsSetFormState) =>
  displayEnumValue(state, {
    NOT_STARTED: t(
      "inboxes.qa_experience.questions_set.item.status.no_started",
    ),
    IN_PROGRESS: t(
      "inboxes.qa_experience.questions_set.item.status.in_progress",
    ),
    ANSWERED: t("inboxes.qa_experience.questions_set.item.status.completed"),
  });

export const displayLocale = (locale: SupportedLocaleKnownValue) => {
  switch (locale) {
    case "FRENCH":
      return "Français";
    case "ENGLISH":
      return "English";
    case "PORTUGUESE":
      return "Português";
  }
};

export const displayRegionFlag = (region: NablaRegion) => {
  if (!isKnownValue(region, NablaRegionKnownValues)) return "❓";
  return {
    EU: "🇪🇺",
    US: "🇺🇸",
  }[region];
};
