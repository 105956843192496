import { ReactNode } from "react";
import { useDropzone } from "react-dropzone";

import { Icon } from "components/Icon/Icon";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

export const MediaPicker = ({
  disabled,
  className,
  children,
}: {
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}) => {
  const t = useTranslation();
  const { mediaMessages, setMediaMessages } = useExperienceDraft();
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 100_000_000,
    disabled,
    noDrag: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.isNotEmpty()) {
        notifier.error({
          user: t("composer_parts.media_picker.files_too_big"),
        });
      }
      setMediaMessages(
        mediaMessages.concat(acceptedFiles.map((file) => ({ file }))),
      );
    },
  });

  return (
    <button {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      <Icon name="clip" className={disabled ? "opacity-70" : undefined} />
      {children}
    </button>
  );
};
