import { ReactNode } from "react";

type MentionsProps<Option extends unknown> = {
  header?: string;
  getOptionId?: (o: Option) => string;
  getOptionLabel: (o: Option) => string;
  display?: (props: {
    option: Option;
    label: string;
    index: number;
    selected: boolean;
  }) => ReactNode;
  skipFuzzyMatchFiltering?: boolean;
  filter?: (option: Option) => boolean;
  onSelected: (o: Option) => void;
  minWidth?: number;
  emptyLabel?: string;
};

export type MentionsSubOptions<Option extends any> = MentionsProps<Option> & {
  data: Option[];
};

export type DataWithSubOptions<Option extends any> = {
  type: Option;
  subOptions: MentionsSubOptions<unknown>;
};

export type MentionsOptionsProps<Option extends any> = MentionsProps<Option> & {
  data: (DataWithSubOptions<Option> | Option)[];
};

export const getMentionOption = <Option extends unknown>(
  option: MentionsOptionsProps<Option>["data"][number],
): Option => (isDataWithSubOptions(option) ? option.type : option);

export const isDataWithSubOptions = <Option extends unknown>(
  option: MentionsOptionsProps<Option>["data"][number],
): option is DataWithSubOptions<Option> =>
  typeof option === "object" && "subOptions" in (option as any);
