import { RefObject } from "react";
import gql from "graphql-tag";

import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { FormSelect } from "components/Form/Select/FormSelect";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { CreateHealthDataPoint } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { HealthDataPointKnownValue, HealthDataPointKnownValues } from "types";
import {
  convertDataPointToMetricSystemIfNeeded,
  displayHealthDataPointName,
  displayHealthDataPointUnit,
  shouldBeImperialLengthFormat,
} from "utils/data-points";
import {
  bloodPressureValuesFromValidInput,
  isValidBloodPressure,
  isValidImperialLength,
  isValidNumericalInput,
} from "utils/form";
import { measurementSystem } from "utils/measurementSystem";
import { notifier } from "utils/notifier";

import { FooterWrapper } from "../FooterWrapper";
import { useEHRInput } from "../useEHRInput";

gql`
  mutation CreateHealthDataPoint(
    $patientUuid: UUID!
    $input: HealthDataPointInput!
  ) {
    createHealthDataPoint(patientUuid: $patientUuid, input: $input) {
      dataPoint {
        ...DataPoint
      }
    }
  }
`;

type FormValues = {
  type: HealthDataPointKnownValue | undefined;
  quantity: string;
};

export const MeasureComposer = ({
  inputRef,
}: {
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { initialEHRInput, setEHRInput, resetEHRInput } = useEHRInput();
  const { patient, setEHRComposerState } = usePatient();

  const [createDataPoint] = useMutation(CreateHealthDataPoint, {
    onSuccess: () => {
      setEHRComposerState(undefined);
      resetEHRInput();
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.ehr_data_point_composer.ehr_data_point_composer.data_point_added",
        ),
      );
    },
  });

  return (
    <Form<FormValues>
      initialValues={{
        type: initialEHRInput.dataPointType,
        quantity: initialEHRInput.dataPointValue,
      }}
      validationSchema={{
        type: "required",
      }}
      validate={({ quantity, type }) => {
        if (type === "BLOOD_PRESSURE_MMHG") {
          if (!isValidBloodPressure(quantity)) {
            return {
              quantity: t(
                "ehr_data_point_composer.format_should_be_systolicdiastolic",
              ),
            };
          }
        } else if (shouldBeImperialLengthFormat(type)) {
          if (!isValidImperialLength(quantity)) {
            return {
              quantity: t(
                "patient_view_side_section.should_be_in_ft_inch_format",
              ),
            };
          }
        } else if (!isValidNumericalInput(quantity)) {
          return {
            quantity: t("ehr_data_point_composer.should_be_a_number"),
          };
        }

        return {};
      }}
      onSubmit={async ({ type, quantity }) => {
        const bloodPressureInput =
          type === "BLOOD_PRESSURE_MMHG"
            ? bloodPressureValuesFromValidInput(quantity)
            : null;
        if (type && quantity) {
          await createDataPoint({
            patientUuid: patient.uuid,
            input: {
              simpleDataPointInput:
                type === "BLOOD_PRESSURE_MMHG"
                  ? null
                  : {
                      quantity: convertDataPointToMetricSystemIfNeeded(
                        quantity,
                        type,
                      ),
                      type,
                    },
              bloodPressureInput,
            },
          });
        }
      }}
      className="flex-fill flex-col"
    >
      <div className="flex items-center my-auto">
        <FormSelect
          name="type"
          wrapperClassName="w-1/2 mr-10"
          options={HealthDataPointKnownValues}
          getOptionLabel={(o) => displayHealthDataPointName(o)}
          onChange={(e) => setEHRInput({ dataPointType: e })}
        />
        <FormState<FormValues>>
          {({ values }) =>
            values.type ? (
              <FormInput
                wrapperClassName="flex-fill"
                name="quantity"
                inputRef={inputRef}
                autoFocus
                onChange={(e) =>
                  setEHRInput({ dataPointValue: e.target.value })
                }
                placeholder={
                  values.type === "BLOOD_PRESSURE_MMHG"
                    ? t(
                        "inboxes.qa_experience.ehr_composer.ehr_data_point_composer.ehr_data_point_composer.example_",
                      )
                    : (values.type === "HEIGHT_CM" ||
                        values.type === "WAIST_SIZE_CM") &&
                      measurementSystem === "IMPERIAL"
                    ? t(
                        "inboxes.qa_experience.ehr_composer.ehr_data_point_composer.ehr_data_point_composer.example_length_imperial",
                      )
                    : t(
                        "inboxes.qa_experience.ehr_composer.ehr_data_point_composer.ehr_data_point_composer.value",
                      )
                }
                rightInnerElementClassName="right-0 h-full "
                rightInnerElement={
                  <div
                    className="flex-center bg-grey-100 border rounded-r-sm text-primary-dark"
                    style={{ width: "80px" }}
                  >
                    {displayHealthDataPointUnit(values.type)}
                  </div>
                }
              />
            ) : null
          }
        </FormState>
      </div>
      <FooterWrapper
        submitLabel={t(
          "inboxes.qa_experience.ehr_composer.ehr_data_point_composer.ehr_data_point_composer.create",
        )}
        inputRef={inputRef}
      />
    </Form>
  );
};
