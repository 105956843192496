import { ReactNode, useState } from "react";
import gql from "graphql-tag";

import { EHRComposer } from "components/EHRComposer/EHRComposer";
import { Query } from "components/Query/Query";
import { GetPatient, GetPatientData } from "generated/provider";
import { EHRComposerState } from "types";

import { PatientContext } from "./PatientContext";

gql`
  query GetPatient($uuid: UUID!) {
    patient(patientUuid: $uuid) {
      patient {
        ...PatientSummary
        birthDate
        sex
        email
        phoneV2
        city
        address
        postcode
        healthcareSystemIdentifier
        state
        summary

        # For compatibility with QAExperience.Patient
        ...Devices
        hasCMU
        hasRegisteredDoctor
        country
        qaExperiencesCount
      }
    }
  }
`;

export const PatientProvider = ({
  uuid,
  children,
}: {
  uuid: UUID;
  children: ReactNode | ((data: GetPatientData) => JSX.Element);
}) => {
  const [ehrComposerState, setEHRComposerState] = useState<EHRComposerState>();
  return (
    <Query query={GetPatient} variables={{ uuid }}>
      {(data) => (
        <PatientContext.Provider
          value={{
            patient: data.patient,
            ehrComposerState,
            setEHRComposerState,
          }}
        >
          {typeof children === "function" ? children(data) : children}
          <EHRComposer />
        </PatientContext.Provider>
      )}
    </Query>
  );
};
