import { AllDoctors, AllDoctorsVariables } from "generated/provider";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";

import { AsyncFormSelect } from "./AsyncFormSelect";
import { DoctorRowOption } from "./DoctorRowOption";

export const AsyncDoctorFormSelect = ({
  placeholder,
  label,
  getVariables = (s) => ({ filter: { freeTextSearch: s } }),
  isClearable = false,
  wrapperClassName,
  name,
  disabled = false,
}: {
  name: string;
  wrapperClassName?: string;
  placeholder?: string;
  label?: string;
  getVariables?: (s: string) => AllDoctorsVariables;
  isClearable?: boolean;
  disabled?: boolean;
}) => {
  const t = useTranslation();

  return (
    <AsyncFormSelect
      selectProps={{
        name,
        isClearable,
        wrapperClassName,
        disabled,
        placeholder:
          placeholder ?? t("form.select.async_doctor_form_select.select"),
        CustomOption: DoctorRowOption,
        getOptionLabel: displayDoctor,
        label: label ?? t("form.select.async_doctor_form_select.practitioner"),
      }}
      queryProps={{
        query: AllDoctors,
      }}
      getOptions={(data) => data?.doctors ?? []}
      getVariables={getVariables}
    />
  );
};
