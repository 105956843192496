import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Separator } from "components/Separator/Separator";
import { Spinner } from "components/Spinner/Spinner";
import { useDoctor } from "contexts/User/UserContext";
import {
  AperoEligibilityCheck,
  CalendarAppointmentFragment,
  SexKnownValues,
} from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";
import { useMountDate } from "hooks";
import { useRerenderAt } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { getPatientViewRoute } from "utils";
import { appointmentImminentAt } from "utils/appointment";
import { displayAge, displayPatient, displaySex } from "utils/display";
import { isKnownValue } from "utils/enum";

gql`
  query AperoEligibilityCheck($appointmentUuid: UUID!) {
    aperoEligibilityCheck(appointmentUuid: $appointmentUuid) {
      isEligible
    }
  }
`;

export const AppointmentCard = ({
  appointment,
  style,
  lowerOpacityForPastEvents,
  otherAction,
  onClose,
}: {
  appointment: CalendarAppointmentFragment;
  style?: CSSProperties;
  lowerOpacityForPastEvents?: boolean;
  otherAction?: ReactNode;
  onClose?: () => void;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();

  const [
    aperoEligibilityCheck,
    { data: aperoEligibilityCheckData, loading: aperoEligibilityCheckLoading },
  ] = useLazyQuery(AperoEligibilityCheck);

  const now = useMountDate();
  useRerenderAt(appointmentImminentAt(appointment));

  return (
    <div
      style={style}
      className={classNames("flex-fill flex-col text-primary-dark", {
        "opacity-50":
          lowerOpacityForPastEvents && appointment.endAt.isBefore(now),
      })}
    >
      <div className="flex-col flex-fill">
        <div className="flex-col flex-fill">
          <div className="flex flex-fill items-center m-16">
            <Icon
              name={
                appointment.location.__typename === "RemoteAppointmentLocation"
                  ? "videoOn"
                  : "medicalOffice"
              }
              className="mr-8"
            />
            <div className="font-medium text-16">
              {t("scheduling.appointment_card.video_consultation")}
            </div>
            <div className="flex ml-auto text-body">
              {otherAction}
              <ClickableIcon
                className="py-0 px-2"
                name="close"
                onClick={onClose}
              />
            </div>
          </div>
          <Separator />
        </div>
      </div>
      <div className="flex-fill p-16 space-y-8">
        <div className="flex flex-fill text-primary-dark">
          <Icon name="profile" size={20} />
          <div className="flex-row flex-fill ml-4">
            <div className="flex flex-fill font-medium">
              <Link
                className="flex-fill truncate font-medium text-primary-dark hover:underline"
                to={getPatientViewRoute(appointment.patient)}
              >
                {displayPatient(appointment.patient)}
              </Link>
            </div>
            <div className="text-grey-300 text-12">
              {appointment.patient.sex &&
                isKnownValue(appointment.patient.sex, SexKnownValues) &&
                displaySex(appointment.patient.sex)}
              {appointment.patient.birthDate &&
                (appointment.patient.sex ? ", " : "") +
                  displayAge(appointment.patient.birthDate)}
            </div>
            <div className="text-grey-300 text-12">
              {appointment.patient.phoneV2
                ? appointment.patient.phoneV2
                : appointment.patient.email
                ? appointment.patient.email
                : ""}
            </div>
          </div>
        </div>
        {appointment.description && (
          <div className="flex flex-fill items-start mt-8 space-x-4">
            <Icon name="grid" size={20} />
            <span className="flex-fill font-medium leading-snug">
              {appointment.description}
            </span>
          </div>
        )}
        <div className="flex text-primary-dark">
          <Icon name="calendar" size={20} />
          <div className="flex-row ml-4">
            <div className="font-medium">
              {appointment.startAt.format("monthDay")}{" "}
              {appointment.startAt.format("monthAndYear")}
            </div>
            <div className="text-grey-300 text-12">
              {appointment.startAt.format("time")}{" "}
              {t("scheduling.appointment_card.to")}{" "}
              {appointment.endAt.format("time")}
            </div>
          </div>
        </div>
        {user.subOrganization.supportsApero && (
          <div className="flex-col mt-8">
            <div className="flex items-center space-x-4">
              <Icon name="dollar" size={20} />
              <span className="font-medium text-primary-dark">Insurance</span>
            </div>
            {aperoEligibilityCheckLoading ? (
              <Spinner />
            ) : (
              <div
                className="flex space-x-2 items-center"
                style={{ marginLeft: 22 }}
              >
                <ClickableIcon
                  onClick={() =>
                    aperoEligibilityCheck({ appointmentUuid: appointment.uuid })
                  }
                  name="refresh"
                  className="p-0"
                  size={20}
                />
                <div
                  className={classNames(
                    "flex-wrap w-full",
                    aperoEligibilityCheckData === undefined
                      ? "text-warning-300"
                      : aperoEligibilityCheckData.isEligible
                      ? "text-primary-dark"
                      : "text-danger",
                  )}
                >
                  {aperoEligibilityCheckData === undefined
                    ? "Not checked"
                    : aperoEligibilityCheckData.isEligible
                    ? "Eligible"
                    : "Not eligible"}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex px-16 pb-16">
        <Button
          className="h-32"
          label={t("appointment_card.see_appointment")}
          to={getPatientViewRoute(appointment.patient, [appointment])}
        />
      </div>
    </div>
  );
};
