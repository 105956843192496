import { HTMLProps, useRef } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { Backdrop } from "./Backdrop";

export const SidePanel = ({
  opened,
  onClickOutside,
  style,
  className,
  width = 470,
  children,
  ...props
}: {
  opened: boolean;
  width?: number;
  onClickOutside?: () => void;
} & Omit<HTMLProps<HTMLDivElement>, "ref">) => {
  // Variable that store true if opened has already been set to true
  const hasAlreadyBeenOpenedRef = useRef(false);
  if (opened && !hasAlreadyBeenOpenedRef.current) {
    hasAlreadyBeenOpenedRef.current = true;
  }

  return ReactDOM.createPortal(
    <>
      <Backdrop
        visible={opened}
        className="fixed z-side-panel inset-0"
        onClick={() => onClickOutside?.()}
      />
      <aside
        className={classNames(
          "absolute inset-y-0 right-0 z-side-panel bg-white shadow-sm-outlined flex-col transition-transform duration-300",
          {
            "border-l": window.innerWidth > width,
            "translate-x-full": !opened,
          },
          className,
        )}
        style={{
          width: `min(100%, ${width}px)`,
          ...style,
        }}
        {...props}
      >
        {hasAlreadyBeenOpenedRef.current && children}
      </aside>
    </>,
    document.getElementById("side-panel-root")!,
  );
};
