import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Modal } from "components/Modal/Modal";
import { Popover } from "components/Popover/Popover";
import { useDoctor } from "contexts/User/UserContext";
import { AvailabilityOccurrenceFragment } from "generated/provider";
import { useIsDesktop } from "hooks/useMediaQuery";

import { AvailabilityCard } from "../AvailabilityCard";

export const AvailabilityPopover = ({
  availabilityOccurrence,
  target,
  onClose,
  onDeleteAvailability,
}: {
  availabilityOccurrence: AvailabilityOccurrenceFragment;
  target: Element;
  onClose: () => void;
  onDeleteAvailability: (availabilityUuid: UUID) => void;
}) => {
  const { hasPermission } = useDoctor();
  const canDelete = hasPermission("EDIT_DOCTOR_AVAILABILITY");
  const isDesktop = useIsDesktop();

  const Delete = canDelete ? (
    <ClickableIcon
      name="trash"
      className="py-0 px-2"
      size={20}
      onClick={() => onDeleteAvailability(availabilityOccurrence.parent.uuid)}
    />
  ) : undefined;

  return isDesktop ? (
    <Popover target={target} position="left" allowScrolling onClose={onClose}>
      <AvailabilityCard
        availabilityOccurrence={availabilityOccurrence}
        style={{ width: 248 }}
        otherAction={Delete}
        onClose={onClose}
      />
    </Popover>
  ) : (
    <Modal onHide={onClose}>
      <AvailabilityCard
        availabilityOccurrence={availabilityOccurrence}
        style={{ width: "100%" }}
        otherAction={Delete}
        onClose={onClose}
      />
    </Modal>
  );
};
