import { staticT as t } from "i18n";
import { displayContraception } from "utils/medical-facts";

import { SuggestionWithStatus } from "./useLiveFactExtraction";

export const displayPrescriptionsDraft = (
  suggestions: SuggestionWithStatus[],
) => {
  const prescriptions = suggestions.mapNotNull((it) =>
    it.suggestion.__typename === "MedicationStatement" &&
    it.speaker === "DOCTOR" &&
    it.status === "ACCEPTED"
      ? it.suggestion.name
      : null,
  );
  return prescriptions.isNotEmpty() ? `#${prescriptions.join("\n#")}` : "";
};

export const displayReport = (suggestions: SuggestionWithStatus[]) => {
  const acceptedSuggestions = suggestions.filter(
    (it) => it.status === "ACCEPTED",
  );
  const medications = acceptedSuggestions.mapNotNull((it) =>
    it.suggestion.__typename === "MedicationStatement" &&
    it.speaker === "PATIENT"
      ? it.suggestion.name
      : null,
  );
  const medicationsPara = medications.isNotEmpty()
    ? `${t(
        "labs.live_speech_analysis.display.treatment",
      )}\n • ${medications.join("\n • ")}\n\n`
    : "";

  const prescriptions = acceptedSuggestions.mapNotNull((it) =>
    it.suggestion.__typename === "MedicationStatement" &&
    it.speaker === "DOCTOR"
      ? it.suggestion.name
      : null,
  );
  const prescriptionsPara = prescriptions.isNotEmpty()
    ? `${t(
        "labs.live_speech_analysis.display.to_be_prescribed",
      )} \n • ${prescriptions.join("\n • ")}\n\n`
    : "";

  const contraceptions = acceptedSuggestions.mapNotNull((it) =>
    it.suggestion.__typename === "Contraception"
      ? displayContraception(it.suggestion)
      : null,
  );
  const contraceptionsPara = contraceptions.isNotEmpty()
    ? `Contraception\n • ${contraceptions.join("\n • ")}\n\n`
    : "";

  const antecedents = acceptedSuggestions.mapNotNull((it) =>
    it.suggestion.__typename === "Condition" ? it.suggestion.type : null,
  );
  const antecedentsPara = antecedents.isNotEmpty()
    ? `${t(
        "labs.live_speech_analysis.display.symptomspmh",
      )}\n • ${antecedents.join("\n • ")}\n\n`
    : "";

  const allergies = acceptedSuggestions.mapNotNull((it) =>
    it.suggestion.__typename === "PatientAllergy" ? it.suggestion.type : null,
  );
  const allergiesPara = allergies.isNotEmpty()
    ? `Allergies\n • ${allergies.join("\n • ")}\n\n`
    : "";

  return [
    antecedentsPara,
    medicationsPara,
    contraceptionsPara,
    allergiesPara,
    prescriptionsPara,
  ].join("");
};
