// Complies with the API of react-big-calendar
// Created when clicking on an appointment, an availability or simply when manually selecting slot
import {
  AvailabilityOccurrenceFragment,
  CalendarAppointmentFragment,
} from "generated/provider";

export type CalendarEvent = {
  start: Date;
  end: Date;
  resources: {
    availabilityOccurrence?: AvailabilityOccurrenceFragment;
    appointment?: CalendarAppointmentFragment;
  };
};

export const availabilityOccurrenceToCalendarEvent = (
  availabilityOccurrence: AvailabilityOccurrenceFragment,
): CalendarEvent => ({
  start: availabilityOccurrence.startAt.getDate(),
  end: availabilityOccurrence.endAt.getDate(),
  resources: {
    availabilityOccurrence,
  },
});

export const appointmentToCalendarEvent = (
  appointment: CalendarAppointmentFragment,
): CalendarEvent => ({
  start: appointment.startAt.getDate(),
  end: appointment.endAt.getDate(),
  resources: { appointment },
});
