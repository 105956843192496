import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { useLivekitRoom } from "contexts/LivekitRoom/LivekitRoomContext";
import { UpdateAppointmentState } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

export const AppointmentCallEndScreen = ({
  appointmentUuid,
  rejoin,
}: {
  appointmentUuid: string;
  rejoin: () => void;
}) => {
  const t = useTranslation();
  const endScreen = useLivekitRoom().endScreen!;
  const [updateAppointmentState, mutating] = useMutation(
    UpdateAppointmentState,
  );

  return (
    <div
      className="fixed z-modal inset-br-16 bg-white border rounded-lg shadow-widget flex-col overflow-auto"
      style={{
        height: "min(330px, calc(100% - 32px))",
        width: "min(340px, calc(100% - 32px))",
      }}
    >
      <ClickableIcon
        name="close"
        className="absolute inset-tr-4"
        onClick={() => endScreen.close()}
      />
      <div className="my-auto p-24 flex-col relative">
        <div className="text-center font-bold text-24 text-grey-400 leading-[32px]">
          {t("appointment.end_screen.you_left_the_call")}
        </div>
        <div className="mt-32 flex-col space-y-12">
          <Button
            label={t("appointment.end_screen.mark_as_done")}
            leftIcon="check"
            disabled={mutating}
            onClick={async () => {
              await updateAppointmentState(
                {
                  appointmentUuid,
                  input: { finalized: {} },
                },
                {
                  onSuccess: () => {
                    endScreen.close();
                  },
                },
              );
            }}
          />
          <Button
            label={t("appointment.end_screen.mark_as_no_show")}
            leftIcon="profileCross"
            secondary
            disabled={mutating}
            onClick={async () => {
              await updateAppointmentState(
                {
                  appointmentUuid,
                  input: { noShow: {} },
                },
                {
                  onSuccess: () => {
                    endScreen.close();
                  },
                },
              );
            }}
          />
          <Button
            label={t("appointment.end_screen.rejoin_the_call")}
            leftIcon="videoOn"
            secondary
            disabled={mutating}
            onClick={() => {
              rejoin();
            }}
          />
        </div>
      </div>
    </div>
  );
};
