// utils around useDraft for notes
import { useDraft } from "atoms/useDraft";
import { NoteDraft } from "types";

const DEFAULT_DRAFT_KEY = "f40aa5bd-56f5-4ee0-835c-2d6d9281cbb8";

export const useNoteDraft = (patientUuid: UUID, draftKey: UUID | undefined) => {
  const { store, setDraft } = useDraft();

  const patientDraft = store[patientUuid];
  const actualDraftKey = draftKey ?? DEFAULT_DRAFT_KEY;

  return {
    noteDraft: patientDraft?.notes?.[actualDraftKey],
    setNoteDraft: (newDraft: NoteDraft) =>
      setDraft(patientUuid, {
        notes: {
          ...patientDraft?.notes,
          [actualDraftKey]: newDraft,
        },
      }),
    deleteNoteDraft: () => {
      const newNotes = {
        ...patientDraft?.notes,
        [actualDraftKey]: undefined,
      };
      setDraft(patientUuid, {
        notes: newNotes,
      });
    },
  };
};
