import { useState } from "react";

import {
  PatientDocumentFragment,
  PatientNoteFragment,
  PatientSummaryFragment,
} from "generated/provider";
import { useDebounceSearch } from "hooks/useDebounce";

import { Experience } from "../components/Experience";
import { PatientInformationPanelState } from "../types";
import { Document } from "./Document";
import { Note } from "./Note";
import { PatientInformation } from "./PatientInformation";

export const PatientInformationPanel = ({
  patient,
  onContentSelected,
}: {
  patient: Pick<PatientSummaryFragment, "uuid" | "__typename">;
  onContentSelected: ((value: string) => void) | null;
}) => {
  const [state, setState] = useState<PatientInformationPanelState>({
    mode: "INFORMATION",
  });
  const { search } = useDebounceSearch();

  return (
    <>
      {state.mode === "INFORMATION" ? (
        <PatientInformation patient={patient} />
      ) : state.mode === "EXPERIENCE" ? (
        <Experience
          experience={state.experience}
          onContentSelected={onContentSelected}
          back={() => setState({ mode: "INFORMATION" })}
          search={search}
        />
      ) : state.mode === "NOTE" ? (
        <Note
          timelineEventUuid={state.timelineEventUuid}
          note={state.note}
          back={() => setState({ mode: "INFORMATION" })}
          search={search}
          onUpdateSuccess={(newNote: PatientNoteFragment) =>
            setState({
              ...state,
              note: newNote,
            })
          }
        />
      ) : (
        <Document
          document={state.document}
          back={() => setState({ mode: "INFORMATION" })}
          onUpdateSuccess={(newDocument: PatientDocumentFragment) =>
            setState({
              ...state,
              document: newDocument,
            })
          }
        />
      )}
    </>
  );
};
