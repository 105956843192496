import { z } from "zod";

import { NablaRegionKnownValue } from "generated/account";
import { run } from "utils";
import {
  cloudRegionSchema,
  cloudRegionToNablaRegion,
  nablaRegionSchema,
} from "utils/environment";

// Returns the raw JSON payload of a JWT.
const decodeJwtPayload = (token: string) =>
  JSON.parse(atob(token.split(".")[1])!);

// Schema for the decoded payload of a JWT.
const jwtPayloadSchema = z.object({
  sub: z.string(),
  iss: z.string(),
  exp: z.number(),

  // At least one of the two region enums should be available:
  // - If the token is very old, only `cloud_region` will be available.
  // - If the token is recent, `nabla_region` will always be available,
  //   and `cloud_region` will be available if the token was issued
  //   before the backend stops issuing tokens with `cloud_region`.
  cloud_region: cloudRegionSchema.optional(),
  nabla_region: nablaRegionSchema.optional(),
});

// Login methods used when creating the account or doctor JWT.
// Keep in sync with `SessionLoginMethod.labelInJwtTokens` in `JwtUtils.kt`.
const sessionLoginMethodSchema = z
  .enum([
    "password_without_mfa",
    "password_with_mfa",
    "one_time_token_without_mfa",
    "one_time_token_with_mfa",
    "google",
    "organization_initiated",
    "one_time_token", // Kept temporarily for backwards compatibility.
  ])
  .transform(
    (method): Exclude<typeof method, "one_time_token"> =>
      method === "one_time_token" ? "one_time_token_with_mfa" : method,
  );

export type SessionLoginMethod = z.infer<typeof sessionLoginMethodSchema>;

export type Jwt = AccountJwt | DoctorJwt | ServerJwt | OneTimeLoginJwt;

export const jwtRegion = (jwt: Jwt): NablaRegionKnownValue =>
  jwt.payload.nabla_region ??
  jwt.payload.cloud_region?.let(cloudRegionToNablaRegion) ??
  run(() => {
    throw new Error("No region information available in the JWT.");
  });

// ----- Account tokens.

const accountJwtPayloadSchema = jwtPayloadSchema.extend({
  typ: z.enum(["account_access", "account_refresh"]),
  session_login_method: sessionLoginMethodSchema,
});

export type AccountJwt = {
  type: "ACCOUNT";
  token: string;
  payload: z.infer<typeof accountJwtPayloadSchema>;
};

export const parseAccountJwt = (token: string): AccountJwt => {
  const rawPayload = decodeJwtPayload(token);
  const payload = accountJwtPayloadSchema.parse(rawPayload);
  return { type: "ACCOUNT", token, payload };
};

// ----- Doctor tokens.

const doctorJwtPayloadSchema = jwtPayloadSchema.extend({
  typ: z.enum(["provider_access", "provider_refresh"]),
  organizationStringId: z.string(),
  session_login_method: sessionLoginMethodSchema,
});

export type DoctorJwt = {
  type: "DOCTOR";
  token: string;
  payload: z.infer<typeof doctorJwtPayloadSchema>;
};

export const parseDoctorJwt = (token: string): DoctorJwt => {
  const rawPayload = decodeJwtPayload(token);
  const payload = doctorJwtPayloadSchema.parse(rawPayload);
  return { type: "DOCTOR", token, payload };
};

// ----- Server tokens.

const serverJwtPayloadSchema = jwtPayloadSchema.extend({
  typ: z.literal("server_key"),
  organizationStringId: z.string(),
});

export type ServerJwt = {
  type: "SERVER";
  token: string;
  payload: z.infer<typeof serverJwtPayloadSchema>;
};

export const parseServerJwt = (token: string): ServerJwt => {
  const rawPayload = decodeJwtPayload(token);
  const payload = serverJwtPayloadSchema.parse(rawPayload);
  return { type: "SERVER", token, payload };
};

// ----- One-time-login tokens.

const oneTimeLoginJwtPayloadSchema = jwtPayloadSchema.extend({
  typ: z.literal("one_time_login"),
  organizationStringId: z.string(),
});

export type OneTimeLoginJwt = {
  type: "ONE_TIME_LOGIN";
  token: string;
  payload: z.infer<typeof oneTimeLoginJwtPayloadSchema>;
};

export const parseOneTimeLoginJwt = (token: string): OneTimeLoginJwt => {
  const rawPayload = decodeJwtPayload(token);
  const payload = oneTimeLoginJwtPayloadSchema.parse(rawPayload);
  return { type: "ONE_TIME_LOGIN", token, payload };
};
