import { useState } from "react";
import gql from "graphql-tag";
import * as Yup from "yup";

import { CopiableText } from "components/CopiableText/CopiableText";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { AsyncDoctorFormMultiSelect } from "components/Form/Select/AsyncDoctorFormMultiSelect";
import { AsyncFormSelect } from "components/Form/Select/AsyncFormSelect";
import { FormModal } from "components/Modal/FormModal";
import { Modal } from "components/Modal/Modal";
import { useDoctor } from "contexts/User/UserContext";
import {
  AppointmentTypeFragment,
  AppointmentTypes,
  CreateUnscheduledWebCall,
  DoctorSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  mutation CreateUnscheduledWebCall($input: CreateUnscheduledWebCallInput!) {
    createUnscheduledWebCall(createUnscheduledWebCallInput: $input) {
      webCallInvitationUrl
    }
  }
`;

type FormValues = {
  doctors: DoctorSummaryFragment[];
  appointmentType: AppointmentTypeFragment | undefined;
  callDurationMinutes: number | undefined;
};

export const CreateUnscheduledWebCallModal = ({
  patientUuid,
  onHide,
}: {
  patientUuid: UUID;
  onHide: () => void;
}) => {
  const t = useTranslation();
  const { user, hasPermission } = useDoctor();
  const [createUnscheduledWebCall] = useMutation(CreateUnscheduledWebCall);

  const [generatedUrl, setGeneratedUrl] = useState<string>();

  if (!hasPermission("CREATE_AND_SCHEDULE_WEB_CALLS")) return null;

  return generatedUrl ? (
    <Modal
      onHide={onHide}
      title={t("create_unscheduled_web_call_modal.video_call_scheduling_link")}
      className="items-stretch"
    >
      <p className="mt-20 mb-10">
        {t(
          "create_unscheduled_web_call_modal.the_video_call_scheduling_link_was_created_you_can_now_send_it_to_the_patient_to_let_them_schedule_a",
        )}
      </p>
      <CopiableText text={generatedUrl} />
    </Modal>
  ) : (
    <FormModal<FormValues>
      title={t(
        "create_unscheduled_web_call_modal.generate_a_video_call_scheduling_link",
      )}
      initialValues={{
        doctors: [user],
        appointmentType: undefined,
        callDurationMinutes: undefined,
      }}
      validationSchema={{
        doctors: Yup.array().min(
          1,
          t(
            "create_unscheduled_web_call_modal.at_least_one_provider_must_be_available",
          ),
        ),
        callDurationMinutes: "required",
      }}
      onSubmit={async ({ doctors, appointmentType, callDurationMinutes }) => {
        const output = await createUnscheduledWebCall({
          input: {
            patientUuid,
            doctorUuids: doctors.map((d) => d.uuid),
            appointmentTypeUuid: appointmentType?.uuid,
            callDurationMinutes,
          },
        });

        if (output) {
          notifier.success(
            t(
              "patients.create_unscheduled_web_call_modal.scheduling_link_created",
            ),
          );
          setGeneratedUrl(output.webCallInvitationUrl);
        } else {
          notifier.error({
            user: t(
              "create_unscheduled_web_call_modal.something_went_wrong_while_trying_to_create_the_scheduling_link_please_try_again",
            ),
          });
        }
      }}
      onHide={onHide}
      submitLabel={t("patients.create_unscheduled_web_call_modal.generate")}
    >
      <FormState<FormValues>>
        {({ setFieldValue }) => (
          <>
            <p>
              {t("patients.create_unscheduled_web_call_modal.calendar_link")}
            </p>
            <AsyncFormSelect
              selectProps={{
                name: "appointmentType",
                label: t(
                  "create_unscheduled_web_call_modal.appointment_type_label",
                ),
                getOptionLabel: (a: AppointmentTypeFragment) => a.name,
                placeholder: "",
                isClearable: true,
                onChange: (type: AppointmentTypeFragment | undefined) => {
                  if (type) {
                    setFieldValue(
                      "callDurationMinutes",
                      type.callDurationMinutes,
                    );
                  }
                },
              }}
              queryProps={{ query: AppointmentTypes }}
              getOptions={(data) => data ?? []}
              getVariables={() => ({})}
            />
            <FormInput
              name="callDurationMinutes"
              label={t(
                "patients.create_unscheduled_web_call_modal.call_duration_label",
              )}
              wrapperClassName="flex-fill"
              type="number"
            />
            <AsyncDoctorFormMultiSelect
              name="doctors"
              label={t(
                "patients.create_unscheduled_web_call_modal.available_providers",
              )}
              getVariables={(s) => ({
                filter: {
                  permissions: ["ANSWER_QA_EXPERIENCE"],
                  freeTextSearch: s,
                },
              })}
            />
          </>
        )}
      </FormState>
    </FormModal>
  );
};
