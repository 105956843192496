import { ButtonHTMLAttributes } from "react";

import { playgroundColors } from "./playgroundStyles";

export const PlaygroundButton = ({
  label,
  ...props
}: { label: string } & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    className="px-10 py-6 font-semibold uppercase"
    style={{
      color: playgroundColors.color,
      backgroundColor: playgroundColors.backgroundColor,
      letterSpacing: 0.53,
      lineHeight: 1,
    }}
    {...props}
  >
    {label}
  </button>
);
