import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { TagPill } from "components/Tag/TagPill";
import { PredictExperienceTags } from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";
import { useTranslation } from "i18n";

gql`
  query PredictExperienceTags($text: String!) {
    predictExperienceTags(query: { text: $text }) {
      predictedTags {
        ...Tag
      }
    }
  }
`;

export const ExperienceTagsPrediction = () => {
  const t = useTranslation();
  const [predictExperienceTags, { loading, data }] = useLazyQuery(
    PredictExperienceTags,
  );

  return (
    <div className="flex-fill bg-blue-overlay overflow-auto">
      <div className="flex-col p-18 space-y-16 lg:items-center">
        <span className="text-primary-dark title">
          {t(
            "labs.experience_tag_prediction.experience_tags_prediction.conversation_tag_prediction",
          )}
        </span>
        <Form<{ text: string }>
          className="bg-white p-16 rounded-item space-y-16 lg:w-1/2"
          initialValues={{ text: "" }}
          onSubmit={(values) => {
            predictExperienceTags(values);
            return Promise.resolve();
          }}
        >
          <FormTextArea
            minRows={5}
            maxRows={10}
            name="text"
            placeholder={t(
              "labs.experience_tag_prediction.experience_tags_prediction.type_a_message",
            )}
            disabled={loading}
          />
          <Submit
            loading={loading}
            label={t(
              "labs.experience_tag_prediction.experience_tags_prediction.predict_tags",
            )}
          />
          <div className="font-semibold mt-24 uppercase">
            {t(
              "labs.experience_tag_prediction.experience_tags_prediction.results",
            )}
          </div>
          <div className="flex space-x-4">
            {data?.predictedTags.map((tag) => (
              <TagPill
                key={tag.uuid}
                tag={tag}
                style={{ fontSize: 10 }}
                isMLTag
              />
            ))}
          </div>
        </Form>
      </div>
    </div>
  );
};
