import classNames from "classnames";

import { DoctorRow } from "components/Doctor/DoctorRow";
import { DoctorSummaryFragment } from "generated/provider";

import { CustomOptionProps } from "./OptionsPopover";

export const DoctorRowOption = ({
  value,
  active,
  selected,
}: CustomOptionProps<DoctorSummaryFragment>) => (
  <DoctorRow
    doctor={value}
    nameClassName={selected ? "text-white" : undefined}
    className={classNames(
      "rounded px-10 py-8",
      selected ? "bg-primary text-white" : active ? "bg-grey-100" : undefined,
      { "font-medium": active },
    )}
  />
);
