import { useCallback } from "react";

import { LoggedInAuthState, useAuth } from "auth/AuthContext";
import { failWith } from "utils";

import { EndpointAuthenticationKind, RequestContext } from "./types";

// Returns a helper which derives a `RequestContext` automatically from the
// `AuthContext` when possible. Re-renders on changes to the `AuthContext`.
export const useGetAuthenticatedRequestContextOrThrow = <
  AuthenticationKind extends EndpointAuthenticationKind,
>(
  authenticationKind: AuthenticationKind,
): (() => RequestContext<AuthenticationKind>) => {
  const auth = useAuth();

  return useCallback(() => {
    if (auth.state !== "LOGGED_IN") {
      throw new Error("Couldn't build request context: user is not logged in.");
    }

    switch (authenticationKind) {
      case "AUTHENTICATED_AS_DOCTOR":
        return (
          getDoctorRequestContext(auth) ??
          failWith(
            new Error(
              "Couldn't build request context: user is not logged in as a doctor.",
            ),
          )
        );
      case "AUTHENTICATED_AS_COPILOT_API_USER":
        return (
          getCopilotApiUserRequestContext(auth) ??
          failWith(
            new Error(
              "Couldn't build request context: user is not logged in as a copilot API user.",
            ),
          )
        );
      case "AUTHENTICATED_AS_SUPERUSER":
        return (
          getSuperuserRequestContext(auth) ??
          failWith(
            new Error(
              "Couldn't build request context: user is not logged in as a superuser.",
            ),
          )
        );
      case "AUTHENTICATED_AS_ACCOUNT":
        return (
          getAccountRequestContext(auth) ??
          failWith(
            new Error(
              "Couldn't build request context: user is not logged in as an account.",
            ),
          )
        );
      case "AUTHENTICATED_WITH_ACCESS_TO_ORGANIZATION_USER_API":
        return (
          getDoctorRequestContext(auth) ??
          getCopilotApiUserRequestContext(auth) ??
          failWith(
            new Error(
              "Couldn't build request context: user is not logged in as a doctor or copilot user.",
            ),
          )
        );

      default:
        throw new Error(
          `Couldn't build request context: deriving a request context for ${authenticationKind} is not supported.`,
        );
    }
  }, [authenticationKind, auth]);
};

const getDoctorRequestContext = <Kind extends EndpointAuthenticationKind>(
  auth: LoggedInAuthState,
) =>
  "doctorRequestContext" in auth
    ? (auth.doctorRequestContext as RequestContext<Kind>)
    : null;

const getCopilotApiUserRequestContext = <
  Kind extends EndpointAuthenticationKind,
>(
  auth: LoggedInAuthState,
) =>
  "copilotApiUserRequestContext" in auth
    ? (auth.copilotApiUserRequestContext as RequestContext<Kind>)
    : null;

const getSuperuserRequestContext = <Kind extends EndpointAuthenticationKind>(
  auth: LoggedInAuthState,
) =>
  "superuserRequestContext" in auth
    ? (auth.superuserRequestContext as RequestContext<Kind>)
    : null;

const getAccountRequestContext = <Kind extends EndpointAuthenticationKind>(
  auth: LoggedInAuthState,
) =>
  "accountRequestContext" in auth
    ? (auth.accountRequestContext as RequestContext<Kind>)
    : null;
