import gql from "graphql-tag";

import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { DeleteAvailability } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  mutation DeleteAvailability($availabilityUuid: UUID!) {
    deleteAvailability(availabilityUuid: $availabilityUuid) {
      status
    }
  }
`;

export const AvailabilityDeletionModal = ({
  availabilityUuid,
  onHide,
}: {
  availabilityUuid: UUID;
  onHide: () => void;
}) => {
  const t = useTranslation();
  const [deleteAvailability] = useMutation(DeleteAvailability, {
    onSuccess: () => {
      notifier.success(
        t(
          "scheduling.calendar.availability_deletion_modal.availability_deleted",
        ),
      );
      onHide();
    },
  });

  return (
    <ControlledDeletionConfirmationModal
      title={t(
        "scheduling.calendar.availability_deletion_modal.delete_availability",
      )}
      suffix={t("availability_deletion_modal.to_delete_this_availability")}
      onConfirm={() => deleteAvailability({ availabilityUuid })}
      onHide={onHide}
    />
  );
};
