import { useState } from "react";

import { Avatar } from "components/Avatar/Avatar";
import { RenameExperienceModal } from "components/ConversationHeader/RenameExperienceModal";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { displayDoctor, displayDoctorTitle } from "utils/display";

import { useLoadedDoctorExperience } from "../useLoadedDoctorExperience";
import { ManageDoctors } from "./ManageDoctors";

export const ListPanel = ({
  close,
  openProfile,
}: {
  close: () => void;
  openProfile: (uuid: UUID) => void;
}) => {
  const t = useTranslation();
  const { experience, title, otherDoctors } = useLoadedDoctorExperience();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const renameTitle = t(
    "doctors_conversation.information_panel.rename_experience.rename_conversation",
  );

  return (
    <>
      {showRenameModal && (
        <RenameExperienceModal
          experience={experience}
          close={() => setShowRenameModal(false)}
          withDescription
        />
      )}

      <div className="p-20 flex-col">
        <div className="flex items-center">
          <div className="flex-fill text-primary-dark font-medium truncate">
            {title}
          </div>
          <ClickableIcon name="close" onClick={() => close()} />
        </div>
        {experience.description && (
          <div className="mt-4 text-14">{experience.description}</div>
        )}
        <button
          className="mt-20 py-8 flex items-center"
          onClick={() => setShowRenameModal(true)}
        >
          <Icon name="edit" />
          <div className="ml-8 text-14 font-medium">{renameTitle}</div>
        </button>
        <ManageDoctors />
        <div className="mt-8 flex-col space-y-20 divide-y">
          {otherDoctors.map((d) => (
            <button
              key={d.uuid}
              className="pt-20 flex items-center"
              onClick={() => openProfile(d.uuid)}
            >
              <Avatar user={d} size={36} />
              <div className="ml-12 flex-fill flex-col">
                <div className="text-14 text-primary-dark font-medium truncate">
                  {displayDoctor(d)}
                </div>
                <div className="text-14 truncate">{displayDoctorTitle(d)}</div>
              </div>
              <Icon name="chevron" className="ml-12 mr-4" />
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
