import { Maybe } from "base-types";
import {
  DoctorSummaryFragment,
  PatientSummaryFragment,
} from "generated/provider";
import { routes } from "routes";
import { ExperienceResult } from "types";
import { PatientTimelineKnownItemTypes } from "types/patient-timeline";

import { notifier } from "./notifier";
import { stringHash } from "./stackoverflow";

export const run = <T>(cb: () => T) => cb();

export const failWith = (error: any) => {
  throw error;
};

// outputs [a, a+1, ..., b]
export const range = (from: number, to: number) => {
  if (to > from) return [...Array(to - from + 1)].map((_, i) => from + i);
  return [...Array(from - to + 1)].map((_, i) => from - i);
};

export const rangeWithStep = (start: number, stop: number, step: number) => {
  const array = [];
  for (let i = start; step < 0 ? i > stop : i < stop; i += step) array.push(i);
  return array;
};

export const sum = (numbers: number[]) =>
  numbers.reduce((acc, value) => acc + value, 0);

// This allow to preserves the undo browser mechanisms (e.g. ctrl-z)
export const insertText = (value: string) => {
  document.execCommand("insertText", false, value);
};

// TODO: A deep dive into readonly and Record in TS could allow a safe prototype extension of these methods
export const stableObjectEntries = <K extends string, T>(
  record: Record<K, T>,
) => Object.entries(record) as [K, T][];
export const stableObjectKeys = <K extends string, T>(record: Record<K, T>) =>
  Object.keys(record) as K[];

export const lookupBasedOnString = <T>(choices: T[], inputString: string) =>
  choices[Math.abs(stringHash(inputString)) % choices.length];

export const getBgColor = (uuid: UUID) =>
  lookupBasedOnString<string>(
    ["bg-radical-red", "bg-orange", "bg-jagged-ice", "bg-coral"],
    uuid,
  );

export const isDesktop = () => window.matchMedia("(min-width: 1024px)").matches;
export const isMobile = () => !isDesktop();

export const on = <E extends keyof WindowEventMap>(
  type: E,
  listener: (this: Window, ev: WindowEventMap[E]) => any,
  useCapture?: boolean,
) => {
  window.addEventListener(type, listener, useCapture);
  return () => window.removeEventListener(type, listener, useCapture);
};

export const listen = <
  Target extends HTMLElement,
  Event extends keyof HTMLElementEventMap,
>(
  target: Target,
  event: Event,
  listener: (this: HTMLElement, ev: HTMLElementEventMap[Event]) => any,
) => {
  target.addEventListener(event, listener);
  return () => target.removeEventListener(event, listener);
};

export const isRecorderAvailable =
  // MediaRecorder is only available in Safari 14
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.MediaRecorder !== undefined &&
  MediaRecorder.prototype.hasOwnProperty("resume");

export const showExperienceAsNotSeen = (
  user: { uuid: UUID },
  e: { seenStatus: boolean; allDoctors: { uuid: UUID }[] },
) => e.allDoctors.some((d) => d.uuid === user.uuid) && !e.seenStatus;

export const isDef = <T>(a: T): a is NonNullable<T> =>
  a !== undefined && a !== null;

export const promiseWithResolverAndRejecter = <T>(): [
  Promise<T>,
  (value: T) => void,
  (value: string) => void,
] => {
  let resolver: (value: T) => void;
  let rejecter: (value: string) => void;
  return [
    new Promise((res, rej) => {
      resolver = res;
      rejecter = rej;
    }),
    // @ts-ignore TS wrongly think resolver is not set
    resolver,
    // @ts-ignore TS wrongly think rejecter is not set
    rejecter,
  ];
};

export const getQAInboxRoute = (
  experience: Pick<ExperienceResult, "uuid" | "isClosed" | "assignedDoctors">,
  userUuid?: UUID,
) => {
  const section = experience.isClosed
    ? routes.QA_CLOSED
    : userUuid &&
      experience.assignedDoctors.map(({ uuid }) => uuid).includes(userUuid)
    ? routes.QA_ASSIGNED_TO_ME
    : experience.assignedDoctors.isNotEmpty()
    ? routes.QA_ASSIGNED
    : routes.QA_UNASSIGNED;
  return `${routes.QA_INBOX}/${experience.uuid}?section=${section}`;
};

export const getNewQAExperienceRoute = (
  patient: Pick<PatientSummaryFragment, "uuid" | "__typename">,
) => `${routes.QA_INBOX}/new/${patient.uuid}`;

export const getCalendarRoute = (doctor: Maybe<DoctorSummaryFragment>) =>
  doctor ? `${routes.SCHEDULING}/${doctor.uuid}` : routes.SCHEDULING;

export const getPatientViewRoute = (
  patient: Pick<PatientSummaryFragment, "uuid" | "__typename">,
  expandedItems?: { uuid: UUID; __typename: PatientTimelineKnownItemTypes }[],
) =>
  expandedItems?.isNotEmpty()
    ? `${routes.PATIENT_LIST}/${patient.uuid}#${expandedItems
        .map((item) => item.uuid)
        .join(",")}`
    : `${routes.PATIENT_LIST}/${patient.uuid}`;

export const getMaybeExperienceUuid = () => {
  const isInQaExperience = window.location.pathname.includes(routes.QA_INBOX);
  const isInDoctorConversation = window.location.pathname.includes(
    routes.CONVERSATION_BASE,
  );

  if (!isInDoctorConversation && !isInQaExperience) return null;

  return (
    window.location.pathname
      .split("/")
      .find((it) => it.match(/[0-9a-f-]{36}/u)) ?? null
  );
};

export const copyToClipBoard = async (text: string, successMessage: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text).then(() => {
      notifier.success(successMessage);
    });
  } else {
    // iOS Safari blocks clipboard on local environment.
    notifier.error({ user: "Clipboard not available" });
  }
};

export const convertPixelStringToNumber = (pixelString: string): number =>
  parseInt(pixelString.replace("px", ""), 10);

/**
 * This function can be used in the default case of a switch that is supposed to be exhaustive. The discriminant of the
 * switch should be passed as parameter. If the switch is not exhaustive the call to this function will not compile.
 *
 * This works by expecting a parameter of type `never`. Since it is impossible to provide a value of that type (nothing
 * has the type `never`) this function is "absurd": it can never be called!
 *
 * So, if a switch case is exhaustive, the default case is dead code and the type of the switch discriminant is `never`
 * so the call to this function compiles (even though the function will never be actually called at runtime).
 * If the switch is not exhaustive, the type of the switch discriminant is not `never` in the default case and the call
 * this function doesn't compile.
 */
export const switchShouldBeExhaustive = (_: never): never => {
  throw new Error("Non exhaustive switch case");
};
