import { ReactNode } from "react";

export const Autocompletion = ({ children }: { children: ReactNode }) => (
  <span
    className="text-body"
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {children}
  </span>
);
