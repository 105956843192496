import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";

import { TagRow } from "./TagPill";
import { TagsMenuItem } from "./TagsMenu";

export const MLTagRow = ({
  tag,
  removeTag,
  addTag,
  disabled,
}: {
  tag: TagsMenuItem;
  removeTag: (tag: TagsMenuItem) => void;
  addTag: (tag: TagsMenuItem) => void;
  disabled?: boolean;
}) => (
  <TagRow
    tag={tag.type}
    rightInnerElement={
      <>
        <ClickableIcon
          name="close"
          size={16}
          className="p-4"
          disabled={disabled}
          onClick={() => removeTag(tag)}
        />
        <ClickableIcon
          name="check"
          size={16}
          className="ml-4 p-4"
          disabled={disabled}
          onClick={() => addTag(tag)}
        />
      </>
    }
  />
);

export const RemoveOrConfirmTagRow = ({
  tag,
  removeTag,
  addTag,
  isML,
  disabled,
}: {
  tag: TagsMenuItem;
  removeTag: (tag: TagsMenuItem) => void;
  addTag: (tag: TagsMenuItem) => void;
  isML?: (tag: TagsMenuItem) => boolean;
  disabled?: boolean;
}) =>
  isML?.(tag) ? (
    <MLTagRow
      tag={tag}
      disabled={disabled}
      addTag={addTag}
      removeTag={removeTag}
    />
  ) : (
    <button disabled={disabled} onClick={() => removeTag(tag)}>
      <TagRow
        tag={tag.type}
        rightInnerElement={<Icon name="close" size={16} />}
      />
    </button>
  );

export const InnerTagClose = ({ onClick }: { onClick: () => void }) => (
  <Icon
    name="close"
    className="p-2 bg-white text-primary-dark rounded-full absolute right-6 invisible group-hover:visible cursor-pointer"
    size={12}
    onClick={onClick}
  />
);
