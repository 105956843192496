import { ReactNode } from "react";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";

import { Avatar } from "components/Avatar/Avatar";
import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Query } from "components/Query/Query";
import {
  CreateDoctorConversation,
  DoctorSummaryFragment,
  GetBiography,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { addDoctorConversationInCache } from "utils/apollo";
import { displayDoctor, displayDoctorTitle } from "utils/display";

import Background from "./profileBackground.svg";

gql`
  query GetBiography($uuid: UUID!) {
    doctor(uuid: $uuid) {
      doctor {
        uuid
        bio
      }
    }
  }
`;

export const ProfilePanel = ({
  doctorSummary,
  back,
  close,
  showConversationButton = false,
}: {
  doctorSummary: DoctorSummaryFragment;
  back?: () => void;
  close: () => void;
  showConversationButton?: boolean;
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [createDoctorConversation, creating] = useMutation(
    CreateDoctorConversation,
  );
  return (
    <div className="flex-col">
      <div className="flex-col relative">
        <div className="absolute inset-0">
          <Background className="h-full w-full" />
        </div>
        <div className="relative flex-col p-20 pb-44">
          <div className="w-full flex-center relative">
            {back && (
              <ClickableIcon
                name="chevron"
                className="absolute inset-tl-0"
                rotate={-180}
                onClick={() => back()}
              />
            )}
            <Avatar user={doctorSummary} size={60} />
            <ClickableIcon
              name="close"
              className="absolute inset-tr-0"
              onClick={() => close()}
            />
          </div>
          <div className="mt-12 text-center text-primary-dark font-medium">
            {displayDoctor(doctorSummary)}
          </div>
          <div className="mt-4 text-14 text-center">
            {displayDoctorTitle(doctorSummary)}
          </div>
        </div>
      </div>
      <Query query={GetBiography} variables={{ uuid: doctorSummary.uuid }}>
        {({ doctor }) => (
          <div className="py-32 px-20 flex-col space-y-32">
            <Section title="Bio">{doctor.bio}</Section>
            {showConversationButton && (
              <Button
                label={t("doctor.profile_panel.send_a_message")}
                disabled={creating}
                onClick={() =>
                  createDoctorConversation(
                    { doctorUuids: [doctor.uuid] },
                    {
                      onSuccess: ({ experience }, client) => {
                        addDoctorConversationInCache(client, experience);
                        navigate(
                          `${routes.CONVERSATION_BASE}/${experience.uuid}`,
                        );
                      },
                    },
                  )
                }
                style={{ width: 180 }}
                className="self-center"
              />
            )}
          </div>
        )}
      </Query>
    </div>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  const t = useTranslation();

  return (
    <div className="flex-col">
      <div className="text-14 text-primary-dark font-medium">{title}</div>
      {typeof children === "string" || children === null ? (
        <div className="mt-4 text-14 whitespace-pre-wrap">
          {children?.trimOrNull() ?? t("doctor.profile_panel.work_in_progress")}
        </div>
      ) : (
        children
      )}
    </div>
  );
};
