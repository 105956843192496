import { HTMLAttributes } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import styles from "components/Doctor/userRow.module.css";
import { PatientSummaryFragment } from "generated/provider";
import { displayPatient } from "utils/display";

export const PatientRow = ({
  patient,
  nameClassName,
  className,
  ...props
}: {
  patient: PatientSummaryFragment;
  nameClassName?: string;
} & HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames(styles.user, className)} {...props}>
    <Avatar user={patient} />
    <div className="flex-fill flex-col">
      <div className={classNames(styles.name, nameClassName)}>
        {displayPatient(patient)}
      </div>
    </div>
  </div>
);
