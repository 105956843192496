import { ExperienceEventFragment } from "generated/provider";
import { staticT as t } from "i18n";

import { isKnownUnionValue } from "./apollo";
import { displayDoctor, displayPatient } from "./display";

export const getEventText = (event: ExperienceEventFragment) => {
  const content = event.content;

  if (!isKnownUnionValue("ExperienceEventContent", content)) return null;

  switch (content.__typename) {
    case "DoctorJoinedExperience": {
      return t("joined_the_conversation", {
        doctor: displayDoctor(content.doctor),
      });
    }
    case "DoctorLeftExperience": {
      return t("left_the_conversation", {
        doctor: displayDoctor(content.doctor),
      });
    }
    case "ExperienceClosed": {
      return t("the_chat_was_closed");
    }
    case "ExperienceReopened": {
      return t("utils.experience.the_chat_was_reopened");
    }
    case "ExperienceLocked": {
      return t("utils.experience.experience_locked");
    }
    case "ExperienceUnlocked": {
      return t("utils.experience.experience_unlocked");
    }
    case "ExperienceRenamed": {
      if (content.name) {
        return t("renamed_the_conversation_to", {
          doctor: content.doctor ? displayDoctor(content.doctor) : t("someone"),
          newName: content.name,
        });
      }
      return t("renamed_the_conversation", {
        doctor: content.doctor ? displayDoctor(content.doctor) : t("someone"),
      });
    }
    case "PatientSubmittedQuestionnaire":
      return t("patient_has_completed_the_questionnaire", {
        patient: displayPatient(content.patient),
      });
    case "PatientCompletedIdentityCheck":
      return t("patient_has_completed_the_identity_check", {
        patient: displayPatient(content.patient),
      });
    case "PatientCompletedQuestionsSet":
      return t("inboxes.qa_experience.questions_set.event.completed");
  }
};
