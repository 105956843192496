import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Separator } from "components/Separator/Separator";
import { TagPill } from "components/Tag/TagPill";
import { AllTagsBackOffice, DeleteTag } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { removeExperienceTagTypeFromCache } from "utils/apollo";
import { notifier } from "utils/notifier";

import { TagComposer } from "./TagComposer";

gql`
  query AllTagsBackOffice {
    allTags {
      tags {
        ...BackofficeTag
      }
    }
  }

  fragment BackofficeTag on TagType {
    ...Tag
    mlPredictable
  }

  mutation DeleteTag($uuid: UUID!) {
    deleteTag(uuid: $uuid)
  }
`;

type ComposerState = { mode: "create" } | { mode: "update"; uuid: UUID };

export const TagsManagement = () => {
  const t = useTranslation();
  const [composerState, setComposerState] = useState<ComposerState>();
  const [deleteTag] = useMutation(DeleteTag);
  const { data } = useQuery(AllTagsBackOffice);

  return (
    <Background className="flex-col flex-fill overflow-auto">
      <div className="flex items-center bg-white p-16 lg:px-44 lg:pt-24">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("tags_management.tags_management.tags_management")}
        </h1>
        <div className="flex space-x-8 flex-shrink ml-auto py-4">
          <Button
            label={t("tags_management.tags_management.create")}
            onClick={() => setComposerState({ mode: "create" })}
          />
        </div>
      </div>

      <Separator />

      <div className="flex-col p-16 lg:px-44">
        <div className="leading-snug mt-10 mb-20">
          {t(
            "tags_management.define_which_tags_are_available_to_classify_conversations",
          )}
        </div>

        {composerState && (
          <TagComposer
            onHide={() => setComposerState(undefined)}
            tag={
              composerState.mode === "update"
                ? data?.tags.find((tag) => tag.uuid === composerState.uuid)
                : undefined
            }
          />
        )}
        <div className="mt-12">
          {data?.tags.isNotEmpty() ? (
            <>
              <span>
                {t("tags_management.tags", { count: data.tags.length })}
              </span>
              <div className="mt-12 flex-col bg-white rounded shadow-sm-outlined border divide-y">
                {data.tags.map((tag) => (
                  <div
                    key={tag.uuid}
                    className="flex-wrap items-center space-x-8 p-20"
                  >
                    <div style={{ width: 200 }} className="flex ml-12">
                      <TagPill tag={tag} />
                    </div>
                    {!tag.mlPredictable && (
                      <div className="ml-auto mr-auto">
                        {t("tags_management.not_automatically_suggested")}
                      </div>
                    )}
                    <div className="flex items-center ml-auto space-x-10">
                      <DeletionConfirmationModal
                        suffix={t(
                          "tags_management.tags_management.delete_this_tag",
                        )}
                        onConfirm={(close) =>
                          deleteTag(
                            { uuid: tag.uuid },
                            {
                              onSuccess: (_, client) => {
                                notifier.success(
                                  t(
                                    "tags_management.tags_management.tag_deleted",
                                  ),
                                );
                                removeExperienceTagTypeFromCache(
                                  client,
                                  tag.uuid,
                                );
                                close();
                              },
                            },
                          )
                        }
                      >
                        {(show) => (
                          <ClickableIcon
                            name="trash"
                            size={24}
                            className="p-4 hover:bg-grey-100 rounded text-danger"
                            onClick={() => show()}
                          />
                        )}
                      </DeletionConfirmationModal>
                      <ClickableIcon
                        name="edit"
                        size={24}
                        className="p-4 hover:bg-grey-100 rounded"
                        onClick={() =>
                          setComposerState({ mode: "update", uuid: tag.uuid })
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="flex-col w-full flex-center space-y-4 my-80">
              <div className="text-primary-dark font-bold text-18">
                {t("tags_management.no_conversation_tag_yet_title")}
              </div>
              <div>{t("tags_management.no_conversation_tag_yet_subtitle")}</div>
            </div>
          )}
        </div>
      </div>
    </Background>
  );
};
