import classNames from "classnames";

import { Icon } from "components/Icon/Icon";
import { useGoBack } from "hooks";

export const BackMobile = ({
  fallback,
  className,
}: {
  fallback: string;
  className?: string;
}) => {
  const goBack = useGoBack();

  return (
    <button
      onClick={() => goBack(fallback)}
      className={classNames(className, "lg:hidden py-4 px-8")}
    >
      <Icon name="chevron" rotate={180} />
    </button>
  );
};
