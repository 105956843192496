import gql from "graphql-tag";

import { useTranslation } from "i18n";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";

import { PatientTimelineItem } from "../PatientTimelineItem";

gql`
  mutation CreateOrGetPhotonPatient($input: CreateOrGetPhotonPatientInput!) {
    createOrGetPhotonPatient(input: $input) {
      newPrescriptionUrl
    }
  }
`;

type PhotonPrescriptionItem = {
  title: string;
  photonOrderUrl: string;
};

// ESLint is wrong here, the PhotonPrescriptionItem is used in PrescriptionItem.tsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhotonPrescriptionItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"PhotonPrescription">;
}) => {
  const t = useTranslation();

  return (
    <PatientTimelineItem<
      "PhotonPrescription",
      PhotonPrescriptionItem,
      PhotonPrescriptionItem
    >
      item={item}
      actionButton={{
        label: t("patient_view.prescription.timeline_item.photon_button"),
        secondary: true,
        mode: "HOVER",
        onClick: () => {
          window.open(item.photonOrderUrl, "_blank");
        },
      }}
      fixedSize
    />
  );
};
