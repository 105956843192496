import { useCanSendPrescription } from "components/EHRComposer/Prescriptions/useCanSendPrescription";
import { Icon } from "components/Icon/Icon";
import { Link } from "components/Link/Link";
import { useTranslation } from "i18n";
import { routes } from "routes";

export const PatientPrescriptionItemWarning = ({
  errorTitle,
  errorPersonalInfo,
}: {
  errorTitle: string;
  errorPersonalInfo: string;
}) => {
  const t = useTranslation();
  const canSendPrescriptionErrors = useCanSendPrescription();

  if (!canSendPrescriptionErrors) return null;

  return (
    <div className="w-full p-20 pb-0">
      <div className="bg-warning-100 outline outline-1 outline-warning-200 rounded-sm p-12 w-full grid grid-cols-[auto_1fr] gap-x-6">
        <Icon name="warning" className="self-center text-warning-300" />
        <div className="text-black font-medium">{errorTitle}</div>
        <ul className="col-start-2 list-disc list-outside pl-16 text-black">
          {canSendPrescriptionErrors.hasDoctorMissingInformation && (
            <li>
              <span>
                {errorPersonalInfo}
                &nbsp;
              </span>
              <Link
                to={`${routes.PREFERENCES}/${routes.PERSONAL_INFO}`}
                className="underline"
              >
                {t("patient_view.patient_prescription.error.edit_info")}
              </Link>
            </li>
          )}

          {canSendPrescriptionErrors.patientMissingInformation && (
            <>
              {canSendPrescriptionErrors.patientMissingInformation
                .birthDate && (
                <li>
                  {t(
                    "patient_view.patient_prescription.error.patient.birthdate",
                  )}
                </li>
              )}

              {canSendPrescriptionErrors.patientMissingInformation
                .firstName && (
                <li>
                  {t(
                    "patient_view.patient_prescription.error.patient.firstname",
                  )}
                </li>
              )}

              {canSendPrescriptionErrors.patientMissingInformation.lastName && (
                <li>
                  {t(
                    "patient_view.patient_prescription.error.patient.lastname",
                  )}
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
