import { Input } from "components/Form/Input/Input";
import { Icon } from "components/Icon/Icon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import {
  TextSectionContentCategoryKnownValue,
  TextSectionContentCategoryKnownValues,
} from "generated/provider";
import { useSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { isKnownValue } from "utils/enum";

import { NoteSectionWithComposerFields } from "./types";
import { createEmptySection, getSectionTitle } from "./utils";

const textSectionContentCategory = (
  section: NoteSectionWithComposerFields,
): (TextSectionContentCategoryKnownValue | "DIAGNOSE") | undefined => {
  const content = section.content;
  switch (content.__typename) {
    case "DiagnoseSectionContent":
      return "DIAGNOSE";
    case "TextSectionContent":
      if (
        isKnownValue(content.category, TextSectionContentCategoryKnownValues)
      ) {
        return content.category;
      }
      return undefined;
  }
};

export const SectionAppender = ({
  sections,
  onChange,
}: {
  sections: NoteSectionWithComposerFields[];
  onChange: (sections: NoteSectionWithComposerFields[]) => void;
}) => {
  const t = useTranslation();
  const inputProps = useSearch();

  const items = [...TextSectionContentCategoryKnownValues, "DIAGNOSE" as const]
    .filter(
      (i) =>
        !sections
          .mapNotNull((section) => textSectionContentCategory(section))
          .includes(i) || i === "FREE_TEXT",
    )
    .filter((i) => getSectionTitle(i).fuzzyMatch(inputProps.value));

  return (
    <UncontrolledPopover
      position={["top-left", "bottom-left"]}
      className="my-6 flex-col overflow-hidden"
      style={{ maxHeight: 303 }}
      content={(close) => (
        <>
          <Input
            {...inputProps}
            autoFocus
            autoComplete="off"
            placeholder={t(
              "inboxes.qa_experience.ehr_composer.note_composer.section_appender.placeholder",
            )}
            wrapperClassName="border-b"
            leftInnerElement={<Icon name="search" />}
            className="border-0"
          />
          <div className="flex-fill flex-col overflow-auto">
            {items.isEmpty() ? (
              <div className="py-8 px-10">
                {t(
                  "inboxes.qa_experience.ehr_composer.note_composer.section_appender.no_items",
                )}
              </div>
            ) : (
              items.map((sectionType) => (
                <button
                  key={sectionType}
                  className="py-8 px-10 text-primary-dark font-medium hover:bg-grey-100"
                  onClick={() => {
                    onChange([...sections, createEmptySection(sectionType)]);
                    close();
                  }}
                >
                  {getSectionTitle(sectionType)}
                </button>
              ))
            )}
          </div>
        </>
      )}
    >
      {({ setTarget }) => (
        <button
          className="mt-12 self-start p-6 pr-10 flex ml-52 items-center hover:bg-grey-100 rounded-sm"
          type="button"
          onClick={setTarget}
        >
          <Icon name="add" color="body" />
          <div className="ml-10">
            {t(
              "inboxes.qa_experience.ehr_composer.note_composer.section_appender.add_a_section",
            )}
          </div>
        </button>
      )}
    </UncontrolledPopover>
  );
};
