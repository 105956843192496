import { Media, RemoteMedia } from "atoms/useMediaWindows";

type MediaType = "IMAGE" | "DICOM" | "VIDEO" | "PDF" | "OTHER";

export const getMediaType = (media: Media): MediaType => {
  if (isRemoteMedia(media)) {
    if (media.mimeType.startsWith("image")) {
      return "IMAGE";
    } else if (media.mimeType === "application/dicom") {
      return "DICOM";
    } else if (media.mimeType.startsWith("video")) {
      return "VIDEO";
    } else if (media.mimeType === "application/pdf") {
      return "PDF";
    }
  } else {
    return "PDF";
  }
  return "OTHER";
};

export const isRemoteMedia = (media: Media): media is RemoteMedia =>
  "url" in media;
