import { createContext, useContext } from "react";

export const QACopilotPanelContext = createContext<{
  isCopilotPanelOpened: boolean;
  openCopilotPanel: () => void;
  closeCopilotPanel: () => void;
} | null>(null);
QACopilotPanelContext.displayName = "QACopilotPanelContext";

export const useQACopilotPanel = () => {
  const context = useContext(QACopilotPanelContext);
  if (!context) throw new Error("QACopilotPanelProvider not available");
  return context;
};
