import { useEffect, useRef } from "react";

export const useScrollIntoView = <E extends Element>(shouldScroll: boolean) => {
  // It is fine to use a plain ref here instead of a callback ref because
  // we don't want to abruptly re-scroll the container into view if the
  // container element changes for some reason.
  const containerRef = useRef<E>(null);
  const isScrolledTo = useRef(false);

  useEffect(() => {
    if (shouldScroll) {
      if (!containerRef.current || isScrolledTo.current) return;
      containerRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      isScrolledTo.current = true;
    } else {
      isScrolledTo.current = false;
    }
  }, [shouldScroll]);

  return containerRef;
};
