import gql from "graphql-tag";

import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { DeleteBravadoPrescription } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { notifier } from "utils/notifier";

import { PatientTimelineItem } from "../PatientTimelineItem";

gql`
  mutation CreateBravadoPrescription($input: CreateBravadoPrescriptionInput!) {
    createBravadoPrescription(input: $input) {
      prescription {
        ...BravadoPrescription
      }
    }
  }

  mutation DeleteBravadoPrescription($prescriptionUuid: UUID!) {
    deleteBravadoPrescription(prescriptionUuid: $prescriptionUuid) {
      prescriptionUuid
    }
  }
`;

type BravadoPrescriptionItem = {
  title: string;
  bravadoEncounterUrl: string;
};

// ESLint is wrong here, the BravadoPrescriptionItem is used in PrescriptionItem.tsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BravadoPrescriptionItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"BravadoPrescription">;
}) => {
  const [deleteBravadoPrescription] = useMutation(DeleteBravadoPrescription, {
    onSuccess: ({ prescriptionUuid }, client) =>
      client.remove("NablaPrescription", prescriptionUuid),
  });
  const t = useTranslation();

  return (
    <DeletionConfirmationModal
      suffix={t("patient_view.patient_document.delete_suffix")}
      onConfirm={async (closeDeletionModal) => {
        await deleteBravadoPrescription({ prescriptionUuid: item.uuid });
        notifier.success(t("patient_view.patient_prescription.delete_success"));
        closeDeletionModal();
      }}
    >
      {(openDeletionModal) => (
        <PatientTimelineItem<
          "BravadoPrescription",
          BravadoPrescriptionItem,
          BravadoPrescriptionItem
        >
          item={item}
          actionButton={
            item.bravadoEncounterUrl
              ? {
                  label: t(
                    "patient_view.prescription.timeline_item.bravado_button",
                  ),
                  secondary: true,
                  mode: "HOVER",
                  onClick: () =>
                    item.bravadoEncounterUrl.let((url) =>
                      window.open(url, "_blank"),
                    ),
                }
              : undefined
          }
          additionalMenuItems={[
            {
              text: t("patient_view.item.delete"),
              icon: "trash",
              className: "text-danger",
              onClick: (closeMenu) => {
                closeMenu();
                openDeletionModal();
              },
            },
          ]}
          fixedSize
        />
      )}
    </DeletionConfirmationModal>
  );
};
