import classNames from "classnames";
import gql from "graphql-tag";
import { Link, useMatch } from "react-router-dom";

import { NextPageButton } from "components/Button/NextPageButton";
import { DoctorSelect } from "components/DoctorSelect/DoctorSelect";
import { useSelectableDoctors } from "components/DoctorSelect/useSelectableDoctors";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Separator } from "components/Separator/Separator";
import { Spinner } from "components/Spinner/Spinner";
import {
  GetAllDoneTimelineItems,
  GetAllTodoTimelineItems,
} from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { filterValidItems } from "types/patient-timeline";

import { PatientsInboxItem } from "./PatientsInboxItem";

gql`
  query GetAllTodoTimelineItems($doctorUuid: UUID!, $cursor: String) {
    allTodoTimelineItems(
      doctorUuid: $doctorUuid
      page: { cursor: $cursor, numberOfItems: 20 }
    ) {
      hasMore
      nextCursor
      data {
        ...PatientTimelineItem
      }
    }
  }

  query GetAllDoneTimelineItems($doctorUuid: UUID!, $cursor: String) {
    allDoneTimelineItems(
      doctorUuid: $doctorUuid
      page: { cursor: $cursor, numberOfItems: 20 }
    ) {
      hasMore
      nextCursor
      data {
        ...PatientTimelineItem
      }
    }
  }
`;

export const PatientsInbox = () => {
  const t = useTranslation();

  const queryParam = useMatch(
    `${routes.PATIENTS_INBOX}/:inboxType*`,
  )?.params.inboxType.toUpperCase();

  const inboxType =
    queryParam && ["TODO", "DONE"].includes(queryParam) ? queryParam : "TODO";

  const { selectedDoctor, setSelectedDoctor, allDoctors } =
    useSelectableDoctors(
      `${routes.PATIENTS_INBOX}/${inboxType.toLocaleLowerCase()}`,
    );

  if (!selectedDoctor) return <Spinner />;

  return (
    <div className="flex-col flex-fill bg-light-blue overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <div className="flex justify-between pt-16 lg:pt-24">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("patients_inbox.title")}
          </h1>

          <DoctorSelect
            className="ml-auto"
            selectableDoctors={allDoctors}
            selectedDoctor={selectedDoctor}
            setSelectedDoctor={setSelectedDoctor}
          />
        </div>

        <div className="flex justify-between">
          <div className="flex">
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  inboxType === "TODO",
              })}
              to={`${routes.PATIENTS_INBOX_TODO}/${selectedDoctor.uuid}`}
            >
              {t("patients_inbox.todo")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  inboxType === "DONE",
              })}
              to={`${routes.PATIENTS_INBOX_DONE}/${selectedDoctor.uuid}`}
            >
              {t("patients_inbox.done")}
            </Link>
          </div>
        </div>
      </div>

      <Separator />
      <div className="bg-white flex-fill">
        <PaginatedQuery
          query={
            inboxType === "TODO"
              ? GetAllTodoTimelineItems
              : GetAllDoneTimelineItems
          }
          variables={{ doctorUuid: selectedDoctor.uuid }}
        >
          {({ data }, utils) => {
            const validItems = filterValidItems(data);
            return (
              <>
                {validItems.isEmpty() ? (
                  <div className="flex-col h-full justify-center text-center text-18 font-medium">
                    {t("patient_inbox.no_activity")}
                  </div>
                ) : (
                  <>
                    <div className="flex w-full bg-grey-100 uppercase font-semibold text-12 px-16 lg:px-44 py-12">
                      <div className="w-1/2">
                        {t("patient_inbox.activity_header")}
                      </div>
                      <div className="w-1/2">
                        {t("patient_inbox.patient_header")}
                      </div>
                    </div>
                    <Separator />
                    {validItems.map((item, i) => (
                      <div key={i} className="border-b">
                        <PatientsInboxItem item={item} />
                      </div>
                    ))}
                  </>
                )}
                <div className="px-16 lg:px-44 bg-white">
                  <NextPageButton {...utils} />
                </div>
              </>
            );
          }}
        </PaginatedQuery>
      </div>
    </div>
  );
};
