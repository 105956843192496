import { useEffect, useRef } from "react";
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertToRaw,
  DraftDecorator,
  EditorState,
  genKey,
  Modifier,
  RawDraftContentState,
  RawDraftEntity,
  SelectionState,
} from "draft-js";

import { useSyncRef } from "hooks/useSyncRef";

import { Decorator, DraftJSDecoratorType, DraftJSEntity } from "./types";

export const getCurrentBlock = (editorState: EditorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  return contentState.getBlockForKey(selectionState.getStartKey());
};

export const getCurrentIndex = (editorState: EditorState) => {
  const block = getCurrentBlock(editorState);
  return editorState
    .getCurrentContent()
    .getBlockMap()
    .keySeq()
    .indexOf(block.getKey());
};

export const getNumberBlocks = (editorState: EditorState) =>
  editorState.getCurrentContent().getBlockMap().size;

export const getFirstBlock = (editorState: EditorState) =>
  editorState.getCurrentContent().getBlockMap().first();

export const getLastBlock = (editorState: EditorState) =>
  editorState.getCurrentContent().getBlockMap().last();

export const createSelectionState = (
  blockKey: string,
  offset: number,
  focusOffset?: number,
  hasFocus = true,
) =>
  new SelectionState({
    anchorKey: blockKey,
    anchorOffset: offset,
    focusKey: blockKey,
    focusOffset: focusOffset ?? offset,
    isBackward: false,
    hasFocus,
  });

export const getSelectionBlocks = (state: EditorState) => {
  let block = getCurrentBlock(state);
  const blocks = [block];
  let key = block.getKey();
  while (key !== state.getSelection().getEndKey()) {
    block = state.getCurrentContent().getBlockAfter(key);
    blocks.push(block);
    key = block.getKey();
  }
  return blocks;
};

export const insertText = (state: EditorState, text: string) =>
  EditorState.push(
    state,
    Modifier.insertText(state.getCurrentContent(), state.getSelection(), text),
    "insert-characters",
  );

export const replaceTextBlock = (
  content: ContentState,
  block: ContentBlock,
  text: string,
) =>
  Modifier.replaceText(
    content,
    createSelectionState(block.getKey(), 0, block.getLength()),
    text,
  );

export const getDecorators = (decorators: Decorator[]) =>
  new CompositeDecorator(
    decorators.map(
      (props): DraftDecorator => ({
        ...props,
        strategy: (block, callback, contentState) => {
          const entityStrategy = props.entityStrategy;
          const textStrategy = props.textStrategy;
          const allBlocks = splitBlockIntoTextAndEntities(block, contentState);
          const allBlocksFiltered = allBlocks.filter((e) =>
            "data" in e
              ? entityStrategy
                ? entityStrategy(e)
                : false
              : textStrategy
              ? textStrategy(e.text)
              : false,
          );
          allBlocksFiltered.forEach(({ start, end }) => callback(start, end));
        },
      }),
    ),
  );

export const splitBlockIntoTextAndEntities = <T>(
  block: ContentBlock,
  contentState: ContentState,
) => {
  const allBlocks: DraftJSDecoratorType<T>[] = [];
  const entities: DraftJSEntity<T>[] = getBlockEntities<T>(block, contentState);
  let currentStart = 0;
  const text = block.getText();
  entities
    .immutableSort((e) => e.start)
    .forEach((entity) => {
      allBlocks.push({
        text: text.slice(currentStart, entity.start),
        start: currentStart,
        end: entity.start,
      });
      currentStart = entity.end;
      allBlocks.push(entity);
    });
  allBlocks.push({
    text: text.slice(currentStart, text.length),
    start: currentStart,
    end: text.length,
  });
  return allBlocks;
};

export const getBlockEntities = <T>(
  block: ContentBlock,
  contentState: ContentState,
) => {
  const entities: DraftJSEntity<T>[] = [];
  block.findEntityRanges(
    (character) => !!character.getEntity(),
    (start, end) => {
      const key = block.getEntityAt(start)!;
      const entity = contentState.getEntity(key);
      entities.push({
        start,
        end,
        type: entity.getType(),
        mutability: entity.getMutability(),
        data: entity.getData(),
        key,
      });
    },
  );
  return entities;
};

export const getAllEntities = <T = any>(editorState: EditorState) =>
  getEntitiesList<T>(convertToRaw(editorState.getCurrentContent()));

export const getEntitiesList = <T = any>(
  rawContent: RawDraftContentState,
): RawDraftEntity<T>[] =>
  Object.values(rawContent.entityMap as { [key: string]: RawDraftEntity<T> });

export const someBlocksAreNotBulletPoints = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  return blockMap.some((it) => {
    // This looks like a strange typing from DraftJS (actually from immutable,
    // the lib that is used). type should just never be undefined here, because
    // there doesn't seem a way to insert an undefined ContentBlock in the map.
    const type = it?.getType();
    return type !== undefined && type !== "unordered-list-item";
  });
};

export const convertAllBlocksToBulletPoints = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  const newBlockMap = blockMap.map((it) =>
    it?.merge({
      type: "unordered-list-item",
    }),
  );
  const newContentState: ContentState = contentState.merge({
    blockMap: newBlockMap,
  }) as ContentState;
  return EditorState.push(editorState, newContentState, "insert-fragment");
};

export const useOnContentChange = (
  editorState: EditorState,
  callback: (newContent: ContentState) => void,
) => {
  const callbackRef = useSyncRef(callback);
  const newContent = editorState.getCurrentContent();
  // Avoids to call the callback when this hook is first mounted
  const hasJustBeenMountedRef = useRef(true);

  useEffect(() => {
    if (hasJustBeenMountedRef.current) {
      hasJustBeenMountedRef.current = false;
    } else {
      callbackRef.current(newContent);
    }
  }, [newContent, callbackRef, hasJustBeenMountedRef]);
};

export const addEmptyBlock = (
  editorState: EditorState,
  type: "unordered-list-item" | "unstyled",
) => {
  const newBlock = new ContentBlock({
    key: genKey(),
    text: "",
    type,
  });
  const currentBlockKey = getCurrentBlock(editorState).getKey();

  const contentState = editorState.getCurrentContent();

  const newBlockMap = contentState
    .getBlockMap()
    .flatMap((oldValue, oldKey) => {
      if (oldKey === currentBlockKey) {
        return [
          [oldKey, oldValue],
          [newBlock.getKey(), newBlock],
        ];
      }
      return [[oldKey, oldValue]];
    })
    .toOrderedMap();

  const newContentState = contentState.merge({
    blockMap: newBlockMap,
  }) as ContentState;

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    "insert-characters",
  );
  return EditorState.forceSelection(
    newEditorState,
    newEditorState.getSelection().merge({
      anchorKey: newBlock.getKey(),
      focusKey: newBlock.getKey(),
      anchorOffset: 0,
      focusOffset: 0,
    }),
  );
};

export const getBlockText = (editorState: EditorState, blockKey: string) =>
  editorState.getCurrentContent().getBlockForKey(blockKey).getText();
