import { useEffect } from "react";
import gql from "graphql-tag";
import { useNavigate, useParams } from "react-router";

import { Background } from "components/Background/Backgound";
import { Composer } from "components/ConversationComposer/Composer";
import { ConversationContent } from "components/ConversationContent/ConversationContent";
import { QueryResult } from "components/Query/QueryResult";
import { ExperienceProvider } from "contexts/Experience/ExperienceProvider";
import { useDoctor } from "contexts/User/UserContext";
import { GetGroupConversation, QAExperienceFragment } from "generated/provider";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { routes } from "routes";
import { config } from "styles";
import { getQAInboxRoute } from "utils";

import { GroupConversationSideSection } from "./GroupConversationSideSection";
import { Header } from "./Header";

gql`
  query GetGroupConversation($uuid: UUID!, $cursor: DateTime) {
    experience(uuid: $uuid) {
      experience {
        ...ExperienceContent
      }
    }
  }
`;

export const GroupConversation = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const { user } = useDoctor();
  const { data, previousData, loading, error, nextPage, fetchingMore } =
    usePaginatedQuery(GetGroupConversation, {
      variables: { uuid },
      selector: (d) => d.experience.itemsV3,
    });
  useEffect(() => {
    if (!data) return;
    const experience = data.experience;

    if (experience.type === "SINGLE_PATIENT_CONVERSATION") {
      // Redirect if the path is for the wrong experience type.
      navigate(
        getQAInboxRoute(
          experience as unknown as QAExperienceFragment,
          user.uuid,
        ),
      );
    }
    if (experience.type === "PROVIDER_ONLY_CONVERSATION") {
      // Redirect if the path is for the wrong experience type.
      navigate(`${routes.CONVERSATION_BASE}/${experience.uuid}`);
    }
  }, [isDesktop, user.uuid, navigate, data, previousData]);

  return (
    <Background className="flex-fill overflow-hidden flex">
      <div className="flex flex-fill">
        <ExperienceProvider
          key={uuid} // Get a new context for each experience
          uuid={uuid}
          type={data?.experience.type ?? "MULTI_PATIENTS_CONVERSATION"}
          data={data}
          fetchingMore={fetchingMore}
          nextPage={nextPage}
        >
          <div className="flex-fill flex-col items-center">
            <QueryResult data={data} error={error} loading={loading}>
              {({ experience: exp }) => (
                <>
                  <Header experience={exp} />
                  <ConversationContent />
                </>
              )}
            </QueryResult>
            <Composer joinExperienceOnSend sendOnCmdEnter />
          </div>
        </ExperienceProvider>
        <GroupConversationSideSection
          className="w-full h-full border-l border-grey-200"
          style={{
            minWidth: config.width["patient-sidebar"],
            maxWidth: config.width["patient-sidebar"],
          }}
          experienceUuid={uuid}
        />
      </div>
    </Background>
  );
};
