import { CallButton } from "components/CallButton/CallButton";

import { useLoadedDoctorExperience } from "../useLoadedDoctorExperience";

export const CallLauncher = () => {
  const { sendMessage } = useLoadedDoctorExperience();

  const sendLivekitRoom = () => {
    sendMessage({ livekitRoom: { _: "EMPTY" } });
  };

  return <CallButton className="ml-8" onClick={() => sendLivekitRoom()} />;
};
