import { RefObject } from "react";
import gql from "graphql-tag";

import { useMedicalCodes } from "atoms/medicalCodes/useMedicalCodes";
import { useUserForPrescriptions } from "components/EHRComposer/Prescriptions/useUserForPrescriptions";
import { FormInput } from "components/Form/Input/FormInput";
import { FormCptCodePicker } from "components/Form/MedicalCodes/FormCptCodePicker";
import { FormIcdCodePicker } from "components/Form/MedicalCodes/FormIcdCodePicker";
import { AsyncDoctorFormSelect } from "components/Form/Select/AsyncDoctorFormSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { AutoPagesOptions } from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  CreateMedicalOrder,
  DeleteMedicalOrder,
  DoctorSummaryFragment,
  SetMedicalOrderStatus,
  SubOrganizationSummaryFragment,
  TaskPriority,
  TaskPriorityKnownValues,
  UpdateMedicalOrder,
  UserPersonalInformationFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { CptCode, DistributiveOmit, ICD10Code } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { run } from "utils";
import { displayTaskPriorityKnownValue } from "utils/display";
import { language } from "utils/intl";
import { notifier } from "utils/notifier";

import { Maybe } from "../../../../base-types";
import { ExistingItemPillButton, NewItemPillButton } from "../ItemPillButton";
import {
  PatientTimelineItem,
  PatientTimelineItemProps,
} from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";
import { usePatientTimelineLocalItemState } from "../PatientTimelineLocalStateContext";
import { BaseItemPdfPreview, Section } from "./BaseItemPdfPreview";

gql`
  mutation CreateMedicalOrder($input: CreateMedicalOrderInput!) {
    createMedicalOrder(createMedicalOrderInput: $input) {
      medicalOrder {
        ...MedicalOrder
      }
    }
  }

  mutation UpdateMedicalOrder($input: UpdateMedicalOrderInput!) {
    updateMedicalOrder(updateMedicalOrderInput: $input) {
      medicalOrder {
        ...MedicalOrder
      }
    }
  }

  mutation DeleteMedicalOrder($input: DeleteMedicalOrderInput!) {
    deleteMedicalOrder(deleteMedicalOrderInput: $input) {
      medicalOrderUuid
    }
  }

  mutation SetMedicalOrderStatus($input: SetMedicalOrderStatusInput!) {
    setMedicalOrderStatus(setMedicalOrderStatusInput: $input) {
      medicalOrder {
        ...MedicalOrder
      }
    }
  }
`;

type FormData = {
  title: string;
  orderingDoctor: DoctorSummaryFragment;
  cptCodes: {
    code: CptCode;
    linkedICD10Codes: ICD10Code[];
  }[];
  icd10Codes: ICD10Code[];
  description: string;
  medicalCenter: string;
  priority: TaskPriority | undefined;
};

type FormDraft = {
  title: string;
  orderingDoctor: DoctorSummaryFragment; // TODO(@liautaud): Store UUIDs instead.
  cptCodes: CptCode[];
  icd10Codes: ICD10Code[];
  description: string;
  medicalCenter: string;
  priority: TaskPriority | null;
};

export const MedicalOrderItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"MedicalOrder">;
}) => {
  const t = useTranslation();
  const [setMedicalOrderStatus, medicalOrderStatusLoading] = useMutation(
    SetMedicalOrderStatus,
  );
  const [updateMedicalOrder] = useMutation(UpdateMedicalOrder);
  const [deleteMedicalOrder] = useMutation(DeleteMedicalOrder, {
    onSuccess: ({ medicalOrderUuid }, client) =>
      client.remove("MedicalOrder", medicalOrderUuid),
  });
  const autoPagesOptions: AutoPagesOptions = { margin: [50, 50, 30, 50] };
  const userForPrescription = useUserForPrescriptions();
  return (
    <DeletionConfirmationModal
      suffix={t("patient_view.medical_order.delete_suffix")}
      onConfirm={async (closeDeletionModal) => {
        await deleteMedicalOrder({ input: { medicalOrderUuid: item.uuid } });
        notifier.success(t("patient_view.medical_order.delete_success"));
        closeDeletionModal();
      }}
    >
      {(openDeletionModal) => (
        <BaseMedicalOrderItem
          item={item}
          assignedDoctors={[item.orderingDoctor]}
          withFaxOptions={{
            documentType: "MEDICAL_ORDER",
            getPreview: (previewRef, comment) => (
              <MedicalOrderFaxPreview
                user={userForPrescription}
                previewRef={previewRef}
                comment={comment}
                autoPagesOptions={autoPagesOptions}
                {...item}
              />
            ),
          }}
          actionButton={
            item.completedAt !== null
              ? {
                  label: t("patient_view.medical_order.mark_todo_button"),
                  leftIcon: "future",
                  secondary: true,
                  loading: medicalOrderStatusLoading,
                  onClick: () =>
                    void setMedicalOrderStatus({
                      input: {
                        medicalOrderUuid: item.uuid,
                        isCompleted: false,
                      },
                    }),
                  mode: "HOVER",
                }
              : {
                  label: t("patient_view.medical_order.mark_done_button"),
                  leftIcon: "check",
                  loading: medicalOrderStatusLoading,
                  onClick: () =>
                    void setMedicalOrderStatus({
                      input: {
                        medicalOrderUuid: item.uuid,
                        isCompleted: true,
                      },
                    }),
                  mode: "HOVER",
                }
          }
          additionalMenuItems={[
            {
              text: t("patient_view.item.delete"),
              icon: "trash",
              className: "text-danger",
              onClick: (closeMenu) => {
                closeMenu();
                openDeletionModal();
              },
            },
          ]}
          footerChildren={
            <>
              <NewItemPillButton
                type="Task"
                payload={{ medicalOrderUuid: item.uuid }}
              />
              {item.appointment && <ExistingItemPillButton item={item} />}
              {item.tasks.map((task) => (
                <ExistingItemPillButton key={task.uuid} item={task} />
              ))}
            </>
          }
          initialValues={{
            title: item.title,
            description: item.description ?? "",
            medicalCenter: item.medicalCenter ?? "",
            priority: item.priority ?? undefined,
            orderingDoctor: item.orderingDoctor,
            cptCodes: item.cptCodes.map((code) => ({
              code,
              linkedICD10Codes: [],
            })),
            icd10Codes: item.icd10Codes,
          }}
          onSubmit={async (values) => {
            await updateMedicalOrder({
              input: {
                medicalOrderUuid: item.uuid,
                title: values.title,
                description: values.description,
                medicalCenter: values.medicalCenter,
                cptCodes: values.cptCodes.map((it) => it.code),
                icd10Codes: values.icd10Codes,
                priority: values.priority ?? null,
                orderingDoctorUuid: values.orderingDoctor.uuid,
              },
            });
            notifier.success(t("patient_view.medical_order.update_success"));
          }}
        />
      )}
    </DeletionConfirmationModal>
  );
};

export const NewMedicalOrderItem = ({
  temporaryUuid,
  appointmentUuid,
  initialIcd10Codes,
}: {
  temporaryUuid: UUID;
  appointmentUuid?: UUID;
  initialIcd10Codes?: ICD10Code[];
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { user } = useDoctor();
  const { expandOtherItem } = usePatientTimelineLocalItemState(temporaryUuid);

  const [createMedicalOrder] = useMutation(CreateMedicalOrder);

  return (
    <BaseMedicalOrderItem
      item={{ temporaryUuid, __typename: "MedicalOrder" }}
      initialValues={{
        title: t("patient_view.default_title.medical_order"),
        description: "",
        medicalCenter: "",
        priority: undefined,
        orderingDoctor: user,
        cptCodes: [],
        icd10Codes: initialIcd10Codes ?? [],
      }}
      onSubmit={async (values) => {
        const medicalOrderData = await createMedicalOrder({
          input: {
            title: values.title,
            description: values.description,
            medicalCenter: values.medicalCenter,
            cptCodes: values.cptCodes.map((it) => it.code),
            icd10Codes: values.icd10Codes,
            priority: values.priority ?? null,
            orderingDoctorUuid: values.orderingDoctor.uuid,
            patientUuid: patient.uuid,
            appointmentUuid,
          },
        });
        if (!medicalOrderData) return;

        notifier.success(t("patient_view.medical_order.create_success"));
        expandOtherItem(medicalOrderData.medicalOrder.uuid);
      }}
    />
  );
};

const BaseMedicalOrderItem = (
  props: DistributiveOmit<
    PatientTimelineItemProps<"MedicalOrder", FormData, FormDraft>,
    "validationSchema" | "toDraft" | "fromDraft" | "children"
  >,
) => {
  const t = useTranslation();
  return (
    <PatientTimelineItem<"MedicalOrder", FormData, FormDraft>
      validationSchema={{
        title: "required",
      }}
      toDraft={({ cptCodes, priority, ...rest }) => ({
        cptCodes: cptCodes.map((it) => it.code),
        priority: priority ?? null,
        ...rest,
      })}
      fromDraft={({ cptCodes, priority, ...rest }) => ({
        cptCodes: cptCodes.map((code) => ({ code, linkedICD10Codes: [] })),
        priority: priority ?? undefined,
        ...rest,
      })}
      {...props}
    >
      <PatientTimelineItemContentWithPadding className="space-y-20">
        <AsyncDoctorFormSelect
          name="orderingDoctor"
          wrapperClassName="flex-fill"
          label={t("patient_view.medical_order.ordering_provider")}
          getVariables={(s) => ({
            filter: { permissions: ["VIEW_MEDICAL_ORDERS"], freeTextSearch: s },
          })}
        />
        <FormCptCodePicker
          label={t("patient_view.medical_order.order_codes")}
          name="cptCodes"
        />
        <FormIcdCodePicker name="icd10Codes" />
        <FormTextArea
          name="description"
          label={t("patient_view.medical_order.description")}
          placeholder={t("patient_view.medical_order.add_a_description")}
        />
        <FormInput
          name="medicalCenter"
          label={t("patient_view.medical_order.medical_center")}
          placeholder={t("patient_view.medical_order.add_a_medical_center")}
        />
        <FormSelect
          name="priority"
          isClearable
          wrapperClassName="flex-fill"
          options={TaskPriorityKnownValues}
          placeholder={t("tasks.task_composer.select")}
          label={t("tasks.task_composer.priority")}
          getOptionLabel={(s) => displayTaskPriorityKnownValue(s)}
        />
      </PatientTimelineItemContentWithPadding>
    </PatientTimelineItem>
  );
};

const MedicalOrderFaxPreview = ({
  cptCodes,
  icd10Codes,
  description,
  medicalCenter,
  user,
  previewRef,
  autoPagesOptions,
  comment,
}: {
  user: DoctorSummaryFragment &
    Omit<UserPersonalInformationFragment, "subOrganization"> & {
      subOrganization: SubOrganizationSummaryFragment & {
        locale: string;
        avatarUrl?: string | null;
      };
    };
  cptCodes: string[];
  icd10Codes: string[];
  description: Maybe<string>;
  medicalCenter: Maybe<string>;
  previewRef: RefObject<HTMLDivElement>;
  autoPagesOptions: AutoPagesOptions;
  comment: string;
}) => {
  const t = useTranslation();
  const { getCptByCode, getICD10CMByCode } = useMedicalCodes();

  return (
    <BaseItemPdfPreview
      previewRef={previewRef}
      className="flex-col text-black"
      autoPagesOptions={autoPagesOptions}
      user={user}
      theme="FAX"
    >
      {comment && (
        <Section
          theme="FAX"
          className="mt-24"
          title={t("pdf_preview.fax_comment")}
        >
          <div>{comment}</div>
        </Section>
      )}
      <Section
        theme="FAX"
        className="mt-24"
        title={t("patient_view.medical_order.order_codes")}
      >
        <div className="flex-col">
          {cptCodes.map((code, idx) =>
            run(() => {
              const cptCode = getCptByCode(code);
              return cptCode ? (
                <div
                  key={`cpt-${code}-${idx}`}
                  className="flex items-end space-y-8 border-b border-dashed"
                >
                  <div className="flex-shrink-0 max-w-1/2">
                    {cptCode.description}
                  </div>
                  <div className="flex-grow" />
                  <div className="flex-shrink-0">{cptCode.code}</div>
                </div>
              ) : null;
            }),
          )}
        </div>
      </Section>
      <Section theme="FAX" className="mt-24" title={t("form.icd_codes.title")}>
        {icd10Codes.map((code, idx) =>
          run(() => {
            const item = getICD10CMByCode(code);
            return item ? (
              <div
                key={`icd10-${item.code}-${idx}`}
                className="flex items-end space-y-8 border-b border-dashed"
              >
                <div className="flex-shrink-0">
                  {item[language as "fr" | "en" | "pt"]}
                </div>
                <div className="flex-grow" />
                <span className="flex-shrink-0">{item.code}</span>
              </div>
            ) : null;
          }),
        )}
      </Section>
      {description && (
        <Section
          theme="FAX"
          className="mt-24"
          title={t("patient_view.medical_order.description")}
        >
          {description}
        </Section>
      )}
      {medicalCenter && (
        <Section
          theme="FAX"
          className="mt-24"
          title={t("patient_view.medical_order.medical_center")}
        >
          {medicalCenter}
        </Section>
      )}
    </BaseItemPdfPreview>
  );
};
