// Conversion helpers

const ROUNDING_DECIMAL = 5;

export type FeetInches = { feet: number; inches: number };

export const cmToFeetInches = (cm: number): FeetInches => {
  const totalFeet = cm / 30.48;
  const feet = Math.trunc(roundNumber(totalFeet, ROUNDING_DECIMAL));
  const inches = roundNumber((totalFeet - feet) * 12, ROUNDING_DECIMAL);
  return { feet, inches };
};
export const feetInchesToCm = (feetInches: FeetInches) => {
  const totalInches = feetInches.feet * 12 + feetInches.inches;
  return roundNumber(2.54 * totalInches, ROUNDING_DECIMAL);
};

export const poundsToKg = (pounds: number) =>
  roundNumber(0.45359237 * pounds, ROUNDING_DECIMAL);
export const kgToPounds = (kg: number) =>
  roundNumber(2.20462262185 * kg, ROUNDING_DECIMAL);

export const celsiusToFahrenheit = (celsius: number) =>
  roundNumber(celsius * (9 / 5) + 32, ROUNDING_DECIMAL);
export const fahrenheitToCelsius = (fahrenheit: number) =>
  roundNumber((fahrenheit - 32) * (5 / 9), ROUNDING_DECIMAL);

export const roundNumber = (num: number, decimal: number) =>
  Math.round(num * 10 ** decimal) / 10 ** decimal;
