import { ReactNode, useCallback, useState } from "react";
import classNames from "classnames";
import * as Yup from "yup";

import { queryEndpoint } from "api/api-client";
import { parseServerJwt } from "api/jwt";
import { Background } from "components/Background/Backgound";
import { Button, FormButtonProps } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { CopiableText } from "components/CopiableText/CopiableText";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Icon } from "components/Icon/Icon";
import {
  CreateAppKey,
  CreateServerKey,
  DeleteServerKey,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { trackEvent } from "tracking";
import { run } from "utils";

import {
  authenticatePatientDefinition,
  CreatePatientData,
  createPatientDefinition,
} from "./utils";

type SetupCardProps = {
  cardStep: number;
  isOpen: boolean;
  isDone: boolean;
  goToNextStep: (from: number) => void;
  goToCompletedStep: (to: number) => void;
};

export const SDKSetupGuide = () => {
  const t = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [lastCompletedStep, setLastCompletedStep] = useState(0);

  const getCardProps = useCallback(
    (cardStep: number) => ({
      cardStep,
      isOpen: currentStep === cardStep,
      isDone: lastCompletedStep >= cardStep,
      goToNextStep: (from: number) => {
        if (from > lastCompletedStep) setLastCompletedStep(from);
        setCurrentStep(from + 1);
      },
      goToCompletedStep: (to: number) => {
        if (to > lastCompletedStep + 1) return;
        if (to === 2) trackEvent({ name: "Finish SDK Setup" });
        setCurrentStep(to);
      },
    }),
    [currentStep, lastCompletedStep],
  );

  return (
    <Background className="flex-col flex-fill flex-shrink overflow-auto max-w-full h-full p-12 lg:p-44">
      <h1 className="text-primary-dark text-24 font-bold mb-48">
        {t("developers.sdk_setup_guide.sdk_setup_guide.sample_app_guide")}
      </h1>
      <CreatePatientStep {...getCardProps(1)} />
      <CreateApiKeysStep {...getCardProps(2)} />
      <RunSampleAppStep {...getCardProps(3)} />
    </Background>
  );
};

const CreateApiKeysStep = (cardProps: SetupCardProps) => {
  const t = useTranslation();
  const [appKey, setAppKey] = useState<string | undefined>(undefined);
  const [creatingAppKey, setCreatingAppKey] = useState(false);
  const [createAppKey] = useMutation(CreateAppKey, {
    refetchQueries: ["AllAppKeys"],
    onSuccess: (response) => {
      setAppKey(response.appKey.value);
      setCreatingAppKey(false);
    },
  });

  return (
    <SetupCard
      title={t("sdk_setup_guide.create_your_mobile_sdk_api_key")}
      isNextReady={appKey !== undefined}
      {...cardProps}
    >
      <div>
        {appKey === undefined && (
          <>
            {t(
              "sdk_setup_guide.this_key_is_required_to_authenticate_your_sample_app_with_nabla",
            )}
            <SetupCardButton
              label={t("developers.sdk_setup_guide.sdk_setup_guide.create")}
              onClick={() => {
                setCreatingAppKey(true);
                return createAppKey({ appKeyInput: { name: "setup" } });
              }}
              loading={creatingAppKey}
            />
          </>
        )}
        {appKey !== undefined && (
          <>
            <SuccessfulStepCard
              text={t(
                "sdk_setup_guide.your_mobile_sdk_api_key_has_been_created",
              )}
            />
            <div className="mt-24">
              {t(
                "sdk_setup_guide.copy_the_api_key_below_as_you_will_need_it_in_the_next_step",
              )}
            </div>
            <CopiableText
              text={appKey}
              title={t(
                "developers.sdk_setup_guide.sdk_setup_guide.mobile_sdk_api_key",
              )}
            />
          </>
        )}
      </div>
    </SetupCard>
  );
};

const CreatePatientStep = ({ ...cardProps }: SetupCardProps) => {
  const t = useTranslation();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    undefined,
  );
  const [creatingPatient, setCreatingPatient] = useState(false);
  const [createServerKey] = useMutation(CreateServerKey);
  const [deleteServerKey] = useMutation(DeleteServerKey);

  return (
    <SetupCard
      title={t("developers.sdk_setup_guide.sdk_setup_guide.create_a_patient")}
      isNextReady={!!accessToken && !!refreshToken}
      {...cardProps}
    >
      {run(() => {
        if (accessToken === undefined || refreshToken === undefined) {
          return (
            <Form<CreatePatientData>
              initialValues={{
                first_name: "",
                last_name: "",
                date_of_birth: "",
              }}
              validateOnMount
              className="w-full"
              validationSchema={{
                first_name: Yup.string().required(
                  t(
                    "developers.sdk_setup_guide.sdk_setup_guide.first_name_is_required",
                  ),
                ),
                last_name: Yup.string().required(
                  t(
                    "developers.sdk_setup_guide.sdk_setup_guide.last_name_is_required",
                  ),
                ),
                date_of_birth: Yup.string()
                  .required(t("sdk_setup_guide.the_birth_date_is_required"))
                  .matches(
                    /^(19|20)\d\d-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])$/u,
                    {
                      message: t("sdk_setup_guide.the_birth_date_is_not_valid"),
                    },
                  ),
              }}
              onSubmit={async (patient) => {
                const result = await createServerKey({
                  serverKeyInput: {
                    name: "SampleAppSetupKey",
                    description:
                      "Temporary server API key to run the sample app",
                  },
                });
                if (!result) return;

                trackEvent({ name: "Start SDK Setup" });
                const uuid = result.serverKey.uuid;
                const serverToken = parseServerJwt(result.bearerToken);

                const { data: createPatientData } = await queryEndpoint(
                  createPatientDefinition,
                  {
                    data: patient,
                    requestContext: {
                      serverToken: () => Promise.resolve(serverToken),
                    },
                  },
                );

                const { data: authenticatePatientData } = await queryEndpoint(
                  authenticatePatientDefinition(createPatientData.id),
                  {
                    requestContext: {
                      serverToken: () => Promise.resolve(serverToken),
                    },
                  },
                );

                setAccessToken(authenticatePatientData.access_token);
                setRefreshToken(authenticatePatientData.refresh_token);
                setCreatingPatient(false);
                await deleteServerKey({ uuid });
              }}
            >
              <div className="grid grid-cols-3 gap-x-24">
                <FormInput
                  name="first_name"
                  label={t(
                    "developers.sdk_setup_guide.sdk_setup_guide.first_name",
                  )}
                  placeholder={t(
                    "developers.sdk_setup_guide.sdk_setup_guide.first_name",
                  )}
                  autoFocus
                />
                <FormInput
                  name="last_name"
                  label={t(
                    "developers.sdk_setup_guide.sdk_setup_guide.last_name",
                  )}
                  placeholder={t(
                    "developers.sdk_setup_guide.sdk_setup_guide.last_name",
                  )}
                />
                <FormInput
                  name="date_of_birth"
                  label={t(
                    "developers.sdk_setup_guide.sdk_setup_guide.birthdate",
                  )}
                  placeholder={t(
                    "developers.sdk_setup_guide.sdk_setup_guide.yyyymmdd",
                  )}
                />
              </div>
              <Submit
                className="w-auto px-12 text-14 font-medium mt-20 h-40"
                label={t("developers.sdk_setup_guide.sdk_setup_guide.create")}
                requiresValid
                loading={creatingPatient}
              />
            </Form>
          );
        }

        return (
          <>
            <SuccessfulStepCard
              text={t("sdk_setup_guide.your_patient_has_been_created")}
            />
            <div className="mt-24">
              {t(
                "sdk_setup_guide.here_are_the__authentication_tokens_you_need_to_use_in_your_app_for_your_patient_please_copy_them",
              )}
            </div>
            <CopiableText title="Access token" text={accessToken} />
            <CopiableText title="Refresh token" text={refreshToken} />
          </>
        );
      })}
    </SetupCard>
  );
};

const RunSampleAppStep = (cardProps: SetupCardProps) => {
  const t = useTranslation();

  return (
    <SetupCard
      {...cardProps}
      title={t("sdk_setup_guide.send_a_message_with_the_sample_app")}
      nextStepLabel={t("developers.sdk_setup_guide.sdk_setup_guide.done")}
    >
      <ul className="ml-16 list-disc list-inside">
        <li className="mb-8">
          {t("developers.sdk_setup_guide.sdk_setup_guide.clone_the_sample_app")}
          <SetupDeviceList
            iOS={
              <SetupLink
                label="iOS"
                href="https://github.com/nabla/nabla-ios/tree/master/Sample/MessagingSampleApp"
              />
            }
            android={
              <SetupLink
                label="Android"
                href="https://github.com/nabla/nabla-android/tree/main/messaging-sample-app"
              />
            }
            RN={
              <SetupLink
                label="ReactNative"
                href="https://github.com/nabla/nabla-react-native/tree/main/messaging-sample-app"
              />
            }
          />
        </li>

        <li className="mb-8">
          {t("sdk_setup_guide.follow_the_readme_instructions")}
          <ul className="list-inside list-disc ml-16">
            <li className="mb-8">
              {t(
                "sdk_setup_guide.paste_your_patients_access_token_and_refresh_tokens_in_the_sample_app",
              )}
              <SetupDeviceList
                iOS={
                  <>
                    {t("developers.sdk_setup_guide.sdk_setup_guide.on_ios_in")}{" "}
                    <SetupLink
                      label="FakeAuthenticator.swift"
                      href="https://github.com/nabla/nabla-ios/blob/main/Sample/MessagingSampleApp/MessagingSampleApp/FakeAuthenticator.swift"
                    />
                  </>
                }
                android={
                  <>
                    {t(
                      "developers.sdk_setup_guide.sdk_setup_guide.on_android_in",
                    )}{" "}
                    <SetupLink
                      label="MessagingSampleApp.kt"
                      href="https://github.com/nabla/nabla-android/blob/main/messaging-sample-app/src/main/java/com/nabla/sdk/messagingsampleapp/MessagingSampleApp.kt"
                    />
                  </>
                }
                RN={
                  <>
                    {t("developers.sdk_setup_guide.sdk_setup_guide.on_rn_in")}{" "}
                    <SetupLink
                      label="src/App.tsx"
                      href="https://github.com/nabla/nabla-react-native/blob/main/messaging-sample-app/src/App.tsx"
                    />
                  </>
                }
              />
            </li>
            <li>
              {t(
                "sdk_setup_guide.then_paste_your_api_key_also_in_the_sample_app",
              )}
              <SetupDeviceList
                iOS={
                  <>
                    {t("developers.sdk_setup_guide.sdk_setup_guide.on_ios_in")}{" "}
                    <SetupLink
                      href="https://github.com/nabla/nabla-ios/blob/main/Sample/MessagingSampleApp/MessagingSampleApp/AppDelegate.swift"
                      label="AppDelegate.swift"
                    />
                  </>
                }
                android={
                  <>
                    {t(
                      "developers.sdk_setup_guide.sdk_setup_guide.on_android_in",
                    )}{" "}
                    <SetupLink
                      label="AndroidManifest.xml"
                      href="https://github.com/nabla/nabla-android/blob/main/messaging-sample-app/src/main/AndroidManifest.xml"
                    />
                  </>
                }
                RN={
                  <>
                    {t("developers.sdk_setup_guide.sdk_setup_guide.on_rn_in")}{" "}
                    <SetupLink
                      label="src/App.tsx"
                      href="https://github.com/nabla/nabla-react-native/blob/main/messaging-sample-app/src/App.tsx"
                    />
                  </>
                }
              />
            </li>
          </ul>
        </li>
        <li className="mb-8">
          {t("developers.sdk_setup_guide.sdk_setup_guide.compile_your_app")}
        </li>
        <li className="mb-8">
          {t(
            "sdk_setup_guide.you_can_now_create_a_new_conversation_and_send_a_message",
          )}
        </li>
      </ul>
      <p className="ml-16">
        {t(
          "sdk_setup_guide.if_you_want_to_know_more_about_the_full_integration_of_the_nabla_sdk_in_your_app_",
        )}
        <SetupLink
          href="https://docs.nabla.com/docs"
          label={t("sdk_setup_guide.check_out_our_documentation")}
        />
        .
      </p>
    </SetupCard>
  );
};

const SetupCard = ({
  cardStep,
  isDone,
  isOpen,
  goToNextStep,
  goToCompletedStep,
  isNextReady = true,
  title,
  nextStepLabel,
  children,
}: {
  cardStep: number;
  isNextReady?: boolean;
  title: string;
  nextStepLabel?: string;
  children?: ReactNode;
} & SetupCardProps) => {
  const t = useTranslation();

  return (
    <div
      className={classNames(
        "bg-white rounded border shadow-sm-outlined px-10 lg:px-30 py-8 lg:py-20 mb-12 lg:mb-24",
        {
          "cursor-pointer": isDone && !isOpen,
        },
      )}
      onClick={() => {
        goToCompletedStep(cardStep);
      }}
    >
      <div className="flex items-center">
        {isDone && (
          <Icon
            name="check"
            size={36}
            className="rounded-full mr-12 p-8 text-white bg-primary"
          />
        )}
        {!isDone && (
          <div className="rounded-full h-36 w-36 flex-center mr-12 bg-grey-100">
            {cardStep}
          </div>
        )}
        <div className="text-16 font-medium text-primary-dark">{title}</div>
      </div>
      {isOpen && (
        <>
          <div className="mt-12 block">{children}</div>
          {isNextReady && (
            <SetupCardButton
              label={
                nextStepLabel ??
                t("developers.sdk_setup_guide.sdk_setup_guide.next")
              }
              onClick={(e) => {
                e.stopPropagation();
                goToNextStep(cardStep);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

const SetupCardButton = ({ label, onClick }: FormButtonProps) => (
  <Button
    className="mt-20"
    label={label}
    onClick={(e) => {
      e.preventDefault();
      if (onClick) onClick(e);
    }}
  />
);

const SuccessfulStepCard = ({ text }: { text: string }) => (
  <div className="flex items-center rounded bg-light-blue border border-primary p-12">
    <Icon
      className="rounded-full bg-primary text-white font-medium p-2 mr-12"
      name="check"
      size={16}
    />
    <span className="text-primary">{text}</span>
  </div>
);

const SetupLink = ({ href, label }: { href: string; label: string }) => (
  <a
    className="text-primary text-14 underline"
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {label}
  </a>
);

const SetupDeviceList = ({
  iOS,
  android,
  RN,
}: {
  iOS: string | ReactNode;
  android: string | ReactNode;
  RN: string | ReactNode;
}) => (
  <ul
    className="ml-16"
    style={{ listStyle: "circle", listStylePosition: "inside" }}
  >
    <li>{iOS}</li>
    <li>{android}</li>
    <li>{RN}</li>
  </ul>
);
