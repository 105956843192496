import { Submit } from "components/Button/Submit";
import {
  FormDropzone,
  FormDropzoneValue,
} from "components/Form/Dropzone/FormDropzone";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Icon } from "components/Icon/Icon";
import { useFocusAfterAnimation } from "hooks";
import { useTranslation } from "i18n";

export type MultimediaTemplateFormValues = {
  title: string;
  file: FormDropzoneValue;
};

export const MultimediaTemplateForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues: MultimediaTemplateFormValues;
  onSubmit: (values: MultimediaTemplateFormValues) => Promise<any>;
}) => {
  const t = useTranslation();
  const inputRef = useFocusAfterAnimation();

  return (
    <Form<MultimediaTemplateFormValues>
      initialValues={initialValues}
      validationSchema={{ file: "required", title: "required" }}
      onSubmit={(values) => onSubmit(values)}
      className="w-full flex-fill flex-col space-y-24"
    >
      <FormInput
        inputRef={inputRef}
        name="title"
        label={t("template_forms.template_form.title")}
        placeholder={t("template_forms.template_form.enter_a_title")}
      />
      <FormDropzone
        name="file"
        maxSize={50_000_000}
        label={t("template_forms.template_form.content")}
        className="flex-fill"
        placeholder={
          <>
            <Icon name="uploadCloud" className="mr-10" size={20} />
            {t("template_forms.template_form.upload_file")}
          </>
        }
      />
      <Submit
        className="mt-36"
        label={t("template_forms.template_form.save")}
      />
    </Form>
  );
};
