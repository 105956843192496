import gql from "graphql-tag";

import { DeleteTemplate } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  mutation DeleteTemplate($templateUuid: UUID!) {
    deleteTemplate(templateUuid: $templateUuid) {
      status
    }
  }
`;

export const useDeleteTemplate = (onSuccess?: () => void) => {
  const t = useTranslation();
  const [deleteTemplate] = useMutation(DeleteTemplate);

  return (templateUuid: UUID) =>
    deleteTemplate(
      { templateUuid },
      {
        onSuccess: (_, client) => {
          client.remove("Template", templateUuid);
          notifier.success(
            t("template_forms.use_delete_template.template_deleted"),
          );
          onSuccess?.();
        },
      },
    );
};
