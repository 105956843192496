import { useEffect, useState } from "react";

import { BottomSafeArea } from "components/Mobile/SafeArea";
import { useMaybeExperience } from "contexts/Experience/ExperienceContext";
import { listen } from "utils";

export const ExperienceComposerBottomSafeArea = () => {
  const { textAreaRef } = useMaybeExperience();
  const [keyboardOpened, setKeyboardOpened] = useState(false);

  useEffect(() => {
    if (!textAreaRef.current) return;
    const removeFocus = listen(textAreaRef.current, "focus", () => {
      setKeyboardOpened(true);
    });
    const removeBlur = listen(textAreaRef.current, "blur", () => {
      setKeyboardOpened(false);
    });
    return () => {
      removeFocus();
      removeBlur();
      setKeyboardOpened(false);
    };
  }, [textAreaRef]);

  return keyboardOpened ? null : <BottomSafeArea />;
};
