import { createContext, useContext } from "react";

import { useMaybeExperience } from "contexts/Experience/ExperienceContext";
import { GetQAExperienceData } from "generated/provider";

export const QAExperienceContext = createContext<{
  isInExperience: boolean;
  patient: NonNullable<GetQAExperienceData["experience"]["patient"]>;
  isActivityPanelOpened: boolean;
  setIsActivityPanelOpened: (isOpen: boolean) => void;
} | null>(null);
QAExperienceContext.displayName = "QAExperienceContext";

export const useMaybeQAExperience = () => {
  const experienceContext =
    useMaybeExperience<GetQAExperienceData["experience"]>();
  const qaContext = useContext(QAExperienceContext);
  if (!qaContext) throw new Error("QAExperienceProvider not available");
  return { ...experienceContext, ...qaContext };
};

export const useQAExperience = () => {
  const maybeQaExperience = useMaybeQAExperience();
  if (!maybeQaExperience.uuid || !maybeQaExperience.isLoaded) {
    throw new Error("QAExperience not loaded");
  }
  return maybeQaExperience;
};
