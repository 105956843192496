import { ReactNode, useRef, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { RecordingData, VoiceRecorder } from "capacitor-voice-recorder";
import classNames from "classnames";

import { Icon } from "components/Icon/Icon";
import { Popover } from "components/Popover/Popover";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useTargetState } from "hooks";
import { useDisplayTimeElapsed } from "hooks/useDisplayTimeElapsed";
import { useTranslation } from "i18n";
import { isRecorderAvailable } from "utils";
import { now } from "utils/date";

export type AudioRecorderProps = {
  buttonClassName?: string;
  submitLabel?: string;
  onSubmit: (file: File) => void;
  allowScrolling?: boolean;
  children?: ReactNode;
  disabled?: boolean;
};

export const AudioRecorder = ({
  buttonClassName,
  submitLabel,
  onSubmit,
  allowScrolling,
  children,
  disabled,
}: AudioRecorderProps) => {
  const t = useTranslation();

  const [recorderTarget, setRecorderTarget] = useTargetState();

  return (
    <TooltipWrapper
      label={t(
        "audio_recorder.audio_messages_are_not_supported_on_this_browser",
      )}
      show={!isRecorderAvailable}
      position="top"
      className="flex"
    >
      <button
        className={classNames(
          disabled ? "opacity-70" : undefined,
          buttonClassName,
        )}
        disabled={!isRecorderAvailable || disabled}
        onClick={setRecorderTarget}
        type="button"
      >
        <Icon name={!isRecorderAvailable || disabled ? "micOff" : "micOn"} />
        {children}
      </button>
      {recorderTarget && (
        <RecorderPopup
          target={recorderTarget}
          onSubmit={onSubmit}
          submitLabel={submitLabel ?? t("audio_recorder.audio_recorder.send")}
          allowScrolling={allowScrolling}
          close={() => setRecorderTarget(undefined)}
        />
      )}
    </TooltipWrapper>
  );
};

const RecorderPopup = ({
  target,
  submitLabel,
  onSubmit,
  allowScrolling,
  close,
}: {
  target: Element;
  submitLabel: string;
  onSubmit: (file: File) => void;
  allowScrolling?: boolean;
  close: () => void;
}) => {
  const t = useTranslation();
  const [state, setState] = useState<
    | { mode: "START" | "ASK_MEDIA_ACCESS" }
    | { mode: "RECORDING"; from: ISOString }
    | { mode: "PAUSED"; millisecondsTotal: number }
  >({ mode: "START" });
  const [webRecorder, setWebRecorder] = useState<MediaRecorder>();
  const time = useDisplayTimeElapsed(
    state.mode === "RECORDING" ? { from: state.from } : { paused: true },
  );
  const chunksRef = useRef<Blob[]>([]);

  // Note: we have to define separate methods for web vs mobile because the
  // library we use to power voice recording on Capacitor does not work
  // well enough on web, for example it does not play well with permissions.
  const isMobile = Capacitor.isNativePlatform();

  return (
    <Popover
      target={target}
      position={["top", "top-left"]}
      onClose={state.mode === "RECORDING" ? undefined : close}
      className="pt-24 pb-16 px-12 flex-col items-center"
      allowScrolling={allowScrolling}
      style={{ width: 220 }}
    >
      <div
        className={classNames("text-18", {
          "text-danger": state.mode === "RECORDING",
        })}
      >
        {time}
      </div>
      <button
        className={classNames(
          "mt-24 bg-danger rounded-full text-white flex-center text-14 disabled:opacity-80",
          { "recording-pulse": state.mode === "RECORDING" },
        )}
        style={{ height: 80, width: 80 }}
        disabled={state.mode === "ASK_MEDIA_ACCESS"}
        onClick={() => {
          if (state.mode === "RECORDING") {
            if (isMobile) {
              VoiceRecorder.pauseRecording().then(() =>
                setState({
                  mode: "PAUSED",
                  millisecondsTotal: state.from.millisecondsFromNow(),
                }),
              );
            } else {
              webRecorder?.pause();
              setState({
                mode: "PAUSED",
                millisecondsTotal: state.from.millisecondsFromNow(),
              });
            }
            return;
          }
          if (state.mode === "PAUSED") {
            if (isMobile) {
              VoiceRecorder.resumeRecording().then(() =>
                setState({
                  mode: "RECORDING",
                  from: now().minusMilliseconds(state.millisecondsTotal),
                }),
              );
            } else {
              webRecorder?.resume();
              setState({
                mode: "RECORDING",
                from: now().minusMilliseconds(state.millisecondsTotal),
              });
            }
            return;
          }

          setState({ mode: "ASK_MEDIA_ACCESS" });
          if (isMobile) {
            VoiceRecorder.requestAudioRecordingPermission().then((result) => {
              if (result.value) {
                VoiceRecorder.startRecording().then(() =>
                  setState({ mode: "RECORDING", from: now() }),
                );
              }
            });
          } else {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((microphone) => {
                const context = new AudioContext();
                const output = context.createMediaStreamDestination();
                context.createMediaStreamSource(microphone).connect(output);
                const recorder = new MediaRecorder(output.stream);
                recorder.ondataavailable = (e) =>
                  chunksRef.current.push(e.data);
                recorder.onstop = () => {
                  microphone.getAudioTracks().forEach((track) => track.stop());
                };
                recorder.start();
                setWebRecorder(recorder);
                setState({ mode: "RECORDING", from: now() });
              });
          }
        }}
      >
        {state.mode === "RECORDING" ? (
          <Icon name="stop" />
        ) : state.mode === "PAUSED" ? (
          t("audio_recorder.audio_recorder.resume")
        ) : (
          t("audio_recorder.audio_recorder.record")
        )}
      </button>
      <div className="mt-16 w-full flex justify-between">
        <button
          className="text-14 p-8 hover:opacity-90"
          onClick={() => {
            if (isMobile && state.mode === "RECORDING") {
              VoiceRecorder.stopRecording().then(close);
            } else {
              webRecorder?.stop();
              close();
            }
          }}
        >
          {t("audio_recorder.audio_recorder.cancel")}
        </button>
        <button
          className="button-link text-14 font-normal"
          disabled={state.mode !== "PAUSED"}
          onClick={() => {
            if (isMobile) {
              VoiceRecorder.stopRecording().then((result: RecordingData) => {
                setState({ mode: "START" });
                chunksRef.current.push(
                  base64StringToBlob(
                    result.value.recordDataBase64,
                    result.value.mimeType,
                  ),
                );
                onSubmit(
                  new File(chunksRef.current, `${now().format("file")}`, {
                    type: result.value.mimeType,
                  }),
                );
                close();
              });
            } else {
              webRecorder?.addEventListener("stop", () => {
                onSubmit(
                  new File(chunksRef.current, `${now().format("file")}.ogg`, {
                    type: "audio/ogg; codecs=opus",
                  }),
                );
                close();
              });
              webRecorder?.stop();
            }
          }}
        >
          {submitLabel}
        </button>
      </div>
    </Popover>
  );
};

// Source: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
const base64StringToBlob = (
  b64Data: string,
  contentType: string,
  sliceSize = 512,
): Blob => {
  // There is a small issue with the VoiceRecorder lib, sometimes the mime type
  // and encoding info are in the b64 data, sometimes not.
  // See https://github.com/tchvu3/capacitor-voice-recorder/issues/16.
  const BASE64_ENCODING_TOKEN = "base64,";
  const actualData = b64Data.includes(BASE64_ENCODING_TOKEN)
    ? b64Data.slice(
        b64Data.indexOf(BASE64_ENCODING_TOKEN) + BASE64_ENCODING_TOKEN.length,
      )
    : b64Data;
  const byteCharacters = atob(actualData);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.codePointAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
