import { ClickableIcon } from "components/Icon/ClickableIcon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import {
  FamilyMemberDegreeKnownValue,
  FamilyMemberDegreeKnownValues,
} from "generated/provider";
import { useSearch } from "hooks/useDebounce";
import { useIsDesktop } from "hooks/useMediaQuery";
import { displayFamilyMemberDegree } from "utils/display";

const DESKTOP_MENU_WIDTH = 280;

export const FamilyMemberMenu = ({
  onFamilyMemberSelected,
}: {
  onFamilyMemberSelected: (o: FamilyMemberDegreeKnownValue) => void;
}) => {
  const inputProps = useSearch();
  const familyMemberList = FamilyMemberDegreeKnownValues;
  const isDesktop = useIsDesktop();

  const searchResults = familyMemberList.filter((familyMember) =>
    familyMember.toLowerCase().fuzzyMatch(inputProps.value),
  );

  return (
    <UncontrolledPopover
      position="top-right"
      className="mt-6 flex-col overflow-hidden"
      style={{
        maxHeight: "min(500px, 80%)",
        width: isDesktop ? DESKTOP_MENU_WIDTH : undefined,
      }}
      fullWidthOnMobile
      allowScrolling
      content={(close) => (
        <div className="flex-fill overflow-auto border-t">
          <div className="flex-col">
            {searchResults.map((familyMemberDegree) => (
              <button
                className="group"
                onClick={() => {
                  onFamilyMemberSelected(familyMemberDegree);
                  close();
                }}
                key={familyMemberDegree}
              >
                <div className="flex items-center list-element py-8 px-16 space-x-8 border-none">
                  {displayFamilyMemberDegree(familyMemberDegree)}
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    >
      {({ setTarget }) => (
        <ClickableIcon
          name="add"
          size={18}
          className="bg-grey-200/25 hover:bg-grey-200/50 rounded p-[3px] ml-auto -my-2"
          onClick={setTarget}
        />
      )}
    </UncontrolledPopover>
  );
};
