import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Modal } from "components/Modal/Modal";
import { Popover } from "components/Popover/Popover";
import { CalendarAppointmentFragment } from "generated/provider";
import { useIsDesktop } from "hooks/useMediaQuery";

import { AppointmentCard } from "../AppointmentCard";

export const AppointmentPopover = ({
  appointment,
  target,
  onClose,
  onCancelAppointment,
}: {
  appointment: CalendarAppointmentFragment;
  target: Element;
  onClose: () => void;
  onCancelAppointment: (appointmentUuid: UUID) => void;
}) => {
  const isDesktop = useIsDesktop();

  const Delete = (
    <ClickableIcon
      name="calendarCancel"
      className="py-0 px-2"
      size={20}
      onClick={() => onCancelAppointment(appointment.uuid)}
    />
  );

  return isDesktop ? (
    <Popover target={target} position="left" allowScrolling onClose={onClose}>
      <AppointmentCard
        appointment={appointment}
        style={{ width: 273 }}
        otherAction={Delete}
        onClose={onClose}
      />
    </Popover>
  ) : (
    <Modal onHide={onClose}>
      <AppointmentCard
        appointment={appointment}
        style={{ width: "100%" }}
        otherAction={Delete}
        onClose={onClose}
      />
    </Modal>
  );
};
