import { useRef } from "react";

import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

export type AutoPagesOptions = {
  margin: [top: number, left: number, bottom: number, right: number];
};
export const usePDFPreview = (options?: AutoPagesOptions) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const t = useTranslation();

  return {
    previewRef,
    generatePdf: (): Promise<Blob> =>
      // Lazy load the module until needed, because it's heavy
      import("html2pdf.js")
        .then(({ default: html2pdf }) =>
          html2pdf()
            .from(previewRef.current)
            .set({
              // https://ekoopmans.github.io/html2pdf.js/#options
              ...(options?.margin && {
                margin: options.margin.map((v) => v / 3.78), // Convert to mm, default jsPDF unit
                pagebreak: { mode: "css" },
              }),
              image: { type: "jpeg", quality: 0.98 },
              html2canvas: {
                scale: 2, // Required to get correct quality output
                logging: false,
                // Required to render images external https://github.com/niklasvh/html2canvas/issues/722
                useCORS: true,
              },
            })
            .outputPdf("blob"),
        )
        .catch((e) => {
          notifier.error({
            user: t("pdf_preview.error"),
            sentry: { exception: e },
          });
          throw e;
        }),
  };
};
