import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { Link } from "components/Link/Link";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Table } from "components/Table/Table";
import {
  DeleteSubOrganization,
  RegisterDoctorInSubOrganization,
  SubOrganizationsQuery,
  SubOrganizationSummaryFragment,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";
import { routes } from "routes";
import { copyToClipBoard } from "utils";
import { notifier } from "utils/notifier";

import { ProviderComposer } from "../../Accounts/Providers/ProviderComposer";
import { SubOrganizationsComposer } from "./SubOrganizationComposer";

gql`
  # schema = ORGANIZATION_USER

  fragment SubOrganizationSummary on SubOrganization {
    uuid
    displayName
    createdAt
    numberOfProviders
    numberOfPatients
  }

  query SubOrganizationsQuery($cursor: String) {
    myOrganization {
      subOrganizationCreationEnabled
      subOrganizations(page: { cursor: $cursor }) {
        data {
          ...SubOrganizationSummary
        }
        hasMore
        nextCursor
      }
    }
  }

  mutation RegisterDoctorInSubOrganization(
    $subOrganizationUuid: UUID!
    $email: String!
    $payload: RegisterDoctorInput!
  ) {
    registerDoctor(
      subOrganizationUuid: $subOrganizationUuid
      email: $email
      payload: $payload
    ) {
      subOrganization {
        uuid
        numberOfProviders
      }
    }
  }

  mutation DeleteSubOrganization($uuid: UUID!) {
    deleteSubOrganization(uuid: $uuid) {
      deletedSubOrganizationUuid
    }
  }
`;

export type SubOrganizationTableHeader =
  | "DISPLAY_NAME"
  | "CREATED_AT"
  | "NUMBER_OF_PROVIDERS"
  | "NUMBER_OF_PATIENTS";

type ComposerState =
  | {
      mode: "createOrUpdateSubOrganization";
      subOrganization?: SubOrganizationSummaryFragment;
    }
  | {
      mode: "createProviderInSubOrganization";
      subOrganization: SubOrganizationSummaryFragment;
    };

export const SubOrganizations = () => {
  const t = useTranslation();
  const [composerState, setComposerState] = useState<ComposerState>();

  const [registerDoctorInSubOrganization] = useMutation(
    RegisterDoctorInSubOrganization,
    {
      onSuccess: () =>
        notifier.success(t("providers.providers.account_created")),
    },
  );

  const [deleteSubOrganization] = useMutation(DeleteSubOrganization, {
    onSuccess: (output, client) => {
      client.update({
        query: SubOrganizationsQuery,
        write: (draft) => {
          draft.subOrganizations.data = draft.subOrganizations.data.filter(
            (subOrg: SubOrganizationSummaryFragment) =>
              subOrg.uuid !== output.deletedSubOrganizationUuid,
          );
        },
      });
      notifier.success(
        t("settings.sub_organizations.suborganization_deleted_notification"),
      );
    },
  });

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44">
      {composerState?.mode === "createOrUpdateSubOrganization" && (
        <SubOrganizationsComposer
          subOrganization={composerState.subOrganization}
          onClose={() => setComposerState(undefined)}
        />
      )}
      {composerState?.mode === "createProviderInSubOrganization" && (
        <ProviderComposer
          onSubmit={async ({ email, avatar: _, ...rest }) => {
            await registerDoctorInSubOrganization({
              subOrganizationUuid: composerState.subOrganization.uuid,
              email,
              payload: rest,
            });
          }}
          onClose={() => setComposerState(undefined)}
        />
      )}
      <PaginatedQuery
        query={SubOrganizationsQuery}
        selector={(d) => d.subOrganizations}
      >
        {({ subOrganizationCreationEnabled, subOrganizations }, utils) => (
          <>
            <div className="flex items-center">
              <div className="flex-col flex-1">
                <h1 className="text-primary-dark text-24 font-bold">
                  {t("settings.sub_organizations.title")}
                </h1>
              </div>
              <div>
                <Button
                  label={t("settings.sub_organizations.create_button")}
                  disabled={!subOrganizationCreationEnabled}
                  onClick={() =>
                    setComposerState({
                      mode: "createOrUpdateSubOrganization",
                      subOrganization: undefined,
                    })
                  }
                  className="sm:mt-12"
                />
              </div>
            </div>
            {!subOrganizationCreationEnabled && (
              <div>
                <Translation
                  k="settings.sub_organizations.please_contact_us_to_enable_suborgs"
                  components={{
                    a: ({ children }) => (
                      <Link
                        className="text-primary underline"
                        to={`${routes.HELP}`}
                        children={children}
                      />
                    ),
                  }}
                />
              </div>
            )}
            {(subOrganizationCreationEnabled ||
              subOrganizations.data.length >= 2) && (
              <>
                <div className="mt-24">
                  <Table<
                    SubOrganizationTableHeader,
                    SubOrganizationSummaryFragment
                  >
                    elements={subOrganizations.data}
                    fieldHeaders={[
                      {
                        id: "DISPLAY_NAME",
                        name: t(
                          "settings.sub_organizations.column_header_name",
                        ),
                        sortable: false,
                      },
                      {
                        id: "CREATED_AT",
                        name: t(
                          "settings.sub_organizations.column_header_created_at",
                        ),
                        sortable: false,
                      },
                      {
                        id: "NUMBER_OF_PROVIDERS",
                        name: t(
                          "settings.sub_organizations.column_header_number_of_providers",
                        ),
                        sortable: false,
                      },
                      {
                        id: "NUMBER_OF_PATIENTS",
                        name: t(
                          "settings.sub_organizations.column_header_number_of_patients",
                        ),
                        sortable: false,
                      },
                    ]}
                    fields={(subOrganization) => [
                      subOrganization.displayName,
                      subOrganization.createdAt.format("date"),
                      subOrganization.numberOfProviders,
                      subOrganization.numberOfPatients,
                    ]}
                    menuItems={(subOrganization) => [
                      {
                        icon: "clipboard",
                        text: t(
                          "settings.sub_organizations.copy_suborganization_id",
                        ),
                        onClick: (close: () => void) => {
                          close();
                          void copyToClipBoard(
                            subOrganization.uuid,
                            t(
                              "settings.sub_organizations.suborganization_id_copied",
                            ),
                          );
                        },
                      },
                      {
                        icon: "edit",
                        text: "Edit",
                        onClick: (close: () => void) => {
                          setComposerState({
                            mode: "createOrUpdateSubOrganization",
                            subOrganization,
                          });
                          close();
                        },
                      },
                      {
                        icon: "addPeople",
                        text: t(
                          "settings.sub_organizations.create_provider_button",
                        ),
                        onClick: (close: () => void) => {
                          setComposerState({
                            mode: "createProviderInSubOrganization",
                            subOrganization,
                          });
                          close();
                        },
                      },
                      {
                        icon: "trash",
                        text: t(
                          "settings.sub_organizations.delete_sub_organization_button",
                        ),
                        disable: {
                          if:
                            subOrganization.numberOfProviders > 0 ||
                            subOrganization.numberOfPatients > 0,
                          tooltip: t(
                            "settings.sub_organizations.can_only_delete_empty_suborg_tooltip",
                          ),
                        },
                        onClick: (close: () => void) => {
                          close();
                          void deleteSubOrganization({
                            uuid: subOrganization.uuid,
                          });
                        },
                      },
                    ]}
                  />
                </div>
                <NextPageButton {...utils} />
              </>
            )}
          </>
        )}
      </PaginatedQuery>
    </Background>
  );
};
