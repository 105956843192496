import { useEffect, useState } from "react";

export const Delayed = ({
  children,
  waitBeforeShow = 500,
}: {
  children: JSX.Element;
  waitBeforeShow?: number;
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timeout);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};
