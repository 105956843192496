import { ReactNode } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import { FileUploadFragment, UploadInput } from "generated/provider";
import { FilePurpose, useUploadFile } from "graphql-client/useUploadFile";
import { useField } from "hooks/useField";
import { usePreventUnload } from "hooks/usePreventUnload";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { localURL } from "utils/file";

import { CommonFormDropzoneProps, Dropzone } from "./Dropzone";
import { FilePreview } from "./FilePreview";

export type LocalUpload = {
  file: File;
  progress: number;
  state: "IDLE" | "UPLOADING" | "SUCCESS" | "FAILED";
  getInput: () => Promise<UploadInput | null>;
};
export type FormDropzoneValue = {
  purpose: FilePurpose;
  upload: FileUploadFragment | LocalUpload | undefined;
};

export const FormDropzone = ({
  name,
  disabled,
  className,
  placeholder,
  wrapperClassName,
  height,
  preventUnloadOnPending,
  isPreviewContained,
  ...props
}: CommonFormDropzoneProps & {
  placeholder?: ReactNode;
  wrapperClassName?: string;
  height?: number;
  isPreviewContained?: boolean;
  preventUnloadOnPending?: boolean;
}) => {
  const t = useTranslation();
  const [{ value, disabled: disabledField }, { error }, { setValue }] =
    useField<FormDropzoneValue>({ name, disabled });
  const uploadFile = useUploadFile();

  usePreventUnload(
    !!preventUnloadOnPending &&
      value.upload !== undefined &&
      "file" in value.upload,
  );

  const ref = useSyncRef(value); // To update value during upload, formik doesn't support callback setters
  const setUpload = (payload: Partial<LocalUpload>) => {
    const upload = getLocalUpload(ref.current.upload);
    if (!upload) return;
    setValue({ ...ref.current, upload: { ...upload, ...payload } });
  };

  return (
    <Dropzone
      multiple={false}
      name={name}
      error={error}
      disabled={disabledField}
      className={wrapperClassName}
      {...props}
      onDrop={(files) => {
        setValue({
          purpose: value.purpose,
          upload: {
            file: files[0],
            progress: 0,
            state: "IDLE",
            getInput: async () => {
              setUpload({ state: "UPLOADING" });
              const uploadInput = await uploadFile({
                purpose: value.purpose,
                file: files[0],
                onProgress: (progress) => setUpload({ progress }),
              });
              if (uploadInput) {
                setValue({
                  purpose: value.purpose,
                  upload: {
                    file: files[0],
                    progress: 0,
                    state: "SUCCESS",
                    getInput: () => Promise.resolve(uploadInput),
                  },
                });
              } else {
                setUpload({ state: "FAILED" });
              }
              return uploadInput;
            },
          },
        });
      }}
    >
      {(isDragActive) => {
        const file = value.upload?.let((it) =>
          "state" in it
            ? {
                name: it.file.name,
                type: it.file.type,
                url: localURL(it.file),
              }
            : { name: it.fileName, type: it.mimeType, url: it.urlV2.url },
        );
        const state = getLocalUpload(value.upload)?.state ?? "IDLE";
        const clear = () => {
          if (disabledField) return;
          setValue({ purpose: value.purpose, upload: undefined });
        };

        return (
          <div
            className={classNames("dropbox py-10", className)}
            style={{ height }}
          >
            {file ? (
              <div className="w-full h-full flex">
                <FilePreview isContained={isPreviewContained} {...file} />
                <div
                  className="self-center flex justify-end"
                  style={{ width: "50px" }}
                >
                  {
                    {
                      IDLE: !disabled && (
                        <ClickableIcon
                          name="close"
                          onClick={(e) => {
                            clear();
                            e.stopPropagation();
                          }}
                        />
                      ),
                      UPLOADING: <Spinner />,
                      FAILED: <Icon name="close" className="text-danger" />,
                      SUCCESS: <Icon name="check" className="text-success" />,
                    }[state]
                  }
                </div>
              </div>
            ) : isDragActive ? (
              t("form.dropzone.form_dropzone.drop_here")
            ) : (
              placeholder ?? t("form.dropzone.form_dropzone.select_a_file")
            )}
          </div>
        );
      }}
    </Dropzone>
  );
};

const getLocalUpload = (value: FormDropzoneValue["upload"]) =>
  !value || "uuid" in value ? undefined : value;
