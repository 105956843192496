import Autolinker from "autolinker";
import { Link } from "react-router-dom";

import { HighlightText } from "components/HighlightTrext/HighlightText";
import { routes } from "routes";
import { defaultOrigin } from "utils/environment";

export const TextWithLinks = ({
  text,
  className,
  urlClassName = "link",
  search,
}: {
  text: string;
  className?: string;
  urlClassName?: string;
  search?: string;
}) => {
  const chunks: (string | JSX.Element)[] = [];
  chunks.push(...urlify(text.slice(), urlClassName, search));
  return <div className={className}>{chunks}</div>;
};

const urlify = (
  text: string,
  className: string,
  search?: string,
): (string | JSX.Element)[] => {
  const matches = Autolinker.parse(text, {});
  if (matches.isEmpty()) {
    return [<HighlightText key="0-text" text={text} search={search} />];
  }
  const chunks: (string | JSX.Element)[] = [];
  let pivot = 0;
  matches.forEach((match, index) => {
    chunks.push(
      <HighlightText
        key={`${index}-text`}
        text={text.slice(pivot, match.getOffset())}
        search={search}
      />,
    );

    const href = match.getAnchorHref();
    const storyUuid = href.match(/link.nabla.com\/post\/(.*)/u)?.[1];

    chunks.push(
      href.startsWith(defaultOrigin) ? (
        <Link
          key={`${index}-link`}
          to={href.slice(defaultOrigin.length)}
          className={className}
        >
          {match.getMatchedText()}
        </Link>
      ) : storyUuid ? (
        <Link
          key={`${index}-story-link`}
          className={className}
          to={`${routes.STORIES}/${storyUuid}`}
        >
          {match.getMatchedText()}
        </Link>
      ) : (
        <a key={`${index}-link`} href={href} className={className}>
          {match.getMatchedText()}
        </a>
      ),
    );
    pivot = match.getOffset() + match.getMatchedText().length;
  });
  chunks.push(
    <HighlightText key="end-text" text={text.slice(pivot)} search={search} />,
  );

  return chunks;
};
