import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { DoctorRow } from "components/Doctor/DoctorRow";
import { DoctorRowOption } from "components/Form/Select/DoctorRowOption";
import { FormMultiSelect } from "components/Form/Select/FormMultiSelect";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { FormModal } from "components/Modal/FormModal";
import { Query } from "components/Query/Query";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import {
  AddDoctorToExperience,
  AllDoctors,
  AllDoctorsData,
  RemoveDoctorFromExperience,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { doctorsToChatWithFilter } from "utils/apollo";
import { displayUser } from "utils/display";

import { useLoadedDoctorExperience } from "../useLoadedDoctorExperience";

gql`
  query AllDoctors($filter: DoctorSearchFilter!) {
    doctorSearch(filter: $filter) {
      doctors(pagination: { numberOfItems: -1 }) {
        ...DoctorSummary
      }
    }
  }

  mutation AddDoctorToExperience($experienceUuid: UUID!, $doctorUuid: UUID!) {
    addDoctorToExperience(
      doctorUuid: $doctorUuid
      experienceUuid: $experienceUuid
    ) {
      experience {
        uuid
        allDoctors {
          ...DoctorSummary
        }
      }
    }
  }

  mutation RemoveDoctorFromExperience(
    $doctorUuid: UUID!
    $experienceUuid: UUID!
  ) {
    removeDoctorFromExperience(
      doctorUuid: $doctorUuid
      experienceUuid: $experienceUuid
    ) {
      experience {
        uuid
        allDoctors {
          ...DoctorSummary
        }
      }
    }
  }
`;

export const ManageDoctors = () => {
  const t = useTranslation();
  const { experience } = useLoadedDoctorExperience();
  const [showModal, setShowModal] = useState(false);
  const [doctorsToRemoveUuids, setDoctorsToRemoveUuids] = useState<UUID[]>([]);
  const [addDoctor] = useMutation(AddDoctorToExperience);
  const [removeDoctor] = useMutation(RemoveDoctorFromExperience);

  const title = t("manage_doctors.add_remove_practitioners");
  const canRemove = experience.allDoctors.length > 2;

  return (
    <>
      <button
        className="mt-4 py-8 flex items-center"
        onClick={() => setShowModal(true)}
      >
        <Icon name="people" />
        <div className="ml-8 text-14 font-medium">{title}</div>
      </button>
      {showModal && (
        <FormModal<{ addDoctors: AllDoctorsData["doctors"] }>
          title={title}
          onHide={() => setShowModal(false)}
          initialValues={{ addDoctors: [] }}
          submitLabel={t("common.save_modifications")}
          onSubmit={({ addDoctors }) =>
            Promise.all([
              ...addDoctors.map(
                (d): Promise<unknown> =>
                  addDoctor({
                    doctorUuid: d.uuid,
                    experienceUuid: experience.uuid,
                  }),
              ),
              ...doctorsToRemoveUuids.map((doctorUuid) =>
                removeDoctor({ doctorUuid, experienceUuid: experience.uuid }),
              ),
            ]).then(() => setShowModal(false))
          }
        >
          <Query
            query={AllDoctors}
            variables={{ filter: doctorsToChatWithFilter }}
            noSpinner
          >
            {({ data, loading }) => (
              <FormMultiSelect
                name="addDoctors"
                label={t(
                  "doctors_conversation.information_panel.manage_doctors.add_practitioners",
                )}
                loading={loading}
                options={
                  data?.doctors.filter(
                    (doctor) =>
                      !experience.allDoctors.some(
                        (d) => d.uuid === doctor.uuid,
                      ),
                  ) ?? []
                }
                getOptionLabel={displayUser}
                CustomOption={DoctorRowOption}
              />
            )}
          </Query>
          <div className="flex-col">
            {experience.allDoctors.map((doctor) => (
              <div
                key={doctor.uuid}
                className="flex items-center border-b last:border-b-0 py-10"
                style={{ minWidth: "200px" }}
              >
                <DoctorRow doctor={doctor} />
                <TooltipWrapper
                  className="ml-auto"
                  tooltipStyle={{ width: "150px" }}
                  label={
                    canRemove
                      ? t(
                          "doctors_conversation.information_panel.manage_doctors.remove_the_practitioner",
                        )
                      : t(
                          "manage_doctors.you_cannot_remove_a_practitioner_from_a_twoperson_conversation",
                        )
                  }
                >
                  <ClickableIcon
                    className={classNames({
                      "text-primary": !doctorsToRemoveUuids.includes(
                        doctor.uuid,
                      ),
                    })}
                    name={
                      doctorsToRemoveUuids.includes(doctor.uuid)
                        ? "radioOff"
                        : "checkCircle"
                    }
                    size={14}
                    onClick={() =>
                      setDoctorsToRemoveUuids(
                        doctorsToRemoveUuids.toggle(doctor.uuid),
                      )
                    }
                    disabled={!canRemove}
                  />
                </TooltipWrapper>
              </div>
            ))}
          </div>
        </FormModal>
      )}
    </>
  );
};
