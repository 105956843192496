import { getTimeZones, TimeZone } from "@vvo/tzdb";
import { DateTime } from "luxon";

import { staticT as t } from "i18n";

export const findTimeZone = (name: string) =>
  getTimeZones().find(
    (timeZone) => name === timeZone.name || timeZone.group.includes(name),
  );

export const displayTimeZone = (timezone: TimeZone) => {
  const cities = timezone.mainCities.join(", ");

  const nowInTimezone = DateTime.now().setZone(timezone.name);

  const currentTimeInTimezone = nowInTimezone.toFormat("HH:mm");
  const currentDateInTimezone = nowInTimezone.toFormat(
    t("timezone_picker.timezone_picker.mmdd"),
  );
  return `${currentTimeInTimezone} (${currentDateInTimezone}) ${timezone.name} - ${cities}`;
};
