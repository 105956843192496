import gql from "graphql-tag";

import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { FormModal } from "components/Modal/FormModal";
import {
  CreateNoteSectionNormalization,
  NoteSectionNormalizationsVariables,
  SupportedLocale,
  SupportedLocaleKnownValue,
  SupportedLocaleKnownValues,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { getCurrentLanguage, useTranslation } from "i18n";
import { addCreatedNoteNormalizationInCache } from "utils/apollo";
import { displayLocale } from "utils/display";
import { getLocale } from "utils/intl";
import { notifier } from "utils/notifier";

import { SectionType, sectionTypes, toTranslationKeyName } from "./utils";

gql`
  mutation CreateNoteSectionNormalization(
    $input: CreateNoteSectionNormalizationInput!
  ) {
    createNoteSectionNormalization(normalizationInput: $input) {
      noteSectionNormalization {
        ...NoteSectionNormalization
      }
    }
  }
`;

type FormValues = {
  type: SectionType;
  content: string;
  locale: SupportedLocale;
};

export const NewNoteNormalizationComposer = ({
  onHide,
  queryVariables,
}: {
  onHide: () => void;
  queryVariables: NoteSectionNormalizationsVariables;
}) => {
  const t = useTranslation();
  const currentLocale = getLocale(getCurrentLanguage());

  const [createNoteSectionNormalization] = useMutation(
    CreateNoteSectionNormalization,
    {
      onSuccess: (result, client) => {
        addCreatedNoteNormalizationInCache(
          client,
          result.noteSectionNormalization,
          queryVariables,
        );
        notifier.success(
          t("add_note_normalization_composer.note_normalization_created"),
        );
      },
    },
  );

  return (
    <FormModal<FormValues>
      title={t("add_note_normalization_composer.add_note_normalization")}
      submitLabel={t("developers.server_keys.api_keys.add_short")}
      onHide={onHide}
      className="flex-col w-full"
      initialValues={{
        type: "medicalHistory",
        content: "",
        locale: currentLocale,
      }}
      validationSchema={{
        type: "requiredValue",
        content: "required",
        locale: "requiredValue",
      }}
      onSubmit={(values) => {
        createNoteSectionNormalization({
          input: {
            type: values.type,
            content: values.content,
            locale: values.locale,
          },
        });
        onHide();
      }}
    >
      <div className="flex items-center space-x-6">
        <div className="flex items-center space-x-6 w-full">
          <FormSelect<SectionType>
            name="type"
            label={t("add_note_normalization_composer.type_of_standardization")}
            placeholder={t(
              "add_note_normalization_composer.type_of_standardization",
            )}
            wrapperClassName="flex-fill"
            options={sectionTypes}
            getOptionLabel={(label) => toTranslationKeyName(t, label)}
          />
          <FormSelect<SupportedLocaleKnownValue>
            name="locale"
            label={t("language")}
            placeholder={t("language")}
            wrapperClassName="flex-fill"
            options={SupportedLocaleKnownValues}
            getOptionLabel={displayLocale}
          />
        </div>
      </div>
      <FormTextArea
        name="content"
        label={t("note_normalization.section_content")}
        placeholder={t("note_normalization.section_content")}
        wrapperClassName="flex-fill"
        minRows={5}
        maxRows={10}
      />
    </FormModal>
  );
};
