import gql from "graphql-tag";
import * as Yup from "yup";

import { FormInput } from "components/Form/Input/FormInput";
import { FormModal } from "components/Modal/FormModal";
import { Query } from "components/Query/Query";
import {
  DoctorManagementDoctorFragment,
  GetAperoDoctor,
  UpdateAperoDoctor,
  UpdateDoctor,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  query GetAperoDoctor($uuid: UUID!) {
    doctor(uuid: $uuid) {
      doctor {
        uuid
        aperoDoctor {
          id
          npi
          taxonomyCode
        }
      }
    }
  }
  mutation UpdateAperoDoctor($uuid: UUID!, $payload: UpdateAperoDoctorInput!) {
    updateAperoDoctor(doctorUuid: $uuid, payload: $payload) {
      doctor {
        uuid
        aperoDoctor {
          id
          npi
          taxonomyCode
        }
      }
    }
  }
`;

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  npi: string;
  taxonomyCode: string;
};

export const ProviderAperoForm = ({
  provider,
  onHide,
}: {
  provider: DoctorManagementDoctorFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();
  const [updateDoctor] = useMutation(UpdateDoctor);
  const [updateAperoDoctor] = useMutation(UpdateAperoDoctor);

  return (
    <Query query={GetAperoDoctor} variables={{ uuid: provider.uuid }}>
      {(aperoDoctorData) => (
        <FormModal<FormValues>
          title={t("provider_apero_form.apero_details")}
          initialValues={{
            firstName: provider.firstName,
            lastName: provider.lastName,
            phone: provider.phone ?? "",
            npi: aperoDoctorData.doctor.aperoDoctor?.npi ?? "",
            taxonomyCode:
              aperoDoctorData.doctor.aperoDoctor?.taxonomyCode ?? "",
          }}
          validationSchema={{
            firstName: "required",
            lastName: "required",
            npi: Yup.string().required().length(10),
            taxonomyCode: "required",
          }}
          onSubmit={(values) =>
            updateDoctor({
              doctorUuid: provider.uuid,
              profilePayload: {
                firstName: values.firstName,
                lastName: values.lastName,
              },
              adminPayload: { phone: values.phone.trimOrNull() },
            }).then(() =>
              updateAperoDoctor(
                {
                  uuid: provider.uuid,
                  payload: {
                    npi: values.npi,
                    taxonomyCode: values.taxonomyCode,
                  },
                },
                {
                  onSuccess: () => {
                    notifier.success(
                      t("provider_apero_form.apero_details_updated"),
                    );
                    onHide();
                  },
                },
              ),
            )
          }
          onHide={onHide}
          submitLabel={t("provider_apero_form.submit")}
        >
          <div className="flex items-center space-x-6">
            <FormInput
              name="firstName"
              label={t("provider_apero_form.first_name")}
              wrapperClassName="flex-fill"
            />
            <FormInput
              name="lastName"
              label={t("provider_apero_form.last_name")}
              wrapperClassName="flex-fill"
            />
          </div>
          <div className="flex items-center space-x-6">
            <FormInput
              name="phone"
              label={t("provider_apero_form.phone")}
              placeholder={t("provider_apero_form.phone")}
              wrapperClassName="flex-fill"
            />
          </div>
          <div className="flex items-center space-x-6">
            <FormInput
              name="npi"
              label={t("provider_apero_form.npi")}
              wrapperClassName="flex-fill"
            />
            <FormInput
              name="taxonomyCode"
              label={t("provider_apero_form.taxonomy_code")}
              wrapperClassName="flex-fill"
            />
          </div>
        </FormModal>
      )}
    </Query>
  );
};
