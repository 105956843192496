import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { useTranslation } from "i18n";
import { routes } from "routes";

export const GoogleCalendarModal = ({ onHide }: { onHide: () => void }) => {
  const t = useTranslation();
  return (
    <Modal title={t("google_calendar_sync_modal.title")} onHide={onHide}>
      <div className="flex-col">
        <div>{t("google_calendar_sync_modal.description")}</div>
        <Button
          className="mt-24"
          label={t("google_calendar_sync_modal.continue")}
          to={`${routes.PREFERENCES}/${routes.GOOGLE_CALENDAR_SYNC}`}
        />
      </div>
    </Modal>
  );
};
