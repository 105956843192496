import {
  ButtonHTMLAttributes,
  lazy,
  memo,
  ReactNode,
  Suspense,
  useState,
} from "react";

import { Audio } from "components/Audio/Audio";
import { EventMessage } from "components/ConversationContent/Timeline/MessageContainer/ActionRequestMessage";
import { Icon } from "components/Icon/Icon";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useTranslation } from "i18n";
import { FileMessageInput } from "types";
import {
  getFileNameFromUpload,
  getFileTypeFromUpload,
  getMimeTypeFromUpload,
  getUploadSrc,
} from "utils/file";

const PDFViewer = lazy(() => import("components/PDFViewer/PDFViewer"));

export const Gallery = ({
  onClickQuestionsSet,
}: {
  onClickQuestionsSet?: () => void;
}) => {
  const t = useTranslation();
  const {
    mediaMessages,
    setMediaMessages,
    livekitRoomInput,
    setLivekitRoomMessageInput,
    questionsSet,
    setQuestionsSet,
  } = useExperienceDraft();

  if (mediaMessages.isEmpty() && !livekitRoomInput && questionsSet.isEmpty()) {
    return null;
  }

  return (
    <div
      className="flex items-end overflow-auto p-8 space-x-8"
      style={{ overscrollBehavior: "contain" }}
    >
      {mediaMessages.map((media, index) => (
        <Suspense key={index}>
          <GalleryItem
            onClose={(e) => {
              setMediaMessages(mediaMessages.filter((_, i) => i !== index));
              e.stopPropagation(); // Do not trigger dropzone
            }}
          >
            <MediaPreview media={media} />
          </GalleryItem>
        </Suspense>
      ))}
      {livekitRoomInput && (
        <GalleryItem onClose={() => setLivekitRoomMessageInput(false)}>
          <div className="-mt-4">
            <EventMessage
              label={t("composer_parts.gallery.video_consultation")}
              icon="videoOn"
            />
          </div>
        </GalleryItem>
      )}
      {questionsSet.isNotEmpty() && (
        <GalleryItem onClose={() => setQuestionsSet([])}>
          <div className="-mt-4">
            <EventMessage
              label={t("inboxes.qa_experience.questions_set.item.label")}
              icon="questionnaire"
              onClick={onClickQuestionsSet}
            />
          </div>
        </GalleryItem>
      )}
    </div>
  );
};

export const GalleryItem = ({
  onClose,
  children,
}: {
  onClose: ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
  children: ReactNode;
}) => (
  <div className="relative bg-white rounded shadow-light overflow-hidden">
    <button
      className="absolute inset-tr-4 z-1 rounded-full bg-body"
      onClick={onClose}
    >
      <Icon name="close" color="white" className="m-6" size={8} />
    </button>
    {children}
  </div>
);

const MediaPreview = memo(({ media }: { media: FileMessageInput }) => {
  const t = useTranslation();
  const [previewHasFailed, setPreviewHasFailed] = useState(false);

  const mediaType = getFileTypeFromUpload(media);

  switch (mediaType) {
    case "IMAGE":
    case "VIDEO":
      const Tag = mediaType === "IMAGE" ? "img" : "video";
      return previewHasFailed ? (
        <div>{t("composer_parts.gallery.preview_has_failed")}</div>
      ) : (
        <Tag
          style={{ height: 100, minWidth: 40, maxWidth: 400 }}
          src={getUploadSrc(media)}
          className="object-cover"
          onError={() => setPreviewHasFailed(true)}
        />
      );
    case "FILE":
      if (getMimeTypeFromUpload(media) === "application/pdf") {
        return (
          <div style={{ height: 100 }}>
            <PDFViewer
              src={getUploadSrc(media)}
              pdfPageClass="h-44"
              className="h-full w-full"
              isThumbnail
            />
          </div>
        );
      }
      return (
        <div className="flex-center p-16">
          <Icon name="filePlus" className="mr-16" />
          {getFileNameFromUpload(media)}
        </div>
      );
    case "AUDIO":
      return <Audio src={getUploadSrc(media)} />;
  }
});
