import gql from "graphql-tag";

import { Modal } from "components/Modal/Modal";
import {
  FileUploadFragment,
  TemplateFragment,
  UpdateTemplate,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { Maybe } from "../../base-types";
import { MultimediaTemplateForm } from "./MultimediaTemplateForm";
import { TemplateForm } from "./TemplateForm";

gql`
  mutation UpdateTemplate($uuid: UUID!, $input: UpdateTemplateInput!) {
    updateTemplate(uuid: $uuid, updateTemplateInput: $input) {
      template {
        ...Template
      }
    }
  }
`;

export type EditTemplateType = {
  uuid: UUID;
  title: string;
  content: Maybe<string>;
  fileUpload: Maybe<FileUploadFragment>;
};

export const EditTemplateModal = ({
  template,
  onHide,
}: {
  template: EditTemplateType;
  onHide: (newTemplate: TemplateFragment | null) => void;
}) => {
  const t = useTranslation();

  const [updateMultimediaTemplate] = useMutation(UpdateTemplate, {
    onSuccess: (output) => {
      notifier.success(
        t("template_forms.edit_template_modal.template_updated"),
      );
      onHide(output.template);
    },
  });

  return (
    <Modal
      title={t("template_forms.edit_template_modal.edit_a_template")}
      onHide={() => onHide(null)}
      alignTopOnMobile
    >
      {template.content && (
        <TemplateForm
          initialValues={{ title: template.title, content: template.content }}
          onSubmit={async ({ content, title }) => {
            if (content) {
              await updateMultimediaTemplate({
                uuid: template.uuid,
                input: {
                  title,
                  textWithMentions: { text: content, mentions: [] },
                },
              });
            }
          }}
        />
      )}
      {template.fileUpload && (
        <MultimediaTemplateForm
          initialValues={{
            title: template.title,
            file: { upload: template.fileUpload, purpose: "MESSAGE" },
          }}
          onSubmit={async ({ file, title }) => {
            if (file.upload) {
              if ("uuid" in file.upload) {
                await updateMultimediaTemplate({
                  uuid: template.uuid,
                  input: {
                    title,
                    fileUuid: file.upload.uuid,
                  },
                });
              } else {
                const uploadInput = await file.upload.getInput();
                if (!uploadInput) return;
                await updateMultimediaTemplate({
                  uuid: template.uuid,
                  input: {
                    title,
                    fileUuid: uploadInput.uuid,
                  },
                });
              }
            }
          }}
        />
      )}
    </Modal>
  );
};
