import {
  createContext,
  CSSProperties,
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
} from "react";
import classNames from "classnames";
import {
  DraftEditorCommand,
  DraftHandleValue,
  Editor as DraftJSEditor,
  EditorProps as DraftJSEditorProps,
  EditorState,
  getDefaultKeyBinding,
} from "draft-js";
import "draft-js/dist/Draft.css";

import { NamedLabelProps } from "components/Form/Label/Label";
import { LabelWrapper } from "components/Form/Label/LabelWrapper";

import { insertText } from "./utils";
import "./editor.css";
import styles from "./editor.module.css";

const EditorContext = createContext<EditorState>(EditorState.createEmpty());

export type EditorProps = NamedLabelProps & {
  wrapperClassName?: string;
  // Container props
  containerRef?: RefObject<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;

  // Missing DraftJS prop
  autoFocus?: boolean;
  disableHtmlPaste?: boolean;

  // DraftJS renamed/retyped props
  disabled?: boolean;
  onKeyStroke?: (e: KeyboardEvent<any>) => string | null | undefined;
  onCommand?: (
    command: DraftEditorCommand,
    editorState: EditorState,
  ) => DraftHandleValue;
} & Pick<
    DraftJSEditorProps,
    | "placeholder"
    | "editorState"
    | "onChange"
    | "handleBeforeInput"
    | "onBlur"
    | "onFocus"
    | "blockStyleFn"
  >;

export const Editor = ({
  wrapperClassName,
  name,
  label,
  hint,
  error,

  containerRef,
  className,
  style,
  children,

  autoFocus,
  disableHtmlPaste,

  disabled,
  onKeyStroke,
  onCommand,
  editorState,
  onChange,
  ...otherDraftJSProps
}: EditorProps) => {
  const editor = useRef<DraftJSEditor>(null);

  useEffect(() => {
    if (autoFocus) onChange(EditorState.moveFocusToEnd(editorState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  return (
    <LabelWrapper
      name={name}
      label={label}
      error={error}
      wrapperClassName={wrapperClassName}
      hint={hint}
    >
      <div
        ref={containerRef}
        className={classNames(styles.editor, className, {
          [styles.disabled]: disabled,
        })}
        style={style}
      >
        {children}
        <EditorContext.Provider value={editorState}>
          <DraftJSEditor
            ref={editor}
            editorState={editorState}
            onChange={onChange}
            webDriverTestID={name}
            readOnly={disabled}
            handleKeyCommand={onCommand}
            keyBindingFn={(e) => {
              const result = onKeyStroke?.(e);
              if (result === undefined) return getDefaultKeyBinding(e);
              if (result === null) e.preventDefault();
              return result;
            }}
            handlePastedText={
              disableHtmlPaste
                ? (text, _html, state) => {
                    onChange(insertText(state, text));
                    return "handled";
                  }
                : undefined
            }
            {...otherDraftJSProps}
          />
        </EditorContext.Provider>
      </div>
    </LabelWrapper>
  );
};
