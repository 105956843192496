import { CSSProperties, useEffect, useLayoutEffect, useState } from "react";
import classNames from "classnames";

import { useMediaWindows } from "atoms/useMediaWindows";
import { Delayed } from "components/Delayed/Delayed";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Spinner } from "components/Spinner/Spinner";
import { useMaybeExperience } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import { AppEventsSubscription } from "generated/provider";
import { useSubscription } from "graphql-client/useSubscription";
import { useOnUnmount, usePrevious } from "hooks";

import { Timeline } from "./Timeline/Timeline";

export const ConversationContent = ({
  wrapperStyle,
  scrollClassName,
  onAvatarClick,
  search,
  compact,
  onCreatePatientTimelineItem,
}: {
  wrapperStyle?: CSSProperties;
  scrollClassName?: string;
  onAvatarClick?: (uuid: UUID) => void;
  search?: string;
  compact?: boolean;
  onCreatePatientTimelineItem?: (uuid: UUID) => void;
}) => {
  const {
    uuid,
    items,
    loadingMoreItems,
    loadMoreItemsIfPossible,
    messagesContainerRef,
    getScrollState,
    instantScroll,
  } = useMaybeExperience();
  const { user } = useDoctor();

  useSubscription(AppEventsSubscription, {
    onData: ({ event }) => {
      if (
        event.__typename === "NewTimelineItemEvent" &&
        event.item.__typename === "Message" &&
        event.item.sender?.__typename === "Doctor" &&
        event.item.sender.uuid === user.uuid
      ) {
        // Sometimes messages are sent asynchronously by the server (when creating a Prescription for example) so we
        // cannot scroll just after the mutation success and need to listen to this event.
        instantScroll();
      }
    },
  });

  const [goToBottom, setGoToBottom] = useState(false);
  useOnUnmount(useMediaWindows().closeMedia);

  const onScroll = () => {
    const scrollState = getScrollState();
    if (!scrollState) return;
    setGoToBottom(scrollState.distanceFromBottom > 300);
    if (scrollState.distanceFromTop === 0) loadMoreItemsIfPossible();
  };

  useEffect(() => {
    instantScroll(); // Scroll down when arriving on an experience
  }, [instantScroll]);

  useLayoutEffect(() => {
    if (
      messagesContainerRef.current &&
      messagesContainerRef.current.scrollHeight <=
        messagesContainerRef.current.clientHeight
    ) {
      loadMoreItemsIfPossible();
    }
  }, [messagesContainerRef, loadMoreItemsIfPossible]);

  const [distanceFromTop, setDistanceFromTop] = useState(0);
  // Compute the distance from header when we scroll to apply the same distance
  // when new items are inserted
  useEffect(() => {
    if (messagesContainerRef.current === null) return;

    const handleScroll = () => {
      if (!items || items.length < 2) return;

      // We use the second item to realign the scroll because it will not change
      // whereas the first item could have some elements modified/removed
      const itemDiv = document.querySelector(`#timeline-${items[1].uuid}`);
      let top = itemDiv?.getBoundingClientRect()?.top;
      const loader = document.querySelector('span[role="status"]');
      if (top && loader) {
        // Ignore loader since it will be removed when we will add the distance
        const MARGIN_TOP = 16;
        top = top - loader.scrollHeight - MARGIN_TOP;
      }
      if (top !== undefined) setDistanceFromTop(top);
    };

    const current = messagesContainerRef.current;
    current.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => current.removeEventListener("scroll", handleScroll);
  }, [messagesContainerRef, items]);

  const previousItems = usePrevious(items);
  useLayoutEffect(() => {
    if (
      !items ||
      !messagesContainerRef.current ||
      !previousItems ||
      previousItems.length < 2 ||
      !items.length
    ) {
      return;
    }
    const itemDiv = document.querySelector(
      `#timeline-${previousItems[1].uuid}`,
    );
    if (!itemDiv) return; // experience changed

    if (previousItems[0].uuid !== items[0].uuid) {
      // Old items loaded
      const totalDistanceFromTop = itemDiv.getBoundingClientRect().top;
      // Check we did not scroll down during loading
      if (messagesContainerRef.current.scrollTop === 0) {
        instantScroll(totalDistanceFromTop - distanceFromTop);
      }
    }
  }, [
    instantScroll,
    items,
    messagesContainerRef,
    previousItems,
    distanceFromTop,
  ]);

  return (
    <div
      className="w-full relative flex-fill flex-col overflow-hidden"
      style={wrapperStyle}
    >
      <div
        ref={messagesContainerRef}
        className={classNames(
          "flex-fill flex-col overflow-auto",
          scrollClassName,
        )}
        onScroll={onScroll}
      >
        {loadingMoreItems && (
          // Don't display loader for fast connection as it will bump the messages twice in a very short period
          <Delayed waitBeforeShow={200}>
            <Spinner inline className="mx-auto mt-16 transition duration-300" />
          </Delayed>
        )}
        {items ? (
          // Existing and loaded experience.
          <Timeline
            search={search}
            onAvatarClick={onAvatarClick}
            compact
            onCreatePatientTimelineItem={onCreatePatientTimelineItem}
          />
        ) : uuid ? (
          // Existing loading experience.
          <Spinner />
        ) : null}
      </div>
      {goToBottom && (
        <ClickableIcon
          name="chevron"
          className={classNames(
            "absolute rounded-full bg-white border inset-br-24 shadow-xs",
            compact ? "h-36 w-36" : "h-44 w-44",
          )}
          onClick={() => instantScroll()}
          rotate={90}
        />
      )}
    </div>
  );
};
