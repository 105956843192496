import { useState } from "react";
import axios from "axios";

import { downloadFile } from "utils/file";

export const useDownload = () => {
  const [downloading, setDownloading] = useState(false);

  return [
    (url: string, fileName: string) => {
      setDownloading(true);
      axios
        .get<Blob>(url, { responseType: "blob" })
        .then(({ data }) => downloadFile(data, fileName))
        .finally(() => setDownloading(false));
    },
    downloading,
  ] as const;
};
