import { useRef, useState } from "react";
import classNames from "classnames";

import { useMediaQuery } from "hooks/useMediaQuery";

import { BottomActionSheet } from "./BottomActionSheet";
import { MenuItemProps } from "./MenuItem";
import { MoreMenu } from "./MoreMenu";

export const AdaptativeMenu = ({
  hide,
  items,
  children,
  className,
}: {
  items: MenuItemProps[];
  children: (menu: JSX.Element | null) => JSX.Element;
  hide?: boolean;
  className?: string;
}) => {
  const useBottomActionSheet =
    // It seems that hover is disabled when puppeteer runs on CI
    useMediaQuery("(any-hover: none)") && !window.E2E;
  const [actionSheetOpened, setActionSheetOpened] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  return (
    <>
      <div
        className={classNames("flex items-center group", className)}
        onTouchStart={() => {
          if (items.isEmpty() || !useBottomActionSheet || hide) return;
          timeoutRef.current = setTimeout(() => {
            setActionSheetOpened(true);
          }, 200);
        }}
        onTouchMove={() => {
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
        }}
        onTouchEnd={() => {
          if (!timeoutRef.current) return;
          clearTimeout(timeoutRef.current);
        }}
      >
        {children(
          useBottomActionSheet ? null : (
            <MoreMenu
              items={items}
              className={classNames(
                "ml-8 invisible",
                !hide && "group-hover:visible",
              )}
            />
          ),
        )}
      </div>
      {!hide && useBottomActionSheet && actionSheetOpened && (
        <BottomActionSheet
          items={items}
          close={() => setActionSheetOpened(false)}
        />
      )}
    </>
  );
};
