import { TranscriptItem } from "./useLocalSpeechToText";

export const TranscriptItems = ({ items }: { items: TranscriptItem[] }) => {
  const firstItem = items.at(0);
  if (!firstItem) return null;

  const mergedItemsWithSameSpeaker: TranscriptItem[] = [];
  let currentSpeaker = firstItem.speaker;
  let currentTextParts: string[] = [firstItem.text];
  items.slice(1).forEach((it) => {
    if (it.speaker !== currentSpeaker) {
      mergedItemsWithSameSpeaker.push({
        text: currentTextParts.join(""),
        speaker: currentSpeaker,
      });
      currentTextParts = [];
      currentSpeaker = it.speaker;
    }
    currentTextParts.push(it.text);
  });
  mergedItemsWithSameSpeaker.push({
    text: currentTextParts.join(""),
    speaker: currentSpeaker,
  });

  return (
    <div className="flex-col space-y-4">
      {mergedItemsWithSameSpeaker.map((it, index) => (
        <div key={index}>
          <span className="font-medium mr-8">{it.speaker}</span>
          {it.text}
        </div>
      ))}
    </div>
  );
};
