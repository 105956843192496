import { PushNotifications } from "@capacitor/push-notifications";
import { PushNotificationSchema } from "@capacitor/push-notifications/dist/esm/definitions";

import { promiseWithResolverAndRejecter } from "utils";

const [promise, resolve] = promiseWithResolverAndRejecter<void>();

export const iosNotificationsRegistrationSuccess = () => resolve();

export const getDeliveredNotifications = () =>
  promise.then(() => PushNotifications.getDeliveredNotifications());

export const removeDeliveredNotifications = (
  predicate: (notification: PushNotificationSchema) => boolean,
) =>
  getDeliveredNotifications().then(({ notifications }) =>
    PushNotifications.removeDeliveredNotifications({
      notifications: notifications.filter(predicate),
    }),
  );
