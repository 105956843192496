import { useParams } from "react-router";

import { Maybe } from "base-types";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Query } from "components/Query/Query";
import {
  DoctorSummaryFragment,
  RecordedConversationWithAnnotatedNote,
} from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { displayDoctor } from "utils/display";

export const NoteAnnotationsComparison = () => {
  const { recordedConversationUuid } = useParams();

  const t = useTranslation();

  return (
    <div className="bg-light-blue flex items-start justify-between flex-fill overflow-auto">
      <div className="flex-col flex-fill p-20 space-y-20">
        <ClickableIcon
          name="arrow"
          to={`/${routes.NOTE_ANNOTATION}`}
          className="mr-auto"
        />
        <Query
          query={RecordedConversationWithAnnotatedNote}
          variables={{ uuid: recordedConversationUuid }}
        >
          {(data) => {
            const notes = data.recordedConversation.annotatedNotes;

            return notes.isEmpty() ? (
              <div>No annotated notes for this transcript</div>
            ) : (
              <div className="rounded bg-white p-10 border flex items-start flex-fill">
                <div className="flex-col flex-fill space-y-16 w-full">
                  <NoteSection
                    label={t("note_annotation.chief_complaint")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.chiefComplaint,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.symptoms")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.symptoms,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.history_illness")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.historyIllness,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.medical_history")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.medicalHistory,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.social_history")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.socialHistory,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.family_history")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.familyHistory,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.surgical_history")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.surgicalHistory,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.medications")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.medications,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.allergies")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.allergies,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.vitals")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.vitals,
                    }))}
                  />
                </div>
                <div className="flex-col flex-fill space-y-16 w-full">
                  <NoteSection
                    label={t("note_annotation.vaccines")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.vaccines,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.physical_exam")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.physicalExam,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.lab_results")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.labResults,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.imaging_results")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.imagingResults,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.assessment")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.assessment,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.plan")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.plan,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.prescription")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.prescription,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.appointments")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.appointments,
                    }))}
                  />
                  <NoteSection
                    label={t("note_annotation.diagnostic")}
                    values={notes.map((note) => ({
                      annotatingDoctor: note.annotatingDoctor,
                      value: note.diagnostic,
                    }))}
                  />
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    </div>
  );
};

const NoteSection = ({
  label,
  values,
}: {
  label: string;
  values: { annotatingDoctor: Maybe<DoctorSummaryFragment>; value: string }[];
}) => (
  <div className="p-10 w-full">
    <div className="text-primary-dark font-semibold">{label}</div>
    {values.map((value) => (
      <div
        className="flex ml-8 mb-8 space-x-8 whitespace-pre-line overflow-auto"
        key={value.value}
      >
        <div className="font-semibold">
          {displayDoctor(value.annotatingDoctor)}
        </div>
        <div>{value.value}</div>
      </div>
    ))}
  </div>
);
