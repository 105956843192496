import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import gql from "graphql-tag";

import { useLoadedExperience } from "contexts/Experience/ExperienceContext";
import { MarkExperienceAsSeen } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useWindowHasFocus } from "hooks";
import { withServiceWorkerRegistration } from "utils/firebase";
import { removeDeliveredNotifications } from "utils/ios-push-notifications";
import { getNewMessageNotification } from "utils/workers-utils";

gql`
  mutation MarkExperienceAsSeen($uuid: UUID!) {
    markExperienceAsSeen(experienceUuid: $uuid) {
      experience {
        uuid
        seenStatus
      }
    }
  }
`;

export const useMarkExperienceAsSeen = () => {
  const { experience } = useLoadedExperience();
  const [markExperienceAsSeen] = useMutation(MarkExperienceAsSeen, {
    variables: { uuid: experience.uuid },
    skipIfImpersonation: true,
    onError: () => null, // Don't notify user
  });

  const hasFocus = useWindowHasFocus();

  useEffect(() => {
    if (!hasFocus) return;
    markExperienceAsSeen().then(() => {
      if (Capacitor.isNativePlatform()) {
        removeDeliveredNotifications(
          (n) =>
            "type" in n.data &&
            n.data.type === "NEW_MESSAGE" &&
            "experience.uuid" in n.data &&
            n.data["experience.uuid"] === experience.uuid,
        );
      } else {
        withServiceWorkerRegistration((r) => {
          getNewMessageNotification(r, experience.uuid).then((it) => {
            it?.close();
          });
        });
      }
    });
  }, [markExperienceAsSeen, hasFocus, experience.seenStatus, experience.uuid]);
};
