import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { Icon } from "components/Icon/Icon";
import {
  DoctorSummaryFragment,
  PatientSummaryFragment,
} from "generated/provider";
import { useTranslation } from "i18n";
import { displayUser } from "utils/display";

const MAX_DISPLAYED_AVATAR = 8;

export type SeenTuple = {
  user: DoctorSummaryFragment | PatientSummaryFragment;
  until: ISOString;
};

export const ChatBubbles = ({ tuples }: { tuples: SeenTuple[] }) => {
  if (tuples.length === 1) {
    return (
      <GutterBubble>
        <Bubble tuple={tuples[0]} />
      </GutterBubble>
    );
  }

  const nbDisplayedAvatar =
    tuples.length > MAX_DISPLAYED_AVATAR
      ? MAX_DISPLAYED_AVATAR - 1
      : tuples.length;

  return (
    <GutterBubble>
      {tuples.length > MAX_DISPLAYED_AVATAR && (
        <div
          className="bg-white shadow-sm-outlined rounded-full px-6 flex-center text-10"
          style={{ lineHeight: "16px" }}
          title={tuples
            .slice(nbDisplayedAvatar)
            .map((t) => displayUser(t.user, "FIRST_NAME"))
            .join("\n")}
        >
          +{tuples.length - nbDisplayedAvatar}
        </div>
      )}
      {tuples.slice(0, nbDisplayedAvatar).map((tuple) => (
        <Bubble key={tuple.user.uuid} tuple={tuple} style={{ marginLeft: 2 }} />
      ))}
    </GutterBubble>
  );
};

const Bubble = ({
  tuple,
  style,
}: {
  tuple: SeenTuple;
  style?: CSSProperties;
}) => {
  const t = useTranslation();
  return (
    <Avatar
      user={tuple.user}
      size={16}
      style={style}
      title={`${t(
        "conversation_content.timeline.chat_bubbles.seen_by",
      )} ${displayUser(tuple.user, "FIRST_NAME")} ${t(
        "conversation_content.timeline.chat_bubbles.at",
      )} ${tuple.until.format("time")}`}
    />
  );
};

export const SentIndicator = ({ sent }: { sent: boolean }) => {
  const t = useTranslation();

  return (
    <GutterBubble>
      <div
        style={{ height: 12, width: 12, marginRight: 2 }}
        title={
          sent
            ? t("conversation_content.timeline.chat_bubbles.sent")
            : t(
                "conversation_content.timeline.chat_bubbles.sending_in_progress",
              )
        }
        className={classNames(
          "rounded-full border border-primary flex-center",
          {
            "bg-primary": sent,
          },
        )}
      >
        {sent && <Icon name="check" className="text-white" size={8} />}
      </div>
    </GutterBubble>
  );
};

const GutterBubble = ({ children }: { children: ReactNode }) => (
  <div className="w-full flex justify-end mt-8 pr-36">{children}</div>
);
