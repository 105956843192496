import { createClient } from "graphql-ws";

import { apiUrl } from "utils/environment";
import { notifier } from "utils/notifier";

import { SchemaType } from "../base-types";
import { getRawRequestContext } from "./api-client";
import { GqlSchemaAuthenticationKind } from "./endpoints";
import {
  AnySocketEndpointDefinition,
  API_KIND_BASE_PATH,
  EndpointAuthenticationKind,
  RequestContext,
  SocketEndpointDefinition,
} from "./types";

/**
 * Creates a new `WebSocket` for the given endpoint.
 */
export const webSocketForEndpointOrThrow = async <
  D extends AnySocketEndpointDefinition,
>(
  definition: D,
  requestContext: RequestContext<D["authenticationKind"]>,
  protocol?: string,
) => {
  const { url, token } = await getUrlAndTokenOrThrow(
    definition,
    requestContext,
  );
  return new WebSocket(url, [...(protocol ? [protocol] : []), `jwt-${token}`]);
};

const SECOND_RECONNECTION_DELAY_MS = 1_000;
const MAXIMUM_RECONNECTION_DELAY_MS = 30_000;

export type GraphQLWsLinkOptions = {
  onConnected?: () => void;
  onClosed?: () => void;
};

export const createGraphQLWsClient = async <T extends SchemaType>(
  schemaType: T,
  definition: SocketEndpointDefinition<GqlSchemaAuthenticationKind<T>>,
  requestContext: RequestContext<GqlSchemaAuthenticationKind<T>>,
  { onConnected, onClosed }: GraphQLWsLinkOptions,
) => {
  const { url } = await getUrlAndTokenOrThrow(definition, requestContext);

  return createClient({
    url: url.toString(),
    connectionParams: async () => {
      const { token } = await getRawRequestContext(requestContext);
      return { bearer: token };
    },
    lazy: false,
    retryAttempts: Number.MAX_SAFE_INTEGER,
    keepAlive: 10000,
    retryWait: async (retries) =>
      new Promise((resolve) => {
        setTimeout(
          resolve,
          retries === 0
            ? 0
            : Math.min(
                MAXIMUM_RECONNECTION_DELAY_MS,
                SECOND_RECONNECTION_DELAY_MS * 2 ** (retries - 1),
              ),
        );
      }),
    shouldRetry: (errOrCloseEvent) => {
      console.debug("Error or close event received", errOrCloseEvent);
      return true;
    },
    on: {
      opened: () => {
        console.debug("graphql-ws client opened for schemaType", schemaType);
      },
      connected: () => {
        console.debug("graphql-ws client connected for schemaType", schemaType);
        onConnected?.();
      },
      closed: () => {
        console.debug("graphql-ws client closed for schemaType", schemaType);
        onClosed?.();
      },
      error: (err) => {
        notifier.error({
          sentry: {
            exception: new Error(
              `Socket client encountered an error: ${JSON.stringify(err)}`,
            ),
          },
        });
      },
    },
  });
};

const getUrlAndTokenOrThrow = async <
  AuthenticationKind extends EndpointAuthenticationKind,
>(
  definition: SocketEndpointDefinition<AuthenticationKind>,
  requestContext: RequestContext<AuthenticationKind>,
) => {
  const { token, explicitRegion, impersonationUuid, currentIdentityUuid } =
    await getRawRequestContext(requestContext);
  if (!token) throw new Error("Could not get a valid token for the WebSocket.");

  // For historical reasons, we pass the token and impersonation UUID in the
  // `Sec-WebSocket-Protocol` header instead of a first authentication packet.
  // Since we didn't want to keep going down this path, the newer concept of
  // "current identity" is passed as a search parameter instead.
  const path = `${API_KIND_BASE_PATH[definition.apiKind]}/${definition.path}`;
  const url = new URL(`${apiUrl(explicitRegion)}/${path}`);
  url.protocol = url.protocol.replace("http", "ws");
  if (currentIdentityUuid) {
    url.searchParams.set("nabla-identity-id", currentIdentityUuid);
  }
  if (impersonationUuid) {
    url.searchParams.set("nabla-impersonation", impersonationUuid);
  }

  return { url: url.toString(), token };
};
