import { ReactNode, useState } from "react";

import { JoinRoomOptions, LivekitRoomContext } from "./LivekitRoomContext";

export const LivekitRoomProvider = ({ children }: { children: ReactNode }) => {
  const [endScreen, setEndScreen] = useState<{
    node: ReactNode;
    livekitRoomUuid: UUID;
  }>();
  const [joinOptions, setJoinOptions] = useState<JoinRoomOptions>();

  return (
    <LivekitRoomContext.Provider
      value={{
        currentRoom: joinOptions?.room,
        endScreen: endScreen
          ? {
              ...endScreen,
              close: () => setEndScreen(undefined),
            }
          : undefined,
        joinRoom: setJoinOptions,
        closeCurrentRoom: () => {
          if (!joinOptions) return;
          if (joinOptions.endScreen) {
            setJoinOptions(undefined);
            setEndScreen({
              node: joinOptions.endScreen,
              livekitRoomUuid: joinOptions.room.uuid,
            });
          } else {
            setJoinOptions(undefined);
          }
        },
        dismissCurrentRoom: () => {
          setJoinOptions(undefined);
        },
      }}
    >
      {children}
    </LivekitRoomContext.Provider>
  );
};
