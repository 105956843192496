import { useState } from "react";

import { useMessages } from "contexts/Messages/MessagesContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  NewTimelineItemFragment,
  ScheduleMessagesFragmentProps,
} from "generated/provider";
import { useGraphQLClient } from "graphql-client/GraphQLClientContext";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useOn } from "hooks/useOn";
import { useTranslation } from "i18n";
import { isKnownUnionValue } from "utils/apollo";
import { displayUser } from "utils/display";
import {
  clearTemporaryStatus,
  flashTemporaryStatus,
} from "utils/title-and-favicon";

import newMessageSound from "./ding.mp3";

export const useOnNewTimelineItem = () => {
  const { user } = useDoctor();
  const t = useTranslation();
  const updateDocumentTitle = useUpdateDocumentTitle();
  const { addItemInCache } = useMessages();
  const client = useGraphQLClient().graphQLClients.PROVIDER;
  const playSound = usePlayNewMessageSound();
  const isDesktop = useIsDesktop();

  return (item: NewTimelineItemFragment) => {
    if (!isKnownUnionValue("TimelineItem", item)) return;

    addItemInCache({ experienceUuid: item.experience.uuid, item });

    if (
      item.__typename === "ExperienceEvent" &&
      item.content.__typename === "PatientCompletedQuestionsSet"
    ) {
      updateDocumentTitle(
        t("inboxes.qa_experience.questions_set.event.completed_from_user", {
          user: displayUser(item.content.patientNonNullable, "FIRST_NAME"),
        }),
      );
      return;
    }

    if (item.__typename !== "Message") return;

    if (item.fromScheduledMessageUuid) {
      client.update({
        uuid: item.experience.uuid,
        fragment: ScheduleMessagesFragmentProps,
        write: (draft) => {
          draft.scheduledMessages.filter(
            (it) => it.uuid === item.fromScheduledMessageUuid,
          );
        },
      });
    }

    if (!isKnownUnionValue("User", item.sender)) return;

    if (item.sender.uuid === user.uuid) return; // Don't notify own messages
    if (
      item.experience.assignedDoctors.isNotEmpty() &&
      !item.experience.assignedDoctors
        .map((doctor) => doctor.uuid)
        .includes(user.uuid)
    ) {
      return; // Don't notify messages if someone else is assigned
    }

    if (isDesktop && user.newMessageSoundEnabled) playSound();

    updateDocumentTitle(
      t("new_message_from_user", {
        user: displayUser(item.sender, "FIRST_NAME"),
      }),
    );
  };
};

const usePlayNewMessageSound = () => {
  const [audio] = useState(() => new Audio(newMessageSound));

  return () => {
    audio.volume = 0.3;
    // Safari will block background audio by default.
    // It can be enabled via Safari -> Preferences -> Auto-Play -> Allow all Auto-Play
    // This is a nice to have, so just ignore if it doesn't works
    audio.play().catch(() => null);
  };
};

export const useUpdateDocumentTitle = () => {
  useOn("focus", clearTemporaryStatus);

  return (text: string) => {
    if (document.hasFocus()) return;
    flashTemporaryStatus(text);
  };
};
