import { useMemo } from "react";
import { gql } from "graphql-tag";

import { useUserForPrescriptions } from "components/EHRComposer/Prescriptions/useUserForPrescriptions";
import { FormNoteSectionsComposer } from "components/Form/Editor/NoteSections/FormNoteSectionsComposer";
import { NoteSectionWithComposerFields } from "components/Form/Editor/NoteSections/types";
import {
  createComposerSections,
  createEmptySections,
} from "components/Form/Editor/NoteSections/utils";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { useEHR } from "components/Patient/useEHR";
import {
  AutoPagesOptions,
  usePDFPreview,
} from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useCustomizedAutocompleteModel } from "contexts/User/useCustomizedAutocompleteModel";
import { CreateNote, DeleteNote, UpdateNote } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { DistributiveOmit } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import {
  getSectionInput,
  noteSectionsFromPatientNoteSection,
  notifyNoteCreationOrUpdate,
} from "utils/notes";
import { notifier } from "utils/notifier";

import {
  PatientTimelineItem,
  PatientTimelineItemProps,
} from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";
import { usePatientTimelineLocalItemState } from "../PatientTimelineLocalStateContext";
import { NotesExportPreview } from "./NotesExportPreview";

type FormData = {
  title: string;
  sections: NoteSectionWithComposerFields[];
};

gql`
  mutation DeleteNote($uuid: UUID!) {
    deletePatientNote(patientNoteUuid: $uuid) {
      patientNoteUuid
      patient {
        uuid
      }
    }
  }
`;

export const PatientNoteItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"PatientNote">;
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { refetchAll } = useEHR(patient.uuid);
  const [updateNote] = useMutation(UpdateNote);
  const [deletePatientNote] = useMutation(DeleteNote, {
    onSuccess: ({ patientNoteUuid }, client) =>
      client.remove("PatientNote", patientNoteUuid),
  });
  const userForPrescription = useUserForPrescriptions();

  const autoPagesOptions: AutoPagesOptions = { margin: [50, 50, 30, 50] };
  const { previewRef: pdfPreviewRef, generatePdf } =
    usePDFPreview(autoPagesOptions);
  const getNoteForExport = () => ({ ...item, author: null, patient });
  const getPdfName = () =>
    `${patient.firstName}_${patient.lastName}_${item.title}.pdf`;

  const initialSections = useMemo(
    () =>
      createComposerSections(noteSectionsFromPatientNoteSection(item.sections)),
    [item],
  );

  return (
    <DeletionConfirmationModal
      suffix={t("patient_view.patient_note.delete_suffix")}
      onConfirm={async (closeDeletionModal) => {
        await deletePatientNote({ uuid: item.uuid });
        notifier.success(t("patient_view.patient_note.delete_success"));
        closeDeletionModal();
      }}
    >
      {(openDeletionModal) => (
        <>
          {userForPrescription.hasAllRequiredFields && (
            <div className="hidden">
              <NotesExportPreview
                theme="PDF"
                notes={[getNoteForExport()]}
                user={userForPrescription}
                previewRef={pdfPreviewRef}
                autoPagesOptions={autoPagesOptions}
              />
            </div>
          )}
          <BasePatientNoteItem
            item={item}
            getFileAttachment={
              userForPrescription.hasAllRequiredFields
                ? () =>
                    generatePdf().then((file) => ({
                      file: new File([file], getPdfName(), {
                        type: "application/pdf",
                      }),
                    }))
                : undefined
            }
            withFaxOptions={{
              documentType: "NOTE",
              getPreview: (previewRef, comment) => (
                <NotesExportPreview
                  theme="PDF"
                  notes={[getNoteForExport()]}
                  user={userForPrescription}
                  previewRef={previewRef}
                  autoPagesOptions={autoPagesOptions}
                  comment={comment}
                />
              ),
            }}
            additionalMenuItems={[
              {
                text: t("patient_view.item.delete"),
                icon: "trash",
                className: "text-danger",
                onClick: (closeMenu) => {
                  closeMenu();
                  openDeletionModal();
                },
              },
            ]}
            initialValues={{
              title:
                item.title.trimOrNull() ??
                t("patient_view.default_title.patient_note"),
              sections: initialSections,
            }}
            onSubmit={async ({ title, sections }) => {
              await updateNote({
                patientNoteUuid: item.uuid,
                title,
                sections: sections.map((it) => getSectionInput(it)),
              });

              // TODO(@liautaud): Clean up this legacy from the appointment view.
              //  Notably, the wording of the success message is not coherent
              //  with all the other success messages in the patient view.
              notifyNoteCreationOrUpdate(t, sections, true);
              refetchAll();
            }}
          />
        </>
      )}
    </DeletionConfirmationModal>
  );
};

export const NewPatientNoteItem = ({
  temporaryUuid,
}: {
  temporaryUuid: UUID;
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { expandOtherItem } = usePatientTimelineLocalItemState(temporaryUuid);
  const { refetchAll } = useEHR(patient.uuid);

  const [createNote] = useMutation(CreateNote);

  const initialSections = useMemo(() => createEmptySections(["FREE_TEXT"]), []);

  return (
    <BasePatientNoteItem
      item={{ temporaryUuid, __typename: "PatientNote" }}
      initialValues={{
        title: t("patient_view.default_title.patient_note"),
        sections: initialSections,
      }}
      onSubmit={async ({ title, sections }) => {
        const noteData = await createNote({
          patientUuid: patient.uuid,
          title,
          sections: sections.map((it) => getSectionInput(it)),
        });
        if (!noteData) return;

        // TODO(@liautaud): Clean up this legacy from the appointment view.
        //  Notably, the wording of the success message is not coherent
        //  with all the other success messages in the patient view.
        notifyNoteCreationOrUpdate(t, sections, false);
        refetchAll();

        expandOtherItem(noteData.note.uuid);
      }}
    />
  );
};

const BasePatientNoteItem = (
  props: DistributiveOmit<
    PatientTimelineItemProps<"PatientNote", FormData, FormData>,
    "children"
  >,
) => {
  const { patient } = usePatient();
  const autocomplete = useCustomizedAutocompleteModel({
    patient,
    useCase: "EHR",
  });
  return (
    <PatientTimelineItem<"PatientNote", FormData, FormData> {...props}>
      {(editCount) => (
        <PatientTimelineItemContentWithPadding>
          <FormNoteSectionsComposer
            // FIXME(@samhumeau): the `FormNoteSectionsComposer` currently
            //  has an internal state, so it needs to be re-rendered every
            //  time the form contents change.
            key={`sections-${editCount}`}
            name="sections"
            keepToolsMarginsInReadOnly
            autocomplete={autocomplete}
            style={{ marginLeft: -60 }}
          />
        </PatientTimelineItemContentWithPadding>
      )}
    </PatientTimelineItem>
  );
};
