import { ReactNode } from "react";
import gql from "graphql-tag";

import { QueryResult } from "components/Query/QueryResult";
import {
  GetPatientViewDoneItems,
  GetPatientViewPatientData,
  GetPatientViewTodoItems,
} from "generated/provider";
import { useQuery } from "graphql-client/useQuery";

import { PatientViewContext } from "./PatientViewContext";

gql`
  fragment PatientTimelineItem on PatientTimelineItem {
    ... on AperoBill {
      ...AperoBill
    }
    ... on Appointment {
      ...AppointmentWithNote
    }
    ... on Experience {
      ...ExperienceItemSummary
    }
    ... on PatientDocument {
      ...PatientDocumentItemSummary
    }
    ... on PatientNote {
      ...PatientNoteItemSummary
    }
    ... on Task {
      ...TaskItemSummary
    }
    ... on MedicalOrder {
      ...MedicalOrder
    }
    ... on QuestionnaireResponse {
      ...QuestionnaireResponse
    }
    ... on NablaPrescription {
      ...NablaPrescription
    }
    ... on BravadoPrescription {
      ...BravadoPrescription
    }
    ... on PhotonPrescription {
      ...PhotonPrescription
    }
  }

  query GetPatientViewPatientData($patientUuid: UUID!) {
    patient(patientUuid: $patientUuid) {
      patient {
        ...PatientSummary
        ...PatientPrescriptionInfos
        weightKG {
          ...HealthDataPoint
        }
        imc
        phoneV2
        externalId
        tags {
          ...PatientTag
        }
        patientFieldValues {
          patientField {
            uuid
          }
          value
        }

        ...Devices
        qaExperiencesCount
      }
    }
  }
  query GetPatientViewTodoItems($patientUuid: UUID!) {
    todoTimelineItems(
      patientUuid: $patientUuid
      page: { cursor: null, numberOfItems: -1 } # TODO: Add pagination.
    ) {
      data {
        ...PatientTimelineItem
      }
      hasMore
      nextCursor
    }
  }
  query GetPatientViewDoneItems($patientUuid: UUID!) {
    doneTimelineItems(
      patientUuid: $patientUuid
      page: { cursor: null, numberOfItems: -1 } # TODO: Add pagination.
    ) {
      data {
        ...PatientTimelineItem
      }
      hasMore
      nextCursor
    }
  }
`;

export const PatientViewProvider = ({
  patientUuid,
  children,
}: {
  patientUuid: UUID;
  children: ReactNode;
}) => {
  // FIXME(@liautaud): Perform all three queries with a single request once
  //  the codegen and runtime support it.
  const variables = { patientUuid };
  const patientDataQuery = useQuery(GetPatientViewPatientData, { variables });
  const todoItemsQuery = useQuery(GetPatientViewTodoItems, { variables });
  const doneItemsQuery = useQuery(GetPatientViewDoneItems, { variables });

  return (
    <QueryResult
      loading={
        patientDataQuery.loading ||
        todoItemsQuery.loading ||
        doneItemsQuery.loading
      }
      error={
        patientDataQuery.error ?? todoItemsQuery.error ?? doneItemsQuery.error
      }
      data={{
        patientData: patientDataQuery.data!,
        todoItems: todoItemsQuery.data!,
        doneItems: doneItemsQuery.data!,
      }}
    >
      {({ patientData, todoItems, doneItems }) => (
        <PatientViewContext.Provider
          value={{
            patientData,
            todoItems,
            doneItems,
          }}
        >
          {children}
        </PatientViewContext.Provider>
      )}
    </QueryResult>
  );
};
