import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import classNames from "classnames";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import { useTranslation } from "i18n";
import { displayFileSize } from "utils/file";

import { Label } from "../Label/Label";
import styles from "./dropzone.module.css";

type DropzoneProps = {
  name: string;
  label?: string;
  accept?: string | string[];
  maxSize: number;
  multiple: boolean;
  onDrop: NonNullable<DropzoneOptions["onDrop"]>;
  className?: string;
  style?: CSSProperties;
  disabled: boolean;
  autofocus?: boolean;
  error: string | undefined;
  fileUploadServerPath?: string;
  children: (isDragActive: boolean) => ReactNode;
};
export type CommonFormDropzoneProps = Omit<
  DropzoneProps,
  "disabled" | "multiple" | "error" | "onDrop" | "children"
> & { disabled?: boolean };

export const Dropzone = ({
  name,
  label,
  accept,
  maxSize,
  multiple,
  onDrop,
  className,
  style,
  disabled,
  autofocus,
  error,
  children,
}: DropzoneProps) => {
  const t = useTranslation();
  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({ accept, maxSize, multiple, disabled, onDrop });

  const didAutofocusRef = useRef(false);

  useEffect(() => {
    if (autofocus && !didAutofocusRef.current) {
      open();
      didAutofocusRef.current = true;
    }
  }, [autofocus, open]);

  const fileTooLarge = fileRejections.find(({ file }) => file.size > maxSize);
  const errorLabel = fileTooLarge
    ? t("dropzone.file_is_too_large", {
        name: fileTooLarge.file.name,
        size: displayFileSize(fileTooLarge.file.size),
        maxSize: displayFileSize(maxSize),
      })
    : error;

  return (
    <div
      className={classNames(styles.dropzone, className)}
      style={style}
      {...getRootProps()}
    >
      <Label name={name} label={label} error={errorLabel} />
      <input name={name} {...getInputProps()} />
      {children(isDragActive)}
    </div>
  );
};
