import {
  CalendarStartOfWeekKnownValue,
  MeasurementSystemKnownValue,
} from "generated/provider";
import { staticT as t } from "i18n";

export const displayCalendarStartOfWeekLabel = (
  calendarStartOfWeek: CalendarStartOfWeekKnownValue,
) => {
  switch (calendarStartOfWeek) {
    case "MONDAY":
      return t("monday");
    case "SUNDAY":
      return t("sunday");
  }
};

export const displayMeasurementSystemLabel = (
  measurementSystem: MeasurementSystemKnownValue,
) => {
  switch (measurementSystem) {
    case "METRIC":
      return t("metric_system_description");
    case "IMPERIAL":
      return t("imperial_system_description");
  }
};
