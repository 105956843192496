import { lazy, memo, useState } from "react";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import gql from "graphql-tag";

import { useAddTemplateWindow } from "atoms/useAddTemplateWindow";
import { useMediaWindows } from "atoms/useMediaWindows";
import { Audio } from "components/Audio/Audio";
import { Avatar } from "components/Avatar/Avatar";
import { MaybeClickableAvatar } from "components/Avatar/MaybeClickableAvatar";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { QuestionsSetReader } from "components/QuestionsSet/QuestionsSetReader";
import { ReplyMessage } from "components/ReplyMessage/ReplyMessage";
import { ShareButton } from "components/Share/ShareButton";
import { SidePanel } from "components/SidePanel/SidePanel";
import { TextWithLinks } from "components/TextWithLinks/TextWithLinks";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { DoctorMessageActionsType } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  DeleteMessage,
  DoctorSummaryFragment,
  ExperienceType,
  FileUploadFragment,
  MessageFragment,
  UnscheduleMessage,
  UpdateMessageAssignment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useDownload } from "hooks/useDownload";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";
import {
  MessageContent,
  ReplyMessage as ReplyMessageType,
  ReplyMessageContent,
} from "types";
import { isKnownUnionValue } from "utils/apollo";
import {
  displayMessageSender,
  displayQuestionsSetState,
  displayUser,
} from "utils/display";

import { EventMessage } from "./ActionRequestMessage";
import { DeleteMessageButton } from "./DeleteMessageButton";
import {
  ImageBatch,
  ImageBatchModal,
  ImageBatchPreview,
} from "./ImageBatchPreview";
import { LivekitRoomMessage } from "./LivekitRoomMessage";
import { MessageEHRSuggestions } from "./MessageEHRSuggestions";
import { MessageReactions } from "./MessageReactions";
import { MessageReactionsPopover } from "./MessageReactionsPopover";
import { SaveAsDocumentButton } from "./SaveAsDocumentButton";
import styles from "./messageContainer.module.css";

const PDFViewer = lazy(() => import("components/PDFViewer/PDFViewer"));

gql`
  mutation DeleteMessage($uuid: UUID!) {
    deleteMessage(messageUuid: $uuid) {
      message {
        uuid
        content {
          ...MessageContentSummary
        }
      }
    }
  }

  mutation UnscheduleMessage($uuid: UUID!) {
    unscheduleMessage(uuid: $uuid) {
      experience {
        ...ScheduleMessages
      }
    }
  }
`;

export const MessageContainer = memo(
  ({
    message,
    userUuid,
    experienceUuid,
    experienceType,
    isFirstOfGroup,
    toggleFocusedMessageUuid,
    onAvatarClick,
    onReply,
    isScheduled = false,
    DoctorMessageActions,
    readonly = false,
    localProps,
    search,
    onCreatePatientTimelineItem,
  }: {
    message: MessageFragment | ImageBatch;
    userUuid: UUID;
    experienceUuid: UUID;
    experienceType: ExperienceType;
    isFirstOfGroup: boolean;
    toggleFocusedMessageUuid: (uuid: UUID) => void;
    onAvatarClick: ((uuid: UUID) => void) | undefined;
    search?: string;
    onCreatePatientTimelineItem?: (uuid: UUID) => void;

    // Remote props, not in object to allow equality compare of React.memo
    onReply?: (replyMessage: ReplyMessageType) => void;
    isScheduled?: boolean;
    DoctorMessageActions?: DoctorMessageActionsType;
    readonly?: boolean;

    localProps?: {
      sending: boolean;
      failed: boolean | undefined;
      retrySendMessage: (messageUuid: UUID) => void;
      deleteFailedMessage: (messageUuid: UUID) => void;
      percentageProgress: number | undefined;
    };
  }) => {
    const t = useTranslation();
    const { user, hasAccessToGatekeeperFeature } = useDoctor();

    // Don't use useExperience here, as any new message or event will render
    // the whole conversation, which is costly when having 20+ messages with medias
    // A selector pattern (like for useMediaWindows atom) should probably be used
    // later on to avoid this big list of props.

    const { mediaWindowOpened, open } = useAddTemplateWindow((atom) => ({
      mediaWindowOpened: atom.content !== null,
      open: atom.open,
    }));

    const [deleteMessage] = useMutation(DeleteMessage);

    // For batch images.
    const [showImageIndex, setShowImageIndex] = useState<number>();

    const [unscheduleMessage] = useMutation(UnscheduleMessage);
    const isDesktop = useIsDesktop();

    const senderUuid = isKnownUnionValue("User", message.sender)
      ? message.sender.uuid
      : null;
    const isMine = senderUuid === userUuid;

    const isSecondary =
      message.senderType === "SYSTEM" ||
      (message.senderType === "DOCTOR" && !isMine);

    const isRightMessage =
      experienceType === "PROVIDER_ONLY_CONVERSATION"
        ? isMine
        : message.senderType === "DOCTOR" || message.senderType === "SYSTEM";

    const content = message.__typename === "Message" ? message.content : null; // Stable reference for callback usage
    const isMessage = message.__typename === "Message";
    const textContent =
      isMessage && message.content.__typename === "TextMessageContent"
        ? message.content.text
        : undefined;
    const mediaContent =
      isMessage &&
      [
        "FileMessageContent",
        "AudioMessageContent",
        "ImageMessageContent",
        "VideoMessageContent",
      ].includes(message.content.__typename) &&
      "fileUpload" in message.content
        ? message.content.fileUpload
        : undefined;

    const onWhiteBackground = readonly;

    const canBeAssigned =
      hasAccessToGatekeeperFeature("MESSAGE_INBOX") &&
      experienceType === "SINGLE_PATIENT_CONVERSATION" &&
      textContent &&
      message.senderType === "PATIENT";
    // Message Inbox objects
    const assignedTo = canBeAssigned
      ? "assignedDoctor" in message && message.assignedDoctor
        ? message.assignedDoctor
        : "unassigned"
      : undefined;
    const status =
      hasAccessToGatekeeperFeature("MESSAGE_INBOX") &&
      experienceType === "SINGLE_PATIENT_CONVERSATION" &&
      textContent &&
      message.senderType === "PATIENT" &&
      "handledAt" in message
        ? message.handledAt
          ? "DONE"
          : "TODO"
        : undefined;

    return (
      <div
        className={classNames("flex", isFirstOfGroup ? "mt-12" : "mt-[5px]", {
          "flex-row-reverse": isRightMessage,
        })}
      >
        {showImageIndex !== undefined &&
          message.__typename === "ImageBatch" && (
            <ImageBatchModal
              batch={message}
              selectedIndex={showImageIndex}
              onHide={() => setShowImageIndex(undefined)}
            />
          )}
        <div
          className={classNames(
            "w-36 flex-col mt-24",
            isRightMessage ? "items-end" : "items-start",
          )}
        >
          {isFirstOfGroup && (
            <MaybeClickableAvatar
              size={27}
              user={
                message.senderType === "SYSTEM"
                  ? user.subOrganization
                  : message.sender
              }
              onClick={
                onAvatarClick && senderUuid
                  ? () => onAvatarClick(senderUuid)
                  : undefined
              }
            />
          )}
        </div>
        <div
          className={classNames(
            "flex-fill flex-col",
            isRightMessage ? "items-end" : "items-start",
          )}
        >
          <Header
            userUuid={userUuid}
            message={message}
            isFirstOfGroup={isFirstOfGroup}
            isMine={isMine}
            onWhiteBackground={onWhiteBackground}
          />
          <div
            className={classNames("w-full flex items-center group relative", {
              "flex-row-reverse": isRightMessage,
            })}
          >
            {message.__typename === "Message" ? (
              <>
                <div
                  // Ensure place for 3 action icons
                  // Technically, if the last message of a member conversation is a patient sending
                  // a PDF, 4 actions will be displayed (expand, reply, add to EHR, mark as unseen)
                  // If member conversation become a thing again, we should rethink this UX
                  // to keep the maxWidth high on mobile
                  style={{
                    maxWidth:
                      readonly || message.extractedEHRSuggestions.isNotEmpty()
                        ? "calc(100% - 32px)"
                        : `min(80%, calc(100% - ${3 * 32}px))`,
                  }}
                  className={classNames("relative", {
                    "ml-4": isRightMessage,
                    "mr-4": !isRightMessage,
                  })}
                  onMouseEnter={() => {
                    if (!matchMedia("(hover: hover)").matches) {
                      toggleFocusedMessageUuid(message.uuid);
                    }
                  }}
                  title={message.eventTime.format({
                    relative: "weekWithTime",
                  })}
                >
                  <Content
                    uuid={message.uuid}
                    search={search}
                    content={content!}
                    replyTo={message.replyMessage?.content}
                    isMine={isMine || (isRightMessage && readonly)}
                    isSecondary={isSecondary}
                    isScheduled={isScheduled}
                    onWhiteBackground={onWhiteBackground}
                    assignedTo={assignedTo}
                    status={status}
                  />
                  {message.reactions.isNotEmpty() && (
                    <MessageReactions message={message} />
                  )}
                  {(message.content.__typename === "ImageMessageContent" ||
                    message.content.__typename === "VideoMessageContent" ||
                    message.content.__typename === "FileMessageContent" ||
                    message.content.__typename === "AudioMessageContent") &&
                    message.content.fileUpload.patientDocuments.isNotEmpty() && (
                      <p className="text-grey-300 text-12">
                        {t(
                          "conversation_content.timeline.message_container.saved_as_document_label",
                        )}
                      </p>
                    )}
                </div>
                {DoctorMessageActions && message.senderType === "DOCTOR" && (
                  <DoctorMessageActions message={message} />
                )}
                {experienceType === "PROVIDER_ONLY_CONVERSATION" &&
                  (isDesktop || !isMine) && (
                    <MessageReactionsPopover message={message} />
                  )}
                {(textContent || mediaContent) &&
                  !readonly &&
                  !mediaWindowOpened &&
                  experienceType === "SINGLE_PATIENT_CONVERSATION" &&
                  message.senderType === "DOCTOR" && (
                    <TooltipWrapper
                      label={t("message_container.template_tooltip")}
                      position={isScheduled ? "top" : "bottom"}
                    >
                      <ClickableIcon
                        name="floppyDisk"
                        className="invisible group-hover:visible p-4 hover:bg-grey-100 rounded"
                        onClick={() =>
                          open({ text: textContent, file: mediaContent })
                        }
                      />
                    </TooltipWrapper>
                  )}
                {!localProps &&
                  !readonly &&
                  (isMine || isScheduled) &&
                  content!.__typename !== "DeletedMessageContent" && (
                    <DeleteMessageButton
                      onDelete={() =>
                        isScheduled
                          ? unscheduleMessage({ uuid: message.uuid })
                          : deleteMessage({ uuid: message.uuid })
                      }
                      isScheduled={isScheduled}
                    />
                  )}
                {onReply && !readonly && (
                  <TooltipWrapper label={t("message_container.reply_tooltip")}>
                    <ClickableIcon
                      name="reply"
                      className="invisible group-hover:visible p-4 hover:bg-grey-100 rounded"
                      onClick={() => onReply(message)}
                    />
                  </TooltipWrapper>
                )}
                {!localProps &&
                  !readonly &&
                  message.senderType === "PATIENT" &&
                  content!.__typename !== "DeletedMessageContent" &&
                  content!.__typename !== "TextMessageContent" && (
                    <DeleteMessageButton
                      onDelete={() => deleteMessage({ uuid: message.uuid })}
                      isScheduled={isScheduled}
                    />
                  )}
                {!localProps &&
                  onCreatePatientTimelineItem &&
                  message.sender !== null &&
                  message.sender.__typename === "Patient" &&
                  (message.content.__typename === "AudioMessageContent" ||
                    message.content.__typename === "FileMessageContent" ||
                    message.content.__typename === "ImageMessageContent" ||
                    message.content.__typename === "VideoMessageContent") &&
                  message.content.fileUpload.patientDocuments.isEmpty() && (
                    <SaveAsDocumentButton
                      experienceUuid={experienceUuid}
                      patientUuid={message.sender.uuid}
                      fileUpload={message.content.fileUpload}
                      onCreatePatientTimelineItem={onCreatePatientTimelineItem}
                    />
                  )}
                {isKnownUnionValue("MessageContent", content) &&
                  content.__typename !== "DeletedMessageContent" &&
                  content.__typename !== "QuestionsSetFormMessageContent" &&
                  experienceType === "PROVIDER_ONLY_CONVERSATION" && (
                    <TooltipWrapper
                      label={t("message_container.share_tooltip")}
                    >
                      <ShareButton
                        position={
                          isRightMessage
                            ? ["top-left", "bottom-left"]
                            : ["top-right", "bottom-right"]
                        }
                        messageInput={{
                          isForwarded: true,
                          content:
                            content.__typename === "TextMessageContent"
                              ? { text: content.text }
                              : content.__typename ===
                                "LivekitRoomMessageContent"
                              ? { livekitRoom: { _: "EMPTY" } }
                              : content.fileUpload,
                        }}
                        icon="rightArrow"
                        className="invisible group-hover:visible p-4 hover:bg-grey-100 rounded"
                        successNotification={t(
                          "message_container.message_shared",
                        )}
                      />
                    </TooltipWrapper>
                  )}
              </>
            ) : (
              <ImageBatchPreview
                batch={message}
                isMine={isMine}
                onWhiteBackground={onWhiteBackground}
                onClick={(i) => setShowImageIndex(i)}
              />
            )}
          </div>
          {message.__typename === "Message" &&
            message.reactions.isNotEmpty() && <div className="h-20" />}
          {localProps?.sending &&
            message.__typename === "Message" &&
            message.content.__typename !== "TextMessageContent" && (
              <div className="text-14">
                {t(
                  "conversation_content.timeline.message_container.message_container.sending_in_progress",
                )}
                {localProps.percentageProgress !== undefined &&
                  (localProps.percentageProgress === 100
                    ? " (finalisation)"
                    : ` (${localProps.percentageProgress}%)`)}
              </div>
            )}
          {localProps?.failed && (
            <p className="text-14 text-danger">
              <Translation
                k="message_container.sending_failed"
                components={{
                  retry: ({ children }) => (
                    <a
                      className="underline cursor-pointer"
                      onClick={() => localProps.retrySendMessage(message.uuid)}
                    >
                      {children}
                    </a>
                  ),
                  cancel: ({ children }) => (
                    <a
                      className="underline cursor-pointer"
                      onClick={() =>
                        localProps.deleteFailedMessage(message.uuid)
                      }
                    >
                      {children}
                    </a>
                  ),
                }}
              />
            </p>
          )}
        </div>
        {message.__typename === "Message" &&
          message.extractedEHRSuggestions.isNotEmpty() &&
          !readonly &&
          experienceType === "SINGLE_PATIENT_CONVERSATION" && (
            <MessageEHRSuggestions
              suggestions={message.extractedEHRSuggestions}
              wrapperClassname={isFirstOfGroup ? "mt-16" : ""}
            />
          )}
      </div>
    );
  },
);

const Content = ({
  uuid,
  content,
  replyTo,
  isMine,
  isSecondary,
  isScheduled,
  onWhiteBackground,
  search,
  assignedTo,
  status,
}: {
  uuid: UUID;
  content: MessageContent;
  replyTo?: ReplyMessageContent;
  isMine: boolean;
  isSecondary: boolean;
  isScheduled: boolean;
  onWhiteBackground: boolean;
  search?: string;
  assignedTo?: DoctorSummaryFragment | "unassigned";
  status?: "TODO" | "DONE";
}) => {
  const t = useTranslation();
  const { showMedia } = useMediaWindows();
  const [isQuestionsSetReaderPanelOpened, setIsQuestionsSetReaderPanelOpened] =
    useState(false);

  if (!isKnownUnionValue("MessageContent", content)) return null;

  switch (content.__typename) {
    case "TextMessageContent":
      return (
        <TextMessage
          uuid={uuid}
          text={content.text}
          replyTo={replyTo}
          isMine={isMine}
          isSecondary={isSecondary}
          isScheduled={isScheduled}
          onWhiteBackground={onWhiteBackground}
          search={search}
          assignedTo={assignedTo}
          status={status}
        />
      );

    case "ImageMessageContent":
      return (
        <img
          className={classNames(styles.media, "object-cover")}
          src={content.fileUpload.urlV2.url}
          onClick={() => showMedia(content.fileUpload)}
          alt=""
        />
      );

    case "VideoMessageContent":
      return (
        <video
          src={content.fileUpload.urlV2.url}
          className={classNames(styles.media, "bg-black")}
          controls
          onClick={() => {
            if (Capacitor.isNativePlatform()) return; // Browser will use native full screen
            showMedia(content.fileUpload);
          }}
        />
      );

    case "AudioMessageContent":
      return <Audio src={content.fileUpload.urlV2.url} className="bg-white" />;

    case "FileMessageContent":
      if (content.fileUpload.mimeType.includes("masked")) {
        return (
          <img
            className={classNames(styles.media, "object-cover")}
            src={content.fileUpload.urlV2.url}
            alt=""
          />
        );
      }

      if (content.fileUpload.mimeType !== "application/pdf") {
        return <DownloadLink fileUpload={content.fileUpload} />;
      }

      const metadata = content.fileUpload.metadata;
      const thumbnail =
        metadata.__typename === "DocumentMetadata"
          ? metadata.thumbnail
          : undefined;

      if (thumbnail) {
        return (
          <img
            className={classNames(styles.media, "object-cover")}
            src={thumbnail.urlV2.url}
            onClick={() =>
              showMedia({
                ...content.fileUpload,
                title: content.fileTitle,
              })
            }
            data-testid="file-message-thumbnail"
            alt=""
          />
        );
      }

      // Wrapper needed for Safari
      return (
        <button
          className={styles.media}
          onClick={() =>
            showMedia({
              ...content.fileUpload,
              title: content.fileTitle,
            })
          }
        >
          <PDFViewer
            src={content.fileUpload.urlV2.url}
            className="h-full"
            pdfPageClass="h-44"
            isThumbnail
          />
        </button>
      );
    case "DeletedMessageContent":
      return (
        <div className="rounded border italic p-10">
          {t(
            "conversation_content.timeline.message_container.message_container.deleted_message",
          )}
        </div>
      );

    case "LivekitRoomMessageContent":
      return <LivekitRoomMessage livekitRoom={content.livekitRoom} />;

    case "QuestionsSetFormMessageContent":
      return (
        <div>
          <SidePanel
            opened={isQuestionsSetReaderPanelOpened}
            width={660}
            onClickOutside={() => setIsQuestionsSetReaderPanelOpened(false)}
          >
            <QuestionsSetReader
              onRequestClose={() => setIsQuestionsSetReaderPanelOpened(false)}
              form={content.form}
            />
          </SidePanel>
          <EventMessage
            label={t("inboxes.qa_experience.questions_set.item.label")}
            icon="questionnaire"
            onClick={() => setIsQuestionsSetReaderPanelOpened(true)}
            status={displayQuestionsSetState(content.form.state)}
          />
        </div>
      );
  }
};
const TextMessage = ({
  uuid,
  text,
  replyTo,
  isMine,
  isSecondary,
  isScheduled,
  onWhiteBackground,
  search,
  assignedTo,
  status,
}: {
  uuid: UUID;
  text?: string;
  replyTo?: ReplyMessageContent;
  isMine: boolean;
  isSecondary?: boolean;
  isScheduled: boolean;
  onWhiteBackground: boolean;
  search?: string;
  assignedTo?: DoctorSummaryFragment | "unassigned";
  status?: "TODO" | "DONE";
}) => {
  const [updateMessageAssignment] = useMutation(UpdateMessageAssignment);
  const { user } = useDoctor();
  const [clicked, setClicked] = useState(false);

  return (
    <div
      className={classNames(
        "rounded px-[11px] py-[9px] whitespace-pre-wrap shadow-sm-outlined hover:cursor-pointer",
        isScheduled
          ? "bg-white"
          : isMine
          ? "bg-primary-dark"
          : isSecondary
          ? "bg-secondary"
          : onWhiteBackground
          ? "bg-grey-100"
          : "bg-primary-100",
        {
          [styles.animatedPatientMessage]:
            clicked &&
            !isScheduled &&
            !isSecondary &&
            !onWhiteBackground &&
            !isMine,
        },
      )}
      onClick={() => {
        if (isScheduled || isMine || isSecondary || onWhiteBackground) return;
        setClicked((previous) => {
          if (!previous) {
            setTimeout(() => {
              setClicked(false);
            }, 1000);
            return true;
          }
          return false;
        });
      }}
    >
      {replyTo && (
        <div
          className={classNames(
            "border-l pl-8",
            isMine ? "text-grey-200" : "text-body",
            { "mb-8": text },
          )}
        >
          <ReplyMessage content={replyTo} maxLines={2} />
        </div>
      )}
      {text && (
        <TextWithLinks
          search={search}
          className={
            isMine && !isScheduled ? "text-white" : "text-primary-dark"
          }
          text={text}
          urlClassName="underline"
        />
      )}
      {(!!assignedTo || !!status) && (
        <div className="flex items-center justify-end mt-2">
          <div className="flex flex-shrink-0 mb-[-7px] mr-[-9px] gap-x-[3px] rounded border border-white p-[3px]">
            {status === "DONE" && (
              <ClickableIcon
                name="check"
                size={12}
                className="transform transition duration-300 hover:scale-125 text-grey-300 p-0"
                onClick={() => {
                  void updateMessageAssignment(
                    {
                      messageUuid: uuid,
                      isHandled: false,
                    },
                    {
                      onSuccess: (_, client) => {
                        client.evictQuery("allTodoTimelineItems", "ALL");
                        client.evictQuery("allDoneTimelineItems", "ALL");
                      },
                    },
                  );
                }}
              />
            )}
            {status === "TODO" && (
              <ClickableIcon
                name="time"
                size={12}
                className="transform transition duration-300 hover:scale-125 text-grey-300 p-0"
                onClick={() => {
                  void updateMessageAssignment(
                    {
                      messageUuid: uuid,
                      isHandled: true,
                    },
                    {
                      onSuccess: (_, client) => {
                        client.evictQuery("allTodoTimelineItems", "ALL");
                        client.evictQuery("allDoneTimelineItems", "ALL");
                      },
                    },
                  );
                }}
              />
            )}
            {assignedTo &&
              (assignedTo === "unassigned" ? (
                <ClickableIcon
                  name="profileCircle"
                  size={12}
                  className="transform transition duration-300 hover:scale-125 text-grey-300 p-0"
                  onClick={() => {
                    void updateMessageAssignment(
                      {
                        messageUuid: uuid,
                        assignedDoctorUuid: user.uuid,
                      },
                      {
                        onSuccess: (_, client) => {
                          client.evictQuery("allTodoTimelineItems", "ALL");
                          client.evictQuery("allDoneTimelineItems", "ALL");
                        },
                      },
                    );
                  }}
                />
              ) : (
                <button
                  className="p-0 m-0"
                  onClick={() => {
                    void updateMessageAssignment(
                      {
                        messageUuid: uuid,
                        assignedDoctorUuid: null,
                      },
                      {
                        onSuccess: (_, client) => {
                          client.evictQuery("allTodoTimelineItems", "ALL");
                          client.evictQuery("allDoneTimelineItems", "ALL");
                        },
                      },
                    );
                  }}
                >
                  <Avatar
                    className="border-0 transform transition duration-300 hover:scale-125"
                    size={12}
                    user={assignedTo}
                  />
                </button>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Header = ({
  userUuid,
  message,
  isFirstOfGroup,
  isMine,
  isSecondary,
  onWhiteBackground,
}: {
  userUuid: UUID;
  message: MessageFragment | ImageBatch;
  isFirstOfGroup: boolean;
  isMine: boolean;
  isSecondary?: boolean;
  onWhiteBackground: boolean;
}) => {
  const t = useTranslation();

  if (message.__typename === "ImageBatch") {
    return isFirstOfGroup ? (
      <div className="text-12 mb-4">{displayMessageSender(message)}</div>
    ) : null;
  }

  if (!message.replyMessage) {
    if (!message.isForwarded) {
      if (!isFirstOfGroup) return null;

      return (
        <div className="text-12 mb-4" data-test="message-sender">
          {displayMessageSender(message)}
        </div>
      );
    }

    return (
      <div className="flex items-center text-10 mb-4">
        <Icon name="rightArrow" size={9} className="mr-4" />
        {message.senderType === "SYSTEM"
          ? t(
              "conversation_content.timeline.message_container.message_container.transferred_message",
            )
          : isMine
          ? t(
              "conversation_content.timeline.message_container.message_container.you_transferred_a_message",
            )
          : `${displayUser(message.sender)} ${t(
              "message_container.transferred_a_message",
            )}`}
      </div>
    );
  }

  if (!isKnownUnionValue("User", message.sender)) return null;
  if (!isKnownUnionValue("User", message.replyMessage.sender)) return null;

  return (
    <div className="flex-col item-start mb-4">
      <div className="flex items-center text-10">
        <Icon name="reply" size={9} className="mr-4" />
        <span>
          {message.sender.uuid === userUuid
            ? `${t(
                "conversation_content.timeline.message_container.message_container.you_replied_to",
              )} ${
                message.replyMessage.sender.uuid === userUuid
                  ? t(
                      "conversation_content.timeline.message_container.message_container.your_message",
                    )
                  : displayUser(message.replyMessage.sender)
              }`
            : message.replyMessage.sender.uuid === userUuid
            ? `${displayUser(message.sender)} ${t(
                "message_container.replied_to_you",
              )}`
            : `${displayUser(message.sender)} ${t(
                "message_container.replied_to",
              )} ${
                message.sender.uuid === message.replyMessage.sender.uuid
                  ? t(
                      "conversation_content.timeline.message_container.message_container.his_message",
                    )
                  : displayUser(message.replyMessage.sender)
              }`}
        </span>
      </div>
      {message.content.__typename !== "TextMessageContent" && (
        <TextMessage
          uuid={message.replyMessage.uuid}
          replyTo={message.replyMessage.content}
          onWhiteBackground={onWhiteBackground}
          isMine={isMine}
          isSecondary={isSecondary}
          isScheduled={false}
        />
      )}
    </div>
  );
};

const DownloadLink = ({ fileUpload }: { fileUpload: FileUploadFragment }) => {
  const [download, downloading] = useDownload();

  return (
    <button
      onClick={() => download(fileUpload.urlV2.url, fileUpload.fileName)}
      disabled={downloading}
      className={classNames("underline text-primary", {
        "opacity-80": downloading,
      })}
    >
      {fileUpload.fileName}
    </button>
  );
};
