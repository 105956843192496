import { useRef, useState } from "react";
import Draft, {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

import {
  AllowedMentionList,
  Mention as MentionType,
  MentionedItem,
  Nullable,
  TextWithMentions,
} from "types";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { Decorator } from "../core/types";
import {
  convertAllBlocksToBulletPoints,
  createSelectionState,
  getBlockEntities,
  getBlockText,
  getCurrentBlock,
  getDecorators,
  someBlocksAreNotBulletPoints,
} from "../core/utils";
import { Autocompletion } from "./Autocompletion";
import { getMentionedItemLabel } from "./display";
import { Mention } from "./Mention";

const KEYSTROKE_TRIGGERS = ["@", "#", "$"] as const;
export type KeystrokeTrigger = typeof KEYSTROKE_TRIGGERS[number];
export type Trigger =
  | { type: "SELECTION" }
  | { type: "START_BLOCK" }
  | {
      type: "KEYSTROKE";
      keystroke: KeystrokeTrigger;
    };

type MutableInput<MutablePayload extends Record<string, unknown>> =
  MutablePayload & {
    immutablePrefix: string;
    initialText: string;
    onSubmit: (label: string) => Nullable<MentionedItem>;
  };

export type MentionEditorState<MutablePayload extends Record<string, unknown>> =
  {
    mutableInput:
      | (MutableInput<MutablePayload> & {
          key: string;
          input: string;
        })
      | undefined;
    replaceSuggestionsInput: (
      options:
        | { data: MentionedItem; label?: string; from?: number }
        | { text: string; from?: number },
    ) => void;
    startMutableEntity: (props: MutableInput<MutablePayload>) => void;
    createEntityFromSelection: (mention: MentionedItem) => void;
    insertFinalEntity: (mention: MentionedItem) => void;
    submitMutableEntityInput: (replaceWithText: boolean) => void;
    setEditorState: (callback: (prevState: EditorState) => EditorState) => void;
    suggestionsInput: undefined | { currentInput: string; trigger: Trigger };
    editorState: EditorState;
    forceBulletPoints: boolean;
  };

const getAndIncrement = (ref: React.MutableRefObject<number>) => {
  const current = ref.current;
  ref.current += 1;
  return current;
};

export const useMentionEditorState = <
  MutablePayload extends Record<string, unknown>,
>({
  initialValue,
  decorators = [],
  allowedMentions,
  forceBulletPoints,
}: {
  initialValue: TextWithMentions;
  decorators?: Decorator[];
  allowedMentions?: AllowedMentionList;
  forceBulletPoints?: boolean;
}): MentionEditorState<MutablePayload> => {
  const [editorState, setEditorState] = useState<EditorState>(() => {
    const initialEditorState = EditorState.createWithContent(
      getEditorContent(initialValue),
      getDecorators([
        // DraftJS stop at first strategy match
        ...decorators,
        {
          entityStrategy: ({ type, data }) => {
            const mentionedItem = data.mentionedItem as
              | MentionedItem
              | undefined;
            const actualItem =
              mentionedItem?.__typename === "DraftMentionedItem"
                ? mentionedItem.draftedItem
                : mentionedItem;
            return (
              type === "MENTION" &&
              !!actualItem &&
              (!allowedMentions || allowedMentions[actualItem.__typename])
            );
          },
          component: Mention,
        },
        {
          entityStrategy: ({ type }) => type === "AUTOCOMPLETION",
          component: Autocompletion,
        },
      ]),
    );
    if (forceBulletPoints && someBlocksAreNotBulletPoints(initialEditorState)) {
      return convertAllBlocksToBulletPoints(initialEditorState);
    }

    return initialEditorState;
  });
  const mentionIdentifierRef = useRef<number>(
    Math.max(0, ...initialValue.mentions.map((m) => m.mentionIdentifier)) + 1,
  );
  const [mutableInputData, setMutableInputData] = useState<
    MutableInput<MutablePayload> & { key: string; blockKey: string }
  >();

  return {
    editorState,
    setEditorState: (callback) => {
      setEditorState((current) => {
        const newState = callback(current);
        if (mutableInputData) {
          const entityProps = getEntityProps(newState, mutableInputData);
          const currentOffset = newState.getSelection().getEndOffset();
          const currentBlockKey = getCurrentBlock(newState).getKey();
          if (!entityProps) return newState;
          if (
            currentBlockKey !== entityProps.blockKey ||
            (currentOffset > entityProps.start &&
              currentOffset <
                entityProps.start + mutableInputData.immutablePrefix.length)
          ) {
            const stateAfterDrop = dropMention({
              contentState: newState.getCurrentContent(),
              selectionState: createSelectionState(
                entityProps.blockKey,
                entityProps.start,
                entityProps.end,
              ),
              label: entityProps.entityLabel,
            });
            setMutableInputData(undefined);
            return EditorState.acceptSelection(
              EditorState.push(newState, stateAfterDrop, "insert-characters"),
              createSelectionState(
                currentBlockKey,
                entityProps.start + entityProps.entityLabel.length,
              ),
            );
          }
          if (currentOffset > entityProps.end) {
            const previousSuffix = getBlockText(
              current,
              entityProps.blockKey,
            ).slice(entityProps.end);
            const newSuffix = getBlockText(
              newState,
              entityProps.blockKey,
            ).slice(entityProps.end);
            if (newSuffix.endsWith(previousSuffix)) {
              const addedText = newSuffix.slice(
                0,
                newSuffix.length - previousSuffix.length,
              );
              return EditorState.acceptSelection(
                EditorState.push(
                  newState,
                  Draft.Modifier.insertText(
                    editorState.getCurrentContent(),
                    createSelectionState(entityProps.blockKey, entityProps.end),
                    addedText,
                    undefined,
                    mutableInputData.key,
                  ),
                  "insert-characters",
                ),
                createSelectionState(currentBlockKey, currentOffset),
              );
            }
          }
        }
        return newState;
      });
    },
    mutableInput: mutableInputData
      ? getEntityProps(editorState, mutableInputData)?.let((props) =>
          Object.assign(mutableInputData, props),
        )
      : undefined,
    createEntityFromSelection: (mentionedItem: MentionedItem) => {
      if (selectionCouldBeConvertedToEntity(editorState)) {
        const newState = addMention({
          contentState: editorState.getCurrentContent(),
          selectionState: editorState.getSelection(),
          mention: {
            uuid: ephemeralUuidV4(),
            mentionIdentifier: getAndIncrement(mentionIdentifierRef),
            mentionedItem,
            displayString: getMentionedItemLabel(mentionedItem),
          },
          label: getMentionedItemLabel(mentionedItem),
        });
        setEditorState((current) =>
          EditorState.push(current, newState, "apply-entity"),
        );
      }
    },
    insertFinalEntity: (mentionedItem: MentionedItem) => {
      // Allows to insert and entity and its text without
      // using the full cycle startMutableEntity -> replaceMutableEntityInput -> repeat
      setEditorState((current) => {
        const newState = addMention({
          contentState: current.getCurrentContent(),
          selectionState: current.getSelection(),
          mention: {
            uuid: ephemeralUuidV4(),
            mentionIdentifier: getAndIncrement(mentionIdentifierRef),
            mentionedItem,
            displayString: getMentionedItemLabel(mentionedItem),
          },
          label: getMentionedItemLabel(mentionedItem),
        });
        return EditorState.push(current, newState, "apply-entity");
      });
    },
    startMutableEntity: (options) => {
      setEditorState((current) => {
        const newState = mutableInputData
          ? getStateAfterSubmittingMutableInput({
              editorState: current,
              mutableInputData,
              mentionIdentifier: getAndIncrement(mentionIdentifierRef),
              replaceWithText: false,
            }).state
          : current.getCurrentContent();

        const blockKey = getCurrentBlock(current).getKey();
        const { start, end } = getExpressionAndRangeBeforeCursor(current);
        const selectionState = createSelectionState(blockKey, start, end);
        const stateWithMutableEntity = newState.createEntity(
          "MUTABLE",
          "MUTABLE",
          options,
        );
        const stateWithPrefix = Draft.Modifier.replaceText(
          stateWithMutableEntity,
          selectionState,
          options.immutablePrefix + options.initialText,
          undefined,
          stateWithMutableEntity.getLastCreatedEntityKey(),
        );
        setMutableInputData({
          ...options,
          key: stateWithPrefix.getLastCreatedEntityKey(),
          blockKey,
        });
        const finalState = EditorState.push(
          current,
          stateWithPrefix,
          "apply-entity",
        );
        return EditorState.acceptSelection(
          finalState,
          createSelectionState(
            blockKey,
            start + options.immutablePrefix.length + options.initialText.length,
          ),
        );
      });
    },
    submitMutableEntityInput: (replaceWithText) => {
      if (!mutableInputData) return;
      setEditorState((current) => {
        const {
          state: newState,
          changeType,
          newSelection,
        } = getStateAfterSubmittingMutableInput({
          editorState: current,
          mutableInputData,
          mentionIdentifier: getAndIncrement(mentionIdentifierRef),
          replaceWithText,
        });
        return EditorState.acceptSelection(
          EditorState.push(current, newState, changeType),
          newSelection,
        );
      });
      setMutableInputData(undefined);
    },
    suggestionsInput: getSuggestionInput(editorState),
    replaceSuggestionsInput: (options) => {
      setEditorState((current) => {
        const blockKey = getCurrentBlock(current).getKey();
        const { start: wordStart, end } =
          getExpressionAndRangeBeforeCursor(current);
        const start = options.from ?? wordStart;
        const selectionState = createSelectionState(blockKey, start, end);
        const label =
          "text" in options
            ? options.text
            : options.label ?? getMentionedItemLabel(options.data);

        const newState =
          "data" in options
            ? insertMentionAndSpace({
                contentState: current.getCurrentContent(),
                selectionState,
                label,
                mention: {
                  uuid: ephemeralUuidV4(),
                  mentionIdentifier: getAndIncrement(mentionIdentifierRef),
                  mentionedItem: options.data,
                  displayString: label,
                },
              })
            : dropMention({
                contentState: current.getCurrentContent(),
                selectionState,
                label,
              });

        return EditorState.acceptSelection(
          EditorState.push(
            current,
            newState,
            "text" in options ? "insert-characters" : "apply-entity",
          ),
          createSelectionState(
            blockKey,
            start + ("text" in options ? label.length : label.length + 1),
          ),
        );
      });
      setMutableInputData(undefined);
    },
    forceBulletPoints: forceBulletPoints ?? false,
  };
};

const getSuggestionInput = (
  current: EditorState,
):
  | {
      trigger: Trigger;
      currentInput: string;
    }
  | undefined => {
  if (!current.getSelection().getHasFocus()) return;

  if (selectionCouldBeConvertedToEntity(current)) {
    const startOffset = current.getSelection().getStartOffset();
    const endOffset = current.getSelection().getEndOffset();
    const selectedText = getCurrentBlock(current)
      .getText()
      .slice(startOffset, endOffset);
    return {
      trigger: { type: "SELECTION" },
      currentInput: selectedText,
    };
  }

  if (getCurrentBlock(current).getText().isEmpty()) {
    return {
      trigger: { type: "START_BLOCK" },
      currentInput: "",
    };
  }

  const { expression, start } = getExpressionAndRangeBeforeCursor(current);
  if (getCurrentBlock(current).getEntityAt(start)) return;
  const keystrokeTrigger = KEYSTROKE_TRIGGERS.find((t) =>
    expression.startsWith(t),
  );
  if (!keystrokeTrigger) return;
  return {
    trigger: { type: "KEYSTROKE", keystroke: keystrokeTrigger },
    currentInput: expression.slice(1),
  };
};

const selectionCouldBeConvertedToEntity = (current: EditorState) => {
  // It is assumed that the selection only spans one block for entities creation.
  if (
    current.getSelection().getEndKey() !== current.getSelection().getStartKey()
  ) {
    return false;
  }

  const startOffset = current.getSelection().getStartOffset();
  const endOffset = current.getSelection().getEndOffset();

  if (current.getSelection().isCollapsed()) return false;

  // Check that there is no overlap between existing entities and current selection
  let intersectWithExistingEntity = false;
  getCurrentBlock(current).findEntityRanges(
    (character) => character.getEntity() !== null,
    (start, end) => {
      if (startOffset < end && start < endOffset) {
        intersectWithExistingEntity = true;
      }
    },
  );
  return !intersectWithExistingEntity;
};

const getEditorContent = (initialValue: TextWithMentions) => {
  let contentState = stateFromMarkdown(initialValue.text);
  // Convert mentions with label _NABLA_MENTION_ and {data : "_NABLA_MENTION_ :xx"}
  // to mentions with correct label and data from initialValue.mentions
  contentState.getBlocksAsArray().forEach((block) => {
    getBlockMentions<{ url: string }>(block, contentState)
      .sortDesc((e) => e.start)
      .forEach(({ start, end, data }) => {
        const mention = initialValue.mentions.find(
          (m) => m.mentionIdentifier === Number(data.url.split(":")[1]),
        );
        const selectionState = createSelectionState(block.getKey(), start, end);
        if (mention) {
          contentState = addMention({
            contentState,
            mention,
            selectionState,
            label: mention.displayString,
          });
        } else {
          // This should never happen, but we don't want to crash if it does.
          contentState = dropMention({
            contentState,
            selectionState,
            label: "",
          });
        }
      });
  });
  return contentState;
};

export const getBlockMentions = <T>(
  block: ContentBlock,
  contentState: ContentState,
) =>
  getBlockEntities<T>(block, contentState).filter(
    ({ type }) => type === "MENTION",
  );

const insertMentionAndSpace = ({
  contentState,
  mention,
  selectionState,
  label,
}: {
  contentState: ContentState;
  mention: MentionType;
  selectionState: SelectionState;
  label: string;
}) => {
  const stateWithMention = addMention({
    contentState,
    mention,
    selectionState,
    label,
  });
  return Modifier.insertText(
    stateWithMention,
    createSelectionState(
      selectionState.getAnchorKey(),
      selectionState.getAnchorOffset() + label.length,
    ),
    " ",
  );
};

const getStateAfterSubmittingMutableInput = <
  MutablePayload extends Record<string, unknown>,
>({
  editorState,
  mutableInputData,
  mentionIdentifier,
  replaceWithText,
}: {
  editorState: EditorState;
  mutableInputData: MutableInput<MutablePayload> & {
    key: string;
    blockKey: string;
  };
  mentionIdentifier: number;
  replaceWithText: boolean;
}): {
  state: ContentState;
  changeType: "insert-characters" | "apply-entity";
  newSelection: SelectionState;
} => {
  const entityProps = getEntityProps(editorState, mutableInputData);
  if (!entityProps) {
    return {
      state: editorState.getCurrentContent(),
      changeType: "insert-characters",
      newSelection: createSelectionState(
        getCurrentBlock(editorState).getKey(),
        editorState.getSelection().getEndOffset(),
      ),
    };
  }

  const mentionedItem = replaceWithText
    ? null
    : mutableInputData.onSubmit(entityProps.input);
  const selectionState = createSelectionState(
    mutableInputData.blockKey,
    entityProps.start,
    entityProps.end,
  );
  const label = mentionedItem
    ? getMentionedItemLabel(mentionedItem)
    : entityProps.entityLabel;

  const newState = mentionedItem
    ? insertMentionAndSpace({
        contentState: editorState.getCurrentContent(),
        selectionState,
        label,
        mention: {
          uuid: ephemeralUuidV4(),
          mentionIdentifier,
          mentionedItem,
          displayString: getMentionedItemLabel(mentionedItem),
        },
      })
    : dropMention({
        contentState: editorState.getCurrentContent(),
        selectionState,
        label,
      });

  return {
    state: newState,
    changeType: mentionedItem ? "apply-entity" : "insert-characters",
    newSelection: createSelectionState(
      mutableInputData.blockKey,
      entityProps.start + (mentionedItem ? label.length + 1 : label.length),
    ),
  };
};

const dropMention = ({
  contentState,
  selectionState,
  label,
}: {
  contentState: ContentState;
  selectionState: SelectionState;
  label: string;
}) =>
  Draft.Modifier.replaceText(
    contentState,
    selectionState,
    label,
    undefined,
    undefined,
  );

const addMention = ({
  contentState,
  mention,
  selectionState,
  label,
}: {
  contentState: ContentState;
  mention: MentionType;
  selectionState: SelectionState;
  label: string;
}) => {
  const newState = contentState.createEntity("MENTION", "IMMUTABLE", mention);
  return Draft.Modifier.replaceText(
    newState,
    selectionState,
    label,
    undefined,
    contentState.getLastCreatedEntityKey(),
  );
};

const getExpressionAndRangeBeforeCursor = (editorState: EditorState) => {
  const end = editorState.getSelection().getEndOffset();
  const blockTextUntilCursor = getCurrentBlock(editorState)
    .getText()
    .slice(0, end);

  // First check for a trigger in reasonable distance
  for (const trigger of KEYSTROKE_TRIGGERS) {
    const startTrigger = blockTextUntilCursor.lastIndexOfOrUndefined(trigger);
    if (
      startTrigger !== undefined &&
      !getCurrentBlock(editorState).getEntityAt(startTrigger)
    ) {
      return {
        start: startTrigger,
        end,
        expression: blockTextUntilCursor.slice(startTrigger),
      };
    }
  }

  const start =
    blockTextUntilCursor.lastIndexOfOrUndefined(" ")?.let((it) => it + 1) ?? 0;
  return { start, end, expression: blockTextUntilCursor.slice(start) };
};

const getEntityProps = <MutablePayload extends Record<string, unknown>>(
  editorState: EditorState,
  {
    blockKey,
    key,
    immutablePrefix,
  }: MutableInput<MutablePayload> & { key: string; blockKey: string },
) => {
  const block = editorState.getCurrentContent().getBlockForKey(blockKey);
  return getBlockEntities(block, editorState.getCurrentContent())
    .find((entity) => entity.key === key)
    ?.let(({ start, end }) => ({
      start,
      end,
      blockKey,
      entityLabel: block.getText().slice(start, end),
      input: block.getText().slice(start + immutablePrefix.length, end),
    }));
};
