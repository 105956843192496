import { useUploadFile } from "graphql-client/useUploadFile";
import { getFileType, getFileTypeFromUpload, isRemote } from "utils/file";

import { MessageContentInput } from "./MessagesContext";

export const useGetMessageContent = () => {
  const getMessageUploadContent = useGetMessageUploadContent();

  return async ({
    content,
    onUploadProgress,
  }: {
    content: MessageContentInput;
    onUploadProgress?: (percentage: number) => void;
  }) => {
    // Text message content
    if ("text" in content) return { textInput: { text: content.text } };

    // Livekit room message content
    if ("livekitRoom" in content) {
      return { livekitRoomInput: content.livekitRoom };
    }

    // QuestionsSet message content
    if ("questionsSet" in content) {
      return {
        questionsSetFormInput: { form: { questions: content.questionsSet } },
      };
    }

    // Uploaded file message content
    if (isRemote(content)) {
      const mediaType = getFileTypeFromUpload(content);
      return {
        AUDIO: { voiceInput: { upload: { uuid: content.uuid } } },
        FILE: {
          fileInput: {
            upload: { uuid: content.uuid },
          },
        },
        IMAGE: { imageInput: { upload: { uuid: content.uuid } } },
        VIDEO: { videoInput: { upload: { uuid: content.uuid } } },
      }[mediaType];
    }

    // Local file to upload message content
    return getMessageUploadContent({
      content,
      onProgress: onUploadProgress,
    });
  };
};

const useGetMessageUploadContent = () => {
  const uploadFile = useUploadFile();

  return async ({
    content,
    onProgress,
  }: {
    content: { file: File };
    onProgress?: (percentage: number) => void;
  }) => {
    const fileType = getFileType(content);
    const upload = await uploadFile({
      file: content.file,
      purpose: "MESSAGE",
      onProgress,
    });
    if (!upload) return null;
    return {
      AUDIO: { voiceInput: { upload } },
      FILE: {
        fileInput: {
          upload,
        },
      },
      IMAGE: { imageInput: { upload } },
      VIDEO: { videoInput: { upload } },
    }[fileType];
  };
};
