import { FileUploadFragment } from "generated/provider";
import { FileMessageInput, FileType, Nullable } from "types";

import { now } from "./date";
import { notifier } from "./notifier";
import { ephemeralUuidV4 } from "./stackoverflow";

export const getBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const localURLCache = new Map<Blob, string>();
export const localURL = (file: Blob) => {
  const cachedValue = localURLCache.get(file);
  if (cachedValue) return cachedValue;
  // eslint-disable-next-line nabla/use-localURL
  const url = URL.createObjectURL(file);
  localURLCache.set(file, url);
  return url;
};

export const getFileUploadFragmentType = (
  fileUpload: FileUploadFragment,
): FileType => {
  switch (fileUpload.metadata.__typename) {
    case "ImageMetadata":
      return "IMAGE";
    case "AudioMetadata":
      return "AUDIO";
    case "VideoMetadata":
      return "VIDEO";
    default:
      return "FILE";
  }
};

export const getFileType = ({ file }: { file: File }): FileType =>
  file.type.includes("image")
    ? "IMAGE"
    : file.type.includes("audio")
    ? "AUDIO"
    : file.type.includes("video")
    ? "VIDEO"
    : "FILE";

export const isRemote = (
  upload: FileMessageInput,
): upload is FileUploadFragment => "__typename" in upload;

export const getFileTypeFromUpload = (media: FileMessageInput): FileType =>
  isRemote(media) ? getFileUploadFragmentType(media) : getFileType(media);

export const getMimeTypeFromUpload = (media: FileMessageInput): string =>
  isRemote(media) ? media.mimeType : media.file.type;

export const getFileNameFromUpload = (media: FileMessageInput): string =>
  isRemote(media) ? media.fileName : media.file.name;

export const getUploadSrc = (upload: FileMessageInput) =>
  isRemote(upload)
    ? upload.urlV2.url
    : localURL(upload instanceof File ? upload : upload.file);

export const fileUploadFromFile = (
  uuid: UUID,
  file: File,
): FileUploadFragment => ({
  __typename: "FileUpload",
  uuid,
  fileName: file.name,
  mimeType: file.type,
  createdAt: now(),
  transcript: null,
  urlV2: {
    __typename: "EphemeralUrl",
    url: localURL(file),
    expiresAt: now().plusYears(50), // local url, always valid
  },
  patientDocuments: [],
  metadata: file.type.startsWith("image")
    ? { __typename: "ImageMetadata" }
    : file.type.startsWith("video")
    ? { __typename: "VideoMetadata" }
    : file.type.startsWith("audio")
    ? { __typename: "AudioMetadata", durationMs: null }
    : file.type.startsWith("text/") || file.type.startsWith("application/pdf")
    ? { __typename: "DocumentMetadata", thumbnail: null }
    : { __typename: "OtherMetadata" },
});

export const downloadFile = (blob: Blob, fileName: string) => {
  const hiddenLink = document.createElement("a");
  // We are revoking it just after so that ok
  // eslint-disable-next-line nabla/use-localURL
  const mediaObjectUrl = URL.createObjectURL(blob);
  hiddenLink.href = mediaObjectUrl;
  hiddenLink.download = fileName;
  hiddenLink.click();
  hiddenLink.remove();
  URL.revokeObjectURL(mediaObjectUrl);
};

export const downloadCSV = (
  name: string,
  data: [key: string, value: Nullable<string | number | ISOString>][][],
) => {
  if (data.isEmpty()) {
    notifier.error({ user: "Not data to download" });
    return;
  }
  const header = data[0].map(([key]) => key).join(",");
  const lines = data.map((line) =>
    line
      .map(([, value]) => value ?? "")
      .map((v) =>
        typeof v === "string" && v.includes(",")
          ? `"${v.replaceAll('"', '""')}"`
          : v,
      )
      .join(","),
  );
  const content = [header].concat(lines).join("\n");
  downloadFile(
    new Blob([content]),
    `${name}-export-${now().format("file")}.csv`,
  );
};

// from https://stackoverflow.com/a/14919494
export const displayFileSize = (bytes: number, si = true, dp = 0): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) return `${bytes} B`;

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  let b = bytes;
  do {
    b /= thresh;
    ++u;
  } while (Math.round(Math.abs(b) * r) / r >= thresh && u < units.length - 1);

  return `${b.toFixed(dp)} ${units[u]}`;
};

/**
 * Images need to be compressed, this require to use canvas
 * We reduce the quality while size > 0.75MB
 *
 * This implementation is custom because:
 * JIC & compressorjs are not iterative
 * browser-image-compression doesn't keep image ratio https://github.com/Donaldcwl/browser-image-compression/issues/21
 * Other libs are either too small or too old to be worth the try
 */
export const compressImage = (file: File) =>
  new Promise<File>((resolve, reject) => {
    getBase64(file)
      .then((base64) => {
        const image = new Image();
        image.src = base64;
        image.onload = () => {
          const canvas = getCanvasFromImage(image);

          let dataURL = canvas.toDataURL("image/jpeg", 1);
          let quality = 1;
          let diff = Infinity;

          while (
            dataURL.length > 1000000 && // Try to compress dataURL below 1M chars (about 750kb jpeg file size)
            quality > 0.2 && // Cap number of iterations (8 for 0.8 multiplier)
            diff > 50000 // Avoid using CPU for small gain (useful for mobile devices)
          ) {
            quality *= 0.8;
            const newDataURL = canvas.toDataURL("image/jpeg", quality);
            diff = dataURL.length - newDataURL.length;
            dataURL = newDataURL;
          }

          fetch(dataURL)
            .then((res) => res.blob())
            .then((blob) => {
              resolve(
                new File([blob], `${ephemeralUuidV4()}.jpg`, {
                  type: "image/jpeg",
                }),
              );
            });
        };
      })
      .catch(reject);
  });

const getCanvasFromImage = (image: HTMLImageElement) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, 0, 0);
  return canvas;
};
