import { To } from "history";

import {
  DeletionConfirmationModal,
  DeletionConfirmationModalProps,
} from "components/Modal/DeletionConfirmationModal";
import { useDoctor } from "contexts/User/UserContext";
import { useTranslation } from "i18n";

import { MenuItemProps } from "./MenuItem";

export type EditMenuWrapperProps = {
  otherActions?: MenuItemProps[];
  edit: { hidden?: boolean } & ({ to: To } | { onClick: () => void });
  // delete is a reserved keyword :/
  remove: Pick<
    DeletionConfirmationModalProps,
    "title" | "suffix" | "onConfirm"
  > & {
    condition?: boolean | { type: "ehr"; author: { uuid: UUID } | null };
  };
  children: (items: MenuItemProps[]) => JSX.Element | null;
};

export const EditMenuWrapper = ({
  edit,
  otherActions,
  remove: { condition, ...modalProps },
  children,
}: EditMenuWrapperProps) => {
  const t = useTranslation();
  const { user, hasPermission } = useDoctor();
  const canRemove =
    condition === undefined ||
    (typeof condition === "boolean"
      ? condition
      : condition.author?.uuid === user.uuid ||
        hasPermission("DELETE_ANYTHING_IN_EHR"));

  return (
    <DeletionConfirmationModal {...modalProps}>
      {(openModal) => {
        const items: MenuItemProps[] = [
          {
            icon: "edit",
            text: t("menu.edit_menu_wrapper.edit"),
            hidden: edit.hidden,
            ...("onClick" in edit
              ? {
                  onClick: (close) => {
                    close();
                    edit.onClick();
                  },
                }
              : { to: edit.to }),
          },
          ...(otherActions ?? []),
          {
            icon: "trash",
            hidden: !canRemove,
            text: t("menu.edit_menu_wrapper.delete"),
            className: "text-danger",
            onClick: (close) => {
              close();
              openModal();
            },
          },
        ];
        return children(items.filter((it) => !it.hidden));
      }}
    </DeletionConfirmationModal>
  );
};
