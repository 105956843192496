import { useState } from "react";

import { Button } from "components/Button/Button";
import { Select } from "components/Form/Select/Select";
import {
  SpeechStreamLanguage,
  TranscriptionResult,
  useVoiceInput,
} from "hooks/useVoiceInput";
import { useTranslation } from "i18n";

export const SpeechToTextFromMic = () => {
  const [language, setLanguage] = useState<SpeechStreamLanguage>("fr-FR");
  const [transcribe, setTranscribe] = useState(false);
  const [transcript, setTranscript] = useState<TranscriptionResult[]>([]);

  useVoiceInput({
    transcribe,
    language,
    onTranscriptionError: () => {
      setTranscribe(false);
    },
    onTranscriptionStart: () => {
      setTranscript([]);
    },
    onChange: (results: TranscriptionResult[]) => {
      setTranscript(results);
    },
  });
  const t = useTranslation();
  return (
    <div className="ml-auto mr-auto mt-52 space-y-10" style={{ maxWidth: 400 }}>
      <Button
        onClick={() => setTranscribe(!transcribe)}
        label={transcribe ? t("stop") : t("start")}
      />
      <Select
        value={language}
        name="language"
        options={["fr-FR", "en-US", "en-GB"] as SpeechStreamLanguage[]}
        label={t("language")}
        onChange={(newVal) => setLanguage(newVal)}
      />
      <div>
        {t("speech_to_text.currently_transcribing")}{" "}
        {transcribe ? t("yes") : t("no")}
      </div>
      <div className="">{transcript.map((it) => it.text).join(" ")}</div>
    </div>
  );
};
