import classNames from "classnames";
import gql from "graphql-tag";
import { Settings } from "luxon";

import { Maybe } from "base-types";
import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { FormDateAndHours } from "components/Form/DatePicker/FormDateAndHours";
import { FormState } from "components/Form/Form/FormState";
import { LabelWrapper } from "components/Form/Label/LabelWrapper";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormModal } from "components/Modal/FormModal";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDoctor } from "contexts/User/UserContext";
import {
  AvailabilityFragment,
  CreateAvailability,
  DoctorWithAppointmentAddressFragment,
  RecurrenceInput,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { CalendarEvent } from "../types";

gql`
  mutation CreateAvailability(
    $doctorUuid: UUID!
    $startAt: DateTime!
    $endAt: DateTime!
    $supportsRemote: Boolean!
    $supportsPhysical: Boolean!
    $recurrenceInput: RecurrenceInput
  ) {
    createAvailability(
      doctorUuid: $doctorUuid
      startAt: $startAt
      endAt: $endAt
      supportsRemote: $supportsRemote
      supportsPhysical: $supportsPhysical
      recurrenceInput: $recurrenceInput
    ) {
      availability {
        ...Availability
      }
    }
  }
`;

type RecurrenceType = "NONE" | "DAILY_EXCEPT_WEEKENDS" | "WEEKLY";

const recurrenceOptions = (startAt: ISOString): RecurrenceType[] => {
  const isWeekend = startAt.weekday() > 5;
  return isWeekend
    ? ["NONE", "WEEKLY"]
    : ["NONE", "DAILY_EXCEPT_WEEKENDS", "WEEKLY"];
};

const recurrenceTypeToInput = (
  type: RecurrenceType,
): Maybe<RecurrenceInput> => {
  const timeZone =
    typeof Settings.defaultZone === "string"
      ? Settings.defaultZone
      : Settings.defaultZone.name;
  return type === "NONE"
    ? null
    : type === "DAILY_EXCEPT_WEEKENDS"
    ? {
        rrule: "FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR",
        exceptAt: [],
        timeZone,
      }
    : {
        rrule: "FREQ=WEEKLY;",
        exceptAt: [],
        timeZone,
      };
};

export type AvailabilityCreationFormValues = {
  startAt: ISOString;
  endAt: ISOString;
  recurrenceType: RecurrenceType;
  supportsRemote: boolean;
  supportsPhysical: boolean;
};

export const AvailabilityCreationModal = ({
  doctor,
  calendarEvent,
  onHide,
  onCreated,
}: {
  doctor: DoctorWithAppointmentAddressFragment;
  calendarEvent: CalendarEvent;
  onHide: () => void;
  onCreated?: (availability: AvailabilityFragment) => void;
}) => {
  const { hasPermission } = useDoctor();
  const t = useTranslation();

  const [createAvailability] = useMutation(CreateAvailability, {
    onSuccess: (data, client) => {
      notifier.success(
        t("scheduling.calendar.availability_creation_modal.slot_added"),
      );
      // TODO(@ruben): Handle properly cache update for availability occurrences
      client.evictQuery("availabilityOccurrencesInTimeRange", "filter");
      onHide();
      onCreated?.(data.availability);
    },
  });

  if (!hasPermission("EDIT_DOCTOR_AVAILABILITY")) return null;

  return (
    <FormModal<AvailabilityCreationFormValues>
      title={t("scheduling.calendar.availability_creation_modal.add_a_slot")}
      submitLabel={t("scheduling.calendar.availability_creation_modal.add")}
      onHide={onHide}
      className="mt-20 w-full space-y-20"
      validate={({ startAt, endAt, supportsRemote, supportsPhysical }) => {
        if (!endAt.isAfter(startAt)) {
          return {
            endAt: t(
              "availability_creation_modal.the_end_time_must_be_after_the_start_time",
            ),
          };
        }
        if (supportsPhysical && !doctor.appointmentAddress) {
          return {
            supportsPhysical: t(
              "availability_creation_modal.physical.disabled_tooltip_no_address",
            ),
          };
        }
        if (!supportsRemote && !supportsPhysical) {
          return {
            supportsPhysical: t(
              "availability_creation_modal.should_choose_remote_or_physical",
            ),
            supportsRemote: t(
              "availability_creation_modal.should_choose_remote_or_physical",
            ),
          };
        }
        return {};
      }}
      onSubmit={(values) =>
        createAvailability({
          doctorUuid: doctor.uuid,
          startAt: values.startAt.alignToSeconds(),
          endAt: values.endAt.alignToSeconds(),
          supportsRemote: values.supportsRemote,
          supportsPhysical: values.supportsPhysical,
          recurrenceInput: recurrenceTypeToInput(values.recurrenceType),
        })
      }
      initialValues={{
        startAt: calendarEvent.start.toISOString(),
        endAt: calendarEvent.end.toISOString(),
        recurrenceType: "NONE",
        supportsRemote: true,
        supportsPhysical: false,
      }}
    >
      <FormState<AvailabilityCreationFormValues>>
        {({ values, errors }) => (
          <>
            <FormDateAndHours
              startName="startAt"
              label="Date"
              endName="endAt"
            />
            <FormSelect
              name="recurrenceType"
              label={t(
                "scheduling.calendar.availability_creation_modal.recurrence",
              )}
              options={recurrenceOptions(values.startAt)}
              getOptionLabel={(o) => {
                switch (o) {
                  case "NONE":
                    return t(
                      "scheduling.calendar.availability_creation_modal.none",
                    );
                  case "DAILY_EXCEPT_WEEKENDS":
                    return t(
                      "availability_creation_modal.every_day_except_weekend",
                    );
                  case "WEEKLY":
                    const todayWeekday = values.startAt.format({
                      exception: "EEEE",
                    });

                    return t("availability_creation_modal.every_weekday", {
                      weekday: todayWeekday,
                    });
                }
              }}
            />
            <LabelWrapper
              name="consultationLocation"
              wrapperClassName="flex-col"
              label={t("availability_creation_modal.remote_or_physical_label")}
              hint={undefined}
              error={errors.supportsPhysical ?? errors.supportsRemote}
            >
              <FormCheckbox
                name="supportsRemote"
                label={t("availability_creation_modal.remote")}
                className="text-14"
              />
              <TooltipWrapper
                label={t(
                  "availability_creation_modal.physical.disabled_tooltip_no_address",
                )}
                show={!doctor.appointmentAddress}
              >
                <FormCheckbox
                  name="supportsPhysical"
                  label={t("availability_creation_modal.physical")}
                  className={classNames("mt-4", {
                    "opacity-50": !doctor.appointmentAddress,
                  })}
                  disabled={!doctor.appointmentAddress}
                />
              </TooltipWrapper>
            </LabelWrapper>
          </>
        )}
      </FormState>
    </FormModal>
  );
};
