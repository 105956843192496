import { createContext, useContext } from "react";

export type SidePanelState =
  | { mode: "LIBRARY" }
  | {
      mode: "PATIENT";
      showAdminForm: boolean;
    }
  | null;

export type QASidePanelContextProps = {
  sidePanelState: SidePanelState;
  setSidePanelState: (state: SidePanelState) => void;
  closeSidePanel: () => void;
  isTimelineOpen: boolean;
  setIsTimelineOpen: (isOpen: boolean) => void;
};
export const QASidePanelContext = createContext<QASidePanelContextProps | null>(
  null,
);
QASidePanelContext.displayName = "QASidePanelContext";

export const useQASidePanel = () => {
  const context = useContext(QASidePanelContext);
  if (!context) throw new Error("QASidePanelProvider not available");
  return context;
};
