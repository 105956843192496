import { useState } from "react";
import { getDaysInMonth } from "date-fns";

import { useField } from "hooks/useField";
import { useTranslation } from "i18n";
import { GranularDateTime } from "types";
import { range } from "utils";

import { Input, InputProps } from "../Input/Input";
import { LabelWrapper, NamedLabelWrapperProps } from "../Label/LabelWrapper";
import { Select } from "../Select/Select";

export const FormGranularDate = ({
  wrapperClassName,
  label,
  hint,
  name,

  hideDay,
  disabled,
  usePicker,
}: Omit<NamedLabelWrapperProps, "error"> & {
  disabled?: boolean;
  hideDay?: boolean;
  usePicker?: boolean;
}) => {
  const [{ value, disabled: disabledField }, { error }, { setValue }] =
    useField<GranularDateTime | undefined>({ name, disabled });

  return usePicker ? (
    <GranularDatePicker
      wrapperClassName={wrapperClassName}
      label={label}
      hint={hint}
      name={name}
      disabled={disabledField}
      error={error}
      value={value}
      setValue={setValue}
      hideDay={hideDay}
    />
  ) : (
    <GranularDateInput
      wrapperClassName={wrapperClassName}
      label={label}
      hint={hint}
      name={name}
      disabled={disabledField}
      setValue={setValue}
      initialValue={value}
    />
  );
};

export const GranularDatePicker = ({
  value,
  setValue,
  disabled,
  error,
  name,
  wrapperClassName,
  label,
  hint,
  hideDay,
}: {
  value: GranularDateTime | undefined;
  setValue: (value: GranularDateTime | undefined) => void;
  disabled?: boolean;
  error?: string;
  name: string;
  wrapperClassName?: string;
  label?: string;
  hint?: string | JSX.Element;
  hideDay?: boolean;
}) => {
  const t = useTranslation();

  return (
    <LabelWrapper
      wrapperClassName={wrapperClassName}
      label={label}
      error={error}
      hint={hint}
      name={name}
    >
      <div className="flex flex-row max-w-[80px]">
        <Select
          name={name}
          placeholder={t("form.granular_date.form_granular_date.year")}
          // TODO @thomas: Figure why it crashes in 1911. France had an offset of 9min 21 sec with GMT in 1911,
          //   https://www.timeanddate.com/time/change/france/paris?year=1911
          options={range(new Date().getFullYear(), 1920).concat(
            range(new Date().getFullYear() + 1, 2100),
          )}
          value={value ? value.dateTime.getDate().getFullYear() : undefined}
          isClearable
          onChange={(year) =>
            setValue(
              year
                ? {
                    dateTime: new Date(year, 0).toISOString(),
                    granularity: "YEAR",
                  }
                : undefined,
            )
          }
          disabled={disabled}
          hideDropdownIndicator
        />
        {value && (
          <Select
            name={`${name}_month`}
            placeholder={t("form.granular_date.form_granular_date.month")}
            options={range(1, 12)}
            getOptionLabel={(month) =>
              new Date(2020, month - 1)
                .toISOString()
                .format({ exception: "MMM" })
            }
            value={
              value.granularity === "YEAR"
                ? undefined
                : value.dateTime.getDate().getMonth() + 1
            }
            isClearable
            onChange={(month) =>
              setValue({
                dateTime: new Date(
                  value.dateTime.getDate().getFullYear(),
                  month ? month - 1 : 0,
                ).toISOString(),
                granularity: month ? "MONTH" : "YEAR",
              })
            }
            disabled={disabled}
            hideDropdownIndicator
          />
        )}
        {value && value.granularity !== "YEAR" && !hideDay && (
          <Select
            name={`${name}_day`}
            placeholder={t("form.granular_date.form_granular_date.day")}
            options={range(1, getDaysInMonth(value.dateTime.getDate()))}
            value={
              value.granularity === "DAY"
                ? value.dateTime.getDate().getDate()
                : undefined
            }
            isClearable
            onChange={(day) =>
              setValue({
                dateTime: new Date(
                  value.dateTime.getDate().setDate(day ?? 1),
                ).toISOString(),
                granularity: day ? "DAY" : "MONTH",
              })
            }
            disabled={disabled}
            hideDropdownIndicator
          />
        )}
      </div>
    </LabelWrapper>
  );
};

export const GranularDateInput = ({
  initialValue,
  setValue,
  disabled,
  name,
  wrapperClassName,
  label,
  hint,
  ...inputProps
}: {
  initialValue: GranularDateTime | undefined;
  setValue: (value: GranularDateTime | undefined) => void;
  disabled?: boolean;
  name: string;
  wrapperClassName?: string;
  label?: string;
  hint?: string | JSX.Element;
} & InputProps) => {
  const t = useTranslation();
  const [inputValue, setInputValue] = useState<string | undefined>(
    granularDateTimeToString(initialValue),
  );
  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <Input
      {...inputProps}
      key={name}
      name={name}
      wrapperClassName={wrapperClassName}
      label={label}
      hint={hint}
      error={error}
      disabled={disabled}
      value={inputValue}
      onChange={(e) => {
        const value = e.target.value;
        const granularDate = parseGranularDateTime(value);

        setInputValue(value);
        if (granularDate.isError) {
          setError(t("form_granular_date.invalid_date_format"));
          return;
        }
        setError(undefined);
        setValue(granularDate.date);
      }}
    />
  );
};

const parseGranularDateTime = (
  value: string,
): { date: GranularDateTime | undefined; isError: boolean } => {
  if (value.isEmpty()) {
    return {
      date: undefined,
      isError: false,
    };
  }

  const regex = /^(((\d{2})\/){0,2})(\d{4})$/u;
  // The regex parses the format YYYY or MM/YYYY or DD/MM/YYYY
  // But the date is invalid like this for the Date constructor
  // We need to reverse the order to make it valid
  const split = value.split("/").reverse();
  const date = new Date(split.join("-"));

  if (!regex.test(value) || date.toString() === "Invalid Date") {
    return {
      date: undefined,
      isError: true,
    };
  }

  return {
    date: {
      dateTime: date.toISOString(),
      granularity:
        value.length === 4 ? "YEAR" : value.length === 7 ? "MONTH" : "DAY",
    },
    isError: false,
  };
};

const granularDateTimeToString = (
  value?: GranularDateTime,
): string | undefined => {
  if (value === undefined) return undefined;
  const date = value.dateTime;
  const granularity = value.granularity;
  const stringDateWithDashes =
    granularity === "YEAR"
      ? date.slice(0, 4)
      : granularity === "MONTH"
      ? date.slice(0, 7)
      : granularity === "DAY"
      ? date.slice(0, 10)
      : undefined;
  return stringDateWithDashes?.split("-").reverse().join("/");
};
