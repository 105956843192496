import { useEffect } from "react";

import { useAutoCompleteModel } from "atoms/useAutoCompleteModel/useAutoCompleteModel";
import { useDraft } from "atoms/useDraft";
import {
  DEVICE_UUID_KEY,
  DRAFT_KEY,
  MESSAGES_KEY,
  RECENT_EMOJIS,
} from "consts";
import { resetLanguage } from "utils/intl";

import { useAuth } from "./AuthContext";

export const useClearUserDataOnLogout = () => {
  const { state } = useAuth();

  useEffect(() => {
    if (state !== "LOGGED_OUT") return;
    useDraft.setState({ store: {} });
    storage.removeItem(DRAFT_KEY);
    storage.removeItem(MESSAGES_KEY);
    storage.removeItem(DEVICE_UUID_KEY);
    storage.removeItem(RECENT_EMOJIS);
    useAutoCompleteModel.getState().removeAutoCompleteData();
    resetLanguage();
  }, [state]);
};
