import { useState } from "react";
import gql from "graphql-tag";
import { NavLink, useNavigate } from "react-router-dom";

import { Avatar } from "components/Avatar/Avatar";
import { Background } from "components/Background/Backgound";
import { ProfilePanel } from "components/Doctor/ProfilePanel";
import { Input } from "components/Form/Input/Input";
import { Icon } from "components/Icon/Icon";
import { BottomSafeArea, TopSafeArea } from "components/Mobile/SafeArea";
import { Query } from "components/Query/Query";
import { SidePanel } from "components/SidePanel/SidePanel";
import { useDoctor } from "contexts/User/UserContext";
import {
  CreateDoctorConversation,
  MedicalTeamQuery,
  MedicalTeamQueryData,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { addDoctorConversationInCache } from "utils/apollo";
import { displayDoctor, displayDoctorTitle } from "utils/display";

import styles from "./medicalTeam.module.css";

gql`
  query MedicalTeamQuery {
    doctorSearch(filter: { permissions: [ANSWER_QA_EXPERIENCE] }) {
      doctors(pagination: { numberOfItems: -1 }) {
        ...DoctorSummary
      }
    }
  }
`;

export const MedicalTeam = () => {
  const t = useTranslation();
  const searchProps = useSearch();
  const [selectedDoctorUuid, setSelectedDoctorUuid] = useState<UUID>();
  const { user } = useDoctor();

  return (
    <Background className="flex-fill flex-col">
      <Query query={MedicalTeamQuery}>
        {({ doctors }) => {
          const selectedDoctor = doctors.find(
            (d) => d.uuid === selectedDoctorUuid,
          );

          return (
            <>
              <div className="bg-white border-b p-16 pt-8 lg:p-24 flex-col">
                <div className="sm:mt-8 flex-col sm:space-y-12 lg:flex-row lg:space-x-16">
                  <Input
                    {...searchProps}
                    placeholder={t("medical_team.search_placeholder", {
                      count: doctors.length,
                    })}
                    wrapperClassName="flex-2"
                    leftInnerElement="search"
                    thinGray
                  />
                </div>
              </div>
              <div className="flex-fill flex-col overflow-auto">
                <div className={styles.teamGrid}>
                  {doctors
                    .filter((d) =>
                      [d.firstName, d.lastName, d.title]
                        .join(" ")
                        .fuzzyMatch(searchProps.value),
                    )
                    .map((doctor) => (
                      <DoctorCard
                        key={doctor.uuid}
                        isMyself={doctor.uuid === user.uuid}
                        doctor={doctor}
                        onSelected={() =>
                          setSelectedDoctorUuid(
                            selectedDoctorUuid === doctor.uuid
                              ? undefined
                              : doctor.uuid,
                          )
                        }
                      />
                    ))}
                </div>
                <BottomSafeArea />
              </div>
              <SidePanel
                opened={selectedDoctor !== undefined}
                onClickOutside={() => setSelectedDoctorUuid(undefined)}
                className="overflow-auto"
              >
                <TopSafeArea />
                {selectedDoctor && (
                  <ProfilePanel
                    doctorSummary={selectedDoctor}
                    close={() => setSelectedDoctorUuid(undefined)}
                  />
                )}
              </SidePanel>
            </>
          );
        }}
      </Query>
    </Background>
  );
};

const DoctorCard = ({
  doctor,
  isMyself,
  onSelected,
}: {
  doctor: MedicalTeamQueryData["doctors"][number];
  isMyself?: boolean;
  onSelected: () => void;
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [createDoctorConversation, creating] = useMutation(
    CreateDoctorConversation,
  );
  const bioPath = `${routes.PREFERENCES}/${routes.PERSONAL_INFO}`;

  return (
    <div className="bg-white rounded shadow-sm-outlined p-16 flex-col items-center text-center relative group">
      <Avatar user={doctor} size={72} />
      <div className="mt-10 w-full truncate text-primary-400 font-bold text-16">
        {displayDoctor(doctor, "WITH_PREFIX")}
      </div>
      <div className="w-full truncate text-12">
        {displayDoctorTitle(doctor)}
      </div>
      <div
        className="mt-12 w-full flex space-x-4 overflow-hidden relative"
        style={{ height: 26 }}
      >
        <div className="absolute inset-y-0 right-0 w-8 bg-gradient-to-r from-transparent to-white group-hover:to-grey-100" />
      </div>
      <div className="flex items-center space-x-10 mt-auto">
        {isMyself ? (
          <NavLink
            onClick={() => false}
            to={bioPath}
            className="mt-12 bg-white py-4 px-30 rounded flex-center disabled:opacity-80 flex-1/2 border hover:bg-grey-100"
            activeClassName="text-primary bg-primary-100 font-medium"
          >
            <Icon name="profile" className="mr-8" />
            <div className="text-10 font-medium">
              {t("medical_team.medical_team.see_bio")}
            </div>
          </NavLink>
        ) : (
          <>
            <button
              className="mt-12 bg-white py-4 px-30 rounded flex-center disabled:opacity-80 flex-1/2 border hover:bg-grey-100"
              onClick={() => onSelected()}
            >
              <Icon name="profile" className="mr-8" />
              <div className="text-10 font-medium">
                {t("medical_team.medical_team.see_bio")}
              </div>
            </button>
            <button
              className="mt-12 bg-primary py-4 px-30 rounded flex-center text-white disabled:opacity-80 flex-1/2"
              disabled={creating}
              onClick={() =>
                createDoctorConversation(
                  { doctorUuids: [doctor.uuid] },
                  {
                    onSuccess: ({ experience }, client) => {
                      addDoctorConversationInCache(client, experience);
                      navigate(
                        `${routes.CONVERSATION_BASE}/${experience.uuid}`,
                      );
                    },
                  },
                )
              }
            >
              <Icon name="chat" className="mr-8" />
              <div className="text-10 font-medium">Message</div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
