import { useEffect, useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { useDoctor } from "contexts/User/UserContext";
import { AllDoctors } from "generated/provider";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useQuery } from "graphql-client/useQuery";

import { DoctorFilter } from "./DoctorSelect";

export const useSelectableDoctors = (
  baseRoute: string,
): {
  selectedDoctor: DoctorFilter | null;
  setSelectedDoctor: (doctor: DoctorFilter) => void;
  allDoctors: DoctorFilter[];
  loading: boolean;
  error: ParsedGraphQLError | undefined;
} => {
  const navigate = useNavigate();
  const selectedDoctorUuid = useMatch(
    `${baseRoute}/:selectedDoctorUuid`,
  )?.params.selectedDoctorUuid.trimOrNull();

  const { user } = useDoctor();
  const {
    data: allDoctorsData,
    loading,
    error,
  } = useQuery(AllDoctors, {
    variables: { filter: {} },
  });

  const selectedDoctor = useMemo(
    () =>
      selectedDoctorUuid
        ? (allDoctorsData?.doctors ?? []).find(
            (doctor) => doctor.uuid === selectedDoctorUuid,
          )
        : user,
    [allDoctorsData, user, selectedDoctorUuid],
  );

  useEffect(() => {
    if (!loading && !selectedDoctor) navigate(baseRoute);
  }, [loading, selectedDoctor, navigate, baseRoute]);

  const allDoctorsWithBumperUser = useMemo(
    () =>
      (allDoctorsData?.doctors ?? []).bumped(
        (doctor) => doctor.uuid === user.uuid,
      ),
    [allDoctorsData, user],
  );

  return {
    selectedDoctor: selectedDoctor ?? null,
    setSelectedDoctor: (doctor: DoctorFilter) =>
      navigate(`${baseRoute}/${doctor.uuid}`),
    allDoctors: allDoctorsWithBumperUser,
    loading,
    error,
  };
};
