import { ReactNode } from "react";
import classNames from "classnames";
import { To } from "history";

import { Link } from "components/Link/Link";
import { AdaptativeMenu } from "components/Menu/AdaptativeMenu";
import { MenuItemProps } from "components/Menu/MenuItem";
import { NotSeenDot } from "components/NotSeenDot/NotSeenDot";

export type InboxRowProps = {
  left?: ReactNode;
  title: string;
  time: ISOString | undefined;
  subtitle: ReactNode;
  notSeen: boolean;
  className?: string;
  highlighted?: boolean;
  children?: ReactNode;
  moreActions: MenuItemProps[];
  to: To;
};

export const InboxRow = ({
  left,
  title,
  time,
  subtitle,
  notSeen,
  className,
  highlighted,
  children,
  moreActions,
  ...navigation
}: InboxRowProps) => (
  <AdaptativeMenu
    items={moreActions}
    hide={moreActions.isEmpty() || !!highlighted || notSeen}
    className="w-full"
  >
    {(menu) => (
      <div
        className={classNames("flex p-16 items-start list-element separated", {
          highlighted,
        })}
      >
        <Link
          to={navigation.to}
          className={classNames("flex flex-fill items-center", className)}
        >
          {left}
          <div className="flex-col flex-fill overflow-hidden">
            <div
              className={classNames("flex items-center text-primary-dark", {
                "font-medium": notSeen,
              })}
            >
              <div className="flex-fill flex items-center">
                <div
                  className={classNames(
                    "text-black flex-shrink truncate text-14",
                    notSeen ? "font-bold" : "font-medium",
                  )}
                >
                  {title}
                </div>
              </div>
              <div
                className="text-12 text-body ml-auto"
                title={time?.format("time")}
              >
                {time?.format({ relative: "timeOrDate" })}
              </div>
            </div>
            <div className="flex items-center mr-14">
              <div
                className={classNames("flex-fill text-14 truncate", {
                  "font-medium": notSeen,
                })}
              >
                {subtitle}
              </div>
              <div className="ml-auto flex items-center">
                <NotSeenDot notSeen={notSeen} className="ml-4" />
              </div>
            </div>
            {children}
          </div>
        </Link>
        <div className="absolute top-30 right-14"> {menu}</div>
      </div>
    )}
  </AdaptativeMenu>
);
