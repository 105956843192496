import { useCallback, useEffect, useState } from "react";
import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Input } from "components/Form/Input/Input";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { ControlledConfirmationModal } from "components/Modal/ControlledConfirmationModal";
import { Popover } from "components/Popover/Popover";
import { Query } from "components/Query/Query";
import { Separator } from "components/Separator/Separator";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  IncrementQuestionsSetTemplateUsage,
  QuestionsSetTemplateFragment,
  QuestionsSetTemplates,
  QuestionsSetTemplatesData,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTargetState } from "hooks";
import { useSearch } from "hooks/useDebounce";
import { useFormState } from "hooks/useFormState";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  query QuestionsSetTemplates {
    questionsSetTemplates {
      ...QuestionsSetTemplate
    }
  }

  mutation IncrementQuestionsSetTemplateUsage($templateUuid: UUID!) {
    incrementQuestionsSetTemplateUsage(templateUuid: $templateUuid) {
      ...QuestionsSetTemplate
    }
  }
`;

type FormValues = {
  questionsSet: string[];
};

export const QAQuestionsSetComposer = ({
  onRequestClose,
  onCancelClose,
  askToClose,
}: {
  onRequestClose: () => void;
  onCancelClose: () => void;
  askToClose: boolean;
}) => {
  const { hasAccessToGatekeeperFeature } = useDoctor();
  const experienceDraft = useExperienceDraft();
  if (!hasAccessToGatekeeperFeature("QUESTIONS_SET")) return null;
  return (
    <Form<FormValues>
      initialValues={{ questionsSet: experienceDraft.questionsSet }}
      onSubmit={(values, _) => {
        experienceDraft.setQuestionsSet(values.questionsSet);
        onRequestClose();
      }}
      className="h-full"
    >
      <QAQuestionsSetComposerInner
        onRequestClose={onRequestClose}
        onCancelClose={onCancelClose}
        askToClose={askToClose}
      />
    </Form>
  );
};

const QAQuestionsSetComposerInner = ({
  onRequestClose,
  onCancelClose,
  askToClose,
}: {
  onRequestClose: () => void;
  onCancelClose: () => void;
  askToClose: boolean;
}) => {
  const t = useTranslation();
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const showCancellationModalIfNeededOrNavBack = useCallback(
    (values: FormValues) => {
      if (values.questionsSet.isNotEmpty()) {
        setShowCancellationModal(true);
      } else {
        onRequestClose();
      }
    },
    [onRequestClose],
  );
  const { values, setFieldValue, resetForm } = useFormState<FormValues>();
  useEffect(() => {
    if (askToClose) showCancellationModalIfNeededOrNavBack(values);
  }, [values, askToClose, showCancellationModalIfNeededOrNavBack]);
  const [templateSelectorTarget, setTemplateSelectorTarget] = useTargetState();
  const [incrementQuestionsSetTemplateUsage] = useMutation(
    IncrementQuestionsSetTemplateUsage,
  );

  return (
    <div className="flex-col h-full px-44 py-32">
      {showCancellationModal && (
        <ControlledConfirmationModal
          title={t("inboxes.qa_experience.questions_set.cancellation.title")}
          onConfirm={() => {
            resetForm();
            setShowCancellationModal(false);
            onRequestClose();
          }}
          onHide={() => {
            setShowCancellationModal(false);
            onCancelClose();
          }}
          children={t(
            "inboxes.qa_experience.questions_set.cancellation.description",
          )}
          cta={{
            label: t("inboxes.qa_experience.questions_set.cancellation.cta"),
            danger: true,
          }}
        />
      )}
      <header className="flex items-center space-x-16">
        <ClickableIcon
          name="doubleChevron"
          className="flex-center w-28 h-28 bg-grey-100 rounded-sm hover:opacity-80"
          onClick={() => showCancellationModalIfNeededOrNavBack(values)}
        />
        <div className="flex items-center">
          <h1 className="text-18 font-bold text-black">
            {t("inboxes.qa_experience.questions_set.title")}
          </h1>
          {values.questionsSet.isNotEmpty() && (
            <div className="text-14 font-normal text-grey-300">
              {t("inboxes.qa_experience.questions_set.count", {
                count: values.questionsSet.length,
              })}
            </div>
          )}
        </div>
      </header>

      {values.questionsSet.isEmpty() && (
        <div className="mt-32 text-14 font-normal text-grey-400">
          {t("inboxes.qa_experience.questions_set.no_question")}
        </div>
      )}

      {values.questionsSet.isNotEmpty() && (
        <div className="flex-col shrink overflow-y-auto mt-32 gap-12">
          {values.questionsSet.map((_, index) => (
            <div key={index} className="flex mr-6 gap-6 group items-end">
              <div className="flex-1">
                <FormInput
                  placeholder={t(
                    "inboxes.qa_experience.questions_set.new_question_placeholder",
                  )}
                  name={`questionsSet.${index}`}
                  validate={(value) => {
                    const questionIsEmpty = value?.isEmpty();
                    return (
                      questionIsEmpty &&
                      t("inboxes.qa_experience.questions_set.question_error")
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
              </div>
              <ClickableIcon
                className="invisible group-hover:visible mb-4"
                name="trash"
                onClick={() =>
                  setFieldValue(
                    "questionsSet",
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    values.questionsSet.filter((_, i) => i !== index),
                  )
                }
              />
            </div>
          ))}
        </div>
      )}

      <div className="flex mt-32 gap-16">
        <div className="flex-col flex-1">
          <Query query={QuestionsSetTemplates} noSpinner>
            {({ loading, data }) => (
              <>
                <Button
                  loading={loading}
                  secondary
                  leftIcon="chevronDown"
                  label={t("inboxes.qa_experience.questions_set.templates")}
                  onClick={setTemplateSelectorTarget}
                />
                {templateSelectorTarget && data && (
                  <SelectTemplateMenu
                    target={templateSelectorTarget}
                    templates={data}
                    onSelectTemplate={(template) => {
                      incrementQuestionsSetTemplateUsage({
                        templateUuid: template.id,
                      }).catch(() => {
                        notifier.error({
                          sentry: {
                            message:
                              "Failed to increment questions set template usage",
                          },
                        });
                      });
                      setFieldValue("questionsSet", [
                        ...values.questionsSet,
                        ...template.questions,
                      ]);
                    }}
                    onClose={() => setTemplateSelectorTarget(undefined)}
                  />
                )}
              </>
            )}
          </Query>
        </div>
        <Button
          secondary
          rightIcon="add"
          label={t("inboxes.qa_experience.questions_set.new_question")}
          className="flex-1"
          onClick={() =>
            setFieldValue("questionsSet", [...values.questionsSet, ""])
          }
        />
      </div>

      <div className="border-t border-gray-200 mt-32" />

      <div className="flex justify-end mt-32 gap-12">
        <Button
          onClick={() => showCancellationModalIfNeededOrNavBack(values)}
          tertiary
          label={t("inboxes.qa_experience.questions_set.cancel")}
        />
        <Submit
          label={t("inboxes.qa_experience.questions_set.send")}
          requiresDirty
        />
      </div>
    </div>
  );
};

const templateMaxHeight = 550;

// #26493 mark
const SelectTemplateMenu = ({
  target,
  templates,
  onSelectTemplate,
  onClose,
}: {
  target: Element;
  templates: QuestionsSetTemplatesData;
  onSelectTemplate: (template: QuestionsSetTemplateFragment) => void;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const inputProps = useSearch();
  const matchingTemplates = templates.filter((template) =>
    template.title.fuzzyMatch(inputProps.value),
  );
  const templateOffsetTop = (target as HTMLElement).offsetTop;
  const templatePosition =
    templateOffsetTop &&
    templateOffsetTop > templateMaxHeight &&
    window.screen.availHeight - templateOffsetTop < templateMaxHeight
      ? "top"
      : "bottom";

  return (
    <Popover
      position={templatePosition}
      allowScrolling
      target={target}
      className="overflow-hidden shadow-light"
      style={{ width: target.clientWidth * 2 }}
      onClose={onClose}
    >
      <Input
        {...inputProps}
        autoFocus
        autoComplete="off"
        name="search"
        leftInnerElement={<Icon name="search" className="text-grey-400" />}
        className="border-0"
        placeholder={t(
          "inboxes.qa_experience.questions_set.template.search.placeholder",
        )}
      />
      <Separator className="my-0" />
      <div className="overflow-y-auto" style={{ maxHeight: templateMaxHeight }}>
        {matchingTemplates.map((template, index) => (
          <TooltipWrapper
            key={index}
            position="left"
            label={generateTooltipLabel(template.questions)}
          >
            <div
              className="flex items-center h-40 cursor-pointer hover:bg-grey-100"
              onClick={() => {
                onClose();
                onSelectTemplate(template);
              }}
            >
              <Icon name="fileText" className="ml-10 text-grey-400" />
              <div className="ml-8 text-grey-400">{template.title}</div>
              <div className="text-grey-300">
                {t("inboxes.qa_experience.questions_set.count", {
                  count: template.questions.length,
                })}
              </div>
            </div>
          </TooltipWrapper>
        ))}
      </div>
    </Popover>
  );
};

const generateTooltipLabel = (questions: string[]) => (
  <div>
    {questions.map((question, index) => (
      <div key={index}>・{question}</div>
    ))}
  </div>
);
