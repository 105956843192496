import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "components/Icon/Icon";
import { Popover, PopoverProps } from "components/Popover/Popover";
import {
  CreateDoctorConversation,
  DoctorSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { addDoctorConversationInCache } from "utils/apollo";

import { DoctorRow } from "./DoctorRow";

export const DoctorsListPopover = ({
  doctors,
  className,
  style,
  ...props
}: Omit<PopoverProps, "children"> & {
  doctors: DoctorSummaryFragment[];
}) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [createDoctorConversation, creating] = useMutation(
    CreateDoctorConversation,
  );

  const handleClick = (d: DoctorSummaryFragment) => {
    if (creating) return;
    return createDoctorConversation(
      { doctorUuids: [d.uuid] },
      {
        onSuccess: ({ experience }, client) => {
          addDoctorConversationInCache(client, experience);
          navigate(`${routes.CONVERSATION_BASE}/${experience.uuid}`);
        },
      },
    );
  };

  return (
    <Popover
      className={classNames("flex-col overflow-y-auto pb-0", className)}
      style={{ ...style, maxHeight: 400, width: 246 }}
      allowScrolling
      noArrow // Doesn't seem to work with overflow-auto
      {...props}
    >
      {doctors.map((doctor) => (
        <DoctorRow
          key={doctor.uuid}
          className="p-8 border-b cursor-pointer hover:bg-grey-100 active:bg-white last:pb-0 last:border-b-0"
          doctor={doctor}
          onClick={() => handleClick(doctor)}
        />
      ))}
      <Link
        to="/medical-team"
        className="text-primary p-8 flex items-center hover:bg-primary-100 active:text-primary-400"
        style={{ paddingLeft: 13, gap: 13 }}
      >
        <Icon name="people" />
        <div>{t("doctor.doctors_list_popover.see_all_doctors")}</div>
      </Link>
    </Popover>
  );
};
