import {
  AutoPagesOptions,
  usePDFPreview,
} from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { UploadInput } from "generated/provider";
import { FilePurpose, useUploadFile } from "graphql-client/useUploadFile";

export const useUploadPatientPdfFileForTimelineItem = (
  purpose: FilePurpose,
) => {
  const autoPagesOptions: AutoPagesOptions = { margin: [50, 50, 30, 50] };
  const { previewRef: pdfPreviewRef, generatePdf } =
    usePDFPreview(autoPagesOptions);
  const { patient } = usePatient();
  const getPdfName = (title: string) =>
    `${patient.firstName}_${patient.lastName}_${title}.pdf`;
  const pdfGenerator = (title: string) =>
    generatePdf().then((file) => ({
      file: new File([file], getPdfName(title), {
        type: "application/pdf",
      }),
    }));

  const uploadFile = useUploadFile();

  const uploadPatientPdfFile = async (
    title: string,
  ): Promise<UploadInput | null> => {
    const pdfFile = await pdfGenerator(title);
    return uploadFile({
      purpose,
      file: pdfFile.file,
    });
  };

  return {
    pdfPreviewRef,
    pdfGenerator,
    uploadPatientPdfFile,
  };
};
