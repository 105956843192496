import gql from "graphql-tag";

import { DoctorFilter } from "components/DoctorSelect/DoctorSelect";
import {
  AvailabilityOccurrenceFragment,
  AvailabilityOccurrencesInTimeRange,
  TimeRangeInput,
} from "generated/provider";
import { useQuery } from "graphql-client/useQuery";

import { availabilityOccurrenceToCalendarEvent } from "../types";

gql`
  query AvailabilityOccurrencesInTimeRange(
    $timeRange: TimeRangeInput!
    $filter: AvailabilitiesFilter
  ) {
    availabilityOccurrencesInTimeRange(timeRange: $timeRange, filter: $filter) {
      data {
        ...AvailabilityOccurrence
      }
    }
  }
`;

export const useAvailabilitiesInTimeRange = (
  page: TimeRangeInput,
  selectedFilter: DoctorFilter,
) => {
  const { data, loading, error } = useQuery(
    AvailabilityOccurrencesInTimeRange,
    {
      variables: {
        timeRange: page,
        filter: {
          doctorUuid: selectedFilter.uuid,
        },
      },
    },
  );

  const events =
    data?.data
      .filterNotNull()
      .filter((availabilityOccurrence) =>
        filterAvailabilityOccurrence(availabilityOccurrence, selectedFilter),
      )
      .map(availabilityOccurrenceToCalendarEvent) ?? [];

  return { events, loading, error };
};

const filterAvailabilityOccurrence = (
  availabilityOccurrence: AvailabilityOccurrenceFragment,
  filter: DoctorFilter,
) => {
  if (availabilityOccurrence.parent.doctor.uuid !== filter.uuid) return false;

  return true;
};
