import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import { HighlightText } from "components/HighlightTrext/HighlightText";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import {
  MessageContentSummaryFragment,
  MessageSenderType,
  UserSummaryFragment,
} from "generated/provider";
import { useTranslation } from "i18n";
import { IconName } from "icon-library";
import { displayMessageSender } from "utils/display";

export const MessageResultRow = ({
  message,
  showDate,
  search,
  onClick,
}: {
  message: {
    uuid: UUID;
    sender: UserSummaryFragment | null;
    content: MessageContentSummaryFragment;
    eventTime: ISOString;
    senderType: MessageSenderType;
  };
  showDate?: boolean;
  search: string | null;
  onClick: () => void;
}) => {
  const t = useTranslation();

  return (
    <Row
      key={message.uuid}
      icon={{
        name: "chat",
        iconClassName: "bg-orange-100 text-orange",
      }}
      title={displayMessageSender(message)}
      date={showDate ? message.eventTime : null}
      content={message.content.let((it) =>
        it.__typename === "TextMessageContent"
          ? it.text
          : t("inboxes.qa_side_panel.row.unsupported_message_format"),
      )}
      contentClassName="line-clamp-7"
      search={
        message.content.__typename === "TextMessageContent" && search
          ? { value: search }
          : null
      }
      onClick={onClick}
    />
  );
};

export const Row = ({
  icon,
  title,
  content,
  rightContent,
  contentClassName,
  date,
  search,
  onClick,
  disableWholeButton,
  rightIcon,
}: {
  icon: { name: IconName; iconClassName?: string; iconStyle?: CSSProperties };
  title?: string;
  content?: string;
  rightContent?: string;
  contentClassName?: string;
  date: ISOString | null;
  search: { value: string; includeTitle?: boolean } | null;
  onClick?: () => void;
  disableWholeButton?: boolean;
  rightIcon?: ReactNode;
}) => (
  <button
    className="w-full rounded-item action p-12 flex items-center group"
    onClick={onClick}
    disabled={disableWholeButton}
  >
    <div
      className={classNames(
        "rounded-full border border-white flex-center p-4",
        icon.iconClassName,
      )}
      style={icon.iconStyle}
    >
      <Icon name={icon.name} size={12} />
    </div>
    <div className="ml-8 flex-fill flex-col">
      <div className="flex items-center">
        {title && (
          <div className="text-primary-dark font-medium text-14 flex-fill">
            <HighlightText text={title} search={search?.value} />
          </div>
        )}
        <div className="flex items-center">
          {date && <div className="text-11 mr-12">{date.format("date")}</div>}
        </div>
      </div>
      <div className="flex items-center">
        {content && (
          <div
            className={classNames(
              "flex-fill text-14 whitespace-pre-wrap",
              contentClassName,
            )}
          >
            <HighlightText text={content} search={search?.value} />
          </div>
        )}
        {rightContent && <div className="ml-4 ">{rightContent}</div>}
      </div>
    </div>
    {rightIcon}
    {disableWholeButton ? (
      <ClickableIcon name="chevron" size={14} onClick={onClick} />
    ) : (
      <Icon name="chevron" size={14} />
    )}
  </button>
);
