import { useMatch } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { useDoctor } from "contexts/User/UserContext";
import {
  ExtendedQAInboxType,
  useExtendedQAInbox,
} from "hooks/useExtendedQAInbox";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { SidebarItem } from "types";
import { sectionQueryUrl } from "utils/routing";

import { InboxSidebarContent } from "../components/InboxSidebarContent";
import { NewQAExperience, QAExperience } from "../QAExperience/QAExperience";
import { QAInboxSidebarHeader } from "./QAInboxSidebarHeader";

const QA_EXPERIENCE_PATH = ":uuid";

export const QAInbox = () => {
  const t = useTranslation();
  const selectedExperienceUuid = useMatch(
    `${routes.QA_INBOX}/${QA_EXPERIENCE_PATH}`,
  )?.params.uuid as UUID | null;
  const { hasAccessToGatekeeperFeature } = useDoctor();

  const ITEMS: (Omit<
    SidebarItem,
    "useCount" | "Component" | "mobileStickOnSidebar"
  > & { type: ExtendedQAInboxType })[] = !hasAccessToGatekeeperFeature(
    "MESSAGE_INBOX",
  )
    ? [
        {
          to: routes.QA_UNASSIGNED,
          type: "UNASSIGNED",
          icon: "drawer",
          label: t("inboxes.qa_inbox.qa_inbox.unassigned"),
        },
        {
          to: routes.QA_ASSIGNED_TO_ME,
          type: "ASSIGNED_TO_ME",
          icon: "mine",
          label: t("inboxes.qa_inbox.qa_inbox.mine"),
        },
        {
          to: routes.QA_ASSIGNED,
          type: "ASSIGNED",
          icon: "tag",
          label: t("inboxes.qa_inbox.qa_inbox.assigned"),
          hasAccess: ({ hasPermission }) =>
            hasPermission("VIEW_ANY_QA_EXPERIENCE"),
        },
        {
          to: routes.QA_CLOSED,
          type: "CLOSED_QUESTIONS",
          icon: "archive",
          label: t("inboxes.qa_inbox.qa_inbox.closed"),
          hasAccess: ({ hasPermission }) =>
            hasPermission("VIEW_ANY_QA_EXPERIENCE"),
        },
      ]
    : [
        {
          to: routes.QA_ALL,
          type: "ALL",
          icon: "drawer",
          label: t("inboxes.qa_inbox.qa_inbox.all"),
          hasAccess: ({ hasPermission }) =>
            hasPermission("VIEW_ANY_QA_EXPERIENCE"),
          hidden: true,
        },
      ];

  return (
    <SidebarPage
      baseRoute={routes.QA_INBOX}
      title={t("inboxes.inbox_sidebar_content.title")}
      items={ITEMS.map((props) => ({
        ...props,
        Component: EmptyBackground,
        useCount: () => useExtendedQAInbox(props.type).totalCount,
        mobileStickOnSidebar: true,
      }))}
      otherRoutes={[
        { to: QA_EXPERIENCE_PATH, Component: QAExperience },
        { to: "/new/:patientUuid", Component: NewQAExperience },
      ]}
      hasSectionQueryParam
    >
      {(selectedItem) =>
        selectedItem ? (
          <>
            <QAInboxSidebarHeader type={selectedItem.type} />
            <InboxSidebarContent
              type={selectedItem.type}
              emptyLabel={t("inboxes.qa_inbox.qa_inbox.no_conversation")}
              selectedExperienceUuid={selectedExperienceUuid}
              experienceTo={(uuid) => ({
                pathname: uuid,
                search: sectionQueryUrl(selectedItem.to),
              })}
            />
          </>
        ) : null
      }
    </SidebarPage>
  );
};

const EmptyBackground = () => <Background className="flex-fill" />;
