import { useState } from "react";
import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { Select } from "components/Form/Select/Select";
import { Icon } from "components/Icon/Icon";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Table } from "components/Table/Table";
import { useUser } from "contexts/User/UserContext";
import {
  NoteSectionNormalizations,
  NoteSectionNormalizationsData,
  SupportedLocale,
  SupportedLocaleKnownValues,
} from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { displayDoctor } from "utils/display";
import { getLanguageFromLocale } from "utils/intl";

import { NewNoteNormalizationComposer } from "./AddNoteNormalizationComposer";
import { SectionType, sectionTypes, toTranslationKeyName } from "./utils";

gql`
  query NoteSectionNormalizations(
    $cursor: TimeAndUuidCursorInput
    $filter: NoteSectionNormalizationsFilter
  ) {
    noteSectionNormalizations(filter: $filter) {
      noteSectionNormalizations(page: { cursor: $cursor, numberOfItems: 40 }) {
        data {
          ...NoteSectionNormalization
        }
        hasMore
        nextCursor {
          fromTime
          fromUuid
        }
      }
      totalCount
    }
  }
`;

const NormalizedSectionHeaders = [
  "DATE",
  "NAME",
  "LANGUAGE",
  "IS_ANNOTATED",
  "ANNOTATOR",
] as const;

type NoteSectionNormalization =
  NoteSectionNormalizationsData["noteSectionNormalizations"]["data"][0];

export const NoteNormalizationTable = () => {
  const [labeledFilter, setLabeledFilter] = useState<boolean | null>(null);
  const [sectionTypeFilter, setSectionTypeFilter] =
    useState<SectionType | null>(null);
  const [localeFilter, setLocaleFilter] = useState<SupportedLocale | null>(
    null,
  );

  const [composerOpened, setComposerOpened] = useState<boolean>(false);

  const { hasRole } = useUser();

  const t = useTranslation();

  const queryVariables = {
    filter: {
      labeled: labeledFilter,
      sectionType: sectionTypeFilter,
      locale: localeFilter,
    },
  };

  return (
    <div className="flex-col flex-fill overflow-auto bg-light-blue">
      <PaginatedQuery
        query={NoteSectionNormalizations}
        variables={queryVariables}
        selector={(d) => d.noteSectionNormalizations}
      >
        {(noteSectionNormalizationData, utils) => (
          <>
            {composerOpened && (
              <NewNoteNormalizationComposer
                onHide={() => setComposerOpened(false)}
                queryVariables={queryVariables}
              />
            )}
            <div className="flex-col flex-fill p-32">
              <div className="lg:flex sm:flex-col sm:space-y-32 items-center sm:items-start mb-32 justify-between w-full">
                <div className="flex-col">
                  <h1 className="text-primary-dark text-24 font-bold">
                    {t("note_normalization.note_sections")}
                  </h1>
                  <div>
                    {noteSectionNormalizationData.totalCount}{" "}
                    {t("note_normalization.sections")}
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-end mb-12">
                <div className="flex space-x-8">
                  <Select<boolean | null>
                    name="labeled"
                    label={t("note_normalization.annotated")}
                    wrapperClassName="w-[250px]"
                    value={labeledFilter}
                    getOptionLabel={(option) => {
                      switch (option) {
                        case null:
                          return t(
                            "note_normalization_table.filter_labeled_all",
                          );
                        case false:
                          return t(
                            "note_normalization_table.filter_not_labeled",
                          );
                        case true:
                          return t("note_normalization_table.filter_labeled");
                      }
                    }}
                    options={[null, false, true]}
                    onChange={setLabeledFilter}
                  />
                  <Select<SectionType | null>
                    name="sectionType"
                    label={t("note_normalization.section_name")}
                    wrapperClassName="w-[250px]"
                    value={sectionTypeFilter}
                    options={[null, ...sectionTypes]}
                    getOptionLabel={(option) => {
                      if (option === null) {
                        return t(
                          "note_normalization_table.filter_section_type_all",
                        );
                      }
                      return toTranslationKeyName(t, option);
                    }}
                    onChange={setSectionTypeFilter}
                  />
                  <Select<SupportedLocale | null>
                    name="locale"
                    label={t("note_normalization.language_table")}
                    wrapperClassName="w-[250px]"
                    value={localeFilter}
                    options={[null, ...SupportedLocaleKnownValues]}
                    getOptionLabel={(option) => {
                      if (option === null) {
                        return t("note_normalization_table.filter_locale_all");
                      }
                      return getLanguageFromLocale(option);
                    }}
                    onChange={setLocaleFilter}
                  />
                </div>
                {hasRole("NABLA_ADMINISTRATOR") && (
                  <Button
                    label={t(
                      "add_note_normalization_composer.add_note_normalization",
                    )}
                    onClick={() => setComposerOpened(true)}
                  />
                )}
              </div>
              <Table<
                typeof NormalizedSectionHeaders[number],
                NoteSectionNormalization
              >
                elements={
                  noteSectionNormalizationData.noteSectionNormalizations.data
                }
                emptyPlaceholder={
                  <div className="text-18 flex items-center text-primary-dark p-24 justify-center text-center h-[180px]">
                    {t("recorded_conversations.placeholder_table")}
                  </div>
                }
                doRowAction={(fragment) => ({
                  mode: "navigate",
                  to: `/${routes.NOTE_NORMALIZATION}/${fragment.uuid}`,
                })}
                fieldHeaders={NormalizedSectionHeaders.map(
                  (it) =>
                    ({
                      DATE: t("note_normalization.date"),
                      NAME: t("note_normalization.section_name"),
                      LANGUAGE: t("note_normalization.language_table"),
                      IS_ANNOTATED: t("note_normalization.annotated"),
                      ANNOTATOR: t("note_normalization.annotator"),
                    }[it]),
                )}
                fields={(fragment) => [
                  fragment.createdAt.format("file"),
                  toTranslationKeyName(t, fragment.type),
                  getLanguageFromLocale(fragment.locale),
                  <Icon
                    key="annotated"
                    className={
                      fragment.isLabeled
                        ? "text-success"
                        : fragment.labeler
                        ? "text-yellow"
                        : "text-danger"
                    }
                    name={
                      fragment.isLabeled
                        ? "check"
                        : fragment.labeler
                        ? "change"
                        : "close"
                    }
                  />,
                  fragment.labeler ? displayDoctor(fragment.labeler) : "-",
                ]}
              />
              <div className="flex-center">
                <NextPageButton {...utils} />
              </div>
            </div>
          </>
        )}
      </PaginatedQuery>
    </div>
  );
};
