import { ReactNode, RefObject } from "react";

import { Submit } from "components/Button/Submit";
import { SubmitWithMenu } from "components/Button/SubmitWithMenu";
import { SingleChoicePills } from "components/Form/Form/FormSingleChoicePills";
import { MenuItemProps } from "components/Menu/MenuItem";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useTranslation } from "i18n";
import { EHRInputType } from "types";

import { useEHRInput } from "./useEHRInput";

export const FooterWrapper = ({
  submitLabel,
  submitMenuItems,
  children,
  danger,
  disabled,
  inputRef,
}: {
  submitLabel: string;
  submitMenuItems?: MenuItemProps[];
  children?: ReactNode;
  danger?: boolean;
  disabled?: boolean;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { ehrComposerState } = usePatient();
  const { ehrInput, setEHRInput } = useEHRInput();
  const orderedInputTypes: EHRInputType[] = [
    "NOTE",
    "ALLERGY",
    "MEDICATION",
    "SURGICAL",
    "MEDICAL",
    "FAMILY",
    "VACCINE",
    "SYMPTOM",
    "DATA_POINT",
    "DOCUMENT",
    "TASK",
  ];
  const isCreatingInExperience =
    ehrComposerState?.mode === "create" &&
    ehrComposerState.submit.to === "experience";

  return (
    <div className="pt-16">
      {isCreatingInExperience && ehrInput.type && (
        <SingleChoicePills<EHRInputType>
          className="flex-wrap gap-10"
          options={orderedInputTypes}
          currentValue={ehrInput.type}
          onChange={(o) => {
            setEHRInput({ type: o });
            inputRef.current?.focus();
          }}
          getOptionLabel={(o) =>
            ({
              NOTE: t("inboxes.qa_experience.ehr_composer.footer_wrapper.note"),
              ALLERGY: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.allergy",
              ),
              MEDICATION: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.medication",
              ),
              SURGICAL: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.surgical_history",
              ),
              MEDICAL: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.medical_history",
              ),
              VACCINE: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.vaccine",
              ),
              SYMPTOM: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.symptom",
              ),
              FAMILY: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.family_history",
              ),
              DATA_POINT: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.measure",
              ),
              DOCUMENT: t(
                "inboxes.qa_experience.ehr_composer.footer_wrapper.document",
              ),
              TASK: t("inboxes.qa_experience.ehr_composer.footer_wrapper.task"),
            }[o])
          }
        />
      )}
      <div className="mt-16 border-t pt-16 flex items-center">
        <div className="flex-fill flex items-center space-x-16">{children}</div>
        {submitMenuItems ? (
          <SubmitWithMenu
            bold
            label={submitLabel}
            wrapperClassName="ml-16 h-40"
            menuItems={submitMenuItems}
          />
        ) : (
          <Submit
            label={submitLabel}
            className="ml-16"
            danger={danger}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
