import { FunctionComponent } from "react";

import {
  PatientTimelineKnownItemTypes,
  PatientTimelineValidItemFragmentOfType,
} from "types/patient-timeline";

import { AperoBillItem, NewAperoBillItem } from "./AperoBillItem";
import { AppointmentItem } from "./AppointmentItem/AppointmentItem";
import { BravadoPrescriptionItem } from "./BravadoPrescriptionItem";
import { ExperienceItem } from "./ExperienceItem";
import { MedicalOrderItem, NewMedicalOrderItem } from "./MedicalOrderItem";
import {
  NablaPrescriptionItem,
  NewNablaPrescriptionItem,
} from "./NablaPrescriptionItem";
import {
  NewPatientDocumentItem,
  PatientDocumentItem,
} from "./PatientDocumentItem";
import { NewPatientNoteItem, PatientNoteItem } from "./PatientNoteItem";
import { PhotonPrescriptionItem } from "./PhotonPrescriptionItem";
import { QuestionnaireResponseItem } from "./QuestionnaireResponseItem";
import { NewTaskItem, TaskItem } from "./TaskItem";
import { PatientTimelineNewItemPayload } from "./types";

/**
 * Mapping of type names to components for existing timeline items.
 */
const PatientTimelineExistingItemComponents: {
  [T in PatientTimelineKnownItemTypes]: PatientTimelineExistingItemComponentOfType<T>;
} = {
  AperoBill: AperoBillItem,
  Appointment: AppointmentItem,
  Experience: ExperienceItem,
  PatientDocument: PatientDocumentItem,
  PatientNote: PatientNoteItem,
  QuestionnaireResponse: QuestionnaireResponseItem,
  Task: TaskItem,
  MedicalOrder: MedicalOrderItem,
  NablaPrescription: NablaPrescriptionItem,
  BravadoPrescription: BravadoPrescriptionItem,
  PhotonPrescription: PhotonPrescriptionItem,
};

/**
 * Mapping of type names to components for new timeline items.
 */
const PatientTimelineNewItemComponents: {
  [T in keyof PatientTimelineNewItemPayload]: PatientTimelineNewItemComponentOfType<T>;
} = {
  AperoBill: NewAperoBillItem,
  PatientNote: NewPatientNoteItem,
  PatientDocument: NewPatientDocumentItem,
  NablaPrescription: NewNablaPrescriptionItem,
  Task: NewTaskItem,
  MedicalOrder: NewMedicalOrderItem,
};

// Helper types.

type PatientTimelineExistingItemComponentOfType<
  T extends PatientTimelineKnownItemTypes,
> = FunctionComponent<{
  item: PatientTimelineValidItemFragmentOfType<T>;
}>;

type PatientTimelineNewItemComponentOfType<
  T extends keyof PatientTimelineNewItemPayload,
> = FunctionComponent<
  {
    temporaryUuid: UUID;
  } & PatientTimelineNewItemPayload[T]
>;

// Helper components.

export const PatientTimelineExistingItem = <
  T extends PatientTimelineKnownItemTypes,
>({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<T>;
}) => {
  const type: T = item.__typename;
  const ItemComponent: PatientTimelineExistingItemComponentOfType<T> =
    PatientTimelineExistingItemComponents[type];
  return <ItemComponent item={item} />;
};

export const PatientTimelineNewItem = <
  T extends keyof PatientTimelineNewItemPayload,
>({
  type,
  temporaryUuid,
  payload,
}: {
  type: T;
  temporaryUuid: UUID;
  payload: PatientTimelineNewItemPayload[T];
}) => {
  const ItemComponent: PatientTimelineNewItemComponentOfType<T> =
    PatientTimelineNewItemComponents[type];
  return (
    // @ts-ignore
    <ItemComponent temporaryUuid={temporaryUuid} {...payload} />
  );
};
