import { BaseSyntheticEvent, useState } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { Icon } from "components/Icon/Icon";
import { Popover, PopoverPosition } from "components/Popover/Popover";
import { useDoctor } from "contexts/User/UserContext";
import { AllDoctors, DoctorSummaryFragment } from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import { useSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";

export const AssignmentPopover = ({
  target,
  setTarget,
  assignedDoctors,
  assign,
  multiline = true,
  position = "bottom-right",
}: {
  target: Element;
  setTarget: (e: BaseSyntheticEvent<any, Element> | undefined) => void;
  assignedDoctors: DoctorSummaryFragment[];
  assign: (doctorUuids: UUID[]) => void;
  multiline?: boolean;
  position?: PopoverPosition;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();

  const { data: allDoctorsData } = useQuery(AllDoctors, {
    variables: { filter: { permissions: ["ANSWER_QA_EXPERIENCE"] } },
  });

  const inputProps = useSearch();

  const allDoctors = (allDoctorsData?.doctors ?? [])
    .bumped(({ uuid }) => uuid === user.uuid)
    .bumped(({ uuid }) =>
      assignedDoctors
        .map((assignedDoctor) => assignedDoctor.uuid)
        .includes(uuid),
    );

  const matchingDoctors = allDoctors.filter((d) =>
    displayDoctor(d).fuzzyMatch(inputProps.value),
  );

  const [selectedDoctorUuids, setSelectedDoctorUuids] = useState(
    assignedDoctors.map((doctor) => doctor.uuid),
  );

  const assignAndClose = (doctorUuids: UUID[]) => {
    if (
      !doctorUuids
        .immutableSort()
        .equals(assignedDoctors.map((doctor) => doctor.uuid).immutableSort())
    ) {
      assign(doctorUuids);
    }
  };

  return (
    <Popover
      target={target}
      position={position}
      className="mt-[6px] flex-col overflow-hidden max-h-[min(500px,80%)]"
      fullWidthOnMobile
      allowScrolling
      onClose={() => {
        setTarget(undefined);
        if (multiline) assignAndClose(selectedDoctorUuids);
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") setTarget(undefined);
      }}
    >
      <div className="relative flex items-center">
        <Icon name="search" className="absolute left-8" />
        <input
          {...inputProps}
          autoFocus
          className="w-full h-40 pl-36 pr-8"
          placeholder={t(
            "inboxes.qa_experience.qa_experience_header.tags_menu.search",
          )}
          spellCheck={false}
          autoComplete="off"
        />
      </div>
      <div className="flex-fill overflow-auto border-t">
        {matchingDoctors.isNotEmpty() ? (
          <div className="flex-col">
            {matchingDoctors.map((doctor) => (
              <DoctorRow
                key={doctor.uuid}
                doctor={doctor}
                selected={selectedDoctorUuids.includes(doctor.uuid)}
                onClick={() => {
                  if (multiline) {
                    setSelectedDoctorUuids(
                      selectedDoctorUuids.toggle(doctor.uuid),
                    );
                  } else {
                    setTarget(undefined);
                    assignAndClose(
                      !selectedDoctorUuids.includes(doctor.uuid)
                        ? [doctor.uuid]
                        : [],
                    );
                  }
                }}
              />
            ))}
          </div>
        ) : (
          <div className="p-12 text-14 opacity-70">
            {t(
              "inboxes.qa_experience.qa_experience_header.tags_menu.no_result",
            )}
          </div>
        )}
      </div>
    </Popover>
  );
};

const DoctorRow = ({
  doctor,
  selected,
  onClick,
}: {
  doctor: DoctorSummaryFragment;
  selected: boolean;
  onClick: () => void;
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      "flex items-center space-x-8 text-14 p-8 hover:bg-grey-100 text-grey-300 h-40",
      selected && "bg-grey-200/50 font-medium",
    )}
  >
    <Avatar size={24} user={doctor} />
    <div className="flex-fill">{displayDoctor(doctor)}</div>
    {selected && <Icon name="close" size={16} />}
  </button>
);
