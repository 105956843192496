import { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Maybe } from "base-types";
import { Avatar } from "components/Avatar/Avatar";
import { NextPageButton } from "components/Button/NextPageButton";
import { MenuItemProps } from "components/Menu/MenuItem";
import {
  ControlledConfirmationModal,
  ControlledConfirmationModalProps,
} from "components/Modal/ControlledConfirmationModal";
import { CreateScheduledWebCallModal } from "components/Patient/CreateScheduledWebCallModal";
import { CreateUnscheduledWebCallModal } from "components/Patient/CreateUnscheduledWebCallModal";
import { PatientViewNavigationState } from "components/Patient/utils";
import { RowList } from "components/RowList/RowList";
import { Spinner } from "components/Spinner/Spinner";
import { useDoctor } from "contexts/User/UserContext";
import {
  PatientManagementPatientFragment,
  PatientManagementSearch,
  SexKnownValues,
} from "generated/provider";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { getNewQAExperienceRoute, getPatientViewRoute } from "utils";
import { displayAge, displayPatient, displaySex } from "utils/display";
import { isKnownValue } from "utils/enum";
import {
  canOpenOrganizationExternalConsole,
  tryToOpenOrganizationExternalConsole,
} from "utils/externalNavigation";

import { PatientAperoForm } from "./PatientAperoForm";
import { PatientComposer } from "./PatientComposer";

type ComposerState = { mode: "create" } | { mode: "edit"; patientUuid: UUID };
type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide">
  | undefined;
type CreateScheduledWebCallModalState = { patientUuid: UUID } | undefined;
type CreateUnscheduledWebCallModalState = { patientUuid: UUID } | undefined;

type AperoDetailsModalState =
  | { patient: PatientManagementPatientFragment }
  | undefined;

const PatientRow = (patient: PatientManagementPatientFragment) => {
  const { sex, birthDate } = patient;
  const patientInformations: string[] = (
    isKnownValue(sex, SexKnownValues) ? [displaySex(sex)] : []
  ).concat(birthDate ? [displayAge(birthDate)] : []);

  return (
    <div
      key="avatar"
      className="flex flex-fill items-center pl-16 lg:pl-44 py-10 leading-[20px] space-x-12"
    >
      <Avatar size={36} user={patient} />
      <div className="flex-col flex-fill">
        <div className="text-primary-dark truncate">
          {displayPatient(patient)}
        </div>
        {patientInformations.isNotEmpty() && (
          <div className="text-12 truncate">
            {patientInformations.join(", ")}
          </div>
        )}
      </div>
    </div>
  );
};

export const ProviderPatientsList = ({
  isPatientAdded,
  onPatientAdded,
  search,
}: {
  isPatientAdded: boolean;
  onPatientAdded: () => void;
  search: string | undefined;
}) => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  const { user, hasPermission } = useDoctor();
  const canAccessQAExperience = hasPermission("VIEW_ANY_QA_EXPERIENCE");
  const canViewAppointments = hasPermission("VIEW_APPOINTMENTS");

  const {
    data: patientsData,
    loading,
    nextPage,
    fetchingMore,
  } = usePaginatedQuery(PatientManagementSearch, {
    variables: {
      search,
      withExperiences: canAccessQAExperience,
      withAppointments: canViewAppointments,
    },
    selector: (data) => data.patients,
  });
  const [composerState, setComposerState] = useState<ComposerState>();
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>();
  const [
    createScheduledWebCallModalState,
    setCreateScheduledWebCallModalState,
  ] = useState<CreateScheduledWebCallModalState>();
  const [
    createUnscheduledWebCallModalState,
    setCreateUnscheduledWebCallModalState,
  ] = useState<CreateUnscheduledWebCallModalState>();

  const [aperoDetailsModalState, setAperoDetailsModalState] =
    useState<AperoDetailsModalState>(undefined);

  return (
    <>
      <div className="px-16 lg:px-44 bg-white pb-24 border-b">
        {patientsData &&
          `${patientsData.totalCount} ${t("patients.patients.patients")}`}
      </div>
      {composerState && (
        <PatientComposer
          patient={
            composerState.mode === "edit"
              ? patientsData?.patients.data.find(
                  (patient) => patient.uuid === composerState.patientUuid,
                )
              : undefined
          }
          onHide={() => setComposerState(undefined)}
        />
      )}
      {isPatientAdded && (
        <PatientComposer patient={undefined} onHide={onPatientAdded} />
      )}
      {confirmationModalState && (
        <ControlledConfirmationModal
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      {createScheduledWebCallModalState && (
        <CreateScheduledWebCallModal
          forcedPatientUuid={createScheduledWebCallModalState.patientUuid}
          onHide={() => setCreateScheduledWebCallModalState(undefined)}
        />
      )}
      {createUnscheduledWebCallModalState && (
        <CreateUnscheduledWebCallModal
          patientUuid={createUnscheduledWebCallModalState.patientUuid}
          onHide={() => setCreateUnscheduledWebCallModalState(undefined)}
        />
      )}
      {aperoDetailsModalState && (
        <PatientAperoForm
          patient={aperoDetailsModalState.patient}
          onHide={() => setAperoDetailsModalState(undefined)}
        />
      )}
      <div className={classNames("flex", { "flex-fill p-16": loading })}>
        {loading ? (
          <Spinner fill />
        ) : patientsData ? (
          <RowList
            elements={patientsData.patients.data}
            field={PatientRow}
            menuItems={(patient) => {
              const isSdkPatient = patient.devices.some(
                (device) => device.os === "ANDROID" || device.os === "IOS",
              );
              const cannotStartConversation =
                patient.qaExperiences.data.length >= 1 &&
                user.subOrganization.organization.singleConversationEnabled;

              const editOption: MenuItemProps = {
                icon: "edit",
                text: t("patients.patients.edit"),
                onClick: (close: () => void) => {
                  close();
                  setComposerState({ mode: "edit", patientUuid: patient.uuid });
                },
              };
              const externalConsoleUrl =
                user.subOrganization.externalConsoleUrl;
              const linkToOrgBackOffice: Maybe<MenuItemProps> =
                externalConsoleUrl
                  ? {
                      icon: "shareLight",
                      text: t("patients.open_back_office", {
                        name: user.subOrganization.organization.displayName,
                      }),
                      disable: {
                        if: !canOpenOrganizationExternalConsole(
                          externalConsoleUrl,
                          patient,
                        ),
                        tooltip: t("patients.missing_external_id"),
                      },
                      onClick: () =>
                        tryToOpenOrganizationExternalConsole(
                          externalConsoleUrl,
                          patient,
                        ),
                    }
                  : null;
              const createScheduledWebCallOption: Maybe<MenuItemProps> =
                hasPermission("CREATE_AND_SCHEDULE_WEB_CALLS")
                  ? {
                      icon: "videoOn",
                      text: t("patients.schedule_a_video_appointment"),
                      onClick: (close: () => void) => {
                        setCreateScheduledWebCallModalState({
                          patientUuid: patient.uuid,
                        });
                        close();
                      },
                    }
                  : null;
              const createUnscheduledWebCallOption: Maybe<MenuItemProps> =
                hasPermission("CREATE_AND_SCHEDULE_WEB_CALLS")
                  ? {
                      icon: "calendar",
                      text: t("patients.generate_a_video_call_scheduling_link"),
                      disable: {
                        if: isSdkPatient,
                        tooltip: t("patients.patients.schedule_unavailable"),
                      },
                      onClick: (close: () => void) => {
                        setCreateUnscheduledWebCallModalState({
                          patientUuid: patient.uuid,
                        });
                        close();
                      },
                    }
                  : null;
              const createQAExperienceOption: Maybe<MenuItemProps> =
                hasPermission("CONTACT_FREE_PATIENTS")
                  ? {
                      icon: "chat",
                      text: t("patients.start_a_chat"),
                      disable: {
                        if: cannotStartConversation,
                        tooltip: t("patients.patients.chat_unavailable_limit"),
                      },
                      onClick: (close: () => void) => {
                        if (isDesktop) {
                          navigate(getPatientViewRoute(patient), {
                            state: {
                              openNewChatWidget: true,
                            } satisfies PatientViewNavigationState,
                          });
                        } else {
                          navigate(getNewQAExperienceRoute(patient));
                        }
                        close();
                      },
                    }
                  : null;

              const addAperoDetails: Maybe<MenuItemProps> = user.subOrganization
                .supportsApero
                ? {
                    icon: "dollar",
                    text: t("patients.add_apero_details"),
                    onClick: (close: () => void) => {
                      close();
                      const maybePatient = patientsData.patients.data.find(
                        (p) => p.uuid === patient.uuid,
                      );
                      maybePatient &&
                        setAperoDetailsModalState({ patient: maybePatient });
                    },
                  }
                : null;

              return [
                linkToOrgBackOffice,
                editOption,
                createScheduledWebCallOption,
                createUnscheduledWebCallOption,
                createQAExperienceOption,
                addAperoDetails,
              ].filterNotNull();
            }}
            navigateTo={(patient) =>
              isDesktop ? getPatientViewRoute(patient) : null
            }
          />
        ) : null}
      </div>
      <div className="pl-16 lg:pl-44">
        <NextPageButton nextPage={nextPage} fetchingMore={fetchingMore} />
      </div>
    </>
  );
};
