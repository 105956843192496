import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { NotFound } from "components/ErrorPage/NotFound";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { AsyncDoctorFormMultiSelect } from "components/Form/Select/AsyncDoctorFormMultiSelect";
import { AsyncFormSelect } from "components/Form/Select/AsyncFormSelect";
import { MoreMenu } from "components/Menu/MoreMenu";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Query } from "components/Query/Query";
import { useUser } from "contexts/User/UserContext";
import {
  AppointmentCategories,
  AppointmentCategoryFragment,
  AppointmentTypeFragment,
  AppointmentTypes,
  CreateAppointmentCategory,
  DeleteAppointmentCategory,
  DoctorSummaryFragment,
  UpdateAppointmentCategory,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { copyToClipBoard } from "utils";
import {
  addAppointmentCategoryInCache,
  removeAppointmentCategoryInCache,
} from "utils/apollo";
import { notifier } from "utils/notifier";

gql`
  query AppointmentCategories {
    appointmentCategories {
      ...AppointmentCategory
    }
  }

  mutation CreateAppointmentCategory(
    $appointmentCategoryInput: AppointmentCategoryInput!
  ) {
    createAppointmentCategory(input: $appointmentCategoryInput) {
      category {
        ...AppointmentCategory
      }
    }
  }

  mutation UpdateAppointmentCategory(
    $uuid: UUID!
    $appointmentCategoryInput: AppointmentCategoryInput!
  ) {
    updateAppointmentCategory(uuid: $uuid, input: $appointmentCategoryInput) {
      category {
        ...AppointmentCategory
      }
    }
  }

  mutation DeleteAppointmentCategory($uuid: UUID!) {
    deleteAppointmentCategory(uuid: $uuid) {
      categoryUuid
    }
  }
`;

type FormValues = {
  appointmentType: AppointmentTypeFragment | undefined;
  doctors: DoctorSummaryFragment[];
};

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

export const SchedulingSettingsCategories = ({
  newCategory,
  onHideNewCategory,
}: {
  newCategory: boolean;
  onHideNewCategory: () => void;
}) => {
  const { hasPermission } = useUser();

  if (!hasPermission("EDIT_APPOINTMENT_CATEGORIES")) return <NotFound />;

  return (
    <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
      {newCategory && (
        <AppointmentCategoryForm
          appointmentCategory={undefined}
          onHide={onHideNewCategory}
        />
      )}
      <Query query={AppointmentCategories}>
        {(categories) =>
          categories.isEmpty() && !newCategory ? (
            <EmptyCategoriesPlaceholder />
          ) : (
            <>
              {categories.map((appointmentCategory) => (
                <AppointmentCategoryForm
                  key={appointmentCategory.uuid}
                  appointmentCategory={appointmentCategory}
                  onHide={onHideNewCategory}
                />
              ))}
            </>
          )
        }
      </Query>
    </Background>
  );
};

const EmptyCategoriesPlaceholder = () => {
  const t = useTranslation();

  return (
    <div className="flex-col w-full flex-center space-y-4 my-80">
      <div className="text-primary-dark font-bold text-18">
        {t("settings.scheduling.categories.empty_state_title")}
      </div>
      <div>
        <Query query={AppointmentTypes}>
          {(types) =>
            types.isNotEmpty() ? (
              <div>
                {t(
                  "settings.scheduling.categories.empty_state_subtitle.has_types",
                )}
              </div>
            ) : (
              <div className="flex-col w-full flex-center space-y-4">
                {t(
                  "settings.scheduling.categories.empty_state_subtitle.no_types",
                )}
                <Link
                  className="underline font-semibold"
                  to={`${routes.SETTINGS}/${routes.SCHEDULING_SETTINGS}/${routes.SCHEDULING_SETTINGS_TYPES}`}
                >
                  {t(
                    "settings.scheduling.categories.empty_state_subtitle.no_types.you_can_create_one_here",
                  )}
                </Link>
              </div>
            )
          }
        </Query>
      </div>
    </div>
  );
};

const AppointmentCategoryForm = ({
  appointmentCategory,
  onHide,
}: {
  appointmentCategory?: AppointmentCategoryFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();

  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>(undefined);

  const [createAppointmentCategory, createAppointmentCategoryLoading] =
    useMutation(CreateAppointmentCategory, {
      onSuccess: (output, client) => {
        addAppointmentCategoryInCache(client, output.category);
        onHide();
        notifier.success(t("settings.scheduling.categories.created_notifier"));
      },
    });

  const [updateAppointmentCategory, updateAppointmentCategoryLoading] =
    useMutation(UpdateAppointmentCategory, {
      onSuccess: () =>
        notifier.success(t("settings.scheduling.categories.updated_notifier")),
    });

  const [deleteAppointmentCategory] = useMutation(DeleteAppointmentCategory, {
    onSuccess: (output, client) => {
      removeAppointmentCategoryInCache(client, output.categoryUuid);
      notifier.success(t("settings.scheduling.categories.deleted_notifier"));
    },
  });

  return (
    <>
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t(
            "settings.scheduling.categories.delete_appointment_category_title",
          )}
          suffix={t(
            "settings.scheduling.categories.delete_appointment_category_suffix",
          )}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <Form<FormValues>
        className="flex-fill bg-white rounded border"
        initialValues={{
          appointmentType: appointmentCategory?.appointmentType,
          doctors: appointmentCategory?.doctors ?? [],
        }}
        validationSchema={{
          appointmentType: "required",
          doctors: Yup.array().min(
            1,
            t("settings.scheduling.categories.doctors_validation"),
          ),
        }}
        onSubmit={(values) => {
          if (values.appointmentType === undefined) return;

          appointmentCategory === undefined
            ? createAppointmentCategory({
                appointmentCategoryInput: {
                  appointmentTypeUuid: values.appointmentType.uuid,
                  doctorUuids: values.doctors.map((d) => d.uuid),
                },
              })
            : updateAppointmentCategory({
                uuid: appointmentCategory.uuid,
                appointmentCategoryInput: {
                  appointmentTypeUuid: values.appointmentType.uuid,
                  doctorUuids: values.doctors.map((d) => d.uuid),
                },
              });
        }}
      >
        <MoreMenu
          className={classNames(
            "ml-auto rounded p-8 hover:bg-grey-100 mt-4 mr-4",
            appointmentCategory === undefined && "invisible",
          )}
          position="left"
          items={[
            {
              icon: "clipboard",
              text: t("settings.scheduling.categories.copy_id_label"),
              onClick: (close) => {
                close();
                if (appointmentCategory === undefined) return;
                copyToClipBoard(
                  appointmentCategory.uuid,
                  t("settings.scheduling.categories.copy_id_success"),
                );
              },
            },
          ]}
        />
        <div className="flex-col space-y-20 p-32 pt-0">
          <div className="flex items-center space-x-24">
            <AsyncFormSelect
              selectProps={{
                name: "appointmentType",
                wrapperClassName: "flex-fill justify-between h-full",
                label: t(
                  "settings.scheduling.categories.associated_type_label",
                ),
                getOptionLabel: (a: AppointmentTypeFragment) => a.name,
                placeholder: "",
              }}
              queryProps={{ query: AppointmentTypes }}
              getOptions={(data) => data ?? []}
              getVariables={() => ({})}
            />
            <AsyncDoctorFormMultiSelect
              wrapperClassName="flex-shrink-0 flex-grow basis-auto justify-between w-2/3 h-full"
              name="doctors"
              label={t("settings.scheduling.categories.form_label_doctors")}
              getVariables={(s) => ({
                filter: {
                  permissions: ["ANSWER_QA_EXPERIENCE"], // TODO use CAN_MAKE_APPOINTMENTS permission instead
                  freeTextSearch: s,
                },
              })}
            />
          </div>
          <div className="flex justify-between">
            <Button
              label={t("settings.scheduling.categories.form_delete")}
              danger
              onClick={() => {
                appointmentCategory === undefined
                  ? onHide()
                  : setConfirmationModalState({
                      onConfirm: (close) => {
                        close();
                        return deleteAppointmentCategory({
                          uuid: appointmentCategory.uuid,
                        });
                      },
                    });
              }}
            />
            <FormState<FormValues>>
              {({ values }) => (
                <Submit
                  label={t("settings.scheduling.categories.form_save")}
                  loading={
                    createAppointmentCategoryLoading ||
                    updateAppointmentCategoryLoading
                  }
                  disabled={
                    appointmentCategory !== undefined &&
                    appointmentCategory.appointmentType.uuid ===
                      values.appointmentType?.uuid &&
                    appointmentCategory.doctors
                      .map((d) => d.uuid)
                      .toString() ===
                      values.doctors.map((d) => d.uuid).toString()
                  }
                />
              )}
            </FormState>
          </div>
        </div>
      </Form>
    </>
  );
};
