import { RefObject } from "react";

import { NoteSectionsViewer } from "components/Form/Editor/NoteSections/NoteSectionsViewer";
import { AutoPagesOptions } from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  DoctorSummaryFragment,
  PatientNoteFragment,
  SexKnownValues,
  SubOrganizationSummaryFragment,
  SupportedLocaleKnownValues,
  UserPersonalInformationFragment,
} from "generated/provider";
import { useTranslation } from "i18n";
import { isKnownUnionValue } from "utils/apollo";
import {
  displayAge,
  displayDoctor,
  displayPhoneNumber,
  displaySex,
} from "utils/display";
import { isKnownValue } from "utils/enum";

import { BaseItemPdfPreview, Section } from "./BaseItemPdfPreview";

type Theme = "FAX" | "PDF";

export const NotesExportPreview = ({
  theme,
  user,
  notes,
  comment,
  previewRef,
  autoPagesOptions,
  className,
}: {
  theme: Theme;
  user: DoctorSummaryFragment &
    Omit<UserPersonalInformationFragment, "subOrganization"> & {
      subOrganization: SubOrganizationSummaryFragment & {
        locale: string;
        avatarUrl?: string | null;
      };
    };
  notes: PatientNoteFragment[];
  comment?: string;
  previewRef: RefObject<HTMLDivElement>;
  autoPagesOptions: AutoPagesOptions;
  className?: string;
}) => {
  const t = useTranslation();
  const { patient } = usePatient();

  const locale = user.subOrganization.locale;
  if (!isKnownValue(locale, SupportedLocaleKnownValues)) return null;

  const text = (en: string, fr: string, pt: string) =>
    ({ ENGLISH: en, FRENCH: fr, PORTUGUESE: pt }[locale]);

  return (
    <BaseItemPdfPreview
      previewRef={previewRef}
      autoPagesOptions={autoPagesOptions}
      theme={theme}
      user={user}
      className={className}
    >
      {comment && (
        <Section
          theme={theme}
          className="mt-24"
          title={t("pdf_preview.fax_comment")}
        >
          <div>{comment}</div>
        </Section>
      )}
      <Section
        theme={theme}
        className="mt-24"
        title={t("pdf_preview.patient_details")}
      >
        <div className="font-medium">
          {patient.firstName} {patient.lastName}
        </div>
        <div className="text-10">
          {patient.sex &&
            isKnownValue(patient.sex, SexKnownValues) &&
            displaySex(patient.sex)}
          {patient.birthDate && displayAge(patient.birthDate)}
        </div>
        {patient.phoneV2 && (
          <div className="text-10">{displayPhoneNumber(patient.phoneV2)}</div>
        )}
        {patient.email && <div className="text-10">{patient.email}</div>}
        {patient.address && patient.city && patient.postcode && (
          <div className="text-10">
            {text(
              `${patient.address}, ${patient.city}, ${patient.postcode}`,
              `${patient.address} ${patient.postcode} ${patient.city}`,
              `${patient.address}, ${patient.city}, ${patient.postcode}`,
            )}
          </div>
        )}
      </Section>
      <Section theme={theme} className="mt-24" title={t("pdf_preview.note")}>
        {notes.map((note, index) => (
          <div key={note.uuid}>
            {notes.length > 1 && (
              <h4 className="flex text-grey-300 font-bold uppercase text-12 mb-10">
                <span className="mr-4">{note.createdAt.format("date")}</span>
                <span>
                  {index === 0
                    ? t("patient_view.appointment.initial_note")
                    : t("patient_view.appointment.amendment_by", {
                        doctor: displayDoctor(note.author),
                      })}
                </span>
              </h4>
            )}
            <NoteSectionsViewer
              title={null}
              sections={note.sections.mapNotNull((s) =>
                isKnownUnionValue("PatientNoteSectionContent", s.content)
                  ? { ...s, content: s.content }
                  : null,
              )}
            />
          </div>
        ))}
      </Section>
    </BaseItemPdfPreview>
  );
};
