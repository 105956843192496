import { useRef } from "react";

import { DraggableWindow } from "components/DraggableWindow/DraggableWindow";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useTranslation } from "i18n";
import { EHRInput } from "types";
import { run } from "utils";

import { DocumentComposer } from "./DocumentComposer";
import { EHRAtomicInput } from "./EHRAtomicInput";
import { MeasureComposer } from "./EHRDataPointComposer/EHRDataPointComposer";
import { FamilyConditionComposer } from "./FamilyConditionComposer/FamilyConditionComposer";
import { NoteComposer } from "./NoteComposer/NoteComposer";
import { NablaPrescriptionPreview } from "./Prescriptions/PrescriptionPreview";
import { EHRTaskComposer } from "./TaskComposer/EHRTaskComposer";
import { useEHRInput } from "./useEHRInput";

const computeHeight = (ehrInput: EHRInput) => {
  const isSingleInput = ![
    "NOTE",
    "PRESCRIPTION",
    "DOCUMENT",
    "TASK",
    undefined,
  ].includes(ehrInput.type);
  const minHeight = isSingleInput ? 380 : ehrInput.type === "TASK" ? 630 : 720;
  const maxHeight = isSingleInput ? minHeight : window.innerHeight - 40;
  return { minHeight, maxHeight };
};

export const EHRComposer = () => {
  const t = useTranslation();
  const { ehrComposerState, setEHRComposerState } = usePatient();
  const { ehrInput, setEHRInput } = useEHRInput();
  const inputRef = useRef<HTMLInputElement>(null);

  if (!ehrComposerState) return null;
  const { minHeight, maxHeight } = computeHeight(ehrInput);

  return (
    <DraggableWindow
      initialHeight={minHeight}
      initialWidth={580}
      minWidth={620} // Ensure enough space for Prescription footer
      minHeight={minHeight}
      maxHeight={maxHeight}
      title={run(() => {
        switch (ehrComposerState.mode) {
          case undefined:
          case "create":
            return t(
              "inboxes.qa_experience.ehr_composer.ehr_composer.add_something",
            );
          case "note-edit":
            return t(
              "inboxes.qa_experience.ehr_composer.ehr_composer.edit_note",
            );
          case "document-edit":
            return t(
              "inboxes.qa_experience.ehr_composer.ehr_composer.comment_the_document",
            );
          case "prescription-templates":
            return t(
              "inboxes.qa_experience.ehr_composer.ehr_composer.templates",
            );
          case "prescription-preview":
            return t(
              "inboxes.qa_experience.ehr_composer.ehr_composer.prescription",
            );
        }
      })}
      leftAction={
        ehrComposerState.mode === "prescription-templates" ||
        ehrComposerState.mode === "prescription-preview"
          ? {
              icon: "arrow",
              onClick: () =>
                setEHRComposerState({
                  mode: "create",
                  submit: ehrComposerState.submit,
                }),
            }
          : undefined
      }
      className="z-observation"
      style={{
        transitionProperty: "all",
        transitionDuration: window.E2E ? "0ms" : "400ms",
        transitionTimingFunction: "ease-in-out",
      }}
      onHide={() => {
        setEHRComposerState(undefined);
        setEHRInput({ onPatientInfoRequiredForPrescription: undefined });
      }}
    >
      {run(() => {
        switch (ehrComposerState.mode) {
          case "prescription-preview":
            return (
              <NablaPrescriptionPreview previewRef={null} className="m-auto" />
            );
          case "note-edit":
            return (
              <NoteComposer state={ehrComposerState} inputRef={inputRef} />
            );
          case "document-edit":
            return (
              <DocumentComposer state={ehrComposerState} inputRef={inputRef} />
            );
          case "create":
            switch (ehrInput.type) {
              case "NOTE":
                return (
                  <NoteComposer state={ehrComposerState} inputRef={inputRef} />
                );
              case "FAMILY":
                return <FamilyConditionComposer inputRef={inputRef} />;
              case "DATA_POINT":
                return <MeasureComposer inputRef={inputRef} />;
              case "DOCUMENT":
                return (
                  <DocumentComposer
                    state={ehrComposerState}
                    inputRef={inputRef}
                  />
                );
              case "TASK":
                return <EHRTaskComposer inputRef={inputRef} />;
              case undefined:
                return (
                  <NoteComposer state={ehrComposerState} inputRef={inputRef} />
                );
              default:
                return (
                  <EHRAtomicInput type={ehrInput.type} inputRef={inputRef} />
                );
            }
        }
      })}
    </DraggableWindow>
  );
};
