import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormSwitch } from "components/Form/Switch/FormSwitch";
import { Switch } from "components/Form/Switch/Switch";
import { stableObjectEntries, stableObjectKeys } from "utils";

export type MultiToggleFormProps<KeyType extends string> = {
  title: string;
  subtitle?: string;
  toggles: Record<
    Exclude<KeyType, "FUTURE_VALUE">,
    [title: string, subtile?: string]
  >;
  enabledToggles: KeyType[];
  onSubmit: (enabled: KeyType[]) => void;
};

export const MultiToggleForm = <KeyType extends string>({
  title,
  subtitle,
  toggles,
  enabledToggles,
  onSubmit,
}: MultiToggleFormProps<KeyType>) => {
  const formatValues = (vals: Record<KeyType, boolean>) =>
    stableObjectEntries<KeyType, boolean>(vals)
      .filter(([_, v]) => v)
      .map(([k, _]) => k);
  const onValueChange = (newState: Record<KeyType, boolean>) => {
    onSubmit(formatValues(newState));
  };

  return (
    <Form<Record<KeyType, boolean>>
      initialValues={stableObjectKeys(toggles).reduce(
        (acc, key) => ({
          ...acc,
          [key]: enabledToggles.includes(key),
        }),
        {} as Record<KeyType, boolean>,
      )}
      onSubmit={() => {
        // no-op
      }}
    >
      <FormState<Record<KeyType, boolean>>>
        {({ values, setFieldValue }) => (
          <div className="flex-col space-y-20">
            <div className="flex justify-between pb-20 border-b">
              <div className="flex-col ">
                <div className="font-bold text-18 text-primary-dark">
                  {title}
                </div>
                {subtitle !== undefined && (
                  <div className="font-normal text-14 text-body">
                    {subtitle}
                  </div>
                )}
              </div>
              <Switch
                checked={Object.values(values).some((x) => x)}
                onChange={(newValue) => {
                  stableObjectKeys(values).forEach((key) => {
                    setFieldValue(key, newValue);
                    values[key] = newValue;
                  });
                  onValueChange(values);
                }}
              />
            </div>
            {stableObjectEntries(toggles).map(
              ([name, [rowTitle, rowSubtitle]]) => (
                <div className="flex justify-between" key={name}>
                  <div className="flex-col">
                    <div className="font-normal text-16 text-primary-dark">
                      {rowTitle}
                    </div>
                    {rowSubtitle !== undefined && (
                      <div className="font-normal text-14 text-body">
                        {rowSubtitle}
                      </div>
                    )}
                  </div>
                  <FormSwitch
                    name={name}
                    onChange={(newVal) => {
                      values[name] = newVal;
                      onValueChange(values);
                    }}
                  />
                </div>
              ),
            )}
          </div>
        )}
      </FormState>
    </Form>
  );
};
