import { Avatar, AvatarProps } from "./Avatar";

export const MaybeClickableAvatar = ({
  onClick,
  ...props
}: {
  onClick: (() => void) | undefined;
} & AvatarProps) =>
  onClick ? (
    <button onClick={() => onClick()}>
      <Avatar {...props} />
    </button>
  ) : (
    <Avatar {...props} />
  );
