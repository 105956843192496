import { useState } from "react";
import gql from "graphql-tag";

import { Select } from "components/Form/Select/Select";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Spinner } from "components/Spinner/Spinner";
import { Table } from "components/Table/Table";
import {
  AllServerKeys,
  DeleteServerKey,
  GetAPIVersions,
  GetOrganizationAPIVersion,
  UpdateOrganizationAPIVersion,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { AppFlavor } from "../../platform";
import { ServerKeyComposer } from "./ServerKeyComposer";

gql`
  # schema = ORGANIZATION_USER
  fragment ServerKey on ServerKey {
    uuid
    publicId
    name
    description
    createdAt
  }

  query AllServerKeys {
    serverKeys {
      ...ServerKey
    }
  }

  mutation DeleteServerKey($uuid: UUID!) {
    deleteServerKey(uuid: $uuid)
  }

  query GetAPIVersions {
    apiVersions {
      versions
    }
  }

  query GetOrganizationAPIVersion {
    myOrganization {
      uuid
      pinnedVersion
    }
  }

  mutation UpdateOrganizationAPIVersion($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        uuid
        pinnedVersion
      }
    }
  }
`;

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

type ComposerState = { mode: "edit"; serverKeyUuid: UUID };

export const ServerKeys = ({
  appFlavor,
  isAddingKey,
  onKeyComposerClosed,
}: {
  appFlavor: AppFlavor;
  isAddingKey: boolean;
  onKeyComposerClosed: () => void;
}) => {
  const t = useTranslation();
  const { data, loading } = useQuery(AllServerKeys);
  const [deleteServerKey] = useMutation(DeleteServerKey);
  const { data: apiVersions } = useQuery(GetAPIVersions);
  const { data: organization } = useQuery(GetOrganizationAPIVersion);
  const [updateOrganizationSettings] = useMutation(
    UpdateOrganizationAPIVersion,
  );
  const pinnedVersion = organization?.pinnedVersion;
  // TODO(@achraf) Move this product-bases filtering to be server-side
  const firstCopilotApiVersion = "2023-06-16";
  const filteredApiVersion = apiVersions
    ? appFlavor === "CARE_PLATFORM"
      ? apiVersions.versions
      : apiVersions.versions.filter((v) => v >= firstCopilotApiVersion)
    : [];

  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>();
  const [versionConfirmationModalState, setVersionConfirmationModalState] =
    useState<ConfirmationModalState>();
  const [composerState, setComposerState] = useState<ComposerState>();
  const [showingDetails, setShowingDetails] = useState(false);

  return (
    <>
      <ServerKeyComposer
        onClose={() => {
          setComposerState(undefined);
          onKeyComposerClosed();
        }}
        serverKey={
          composerState?.mode === "edit"
            ? data?.find(
                (serverKey) => serverKey.uuid === composerState.serverKeyUuid,
              )
            : undefined
        }
        showComposer={!!composerState || isAddingKey}
      />
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("server_keys.delete_server_api_key")}
          suffix={t("server_keys.to_delete_this_server_api_key")}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      {versionConfirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("server_keys.update_api_version")}
          suffix={t("server_keys.to_update_the_api_version")}
          {...versionConfirmationModalState}
          onHide={() => setVersionConfirmationModalState(undefined)}
          ctaLabel={t("server_keys.cta.update")}
        />
      )}
      <div>
        {pinnedVersion && (
          <>
            <div>
              {t("developers.server_keys.server_keys.api_version")}{" "}
              <a
                href={
                  appFlavor === "CARE_PLATFORM"
                    ? "https://cp-docs.nabla.com/reference/versioning#default-api-version"
                    : "https://docs.nabla.com/guides/api-versioning/upgrades"
                }
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                ({t("developers.server_keys.server_keys.doc_link")})
              </a>
              {t("developers.server_keys.server_keys.colon")}
              <Select
                name="apiVersion"
                options={filteredApiVersion}
                wrapperClassName="w-[130px] inline-block"
                value={pinnedVersion}
                onChange={(label) => {
                  setVersionConfirmationModalState({
                    onConfirm: async (close: () => void) => {
                      await updateOrganizationSettings(
                        { input: { apiVersion: label } },
                        {
                          onSuccess: () => {
                            setVersionConfirmationModalState(undefined);
                            notifier.success(
                              t("server_keys.organization_api_version_updated"),
                            );
                          },
                        },
                      );
                      close();
                    },
                  });
                }}
              />
            </div>
            <div className="mb-16">
              {t("developers.server_keys.server_keys.custom_api_version")}{" "}
              <a
                href={
                  appFlavor === "CARE_PLATFORM"
                    ? "https://docs.nabla.com/reference/versioning#manually-set-the-api-version"
                    : "https://docs.nabla.com/guides/api-versioning/usage#manually-set-the-api-version"
                }
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                {t("developers.server_keys.server_keys.header_link")}
              </a>
            </div>
          </>
        )}
        {loading ? (
          <Spinner />
        ) : data ? (
          <>
            <Table
              elements={data}
              onShowDetails={(show) => setShowingDetails(show)}
              fieldHeaders={[
                t("developers.server_keys.server_keys.public_id"),
                t("developers.server_keys.server_keys.name"),
                t("developers.server_keys.server_keys.description"),
                t("developers.server_keys.server_keys.created_at"),
              ]}
              fields={(serverKey) => [
                serverKey.publicId,
                <span key={`name-${serverKey.uuid}`} className="truncate">
                  {serverKey.name}
                </span>,
                serverKey.description,
                <span
                  key={`date-${serverKey.uuid}`}
                  className="whitespace-nowrap"
                >
                  {serverKey.createdAt.format("date")}
                </span>,
              ]}
              menuItems={(serverKey) => [
                {
                  icon: "edit",
                  text: t("developers.server_keys.server_keys.edit"),
                  onClick: (close: () => void) => {
                    close();
                    setComposerState({
                      mode: "edit",
                      serverKeyUuid: serverKey.uuid,
                    });
                  },
                },
                {
                  icon: "trash",
                  text: t(
                    "developers.server_keys.server_keys.permanently_delete",
                  ),
                  className: "text-danger",
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      onConfirm: () =>
                        deleteServerKey(
                          { uuid: serverKey.uuid },
                          {
                            onSuccess: (_, client) => {
                              notifier.success(
                                t("server_keys.server_api_key_deleted"),
                              );
                              setConfirmationModalState(undefined);
                              client.remove("ServerKey", serverKey.uuid);
                            },
                          },
                        ),
                    });
                  },
                },
              ]}
            />
            {!showingDetails && (
              <div className="float-right my-16">
                {data.length}{" "}
                {t("developers.server_keys.server_keys.server_api_keys")}
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
