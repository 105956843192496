import { GetPatientInformation } from "generated/provider";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";

export const useEHR = (patientUuid: UUID) => {
  const medicalAtcdQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "MEDICAL_ATCD" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const surgicalAtcdQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "SURGICAL_ATCD" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const familyAtcdQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "FAMILY_ATCD" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const medicationQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "MEDICATION" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const allergiesQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "ALLERGY" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const vaccinesQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "VACCINE" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const datapointsQuery = usePaginatedQuery(GetPatientInformation, {
    variables: { patientUuid, filter: { itemTypesToShow: "DATAPOINTS" } },
    selector: (d) => d.patient.patientTimeline.data,
  });

  const ehrLoading =
    medicationQuery.loading ||
    datapointsQuery.loading ||
    allergiesQuery.loading ||
    vaccinesQuery.loading ||
    familyAtcdQuery.loading ||
    surgicalAtcdQuery.loading ||
    medicalAtcdQuery.loading;

  return {
    medicalAtcdQuery,
    surgicalAtcdQuery,
    familyAtcdQuery,
    medicationQuery,
    allergiesQuery,
    vaccinesQuery,
    datapointsQuery,
    ehrLoading,
    refetchAll: () => {
      medicalAtcdQuery.refetch();
      surgicalAtcdQuery.refetch();
      familyAtcdQuery.refetch();
      medicationQuery.refetch();
      allergiesQuery.refetch();
      vaccinesQuery.refetch();
      datapointsQuery.refetch();
    },
  };
};
