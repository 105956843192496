import { Link } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { IconName } from "icon-library";
import { routes } from "routes";

export const DevGettingStarted = () => {
  const t = useTranslation();

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t(
            "developers.dev_getting_started.dev_getting_started.getting_started",
          )}
        </h1>
        <div>
          {t("dev_getting_started.welcome_to_the_developers_section")}
          <div className="my-32 space-y-16">
            <GettingStartedButton
              icon="mobile"
              title={t(
                "dev_getting_started.follow_the_sample_app_guide_to_easily_set_up_the_mobile_sdks",
              )}
              to={`${routes.DEVELOPERS}/${routes.SETUP_GUIDE}`}
            />
            <GettingStartedButton
              icon="lock"
              title={t(
                "dev_getting_started.create_api_keys_to_use_them_directly_in_our_mobile_sdks_or_our_rest_api",
              )}
              to={`${routes.DEVELOPERS}/${routes.API_KEYS}`}
            />
            <GettingStartedButton
              icon="uploadCloud"
              title={t(
                "dev_getting_started.create_webhooks_to_receive_live_events_from_nabla",
              )}
              to={`${routes.DEVELOPERS}/${routes.WEBHOOKS}`}
            />
            <GettingStartedButton
              icon="history"
              title={t(
                "dev_getting_started.see_the_latest_events_sent_to_webhooks",
              )}
              to={`${routes.DEVELOPERS}/${routes.WEBHOOK_EVENTS}`}
            />
            <GettingStartedButton
              icon="machineLearning"
              title={t(
                "dev_getting_started.access_the_machine_learning_models",
              )}
              to={`${routes.DEVELOPERS}/${routes.ML_MODELS}`}
            />
          </div>
          <p>{t("dev_getting_started.you_can_also_access")}</p>
          <ul className="mt-6 list-disc ml-14 space-y-6 leading-normal">
            <li>
              <a
                className="link"
                href="https://docs.nabla.com/reference"
                target="_blank"
                rel="noreferrer"
              >
                {t(
                  "developers.dev_getting_started.dev_getting_started.the_api_reference",
                )}
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://docs.nabla.com/docs"
                target="_blank"
                rel="noreferrer"
              >
                {t("dev_getting_started.the_mobile_sdks_documentation")}
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://github.com/nabla"
                target="_blank"
                rel="noreferrer"
              >
                {t(
                  "developers.dev_getting_started.dev_getting_started.the_nabla_github_repo",
                )}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Background>
  );
};

const GettingStartedButton = ({
  icon,
  title,
  to,
}: {
  icon: IconName;
  title: string;
  to: string;
}) => (
  <Link
    className="border rounded-lg bg-white hover:bg-grey-100 cursor-pointer p-16 flex items-center space-x-16"
    to={to}
  >
    <div className="bg-[#F2F3FF] text-primary rounded-full p-6">
      <Icon name={icon} size={24} />
    </div>
    <div className="text-primary-dark text-14 flex-fill">{title}</div>
    <Icon name="arrow" rotate={180} />
  </Link>
);
