import { SupportedLocale } from "generated/provider";
import { useField } from "hooks/useField";

import {
  IcdCodePickerWithDatesAndStatus,
  IcdCodePickerWithDatesValue,
} from "./IcdCodePickerWithDatesAndStatus";

export const FormMedicalConditionLabel = ({
  name,
  label,
  disabled: disabledProp,
  locale,
  onSetValue,
  includeFamilyMembers,
}: {
  name: string;
  label?: string;
  disabled?: boolean;
  locale?: SupportedLocale;
  onSetValue?: (value: IcdCodePickerWithDatesValue) => void;
  includeFamilyMembers?: boolean;
}) => {
  const [{ value, disabled }, { error }, { setValue }] =
    useField<IcdCodePickerWithDatesValue>({
      name,
      disabled: disabledProp,
    });

  return (
    <IcdCodePickerWithDatesAndStatus
      label={label}
      disabled={disabled}
      error={error}
      value={value}
      setValue={(v) => {
        setValue(v);
        onSetValue?.(v);
      }}
      locale={locale}
      includeFamilyMembers={includeFamilyMembers}
    />
  );
};
