import { RefObject } from "react";

import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormSelect } from "components/Form/Select/FormSelect";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  CreateCondition,
  FamilyMemberDegree,
  FamilyMemberDegreeKnownValues,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayFamilyMemberDegree } from "utils/display";
import { notifier } from "utils/notifier";

import { FooterWrapper } from "../FooterWrapper";
import { useEHRInput } from "../useEHRInput";

type FormValues = {
  text: string;
  familyMember: FamilyMemberDegree;
};

export const FamilyConditionComposer = ({
  inputRef,
}: {
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { initialEHRInput, setEHRInput, resetEHRInput } = useEHRInput();
  const { patient } = usePatient();
  const { setEHRComposerState } = usePatient();

  const [createConditionMutation] = useMutation(CreateCondition, {
    onSuccess: () => {
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.family_condition_composer.family_condition_composer.family_condition_added",
        ),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });

  const createCondition = ({ text, familyMember }: FormValues) =>
    createConditionMutation({
      patientUuid: patient.uuid,
      input: {
        type: text,
        description: "",
        familyMember,
      },
    });

  return (
    <Form<FormValues>
      initialValues={{
        text: initialEHRInput.atomicInput,
        familyMember: initialEHRInput.familyMember,
      }}
      validationSchema={{ text: "required", familyMember: "required" }}
      onSubmit={(values) => createCondition(values)}
      className="flex-fill flex-col"
    >
      <div className="flex items-center my-auto">
        <FormSelect
          name="familyMember"
          wrapperClassName="w-1/4 mr-10"
          options={FamilyMemberDegreeKnownValues}
          getOptionLabel={displayFamilyMemberDegree}
        />
        <FormInput
          name="text"
          autoFocus
          inputRef={inputRef}
          wrapperClassName="flex-fill"
          onChange={(e) => setEHRInput({ atomicInput: e.target.value })}
          placeholder={t("family_condition_composer.write_a_family_condition")}
        />
      </div>
      <FooterWrapper
        submitLabel={t(
          "inboxes.qa_experience.ehr_composer.family_condition_composer.family_condition_composer.create",
        )}
        inputRef={inputRef}
      />
    </Form>
  );
};
