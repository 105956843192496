import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";

import styles from "./PlusMinusSlider.module.css";

export const PlusMinusSlider = ({
  min,
  max,
  sliderStep,
  buttonStep,
  value,
  onChange,
  className,
}: {
  min: number;
  max: number;
  sliderStep: number;
  buttonStep: number;
  value: number;
  onChange?: (value: number) => void;
  className?: string;
}) => {
  // When the user clicks on "plus" or "minus" buttons, we want to increment/decrement to
  // the next multiple of buttonStep. If the current value is already a multiple of buttonStep,
  // we increment/decrement by buttonStep
  const incrementZoomHandler = () => {
    const newValue = Math.floor((value + buttonStep) / buttonStep) * buttonStep;
    onChange?.(Math.min(newValue, max));
  };

  const decrementZoomHandler = () => {
    const newValue = Math.ceil((value - buttonStep) / buttonStep) * buttonStep;
    onChange?.(Math.max(newValue, min));
  };

  return (
    <div
      className={classNames(
        "flex items-center gap-2 rounded-sm bg-grey-200/[.47] rounded-sm backdrop-blur-sm",
        className,
      )}
    >
      <ClickableIcon
        name="minus"
        className="text-grey-200 hover:text-white"
        onClick={decrementZoomHandler}
      />
      <TooltipWrapper label={`${Math.round(value)}%`} position="top">
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          className={`${styles.slider} flex-fill`}
          onChange={(e) => onChange?.(Number(e.target.value))}
          step={sliderStep}
        />
      </TooltipWrapper>
      <ClickableIcon
        name="add"
        className="text-grey-200 hover:text-white"
        onClick={incrementZoomHandler}
      />
    </div>
  );
};
