import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { IconName } from "icon-library";

export const PresentationPage = ({
  title,
  icon,
  image,
  description,
  onClickNavigateTo,
}: {
  title: string;
  icon: IconName;
  image: string;
  description: string;
  onClickNavigateTo: () => void;
}) => {
  const t = useTranslation();

  return (
    <>
      <PageTitle />

      <div className="flex-col space-y-12">
        <ContentTitle icon={icon} label={title} />
        <ExplanationImage url={image} />
        <div className="flex justify-between space-x-8 items-center">
          <div className="flex-shrink text-primary-dark text-14 font-medium">
            {description}
          </div>
          <NavigateToButton
            label={t("common.go_to_title", { title })}
            onClick={onClickNavigateTo}
          />
        </div>
      </div>
    </>
  );
};

// --- Private

const PageTitle = () => {
  const t = useTranslation();

  return (
    <div className="flex-col items-center">
      <span className="text-primary-dark text-32 leading-10 font-medium">
        {t("onboarding_modal.presentation_page.welcome")}
      </span>
      <span className="text-primary-dark text-18">
        {t("onboarding_modal.presentation_page.discover_our_features")}
      </span>
    </div>
  );
};

const ContentTitle = ({ icon, label }: { icon: IconName; label: string }) => (
  <div className="flex items-center">
    <Icon name={icon} className="text-primary-dark mr-8" size={32} />
    <h2 className="text-24 text-center text-primary-dark font-semibold">
      {label}
    </h2>
  </div>
);

const ExplanationImage = ({ url }: { url: string }) => (
  <div
    className="block bg-white"
    style={{
      height: "377px",
      width: "553px",
    }}
  >
    <img
      key="1"
      src={url}
      style={{ width: "553", height: "377" }}
      className="cursor-pointer object-cover border-[1px]"
      alt=""
    />
  </div>
);

const NavigateToButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => (
  <div className="flex-col">
    <button className="flex items-center space-x-8 group" onClick={onClick}>
      <div className="text-primary text-14 font-medium group-hover:text-opacity-80 group-active:text-opacity-70">
        {label}
      </div>
      <Icon
        name="arrow"
        rotate={180}
        className="text-primary text-14 font-medium group-hover:text-opacity-80 group-active:text-opacity-70"
      />
    </button>
    <hr />
  </div>
);
