import {
  Avatar,
  SubOrganizationAvatarFragment,
} from "components/Avatar/Avatar";
import { Icon, IconProps } from "components/Icon/Icon";
import { IconName } from "icon-library";
import { Exclusive } from "types";

type OrganizationLogoProps = Exclusive<
  | { fixedTitle: string }
  | { fixedIcon: IconName | IconProps }
  | { subOrganization: SubOrganizationAvatarFragment }
>;

export const OrganizationLogo = ({
  fixedTitle,
  fixedIcon,
  subOrganization,
}: OrganizationLogoProps) =>
  subOrganization ? (
    <Avatar
      user={subOrganization}
      squared
      size={44}
      title={[
        subOrganization.organization.displayName,
        subOrganization.displayName,
      ]
        .filterNotNull()
        .join(" - ")}
      className="border-none"
    />
  ) : (
    <div className="bg-jagged-ice text-white text-primary-dark flex-center text-18 h-44 w-44 rounded">
      <div className="overflow-hidden">
        {fixedIcon ? (
          typeof fixedIcon === "string" ? (
            <Icon size={26} name={fixedIcon} />
          ) : (
            <Icon size={26} {...fixedIcon} />
          )
        ) : (
          fixedTitle
        )}
      </div>
    </div>
  );
