import { getTimeZones, TimeZone } from "@vvo/tzdb";

import { FormSelect, FormSelectProps } from "components/Form/Select/FormSelect";
import { Select, SelectProps } from "components/Form/Select/Select";

import { displayTimeZone } from "./utils";

export type TimezonePickerProps<IsClearable extends boolean> = Omit<
  SelectProps<TimeZone>,
  "options" | "getOptionId" | "getOptionLabel"
> & { isClearable?: IsClearable };

export type FormTimezonePickerProps<IsClearable extends boolean> = Omit<
  FormSelectProps<TimeZone>,
  "options" | "getOptionId" | "getOptionLabel"
> & { isClearable?: IsClearable };

export const TimezonePicker = <IsClearable extends boolean>({
  onChange,
  ...props
}: TimezonePickerProps<IsClearable>) => {
  const timezones = getTimeZones();

  return (
    <Select
      options={timezones}
      getOptionLabel={displayTimeZone}
      getOptionId={displayTimeZone}
      onChange={onChange}
      {...props}
    />
  );
};

export const FormTimezonePicker = <IsClearable extends boolean>(
  props: FormTimezonePickerProps<IsClearable>,
) => {
  const timezones = getTimeZones();

  return (
    <FormSelect
      options={timezones}
      getOptionLabel={displayTimeZone}
      getOptionId={displayTimeZone}
      {...props}
    />
  );
};
