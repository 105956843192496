import { useState } from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

import { Button } from "components/Button/Button";
import { Separator } from "components/Separator/Separator";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { TemplatesSettingsConsultation } from "./TemplatesSettingsConsultation";
import { TemplatesSettingsDocuments } from "./TemplatesSettingsDocuments";
import { TemplatesSettingsPrescriptions } from "./TemplatesSettingsPrescriptions";

export const TemplatesSettings = () => {
  const t = useTranslation();
  const [newPrescriptionTemplate, setNewPrescriptionTemplate] = useState(false);
  const [newDocumentTemplate, setNewDocumentTemplate] = useState(false);
  const queryParam = useMatch(
    `${routes.SETTINGS}/${routes.TEMPLATES_SETTINGS}/:templatesSettingsTab*`,
  )?.params.templatesSettingsTab.toUpperCase();
  const templatesSettingsTab =
    queryParam &&
    [
      "VIDEO-CONSULTATION",
      "PRESCRIPTION-TEMPLATES",
      "DOCUMENT-TEMPLATES",
    ].includes(queryParam)
      ? queryParam
      : "VIDEO-CONSULTATION";

  return (
    <div className="flex-col flex-fill bg-light-blue overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold pt-16 lg:pt-24 ">
          {t("settings.templates.title")}
        </h1>

        <div className="flex justify-between">
          <div className="flex">
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  templatesSettingsTab === "VIDEO-CONSULTATION",
              })}
              to={routes.TEMPLATES_SETTINGS_VIDEO_CONSULTATION}
            >
              {t("settings.templates.video_consultation.title")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  templatesSettingsTab === "PRESCRIPTION-TEMPLATES",
              })}
              to={routes.TEMPLATES_SETTINGS_PRESCRIPTIONS}
            >
              {t("settings.templates.prescriptions.title")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  templatesSettingsTab === "DOCUMENT-TEMPLATES",
              })}
              to={routes.TEMPLATES_SETTINGS_DOCUMENTS}
            >
              {t("settings.templates.documents.title")}
            </Link>
          </div>
          <div>
            <div className="flex space-x-8 flex-shrink ml-auto py-4">
              {templatesSettingsTab === "PRESCRIPTION-TEMPLATES" && (
                <Button
                  label={t("settings.templates.new_template")}
                  onClick={() => {
                    setNewPrescriptionTemplate(true);
                  }}
                />
              )}
              {templatesSettingsTab === "DOCUMENT-TEMPLATES" && (
                <Button
                  label={t("settings.templates.new_template")}
                  onClick={() => {
                    setNewDocumentTemplate(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="flex-col p-16 lg:px-44">
        {templatesSettingsTab === "VIDEO-CONSULTATION" && (
          <TemplatesSettingsConsultation />
        )}
        {templatesSettingsTab === "PRESCRIPTION-TEMPLATES" && (
          <TemplatesSettingsPrescriptions
            newTemplate={newPrescriptionTemplate}
            onHideNewTemplate={() => setNewPrescriptionTemplate(false)}
          />
        )}
        {templatesSettingsTab === "DOCUMENT-TEMPLATES" && (
          <TemplatesSettingsDocuments
            newTemplate={newDocumentTemplate}
            onHideNewTemplate={() => setNewDocumentTemplate(false)}
          />
        )}
      </div>
    </div>
  );
};
