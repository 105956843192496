import classNames from "classnames";

import { Audio } from "components/Audio/Audio";
import { Button } from "components/Button/Button";
import { Dropzone } from "components/Form/Dropzone/Dropzone";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDoctor } from "contexts/User/UserContext";
import { FileUploadFragment } from "generated/provider";
import { useField } from "hooks/useField";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { copyToClipBoard, run } from "utils";
import { localURL } from "utils/file";
import { notifier } from "utils/notifier";
import { useMicrophoneRecorder } from "utils/recording";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { LocalAudioFile } from "./type";

export type FormAudioRecorderValue = {
  files: (LocalAudioFile | FileUploadFragment)[];
  recording: boolean;
};

export const FormAudioRecorder = ({
  name,
  createOrUpdateDraft,
  deleteDraft,
}: {
  name: string;
  createOrUpdateDraft?: (draft: LocalAudioFile) => Promise<void>;
  deleteDraft?: (draft: LocalAudioFile) => Promise<void>;
}) => {
  const [{ value, disabled }, , { setValue }] =
    useField<FormAudioRecorderValue>({
      name,
    });

  const newLocalAudioFile = (
    file: File,
    recordingStartDate: Date | undefined,
    id: string,
  ) => ({
    __typename: "LocalAudioFile" as const,
    file,
    durationSecond: recordingStartDate
      ? (Date.now() - recordingStartDate.getTime()) / 1000
      : undefined,
    localName: id,
  });

  const addFile = (localAudioFile: LocalAudioFile) => {
    setValue({
      recording: false,
      files: [...value.files, localAudioFile],
    });
  };

  const { startRecording, stopRecording, timeEllapsed } = useMicrophoneRecorder(
    {
      onFinalFileProduced: (file, recordingStartDate, recordingUuid) =>
        addFile(newLocalAudioFile(file, recordingStartDate, recordingUuid)),
      onIntermediaryFileProduced: (file, recordingStartDate, recordingUuid) => {
        createOrUpdateDraft?.(
          newLocalAudioFile(file, recordingStartDate, recordingUuid),
        );
      },
    },
  );
  const isRecording = stopRecording !== undefined;
  const isDesktop = useIsDesktop();
  const t = useTranslation();
  const { hasPermission } = useDoctor();

  return (
    <div className="flex-col space-y-8 items-center py-32 w-full">
      {value.files.map((it, index) => (
        <div key={index} className="flex items-center space-x-6 w-full">
          {it.__typename === "FileUpload" &&
            hasPermission("VIEW_ANY_RECORDED_CONVERSATION") && (
              <div className="flex space-x-6">
                <TooltipWrapper label={t("form_audio_recorder.copy_uuid")}>
                  <ClickableIcon
                    name="copy"
                    onClick={() =>
                      copyToClipBoard(
                        it.uuid,
                        t("form_audio_recorder.audio_file_uuid_copied"),
                      )
                    }
                  />
                </TooltipWrapper>
                <TooltipWrapper label="Command to download audio file directly">
                  <ClickableIcon
                    name="copy"
                    className="text-danger"
                    onClick={() =>
                      copyToClipBoard(
                        `wget "${it.urlV2.url}" -O audio_files/${it.uuid}.mp3`,
                        "Command to download audio file copied.",
                      )
                    }
                  />
                </TooltipWrapper>
              </div>
            )}
          <Audio
            className="flex flex-1"
            src={
              it.__typename === "LocalAudioFile"
                ? localURL(it.file)
                : it.urlV2.url
            }
            durationHint={
              it.__typename === "LocalAudioFile"
                ? it.durationSecond
                : it.metadata.__typename === "AudioMetadata"
                ? it.metadata.durationMs?.let((duration) => duration / 1000)
                : undefined
            }
          />
          <DeletionConfirmationModal
            suffix={t("form_audio_recorder.audio_suppression_confirmation")}
            onConfirm={async (closeDeletionModal) => {
              if (it.__typename === "LocalAudioFile") await deleteDraft?.(it);
              setValue({
                ...value,
                files: value.files.filter((file) => file !== it),
              });

              notifier.success(t("form_audio_recorded.audio_deleted"));
              closeDeletionModal();
            }}
          >
            {(open) => (
              <ClickableIcon
                name="trash"
                onClick={open}
                disabled={isRecording}
              />
            )}
          </DeletionConfirmationModal>
          {it.__typename === "FileUpload" && it.transcript && (
            <>
              <Button
                className="flex"
                label={t("form_audio_recorder.open_transcript")}
                to={`/${routes.RECORDED_CONVERSATIONS}/file-upload/${it.uuid}/transcript`}
              />
              {it.transcript.firstTranscribedAt && (
                <Button
                  className="flex"
                  label={t(
                    "form_audio_recorder.open_transcript_for_verification",
                  )}
                  to={`/${routes.RECORDED_CONVERSATIONS}/file-upload/${it.uuid}/transcript?verification`}
                />
              )}
            </>
          )}
        </div>
      ))}
      <div className="flex items-center justify-between w-full mb-16">
        <button
          type="button"
          disabled={disabled}
          className={classNames(
            "rounded border border-danger flex items-center px-16 h-48 flex-fill lg:max-w-[250px] text-14 font-medium text-center",
            {
              "recording-pulse": isRecording,
              "bg-danger text-white": !isRecording,
            },
          )}
          onClick={() => {
            isRecording
              ? stopRecording()
              : run(() => {
                  setValue({
                    ...value,
                    recording: true,
                  });
                  startRecording(ephemeralUuidV4(), undefined);
                });
          }}
        >
          <div className="ml-auto mr-auto">
            {isRecording
              ? t("recorded_conversations.stop")
              : t("recorded_conversations.record")}
          </div>
          {isRecording && <div className="w-32">{timeEllapsed}</div>}
        </button>
        {isDesktop && (
          <>
            <div className="mx-12">{t("recorded_conversations.or")}</div>
            <Dropzone
              name="dropzoneaudio"
              className="flex-fill flex items-center justify-center border h-48 rounded max-w-[250px] border-body"
              maxSize={100_000_000}
              multiple={false}
              accept={[".wav", ".mp3", ".m4a"]}
              onDrop={(files) => {
                addFile(
                  newLocalAudioFile(files[0], undefined, ephemeralUuidV4()),
                );
              }}
              disabled={disabled || isRecording}
              error={undefined}
            >
              {() => t("recorded_conversations.select_file")}
            </Dropzone>
          </>
        )}
      </div>
    </div>
  );
};
