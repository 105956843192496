import { useMemo } from "react";

import { useDoctor } from "contexts/User/UserContext";
import {
  AllDoctorsWithAppointmentAddress,
  DoctorWithAppointmentAddressFragment,
} from "generated/provider";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useQuery } from "graphql-client/useQuery";

export const useAvailableFilters = (): {
  filters: DoctorWithAppointmentAddressFragment[];
  loading: boolean;
  error: ParsedGraphQLError | undefined;
} => {
  const { user } = useDoctor();
  const {
    data: allDoctorsData,
    loading,
    error,
  } = useQuery(AllDoctorsWithAppointmentAddress, {
    variables: { filter: { permissions: ["EDIT_DOCTOR_AVAILABILITY"] } },
  });

  const filtersWithBumpedUser = useMemo(
    () =>
      (allDoctorsData?.doctors ?? [])
        .bumped((doctor) => doctor.uuid === user.uuid)
        .map((doctor) => doctor),
    [allDoctorsData, user],
  );

  return {
    filters: filtersWithBumpedUser,
    loading,
    error,
  };
};
