import { useMemo, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { FormDateAndHours } from "components/Form/DatePicker/FormDateAndHours";
import {
  alignDateToTimePossibilities,
  getTimePossibilities,
  Time,
} from "components/Form/DatePicker/utils";
import { FormState } from "components/Form/Form/FormState";
import { LabelWrapper } from "components/Form/Label/LabelWrapper";
import { AsyncFormSelect } from "components/Form/Select/AsyncFormSelect";
import { AsyncPatientFormSelect } from "components/Form/Select/AsyncPatientFormSelect";
import { DoctorRowOption } from "components/Form/Select/DoctorRowOption";
import { FormModal } from "components/Modal/FormModal";
import { Spinner } from "components/Spinner/Spinner";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDoctor } from "contexts/User/UserContext";
import {
  AllDoctorsWithAppointmentAddress,
  AppointmentSummaryFragment,
  AppointmentTypeFragment,
  AppointmentTypes,
  BackOfficePatient,
  BackOfficePatientFragment,
  CreateScheduledWebCall,
  DoctorWithAppointmentAddressFragment,
  GetDoctorAvailabilityStatus,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useFormState } from "hooks/useFormState";
import { useTranslation } from "i18n";
import { getCalendarRoute, run } from "utils";
import { now } from "utils/date";
import { displayDoctor } from "utils/display";
import { notifier } from "utils/notifier";

gql`
  query GetDoctorAvailabilityStatus($input: DoctorAvailabilityStatusInput!) {
    doctorAvailabilityStatus(input: $input) {
      availabilityStatus
    }
  }

  mutation CreateScheduledWebCall($input: CreateScheduledWebCallInput!) {
    createScheduledWebCall(createScheduledWebCallInput: $input) {
      appointment {
        ...AppointmentSummary
      }
    }
  }

  query AllDoctorsWithAppointmentAddress($filter: DoctorSearchFilter!) {
    doctorSearch(filter: $filter) {
      doctors(pagination: { numberOfItems: -1 }) {
        ...DoctorWithAppointmentAddress
      }
    }
  }

  fragment DoctorWithAppointmentAddress on Doctor {
    ...DoctorSummary
    appointmentAddress {
      ...Address
    }
  }

  query BackOfficePatient($uuid: UUID!) {
    patient(patientUuid: $uuid) {
      patient {
        ...BackOfficePatient
      }
    }
  }

  fragment BackOfficePatient on Patient {
    ...PatientSummary
    phoneV2
    email
    createdAt
    gatekeeperFeatures
  }
`;

type FormValues = {
  doctor: DoctorWithAppointmentAddressFragment;
  patient: BackOfficePatientFragment | undefined;
  appointmentType: AppointmentTypeFragment | undefined;
  startAt: ISOString;
  endAt: ISOString;
  isPhysical: boolean;
};

type WarningType = "NO_AVAILABILITY" | "OVERLAPPING_APPOINTMENT";

export const CreateScheduledWebCallModal = ({
  startDate,
  initialDoctor,
  forcedPatientUuid,
  onHide,
  onScheduled,
}: {
  startDate?: ISOString;
  initialDoctor?: DoctorWithAppointmentAddressFragment;
  forcedPatientUuid?: UUID;
  onHide: () => void;
  onScheduled?: (appointment: AppointmentSummaryFragment) => void;
}) => {
  const t = useTranslation();
  const { user, hasPermission } = useDoctor();

  const [createScheduledWebCall] = useMutation(CreateScheduledWebCall, {
    onSuccess: (_, client) => {
      client.evictQuery("appointmentsInTimeRange", "page");
      client.evictQuery("doctor", "uuid");
    },
  });
  const { data, loading: patientDataLoading } = useQuery(BackOfficePatient, {
    variables: { uuid: forcedPatientUuid! },
    skip: !forcedPatientUuid,
  });
  const { refetch: getAvailabilityStatus } = useQuery(
    GetDoctorAvailabilityStatus,
    { skip: true },
  );

  const [timePossibilities, initialStartAt, initialEndAt] = useMemo(() => {
    const possibilities = getTimePossibilities(0, 23, 5);
    const startAt = alignDateToTimePossibilities(
      startDate ? startDate : now().plusMinutes(5),
      possibilities,
    );
    const endAt = startAt.plusMinutes(30);
    return [possibilities, startAt, endAt];
  }, [startDate]);
  const [showWarning, setShowWarning] = useState<WarningType>();

  if (!hasPermission("CREATE_AND_SCHEDULE_WEB_CALLS")) return null;

  const scheduleAppointment = async (formValues: FormValues) => {
    const output = await createScheduledWebCall({
      input: {
        patientUuid: formValues.patient!.uuid, // Checked by validationSchema.
        doctorUuid: formValues.doctor.uuid,
        startAt: formValues.startAt,
        endAt: formValues.endAt,
        appointmentTypeUuid: formValues.appointmentType?.uuid,
        isPhysical: formValues.isPhysical,
      },
    });

    if (output) {
      notifier.success(
        t("patients.create_scheduled_web_call_modal.appointment_scheduled"),
      );
      onScheduled?.(output.appointment);
      onHide();
    } else {
      notifier.error({
        user: t(
          "create_scheduled_web_call_modal.something_went_wrong_while_trying_to_schedule_the_appointment_please_try_again",
        ),
      });
    }
  };

  return (
    <FormModal<FormValues>
      title={run(() => {
        if (showWarning === undefined) {
          return t(
            "patients.create_scheduled_web_call_modal.schedule_a_video_appointment",
          );
        }
        switch (showWarning) {
          case "NO_AVAILABILITY":
            return t(
              "patients.create_scheduled_web_call_modal.no_availability_warning_title",
            );
          case "OVERLAPPING_APPOINTMENT":
            return t(
              "patients.create_scheduled_web_call_modal.overlapping_appointment_warning_title",
            );
        }
      })}
      initialValues={{
        doctor: initialDoctor ?? user,
        patient: data?.patient, // Might be undefined.
        appointmentType: undefined,
        startAt: initialStartAt,
        endAt: initialEndAt,
        isPhysical: false,
      }}
      validationSchema={{
        patient: "required",
      }}
      validate={({ startAt, endAt, isPhysical, doctor }) => ({
        ...(isPhysical &&
          !doctor.appointmentAddress && {
            isPhysical: t(
              "patients.create_scheduled_web_call_modal.location.tooltip_no_address",
            ),
          }),
        ...(startAt.isPast() && {
          startAt: t(
            "create_scheduled_web_call_modal.the_start_time_must_be_in_the_future",
          ),
        }),
        ...(startAt.isAfterOrEqual(endAt) && {
          endAt: t(
            "create_scheduled_web_call_modal.the_end_time_must_be_after_the_start_time",
          ),
        }),
      })}
      onSubmit={async (formValues) => {
        const availabilityStatusData = await getAvailabilityStatus({
          input: {
            doctorUuid: formValues.doctor.uuid,
            from: formValues.startAt,
            to: formValues.endAt,
          },
        });

        if (availabilityStatusData === undefined) return;
        const status = availabilityStatusData.availabilityStatus;

        if (status === "NO_AVAILABILITY") {
          setShowWarning("NO_AVAILABILITY");
        } else if (status === "OVERLAPPING_APPOINTMENT") {
          setShowWarning("OVERLAPPING_APPOINTMENT");
        } else if (status === "AVAILABLE") {
          await scheduleAppointment(formValues);
        }
      }}
      onHide={onHide}
      submitLabel={t("patients.create_scheduled_web_call_modal.schedule")}
      showSubmitButton={!showWarning}
    >
      <FormState<FormValues>>
        {({ values }) =>
          patientDataLoading ? (
            <Spinner />
          ) : showWarning ? (
            <>
              <div className="text-body text-14 font-normal">
                {showWarning === "NO_AVAILABILITY"
                  ? t(
                      "patients.create_scheduled_web_call_modal.no_availability_warning_content",
                      { doctorName: displayDoctor(values.doctor) },
                    )
                  : t(
                      "patients.create_scheduled_web_call_modal.overlapping_appointment_warning_content",
                    )}
              </div>
              <div className="flex space-x-16">
                <Button
                  label={t(
                    "patients.create_scheduled_web_call_modal.no_availability_warning_see_calendar",
                  )}
                  secondary
                  className="w-auto flex-grow"
                  to={getCalendarRoute(values.doctor)}
                />

                <Button
                  label={t(
                    "patients.create_scheduled_web_call_modal.no_availability_warning_schedule_anyway",
                  )}
                  danger
                  className="w-auto flex-grow"
                  onClick={() => scheduleAppointment(values)}
                />
              </div>
            </>
          ) : (
            <CreateScheduledWebCallModalFormContent
              startAt={values.startAt}
              timePossibilities={timePossibilities}
              showPatientSelect={!forcedPatientUuid}
            />
          )
        }
      </FormState>
    </FormModal>
  );
};

export const CreateScheduledWebCallModalFormContent = ({
  startAt,
  timePossibilities,
  showPatientSelect,
}: {
  startAt: ISOString;
  timePossibilities: Time[];
  showPatientSelect: boolean;
}) => {
  const t = useTranslation();
  const { values, errors, setFieldValue } = useFormState<FormValues>();

  return (
    <>
      <LabelWrapper
        name="consultationLocation"
        wrapperClassName="flex-col"
        label={t(
          "patients.create_scheduled_web_call_modal.location.remote_or_physical_label",
        )}
        hint={undefined}
        error={errors.isPhysical}
      >
        <CheckBox
          name="remote"
          checked={!values.isPhysical}
          onChange={(checked) => setFieldValue("isPhysical", !checked)}
          label={t("patients.create_scheduled_web_call_modal.location.remote")}
          iconNameOn="radioOn"
          iconNameOff="radioOff"
        />
        <TooltipWrapper
          label={t(
            "patients.create_scheduled_web_call_modal.location.tooltip_no_address",
          )}
          show={!values.doctor.appointmentAddress}
        >
          <CheckBox
            name="physical"
            disabled={!values.doctor.appointmentAddress}
            className={classNames("mt-4", {
              "opacity-50": !values.doctor.appointmentAddress,
            })}
            checked={values.isPhysical}
            onChange={(checked) => setFieldValue("isPhysical", checked)}
            label={
              values.doctor.appointmentAddress
                ? t(
                    "patients.create_scheduled_web_call_modal.location.physical_at_",
                    { address: values.doctor.appointmentAddress.address },
                  )
                : t(
                    "patients.create_scheduled_web_call_modal.location.physical",
                  )
            }
            iconNameOn="radioOn"
            iconNameOff="radioOff"
          />
        </TooltipWrapper>
      </LabelWrapper>
      <AsyncFormSelect
        selectProps={{
          name: "doctor",
          placeholder: t("form.select.async_doctor_form_select.select"),
          CustomOption: DoctorRowOption,
          getOptionLabel: displayDoctor,
          label: t("form.select.async_doctor_form_select.practitioner"),
        }}
        queryProps={{
          query: AllDoctorsWithAppointmentAddress,
        }}
        getOptions={(data) => data?.doctors ?? []}
        getVariables={(s) => ({
          filter: {
            permissions: ["ANSWER_QA_EXPERIENCE" as const],
            freeTextSearch: s,
          },
        })}
      />
      <AsyncFormSelect
        selectProps={{
          name: "appointmentType",
          label: t(
            "patients.create_scheduled_web_call_modal.appointment_type_label",
          ),
          getOptionLabel: (a: AppointmentTypeFragment) => a.name,
          position: "bottom",
          isClearable: true,
          onChange: (value) => {
            if (value) {
              setFieldValue(
                "endAt",
                startAt.plusMinutes(value.callDurationMinutes),
              );
            }
          },
        }}
        queryProps={{ query: AppointmentTypes }}
        getOptions={(data) => data ?? []}
        getVariables={() => ({})}
      />
      {showPatientSelect && (
        <AsyncPatientFormSelect name="patient" position="bottom" />
      )}
      <FormDateAndHours
        label="Date"
        startName="startAt"
        endName="endAt"
        timePossibilities={timePossibilities}
      />
      {values.patient && !values.patient.email?.trimOrNull() && (
        <div>
          {t(
            "patients.create_scheduled_web_call_modal.notification_disclaimer",
          )}
        </div>
      )}
    </>
  );
};
