import { TagsManagement } from "components/TagsManagement/TagsManagement";
import {
  GetDoctorsConversations,
  GetUnreadTaskCount,
} from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import { useExtendedQAInbox } from "hooks/useExtendedQAInbox";
import { routes } from "routes";
import { useImminentAppointments } from "singletons/useImminentAppointments";
import { AccountMenuItem, MainSidebarItem } from "types";
import { isDesktop } from "utils";
import { supportsGoogleCalendarSync } from "utils/google-calendar-sync";

import { SecuritySettings } from "../shared/Preferences/SecuritySettings";
import { Accounts } from "./Accounts/Accounts";
import { Developers } from "./Developers/Developers";
import { DoctorsConversations } from "./DoctorsConversation/DoctorsConversations";
import { GroupConversations } from "./GroupConversations/GroupConversations";
import { QAInbox } from "./Inboxes/QAInbox/QAInbox";
import { Labs } from "./Labs/Labs";
import { NoteAnnotationHome } from "./NoteAnnotation/NoteAnnotationHome";
import { NoteNormalizationHome } from "./NoteNormalization/NoteNormalizationHome";
import { PatientsInbox } from "./Patients/PatientsInbox/PatientsInbox";
import { PatientsPage } from "./Patients/PatientsPage";
import { DoctorNotifications as PractitionerNotifications } from "./Preferences/DoctorNotifications/DoctorNotifications";
import { PersonalInformation } from "./Preferences/PersonalInformation/PersonalInformation";
import { RecordedConversations } from "./RecordedConversation/RecordedConversations";
import { Scheduling } from "./Scheduling/Scheduling";
import { DoctorGoogleCalendarSync } from "./Settings/GoogleCalendar/DoctorGoogleCalendarSync";
import { Settings } from "./Settings/Settings";
import { DoctorTimezoneAndLanguage } from "./Settings/Timezone/DoctorTimezoneAndLanguage";
import { StatsDashboard } from "./StatsDashboard/StatsDashboard";
import { Tasks } from "./Tasks/Tasks";

const patientInboxSidebarItem: MainSidebarItem = {
  to: routes.PATIENTS_INBOX,
  icon: "drawer",
  label: ["Inbox", "Inbox", "Caixa de entrada"],
  Component: PatientsInbox,
  hasAccess: ({ hasRole }) => hasRole("NABLA_MEDICAL_STAFF"),
};

const backOfficeMainSidebarItem: MainSidebarItem = {
  to: routes.SETTINGS,
  icon: "settings",
  label: ["Settings", "Réglages", "Preferências"],
  Component: Settings,
  hasAccess: ({ hasRole }) => hasRole("NABLA_ADMINISTRATOR"),
};

const developersMainSidebarItem: MainSidebarItem = {
  to: routes.DEVELOPERS,
  icon: "code",
  label: ["Developers", "Développeurs", "Dev"],
  Component: Developers,
  hasAccess: ({ hasRole }) => hasRole("NABLA_ADMINISTRATOR"),
  organizationUserOnly: true,
};

const accountsMainSidebarItem: MainSidebarItem = {
  to: routes.ACCOUNTS,
  icon: "passport",
  label: ["Accounts", "Comptes", "Contas"],
  Component: Accounts,
  hasAccess: ({ hasPermission }) => hasPermission("EDIT_DOCTORS"),
};

const patientsMainSideBarItem: MainSidebarItem = {
  to: routes.PATIENT_LIST,
  icon: "people",
  label: ["Patients", "Patients", "Pacientes"],
  Component: PatientsPage,
  hasAccess: ({ hasPermission, hasRole }) =>
    hasPermission("EDIT_PATIENTS_ADMIN_DATA") && hasRole("NABLA_MEDICAL_STAFF"),
};

const qaInboxMainSidebarItem: MainSidebarItem = {
  to: routes.QA_INBOX,
  icon: "doubleChatBubble",
  label: ["Conversations", "Conversations", "Conversas"],
  Component: QAInbox,
  hasAccess: ({ hasPermission, hasRole }) =>
    hasPermission("ANSWER_QA_EXPERIENCE") || hasRole("REVIEWER"),
  useCount: () =>
    useExtendedQAInbox("ASSIGNED_TO_ME").items.count((i) => !i.seenStatus),
};

const tasksMainSidebarItem: MainSidebarItem = {
  to: routes.TASKS,
  icon: "checklist",
  label: ["Tasks", "Tâches", "Tarefas"],
  Component: Tasks,
  hasAccess: ({ hasPermission }) => hasPermission("VIEW_TASKS"),
  useCount: () =>
    useQuery(GetUnreadTaskCount).data?.doctor.unreadTasksCount ?? 0,
};

const doctorsConversationsMainSidebarItem: MainSidebarItem = {
  to: routes.CONVERSATION_BASE,
  icon: "chat",
  label: ["Internal chats", "Chats internes", "Chats internos"],
  Component: DoctorsConversations,
  hasAccess: ({ hasPermission }) => hasPermission("CHAT_WITH_DOCTORS"),
  useCount: () =>
    useQuery(GetDoctorsConversations).data?.experiences.data.count(
      (e) => e && !e.seenStatus,
    ),
};

const groupChatsMainSidebarItem: MainSidebarItem = {
  to: routes.GROUP_CHAT_BASE,
  icon: "megaphone",
  label: ["Community", "Communauté", "Comunidade"],
  Component: GroupConversations,
  hasAccess: ({ hasPermission, hasAccessToGatekeeperFeature }) =>
    hasPermission("VIEW_ANY_MULTI_PATIENTS_EXPERIENCE") &&
    hasAccessToGatekeeperFeature("GROUP_CHATS"),
};

const statsMainSidebarItem: MainSidebarItem = {
  to: routes.STATS,
  icon: "chart",
  label: ["Analytics", "Statistiques", "Análise de dados"],
  Component: StatsDashboard,
  hasAccess: ({ hasPermission, hasRole }) =>
    hasPermission("USE_WEB_APP") && hasRole("NABLA_ADMINISTRATOR"),
};

const hcpSchedulingSideBarItem: MainSidebarItem = {
  to: routes.SCHEDULING,
  icon: "calendar",
  label: ["Calendar", "Calendrier", "Agenda"],
  Component: Scheduling,
  hasAccess: ({ hasPermission }) => hasPermission("VIEW_CALENDAR"),
  useCount: () => useImminentAppointments().length,
};

const labsMainSidebarItem: MainSidebarItem = {
  to: routes.LABS,
  icon: "laboratory",
  label: "Labs",
  Component: Labs,
  hasAccess: ({ hasAccessToGatekeeperFeature }) =>
    hasAccessToGatekeeperFeature("NABLA_LABS"),
};

const personalInfoAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.PERSONAL_INFO}`,
  icon: "profile",
  label: [
    "Personal information",
    "Informations personnelles",
    "Informações pessoais",
  ],
  Component: PersonalInformation,
};

const tagsAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.TAGS}`,
  icon: "tag",
  label: ["Tag management", "Gestion des tags", "Gerenciamento de tags"],
  Component: TagsManagement,
  hasAccess: ({ hasPermission }) => hasPermission("UPDATE_TAGS"),
};

const securityAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.SECURITY}`,
  icon: "key",
  label: [
    "Password & Security",
    "Sécurité & Mot de passe",
    "Segurança & Senha",
  ],
  Component: SecuritySettings,
};

const timezoneAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.TIMEZONE}`,
  icon: "globe",
  label: [
    "Timezone and language",
    "Fuseau horaire et langue",
    "Fuso horário e língua",
  ],
  Component: DoctorTimezoneAndLanguage,
};

const doctorEmailNotificationPreferences: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.EMAIL_NOTIFICATIONS}`,
  icon: "email",
  label: [
    "Emails and notifications",
    "Notifications et emails",
    "Notificações e e-mails",
  ],
  Component: PractitionerNotifications,
  hasAccess: ({ hasPermission }) =>
    hasPermission("EDIT_APPOINTMENT") ||
    hasPermission("EDIT_PATIENT_EXPERIENCE"),
};

const conversationUploaderItem: MainSidebarItem = {
  to: routes.RECORDED_CONVERSATIONS,
  icon: "machineLearning",
  label: [
    "Provide consultation audio",
    "Audio consultation upload",
    "Dar consulta de áudio",
  ],
  Component: RecordedConversations,
  hasAccess: ({ hasAccessToGatekeeperFeature }) =>
    hasAccessToGatekeeperFeature("RECORDED_CONVERSATIONS"),
};

const noteAnnotationItem: MainSidebarItem = {
  to: routes.NOTE_ANNOTATION,
  icon: "fileText",
  label: ["Note writing", "Écriture de notes", "Nota escrita"],
  Component: NoteAnnotationHome,
  hasAccess: ({ hasAccessToGatekeeperFeature, hasPermission }) =>
    hasAccessToGatekeeperFeature("NOTE_ANNOTATION") &&
    hasPermission("ANNOTATE_NOTE"),
};

const noteNormalizationItem: MainSidebarItem = {
  to: routes.NOTE_NORMALIZATION,
  icon: "humanHeight",
  label: ["Note normalization", "Normalisation de notes", "Nota normalizada"],
  Component: NoteNormalizationHome,
  hasAccess: ({ hasAccessToGatekeeperFeature, hasPermission }) =>
    hasAccessToGatekeeperFeature("NOTE_NORMALIZATION") &&
    hasPermission("NORMALIZE_NOTE"),
};

const googleCalendarSyncAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.GOOGLE_CALENDAR_SYNC}`,
  icon: "calendar",
  label: [
    "Google Calendar synchronization",
    "Synchronisation Google Agenda",
    "Sincronização do Google Agenda",
  ],
  Component: DoctorGoogleCalendarSync,
  hasAccess: ({ user }) =>
    !!user && user.__typename === "Doctor" && supportsGoogleCalendarSync(user),
};

export const MAIN_SIDEBAR_ITEMS: MainSidebarItem[] = isDesktop()
  ? [
      conversationUploaderItem,
      noteAnnotationItem,
      noteNormalizationItem,
      patientInboxSidebarItem,
      patientsMainSideBarItem,
      qaInboxMainSidebarItem,
      hcpSchedulingSideBarItem,
      tasksMainSidebarItem,
      statsMainSidebarItem,
      accountsMainSidebarItem,
      doctorsConversationsMainSidebarItem,
      groupChatsMainSidebarItem,
      developersMainSidebarItem,
      backOfficeMainSidebarItem,
      labsMainSidebarItem,
    ]
  : [
      conversationUploaderItem,
      qaInboxMainSidebarItem,
      accountsMainSidebarItem,
      patientsMainSideBarItem,
      doctorsConversationsMainSidebarItem,
      groupChatsMainSidebarItem,
      labsMainSidebarItem,
    ];

export const ACCOUNT_MENU_ITEMS: AccountMenuItem[] = [
  personalInfoAccountMenuItem,
  securityAccountMenuItem,
  doctorEmailNotificationPreferences,
  tagsAccountMenuItem,
  timezoneAccountMenuItem,
  googleCalendarSyncAccountMenuItem,
];
