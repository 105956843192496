import { useMatch } from "react-router-dom";

import { Maybe } from "base-types";
import { PatientProvider } from "contexts/PatientContext/PatientProvider";
import { PatientViewProvider } from "contexts/PatientViewContext/PatientViewProvider";
import { routes } from "routes";

import { Patients } from "./Patients";
import { PatientView } from "./PatientView/PatientView";

export const PatientsPage = () => {
  // FIXME(@liautaud): Rewrite using <Router> and <Route>.
  const patientUuid = useMatch(`${routes.PATIENT_LIST}/:patientUuid*`)?.params
    .patientUuid as Maybe<UUID>;

  return patientUuid ? (
    // FIXME(@liautaud): Remove once we no longer use setEHRComposerState.
    <PatientProvider uuid={patientUuid}>
      <PatientViewProvider patientUuid={patientUuid}>
        <PatientView
          key={patientUuid} // To avoid mixing up internal state between patients.
          patientUuid={patientUuid}
        />
      </PatientViewProvider>
    </PatientProvider>
  ) : (
    <Patients />
  );
};
