import { ArrayHelpers as FormikArrayHelpers } from "formik";

export const getArrayHelpers = <ArrayValue>(
  array: ArrayValue[],
  { replace, ...helpers }: FormikArrayHelpers,
  set: (array: ArrayValue[]) => void,
): ArrayHelpers<ArrayValue> => ({
  ...helpers,
  set,
  update: (index, payload) =>
    replace(
      index,
      typeof payload === "object" ? { ...array[index], ...payload } : payload,
    ),
  toggle: (element) => {
    array.includes(element)
      ? helpers.remove(array.indexOf(element))
      : helpers.push(element);
  },
  selected: (element) => array.includes(element),
});

export type ArrayHelpers<ArrayValue> = Omit<
  FormikArrayHelpers,
  "push" | "replace" | "insert"
> & {
  set: (array: ArrayValue[]) => void;
  update: (
    index: number,
    payload: ArrayValue extends Record<string, unknown>
      ? Partial<ArrayValue>
      : ArrayValue,
  ) => void;
  push: (element: ArrayValue) => void;
  insert: (index: number, element: ArrayValue) => void;
  toggle: (element: ArrayValue) => void;
  selected: (element: ArrayValue) => boolean;
};
