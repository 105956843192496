import { ReactNode } from "react";

import { MenuItemProps } from "components/Menu/MenuItem";
import { useFormState } from "hooks/useFormState";
import { useSubmit } from "hooks/useSubmit";

import { ButtonWithMenu, ButtonWithMenuProps } from "./ButtonWithMenu";

export type SubmitItemProps<
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
> = Omit<MenuItemProps, "to" | "onClick"> & {
  context?: SubmitContext;
};

export const SubmitWithMenu = <
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  label,
  disabled = false,
  requiresDirty = false,
  requiresValid = false,
  context,
  ...rest
}: {
  label: string | ReactNode;
  requiresDirty?: boolean;
  requiresValid?: boolean;
  context?: SubmitContext;
} & (
  | { menuItems: MenuItemProps[] }
  | { submitItems: SubmitItemProps<SubmitContext>[] }
) &
  Omit<ButtonWithMenuProps, "label" | "menuItems">) => {
  const { submitForm, dirty } = useFormState<
    Record<string, unknown>,
    SubmitContext
  >();
  const { submitProps, isSubmitting } = useSubmit({
    disabled,
    requiresDirty,
    requiresValid,
    context,
  });

  const menuItems: MenuItemProps[] =
    "menuItems" in rest
      ? rest.menuItems
      : rest.submitItems.map(({ context: itemContext, ...menuItem }) => ({
          ...menuItem,
          onClick: async (closeMenu) => {
            closeMenu();
            await submitForm(itemContext);
          },
        }));

  return (
    <ButtonWithMenu
      label={label}
      loading={isSubmitting}
      menuDisabled={
        isSubmitting || rest.menuDisabled || (requiresDirty && !dirty)
      }
      menuItems={menuItems}
      {...rest.withoutKey("menuItems").withoutKey("submitItems")}
      {...submitProps}
    />
  );
};
