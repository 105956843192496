import { createAtom } from "atoms/createAtom";
import { TagFragment } from "generated/provider";

export const useExtendedQASearch = createAtom(
  {
    tags: [] as TagFragment[],
    participatingDoctorsUuids: [] as UUID[],
  },
  ({ set }) => ({
    // Unsure stable order to improve apollo cache hits
    setTags: (tags: TagFragment[]) => set({ tags: tags.immutableSort() }),
    setParticipatingDoctorsUuids: (uuids: UUID[]) =>
      set({ participatingDoctorsUuids: uuids.immutableSort() }),
  }),
);
