import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import { TagFragment } from "generated/provider";
import { Color, colors } from "styles";

export const TagPill = ({
  tag,
  tagClassName,
  className,
  isMLTag,
  rightInnerElement,
  style,
  disabled,
  fontSize = 12,
}: {
  tag: TagFragment;
  tagClassName?: string;
  className?: string;
  isMLTag?: boolean;
  rightInnerElement?: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  fontSize?: number;
}) => {
  const tagHexColor = getTagHexColor(tag);

  return (
    // adding this div to make sure the tag background opacity doesn't reveal the underlying color
    <div className={classNames("bg-white rounded-full", className)}>
      <div
        className={classNames(
          "h-24 px-4 flex group items-center relative font-medium border",
          { "opacity-70": disabled },
        )}
        style={{
          borderRadius: 12,
          fontSize,
          backgroundColor: isMLTag ? "white" : `${tagHexColor}0d`,
          color: isMLTag ? colors.body : tagHexColor,
          borderColor: isMLTag ? tagHexColor : `${tagHexColor}4d`,
          ...style,
        }}
      >
        <span
          className={classNames("flex-fill truncate", tagClassName ?? "px-4")}
          title={tag.name}
        >
          {tag.name}
        </span>
        {rightInnerElement}
      </div>
    </div>
  );
};

export const TagRow = ({
  tag,
  rightInnerElement,
}: {
  tag: TagFragment;
  rightInnerElement?: ReactNode;
}) => (
  <div className="flex items-center list-element py-8 px-12 space-x-8">
    <div
      className="w-8 h-8 rounded-full"
      style={{ backgroundColor: getTagHexColor(tag) }}
    />
    <span className="flex-fill truncate text-primary-dark">{tag.name}</span>
    {rightInnerElement}
  </div>
);

const getTagHexColor = (tag: TagFragment): string =>
  Object.keys(colors).includes(tag.color)
    ? colors[tag.color as Color]
    : tag.color;
