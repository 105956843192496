import { MenuItemProps } from "components/Menu/MenuItem";
import { usePatientTimelineLocalState } from "components/Patient/Timeline/PatientTimelineLocalStateContext";
import { DocumentTemplates } from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";

export const useDocumentUploadMenuSubItems = ({
  appointmentUuid,
  onClose,
}: {
  appointmentUuid?: UUID;
  onClose?: () => void;
} = {}): MenuItemProps[] => {
  const t = useTranslation();
  const { addNewItem } = usePatientTimelineLocalState();
  const { data } = useQuery(DocumentTemplates);

  const templatesItems: MenuItemProps[] = (data ?? []).map((template) => ({
    icon: "fileText" as const,
    text: template.title,
    onClick: (closeMenu: () => void) => {
      closeMenu();
      onClose?.();
      addNewItem("PatientDocument", {
        shouldUploadDocument: false,
        documentTemplate: template,
      });
    },
  }));

  return [
    {
      icon: "upload" as const,
      text: t("patient_view.document.upload"),
      bottomSeparator: true,
      onClick: (closeMenu: () => void) => {
        closeMenu();
        onClose?.();
        addNewItem("PatientDocument", {
          shouldUploadDocument: true,
          appointmentUuid,
        });
      },
    },
    templatesItems,
    {
      icon: "add" as const,
      text: t("patient_view.document.blank"),
      topSeparator: true,
      onClick: (closeMenu: () => void) => {
        closeMenu();
        onClose?.();
        addNewItem("PatientDocument", {
          shouldUploadDocument: false,
          appointmentUuid,
        });
      },
    },
  ]
    .flat()
    .filterNotNull();
};
