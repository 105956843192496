import { ReactNode } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { Icon } from "components/Icon/Icon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { useUser } from "contexts/User/UserContext";
import { ExperienceTagStatus, TagFragment } from "generated/provider";
import { useSearch } from "hooks/useDebounce";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { TagRow } from "./TagPill";

const DESKTOP_MENU_WIDTH = 280;

export type TagsMenuItem = { type: TagFragment; status?: ExperienceTagStatus };

export const TagsMenu = ({
  tagsList,
  addTagLabel,
  displayTag,
  className,
  strokeHover,
  forceBottomLeft,
}: {
  tagsList: TagsMenuItem[];
  displayTag: (tag: TagsMenuItem) => ReactNode;
  addTagLabel?: string;
  className?: string;
  strokeHover?: boolean;
  forceBottomLeft?: boolean;
}) => {
  const t = useTranslation();
  const { hasPermission } = useUser();
  const inputProps = useSearch();
  const isDesktop = useIsDesktop();

  const hasResults = tagsList.some((tag) =>
    tag.type.name.fuzzyMatch(inputProps.value),
  );

  const menuDisabled = !hasPermission("ANSWER_QA_EXPERIENCE");

  return (
    <UncontrolledPopover
      position={
        isDesktop
          ? forceBottomLeft
            ? (rect) =>
                window.innerWidth - rect.left > DESKTOP_MENU_WIDTH + 10
                  ? ["bottom-left"]
                  : ["bottom-left", "bottom-right"]
            : ["bottom-left", "bottom-right"]
          : "bottom"
      }
      className="mt-6 flex-col overflow-hidden"
      style={{
        maxHeight: "min(500px, 80%)",
        width: isDesktop ? DESKTOP_MENU_WIDTH : undefined,
      }}
      fullWidthOnMobile
      allowScrolling
      content={() => (
        <>
          <div className="relative flex items-center">
            <Icon name="search" className="absolute left-8" />
            <input
              {...inputProps}
              className="w-full h-40 pl-36 pr-8"
              placeholder={t(
                "inboxes.qa_experience.qa_experience_header.tags_menu.search",
              )}
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="flex-fill overflow-auto border-t">
            {hasResults ? (
              <div className="flex-col">
                {tagsList
                  .filter((tag) => tag.type.name.fuzzyMatch(inputProps.value))
                  .map((tag) => displayTag(tag))}
              </div>
            ) : (
              <div className="p-12 text-14 opacity-70">
                {t(
                  "inboxes.qa_experience.qa_experience_header.tags_menu.no_result",
                )}
              </div>
            )}
          </div>
          <Link
            className="border-t flex items-center space-x-12 py-8 px-12 font-medium hover:bg-grey-200"
            to={`${routes.PREFERENCES}/${routes.TAGS}`}
          >
            <Icon name="edit" className="mr-12" />
            {t(
              "inboxes.qa_experience.qa_experience_header.tags_menu.edit_tags",
            )}
          </Link>
        </>
      )}
    >
      {({ setTarget }) => (
        <>
          <button
            className={classNames(
              "flex rounded-sm",
              className,
              addTagLabel ? "py-2 pl-4 pr-8" : "p-2",
              strokeHover ? "hover:bg-grey-200" : "hover:bg-grey-100",
              {
                "opacity-70": menuDisabled,
              },
            )}
            onClick={setTarget}
            disabled={menuDisabled}
          >
            <div className="flex items-center">
              <Icon name="add" size={20} />
              {addTagLabel && (
                <span className="ml-2" style={{ lineHeight: "20px" }}>
                  {addTagLabel}
                </span>
              )}
            </div>
          </button>
          <div className="pr-1 flex flex-fill" />
        </>
      )}
    </UncontrolledPopover>
  );
};

export const AddTagRow = ({
  tag,
  addTag,
  disabled,
}: {
  tag: TagsMenuItem;
  addTag: (tag: TagsMenuItem) => void;
  disabled?: boolean;
}) => (
  <button disabled={disabled} onClick={() => addTag(tag)} className="group">
    <TagRow tag={tag.type} />
  </button>
);
