import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Switch } from "components/Form/Switch/Switch";
import { MultiToggleForm } from "components/MultiToggleForm/MultiToggleForm";
import { Query } from "components/Query/Query";
import { useUser } from "contexts/User/UserContext";
import {
  DoctorEmailNotificationType,
  GetDoctorNotificationSettings,
  UpdateDoctorNotificationSettings,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { UnionMatcher } from "types/utils";
import { notifier } from "utils/notifier";

gql`
  query GetDoctorNotificationSettings {
    me {
      doctor {
        uuid
        enabledEmailNotifications
        newMessageSoundEnabled
      }
    }
  }

  mutation UpdateDoctorNotificationSettings($input: UpdateDoctorProfileInput!) {
    updateDoctorProfile(payload: $input) {
      doctor {
        uuid
        enabledEmailNotifications
        newMessageSoundEnabled
      }
    }
  }
`;

export const DoctorNotifications = () => {
  const t = useTranslation();
  const { hasPermission } = useUser();
  const [updateDoctorNotifications] = useMutation(
    UpdateDoctorNotificationSettings,
    {
      onSuccess: () =>
        notifier.success(t("preferences.doctor_email_notifications.updated")),
    },
  );

  type AppointmentNotificationType = UnionMatcher<
    DoctorEmailNotificationType,
    `APPOINTMENT_${string}`
  >;
  type ExperienceNotificationType = UnionMatcher<
    DoctorEmailNotificationType,
    `EXPERIENCE_${string}`
  >;

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <h1 className="text-primary-dark text-24 font-bold">
        {t("preferences.doctor_email_notifications.title")}
      </h1>
      <>
        {
          // Messaging sound
          hasPermission("EDIT_PATIENT_EXPERIENCE") && (
            <Query query={GetDoctorNotificationSettings}>
              {({ doctor }) => (
                <div className="flex-col bg-white rounded border p-32 space-y-20">
                  <div className="flex justify-between pb-20 border-b">
                    <div className="flex-col ">
                      <div className="font-bold text-18 text-primary-dark">
                        {t(
                          "preferences.doctor_email_notifications.console_notifications_title",
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex-col">
                      <div className="font-normal text-16 text-primary-dark">
                        {t(
                          "preferences.doctor_email_notifications.new_message_sound",
                        )}
                      </div>
                    </div>
                    <Switch
                      checked={doctor.newMessageSoundEnabled}
                      onChange={(soundActivated) =>
                        updateDoctorNotifications({
                          input: {
                            newMessageSoundEnabled: soundActivated,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </Query>
          )
        }
        {
          // Messaging emails
          hasPermission("EDIT_PATIENT_EXPERIENCE") && (
            <Query query={GetDoctorNotificationSettings}>
              {({ doctor }) => (
                <div className="flex-col bg-white rounded border p-32">
                  <MultiToggleForm<ExperienceNotificationType>
                    title={t(
                      "preferences.doctor_email_notifications.experience_main_toggle_title",
                    )}
                    toggles={{
                      EXPERIENCE_ASSIGNED_NEW_MESSAGE: [
                        t(
                          "preferences.doctor_email_notifications.experience_assigned_new_message_toggle_title",
                        ),
                      ],
                      EXPERIENCE_UNASSIGNED_NEW_MESSAGE: [
                        t(
                          "preferences.doctor_email_notifications.experience_unassigned_new_message_toggle_title",
                        ),
                        t(
                          "preferences.doctor_email_notifications.experience_unassigned_new_message_toggle_subtitle",
                        ),
                      ],
                      EXPERIENCE_ASSIGNMENT: [
                        t(
                          "preferences.doctor_email_notifications.experience_assignment_toggle_title",
                        ),
                      ],
                    }}
                    enabledToggles={
                      doctor.enabledEmailNotifications.filter((v) =>
                        v.startsWith("EXPERIENCE_"),
                      ) as ExperienceNotificationType[]
                    }
                    onSubmit={(newEnabled) =>
                      updateDoctorNotifications({
                        input: {
                          enabledEmailNotifications: [
                            ...newEnabled,
                            ...doctor.enabledEmailNotifications.filter(
                              (v) => !v.startsWith("EXPERIENCE"),
                            ),
                          ],
                        },
                      })
                    }
                  />
                </div>
              )}
            </Query>
          )
        }
        {
          // Appointment
          hasPermission("EDIT_APPOINTMENT") && (
            <Query query={GetDoctorNotificationSettings}>
              {({ doctor }) => (
                <div className="flex-col bg-white rounded border p-32">
                  <MultiToggleForm<AppointmentNotificationType>
                    title={t(
                      "preferences.doctor_email_notifications.appointment_main_toggle_title",
                    )}
                    toggles={{
                      APPOINTMENT_STATUS: [
                        t(
                          "preferences.doctor_email_notifications.appointment_status_toggle_title",
                        ),
                      ],
                      APPOINTMENT_REMINDER: [
                        t(
                          "preferences.doctor_email_notifications.appointment_reminder_toggle_title",
                        ),
                        t(
                          "preferences.doctor_email_notifications.appointment_reminder_toggle_subtitle",
                        ),
                      ],
                      APPOINTMENT_AVAILABILITY_UPDATE: [
                        t(
                          "preferences.doctor_email_notifications.appointment_availability_update_toggle_title",
                        ),
                        t(
                          "preferences.doctor_email_notifications.appointment_availability_update_toggle_subtitle",
                        ),
                      ],
                    }}
                    enabledToggles={
                      doctor.enabledEmailNotifications.filter((v) =>
                        v.startsWith("APPOINTMENT_"),
                      ) as AppointmentNotificationType[]
                    }
                    onSubmit={(newEnabled) =>
                      updateDoctorNotifications({
                        input: {
                          enabledEmailNotifications: [
                            ...newEnabled,
                            ...doctor.enabledEmailNotifications.filter(
                              (v) => !v.startsWith("APPOINTMENT"),
                            ),
                          ],
                        },
                      })
                    }
                  />
                </div>
              )}
            </Query>
          )
        }
      </>
    </Background>
  );
};
