import { EmojiPicker } from "components/EmojiPicker/EmojiPicker";
import { useMaybeExperience } from "contexts/Experience/ExperienceContext";
import { insertText } from "utils";

export const ExperienceEmojiPicker = ({
  className,
}: {
  className?: string;
}) => {
  const { textAreaRef } = useMaybeExperience();

  return (
    <EmojiPicker
      className={className}
      onSelect={(emoji) => {
        textAreaRef.current?.focus();
        insertText(emoji);
      }}
    />
  );
};
