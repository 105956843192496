import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import * as Yup from "yup";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { NotFound } from "components/ErrorPage/NotFound";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { MoreMenu } from "components/Menu/MoreMenu";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Query } from "components/Query/Query";
import { useUser } from "contexts/User/UserContext";
import {
  AppointmentCategories,
  AppointmentTypeFragment,
  AppointmentTypes,
  CreateAppointmentType,
  DeleteAppointmentType,
  UpdateAppointmentType,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { copyToClipBoard } from "utils";
import {
  addAppointmentTypeInCache,
  removeAppointmentTypeInCache,
} from "utils/apollo";
import { notifier } from "utils/notifier";

gql`
  query AppointmentTypes {
    appointmentTypes {
      ...AppointmentType
    }
  }

  mutation CreateAppointmentType($appointmentTypeInput: AppointmentTypeInput!) {
    createAppointmentType(input: $appointmentTypeInput) {
      type {
        ...AppointmentType
      }
    }
  }

  mutation UpdateAppointmentType(
    $uuid: UUID!
    $appointmentTypeInput: AppointmentTypeInput!
  ) {
    updateAppointmentType(uuid: $uuid, input: $appointmentTypeInput) {
      type {
        ...AppointmentType
      }
    }
  }

  mutation DeleteAppointmentType($uuid: UUID!) {
    deleteAppointmentType(uuid: $uuid) {
      appointmentTypeUuid
    }
  }
`;

type FormValues = {
  name: string;
  durationInMinutes: Int;
};

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

export const SchedulingSettingsTypes = ({
  newType,
  onHideNewType,
}: {
  newType: boolean;
  onHideNewType: () => void;
}) => {
  const { hasPermission } = useUser();

  if (!hasPermission("EDIT_APPOINTMENT_CATEGORIES")) return <NotFound />;

  return (
    <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
      {newType && (
        <AppointmentTypeForm
          appointmentType={undefined}
          onHide={onHideNewType}
        />
      )}
      <Query query={AppointmentTypes}>
        {(types) =>
          types.isEmpty() && !newType ? (
            <EmptyTypesPlaceholder />
          ) : (
            <>
              {types.map((appointmentType) => (
                <AppointmentTypeForm
                  key={appointmentType.uuid}
                  appointmentType={appointmentType}
                  onHide={onHideNewType}
                />
              ))}
            </>
          )
        }
      </Query>
    </Background>
  );
};

const EmptyTypesPlaceholder = () => {
  const t = useTranslation();

  return (
    <div className="flex-col w-full flex-center space-y-4 my-80">
      <div className="text-primary-dark font-bold text-18">
        {t("settings.scheduling.types.empty_state_title")}
      </div>
      <div>{t("settings.scheduling.types.empty_state_subtitle")}</div>
    </div>
  );
};

const AppointmentTypeForm = ({
  appointmentType,
  onHide,
}: {
  appointmentType?: AppointmentTypeFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();

  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>(undefined);

  const [createAppointmentType, createAppointmentTypeLoading] = useMutation(
    CreateAppointmentType,
    {
      onSuccess: (output, client) => {
        addAppointmentTypeInCache(client, output.type);
        onHide();
        notifier.success(t("settings.scheduling.types.created_notifier"));
      },
    },
  );

  const [updateAppointmentType, updateAppointmentTypeLoading] = useMutation(
    UpdateAppointmentType,
    {
      onSuccess: () =>
        notifier.success(t("settings.scheduling.types.updated_notifier")),
    },
  );

  const { refetch } = useQuery(AppointmentCategories);

  const [deleteAppointmentType] = useMutation(DeleteAppointmentType, {
    onSuccess: (output, client) => {
      removeAppointmentTypeInCache(client, output.appointmentTypeUuid);
      refetch(); // in case there's a cascade delete of a category
      notifier.success(t("settings.scheduling.types.deleted_notifier"));
    },
  });

  return (
    <>
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("settings.scheduling.types.delete_appointment_type_title")}
          suffix={t("settings.scheduling.types.delete_appointment_type_suffix")}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <Form<FormValues>
        className="flex-fill bg-white rounded border"
        initialValues={{
          name: appointmentType?.name ?? "",
          durationInMinutes: appointmentType?.callDurationMinutes ?? 15,
        }}
        validationSchema={{
          name: "required",
          durationInMinutes: Yup.number().min(
            1,
            t("settings.scheduling.types.duration_validation"),
          ),
        }}
        onSubmit={(values) => {
          const input = {
            name: values.name,
            callDurationMinutes: values.durationInMinutes,
          };
          appointmentType === undefined
            ? createAppointmentType({
                appointmentTypeInput: input,
              })
            : updateAppointmentType({
                uuid: appointmentType.uuid,
                appointmentTypeInput: input,
              });
        }}
      >
        <MoreMenu
          className={classNames(
            "ml-auto rounded p-8 hover:bg-grey-100 mt-4 mr-4",
            appointmentType === undefined && "invisible",
          )}
          position="left"
          items={[
            {
              icon: "clipboard",
              text: t("settings.scheduling.types.copy_id_label"),
              onClick: (close) => {
                close();
                if (appointmentType === undefined) return;
                copyToClipBoard(
                  appointmentType.uuid,
                  t("settings.scheduling.types.copy_id_success"),
                );
              },
            },
          ]}
        />
        <div className="flex-col space-y-20 p-32 pt-0">
          <div className="flex items-center space-x-24">
            <FormInput
              wrapperClassName="flex-fill justify-between h-full"
              name="name"
              label={t("settings.scheduling.types.name_label")}
            />
            <FormInput
              wrapperClassName="flex-shrink-0 flex-grow basis-auto justify-between w-1/3 h-full"
              name="durationInMinutes"
              label={t("settings.scheduling.types.duration_label")}
              type="number"
              step={5}
            />
          </div>
          <div className="flex justify-between">
            <Button
              label={t("settings.scheduling.types.form_delete")}
              danger
              secondary
              onClick={() => {
                appointmentType === undefined
                  ? onHide()
                  : setConfirmationModalState({
                      onConfirm: (close) => {
                        close();
                        return deleteAppointmentType({
                          uuid: appointmentType.uuid,
                        });
                      },
                    });
              }}
            />
            <FormState<FormValues>>
              {({ values }) => (
                <Submit
                  label={t("settings.scheduling.types.form_save")}
                  loading={
                    createAppointmentTypeLoading || updateAppointmentTypeLoading
                  }
                  disabled={
                    appointmentType !== undefined &&
                    appointmentType.name === values.name &&
                    appointmentType.callDurationMinutes ===
                      values.durationInMinutes
                  }
                />
              )}
            </FormState>
          </div>
        </div>
      </Form>
    </>
  );
};
