import { useState } from "react";

import { useDoctor } from "contexts/User/UserContext";
import {
  CreateBravadoPrescription,
  CreateOrGetPhotonPatient,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

import { usePatientTimelineLocalState } from "../PatientTimelineLocalStateContext";

export const useAddPrescription = (
  patientUuid: UUID,
  appointmentUuid?: UUID,
): {
  addPrescription: (prescriptionTemplate?: {
    title: string;
    text: string;
  }) => Promise<void>;
  isAddingPrescription: boolean;
} => {
  const { bravadoEnabled, photonEnabled } = useDoctor();
  const { addNewItem } = usePatientTimelineLocalState();
  const [createBravadoPrescription] = useMutation(CreateBravadoPrescription);
  const [createOrGetPhotonPatient] = useMutation(CreateOrGetPhotonPatient);
  const t = useTranslation();
  const [isAddingPrescription, setIsAddingPrescription] = useState(false);

  const addPrescription = (prescriptionTemplate?: {
    title: string;
    text: string;
  }) => {
    if (isAddingPrescription) return Promise.resolve();

    if (bravadoEnabled) {
      setIsAddingPrescription(true);
      return createBravadoPrescription({
        input: {
          patientUuid,
          title: prescriptionTemplate?.title ?? t("patient_view.prescription"),
          appointmentUuid,
        },
      })
        .then((res) => {
          if (res) {
            window
              .open(res.prescription.bravadoEncounterUrl, "_blank")
              ?.focus();
          }
        })
        .finally(() => {
          setIsAddingPrescription(false);
        });
    }

    if (photonEnabled) {
      setIsAddingPrescription(true);
      return createOrGetPhotonPatient({
        input: {
          patientUuid,
        },
      })
        .then((res) => {
          if (res) window.open(res.newPrescriptionUrl, "_blank")?.focus();
        })
        .finally(() => {
          setIsAddingPrescription(false);
        });
    }

    addNewItem("NablaPrescription", { appointmentUuid, prescriptionTemplate });
    return Promise.resolve();
  };

  return { addPrescription, isAddingPrescription };
};
