import { useState } from "react";
import gql from "graphql-tag";

import { ButtonWithMenu } from "components/Button/ButtonWithMenu";
import { FormDateAndHour } from "components/Form/DatePicker/FormDateAndHour";
import { FormModal } from "components/Modal/FormModal";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { ScheduleMessage, UpdateIsTyping } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";
import { useSendExistingExperienceButtonsUtils } from "./utils";

gql`
  mutation ScheduleMessage(
    $experienceUuid: UUID!
    $input: ScheduleMessageInput!
  ) {
    scheduleMessage(experienceUuid: $experienceUuid, input: $input) {
      experience {
        ...ScheduleMessages
      }
    }
  }

  fragment ScheduleMessages on Experience {
    uuid
    scheduledMessages {
      uuid
      textContent
      createdAt
      scheduledAt
      isOutdated
      author {
        ...DoctorSummary
      }
    }
  }
`;

export const SendButton = ({ disabled }: { disabled?: boolean }) => {
  const t = useTranslation();

  const { experience, scheduledMessages, replyMessage, instantScroll } =
    useQAExperience();
  const { mediaMessages, messageText, clearDraft } = useExperienceDraft();

  const { canSendMessage, sendMessageAndAddDoctorIfNeeded, joiningExperience } =
    useSendExistingExperienceButtonsUtils();

  const [modalOpened, setModalOpened] = useState(false);
  const [send] = useMutation(ScheduleMessage, {
    onSuccess: () => instantScroll(),
  });

  const [setTyping] = useMutation(UpdateIsTyping, {
    skipIfImpersonation: true,
    onError: () => undefined, // Don't notify user
  });

  const canSendLater =
    scheduledMessages.isEmpty() &&
    mediaMessages.isEmpty() &&
    messageText.isNotBlank() &&
    !replyMessage;

  return (
    <>
      <ButtonWithMenu
        label={t("inboxes.qa_experience.qa_footer.send_button.send")}
        onClick={sendMessageAndAddDoctorIfNeeded}
        disabled={!canSendMessage || disabled}
        menuDisabled={!canSendLater || joiningExperience || disabled}
        wrapperClassName="h-32"
        menuItems={[
          {
            text: t("inboxes.qa_experience.qa_footer.send_button.send_later"),
            className: "text-body",
            onClick: (close) => {
              close();
              setModalOpened(true);
            },
          },
        ]}
      />
      {modalOpened && (
        <FormModal<{ scheduledAt: ISOString | undefined }>
          title={t(
            "inboxes.qa_experience.qa_footer.send_button.pick_date__time",
          )}
          initialValues={{ scheduledAt: undefined }}
          validationSchema={{ scheduledAt: "required" }}
          validate={({ scheduledAt }) => {
            if (scheduledAt?.isPast()) {
              return {
                scheduledAt: t("send_button.choose_a_date_in_the_future"),
              };
            }
            return {};
          }}
          onSubmit={({ scheduledAt }) =>
            send(
              {
                experienceUuid: experience.uuid,
                input: {
                  textContent: messageText.trim(),
                  scheduledAt: scheduledAt!,
                },
              },
              {
                onSuccess: () => {
                  clearDraft();
                  setModalOpened(false);
                  setTyping({
                    experienceUuid: experience.uuid,
                    isTyping: false,
                  });
                },
              },
            )
          }
          onHide={() => setModalOpened(false)}
          submitLabel={t(
            "inboxes.qa_experience.qa_footer.send_button.schedule_send",
          )}
        >
          <FormDateAndHour name="scheduledAt" />
        </FormModal>
      )}
    </>
  );
};
