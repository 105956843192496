import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormPasswordInput } from "components/Form/Input/PasswordInput";
import { Switch } from "components/Form/Switch/Switch";
import { Link } from "components/Link/Link";
import { Query } from "components/Query/Query";
import { useDoctor } from "contexts/User/UserContext";
import {
  GetSubOrganizationSettings,
  UpdateSubOrganizationSettings,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";
import { routes } from "routes";
import { notifier } from "utils/notifier";

import aperoLogo from "./aperoLogo.png";
import bravadoLogo from "./bravadoLogo.png";
import googleLogo from "./googleLogo.png";
import photonLogo from "./photonLogo.png";

gql`
  fragment SubOrganizationSettings on SubOrganization {
    uuid
    aperoKey
    aperoFacilityId

    bravadoCredentials {
      clientId
      clientSecret
      webhookSettings {
        url
        username
        secret
      }
    }
    photonCredentials {
      clientId
      clientSecret
      webhookSettings {
        secret
        url
      }
    }
    googleCalendarSyncEnabled
  }

  query GetSubOrganizationSettings {
    me {
      doctor {
        subOrganization {
          ...SubOrganizationSettings
        }
      }
    }
  }

  mutation UpdateSubOrganizationSettings($input: OrganizationSettingsInput!) {
    updateOrganizationSettings(input: $input) {
      doctor {
        uuid
        subOrganization {
          ...SubOrganizationSettings
        }
      }
    }
  }
`;

type AperoFormValues = {
  key?: string | null;
  facilityId?: string | null;
};

type BravadoFormValues = {
  clientId?: string | null;
  clientSecret?: string | null;
  webhookUsername?: string | null;
  webhookSecret?: string | null;
};

type PhotonFormValues = {
  clientId?: string | null;
  clientSecret?: string | null;
  webhookSecret?: string | null;
  webhookUrl?: string | null;
};

export const Integrations = () => {
  const t = useTranslation();
  const { user, hasAccessToGatekeeperFeature } = useDoctor();
  const [updateSettings] = useMutation(UpdateSubOrganizationSettings, {
    onSuccess: () =>
      notifier.success(
        t("settings.integrations.integrations.settings_updated"),
      ),
  });

  const [updateGoogleSettings, googleSettingsIsLoading] = useMutation(
    UpdateSubOrganizationSettings,
    {
      onSuccess: () =>
        notifier.success(
          t("settings.integrations.integrations.settings_updated"),
        ),
    },
  );

  const [showApero, setShowApero] = useState(false);

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("settings.integrations.integrations.integrations")}
        </h1>
        <div>
          {t(
            "settings.integrations.integrations.you_can_activate_these_supported_third_party_apps_and_apis_in_the_console_here",
          )}
        </div>
      </div>
      <Query query={GetSubOrganizationSettings}>
        {(data) => (
          <div className="items-center flex-col space-y-24">
            <Form<AperoFormValues>
              initialValues={{
                key: data.doctor.subOrganization.aperoKey,
                facilityId: data.doctor.subOrganization.aperoFacilityId,
              }}
              onSubmit={async (values) =>
                updateSettings({
                  input: {
                    aperoKey: values.key,
                    aperoFacilityId: values.facilityId,
                  },
                })
              }
              className="flex-col w-full items-start space-y-12 p-32 bg-white border rounded shadow-sm-outlined"
            >
              <img src={aperoLogo} style={{ maxHeight: 40 }} alt="apero" />
              <Switch
                checked={
                  showApero || data.doctor.subOrganization.aperoKey !== ""
                }
                label={t("settings.integrations.integrations.apero")}
                disabled={googleSettingsIsLoading}
                onChange={(enabled) => {
                  if (!enabled) {
                    updateSettings({
                      input: {
                        aperoKey: "",
                        aperoFacilityId: "",
                      },
                    });
                  }
                  setShowApero(enabled);
                }}
              />
              {(showApero || data.doctor.subOrganization.aperoKey !== "") && (
                <>
                  <div className="">
                    {t(
                      "settings.integrations.integrations.apero.intro.configure_with_your_",
                    )}
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://app.aperohealth.com/dash/organization"
                    >
                      {t(
                        "settings.integrations.integrations.in_sentence.api_key",
                      )}
                    </a>
                    {t(
                      "settings.integrations.integrations.apero.intro.and_your",
                    )}
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://app.aperohealth.com/dash/organization/facilities"
                    >
                      {t(
                        "settings.integrations.integrations.in_sentence.facility_id",
                      )}
                      .
                    </a>
                  </div>
                  <FormPasswordInput
                    name="key"
                    autoComplete="new-password" // To prevent chrome from prefilling it with email+password
                    label={t(
                      "settings.integrations.integrations.apero.apero_key",
                    )}
                  />
                  <FormInput
                    name="facilityId"
                    label={t(
                      "settings.integrations.integrations.apero.apero_facility_id",
                    )}
                  />
                  <Submit
                    requiresDirty
                    label={t("settings.integrations.integrations.save")}
                  />
                </>
              )}
            </Form>
            <div className="flex-col w-full items-start space-y-12 p-32 bg-white border rounded shadow-sm-outlined">
              <img src={googleLogo} style={{ maxHeight: 40 }} alt="google" />
              {user.googleLoginEnabled &&
              hasAccessToGatekeeperFeature("GOOGLE_CALENDAR_SYNC") ? (
                <Switch
                  checked={
                    data.doctor.subOrganization.googleCalendarSyncEnabled
                  }
                  label={t(
                    "settings.integrations.integrations.google.sync_google_calendar",
                  )}
                  disabled={googleSettingsIsLoading}
                  onChange={(enabled) =>
                    updateGoogleSettings({
                      input: {
                        googleCalendarSyncEnabled: enabled,
                      },
                    })
                  }
                />
              ) : (
                <div>
                  <Translation
                    k="settings.integrations.integrations.google"
                    components={{
                      a: ({ children }) => (
                        <Link
                          className="underline"
                          to={`${routes.HELP}`}
                          children={children}
                        />
                      ),
                    }}
                  />
                </div>
              )}
            </div>
            <Form<BravadoFormValues>
              initialValues={{
                clientId:
                  data.doctor.subOrganization.bravadoCredentials?.clientId,
                clientSecret:
                  data.doctor.subOrganization.bravadoCredentials?.clientSecret,
                webhookUsername:
                  data.doctor.subOrganization.bravadoCredentials
                    ?.webhookSettings.username,
                webhookSecret:
                  data.doctor.subOrganization.bravadoCredentials
                    ?.webhookSettings.secret,
              }}
              onSubmit={async (values) => {
                const credentialsUpdate =
                  values.clientId?.isNotBlank() &&
                  values.clientSecret?.isNotBlank()
                    ? {
                        clientId: values.clientId,
                        clientSecret: values.clientSecret,
                      }
                    : null;
                return updateSettings({
                  input: {
                    bravadoCredentials: credentialsUpdate,
                  },
                });
              }}
              validate={({ clientId, clientSecret }) => ({
                ...((clientId === null ||
                  clientId === undefined ||
                  clientId.isBlank()) &&
                  clientSecret?.isNotBlank() && {
                    clientId: t(
                      "settings.integrations.integrations.bravado.credentials_error",
                    ),
                  }),
                ...((clientSecret === null ||
                  clientSecret === undefined ||
                  clientSecret.isBlank()) &&
                  clientId?.isNotBlank() && {
                    clientSecret: t(
                      "settings.integrations.integrations.bravado.credentials_error",
                    ),
                  }),
              })}
              className="flex-col w-full items-start space-y-12 p-32 bg-white border rounded shadow-sm-outlined"
            >
              <img src={bravadoLogo} style={{ maxHeight: 40 }} alt="bravado" />
              <div className="">
                {t(
                  "settings.integrations.integrations.bravado.intro.configure_with_your_",
                )}
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://ayva-demo.dev.bravadocloud.com/#!/app/account/facility-view"
                >
                  {t("settings.integrations.integrations.in_sentence.api_key")}
                </a>
                {t("settings.integrations.integrations.bravado.intro.and_your")}
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://ayva-demo.dev.bravadocloud.com/#!/app/account/facility-view"
                >
                  {t("settings.integrations.integrations.in_sentence.secret")}.
                </a>
              </div>
              <FormInput
                name="clientId"
                label={t(
                  "settings.integrations.integrations.bravado.client_id",
                ).upperFirst()}
              />
              <FormPasswordInput
                name="clientSecret"
                autoComplete="new-password" // To prevent chrome from prefilling it with email+password
                label={t(
                  "settings.integrations.integrations.bravado.client_secret",
                )}
              />
              {data.doctor.subOrganization.bravadoCredentials && (
                <>
                  <a>
                    {t(
                      "settings.integrations.integrations.bravado.webhook_url",
                    ) +
                      data.doctor.subOrganization.bravadoCredentials
                        .webhookSettings.url}
                  </a>
                  <FormInput
                    name="webhookUsername"
                    label={t(
                      "settings.integrations.integrations.bravado.webhook_username",
                    ).upperFirst()}
                    readOnly
                  />
                  <FormPasswordInput
                    name="webhookSecret"
                    autoComplete="new-password" // To prevent chrome from prefilling it with email+password
                    label={t(
                      "settings.integrations.integrations.bravado.webhook_secret",
                    )}
                    readOnly
                  />
                </>
              )}
              <Submit
                requiresDirty
                label={t("settings.integrations.integrations.save")}
              />
            </Form>
            <Form<PhotonFormValues>
              initialValues={{
                clientId:
                  data.doctor.subOrganization.photonCredentials?.clientId,
                clientSecret:
                  data.doctor.subOrganization.photonCredentials?.clientSecret,
                webhookSecret:
                  data.doctor.subOrganization.photonCredentials?.webhookSettings
                    .secret,
                webhookUrl:
                  data.doctor.subOrganization.photonCredentials?.webhookSettings
                    .url,
              }}
              onSubmit={async (values) => {
                const credentialsUpdate =
                  values.clientId?.isNotBlank() &&
                  values.clientSecret?.isNotBlank()
                    ? {
                        clientId: values.clientId,
                        clientSecret: values.clientSecret,
                      }
                    : null;
                return updateSettings({
                  input: {
                    photonCredentials: credentialsUpdate,
                  },
                });
              }}
              validate={({ clientId, clientSecret }) => ({
                ...((clientId === null ||
                  clientId === undefined ||
                  clientId.isBlank()) &&
                  clientSecret?.isNotBlank() && {
                    clientId: t(
                      "settings.integrations.integrations.photon.credentials_error",
                    ),
                  }),
                ...((clientSecret === null ||
                  clientSecret === undefined ||
                  clientSecret.isBlank()) &&
                  clientId?.isNotBlank() && {
                    clientSecret: t(
                      "settings.integrations.integrations.photon.credentials_error",
                    ),
                  }),
              })}
              className="flex-col w-full items-start space-y-12 p-32 bg-white border rounded shadow-sm-outlined"
            >
              <img src={photonLogo} style={{ maxHeight: 18 }} alt="photon" />
              <div className="">
                {t(
                  "settings.integrations.integrations.photon.intro.configure_with_your_",
                )}
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.photon.health/settings/user"
                >
                  {t("settings.integrations.integrations.in_sentence.api_key")}
                </a>
                {t("settings.integrations.integrations.photon.intro.and_your")}
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.photon.health/settings/user"
                >
                  {t("settings.integrations.integrations.in_sentence.secret")}.
                </a>
              </div>
              <FormInput
                name="clientId"
                label={t(
                  "settings.integrations.integrations.photon.client_id",
                ).upperFirst()}
              />
              <FormPasswordInput
                name="clientSecret"
                autoComplete="new-password" // To prevent chrome from prefilling it with email+password
                label={t(
                  "settings.integrations.integrations.photon.client_secret",
                )}
              />
              {data.doctor.subOrganization.photonCredentials && (
                <>
                  <p>
                    {t(
                      "settings.integrations.integrations.photon.webhook_setup",
                    )}
                  </p>
                  <FormInput
                    name="webhookUrl"
                    label={t(
                      "settings.integrations.integrations.photon.webhook_url",
                    ).upperFirst()}
                    readOnly
                  />
                  <FormPasswordInput
                    name="webhookSecret"
                    autoComplete="new-password" // To prevent chrome from prefilling it with email+password
                    label={t(
                      "settings.integrations.integrations.photon.webhook_secret",
                    )}
                    readOnly
                  />
                </>
              )}
              <Submit
                requiresDirty
                label={t("settings.integrations.integrations.save")}
              />
            </Form>
          </div>
        )}
      </Query>
    </Background>
  );
};
