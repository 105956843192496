import { ReactElement, ReactNode, useEffect } from "react";
import gql from "graphql-tag";

import { IdentityType } from "auth/AuthContext";
import { Query } from "components/Query/Query";
import {
  CopilotApiUserRole,
  CurrentCopilotApiUser,
  CurrentCopilotApiUserData,
} from "generated/copilot-api-user";
import {
  CurrentDoctor,
  CurrentDoctorData,
  DoctorRole,
  MeasurementSystem,
  MeasurementSystemKnownValues,
  SupportedLocale,
} from "generated/provider";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useSetLocaleAndTimezone } from "hooks/useSetLocaleAndTimezone";
import { isKnownValue } from "utils/enum";
import { setMeasurementSystem } from "utils/measurementSystem";

import { UserContext } from "./UserContext";

gql`
  query CurrentDoctor {
    me {
      doctor {
        ...DoctorSummary
        ...UserPersonalInformation

        subOrganization {
          ...SubOrganizationSummary
        }

        newMessageSoundEnabled

        # permissions
        roles
        permissions
        canAccessOrganizationUserApi
        canDoTeleconsultation
        gatekeeperFeatures

        aperoDoctor {
          id
        }

        # Google settings
        googleLoginEnabled
        hasGoogleCalendarSyncAuthorization
        googleCalendarSyncEnabled
      }
    }
  }

  fragment OrganizationSummary on Organization {
    uuid
    stringId
    displayName
    pinnedVersion
    singleConversationEnabled
    faxMonthlyThreshold
    whiteLabelEnabled
  }

  fragment SubOrganizationSummary on SubOrganization {
    uuid
    displayName
    avatar {
      ...FileUpload
    }
    color
    supportsApero
    explicitTimezone
    timezone
    organization {
      ...OrganizationSummary
    }
    externalConsoleUrl
    calendarStartOfWeek
    measurementSystem
    appointmentConfirmationConsents {
      firstConsentMarkdown
      secondConsentMarkdown
    }
    physicalAppointmentConfirmationConsents {
      firstConsentMarkdown
      secondConsentMarkdown
    }
    googleCalendarSyncEnabled
    bravadoEnabled
    photonEnabled
    whiteLabelTitle
    whiteLabelFaviconUrl
    whiteLabelDocumentSignature
  }
`;

gql`
  # schema = COPILOT_API_USER
  query CurrentCopilotApiUser {
    me {
      user {
        ...CopilotApiUserSummary

        roles
        permissions
        canAccessOrganizationUserApi

        subOrganization {
          uuid
          displayName
          timezone
          measurementSystem
          calendarStartOfWeek
          whiteLabelTitle
          whiteLabelFaviconUrl

          avatar {
            uuid
            urlV2 {
              url
              expiresAt
            }
          }

          organization {
            uuid
            stringId
            displayName
            whiteLabelEnabled
          }
        }
      }
    }
  }

  fragment CopilotApiUserSummary on CopilotApiUser {
    uuid
    email
    firstName
    lastName
    locale
    timezone
  }
`;

// TODO(@liautaud): This should not live this high in the component tree, but
//  the `AutomaticIdentitySwitcher` needs to live relatively high and needs to
//  access the organization and sub-organization of the current identity.
export const UserProvider = ({
  type,
  children,
}: {
  type: IdentityType | undefined;
  children: ReactElement;
}) => {
  switch (type) {
    case undefined:
      return children;
    case "DOCTOR":
      return (
        // @graphql-schema-usage-ignore: Doctors always have access to PROVIDER.
        <Query query={CurrentDoctor}>
          {({ doctor }) => (
            <DoctorProvider doctor={doctor} children={children} />
          )}
        </Query>
      );
    case "COPILOT_API_USER":
      return (
        // @graphql-schema-usage-ignore: Copilot API users always have access to COPILOT_API_USER.
        <Query query={CurrentCopilotApiUser}>
          {({ user }) => (
            <CopilotApiUserProvider user={user} children={children} />
          )}
        </Query>
      );
  }
};

const DoctorProvider = ({
  doctor,
  children,
}: {
  doctor: CurrentDoctorData["doctor"];
  children: ReactNode;
}) => {
  useApplyPreferences(
    doctor.locale,
    doctor.timezone,
    doctor.subOrganization.measurementSystem,
  );

  const hasRole = (role: DoctorRole | CopilotApiUserRole) =>
    doctor.roles.some((r) => r === role);

  return (
    <UserContext.Provider
      value={{
        type: "DOCTOR",
        user: doctor,
        uuid: doctor.uuid,
        email: doctor.email,
        isDesktop: useIsDesktop(),
        hasRole,
        hasPermission: (permission) =>
          doctor.permissions.some((p) => p === permission),
        hasAccessToGatekeeperFeature: (feature) =>
          doctor.gatekeeperFeatures.some((f) => f === feature),
        hasAccessToOrganizationUserApi: doctor.canAccessOrganizationUserApi,
        timezone: doctor.timezone,
        subOrganizationTimezone: doctor.subOrganization.timezone,
        subOrganizationSupportsApero: doctor.subOrganization.supportsApero,
        doctorSupportsApero: !!doctor.aperoDoctor,
        bravadoEnabled: doctor.subOrganization.bravadoEnabled,
        photonEnabled: doctor.subOrganization.photonEnabled,
        showOnboardingModal: hasRole("NABLA_MEDICAL_STAFF"),
        organizationStringId: doctor.subOrganization.organization.stringId,
        subOrganizationUuid: doctor.subOrganization.uuid,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const CopilotApiUserProvider = ({
  user,
  children,
}: {
  user: CurrentCopilotApiUserData["user"];
  children: ReactNode;
}) => {
  useApplyPreferences(
    user.locale,
    user.timezone,
    user.subOrganization.measurementSystem,
  );

  return (
    <UserContext.Provider
      value={{
        type: "COPILOT_API_USER",
        user,
        uuid: user.uuid,
        email: user.email,
        isDesktop: useIsDesktop(),
        hasRole: (role) => user.roles.some((r) => r === role),
        hasPermission: (permission) =>
          user.permissions.some((p) => p === permission),
        hasAccessToGatekeeperFeature: () => false,
        hasAccessToOrganizationUserApi: user.canAccessOrganizationUserApi,
        timezone: user.timezone,
        subOrganizationSupportsApero: false,
        doctorSupportsApero: false,
        bravadoEnabled: false,
        photonEnabled: false,
        showOnboardingModal: false,
        subOrganizationTimezone: user.subOrganization.timezone,
        organizationStringId: user.subOrganization.organization.stringId,
        subOrganizationUuid: user.subOrganization.uuid,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useApplyPreferences = (
  locale: SupportedLocale,
  timezone: string,
  measurementSystem: MeasurementSystem,
) => {
  useSetLocaleAndTimezone(locale, timezone);
  useEffect(() => {
    if (isKnownValue(measurementSystem, MeasurementSystemKnownValues)) {
      setMeasurementSystem(measurementSystem);
    }
  }, [measurementSystem]);
};
