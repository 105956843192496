import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { Anonymization } from "./Anonymization/Anonymization";
import { AnswersRanking } from "./AnswersRanking/AnswersRanking";
import { AperoInvoiceLab } from "./Apero/AperoInvoiceLab";
import { AperoPatientVerification } from "./Apero/AperoPatientVerification";
import { ExperienceTagsPrediction } from "./ExperienceTagPrediction/ExperienceTagsPrediction";
import { ICD10Normalization } from "./ICD10Normalization/ICD10Normalization";
import { LiveSpeechAnalysisPage } from "./LiveSpeechAnalysis/LiveSpeechAnalysis";
import { SpeechToText } from "./SpeechToText/SpeechToText";

export const Labs = () => {
  const t = useTranslation();

  return (
    <SidebarPage
      baseRoute={routes.LABS}
      title="Labs"
      items={[
        {
          to: routes.LIVE_SPEECH_ANALYSIS,
          icon: "headphones",
          label: t("labs.labs.live_speech_analysis"),
          Component: LiveSpeechAnalysisPage,
        },
        {
          to: routes.EXPERIENCE_TAGS_PREDICTION,
          icon: "books",
          label: t("labs.labs.tag_prediction"),
          Component: ExperienceTagsPrediction,
        },
        {
          to: routes.ICD10_NORMALIZATION,
          icon: "reporting",
          label: t("labs.labs.icd_normalization"),
          Component: ICD10Normalization,
        },
        {
          to: routes.ANONYMIZATION,
          icon: "incognito",
          label: t("labs.labs.anonymization"),
          Component: Anonymization,
        },
        {
          to: routes.ANSWERS_RANKING,
          icon: "trophy",
          label: t("labs.labs.answers_ranking"),
          Component: AnswersRanking,
        },
        {
          to: routes.SPEECH_TO_TEXT,
          icon: "speaker",
          label: t("labs.labs.speech_to_text"),
          Component: SpeechToText,
        },
        {
          to: routes.APERO_PATIENT_VERIFICATION,
          icon: "dollar",
          label: t("labs.labs.apero_patient_verification"),
          Component: AperoPatientVerification,
          hasAccess: ({ user }) =>
            user?.__typename === "Doctor" && user.subOrganization.supportsApero,
        },
        {
          to: `${routes.APERO_INVOICE}`,
          icon: "dollar",
          label: t("labs.labs.apero_invoice"),
          Component: AperoInvoiceLab,
          hasAccess: ({ user }) =>
            user?.__typename === "Doctor" && user.subOrganization.supportsApero,
        },
      ]}
      otherRoutes={[
        {
          to: `${routes.APERO_INVOICE}/:id`,
          Component: AperoInvoiceLab,
          hasAccess: ({ user }) =>
            user?.__typename === "Doctor" && user.subOrganization.supportsApero,
        },
      ]}
    />
  );
};
