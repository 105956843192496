import { ReactNode } from "react";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { BottomSafeArea } from "components/Mobile/SafeArea";

export const SearchPage = ({
  title,
  rightTitleElement,
  subtitle,
  back,
  skipScrollContainer,
  children,
}: {
  title: string | ReactNode;
  rightTitleElement?: ReactNode;
  subtitle?: string;
  back?: () => void;
  skipScrollContainer?: boolean;
  children: ReactNode;
}) => (
  <div className="flex-fill flex-col">
    <div className="flex items-center px-16 space-x-8 border-b min-h-[56px]">
      {back && (
        <ClickableIcon
          name="chevron"
          className="px-0"
          size={22}
          rotate={-180}
          onClick={() => back()}
        />
      )}
      <div className="font-medium leading-normal text-grey-400 flex-fill truncate mt-[0.5px]">
        {title}
      </div>
      {subtitle && <div className="text-13">{subtitle}</div>}
      {rightTitleElement}
    </div>
    {skipScrollContainer ? (
      children
    ) : (
      <div className="flex-fill flex-col p-20 overflow-auto">
        {children}
        <BottomSafeArea />
      </div>
    )}
  </div>
);
