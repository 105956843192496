// This file contains shared types for the auth between web and copilot.
// Do not modify without extra caution.
//
// We should not import external dependencies here because there is no guarantee
// that both codebases will have the same version of the dependency.

export const SIGN_IN_CALLBACK_PATH = "sign-in-callback";

export const SIGN_IN_CALLBACK_CHROME_PATH = "sign-in-callback-chrome";

// WARNING: if you change any of these two values, the Epic app must be updated to whitelist
// the new redirect URLs (see https://vendorservices.epic.com/Developer/Edit?appId=28166).
export const EPIC_LAUNCH_REDIRECT_PATH = "epic-launch-redirect";
export const EPIC_LAUNCH_REDIRECT_CHROME_PATH = "epic-launch-redirect-chrome";

// WARNING: if you change any of these two values, the Cerner app must be updated to whitelist
// the new redirect URLs (see https://code-console.cerner.com/console/apps).
export const CERNER_LAUNCH_REDIRECT_PATH = "cerner-launch-redirect";
export const CERNER_LAUNCH_REDIRECT_CHROME_PATH =
  "cerner-launch-redirect-chrome";

// WARNING: if you change any of these two values, the Athena app must be updated to whitelist
// the new redirect URLs (see https://developer.api.athena.io/ams-portal/apps/Nabla%20Copilot%20-%20preview).
export const ATHENA_LAUNCH_REDIRECT_PATH = "athena-launch-redirect";
export const ATHENA_LAUNCH_REDIRECT_CHROME_PATH =
  "athena-launch-redirect-chrome";

export type CopilotWebappAuthData = {
  copilotAssistantUserUuid: string;
  accessToken: string;
  refreshToken: string;
  nablaRegion: string;
};

// Kept this way to remain compatible with previous extension versions.
export type CopilotExtensionAuthData = {
  identity: string;
  accessToken: string;
  refreshToken: string;
  nablaRegion: string;
};

export type CopilotExtensionLoginMessage = CopilotExtensionAuthData & {
  type: "login";
};

export type CopilotExtensionEpicLaunchMessage = {
  type: "epic_launch";
  code: string;
  state: string;
};

export type CopilotExtensionCernerLaunchMessage = {
  type: "cerner_launch";
  code: string;
  state: string;
};

export type CopilotExtensionAthenaLaunchMessage = {
  type: "athena_launch";
  code: string;
  state: string;
};

export type CopilotExtensionFhirLaunchMessage =
  | CopilotExtensionEpicLaunchMessage
  | CopilotExtensionCernerLaunchMessage
  | CopilotExtensionAthenaLaunchMessage;

export type CopilotExtensionExternalMessage =
  | CopilotExtensionLoginMessage
  | CopilotExtensionFhirLaunchMessage;

export type CopilotExtensionExternalMessageResponse = {
  code: "EXTENSION_ACTIVE_TAB_FOCUSED" | "SHOULD_DISPLAY_LOGIN_SUCCESS";
};
