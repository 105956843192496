import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { IconName } from "icon-library";
import { routes } from "routes";
import { trackEvent } from "tracking";

export const YourTurnPage = ({
  onLinkClick,
}: {
  onLinkClick: (path: string) => void;
}) => {
  const t = useTranslation();

  return (
    <>
      <div className="flex justify-center">
        <span className="text-primary-dark text-32 leading-10 font-medium">
          {t("onboarding_modal.your_turn_page.its_your_turn")}
        </span>
      </div>

      <div className="flex-col space-y-16">
        <OnboardingModalButton
          icon="drawer"
          title={t("your_turn_page.discover_the_console_by_yourself")}
          subtitle={t(
            "your_turn_page.weve_added_a_few_demo_conversations_to_the_inbox_so_that_you_can_play_around",
          )}
          onClick={() => {
            trackEvent({ name: "Tap Jump to Inbox in Welcome Modal" });
            onLinkClick(
              `/${routes.QA_INBOX}?section=${routes.QA_UNASSIGNED}&${routes.OPEN_FIRST_CONVERSATION}`,
            );
          }}
        />
        <OnboardingModalButton
          icon="code"
          title={t("onboarding_modal.your_turn_page.technical_documentation")}
          subtitle={t("your_turn_page.check_out_our_sdk_integration_guide")}
          onClick={() => {
            trackEvent({ name: "Tap Jump to SDK in Welcome Modal" });
            onLinkClick(`/${routes.DEVELOPERS}/${routes.SETUP_GUIDE}`);
          }}
        />
        <OnboardingModalButton
          icon="email"
          title={t("onboarding_modal.your_turn_page.contact_us")}
          subtitle={t(
            "your_turn_page.if_you_have_any_question_or_would_like_a_more_detailed_demo_please_reach_out",
          )}
          onClick={() => {
            trackEvent({ name: "Tap Beta Access in Welcome Modal" });
            onLinkClick(`/${routes.HELP}`);
          }}
        />
      </div>
    </>
  );
};

const OnboardingModalButton = ({
  icon,
  title,
  subtitle,
  onClick,
}: {
  icon: IconName;
  title: string;
  subtitle: string;
  onClick: () => void;
}) => (
  <div
    className="flex justify-start border rounded-lg bg-white hover:bg-grey-100 cursor-pointer p-16 items-center space-x-16"
    onClick={onClick}
  >
    <div className="bg-[#F2F3FF] text-primary rounded-full p-6">
      <Icon name={icon} size={24} />
    </div>
    <div className="flex-col flex-shrink flex-grow">
      <div className="text-primary-dark text-16 font-medium flex-fill">
        {title}
      </div>
      <div className="text-body text-14">{subtitle}</div>
    </div>
    <Icon name="arrow" rotate={180} />
  </div>
);
