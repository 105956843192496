import classNames from "classnames";
import gql from "graphql-tag";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDoctor } from "contexts/User/UserContext";
import {
  AddOrUpdateMessageReaction,
  MessageFragment,
  MessageReactionTypeKnownValues,
  RemoveMessageReaction,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";

import { MessageReactionTypeToEmoji } from "./display";

gql`
  mutation AddOrUpdateMessageReaction(
    $messageUuid: UUID!
    $input: AddOrUpdateMessageReactionInput!
  ) {
    addOrUpdateMessageReaction(messageUuid: $messageUuid, input: $input) {
      message {
        ...Message
      }
    }
  }

  mutation RemoveMessageReaction($messageUuid: UUID!) {
    removeMessageReaction(messageUuid: $messageUuid) {
      message {
        ...Message
      }
    }
  }
`;

export const MessageReactionsPopover = ({
  message,
}: {
  message: MessageFragment;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const myReaction = message.reactions.find((r) => r.doctor.uuid === user.uuid);
  const [addOrUpdateMessageReaction] = useMutation(AddOrUpdateMessageReaction);
  const [removeMessageReaction] = useMutation(RemoveMessageReaction);
  const isDesktop = useIsDesktop();

  return (
    <UncontrolledPopover
      noArrow
      position={["top", "bottom"]}
      fullWidthOnMobile
      className={classNames({ "mx-10": !isDesktop })}
      content={(close) => (
        <div className="flex items-center justify-between space-x-6 p-16">
          {MessageReactionTypeKnownValues.map((r) => (
            <button
              key={r}
              className={classNames(
                "h-30 w-30 rounded-full text-20 flex-center hover:bg-grey-100",
                { "bg-primary-100": myReaction?.type === r },
              )}
              onClick={() =>
                myReaction?.type === r
                  ? removeMessageReaction(
                      { messageUuid: message.uuid },
                      { onSuccess: () => close() },
                    )
                  : addOrUpdateMessageReaction(
                      {
                        messageUuid: message.uuid,
                        input: { type: r },
                      },
                      { onSuccess: () => close() },
                    )
              }
            >
              <div>{MessageReactionTypeToEmoji[r]}</div>
            </button>
          ))}
        </div>
      )}
    >
      {({ setTarget }) => (
        <TooltipWrapper
          label={t(
            "conversation_content.timeline.message_container.message_reactions_popover.reaction_tooltip",
          )}
        >
          <ClickableIcon
            className="invisible group-hover:visible p-4 hover:bg-grey-100 rounded"
            name="happy"
            onClick={setTarget}
          />
        </TooltipWrapper>
      )}
    </UncontrolledPopover>
  );
};
