import { ReactNode, useState } from "react";
import gql from "graphql-tag";

import { useShadowMode } from "atoms/useShadowMode";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { Spinner } from "components/Spinner/Spinner";
import { DoctorMessageActionsType } from "contexts/Experience/ExperienceContext";
import { ExperienceProvider } from "contexts/Experience/ExperienceProvider";
import { PatientProvider } from "contexts/PatientContext/PatientProvider";
import { useDoctor } from "contexts/User/UserContext";
import { GetQAExperience, GetQAExperienceData } from "generated/provider";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import {
  SHADOW_FIRST_NAME,
  SHADOW_LAST_NAME,
  SHADOW_NAME_FOR_INPUT,
  SHADOW_USERNAME,
} from "utils/display";

import { QAExperienceContext } from "./QAExperienceContext";
import { newExperienceDraftKey } from "./utils";

gql`
  query GetQAExperience($uuid: UUID!, $cursor: DateTime) {
    experience(uuid: $uuid) {
      experience {
        ...QAExperience
        ...ExperienceContent
        patient {
          ...Devices
          phoneV2
          qaExperiencesCount
        }
      }
    }
  }
`;

export const QAExperienceProvider = ({
  uuid,
  DoctorMessageActions,
  readonly,
  noSpinner,
  patient: initialPatient,
  children,
}: {
  uuid: UUID;
  DoctorMessageActions?: DoctorMessageActionsType;
  readonly?: boolean;
  children: ReactNode;
} & (
  | {
      // Will show a spinner while loading the QA experience.
      noSpinner?: false;
      patient?: undefined;
    }
  | {
      // Will always display the children.
      // Note: all child components should use `useMaybeQAExperience` instead
      //  of `useQAExperience` since the latter expects a loaded experience.
      noSpinner: true;
      patient: NonNullable<GetQAExperienceData["experience"]["patient"]>;
    }
)) => {
  const { user } = useDoctor();
  const { shadowMode } = useShadowMode();
  const [isActivityPanelOpened, setIsActivityPanelOpened] = useState(false);

  const { data, error, nextPage, fetchingMore } = usePaginatedQuery(
    GetQAExperience,
    {
      variables: { uuid },
      selector: (d) => d.experience.itemsV3,
    },
  );

  if (error) return <ErrorPage error={error} />;
  if (!noSpinner && !data) return <Spinner />;

  const experience = data?.experience;
  const patient = experience ? experience.patient! : initialPatient!;
  const username = patient.username;
  const isInExperience =
    experience?.allDoctors.some((d) => d.uuid === user.uuid) ?? false;

  return (
    <PatientProvider uuid={patient.uuid}>
      <ExperienceProvider
        key={uuid}
        uuid={uuid}
        type={experience?.type ?? "SINGLE_PATIENT_CONVERSATION"}
        DoctorMessageActions={DoctorMessageActions}
        readonly={readonly}
        data={data}
        nextPage={nextPage}
        fetchingMore={fetchingMore}
        processDisplayedMessageText={
          shadowMode && username
            ? (text) =>
                text
                  .replaceAll(username, SHADOW_USERNAME)
                  .replaceAll(patient.firstName, SHADOW_FIRST_NAME)
                  .replaceAll(patient.lastName, SHADOW_LAST_NAME)
            : undefined
        }
        processSendingMessageContent={(content) =>
          "text" in content
            ? {
                text: content.text.replaceAll(
                  SHADOW_NAME_FOR_INPUT,
                  patient.firstName ? patient.firstName : username,
                ),
              }
            : content
        }
      >
        <QAExperienceContext.Provider
          value={{
            isInExperience,
            patient,
            isActivityPanelOpened,
            setIsActivityPanelOpened,
          }}
        >
          {children}
        </QAExperienceContext.Provider>
      </ExperienceProvider>
    </PatientProvider>
  );
};

export const NewQAExperienceProvider = ({
  patientUuid,
  children,
}: {
  patientUuid: UUID;
  children: ReactNode;
}) => {
  const [isActivityPanelOpened, setIsActivityPanelOpened] = useState(false);

  return (
    <PatientProvider uuid={patientUuid}>
      {({ patient }) => (
        <ExperienceProvider
          draftKey={newExperienceDraftKey(patient.uuid)}
          type="SINGLE_PATIENT_CONVERSATION"
          data={undefined}
          nextPage={undefined}
          fetchingMore={false}
        >
          <QAExperienceContext.Provider
            value={{
              isInExperience: false,
              patient,
              isActivityPanelOpened,
              setIsActivityPanelOpened,
            }}
          >
            {children}
          </QAExperienceContext.Provider>
        </ExperienceProvider>
      )}
    </PatientProvider>
  );
};
