import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { ReplyMessage } from "components/ReplyMessage/ReplyMessage";
import { useExperience } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import { useTranslation } from "i18n";
import { isKnownUnionValue } from "utils/apollo";
import { displayUser } from "utils/display";

export const ReplyMessageBanner = ({ className }: { className?: string }) => {
  const t = useTranslation();
  const { replyMessage, setReplyMessage } = useExperience();
  const { user } = useDoctor();

  if (!replyMessage) return null;

  return (
    <div className={classNames("flex items-center space-x-10", className)}>
      <Icon name="reply" />
      <div className="flex-col flex-fill text-14">
        {isKnownUnionValue("User", replyMessage.sender) &&
        replyMessage.sender.uuid === user.uuid ? (
          <span className="text-primary-dark">
            {t("composer_parts.reply_message_banner.reply_to_your_message")}
          </span>
        ) : (
          <span className="text-primary-dark">
            {t("composer_parts.reply_message_banner.reply_to_")}
            <span className="font-semibold">
              {displayUser(replyMessage.sender)}
            </span>
          </span>
        )}

        <ReplyMessage content={replyMessage.content} maxLines={1} />
      </div>
      <ClickableIcon name="close" onClick={() => setReplyMessage(undefined)} />
    </div>
  );
};
