import produce from "immer";
import zustand, { GetState, SetState, State } from "zustand";
import { combine } from "zustand/middleware";
import { StoreApi } from "zustand/vanilla";

export const createAtom = <S1 extends State, S2 extends State>(
  initialState: S1,
  create: (props: {
    set: SetState<S1>;
    get: GetState<S1>;
    api: StoreApi<S1>;
    update: (fn: (state: S1) => void) => void;
    setter: <K extends keyof S1>(key: K) => (value: S1[K]) => void;
  }) => S2,
) =>
  zustand(
    combine(initialState, (set, get, api) =>
      create({
        set,
        get,
        api,
        update: (fn) => set(produce(fn) as (state: S1) => S1),
        setter: (key) => (value) =>
          // @ts-ignore When evaluating as an object key, keyof S1 becomes string 😥
          set({ [key]: value }),
      }),
    ),
  );
