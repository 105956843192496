import { CSSProperties, ReactNode, useState } from "react";
import classNames from "classnames";

import { IcdCodePicker } from "components/Form/MedicalCodes/IcdCodePicker";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { navigatorSupportsVoiceInput } from "hooks/useVoiceInput";
import { NoteSuggestion } from "types";
import { run } from "utils";

import audioSpinnerUrl from "./audioSpiner.gif";
import { NoteMentionsInfo } from "./NoteMentionsInfo";
import { SectionAppender } from "./SectionAppender";
import { TextSectionComposer } from "./TextSectionComposer";
import { NoteSectionWithComposerFields } from "./types";
import { useNablaForChromeIntegration } from "./useNablaForChromeIntegration";
import { useNavigationBetweenSections } from "./useNavigationBetweenSections";
import { createTextSection, getSectionTitle } from "./utils";

export type NoteSectionsComposerProps = {
  value: NoteSectionWithComposerFields[];
  onChange: (sections: NoteSectionWithComposerFields[]) => void;
  style?: CSSProperties;
  readOnly?: boolean;
  mentionsDisabled?: boolean;
  keepToolsMarginsInReadOnly?: boolean;
  autocomplete?: (context: string) => string | undefined;
  onClick?: () => void;
  suggestions?: NoteSuggestion[];
};

export const NoteSectionsComposer = ({
  value,
  onChange: onChangeEditMode,
  style,
  readOnly,
  mentionsDisabled,
  onClick,
  autocomplete,
  keepToolsMarginsInReadOnly,
  suggestions,
}: NoteSectionsComposerProps) => {
  const onChange = readOnly ? () => null : onChangeEditMode;
  const [focusedSectionUuid, setFocusedSectionUuid] = useState<UUID>();
  const [idSectionBeingTranscribed, setIdSectionBeingTranscribed] =
    useState<UUID>();

  const { handleExitDown, handleExitUp, handleBlur } =
    useNavigationBetweenSections(value, onChange);

  useNablaForChromeIntegration((nablaForChromeSections) => {
    const newSections = nablaForChromeSections.map((section) =>
      createTextSection(`${section.title} \n\n ${section.value}`),
    );
    onChange([...newSections, ...value]);
  });

  return (
    <>
      <div
        className="flex-col flex-fill overflow-y-auto space-y-24"
        style={{ paddingRight: 24, ...style }}
        onClick={onClick ? () => onClick() : undefined}
      >
        {value.map((section, index) => (
          <SectionStructure
            key={section.uuid}
            noDictation={
              section.content.__typename === "DiagnoseSectionContent"
            }
            readOnly={section.readonly ?? readOnly}
            focused={section.uuid === focusedSectionUuid}
            onDelete={() => {
              if (idSectionBeingTranscribed === section.uuid) {
                setIdSectionBeingTranscribed(undefined);
              }
              onChange(
                value.mapNotNull((it) => {
                  if (it.uuid === section.uuid) return null;
                  return it;
                }),
              );
            }}
            isDictating={idSectionBeingTranscribed === section.uuid}
            onDictationClicked={() => {
              const isDictating = idSectionBeingTranscribed === section.uuid;
              setIdSectionBeingTranscribed(
                isDictating ? undefined : section.uuid,
              );
            }}
            keepToolsMarginsInReadOnly={keepToolsMarginsInReadOnly}
          >
            {run(() => {
              const content = section.content;
              switch (content.__typename) {
                case "TextSectionContent":
                  return (
                    <TextSectionComposer
                      section={{
                        ...section,
                        content,
                      }} // Otherwise typescript is not smart enough to realise the typing is ok
                      onChange={(newNoteSection) => {
                        onChange(
                          value.map((sec) => {
                            if (sec.uuid === section.uuid) {
                              return newNoteSection;
                            }
                            return sec;
                          }),
                        );
                      }}
                      disabled={section.readonly ?? readOnly}
                      mentionsDisabled={mentionsDisabled}
                      title={
                        content.category === "FREE_TEXT"
                          ? undefined
                          : getSectionTitle(content.category)
                      }
                      transcribe={section.uuid === idSectionBeingTranscribed}
                      onTranscriptionError={() => {
                        setIdSectionBeingTranscribed(undefined);
                      }}
                      onVoiceNavigateDown={() => {
                        if (index < value.length - 1) {
                          if (
                            value[index + 1].content.__typename ===
                            "DiagnoseSectionContent"
                          ) {
                            if (index + 1 < value.length - 1) {
                              setIdSectionBeingTranscribed(
                                value[index + 2].uuid,
                              );
                            }
                          } else {
                            setIdSectionBeingTranscribed(value[index + 1].uuid);
                          }
                        }
                      }}
                      onVoiceNavigateUp={() => {
                        if (index > 0) {
                          if (
                            value[index - 1].content.__typename ===
                            "DiagnoseSectionContent"
                          ) {
                            if (index - 1 > 0) {
                              setIdSectionBeingTranscribed(
                                value[index - 2].uuid,
                              );
                            }
                          } else {
                            setIdSectionBeingTranscribed(value[index - 1].uuid);
                          }
                        }
                      }}
                      onFocus={() => {
                        setFocusedSectionUuid(section.uuid);
                        handleBlur(index);
                      }}
                      onBlur={() => {
                        setFocusedSectionUuid(undefined);
                        handleBlur(index);
                      }}
                      onKeyNavigateUp={() => handleExitUp(index)}
                      onKeyNavigateDown={() => handleExitDown(index)}
                      bulletPointEntityType={
                        content.category === "ALLERGY"
                          ? "ALLERGY"
                          : content.category === "MEDICAL_HISTORY"
                          ? "MEDICAL_HISTORY"
                          : content.category === "SURGERY_HISTORY"
                          ? "PROCEDURE"
                          : content.category === "MEDICATION"
                          ? "MEDICATION"
                          : content.category === "VACCINE"
                          ? "VACCINATION"
                          : undefined
                      }
                      onClick={() => setIdSectionBeingTranscribed(undefined)}
                      suggestions={suggestions?.filter(
                        (it) => it.contentCategory === content.category,
                      )}
                      autocomplete={autocomplete}
                    />
                  );
                case "DiagnoseSectionContent":
                  return (
                    <IcdCodePicker
                      value={content.icd10Codes}
                      onChange={(newICD10Codes) =>
                        onChange(
                          value.mapNotNull((sec) => {
                            if (sec.uuid === section.uuid) {
                              return {
                                ...section,
                                content: {
                                  ...content,
                                  icd10Codes: newICD10Codes,
                                },
                              };
                            }
                            return sec;
                          }),
                        )
                      }
                      disabled={section.readonly ?? readOnly}
                    />
                  );
              }
            })}
          </SectionStructure>
        ))}
        {!readOnly && <SectionAppender sections={value} onChange={onChange} />}
      </div>
      {/* Only shown when there are draft mentions. */}
      <NoteMentionsInfo sections={value} className="mt-20 mb-10" />
    </>
  );
};

const SectionStructure = ({
  onDelete,
  onDictationClicked,
  isDictating,
  noDictation,
  focused,
  children,
  readOnly,
  keepToolsMarginsInReadOnly,
}: {
  onDelete: () => void;
  children: ReactNode;
  isDictating: boolean;
  noDictation: boolean;
  focused: boolean;
  onDictationClicked?: () => void;
  readOnly: boolean | undefined;
  keepToolsMarginsInReadOnly?: boolean;
}) => (
  <div className="flex group">
    <div
      className={classNames("flex-col mr-8 mt-4", {
        "group-hover:border-r": !readOnly,
        "w-48": !readOnly || keepToolsMarginsInReadOnly,
        "border-r": focused,
      })}
    >
      {!readOnly && (
        <div className="flex space-x-2">
          {isDictating ? (
            <img
              src={audioSpinnerUrl}
              className="object-contain h-20 w-20"
              alt=""
            />
          ) : (
            <ClickableIcon
              onClick={onDelete}
              size={20}
              className="p-0 rounded-sm hover:bg-grey-100 invisible group-hover:visible"
              name="trash"
            />
          )}
          {navigatorSupportsVoiceInput() && !noDictation && (
            <ClickableIcon
              onClick={onDictationClicked}
              size={20}
              className={classNames(
                "p-0 rounded-sm hover:bg-grey-100 group-hover:visible",
                isDictating ? "visible text-primary" : "invisible",
              )}
              name="micOn"
            />
          )}
        </div>
      )}
    </div>
    {children}
  </div>
);
