import { useState } from "react";
import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Background } from "components/Background/Backgound";
import { Input } from "components/Form/Input/Input";
import { Switch } from "components/Form/Switch/Switch";
import { MultiToggleForm } from "components/MultiToggleForm/MultiToggleForm";
import { Query } from "components/Query/Query";
import {
  GetPatientEmailNotificationSettings,
  PatientEmailNotificationType,
  UpdatePatientEmailNotificationSettings,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  query GetPatientEmailNotificationSettings {
    me {
      doctor {
        uuid
        subOrganization {
          uuid
          enabledPatientEmailNotifications
          newMessageEmailNotificationUrl
        }
      }
    }
  }

  mutation UpdatePatientEmailNotificationSettings(
    $input: OrganizationSettingsInput!
  ) {
    updateOrganizationSettings(input: $input) {
      doctor {
        uuid
        subOrganization {
          uuid
          enabledPatientEmailNotifications
          newMessageEmailNotificationUrl
        }
      }
    }
  }
`;

export const PatientOrgEmailNotifications = () => {
  const t = useTranslation();

  const [updatePatientEmailNotifications] = useMutation(
    UpdatePatientEmailNotificationSettings,
    {
      onSuccess: () =>
        notifier.success(t("settings.org_patient_email_notifications.updated")),
    },
  );

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <h1 className="text-primary-dark text-24 font-bold">
        {t("settings.org_patient_email_notifications.title")}
      </h1>
      <Query query={GetPatientEmailNotificationSettings}>
        {({
          doctor: {
            subOrganization: {
              enabledPatientEmailNotifications,
              newMessageEmailNotificationUrl,
            },
          },
        }) => (
          <>
            <NewMessageEmailNotificationForm
              newMessageEmailNotificationUrl={newMessageEmailNotificationUrl}
              enabledPatientEmailNotifications={
                enabledPatientEmailNotifications
              }
            />
            <div className="flex-col bg-white rounded border p-32">
              <MultiToggleForm<
                Exclude<PatientEmailNotificationType, "NEW_MESSAGE">
              >
                title={t(
                  "settings.org_patient_email_notifications.scheduling_section_toggle_title",
                )}
                subtitle={t(
                  "settings.org_patient_email_notifications.scheduling_section_toggle_subtitle",
                )}
                toggles={{
                  APPOINTMENT_REMINDER: [
                    t(
                      "settings.org_patient_email_notifications.appointment_reminders_toggle_title",
                    ),
                    t(
                      "settings.org_patient_email_notifications.appointment_reminders_toggle_subtitle",
                    ),
                  ],
                  APPOINTMENT_STATUS: [
                    t(
                      "settings.org_patient_email_notifications.appointment_status_toggle_title",
                    ),
                    t(
                      "settings.org_patient_email_notifications.appointment_status_toggle_subtitle",
                    ),
                  ],
                }}
                enabledToggles={enabledPatientEmailNotifications.mapNotNull(
                  (type) => {
                    if (type === "NEW_MESSAGE") return null;
                    return type;
                  },
                )}
                onSubmit={(newEnabled) => {
                  const newEnabledWithNewMessage =
                    enabledPatientEmailNotifications.includes("NEW_MESSAGE")
                      ? [
                          ...newEnabled,
                          "NEW_MESSAGE" as PatientEmailNotificationType,
                        ]
                      : newEnabled;
                  updatePatientEmailNotifications({
                    input: {
                      enabledPatientEmailNotifications:
                        newEnabledWithNewMessage,
                    },
                  });
                }}
              />
            </div>
          </>
        )}
      </Query>
    </Background>
  );
};

const NewMessageEmailNotificationForm = ({
  newMessageEmailNotificationUrl,
  enabledPatientEmailNotifications,
}: {
  newMessageEmailNotificationUrl: Maybe<string>;
  enabledPatientEmailNotifications: PatientEmailNotificationType[];
}) => {
  const t = useTranslation();
  const [url, setUrl] = useState(newMessageEmailNotificationUrl ?? "");
  const [newMessageNotificationEnabled, setNewMessageNotificationEnabled] =
    useState(enabledPatientEmailNotifications.includes("NEW_MESSAGE"));

  const [updatePatientEmailNotifications] = useMutation(
    UpdatePatientEmailNotificationSettings,
    {
      onSuccess: () =>
        notifier.success(t("settings.org_patient_email_notifications.updated")),
    },
  );

  const updateUrlIfChanged = (newUrl: string, oldUrl: Maybe<string>) => {
    if (newUrl === (oldUrl ?? "")) return;
    updatePatientEmailNotifications({
      input: {
        newMessageEmailNotificationUrl: newUrl,
      },
    });
  };

  return (
    <div className="bg-white rounded border p-32 flex justify-between">
      <div className="flex-col">
        <div className="font-bold text-18 text-primary-dark">
          {t(
            "settings.org_patient_email_notifications.new_message_section_toggle_title",
          )}
        </div>
        <div>
          {t(
            "settings.org_patient_email_notifications.new_message_section_toggle_subtitle",
          )}
        </div>
        <div>
          {t(
            "settings.org_patient_email_notifications.new_message_section_toggle_tooltip",
          )}
        </div>
        <Input
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          inputMode="url"
          name="url"
          placeholder={t(
            "settings.org_patient_email_notifications.new_message_section_toggle_description",
          )}
          wrapperClassName="flex-fill mt-8"
          disabled={!newMessageNotificationEnabled}
          onBlur={() => {
            updateUrlIfChanged(url, newMessageEmailNotificationUrl);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateUrlIfChanged(url, newMessageEmailNotificationUrl);
            }
          }}
        />
      </div>
      <Switch
        onChange={(checked) => {
          if (!checked) setUrl("");
          setNewMessageNotificationEnabled(checked);
          updatePatientEmailNotifications({
            input: {
              enabledPatientEmailNotifications: enabledPatientEmailNotifications
                .filter((type) => type !== "NEW_MESSAGE")
                .concatIf(checked, "NEW_MESSAGE"),
              newMessageEmailNotificationUrl: checked ? url : "",
            },
          });
        }}
        checked={newMessageNotificationEnabled}
      />
    </div>
  );
};
