import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { NotFound } from "components/ErrorPage/NotFound";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { Icon } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useUser } from "contexts/User/UserContext";
import {
  AppointmentConsentsSettings,
  UpdatePhysicalAppointmentConfirmationConsents,
  UpdateRemoteAppointmentConfirmationConsents,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { ConsentsPreview } from "./ConsentsPreview";

gql`
  query AppointmentConsentsSettings {
    me {
      doctor {
        subOrganization {
          ...SubOrganizationSummary
        }
      }
    }
  }

  mutation UpdateRemoteAppointmentConfirmationConsents(
    $input: AppointmentConfirmationConsentInput!
  ) {
    updateOrganizationSettings(
      input: { appointmentConfirmationConsents: $input }
    ) {
      doctor {
        subOrganization {
          ...SubOrganizationSummary
        }
      }
    }
  }

  mutation UpdatePhysicalAppointmentConfirmationConsents(
    $input: AppointmentConfirmationConsentInput!
  ) {
    updateOrganizationSettings(
      input: { physicalAppointmentConfirmationConsents: $input }
    ) {
      doctor {
        subOrganization {
          ...SubOrganizationSummary
        }
      }
    }
  }
`;

type FormValues = {
  firstConsent: string;
  secondConsent: string;
};

export const SchedulingSettingsConsents = () => {
  const t = useTranslation();
  const { hasPermission } = useUser();
  const { data, loading } = useQuery(AppointmentConsentsSettings);

  const [updateRemoteConsents, updateRemoteConsentsLoading] = useMutation(
    UpdateRemoteAppointmentConfirmationConsents,
    {
      onSuccess: () => {
        notifier.success(t("settings.scheduling.consents_success"));
      },
    },
  );

  const [updatePhysicalConsents, updatePhysicalConsentsLoading] = useMutation(
    UpdatePhysicalAppointmentConfirmationConsents,
    {
      onSuccess: () => {
        notifier.success(t("settings.scheduling.consents_success"));
      },
    },
  );

  if (!hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS")) return <NotFound />;

  return (
    <div className="flex-col space-y-12">
      <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
        {loading && <Spinner small inline className="mx-auto" />}
        {data && (
          <Form<FormValues>
            className="flex-col w-full items-start space-y-20 p-32 bg-white border rounded shadow-sm-outlined"
            initialValues={{
              firstConsent:
                data.doctor.subOrganization.appointmentConfirmationConsents
                  .firstConsentMarkdown,
              secondConsent:
                data.doctor.subOrganization.appointmentConfirmationConsents
                  .secondConsentMarkdown,
            }}
            onSubmit={(values) =>
              updateRemoteConsents({
                input: {
                  firstConsentMarkdown: values.firstConsent,
                  secondConsentMarkdown: values.secondConsent,
                },
              })
            }
          >
            <div className="flex-col w-full items-start space-y-12">
              <div className="flex items-center space-x-4">
                <h1 className="flex-fill text-primary-dark text-24 font-bold">
                  {t("settings.scheduling.consents_title_remote")}
                </h1>
                <TooltipWrapper
                  position="top"
                  label={t("settings.scheduling.markdown_notice")}
                >
                  <Icon name="info" className="hover:text-primary" />
                </TooltipWrapper>
              </div>

              {t("settings.scheduling.consents_body")}

              <FormInput
                wrapperClassName="flex-fill justify-between h-full"
                name="firstConsent"
                label={t("settings.scheduling.first_consent")}
              />
              <FormInput
                wrapperClassName="flex-fill justify-between h-full"
                name="secondConsent"
                label={t("settings.scheduling.second_consent")}
              />
            </div>
            <FormState<FormValues>>
              {({ values }) => (
                <>
                  <ConsentsPreview
                    consents={[
                      values.firstConsent.trimOrNull(),
                      values.secondConsent.trimOrNull(),
                    ].filterNotNull()}
                  />
                  <Submit
                    label={t("settings.scheduling.categories.form_save")}
                    loading={updateRemoteConsentsLoading}
                    disabled={
                      values.firstConsent ===
                        data.doctor.subOrganization
                          .appointmentConfirmationConsents
                          .firstConsentMarkdown &&
                      values.secondConsent ===
                        data.doctor.subOrganization
                          .appointmentConfirmationConsents.secondConsentMarkdown
                    }
                  />
                </>
              )}
            </FormState>
          </Form>
        )}
      </Background>
      <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
        {loading && <Spinner small inline className="mx-auto" />}
        {data && (
          <Form<FormValues>
            className="flex-col w-full items-start space-y-20 p-32 bg-white border rounded shadow-sm-outlined"
            initialValues={{
              firstConsent:
                data.doctor.subOrganization
                  .physicalAppointmentConfirmationConsents.firstConsentMarkdown,
              secondConsent:
                data.doctor.subOrganization
                  .physicalAppointmentConfirmationConsents
                  .secondConsentMarkdown,
            }}
            onSubmit={(values) =>
              updatePhysicalConsents({
                input: {
                  firstConsentMarkdown: values.firstConsent,
                  secondConsentMarkdown: values.secondConsent,
                },
              })
            }
          >
            <div className="flex-col w-full items-start space-y-12">
              <div className="flex items-center space-x-4">
                <h1 className="flex-fill text-primary-dark text-24 font-bold">
                  {t("settings.scheduling.consents_title_physical")}
                </h1>
                <TooltipWrapper
                  position="top"
                  label={t("settings.scheduling.markdown_notice")}
                >
                  <Icon name="info" className="hover:text-primary" />
                </TooltipWrapper>
              </div>

              {t("settings.scheduling.consents_body")}

              <FormInput
                wrapperClassName="flex-fill justify-between h-full"
                name="firstConsent"
                label={t("settings.scheduling.first_consent")}
              />
              <FormInput
                wrapperClassName="flex-fill justify-between h-full"
                name="secondConsent"
                label={t("settings.scheduling.second_consent")}
              />
            </div>
            <FormState<FormValues>>
              {({ values }) => (
                <>
                  <ConsentsPreview
                    consents={[
                      values.firstConsent.trimOrNull(),
                      values.secondConsent.trimOrNull(),
                    ].filterNotNull()}
                  />
                  <Submit
                    label={t("settings.scheduling.categories.form_save")}
                    loading={updatePhysicalConsentsLoading}
                    disabled={
                      values.firstConsent ===
                        data.doctor.subOrganization
                          .physicalAppointmentConfirmationConsents
                          .firstConsentMarkdown &&
                      values.secondConsent ===
                        data.doctor.subOrganization
                          .physicalAppointmentConfirmationConsents
                          .secondConsentMarkdown
                    }
                  />
                </>
              )}
            </FormState>
          </Form>
        )}
      </Background>
    </div>
  );
};
