import { Fragment, useState } from "react";
import gql from "graphql-tag";
import { useLocation } from "react-router-dom";

import { NextPageButton } from "components/Button/NextPageButton";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { ConversationLink } from "components/InboxRow/ConversationLink";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { useDoctor } from "contexts/User/UserContext";
import { GetGroupConversations } from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { AddGroupChatComposer } from "./AddGroupChatComposer";
import { GroupConversation } from "./GroupConversation";

gql`
  query GetGroupConversations($cursor: DateTime) {
    multiPatientsExperiences(page: { numberOfItems: 20, from: $cursor }) {
      hasMore
      nextCursor
      data {
        ...ExperienceSummary
      }
    }
  }
`;

export const GroupConversations = () => {
  const location = useLocation();
  const { user } = useDoctor();
  const t = useTranslation();
  const [showAddGroupChatComposer, setShowAddGroupChatComposer] =
    useState(false);
  return (
    <>
      {showAddGroupChatComposer && (
        <AddGroupChatComposer
          onClose={() => setShowAddGroupChatComposer(false)}
        />
      )}
      <SidebarPage
        baseRoute={routes.GROUP_CHAT_BASE}
        title={t("group_chat.title")}
        items={[]}
        otherRoutes={[{ to: "/:uuid", Component: GroupConversation }]}
      >
        <ClickableIcon
          name="add"
          size={24}
          className="border rounded py-6 px-8 absolute right-12 top-12"
          onClick={() => setShowAddGroupChatComposer(true)}
        />
        <PaginatedQuery query={GetGroupConversations}>
          {({ data: experiences }, utils) => (
            <div className="flex-fill flex-col items-center overflow-auto">
              {experiences
                .filterNotNull()
                .sortDesc((e) => e.lastModified.getTime())
                .map((e) => (
                  <Fragment key={e.uuid}>
                    <ConversationLink
                      to={`${routes.GROUP_CHAT_BASE}/${e.uuid}`}
                      experience={e}
                      patient={undefined}
                      highlighted={location.pathname.includes(e.uuid)}
                      isInExperience={e.allDoctors
                        .map((doc) => doc.uuid)
                        .includes(user.uuid)}
                      hideAvatar
                    />
                    <div
                      className="bg-grey-200 h-1 last:invisible"
                      style={{ width: "87%" }}
                    />
                  </Fragment>
                ))}
              <NextPageButton {...utils} />
              <BottomSafeArea />
            </div>
          )}
        </PaginatedQuery>
      </SidebarPage>
    </>
  );
};
