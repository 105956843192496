import { useMediaWindows } from "atoms/useMediaWindows";
import {
  FaxStatus,
  FaxStatusKnownValues,
  SentFaxFragment,
} from "generated/provider";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";
import { isKnownValue } from "utils/enum";

export const FaxListStatus = ({
  sentFaxes,
}: {
  sentFaxes: SentFaxFragment[];
}) => {
  const t = useTranslation();
  const { showMedia } = useMediaWindows();

  if (sentFaxes.isEmpty()) return null;

  return (
    <div className="flex-col">
      {sentFaxes.map((fax) => (
        <div key={fax.uuid}>
          {t("patient_view.fax.sent_to_by_at", {
            phone: fax.recipientPhone,
            doctor: displayDoctor(fax.sentBy),
            date: fax.sentAt.format({
              relative: "timeOrDateWithTime",
            }),
          })}
          ・
          <span
            className="underline hover:cursor-pointer"
            onClick={() => showMedia(fax.file)}
          >
            {t("patient_view.fax.see")}
          </span>
          ・<FaxStatusLabel status={fax.status} />
        </div>
      ))}
    </div>
  );
};

export const FaxStatusLabel = ({
  status,
}: {
  status: FaxStatus;
}): JSX.Element => {
  const t = useTranslation();
  if (!isKnownValue(status, FaxStatusKnownValues)) {
    return <span className="text-warning-300">{t("fax.status.unknown")}</span>;
  }
  return (() => {
    switch (status) {
      case "SUCCESS":
        return <span className="text-success">{t("fax.status.success")}</span>;
      case "IN_PROGRESS":
        return (
          <span className="text-warning-300">
            {t("fax.status.in_progress")}
          </span>
        );
      case "FAILURE":
        return <span className="text-danger">{t("fax.status.failure")}</span>;
      case "UNKNOWN":
        return (
          <span className="text-warning-300">{t("fax.status.unknown")}</span>
        );
    }
  })();
};
