import gql from "graphql-tag";

import { Maybe } from "base-types";
import {
  PatientTimelineEventFragment,
  SimpleHealthDataPointTypeKnownValues,
  UpdateCondition,
  UpdateDataPoint,
  UpdateMedicationStatement,
  UpdatePatientAllergy,
  UpdateProcedure,
  UpdateSymptom,
  UpdateVaccinationStatement,
} from "generated/provider";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useMutation } from "graphql-client/useMutation";
import { staticT as t } from "i18n";
import { isKnownUnionValue } from "utils/apollo";
import { convertDataPointToMetricSystemIfNeeded } from "utils/data-points";
import { isKnownValue } from "utils/enum";
import { bloodPressureValuesFromValidInput, parseNumber } from "utils/form";

gql`
  mutation UpdateVaccinationStatement(
    $vaccinationUuid: UUID!
    $input: UpdateVaccinationStatementInput!
  ) {
    updateVaccinationStatement(
      vaccinationStatementUuid: $vaccinationUuid
      input: $input
    ) {
      vaccinationStatement {
        ...VaccinationStatement
      }
    }
  }

  mutation UpdateMedicationStatement(
    $medicationUuid: UUID!
    $input: UpdateMedicationStatementInput!
  ) {
    updateMedicationStatement(
      medicationStatementUuid: $medicationUuid
      input: $input
    ) {
      medicationStatement {
        ...MedicationStatement
      }
    }
  }

  mutation UpdatePatientAllergy(
    $allergyUuid: UUID!
    $input: UpdatePatientAllergyInput!
  ) {
    updatePatientAllergy(allergyUuid: $allergyUuid, input: $input) {
      patientAllergy {
        ...PatientAllergy
      }
    }
  }

  mutation UpdateCondition(
    $conditionUuid: UUID!
    $input: UpdateConditionInput!
  ) {
    updateCondition(uuid: $conditionUuid, input: $input) {
      condition {
        ...Condition
      }
    }
  }

  mutation UpdateSymptom($symptomUuid: UUID!, $input: UpdateSymptomInput!) {
    updateSymptom(symptomUuid: $symptomUuid, input: $input) {
      symptom {
        ...Symptom
      }
    }
  }

  mutation UpdateProcedure(
    $procedureUuid: UUID!
    $input: UpdateProcedureInput!
  ) {
    updateProcedure(procedureUuid: $procedureUuid, input: $input) {
      procedure {
        ...Procedure
      }
    }
  }

  mutation UpdateDataPoint(
    $dataPointUuid: UUID!
    $input: UpdateHealthDataPointValueInput!
  ) {
    updateHealthDataPoint(uuid: $dataPointUuid, input: $input) {
      dataPoint {
        ...DataPoint
      }
    }
  }
`;

export const useUpdateEHRTimelineItem = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError?: (e: ParsedGraphQLError) => void;
}) => {
  const [updateVaccination, vaccinationLoading] = useMutation(
    UpdateVaccinationStatement,
    {
      onSuccess,
      onError,
    },
  );
  const [updateMedication, medicationLoading] = useMutation(
    UpdateMedicationStatement,
    {
      onSuccess,
      onError,
    },
  );
  const [updateAllergy, allergyLoading] = useMutation(UpdatePatientAllergy, {
    onSuccess,
    onError,
  });
  const [updateCondition, conditionLoading] = useMutation(UpdateCondition, {
    onSuccess,
    onError,
  });
  const [updateSymptom, symptomLoading] = useMutation(UpdateSymptom, {
    onSuccess,
    onError,
  });
  const [updateProcedure, procedureLoading] = useMutation(UpdateProcedure, {
    onSuccess,
    onError,
  });
  const [updateDataPoint, datapointLoading] = useMutation(UpdateDataPoint, {
    onSuccess,
    onError,
  });

  return {
    loading:
      vaccinationLoading ||
      medicationLoading ||
      allergyLoading ||
      conditionLoading ||
      symptomLoading ||
      procedureLoading ||
      datapointLoading,
    updateEHRTimelineItem: (
      item: PatientTimelineEventFragment,
      newTitle: string,
      newDescription: Maybe<string>,
    ) => {
      if (!isKnownUnionValue("EhrEventContent", item.content)) {
        onSuccess();
        return Promise.resolve();
      }

      switch (item.content.__typename) {
        case "VaccineCreated":
          return updateVaccination({
            vaccinationUuid: item.content.vaccinationStatement.uuid,
            input: {
              type: newTitle,
              description: newDescription ?? "",
              status: "COMPLETED",
            },
          }).then(() => undefined);

        case "MedicationCreatedOrAccepted":
          return updateMedication({
            medicationUuid: item.content.medicationStatement.uuid,
            input: {
              name: newTitle,
              description: newDescription ?? "",
              status: "UNKNOWN",
            },
          }).then(() => undefined);

        case "PatientAllergyCreatedOrAccepted":
          return updateAllergy({
            allergyUuid: item.content.patientAllergy.uuid,
            input: {
              type: newTitle,
              comment: newDescription ?? "",
            },
          }).then(() => undefined);

        case "ConditionCreatedOrAccepted":
          return updateCondition({
            conditionUuid: item.content.condition.uuid,
            input: {
              type: newTitle,
              description: newDescription ?? "",
            },
          }).then(() => undefined);

        case "SymptomCreated":
          return updateSymptom({
            symptomUuid: item.content.symptom.uuid,
            input: {
              name: newTitle,
              comment: newDescription ?? "",
            },
          }).then(() => undefined);

        case "ProcedureCreatedOrAccepted":
          return updateProcedure({
            procedureUuid: item.content.procedure.uuid,
            input: {
              name: newTitle,
              comment: newDescription ?? "",
            },
          }).then(() => undefined);

        case "DataPointCreated":
          if (
            item.content.dataPoint.value.__typename === "BloodPressureValue"
          ) {
            return updateDataPoint({
              dataPointUuid: item.content.dataPoint.uuid,
              input: {
                bloodPressureInput: bloodPressureValuesFromValidInput(newTitle),
              },
            }).then(() => undefined);
          }

          if (
            item.content.dataPoint.value.__typename ===
              "SimpleHealthDataPointValue" &&
            newTitle.isNotBlank()
          ) {
            return updateDataPoint({
              dataPointUuid: item.content.dataPoint.uuid,
              input: {
                simpleDataPointInput: isKnownValue(
                  item.content.dataPoint.value.type,
                  SimpleHealthDataPointTypeKnownValues,
                )
                  ? convertDataPointToMetricSystemIfNeeded(
                      newTitle.trim(),
                      item.content.dataPoint.value.type,
                    )
                  : parseNumber(newTitle.trim()),
              },
            }).then(() => undefined);
          }

          onError?.(
            new ParsedGraphQLError({
              display: t("utils.data_points.invalid_value_format"),
            }),
          );
          return Promise.resolve();

        case "ContraceptionCreatedOrApproved":
        case "DocumentCreated":
        case "ExperienceCreated":
        case "NoteCreated":
        case "TaskCreated":
          onSuccess();
          return Promise.resolve();
      }
    },
  };
};
