import { EditMenuWrapper, EditMenuWrapperProps } from "./EditMenuWrapper";
import { MoreMenu, MoreMenuProps } from "./MoreMenu";

export type EditMenuProps = Omit<MoreMenuProps, "items"> &
  Omit<EditMenuWrapperProps, "children">;

export const EditMenu = ({
  edit,
  otherActions,
  remove,
  ...popoverMenuProps
}: EditMenuProps) => (
  <EditMenuWrapper edit={edit} otherActions={otherActions} remove={remove}>
    {(items) => <MoreMenu items={items} {...popoverMenuProps} />}
  </EditMenuWrapper>
);
