import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Avatar } from "components/Avatar/Avatar";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { AsyncPatientFormSelect } from "components/Form/Select/AsyncPatientFormSelect";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { FormModal } from "components/Modal/FormModal";
import {
  AddGroupChat,
  AddGroupChatData,
  PatientSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { displayPatient } from "utils/display";
import { notifier } from "utils/notifier";

type FormValues = {
  title: string;
  patients: PatientSummaryFragment[];
};

gql`
  mutation AddGroupChat($title: String!, $patientUuids: [UUID!]!) {
    createMultiPatientsExperience(title: $title, patientUuids: $patientUuids) {
      experience {
        uuid
      }
    }
  }
`;
export const AddGroupChatComposer = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [addGroupChat] = useMutation(AddGroupChat);
  return (
    <FormModal<FormValues>
      title={t("view.community.group_conversations.add_group_chat.title")}
      initialValues={{
        title: "",
        patients: [],
      }}
      validationSchema={{
        title: "required",
        patients: Yup.array().min(
          1,
          t(
            "view.community.group_conversations.add_group_chat.patients_required",
          ),
        ),
      }}
      onHide={onClose}
      submitLabel={t("view.community.group_conversations.add_group_chat.cta")}
      className="flex-col w-full pb-20 px-44"
      onSubmit={({ title, patients }) => {
        addGroupChat(
          {
            title,
            patientUuids: patients.map((p) => p.uuid),
          },
          {
            onSuccess: (data: AddGroupChatData) => {
              notifier.success(
                t(
                  "view.community.group_conversations.add_group_chat.chat_added",
                ),
              );
              navigate(`${routes.GROUP_CHAT_BASE}/${data.experience.uuid}`);
              onClose();
            },
          },
        );
      }}
    >
      <FormInput
        name="title"
        label={t(
          "view.community.group_conversations.add_group_chat.title_label",
        )}
      />
      <FormState<FormValues>>
        {({ values, setFieldValue }) => (
          <>
            {values.patients.length > 0 && (
              <div className="flex flex-col">
                <div className="font-bold">
                  {t(
                    "view.community.group_conversations.add_group_chat.patients_label",
                  )}
                </div>
                <div className=" max-h-[280px] overflow-auto">
                  {values.patients.map((p) => (
                    <div
                      key={p.uuid}
                      className="flex gap-12 items-center group"
                    >
                      <Avatar size={20} user={p} />
                      <div className="flex-shrink font-medium truncate mr-auto">
                        {displayPatient(p)}
                      </div>
                      <ClickableIcon
                        name="close"
                        onClick={() => {
                          setFieldValue(
                            "patients",
                            values.patients.filter((p2) => p2.uuid !== p.uuid),
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <AsyncPatientFormSelect
              name="patient"
              withoutLabel
              placeholder={t(
                "view.community.group_conversations.add_group_chat.patients_placeholder",
              )}
              wrapperClassName="flex-fill justify-between h-full"
              onChange={(p) => {
                if (values.patients.some((p2) => p2.uuid === p?.uuid)) return;
                setFieldValue("patients", [
                  ...(p ? [p] : []),
                  ...values.patients,
                ]);
              }}
            />
          </>
        )}
      </FormState>
    </FormModal>
  );
};
