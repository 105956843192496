import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";

import { PlusMinusSlider } from "components/PlusMinusSlider/PlusMinusSlider";

const DEFAULT_ZOOM_PERCENT = 100;
const MIN_ZOOM_PERCENT = 100;
const MAX_ZOOM_PERCENT = 300;
const SLIDER_ZOOM_STEP_PERCENT = 1;
const BUTTON_ZOOM_STEP_PERCENT = 50;
const PERCENT_TO_RATIO = 0.01;
const MIN_ZOOM_RATIO = MIN_ZOOM_PERCENT * PERCENT_TO_RATIO;
const MAX_ZOOM_RATIO = MAX_ZOOM_PERCENT * PERCENT_TO_RATIO;

export const ZoomableMediaContentWrapper: FC<
  PropsWithChildren<{
    backgroundElement?: ReactNode;
  }>
> = ({ children, backgroundElement }) => {
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM_PERCENT);

  const zoomChangeHandler = (zoomEvent: ReactZoomPanPinchRef) => {
    setZoomValue(zoomEvent.state.scale / PERCENT_TO_RATIO);
  };

  // There is no setScale method in react-zoom-pan-pinch
  // https://github.com/prc5/react-zoom-pan-pinch/issues/137#issuecomment-907817377
  const updateScale = (targetScale: number) => {
    if (!zoomRef.current) return;

    const scale = zoomRef.current.state.scale;
    const factor = Math.log(targetScale / scale);
    const { zoomIn, zoomOut } = zoomRef.current;

    if (targetScale > scale) {
      zoomIn(factor, 0);
    } else {
      zoomOut(-factor, 0);
    }
  };

  useEffect(() => {
    updateScale(zoomValue * PERCENT_TO_RATIO);
  }, [zoomValue]);

  return (
    <div className="relative overflow-hidden flex-col flex-fill">
      {backgroundElement}
      <TransformWrapper
        wheel={{ step: 0.2 }}
        doubleClick={{ disabled: true }}
        onZoom={zoomChangeHandler}
        minScale={MIN_ZOOM_RATIO}
        maxScale={MAX_ZOOM_RATIO}
        ref={zoomRef}
      >
        <TransformComponent
          contentClass="h-full"
          wrapperClass="flex flex-fill w-full"
        >
          {children}
        </TransformComponent>
      </TransformWrapper>

      <div className="absolute bottom-12 left-12 z-1">
        <div className="w-full h-full flex">
          <PlusMinusSlider
            min={MIN_ZOOM_PERCENT}
            max={MAX_ZOOM_PERCENT}
            sliderStep={SLIDER_ZOOM_STEP_PERCENT}
            buttonStep={BUTTON_ZOOM_STEP_PERCENT}
            value={zoomValue}
            onChange={setZoomValue}
          />
        </div>
      </div>
    </div>
  );
};
