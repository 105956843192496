import { AllowedMentionList } from "types";

export const prescriptionAllowedMentions: AllowedMentionList = {
  PatientNote: false,
  MentionedPatientDocument: false,
  Condition: false,
  PatientAllergy: false,
  Experience: false,
  HealthDataPoint: false,
  MentionedDoctor: false,
  MentionedPatient: false,
  Symptom: false,
  FutureValue: false,
  VaccinationStatement: false,
  MedicationStatement: false,
  Procedure: false,
  DeletedVaccination: false,
  DeletedCondition: false,
  DeletedSymptom: false,
  DeletedMedication: false,
  DeletedDatapoint: false,
  DeletedProcedure: false,
  DeletedAllergy: false,
  PubliclyListedDrug: true,
};
