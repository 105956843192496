import { ReactNode, RefObject } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import { AutoPagesOptions } from "components/PDFPreview/usePDFPreview";
import {
  DoctorSummaryFragment,
  SubOrganizationSummaryFragment,
  SupportedLocaleKnownValues,
  UserPersonalInformationFragment,
} from "generated/provider";
import { useTranslation } from "i18n";
import { now } from "utils/date";
import { displayDoctor } from "utils/display";
import { isKnownValue } from "utils/enum";
import { getSignatureSrc } from "utils/signature";

type Theme = "FAX" | "PDF";

export const BaseItemPdfPreview = ({
  theme,
  user,
  previewRef,
  autoPagesOptions,
  className,
  children,
}: {
  theme: Theme;
  user: DoctorSummaryFragment &
    Omit<UserPersonalInformationFragment, "subOrganization"> & {
      subOrganization: SubOrganizationSummaryFragment & {
        locale: string;
        avatarUrl?: string | null;
      };
    };
  previewRef: RefObject<HTMLDivElement>;
  autoPagesOptions: AutoPagesOptions;
  className?: string;
  children: ReactNode;
}) => {
  const t = useTranslation();

  const locale = user.subOrganization.locale;
  if (!isKnownValue(locale, SupportedLocaleKnownValues)) return null;

  const text = (en: string, fr: string, pt: string) =>
    ({ ENGLISH: en, FRENCH: fr, PORTUGUESE: pt }[locale]);

  const signatureSrc = getSignatureSrc(user.signature);
  return (
    <PDFPreview
      previewRef={previewRef}
      className={className}
      documentClassName="flex-col text-black"
      autoPagesOptions={autoPagesOptions}
    >
      <div className="flex items-center space-x-16">
        <Avatar
          user={user.subOrganization}
          squared
          size={44}
          className={
            user.subOrganization.avatar
              ? undefined
              : "bg-grey-200 text-primary-dark"
          }
        />
        <div className="text-16 font-bold">
          {user.subOrganization.displayName ??
            user.subOrganization.organization.displayName}
        </div>
      </div>
      <div className="mt-12 font-medium">
        {displayDoctor(user, "WITH_PREFIX")}
      </div>
      {user.prescriptionAddress && (
        <div className="text-10">
          {text(
            `${user.prescriptionAddress.address}, ${user.prescriptionAddress.city}, ${user.prescriptionAddress.zipCode}`,
            `${user.prescriptionAddress.address} ${user.prescriptionAddress.zipCode} ${user.prescriptionAddress.city}`,
            `${user.prescriptionAddress.address}, ${user.prescriptionAddress.city}, ${user.prescriptionAddress.zipCode}`,
          )}
        </div>
      )}
      {children}
      {signatureSrc && (
        <Section
          theme={theme}
          className="mt-24 legacy-page-break-avoid"
          title={t("pdf_preview.signature")}
        >
          <div>Date: {now().format("date")}</div>
          <img
            src={signatureSrc}
            alt="signature"
            className="self-start object-contain"
            style={{ height: 50 }}
          />
        </Section>
      )}
      <div className="mt-24 border-t pt-12">
        {user.subOrganization.whiteLabelDocumentSignature ??
          t("pdf_preview.default_signature.document")}
      </div>
    </PDFPreview>
  );
};

export const Section = ({
  theme,
  title,
  children,
  className,
}: {
  theme: Theme;
  title: string;
  children: ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <div
      className={classNames(
        "border-t px-8 py-4 uppercase",
        theme === "FAX"
          ? "font-bold text-18"
          : "font-medium text-body bg-grey-100",
      )}
    >
      {title}
    </div>
    <div className="pt-12 px-8 flex-col">{children}</div>
  </div>
);
