import { createContext, useContext } from "react";

import { GetPatientData } from "generated/provider";
import { EHRComposerState } from "types";

export const PatientContext = createContext<{
  patient: NonNullable<GetPatientData["patient"]>;
  ehrComposerState: EHRComposerState;
  setEHRComposerState: (state: EHRComposerState) => void;
} | null>(null);
PatientContext.displayName = "PatientContext";

export const usePatient = () => {
  const context = useContext(PatientContext);
  if (!context) throw new Error("PatientProvider not available");
  return context;
};
