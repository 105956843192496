import { useMemo, useState } from "react";

import {
  consumeCptCode,
  consumeIcd10CMCode,
  consumeIcd10WHOCode,
  getCptCodeStatistics,
  getIcd10CMCodeStatistics,
  getIcd10WHOCodeStatistics,
  useMedicalCodes,
} from "atoms/medicalCodes/useMedicalCodes";
import { Icon } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import { useDoctor } from "contexts/User/UserContext";
import { SupportedLocale } from "generated/provider";
import { getCurrentLanguage, useTranslation } from "i18n";
import {
  CptCode,
  CptCodeKnowledgeBaseEntry,
  ICD10Code,
  ICD10KnowledgeBaseEntry,
} from "types";
import { getLanguageFromLocale } from "utils/intl";

import { Input } from "../Input/Input";

export const MedicalCodesExplorer = ({
  codeType,
  onSelect,
  close,
  pickedCodes,
  locale,
}: {
  codeType: "ICD10CM" | "CPT" | "ICD10WHO";
  onSelect: (code: ICD10KnowledgeBaseEntry | CptCodeKnowledgeBaseEntry) => void;
  close: () => void;
  pickedCodes: (ICD10Code | CptCode)[];
  locale?: SupportedLocale;
}) => {
  const language = locale
    ? getLanguageFromLocale(locale)
    : getCurrentLanguage();
  const [query, setQuery] = useState("");
  const doctor = useDoctor();
  const icd10CMCodeStatistics = getIcd10CMCodeStatistics(doctor.user);
  const cptCodeStatistics = getCptCodeStatistics(doctor.user);
  const icd10WHOCodeStatistics = getIcd10WHOCodeStatistics(doctor.user);
  const {
    searchCpt,
    cpt: { loading: cptLoading },
    searchICD10CM,
    icd10CM: { loading: icd10Loading },
    searchICD10WHO,
    icd10WHO: { loading: icd10WHOLoading },
  } = useMedicalCodes();
  const resultsCpt = useMemo(
    () => searchCpt({ query, cptCodeStatistics, pickedCodes }),
    [searchCpt, query, cptCodeStatistics, pickedCodes],
  );
  const resultsIcd10CM = useMemo(
    () =>
      searchICD10CM({ query, language, icd10CMCodeStatistics, pickedCodes }),
    [searchICD10CM, query, language, icd10CMCodeStatistics, pickedCodes],
  );
  const resultsIcd10WHO = useMemo(
    () =>
      searchICD10WHO({
        query,
        pickedCodes,
        icd10WHOCodeStatistics,
      }),
    [searchICD10WHO, query, pickedCodes, icd10WHOCodeStatistics],
  );

  const results =
    codeType === "CPT"
      ? resultsCpt
      : codeType === "ICD10CM"
      ? resultsIcd10CM
      : resultsIcd10WHO;

  const t = useTranslation();

  return (
    <>
      <Input
        autoFocus
        autoComplete="off"
        placeholder={t("diagnose_section_composer.placeholder")}
        wrapperClassName="border-b"
        leftInnerElement={<Icon name="search" />}
        className="border-0"
        name={codeType === "CPT" ? "cptCodesSearch" : "icd10Search"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="flex-fill flex-col overflow-y-auto">
        {(codeType === "CPT" && cptLoading) ||
        (codeType === "ICD10CM" && icd10Loading) ||
        (codeType === "ICD10WHO" && icd10WHOLoading) ? (
          <Spinner />
        ) : results.isEmpty() ? (
          <div className="px-16 py-8">
            {t("diagnose_section_composer.no_items")}
          </div>
        ) : (
          results.map((it, index) => (
            <button
              onClick={() => {
                onSelect(it);
                close();
                if (codeType === "CPT") consumeCptCode(it.code, doctor.user);
                if (codeType === "ICD10CM") {
                  consumeIcd10CMCode(it.code, doctor.user);
                }
                if (codeType === "ICD10WHO") {
                  consumeIcd10WHOCode(it.code, doctor.user);
                }
              }}
              className="px-16 py-8 text-primary-dark hover:bg-grey-100"
              key={index}
            >
              {"description" in it ? it.description : it[language]}
              <span className="text-body ml-4">{it.code}</span>
            </button>
          ))
        )}
      </div>
    </>
  );
};
