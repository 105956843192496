import { RefObject, useRef } from "react";
import { gql } from "@apollo/client";

import {
  FormDropzone,
  FormDropzoneValue,
  LocalUpload,
} from "components/Form/Dropzone/FormDropzone";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { useMessages } from "contexts/Messages/MessagesContext";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  AddDoctorToExperience,
  CreatePatientDocument,
  PatientDocumentFragment,
  UpdatePatientDocument,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { EHRComposerState } from "types";
import { notifier } from "utils/notifier";

import { EHRComposerTitle } from "./EHRComposerTitle";
import { FooterWrapper } from "./FooterWrapper";
import { useEHRInput } from "./useEHRInput";

gql`
  mutation CreatePatientDocument(
    $upload: UploadInput!
    $title: String!
    $plainTextContent: String
    $category: PatientDocumentCategory!
    $description: String
    $realizedAt: GranularDateTimeInput
    $patientUuid: UUID!
    $visibleToPatient: Boolean!
    $experienceUuid: UUID
    $appointmentUuid: UUID
  ) {
    createPatientDocument(
      input: {
        upload: $upload
        title: $title
        plainTextContent: $plainTextContent
        description: $description
        category: $category
        realizedAt: $realizedAt
        patientUuid: $patientUuid
        visibleToPatient: $visibleToPatient
        experienceUuid: $experienceUuid
        appointmentUuid: $appointmentUuid
      }
    ) {
      document {
        ...PatientDocument
      }
      appointment {
        ...Appointment
      }
    }
  }

  mutation UpdatePatientDocument(
    $uuid: UUID!
    $input: UpdatePatientDocumentInput!
  ) {
    updatePatientDocument(uuid: $uuid, input: $input) {
      document {
        ...PatientDocument
      }
    }
  }
`;

type DocumentComposerFormValues = {
  title: string;
  description: string;
  upload: FormDropzoneValue;
};

export const DocumentComposer = ({
  state,
  inputRef,
}: {
  state: EHRComposerState;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { patient, setEHRComposerState } = usePatient();
  const { user } = useDoctor();

  const { initialEHRInput, resetEHRInput } = useEHRInput();
  const submit = state?.mode === "create" ? state.submit : undefined;

  const { sendMessage } = useMessages();

  const [createDocument] = useMutation(CreatePatientDocument, {
    onSuccess: () => {
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.document_composer.document_created",
        ),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });

  const [updateDocument] = useMutation(UpdatePatientDocument, {
    onSuccess: (result) => {
      if (state?.mode === "document-edit") state.onSuccess?.(result.document);
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.document_composer.document_updated",
        ),
      );
      setEHRComposerState(undefined);
      resetEHRInput();
    },
  });

  const [addDoctorToExperience] = useMutation(AddDoctorToExperience);

  // Used as a workaround to store the document that was just saved by the
  // [onSubmit] handler, so that we can send that document to the patient
  // in the [onClick] handler of the "Send to patient" menu item.
  // TODO: Replace this ref by using submitContext
  const createdDocumentRef = useRef<PatientDocumentFragment>();

  const initialDocument =
    state?.mode === "document-edit" ? state.document : undefined;

  const sendDocument = (
    experienceUuid: UUID,
    document: PatientDocumentFragment,
  ) => {
    document.fileUpload.let((content) =>
      sendMessage(experienceUuid, {
        content,
      }),
    );
  };

  const sendDocumentAndAddDoctorIfNeeded = (
    experienceUuid: UUID,
    isInExperience: boolean,
  ) => {
    if (!createdDocumentRef.current) {
      throw new Error("createdDocumentRef.current was undefined.");
    }

    const document = createdDocumentRef.current;
    if (isInExperience) {
      sendDocument(experienceUuid, document);
    } else {
      addDoctorToExperience(
        { experienceUuid, doctorUuid: user.uuid },
        { onSuccess: () => sendDocument(experienceUuid, document) },
      );
    }
  };

  return (
    <Form<DocumentComposerFormValues>
      initialValues={{
        title: initialDocument?.title ?? initialEHRInput.title,
        description:
          initialDocument?.description ?? initialEHRInput.description,
        upload: { purpose: "PATIENT_DOCUMENT", upload: undefined },
      }}
      className="w-full flex-fill flex-col"
      validate={({ title, upload }) => ({
        ...(title.isBlank() && {
          title: t("document_composer.please_add_a_title_for_your_document"),
        }),
        ...(!initialDocument &&
          !upload.upload && {
            upload: t("document_composer.please_select_a_document_to_upload"),
          }),
        ...(initialDocument &&
          upload.upload && {
            upload: false, // Sanity check: can't upload a new document in edit mode.
          }),
      })}
      onSubmit={async ({ upload, description, title }) => {
        createdDocumentRef.current = undefined;

        if (initialDocument) {
          return updateDocument({
            uuid: initialDocument.uuid,
            input: { title, description },
          });
        }

        const uploadInput = await (upload.upload as LocalUpload).getInput();
        if (!uploadInput) return;
        const output = await createDocument({
          title,
          visibleToPatient: true,
          category: "MISC",
          patientUuid: patient.uuid,
          upload: uploadInput,
          description: description.trimOrNull(),
          realizedAt: {
            dateTime: new Date().toISOString(),
            granularity: "DAY",
          },
          experienceUuid: submit?.to === "experience" ? submit.uuid : undefined,
          appointmentUuid:
            submit?.to === "appointment" ? submit.uuid : undefined,
        });

        if (output) createdDocumentRef.current = output.document;
      }}
    >
      <FormState<DocumentComposerFormValues>>
        {({ submitForm }) => (
          <>
            <EHRComposerTitle inputRef={inputRef} />
            <FormTextArea
              name="description"
              className="border-0"
              wrapperClassName="flex-fill"
              placeholder={t("document_composer.comment_the_document")}
              minRows={5}
            />
            {!initialDocument && (
              <FormDropzone
                name="upload"
                maxSize={50_000_000}
                height={100}
                className="border border-grey-200"
                placeholder={
                  <>
                    <Icon name="uploadCloud" className="mr-10" size={20} />
                    {t(
                      "document_composer.drop_or_upload_a_document_from_your_computer",
                    )}
                  </>
                }
              />
            )}
            <FooterWrapper
              submitLabel={
                submit?.to === "appointment"
                  ? t("document_composer.create_and_send_to_patient")
                  : initialDocument
                  ? t(
                      "inboxes.qa_experience.ehr_composer.document_composer.save",
                    )
                  : t(
                      "inboxes.qa_experience.ehr_composer.document_composer.create",
                    )
              }
              submitMenuItems={
                submit?.to === "appointment"
                  ? undefined
                  : initialDocument
                  ? undefined
                  : [
                      {
                        text: t("document_composer.create_and_send_to_patient"),
                        onClick: async (close: () => void) => {
                          close();
                          await submitForm();
                          if (submit?.to === "experience") {
                            sendDocumentAndAddDoctorIfNeeded(
                              submit.uuid,
                              submit.isInExperience,
                            );
                          }
                        },
                      },
                    ]
              }
              inputRef={inputRef}
            />
          </>
        )}
      </FormState>
    </Form>
  );
};
