import classNames from "classnames";

import { Icon } from "components/Icon/Icon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { AvailabilityOccurrenceFragment } from "generated/provider";
import { useTranslation } from "i18n";

export const AvailabilityOccurrenceEvent = ({
  availabilityOccurrence,
}: {
  availabilityOccurrence: AvailabilityOccurrenceFragment;
}) => {
  const t = useTranslation();
  const availability = availabilityOccurrence.parent;

  const isShortAppointment =
    availability.endAt.secondsFrom(availability.startAt) <= 15 * 60;

  return (
    <button
      onMouseDown={(e) => e.stopPropagation()}
      className={classNames(
        "h-full w-full group overflow-hidden px-8 flex-col space-y-4 rounded text-body relative bg-white",
        { "py-8": !isShortAppointment },
      )}
    >
      <div className="flex items-center">
        {availabilityOccurrence.parent.supportsPhysical && (
          <TooltipWrapper
            label={t(
              "scheduling.calendar.availability_occurrence_event.medical_office",
            )}
            position="top"
          >
            <Icon name="medicalOffice" className="mr-0" color="#DCE0E9" />
          </TooltipWrapper>
        )}
        {availabilityOccurrence.parent.supportsRemote && (
          <TooltipWrapper
            label={t(
              "scheduling.calendar.availability_occurrence_event.remote",
            )}
            position="top"
          >
            <Icon name="videoOn" className="mr-0" color="#DCE0E9" />
          </TooltipWrapper>
        )}
      </div>

      <div className="m-auto hidden group-hover:flex font-semibold uppercase text-10">
        {t("scheduling.calendar.availability_occurrence_event.available")}
      </div>
    </button>
  );
};
