import { useState } from "react";
import { gql } from "@apollo/client";

import {
  AcceptSuggestion,
  GetNoteSuggestion,
  RefuseSuggestion,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { NoteSuggestion } from "types";

gql`
  fragment PatientNoteSuggestion on PatientNoteSuggestion {
    uuid
    contentCategory
    state
    text
  }

  query GetNoteSuggestion($noteUuid: UUID!) {
    patientNote(uuid: $noteUuid) {
      note {
        uuid
        suggestions {
          ...PatientNoteSuggestion
        }
      }
    }
  }

  mutation AcceptSuggestion($suggestionUuid: UUID!) {
    markSuggestionAsAccepted(suggestionUuid: $suggestionUuid) {
      suggestion {
        ...PatientNoteSuggestion
      }
    }
  }

  mutation RefuseSuggestion($suggestionUuid: UUID!) {
    markSuggestionAsRefused(suggestionUuid: $suggestionUuid) {
      suggestion {
        ...PatientNoteSuggestion
      }
    }
  }
`;

export const useSuggestions = (uuid: UUID | undefined): NoteSuggestion[] => {
  const { data } = useQuery(GetNoteSuggestion, {
    skip: !uuid,
    variables: { noteUuid: uuid! },
  });

  const [acceptSuggestion] = useMutation(AcceptSuggestion);
  const [refuseSuggestion] = useMutation(RefuseSuggestion);

  const [currentlyUpdating, setCurrentlyUpdating] = useState<UUID[]>([]);

  return (
    data?.note.suggestions
      .filter((it) => it.state === "PROPOSED")
      .map((it) => ({
        state: currentlyUpdating.includes(it.uuid) ? "PENDING" : "PROPOSED",
        text: it.text,
        uuid: it.uuid,
        contentCategory: it.contentCategory,
        accept: (options: { onSuccess: () => void }) => {
          const op = acceptSuggestion({ suggestionUuid: it.uuid }, options);
          setCurrentlyUpdating((current) => [...current, it.uuid]);
          return op;
        },
        refuse: () => {
          const op = refuseSuggestion({ suggestionUuid: it.uuid });
          setCurrentlyUpdating((current) => [...current, it.uuid]);
          return op;
        },
      })) ?? []
  );
};
