import { useState } from "react";
import gql from "graphql-tag";

import {
  MultimediaContentType,
  useAddTemplateWindow,
} from "atoms/useAddTemplateWindow";
import { DraggableWindow } from "components/DraggableWindow/DraggableWindow";
import { Modal } from "components/Modal/Modal";
import { CreateTemplate } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { isDesktop } from "utils";
import { addTemplateInCache } from "utils/apollo";
import { notifier } from "utils/notifier";

import { MultimediaTemplateForm } from "./MultimediaTemplateForm";
import { TemplateForm } from "./TemplateForm";

gql`
  mutation CreateTemplate($input: CreateTemplateInput!) {
    createTemplate(createTemplateInput: $input) {
      template {
        ...Template
      }
    }
  }

  fragment Template on Template {
    uuid
    title
    content
    fileUpload {
      ...FileUpload
    }
    mentions {
      ...Mention
    }
    type
    doctor {
      uuid
    }
  }

  query GetTemplates($type: TemplateType!) {
    getTemplates(type: $type) {
      templates {
        ...Template
      }
    }
  }
`;

export const AddTemplateWindow = () => {
  const { content } = useAddTemplateWindow();
  return content === null ? null : <AddForm content={content} />;
};

const AddForm = ({ content }: { content: MultimediaContentType }) => {
  const t = useTranslation();
  const [isInitiallyDesktop] = useState(isDesktop());
  const { close } = useAddTemplateWindow();
  const [createTemplate] = useMutation(CreateTemplate, {
    onSuccess: ({ template }, client) => {
      notifier.success(
        t("template_forms.add_template_window.template_created"),
      );
      addTemplateInCache(client, template);
      client.evictQuery("mixedQaInboxSearch", "freeText");
      close();
    },
  });

  const { text, file } = content;
  const form =
    text !== undefined ? (
      <TemplateForm
        initialValues={{
          title: "",
          content: text,
        }}
        onSubmit={async ({ content: txt, ...values }) => {
          if (txt) {
            await createTemplate({
              input: {
                ...values,
                textWithMentions: { text: txt, mentions: [] },
                type: "MESSAGE",
              },
            });
          }
        }}
      />
    ) : (
      <MultimediaTemplateForm
        initialValues={{
          title: "",
          file: { upload: file, purpose: "MESSAGE" },
        }}
        onSubmit={async ({ title, file: f }) => {
          if (f.upload) {
            if ("uuid" in f.upload) {
              await createTemplate({
                input: {
                  title,
                  fileUuid: f.upload.uuid,
                  type: "MESSAGE",
                },
              });
            } else {
              const uploadInput = await f.upload.getInput();
              if (!uploadInput) return;
              await createTemplate({
                input: {
                  title,
                  fileUuid: uploadInput.uuid,
                  type: "MESSAGE",
                },
              });
            }
          }
        }}
      />
    );

  const title = t("template_forms.add_template_window.add_a_template");

  return isInitiallyDesktop ? (
    <DraggableWindow
      initialHeight={500}
      initialWidth={600}
      title={title}
      className="z-observation"
      onHide={close}
      scroll
    >
      {form}
    </DraggableWindow>
  ) : (
    <Modal title={title} onHide={close} alignTopOnMobile>
      {form}
    </Modal>
  );
};
