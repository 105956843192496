import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { TagsManagement } from "components/TagsManagement/TagsManagement";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { ActivityLogs } from "./ActivityLogEvents/ActivityLogs";
import { Branding } from "./Branding/Branding";
import { Integrations } from "./Integrations/Integrations";
import { LinkToBackOffice } from "./OrganizationBackOffice/LinkToBackOffice";
import { PatientCustomFields } from "./PatientCustomFields/PatientCustomFields";
import { PatientOrgEmailNotifications } from "./PatientOrgEmailNotifications/PatientOrgEmailNotifications";
import { RegionPreferences } from "./RegionPreferences/RegionPreferences";
import { SchedulingSettings } from "./Scheduling/SchedulingSettings";
import { Security } from "./Security/Security";
import { ServiceHours } from "./ServiceHours/ServiceHours";
import { SubOrganizations } from "./SubOrganizations/SubOrganizations";
import { TemplatesSettings } from "./Templates/TemplatesSettings";

export const Settings = () => {
  const t = useTranslation();

  return (
    <SidebarPage
      baseRoute={routes.SETTINGS}
      title={t("settings.settings.settings")}
      items={[
        {
          icon: "calendar",
          to: routes.SCHEDULING_SETTINGS,
          Component: SchedulingSettings,
          label: t("settings.settings.scheduling"),
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_APPOINTMENT_CATEGORIES"),
        },
        {
          icon: "filePlus",
          to: routes.TEMPLATES_SETTINGS,
          Component: TemplatesSettings,
          label: t("settings.templates.title"),
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_NOTE_TEMPLATES"),
        },
        {
          icon: "key",
          to: routes.SECURITY_SETTINGS,
          Component: Security,
          label: t("settings.security.title"),
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
        {
          icon: "email",
          to: routes.PATIENT_EMAIL_NOTIFICATIONS,
          Component: PatientOrgEmailNotifications,
          label: t("settings.settings.org_patient_email_notifications"),
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
        {
          icon: "globe",
          to: routes.REGION_SETTINGS,
          Component: RegionPreferences,
          label: t("settings.region_preferences.title"),
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
        {
          to: routes.BRANDING,
          icon: "paint",
          label: t("settings.settings.branding"),
          Component: Branding,
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
        {
          icon: "list",
          to: routes.PATIENT_CUSTOM_FIELDS,
          Component: PatientCustomFields,
          label: t("settings.settings.patient_custom_fields"),
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
        {
          to: routes.INTEGRATIONS,
          icon: "puzzle",
          label: t("settings.settings.integrations"),
          Component: Integrations,
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
        {
          to: routes.SERVICE_HOURS,
          icon: "doubleChatBubble",
          label: t("settings.settings.personalized_autoreplies"),
          Component: ServiceHours,
          hasAccess: (accessor) => accessor.hasPermission("EDIT_SERVICE_HOURS"),
        },
        {
          to: routes.TAGS,
          icon: "tag",
          label: t("settings.settings.tags_management"),
          Component: TagsManagement,
          hasAccess: (accessor) => accessor.hasPermission("UPDATE_TAGS"),
        },
        {
          to: routes.ACTIVITY_LOGS,
          icon: "clipboard",
          label: t("settings.settings.activity_logs"),
          Component: ActivityLogs,
          hasAccess: (accessor) => accessor.hasPermission("VIEW_ACTIVITY_LOGS"),
        },
        {
          to: routes.SUB_ORGANIZATIONS,
          icon: "folder",
          label: t("settings.settings.label_sub_organizations"),
          Component: SubOrganizations,
          organizationUserOnly: true,
        },
        {
          to: routes.LINK_TO_BACKOFFICE,
          icon: "hyperlink",
          label: t("settings.settings.label_link_to_backoffice"),
          Component: LinkToBackOffice,
          hasAccess: (accessor) =>
            accessor.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS"),
        },
      ]}
    />
  );
};
