import { FormInput } from "components/Form/Input/FormInput";
import { FormModal } from "components/Modal/FormModal";
import { useTranslation } from "i18n";

type FormValues = {
  displayName: string;
};

export const BaseSubOrganizationsComposer = ({
  uuid,
  displayName,
  onClose,
  createSubOrganization,
  updateSubOrganization,
}: {
  uuid?: UUID;
  displayName?: string;
  createSubOrganization: (displayName: string) => Promise<void>;
  updateSubOrganization: (updateInput: {
    uuid: UUID;
    input: FormValues;
  }) => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslation();

  return (
    <FormModal<FormValues>
      title={
        uuid
          ? t("settings.sub_organizations.composer.edit_a_sub_organization")
          : t("settings.sub_organizations.composer.create_a_sub_organization")
      }
      submitLabel={
        uuid
          ? t("settings.sub_organizations.composer.edit")
          : t("settings.sub_organizations.composer.create")
      }
      onHide={onClose}
      className="flex-col w-full"
      initialValues={{
        displayName: displayName ?? "",
      }}
      validationSchema={{
        displayName: "required",
      }}
      onSubmit={async ({ displayName: editedDisplayName }) => {
        if (uuid) {
          await updateSubOrganization({
            uuid,
            input: { displayName: editedDisplayName },
          });
        } else {
          await createSubOrganization(editedDisplayName);
        }
      }}
    >
      <FormInput
        name="displayName"
        label={t(
          "settings.sub_organizations.composer.organization_display_name",
        )}
        placeholder={
          uuid
            ? ""
            : t(
                "settings.sub_organizations.composer.organization_display_name_placeholder",
              )
        }
        wrapperClassName="flex-fill justify-between h-full"
      />
    </FormModal>
  );
};
