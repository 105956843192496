import { createContext, useContext } from "react";

import { Maybe } from "base-types";
import { AccessProps } from "types";

export const UserContext = createContext<
  | (AccessProps & {
      uuid: Maybe<UUID>;
      email: Maybe<string>;
      timezone: TimeZone;
      subOrganizationTimezone: TimeZone;
      subOrganizationSupportsApero: boolean;
      doctorSupportsApero: boolean;
      bravadoEnabled: boolean;
      photonEnabled: boolean;
      showOnboardingModal: boolean;
      organizationStringId?: string;
      subOrganizationUuid?: string;
    })
  | null
>(null);
UserContext.displayName = "UserContext";

export const useDoctor = () => {
  const context = useContext(UserContext);
  if (context?.type !== "DOCTOR") {
    throw new Error("Doctor UserContext not available");
  }
  return context;
};

export const useCopilotApiUser = () => {
  const context = useContext(UserContext);
  if (context?.type !== "COPILOT_API_USER") {
    throw new Error("CopilotApiUser UserContext not available");
  }
  return context;
};

export const useMaybeUser = () => useContext(UserContext);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) throw new Error("UserContext not available");
  return context;
};
