import { CSSProperties, ReactNode, useState } from "react";
import { gql } from "@apollo/client";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Maybe } from "base-types";
import { Avatar } from "components/Avatar/Avatar";
import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { DataPointsMenu } from "components/DataPointsMenu/DataPointsMenu";
import { FamilyMemberMenu } from "components/FamilyMemberMenu/FamilyMemberMenu";
import { FormatType, isValidDate } from "components/Form/DatePicker/utils";
import { TextArea } from "components/Form/TextArea/TextArea";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Link } from "components/Link/Link";
import { MenuItemProps } from "components/Menu/MenuItem";
import { MoreMenu } from "components/Menu/MoreMenu";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { Query } from "components/Query/Query";
import { Separator } from "components/Separator/Separator";
import { Spinner } from "components/Spinner/Spinner";
import {
  InnerTagClose,
  RemoveOrConfirmTagRow,
} from "components/Tag/RemoveOrConfirmTag";
import { TagPill } from "components/Tag/TagPill";
import { AddTagRow, TagsMenu, TagsMenuItem } from "components/Tag/TagsMenu";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { usePatientViewData } from "contexts/PatientViewContext/PatientViewContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  AddTagToPatient,
  AllTags,
  CreateCondition,
  CreateHealthDataPoint,
  CreateMedicationStatement,
  CreatePatientAllergy,
  CreateProcedure,
  CreateVaccinationStatement,
  DeleteEHRItemFromTimelineEvent,
  DoctorMedicalLicenses,
  ExperienceTagStatus,
  FamilyMemberDegreeKnownValue,
  FamilyMemberDegreeKnownValues,
  GetAperoPayers,
  GetPatientViewPatientDataData,
  GetPatientWithApero,
  OrganizationPatientFields,
  PatientFieldFragment,
  PatientTimelineEventFragment,
  RemoveTagFromPatient,
  SexKnownValues,
  SimpleHealthDataPointTypeKnownValues,
  UpdateMedicationStatement,
  UpdatePatientAdministrativeData,
  UpdatePatientFieldValue,
  UpdatePatientInput,
} from "generated/provider";
import { ParsedGraphQLError } from "graphql-client/errors";
import { VariablesWithoutUpdateInputs } from "graphql-client/updateInputs";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { TFunction, useTranslation } from "i18n";
import { IconName } from "icon-library";
import { routes } from "routes";
import {
  HealthDataPointKnownValue,
  isHealthDataPointWithKnownUnionValue,
} from "types";
import { getPatientViewRoute } from "utils";
import {
  convertDataPointToMetricSystemIfNeeded,
  displayDataPointValue,
  displayHealthDataPoint,
  displayHealthDataPointName,
  displayHealthDataPointUnit,
  getHealthDataPointEnumValue,
  getHealthDataPointName,
  getHealthDataPointUnitMap,
  shouldBeImperialLengthFormat,
} from "utils/data-points";
import { parseLocalDate } from "utils/date";
import {
  displayAge,
  displayDoctor,
  displayFamilyMemberDegree,
  displayPatient,
  displaySex,
} from "utils/display";
import { isKnownValue } from "utils/enum";
import {
  canOpenOrganizationExternalConsole,
  tryToOpenOrganizationExternalConsole,
} from "utils/externalNavigation";
import {
  bloodPressureValuesFromValidInput,
  isValidBloodPressure,
  isValidImperialLength,
  isValidNumericalInput,
} from "utils/form";
import { notifier } from "utils/notifier";
import { US_STATES_NAME_BY_CODE } from "utils/us-states";

import { useUpdateEHRTimelineItem } from "./PatientInformationPanel/useUpdateEHRTimelineItem";
import { useEHR } from "./useEHR";
import {
  getSourceNote,
  getTimelineEventInfo,
  getUpdatedAt,
  getUpdatingDoctor,
} from "./utils";

gql`
  mutation AddTagToPatient($uuid: UUID!, $tagUuid: UUID!) {
    addTagToPatient(patientUuid: $uuid, tagUuid: $tagUuid) {
      patient {
        uuid
        tags {
          ...PatientTag
        }
      }
    }
  }

  mutation RemoveTagFromPatient($uuid: UUID!, $tagUuid: UUID!) {
    removeTagFromPatient(patientUuid: $uuid, tagUuid: $tagUuid) {
      patient {
        uuid
        tags {
          ...PatientTag
        }
      }
    }
  }

  mutation UpdatePatientFieldValue(
    $updatePatientFieldValueInput: UpdatePatientFieldValueInput!
  ) {
    updatePatientFieldValue(
      updatePatientFieldValueInput: $updatePatientFieldValueInput
    ) {
      patient {
        uuid
        patientFieldValues {
          uuid
          patientField {
            uuid
          }
          value
        }
      }
    }
  }

  mutation UpdatePatientAdministrativeData($input: UpdatePatientInput!) {
    updatePatient(updatePatientInput: $input) {
      patient {
        ...PatientPrescriptionInfos
      }
    }
  }

  fragment PatientPrescriptionInfos on Patient {
    uuid
    firstName
    lastName
    phoneV2
    birthDate
    healthcareSystemIdentifier
    hasCMU
    hasRegisteredDoctor
    address
    postcode
    city
    country
    email
    sex
    state
    summary
  }

  mutation DeleteEHRItemFromTimelineEvent($uuid: UUID!) {
    deleteEHRItemFromTimelineEvent(timelineEventUuid: $uuid) {
      deletedEhrItemUuid
    }
  }

  query PatientDocument($uuid: UUID!) {
    patientDocument(uuid: $uuid) {
      document {
        ...PatientDocument
      }
    }
  }

  query OrganizationPatientFields {
    me {
      doctor {
        uuid
        subOrganization {
          uuid
          organization {
            uuid
            displayName
          }
          displayName
          patientFields {
            ...PatientField
          }
        }
      }
    }
  }

  query DoctorMedicalLicenses {
    me {
      doctor {
        uuid
        medicalLicenses {
          uuid
          state
          expiresAt
        }
      }
    }
  }
`;

export const PatientViewSideSection = ({
  className,
  style,
  onOpenActivity,
  onClose,
}: {
  className?: string;
  style?: CSSProperties;
  onOpenActivity?: () => void;
  onClose?: () => void;
}) => {
  const t = useTranslation();
  const { user, hasPermission, hasAccessToGatekeeperFeature } = useDoctor();
  const isDesktop = useIsDesktop();
  const [addTagToPatient, adding] = useMutation(AddTagToPatient);
  const [removeTagFromPatient, removing] = useMutation(RemoveTagFromPatient);
  const patientViewData = usePatientViewData();
  const patient = patientViewData.patientData.patient;

  const {
    medicalAtcdQuery,
    surgicalAtcdQuery,
    familyAtcdQuery,
    medicationQuery,
    allergiesQuery,
    vaccinesQuery,
    datapointsQuery,
    ehrLoading,
    refetchAll,
  } = useEHR(patient.uuid);
  const { data: allTagsData } = useQuery(AllTags);

  const activeMedicationQuery = medicationQuery.data
    ? medicationQuery.data.patient.patientTimeline.data.data
        .filterNotNull()
        .filter(
          (it) =>
            (it.content.__typename === "MedicationCreatedOrAccepted" &&
              it.content.medicationStatement.status !== "STOPPED") ||
            it.content.__typename === "ContraceptionCreatedOrApproved",
        )
    : [];

  const stoppedMedicationQuery = medicationQuery.data
    ? medicationQuery.data.patient.patientTimeline.data.data
        .filterNotNull()
        .filter(
          (it) =>
            it.content.__typename === "MedicationCreatedOrAccepted" &&
            it.content.medicationStatement.status === "STOPPED",
        )
    : [];

  const addTag = (tag: TagsMenuItem) => {
    addTagToPatient({ uuid: patient.uuid, tagUuid: tag.type.uuid });
  };

  const removeTag = (tag: TagsMenuItem) => {
    removeTagFromPatient({ uuid: patient.uuid, tagUuid: tag.type.uuid });
  };

  const [createCondition, createConditionLoading] =
    useMutation(CreateCondition);
  const [createHealthDataPoint, createHealthDataPointLoading] = useMutation(
    CreateHealthDataPoint,
  );
  const [createProcedure, createProcedureLoading] =
    useMutation(CreateProcedure);
  const [createMedication, createMedicationLoading] = useMutation(
    CreateMedicationStatement,
  );
  const [createAllergy, createAllergyLoading] =
    useMutation(CreatePatientAllergy);
  const [createVaccination, createVaccinationLoading] = useMutation(
    CreateVaccinationStatement,
  );

  const firstInformations = [
    isKnownValue(patient.sex, SexKnownValues) ? displaySex(patient.sex) : null,
    patient.birthDate ? displayAge(patient.birthDate) : null,
    patient.weightKG && isHealthDataPointWithKnownUnionValue(patient.weightKG)
      ? displayHealthDataPoint(patient.weightKG)
      : null,
    patient.imc
      ? `${patient.imc.toFixed(1)} ${t(
          "views.patients.patient_view.ehr.medical_information.bmi",
        )}`
      : null,
  ].filterNotNull();

  const tagsMenuList = allTagsData
    ? allTagsData.tags
        .map((tag) => {
          const patientTag = patient.tags.find(
            (pt) => pt.type.uuid === tag.uuid,
          );
          return patientTag
            ? {
                type: tag,
                status: "VALID" as ExperienceTagStatus,
                updatedAt: patientTag.updatedAt.getTime(),
              }
            : { type: tag };
        })
        .sortAsc((tag) => tag.type.name)
        .sortDesc((tag) => tag.updatedAt ?? 0)
        .sortAsc((tag) => (tag.status === "VALID" ? 0 : 1))
    : null;

  // Silences typescript warning that patient.state might be null after null-check.
  const patientState = patient.state;
  return (
    <div
      className={classNames(
        "flex-col flex-fill bg-grey-100 overflow-hidden relative",
        className,
      )}
      style={style}
    >
      {patientState && hasAccessToGatekeeperFeature("IS_US_COUNTRY") && (
        <Query query={DoctorMedicalLicenses}>
          {(output) => {
            const matchingLicence = output.doctor.medicalLicenses.find(
              (license) => {
                const licenceState = US_STATES_NAME_BY_CODE[license.state];
                return (
                  patientState.toLowerCase() === license.state.toLowerCase() ||
                  patientState.toLowerCase() === licenceState.toLowerCase()
                );
              },
            );
            if (!matchingLicence) {
              return (
                <div className="p-32 bg-danger text-white">
                  <TopSafeArea />
                  {t(
                    "views.patients.patient_view.ehr.disclaimer.missing_license",
                    { state: patientState },
                  )}

                  <Link
                    to={`/${routes.PREFERENCES}/${routes.PERSONAL_INFO}/${routes.PERSONAL_INFO_MEDICAL_LICENSES}`}
                    className="underline"
                  >
                    {t("views.patients.patient_view.ehr.disclaimer.link")}
                  </Link>
                </div>
              );
            }
            if (matchingLicence.expiresAt.isBeforeToday()) {
              return (
                <div className="p-32 bg-danger text-white">
                  <TopSafeArea />
                  {t(
                    "views.patients.patient_view.ehr.disclaimer.expired_license",
                    { state: patientState },
                  )}
                  <Link
                    to={`/${routes.PREFERENCES}/${routes.PERSONAL_INFO}/${routes.PERSONAL_INFO_MEDICAL_LICENSES}`}
                    className="underline"
                  >
                    {t("views.patients.patient_view.ehr.disclaimer.link")}
                  </Link>
                </div>
              );
            }
            return null;
          }}
        </Query>
      )}
      <div className="flex-col flex-fill overflow-auto p-32">
        <TopSafeArea />
        <div className="flex-col flex-fill space-y-32">
          <div className="flex-col">
            <div className="mb-16">
              <div className="flex space-x-12 items-center">
                <Avatar size={36} user={patient} />
                <div className="flex-col leading-snug">
                  <Link
                    to={`${routes.PATIENT_LIST}/${patient.uuid}`}
                    className="text-18 font-bold text-black"
                  >
                    {displayPatient(patient)}
                  </Link>
                  <div className="text-grey-300 text-14">
                    {firstInformations.join(" - ")}
                  </div>
                </div>
                <div className="flex-fill" />
                {user.subOrganization.externalConsoleUrl && (
                  <div className="flex items-center hover:rounded hover:bg-grey-100 px-6 self-start">
                    <ClickableIcon
                      name="shareLight"
                      size={20}
                      className="text-body p-4 rounded hover:bg-grey-200/50"
                      disabled={{
                        if: !canOpenOrganizationExternalConsole(
                          user.subOrganization.externalConsoleUrl,
                          patient,
                        ),
                        tooltip: t("patients.missing_external_id"),
                        position: "left",
                      }}
                      onClick={() =>
                        tryToOpenOrganizationExternalConsole(
                          user.subOrganization.externalConsoleUrl,
                          patient,
                        )
                      }
                    />
                  </div>
                )}
                {onClose && (
                  <div className="flex items-center hover:rounded hover:bg-grey-100 px-6 self-start">
                    <ClickableIcon
                      name="close"
                      className="text-body p-4 rounded hover:bg-grey-200/50"
                      onClick={onClose}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex-wrap gap-x-4 gap-y-8">
              {patient.tags
                .sortAsc((tag) => tag.updatedAt.getTime())
                .map((tag) => (
                  <TagPill
                    key={tag.type.uuid}
                    tag={tag.type}
                    rightInnerElement={
                      isDesktop && (
                        <InnerTagClose onClick={() => removeTag(tag)} />
                      )
                    }
                  />
                ))}
              {tagsMenuList && (
                <TagsMenu
                  tagsList={tagsMenuList}
                  className={patient.tags.isEmpty() ? "-ml-6" : undefined}
                  strokeHover
                  forceBottomLeft
                  addTagLabel={
                    patient.tags.isEmpty()
                      ? t("views.patients.patient_view.ehr.tags.add_tag")
                      : undefined
                  }
                  displayTag={(tag) =>
                    tag.status === "VALID" ? (
                      <RemoveOrConfirmTagRow
                        key={tag.type.uuid}
                        tag={tag}
                        removeTag={removeTag}
                        addTag={addTag}
                        isML={() => false}
                        disabled={
                          removing || adding || !hasPermission("EDIT_PATIENTS")
                        }
                      />
                    ) : (
                      <AddTagRow
                        tag={tag}
                        addTag={addTag}
                        disabled={adding || !hasPermission("EDIT_PATIENTS")}
                      />
                    )
                  }
                />
              )}
            </div>
            <PatientSummary patient={patient} />
          </div>
          <div className={classNames(onOpenActivity && "pb-96")}>
            <Separator />
            <Query query={OrganizationPatientFields}>
              {(output) => {
                if (output.doctor.subOrganization.patientFields.length > 0) {
                  return (
                    <>
                      <PatientCustomFieldsSection
                        orgName={
                          output.doctor.subOrganization.displayName
                            ? output.doctor.subOrganization.displayName
                            : output.doctor.subOrganization.organization
                                .displayName
                        }
                        patient={patient}
                        orgPatientFields={
                          output.doctor.subOrganization.patientFields
                        }
                      />
                      <Separator />
                    </>
                  );
                }
                return null;
              }}
            </Query>
            <AdminSection patient={patient} />
            <PatientViewEhrFoldableSection
              title={t(
                "views.patients.patient_view.ehr.medical_information.title",
              )}
              loading={ehrLoading}
              initialyOpened
            >
              <div className="space-y-20 mt-18">
                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.medical_atcd",
                  )}
                  icon="history"
                  items={
                    medicalAtcdQuery.data
                      ? medicalAtcdQuery.data.patient.patientTimeline.data.data.filterNotNull()
                      : []
                  }
                  nextPage={medicalAtcdQuery.nextPage}
                  fetchingMore={medicalAtcdQuery.fetchingMore}
                  hasMore={
                    medicalAtcdQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  createItem={({ value, onSuccess }) => {
                    createCondition(
                      {
                        patientUuid: patient.uuid,
                        input: {
                          type: value,
                          description: "",
                        },
                      },
                      {
                        onSuccess: () => {
                          refetchAll();
                          notifier.success(
                            t(
                              "view.patients.patient_view.ehr.medical_atcd_added",
                            ),
                          );
                          onSuccess();
                        },
                      },
                    );
                  }}
                  createLoading={createConditionLoading}
                />

                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.surgical_atcd",
                  )}
                  icon="history"
                  items={
                    surgicalAtcdQuery.data
                      ? surgicalAtcdQuery.data.patient.patientTimeline.data.data.filterNotNull()
                      : []
                  }
                  nextPage={surgicalAtcdQuery.nextPage}
                  fetchingMore={surgicalAtcdQuery.fetchingMore}
                  hasMore={
                    surgicalAtcdQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  createItem={({ value, onSuccess }) => {
                    createProcedure(
                      {
                        patientUuid: patient.uuid,
                        input: {
                          name: value,
                          comment: "",
                        },
                      },
                      {
                        onSuccess: () => {
                          refetchAll();
                          notifier.success(
                            t(
                              "view.patients.patient_view.ehr.surgical_atcd_added",
                            ),
                          );
                          onSuccess();
                        },
                      },
                    );
                  }}
                  createLoading={createProcedureLoading}
                />

                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.family_atcd",
                  )}
                  icon="history"
                  items={
                    familyAtcdQuery.data
                      ? familyAtcdQuery.data.patient.patientTimeline.data.data.filterNotNull()
                      : []
                  }
                  nextPage={familyAtcdQuery.nextPage}
                  fetchingMore={familyAtcdQuery.fetchingMore}
                  hasMore={
                    familyAtcdQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  createItem={({ value, onSuccess, type }) => {
                    const familyMember = FamilyMemberDegreeKnownValues.find(
                      (it) => it === type,
                    );
                    createCondition(
                      {
                        patientUuid: patient.uuid,
                        input: {
                          type: value,
                          description: "",
                          familyMember,
                        },
                      },
                      {
                        onSuccess: () => {
                          refetchAll();
                          notifier.success(
                            t(
                              "view.patients.patient_view.ehr.family_history_added",
                            ),
                          );
                          onSuccess();
                        },
                      },
                    );
                  }}
                  isFamilyMemberList
                  createLoading={createConditionLoading}
                />

                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.medication",
                  )}
                  icon="pillsStroke"
                  items={activeMedicationQuery}
                  nextPage={medicationQuery.nextPage}
                  fetchingMore={medicationQuery.fetchingMore}
                  hasMore={
                    medicationQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  createItem={({ value, onSuccess }) => {
                    createMedication(
                      {
                        patientUuid: patient.uuid,
                        name: value,
                        description: "",
                        status: "ACTIVE",
                      },
                      {
                        onSuccess: () => {
                          refetchAll();
                          notifier.success(
                            t(
                              "view.patients.patient_view.ehr.medication_added",
                            ),
                          );
                          onSuccess();
                        },
                      },
                    );
                  }}
                  createLoading={createMedicationLoading}
                />

                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.allergies",
                  )}
                  icon="virus"
                  items={
                    allergiesQuery.data
                      ? allergiesQuery.data.patient.patientTimeline.data.data.filterNotNull()
                      : []
                  }
                  nextPage={allergiesQuery.nextPage}
                  fetchingMore={allergiesQuery.fetchingMore}
                  hasMore={
                    allergiesQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  createItem={({ value, onSuccess }) => {
                    createAllergy(
                      {
                        patientUuid: patient.uuid,
                        input: { type: value },
                      },
                      {
                        onSuccess: () => {
                          refetchAll();
                          notifier.success(
                            t("view.patients.patient_view.ehr.allergy_added"),
                          );
                          onSuccess();
                        },
                      },
                    );
                  }}
                  createLoading={createAllergyLoading}
                />

                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.datapoints",
                  )}
                  icon="dimensions"
                  items={
                    datapointsQuery.data
                      ? datapointsQuery.data.patient.patientTimeline.data.data.filterNotNull()
                      : []
                  }
                  nextPage={datapointsQuery.nextPage}
                  fetchingMore={datapointsQuery.fetchingMore}
                  hasMore={
                    datapointsQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  isHealthDataPointList
                  createItem={({ type, value, onSuccess, onError }) => {
                    const bloodPressureInput =
                      type === "BLOOD_PRESSURE_MMHG"
                        ? bloodPressureValuesFromValidInput(value)
                        : null;
                    if (
                      (isKnownValue(
                        type,
                        SimpleHealthDataPointTypeKnownValues,
                      ) ||
                        type === "BLOOD_PRESSURE_MMHG") &&
                      value
                    ) {
                      createHealthDataPoint(
                        {
                          patientUuid: patient.uuid,
                          input: {
                            simpleDataPointInput:
                              type === "BLOOD_PRESSURE_MMHG"
                                ? null
                                : {
                                    quantity:
                                      convertDataPointToMetricSystemIfNeeded(
                                        value,
                                        type,
                                      ),
                                    type,
                                  },
                            bloodPressureInput,
                          },
                        },
                        {
                          onSuccess: () => {
                            refetchAll();
                            notifier.success(
                              t(
                                "view.patients.patient_view.ehr.datapoint_added",
                              ),
                            );
                            onSuccess();
                          },
                          onError: (e) => {
                            onError?.(e);
                          },
                        },
                      );
                    }
                  }}
                  createLoading={createHealthDataPointLoading}
                />

                <EHRItemsList
                  label={t(
                    "views.patients.patient_view.ehr.medical_information.vaccine",
                  )}
                  icon="vaccine"
                  items={
                    vaccinesQuery.data
                      ? vaccinesQuery.data.patient.patientTimeline.data.data.filterNotNull()
                      : []
                  }
                  nextPage={vaccinesQuery.nextPage}
                  fetchingMore={vaccinesQuery.fetchingMore}
                  hasMore={
                    vaccinesQuery.data?.patient.patientTimeline.data.hasMore
                  }
                  createItem={({ value, onSuccess }) => {
                    createVaccination(
                      {
                        patientUuid: patient.uuid,
                        type: value,
                        description: "",
                        status: "UNKNOWN",
                      },
                      {
                        onSuccess: () => {
                          refetchAll();
                          notifier.success(
                            t("view.patients.patient_view.ehr.vaccine_added"),
                          );
                          onSuccess();
                        },
                      },
                    );
                  }}
                  createLoading={createVaccinationLoading}
                />

                {stoppedMedicationQuery.isNotEmpty() && (
                  <EHRItemsList
                    label={t(
                      "views.patients.patient_view.ehr.medical_information.medication_stopped",
                    )}
                    icon="pillsStroke"
                    items={stoppedMedicationQuery}
                    nextPage={medicationQuery.nextPage}
                    fetchingMore={medicationQuery.fetchingMore}
                    hasMore={
                      medicationQuery.data?.patient.patientTimeline.data.hasMore
                    }
                  />
                )}
              </div>
            </PatientViewEhrFoldableSection>
          </div>
        </div>
      </div>

      {onOpenActivity && (
        <div className="absolute z-1 bottom-0 inset-x-0 p-32">
          <Button
            label={t("views.patients.patient_view.ehr.tags.open_activity")}
            secondary
            className="w-full"
            onClick={() => onOpenActivity()}
          />
        </div>
      )}
    </div>
  );
};

const PatientSummary = ({
  patient,
}: {
  patient: GetPatientViewPatientDataData["patient"];
}) => {
  const t = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [draftSummary, setDraftSummary] = useState(patient.summary);
  const [updatePatientSummary, updatingPatientSummary] = useMutation(
    UpdatePatientAdministrativeData,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submitSummary = (value: string | null) => {
    if (patient.summary?.trimOrNull() === value?.trimOrNull()) {
      setIsEditing(false);
    } else {
      updatePatientSummary(
        {
          input: {
            patientUuid: patient.uuid,
            summary: value?.trimOrNull(),
          },
        },
        {
          onSuccess: () => {
            setIsEditing(false);
            setErrorMessage(undefined);
            notifier.success(
              t("views.patients.patient_view.ehr.summary_success"),
            );
          },
          onError: (error) => {
            setErrorMessage(error.display);
          },
        },
      );
    }
  };

  return (
    <div className="flex items-center mt-8 relative group">
      {patient.summary === null && !isEditing ? (
        <button
          className="flex self-start h-24 rounded-sm -ml-8 py-2 pl-4 pr-8 hover:bg-grey-200/50"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <Icon name="add" size={20} />
          <span className="ml-2" style={{ lineHeight: "20px" }}>
            {t("views.patients.patient_view.ehr.summary_add")}
          </span>
        </button>
      ) : isEditing ? (
        <>
          {errorMessage && (
            <TooltipWrapper
              className="absolute top-2 left-4"
              label={errorMessage}
            >
              <Icon name="warning" className="text-danger" />
            </TooltipWrapper>
          )}
          <TextArea
            name="PatientSummary"
            className="flex-fill bg-transparent leading-[22px] py-0 px-6 min-h-[24px]"
            autoFocus
            minRows={1}
            value={draftSummary ?? ""}
            disabled={updatingPatientSummary}
            onChange={(e) => {
              setDraftSummary(e.currentTarget.value);
            }}
            onBlur={() => {
              submitSummary(draftSummary);
            }}
            onKeyDown={(e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                submitSummary(draftSummary);
              }
              if (e.key === "Escape") {
                setIsEditing(false);
                setDraftSummary(patient.summary);
              }
            }}
          />
        </>
      ) : (
        <>
          <div className="border border-transparent w-full px-6 leading-[22px] min-h-[24px]">
            {patient.summary}
          </div>
          <ClickableIcon
            name="edit"
            className="invisible absolute right-0 bottom-0 group-hover:visible bg-grey-200/50 rounded p-2"
            onClick={() => setIsEditing(true)}
          />
        </>
      )}
    </div>
  );
};

const PatientCustomFieldsSection = ({
  orgName,
  patient,
  orgPatientFields,
}: {
  orgName: string;
  patient: GetPatientViewPatientDataData["patient"];
  orgPatientFields: PatientFieldFragment[];
}) => {
  const t = useTranslation();
  const [isOpened, setIsOpened] = useState(true);
  const getValueForFieldUuid = (id: string) =>
    patient.patientFieldValues.find((f) => f.patientField.uuid === id)?.value ??
    "";

  return (
    <div className="flex-col flex-fill py-16">
      <button
        className="flex items-center"
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className="flex-fill text-black font-medium">
          {t("views.patients.patient_view.ehr.patient_custom_fields.title", {
            org: orgName.upperFirst(),
          })}
        </div>
        <Icon
          name="chevron"
          rotate={isOpened ? 90 : 0}
          className="mr-2 transition-transform duration-100"
        />
      </button>
      {isOpened && (
        <div className="flex-col space-y-2 mt-14">
          {orgPatientFields.map((orgPatientField) => (
            <AdminSectionItem
              key={orgPatientField.uuid}
              patient={patient}
              label={orgPatientField.displayName.upperFirst()}
              initialValue={getValueForFieldUuid(orgPatientField.uuid)}
              successLabel={t(
                "views.patients.patient_view.ehr.patient_custom_fields.update_success",
                { field: orgPatientField.displayName },
              )}
              getPayload={(value) => ({
                type: "PATIENT_FIELD",
                value: {
                  patientFieldUuid: orgPatientField.uuid,
                  value: value.trimOrNull() ? value : null,
                },
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const AdminSection = ({
  patient,
}: {
  patient: GetPatientViewPatientDataData["patient"];
}) => {
  const t = useTranslation();
  const { user, hasAccessToGatekeeperFeature } = useDoctor();
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="flex-col flex-fill border-b py-16">
      <button
        className="flex items-center"
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className="flex-fill text-black font-medium">
          {t("views.patients.patient_view.ehr.admin_information.title")}
        </div>
        <Icon
          name="chevron"
          rotate={isOpened ? 90 : 0}
          className="mr-2 transition-transform duration-100"
        />
      </button>
      {isOpened && (
        <div className="flex-col space-y-2 mt-14">
          <AdminSectionItem
            patient={patient}
            label={t(
              "views.patients.patient_view.ehr.admin_information.first_name",
            )}
            initialValue={patient.firstName}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.first_name_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                firstName: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t(
              "views.patients.patient_view.ehr.admin_information.last_name",
            )}
            initialValue={patient.lastName}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.last_name_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                lastName: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t(
              "views.patients.patient_view.ehr.admin_information.birthdate",
            )}
            initialValue={patient.birthDate?.formatDateInLocale() ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.birthdate_success",
            )}
            getPayload={(value) => {
              if (value.trimOrNull() === null) {
                return { type: "PATIENT", value: { birthDate: null } };
              }
              const format = t("common.date_format") as FormatType;

              if (!isValidDate(value, format)) {
                return {
                  type: "FORMAT_ERROR",
                  errorMessage: t(
                    "views.patients.patient_view.ehr.admin_information.birthdate_format_error",
                  ),
                };
              }
              return {
                type: "PATIENT",
                value: { birthDate: value ? parseLocalDate(value) : null },
              };
            }}
          />
          <AdminSectionItem
            patient={patient}
            label={t("views.patients.patient_view.ehr.admin_information.email")}
            initialValue={patient.email ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.email_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                email: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t("views.patients.patient_view.ehr.admin_information.phone")}
            initialValue={patient.phoneV2 ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.phone_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                phone: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t(
              "views.patients.patient_view.ehr.admin_information.healthcare_system_identifier",
            )}
            initialValue={patient.healthcareSystemIdentifier ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.healthcare_system_identifier_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                healthcareSystemIdentifier: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t(
              "views.patients.patient_view.ehr.admin_information.address",
            )}
            initialValue={patient.address ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.address_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                address: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t(
              "views.patients.patient_view.ehr.admin_information.postcode",
            )}
            initialValue={patient.postcode ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.postcode_sucess",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                postcode: value.trimOrNull() ? value : null,
              },
            })}
          />
          <AdminSectionItem
            patient={patient}
            label={t("views.patients.patient_view.ehr.admin_information.city")}
            initialValue={patient.city ?? ""}
            successLabel={t(
              "views.patients.patient_view.ehr.admin_information.city_success",
            )}
            getPayload={(value) => ({
              type: "PATIENT",
              value: {
                city: value.trimOrNull() ? value : null,
              },
            })}
          />
          {(patient.state || hasAccessToGatekeeperFeature("IS_US_COUNTRY")) && (
            <AdminSectionItem
              patient={patient}
              label={t(
                "views.patients.patient_view.ehr.admin_information.state",
              )}
              initialValue={patient.state ?? ""}
              successLabel={t(
                "views.patients.patient_view.ehr.admin_information.state_success",
              )}
              getPayload={(value) => ({
                type: "PATIENT",
                value: {
                  state: value.trimOrNull() ? value : null,
                },
              })}
            />
          )}
          {
            // Apero fields
            user.subOrganization.supportsApero && (
              <AperoAdminSections patient={patient} />
            )
          }
        </div>
      )}
    </div>
  );
};

type AdminSectionItemPayload =
  | {
      type: "PATIENT";
      value: Omit<
        VariablesWithoutUpdateInputs<UpdatePatientInput>,
        "patientUuid"
      >;
    }
  | {
      type: "PATIENT_FIELD";
      value: { patientFieldUuid: string; value: string | null };
    }
  | { type: "FORMAT_ERROR"; errorMessage: string };

const AdminSectionItem = ({
  patient,
  label,
  initialValue,
  successLabel,
  getPayload,
}: {
  patient: GetPatientViewPatientDataData["patient"];
  label: string;
  initialValue: string;
  successLabel?: string;
  getPayload?: (value: string) => AdminSectionItemPayload;
}) => {
  const [text, setText] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [updatePatient, updating] = useMutation(
    UpdatePatientAdministrativeData,
  );
  const [updatePatientFieldValue, updatingFields] = useMutation(
    UpdatePatientFieldValue,
  );

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const submitInput = (value: string) => {
    if (!getPayload || !successLabel) return;
    if (initialValue.trimOrNull() === value.trimOrNull()) {
      setIsEditing(false);
    } else {
      const payload = getPayload(value);
      if (payload.type === "FORMAT_ERROR") {
        setErrorMessage(payload.errorMessage);
        return;
      }
      if (payload.type === "PATIENT") {
        updatePatient(
          { input: { patientUuid: patient.uuid, ...payload.value } },
          {
            onSuccess: () => {
              setIsEditing(false);
              setErrorMessage(undefined);
              notifier.success(successLabel);
            },
            onError: (error) => {
              setErrorMessage(error.display);
            },
          },
        );
      } else {
        updatePatientFieldValue(
          {
            updatePatientFieldValueInput: {
              patientUuid: patient.uuid,
              ...payload.value,
            },
          },
          {
            onSuccess: () => {
              setIsEditing(false);
              setErrorMessage(undefined);
              notifier.success(successLabel);
            },
            onError: (error) => {
              setErrorMessage(error.display);
            },
          },
        );
      }
    }
  };

  return (
    <div
      className={classNames(
        "-ml-4 flex items-start rounded-sm group relative",
        { "hover:bg-grey-150": !isEditing },
      )}
    >
      <div className="flex-fill pl-4">{label}</div>
      {isEditing ? (
        <EditableItem
          value={text}
          className="w-[229px]"
          wrapperClassName="flex-col"
          setValue={setText}
          submit={(val) => {
            submitInput(val.toString());
          }}
          cancel={() => {
            setIsEditing(false);
            setText(initialValue);
            setErrorMessage(undefined);
          }}
          loading={updating || updatingFields}
          errorMessage={errorMessage}
        />
      ) : (
        <>
          <div
            className={classNames(
              "w-[229px] border border-transparent text-primary-dark px-10 leading-[22px]",
            )}
          >
            {initialValue}
          </div>
          {getPayload && (
            <ClickableIcon
              name="edit"
              className="absolute inset-tr-0 invisible group-hover:visible bg-grey-150/85 rounded p-2"
              onClick={() => setIsEditing(true)}
            />
          )}
        </>
      )}
    </div>
  );
};

const PatientViewEhrFoldableSection = ({
  title,
  children,
  loading,
  initialyOpened,
}: {
  title: string;
  children: ReactNode;
  loading?: boolean;
  initialyOpened?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(initialyOpened ?? false);

  return (
    <div className="flex-col flex-fill border-b py-16">
      <button
        className="flex items-center"
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className="flex-fill text-black font-medium">{title}</div>
        <Icon
          name="chevron"
          rotate={isOpened ? 90 : 0}
          className="mr-2 transition-transform duration-100"
        />
      </button>
      {isOpened && (loading ? <Spinner /> : children)}
    </div>
  );
};

const EHRItemsList = ({
  label,
  icon,
  items,
  hasMore,
  nextPage,
  fetchingMore,
  createItem,
  createLoading,
  isHealthDataPointList,
  isFamilyMemberList,
}: {
  label: string;
  icon: IconName;
  items: PatientTimelineEventFragment[];
  hasMore?: boolean;
  nextPage?: () => void;
  fetchingMore: boolean;
  createItem?: (params: {
    value: string;
    type?: HealthDataPointKnownValue | FamilyMemberDegreeKnownValue;
    onSuccess: () => void;
    onError?: (e: ParsedGraphQLError) => void;
  }) => void;
  createLoading?: boolean;
  isHealthDataPointList?: boolean;
  isFamilyMemberList?: boolean;
}) => {
  const t = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [text, setText] = useState("");
  const [selectedDatapoint, setSelectedDatapoint] =
    useState<HealthDataPointKnownValue>();
  const [selectedFamilyMember, setSelectedFamilyMember] =
    useState<FamilyMemberDegreeKnownValue>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const datapointlist = (item: PatientTimelineEventFragment) =>
    isHealthDataPointList
      ? items.filter(
          (d) =>
            d.content.__typename === "DataPointCreated" &&
            item.content.__typename === "DataPointCreated" &&
            getHealthDataPointName(d.content.dataPoint) ===
              getHealthDataPointName(item.content.dataPoint),
        )
      : [];

  const submitInput = (
    value: string,
    type?: HealthDataPointKnownValue | FamilyMemberDegreeKnownValue,
  ) => {
    if (value.isBlank()) {
      setIsAdding(false);
      setText("");
      setErrorMessage(undefined);
      return;
    }
    if (type === "BLOOD_PRESSURE_MMHG" && !isValidBloodPressure(value)) {
      setErrorMessage(
        t("ehr_data_point_composer.format_should_be_systolicdiastolic"),
      );
      return;
    }
    if (
      type &&
      SimpleHealthDataPointTypeKnownValues.some((i) => i === type) &&
      // @ts-ignore TS2345 (SimpleHealthDataPointTypeKnownValues.some(...) does not narrow type)
      !shouldBeImperialLengthFormat(type) &&
      !isValidNumericalInput(value)
    ) {
      setErrorMessage(t("ehr_data_point_composer.should_be_a_number"));
      return;
    }
    if (shouldBeImperialLengthFormat(type) && !isValidImperialLength(value)) {
      setErrorMessage(
        t("patient_view_side_section.should_be_in_ft_inch_format"),
      );
      return;
    }

    createItem?.({
      value,
      type,
      onSuccess: () => {
        setIsAdding(false);
        setText("");
        setErrorMessage(undefined);
      },
      onError: (e) => {
        setErrorMessage(e.display);
      },
    });
  };

  return (
    <div>
      <div className="flex items-center space-x-4 leading-normal">
        <Icon name={icon} />
        <div className="uppercase font-bold text-12">{label}</div>
        {createItem &&
          (isHealthDataPointList ? (
            <DataPointsMenu
              onDatapointSelected={(datapoint) => {
                setSelectedDatapoint(datapoint);
                setIsAdding(true);
              }}
            />
          ) : isFamilyMemberList ? (
            <FamilyMemberMenu
              onFamilyMemberSelected={(famMemberDegree) => {
                setSelectedFamilyMember(famMemberDegree);
                setIsAdding(true);
              }}
            />
          ) : (
            <ClickableIcon
              name="add"
              size={18}
              className="bg-grey-200/25 hover:bg-grey-200/50 rounded p-[3px] ml-auto -my-2"
              onClick={() => setIsAdding(true)}
            />
          ))}
      </div>

      {(items.isNotEmpty() || (createItem && isAdding)) && (
        <div className="flex-col mt-6">
          {createItem && isAdding && (
            <div className="flex items-center ml-8">
              <Icon name="dot" size={6} className="-ml-1 text-primary-dark" />
              <EditableItem
                className="flex-fill flex px-6"
                wrapperClassName={
                  selectedDatapoint ? "flex flex-shrink ml-4" : "flex-fill ml-4"
                }
                value={text}
                setValue={setText}
                submit={(val) => {
                  submitInput(
                    val.toString(),
                    selectedDatapoint ?? selectedFamilyMember,
                  );
                }}
                cancel={() => {
                  setIsAdding(false);
                  setText("");
                }}
                loading={createLoading}
                prefix={
                  selectedDatapoint
                    ? `${displayHealthDataPointName(selectedDatapoint)}${t(
                        "utils.colon",
                      )}`
                    : selectedFamilyMember
                    ? ` ${displayFamilyMemberDegree(selectedFamilyMember)}${t(
                        "utils.colon",
                      )}`
                    : undefined
                }
                suffix={
                  selectedDatapoint
                    ? displayHealthDataPointUnit(selectedDatapoint)
                    : undefined
                }
                errorMessage={errorMessage}
              />
            </div>
          )}
          {items
            .reduce<PatientTimelineEventFragment[]>((acc, e) => {
              if (e.content.__typename === "DataPointCreated") {
                const dataPointName = getHealthDataPointName(
                  e.content.dataPoint,
                );
                if (
                  dataPointName &&
                  !acc.some(
                    (element) =>
                      element.content.__typename === "DataPointCreated" &&
                      getHealthDataPointName(element.content.dataPoint) ===
                        dataPointName,
                  )
                ) {
                  acc.push(e);
                }
              } else {
                acc.push(e);
              }
              return acc;
            }, [])
            .map((item) => (
              <EHRItem
                item={item}
                key={item.uuid}
                subItems={datapointlist(item)}
              />
            ))}
          {hasMore && (
            <NextPageButton
              className="p-0 ml-[31px]"
              nextPage={nextPage}
              fetchingMore={fetchingMore}
            />
          )}
        </div>
      )}
    </div>
  );
};

const getItemAdditionalInformation = (
  item: PatientTimelineEventFragment,
  t: TFunction,
) => {
  if (
    item.content.__typename === "DataPointCreated" &&
    isHealthDataPointWithKnownUnionValue(item.content.dataPoint)
  ) {
    return `${t("utils.colon")}${displayHealthDataPoint(
      item.content.dataPoint,
    )}`;
  } else if (
    item.content.__typename === "ConditionCreatedOrAccepted" &&
    item.content.condition.familyMember &&
    isKnownValue(
      item.content.condition.familyMember,
      FamilyMemberDegreeKnownValues,
    )
  ) {
    return ` (${displayFamilyMemberDegree(
      item.content.condition.familyMember,
    )})`;
  }
  return "";
};

const getCustomInitialValue = (item: PatientTimelineEventFragment) => {
  if (
    item.content.__typename === "DataPointCreated" &&
    isHealthDataPointWithKnownUnionValue(item.content.dataPoint)
  ) {
    if (item.content.dataPoint.value.__typename === "BloodPressureValue") {
      return displayDataPointValue(item.content.dataPoint);
    }
    if (
      isKnownValue(
        item.content.dataPoint.value.type,
        SimpleHealthDataPointTypeKnownValues,
      )
    ) {
      return displayDataPointValue(item.content.dataPoint);
    }
  } else if (
    item.content.__typename === "ConditionCreatedOrAccepted" &&
    item.content.condition.familyMember
  ) {
    return getTimelineEventInfo(item).summary;
  }
};

const EHRItem = ({
  item,
  subItems,
  isSub = false,
}: {
  item: PatientTimelineEventFragment;
  subItems?: PatientTimelineEventFragment[];
  isSub?: boolean;
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { patient } = usePatient();
  const { summary } = getTimelineEventInfo(item);
  const sourceNote = getSourceNote(item);
  const updatingDoctor = getUpdatingDoctor(item);
  const updatedAt = getUpdatedAt(item);
  const [isOpened, setIsOpened] = useState(false);
  const additionalInformation = getItemAdditionalInformation(item, t);
  const [updateMedication] = useMutation(UpdateMedicationStatement);

  const viewText = `${summary.upperFirst()}${additionalInformation}`;
  const initialValue = getCustomInitialValue(item) ?? viewText;

  const updatedBy = updatingDoctor
    ? ` ${t("note_annotation.by").toLowerCase()} ${displayDoctor(
        updatingDoctor,
        "WITH_PREFIX",
      )}`
    : item.author?.__typename === "Doctor"
    ? ` ${t("note_annotation.by").toLowerCase()} ${displayDoctor(
        item.author,
        "WITH_PREFIX",
      )}`
    : "";

  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [itemValue, setItemValue] = useState(initialValue);
  const [deleteTimelineItem] = useMutation(DeleteEHRItemFromTimelineEvent, {
    onSuccess: () =>
      notifier.success(
        t("views.patients.patient_view.ehr.item_update_success"),
      ),
  });

  const { updateEHRTimelineItem, loading: updateEHRTimelineItemLoading } =
    useUpdateEHRTimelineItem({
      onSuccess: () => {
        setIsEditing(false);
        notifier.success(
          t("views.patients.patient_view.ehr.item_update_success"),
        );
        setErrorMessage(undefined);
      },
      onError: (e) => {
        setErrorMessage(e.display);
      },
    });

  const submitInput = (value: string) => {
    if (viewText.trimOrNull() === value.trimOrNull()) {
      setIsEditing(false);
    } else {
      if (
        item.content.__typename === "DataPointCreated" &&
        item.content.dataPoint.value.__typename === "BloodPressureValue" &&
        !isValidBloodPressure(value)
      ) {
        setErrorMessage(
          t("ehr_data_point_composer.format_should_be_systolicdiastolic"),
        );
        return;
      } else if (
        item.content.__typename === "DataPointCreated" &&
        item.content.dataPoint.value.__typename === "SimpleHealthDataPointValue"
      ) {
        if (
          !shouldBeImperialLengthFormat(item.content.dataPoint.value.type) &&
          !isValidNumericalInput(value)
        ) {
          setErrorMessage(t("ehr_data_point_composer.should_be_a_number"));
          return;
        } else if (
          shouldBeImperialLengthFormat(item.content.dataPoint.value.type) &&
          !isValidImperialLength(value)
        ) {
          setErrorMessage(
            t("patient_view_side_section.should_be_in_ft_inch_format"),
          );
          return;
        }
      }

      updateEHRTimelineItem(item, value, null);
    }
  };

  const menuItems: Maybe<MenuItemProps>[] = [
    {
      text: t("views.patients.patient_view.ehr.modify_label"),
      icon: "edit",
      onClick: (close: () => void) => {
        setIsEditing(true);
        close();
      },
    },
    item.content.__typename === "MedicationCreatedOrAccepted"
      ? {
          text:
            item.content.medicationStatement.status === "STOPPED"
              ? t("view.patients.patient_view.ehr.medication_mark_active")
              : t("view.patients.patient_view.ehr.medication_mark_stopped"),
          icon:
            item.content.medicationStatement.status === "STOPPED"
              ? "player"
              : "stop",
          onClick: (close: () => void) => {
            if (item.content.__typename === "MedicationCreatedOrAccepted") {
              updateMedication({
                medicationUuid: item.content.medicationStatement.uuid,
                input: {
                  name: item.content.medicationStatement.name,
                  description: item.content.medicationStatement.description,
                  status:
                    item.content.medicationStatement.status === "STOPPED"
                      ? "ACTIVE"
                      : "STOPPED",
                },
              }).then(close);
            } else {
              close();
            }
          },
        }
      : null,
    sourceNote
      ? {
          text: t("patient_view.ehr.see_associated_note"),
          icon: "chatVerticalEmpty",
          onClick: (close: () => void) => {
            navigate(
              getPatientViewRoute(patient, [
                // TODO(@liautaud): Simplify after #22543.
                sourceNote.parentAppointment ?? sourceNote,
              ]),
            );
            close();
          },
        }
      : null,
    {
      text: t("views.patients.patient_view.ehr.delete"),
      icon: "trash",
      className: "text-danger",
      onClick: (close: () => void) => {
        deleteTimelineItem({ uuid: item.uuid });
        close();
      },
    },
  ];

  const ViewItem = ({
    noTooltip = false,
    isSubItem = false,
  }: {
    noTooltip?: boolean;
    isSubItem?: boolean;
  }) => {
    const viewItemText =
      (isOpened || isSub) && item.content.__typename === "DataPointCreated" ? (
        <TooltipWrapper label={updatedBy} position="top">
          <div
            className={classNames(
              "border border-transparent px-4 leading-[22px] min-h-[24px]",
              isSub ? "text-grey-300" : "text-primary-dark",
            )}
          >
            {isSub ? (
              <>
                <span className="invisible -ml-5">
                  {summary.upperFirst()}
                  {t("utils.colon")}
                </span>
                {additionalInformation.slice(t("utils.colon").length)}
              </>
            ) : (
              <>{viewText}</>
            )}
            {` ${t("view.patients.patient_view.ehr.datapoint_edited_on", {
              date: item.content.dataPoint.createdAt.format("date"),
            }).toLowerCase()}`}
          </div>
        </TooltipWrapper>
      ) : (
        <div className="border border-transparent text-primary-dark px-4 leading-[22px] min-h-[24px]">
          {viewText}
        </div>
      );

    return (
      <>
        {subItems && subItems.length > 1 ? (
          <Icon
            name="chevron"
            rotate={isOpened ? 90 : 0}
            className="-ml-8 -mr-8 text-primary-dark"
          />
        ) : (
          <Icon
            name="dot"
            size={6}
            className={classNames("-ml-1 text-primary-dark", {
              invisible: isSubItem,
            })}
          />
        )}
        <div className="flex-fill relative rounded hover:bg-grey-200/50 group ml-6">
          {!noTooltip ? (
            <TooltipWrapper
              label={
                item.content.__typename === "MedicationCreatedOrAccepted"
                  ? item.content.medicationStatement.status === "STOPPED"
                    ? `${t(
                        "view.patients.patient_view.ehr.medication_stopped_on",
                        {
                          date: item.content.medicationStatement.updatedAt.format(
                            "date",
                          ),
                        },
                      )}${updatedBy}`
                    : `${t(
                        "view.patients.patient_view.ehr.medication_active_on",
                        {
                          date: item.content.medicationStatement.updatedAt.format(
                            "date",
                          ),
                        },
                      )}${updatedBy}`
                  : `${t("view.patients.patient_view.ehr.datapoint_edited_on", {
                      date: updatedAt,
                    })}${updatedBy}`
              }
              position="top"
            >
              {viewItemText}
            </TooltipWrapper>
          ) : (
            viewItemText
          )}
          {menuItems.filterNotNull().isNotEmpty() && (
            <MoreMenu
              className="invisible absolute top-2 right-[3px] p-0 group-hover:visible"
              iconSize={20}
              items={menuItems.filterNotNull()}
              position="bottom-right"
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="flex items-center ml-8">
        {isEditing ? (
          <>
            <Icon name="dot" size={6} className="-ml-1 text-primary-dark" />
            <EditableItem
              value={itemValue}
              setValue={setItemValue}
              className="flex-fill flex px-6"
              wrapperClassName={
                item.content.__typename === "DataPointCreated" &&
                isHealthDataPointWithKnownUnionValue(item.content.dataPoint)
                  ? "flex flex-shrink ml-4"
                  : "flex-fill ml-4"
              }
              submit={(val) => {
                submitInput(val.toString());
              }}
              cancel={() => {
                setIsEditing(false);
                setItemValue(initialValue);
                setErrorMessage(undefined);
              }}
              loading={updateEHRTimelineItemLoading}
              prefix={
                item.content.__typename === "DataPointCreated" &&
                isHealthDataPointWithKnownUnionValue(item.content.dataPoint)
                  ? `${summary.upperFirst()}${t("utils.colon")}`
                  : item.content.__typename === "ConditionCreatedOrAccepted" &&
                    item.content.condition.familyMember &&
                    isKnownValue(
                      item.content.condition.familyMember,
                      FamilyMemberDegreeKnownValues,
                    )
                  ? `${displayFamilyMemberDegree(
                      item.content.condition.familyMember,
                    )}${t("utils.colon")}`
                  : ""
              }
              suffix={
                item.content.__typename === "DataPointCreated" &&
                isHealthDataPointWithKnownUnionValue(item.content.dataPoint)
                  ? getHealthDataPointEnumValue(
                      item.content.dataPoint,
                      getHealthDataPointUnitMap(),
                    )
                  : undefined
              }
              errorMessage={errorMessage}
            />
          </>
        ) : subItems && subItems.length > 1 ? (
          <div
            className="flex flex-fill items-center hover:cursor-pointer"
            onClick={() => setIsOpened(!isOpened)}
          >
            <ViewItem noTooltip={isOpened || isSub} isSubItem={isSub} />
          </div>
        ) : (
          <ViewItem noTooltip={isOpened || isSub} isSubItem={isSub} />
        )}
      </div>
      {isOpened && subItems && subItems.length > 1 && (
        <div className="flex-col">
          {subItems.slice(1).map((it) => (
            <EHRItem item={it} key={it.uuid} isSub />
          ))}
        </div>
      )}
    </>
  );
};

const EditableItem = ({
  value,
  setValue,
  className,
  wrapperClassName,
  submit,
  cancel,
  prefix,
  suffix,
  loading,
  errorMessage,
}: {
  value: string | number;
  setValue: (v: string) => void;
  className?: string;
  wrapperClassName?: string;
  submit: (input: string | number) => void;
  cancel: () => void;
  prefix?: string;
  suffix?: string;
  loading?: boolean;
  errorMessage?: string;
}) => (
  <>
    {prefix && <div className="ml-10 text-primary-dark">{prefix}</div>}
    <div className={classNames(wrapperClassName, "relative")}>
      {errorMessage && (
        <TooltipWrapper className="absolute top-2 left-4" label={errorMessage}>
          <Icon name="warning" className="text-danger" />
        </TooltipWrapper>
      )}
      <TextArea
        className={classNames(
          className,
          "bg-white leading-[22px] py-0 min-h-[24px]",
          { "text-danger pl-32": errorMessage !== undefined },
          { "text-right": suffix !== undefined },
        )}
        style={
          errorMessage ? { borderColor: "#FF323D" } : { borderColor: "#DCE0E9" }
        }
        autoFocus
        minRows={1}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onBlur={() => {
          submit(value);
        }}
        disabled={loading ?? false}
        onKeyDown={(e) => {
          if (!e.shiftKey && e.key === "Enter") {
            e.preventDefault();
            submit(value);
          }
          if (e.key === "Escape") cancel();
        }}
      />
    </div>
    {suffix && <div className="px-4 text-primary-dark">{suffix}</div>}
  </>
);

const AperoAdminSections = ({
  patient,
}: {
  patient: GetPatientViewPatientDataData["patient"];
}) => {
  const aperoPayers = useQuery(GetAperoPayers);
  const patientWithApero = useQuery(GetPatientWithApero, {
    variables: { patientUuid: patient.uuid },
  });
  const t = useTranslation();
  return (
    <>
      <AdminSectionItem
        patient={patient}
        label={t("patients.patient_apero_form.payer")}
        initialValue={
          aperoPayers.data?.results.find(
            (o) =>
              o.tradingPartnerId ===
              patientWithApero.data?.patient.aperoPatient?.coverages.at(0)
                ?.tradingPartnerId,
          )?.name ?? ""
        }
      />
      <AdminSectionItem
        patient={patient}
        label={t("patients.patient_apero_form.membership_id")}
        initialValue={
          patientWithApero.data?.patient.aperoPatient?.coverages.at(0)
            ?.subscriberId ?? ""
        }
      />
    </>
  );
};
