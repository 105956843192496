import { ClickableIcon } from "components/Icon/ClickableIcon";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useDoctor } from "contexts/User/UserContext";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { trackEvent } from "tracking";
import { copyToClipBoard } from "utils";

import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";
import { Row } from "../components/Row";
import { SearchPanelState } from "../types";

export const Copilot = ({
  onContentSelected,
  setState,
}: {
  onContentSelected: ((value: string) => void) | null;
  setState: (value: SearchPanelState) => void;
}) => {
  const t = useTranslation();
  const { experience, isInExperience } = useQAExperience();
  const { setEHRComposerState } = usePatient();
  const { hasRole } = useDoctor();

  const contentSelectedRef = useSyncRef(onContentSelected);
  const showRankingScore = hasRole("REVIEWER");

  const suggestedAnswerThreshold = 0.08;
  const actions = experience.suggestedActions.mapNotNull((a) =>
    a.__typename === "SendPrescriptionCopilotAction" ? a : null,
  );

  return (
    <>
      {actions.isNotEmpty() && (
        <div className="mb-12 w-full text-16 font-medium text-primary-dark">
          {t("inboxes.qa_side_panel.search_panel.copilot.actions")}
        </div>
      )}
      {actions.map((it) => (
        <Row
          key={it.appointment.uuid}
          icon={{
            name: "document",
            iconClassName: "bg-grey text-white",
          }}
          title={t(
            "inboxes.qa_side_panel.search_panel.copilot.send_a_prescription",
          )}
          content={t("copilot.video_consultation_on_date", {
            date: it.appointment.startAt.format("date"),
          })}
          contentClassName="line-clamp-7"
          date={null}
          search={null}
          onClick={() => {
            setEHRComposerState({
              mode: "create",
              submit: {
                to: "experience",
                uuid: experience.uuid,
                isInExperience,
              },
            });
          }}
        />
      ))}
      {experience.suggestedAnswers
        .filter((answer) =>
          answer ? answer.score > suggestedAnswerThreshold : false,
        )
        .mapNotNull((it, index) => {
          if (!it) return null;
          const textContent = it.message.content.let((content) =>
            content.__typename === "TextMessageContent"
              ? content.text
                  .replaceAll(
                    "@patient_username",
                    experience.patient?.username ?? "@patient_username",
                  )
                  .replaceAll(
                    /(bonjour) [\w-]*/gimu,
                    `Bonjour ${
                      experience.patient?.username ?? "@patient_username"
                    }`,
                  )
              : t("copilot.unsupported_message_format"),
          );
          const action = contentSelectedRef.current
            ? contentSelectedRef.current
            : (value: string) => {
                copyToClipBoard(
                  value,
                  t(
                    "inboxes.qa_side_panel.search_panel.copilot.message_copied",
                  ),
                );
              };
          return (
            <>
              {index === 0 && (
                <div className="w-full text-16 font-medium text-primary-dark">
                  {t(
                    "inboxes.qa_side_panel.search_panel.copilot.suggested_answers",
                  )}
                </div>
              )}
              <Row
                key={it.uuid}
                icon={{
                  name: "chat",
                  iconClassName: "bg-orange-100 text-orange",
                }}
                content={textContent.replaceAll("\n\n", "\n")}
                rightContent={
                  showRankingScore
                    ? it.score === -1
                      ? undefined
                      : `${it.score.toFixed(3)}`
                    : undefined
                }
                contentClassName="line-clamp-7"
                date={null}
                search={null}
                onClick={() =>
                  setState({
                    mode: "SUGGESTED_ANSWER",
                    textContent,
                    rank: index + 1,
                  })
                }
                rightIcon={
                  <ClickableIcon
                    name="copy"
                    className="invisible group-hover:visible"
                    onClick={(e) => {
                      action(textContent);
                      trackEvent({
                        name: "Copilot Suggestion Accepted",
                        properties: { rank: index + 1 },
                      });
                      e.stopPropagation();
                    }}
                  />
                }
              />
            </>
          );
        })}
    </>
  );
};
