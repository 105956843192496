import classNames from "classnames";

import { PatientRow } from "components/Patient/PatientRow";
import { BackOfficePatientFragment } from "generated/provider";

import { CustomOptionProps } from "./OptionsPopover";

export const PatientRowOption = ({
  value,
  active,
  selected,
}: CustomOptionProps<BackOfficePatientFragment>) => (
  <PatientRow
    patient={value}
    nameClassName={selected ? "text-white" : undefined}
    className={classNames(
      "rounded px-10 py-8",
      selected ? "bg-primary text-white" : active ? "bg-grey-100" : undefined,
      { "font-medium": active },
    )}
  />
);
