import classNames from "classnames";

import { useDoctor } from "contexts/User/UserContext";
import {
  DoctorSummaryFragment,
  PatientSummaryFragment,
} from "generated/provider";
import { sortUsers } from "utils/display";

import { Avatar } from "./Avatar";

export const AvatarDuo = ({
  doctors,
  patient,
  className,
  omitUser,
  withConnectedStatus,
}: {
  doctors: DoctorSummaryFragment[];
  patient?: PatientSummaryFragment;
  className?: string;
  omitUser?: boolean;
  withConnectedStatus?: boolean;
}) => {
  const { user } = useDoctor();
  const users = sortUsers({
    doctors: omitUser ? doctors.filter((d) => d.uuid !== user.uuid) : doctors,
    patient,
  });

  // List can be empty when you are alone in a conversation
  return users.length <= 1 ? (
    <Avatar
      user={users.at(0)}
      size={36}
      className={className}
      withConnectedStatus={withConnectedStatus}
    />
  ) : (
    <div className={classNames("flex items-center", className)}>
      <Avatar
        user={users[0]}
        size={24}
        className="mt-12 z-1"
        withConnectedStatus={withConnectedStatus}
      />
      <Avatar
        user={users[1]}
        size={24}
        className="mb-12 -ml-12"
        withConnectedStatus={withConnectedStatus}
      />
    </div>
  );
};
