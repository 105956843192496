import { useCallback, useState } from "react";

import { useAuth } from "auth/AuthContext";
import { DEVICE_UUID_KEY } from "consts";
import { RemoveDevice } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";

export const useLogout = () => {
  const auth = useAuth();
  const [loggingOut, setLoggingOut] = useState(false);

  // @graphql-schema-usage-ignore: We check the request context before calling.
  const [removeDevice] = useMutation(RemoveDevice);

  return [
    useCallback(async () => {
      if (auth.state !== "LOGGED_IN") return;
      setLoggingOut(true);
      const serializedDeviceUuid = storage.getItem(DEVICE_UUID_KEY);
      const deviceUuid =
        serializedDeviceUuid && JSON.parse(serializedDeviceUuid);
      if (deviceUuid && "doctorRequestContext" in auth) {
        await removeDevice({ uuid: deviceUuid as string });
      }
      await auth.logout();
    }, [auth, removeDevice]),
    loggingOut,
  ] as const;
};
