import { usePrompt } from "react-router-dom";

import { staticT } from "i18n";

import { useOn } from "./useOn";

/**
 * Conditionally displays a popup when the user tries to leave the page.
 */
export const usePreventUnload = (prevent: boolean) => {
  const message = staticT("page_exit_confirmation");

  // Blocks internal navigation using react-router.
  usePrompt(message, prevent);

  // Blocks external navigation and page reloads.
  useOn(
    "beforeunload",
    (event) => {
      if (prevent) {
        // Note: most browsers will ignore the message and show their own one.
        event.returnValue = message;
        event.preventDefault();
      }
    },
    true,
  );
};
