import { FieldArray as FormikFieldArray } from "formik";

import { useFormState } from "hooks/useFormState";
import { KeysMatching } from "types";

import { ArrayHelpers, getArrayHelpers } from "./getArrayHelpers";

export const FieldArray = <
  FormValues extends Record<string, unknown>,
  ArrayValue,
>({
  children,
  name,
}: {
  children: (
    helpers: ArrayHelpers<ArrayValue>,
    values: FormValues,
    error: string | undefined,
  ) => JSX.Element | null;
  name: KeysMatching<FormValues, ArrayValue[]>;
}) => {
  const { values, submitCount, errors, setFieldValue } =
    useFormState<FormValues>();
  const array = values[name] as ArrayValue[];

  return (
    <FormikFieldArray name={name as string}>
      {(helpers) =>
        children(
          getArrayHelpers(array, helpers, (value) =>
            setFieldValue(
              name,
              value as FormValues[KeysMatching<FormValues, ArrayValue[]>],
            ),
          ),
          values,
          submitCount > 0 ? (errors[name] as string | undefined) : undefined,
        )
      }
    </FormikFieldArray>
  );
};
