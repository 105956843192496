import { useEffect, useMemo } from "react";
import gql from "graphql-tag";

import { useDoctor } from "contexts/User/UserContext";
import {
  CachedQAInbox,
  DoctorSummaryFragment,
  QAExperienceFragment,
  QAInbox,
  QAInboxExperienceFilter,
} from "generated/provider";
import { useGraphQLClient } from "graphql-client/GraphQLClientContext";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useQuery } from "graphql-client/useQuery";
import { isDef } from "utils";
import { addQAInboxItemInCache } from "utils/apollo";

import { useExtendedQASearch } from "./useExtendedQASearch";

// This is a special client query that will be used as a cache to store
// all the experiences that are discovered by events and filtered queries
gql`
  query CachedQAInbox {
    cachedQAInbox @client {
      ...QAExperience
    }
  }

  query QAInbox(
    $filter: QAInboxFilter!
    $orderField: ExperienceSearchOrder!
    $cursor: String
  ) {
    qaInbox(filter: $filter) {
      totalCount
      results(
        page: { numberOfItems: 20, cursor: $cursor }
        orderField: $orderField
      ) {
        hasMore
        nextCursor
        data {
          ...QAExperience
        }
      }
    }
  }
`;

const filterExperience = (
  filter: QAInboxExperienceFilter | null,
  { isClosed, tags, allDoctors, assignedDoctors }: QAExperienceFragment,
  user: DoctorSummaryFragment,
): boolean => {
  if (!filter) return false;
  if (isDef(filter.isClosed) && filter.isClosed !== isClosed) return false;
  if (
    isDef(filter.isAssigned) &&
    // Filter out unassigned ones...
    (filter.isAssigned !== assignedDoctors.isNotEmpty() ||
      // or assigned ones but to the user itself.
      (filter.isAssigned &&
        assignedDoctors.isNotEmpty() &&
        assignedDoctors.map((doctor) => doctor.uuid).includes(user.uuid)))
  ) {
    return false;
  }
  if (
    filter.assignedDoctorUuids &&
    !assignedDoctors.some((d) => filter.assignedDoctorUuids!.includes(d.uuid))
  ) {
    return false;
  }
  if (filter.participatingOrAssignedDoctorsUuids) {
    const matchParticipant = allDoctors.some(
      (d) => filter.participatingOrAssignedDoctorsUuids!.includes(d.uuid), // TS doesn't know that some in synchronous :'(
    );
    const matchAssigned = assignedDoctors.some((d) =>
      filter.participatingOrAssignedDoctorsUuids!.includes(d.uuid),
    );
    if (!matchParticipant && !matchAssigned) return false;
  }
  if (
    filter.tagUuids &&
    !filter.tagUuids.every((filterTagUuid) =>
      tags.some((t) => t.type.uuid === filterTagUuid && t.status === "VALID"),
    )
  ) {
    return false;
  }
  return true;
};

export type ExtendedQAInboxType =
  | "UNASSIGNED"
  | "ASSIGNED_TO_ME"
  | "ASSIGNED"
  | "CLOSED_QUESTIONS"
  | "ALL";

export const allowParticipantsSearch = (type: ExtendedQAInboxType) =>
  type === "ASSIGNED" || type === "CLOSED_QUESTIONS";

// Returns the experiences from Apollo cache instead of the searchResults directly
// Events in the common cache will trigger a rerender to all components using this hook
export const useExtendedQAInbox = (inbox: ExtendedQAInboxType) => {
  const { user, hasPermission } = useDoctor();
  const client = useGraphQLClient().graphQLClients.PROVIDER;
  const { tags, participatingDoctorsUuids } = useExtendedQASearch();

  const experienceFilter = useMemo((): QAInboxExperienceFilter => {
    const experienceFilters: {
      [key in ExtendedQAInboxType]: QAInboxExperienceFilter;
    } = {
      UNASSIGNED: { isClosed: false, isAssigned: false },
      ASSIGNED_TO_ME: { isClosed: false, assignedDoctorUuids: [user.uuid] },
      ASSIGNED: { isClosed: false, isAssigned: true },
      CLOSED_QUESTIONS: { isClosed: true },
      ALL: { isClosed: false },
    };
    return {
      ...experienceFilters[inbox],
      participatingOrAssignedDoctorsUuids:
        inbox !== "ASSIGNED" &&
        allowParticipantsSearch(inbox) &&
        participatingDoctorsUuids.isNotEmpty()
          ? participatingDoctorsUuids
          : undefined,
      assignedDoctorUuids:
        inbox === "ASSIGNED_TO_ME"
          ? [user.uuid]
          : inbox === "ASSIGNED" &&
            allowParticipantsSearch(inbox) &&
            participatingDoctorsUuids.isNotEmpty()
          ? participatingDoctorsUuids
          : undefined,
      tagUuids: tags.isEmpty() ? undefined : tags.map((t) => t.uuid),
    };
  }, [inbox, participatingDoctorsUuids, tags, user.uuid]);

  const { data, loading, error, nextPage, fetchingMore } = usePaginatedQuery(
    QAInbox,
    {
      selector: (d) => d.results,
      variables: {
        filter: { experienceFilter },
        orderField: "RECENTLY_UPDATED",
      },
      skip: !hasPermission("VIEW_EXPERIENCES"),
    },
  );

  // Empty query to create an entry in Apollo cache artificially populated
  // when events are received or QAInbox queries made.
  const { data: cache } = useQuery(CachedQAInbox);

  const results = useMemo(() => data?.results.data ?? [], [data]);

  const allLoaded = !loading && data && !data.results.hasMore;

  useEffect(() => {
    addQAInboxItemInCache(client, results.filterNotNull());
  }, [results, client]);

  const items = useMemo(
    () =>
      [...results, ...(cache ?? [])]
        .filterNotNull()
        .filter((it) => filterExperience(experienceFilter, it, user))
        .distinctBy((it) => it.uuid)
        .filter((it) => {
          if (!data?.results.nextCursor) return true; // Empty list
          // If everything is loaded, we know that there is
          // no remaining data between the last fetched experience and the oldest in the cache
          if (allLoaded) return true;
          // This filtering with cursor is needed to be sure we are not displaying items
          // from another pagination in the wrong order
          // Ex: If you have 45 closed experiences in the cache
          // but did only one query for closed experiences and the backend returned 30 (with the last one updated 7 oct)
          // you should not display closed experiences older than the 7 oct
          // because the 31st for this filter & order in the db could be different from the one in cache

          // WARNING: this is fundamentally broken. We should never try to parse
          // opaque cursors because they are supposed to be opaque, meaning that
          // the backend can change their format at any time without warning.
          const cursor = JSON.parse(atob(data.results.nextCursor));
          const [cursorDateTemp] = (cursor.thresholds as [string, string]).map(
            (val) => JSON.parse(val),
          ) as [string, string];
          const cursorDate = new Date(cursorDateTemp).toISOString();

          return cursorDate.isBeforeOrEqual(it.lastModified);
        })
        .sortDesc((e) => e.lastModified.getTime()),
    [allLoaded, cache, data, experienceFilter, results, user],
  );

  return {
    items: loading ? [] : items, // Don't show cache experience until we get at least one page from the back
    error,
    loading,
    fetchingMore,
    nextPage,
    totalCount: allLoaded
      ? items.length
      : data
      ? data.totalCount + (items.length - results.length)
      : undefined,
  };
};
