import { useSubscription as useApolloSubscription } from "@apollo/client";

import { SchemaType, Subscription } from "base-types";
import { GraphQLClient } from "types";

import { useGraphQLClient } from "./GraphQLClientContext";
import { WithOperation } from "./types";
import { getOutput } from "./utils";

export type SubscriptionOptions<Data, Variables> = {
  variables?: Variables;
  onData?: (data: Data, client: GraphQLClient) => void;
  skip?: boolean;
};

export const useSubscription = <Data, Variables, Schema extends SchemaType>(
  subscription: Subscription<Data, Variables, Schema>,
  { variables, onData, skip }: SubscriptionOptions<Data, Variables>,
) => {
  const client = useGraphQLClient().graphQLClients[subscription.schemaType];
  useApolloSubscription<WithOperation<Data>, Variables>(subscription.document, {
    client,
    variables,
    skip,
    shouldResubscribe: true,
    onSubscriptionData: ({
      subscriptionData: { data },
      client: apolloClient,
    }) => {
      if (!data) return;
      const output = getOutput(data);
      if (!output) return;
      onData?.(output, apolloClient as GraphQLClient);
    },
  });
};
