import {
  Jwt,
  parseAccountJwt,
  parseDoctorJwt,
  parseOneTimeLoginJwt,
} from "api/jwt";
import { LoginMethodKnownValue } from "generated/account";

// Used for URL authentication with the "old" refresh-token hack.
export const REFRESH_TOKEN_SEARCH_PARAM = "refresh_token";

// Used for URL authentication with one-time tokens.
export const ONE_TIME_LOGIN_JWT_SEARCH_PARAM = "one_time_login_jwt";

// Access tokens we return will always be valid for at least this amount of
// time. Upon reaching this point (if we were unable to refresh it before), we
// go into "DISCONNECTED" state.
const CONSIDER_ACCESS_TOKEN_VALID_UP_TO_N_SECONDS_BEFORE_EXPIRATION = 10;

const getSecondsBeforeExpiration = (token: Jwt) =>
  Math.max(0, token.payload.exp - Date.now() / 1000);

export const isTokenExpired = (token: Jwt) =>
  getSecondsBeforeExpiration(token) <=
  CONSIDER_ACCESS_TOKEN_VALID_UP_TO_N_SECONDS_BEFORE_EXPIRATION;

export const parseFreshDoctorJwt = (token: string) => {
  const parsedToken = parseDoctorJwt(token);
  if (isTokenExpired(parsedToken)) throw new ExpiredTokenError(token);
  return parsedToken;
};

export const parseFreshAccountJwt = (token: string) => {
  const parsedToken = parseAccountJwt(token);
  if (isTokenExpired(parsedToken)) throw new ExpiredTokenError(token);
  return parsedToken;
};

export const parseFreshOneTimeLoginJwt = (token: string) => {
  const parsedToken = parseOneTimeLoginJwt(token);
  if (isTokenExpired(parsedToken)) throw new ExpiredTokenError(token);
  return parsedToken;
};

export class ExpiredTokenError extends Error {
  constructor(token: string) {
    super(`Token is expired (${token}).`);
  }
}

const UNVERIFIED_LOGIN_METHODS = ["INITIAL_CREATION"] as const;
type UnverifiedLoginMethodKnownValues = typeof UNVERIFIED_LOGIN_METHODS[number];
export type VerifiedLoginMethodKnownValue = Exclude<
  LoginMethodKnownValue,
  UnverifiedLoginMethodKnownValues
>;
export const isVerifiedLoginMethod = (
  method: LoginMethodKnownValue,
): method is VerifiedLoginMethodKnownValue =>
  !UNVERIFIED_LOGIN_METHODS.includes(
    method as UnverifiedLoginMethodKnownValues,
  );
