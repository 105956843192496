import firebaseConfigs from "firebase.json";

import { getConfigForHost } from "./config";

/**
 * These utils are shared between service workers and code source
 * They should not make side effects or use the window
 */

export type FirebaseMessageData =
  | { uuid: string; isRemoval: "true"; "remove.uuid": string }
  | {
      uuid?: never;
      isRemoval?: never;
      callerName: string;
      userCallUuid: string;
      notificationType?: never;
    }
  | ({
      uuid: string;
      isRemoval?: never;
      title: string;
      body: string;
      canBeSensitive: "true" | "false";
    } & (
      | { notificationType: "NEW_MESSAGE"; "experience.uuid": string }
      | { notificationType: "FUTURE_VALUE" }
    ));

export type NonRemovalFirebaseMessage = FirebaseMessageData & {
  isRemoval?: never;
};
type NewMessageNotification = Omit<Notification, "data"> & {
  data: FirebaseMessageData & { notificationType: "NEW_MESSAGE" };
};

export type WorkerMessageData = { type: "NAVIGATION"; url: string };

export const getFirebaseConfig = (host: string) =>
  getConfigForHost(host)({
    dev: firebaseConfigs.dev,
    pr: firebaseConfigs.staging,
    staging: firebaseConfigs.staging,
    preprod: firebaseConfigs.prod,
    prod: firebaseConfigs.prod,
  });

export const handleFirebaseMessage = async (
  registration: ServiceWorkerRegistration,
  data: FirebaseMessageData | undefined,
) => {
  if (!data) return;

  const notify = (title: string, body?: string) =>
    registration.showNotification(title, {
      body,
      icon: "/icon.png",
      badge: "/badge.png",
      data,
    });

  if (data.isRemoval === "true") {
    registration.getNotifications().then((notifications) => {
      notifications
        .find(
          (n) => (n.data as FirebaseMessageData).uuid === data["remove.uuid"],
        )
        ?.close();
    });
  } else if ("callerName" in data) {
    await notify(`${data.callerName} vous appelle`);
  } else {
    if (data.notificationType === "NEW_MESSAGE") {
      const previousNotification = await getNewMessageNotification(
        registration,
        data["experience.uuid"],
      );
      if (!previousNotification) return notify(data.title, data.body);
      previousNotification.close();
      await notify(data.title, `${previousNotification.body}\n${data.body}`);
    } else {
      await notify(data.title, data.body);
    }
  }
};

export const getNewMessageNotification = async (
  registration: ServiceWorkerRegistration,
  experienceUuid: string,
) =>
  registration
    .getNotifications()
    .then(
      (notifications) =>
        notifications.find((n) =>
          (n.data as NonRemovalFirebaseMessage).let(
            (it) =>
              it.notificationType === "NEW_MESSAGE" &&
              it["experience.uuid"] === experienceUuid,
          ),
        ) as NewMessageNotification | undefined,
    );
