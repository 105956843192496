import { ForwardedRef, forwardRef, useCallback, useState } from "react";
import classNames from "classnames";

import { PopoverPosition } from "components/Popover/Popover";
import {
  UncontrolledPopover,
  UncontrolledPopoverProps,
} from "components/Popover/UncontrolledPopover";

import { MenuItem, MultiLevelMenuItemProps } from "./MenuItem";
import styles from "./multilevelMenu.module.css";

const reversedPosition: { [key in PopoverPosition]: PopoverPosition } = {
  top: "bottom",
  "top-left": "bottom-left",
  "top-right": "bottom-right",
  bottom: "top",
  "bottom-left": "top-left",
  "bottom-right": "top-right",
  left: "right",
  right: "left",
};

const computeRealPosition = (
  position: UncontrolledPopoverProps["position"],
  anchorNode: HTMLElement | null,
  contentNode: HTMLElement | null,
): UncontrolledPopoverProps["position"] => {
  if (!anchorNode || !contentNode) return position;

  if (
    position === "top" ||
    position === "top-left" ||
    position === "top-right"
  ) {
    const anchorTop = anchorNode.getBoundingClientRect().top;
    const { height: contentHeight } = contentNode.getBoundingClientRect();
    return anchorTop - contentHeight <= 0
      ? reversedPosition[position]
      : position;
  } else if (
    position === "bottom" ||
    position === "bottom-left" ||
    position === "bottom-right"
  ) {
    const anchorBottom = anchorNode.getBoundingClientRect().bottom;
    const { height: contentHeight } = contentNode.getBoundingClientRect();

    return anchorBottom + contentHeight >
      (window.innerHeight || document.documentElement.clientHeight)
      ? reversedPosition[position]
      : position;
  }
  return position;
};

export const MultilevelPopoverMenu = ({
  items,
  className,
  children,
  position,
}: {
  items: MultiLevelMenuItemProps[];
  className?: string;
  children: UncontrolledPopoverProps["children"];
  position?: UncontrolledPopoverProps["position"];
}) => {
  const [anchorNode, setAnchorNode] = useState<HTMLElement | null>(null);
  const [contentNode, setContentNode] = useState<HTMLElement | null>(null);
  const anchorRefChange = useCallback((node: HTMLElement | null) => {
    setAnchorNode(node);
  }, []);
  const contentRefChange = useCallback((node: HTMLElement | null) => {
    setContentNode(node);
  }, []);

  return (
    <div ref={anchorRefChange}>
      <UncontrolledPopover
        position={computeRealPosition(position, anchorNode, contentNode)}
        className={className}
        content={(close) => (
          <MultilevelMenu items={items} close={close} ref={contentRefChange} />
        )}
      >
        {children}
      </UncontrolledPopover>
    </div>
  );
};

export const MultilevelMenu = forwardRef(
  (
    {
      items,
      close,
    }: {
      items: MultiLevelMenuItemProps[];
      close: () => void;
    },
    ref?: ForwardedRef<HTMLUListElement>,
  ) => (
    <ul ref={ref}>
      {items.map((item, index) => (
        <MultilevelMenuItem
          key={index}
          item={item}
          close={close}
          className={classNames({
            "rounded-t": index === 0,
            "rounded-b": index === items.length - 1,
          })}
        />
      ))}
    </ul>
  ),
);

const MultilevelMenuItem = ({
  item,
  close,
  className,
}: {
  item: MultiLevelMenuItemProps;
  close: () => void;
  className?: string;
}) => {
  const [isRightSide, setIsRightSide] = useState(true);

  const handleSubMenuRef = useCallback((node: HTMLDivElement | null) => {
    if (!node) return;

    const parentRight = node.parentElement?.getBoundingClientRect().right;
    if (!parentRight) return;

    const { width: subMenuWidth } = node.getBoundingClientRect();

    setIsRightSide(
      parentRight + subMenuWidth <=
        (window.innerWidth || document.documentElement.clientWidth),
    );
  }, []);

  if ("subItems" in item) {
    return (
      <li
        className={classNames(styles["multilevel-menu-item"], {
          [styles["multilevel-menu-item-disabled"]]: item.disable?.if,
        })}
      >
        <MenuItem
          item={item}
          close={close}
          className={classNames("w-full", className)}
        />
        <div
          ref={handleSubMenuRef}
          className={classNames(
            styles["sub-items-container"],
            "absolute bg-white border z-popover shadow-lg rounded",
            isRightSide
              ? "translate-x-full right-4"
              : "-translate-x-full left-4",
            item.subItemsPosition && item.subItemsPosition === "bottom"
              ? "top-0"
              : "bottom-0",
          )}
        >
          <MultilevelMenu items={item.subItems} close={close} />
        </div>
      </li>
    );
  }

  return (
    <li>
      <MenuItem
        item={item}
        close={close}
        className={classNames("w-full", className)}
      />
    </li>
  );
};
