import classNames from "classnames";
import { DraftBlockType, EditorState, RichUtils } from "draft-js";

import { Icon } from "components/Icon/Icon";
import { Popover } from "components/Popover/Popover";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { IconName } from "icon-library";
import { FakeClientRect } from "types";

const InlineButton = ({
  label,
  editorState,
  setEditorState,
  inlineStyle,
  className,
}: {
  label: string;
  editorState: EditorState;
  setEditorState: (cb: (editorState: EditorState) => EditorState) => void;
  inlineStyle: string;
  className?: string;
}) => (
  <RichTextButton
    label={label}
    className={className}
    active={editorState.getCurrentInlineStyle().contains(inlineStyle)}
    onClick={() => {
      setEditorState((current) =>
        RichUtils.toggleInlineStyle(current, inlineStyle),
      );
    }}
  />
);

const BlockButton = ({
  help,
  editorState,
  setEditorState,
  blockType,
  ...rest
}: {
  help: string;
  editorState: EditorState;
  setEditorState: (cb: (editorState: EditorState) => EditorState) => void;
  blockType: DraftBlockType;
} & ({ label: string; icon?: never } | { icon: IconName; label?: never })) => {
  const selection = editorState.getSelection();
  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <TooltipWrapper className="flex-fill" label={help} position="top">
      <RichTextButton
        label={rest.label ?? <Icon name={rest.icon} />}
        active={blockType === currentBlockType}
        onClick={() => {
          setEditorState((current) =>
            RichUtils.toggleBlockType(current, blockType),
          );
        }}
      />
    </TooltipWrapper>
  );
};

const RichTextButton = ({
  label,
  active,
  className,
  onClick,
}: {
  label: string | JSX.Element;
  className?: string;
  active: boolean;
  onClick: () => void;
}) => (
  <button
    type="button"
    className={classNames(
      "h-44 flex-center flex-fill transition duration-100",
      className,
      active ? "bg-grey-100 text-black" : "text-white",
    )}
    onMouseDown={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
  >
    {label}
  </button>
);

export const RichTextButtons = ({
  editorState,
  setEditorState,
  coords,
}: {
  editorState: EditorState;
  setEditorState: (cb: (editorState: EditorState) => EditorState) => void;
  coords: FakeClientRect;
}) => (
  <Popover
    target={{ getBoundingClientRect: () => coords }}
    // When selecting text, the onChange event set selectedText
    // and react re-render before the click event is fired,
    // closing the popover (with onClickOutside)
    // So we disable the way of closing it for now
    onClose={() => undefined}
    className="m-4 bg-black text-white flex items-center"
    noArrow
    position={["bottom", "top"]}
    style={{ width: 200 }}
  >
    <BlockButton
      label="H"
      help="Titre"
      editorState={editorState}
      setEditorState={setEditorState}
      blockType="header-two"
    />
    <BlockButton
      icon="list"
      help="Liste à points"
      blockType="unordered-list-item"
      editorState={editorState}
      setEditorState={setEditorState}
    />
    <BlockButton
      icon="list"
      help="Liste ordonnée"
      blockType="ordered-list-item"
      editorState={editorState}
      setEditorState={setEditorState}
    />
    <InlineButton
      className="font-medium"
      label="B"
      inlineStyle="BOLD"
      editorState={editorState}
      setEditorState={setEditorState}
    />
    <InlineButton
      className="italic"
      label="I"
      inlineStyle="ITALIC"
      editorState={editorState}
      setEditorState={setEditorState}
    />
    <InlineButton
      className="underline"
      label="U"
      inlineStyle="UNDERLINE"
      editorState={editorState}
      setEditorState={setEditorState}
    />
  </Popover>
);
