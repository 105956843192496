import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Location } from "history";
import { useLocation, useNavigate } from "react-router-dom";

import { useStorageState } from "hooks/useStorageState";
import { FileMessageInput, SerializableRecord } from "types";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { PatientViewNavigationState } from "../utils";
import { PatientTimelineNewItemPayload } from "./Item/types";
import {
  NewTimelineItemWithPayload,
  PatientTimelineLocalItemState,
  PatientTimelineLocalStateContext,
} from "./PatientTimelineLocalStateContext";

export const PatientTimelineLocalStateProvider = ({
  children,
  attachItemToMessage,
  ...props
}: {
  children: ReactNode;
  attachItemToMessage?: (file: FileMessageInput) => void;
  patientUuid: UUID | null;
}) => {
  const patientUuid = props.patientUuid ?? ephemeralUuidV4();
  const navigate = useNavigate();
  const location: Location<PatientViewNavigationState> = useLocation();
  const [scrollToActivityDisabled, setScrollToActivityDisabled] =
    useState(false);

  // Items being created are stored in local storage.
  // Specifically, we store:
  // - The type of the item as a string.
  // - The temporary UUID of the item, which is used as a key for the draft.
  // - A specific context which is passed as props to the "new item" component.
  const [newItems, setNewItems] = useStorageState<NewTimelineItemWithPayload[]>(
    newItemsKey(patientUuid),
    [],
  );

  const addNewItem = <T extends keyof PatientTimelineNewItemPayload>(
    type: T,
    payload: PatientTimelineNewItemPayload[T],
  ) => {
    const uuid = ephemeralUuidV4();
    const item = {
      temporaryUuid: uuid,
      type,
      payload,
    } as NewTimelineItemWithPayload;
    setNewItems((items) => [...items, item]);
  };
  const newItemsUuids = newItems.map(({ temporaryUuid }) => temporaryUuid);

  // Expanded items are stored in the URL hash.
  // Last element in the list is the last expanded item.
  const hash = location.hash.slice(1);
  const expandedItems: UUID[] = useMemo(
    () => (hash.isNotBlank() ? hash.trim().split(",") : []),
    [hash],
  );
  const setExpandedItems = useCallback(
    (uuids: UUID[], shouldPreventFocus = false) =>
      navigate(
        { ...location, hash: uuids.isNotEmpty() ? `#${uuids.join(",")}` : "" },
        { replace: true, state: { preventFocus: shouldPreventFocus } },
      ),
    [navigate, location],
  );
  const expandItem = (uuid: UUID) => {
    if (newItemsUuids.includes(uuid)) return;
    setExpandedItems([...expandedItems.without(uuid), uuid]);
  };
  const closeItem = (uuid: UUID) => {
    if (newItemsUuids.includes(uuid)) return;
    setExpandedItems(expandedItems.without(uuid), true);
  };

  // Items being edited are stored in local storage.
  // They can be in two states:
  // - Either they have just started being edited, and they have no draft;
  // - Or they have an ongoing draft, which is stored in a separate storage key.
  //   That key is read from and written to non-reactively via `storage` instead
  //   of reactively via `useStorageState`, which lets us avoid re-rendering the
  //   timeline everytime a draft is updated.
  const [editedItemsUuids, setEditedItemsUuids] = useStorageState<UUID[]>(
    editedItemsKey(patientUuid),
    [],
  );
  const updateItemDraft = (uuid: UUID, draft: SerializableRecord) => {
    storage.setItem(itemDraftKey(patientUuid, uuid), JSON.stringify(draft));
  };
  const startEditingItem = (uuid: UUID) => {
    if (!newItemsUuids.includes(uuid)) {
      setEditedItemsUuids((items) => [...items.without(uuid), uuid]);
      expandItem(uuid);
    }
    storage.setItem(itemDraftKey(patientUuid, uuid), JSON.stringify(null));
  };
  const stopEditingItem = (uuid: UUID) => {
    if (newItemsUuids.includes(uuid)) {
      setNewItems((items) => items.filter((it) => it.temporaryUuid !== uuid));
    } else {
      setEditedItemsUuids((items) => items.without(uuid));
    }
    storage.removeItem(itemDraftKey(patientUuid, uuid));
  };

  // Items in edit mode should also be expanded. While they will usually be in
  // `expandedItems` as `startEditingItem` first calls `expandItem`, this might
  // not be the case when navigating back to the patient timeline while a draft
  // from a previous session exists in local storage. To avoid inconsistencies
  // in that case, an effect will correct the URL hash to what it should be.
  const allExpandedItems = useMemo(
    () => [...editedItemsUuids.without(...expandedItems), ...expandedItems],
    [editedItemsUuids, expandedItems],
  );
  useEffect(() => {
    if (allExpandedItems.equals(expandedItems)) return;
    setExpandedItems(allExpandedItems);
  }, [expandedItems, allExpandedItems, setExpandedItems]);

  // The focused item is the last item that was expanded or navigated to, i.e.
  // the last item in the URL hash, with the exception that we don't want to
  // refocus the second to last expanded item when the last one is closed.
  const focusedItem =
    location.state?.preventFocus || scrollToActivityDisabled
      ? undefined
      : allExpandedItems.at(-1);

  const itemState = (uuid: UUID): PatientTimelineLocalItemState =>
    allExpandedItems.includes(uuid) || newItemsUuids.includes(uuid)
      ? {
          mode: "EXPANDED",
          isFocused: uuid === focusedItem,
          ...(editedItemsUuids.includes(uuid) || newItemsUuids.includes(uuid)
            ? {
                isEditing: true,
                draft: storage
                  .getItem(itemDraftKey(patientUuid, uuid))
                  ?.let((it) => JSON.parse(it)),
              }
            : { isEditing: false, draft: undefined }),
        }
      : { mode: "CLOSED" };

  return (
    <PatientTimelineLocalStateContext.Provider
      value={{
        patientUuid,
        itemState,
        closeItem,
        expandItem,
        startEditingItem,
        updateItemDraft,
        stopEditingItem,
        attachItemToMessage,
        newItems,
        addNewItem,
        setScrollToActivityDisabled,
      }}
    >
      {children}
    </PatientTimelineLocalStateContext.Provider>
  );
};

const newItemsKey = (patientUuid: UUID) => `patient:${patientUuid}:new`;
const editedItemsKey = (patientUuid: UUID) => `patient:${patientUuid}:edited`;
const itemDraftKey = (patientUuid: UUID, itemUuid: UUID) =>
  `patient:${patientUuid}:draft:${itemUuid}`;
