import { useEffect, useRef, useState } from "react";
import { AudioRenderer, useRoom } from "@livekit/react-core";
import { LocalParticipant, Participant, Room } from "livekit-client";

import Profile from "./icons/profile.svg";
import { LivekitParticipant } from "./LivekitParticipant";
import { LivekitStatusMessage } from "./LivekitStatusMessage";
import { LivekitToolbar } from "./LivekitToolbar";
import {
  absolute,
  flex,
  flexCenter,
  fullSize,
  fullWidth,
  relative,
  shadow,
  STATUS_INSET_LARGE,
  STATUS_INSET_MINIMIZED,
  TOOLBAR_INSET_LARGE,
  TOOLBAR_INSET_MINIMIZED,
} from "./styles";

/**
 * Displays the participants of a LiveKit room and a floating toolbar.
 *
 * `maxWidth` and `maxHeight` can be used to constrain the maximum size of the
 * component using a CSS expression without percentages.
 */
export const LivekitRoomViewer = ({
  url,
  token,
  onClose,
  onMissingPermissions,
  maxWidth,
  maxHeight,
  minimized,
  emptyRoomLabel,
  toggleTranscript,
}: {
  url: string;
  token: string;
  onClose?: () => void;
  onMissingPermissions?: (type: "MICROPHONE" | "CAMERA" | "SCREEN") => void;
  maxWidth?: string;
  maxHeight?: string;
  minimized?: boolean;
  emptyRoomLabel?: string;
  toggleTranscript?: () => void;
}) => {
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const { connect, audioTracks, participants, room } = useRoom();

  useEffect(() => {
    let hasAborted = false;
    let currentRoom: Room | undefined = undefined;

    connect(url, token).then((connectedRoom) => {
      if (!connectedRoom) return;
      if (hasAborted) return;
      currentRoom = connectedRoom;
      void currentRoom.localParticipant.enableCameraAndMicrophone();
    });

    return () => {
      hasAborted = true;
      currentRoom?.disconnect();
    };
  }, [connect, url, token]);

  const isLocal = (p: Participant) => p instanceof LocalParticipant;
  const localParticipant = participants.find((p) => isLocal(p));
  const otherParticipants = participants.filter((p) => !isLocal(p));

  // This is for the case where there's no current active speaker, we'll then keep the last previously active one.
  const previousSpeaker = useRef<Participant>();

  const previousSpeakerStillParticipant = otherParticipants
    .filter((p) => p.sid === previousSpeaker.current?.sid)
    .isNotEmpty();

  const currentSpeaker = otherParticipants.isEmpty()
    ? undefined
    : otherParticipants.find((p) => p.isSpeaking) ??
      (previousSpeakerStillParticipant ? previousSpeaker.current : undefined) ??
      otherParticipants.at(0);

  useEffect(() => {
    previousSpeaker.current = currentSpeaker;
  }, [currentSpeaker]);

  return (
    <div
      style={{
        ...relative,
        ...fullSize,
        ...flex,
        backgroundColor: "black",
      }}
      onMouseEnter={() => setIsToolbarVisible(true)}
      onMouseLeave={() => setIsToolbarVisible(false)}
      onTouchEnd={() => setIsToolbarVisible((previous) => !previous)}
    >
      {audioTracks.map((track) => (
        <AudioRenderer key={track.sid} track={track} isLocal={false} />
      ))}

      {!currentSpeaker && (
        <>
          <LivekitParticipant
            participant={localParticipant}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          />

          {emptyRoomLabel && (
            <div
              style={{
                ...absolute,
                ...flexCenter,
                ...fullWidth,
                zIndex: 10,
                top: minimized ? STATUS_INSET_MINIMIZED : STATUS_INSET_LARGE,
              }}
            >
              <LivekitStatusMessage label={emptyRoomLabel} />
            </div>
          )}
        </>
      )}

      {currentSpeaker && (
        <>
          <div
            style={{
              ...absolute,
              ...flex,
              ...fullWidth,
              zIndex: 12,
              marginLeft: 12,
              top: minimized ? STATUS_INSET_MINIMIZED : STATUS_INSET_LARGE,
            }}
          >
            <div
              style={{
                ...flexCenter,
                color: "#ffffff",
                backgroundColor: "rgba(0,0,0,0.3)",
                fontWeight: 500,
                fontSize: 14,
                padding: "4px 12px",
                borderRadius: 100,
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              <Profile style={{ height: 20, width: 20, marginRight: 4 }} />
              {participants.length}
            </div>
          </div>

          <LivekitParticipant
            participant={currentSpeaker}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          />

          {localParticipant && (
            <div
              style={{
                ...absolute,
                ...shadow,
                zIndex: 10,
                bottom: minimized
                  ? TOOLBAR_INSET_MINIMIZED
                  : TOOLBAR_INSET_LARGE,
                right: minimized
                  ? TOOLBAR_INSET_MINIMIZED
                  : TOOLBAR_INSET_LARGE,
                borderRadius: 8,
                overflow: "hidden",
              }}
            >
              <LivekitParticipant
                participant={localParticipant}
                maxWidth={`min(300px, 0.3 * ${maxWidth ?? "100vw"})`}
                maxHeight={`min(300px, 0.3 * ${maxHeight ?? "100vh"})`}
              />
            </div>
          )}
        </>
      )}

      {room && (
        <div
          style={{
            ...absolute,
            ...fullWidth,
            ...flexCenter,
            zIndex: 50,
            bottom: minimized ? TOOLBAR_INSET_MINIMIZED : TOOLBAR_INSET_LARGE,
            opacity: isToolbarVisible ? 100 : 0,
            visibility: isToolbarVisible ? "visible" : "hidden",
            transition: "opacity .2s, visibility .2s",
          }}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <LivekitToolbar
            room={room}
            onClose={onClose}
            onMissingPermissions={onMissingPermissions}
            minimized={minimized}
            toggleTranscript={toggleTranscript}
          />
        </div>
      )}
    </div>
  );
};
