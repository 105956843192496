import { TFunction } from "i18n";
import { IconName } from "icon-library";
import { colors } from "styles";
import { EmptyRecord, ICD10Code } from "types";
import {
  PatientTimelineKnownItemTypes,
  PatientTimelineValidItemFragmentOfType,
} from "types/patient-timeline";

/**
 * Additional payload passed as props to components for new timeline items.
 * Note: that payload should be `Serializable` to be stored in local storage.
 */
export type PatientTimelineNewItemPayload = {
  AperoBill: { appointmentUuid: UUID; icd10Codes: ICD10Code[] };
  PatientDocument: {
    appointmentUuid?: UUID;
    shouldUploadDocument?: boolean;
    documentTemplate?: { title: string; text: string };
  };
  NablaPrescription: {
    appointmentUuid?: UUID;
    prescriptionTemplate?: { title: string; text: string };
  };
  PatientNote: EmptyRecord;
  Task: { medicalOrderUuid?: UUID };
  MedicalOrder: { appointmentUuid?: UUID; initialIcd10Codes?: ICD10Code[] };
};

/**
 * The item types which support creating new timeline items.
 */
export type PatientTimelineCreatableItemTypes = Extract<
  keyof PatientTimelineNewItemPayload,
  PatientTimelineKnownItemTypes
>;

/**
 * How to display timeline items depending on their item type.
 */
const PatientTimelineKnownItemsAppearance: {
  [T in PatientTimelineKnownItemTypes]: (
    t: TFunction,
    item: PatientTimelineValidItemFragmentOfType<T> | null,
  ) => {
    entityName: string;
    iconName: IconName;
    iconColor: string;
    iconBackgroundColor: string;
    title: string;
    tag?: string;
    timePrefix?: string;
    statusLabel?: string;
  };
} = {
  AperoBill: (t, item) => ({
    entityName: t("patient_view.default_title.bill"),
    iconName: "bill",
    iconColor: "#5A77AD",
    iconBackgroundColor: "#F4F8FF",
    title:
      item?.optionalTitle?.trimOrNull() ?? t("patient_view.default_title.bill"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),

  Appointment: (t, item) => ({
    entityName: t("patient_view.appointment"),
    iconName:
      item?.location.__typename === "PhysicalAppointmentLocation"
        ? "medicalOffice"
        : "videoOn",
    iconColor: "#FF8552",
    iconBackgroundColor: "#FFF5F1",
    title:
      item?.title.trimOrNull() ?? t("patient_view.default_title.appointment"),
    tag: item?.description ?? undefined,
    timePrefix:
      item?.state.__typename === "AppointmentStateScheduled"
        ? t("patient_view.time_prefix.scheduled")
        : undefined,
    statusLabel:
      item?.state.__typename === "AppointmentStateFinalized"
        ? t("patient_view.status_label.completed")
        : item?.state.__typename === "AppointmentStateCancelled"
        ? t("patient_view.status_label.cancelled")
        : item?.state.__typename === "AppointmentStateNoShow"
        ? t("patient_view.status_label.no_show")
        : undefined,
  }),

  Experience: (t, item) => ({
    entityName: t("patient_view.default_title.experience"),
    iconName: "doubleChatBubble",
    iconColor: "#0090E0",
    iconBackgroundColor: "#F7FCFF",
    title:
      item?.optionalTitle?.trimOrNull() ??
      t("patient_view.default_title.experience"),
    timePrefix: item
      ? item.lastItem !== null
        ? t("patient_view.time_prefix.last_message_sent")
        : t("patient_view.time_prefix.created")
      : undefined,
  }),

  PatientDocument: (t, item) => ({
    entityName: t("patient_view.default_title.patient_document"),
    iconName: "fileText",
    iconColor: "#079A2A",
    iconBackgroundColor: "#F5FCF6",
    title:
      item?.title.trimOrNull() ??
      t("patient_view.default_title.patient_document"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),

  PatientNote: (t, item) => ({
    entityName: t("patient_view.default_title.patient_note"),
    iconName: "chatVerticalEmpty",
    iconColor: "#DDBA03",
    iconBackgroundColor: "#FFFDF5",
    title:
      item?.title.trimOrNull() ?? t("patient_view.default_title.patient_note"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),

  QuestionnaireResponse: (t, item) => ({
    entityName: t("patient_view.default_title.questionnaire_response"),
    iconName: "program",
    iconColor: "#8900B9",
    iconBackgroundColor: "#FBF1FE",
    title:
      item?.optionalTitle ??
      t("patient_view.default_title.questionnaire_response"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),

  Task: (t, item) => ({
    entityName: t("patient_view.task"),
    iconName: "checklist",
    iconColor: "#D83CA7",
    iconBackgroundColor: "#FEF8FC",
    title: item?.title.trimOrNull() ?? t("patient_view.default_title.task"),
    timePrefix: item
      ? item.isCompleted
        ? t("patient_view.time_prefix.completed")
        : t("patient_view.time_prefix.created")
      : undefined,
  }),

  MedicalOrder: (t, item) => ({
    entityName: t("patient_view.medical_order"),
    iconName: "laboratory",
    iconColor: "#8900B9",
    iconBackgroundColor: "#FBF1FE",
    title: item?.title ?? t("patient_view.default_title.medical_order"),
    timePrefix: item
      ? item.completedAt !== null
        ? t("patient_view.time_prefix.completed")
        : t("patient_view.time_prefix.created")
      : undefined,
  }),

  NablaPrescription: (t, item) => ({
    entityName: t("patient_view.prescription"),
    iconName: "pill",
    iconColor: colors["menthe-300"],
    iconBackgroundColor: colors["menthe-100"],
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    title: item?.title || t("patient_view.prescription"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),

  BravadoPrescription: (t, item) => ({
    entityName: t("patient_view.prescription"),
    iconName: "pill",
    iconColor: colors["menthe-300"],
    iconBackgroundColor: colors["menthe-100"],
    title: t("patient_view.prescription"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),

  PhotonPrescription: (t, item) => ({
    entityName: t("patient_view.prescription"),
    iconName: "pill",
    iconColor: colors["menthe-300"],
    iconBackgroundColor: colors["menthe-100"],
    title: t("patient_view.prescription"),
    timePrefix: item ? t("patient_view.time_prefix.created") : undefined,
  }),
};

/**
 * Helpers to compute the appearance properties for existing or new items.
 */
export const appearanceForExistingItem = <
  T extends PatientTimelineKnownItemTypes,
>(
  t: TFunction,
  item: PatientTimelineValidItemFragmentOfType<T>,
) => {
  const typename: T = item.__typename;
  return PatientTimelineKnownItemsAppearance[typename](t, item);
};

export const appearanceForNewItem = (
  t: TFunction,
  type: PatientTimelineKnownItemTypes,
) => PatientTimelineKnownItemsAppearance[type](t, null);
