import { MixedQAInboxSearchResultTypeKnownValue } from "generated/provider";

import { Filter } from "./types";

export const getVariables = ({
  search,
  filter,
  canViewExperiences,
  canViewTemplates,
  contextExperienceUuid,
}: {
  search: string;
  filter: Filter;
  canViewExperiences: boolean;
  canViewTemplates: boolean;
  contextExperienceUuid: UUID | null;
}) => ({
  freeText: search,
  resultTypes: filterVariableMapping[filter].filter((it) => {
    if (it === "EXPERIENCE_WITH_MESSAGE") return canViewExperiences;
    return canViewTemplates;
  }),
  contextExperienceUuid,
});

const filterVariableMapping: {
  [key in Filter]: MixedQAInboxSearchResultTypeKnownValue[];
} = {
  ALL: ["EXPERIENCE_WITH_MESSAGE", "TEMPLATE"],
  EXPERIENCE: ["EXPERIENCE_WITH_MESSAGE"],
  TEMPLATE: ["TEMPLATE"],
};
