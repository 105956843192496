import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import { DateTime, Duration } from "luxon";
import { CSVLink } from "react-csv";

import { Maybe } from "base-types";
import { Background } from "components/Background/Backgound";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Query } from "components/Query/Query";
import { Table } from "components/Table/Table";
import { useCopilotApiUser, useUser } from "contexts/User/UserContext";
import { CopilotApiUsersActivityStats } from "generated/copilot-api-user";
import { useTranslation } from "i18n";
import { run } from "utils";

gql`
  # schema = COPILOT_API_USER
  query CopilotApiUsersActivityStats($start: DateTime!, $end: DateTime!) {
    copilotApiUsersActivityStats(start: $start, end: $end) {
      copilotApiUsersWithActivity {
        uuid
        externalId
        activationDaysCount
      }
    }
  }
`;

export const Billing = () => {
  const t = useTranslation();
  const { subOrganizationTimezone } = useUser();
  const now = DateTime.now().setZone(subOrganizationTimezone);
  const [selectedMonth, setSelectedMonth] = useState(now);

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col space-y-16">
        <div className="flex items-center justify-between">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("developers.billing.title")}
          </h1>
          <MonthPicker
            now={now}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
        </div>
        <span className="text-grey-300 text-16">
          {t("developers.billing.subtitle", {
            timezone: subOrganizationTimezone,
          })}
        </span>
        <div className="flex-col space-y-16">
          <UsersWithActivity selectedMonth={selectedMonth} />
        </div>
      </div>
    </Background>
  );
};

const MonthPicker = ({
  now,
  selectedMonth,
  setSelectedMonth,
}: {
  now: DateTime;
  selectedMonth: DateTime;
  setSelectedMonth: (date: DateTime) => void;
}) => {
  const { user } = useCopilotApiUser();
  const locale = run(() => {
    switch (user.locale) {
      case "ENGLISH":
        return "en";
      case "FRENCH":
        return "fr";
      case "PORTUGUESE":
        return "pt";
    }
  });

  const nextMonth = selectedMonth.plus(Duration.fromObject({ months: 1 }));
  const nextYear = selectedMonth.plus(Duration.fromObject({ years: 1 }));

  return (
    <div className="flex space-x-16 rounded bg-white shadow-sm-outlined border p-16">
      <ClickableIcon
        className="py-4 hover:rounded-full hover:bg-grey-100"
        name="doubleChevron"
        size={18}
        rotate={180}
        onClick={() =>
          setSelectedMonth(
            selectedMonth.minus(Duration.fromObject({ years: 1 })),
          )
        }
      />
      <ClickableIcon
        id="prevMonth"
        className="py-4 hover:rounded-full hover:bg-grey-100 "
        name="chevron"
        size={18}
        rotate={180}
        onClick={() =>
          setSelectedMonth(
            selectedMonth.minus(Duration.fromObject({ months: 1 })),
          )
        }
      />
      <span className="flex flex-center font-semibold w-[150px]">
        {selectedMonth.toFormat("LLLL yyyy", { locale }).upperFirst()}
      </span>
      <ClickableIcon
        id="nextMonth"
        className={classNames("py-4", {
          "hover:rounded-full hover:bg-grey-100": nextMonth <= now,
        })}
        name="chevron"
        size={18}
        disabled={nextMonth > now}
        onClick={() => setSelectedMonth(nextMonth)}
      />
      <ClickableIcon
        className={classNames("py-4", {
          "hover:rounded-full hover:bg-grey-100": nextMonth <= now,
        })}
        name="doubleChevron"
        size={18}
        onClick={() => setSelectedMonth(nextYear)}
        disabled={nextYear > now}
      />
    </div>
  );
};

const UsersWithActivity = ({ selectedMonth }: { selectedMonth: DateTime }) => {
  const t = useTranslation();
  const variables = {
    start: selectedMonth.startOf("month").toJSDate().toISOString(),
    end: selectedMonth.endOf("month").toJSDate().toISOString(),
  };

  return (
    <Query query={CopilotApiUsersActivityStats} variables={variables} noSpinner>
      {({ data }) => (
        <>
          <Table
            elements={data?.copilotApiUsersWithActivity ?? []}
            fieldHeaders={[
              t("developers.billing.header.nabla_id"),
              t("developers.billing.header.external_id"),
              t("developers.billing.header.activation_count"),
            ]}
            fields={(user) => [
              user.uuid,
              user.externalId ?? (
                <span className="text-grey-300">{"<empty>"}</span>
              ),
              user.activationDaysCount,
            ]}
          />
          <UsersFooter
            users={data?.copilotApiUsersWithActivity ?? []}
            selectedMonth={selectedMonth.toJSDate().toISOString()}
          />
        </>
      )}
    </Query>
  );
};

const UsersFooter = ({
  users,
  selectedMonth,
}: {
  selectedMonth: ISOString;
  users: {
    uuid: string;
    externalId: Maybe<string>;
    activationDaysCount: number;
  }[];
}) => {
  const t = useTranslation();
  const totalCount = users.length;
  const { subOrganizationUuid } = useUser();

  return (
    <div className="flex justify-between">
      <span className="font-medium">
        {t("developers.billing.footer.total_users")}&nbsp;{totalCount}
      </span>
      <CSVLink
        data={users.map((u) => [u.uuid, u.externalId, u.activationDaysCount])}
        headers={[
          t("developers.billing.header.nabla_id"),
          t("developers.billing.header.external_id"),
          t("developers.billing.header.activation_count"),
        ]}
        filename={`users-${
          subOrganizationUuid?.slice(0, 8) ?? ""
        }-${selectedMonth.getDate().getFullYear()}-${(
          selectedMonth.getDate().getMonth() + 1
        )
          .toLocaleString()
          .padStart(2, "0")}.csv`}
        className="text-primary"
      >
        {t("developers.billing.footer.export")}
      </CSVLink>
    </div>
  );
};
