import { ReactNode, useRef, useState } from "react";
import classNames from "classnames";

import { Icon } from "components/Icon/Icon";

import { LabelWrapper } from "../Label/LabelWrapper";
import { InputProps } from "./Input";
import styles from "./inputWithPills.module.css";

export const InputWithPills = ({
  wrapperClassName,
  label,
  error,
  hint,
  name,

  inputRef,
  leftInnerElement,
  innerPillsElement,
  rightInnerElement,
  rightInnerElementClassName,
  thinGray,

  value,
  maxLength,
  disabled,
  className,
  ...rest
}: InputProps & { innerPillsElement?: ReactNode }) => {
  const [focused, setFocused] = useState(false);
  const internalInputRef = useRef<HTMLInputElement>(null);
  const setInputFocus = () => {
    internalInputRef.current?.focus();
  };

  return (
    <LabelWrapper
      name={name}
      wrapperClassName={wrapperClassName}
      label={label}
      error={error}
      hint={
        hint ??
        (maxLength && typeof value === "string"
          ? `${value.length}/${maxLength}`
          : undefined)
      }
    >
      <div
        ref={inputRef} // used by OptionsPopover
        onBlur={() => {
          focused && setFocused(false);
        }}
        onFocus={setInputFocus}
        onClick={setInputFocus}
        className={classNames(styles.container, className, {
          [styles.error]: error,
          [styles.withLeftElement]: !!leftInnerElement,
          [styles.thinGray]: thinGray,
          [styles.focused]: focused,
        })}
      >
        {leftInnerElement &&
          (typeof leftInnerElement === "string" ? (
            <Icon
              name={leftInnerElement}
              className="absolute z-2 self-center ml-10 left-0"
            />
          ) : (
            <div className="absolute z-2 self-center ml-8 left-0">
              {leftInnerElement}
            </div>
          ))}
        {innerPillsElement}
        <div style={{ position: "relative", display: "inline-block" }}>
          <input
            id={name}
            ref={internalInputRef}
            className={classNames(styles.input, {
              [styles.focused]: focused,
            })}
            style={{
              width:
                value && typeof value === "string" && value.length > 0
                  ? `calc(${value.length}ch + 20px)` // padding + a small buffer
                  : "auto",
            }}
            name={name}
            value={value}
            maxLength={maxLength}
            disabled={disabled}
            onFocus={() => {
              if (!focused) setFocused(true);
            }}
            {...rest}
          />
        </div>
        {rightInnerElement ? (
          <div
            className={classNames(
              styles.rightInnerElement,
              rightInnerElementClassName,
            )}
          >
            {rightInnerElement}
          </div>
        ) : null}
      </div>
    </LabelWrapper>
  );
};
