import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { usePrevious } from "./index";

/**
 * Looks for a search parameter from the URL. When found, the parameter is
 * stripped from the URL and its value is passed exactly once to `callback`.
 */
export const useOnHiddenSearchParam = (
  name: string,
  callback: (value: string) => void,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(name);
  const previousName = usePrevious(name);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (!value) return; // Not in the search params: nothing to do!

    // To avoid calling `callback` several times in development or when the
    // identity of the callback or the searchParams changes.
    if (previousName === name && previousValue === value) return;

    callback(value);
    const newUrlParams = new URLSearchParams(searchParams);
    newUrlParams.delete(name);
    setSearchParams(newUrlParams);
  }, [
    name,
    previousName,
    value,
    previousValue,
    callback,
    searchParams,
    setSearchParams,
  ]);
};
