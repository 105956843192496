import { useState } from "react";
import { mapValues } from "lodash";

import { Maybe } from "base-types";
import { MESSAGES_KEY } from "consts";
import {
  DoctorRoleKnownValues,
  DoctorSummaryFragment,
  PatientSummaryFragment,
} from "generated/provider";
import { getKnownValueFilter } from "utils/enum";

import { ExperienceStore, Store } from "./MessagesContext";

export const useMessagesStore = () =>
  useState<Store>(() => {
    const storageValue = JSON.parse(storage.getItem(MESSAGES_KEY) ?? "{}") as {
      [key: string]: ExperienceStore<LegacyMessageFragment>;
    };
    return mapValues(
      storageValue,
      (experience): ExperienceStore => ({
        allItemsLoaded: experience.allItemsLoaded,
        sendingItems: experience.sendingItems.map((value) => ({
          extractedEHRSuggestions: [],
          ...value,
          batchIdentifier: null,
          sender: mapLegacyDoctor(value.sender),
          actionRequest: null, // Not used anymore, so we just drop it
          replyMessage: value.replyMessage
            ? {
                ...value.replyMessage,
                sender: mapLegacyUser(value.replyMessage.sender),
              }
            : null,
          handledAt: null,
          assignedDoctor: null,
        })),
      }),
    );
  });

const mapLegacyUser = (value: Maybe<LegacyUserSummaryFragment>) => {
  if (value?.__typename === "Doctor") return mapLegacyDoctor(value);
  if (value?.__typename === "Patient") return mapLegacyPatient(value);
  return null;
};

const mapLegacyDoctor = (
  value: LegacyDoctorSummaryFragment,
): DoctorSummaryFragment => ({
  locale: "FRENCH",
  ...value,
  email: null,
  roles: value.roles.mapNotNull(getKnownValueFilter(DoctorRoleKnownValues)),
});

const mapLegacyPatient = (
  value: LegacyPatientSummaryFragment,
): PatientSummaryFragment => ({
  locale: "FRENCH",
  firstName: "",
  lastName: "",
  ...value,
  username: value.username!,
});

type LegacyMessageFragment = {
  __typename: "Message";
  uuid: UUID;
  eventTime: ISOString;
  senderType: "DOCTOR" | "PATIENT" | "SYSTEM";
  clientIdentifier: Maybe<UUID>;
  isForwarded: boolean;
  sender: LegacyDoctorSummaryFragment;
  content:
    | { __typename: "TextMessageContent"; text: string }
    | {
        __typename: "ImageMessageContent";
        fileUpload: LegacyFileUploadFragment;
      }
    | {
        __typename: "VideoMessageContent";
        fileUpload: LegacyFileUploadFragment;
      }
    | {
        __typename: "AudioMessageContent";
        fileUpload: LegacyFileUploadFragment;
      }
    | {
        __typename: "FileMessageContent";
        fileUpload: LegacyFileUploadFragment;
        fileTitle: string | null;
      }
    | { __typename: "DeletedMessageContent"; _: "EMPTY" };
  replyMessage: Maybe<{
    __typename: "Message";
    uuid: UUID;
    sender: Maybe<LegacyUserSummaryFragment>;
    content: LegacyMessageContentSummaryFragment;
  }>;
  actionRequest: Maybe<{
    __typename: "ActionRequest";
    uuid: UUID;
    response: string;
    type: string;
  }>;
  reactions: [];
};

type LegacyFileUploadFragment = {
  __typename: "FileUpload";
  uuid: string;
  urlV2: { __typename: "EphemeralUrl"; url: string; expiresAt: ISOString };
  fileName: string;
  mimeType: string;
  transcript: {
    __typename: "Transcript";
    uuid: UUID;
    firstTranscribedAt: null;
  };
  createdAt: ISOString;
  patientDocuments: [];
  metadata:
    | {
        __typename: "DocumentMetadata";
        thumbnail: Maybe<{
          __typename: "FileUpload";
          uuid: string;
          urlV2: {
            __typename: "EphemeralUrl";
            url: string;
            expiresAt: ISOString;
          };
        }>;
      }
    | { __typename: "ImageMetadata" }
    | { __typename: "VideoMetadata" }
    | { __typename: "AudioMetadata"; durationMs: 3000 }
    | { __typename: "OtherMetadata" }
    | { __typename: "FutureValue" };
};

type LegacyUserSummaryFragment =
  | LegacyDoctorSummaryFragment
  | LegacyPatientSummaryFragment;

type LegacyMessageContentSummaryFragment =
  | { __typename: "TextMessageContent"; text: string }
  | {
      __typename: "ImageMessageContent";
      fileUpload: { __typename: "FileUpload"; uuid: string };
    }
  | {
      __typename: "VideoMessageContent";
      fileUpload: { __typename: "FileUpload"; uuid: string };
    }
  | {
      __typename: "AudioMessageContent";
      fileUpload: { __typename: "FileUpload"; uuid: string };
    }
  | {
      __typename: "FileMessageContent";
      fileUpload: { __typename: "FileUpload"; uuid: string };
    }
  | { __typename: "DeletedMessageContent"; _: "EMPTY" };

type LegacyDoctorSummaryFragment = {
  __typename: "Doctor";
  uuid: UUID;
  registrationStatus:
    | {
        __typename: "RegisteredDoctor";
        email: string;
        isAccountInRecoveryMode: boolean;
      }
    | { __typename: "InvitedDoctor"; email: string }
    | { __typename: "NotInvitedDoctor"; annuaireSantePractitioner: string };
  firstName: string;
  lastName: string;
  avatarUrl: Maybe<string>;
  lastConnectedAt: Maybe<ISOString>;
  title: Maybe<string>;
  prefix: Maybe<string>;
  phone: Maybe<string>;
  deactivated: boolean;
  roles: string[];
};

type LegacyPatientSummaryFragment = {
  __typename: "Patient";
  uuid: UUID;
  sex: Maybe<string>;
  avatarUrl: Maybe<string>;
  isArchived: boolean;
  username: Maybe<string>;
  birthDate: Maybe<ISOString>;
  summary: Maybe<string>;
  accountType:
    | {
        __typename: "BetaPatientAccountType";
        firstName: string;
        lastName: string;
      }
    | { __typename: "BasicPatientAccountType"; numberOfQuestionsLeft: Int };
};
