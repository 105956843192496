import { MessageReactionTypeKnownValue } from "generated/provider";

export const MessageReactionTypeToEmoji: {
  [key in MessageReactionTypeKnownValue]: string;
} = {
  ANGRY: "😡",
  THUMBS_UP: "👍",
  THUMBS_DOWN: "👎",
  SAD: "😥",
  HEART: "❤️",
  SURPRISED: "😮",
  LAUGHING: "😂",
};
