import { ReactNode, useState } from "react";

import {
  ControlledConfirmationModal,
  ControlledConfirmationModalProps,
} from "./ControlledConfirmationModal";

export type ConfirmationModalProps = Omit<
  ControlledConfirmationModalProps,
  "children" | "onHide" | "show"
> & {
  children: (open: () => void) => JSX.Element | null;
  body?: ReactNode;
};

export const ConfirmationModal = ({
  children,
  body,
  ...modalProps
}: ConfirmationModalProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      {children(() => setOpened(true))}
      <ControlledConfirmationModal
        show={opened}
        onHide={() => setOpened(false)}
        children={body}
        {...modalProps}
      />
    </>
  );
};
