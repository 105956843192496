import { useState } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { EHRSuggestionFragment } from "generated/provider";
import { ehrSuggestionToDisplayProps } from "utils/medical-facts";

export const EHRSuggestion = ({
  suggestion,
  disabled,
  onDelete,
  onValid,
  showICD10,
}: {
  suggestion: EHRSuggestionFragment;
  disabled?: boolean;
  onValid?: () => void;
  onDelete?: () => void;
  showICD10?: boolean;
}) => {
  const { title, details, type, icon, iconBgColor } =
    ehrSuggestionToDisplayProps(suggestion, !!showICD10);
  const formattedTitle =
    title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  return (
    <div className="p-12 rounded bg-white shadow-light flex border">
      <Icon
        className="rounded-full p-4 h-20 w-20 text-white"
        name={icon}
        size={8}
        style={{ background: iconBgColor }}
      />
      <div className="flex-fill flex-col ml-8 mr-8">
        <div className="text-primary-dark font-regular text-14">
          {formattedTitle}
        </div>
        {details && (
          <div className="font-regular text-11 flex">
            <div
              className={classNames("flex-fill", {
                truncate: !detailsExpanded,
              })}
            >
              {details}
            </div>
            {!detailsExpanded && (
              <button
                className="underline ml-4"
                onClick={() => setDetailsExpanded(true)}
              >
                plus
              </button>
            )}
          </div>
        )}

        <div className="font-regular text-11">{type}</div>
      </div>
      {onDelete && (
        <ClickableIcon
          disabled={disabled}
          className="rounded-full bg-grey-100 h-20 w-20 border ml-4 "
          onClick={onDelete}
          name="trash"
          size={12}
        />
      )}
      {onValid && (
        <ClickableIcon
          disabled={disabled}
          className="rounded-full bg-primary h-20 w-20 border text-white ml-4"
          onClick={onValid}
          name="check"
          size={12}
        />
      )}
    </div>
  );
};
