import gql from "graphql-tag";
import { capitalize } from "lodash";

import {
  ContraceptionFragment,
  ContraceptionMethodKnownValue,
  EHRSuggestionFragment,
} from "generated/provider";
import { staticT as t } from "i18n";
import { IconName } from "icon-library";
import { colors } from "styles";
import { GranularDateTime } from "types";

import { isKnownUnionValue } from "./apollo";
import { displayGranularDateRange, displayGranularDateTime } from "./date";
import { displayEnumValue } from "./display";

gql`
  fragment Contraception on Contraception {
    uuid
    brand
    details
    startedAt {
      reference
      relationWithReference
    }
    endedAt {
      reference
      relationWithReference
    }
    method
    extractionStatus
  }
`;

type ApproximatedDate = NonNullable<ContraceptionFragment["startedAt"]>;

const getContraceptionMethodMap = (): {
  [key in ContraceptionMethodKnownValue]: string;
} => ({
  PILL: t("utils.medical_facts.pill"),
  IUD: t("utils.medical_facts.iud"),
  MALE_CONDOM: t("utils.medical_facts.condom"),
  FEMALE_CONDOM: t("utils.medical_facts.female_condom"),
  CONTRACEPTIVE_IMPLANT: t("utils.medical_facts.contraceptive_implant"),
  CONTRACEPTIVE_PATCH: t("utils.medical_facts.contraceptive_patch"),
  PROGESTATIVE_INJECTION: t("utils.medical_facts.progestative_injection"),
  CERVICAL_CAP: t("utils.medical_facts.cervical_cap"),
  DIAPHRAGM: t("utils.medical_facts.diaphragm"),
  FEMALE_STERILIZATION: t("utils.medical_facts.sterilization"),
  MALE_STERILIZATION: t("utils.medical_facts.sterilization_male"),
  VAGINAL_RING: t("utils.medical_facts.vaginal_ring"),
  NATURAL: t("utils.medical_facts.naturel"),

  OTHER: t("utils.medical_facts.other"),
});

const asGranularDate = (
  approximatedDate: ApproximatedDate,
): GranularDateTime | undefined =>
  approximatedDate.relationWithReference === "SAME_YEAR"
    ? { dateTime: approximatedDate.reference, granularity: "YEAR" }
    : approximatedDate.relationWithReference === "SAME_MONTH"
    ? { dateTime: approximatedDate.reference, granularity: "MONTH" }
    : approximatedDate.relationWithReference === "SAME_DAY"
    ? { dateTime: approximatedDate.reference, granularity: "DAY" }
    : undefined;

const displayApproximatedDate = (date: ApproximatedDate): string => {
  const asGranular = asGranularDate(date);
  if (asGranular) return displayGranularDateTime(asGranular);

  const secondsSinceReference = (Date.now() - date.reference.getTime()) / 1000;
  if (
    secondsSinceReference < 30 * 24 * 3600 &&
    date.relationWithReference === "SOME_DAYS_BEFORE"
  ) {
    return t("utils.medical_facts.some_days_ago");
  }

  if (
    secondsSinceReference < 365 * 24 * 3600 &&
    date.relationWithReference === "SOME_MONTHS_BEFORE"
  ) {
    return t("utils.medical_facts.some_months_ago");
  }

  return date.relationWithReference === "BEFORE"
    ? t("utils.medical_facts.in_the_past")
    : t("utils.medical_facts.some_years_ago");
};

const displayApproximatedDateInterval = (
  since?: ApproximatedDate | null,
  until?: ApproximatedDate | null,
) => {
  const sinceAsGranular = since?.let((it) => asGranularDate(it));
  const untilAsGranular = until?.let((it) => asGranularDate(it));
  if (
    (since === undefined || sinceAsGranular !== undefined) &&
    (until === undefined || untilAsGranular !== undefined)
  ) {
    return displayGranularDateRange(sinceAsGranular, untilAsGranular);
  }
  if (until) {
    return t("common.until_date", {
      date: displayApproximatedDate(until).toLowerCase(),
    });
  }
  if (since) {
    return t("common.since_date", {
      date: displayApproximatedDate(since).toLowerCase(),
    });
  }
  return "";
};

export const displayContraception = (contraception: ContraceptionFragment) => {
  const method = displayEnumValue(
    contraception.method,
    getContraceptionMethodMap(),
  );

  const details = [
    contraception.brand?.let((it) => capitalize(it)),
    contraception.details,
  ].filterNotNull();
  const detailDisplay = details.isNotEmpty() ? details.join(", ") : "";

  const dateInterval =
    displayApproximatedDateInterval(
      contraception.startedAt,
      contraception.endedAt,
    )?.toLowerCase() ?? "";

  return `${method} ${detailDisplay} ${dateInterval}`;
};

// TODO: stop using the "type" field as a card title name in some ehrSuggestion objects

export const ehrSuggestionToDisplayProps: (
  ehrSuggestion: EHRSuggestionFragment,
  withICD10: boolean,
) => {
  title: string;
  details?: string;
  type: string;
  icon: IconName;
  iconBgColor: string;
} = (ehrSuggestion, withICD10) => {
  if (!isKnownUnionValue("EHRSuggestion", ehrSuggestion)) {
    return {
      title: t("utils.medical_facts.unsupported_suggestion"),
      type: t("utils.medical_facts.unknown"),
      icon: "bug",
      iconBgColor: colors.danger,
    };
  }

  switch (ehrSuggestion.__typename) {
    case "Contraception":
      return {
        title: displayContraception(ehrSuggestion),
        type: t("utils.medical_facts.birth_control"),
        icon: "people",
        iconBgColor: colors.pink,
      };
    case "MedicationStatement":
      return {
        title: ehrSuggestion.name,
        type: t("utils.medical_facts.medication"),
        details: ehrSuggestion.activeIngredients
          .sortAsc((it) => it.length)
          .join(", "),
        icon: "pill",
        iconBgColor: colors.pink,
      };
    case "Condition":
      return {
        title:
          withICD10 && ehrSuggestion.icd10Normalization
            ? `${ehrSuggestion.type} (${ehrSuggestion.icd10Normalization.code})`
            : ehrSuggestion.type,
        type: t("utils.medical_facts.medical_history"),
        details: withICD10
          ? ehrSuggestion.icd10Normalization?.description
          : undefined,
        icon: "history",
        iconBgColor: colors.orange,
      };
    case "PatientAllergy":
      return {
        title: ehrSuggestion.type,
        type: t("utils.medical_facts.allergy"),
        icon: "bug",
        iconBgColor: colors.danger,
      };
    case "VaccinationStatement":
      return {
        title: ehrSuggestion.type,
        type: t("utils.medical_facts.vaccine"),
        details: ehrSuggestion.description,
        icon: "vaccine",
        iconBgColor: colors.primary,
      };
    case "Symptom":
      return {
        title: ehrSuggestion.name,
        type: t("utils.medical_facts.symptom"),
        icon: "fever",
        iconBgColor: colors.yellow,
      };
    case "Procedure":
      return {
        title: ehrSuggestion.name,
        type: t("utils.medical_facts.procedure"),
        details: ehrSuggestion.comment as string | undefined,
        icon: "stethoscope",
        iconBgColor: colors.brown,
      };
  }
};
