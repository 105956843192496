import { useState } from "react";

import { AvatarList } from "components/Avatar/AvatarList";
import { Button } from "components/Button/Button";
import { VoteForPollModal } from "components/composer-parts/VoteForPollModal";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { useDoctor } from "contexts/User/UserContext";
import { PollFragment } from "generated/provider";
import { useTranslation } from "i18n";

export const PollMessage = ({ poll }: { poll: PollFragment }) => {
  const t = useTranslation();
  const numberOfVoter = new Set(
    poll.items.flatMap((i) => i.voters.map((v) => v.uuid)),
  ).size;

  const [showVoteModal, setShowVoteModal] = useState(false);
  const { user } = useDoctor();
  const iAlreadyVoted = poll.items.some((item) =>
    item.voters.some((v) => v.uuid === user.uuid),
  );
  const changeMyVoteLabel = t(
    "conversation_content.timeline.message_container.poll_message.change_my_vote",
  );
  const voteLabel = t(
    "conversation_content.timeline.message_container.poll_message.vote",
  );

  return (
    <div
      className="flex-col items-center justify-center ml-auto mt-8 rounded border p-12 bg-white mx-36"
      style={{ width: "min(80%, 500px)" }}
    >
      <div className="flex-col text-primary-dark text-18 center mb-10">
        {poll.title}
      </div>
      {poll.items.map((item) => (
        <div key={item.uuid} className="w-full mt-14 flex-col space-y-6">
          <div className="flex items-center">
            <div className="flex-fill text-18 truncate">{item.title}</div>
            <AvatarList
              size={30}
              className="ml-6"
              users={item.voters}
              withBrowserTitle
            />
          </div>
          <ProgressBar
            percentage={
              numberOfVoter > 0 ? (100 * item.voters.length) / numberOfVoter : 0
            }
          />
        </div>
      ))}
      <Button
        className="mt-20"
        label={iAlreadyVoted ? changeMyVoteLabel : voteLabel}
        onClick={() => setShowVoteModal(true)}
      />
      {showVoteModal && (
        <VoteForPollModal
          poll={poll}
          onHide={() => setShowVoteModal(false)}
          submitLabel={iAlreadyVoted ? changeMyVoteLabel : voteLabel}
        />
      )}
    </div>
  );
};
