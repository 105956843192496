import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import {
  FormAvatar,
  FormAvatarValue,
} from "components/Form/Dropzone/FormAvatar";
import { FormImage, FormImageValue } from "components/Form/Dropzone/FormImage";
import { Form } from "components/Form/Form/Form";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { useDoctor } from "contexts/User/UserContext";
import { UpdateBranding } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  mutation UpdateBranding($input: OrganizationSettingsInput!) {
    updateOrganizationSettings(input: $input) {
      doctor {
        uuid
        subOrganization {
          ...SubOrganizationSummary
        }
      }
    }
  }
`;

type FormValues = {
  avatarPicture: FormAvatarValue;
  favicon: FormImageValue | undefined;
  documentSignature: Maybe<string> | undefined;
};

export const Branding = () => {
  const t = useTranslation();
  const { user } = useDoctor();
  const [updateBranding] = useMutation(UpdateBranding, {
    onSuccess: () =>
      notifier.success(t("settings.branding.branding.branding_updated")),
  });

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("settings.branding.branding.branding")}
        </h1>
      </div>
      <div className="flex items-center">
        <Form<FormValues>
          initialValues={{
            avatarPicture: { avatar: user.subOrganization.avatar?.urlV2.url },
            favicon: user.subOrganization.organization.whiteLabelEnabled
              ? { image: user.subOrganization.whiteLabelFaviconUrl }
              : undefined,
            documentSignature: user.subOrganization.organization
              .whiteLabelEnabled
              ? user.subOrganization.whiteLabelDocumentSignature
              : undefined,
          }}
          onSubmit={async (values) =>
            updateBranding({
              input: {
                avatar: values.avatarPicture.upload
                  ? await values.avatarPicture.upload()
                  : undefined,
                whiteLabelFavicon: values.favicon?.upload
                  ? await values.favicon.upload()
                  : undefined,
                whiteLabelDocumentSignature: values.documentSignature,
              },
            })
          }
          className="flex-col w-full items-start space-y-24 p-32 bg-white border rounded shadow-sm-outlined"
        >
          <div>
            <div className="font-bold text-18 text-primary-dark">
              {t("branding.logo")}
            </div>
            <div className="mb-16">{t("branding.logo_intro")}</div>
            <FormAvatar
              name="avatarPicture"
              forUser={user.subOrganization}
              isImage
            />
          </div>

          {user.subOrganization.organization.whiteLabelEnabled && (
            <>
              <div>
                <div className="font-bold text-18 text-primary-dark">
                  {t("branding.favicon")}
                </div>
                <div className="mb-16">{t("branding.favicon_intro")}</div>
                <FormImage name="favicon" purpose="FAVICON" />
              </div>

              <div>
                <div className="font-bold text-18 text-primary-dark">
                  {t("branding.document_signature")}
                </div>
                <div className="mb-16">
                  {t("branding.document_signature_intro")}
                </div>
                <FormTextArea name="documentSignature" />
              </div>
            </>
          )}

          <Submit
            label={t("settings.branding.branding.save")}
            requiresDirty
            className="mt-32"
          />
        </Form>
      </div>
    </Background>
  );
};
