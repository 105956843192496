import { useState } from "react";
import gql from "graphql-tag";
import * as Yup from "yup";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { FormDatePicker } from "components/Form/DatePicker/FormDatePicker";
import { FieldArray } from "components/Form/FieldArray/FieldArray";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormSelect } from "components/Form/Select/FormSelect";
import { PatientRowOption } from "components/Form/Select/PatientRowOption";
import { Select } from "components/Form/Select/Select";
import { Query } from "components/Query/Query";
import {
  BackOfficePatientFragment,
  GetAperoPayers,
  GetPatientWithApero,
  PatientSearch,
  UpdatePatientCoverages,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayPatient } from "utils/display";

type CoverageFormValues = {
  tradingPartnerId: string;
  subscriberId: string;
  lastVerifiedAt?: ISOString | null;
};

type FormValues = {
  coverages: CoverageFormValues[];
};

gql`
  fragment AperoPayer on AperoPayer {
    uuid: tradingPartnerId
    name
    tradingPartnerId
  }

  fragment PatientWithApero on Patient {
    uuid
    aperoId
    aperoPatient {
      id
      coverages {
        id
        lastVerifiedAt
        tradingPartnerId
        subscriberId
        subscriberFirstName
        subscriberLastName
        subscriberDateOfBirth
        subscriberRelationship
        subscriberPlan
      }
      guarantors {
        firstName
        lastName
        email
        phone
        address
        city
        zipcode
      }
    }
  }

  query GetAperoPayers($search: String) {
    aperoPayers(search: $search) {
      results {
        ...AperoPayer
      }
    }
  }

  query GetPatientWithApero($patientUuid: UUID!) {
    patient(patientUuid: $patientUuid) {
      patient {
        ...PatientWithApero
      }
    }
  }

  mutation UpdatePatientCoverages($input: UpdatePatientCoveragesInput!) {
    updatePatientCoverages(input: $input) {
      patient {
        ...PatientWithApero
      }
    }
  }
`;

export const AperoPatientVerification = () => {
  const t = useTranslation();
  const [updateAndVerify, loading] = useMutation(UpdatePatientCoverages);
  const [patient, setPatient] = useState<BackOfficePatientFragment | undefined>(
    undefined,
  );
  return (
    <div className="flex-col flex-fill h-full bg-blue-overlay">
      <div className="flex-col p-18 space-y-32 items-center w-full">
        <div className="text-primary-dark title">
          {t("labs.apero.apero_patient_verification.patient_verification")}
        </div>
        <Query query={PatientSearch} noSpinner>
          {({ data, loading: l }) => (
            <Select
              name="patient"
              isClearable
              options={data?.patients.data ?? []}
              loading={l}
              placeholder={t("labs.apero.apero_patient_verification.select")}
              CustomOption={PatientRowOption}
              getOptionLabel={displayPatient}
              label={t("labs.apero.apero_patient_verification.patient")}
              value={patient}
              onChange={(p) => setPatient(p)}
            />
          )}
        </Query>
        {patient && (
          <Query
            query={GetPatientWithApero}
            variables={{ patientUuid: patient.uuid }}
          >
            {({ patient: patientData }) => (
              <Form<FormValues>
                className="bg-white p-16 rounded-item space-y-16 w-full"
                initialValues={{
                  coverages:
                    patientData.aperoPatient?.coverages.map((c) => ({
                      tradingPartnerId: c.tradingPartnerId ?? "",
                      subscriberId: c.subscriberId,
                      lastVerifiedAt: c.lastVerifiedAt,
                    })) ?? [],
                }}
                onSubmit={({ coverages }) =>
                  updateAndVerify({
                    input: {
                      patientUuid: patient.uuid,
                      coverages: coverages.map((coverage) => ({
                        tradingPartnerId: coverage.tradingPartnerId,
                        subscriberId: coverage.subscriberId,
                      })),
                    },
                  })
                }
                validationSchema={{
                  coverages: Yup.array().of(
                    Yup.object().shape({
                      tradingPartnerId: Yup.string().required(),
                      subscriberId: Yup.string().required(),
                    }),
                  ),
                }}
              >
                <FieldArray<FormValues, CoverageFormValues> name="coverages">
                  {(arrayHelpers, values) => (
                    <div>
                      <div className="mb-8">
                        {values.coverages.map((coverage, index) => (
                          <div
                            key={index}
                            className="flex space-x-8 items-center"
                          >
                            <div>
                              <Query query={GetAperoPayers} noSpinner>
                                {({ data, loading: l }) => (
                                  <FormSelect
                                    name={`coverages.${index}.tradingPartnerId`}
                                    wrapperClassName="flex-fill justify-between h-full"
                                    options={
                                      data?.results.map(
                                        (o) => o.tradingPartnerId,
                                      ) ?? []
                                    }
                                    loading={l}
                                    placeholder={t(
                                      "labs.apero.apero_patient_verification.select",
                                    )}
                                    getOptionLabel={(id) =>
                                      data?.results.find(
                                        (o) => o.tradingPartnerId === id,
                                      )?.name ?? ""
                                    }
                                    label={t(
                                      "labs.apero.apero_patient_verification.payer",
                                    )}
                                  />
                                )}
                              </Query>
                            </div>
                            <div>
                              <FormInput
                                label={t(
                                  "apero_patient_verification.subscriber_id",
                                )}
                                name={`coverages.${index}.subscriberId`}
                              />
                            </div>
                            <div>
                              {coverage.lastVerifiedAt && (
                                <FormDatePicker
                                  label={t(
                                    "apero_patient_verification.last_verification_at",
                                  )}
                                  name={`coverages.${index}.lastVerifiedAt`}
                                  disabled
                                />
                              )}
                            </div>
                            <div className="flex-1" />
                            <Button
                              label={t(
                                "labs.apero.apero_patient_verification.delete",
                              )}
                              danger
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </div>
                        ))}
                      </div>
                      <Button
                        label={t(
                          "labs.apero.apero_patient_verification.add_coverage",
                        )}
                        className="bg-grey-100 text-primary-dark text-14"
                        onClick={() =>
                          arrayHelpers.push({
                            tradingPartnerId: "",
                            subscriberId: "",
                          })
                        }
                      />
                    </div>
                  )}
                </FieldArray>
                <Submit
                  loading={loading}
                  className="px-56"
                  label={t("labs.apero.apero_patient_verification.verify")}
                />
              </Form>
            )}
          </Query>
        )}
      </div>
    </div>
  );
};
