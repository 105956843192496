import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Query } from "components/Query/Query";
import { Secret } from "components/Secret/Secret";
import { Spinner } from "components/Spinner/Spinner";
import { Table } from "components/Table/Table";
import {
  DeleteWebhook,
  GetWebhooks,
  GetWebhookSettings,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { AppFlavor } from "../platform";
import { PayloadTestModal } from "./PayloadTestModal";
import { WebhookComposer } from "./WebhookComposer";

gql`
  # schema = ORGANIZATION_USER

  query GetWebhookSettings {
    myOrganization {
      alertEmails
    }
  }

  fragment Webhook on Webhook {
    uuid
    url
    signatureSecret
  }

  query GetWebhooks {
    webhooks {
      ...Webhook
    }
  }

  mutation DeleteWebhook($uuid: UUID!) {
    deleteWebhook(uuid: $uuid)
  }
`;

type ComposerState =
  | { mode: "create" }
  | { mode: "edit"; webhookUuid: UUID }
  | { mode: "test"; webhookUuid: UUID };

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

type PayloadTestModalState = { webhookUuid: UUID };

export const Webhooks = ({ appFlavor }: { appFlavor: AppFlavor }) => {
  const t = useTranslation();
  const { data, loading } = useQuery(GetWebhooks);
  const [deleteWebhook] = useMutation(DeleteWebhook);
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>(undefined);
  const [composerState, setComposerState] = useState<ComposerState>();
  const [payloadTestModalState, setPayloadTestModalState] =
    useState<PayloadTestModalState>();

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      {composerState && (
        <WebhookComposer
          onClose={() => setComposerState(undefined)}
          webhook={
            composerState.mode === "edit"
              ? data?.find(
                  (webhook) => webhook.uuid === composerState.webhookUuid,
                )
              : undefined
          }
        />
      )}

      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("developers.webhooks.webhooks.delete_webhook")}
          suffix={t("developers.webhooks.webhooks.to_delete_this_webhook")}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}

      {payloadTestModalState && (
        <PayloadTestModal
          appFlavor={appFlavor}
          onHide={() => setPayloadTestModalState(undefined)}
          {...payloadTestModalState}
        />
      )}

      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("developers.webhooks.webhooks.webhooks")}
        </h1>
        <div>
          {t(
            "webhooks.set_up_your_webhook_endpoint_to_receive_live_events_from_nabla_use_the_api_reference_guide_to",
          )}
          <a
            className="link"
            href="https://docs.nabla.com/reference/webhooks"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {t("webhooks.nabla_events_and_their_event_objects")}{" "}
          </a>
          {t("webhooks.your_webhook_endpoint_needs_to_handle")}
        </div>
      </div>
      <Query query={GetWebhookSettings}>
        {(webhookSettings) => (
          <div>
            {t("webhooks.if_any_of_your_webhooks_fail_you_will_be_notified_at")}
            {webhookSettings.alertEmails}
          </div>
        )}
      </Query>
      <div className="flex items-center">
        <div className="flex-col flex-1">
          {data && (
            <span>
              {data.length} {t("developers.webhooks.webhooks.webhooks")}
            </span>
          )}
        </div>
        <Button
          label={t("developers.webhooks.webhooks.add_webhook")}
          onClick={() => setComposerState({ mode: "create" })}
        />
      </div>
      {loading ? (
        <Spinner />
      ) : data ? (
        <Table
          elements={data}
          fieldHeaders={[
            t("developers.webhooks.webhooks.url"),
            t("developers.webhooks.webhooks.secret"),
          ]}
          fields={(webhook) => [
            webhook.url,
            <Secret key={webhook.uuid} secret={webhook.signatureSecret} />,
          ]}
          menuItems={(webhook) => [
            {
              icon: "edit",
              text: t("developers.webhooks.webhooks.edit"),
              onClick: (close: () => void) => {
                close();
                setComposerState({ mode: "edit", webhookUuid: webhook.uuid });
              },
            },
            {
              icon: "bug",
              text: t("developers.webhooks.webhooks.send_a_test_payload"),
              onClick: (close: () => void) => {
                close();
                setPayloadTestModalState({ webhookUuid: webhook.uuid });
              },
            },
            {
              icon: "trash",
              text: t("developers.webhooks.webhooks.permanently_delete"),
              className: "text-danger",
              onClick: (close: () => void) => {
                close();
                setConfirmationModalState({
                  onConfirm: () =>
                    deleteWebhook(
                      { uuid: webhook.uuid },
                      {
                        onSuccess: (_, client) => {
                          notifier.success(
                            t("developers.webhooks.webhooks.webhook_deleted"),
                          );
                          setConfirmationModalState(undefined);
                          client.remove("Webhook", webhook.uuid);
                        },
                      },
                    ),
                });
              },
            },
          ]}
        />
      ) : null}
    </Background>
  );
};
