import * as Yup from "yup";

import {
  FormAvatar,
  FormAvatarValue,
} from "components/Form/Dropzone/FormAvatar";
import { FormInput } from "components/Form/Input/FormInput";
import { FormPhoneInput } from "components/Form/Input/FormPhoneInput";
import { FormMultiSelect } from "components/Form/Select/FormMultiSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormModal } from "components/Modal/FormModal";
import { useDoctor } from "contexts/User/UserContext";
import {
  DoctorManagementDoctorFragment,
  DoctorRole,
  Gender,
  GenderKnownValues,
  UploadInput,
} from "generated/provider";
import { useTranslation } from "i18n";
import { displayDoctorRole } from "utils/display";

type FormValues = {
  roles: DoctorRole[];
  gender: Gender | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | undefined;
  title: string;
  prefix: string;
  avatarPicture: FormAvatarValue;
};

export type ProviderComposerPayload = {
  roles: DoctorRole[];
  gender: Gender | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  title: string | null;
  prefix: string | null;
  avatar: UploadInput | null;
};

export const ProviderComposer = ({
  doctor,
  onSubmit,
  onClose,
}: {
  doctor?: DoctorManagementDoctorFragment;
  onSubmit: (payload: ProviderComposerPayload) => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const { hasAccessToGatekeeperFeature } = useDoctor();

  return (
    <FormModal<FormValues>
      title={
        doctor
          ? t("providers.provider_composer.edit_account")
          : t("providers.provider_composer.add_an_account")
      }
      disabled={doctor?.deactivated}
      submitLabel={
        doctor
          ? t("providers.provider_composer.save")
          : t("providers.provider_composer.create")
      }
      onHide={onClose}
      className="flex-col w-full"
      initialValues={{
        roles: doctor?.roles ?? ["NABLA_MEDICAL_STAFF"],
        gender: doctor?.gender ?? undefined,
        firstName: doctor?.firstName ?? "",
        lastName: doctor?.lastName ?? "",
        email: doctor?.email ?? "",
        phone: doctor?.phone ? `${doctor.phone}` : "",
        title: doctor?.title ?? "",
        prefix: doctor?.prefix ?? "",
        avatarPicture: { avatar: doctor?.avatarUrl },
      }}
      validationSchema={{
        firstName: "required",
        lastName: "required",
        email: Yup.string()
          .email(t("providers.provider_composer.enter_email"))
          .required(t("providers.provider_composer.email_required")),
        prefix: Yup.string().max(10, "Prefix is too long"),
        roles: "required",
      }}
      onSubmit={async ({
        phone,
        title,
        prefix,
        gender,
        avatarPicture,
        ...values
      }) => {
        const avatar = (await avatarPicture.upload?.()) ?? null;
        await onSubmit({
          ...values,
          avatar,
          gender: gender ?? null,
          phone: phone?.trimOrNull() ?? null,
          title: title.trimOrNull(),
          prefix: prefix.trimOrNull(),
        });
        onClose();
      }}
    >
      {doctor && (
        <FormAvatar
          name="avatarPicture"
          forUser={doctor}
          className="self-start"
        />
      )}
      <div className="flex items-start space-x-6">
        <FormSelect
          name="gender"
          options={GenderKnownValues}
          getOptionLabel={(o) => {
            switch (o) {
              case "MALE":
                return t("providers.provider_composer.m");
              case "FEMALE":
                return t("providers.provider_composer.f");
            }
          }}
          label={t("providers.provider_composer.gender")}
          wrapperClassName="flex-shrink-0 flex-grow basis-auto justify-between w-1/4 h-full"
        />
        <FormInput
          name="firstName"
          label={t("providers.provider_composer.first_name")}
          placeholder={t("providers.provider_composer.jane")}
          wrapperClassName="flex-fill justify-between h-full"
        />
        <FormInput
          name="lastName"
          label={t("providers.provider_composer.last_name")}
          placeholder={t("providers.provider_composer.smith")}
          wrapperClassName="flex-fill justify-between h-full"
        />
      </div>
      <div className="flex items-start space-x-6">
        <FormPhoneInput
          name="phone"
          label={t("providers.provider_composer.phone")}
          placeholder={t("providers.provider_composer.phone_placeholder")}
          wrapperClassName="flex-shrink-0 flex-grow basis-auto justify-between w-1/3 h-full"
        />
        <FormInput
          name="email"
          disabled={!!doctor}
          label={t("providers.provider_composer.email")}
          placeholder={t("providers.provider_composer.janesmithcom")}
          wrapperClassName="flex-fill justify-between h-full"
        />
      </div>
      <div className="flex items-start space-x-6">
        <FormInput
          name="prefix"
          label={t("providers.provider_composer.prefix")}
          placeholder={t("providers.provider_composer.dr")}
          wrapperClassName="flex-shrink-0 flex-grow basis-auto justify-between w-1/3 h-full"
        />
        <FormInput
          name="title"
          label={t("providers.provider_composer.title")}
          placeholder={t("providers.provider_composer.gp")}
          wrapperClassName="flex-fill justify-between h-full"
        />
      </div>
      <FormMultiSelect
        wrapperClassName="flex-fill"
        label={t("providers.provider_composer.role")}
        name="roles"
        options={
          hasAccessToGatekeeperFeature("RECORDED_CONVERSATIONS")
            ? [
                "NABLA_ADMINISTRATOR",
                "NABLA_MEDICAL_STAFF",
                "REVIEWER",
                "LABELLER",
              ]
            : ["NABLA_ADMINISTRATOR", "NABLA_MEDICAL_STAFF", "REVIEWER"]
        }
        getOptionLabel={displayDoctorRole}
      />
    </FormModal>
  );
};
