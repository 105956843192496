import { useEffect, useState } from "react";

import { displayTimeElapsed, now } from "utils/date";

export const useDisplayTimeElapsed = ({
  from,
  paused,
}: {
  paused?: boolean;
  from?: ISOString | null;
} = {}) => {
  const [elapsedTime, setElapsedTime] = useState(
    displayTimeElapsed(from?.secondsFromNow() ?? 0),
  );

  useEffect(() => {
    if (paused) return;
    const dateTime = from ?? now();

    let timeoutId: NodeJS.Timeout;
    const update = () => {
      setElapsedTime(displayTimeElapsed(dateTime.secondsFromNow()));
      timeoutId = setTimeout(() => {
        update();
      }, 1000 - (dateTime.millisecondsFromNow() % 1000));
    };

    update();
    return () => clearInterval(timeoutId);
  }, [paused, from]);

  return elapsedTime;
};
