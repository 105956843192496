import { ReactNode, useRef, useState } from "react";
import gql from "graphql-tag";

import { useMediaWindows } from "atoms/useMediaWindows";
import {
  MessageContentInput,
  useMessages,
} from "contexts/Messages/MessagesContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  ExperienceContentFragment,
  ExperienceType,
  MessageContentSummaryFragment,
  MessageFragment,
} from "generated/provider";
import { useOnUnmount } from "hooks";
import { knownUnionValueFilter } from "utils/apollo";

import {
  DoctorMessageActionsType,
  ExperienceContext,
} from "./ExperienceContext";
import { useMessagesContainerUtils } from "./useMessagesContainerUtils";

gql`
  fragment ExperienceContent on Experience {
    ...ExperienceSummary
    ...SeenState
    ...ScheduleMessages
    itemsV3(page: { from: $cursor, numberOfItems: 20 }) {
      data {
        ...TimelineItem
      }
      hasMore
      nextCursor
    }
  }
`;

export const ExperienceProvider = ({
  uuid,
  draftKey,
  type,
  DoctorMessageActions,
  processSendingMessageContent = (content) => content,
  data,
  nextPage,
  fetchingMore,
  processDisplayedMessageText,
  children,
  readonly,
}: (
  | {
      uuid: UUID;
      draftKey?: undefined;
    }
  | {
      uuid?: undefined;
      draftKey: string;
    }
) & {
  type: ExperienceType;
  DoctorMessageActions?: DoctorMessageActionsType;
  processSendingMessageContent?: (
    content: MessageContentInput,
  ) => MessageContentInput;
  data: { experience: ExperienceContentFragment } | undefined;
  nextPage: (() => void) | undefined;
  fetchingMore: boolean;
  processDisplayedMessageText?: (text: string) => string;
  children: ReactNode;
  readonly?: boolean;
}) => {
  const { store, sendMessage, retrySendMessage, deleteFailedMessage } =
    useMessages();
  const [replyMessageUuid, setReplyMessageUuid] = useState<UUID>();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const messagesContainerUtils = useMessagesContainerUtils();
  useOnUnmount(useMediaWindows().closeMedia);
  const { user } = useDoctor();

  const items = data?.experience.itemsV3.data
    .filter(knownUnionValueFilter("TimelineItem"))
    .reverse()
    .sortAsc((i) => i.eventTime.getTime())
    .map((i) =>
      processDisplayedMessageText && i.__typename === "Message"
        ? processDisplayedMessage(i, processDisplayedMessageText)
        : i,
    );

  const replyMessage = replyMessageUuid?.let((it) =>
    items?.find(
      (i): i is MessageFragment => i.__typename === "Message" && i.uuid === it,
    ),
  );

  return (
    <ExperienceContext.Provider
      value={{
        ...messagesContainerUtils,
        ...(uuid === undefined
          ? {
              // "Lazy" experiences.
              uuid: undefined,
              draftKey,
              isLoaded: false,
              experience: undefined,
              items: undefined,
              scheduledMessages: undefined,
              hasMoreItems: undefined,
              isInExperience: false,
              loadingMoreItems: false,
              loadMoreItemsIfPossible: () => {
                // Do nothing.
              },
            }
          : {
              ...(data
                ? {
                    // Loaded experiences.
                    uuid,
                    isLoaded: true,
                    experience: data.experience,
                    items: items!,
                    scheduledMessages: data.experience.scheduledMessages.map(
                      (i) =>
                        processDisplayedMessageText
                          ? {
                              ...i,
                              textContent: processDisplayedMessageText(
                                i.textContent,
                              ),
                            }
                          : i,
                    ),
                    hasMoreItems: data.experience.itemsV3.hasMore,
                    isInExperience: data.experience.allDoctors
                      .map((doc) => doc.uuid)
                      .includes(user.uuid),
                  }
                : {
                    // Loading experiences.
                    uuid,
                    isLoaded: false,
                    experience: undefined,
                    items: undefined,
                    scheduledMessages: undefined,
                    hasMoreItems: undefined,
                    isInExperience: false,
                  }),
              draftKey: uuid,
              sendingItems:
                store[uuid]?.sendingItems.map((m) =>
                  processDisplayedMessageText
                    ? processDisplayedMessage(m, processDisplayedMessageText)
                    : m,
                ) ?? [],
              sendMessage: (content, batchId) =>
                sendMessage(
                  uuid,
                  {
                    content: processSendingMessageContent(content),
                    replyMessage,
                  },
                  batchId,
                ),
              retrySendMessage: (localUUID) =>
                retrySendMessage(uuid, localUUID),
              deleteFailedMessage: (localUUID) =>
                deleteFailedMessage(uuid, localUUID),
              loadingMoreItems: fetchingMore,
              loadMoreItemsIfPossible: () => nextPage?.(),
            }),
        type,
        DoctorMessageActions,
        replyMessage,
        setReplyMessage: (message) => setReplyMessageUuid(message?.uuid),
        textAreaRef,
        readonly: readonly ?? false,
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
};

const processDisplayedMessage = <Message extends MessageFragment>(
  message: Message,
  processText: (text: string) => string,
): Message => ({
  ...message,
  content: processDisplayedMessageContent(message.content, processText),
  replyMessage:
    message.replyMessage?.let((it) => ({
      ...it,
      content: processDisplayedMessageContent(it.content, processText),
    })) ?? null,
});

const processDisplayedMessageContent = <
  Content extends MessageContentSummaryFragment,
>(
  content: Content,
  processText: (text: string) => string,
): Content =>
  content.__typename === "TextMessageContent"
    ? { ...content, text: processText(content.text) }
    : content;
