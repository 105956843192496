import { RefObject } from "react";
import classNames from "classnames";

import { useExperienceDraft } from "contexts/Experience/ExperienceContext";

import styles from "./autocompleteOverlay.module.css";

export const AutocompleteOverlay = ({
  autoComplete,
  className,
  overlayRef,
}: {
  autoComplete: string | undefined;
  className: string | undefined;
  overlayRef: RefObject<HTMLDivElement>;
}) => {
  const { messageText } = useExperienceDraft();

  return (
    <div className={classNames(className, styles.overlay)} ref={overlayRef}>
      <span className="text-transparent">{messageText}</span>
      <span className="text-body">{autoComplete}</span>
    </div>
  );
};
