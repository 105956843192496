import { useCallback, useState } from "react";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { Maybe } from "base-types";
import { NextPageButton } from "components/Button/NextPageButton";
import { Select } from "components/Form/Select/Select";
import { Icon } from "components/Icon/Icon";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Separator } from "components/Separator/Separator";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { TranscriptItems, TranscriptItemsFilter } from "generated/provider";
import { staticT, useTranslation } from "i18n";
import { routes } from "routes";

import { TranscriptItemsTagsList } from "./TranscriptAnnotation";
import {
  displayTranscriptItemTag,
  getTranscriptItemTags,
  tagToIconName,
  TranscriptItemTag,
} from "./utils";

gql`
  query TranscriptItems($filter: TranscriptItemsFilter!, $cursor: String) {
    transcriptItems(
      input: { page: { numberOfItems: 50, cursor: $cursor }, filter: $filter }
    ) {
      data {
        ...TranscriptItem
        transcript {
          uuid
          fileUpload {
            uuid
            urlV2 {
              url
            }
          }
        }
      }
      nextCursor
      hasMore
    }
  }
`;

const FilterOptions = ["Activated", "Deactivated", "All"] as const;
type FilterOption = typeof FilterOptions[number];

const getOptionLabel = (filterOption: FilterOption) => {
  switch (filterOption) {
    case "Activated":
      return staticT("yes");
    case "Deactivated":
      return staticT("no");
    case "All":
      return staticT("all");
  }
};

const filterOptionToBoolean = (filterOption: FilterOption): Maybe<boolean> => {
  switch (filterOption) {
    case "Activated":
      return true;
    case "Deactivated":
      return false;
    case "All":
      return null;
  }
};

const maybeBooleanToFilterOption = (
  maybeBoolean: Maybe<boolean>,
): FilterOption => {
  if (maybeBoolean === null) return "All";
  return maybeBoolean ? "Activated" : "Deactivated";
};

const FilterSelect = ({
  value,
  onChange,
  tag,
}: {
  value: FilterOption;
  onChange: (newValue: FilterOption) => void;
  tag: TranscriptItemTag;
}) => (
  <div className="flex items-center space-x-8">
    <TooltipWrapper key={tag} label={displayTranscriptItemTag(tag)}>
      <Icon className="text-primary" name={tagToIconName(tag)} />
    </TooltipWrapper>
    <Select
      wrapperClassName="flex flex-1"
      name={tag}
      options={FilterOptions}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
    />
  </div>
);

export const TranscriptInbox = () => {
  const t = useTranslation();
  const [filter, setFilter] = useState<TranscriptItemsFilter>({
    isInaudible: null,
    isIncomprehensible: null,
    requiresMedicalOpinion: null,
    hasOverlappingIssues: null,
  });
  const updatePartialFilter = useCallback(
    (partial: Partial<TranscriptItemsFilter>) =>
      setFilter({ ...filter, ...partial }),
    [filter, setFilter],
  );

  return (
    <div className="flex-col flex-fill p-16 space-y-24">
      <div className="text-24 font-bold text-primary-dark">
        {t("recorded_conversation.search_transcripts")}
      </div>
      <div className="flex-col space-y-4">
        <div className="font-bold">{t("transcript_inbox.filters")}</div>
        <div className="flex space-x-64">
          <FilterSelect
            tag="Inaudible"
            value={maybeBooleanToFilterOption(filter.isInaudible ?? null)}
            onChange={(newValue) =>
              updatePartialFilter({
                isInaudible: filterOptionToBoolean(newValue),
              })
            }
          />
          <FilterSelect
            tag="Incomprehensible"
            value={maybeBooleanToFilterOption(
              filter.isIncomprehensible ?? null,
            )}
            onChange={(newValue) =>
              updatePartialFilter({
                isIncomprehensible: filterOptionToBoolean(newValue),
              })
            }
          />
          <FilterSelect
            tag="RequiresMedicalOpinion"
            value={maybeBooleanToFilterOption(
              filter.requiresMedicalOpinion ?? null,
            )}
            onChange={(newValue) =>
              updatePartialFilter({
                requiresMedicalOpinion: filterOptionToBoolean(newValue),
              })
            }
          />
          <FilterSelect
            tag="HasOverlappingIssues"
            value={maybeBooleanToFilterOption(
              filter.hasOverlappingIssues ?? null,
            )}
            onChange={(newValue) =>
              updatePartialFilter({
                hasOverlappingIssues: filterOptionToBoolean(newValue),
              })
            }
          />
        </div>
      </div>
      <div className="flex-col">
        <PaginatedQuery
          query={TranscriptItems}
          variables={{
            filter,
          }}
        >
          {({ data: transcriptItems }, utils) => (
            <>
              <div className="bg-white rounded border">
                {transcriptItems.map(
                  (item, index) =>
                    item.transcript.fileUpload?.uuid && (
                      <div key={item.uuid}>
                        <Link
                          className="flex items-center flex-fill space-x-12 px-16 py-8 hover:bg-grey-100"
                          to={`/${routes.RECORDED_CONVERSATIONS}/file-upload/${item.transcript.fileUpload.uuid}/transcript`}
                        >
                          <div>{item.transcript.uuid.slice(0, 8)}</div>
                          <div className="flex-fill">{item.text}</div>
                          <TranscriptItemsTagsList
                            tags={getTranscriptItemTags(item)}
                          />
                        </Link>
                        {index !== transcriptItems.length - 1 && <Separator />}
                      </div>
                    ),
                )}
              </div>
              <NextPageButton {...utils} />
            </>
          )}
        </PaginatedQuery>
      </div>
    </div>
  );
};
