import {
  MentionWithPubliclyListedDrug,
  RichText,
} from "components/Form/Editor/RichText/RichText";
import { TextWithMentions } from "types";
import { now } from "utils/date";

export const FakeFRPrescriptionPreview = ({
  content,
}: {
  content: TextWithMentions;
}) => (
  <div className="flex-col flex-fill text-primary-dark py-40 space-y-30">
    <div className="flex-col px-30">
      <div className="font-medium">Dr Laurent Doe</div>
      <div className="text-14">RPPS : 12345678912</div>
      <div className="text-12">Docteur</div>
    </div>
    <div className="bg-light-blue flex px-30 py-10 text-12">
      <div className="flex-col w-1/3">
        <span className="flex">22 Rue Chapon</span>
        <span className="flex">75 011 Paris</span>
      </div>
      <div className="flex-col w-1/3">
        <span className="flex">Adeli : 123456789</span>
      </div>
      <div className="flex-col w-1/3">
        <span className="flex">Nº AM : 123456789</span>
      </div>
    </div>
    <div className="flex-col px-30">
      Pour Marie Doe (29 ans)
      <div className="mt-30">À Paris, le {now().format("date")}</div>
      <div className="flex-col mt-56 whitespace-pre-wrap">
        <RichText
          source={content}
          hasMarkdown
          Mention={MentionWithPubliclyListedDrug}
        />
      </div>
    </div>
    <div>
      <div className="mt-16 flex items-center px-30 text-primary-dark space-x-10">
        <span>Signature :</span>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Joachim_Gaucks_signature.svg/langfr-914px-Joachim_Gaucks_signature.svg.png"
          alt="signature"
          className="object-contain"
          style={{ height: 44 }}
        />
      </div>
      <div className="flex justify-center mx-30 border-t text-primary-dark text-10 mt-36  py-14">
        Cette ordonnance est nominative et n'est valable qu'une seule fois. En
        cas d'urgence, appelez le 15.
      </div>
    </div>
  </div>
);
