import { useState } from "react";
import classNames from "classnames";

import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { ControlledConfirmationModal } from "components/Modal/ControlledConfirmationModal";
import { SidePanel } from "components/SidePanel/SidePanel";
import { SortStatus } from "components/Table/Table";
import { DeleteTask, SetTaskStatus, TaskFragment } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import {
  displayDoctor,
  displayPatient,
  displayTaskColor,
  displayTaskPriority,
} from "utils/display";
import { notifier } from "utils/notifier";

import { TaskComposer } from "./TaskComposer";
import {
  FormTaskFilterValues,
  TaskConfirmationModalState,
  TaskHeader,
  updateTaskStatusInCache,
} from "./utils";

export const TaskPanel = ({
  task,
  filter,
  search,
  sortStatus,
  onClose,
}: {
  task: TaskFragment;
  filter: FormTaskFilterValues;
  search: string | undefined;
  sortStatus: SortStatus<TaskHeader>;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const [composerState, setComposerState] = useState<{ task: TaskFragment }>();
  const [confirmationModalState, setConfirmationModalState] =
    useState<TaskConfirmationModalState>();
  const [deleteTask] = useMutation(DeleteTask);
  const [setTaskStatus] = useMutation(SetTaskStatus, {
    onSuccess: (output, client) => {
      updateTaskStatusInCache(client, output.task, filter, search, sortStatus);
      output.task.isCompleted
        ? notifier.success(t("tasks.task_panel.task_marked_as_completed"))
        : notifier.success(t("task_panel.task_marked_as_not_completed"));
    },
  });

  return (
    <SidePanel opened width={380} onClickOutside={onClose}>
      {composerState && (
        <TaskComposer
          task={composerState.task}
          onClose={() => setComposerState(undefined)}
          onSuccess={onClose}
        />
      )}
      {confirmationModalState && (
        <ControlledConfirmationModal
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <TopSafeArea />
      <div className="flex-col m-16 space-y-20">
        <div className="flex justify-between">
          <span className="text-primary-dark font-bold text-18">
            {t("tasks.task_panel.task")}
          </span>
          <ClickableIcon name="close" onClick={onClose} />
        </div>
        <div className="flex-col space-y-4">
          <div className="text-primary-dark font-medium text-16">
            {task.title}
          </div>
          {task.description && (
            <div className="text-primary-dark font-normal text-14">
              {task.description}
            </div>
          )}
        </div>
        <div className="border" />
        <div className="flex-col space-y-24">
          <div className="flex-col">
            <div className="text-primary-dark font-medium text-14">
              {t("tasks.task_panel.patient")}
            </div>
            <div className="text-primary-dark font-normal text-14">
              {displayPatient(task.patient)}
            </div>
          </div>
          {task.priority && (
            <div className="flex-col">
              <div className="text-primary-dark font-medium text-14">
                {t("tasks.task_panel.priority")}
              </div>
              <div
                className={classNames(
                  "text-primary-dark font-normal text-14",
                  displayTaskColor(task.priority),
                )}
              >
                {displayTaskPriority(task.priority)}
              </div>
            </div>
          )}
          {task.assignedDoctor && (
            <div className="flex-col">
              <div className="text-primary-dark font-medium text-14">
                {t("tasks.task_panel.assignee")}
              </div>
              <div className="text-primary-dark font-normal text-14">
                {displayDoctor(task.assignedDoctor)}
              </div>
            </div>
          )}
          <div className="flex">
            <ClickableIcon
              name="edit"
              className="hover:bg-grey-100 rounded p-8"
              onClick={() => {
                setComposerState({ task });
              }}
            />
            <ClickableIcon
              name="trash"
              className="hover:bg-grey-100 rounded p-8"
              onClick={() => {
                setConfirmationModalState({
                  cta: {
                    label: t("tasks.task_panel.delete_this_task"),
                    danger: true,
                  },
                  children: t(
                    "task_panel.it_will_delete_the_task_tasktitle_this_action_cannot_be_undone",
                    { title: task.title },
                  ),
                  onConfirm: () =>
                    deleteTask(
                      { taskUuid: task.uuid },
                      {
                        onSuccess: (_, client) => {
                          setConfirmationModalState(undefined);
                          onClose();
                          client.remove("Task", task.uuid);
                          notifier.success(t("tasks.task_panel.task_deleted"));
                        },
                      },
                    ),
                });
              }}
            />
          </div>
          <Button
            label={
              task.isCompleted
                ? t("tasks.task_panel.mark_as_not_completed")
                : t("tasks.task_panel.mark_as_completed")
            }
            onClick={() =>
              setTaskStatus({
                taskUuid: task.uuid,
                isCompleted: !task.isCompleted,
              })
            }
          />
        </div>
      </div>
    </SidePanel>
  );
};
