import { ReactNode } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { ConversationContent } from "components/ConversationContent/ConversationContent";
import { PatientNoDeviceWarning } from "components/ConversationHeader/PatientNoDeviceWarning";
import { BackMobile } from "components/Mobile/BackMobile";
import { usePatientTimelineLocalState } from "components/Patient/Timeline/PatientTimelineLocalStateContext";
import { PatientTimelineLocalStateProvider } from "components/Patient/Timeline/PatientTimelineLocalStateProvider";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useUser } from "contexts/User/UserContext";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { FileMessageInput } from "types";
import { getQAInboxRoute } from "utils";

import { QACopilotPanelContext } from "../QACopilotContext/QACopilotPanelContext";
import {
  useMaybeQAExperience,
  useQAExperience,
} from "../QAExperienceContext/QAExperienceContext";
import {
  NewQAExperienceProvider,
  QAExperienceProvider,
} from "../QAExperienceContext/QAExperienceProvider";
import { QASidePanel } from "../QASidePanel/QASidePanel";
import { useQASidePanel } from "../QASidePanelContext/QASidePanelContext";
import { QASidePanelProvider } from "../QASidePanelContext/QASidePanelProvider";
import { QAExperienceHeader } from "./QAExperienceHeader/QAExperienceHeader";
import { QAComposer } from "./QAFooter/QAComposer";
import { QAFooter } from "./QAFooter/QAFooter";

export const QAExperience = () => {
  const { uuid } = useParams();

  return (
    <div className="flex-fill flex relative overflow-hidden">
      <QAExperienceProvider key={uuid} uuid={uuid}>
        <QAProvidersWrapper experienceUuid={uuid}>
          <QAExperienceContent />
        </QAProvidersWrapper>
      </QAExperienceProvider>
    </div>
  );
};

export const NewQAExperience = () => {
  const { patientUuid } = useParams();

  return (
    <div className="flex-fill flex relative overflow-hidden">
      <NewQAExperienceProvider patientUuid={patientUuid}>
        <QAProvidersWrapper experienceUuid={null}>
          <NewQAExperienceContent />
        </QAProvidersWrapper>
      </NewQAExperienceProvider>
    </div>
  );
};

const QAExperienceContent = () => {
  const { experience, patient } = useQAExperience();
  const { setIsTimelineOpen } = useQASidePanel();
  const { expandItem, setScrollToActivityDisabled } =
    usePatientTimelineLocalState();

  return (
    <div className="flex-fill flex-col">
      <QAExperienceHeader />
      {patient.devices.isEmpty() &&
        experience.patientSeenUntil.getTime() === 0 && (
          <PatientNoDeviceWarning />
        )}
      <ConversationContent
        onCreatePatientTimelineItem={(itemUuid) => {
          setIsTimelineOpen(true);
          expandItem(itemUuid);
          setTimeout(() => {
            setScrollToActivityDisabled(false);
          }, 800);
        }}
      />
      <QAFooter setIsTimelineOpen={setIsTimelineOpen} />
    </div>
  );
};

const NewQAExperienceContent = () => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { user } = useUser();
  const navigate = useNavigate();
  const { messagesContainerRef } = useMaybeQAExperience();
  const { setIsTimelineOpen } = useQASidePanel();

  return (
    <div className="flex-fill flex-col">
      <div className="pl-8 pr-16 py-12 text-18 border-b flex items-center">
        <BackMobile fallback={routes.QA_INBOX} className="mr-8" />
        {t("qa_experience.new_conversation")}
      </div>
      {patient.devices.isEmpty() && <PatientNoDeviceWarning />}
      <div className="flex-fill border-b" ref={messagesContainerRef} />
      <QAComposer
        setIsTimelineOpen={setIsTimelineOpen}
        onCreateExperienceSuccess={(result) => {
          navigate(getQAInboxRoute(result.experience, user?.uuid), {
            replace: true,
          });
        }}
      />
    </div>
  );
};

const QAProvidersWrapper = ({
  experienceUuid,
  children,
}: {
  experienceUuid: UUID | null;
  children: ReactNode;
}) => {
  const { patient } = usePatient();
  const { mediaMessages, setMediaMessages } = useExperienceDraft();

  return (
    <QASidePanelProvider>
      {(ctx) => (
        <PatientTimelineLocalStateProvider
          patientUuid={patient.uuid}
          attachItemToMessage={(file: FileMessageInput) => {
            setMediaMessages([...mediaMessages, file]);
            ctx.setIsTimelineOpen(false);
          }}
        >
          <QACopilotPanelContext.Provider
            value={{
              isCopilotPanelOpened: ctx.sidePanelState?.mode === "LIBRARY",
              openCopilotPanel: () =>
                ctx.setSidePanelState({ mode: "LIBRARY" }),
              closeCopilotPanel: ctx.closeSidePanel,
            }}
          >
            {children}
            <QASidePanel experienceUuid={experienceUuid} />
          </QACopilotPanelContext.Provider>
        </PatientTimelineLocalStateProvider>
      )}
    </QASidePanelProvider>
  );
};
