import { RefObject } from "react";

import { FormInput } from "components/Form/Input/FormInput";
import { useTranslation } from "i18n";

import { useEHRInput } from "./useEHRInput";

export const EHRComposerTitle = ({
  wrapperClassName,
  inputRef,
}: {
  wrapperClassName?: string;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { setEHRInput } = useEHRInput();

  return (
    <FormInput
      name="title"
      inputRef={inputRef}
      autoFocus
      placeholder={t(
        "inboxes.qa_experience.ehr_composer.ehr_composer_title.title",
      )}
      className="border-0 text-primary-dark text-18 font-medium"
      wrapperClassName={wrapperClassName}
      onChange={(e) => setEHRInput({ title: e.currentTarget.value })}
    />
  );
};
