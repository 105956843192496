import { useRef } from "react";

import { Media } from "atoms/useMediaWindows";

import { isRemoteMedia } from "../mediaWindowsModel";
import { AbstractMediaContent } from "./mediaContentModel";
import { ZoomableMediaContentWrapper } from "./ZoomableMediaContentWrapper";

export const ImageMediaContent: AbstractMediaContent = ({
  media,
}: {
  media: Media;
}) => {
  const imageRef = useRef<HTMLImageElement>(null);

  if (!isRemoteMedia(media)) {
    // Handle blob media is needed
    return null;
  }

  const backgroundElement = (
    <div
      className="absolute inset-[-100px] pointer-events-none bg-cover bg-black bg-[50%] blur-md brightness-60"
      style={{
        backgroundImage: `url(${media.url})`,
      }}
    />
  );

  return (
    <ZoomableMediaContentWrapper backgroundElement={backgroundElement}>
      <img
        src={media.url}
        className="h-full w-full object-contain"
        alt=""
        ref={imageRef}
      />
    </ZoomableMediaContentWrapper>
  );
};
