import { gql } from "@apollo/client";

import { Switch } from "components/Form/Switch/Switch";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import {
  AppointmentWithNoteFragment,
  StartSuggestingNoteFromTranscript,
  StopSuggestingNoteFromTranscript,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

gql`
  mutation StartSuggestingNoteFromTranscript($appointmentUuid: UUID!) {
    startSuggestingNoteFromTranscript(appointmentUuid: $appointmentUuid) {
      appointment {
        uuid
        suggestingInPatientNote
      }
    }
  }

  mutation StopSuggestingNoteFromTranscript($appointmentUuid: UUID!) {
    stopSuggestingNoteFromTranscript(appointmentUuid: $appointmentUuid) {
      appointment {
        uuid
        suggestingInPatientNote
      }
    }
  }
`;

export const SuggestionSwitch = ({
  appointment,
}: {
  appointment: AppointmentWithNoteFragment;
}) => {
  const t = useTranslation();
  const [startSuggesting] = useMutation(StartSuggestingNoteFromTranscript);
  const [stopSuggesting] = useMutation(StopSuggestingNoteFromTranscript);

  return (
    // TooltipWrapper uses the same className for the tooltip wrapper and the target wrapper,
    // so we can't use it and need an extra wrapper
    <div className="ml-40 flex">
      <TooltipWrapper
        label={t("suggestion_switch.display")}
        position="top"
        className="flex"
      >
        {/* To balance the fixed ml-12 on label less switch and get centered tooltip */}
        <div className="w-12" />
        <Switch
          closeIcon="audioBars"
          checkIcon="audioBars"
          checked={appointment.suggestingInPatientNote}
          onChange={() =>
            appointment.suggestingInPatientNote
              ? stopSuggesting(
                  { appointmentUuid: appointment.uuid },
                  {
                    optimisticResponse: {
                      __typename: "StopSuggestingNoteFromTranscriptOutput",
                      appointment: {
                        __typename: "Appointment",
                        uuid: appointment.uuid,
                        suggestingInPatientNote: false,
                      },
                    },
                  },
                )
              : startSuggesting(
                  { appointmentUuid: appointment.uuid },
                  {
                    optimisticResponse: {
                      __typename: "StartSuggestingNoteFromTranscriptOutput",
                      appointment: {
                        __typename: "Appointment",
                        uuid: appointment.uuid,
                        suggestingInPatientNote: true,
                      },
                    },
                  },
                )
          }
        />
      </TooltipWrapper>
    </div>
  );
};
