import { lazy, Suspense } from "react";
import classNames from "classnames";

import { Spinner } from "components/Spinner/Spinner";
import { useTranslation } from "i18n";

const DicomViewer = lazy(() => import("components/DicomViewer/DicomViewer"));

export const FilePreview = ({
  name,
  url,
  type,
  className,
  isContained = false,
}: {
  name: string;
  url: string;
  type: string;
  className?: string;
  isContained?: boolean;
}) => {
  const t = useTranslation();
  if (type.startsWith("audio")) {
    return (
      <audio controls>
        <source src={url} type={type} />
      </audio>
    );
  }

  const Tag = type.startsWith("image")
    ? "img"
    : type.startsWith("video")
    ? "video"
    : type.endsWith("pdf")
    ? "iframe"
    : type === "application/dicom"
    ? DicomViewer
    : undefined;
  if (!Tag) {
    return (
      <span className="text-14">
        {name} {t("form.dropzone.file_preview.no_preview_available")}
      </span>
    );
  }
  return (
    <Suspense fallback={<Spinner className="m-20" />}>
      <Tag
        src={url}
        className={classNames(className, "flex-fill", {
          "object-cover": !isContained && (Tag === "img" || Tag === "video"),
          "object-contain": isContained,
        })}
      />
    </Suspense>
  );
};
