import { useEffect, useState } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

import { Icon } from "components/Icon/Icon";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useIsOnboardingModalVisible } from "hooks/useStorageState";
import { getCurrentLanguage, useTranslation } from "i18n";
import { routes } from "routes";
import styles from "singletons/onboardingModal.module.css";

import { PresentationPage } from "./PresentationPage";
import accountsImageEN from "./resources/OnboardingAccountsEN.png";
import accountsImageFR from "./resources/OnboardingAccountsFR.png";
import accountsImagePT from "./resources/OnboardingAccountsPT.png";
import analyticsImageEN from "./resources/OnboardingAnalyticsEN.png";
import analyticsImageFR from "./resources/OnboardingAnalyticsFR.png";
import analyticsImagePT from "./resources/OnboardingAnalyticsPT.png";
import calendarImageEN from "./resources/OnboardingCalendarEN.png";
import calendarImageFR from "./resources/OnboardingCalendarFR.png";
import calendarImagePT from "./resources/OnboardingCalendarPT.png";
import conversationsImageEN from "./resources/OnboardingConversationsEN.png";
import conversationsImageFR from "./resources/OnboardingConversationsFR.png";
import conversationsImagePT from "./resources/OnboardingConversationsPT.png";
import inboxImageEN from "./resources/OnboardingInboxEN.png";
import inboxImageFR from "./resources/OnboardingInboxFR.png";
import inboxImagePT from "./resources/OnboardingInboxPT.png";
import internalChatsImageEN from "./resources/OnboardingInternalChatsEN.png";
import internalChatsImageFR from "./resources/OnboardingInternalChatsFR.png";
import internalChatsImagePT from "./resources/OnboardingInternalChatsPT.png";
import patientViewImageEN from "./resources/OnboardingPatientViewEN.png";
import patientViewImageFR from "./resources/OnboardingPatientViewFR.png";
import patientViewImagePT from "./resources/OnboardingPatientViewPT.png";
import { YourTurnPage } from "./YourTurnPage";

const onboardingImages = {
  fr: {
    accounts: accountsImageFR,
    analytics: analyticsImageFR,
    calendar: calendarImageFR,
    conversations: conversationsImageFR,
    inbox: inboxImageFR,
    internalchat: internalChatsImageFR,
    patientview: patientViewImageFR,
  },
  pt: {
    accounts: accountsImagePT,
    analytics: analyticsImagePT,
    calendar: calendarImagePT,
    conversations: conversationsImagePT,
    inbox: inboxImagePT,
    internalchat: internalChatsImagePT,
    patientview: patientViewImagePT,
  },
  en: {
    accounts: accountsImageEN,
    analytics: analyticsImageEN,
    calendar: calendarImageEN,
    conversations: conversationsImageEN,
    inbox: inboxImageEN,
    internalchat: internalChatsImageEN,
    patientview: patientViewImageEN,
  },
};

export const OnboardingModal = ({ isAdmin }: { isAdmin: boolean }) => {
  const t = useTranslation();
  const [isVisible, setIsVisible] = useIsOnboardingModalVisible();
  const [isMounted, setIsMounted] = useState(isVisible);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const language = getCurrentLanguage();

  useEffect(() => {
    if (isVisible && !isMounted) setIsMounted(true);
  }, [isVisible, isMounted]);

  const dismiss = () => setIsVisible(false);

  if (!isMounted) return null;

  if (!isDesktop) return null;

  const jointPages: JSX.Element[] = [
    <PresentationPage
      key="inbox"
      icon="drawer"
      title={t("onboarding_modal.onboarding_modal.inbox")}
      image={onboardingImages[language].inbox}
      description={t("onboarding_modal.onboarding_modal.inbox.description")}
      onClickNavigateTo={() => {
        dismiss();
        navigate(routes.PATIENTS_INBOX);
      }}
    />,
    <PresentationPage
      key="calendar"
      icon="calendar"
      title={t("onboarding_modal.onboarding_modal.calendar")}
      image={onboardingImages[language].calendar}
      description={t("onboarding_modal.onboarding_modal.calendar.description")}
      onClickNavigateTo={() => {
        dismiss();
        navigate(routes.SCHEDULING);
      }}
    />,
    <PresentationPage
      key="patient view"
      icon="profile"
      title={t("onboarding_modal.onboarding_modal.patient_view")}
      image={onboardingImages[language].patientview}
      description={t(
        "onboarding_modal.onboarding_modal.patient_view.description",
      )}
      onClickNavigateTo={() => {
        dismiss();
        navigate(routes.PATIENT_LIST);
      }}
    />,
    <PresentationPage
      key="conversations"
      icon="drawer"
      title={t("onboarding_modal.onboarding_modal.conversations")}
      image={onboardingImages[language].conversations}
      description={t(
        "onboarding_modal.onboarding_modal.conversations.description",
      )}
      onClickNavigateTo={() => {
        dismiss();
        navigate(
          `/${routes.QA_INBOX}?section=${routes.QA_UNASSIGNED}&${routes.OPEN_FIRST_CONVERSATION}`,
        );
      }}
    />,
  ];

  const adminOnlyPages: JSX.Element[] = [
    <PresentationPage
      key="analytics"
      icon="chart"
      title={t("onboarding_modal.onboarding_modal.analytics")}
      image={onboardingImages[language].analytics}
      description={t("onboarding_modal.onboarding_modal.analytics.description")}
      onClickNavigateTo={() => {
        dismiss();
        navigate(routes.STATS);
      }}
    />,
    <PresentationPage
      key="accounts"
      icon="passport"
      title={t("onboarding_modal.onboarding_modal.accounts")}
      image={onboardingImages[language].accounts}
      description={t("onboarding_modal.onboarding_modal.accounts.description")}
      onClickNavigateTo={() => {
        dismiss();
        navigate(routes.TEAM_ACCOUNTS);
      }}
    />,
  ];

  const practitionerOnlyPages: JSX.Element[] = [
    <PresentationPage
      key="internal chats"
      icon="chat"
      title={t("onboarding_modal.onboarding_modal.internal_chats")}
      image={onboardingImages[language].internalchat}
      description={t(
        "onboarding_modal.onboarding_modal.internal_chats.description",
      )}
      onClickNavigateTo={() => {
        dismiss();
        navigate(routes.CONVERSATION_BASE);
      }}
    />,
  ];

  const yourTurnPage: JSX.Element = (
    <YourTurnPage
      key="yourturn"
      onLinkClick={(path) => {
        dismiss();
        navigate(path);
      }}
    />
  );

  const PAGES: JSX.Element[] = [
    ...jointPages,
    ...(isAdmin ? adminOnlyPages : practitionerOnlyPages),
    yourTurnPage,
  ];

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.onboardingModalBackdrop, {
        // Lets us easily animate the fade in and out.
        "invisible bg-transparent": !isVisible,
      })}
      onClick={dismiss}
      onTransitionEnd={(e) =>
        !isVisible && e.propertyName === "visibility" && setIsMounted(false)
      }
      role="dialog"
      tabIndex={-1}
    >
      <TopSafeArea />
      <div
        className={classNames(styles.onboardingModal, {
          [styles.onboardingModalDisappearing]: !isVisible,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={dismiss} />
        <div className="flex-col p-24 lg:p-44 space-y-24">
          {PAGES[currentPageIndex]}
          <Navigation
            onClickPrevious={() => setCurrentPageIndex(currentPageIndex - 1)}
            onClickNext={() => setCurrentPageIndex(currentPageIndex + 1)}
            numberOfPages={PAGES.length}
            currentPageIndex={currentPageIndex}
          />
        </div>
      </div>
    </div>,
    document.getElementById("onboarding-modal-root")!,
  );
};

// --- Private

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <div className="absolute top-24 right-24 lg:right-24">
    <button
      className="h-36 lg:h-40 w-36 lg:w-40 rounded-full border flex-center hover:bg-grey-100/50"
      onClick={onClick}
    >
      <Icon name="close" />
    </button>
  </div>
);

const PageIndicatorDots = ({
  numberOfPages,
  currentPageIndex,
}: {
  numberOfPages: number;
  currentPageIndex: number;
}) => (
  <div className="flex items-center space-x-8">
    {[...Array(numberOfPages).keys()].map((pageIndex, _) => (
      <div
        key={pageIndex}
        className={classNames(
          "dot h-8 w-8 rounded-full",
          pageIndex === currentPageIndex ? "bg-primary" : "bg-grey",
        )}
      />
    ))}
  </div>
);

const Navigation = ({
  onClickPrevious,
  onClickNext,
  numberOfPages,
  currentPageIndex,
}: {
  onClickPrevious: () => void;
  onClickNext: () => void;
  numberOfPages: number;
  currentPageIndex: number;
}) => {
  const t = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <button
        className={classNames(
          "text-14 font-medium text-body bg-grey-100 px-12 py-4 rounded hover:opacity-85 active:opacity-75",
          currentPageIndex === 0 ? "invisible" : "",
        )}
        onClick={onClickPrevious}
      >
        <div className="flex items-center space-x-8">
          <Icon name="arrow" />
          <span>{t("onboarding_modal.onboarding_modal.previous")}</span>
        </div>
      </button>

      <PageIndicatorDots
        numberOfPages={numberOfPages}
        currentPageIndex={currentPageIndex}
      />

      <button
        className={classNames(
          "text-14 font-medium text-white bg-primary px-12 py-4 rounded hover:opacity-85 active:opacity-75",
          currentPageIndex === numberOfPages - 1 ? "invisible" : "",
        )}
        onClick={onClickNext}
      >
        <div className="flex items-center space-x-8">
          <span>{t("onboarding_modal.onboarding_modal.next")}</span>
          <Icon name="arrow" rotate={180} />
        </div>
      </button>
    </div>
  );
};
