import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { Maybe } from "base-types";
import { FileMessageInput, SerializableRecord } from "types";

import {
  PatientTimelineCreatableItemTypes,
  PatientTimelineNewItemPayload,
} from "./Item/types";

export type PatientTimelineLocalState = {
  patientUuid: UUID;
  itemState: (uuid: UUID) => PatientTimelineLocalItemState;
  closeItem: (uuid: UUID) => void;
  expandItem: (uuid: UUID) => void;
  startEditingItem: (uuid: UUID) => void;
  stopEditingItem: (uuid: UUID) => void;
  updateItemDraft: (uuid: UUID, draft: SerializableRecord) => void;
  attachItemToMessage?: (file: FileMessageInput) => void;
  newItems: NewTimelineItemWithPayload[];
  addNewItem: <T extends PatientTimelineCreatableItemTypes>(
    type: T,
    payload: PatientTimelineNewItemPayload[T],
  ) => void;
  setScrollToActivityDisabled: Dispatch<SetStateAction<boolean>>;
};

export type NewTimelineItemWithPayload = {
  [Key in keyof PatientTimelineNewItemPayload]: {
    temporaryUuid: UUID;
    type: Key;
    payload: PatientTimelineNewItemPayload[Key];
  };
}[keyof PatientTimelineNewItemPayload];

export type PatientTimelineLocalItemState =
  | { mode: "CLOSED" }
  | ({ mode: "EXPANDED"; isFocused: boolean } & (
      | { isEditing: false; draft: undefined }
      | { isEditing: true; draft: Maybe<SerializableRecord> }
    ));

export const PatientTimelineLocalStateContext =
  createContext<PatientTimelineLocalState | null>(null);

PatientTimelineLocalStateContext.displayName =
  "PatientTimelineLocalStateContext";

export const usePatientTimelineLocalState = () => {
  const context = useContext(PatientTimelineLocalStateContext);
  if (!context) throw new Error("No PatientTimelineLocalStateProvider");
  return context;
};

export const usePatientTimelineLocalItemState = (itemUuid: UUID) => {
  const timelineState = usePatientTimelineLocalState();
  return {
    itemState: timelineState.itemState(itemUuid),
    closeItem: () => timelineState.closeItem(itemUuid),
    expandItem: () => timelineState.expandItem(itemUuid),
    expandOtherItem: timelineState.expandItem,
    startEditingItem: () => timelineState.startEditingItem(itemUuid),
    updateItemDraft: (draft: SerializableRecord) =>
      timelineState.updateItemDraft(itemUuid, draft),
    stopEditingItem: () => timelineState.stopEditingItem(itemUuid),
    attachItemToMessage: timelineState.attachItemToMessage,
  };
};
