import { ClickableIcon } from "components/Icon/ClickableIcon";
import {
  UncontrolledPopover,
  UncontrolledPopoverProps,
} from "components/Popover/UncontrolledPopover";
import { useIsDesktop } from "hooks/useMediaQuery";
import { IconName } from "icon-library";

import { ShareContent, ShareContentProps } from "./ShareContent";

export const ShareButton = ({
  messageInput,
  successNotification,
  position,
  icon = "shareLight",
  size,
  className,
}: {
  icon?: IconName;
  size?: number;
  className?: string;
} & Omit<ShareContentProps, "onSelected"> &
  Pick<UncontrolledPopoverProps, "position">) => {
  const isDesktop = useIsDesktop();

  return (
    <UncontrolledPopover
      allowScrolling
      fullWidthOnMobile
      position={position}
      content={(close) => (
        <div
          className="flex-col"
          style={{ maxHeight: 330, width: isDesktop ? 300 : undefined }}
        >
          <ShareContent
            messageInput={messageInput}
            successNotification={successNotification}
            onSelected={close}
          />
        </div>
      )}
    >
      {({ setTarget }) => (
        <ClickableIcon
          className={className}
          size={size}
          name={icon}
          onClick={setTarget}
        />
      )}
    </UncontrolledPopover>
  );
};
