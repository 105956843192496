import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { firebase } from "@firebase/app";
import "@firebase/messaging";
import gql from "graphql-tag";
import { useNavigate } from "react-router";

import { useLoggedInAuth } from "auth/AuthContext";
import { DEVICE_UUID_KEY } from "consts";
import { AppErrorCode } from "errors/generated";
import {
  RegisterOrUpdateDevice,
  RegisterOrUpdateDeviceVariables,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useIsPWA } from "hooks/useMediaQuery";
import { useStorageState } from "hooks/useStorageState";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { getMaybeExperienceUuid } from "utils";
import { withServiceWorkerRegistration } from "utils/firebase";
import {
  iosNotificationsRegistrationSuccess,
  removeDeliveredNotifications,
} from "utils/ios-push-notifications";
import { notifier } from "utils/notifier";
import {
  getFirebaseConfig,
  handleFirebaseMessage,
  WorkerMessageData,
} from "utils/workers-utils";

gql`
  mutation RemoveDevice($uuid: UUID!) {
    removeDevice(deviceUuid: $uuid) {
      status
    }
  }

  mutation RegisterOrUpdateDevice(
    $deviceUuid: UUID
    $os: DeviceOs!
    $model: String!
    $osVersion: String!
    $sdkModules: [SdkModule!]!
    $notificationsPreference: NotificationsPreference!
    $notificationToken: String
  ) {
    registerOrUpdateDevice(
      deviceUuid: $deviceUuid
      deviceInput: {
        os: $os
        model: $model
        osVersion: $osVersion
        sdkModules: $sdkModules
        notificationsPreference: $notificationsPreference
        notificationToken: $notificationToken
      }
    ) {
      device {
        uuid
      }
    }
  }
`;

export const useNotifications = () => {
  const t = useTranslation();
  const [registerOrUpdateDeviceMutation] = useMutation(RegisterOrUpdateDevice);
  const { currentImpersonationUuid } = useLoggedInAuth();
  const [deviceUuid, setDeviceUuid] = useStorageState<string | null>(
    DEVICE_UUID_KEY,
    null,
  );
  const navigate = useNavigate();

  const isPWA = useIsPWA();

  useEffect(() => {
    if (currentImpersonationUuid) return;

    const registerOrUpdateDevice = (
      variables: RegisterOrUpdateDeviceVariables,
    ) => {
      void registerOrUpdateDeviceMutation(variables, {
        onSuccess: (result) => {
          setDeviceUuid(result.device.uuid);
        },
        onError: (error, fallback) => {
          if (error.code === AppErrorCode.ENTITY_NOT_FOUND) {
            setDeviceUuid(null);
            registerOrUpdateDevice(variables);
          } else {
            fallback();
          }
        },
      });
    };

    if (Capacitor.isNativePlatform()) {
      const deviceTokenPromise = new Promise<string | null>((resolve) => {
        PushNotifications.addListener("registration", (token: Token) => {
          resolve(token.value);
          iosNotificationsRegistrationSuccess();
        });
        PushNotifications.addListener("registrationError", (error: any) => {
          notifier.error({
            user: t(
              "use_notifications.an_error_occurred_while_requesting_notifications",
            ),
            sentry: { level: "error", exception: error },
          });
          resolve(null);
        });
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          // Remove notifications associated with message removal
          if ("remove.uuid" in notification.data) {
            removeDeliveredNotifications(
              (n) => n.id === notification.data["remove.uuid"],
            );
          }

          // Ignore notifications if we are looking at the experience
          if (
            "type" in notification.data &&
            notification.data.type === "NEW_MESSAGE" &&
            "experience.uuid" in notification.data &&
            notification.data["experience.uuid"] === getMaybeExperienceUuid()
          ) {
            removeDeliveredNotifications((n) => n.id === notification.id);
          }
        },
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          if (
            notification.actionId === "tap" &&
            "type" in notification.notification.data &&
            notification.notification.data.type === "NEW_MESSAGE" &&
            "experience.uuid" in notification.notification.data
          ) {
            const route =
              notification.notification.data["experience.type"] ===
              "SINGLE_PATIENT_CONVERSATION"
                ? routes.QA_INBOX
                : routes.CONVERSATION_BASE;
            navigate(
              `${route}/${
                notification.notification.data["experience.uuid"] as string
              }`,
            );
          }
        },
      );

      PushNotifications.requestPermissions().then((result) => {
        PushNotifications.register();
        deviceTokenPromise.then((deviceToken) => {
          registerOrUpdateDevice({
            deviceUuid,
            os: "IOS",
            model: "WEB",
            osVersion: "WEB",
            sdkModules: [],
            notificationsPreference:
              result.receive === "granted"
                ? "ACCEPTED"
                : result.receive === "denied"
                ? "REFUSED"
                : "NOT_REQUESTED",
            notificationToken: deviceToken,
          });
        });
      });

      return () => {
        PushNotifications.removeAllListeners();
      };
    } else if (firebase.messaging.isSupported() && isPWA) {
      Notification.requestPermission().then(async (value) => {
        if (value !== "granted") return;
        const firebaseConfig = getFirebaseConfig(window.location.host);
        if (firebase.apps.isEmpty()) firebase.initializeApp(firebaseConfig);

        const messaging = firebase.messaging();
        messaging.onMessage((message) => {
          withServiceWorkerRegistration((r) => {
            handleFirebaseMessage(r, message.data);
          });
        });

        registerOrUpdateDevice({
          deviceUuid,
          os: "ANDROID",
          model: "WEB",
          osVersion: "WEB",
          sdkModules: [],
          notificationsPreference: "ACCEPTED",
          notificationToken: await messaging.getToken({
            vapidKey: firebaseConfig.vapidKey,
          }),
        });
      });

      return onWorkerMessage((data) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.type === "NAVIGATION") navigate(data.url);
      });
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPWA]);
};

const onWorkerMessage = (callback: (data: WorkerMessageData) => void) => {
  const listener = (event: ServiceWorkerContainerEventMap["message"]) => {
    callback(event.data);
  };
  navigator.serviceWorker.addEventListener("message", listener);
  return () => {
    navigator.serviceWorker.removeEventListener("message", listener);
  };
};
