import { useField } from "hooks/useField";

import {
  NoteSectionsComposer,
  NoteSectionsComposerProps,
} from "./NoteSectionsComposer";
import { NoteSectionWithComposerFields } from "./types";

export const FormNoteSectionsComposer = ({
  name,
  readOnly,
  onChange,
  ...otherProps
}: {
  name: string;
  onChange?: (sections: NoteSectionWithComposerFields[]) => void;
} & Omit<NoteSectionsComposerProps, "value" | "onChange">) => {
  const [{ value, disabled: formDisabled }, , { setValue }] = useField<
    NoteSectionWithComposerFields[]
  >({ name });

  return (
    <NoteSectionsComposer
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue);
        setValue(newValue);
      }}
      readOnly={readOnly ?? formDisabled}
      {...otherProps}
    />
  );
};
