import { z } from "zod";

import { AnyEndpointDefinition } from "api/types";

// These endpoint definitions are added here instead of in `api/endpoints.ts`
// to stress that we shouldn't call the server API directly from the console,
// and that this is only done exceptionally for the SDK setup guide.

export const createPatientDefinition = {
  apiKind: "SERVER",
  authenticationKind: "AUTHENTICATED_AS_SERVER",
  path: "patients",
  requestDataSchema: z.object({
    first_name: z.string(),
    last_name: z.string(),
    date_of_birth: z.string(),
  }),
  responseSchema: z.object({
    id: z.string().uuid(),
  }),
} satisfies AnyEndpointDefinition;

export const authenticatePatientDefinition = (patientId: string) =>
  ({
    apiKind: "SERVER",
    authenticationKind: "AUTHENTICATED_AS_SERVER",
    path: `jwt/patient/authenticate/${patientId}`,
    responseSchema: z.object({
      access_token: z.string(),
      refresh_token: z.string(),
    }),
  } satisfies AnyEndpointDefinition);

export type CreatePatientData = z.infer<
  typeof createPatientDefinition.requestDataSchema
>;
