import { useState } from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

import { Button } from "components/Button/Button";
import { Separator } from "components/Separator/Separator";
import { useUser } from "contexts/User/UserContext";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { PersonalInformationAppointments } from "./general/PersonalInformationAppointments";
import { PersonalInformationGeneral } from "./general/PersonalInformationGeneral";
import { PersonalInformationMedicalLicenses } from "./general/PersonalInformationMedicalLicenses";
import { PersonalInformationPrescriptions } from "./general/PersonalInformationPrescriptions";

type Tab = "GENERAL" | "APPOINTMENTS" | "PRESCRIPTIONS" | "MEDICAL-LICENSES";
const isTab = (str: string | undefined): str is Tab =>
  str !== undefined &&
  ["GENERAL", "APPOINTMENTS", "PRESCRIPTIONS", "MEDICAL-LICENSES"].includes(
    str,
  );

export const PersonalInformation = () => {
  const t = useTranslation();
  const { hasAccessToGatekeeperFeature } = useUser();
  const [newMedicalLicense, setNewMedicalLicense] = useState(false);

  const queryParam = useMatch(
    `${routes.PREFERENCES}/${routes.PERSONAL_INFO}/:tab*`,
  )?.params.tab.toUpperCase();
  const currentTab: Tab =
    !isTab(queryParam) ||
    (queryParam === "MEDICAL-LICENSES" &&
      !hasAccessToGatekeeperFeature("IS_US_COUNTRY"))
      ? "GENERAL"
      : queryParam;

  // TODO Aurélien: We need a Tabs component
  return (
    <div className="flex-col flex-fill bg-white overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold pt-16 lg:pt-24 ">
          {t("preferences.info.personal_information")}
        </h1>

        <div className="flex justify-between">
          <div className="flex">
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  currentTab === "GENERAL",
              })}
              to={routes.PERSONAL_INFO_GENERAL}
            >
              {t("preferences.info.personal_information.general")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  currentTab === "PRESCRIPTIONS",
              })}
              to={routes.PERSONAL_INFO_PRESCRIPTIONS}
            >
              {t("preferences.info.personal_information.prescriptions")}
            </Link>
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  currentTab === "APPOINTMENTS",
              })}
              to={routes.PERSONAL_INFO_APPOINTMENTS}
            >
              {t("preferences.info.personal_information.appointments")}
            </Link>
            {hasAccessToGatekeeperFeature("IS_US_COUNTRY") && (
              <Link
                className={classNames("mr-24 hover:cursor-pointer py-16", {
                  "text-primary font-semibold border-b-2 border-primary":
                    currentTab === "MEDICAL-LICENSES",
                })}
                to={routes.PERSONAL_INFO_MEDICAL_LICENSES}
              >
                {t("preferences.info.personal_information.medical_licenses")}
              </Link>
            )}
          </div>
          <div>
            <div className="flex space-x-8 flex-shrink ml-auto py-4">
              {currentTab === "MEDICAL-LICENSES" && (
                <Button
                  label={t("preferences.info.medical_licenses.new.button")}
                  onClick={() => {
                    setNewMedicalLicense(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="flex-col p-16 lg:px-44 max-w-[1080px]">
        {currentTab === "GENERAL" && <PersonalInformationGeneral />}
        {currentTab === "PRESCRIPTIONS" && <PersonalInformationPrescriptions />}
        {currentTab === "APPOINTMENTS" && <PersonalInformationAppointments />}
        {currentTab === "MEDICAL-LICENSES" && (
          <PersonalInformationMedicalLicenses
            newMedicalLicense={newMedicalLicense}
            onHideNewMedicalLicense={() => setNewMedicalLicense(false)}
          />
        )}
      </div>
    </div>
  );
};
