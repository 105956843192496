import { ButtonHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { MenuItemProps } from "components/Menu/MenuItem";
import { PopoverMenu } from "components/Menu/PopoverMenu";
import { PopoverPosition } from "components/Popover/Popover";
import { Spinner } from "components/Spinner/Spinner";

const commonClassName = ({
  enabled,
  bold,
}: {
  enabled: boolean;
  bold: boolean;
}) =>
  classNames(
    "px-10 text-14 text-white",
    enabled ? "bg-primary active:bg-primary/70" : "bg-primary-200",
    bold ? "font-bold" : "font-medium",
  );

export type ButtonWithMenuProps = {
  label?: string | ReactNode;
  loading?: boolean;
  bold?: boolean;
  menuItems: MenuItemProps[];
  menuFooter?: JSX.Element | null;
  menuDisabled?: boolean;
  menuPosition?: PopoverPosition | PopoverPosition[];
  wrapperClassName?: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">;

export const ButtonWithMenu = ({
  label,
  loading,
  disabled,
  bold,
  menuItems,
  menuFooter,
  menuDisabled,
  menuPosition,
  wrapperClassName,
  ...rest
}: ButtonWithMenuProps) => (
  <div className={classNames("flex", wrapperClassName)}>
    <button
      className={classNames(
        commonClassName({ enabled: !disabled && !loading, bold: !!bold }),
        "relative rounded-l border-r border-white",
      )}
      disabled={!!disabled || loading}
      {...rest}
    >
      {loading && (
        <Spinner small className="absolute inset-0 flex-center text-primary" />
      )}
      {label}
    </button>
    <PopoverMenu
      items={menuItems}
      footer={menuFooter}
      position={menuPosition ?? "top"}
    >
      {({ opened, setTarget }) => (
        <ClickableIcon
          name="chevron"
          className={classNames(
            commonClassName({ enabled: !menuDisabled, bold: !!bold }),
            "rounded-r",
          )}
          style={{ opacity: 100 }}
          disabled={menuDisabled}
          size={16}
          rotate={opened ? -90 : 90}
          onClick={setTarget}
        />
      )}
    </PopoverMenu>
  </div>
);
