import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";

export const useServiceWorker = () => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) return;
    // Not available when served over non-localhost http (i.e. 192.XXX.X.X)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    navigator.serviceWorker?.register("/service-worker.js");
  }, []);
};
