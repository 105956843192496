import { useCallback, useRef } from "react";

export const useMessagesContainerUtils = () => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const instantScroll = useCallback((top?: number) => {
    const node = messagesContainerRef.current;
    if (node) {
      const maxScroll = node.scrollHeight;
      node.scrollTo({ left: 0, top: top ?? maxScroll, behavior: "auto" });
    }
  }, []);

  const getScrollState = useCallback(() => {
    const node = messagesContainerRef.current;
    if (!node) return;
    const distanceFromTop = node.scrollTop;
    const boxHeight = node.offsetHeight;
    const maxScroll = node.scrollHeight;
    const distanceFromBottom = maxScroll - distanceFromTop - boxHeight;
    return { distanceFromBottom, distanceFromTop };
  }, []);

  return {
    messagesContainerRef,
    instantScroll,
    getScrollState,
    scrollIfCloseToBottom: useCallback(() => {
      const scrollState = getScrollState();
      if (!scrollState || scrollState.distanceFromBottom > 150) return;
      instantScroll();
    }, [instantScroll, getScrollState]),
  };
};
