import { useState } from "react";

import { RenameExperienceModal } from "components/ConversationHeader/RenameExperienceModal";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { BackMobile } from "components/Mobile/BackMobile";
import { ShareButton } from "components/Share/ShareButton";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { ExperienceSummaryFragment } from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { defaultOrigin } from "utils/environment";

export const Header = ({
  experience,
}: {
  experience: ExperienceSummaryFragment;
}) => {
  const t = useTranslation();
  const [showConversationTitleModal, setShowConversationTitleModal] =
    useState(false);
  return (
    <>
      {showConversationTitleModal && (
        <RenameExperienceModal
          experience={experience}
          close={() => setShowConversationTitleModal(false)}
          withSubtitle
        />
      )}
      <header className="w-full py-10 px-20 bg-white border-b pr-12 sm:pl-10 flex items-center overflow-hidden">
        <BackMobile fallback={routes.GROUP_CHAT_BASE} className="mr-8" />
        <div className="flex-fill flex items-center">
          <div className="flex-fill">
            <div className="flex items-center text-14 text-primary-dark font-medium group">
              {experience.title}
              <ClickableIcon
                name="edit"
                onClick={() => setShowConversationTitleModal(true)}
                className="invisible group-hover:visible"
              />
            </div>
            <div className="text-12 truncate">
              {experience.subtitle ? `${experience.subtitle} - ` : ""}
              {experience.numberOfParticipants} {t("group_chat.participants")}
            </div>
          </div>
          <TooltipWrapper
            label={t(
              "inboxes.qa_experience.qa_experience_header.qa_experience_header.share",
            )}
          >
            <ShareButton
              position={["bottom-right", "bottom", "bottom-left"]}
              messageInput={{
                content: {
                  text: `Hello, ${t(
                    "qa_experience_header.can_you_look_at_this_conversation_please",
                  )}: ${defaultOrigin}${routes.GROUP_CHAT_BASE}/${
                    experience.uuid
                  }`,
                },
              }}
              icon="reply"
              size={24}
              className="mirrored px-4 py-2 rounded hover:bg-grey-100"
              successNotification={t(
                "inboxes.qa_experience.qa_experience_header.qa_experience_header.conversation_shared",
              )}
            />
          </TooltipWrapper>
        </div>
      </header>
    </>
  );
};
