import { ReactNode } from "react";
import classNames from "classnames";

export const PatientTimelineItemContentWithPadding = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={classNames(
      "pt-20 pb-28",

      // Left of the card contents must be aligned with the title, so we need to account for the padding,
      // the 32 pixels of the card icon, and the 16px of spacing between the icon and the title.
      "px-[calc(theme(spacing.timeline-margin)-theme(spacing.timeline-opened-item-margin)-1px+32px+16px)]",
      className,
    )}
  >
    {children}
  </div>
);
