import { useLayoutEffect, useRef, useState } from "react";

import { ExperienceTagFragment } from "generated/provider";

export const useTagsListOverflowing = (tags: ExperienceTagFragment[]) => {
  const tagsListRef = useRef<HTMLDivElement>(null);
  const [isTagsListOverflowing, setIsTagsListOverflowing] = useState(false);
  useLayoutEffect(() => {
    if (!tagsListRef.current) return;
    setIsTagsListOverflowing(
      tagsListRef.current.scrollWidth > tagsListRef.current.clientWidth,
    );
  }, [tags]);

  return {
    tagsListRef,
    tagOverflowClassName: isTagsListOverflowing ? "tags-fade" : "",
  };
};
