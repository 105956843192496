import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { NormalizeICD10 } from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";
import { useTranslation } from "i18n";

gql`
  query NormalizeICD10($text: String!, $language: String) {
    normalizeICD10(query: { text: $text, language: $language }) {
      icd10Concepts {
        code
        description
        score
      }
    }
  }
`;

type ICD10NormalizationFormValues = {
  text: string;
  language: "fr" | "en";
};

export const ICD10Normalization = () => {
  const t = useTranslation();

  const [normalizeICD10, { loading, data }] = useLazyQuery(NormalizeICD10);
  return (
    <div className="flex-fill bg-blue-overlay overflow-auto">
      <div className="flex-col p-18 space-y-16 lg:items-center">
        <span className="text-primary-dark title">ICD-10 Normalization</span>
        <Form<ICD10NormalizationFormValues>
          className="bg-white p-16 rounded-item space-y-16 lg:w-1/2"
          initialValues={{ text: "", language: "fr" }}
          onSubmit={(values) => {
            normalizeICD10(values);
            return Promise.resolve();
          }}
        >
          <FormTextArea
            minRows={5}
            maxRows={10}
            name="text"
            placeholder="Tapez un message"
            disabled={loading}
          />
          <FormSelect
            label={t("labs.icd10_normalization.icd10_normalization.language")}
            name="language"
            options={["fr", "en"]}
            getOptionLabel={(languageCode) =>
              languageCode === "fr" ? "Français" : "English"
            }
          />
          <Submit loading={loading} label="Normaliser" />
          <div className="font-semibold mt-24 uppercase">Résultats:</div>
          {data?.icd10Concepts.isEmpty() ? (
            <div className="flex justify-center">
              <span>Aucun résultat</span>
            </div>
          ) : (
            <table className="w-full border">
              <tr className="border">
                {["Code", "Description", "Confidence"].map((it, index) => (
                  <th
                    className="border text-primary-dark font-medium p-6 text-left"
                    key={index}
                  >
                    {it}
                  </th>
                ))}
              </tr>
              {data?.icd10Concepts.map((icd10Concept, icd10ConceptIndex) => (
                <tr className="border" key={icd10ConceptIndex}>
                  {[
                    icd10Concept.code,
                    icd10Concept.description,
                    icd10Concept.score.toFixed(3),
                  ].map((it, index) => (
                    <td className="border p-6 text-left text-body" key={index}>
                      {it}
                    </td>
                  ))}
                </tr>
              ))}
            </table>
          )}
        </Form>
      </div>
    </div>
  );
};
