import gql from "graphql-tag";

import { FormCptCodePicker } from "components/Form/MedicalCodes/FormCptCodePicker";
import { IcdCodePicker } from "components/Form/MedicalCodes/IcdCodePicker";
import { Spinner } from "components/Spinner/Spinner";
import {
  CreateAperoBill,
  FinalizeAperoBill,
  GetAperoBill,
  UpdateAperoBill,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { CptCode, ICD10Code } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { notifier } from "utils/notifier";

import { ExistingItemPillButton } from "../ItemPillButton";
import { PatientTimelineItem } from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";
import { usePatientTimelineLocalItemState } from "../PatientTimelineLocalStateContext";

gql`
  query GetAperoBill($uuid: UUID!) {
    aperoBill(uuid: $uuid) {
      aperoBill {
        ...AperoBillWithInvoice
      }
    }
  }

  mutation CreateAperoBill($appointmentUuid: UUID!, $input: AperoBillInput!) {
    createAperoBill(appointmentUuid: $appointmentUuid, input: $input) {
      aperoBill {
        ...AperoBillWithInvoice
        appointment {
          uuid
          aperoBills {
            ...AperoBillWithInvoice
          }
        }
      }
    }
  }

  mutation UpdateAperoBill($billUuid: UUID!, $input: AperoBillInput!) {
    updateAperoBill(billUuid: $billUuid, input: $input) {
      aperoBill {
        ...AperoBillWithInvoice
      }
    }
  }

  mutation FinalizeAperoBill($billUuid: UUID!) {
    finalizeAperoBill(billUuid: $billUuid) {
      aperoBill {
        ...AperoBillWithInvoice
      }
    }
  }
`;

type FormData = {
  title: string;
  icd10Codes: ICD10Code[];
  cptCodes: {
    code: CptCode;
    linkedICD10Codes: ICD10Code[];
  }[];
};

type NewFormData = {
  title: string;
  cptCodes: {
    code: CptCode;
    linkedICD10Codes: ICD10Code[];
  }[];
};

type SubmitContext = {
  shouldFinalize: boolean;
};

export const AperoBillItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"AperoBill">;
}) => {
  const t = useTranslation();
  const [updateAperoBill] = useMutation(UpdateAperoBill);
  const [finalizeAperoBill] = useMutation(FinalizeAperoBill);

  const billTitle =
    item.optionalTitle?.trimOrNull() ?? t("patient_view.default_title.bill");

  // Lazy-load the full bill to mitigate the latency of the Apero API.
  const { data } = useQuery(GetAperoBill, { variables: { uuid: item.uuid } });
  if (!data) {
    return (
      <PatientTimelineItem item={item}>
        <PatientTimelineItemContentWithPadding>
          <Spinner className="my-10" />
        </PatientTimelineItemContentWithPadding>
      </PatientTimelineItem>
    );
  }

  const bill = data.aperoBill;
  const isPublished =
    !!bill.aperoInvoice && bill.aperoInvoice.status !== "Draft";

  const initialValues: FormData = {
    title: billTitle,
    icd10Codes: bill.aperoInvoice?.diagnosisCodes ?? [],
    cptCodes:
      bill.aperoInvoice?.lineItems.map(({ code, diagnosisCodes }) => ({
        code,
        linkedICD10Codes: diagnosisCodes,
      })) ?? [],
  };

  return (
    <PatientTimelineItem<"AperoBill", FormData, FormData, SubmitContext>
      item={item}
      actionButton={
        isPublished
          ? {
              label: t("patient_view.bill.see_in_apero"),
              leftIcon: "shareLight",
              secondary: true,
              mode: "HOVER",
              onClick: () => {
                if (!bill.aperoInvoice) return;
                window.open(invoiceUrl(bill.aperoInvoice));
              },
            }
          : undefined
      }
      disabled={isPublished}
      initialValues={initialValues}
      validationSchema={{
        title: "required",
      }}
      validate={({ icd10Codes }) => ({
        ...(!icd10Codes.equals(initialValues.icd10Codes) && {
          icd10Codes: t("patient_view.bill.icd10_cannot_be_changed"),
        }),
      })}
      footerChildren={<ExistingItemPillButton item={bill} />}
      onSubmit={async ({ title, icd10Codes, cptCodes }, _, context) => {
        const updateData = await updateAperoBill({
          billUuid: item.uuid,
          input: { title, icd10Codes, cptCodes },
        });
        if (!updateData) return;

        if (context?.shouldFinalize) {
          const finalizeData = await finalizeAperoBill({ billUuid: item.uuid });
          if (!finalizeData) return;

          notifier.success(t("patient_view.bill.finalize_success"));
        } else {
          notifier.success(t("patient_view.bill.update_success"));
        }
      }}
      additionalSubmitButtons={[
        {
          text: t("patient_view.bill.save_and_finalize"),
          context: { shouldFinalize: true },
        },
      ]}
    >
      <PatientTimelineItemContentWithPadding>
        <IcdCodePicker
          value={initialValues.icd10Codes}
          onChange={() => void 0}
          disabled
        />
        <FormCptCodePicker
          name="cptCodes"
          icd10Codes={initialValues.icd10Codes}
        />
      </PatientTimelineItemContentWithPadding>
    </PatientTimelineItem>
  );
};

export const NewAperoBillItem = ({
  temporaryUuid,
  appointmentUuid,
  icd10Codes,
}: {
  temporaryUuid: UUID;
  appointmentUuid: UUID;
  icd10Codes: ICD10Code[];
}) => {
  const t = useTranslation();
  const [createAperoBill] = useMutation(CreateAperoBill);
  const [finalizeAperoBill] = useMutation(FinalizeAperoBill);
  const { expandOtherItem } = usePatientTimelineLocalItemState(temporaryUuid);

  return (
    <PatientTimelineItem<"AperoBill", NewFormData, NewFormData, SubmitContext>
      item={{ temporaryUuid, __typename: "AperoBill" }}
      initialValues={{
        title: t("patient_view.default_title.bill"),
        cptCodes: [],
      }}
      validationSchema={{
        title: "required",
      }}
      onSubmit={async ({ title, cptCodes }, _, context) => {
        const createData = await createAperoBill({
          appointmentUuid,
          input: { title, icd10Codes, cptCodes },
        });
        if (!createData) return;
        expandOtherItem(createData.aperoBill.uuid);

        if (context?.shouldFinalize) {
          const finalizeData = await finalizeAperoBill({
            billUuid: createData.aperoBill.uuid,
          });
          if (!finalizeData) return;

          notifier.success(t("patient_view.bill.finalize_success"));
        } else {
          notifier.success(t("patient_view.bill.create_success"));
        }
      }}
      additionalSubmitButtons={[
        {
          text: t("patient_view.bill.save_and_finalize"),
          context: { shouldFinalize: true },
        },
      ]}
    >
      <PatientTimelineItemContentWithPadding>
        <IcdCodePicker value={icd10Codes} onChange={() => void 0} disabled />
        <FormCptCodePicker name="cptCodes" icd10Codes={icd10Codes} />
      </PatientTimelineItemContentWithPadding>
    </PatientTimelineItem>
  );
};

const invoiceUrl = (invoice: { __typename: "AperoInvoice"; id: string }) =>
  `https://app.aperohealth.com/dash/payments/invoices/${invoice.id}`;
