import { Link } from "react-router-dom";

import { Avatar } from "components/Avatar/Avatar";
import { Icon } from "components/Icon/Icon";
import { PatientViewTagPill } from "components/Patient/PatientViewTagPill";
import { appearanceForExistingItem } from "components/Patient/Timeline/Item/types";
import { RelativeTime } from "components/RelativeTime/RelativeTime";
import { useDoctor } from "contexts/User/UserContext";
import { SexKnownValues } from "generated/provider";
import { useTranslation } from "i18n";
import { PatientTimelineValidItemFragment } from "types/patient-timeline";
import { getPatientViewRoute, getQAInboxRoute } from "utils";
import { displayAge, displayPatient, displaySex } from "utils/display";
import { isKnownValue } from "utils/enum";

export const PatientsInboxItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragment;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const { patient, time } = item.patientTimelineItemMetadata;
  const {
    iconName,
    iconColor,
    iconBackgroundColor,
    title,
    tag,
    timePrefix,
    statusLabel,
  } = appearanceForExistingItem(t, item);

  return (
    <Link
      className="flex w-full bg-white px-16 lg:px-44 py-12 hover:bg-grey-100 hover:cursor-pointer"
      to={
        item.__typename === "Experience"
          ? getQAInboxRoute(item, user.uuid)
          : getPatientViewRoute(patient, [item])
      }
    >
      <div className="flex w-1/2 items-center space-x-16">
        <div
          className="rounded-[11px] w-32 h-32 flex-center"
          style={{ color: iconColor, backgroundColor: iconBackgroundColor }}
        >
          <Icon size={20} name={iconName} />
        </div>
        <div className="flex-col flex-fill leading-[20px]">
          <div className="flex items-center">
            <h4 className="text-grey-400 truncate flex-shrink">{title}</h4>
            {tag && <PatientViewTagPill tag={tag} className="mr-8" />}
          </div>
          <time className="text-12 truncate" dateTime={time.asString()}>
            {timePrefix !== undefined ? `${timePrefix} ` : ""}
            <RelativeTime
              time={time}
              format="timeOrDateWithTime"
              withDatePrefix={timePrefix !== undefined}
              upperFirst={timePrefix === undefined}
            />
            {statusLabel !== undefined ? `${statusLabel} ・ ` : ""}
          </time>
        </div>
      </div>

      <div className="flex w-1/2 items-center space-x-12">
        <div>
          <Avatar user={patient} size={26} />
        </div>
        <div className="flex-col flex-fill leading-[20px]">
          <div className="text-primary-dark">{displayPatient(patient)}</div>
          <div className="text-12">
            {[
              ...(patient.sex && isKnownValue(patient.sex, SexKnownValues)
                ? [displaySex(patient.sex)]
                : []),
              ...(patient.birthDate ? [displayAge(patient.birthDate)] : []),
            ].join(", ")}
          </div>
        </div>
        <div>
          <Icon name="chevron" size={24} />
        </div>
      </div>
    </Link>
  );
};
