import classNames from "classnames";

import { createAtom } from "atoms/createAtom";
import { AvatarDuo } from "components/Avatar/AvatarDuo";
import { NextPageButton } from "components/Button/NextPageButton";
import { DoctorRow } from "components/Doctor/DoctorRow";
import { Switch } from "components/Form/Switch/Switch";
import { Icon } from "components/Icon/Icon";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Query } from "components/Query/Query";
import { MessageInput, useMessages } from "contexts/Messages/MessagesContext";
import { useDoctor } from "contexts/User/UserContext";
import { AllDoctors, GetDoctorsConversations } from "generated/provider";
import { useFocusAfterAnimation } from "hooks";
import { useSearch } from "hooks/useDebounce";
import { useShareContent } from "hooks/useShareContent";
import { useTranslation } from "i18n";
import { doctorsToChatWithFilter } from "utils/apollo";
import { displayExperienceTitle } from "utils/display";
import { notifier } from "utils/notifier";

const useShareButton = createAtom(
  { showGroupConversations: false },
  ({ setter }) => ({
    setShowGroupConversations: setter("showGroupConversations"),
  }),
);

export type ShareContentProps = {
  messageInput: MessageInput;
  successNotification: string;
  onSelected: () => void;
  focusAfterAnimation?: boolean;
};

export const ShareContent = ({
  messageInput,
  successNotification,
  onSelected,
  focusAfterAnimation,
}: ShareContentProps) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const inputProps = useSearch();
  const { sendMessage } = useMessages();
  const shareContent = useShareContent();
  const { showGroupConversations, setShowGroupConversations } =
    useShareButton();
  const inputRef = useFocusAfterAnimation();

  const rowClassName = "px-10 py-4 hover:bg-grey-100";

  return (
    <>
      <div className="mt-10 border-b px-10 pb-6 flex items-center ">
        <div>{t("share.share_content.to")} :</div>
        <input
          ref={focusAfterAnimation ? inputRef : undefined}
          autoFocus={!focusAfterAnimation}
          className="ml-6 flex-fill"
          {...inputProps}
        />
        <Icon name="profile" className="mr-12" size={12} />
        <Switch
          checked={showGroupConversations}
          onChange={setShowGroupConversations}
        />
        <Icon name="people" />
      </div>
      <div className="flex-fill flex-col overflow-auto py-8">
        {showGroupConversations ? (
          <PaginatedQuery
            query={GetDoctorsConversations}
            selector={(d) => d.experiences}
          >
            {({ experiences }, utils) => (
              <>
                {experiences.data
                  .filterNotNull()
                  .filter((c) => c.allDoctors.length > 2)
                  .filter((c) =>
                    displayExperienceTitle(c, user.uuid).fuzzyMatch(
                      inputProps.value,
                    ),
                  )
                  .map((c) => (
                    <button
                      key={c.uuid}
                      className={classNames(rowClassName, "flex items-center")}
                      onClick={() => {
                        sendMessage(c.uuid, messageInput);
                        notifier.success(successNotification);
                        onSelected();
                      }}
                    >
                      <AvatarDuo doctors={c.allDoctors} omitUser />
                      <div className="ml-12 text-primary-dark">
                        {displayExperienceTitle(c, user.uuid)}
                      </div>
                    </button>
                  ))}
                <div className="flex-center">
                  <NextPageButton {...utils} />
                </div>
              </>
            )}
          </PaginatedQuery>
        ) : (
          <Query
            query={AllDoctors}
            variables={{ filter: doctorsToChatWithFilter }}
          >
            {({ doctors }) => (
              <>
                {doctors
                  .filter(
                    (d) =>
                      `${d.firstName} ${d.lastName}`.fuzzyMatch(
                        inputProps.value,
                      ) && d.uuid !== user.uuid,
                  )
                  .map((d) => (
                    <button
                      key={d.uuid}
                      className={rowClassName}
                      onClick={() => {
                        shareContent({
                          doctorUuid: d.uuid,
                          successNotification,
                          messageInput,
                        });
                        onSelected();
                      }}
                    >
                      <DoctorRow doctor={d} />
                    </button>
                  ))}
              </>
            )}
          </Query>
        )}
      </div>
    </>
  );
};
