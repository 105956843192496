import { usePatient } from "contexts/PatientContext/PatientContext";

import { useUserForPrescriptions } from "./useUserForPrescriptions";

export type CanSendPrescriptionErrors = {
  hasDoctorMissingInformation: boolean;
  patientMissingInformation: {
    birthDate: boolean;
    firstName: boolean;
    lastName: boolean;
  } | null;
};

export const useCanSendPrescription = (): CanSendPrescriptionErrors | null => {
  const user = useUserForPrescriptions();
  const { patient } = usePatient();

  const patientBirthDateIsMissing = !patient.birthDate;
  const patientFirstNameIsMissing = patient.firstName.isBlank();
  const patientLastNameIsMissing = patient.lastName.isBlank();
  const hasPatientMissingInformation =
    patientBirthDateIsMissing ||
    patientFirstNameIsMissing ||
    patientLastNameIsMissing;
  const hasDoctorMissingInformation = !user.hasAllRequiredFields;
  const hasMissingData =
    hasPatientMissingInformation || hasDoctorMissingInformation;

  if (!hasMissingData) return null;

  return {
    hasDoctorMissingInformation,
    patientMissingInformation: hasPatientMissingInformation
      ? {
          birthDate: patientBirthDateIsMissing,
          firstName: patientFirstNameIsMissing,
          lastName: patientLastNameIsMissing,
        }
      : null,
  };
};
