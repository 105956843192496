import { useState } from "react";

import { Button } from "components/Button/Button";
import { DraggableWindow } from "components/DraggableWindow/DraggableWindow";
import { MentionEditor } from "components/Form/Editor/MentionEditor/MentionEditor";
import { useMentionEditorState } from "components/Form/Editor/MentionEditor/useMentionEditorState";
import { MentionsOptionsProps } from "components/Form/Editor/MentionEditor/utils";
import { Input } from "components/Form/Input/Input";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import { usePDFPreview } from "components/PDFPreview/usePDFPreview";
import { DrugSearch, DrugSearchData } from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import { useDebounce } from "hooks/useDebounce";
import { AllowedMentionList, TextWithMentions } from "types";
import { run } from "utils";

import { FakeFRPrescriptionPreview } from "./FakePrescription";

const prescriptionAllowedMentions: AllowedMentionList = {
  PatientNote: false,
  MentionedPatientDocument: false,
  Condition: false,
  PatientAllergy: false,
  Experience: false,
  HealthDataPoint: false,
  MentionedDoctor: false,
  MentionedPatient: false,
  Symptom: false,
  FutureValue: false,
  VaccinationStatement: false,
  MedicationStatement: false,
  Procedure: false,
  DeletedVaccination: false,
  DeletedAllergy: false,
  DeletedProcedure: false,
  DeletedDatapoint: false,
  DeletedMedication: false,
  DeletedSymptom: false,
  DeletedCondition: false,
  PubliclyListedDrug: true,
};

export const PrescriptionModal = ({
  onHide,
  initialText,
}: {
  onHide: () => void;
  initialText: string;
}) => {
  const mentionState = useMentionEditorState({
    initialValue: { text: initialText, mentions: [] },
    allowedMentions: prescriptionAllowedMentions,
  });
  const [currentContent, setCurrentContent] = useState<TextWithMentions>({
    text: "",
    mentions: [],
  });
  const searchInput = useDebounce(
    mentionState.suggestionsInput?.currentInput,
    300,
  );
  const [previewMode, setPreviewMode] = useState(false);
  const { previewRef } = usePDFPreview();

  const { data } = useQuery(
    DrugSearch,
    (searchInput?.trim().length ?? 0) < 3
      ? { skip: true }
      : { variables: { filter: { freeTextSearch: searchInput } } },
  );

  const drugsOptions: MentionsOptionsProps<
    DrugSearchData["drugs"][number]
  > | null = data
    ? {
        data: data.drugs,
        emptyLabel: `Pas de résultats pour "${searchInput ?? ""}"`,
        getOptionLabel: (d) => d.name,
        skipFuzzyMatchFiltering: true,
        onSelected: (drug) => {
          mentionState.replaceSuggestionsInput({ data: drug });
        },
      }
    : null;

  return (
    <DraggableWindow
      initialHeight={700}
      initialWidth={580}
      minWidth={620}
      minHeight={400}
      maxHeight={800}
      title={run(() => (previewMode ? "Preview" : "Prescription"))}
      leftAction={
        previewMode
          ? {
              icon: "arrow",
              onClick: () => setPreviewMode(false),
            }
          : undefined
      }
      className="z-observation duration-300"
      style={{ transitionProperty: "min-height, max-height" }}
      onHide={onHide}
    >
      {previewMode ? (
        <PDFPreview
          previewRef={previewRef}
          className="mx-auto mb-20"
          documentClassName="flex-col space-y-30"
        >
          <FakeFRPrescriptionPreview content={currentContent} />
        </PDFPreview>
      ) : (
        <div className="h-full w-full flex-col">
          <Input
            name="title"
            placeholder="Titre"
            className="border-0 text-18 font-medium"
          />
          <MentionEditor
            className="mb-20 flex-fill"
            name="Hello"
            state={mentionState}
            onChange={(content) => setCurrentContent(content)}
            suggestions={[
              {
                trigger: { type: "KEYSTROKE", keystroke: "#" },
                options: drugsOptions,
              },
            ]}
          />
          <div className="flex border-t mt-auto mb-12 p-8">
            <ClickableIcon
              name="eye"
              onClick={() => setPreviewMode(!previewMode)}
            />
            <Button
              className="ml-auto"
              style={{ width: 100 }}
              label="Envoyer"
            />
          </div>
        </div>
      )}
    </DraggableWindow>
  );
};
