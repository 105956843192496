import { useRef } from "react";
import classNames from "classnames";

import { InputWithPills } from "components/Form/Input/InputWithPills";
import { OptionsPopover } from "components/Form/Select/OptionsPopover";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TagPill } from "components/Tag/TagPill";
import {
  AllDoctors,
  AllTags,
  DoctorSummaryFragment,
  TagFragment,
} from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import {
  allowParticipantsSearch,
  ExtendedQAInboxType,
} from "hooks/useExtendedQAInbox";
import { useExtendedQASearch } from "hooks/useExtendedQASearch";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";

export const QAInboxSidebarHeader = ({
  type,
}: {
  type: ExtendedQAInboxType;
}) => {
  const t = useTranslation();

  const {
    tags,
    setTags,
    participatingDoctorsUuids,
    setParticipatingDoctorsUuids,
  } = useExtendedQASearch();

  const inputRef = useRef<HTMLInputElement>(null);
  const searchInDoctors = allowParticipantsSearch(type);
  const { data, loading } = useQuery(AllDoctors, {
    skip: !searchInDoctors,
    variables: {
      filter: {
        includeInactiveAccounts: true,
        ...(type === "ASSIGNED"
          ? { isAssignedToExperience: true }
          : { hasParticipatedInQA: true }),
      },
    },
  });
  const { data: allTagsData } = useQuery(AllTags);

  const selectedDoctors =
    data?.doctors.filter((d) => participatingDoctorsUuids.includes(d.uuid)) ??
    [];

  const lastIndex =
    tags.length + (searchInDoctors ? selectedDoctors.length : 0) - 1;
  const innerPills =
    tags.length > 0 || selectedDoctors.length > 0 ? (
      <>
        {tags.map((tag, index) => (
          <TagPill
            key={index}
            tag={tag}
            tagClassName="pl-4"
            style={
              // the margin needed at the bottom of the last pill is the one between the last row of tags and the search input container
              index === lastIndex
                ? { marginTop: 8, marginBottom: 8, marginRight: 4 }
                : { marginTop: 8, marginRight: 4 }
            }
            rightInnerElement={
              <ClickableIcon
                name="close"
                className="px-2"
                size={16}
                onClick={() => setTags(tags.filter((e) => e !== tag))}
              />
            }
          />
        ))}
        {searchInDoctors && (
          <>
            {selectedDoctors.map((doctor, index) => (
              <div
                key={`doctor-${index}`}
                className={classNames(
                  "rounded-full bg-grey-100 h-24 pl-4 pr-2 mr-4 font-medium flex items-center",
                  {
                    "mt-8": index < lastIndex - tags.length,
                    // margin at the bottom of the last pill needed between the last row of tags and the search input container
                    "my-8": index === lastIndex - tags.length,
                  },
                )}
              >
                <span className="pl-4 text-12">
                  {displayDoctor(doctor, "FIRST_NAME")}
                </span>
                <ClickableIcon
                  name="close"
                  className="px-2"
                  size={16}
                  onClick={() => {
                    setParticipatingDoctorsUuids(
                      participatingDoctorsUuids.filter(
                        (uuid) => uuid !== doctor.uuid,
                      ),
                    );
                  }}
                />
              </div>
            ))}
          </>
        )}
      </>
    ) : null;

  return (
    <section role="search" className="w-full p-16 flex items-center">
      <OptionsPopover<TagFragment | DoctorSummaryFragment, true>
        options={[
          ...(allTagsData?.tags ?? []),
          ...(searchInDoctors ? data?.doctors ?? [] : []),
        ]}
        value={[...tags, ...selectedDoctors]}
        targetRef={inputRef}
        onSelect={(options) => {
          setTags(
            options.mapNotNull((o) => (o.__typename === "TagType" ? o : null)),
          );
          setParticipatingDoctorsUuids(
            options.mapNotNull((o) =>
              o.__typename === "TagType" ? null : o.uuid,
            ),
          );
          inputRef.current?.focus();
        }}
        getOptionId={(option) => option.uuid}
        getOptionLabel={(option) =>
          option.__typename === "TagType" ? option.name : displayDoctor(option)
        }
        loading={searchInDoctors ? loading : false}
      >
        {({ search, onChange, onClick, onBlur, onKeyDown }) => (
          <InputWithPills
            inputRef={inputRef}
            name="filter"
            autoComplete="off"
            value={search ?? ""}
            thinGray
            onChange={onChange}
            onClick={onClick}
            onBlur={onBlur}
            onKeyDown={(e) => {
              if ((!search || search === "") && e.key === "Backspace") {
                // remove the last element on backspace; first doctors, then tags
                if (selectedDoctors.length > 0) {
                  setParticipatingDoctorsUuids(
                    participatingDoctorsUuids.slice(0, -1),
                  );
                } else if (tags.length > 0) {
                  setTags(tags.slice(0, -1));
                }
              } else {
                onKeyDown(e);
              }
            }}
            placeholder={
              // no placeholder if at least one tag or doctor is already selected
              tags.isEmpty() && selectedDoctors.isEmpty()
                ? searchInDoctors
                  ? t(
                      "inboxes.qa_inbox.qa_inbox_sidebar_header.tags_or_practitioners",
                    )
                  : t("inboxes.qa_inbox.qa_inbox_sidebar_header.filter_by_tags")
                : ""
            }
            wrapperClassName="flex-fill"
            leftInnerElement="search"
            innerPillsElement={innerPills}
          />
        )}
      </OptionsPopover>
    </section>
  );
};
