import { useEffect } from "react";
import gql from "graphql-tag";

import {
  useFetchCptKnowledgeBase,
  useFetchICD10CMKnowledgeBase,
  useFetchICD10WHOKnowledgeBase,
} from "atoms/medicalCodes/useMedicalCodes";
import { SidebarRoutes } from "components/Routes/SidebarRoutes";
import { AddTemplateWindow } from "components/TemplateForms/AddTemplateWindow";
import { LivekitRoomProvider } from "contexts/LivekitRoom/LivekitRoomProvider";
import { MessagesProvider } from "contexts/Messages/MessagesProvider";
import { useDoctor } from "contexts/User/UserContext";
import {
  UpdateDoctorTimezone,
  UpdateOrganizationTimezone,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useIsDesktop } from "hooks/useMediaQuery";
import { routes } from "routes";
import {
  AppointmentReminders,
  CallPopup,
  MainSidebar,
  MediaWindows,
  useAppEvents,
  useConfigureSentryScope,
  useFetchAutocompleteData,
  useNotifications,
  useServiceWorker,
  useSetConnected,
} from "singletons";
import { OnboardingModal } from "singletons/OnboardingModal/OnboardingModal";
import { useNativeBadge } from "singletons/useNativeBadge";
import { filterAvailableSidebarItems, SidebarItem } from "types";
import { usePageTitleAndFavicon } from "utils/title-and-favicon";

import { Help } from "./Help/Help";
import { MedicalTeam } from "./MedicalTeam/MedicalTeam";
import { ACCOUNT_MENU_ITEMS, MAIN_SIDEBAR_ITEMS } from "./pages";
import { PatientsPage } from "./Patients/PatientsPage";

gql`
  mutation UpdateOrganizationTimezone($timezone: TimeZone!) {
    updateOrganizationSettings(input: { timezone: $timezone }) {
      doctor {
        subOrganization {
          ...SubOrganizationSummary
        }
      }
    }
  }

  mutation UpdateDoctorTimezone($timezone: TimeZone!) {
    updateDoctorProfile(payload: { timezone: $timezone }) {
      doctor {
        ...DoctorSummary
        ...UserPersonalInformation
      }
    }
  }
`;

// Was asked, among others, by Medcase
const hideNavbarViaUrlForIframe = new URLSearchParams(
  window.location.search,
).has("hide-navbar");

export const DoctorApp = () => (
  <MessagesProvider>
    <LivekitRoomProvider>
      {/* TODO(@liautaud): This wouldn't be needed if we didn't use top-level
           singletons hooks that depend a bunch of contexts. */}
      <DoctorAppContent />
    </LivekitRoomProvider>
  </MessagesProvider>
);

const DoctorAppContent = () => {
  const userContext = useDoctor();
  const user = userContext.user;
  const isDesktop = useIsDesktop();
  const [updateOrganizationTimezone] = useMutation(UpdateOrganizationTimezone);
  const [updateDoctorTimezone] = useMutation(UpdateDoctorTimezone);

  useConfigureSentryScope();
  useAppEvents();
  useSetConnected();
  useServiceWorker();
  useNotifications();
  useNativeBadge();
  useFetchAutocompleteData();
  useFetchICD10CMKnowledgeBase(
    userContext.hasAccessToGatekeeperFeature("NOTE_NORMALIZATION"),
  );
  useFetchICD10WHOKnowledgeBase();
  useFetchCptKnowledgeBase();

  useEffect(() => {
    // Update organization's timezone on login of the owner doctor
    // if the organization's timezone is not already set
    // TODO (marianne) remove this function and make organization.timezone
    // TODO (marianne) non-nullable after 3-4 months
    if (
      userContext.hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS") &&
      user.subOrganization.explicitTimezone === null
    ) {
      updateOrganizationTimezone({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
  }, [
    updateOrganizationTimezone,
    userContext,
    user.subOrganization.explicitTimezone,
  ]);

  useEffect(() => {
    // Update doctor's timezone on login of the owner doctor
    // if the doctor's timezone is not already set
    // TODO (marianne) remove this function and make organization.timezone
    // TODO (marianne) non-nullable after 3-4 months
    if (user.explicitTimezone === null) {
      updateDoctorTimezone({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
  }, [updateDoctorTimezone, user.explicitTimezone]);

  // Dynamically update the title and favicon.
  usePageTitleAndFavicon(user.subOrganization);

  const availableMainSidebarItems = filterAvailableSidebarItems(
    MAIN_SIDEBAR_ITEMS,
    userContext,
  );

  const availableAccountMenuItems = filterAvailableSidebarItems(
    ACCOUNT_MENU_ITEMS,
    userContext,
  );

  const hideNavbar =
    hideNavbarViaUrlForIframe ||
    // Make medical conversation upload interface simple on mobile (#22756)
    (userContext.hasAccessToGatekeeperFeature("RECORDED_CONVERSATIONS") &&
      !isDesktop);

  return (
    <>
      <CallPopup />
      {userContext.hasPermission("VIEW_APPOINTMENTS") && isDesktop && (
        <AppointmentReminders />
      )}
      <AddTemplateWindow />
      <MediaWindows />
      {userContext.showOnboardingModal && (
        <OnboardingModal isAdmin={userContext.hasRole("NABLA_ADMINISTRATOR")} />
      )}

      <div className="flex-col flex-fill lg:flex-row">
        {!hideNavbar && (
          <MainSidebar
            mainSidebarItems={availableMainSidebarItems}
            accountMenuItems={availableAccountMenuItems}
          />
        )}
        <SidebarRoutes
          items={(availableMainSidebarItems as SidebarItem[]).concat(
            availableAccountMenuItems,
          )}
          withRedirect
          otherRoutes={[
            // Hidden routes
            {
              to: routes.HELP,
              Component: Help,
            },
            {
              to: routes.MEDICAL_TEAM,
              Component: MedicalTeam,
              hasAccess: ({ hasPermission }) => hasPermission("VIEW_DOCTORS"),
            },
            {
              to: routes.PATIENT_LIST,
              Component: PatientsPage,
              hasAccess: ({ hasPermission }) => hasPermission("VIEW_PATIENTS"),
            },
          ]}
        />
      </div>
    </>
  );
};
