import gql from "graphql-tag";

import { FormDateInput } from "components/Form/DatePicker/FormDateInput";
import { FormInput } from "components/Form/Input/FormInput";
import { FormPhoneInput } from "components/Form/Input/FormPhoneInput";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormModal } from "components/Modal/FormModal";
import { useDoctor } from "contexts/User/UserContext";
import {
  CreatePatient,
  PatientManagementPatientFragment,
  PatientManagementSearch,
  Sex,
  SexKnownValues,
  UpdatePatientAdministrativeData,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { parseLocalDate } from "utils/date";
import { notifier } from "utils/notifier";

gql`
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(createPatientInput: $input) {
      patient {
        ...PatientManagementPatient
      }
    }
  }
`;

type FormValues = {
  username: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone: string | undefined;
  email: string;
  healthcareSystemIdentifier: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
  sex: Sex | undefined;
};

export const PatientComposer = ({
  patient,
  onHide,
}: {
  patient?: PatientManagementPatientFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const [updatePatient] = useMutation(UpdatePatientAdministrativeData, {
    onSuccess: () => {
      notifier.success(t("patients.patient_composer.patient_updated"));
      onHide();
    },
  });

  const [createPatient] = useMutation(CreatePatient, {
    onSuccess: (output, client) => {
      client.update({
        query: PatientManagementSearch,
        variables: {
          search: undefined,
          withExperiences: false,
          withAppointments: false,
        },
        skip: ({ patients }) =>
          patients.data.some((p) => p.uuid === output.patient.uuid),
        write: (draft) => {
          draft.patients.data.push({
            ...output.patient,
            qaExperiences: { __typename: "ExperienceSearchData", data: [] },
            upcomingAppointments: [],
            pastAppointments: [],
          });
        },
      });
      notifier.success(t("patients.patient_composer.patient_created"));
      onHide();
    },
  });

  return (
    <FormModal<FormValues>
      title={
        patient
          ? t("patients.patient_composer.edit_a_patient")
          : t("patients.patient_composer.add_a_patient")
      }
      submitLabel={
        patient
          ? t("patients.patient_composer.save")
          : t("patients.patient_composer.create")
      }
      onHide={onHide}
      className="flex-col w-full"
      initialValues={{
        firstName: patient?.firstName ?? "",
        lastName: patient?.lastName ?? "",
        username: patient?.username ?? "",
        phone: patient?.phoneV2 ?? "",
        email: patient?.email ?? "",
        dateOfBirth: patient?.birthDate?.formatDateInLocale() ?? "",
        address: patient?.address ?? "",
        postcode: patient?.postcode ?? "",
        city: patient?.city ?? "",
        country: patient?.country ?? "",
        healthcareSystemIdentifier: patient?.healthcareSystemIdentifier ?? "",
        sex: patient?.sex ?? undefined,
      }}
      onSubmit={(values) =>
        patient
          ? updatePatient({
              input: {
                patientUuid: patient.uuid,
                firstName: values.firstName.trimOrNull(),
                lastName: values.lastName.trimOrNull(),
                phone: values.phone?.trimOrNull(),
                email: values.email.trimOrNull(),
                address: values.address.trimOrNull(),
                postcode: values.postcode.trimOrNull(),
                city: values.city.trimOrNull(),
                country: values.country.trimOrNull(),
                healthcareSystemIdentifier:
                  values.healthcareSystemIdentifier.trimOrNull(),
                sex: values.sex,
                birthDate: values.dateOfBirth
                  ? parseLocalDate(values.dateOfBirth)
                  : null,
              },
            })
          : createPatient({
              input: {
                username: values.username.trimOrNull(),
                phone: values.phone?.trimOrNull(),
                email: values.email.trimOrNull(),
                firstName: values.firstName.trimOrNull(),
                lastName: values.lastName.trimOrNull(),
                address: values.address.trimOrNull(),
                postcode: values.postcode.trimOrNull(),
                city: values.city.trimOrNull(),
                country: values.country.trimOrNull(),
                healthcareSystemIdentifier:
                  values.healthcareSystemIdentifier.trimOrNull(),
                sex: values.sex,
                birthDate: values.dateOfBirth
                  ? parseLocalDate(values.dateOfBirth)
                  : null,
              },
            })
      }
    >
      <div className="flex items-center space-x-6">
        <FormInput
          name="firstName"
          label={t("patients.patient_composer.first_name")}
          placeholder={t("patients.patient_composer.first_name")}
          wrapperClassName="flex-fill"
        />
        <FormInput
          name="lastName"
          label={t("patients.patient_composer.last_name")}
          placeholder={t("patients.patient_composer.last_name")}
          wrapperClassName="flex-fill"
        />
      </div>
      <div className="flex items-center space-x-6">
        <FormInput
          name="username"
          disabled={!!patient}
          label={t("patients.patient_composer.username")}
          placeholder={t("patients.patient_composer.username")}
          wrapperClassName="flex-fill"
        />
        <FormPhoneInput
          name="phone"
          label={t("patients.patient_composer.phone")}
          placeholder={t("patients.patient_composer.phone")}
          wrapperClassName="flex-fill"
        />
      </div>
      <div className="flex items-center space-x-6">
        <FormInput
          name="email"
          label={t("patients.patient_composer.email_address")}
          placeholder={t("patients.patient_composer.emailexamplecom")}
          wrapperClassName="flex-fill"
        />
      </div>
      <div className="flex items-center space-x-6">
        <FormSelect
          name="sex"
          options={SexKnownValues}
          placeholder={t("patients.patient_composer.chose")}
          label={t("patients.patient_composer.gender")}
          getOptionLabel={(s) => {
            switch (s) {
              case "MALE":
                return t("patients.patient_composer.male");
              case "FEMALE":
                return t("patients.patient_composer.female");
              case "OTHER":
                return t("patients.patient_composer.other");
            }
          }}
          wrapperClassName="flex-fill"
        />
        <FormDateInput
          name="dateOfBirth"
          label={t("patients.patient_composer.date_of_birth")}
          wrapperClassName="flex-fill"
        />
      </div>
      <div className="flex items-center space-x-6">
        {user.subOrganization.locale === "ENGLISH" && (
          <FormInput
            name="healthcareSystemIdentifier"
            label="Healthcare System Identifier" // TODO(@pierre) No relevant translation in French, add a proper one when switching to org admin region
            wrapperClassName="flex-fill"
          />
        )}
      </div>
      <FormInput
        name="address"
        label={t("patients.patient_composer.address")}
        placeholder={t("patients.patient_composer.address")}
        wrapperClassName="flex-fill"
      />
      <div className="flex items-center space-x-6">
        <FormInput
          name="city"
          label={t("patients.patient_composer.city")}
          wrapperClassName="flex-fill"
        />
        <FormInput
          name="postcode"
          label={t("patients.patient_composer.postcode")}
          placeholder={t("patients.patient_composer.postcode")}
          wrapperClassName="flex-fill"
        />
        <FormInput
          name="country"
          label={t("patients.patient_composer.country")}
          wrapperClassName="flex-fill"
        />
      </div>
    </FormModal>
  );
};
