import { MessageInput, useMessages } from "contexts/Messages/MessagesContext";
import { CreateDoctorConversation } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { addDoctorConversationInCache } from "utils/apollo";
import { notifier } from "utils/notifier";

// TODO: We should use one mutation for that
export const useShareContent = () => {
  const [createDoctorConversation] = useMutation(CreateDoctorConversation);
  const { sendMessage } = useMessages();

  return ({
    doctorUuid,
    messageInput,
    successNotification,
  }: {
    doctorUuid: UUID;
    messageInput: MessageInput;
    successNotification: string;
  }) =>
    createDoctorConversation(
      { doctorUuids: [doctorUuid] },
      {
        onSuccess: ({ experience }, client) => {
          addDoctorConversationInCache(client, experience);
          sendMessage(experience.uuid, messageInput);
          notifier.success(successNotification);
        },
      },
    );
};
