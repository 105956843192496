import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { TagPill } from "components/Tag/TagPill";
import { useDoctor } from "contexts/User/UserContext";
import {
  AddTagToQAExperience,
  RemoveTagFromQAExperience,
  TagFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useIsDesktop } from "hooks/useMediaQuery";

import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";

export const MLTagPill = ({
  tag,
  fontSize,
}: {
  tag: TagFragment;
  fontSize?: number;
}) => {
  const isDesktop = useIsDesktop();
  const { hasPermission } = useDoctor();
  const { experience } = useQAExperience();
  const [addTag, adding] = useMutation(AddTagToQAExperience);
  const [removeTag, removing] = useMutation(RemoveTagFromQAExperience);

  return (
    <TagPill
      tag={tag}
      fontSize={fontSize}
      isMLTag
      rightInnerElement={
        <div className="flex items-center">
          {hasPermission("ANSWER_QA_EXPERIENCE") && (
            <>
              <ClickableIcon
                name="close"
                size={16}
                className={classNames("text-danger py-6 hover:opacity-70", {
                  "px-0": isDesktop,
                })}
                disabled={removing || adding}
                onClick={() =>
                  removeTag({ uuid: experience.uuid, tagUuid: tag.uuid })
                }
              />
              <ClickableIcon
                name="check"
                size={16}
                className={classNames("text-green-300 py-6 hover:opacity-70", {
                  "px-0": isDesktop,
                })}
                disabled={removing || adding}
                onClick={() =>
                  addTag({ uuid: experience.uuid, tagUuid: tag.uuid })
                }
              />
            </>
          )}
        </div>
      }
      disabled={removing || adding}
    />
  );
};
