import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { FormDatePicker } from "components/Form/DatePicker/FormDatePicker";
import { Form } from "components/Form/Form/Form";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormSwitch } from "components/Form/Switch/FormSwitch";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { ExtractFacts } from "generated/provider";
import { useLazyQuery } from "graphql-client/useLazyQuery";
import { useMountDate } from "hooks";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { trackEvent } from "tracking";

import { Report, ReportType } from "./Report";

gql`
  query ExtractFacts(
    $text: String!
    $writtenAt: DateTime!
    $language: String!
    $withGPT3PostProcessing: Boolean!
  ) {
    extractFacts(
      query: {
        text: $text
        writtenAt: $writtenAt
        language: $language
        withGPT3PostProcessing: $withGPT3PostProcessing
      }
    ) {
      jsonReport
    }
  }
`;

type FactExtractionFormValues = {
  text: string;
  writtenAt: ISOString;
  language: "fr" | "en";
  enrichData: boolean;
};

export const FactExtraction = () => {
  const t = useTranslation();
  const [extractFacts, { loading, data }] = useLazyQuery(ExtractFacts);
  const mountDate = useMountDate();

  return (
    <Background className="flex-col flex-fill flex-shrink overflow-auto space-y-32 max-w-full h-full p-12 lg:p-44">
      <h1 className="text-primary-dark text-24 font-bold">
        {t("developers.developers.fact_extraction")}
      </h1>
      <Form<FactExtractionFormValues>
        className="bg-white shadow-sm-outlined p-16 rounded-item space-y-16 w-full"
        initialValues={{
          text: "",
          writtenAt: mountDate,
          language: "fr",
          enrichData: false,
        }}
        onSubmit={(values) => {
          extractFacts({
            withGPT3PostProcessing: values.enrichData,
            ...values,
          });
          trackEvent({
            name: "Try Fact Extraction In Developers",
            properties: { language: values.language },
          });
        }}
      >
        <FormTextArea
          minRows={5}
          maxRows={10}
          name="text"
          placeholder={t("labs.fact_extraction.fact_extraction.type_a_message")}
          disabled={loading}
          label={t("labs.fact_extraction.fact_extraction.patient_message")}
        />
        <div className="flex space-x-12">
          <FormDatePicker
            wrapperClassName="flex-1"
            label={t("labs.fact_extraction.fact_extraction.message_written_at")}
            name="writtenAt"
          />
          <FormSelect
            wrapperClassName="flex-1"
            label={t("labs.fact_extraction.fact_extraction.language")}
            name="language"
            options={["fr", "en"]}
            getOptionLabel={(languageCode) =>
              languageCode === "fr" ? "Français" : "English"
            }
          />
        </div>
        <div className="mt-10">
          <FormSwitch
            label={t("labs.fact_extraction.fact_extraction.enrich_data")}
            name="enrichData"
          />
          <div>
            {t("labs.fact_extraction.fact_extraction.enrich_data_disclaimer")}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Submit
            loading={loading}
            className="px-56"
            label={t("labs.fact_extraction.fact_extraction.extract")}
          />
          <Link className="text-primary underline" to={routes.HELP}>
            {t("labs.fact_extraction.fact_extraction.help_link")}
          </Link>
        </div>
      </Form>

      {data?.jsonReport && (
        <Report
          report={JSON.parse(data.jsonReport) as ReportType}
          wrapperClassName="p-16 shadow-sm-outlined rounded-item w-full"
        />
      )}
    </Background>
  );
};
