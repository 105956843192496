import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { ReplyMessageContent } from "types";

export const ReplyMessage = ({
  content,
  maxLines,
}: {
  content: ReplyMessageContent;
  maxLines: number;
}) => {
  const t = useTranslation();

  return content.__typename === "TextMessageContent" ? (
    <div
      style={{
        display: "-webkit-box",
        overflow: "hidden",
        WebkitLineClamp: maxLines,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
      }}
    >
      {content.text}
    </div>
  ) : content.__typename === "DeletedMessageContent" ? (
    <span className="italic">
      {t("reply_message.reply_message.deleted_message")}
    </span>
  ) : (
    <div className="flex items-center">
      <span className="mr-6">
        {content.__typename === "ImageMessageContent"
          ? t("reply_message.reply_message.picture")
          : content.__typename === "VideoMessageContent"
          ? t("reply_message.reply_message.video")
          : content.__typename === "AudioMessageContent"
          ? t("reply_message.reply_message.audio_file")
          : t("reply_message.reply_message.document")}
      </span>
      <Icon
        size={12}
        name={
          content.__typename === "ImageMessageContent"
            ? "photo"
            : content.__typename === "VideoMessageContent"
            ? "videoOn"
            : content.__typename === "AudioMessageContent"
            ? "micOn"
            : "document"
        }
      />
    </div>
  );
};
