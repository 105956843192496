import { AllDoctors, AllDoctorsVariables } from "generated/provider";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";

import { AsyncFormMultiSelect } from "./AsyncFormMultiSelect";
import { DoctorRowOption } from "./DoctorRowOption";

export const AsyncDoctorFormMultiSelect = ({
  placeholder,
  label,
  getVariables = (s) => ({ filter: { freeTextSearch: s } }),
  wrapperClassName,
  name,
}: {
  name: string;
  wrapperClassName?: string;
  placeholder?: string;
  label?: string;
  getVariables?: (s: string) => AllDoctorsVariables;
}) => {
  const t = useTranslation();

  return (
    <AsyncFormMultiSelect
      multiSelectProps={{
        name,
        wrapperClassName,
        placeholder:
          placeholder ?? t("form.select.async_doctor_form_multi_select.select"),
        CustomOption: DoctorRowOption,
        getOptionLabel: displayDoctor,
        label:
          label ??
          t("form.select.async_doctor_form_multi_select.practitioners"),
      }}
      queryProps={{
        query: AllDoctors,
      }}
      getOptions={(data) => data?.doctors ?? []}
      getVariables={getVariables}
    />
  );
};
