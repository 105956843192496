import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { Select } from "components/Form/Select/Select";
import { DoctorSummaryFragment } from "generated/provider";
import { displayUser } from "utils/display";

export type DoctorFilter = DoctorSummaryFragment;

export const DoctorSelect = ({
  selectableDoctors,
  selectedDoctor,
  setSelectedDoctor,
  className,
}: {
  selectableDoctors: DoctorFilter[];
  selectedDoctor: DoctorFilter;
  setSelectedDoctor: (val: DoctorFilter) => void;
  className?: string;
}) => (
  <Select<DoctorFilter>
    wrapperClassName={classNames("w-auto font-medium", className)}
    className="h-44 flex items-end caret-transparent"
    optionsStyle={{ padding: 0 }}
    name="calendar"
    options={selectableDoctors}
    value={selectedDoctor}
    onChange={setSelectedDoctor}
    getOptionId={(option) => option.uuid}
    leftInnerElement={<Avatar size={23} user={selectedDoctor} />}
    CustomOption={(props) => (
      <div
        className={classNames(
          "flex space-x-8 p-8 text-14 text-primary-dark",
          props.selected && "bg-grey-200/70",
          props.active && !props.selected && "bg-grey-100",
        )}
      >
        <Avatar size={23} user={props.value} className="border-transparent" />
        <div>{props.label}</div>
      </div>
    )}
    getOptionLabel={displayUser}
  />
);
