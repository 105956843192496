import { EditorState } from "draft-js";

import {
  DataPointFragment,
  FamilyMemberDegreeKnownValue,
  FamilyMemberDegreeKnownValues,
  SimpleHealthDataPointTypeKnownValues,
} from "generated/provider";
import { staticT } from "i18n";
import { DraftMentionedItem, HealthDataPointKnownValue } from "types";
import {
  convertDataPointToMetricSystemIfNeeded,
  getHealthDataPointNameMap,
  getHealthDataPointUnitMap,
} from "utils/data-points";
import { displayFamilyMemberDegree } from "utils/display";
import { isKnownValue } from "utils/enum";
import {
  bloodPressureValuesFromValidInput,
  isValidBloodPressure,
  isValidImperialLength,
  isValidNumericalInput,
} from "utils/form";
import { measurementSystem } from "utils/measurementSystem";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { getCurrentBlock } from "../core/utils";
import {
  MentionEditor,
  MentionEditorProps,
} from "../MentionEditor/MentionEditor";
import { MentionEditorState } from "../MentionEditor/useMentionEditorState";
import {
  MentionsOptionsProps,
  MentionsSubOptions,
} from "../MentionEditor/utils";
import {
  cleanAndSubmitFamilyMembersOptions,
  DEFAULT_FAMILY_MEMBER,
  newFamilyHistoryToMentionedItem,
  newRegularEntityToMentionedItem,
} from "./utils";

const NewEntityTypes = [
  "SYMPTOM",
  "MEDICATION",
  "VACCINATION",
  "ALLERGY",
  "PROCEDURE",
  "MEDICAL_HISTORY",
  "FAMILY_HISTORY",
  "DATA_POINT",
] as const;
export type NewEntityType = typeof NewEntityTypes[number];

export type TypedEntityType =
  | "SYMPTOM"
  | "MEDICATION"
  | "VACCINATION"
  | "ALLERGY"
  | "PROCEDURE"
  | "MEDICAL_HISTORY"
  | FamilyMemberDegreeKnownValue // For family history
  | HealthDataPointKnownValue; // For DATA_POINTS

const createNewEntitiesOptions = ({
  onMainOptionSelected,
  skipFuzzyMatchFiltering,
  dataPointsSubOptions,
  familyHistorySubOptions,
}: {
  onMainOptionSelected: (o: NewEntityType) => void;
  skipFuzzyMatchFiltering: boolean;
  dataPointsSubOptions: Omit<
    MentionsSubOptions<HealthDataPointKnownValue>,
    "skipFuzzyMatchFiltering"
  >;
  familyHistorySubOptions: MentionsSubOptions<FamilyMemberDegreeKnownValue>;
}): MentionsOptionsProps<NewEntityType> => ({
  header: staticT(
    "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.add_to_medical_record",
  ),
  data: [
    "SYMPTOM",
    "MEDICATION",
    "VACCINATION",
    "ALLERGY",
    "MEDICAL_HISTORY",
    "PROCEDURE",
    {
      type: "FAMILY_HISTORY",
      subOptions: familyHistorySubOptions as MentionsSubOptions<unknown>,
    },
    {
      type: "DATA_POINT",
      subOptions: {
        ...dataPointsSubOptions,
        skipFuzzyMatchFiltering,
      } as MentionsSubOptions<unknown>,
    },
  ],
  getOptionId: (o) => o,
  getOptionLabel: (o) => getLabel(o).replaceAll(/[()]/gu, ""),
  display: ({ option }) => getLabel(option),
  onSelected: onMainOptionSelected,
  skipFuzzyMatchFiltering,
});

export const EditorWithEHRMentions = ({
  name,
  state,
  autocomplete,
  autofillBulletPointWithEntityType,
  mentionsDisabled,
  mentionsWithDataPointsOnly,
  mentionsWithFamilyMembersOnly,
  ...props
}: Omit<MentionEditorProps, "suggestions" | "state"> & {
  state: MentionEditorState<{ type: TypedEntityType }>;
  autofillBulletPointWithEntityType?: Exclude<NewEntityType, "DATA_POINT">;
  autocomplete?: (context: string) => string | undefined;
  mentionsDisabled?: boolean;
  mentionsWithDataPointsOnly?: boolean;
  mentionsWithFamilyMembersOnly?: boolean;
}) => {
  const baseDataPointOptions: Omit<
    MentionsSubOptions<HealthDataPointKnownValue>,
    "skipFuzzyMatchFiltering" | "onSelected"
  > = {
    header: staticT(
      "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.add_to_medical_record",
    ),
    data: [...SimpleHealthDataPointTypeKnownValues, "BLOOD_PRESSURE_MMHG"],
    getOptionId: (o) => o,
    getOptionLabel: (o) => displayDataPointLabel(o).upperFirst(),
  };
  const baseFamilyMemberDegreeOptions: Omit<
    MentionsSubOptions<FamilyMemberDegreeKnownValue>,
    "skipFuzzyMatchFiltering" | "onSelected"
  > = {
    header: staticT(
      "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.add_to_medical_record",
    ),
    data: [...FamilyMemberDegreeKnownValues],
    getOptionId: (o) => o,
    getOptionLabel: displayFamilyMemberDegree,
  };
  const dataPointsOptionsWithOnSelect: MentionsSubOptions<HealthDataPointKnownValue> =
    {
      ...baseDataPointOptions,
      skipFuzzyMatchFiltering: false,
      onSelected: (o) => {
        state.startMutableEntity({
          immutablePrefix: getTypedLabel(o),
          initialText: "",
          type: o,
          onSubmit: (label) =>
            newDataPointToMentionedItem({
              entityType: o,
              currentValue: label,
            }),
        });
      },
    };

  const familyMembersOptionsWithOnSelect: MentionsSubOptions<FamilyMemberDegreeKnownValue> =
    {
      ...baseFamilyMemberDegreeOptions,
      skipFuzzyMatchFiltering: false,
      onSelected: (o) => {
        state.startMutableEntity({
          immutablePrefix: getTypedLabel(o),
          initialText: `${displayFamilyMemberDegree(o)}: `,
          type: o,
          onSubmit: (label: string) =>
            cleanAndSubmitFamilyMembersOptions(label),
        });
      },
    };

  const newEntitiesByKeystrokeOptions = createNewEntitiesOptions({
    onMainOptionSelected: (o) => {
      if (o === "DATA_POINT" || o === "FAMILY_HISTORY") {
        state.replaceSuggestionsInput({ text: "#" });
      } else {
        state.startMutableEntity({
          immutablePrefix: getTypedLabel(o),
          initialText: "",
          type: o,
          onSubmit: (label) =>
            label
              .trimOrNull()
              ?.let((it) => newRegularEntityToMentionedItem(o, it)),
        });
      }
    },
    dataPointsSubOptions: dataPointsOptionsWithOnSelect,
    familyHistorySubOptions: familyMembersOptionsWithOnSelect,
    skipFuzzyMatchFiltering: false,
  });

  const newEntitiesBySelectionOptions = createNewEntitiesOptions({
    onMainOptionSelected: (option) => {
      if (state.suggestionsInput?.trigger.type !== "SELECTION") return;
      if (option !== "DATA_POINT" && option !== "FAMILY_HISTORY") {
        state.createEntityFromSelection(
          newRegularEntityToMentionedItem(
            option,
            state.suggestionsInput.currentInput,
          ),
        );
      }
    },
    dataPointsSubOptions: {
      ...baseDataPointOptions,
      onSelected: (option) => {
        if (state.suggestionsInput?.trigger.type !== "SELECTION") return;
        const newMentionedItem = newDataPointToMentionedItem({
          entityType: option,
          currentValue: state.suggestionsInput.currentInput,
          fuzzy: true,
        });
        if (newMentionedItem) {
          state.createEntityFromSelection(newMentionedItem);
        } else {
          state.setEditorState((current) =>
            EditorState.forceSelection(
              current,
              current.getSelection().merge({
                anchorOffset: current.getSelection().getFocusOffset(),
              }),
            ),
          );
        }
      },
    },
    // TODO: we should create an entity directly from selected text + selected family degree with createEntityFromSelection and not use startMutableEntity
    familyHistorySubOptions: {
      ...baseFamilyMemberDegreeOptions,
      onSelected: (option) => {
        state.startMutableEntity({
          immutablePrefix: getTypedLabel(option),
          initialText: `${displayFamilyMemberDegree(option).toLowerCase()}: ${
            state.suggestionsInput?.currentInput ?? ""
          }`,
          type: option,
          onSubmit: (label) => cleanAndSubmitFamilyMembersOptions(label),
        });
      },
    },
    skipFuzzyMatchFiltering: true,
  });
  // Reassignment for safe cast MentionsSubOptions -> MentionsOptionsProps
  const datapointsOptionsOnly: MentionsOptionsProps<HealthDataPointKnownValue> =
    dataPointsOptionsWithOnSelect;
  const familyMembersOptionsOnly: MentionsOptionsProps<FamilyMemberDegreeKnownValue> =
    familyMembersOptionsWithOnSelect;

  return (
    <MentionEditor
      name={name}
      state={state}
      forceSuggestions={
        !!mentionsWithDataPointsOnly || !!mentionsWithFamilyMembersOnly
      }
      suggestions={
        mentionsDisabled
          ? []
          : mentionsWithDataPointsOnly
          ? [
              {
                trigger: { type: "START_BLOCK" },
                options: datapointsOptionsOnly,
              },
              {
                trigger: { type: "KEYSTROKE", keystroke: "#" },
                options: datapointsOptionsOnly,
              },
            ]
          : mentionsWithFamilyMembersOnly
          ? [
              {
                trigger: { type: "START_BLOCK" },
                options: familyMembersOptionsOnly,
              },
              {
                trigger: { type: "KEYSTROKE", keystroke: "#" },
                options: familyMembersOptionsOnly,
              },
            ]
          : [
              {
                trigger: { type: "KEYSTROKE", keystroke: "#" },
                options: newEntitiesByKeystrokeOptions,
              },
              {
                trigger: { type: "SELECTION" },
                options: newEntitiesBySelectionOptions,
              },
            ]
      }
      withRichText
      autocompletion={autocomplete?.(
        getCurrentBlock(state.editorState).getText(),
      )}
      afterEditorStateChange={(editorState) => {
        // Right after we've inserted some characters in a bullet point,
        // start a new mention. (Note: this can hardly be done in a
        // useEffect callback, because of complex intersections with
        // the way MentionEditor updates the editorState. One notable
        // bug with useEffect implementation of this logic is that
        // if a person types several letters quite fast, the new mention
        // is erased)
        const previousCurrentBlock = getCurrentBlock(state.editorState);
        const currentBlock = getCurrentBlock(editorState);
        const text = currentBlock.getText();
        const previousText = previousCurrentBlock.getText();

        if (
          !mentionsDisabled &&
          state.forceBulletPoints &&
          previousCurrentBlock.getKey() === currentBlock.getKey() &&
          text.isNotEmpty() &&
          previousText.isEmpty()
        ) {
          if (autofillBulletPointWithEntityType) {
            const typedEntity =
              autofillBulletPointWithEntityType === "FAMILY_HISTORY"
                ? DEFAULT_FAMILY_MEMBER
                : autofillBulletPointWithEntityType;
            state.startMutableEntity({
              immutablePrefix: getTypedLabel(typedEntity),
              initialText: text.startsWith("#") ? text.slice(1) : text,
              type: typedEntity,
              onSubmit: (label) =>
                label
                  .trimOrNull()
                  ?.let((it) =>
                    autofillBulletPointWithEntityType === "FAMILY_HISTORY"
                      ? newFamilyHistoryToMentionedItem(
                          it,
                          DEFAULT_FAMILY_MEMBER,
                        )
                      : newRegularEntityToMentionedItem(
                          autofillBulletPointWithEntityType,
                          it,
                        ),
                  ),
            });
          } else if (
            mentionsWithDataPointsOnly ||
            mentionsWithFamilyMembersOnly
          ) {
            // We always start with a suggestion
            state.replaceSuggestionsInput({ text: `#${text}` });
          }
        }
      }}
      {...props}
    />
  );
};

const getTypedLabel = (o: TypedEntityType) => {
  if (
    o === "SYMPTOM" ||
    o === "ALLERGY" ||
    o === "MEDICATION" ||
    o === "VACCINATION" ||
    o === "PROCEDURE" ||
    o === "MEDICAL_HISTORY"
  ) {
    return "#";
  }
  if (isKnownValue(o, FamilyMemberDegreeKnownValues)) return "#";
  const colon = staticT("utils.colon");
  return `#${displayDataPointLabel(o)}${colon}`;
};

const getLabel = (o: NewEntityType) => {
  switch (o) {
    case "SYMPTOM":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.symptom",
      );
    case "MEDICATION":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.medicine",
      );
    case "VACCINATION":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.vaccine",
      );
    case "ALLERGY":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.allergy",
      );
    case "PROCEDURE":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.surgery_history",
      );
    case "MEDICAL_HISTORY":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.medical_history",
      );
    case "FAMILY_HISTORY":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.family_history",
      );
    case "DATA_POINT":
      return staticT(
        "inboxes.qa_experience.ehr_composer.note_composer.editor_with_all_ehr_mentions.datapoint",
      );
  }
};

const newDataPointToMentionedItem = ({
  entityType,
  currentValue,
  fuzzy,
}: {
  entityType: HealthDataPointKnownValue;
  currentValue: string;
  fuzzy?: boolean;
}): DraftMentionedItem | null => {
  let dataPointValue: DataPointFragment["value"] | null = null;
  if (entityType === "BLOOD_PRESSURE_MMHG") {
    const fuzzyVersion = fuzzy
      ? stripToFirstBloodPressureIfPossible(currentValue)
      : currentValue;
    if (isValidBloodPressure(fuzzyVersion)) {
      dataPointValue = {
        __typename: "BloodPressureValue",
        ...bloodPressureValuesFromValidInput(fuzzyVersion),
      };
    }
  } else if (
    (entityType === "HEIGHT_CM" || entityType === "WAIST_SIZE_CM") &&
    measurementSystem === "IMPERIAL"
  ) {
    const fuzzyVersion = fuzzy
      ? stripToFirstImperialLengthIfPossible(currentValue)
      : currentValue;
    if (isValidImperialLength(fuzzyVersion)) {
      dataPointValue = {
        __typename: "SimpleHealthDataPointValue",
        type: entityType,
        quantity: convertDataPointToMetricSystemIfNeeded(
          fuzzyVersion,
          entityType,
        ),
      };
    }
  } else {
    const fuzzyVersion = fuzzy
      ? stripToFirstNumericalValueIfPossible(currentValue)
      : currentValue;
    if (isValidNumericalInput(fuzzyVersion)) {
      dataPointValue = {
        __typename: "SimpleHealthDataPointValue",
        type: entityType,
        quantity: convertDataPointToMetricSystemIfNeeded(
          fuzzyVersion,
          entityType,
        ),
      };
    }
  }

  return dataPointValue
    ? {
        __typename: "DraftMentionedItem",
        draftedItem: {
          __typename: "HealthDataPoint",
          uuid: ephemeralUuidV4(),
          value: dataPointValue,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          updatedBy: null,
        },
      }
    : null;
};

const stripToFirstNumericalValueIfPossible = (sentence: string): string => {
  const matches = sentence.match(/([0-9.]+)/u);
  if (matches) return matches[0];
  return sentence;
};

const stripToFirstBloodPressureIfPossible = (sentence: string): string => {
  const matches = sentence.match(/([0-9]+\/[0-9]+)/u);
  if (matches) return matches[0];
  return sentence;
};

const stripToFirstImperialLengthIfPossible = (sentence: string): string => {
  const matches = sentence.match(/(\d+)'(\d+)"/u);
  if (matches) return matches[0];
  return sentence;
};

const displayDataPointLabel = (type: HealthDataPointKnownValue) =>
  `${getHealthDataPointNameMap()[type].toLowerCase()} (${
    getHealthDataPointUnitMap()[type]
  })`;
