import { KeyboardEvent } from "react";

import { useField } from "hooks/useField";
import { useTranslation } from "i18n";

import { FormInput } from "../Input/FormInput";
import { TimePickerProps } from "./TimePicker";
import { FormatType, isValidDate } from "./utils";

type FormDateType = Omit<
  TimePickerProps,
  "value" | "onChange" | "error" | "baseDate" | "format" | "placeholder"
>;

export const FormDateInput = ({ name, disabled, ...props }: FormDateType) => {
  const t = useTranslation();
  const [{ value, disabled: disabledField }, { error }] = useField<
    string | undefined
  >({ name, disabled });

  const format = t("common.date_format") as FormatType;
  const placeholder = t("common.date_placeholder");

  const isValidOrUndefined = value ? isValidDate(value, format) : true;

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    const typedValue = e.key;
    const caretPositionIsAtEnd =
      inputValue.length === e.currentTarget.selectionStart;

    if (
      // Auto add the '/' character only if the user is typing at the end of the input
      caretPositionIsAtEnd &&
      e.currentTarget.value.length + 1 < format.length
    ) {
      e.preventDefault();
      // Add the typed character
      e.currentTarget.value += typedValue;

      // Start to indexes at the beginning of the format and at the beginning of the typed value
      // and iterate over the format and the typed value to find if the next character in the format
      // is a symbol. If it is, add it to the input value.
      let formatCharIndex = 0;
      for (
        let typedCharIndex = 0;
        typedCharIndex < e.currentTarget.value.length;
        ++typedCharIndex
      ) {
        const char = e.currentTarget.value.charAt(typedCharIndex);

        if (isSymbol(char)) {
          while (
            formatCharIndex < format.length &&
            format.charAt(formatCharIndex) !== char
          ) {
            formatCharIndex++;
          }

          if (formatCharIndex < format.length) formatCharIndex++;
        } else {
          formatCharIndex++;
        }
      }

      const nextCharInFormat = format.charAt(formatCharIndex);

      // If the next character in the format is a symbol, add it
      if (isSymbol(nextCharInFormat)) e.currentTarget.value += nextCharInFormat;
    }
  };

  return (
    <FormInput
      name={name}
      disabled={disabledField}
      error={error}
      validate={() =>
        isValidOrUndefined
          ? undefined
          : t("common.date_format_error_message", {
              format: formatToString(format),
            })
      }
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      {...props}
    />
  );
};

const formatToString = (format: FormatType) => {
  if (format === "MM/dd/yyyy") return "MM/DD/YYYY";
  return "JJ/MM/AAAA";
};

const isLetter = (char: string) => char.toLowerCase() !== char.toUpperCase();

const isNumber = (char: string) => !isNaN(parseInt(char));

const isSymbol = (char: string) => !isLetter(char) && !isNumber(char);
