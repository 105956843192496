import { useCallback } from "react";

import { queryEndpoint } from "api/api-client";
import { REST_ENDPOINTS } from "api/endpoints";
import { useGetAuthenticatedRequestContextOrThrow } from "api/utils";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

export type FilePurpose =
  | "AVATAR"
  | "FAVICON"
  | "DATASET"
  | "INVOICE"
  | "PATIENT_DOCUMENT"
  | "PRESCRIPTION"
  | "MESSAGE"
  | "STORY_MEDIA"
  | "PROGRAM_MEDIA"
  | "OBSERVATION_AUDIO"
  | "AUDIO_CONVERSATION"
  | "AUDIO_CONVERSATION_CONSENT"
  | "SIGNATURE";

export type UploadInput = { uuid: string };

export const useUploadFile = () => {
  const t = useTranslation();

  const endpoint = REST_ENDPOINTS.PROVIDER_UPLOAD;

  const getAuthenticatedRequestContextOrThrow =
    useGetAuthenticatedRequestContextOrThrow(endpoint.authenticationKind);

  return useCallback(
    async (variables: {
      purpose: FilePurpose;
      file: File;
      onProgress?: (percentage: number) => void;
    }): Promise<UploadInput | null> => {
      const data = new FormData();
      data.append("purpose", variables.purpose);
      data.append("file", variables.file);

      try {
        const response = await queryEndpoint(endpoint, {
          data,
          requestContext: getAuthenticatedRequestContextOrThrow(),
          onUploadProgress: variables.onProgress,
        });

        return { uuid: response.data[0] };
      } catch (e) {
        // We don't want file upload errors to bubble up.
        notifier.error({
          sentry: { exception: e },
          user: t("use_upload_file.file_upload_failed"),
        });
        return null;
      }
    },
    [getAuthenticatedRequestContextOrThrow, t, endpoint],
  );
};
