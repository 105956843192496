import { useEffect, useState } from "react";
import classNames from "classnames";
import { EditorState } from "draft-js";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Spinner } from "components/Spinner/Spinner";
import { FamilyMemberDegreeKnownValues } from "generated/provider";
import { usePrevious } from "hooks";
import { useVoiceInput } from "hooks/useVoiceInput";
import { staticT as t } from "i18n";
import {
  AllowedMentionList,
  HealthDataPointKnownValues,
  Mention,
  MentionedItem,
  NoteSuggestion,
} from "types";
import { run } from "utils";
import { isKnownValue } from "utils/enum";
import {
  isValidBloodPressure,
  isValidImperialLength,
  isValidNumericalInput,
} from "utils/form";
import { getLanguageCode, language } from "utils/intl";
import { measurementSystem } from "utils/measurementSystem";

import { Decorator, DraftJSEntity } from "../core/types";
import {
  addEmptyBlock,
  getCurrentBlock,
  getCurrentIndex,
  getFirstBlock,
  getLastBlock,
  getNumberBlocks,
  insertText,
} from "../core/utils";
import { MentionPill } from "../MentionEditor/MentionPill";
import {
  MentionEditorState,
  useMentionEditorState,
} from "../MentionEditor/useMentionEditorState";
import {
  EditorWithEHRMentions,
  NewEntityType,
  TypedEntityType,
} from "./EditorWithEHRMentions";
import { NoteSectionWithComposerFields } from "./types";
import { NavigationStatus } from "./useNavigationBetweenSections";
import { newRegularEntityToMentionedItem } from "./utils";

const getPlaceholder = (
  bulletPointSection: boolean,
  mentionsDisabled?: boolean,
) =>
  mentionsDisabled
    ? t(
        "components.form.note_sections.section_composer.tap_to_add_something_mentions_disabled",
      )
    : bulletPointSection
    ? t("components.form.note_sections.section_composer.tap_to_add_something")
    : t(
        "components.form.note_sections.section_composer.tap_to_add_something_or_use_hash_to_add_a_mention",
      );

const noteAllowedMentions: AllowedMentionList = {
  PatientNote: true,
  MentionedPatientDocument: true,
  HealthDataPoint: true,
  MentionedDoctor: true,
  Symptom: true,
  Condition: true,
  PatientAllergy: true,
  VaccinationStatement: true,
  MedicationStatement: true,
  Procedure: true,
  Experience: false,
  MentionedPatient: false,
  PubliclyListedDrug: false,
  DeletedAllergy: true,
  DeletedCondition: true,
  DeletedDatapoint: true,
  DeletedMedication: true,
  DeletedProcedure: true,
  DeletedSymptom: true,
  DeletedVaccination: true,
  FutureValue: false,
};

const getEHRMentionsDecorators: () => Decorator[] = () => [
  {
    entityStrategy: (entity: DraftJSEntity<Mention>) => {
      const item: MentionedItem | null = entity.data.mentionedItem;
      const actualItem =
        item?.__typename === "DraftMentionedItem" ? item.draftedItem : item;
      return (
        entity.mutability === "IMMUTABLE" &&
        (actualItem?.__typename === "Symptom" ||
          actualItem?.__typename === "MedicationStatement" ||
          actualItem?.__typename === "VaccinationStatement" ||
          actualItem?.__typename === "Condition" ||
          actualItem?.__typename === "HealthDataPoint" ||
          actualItem?.__typename === "PatientAllergy" ||
          actualItem?.__typename === "Procedure")
      );
    },
    component: ({ contentState, entityKey, children }) => {
      const mentionedItem: MentionedItem | undefined = entityKey
        ? contentState.getEntity(entityKey).getData()?.mentionedItem
        : undefined;
      const actualMentionedItem =
        mentionedItem?.__typename === "DraftMentionedItem"
          ? mentionedItem.draftedItem
          : mentionedItem;
      return actualMentionedItem ? (
        <MentionPill
          type={actualMentionedItem.__typename}
          children={children}
          status={
            mentionedItem?.__typename === "DraftMentionedItem"
              ? "DRAFT"
              : "PUBLISHED"
          }
        />
      ) : (
        <span className="bg-grey">{children}</span>
      );
    },
  },
  {
    entityStrategy: ({ type }) => type === "MUTABLE",
    component: ({ children, contentState, entityKey, decoratedText }) => {
      if (entityKey === undefined) return null;
      const mutablePayload:
        | { type: TypedEntityType; immutablePrefix: string }
        | undefined = contentState.getEntity(entityKey).getData();

      if (!mutablePayload) return <span className="bg-grey">{children}</span>;

      const input = decoratedText.startsWith(mutablePayload.immutablePrefix)
        ? decoratedText.slice(mutablePayload.immutablePrefix.length)
        : decoratedText;
      const itemType = toMentionedItemType(mutablePayload.type);
      const error = run(() => {
        if (!input) return;
        if (itemType === "HealthDataPoint") {
          if (mutablePayload.type === "BLOOD_PRESSURE_MMHG") {
            if (isValidBloodPressure(input)) return;
            return t("editor_with_all_ehr_mentions.invalid_format");
          }
          if (
            measurementSystem === "IMPERIAL" &&
            (mutablePayload.type === "WAIST_SIZE_CM" ||
              mutablePayload.type === "HEIGHT_CM")
          ) {
            if (isValidImperialLength(input)) return;
            return t("editor_with_ehr_mentions.invalid_imperial_length_format");
          }
          if (!isValidNumericalInput(input)) {
            return t("input_is_not_a_number", { input });
          }
        }
      });

      return (
        <MentionPill
          type={itemType}
          status="BEING_TYPED"
          className={error ? "relative" : undefined}
        >
          {error && (
            <span
              // whitespace-nowrap to allow being lager than the mention
              className="absolute bg-grey-100 px-4 rounded-sm text-danger whitespace-nowrap"
              style={{ top: "calc(100% + 1px)", left: -1 }}
            >
              ⚠️ {error}
            </span>
          )}
          {children}
        </MentionPill>
      );
    },
  },
];

export const TextSectionComposer = ({
  section,
  onChange,
  title,
  bulletPointEntityType,
  transcribe,
  onTranscriptionError,
  onClick,
  onKeyNavigateUp,
  onKeyNavigateDown,
  onVoiceNavigateUp,
  onVoiceNavigateDown,
  onFocus,
  onBlur,
  disabled,
  mentionsDisabled,
  autocomplete,
  suggestions,
}: {
  section: NoteSectionWithComposerFields & {
    content: { __typename: "TextSectionContent" };
  };
  onChange: (newNoteSection: NoteSectionWithComposerFields) => void;
  title?: string;
  transcribe?: boolean;
  onTranscriptionError: () => void;
  bulletPointEntityType?: Exclude<
    NewEntityType,
    "DATA_POINT" | "FAMILY_HISTORY"
  >;
  onClick: () => void;
  onKeyNavigateUp: () => NavigationStatus;
  onKeyNavigateDown: () => NavigationStatus;
  onVoiceNavigateUp: () => void;
  onVoiceNavigateDown: () => void;
  onFocus: () => void;
  onBlur: () => void;
  disabled?: boolean;
  mentionsDisabled?: boolean;
  autocomplete?: (context: string) => string | undefined;
  suggestions?: NoteSuggestion[];
}) => {
  const [placeholder, setPlaceholder] = useState("");
  const mentionsWithDataPointsOnly =
    section.content.category === "OBSERVATIONS";
  const mentionsWithFamilyMembersOnly =
    section.content.category === "FAMILY_HISTORY";
  const forceBulletPoints =
    bulletPointEntityType !== undefined ||
    mentionsWithDataPointsOnly ||
    mentionsWithFamilyMembersOnly;

  const content = section.content;

  const editorState = useMentionEditorState<{ type: TypedEntityType }>({
    initialValue: content.textWithMentions,
    allowedMentions: noteAllowedMentions,
    decorators: getEHRMentionsDecorators(),
    forceBulletPoints,
  });

  useHandleNavigationFromOutside(
    section,
    editorState.editorState,
    editorState.setEditorState,
  );

  // It's been observed that the editor wrapper's onFocus method wasn't fired
  // in some occasions (for instance when we create the editor section). So
  // we rely on a particular property of draftjs, .getSelection().getHasFocus()
  const cursorHasFocus = editorState.editorState.getSelection().getHasFocus();
  useEffect(() => {
    if (cursorHasFocus) {
      setPlaceholder("");
    } else {
      setPlaceholder(getPlaceholder(forceBulletPoints, mentionsDisabled));
    }
  }, [cursorHasFocus, forceBulletPoints, mentionsDisabled]);

  const [editorStateBeforeTranscribing, setEditorStateBeforeTranscribing] =
    useState<EditorState | undefined>(undefined);

  useVoiceInput({
    transcribe: transcribe ?? false,
    language: getLanguageCode(language),
    onTranscriptionError,
    onTranscriptionStart: () => {
      // Closes any mention
      if (editorState.mutableInput) editorState.submitMutableEntityInput(true);
      editorState.setEditorState((current) => {
        const withSelectionMoved = EditorState.moveSelectionToEnd(current);
        if (!getCurrentBlock(withSelectionMoved).getText().endsWith(" ")) {
          return insertText(withSelectionMoved, " ");
        }
        return withSelectionMoved;
      });
    },
    onTranscriptionEnd: () => {
      setEditorStateBeforeTranscribing(undefined);
    },
    onChange: (results) => {
      const transcript = results.map((it) => it.text).join("");
      if (!editorStateBeforeTranscribing) {
        setEditorStateBeforeTranscribing(editorState.editorState);
      }
      const startingPointEditorState =
        editorStateBeforeTranscribing ?? editorState.editorState;

      if (transcript.isNotBlank()) {
        editorState.setEditorState(() => startingPointEditorState);
        insertTranscript(
          transcript,
          editorState,
          bulletPointEntityType,
          mentionsDisabled,
        );
      }

      if (transcript.search(nextSectionPatterns) !== -1) onVoiceNavigateDown();
      if (transcript.search(previousSectionPatterns) !== -1) {
        onVoiceNavigateUp();
      }
    },
  });

  return (
    <div
      className={classNames("flex-col text-primary-dark flex-fill", {
        "override-unordered-list": forceBulletPoints,
      })}
      onClick={onClick}
    >
      {title && <div className="font-medium mt-4">{title}</div>}
      {suggestions?.isNotEmpty() && !disabled && (
        <div className="bg-grey-100 rounded-sm flex-col space-y-4 px-4">
          {suggestions.map((it) => (
            <div
              className="text-body flex items-center -ml-[64px]"
              key={it.uuid}
            >
              <div className="w-[64px] flex">
                {it.state === "PENDING" ? (
                  <Spinner />
                ) : (
                  <>
                    <ClickableIcon
                      name="close"
                      className="p-2"
                      onClick={() => it.refuse()}
                    />
                    <ClickableIcon
                      name="check"
                      className="p-2"
                      onClick={() =>
                        it.accept({
                          onSuccess: () =>
                            insertSuggestion(
                              it.text,
                              editorState,
                              bulletPointEntityType,
                            ),
                        })
                      }
                    />
                  </>
                )}
              </div>
              <div className="flex flex-fill items-center py-2">
                {run(() => {
                  if (!bulletPointEntityType) return it.text;
                  const draftItemType = newRegularEntityToMentionedItem(
                    bulletPointEntityType,
                    it.text,
                  ).draftedItem.__typename;
                  return (
                    <>
                      <div className="w-4 h-4 rounded-full bg-black ml-4 mr-8 " />
                      <MentionPill type={draftItemType} status="BEING_TYPED">
                        {it.text}
                      </MentionPill>
                    </>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
      {disabled && content.textWithMentions.text === "" ? null : (
        <EditorWithEHRMentions
          className="p-0 text-14 mt-4 bg-white"
          disabled={disabled}
          placeholder={disabled ? "" : placeholder}
          withRichText={!forceBulletPoints}
          name={`section.${section.uuid}.content`}
          mentionsDisabled={mentionsDisabled}
          mentionsWithDataPointsOnly={mentionsWithDataPointsOnly}
          mentionsWithFamilyMembersOnly={mentionsWithFamilyMembersOnly}
          autofillBulletPointWithEntityType={bulletPointEntityType}
          state={editorState}
          error={undefined}
          onChange={(newText) => {
            onChange({
              ...section,
              content: {
                ...content,
                textWithMentions: newText,
              },
            });
          }}
          onKeyStroke={(e) => {
            if (e.key === "ArrowUp") {
              const blockIndex = getCurrentIndex(editorState.editorState);
              const offset = editorState.editorState
                .getSelection()
                .getStartOffset();
              if (blockIndex === 0 && offset === 0) {
                const navigationStatus = onKeyNavigateUp();
                if (navigationStatus === "NAVIGATION_ALLOWED") {
                  e.stopPropagation();
                }
              }
            }
            if (e.key === "ArrowDown") {
              const blockIndex = getCurrentIndex(editorState.editorState);
              const lengthBlock = getCurrentBlock(
                editorState.editorState,
              ).getLength();
              const offset = editorState.editorState
                .getSelection()
                .getStartOffset();
              if (
                blockIndex === getNumberBlocks(editorState.editorState) - 1 &&
                offset >= lengthBlock
              ) {
                const navigationStatus = onKeyNavigateDown();
                if (navigationStatus === "NAVIGATION_ALLOWED") {
                  e.stopPropagation();
                }
              }
            }
            return undefined;
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          autocomplete={autocomplete}
        />
      )}
    </div>
  );
};

const insertSuggestion = (
  text: string,
  state: MentionEditorState<{ type: TypedEntityType }>,
  bulletPointEntityType?: Exclude<
    NewEntityType,
    "DATA_POINT" | "FAMILY_HISTORY"
  >,
) => {
  if (bulletPointEntityType) {
    state.insertFinalEntity(
      newRegularEntityToMentionedItem(bulletPointEntityType, text),
    );
  } else {
    state.setEditorState((prev) => insertText(prev, text));
  }

  state.setEditorState((prev) =>
    addEmptyBlock(
      prev,
      bulletPointEntityType ? "unordered-list-item" : "unstyled",
    ),
  );
};

const insertTranscript = (
  transcript: string,
  state: MentionEditorState<{ type: TypedEntityType }>,
  bulletPointEntityType?: Exclude<
    NewEntityType,
    "DATA_POINT" | "FAMILY_HISTORY"
  >,
  mentionsDisabled?: boolean,
) => {
  const lines = splitIntoLines(stripNavigationPatterns(transcript));
  lines.forEach((line, index) => {
    const trimmedLine = line.trim();
    if (bulletPointEntityType && !mentionsDisabled) {
      state.insertFinalEntity(
        newRegularEntityToMentionedItem(bulletPointEntityType, trimmedLine),
      );
    } else {
      state.setEditorState((prev) => insertText(prev, trimmedLine));
    }

    if (index !== lines.length - 1) {
      state.setEditorState((prev) =>
        addEmptyBlock(
          prev,
          bulletPointEntityType ? "unordered-list-item" : "unstyled",
        ),
      );
    }
  });
};

const splitIntoLines = (text: string) =>
  text.split(/à la ligne|\n|la ligne|new line|next line/u);

const nextSectionPatterns = /section suivante|session suivante|next section/gu;
const previousSectionPatterns =
  /section précédente|session précédente|previous section/gu;

const stripNavigationPatterns = (text: string) =>
  text
    .replaceAll(nextSectionPatterns, "")
    .replaceAll(previousSectionPatterns, "");

const useHandleNavigationFromOutside = (
  section: NoteSectionWithComposerFields,
  editorState: EditorState,
  setEditorState: (setter: (e: EditorState) => EditorState) => void,
) => {
  const navigationState = section.navigationState;
  const previousNavigationState = usePrevious(navigationState);
  useEffect(() => {
    if (
      navigationState !== "NEUTRAL" &&
      navigationState !== previousNavigationState
    ) {
      const blockToNavigate =
        navigationState === "NAVIGATED_USING_ARROW_DOWN"
          ? getFirstBlock(editorState)
          : getLastBlock(editorState);

      const lengthBlockToNavigate = blockToNavigate.getLength();
      const charPosition =
        navigationState === "NAVIGATED_USING_ARROW_DOWN"
          ? 0
          : lengthBlockToNavigate;

      const selectionState = editorState.getSelection();
      setEditorState((prevState) =>
        EditorState.forceSelection(
          prevState,
          selectionState.merge({
            anchorKey: blockToNavigate.getKey(),
            focusKey: blockToNavigate.getKey(),
            focusOffset: Math.min(charPosition, lengthBlockToNavigate),
            anchorOffset: Math.min(charPosition, lengthBlockToNavigate),
          }),
        ),
      );
    }
  }, [navigationState, previousNavigationState, editorState, setEditorState]);
};

const toMentionedItemType = (
  type: TypedEntityType,
): MentionedItem["__typename"] => {
  if (isKnownValue(type, HealthDataPointKnownValues)) return "HealthDataPoint";
  if (isKnownValue(type, FamilyMemberDegreeKnownValues)) return "Condition";
  switch (type) {
    case "SYMPTOM":
      return "Symptom";
    case "ALLERGY":
      return "PatientAllergy";
    case "PROCEDURE":
      return "Procedure";
    case "MEDICAL_HISTORY":
      return "Condition";
    case "MEDICATION":
      return "MedicationStatement";
    case "VACCINATION":
      return "VaccinationStatement";
  }
};
