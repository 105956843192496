import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useCustomizedAutocompleteModel } from "contexts/User/useCustomizedAutocompleteModel";
import { PatientSummaryFragment } from "generated/provider";
import { trackEvent } from "tracking";
import { insertText } from "utils";
import { getDefaultMessageIntro } from "utils/display";

import { MessageInput, MessageInputProps } from "../MessageInput";
import { AutocompleteOverlay } from "./AutocompleteOverlay";

export const AutocompleteInput = ({
  patient,
  wrapperClassName,
  onInput,
  onPressCmdEnter,
  className,
  ...props
}: MessageInputProps & {
  patient: PatientSummaryFragment;
  wrapperClassName?: string;
  onPressCmdEnter?: () => void;
}) => {
  const { messageText, setMessageText } = useExperienceDraft();
  const overlayRef = useRef<HTMLDivElement>(null);

  const [autoCompleteSuggestion, setAutoCompleteSuggestion] =
    useState<string>();
  const autocomplete = useAutocompleteForPatient(patient);

  useEffect(() => {
    if (!messageText) setAutoCompleteSuggestion(undefined);
  }, [messageText]);

  return (
    <div
      className={classNames("flex-fill relative flex-col", wrapperClassName)}
    >
      <MessageInput
        className={classNames("flex-fill p-8", className)}
        onInput={(e) => {
          const newText = e.currentTarget.value;
          setMessageText(newText);
          // TODO: we only offer suggestion when the user is at the end of their input
          //       more work needed to support insertion at an arbitrary index.
          const newSuggestion =
            e.currentTarget.selectionStart === newText.length
              ? autocomplete(newText)
              : undefined;
          const isNewSuggestion =
            messageText + (autoCompleteSuggestion ?? "") !==
            newText + (newSuggestion ?? "");
          if (newSuggestion && isNewSuggestion) {
            trackEvent({
              name: "Autocomplete Suggestion Shown",
              properties: {
                completionLength: newSuggestion.length,
                prefixLength: messageText.length,
              },
            });
          }
          setAutoCompleteSuggestion(newSuggestion);
          onInput?.(e);
        }}
        onKeyDown={(e) => {
          if (
            onPressCmdEnter &&
            e.key === "Enter" &&
            (e.metaKey || e.ctrlKey)
          ) {
            // When the user presses Cmd+Enter, we want to send the message
            // and not create a new line
            onPressCmdEnter();
          } else if (
            autoCompleteSuggestion &&
            (e.key === "Tab" || e.key === "Enter" || e.key === "ArrowRight")
          ) {
            e.preventDefault();
            insertText(autoCompleteSuggestion);
            trackEvent({
              name: "Autocomplete Suggestion Accepted",
              properties: {
                completionLength: autoCompleteSuggestion.length,
                prefixLength: messageText.length,
              },
            });
          }
        }}
        {...props}
      />
      <AutocompleteOverlay
        autoComplete={autoCompleteSuggestion}
        className={className}
        overlayRef={overlayRef}
      />
    </div>
  );
};

const useAutocompleteForPatient = (patient: PatientSummaryFragment) => {
  const generalAutocomplete = useCustomizedAutocompleteModel({
    patient,
    useCase: "GENERAL",
  });
  const intro = getDefaultMessageIntro(patient);
  return (query: string) => {
    if (
      query.length > 0 &&
      query.length < intro.length &&
      intro.fuzzyMatch(query)
    ) {
      return intro.slice(query.length);
    }
    return generalAutocomplete(query);
  };
};
