import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { NotFound } from "components/ErrorPage/NotFound";
import { Query } from "components/Query/Query";
import {
  EmptyTemplatesPlaceholder,
  TemplateSettingsForm,
} from "components/TemplateSettingsForm/TemplateSettingsForm";
import { useUser } from "contexts/User/UserContext";
import {
  CreatePrescriptionTemplate,
  DeletePrescriptionTemplate,
  PrescriptionTemplateFragment,
  PrescriptionTemplates,
  UpdatePrescriptionTemplate,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import {
  addPrescriptionTemplateInCache,
  removePrescriptionTemplateInCache,
} from "utils/apollo";
import { notifier } from "utils/notifier";

gql`
  query PrescriptionTemplates {
    prescriptionTemplates {
      ...PrescriptionTemplate
    }
  }

  mutation CreatePrescriptionTemplate(
    $prescriptionTemplateInput: PrescriptionTemplateInput!
  ) {
    createPrescriptionTemplate(input: $prescriptionTemplateInput) {
      template {
        ...PrescriptionTemplate
      }
    }
  }

  mutation UpdatePrescriptionTemplate(
    $uuid: UUID!
    $prescriptionTemplateInput: PrescriptionTemplateInput!
  ) {
    updatePrescriptionTemplate(uuid: $uuid, input: $prescriptionTemplateInput) {
      template {
        ...PrescriptionTemplate
      }
    }
  }

  mutation DeletePrescriptionTemplate($uuid: UUID!) {
    deletePrescriptionTemplate(uuid: $uuid) {
      prescriptionTemplateUuid
    }
  }
`;

export const TemplatesSettingsPrescriptions = ({
  newTemplate,
  onHideNewTemplate,
}: {
  newTemplate: boolean;
  onHideNewTemplate: () => void;
}) => {
  const { hasPermission } = useUser();
  const t = useTranslation();

  if (!hasPermission("EDIT_PRESCRIPTION_TEMPLATES")) return <NotFound />;

  return (
    <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
      {newTemplate && (
        <PrescriptionTemplateForm
          template={undefined}
          onHide={onHideNewTemplate}
        />
      )}
      <Query query={PrescriptionTemplates}>
        {(templates) =>
          templates.isEmpty() && !newTemplate ? (
            <EmptyTemplatesPlaceholder
              title={t("settings.templates.prescriptions.empty_state_title")}
              subtitle={t(
                "settings.templates.prescriptions.empty_state_subtitle",
              )}
            />
          ) : (
            <>
              {templates.map((template) => (
                <PrescriptionTemplateForm
                  key={template.uuid}
                  template={template}
                  onHide={onHideNewTemplate}
                />
              ))}
            </>
          )
        }
      </Query>
    </Background>
  );
};

const PrescriptionTemplateForm = ({
  template,
  onHide,
}: {
  template?: PrescriptionTemplateFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();

  const [createPrescriptionTemplate, createPrescriptionTemplateLoading] =
    useMutation(CreatePrescriptionTemplate, {
      onSuccess: (output, client) => {
        addPrescriptionTemplateInCache(client, output.template);
        onHide();
        notifier.success(
          t("settings.templates.prescriptions.created_notifier"),
        );
      },
    });

  const [updatePrescriptionTemplate, updatePrescriptionTemplateLoading] =
    useMutation(UpdatePrescriptionTemplate, {
      onSuccess: () =>
        notifier.success(
          t("settings.templates.prescriptions.updated_notifier"),
        ),
    });

  const { refetch } = useQuery(PrescriptionTemplates);

  const [deletePrescriptionTemplate] = useMutation(DeletePrescriptionTemplate, {
    onSuccess: (output, client) => {
      removePrescriptionTemplateInCache(
        client,
        output.prescriptionTemplateUuid,
      );
      refetch();
      notifier.success(t("settings.templates.prescriptions.deleted_notifier"));
    },
  });

  return (
    <TemplateSettingsForm
      template={template}
      onHide={onHide}
      icon={{
        name: "pill",
        colorsClassNames: "text-indigo-300 bg-indigo-100",
      }}
      titlePlaceholder={t("settings.templates.prescriptions.title_placeholder")}
      textPlaceholder={t("settings.templates.prescriptions.text_placeholder")}
      submitLabel={t("settings.templates.prescriptions.form_save")}
      deleteLabel={t("settings.templates.prescriptions.form_delete")}
      deletionConfirmationModal={{
        title: t(
          "settings.templates.prescriptions.delete_prescription_template_title",
        ),
        suffix: t(
          "settings.templates.prescriptions.delete_prescription_template_suffix",
        ),
      }}
      onSubmit={(values) => {
        const input = {
          title: values.title,
          text: values.text,
        };
        return template === undefined
          ? createPrescriptionTemplate({
              prescriptionTemplateInput: input,
            })
          : updatePrescriptionTemplate({
              uuid: template.uuid,
              prescriptionTemplateInput: input,
            });
      }}
      loading={
        createPrescriptionTemplateLoading || updatePrescriptionTemplateLoading
      }
      onDelete={(uuid) => deletePrescriptionTemplate({ uuid })}
    />
  );
};
