import { useField } from "hooks/useField";
import { Bill } from "types";

import { CptCodePicker } from "./CptCodePicker";

export const FormCptCodePicker = ({
  name,
  label,
  disabled: disabledProp,
  icd10Codes,
}: {
  name: string;
  label?: string;
  disabled?: boolean;
  icd10Codes?: string[];
}) => {
  const [{ value, disabled }, { error }, { setValue }] = useField<
    Bill["cptCodes"]
  >({
    name,
    disabled: disabledProp,
  });

  return (
    <CptCodePicker
      value={value}
      label={label}
      disabled={disabled}
      error={error}
      icd10Codes={icd10Codes}
      onChange={setValue}
    />
  );
};
