import { CSSProperties, useState } from "react";

import { Button } from "components/Button/Button";
import { useUserForPrescriptions } from "components/EHRComposer/Prescriptions/useUserForPrescriptions";
import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { Modal, ModalProps } from "components/Modal/Modal";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import { usePDFPreview } from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  AppointmentSummaryFragment,
  CreatePatientDocument,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useUploadFile } from "graphql-client/useUploadFile";
import { now } from "utils/date";
import { notifier } from "utils/notifier";
import { getSignatureSrc } from "utils/signature";

import cerfa from "./cerfa.png";

export const CerfaGenerationModal = ({
  appointment,
  onHide,
  ...modalProps
}: { appointment: AppointmentSummaryFragment } & ModalProps) => {
  const { patient } = usePatient();
  const userWithPrescriptionInfo = useUserForPrescriptions();
  const signatureSrc = getSignatureSrc(userWithPrescriptionInfo.signature);
  const { previewRef, generatePdf } = usePDFPreview();
  const uploadFile = useUploadFile();

  const [tcg, setTcg] = useState(false);
  const [specialAccess, setSpecialAccess] = useState(false);

  const [hasCMU, setHasCMU] = useState(false);
  const [hasCS, setHasCS] = useState(false);
  const [hasMPC, setHasMPC] = useState(false);
  const [hasMCS, setHasMCS] = useState(false);

  const [createDocument] = useMutation(CreatePatientDocument, {
    onSuccess: () => {
      notifier.success("La feuille de soin a bien été créée.");
      onHide();
    },
  });

  const priceCS = 23;
  const priceMPC = 2;
  const priceMCS = 5;

  const totalPrice =
    priceCS * Number(hasCS) +
    priceMPC * Number(hasMPC) +
    priceMCS * Number(hasMCS);

  const saveCerfa = async (blob: Blob) => {
    const file = new File([blob], "Feuille de soin.pdf", {
      type: "application/pdf",
    });
    const upload = await uploadFile({ purpose: "PATIENT_DOCUMENT", file });
    if (!upload) return;

    await createDocument({
      title: "Feuille de soin",
      visibleToPatient: true, // Need this to send an email to the patient.
      category: "UNKNOWN",
      patientUuid: patient.uuid,
      upload,
      description: "",
      realizedAt: {
        dateTime: new Date().toISOString(),
        granularity: "DAY",
      },
      appointmentUuid: appointment.uuid,
    });
  };

  const date =
    appointment.state.__typename === "AppointmentStateFinalized"
      ? appointment.state.finalizedAt
      : now();

  const patientHasRequiredInfo =
    patient.birthDate !== null &&
    patient.firstName.isNotBlank() &&
    patient.lastName.isNotBlank() &&
    patient.healthcareSystemIdentifier?.isNotBlank() === true;

  const cerfaEntries: {
    text: string | undefined | null;
    style: CSSProperties;
    hide?: boolean;
  }[] = [
    {
      text: date.format({ exception: "ddMMyyyy" }),
      style: { top: 53, left: 622, letterSpacing: 10 },
    },
    {
      text: `${patient.lastName} ${patient.firstName}`,
      style: { top: 105, left: 150, letterSpacing: 4 },
    },
    {
      text: patient.healthcareSystemIdentifier,
      style: { top: 138, left: 230, letterSpacing: 10 },
    },
    {
      text: patient.birthDate
        ? patient.birthDate
            .toIsoStringAtUtcMidnight()
            .format({ exception: "ddMMyyyy" })
        : "",
      style: { top: 165, left: 230, letterSpacing: 9 },
    },
    {
      text: userWithPrescriptionInfo.hasAllRequiredFields
        ? `${userWithPrescriptionInfo.prefix ?? ""} ${
            userWithPrescriptionInfo.firstName
          } ${userWithPrescriptionInfo.lastName}`
        : null,
      style: { top: 310, left: 50 },
    },
    {
      text: userWithPrescriptionInfo.rpps
        ? `${userWithPrescriptionInfo.rpps} RPPS`
        : null,
      style: { top: 350, left: 50, letterSpacing: 5 },
    },
    {
      text: userWithPrescriptionInfo.prescriptionAddress
        ? `${userWithPrescriptionInfo.prescriptionAddress.address}`
        : null,
      style: { top: 310, left: 410 },
    },
    {
      text: userWithPrescriptionInfo.prescriptionAddress
        ? `${userWithPrescriptionInfo.prescriptionAddress.zipCode} ${userWithPrescriptionInfo.prescriptionAddress.city}`
        : null,
      style: { top: 330, left: 410 },
    },
    {
      text: userWithPrescriptionInfo.amNumber
        ? `${userWithPrescriptionInfo.amNumber} N AM`
        : null,
      style: { top: 380, left: 410, letterSpacing: 5 },
    },
    {
      text: "X",
      style: { top: 475, left: 50 },
    },
    {
      text: "X",
      style: { top: 685, left: 160 },
      hide: !specialAccess,
    },
    {
      text: date.format({ exception: "ddMMyyyy" }),
      style: { top: 800, left: 45, letterSpacing: 4.5 },
    },
    {
      text: tcg ? " TCG" : "TC",
      style: { top: 800, left: 162, letterSpacing: 10 },
    },
    {
      text: date.format({ exception: "ddMMyyyy" }),
      style: { top: 832, left: 45, letterSpacing: 4.5 },
      hide: !hasCS,
    },
    {
      text: "CS",
      style: { top: 832, left: 162, letterSpacing: 10 },
      hide: !hasCS,
    },
    {
      text: priceCS.toString(),
      style: { top: 832, left: 482, letterSpacing: 10 },
      hide: !hasCS,
    },
    {
      text: date.format({ exception: "ddMMyyyy" }),
      style: { top: 865, left: 45, letterSpacing: 4.5 },
      hide: !hasMPC,
    },
    {
      text: "MPC",
      style: { top: 865, left: 162, letterSpacing: 10 },
      hide: !hasMPC,
    },
    {
      text: priceMPC.toString(),
      style: { top: 865, left: 482, letterSpacing: 10 },
      hide: !hasMPC,
    },
    {
      text: date.format({ exception: "ddMMyyyy" }),
      style: { top: 894, left: 45, letterSpacing: 4.5 },
      hide: !hasMCS,
    },
    {
      text: "MCS",
      style: { top: 894, left: 162, letterSpacing: 10 },
      hide: !hasMCS,
    },
    {
      text: priceMCS.toString(),
      style: { top: 894, left: 482, letterSpacing: 10 },
      hide: !hasMCS,
    },
    {
      text: totalPrice.toString(),
      style: { top: 800, left: 482, letterSpacing: 10 },
    },
    {
      text: totalPrice.toString(),
      style: { top: 940, left: 428, letterSpacing: 10 },
    },
    {
      text: "X",
      style: { top: 974, left: 342 },
      hide: !hasCMU,
    },
    {
      text: "X",
      style: { top: 974, left: 726 },
      hide: !hasCMU,
    },
    {
      text: "X",
      style: { top: 1015, left: 726 },
      hide: !hasCMU,
    },
  ];

  return (
    <Modal
      title="Envoyer une feuille de soins"
      className="flex items-center space-y-20"
      onHide={onHide}
      {...modalProps}
    >
      {!patientHasRequiredInfo && (
        <div className="mt-4 flex-col items-center text-center font-semibold text-danger p-10 bg-grey-100 rounded">
          Certaines informations patient sont manquantes (prénom, nom, numéro de
          téléphone ou numéro de sécurité sociale).
        </div>
      )}
      {!userWithPrescriptionInfo.hasAllRequiredFields && (
        <div className="mt-4 flex-col items-center text-center font-semibold text-danger p-10 bg-grey-100 rounded">
          Vous n'avez pas rempli les informations nécessaires à l'élaboration de
          votre feuille de soins. Merci de les remplir dans la section
          Informations personnelles (onglet "Ordonnance").
        </div>
      )}

      <div className="flex-col space-y-8">
        <div className="flex items-center space-x-18">
          <CheckBox
            name="tcg"
            label="TCG"
            checked={tcg}
            className="text-11 flex items-center flex-fill"
            onChange={(val) => setTcg(val)}
          />
          <CheckBox
            name="specialAccess"
            label="Accès direct spécifique"
            className="text-11 flex items-center flex-fill"
            checked={specialAccess}
            onChange={(val) => setSpecialAccess(val)}
          />
          <CheckBox
            name="hasCMU"
            label="CMU"
            className="text-11 flex items-center flex-fill"
            checked={hasCMU}
            onChange={(val) => setHasCMU(val)}
          />
        </div>
        <div className="flex items-center space-x-18">
          <CheckBox
            name="hasCS"
            label="CS"
            className="text-11 flex items-center flex-fill"
            checked={hasCS}
            onChange={(val) => setHasCS(val)}
          />
          <CheckBox
            name="hasMPC"
            label="MPC"
            className="text-11 flex items-center flex-fill"
            checked={hasMPC}
            onChange={(val) => setHasMPC(val)}
          />
          <CheckBox
            name="hasMCS"
            label="MCS"
            className="text-11 flex items-center flex-fill"
            checked={hasMCS}
            onChange={(val) => setHasMCS(val)}
          />
        </div>
      </div>
      <PDFPreview
        previewRef={previewRef}
        documentClassName="w-full"
        scale={0.5}
        style={{
          backgroundImage: `url('${cerfa}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <>
          {cerfaEntries.map(({ text, style, hide }, i) =>
            text && !hide ? (
              <CerfaTextEntry key={i} text={text} style={style} />
            ) : null,
          )}
          {signatureSrc && (
            <img
              src={signatureSrc}
              alt="signature"
              className="object-contain absolute"
              style={{ height: 44, top: 1000, left: 180 }}
            />
          )}
        </>
      </PDFPreview>
      <Button
        label="Envoyer"
        disabled={
          !patientHasRequiredInfo ||
          !userWithPrescriptionInfo.hasAllRequiredFields
        }
        onClick={() => generatePdf().then(saveCerfa)}
      />
    </Modal>
  );
};

const CerfaTextEntry = ({
  text,
  style,
}: {
  text: string;
  style?: CSSProperties;
}) => (
  <div
    className="w-full text-black text-16 font-semibold text-primary-dark uppercase absolute"
    style={{ fontFamily: "Courier", ...style }}
  >
    {text}
  </div>
);
