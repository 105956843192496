import { useState } from "react";

import { DoctorRow } from "components/Doctor/DoctorRow";
import { Modal } from "components/Modal/Modal";
import { useDoctor } from "contexts/User/UserContext";
import {
  MessageFragment,
  MessageReactionTypeKnownValues,
} from "generated/provider";
import { useTranslation } from "i18n";
import { isKnownValue } from "utils/enum";

import { MessageReactionTypeToEmoji } from "./display";

export const MessageReactions = ({ message }: { message: MessageFragment }) => {
  const t = useTranslation();
  const [showReactions, setShowReactions] = useState(false);
  const { user } = useDoctor();

  return (
    <>
      <button
        className="absolute bg-white shadow-sm-outlined rounded text-11 font-medium flex items-center space-x-4 px-6 py-4 -bottom-14 right-6"
        onClick={() => {
          setShowReactions(true);
        }}
      >
        {message.reactions
          .distinctBy((r) => r.type)
          .map((r) =>
            isKnownValue(r.type, MessageReactionTypeKnownValues) ? (
              <span key={r.type}>{MessageReactionTypeToEmoji[r.type]}</span>
            ) : null,
          )}
        <span
          className={
            message.reactions.some((r) => r.doctor.uuid === user.uuid)
              ? "text-primary"
              : undefined
          }
        >
          {message.reactions.length}
        </span>
      </button>
      {showReactions && (
        <Modal
          title={t(
            "conversation_content.timeline.message_container.message_reactions.reactions_to_the_message",
          )}
          onHide={() => setShowReactions(false)}
        >
          <div className="w-full px-18 flex-col divide-y">
            {message.reactions.map((r) =>
              isKnownValue(r.type, MessageReactionTypeKnownValues) ? (
                <div
                  key={r.doctor.uuid}
                  className="w-full py-12 flex items-center"
                >
                  <DoctorRow doctor={r.doctor} />
                  <div className="ml-auto text-18">
                    {MessageReactionTypeToEmoji[r.type]}
                  </div>
                </div>
              ) : null,
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
