import { RefObject, useState } from "react";
import gql from "graphql-tag";

import { FormNoteSectionsComposer } from "components/Form/Editor/NoteSections/FormNoteSectionsComposer";
import { NoteSectionsViewer } from "components/Form/Editor/NoteSections/NoteSectionsViewer";
import { NoteSectionWithComposerFields } from "components/Form/Editor/NoteSections/types";
import { createEmptySection } from "components/Form/Editor/NoteSections/utils";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { PDFPreview } from "components/PDFPreview/PDFPreview";
import { usePDFPreview } from "components/PDFPreview/usePDFPreview";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { useCustomizedAutocompleteModel } from "contexts/User/useCustomizedAutocompleteModel";
import {
  CreateNote,
  PatientNoteFragment,
  UpdateNote,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useNoteDraft } from "hooks/useNoteDraft";
import { useTranslation } from "i18n";
import { downloadFile } from "utils/file";
import {
  getSectionInput,
  noteSectionsFromPatientNoteSection,
  notifyNoteCreationOrUpdate,
} from "utils/notes";

import { FooterWrapper } from "../FooterWrapper";

gql`
  mutation CreateNote(
    $patientUuid: UUID!
    $title: String!
    $sections: [PatientNoteSectionInput!]!
    $experienceUuid: UUID
  ) {
    createPatientNote(
      patientUuid: $patientUuid
      title: $title
      sections: $sections
      experienceUuid: $experienceUuid
    ) {
      note {
        ...PatientNote
      }
    }
  }

  mutation UpdateNote(
    $patientNoteUuid: UUID!
    $title: String!
    $sections: [PatientNoteSectionInput!]!
  ) {
    updatePatientNote(
      patientNoteUuid: $patientNoteUuid
      title: $title
      sections: $sections
    ) {
      note {
        ...PatientNote
      }
    }
  }
`;

type NoteComposerState = {
  title: string;
  sections: NoteSectionWithComposerFields[];
};

export const NoteComposer = ({
  state,
  inputRef,
}: {
  state:
    | { mode: "create" }
    | {
        mode: "note-edit";
        note: PatientNoteFragment;
        onSuccess?: (note: PatientNoteFragment) => void;
      };
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { patient, setEHRComposerState } = usePatient();
  const [exportError, setExportError] = useState(false);
  const { previewRef, generatePdf } = usePDFPreview();

  const [createNote, creatingNote] = useMutation(CreateNote);
  const [updateNote, updatingNote] = useMutation(UpdateNote);

  const initialNote = state.mode === "note-edit" ? state.note : undefined;
  const { noteDraft, setNoteDraft, deleteNoteDraft } = useNoteDraft(
    patient.uuid,
    undefined,
  );
  const autocomplete = useCustomizedAutocompleteModel({
    patient: undefined,
    useCase: "EHR",
  });

  return (
    <Form<NoteComposerState>
      className="w-full flex-fill flex-col"
      initialValues={{
        title: initialNote?.title ?? noteDraft?.title ?? "",
        sections: (
          initialNote?.sections.let((it) =>
            noteSectionsFromPatientNoteSection(it),
          ) ??
          noteDraft?.sections ?? [createEmptySection("FREE_TEXT", "NEUTRAL")]
        ).map((it) => ({
          ...it,
          navigationState: "NEUTRAL",
        })),
      }}
      disabled={creatingNote || updatingNote}
      validationSchema={{ title: "required", sections: "required" }}
      onSubmit={async (values) => {
        if (initialNote) {
          await updateNote(
            {
              title: values.title,
              sections: values.sections.map((it) => getSectionInput(it)),
              patientNoteUuid: initialNote.uuid,
            },
            {
              onSuccess: (data) => {
                notifyNoteCreationOrUpdate(t, values.sections, true);
                deleteNoteDraft();
                if (state.mode === "note-edit") {
                  // Should always be true but typescript is not smart enough
                  state.onSuccess?.(data.note);
                }
                setEHRComposerState(undefined);
              },
            },
          );
        } else {
          await createNote(
            {
              title: values.title,
              sections: values.sections.map((it) => getSectionInput(it)),
              patientUuid: patient.uuid,
            },
            {
              onSuccess: () => {
                notifyNoteCreationOrUpdate(t, values.sections, false);
                setEHRComposerState(undefined);
              },
            },
          );
        }
      }}
    >
      <FormState<NoteComposerState>>
        {({ values }) => (
          <>
            <FormInput
              name="title"
              inputRef={inputRef}
              autoFocus
              placeholder={t(
                "inboxes.qa_experience.ehr_composer.ehr_composer_title.title",
              )}
              className="text-primary-dark text-18 font-medium"
              wrapperClassName="w-auto mx-28"
              onChange={(e) => {
                if (!initialNote) {
                  setNoteDraft({
                    ...values,
                    title: e.target.value,
                    updatedAt: new Date(),
                  });
                }
              }}
            />
            <FormNoteSectionsComposer
              name="sections"
              style={{ marginLeft: -24, marginTop: 24 }}
              onChange={(newSections) => {
                if (!initialNote) {
                  setNoteDraft({
                    ...values,
                    sections: newSections,
                    updatedAt: new Date(),
                  });
                }
              }}
              autocomplete={autocomplete}
            />
            <FooterWrapper
              submitLabel={
                initialNote
                  ? t(
                      "inboxes.qa_experience.ehr_composer.note_composer.note_composer.edit_the_note",
                    )
                  : t(
                      "inboxes.qa_experience.ehr_composer.note_composer.note_composer.publish_the_note",
                    )
              }
              inputRef={inputRef}
            >
              <TooltipWrapper
                position="right"
                label={
                  <>
                    {[
                      t(
                        "inboxes.qa_experience.ehr_composer.note_composer.note_composer.click_to_add_section",
                      ),
                      t(
                        "inboxes.qa_experience.ehr_composer.note_composer.note_composer.add_medical_record_hint",
                      ),
                    ].map((hint, index) => (
                      <div key={index}>{hint}</div>
                    ))}
                  </>
                }
              >
                <Icon name="info" className="hover:text-primary" />
              </TooltipWrapper>
              <TooltipWrapper
                position="right"
                label={t(
                  "inboxes.qa_experience.ehr_composer.note_composer.note_composer.export_in_pdf",
                )}
              >
                <>
                  <ClickableIcon
                    name="download"
                    className="hover:text-primary"
                    onClick={async () => {
                      if (values.title.isBlank() || values.sections.isEmpty()) {
                        setExportError(true);
                        return;
                      }
                      setExportError(false);
                      downloadFile(
                        await generatePdf(),
                        `${patient.firstName}_${patient.lastName}_${values.title}.pdf`,
                      );
                    }}
                  />
                  {exportError && (
                    <div className="text-danger">
                      {t(
                        "inboxes.qa_experience.ehr_composer.note_composer.note_composer.add_a_title",
                      )}
                    </div>
                  )}
                  <div className="hidden">
                    <PDFPreview previewRef={previewRef}>
                      <div className="p-40 flex-col">
                        <NoteSectionsViewer
                          title={values.title}
                          sections={values.sections}
                        />
                      </div>
                    </PDFPreview>
                  </div>
                </>
              </TooltipWrapper>
            </FooterWrapper>
          </>
        )}
      </FormState>
    </Form>
  );
};
