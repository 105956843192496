import { Fragment, useRef, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { useAddTemplateWindow } from "atoms/useAddTemplateWindow";
import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { Input } from "components/Form/Input/Input";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { QueryResult } from "components/Query/QueryResult";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useDoctor } from "contexts/User/UserContext";
import {
  FileUploadFragment,
  MixedQAInboxSearch,
  MixedQAInboxSearchData,
  MixedQAInboxSearchResultType,
} from "generated/provider";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useDebounceSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { IconName } from "icon-library";
import { TemplateResult } from "types";
import { pluralize } from "utils/display";

import { useQACopilotPanel } from "../../QACopilotContext/QACopilotPanelContext";
import { Experience } from "../components/Experience";
import { MessageResultRow, Row } from "../components/Row";
import { SearchPage } from "../components/SearchPage";
import { SuggestedAnswer } from "../components/SuggestedAnswer";
import { Template } from "../components/Template";
import { Filter, SearchPanelState } from "../types";
import { getVariables } from "../utils";
import { Copilot } from "./Copilot";

gql`
  query MixedQAInboxSearch(
    $freeText: String
    $cursor: String
    $resultTypes: [MixedQAInboxSearchResultType!]!
    $contextExperienceUuid: UUID
  ) {
    mixedQaInboxSearch(
      filter: { freeText: $freeText, resultTypes: $resultTypes }
      context: { experienceUuid: $contextExperienceUuid }
    ) {
      totalCount
      result(page: { numberOfItems: 15, cursor: $cursor }) {
        hasMore
        nextCursor
        data {
          ... on ExperienceWithHighlightedMessage {
            experience {
              uuid
              title
              createdAt
              patient {
                uuid
                username
              }
              isClosed
              assignedDoctors {
                uuid
              }
            }
            message {
              uuid
              eventTime
              sender {
                ...UserSummary
              }
              senderType
              content {
                ...MessageContentSummary
              }
            }
          }
          ... on Template {
            uuid
            title
            content
            fileUpload {
              ...FileUpload
            }
          }
        }
      }
    }
  }
`;

export const Search = ({
  experienceUuid,
  onContentSelected,
  onFileSelected,
}: {
  experienceUuid: UUID | null;
  onContentSelected: ((value: string) => void) | null;
  onFileSelected?: (file: FileUploadFragment) => void;
}) => {
  const t = useTranslation();
  const { hasPermission } = useDoctor();
  const { closeCopilotPanel } = useQACopilotPanel();
  const inputRef = useRef<HTMLInputElement>(null);
  const { open } = useAddTemplateWindow();
  const canViewTemplates = hasPermission("VIEW_TEMPLATES");
  const canViewExperiences = hasPermission("VIEW_ANY_QA_EXPERIENCE");
  const [filter, setFilter] = useState<Filter>("ALL");
  const { search, inputProps } = useDebounceSearch();

  const filters: [Filter, IconName, string][] = [
    ["ALL", "menu", t("inboxes.qa_side_panel.search_panel.search.all")],
    ["EXPERIENCE", "chat", "Questions"],
    [
      "TEMPLATE",
      "floppyDisk",
      t("inboxes.qa_side_panel.search_panel.search.templates"),
    ],
  ];

  const searchQuery = usePaginatedQuery(MixedQAInboxSearch, {
    selector: (d) => d.result,
    uuidSelector: (it: MixedQAInboxSearchData["result"]["data"][0]) =>
      it?.__typename === "Template"
        ? it.uuid
        : it?.__typename === "ExperienceWithHighlightedMessage"
        ? it.experience.uuid
        : null,
    ...(search
      ? {
          variables: getVariables({
            search,
            filter,
            canViewExperiences,
            canViewTemplates,
            contextExperienceUuid: experienceUuid,
          }),
        }
      : { skip: true }),
  });

  const zeroStateQuery = usePaginatedQuery(MixedQAInboxSearch, {
    selector: (d) => d.result,
    uuidSelector: (it: MixedQAInboxSearchData["result"]["data"][0]) =>
      it?.__typename === "Template"
        ? it.uuid
        : it?.__typename === "ExperienceWithHighlightedMessage"
        ? it.experience.uuid
        : null,
    variables: {
      resultTypes: [
        canViewTemplates ? ["TEMPLATE" as MixedQAInboxSearchResultType] : null,
        canViewExperiences
          ? (["EXPERIENCE_WITH_MESSAGE"] as MixedQAInboxSearchResultType[])
          : null,
      ]
        .flat()
        .filterNotNull(),
      contextExperienceUuid: experienceUuid,
    },
  });
  const actualQuery = search ? searchQuery : zeroStateQuery;
  const showRecommendations = experienceUuid !== null && search === undefined;
  const [state, setState] = useState<SearchPanelState>({ mode: "SEARCH" });

  return (
    <QueryResult {...actualQuery}>
      {({ result }) => (
        <>
          {state.mode === "SEARCH" ? (
            <SearchPage
              title={
                <div className="flex items-center space-x-10">
                  <Icon name="wand" />
                  <span>Copilot</span>
                </div>
              }
              subtitle={
                searchQuery.data
                  ? pluralize(
                      searchQuery.data.totalCount,
                      t("inboxes.qa_side_panel.search_panel.search.result"),
                    )
                  : undefined
              }
              rightTitleElement={
                <ClickableIcon
                  name="close"
                  className="p-4"
                  onClick={closeCopilotPanel}
                />
              }
            >
              <div className="flex items-center">
                <Input
                  {...inputProps}
                  inputRef={inputRef}
                  autoFocus
                  placeholder={
                    {
                      ALL: t("search.search_all"),
                      EXPERIENCE: t("search.search_conversations"),
                      TEMPLATE: t("search.search_templates"),
                    }[filter]
                  }
                  wrapperClassName="flex-fill mr-10"
                  leftInnerElement="search"
                  gray
                  rightInnerElement={
                    inputProps.value.isNotEmpty() ? (
                      <ClickableIcon
                        name="close"
                        onClick={() => {
                          inputProps.onChange({ currentTarget: { value: "" } });
                          inputRef.current!.focus();
                        }}
                      />
                    ) : null
                  }
                />
                <UncontrolledPopover
                  noArrow
                  position="bottom-right"
                  className="mt-4"
                  content={
                    <div className="flex-col py-12">
                      {filters.map(([value, icon, label]) =>
                        value === "EXPERIENCE" &&
                        !canViewExperiences ? null : value === "TEMPLATE" &&
                          !canViewTemplates ? null : (
                          <button
                            key={value}
                            className={classNames("list-element menu", {
                              highlighted: value === filter,
                            })}
                            onClick={() => setFilter(value)}
                          >
                            <Icon name={icon} className="mr-8" />
                            {label}
                          </button>
                        ),
                      )}
                    </div>
                  }
                >
                  {({ opened, setTarget }) => (
                    <ClickableIcon
                      name="filter"
                      onClick={setTarget}
                      className={classNames("hover:bg-grey-100 rounded", {
                        "text-primary": opened || filter !== "ALL",
                      })}
                    />
                  )}
                </UncontrolledPopover>
                <TooltipWrapper
                  label={t(
                    "inboxes.qa_side_panel.search_panel.search.create_a_template",
                  )}
                  position="left"
                >
                  <ClickableIcon
                    name="add"
                    className="hover:bg-grey-100 rounded ml-2"
                    onClick={() => open()}
                  />
                </TooltipWrapper>
              </div>
              {result.data.isEmpty() ? (
                <div className="flex-fill flex-col flex-center">
                  <div className="text-primary-dark font-medium text-18">
                    {search
                      ? t("inboxes.qa_side_panel.search_panel.search.no_result")
                      : canViewTemplates
                      ? t(
                          "inboxes.qa_side_panel.search_panel.search.no_template",
                        )
                      : t(
                          "inboxes.qa_side_panel.search_panel.search.cannot_view_templates",
                        )}
                  </div>
                  {hasPermission("EDIT_TEMPLATES") ? (
                    <Button
                      className="mt-12"
                      onClick={() => open()}
                      label={t(
                        "inboxes.qa_side_panel.search_panel.search.create_a_template",
                      )}
                    />
                  ) : (
                    <Icon name="search" size={25} className="mt-12" />
                  )}
                </div>
              ) : (
                <div className="mt-20 flex-col space-y-12 items-center overflow-auto">
                  {showRecommendations && (
                    <Copilot
                      onContentSelected={onContentSelected}
                      setState={setState}
                    />
                  )}
                  {result.data.map((element, index) => {
                    if (
                      element?.__typename === "ExperienceWithHighlightedMessage"
                    ) {
                      return (
                        <MessageResultRow
                          key={element.experience.uuid}
                          message={element.message}
                          search={search ?? null}
                          onClick={() =>
                            setState({
                              mode: "EXPERIENCE",
                              experience: element.experience,
                            })
                          }
                        />
                      );
                    }

                    if (element?.__typename === "Template") {
                      return (
                        <Fragment key={element.uuid}>
                          {index === 0 && showRecommendations && (
                            <div className="w-full text-16 font-medium text-primary-dark">
                              {t(
                                "inboxes.qa_side_panel.search_panel.search.templates",
                              )}
                            </div>
                          )}
                          <Row
                            icon={{
                              name: "floppyDisk",
                              iconClassName: "bg-success-100 text-success",
                            }}
                            title={element.title}
                            content={
                              element.content
                                ? element.content
                                : element.fileUpload
                                ? `${element.fileUpload.fileName} (${element.fileUpload.mimeType})`
                                : ""
                            }
                            contentClassName="line-clamp-5"
                            date={null}
                            search={
                              search
                                ? { value: search, includeTitle: false }
                                : null
                            }
                            onClick={() =>
                              setState({
                                mode: "TEMPLATE",
                                templateUuid: element.uuid,
                              })
                            }
                          />
                        </Fragment>
                      );
                    }
                    return null;
                  })}
                  <NextPageButton {...searchQuery} />
                  <BottomSafeArea />
                </div>
              )}
            </SearchPage>
          ) : state.mode === "EXPERIENCE" ? (
            <Experience
              experience={state.experience}
              onContentSelected={onContentSelected}
              back={() => setState({ mode: "SEARCH" })}
            />
          ) : state.mode === "SUGGESTED_ANSWER" ? (
            <SuggestedAnswer
              textContent={state.textContent}
              onContentSelected={onContentSelected}
              back={() => setState({ mode: "SEARCH" })}
              rank={state.rank}
            />
          ) : (
            <Template
              template={
                result.data.find(
                  (element) =>
                    element &&
                    element.__typename === "Template" &&
                    element.uuid === state.templateUuid,
                ) as TemplateResult
              }
              fromSearch={getVariables({
                search: search!,
                filter,
                canViewExperiences,
                canViewTemplates,
                contextExperienceUuid: experienceUuid,
              })}
              onTextContentSelected={onContentSelected}
              onFileSelected={onFileSelected}
              back={() => setState({ mode: "SEARCH" })}
            />
          )}
        </>
      )}
    </QueryResult>
  );
};
