import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { useSearch } from "hooks/useDebounce";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { HealthDataPointKnownValue, HealthDataPointKnownValues } from "types";
import { displayHealthDataPointName } from "utils/data-points";

const DESKTOP_MENU_WIDTH = 280;

export const DataPointsMenu = ({
  onDatapointSelected,
}: {
  onDatapointSelected: (o: HealthDataPointKnownValue) => void;
}) => {
  const t = useTranslation();
  const inputProps = useSearch();
  const datapointsList = HealthDataPointKnownValues;
  const isDesktop = useIsDesktop();

  const searchResults = datapointsList.filter((datapoint) =>
    displayHealthDataPointName(datapoint).fuzzyMatch(inputProps.value),
  );

  return (
    <UncontrolledPopover
      position="top-right"
      className="mt-6 flex-col overflow-hidden"
      style={{
        maxHeight: "min(500px, 80%)",
        width: isDesktop ? DESKTOP_MENU_WIDTH : undefined,
      }}
      fullWidthOnMobile
      allowScrolling
      content={(close) => (
        <>
          <div className="relative flex items-center">
            <Icon name="search" className="absolute left-8" />
            <input
              {...inputProps}
              className="w-full h-40 pl-36 pr-8"
              autoFocus
              placeholder={t(
                "views.patients.patient_view.datapoints_menu.search",
              )}
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="flex-fill overflow-auto border-t">
            {searchResults.isNotEmpty() ? (
              <div className="flex-col">
                {searchResults.map((datapoint) => (
                  <button
                    className="group"
                    onClick={() => {
                      onDatapointSelected(datapoint);
                      close();
                    }}
                    key={displayHealthDataPointName(datapoint)}
                  >
                    <div className="flex items-center list-element py-8 px-16 space-x-8 border-none">
                      {displayHealthDataPointName(datapoint)}
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="p-12 text-14 opacity-70">
                {t("views.patients.patient_view.datapoints_menu.no_result")}
              </div>
            )}
          </div>
        </>
      )}
    >
      {({ setTarget }) => (
        <ClickableIcon
          name="add"
          size={18}
          className="bg-grey-200/25 hover:bg-grey-200/50 rounded p-[3px] ml-auto -my-2"
          onClick={setTarget}
        />
      )}
    </UncontrolledPopover>
  );
};
