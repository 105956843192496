import { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { Badge } from "@capawesome/capacitor-badge";

import { useExtendedQAInbox } from "hooks/useExtendedQAInbox";

export const useNativeBadge = () => {
  const count = useExtendedQAInbox("ASSIGNED_TO_ME").items.count(
    (i) => !i.seenStatus,
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;
    void Badge.set({ count });
  }, [count]);
};
