import { useEffect, useRef, useState } from "react";
import { Location } from "history";
import { useLocation } from "react-router-dom";

import { useExperienceHasDraft } from "atoms/useDraft";
import { Icon } from "components/Icon/Icon";
import { PopoverMenu } from "components/Menu/PopoverMenu";
import { PatientViewSideSection } from "components/Patient/PatientViewSideSection";
import { PatientTimeline } from "components/Patient/Timeline/PatientTimeline";
import { PatientTimelineLocalStateProvider } from "components/Patient/Timeline/PatientTimelineLocalStateProvider";
import { PatientViewNavigationState } from "components/Patient/utils";
import {
  ChatWidgets,
  ChatWidgetsContext,
} from "contexts/ChatWidgetsContext/ChatWidgetsContext";
import { usePatientViewData } from "contexts/PatientViewContext/PatientViewContext";
import { ExperienceItemSummaryFragment } from "generated/provider";
import { useGoBack, useWindowSize } from "hooks";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { config } from "styles";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";

import { newExperienceDraftKey } from "../../Inboxes/QAExperienceContext/utils";
import { ChatWidget } from "./Chat/ChatWidget";

export const PatientView = ({ patientUuid }: { patientUuid: UUID }) => {
  const t = useTranslation();
  const goBack = useGoBack();
  const location: Location<PatientViewNavigationState> = useLocation();
  const { patientData, todoItems, doneItems } = usePatientViewData();

  const hasNewMessageDraft = useExperienceHasDraft(
    newExperienceDraftKey(patientUuid),
  );
  const [showNewChatWidget, setShowNewChatWidget] =
    useState(hasNewMessageDraft);

  const openNewChatWidget = () => setShowNewChatWidget(true);
  const closeNewChatWidget = () => setShowNewChatWidget(false);

  const [chatWidgets, setChatWidgets] = useState<
    { experienceUuid: UUID; minimized: boolean }[]
  >([]);

  const openChatWidget = (
    targetExperience: Parameters<ChatWidgets["openChatWidget"]>[0],
    minimized = false,
  ) => {
    setChatWidgets((previousWidgets) => [
      ...previousWidgets.filter(
        (it) => it.experienceUuid !== targetExperience.uuid,
      ),
      { experienceUuid: targetExperience.uuid, minimized },
    ]);
  };
  const closeChatWidget = (experienceUuid: UUID) => {
    setChatWidgets((previousWidgets) =>
      previousWidgets.filter((it) => it.experienceUuid !== experienceUuid),
    );
  };
  const toggleChatWidgetMinimize = (experienceUuid: UUID) => {
    setChatWidgets((previousWidgets) =>
      previousWidgets.map((widget) =>
        widget.experienceUuid === experienceUuid
          ? { ...widget, minimized: !widget.minimized }
          : widget,
      ),
    );
  };

  // Allow pre-opening the new chat widget when navigating from other pages.
  useEffect(() => {
    if (location.state?.openNewChatWidget) openNewChatWidget();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Automatically show all opened experiences.
  //
  // For now, the most straightforward way to fetch all opened experiences
  // is to use the to-do items query. This might need revisiting once we
  // introduce pagination.
  const alreadyOpenedExperiencesRef = useRef(new Set<UUID>());
  useEffect(() => {
    // Open the pre-existing experiences in minimized form.
    const minimized = alreadyOpenedExperiencesRef.current.size === 0;

    todoItems.data.forEach((item) => {
      if (
        item.__typename === "Experience" &&
        !alreadyOpenedExperiencesRef.current.has(item.uuid)
      ) {
        alreadyOpenedExperiencesRef.current.add(item.uuid);
        openChatWidget(item, minimized);
      }
    });
  }, [todoItems.data]);

  // FIXME(@liautaud): This only works because there is no pagination.
  const getExperience = (
    experienceUuid: UUID,
  ): ExperienceItemSummaryFragment | null =>
    [...todoItems.data, ...doneItems.data].find(
      (item): item is PatientTimelineValidItemFragmentOfType<"Experience"> =>
        item.__typename === "Experience" && item.uuid === experienceUuid,
    ) ?? null;

  const getExperienceName = (experienceUuid: UUID) =>
    getExperience(experienceUuid)?.optionalTitle?.trimOrNull() ??
    t("patient_view.default_title.experience");

  // Truncate the widgets which don't fit on the screen.
  const screenWidth = useWindowSize().width;
  const displayableWidgetCount = Math.floor(
    screenWidth /
      (config.width["chat-widget"] +
        config.spacing["chat-widget-spacing"] +
        70), // Leave space for the "see more" button.
  );
  const visibleWidgetCount =
    displayableWidgetCount - (showNewChatWidget ? 1 : 0);
  const [hiddenChatWidgets, visibleChatWidgets] = chatWidgets.split(
    Math.max(0, chatWidgets.length - visibleWidgetCount),
  );

  return (
    <ChatWidgetsContext.Provider
      value={{ openChatWidget, openNewChatWidget, closeNewChatWidget }}
    >
      <PatientTimelineLocalStateProvider patientUuid={patientData.patient.uuid}>
        <div className="flex flex-fill">
          <PatientTimeline
            patient={patientData.patient}
            todoItems={todoItems.data}
            doneItems={doneItems.data}
            backIcon={{
              name: "arrow",
              onClick: () => goBack(routes.PATIENTS_INBOX),
            }}
          />
          <PatientViewSideSection
            className="w-full h-full border-l border-grey-200"
            style={{
              minWidth: config.width["patient-sidebar"],
              maxWidth: config.width["patient-sidebar"],
            }}
          />

          {(showNewChatWidget || chatWidgets.isNotEmpty()) && (
            <div className="absolute bottom-0 right-[23px] flex items-end space-x-chat-widget-spacing pointer-events-none">
              {hiddenChatWidgets.isNotEmpty() && (
                <PopoverMenu
                  position="top-left"
                  className="mb-10 min-w-[250px]"
                  noArrow
                  items={hiddenChatWidgets.map(({ experienceUuid }) => ({
                    text: getExperienceName(experienceUuid),
                    onClick: (closeMenu) => {
                      closeMenu();
                      getExperience(experienceUuid)?.let((experience) => {
                        openChatWidget(experience);
                      });
                    },
                  }))}
                >
                  {({ setTarget }) => (
                    <button
                      className="flex-center px-12 space-x-6 bg-white border rounded-t shadow-widget z-chat-widget h-56 pointer-events-auto"
                      onClick={setTarget}
                    >
                      <Icon name="doubleChatBubble" />
                      <div className="font-medium leading-normal text-grey-400 mr-4">
                        +{hiddenChatWidgets.length}
                      </div>
                    </button>
                  )}
                </PopoverMenu>
              )}

              {visibleChatWidgets.map(({ experienceUuid, minimized }) => (
                <ChatWidget
                  key={experienceUuid}
                  patient={patientData.patient}
                  experienceUuid={experienceUuid}
                  minimized={minimized}
                  onClose={() => closeChatWidget(experienceUuid)}
                  onToggleMinimize={() =>
                    toggleChatWidgetMinimize(experienceUuid)
                  }
                />
              ))}

              {showNewChatWidget && (
                <ChatWidget
                  patient={patientData.patient}
                  experienceUuid={undefined}
                  onClose={closeNewChatWidget}
                />
              )}
            </div>
          )}
        </div>
      </PatientTimelineLocalStateProvider>
    </ChatWidgetsContext.Provider>
  );
};
