import { Query } from "components/Query/Query";
import { DoctorTitles } from "generated/provider";
import { useTranslation } from "i18n";

import { FormSelect } from "./FormSelect";

export const FormTitleSelect = () => {
  const t = useTranslation();

  return (
    <Query query={DoctorTitles} noSpinner>
      {({ loading, data }) => (
        <FormSelect
          creatable
          name="title"
          label={t("form.select.form_title_select.title")}
          loading={loading}
          options={data?.titles ?? []}
          placeholder={t("form_title_select.gp_gynaecologist_")}
        />
      )}
    </Query>
  );
};
