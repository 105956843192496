import {
  PatientSummaryFragment,
  PublicPatientFragment,
} from "generated/provider";
import { displayPatientForInput } from "utils/display";

export const replaceUsername = (
  message: string,
  patient: PatientSummaryFragment | PublicPatientFragment | null,
) => {
  const match = message.match(/^(Bonjour|Hello) ([^\s]+),/u);
  return match && patient?.username
    ? message.replace(match[2], displayPatientForInput(patient))
    : message;
};
