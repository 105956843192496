import { Media } from "atoms/useMediaWindows";

import { isRemoteMedia } from "../mediaWindowsModel";
import { AbstractMediaContent } from "./mediaContentModel";

export const VideoMediaContent: AbstractMediaContent = ({
  media,
}: {
  media: Media;
}) => {
  if (!isRemoteMedia(media)) {
    // Handle blob media is needed
    return null;
  }

  return (
    <video
      src={media.url}
      className="flex-fill w-full object-contain"
      controls
    />
  );
};
