import { AppointmentFragment } from "generated/provider";

type AppointmentSummary = Pick<
  AppointmentFragment,
  "__typename" | "startAt" | "state" | "doctor"
>;

export const appointmentImminentAt = (appointment: AppointmentSummary) =>
  appointment.startAt.minusMinutes(10);

// Whether the doctor can join the LiveKit room for a given appointment.
// Also controls whether the appointment is bumped at the top of the timeline.
export const canJoinAppointmentRoom = (
  appointment: AppointmentSummary,
): boolean =>
  appointment.state.__typename === "AppointmentStateScheduled" &&
  appointmentImminentAt(appointment).isPast();
