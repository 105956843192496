import { ReactNode, useCallback, useEffect, useState } from "react";

import { useIsDesktop } from "hooks/useMediaQuery";

import {
  QASidePanelContext,
  QASidePanelContextProps,
  SidePanelState,
} from "./QASidePanelContext";

const getInitialState = (isDesktop: boolean): SidePanelState =>
  isDesktop ? { mode: "PATIENT", showAdminForm: false } : null;

export const QASidePanelProvider = ({
  children,
}: {
  children: ReactNode | ((props: QASidePanelContextProps) => JSX.Element);
}) => {
  const isDesktop = useIsDesktop();
  const [sidePanelState, setSidePanelState] = useState(
    getInitialState(isDesktop),
  );
  const [isTimelineOpen, setIsTimelineOpen] = useState(false);

  // The side view must open/close when the desktop/mobile mode changes
  useEffect(
    () => setSidePanelState(getInitialState(isDesktop)),
    [isDesktop, setSidePanelState],
  );

  const closeSidePanel = useCallback(
    () => setSidePanelState(getInitialState(isDesktop)),
    [isDesktop, setSidePanelState],
  );

  const value: QASidePanelContextProps = {
    sidePanelState,
    setSidePanelState,
    closeSidePanel,
    setIsTimelineOpen,
    isTimelineOpen,
  };

  return (
    <QASidePanelContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </QASidePanelContext.Provider>
  );
};
