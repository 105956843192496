import { ReactElement, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useLoggedInAuth } from "auth/AuthContext";
import { IdentitiesQuery } from "components/IdentityPicker/IdentitiesQuery";
import {
  Identity,
  isPickableIdentity,
  usePickIdentity,
} from "components/IdentityPicker/utils";
import { useMaybeUser } from "contexts/User/UserContext";
import {
  ORGANIZATION_ID_SEARCH_PARAM,
  SUB_ORGANIZATION_ID_SEARCH_PARAM,
} from "utils/environment";

// Automatically switches the user to the right identity if the `organizationId`
// or `subOrganizationId` search parameters are present in the URL. If there is
// no matching identity available, logs out the user.
export const AutomaticIdentitySwitcher = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { canChangeIdentity } = useLoggedInAuth();
  const user = useMaybeUser();

  const [searchParams] = useSearchParams();
  const requestedOrganizationStringId = searchParams
    .get(ORGANIZATION_ID_SEARCH_PARAM)
    ?.trimOrNull();
  const requestedSubOrganizationUuid = searchParams
    .get(SUB_ORGANIZATION_ID_SEARCH_PARAM)
    ?.trimOrNull();

  if (
    canChangeIdentity &&
    ((requestedOrganizationStringId &&
      requestedOrganizationStringId !== user?.organizationStringId) ||
      (requestedSubOrganizationUuid &&
        requestedSubOrganizationUuid !== user?.subOrganizationUuid))
  ) {
    return (
      <IdentitiesQuery>
        {({ identities }) => {
          const matchingIdentity = identities
            .flatMap((it) => it.identitiesForOrganization)
            .find(
              (it) =>
                it.__typename === "Doctor" &&
                (!requestedOrganizationStringId ||
                  it.subOrganization.organization.stringId ===
                    requestedOrganizationStringId) &&
                (!requestedSubOrganizationUuid ||
                  it.subOrganization.uuid === requestedSubOrganizationUuid),
            );

          return <PickIdentityOrLogout matchingIdentity={matchingIdentity} />;
        }}
      </IdentitiesQuery>
    );
  }

  return children;
};

// Removes the organization and sub-organization search parameters from the URL,
// and picks the given identity (or logs out if there is none) when mounted.
const PickIdentityOrLogout = ({
  matchingIdentity,
}: {
  matchingIdentity: Identity | undefined;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useLoggedInAuth();
  const pickIdentity = usePickIdentity();

  useEffect(() => {
    const newUrlParams = new URLSearchParams(searchParams);
    newUrlParams.delete(ORGANIZATION_ID_SEARCH_PARAM);
    newUrlParams.delete(SUB_ORGANIZATION_ID_SEARCH_PARAM);
    setSearchParams(newUrlParams);

    if (matchingIdentity) {
      // There is a matching identity. Pick it directly if possible,
      // otherwise fallback to showing the identity picker. This is
      // especially useful for tentative identities (which need to
      // be accepted or rejected by clicking in the picker).
      pickIdentity(
        isPickableIdentity(matchingIdentity) ? matchingIdentity : null,
        { redirectToIndex: false },
      );
    } else {
      // There is no matching identity, log out instead.
      void auth.logout();
    }
  }, [auth, matchingIdentity, pickIdentity, searchParams, setSearchParams]);
  return null;
};
