import classNames from "classnames";
import { differenceInMilliseconds } from "date-fns";

import { Icon } from "components/Icon/Icon";
import { CalendarAppointmentFragment } from "generated/provider";
import { displayPatient } from "utils/display";

export const AppointmentEvent = ({
  appointment,
}: {
  appointment: CalendarAppointmentFragment;
}) => {
  const duration = differenceInMilliseconds(
    appointment.endAt.getDate(),
    appointment.startAt.getDate(),
  );
  const isSmall = duration <= 20 * 60 * 1000;
  return (
    <button
      onMouseDown={(e) => e.stopPropagation()}
      className={classNames(
        "flex w-full h-full rounded text-white bg-blue-300 relative z-3",
        {
          "text-12 p-2": !isSmall,
          "text-10 px-2": isSmall,
        },
      )}
      style={{
        lineHeight: isSmall ? "14px" : "18px",
      }}
    >
      <div className="flex flex-fill items-center space-x-4">
        <Icon
          name={
            appointment.location.__typename === "RemoteAppointmentLocation"
              ? "videoOn"
              : "medicalOffice"
          }
          size={isSmall ? 14 : 16}
        />
        <div className="flex-fill truncate">
          {displayPatient(appointment.patient)}
        </div>
      </div>
    </button>
  );
};
