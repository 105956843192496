import { Button } from "components/Button/Button";
import { useTranslation } from "i18n";
import { trackEvent } from "tracking";
import { copyToClipBoard } from "utils";

import { SearchPage } from "./SearchPage";

export const SuggestedAnswer = ({
  textContent,
  onContentSelected,
  back,
  rank,
}: {
  textContent: string;
  onContentSelected: ((value: string) => void) | null;
  back: () => void;
  rank: number;
}) => {
  const t = useTranslation();

  return (
    <SearchPage
      title={t("inboxes.qa_side_panel.suggested_answer.suggested_answer")}
      back={back}
    >
      <div className="whitespace-pre-wrap text-primary-dark text-14">
        {textContent}
      </div>
      <Button
        label={
          onContentSelected
            ? t("suggested_answer.use_this_suggestion_in_my_answer")
            : t("inboxes.qa_side_panel.suggested_answer.copy_this_suggestion")
        }
        className="mt-24"
        onClick={() => {
          trackEvent({
            name: "Copilot Suggestion Accepted",
            properties: { rank },
          });
          onContentSelected
            ? onContentSelected(textContent)
            : copyToClipBoard(
                textContent,
                t("inboxes.qa_side_panel.suggested_answer.suggestion_copied"),
              );
        }}
      />
    </SearchPage>
  );
};
