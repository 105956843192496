import { useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import {
  MenuItemProps,
  MultiLevelMenuItemProps,
} from "components/Menu/MenuItem";
import { MultilevelPopoverMenu } from "components/Menu/MultilevelMenu";
import { Spinner } from "components/Spinner/Spinner";
import { useChatWidgets } from "contexts/ChatWidgetsContext/ChatWidgetsContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  GetPatientViewPatientDataData,
  PatientTimelineItemFragment,
  PrescriptionTemplates,
} from "generated/provider";
import { useQuery } from "graphql-client/useQuery";
import { useDocumentUploadMenuSubItems } from "hooks/useDocumentUploadMenuSubItems";
import { useRerenderAt } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";
import { IconName } from "icon-library";
import { filterValidItems } from "types/patient-timeline";
import {
  appointmentImminentAt,
  canJoinAppointmentRoom,
} from "utils/appointment";

import { CreateScheduledWebCallModal } from "../CreateScheduledWebCallModal";
import { CreateUnscheduledWebCallModal } from "../CreateUnscheduledWebCallModal";
import { PatientTimelineExistingItem, PatientTimelineNewItem } from "./Item";
import { useAddPrescription } from "./Item/useAddPrescription";
import { usePatientTimelineLocalState } from "./PatientTimelineLocalStateContext";

// TODO(@liautaud):
//  - P1: Allow pressing ESC to reset forms.
//  - P1: Add memoization to fix performance when showing >100 timeline items.
//  - P2: Profile and improve performance of card open (20ms right now).
//  - P3: Add two-way pagination (or one-way pagination with catch-up
//    scrolling when navigating to an anchor, like SoundCloud favorites).
//  - P3: Benchmark flip-toolkit vs. auto-animate.
//  - P3: Investigate after-flip hover bug.
export const PatientTimeline = ({
  patient,
  todoItems,
  doneItems,
  backIcon,
}: {
  patient: GetPatientViewPatientDataData["patient"];
  todoItems: PatientTimelineItemFragment[];
  doneItems: PatientTimelineItemFragment[];
  backIcon: { name: IconName; onClick: () => void };
}) => {
  const t = useTranslation();
  const { user, hasPermission, hasAccessToGatekeeperFeature } = useDoctor();
  const { openNewChatWidget } = useChatWidgets();
  const { isAddingPrescription } = useAddPrescription(patient.uuid);

  // Bump certain items to the top of the to-do items.
  const [bumpedTodoItems, otherTodoItems] = todoItems.partition(
    (item) => item.__typename === "Appointment" && canJoinAppointmentRoom(item),
  );

  // Re-render the timeline as soon as another to-do item might get bumped.
  useRerenderAt(
    todoItems
      .mapNotNull((item) =>
        item.__typename === "Appointment" ? appointmentImminentAt(item) : null,
      )
      .find((date) => date.isFuture()),
  );

  const orderedTodoItems = [...bumpedTodoItems, ...otherTodoItems];
  const validTodoItems = filterValidItems(orderedTodoItems);
  const validDoneItems = filterValidItems(doneItems);

  const [createWebCallModalType, setCreateWebCallModalType] = useState<
    "SCHEDULED" | "UNSCHEDULED" | undefined
  >();

  const cannotStartConversation =
    patient.qaExperiencesCount >= 1 &&
    user.subOrganization.organization.singleConversationEnabled;
  const canScheduleWebCalls = hasPermission("CREATE_AND_SCHEDULE_WEB_CALLS");
  const { newItems, addNewItem } = usePatientTimelineLocalState();

  const documentItems = useDocumentItems();
  const prescriptionItems = usePrescriptionItems(patient.uuid);

  return (
    <div className="flex-col flex-fill overflow-auto px-timeline-margin py-32">
      {createWebCallModalType === "UNSCHEDULED" && (
        <CreateUnscheduledWebCallModal
          patientUuid={patient.uuid}
          onHide={() => setCreateWebCallModalType(undefined)}
        />
      )}
      {createWebCallModalType === "SCHEDULED" && (
        <CreateScheduledWebCallModal
          forcedPatientUuid={patient.uuid}
          onHide={() => setCreateWebCallModalType(undefined)}
        />
      )}
      <header className="flex items-center space-x-16">
        <ClickableIcon
          name={backIcon.name}
          className="flex-center w-28 h-28 bg-grey-100 rounded-sm hover:opacity-80"
          onClick={backIcon.onClick}
        />
        <h1 className="text-18 font-bold text-black flex-fill">
          {t("patient_view.activity")}
        </h1>

        <MultilevelPopoverMenu
          position="bottom-right"
          className="mt-10 min-w-[180px]"
          items={[
            {
              icon: "checklist" as const,
              text: t("patient_view.task"),
              onClick: (closeMenu: () => void) => {
                closeMenu();
                addNewItem("Task", {});
              },
            },
            {
              icon: "chatVerticalEmpty" as const,
              text: t("patient_view.note"),
              onClick: (closeMenu: () => void) => {
                closeMenu();
                addNewItem("PatientNote", {});
              },
            },
            documentItems,
            user.subOrganization.locale === "ENGLISH" ||
            hasAccessToGatekeeperFeature("IS_US_COUNTRY")
              ? {
                  icon: "laboratory" as const,
                  text: t("patient_view.medical_order"),
                  onClick: (closeMenu: () => void) => {
                    closeMenu();
                    addNewItem("MedicalOrder", {});
                  },
                }
              : null,
            {
              icon: "doubleChatBubble" as const,
              text: t("patient_view.conversation"),
              onClick: (closeMenu: () => void) => {
                closeMenu();
                openNewChatWidget();
              },
              disable: {
                if: cannotStartConversation,
                tooltip: t("patients.patients.chat_unavailable_limit"),
              },
            },
            canScheduleWebCalls
              ? {
                  icon: "calendar" as const,
                  text: t("patient_view.appointment"),
                  onClick: (closeMenu: () => void) => {
                    closeMenu();
                    setCreateWebCallModalType("SCHEDULED");
                  },
                }
              : null,
            canScheduleWebCalls
              ? {
                  icon: "hyperlink" as const,
                  text: t("patient_view.appointment_booking_link"),
                  onClick: (closeMenu: () => void) => {
                    closeMenu();
                    setCreateWebCallModalType("UNSCHEDULED");
                  },
                }
              : null,
            prescriptionItems,
          ].filterNotNull()}
        >
          {({ setTarget }) =>
            isAddingPrescription ? (
              <div className="flex items-center justify-center flex-center ml-auto w-40 h-40 rounded border border-grey-200 hover:opacity-80">
                <Spinner className="w-28 h-28" />
              </div>
            ) : (
              <ClickableIcon
                name="add"
                className="flex-center ml-auto w-40 h-40 rounded border border-grey-200 hover:opacity-80"
                onClick={setTarget}
              />
            )
          }
        </MultilevelPopoverMenu>
      </header>

      {validTodoItems.isEmpty() &&
        validDoneItems.isEmpty() &&
        newItems.isEmpty() && <Placeholder />}

      <Flipper
        // Animates the items at every render (re-ordering or opening),
        // except during E2E tests to avoid flakyness.
        flipKey={window.E2E ? "never" : []}
        // See https://spring-playground.netlify.app/ for details.
        spring={{ stiffness: 1542, damping: 79 }}
      >
        {newItems.isNotEmpty() && (
          <section className="pt-32 -top-32 -mx-timeline-margin px-timeline-margin shadow-[0_25px_25px_#fff] sticky bg-white z-patient-timeline">
            {newItems.map(({ temporaryUuid, type, payload }) => (
              <PatientTimelineNewItem
                key={temporaryUuid}
                type={type}
                temporaryUuid={temporaryUuid}
                payload={payload}
              />
            ))}
          </section>
        )}

        {validTodoItems.isNotEmpty() && (
          <section>
            <Flipped flipId="header.todo" translate>
              <h3 className="mt-32 mb-4 font-medium text-grey-400">
                {t("patient_view.to_do")}
              </h3>
            </Flipped>

            {validTodoItems.map((item) => (
              <PatientTimelineExistingItem key={item.uuid} item={item} />
            ))}
          </section>
        )}

        {validDoneItems.isNotEmpty() && (
          <section>
            <Flipped flipId="header.done" translate>
              <h3 className="mt-32 mb-4 font-medium text-grey-400">
                {t("patient_view.done")}
              </h3>
            </Flipped>

            {validDoneItems.map((item) => (
              <PatientTimelineExistingItem key={item.uuid} item={item} />
            ))}
          </section>
        )}
      </Flipper>
    </div>
  );
};

const Placeholder = () => {
  const t = useTranslation();
  return (
    <section className="flex-col flex-center flex-fill space-y-4">
      <h3 className="font-medium text-18 text-grey-400">
        {t("patient_view.no_activity_title")}
      </h3>

      <p>
        <Translation
          k="patient_view.no_activity_subtitle"
          components={{
            plusButton: () => (
              <Icon
                name="add"
                size={20}
                className="inline align-[-5px] mx-2 p-2 rounded-sm border border-grey-200"
              />
            ),
          }}
        />
      </p>
    </section>
  );
};

const useDocumentItems = (): MultiLevelMenuItemProps => {
  const t = useTranslation();

  const documentSubItems = useDocumentUploadMenuSubItems();

  return {
    icon: "fileText" as const,
    text: t("patient_view.document"),
    subItemsPosition: "bottom",
    subItems: documentSubItems,
  };
};

const usePrescriptionItems = (patientUuid: UUID): MultiLevelMenuItemProps => {
  const t = useTranslation();
  const { addPrescription } = useAddPrescription(patientUuid);
  const { data } = useQuery(PrescriptionTemplates);

  if (!data || data.isEmpty()) {
    return {
      icon: "pill" as const,
      text: t("patient_view.prescription"),
      onClick: (closeMenu: () => void) => {
        closeMenu();
        return addPrescription();
      },
    };
  }

  const templatesSubItems: MenuItemProps[] = data.map((template) => ({
    icon: "fileText" as const,
    text: template.title,
    onClick: (closeMenu: () => void) => {
      closeMenu();
      return addPrescription(template);
    },
  }));

  return {
    icon: "pill" as const,
    text: t("inboxes.qa_experience.new_prescription"),
    subItemsPosition: "bottom",
    subItems: [
      templatesSubItems,
      {
        icon: "add" as const,
        text: t("patient_view.prescription.blank"),
        topSeparator: true,
        onClick: (closeMenu: () => void) => {
          closeMenu();
          return addPrescription();
        },
      },
    ].flat(),
  };
};
