import { ClickableIcon } from "components/Icon/ClickableIcon";
import { useDoctor } from "contexts/User/UserContext";
import { QuestionsSetFormFragment } from "generated/provider";
import { useTranslation } from "i18n";
import { displayQuestionsSetState } from "utils/display";

const isQuestionsSetFormFragment = (
  form: QuestionsSetFormFragment | string[],
): form is QuestionsSetFormFragment => !Array.isArray(form);

export const QuestionsSetReader = ({
  onRequestClose,
  form,
}: {
  onRequestClose: () => void;
  form: QuestionsSetFormFragment | string[];
}) => {
  const questions = isQuestionsSetFormFragment(form)
    ? form.questions.map((question, _) => question.question)
    : form;

  const { hasAccessToGatekeeperFeature } = useDoctor();
  const t = useTranslation();

  if (!hasAccessToGatekeeperFeature("QUESTIONS_SET")) return null;
  return (
    <div className="flex-col h-full pl-44 py-32">
      <header className="flex items-center space-x-16">
        <ClickableIcon
          name="doubleChevron"
          className="flex-center w-28 h-28 bg-grey-100 rounded-sm hover:opacity-80"
          onClick={() => onRequestClose()}
        />
        <div className="flex items-center">
          <h1 className="text-18 font-bold text-black">
            {t("inboxes.qa_experience.questions_set.title")}
          </h1>
          {isQuestionsSetFormFragment(form) && (
            <div className="text-14 font-normal text-grey-300">
              ・{displayQuestionsSetState(form.state)}
            </div>
          )}
        </div>
      </header>

      <div className="mt-32 shrink overflow-y-auto">
        {questions.map((question, index) => (
          <div key={index} className="flex-col">
            <div className="font-normal text-14 text-grey-300 mt-12">
              {question}
            </div>
            {isQuestionsSetFormFragment(form) && (
              <div className="font-normal text-14 text-grey-400">
                {form.questions[index].answer ?? "---"}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
