import { Link } from "react-router-dom";

import { NextPageButton } from "components/Button/NextPageButton";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Separator } from "components/Separator/Separator";
import { TranscriptItems } from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";

export const MedicalVerificationInbox = () => {
  const t = useTranslation();
  return (
    <div className="flex-col flex-fill p-16 space-y-24">
      <div className="text-24 font-bold text-primary-dark">
        {t("recorded_conversations.medical_verification")}
      </div>
      <div className="flex-col">
        <PaginatedQuery
          query={TranscriptItems}
          variables={{
            filter: {
              requiresMedicalOpinion: true,
            },
          }}
        >
          {({ data: transcriptItems }, utils) => (
            <>
              <div className="bg-white rounded border">
                {transcriptItems
                  .groupByToEntries((it) => it.transcript.uuid)
                  .map(
                    ([transcriptUuid, items], index) =>
                      items[0].transcript.fileUpload?.uuid && (
                        <div key={items[0].uuid}>
                          <Link
                            className="flex items-center flex-fill space-x-12 px-16 py-8 hover:bg-grey-100"
                            to={`/${routes.RECORDED_CONVERSATIONS}/file-upload/${items[0].transcript.fileUpload.uuid}/transcript?showOnlyMedicalInitially`}
                          >
                            <div className="flex-fill">
                              {t(
                                "recorded_conversations.medical_verification_transcript",
                                {
                                  count: items.length,
                                  uuid: transcriptUuid.slice(0, 8),
                                },
                              )}
                            </div>
                          </Link>
                          {index !== transcriptItems.length - 1 && (
                            <Separator />
                          )}
                        </div>
                      ),
                  )}
              </div>
              <NextPageButton {...utils} />
            </>
          )}
        </PaginatedQuery>
      </div>
    </div>
  );
};
