import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { FormNoteSectionsComposer } from "components/Form/Editor/NoteSections/FormNoteSectionsComposer";
import { NoteSectionWithComposerFields } from "components/Form/Editor/NoteSections/types";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { FormSwitch } from "components/Form/Switch/FormSwitch";
import { Icon } from "components/Icon/Icon";
import { Query } from "components/Query/Query";
import {
  CreateNoteTemplate,
  GetNoteTemplate,
  PatientNoteTemplateFragment,
  PatientNoteTemplateSectionFragment,
  PatientNoteTemplateSectionInput,
  TextSectionContentCategory,
  UpdateNoteTemplate,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { NoteSection } from "types";
import { addNoteTemplateInCache, isKnownUnionValue } from "utils/apollo";
import { isContentEqual } from "utils/notes";
import { notifier } from "utils/notifier";

gql`
  query GetNoteTemplate {
    patientNoteTemplate {
      template {
        ...PatientNoteTemplate
      }
    }
  }

  mutation CreateNoteTemplate(
    $title: String!
    $sections: [PatientNoteTemplateSectionInput!]!
    $prefillWithEhr: Boolean!
  ) {
    createPatientNoteTemplate(
      title: $title
      sections: $sections
      prefillWithEhr: $prefillWithEhr
    ) {
      template {
        ...PatientNoteTemplate
      }
    }
  }

  mutation UpdateNoteTemplate(
    $patientNoteTemplateUuid: UUID!
    $title: String!
    $sections: [PatientNoteTemplateSectionInput!]!
    $prefillWithEhr: Boolean!
  ) {
    updatePatientNoteTemplate(
      patientNoteTemplateUuid: $patientNoteTemplateUuid
      title: $title
      sections: $sections
      prefillWithEhr: $prefillWithEhr
    ) {
      template {
        ...PatientNoteTemplate
      }
    }
  }
`;

type FormData = {
  title: string;
  sections: NoteSectionWithComposerFields[];
  prefillWithEhr: boolean;
};

type NoteTemplateSection = {
  uuid: UUID;
  content: TemplateTextSectionContent | TemplateDiagnoseSectionContent;
};

type TemplateTextSectionContent = {
  __typename: "TemplateTextSectionContent";
  category: TextSectionContentCategory;
  text: string;
};

type TemplateDiagnoseSectionContent = {
  __typename: "TemplateDiagnoseSectionContent";
  icd10Codes: string[];
};

const getSectionInput = (
  section: NoteTemplateSection,
): PatientNoteTemplateSectionInput => {
  switch (section.content.__typename) {
    case "TemplateTextSectionContent":
      return {
        textSection: {
          text: section.content.text,
          category: section.content.category,
        },
        diagnoseSection: null,
      };
    case "TemplateDiagnoseSectionContent":
      return {
        textSection: null,
        diagnoseSection: {
          icd10Codes: section.content.icd10Codes,
        },
      };
  }
};

const noteTemplateSectionToNoteSection = (
  template: NoteTemplateSection,
): NoteSection => ({
  ...template,
  content:
    template.content.__typename === "TemplateDiagnoseSectionContent"
      ? {
          __typename: "DiagnoseSectionContent",
          icd10Codes: template.content.icd10Codes,
        }
      : {
          __typename: "TextSectionContent",
          category: template.content.category,
          textWithMentions: {
            text: template.content.text,
            mentions: [],
          },
        },
});

const noteSectionToNoteTemplateSection = (
  section: NoteSection,
): NoteTemplateSection => ({
  uuid: section.uuid,
  content:
    section.content.__typename === "DiagnoseSectionContent"
      ? {
          __typename: "TemplateDiagnoseSectionContent",
          icd10Codes: section.content.icd10Codes,
        }
      : {
          __typename: "TemplateTextSectionContent",
          category: section.content.category,
          text: section.content.textWithMentions.text,
        },
});

const noteSectionsFromPatientNoteTemplateSection = (
  gqlSections: PatientNoteTemplateSectionFragment[],
): NoteSection[] =>
  gqlSections.mapNotNull((section) => {
    if (
      !isKnownUnionValue("PatientNoteTemplateSectionContent", section.content)
    ) {
      return null;
    }
    return noteTemplateSectionToNoteSection({
      uuid: section.uuid,
      content: section.content,
    });
  });

const templateToFormData = (
  template: Maybe<PatientNoteTemplateFragment>,
): FormData => ({
  title: template?.title ?? "",
  sections: (
    template?.sections.let((it) =>
      noteSectionsFromPatientNoteTemplateSection(it),
    ) ?? []
  ).map((it) => ({
    ...it,
    navigationState: "NEUTRAL",
  })),
  prefillWithEhr: template?.prefillWithEhr ?? false,
});

export const TemplatesSettingsConsultation = () => {
  const t = useTranslation();
  const [createNoteTemplate] = useMutation(CreateNoteTemplate, {
    onSuccess: ({ template }, client) => {
      addNoteTemplateInCache(client, template);
      notifier.success(
        t("settings.templates.video_consultation.created_notifier"),
      );
    },
  });
  const [updateNoteTemplate] = useMutation(UpdateNoteTemplate, {
    onSuccess: () => {
      notifier.success(
        t("settings.templates.video_consultation.updated_notifier"),
      );
    },
  });

  return (
    <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24 bg-radical-red">
      <Query query={GetNoteTemplate}>
        {({ template }) => (
          <Form<FormData>
            initialValues={templateToFormData(template)}
            onSubmit={async (values) => {
              if (template) {
                await updateNoteTemplate({
                  patientNoteTemplateUuid: template.uuid,
                  title: values.title,
                  sections: values.sections
                    .map(noteSectionToNoteTemplateSection)
                    .map(getSectionInput),
                  prefillWithEhr: values.prefillWithEhr,
                });
              } else {
                await createNoteTemplate({
                  title: values.title,
                  sections: values.sections
                    .map(noteSectionToNoteTemplateSection)
                    .map(getSectionInput),
                  prefillWithEhr: values.prefillWithEhr,
                });
              }
            }}
          >
            <div className="bg-white rounded border">
              <div className="flex items-center h-64 space-x-16 border-b pt-1 px-16">
                <div className="rounded-[11px] w-32 h-32 flex-center text-[#FF8552] bg-[#FFF5F1]">
                  <Icon size={20} name="videoOn" />
                </div>
                <div className="mr-48 flex-fill leading-[20px]">
                  <FormInput
                    inlineError="bottom"
                    name="title"
                    className="bg-transparent w-full !text-primary-dark"
                    placeholder={t(
                      "settings.templates.video_consultation.title_placeholder",
                    )}
                  />
                </div>
              </div>
              <div className="px-[8px]">
                <div className="overflow-x-auto pt-20 pb-28 animate-fade-in">
                  <FormNoteSectionsComposer
                    name="sections"
                    mentionsDisabled
                    readOnly={false}
                  />
                </div>
                <div className="flex justify-between pt-20 border-t mx-[60px] text-grey-400">
                  <div>
                    {t(
                      "settings.templates.video_consultation.prefill_template",
                    )}
                  </div>
                  <FormSwitch name="prefillWithEhr" />
                </div>
                <div className="flex justify-end py-20 mx-[60px]">
                  <FormState<FormData>>
                    {({ values }) => {
                      const hasNotChanged =
                        template?.title === values.title &&
                        isContentEqual(
                          noteSectionsFromPatientNoteTemplateSection(
                            template.sections,
                          ),
                          values.sections,
                        ) &&
                        template.prefillWithEhr === values.prefillWithEhr;

                      return (
                        <Submit
                          small
                          label={t("patient_view.save")}
                          disabled={hasNotChanged}
                        />
                      );
                    }}
                  </FormState>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Query>
    </Background>
  );
};
