import { useState } from "react";
import classNames from "classnames";

import { AccountGoogleLoginButton } from "auth/components/AccountGoogleLoginButton";
import {
  AccountPasswordLoginForm,
  AccountPasswordLoginFormState,
} from "auth/components/AccountPasswordLoginForm";
import { QuickLogin } from "auth/components/QuickLogin";
import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { PublicScreenWrapper } from "components/PublicScreenWrapper/PublicScreenWrapper";
import { useTranslation } from "i18n";

export const Login = ({
  // FIXME(@liautaud): Actually use these to write an organization-specific
  //  login page. See https://github.com/nabla/health/issues/26240.
  organizationName,
  showPasswordLogin = true,
  showGoogleLogin = true,
}: {
  organizationName?: string;
  showPasswordLogin?: boolean;
  showGoogleLogin?: boolean;
}) => {
  const t = useTranslation();
  const [passwordLoginFormState, setPasswordLoginFormState] =
    useState<AccountPasswordLoginFormState>({ step: "CREDENTIALS_FORM" });

  // Hide the rest of the layout if the password login form is past the initial step.
  if (showPasswordLogin && passwordLoginFormState.step !== "CREDENTIALS_FORM") {
    return (
      <PublicScreenWrapper>
        <EnvironmentBanner />

        <div className="flex-col w-full max-w-400">
          <AccountPasswordLoginForm
            state={passwordLoginFormState}
            onStateChange={setPasswordLoginFormState}
          />
        </div>
      </PublicScreenWrapper>
    );
  }

  return (
    <PublicScreenWrapper>
      <EnvironmentBanner />

      <div className="flex-col w-full max-w-400">
        <h1 className={classNames("title", { "mb-40": showPasswordLogin })}>
          {organizationName
            ? t("login.title_for_organization", { organizationName })
            : t("login.title")}
        </h1>

        {showPasswordLogin && (
          <AccountPasswordLoginForm
            state={passwordLoginFormState}
            onStateChange={setPasswordLoginFormState}
          />
        )}

        {showPasswordLogin && showGoogleLogin && (
          <p className="my-22 text-center">{t("login.or")}</p>
        )}

        {showGoogleLogin && <AccountGoogleLoginButton />}

        {process.env.VITE_NABLA_USER && (
          <>
            <p className="my-22 text-center">{t("login.or")}</p>
            <QuickLogin />
          </>
        )}
      </div>
    </PublicScreenWrapper>
  );
};
