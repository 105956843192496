import { FileUploadFragment } from "generated/provider";

import { createAtom } from "./createAtom";

export type MultimediaContentType = {
  text?: string;
  file?: FileUploadFragment;
};

const EmptyTemplate: MultimediaContentType = { text: "" };

export const useAddTemplateWindow = createAtom(
  { content: null as MultimediaContentType | null },
  ({ set }) => ({
    open: (content: MultimediaContentType = EmptyTemplate) => set({ content }),
    close: () => set({ content: null }),
  }),
);
