import { useMedicalCodes } from "atoms/medicalCodes/useMedicalCodes";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { useTranslation } from "i18n";
import { Bill } from "types";
import { language } from "utils/intl";

import { LabelWrapper, UnnamedLabelWrapperProps } from "../Label/LabelWrapper";
import { MedicalCodesExplorer } from "./MedicalCodesExplorer";

export const CptCodePicker = ({
  value,
  label,
  disabled,
  error,
  hint,
  onChange,
  icd10Codes,
}: {
  value: Bill["cptCodes"];
  icd10Codes?: string[];
  disabled?: boolean;
  onChange: (cptCodes: Bill["cptCodes"]) => void;
} & UnnamedLabelWrapperProps) => {
  const { getCptByCode, getICD10CMByCode } = useMedicalCodes();
  const t = useTranslation();

  return (
    <LabelWrapper
      label={label ?? t("form.cpt_codes.title")}
      error={error}
      hint={hint}
      useDiv
      wrapperClassName="items-start"
    >
      <ul className="list-disc ml-20 flex-col items-start">
        {value.map((it, index) => {
          const item = getCptByCode(it.code);
          if (!item) return null;
          return (
            <li key={index} className="text-primary-dark mb-4">
              <div className="bg-grey-200 rounded-full px-8 flex items-center">
                {item.description}
                <span className="text-body ml-4">{item.code}</span>
                {!disabled && (
                  <ClickableIcon
                    name="close"
                    size={12}
                    className="h-12 w-12"
                    onClick={() =>
                      onChange(
                        value.mapNotNull((code, indexCode) => {
                          if (indexCode === index) return null;
                          return code;
                        }),
                      )
                    }
                  />
                )}
              </div>
              {it.linkedICD10Codes
                .mapNotNull((icd10Code) => getICD10CMByCode(icd10Code))
                .map((icd10KBEntry, index2) => (
                  <div
                    className="ml-14 flex"
                    key={index * value.length + index2}
                  >
                    。
                    <div className="bg-grey-100 mt-4 rounded-full px-8 flex items-center flex-shrink">
                      {icd10KBEntry[language as "fr" | "en" | "pt"]}
                      <span className="text-body ml-4">
                        {icd10KBEntry.code}
                      </span>
                      {!disabled && (
                        <ClickableIcon
                          name="close"
                          size={12}
                          className="h-12 w-12"
                          onClick={() =>
                            onChange(
                              value.mapNotNull((code, indexCode) => {
                                if (indexCode === index) {
                                  return {
                                    ...code,
                                    linkedICD10Codes:
                                      code.linkedICD10Codes.filter(
                                        (icd10Item) =>
                                          icd10Item !== icd10KBEntry.code,
                                      ),
                                  };
                                }
                                return code;
                              }),
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
            </li>
          );
        })}
      </ul>
      {!disabled && (
        <UncontrolledPopover
          position="bottom-left"
          className="flex-col my-6 overflow-hidden"
          style={{ width: 300, maxHeight: 303 }}
          content={(close) => (
            <MedicalCodesExplorer
              codeType="CPT"
              onSelect={(it) =>
                "description" in it &&
                onChange([
                  ...value,
                  {
                    code: it.code,
                    linkedICD10Codes: icd10Codes ?? [],
                  },
                ])
              }
              close={close}
              pickedCodes={value.map((it) => it.code)}
            />
          )}
        >
          {({ setTarget }) => (
            <button
              className="rounded-sm py-2 px-8 hover:bg-grey-100"
              type="button"
              onClick={setTarget}
            >
              {t("form.cpt_codes.add")}
            </button>
          )}
        </UncontrolledPopover>
      )}
    </LabelWrapper>
  );
};
