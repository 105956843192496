import { useEffect, useRef, useState } from "react";
import {
  convertToRaw,
  EditorState,
  getVisibleSelectionRect,
  RawDraftContentState,
} from "draft-js";
import { stateToMarkdown } from "draft-js-export-markdown";

import { MentionFragment } from "generated/provider";
import { TextWithMentions } from "types";

import { Editor, EditorProps } from "../core/Editor";
import {
  addEmptyBlock,
  convertAllBlocksToBulletPoints,
  getCurrentBlock,
  getEntitiesList,
  insertText,
  someBlocksAreNotBulletPoints,
  useOnContentChange,
} from "../core/utils";
import {
  addAutocompletion,
  canAcceptAutocompletion,
  removeAutocompletion,
} from "./autocompletionUtils";
import { MentionsOptions } from "./MentionsOptions";
import { RichTextButtons } from "./RichTextButtons";
import { MentionEditorState, Trigger } from "./useMentionEditorState";
import {
  getMentionOption,
  isDataWithSubOptions,
  MentionsOptionsProps,
  MentionsSubOptions,
} from "./utils";

export type MentionEditorProps<
  Option extends any = any,
  MutablePayload extends Record<string, unknown> = Record<string, unknown>,
> = Omit<
  EditorProps,
  "editorState" | "onChange" | "containerRef" | "disableHtmlPaste"
> & {
  state: MentionEditorState<MutablePayload>;
  onChange?: (value: TextWithMentions) => void;
  suggestions: {
    trigger: Trigger;
    options: MentionsOptionsProps<Option> | null;
  }[];
  forceSuggestions?: boolean;
  onHighlightSuggestions?: MentionsOptionsProps<Option>;
  afterEditorStateChange?: (state: EditorState) => void;
  withRichText?: boolean;
  autocompletion?: string;
  autoFocus?: boolean;
};

export const MentionEditor = <
  Option extends any = any,
  MutablePayload extends Record<string, unknown> = Record<string, unknown>,
>({
  state,
  suggestions,
  withRichText,
  children,
  onChange,
  onKeyStroke,
  afterEditorStateChange,
  autocompletion,
  forceSuggestions,
  autoFocus,
  ...otherEditorProps
}: MentionEditorProps<Option, MutablePayload>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editorCoords = containerRef.current?.getBoundingClientRect();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [subMenu, setSubMenu] = useState<MentionsSubOptions<any>>();

  const onSelectOptions = (options: MentionsOptionsProps<Option>) => {
    const currentValue = options.data[selectedIndex];
    setSubMenu(
      isDataWithSubOptions(currentValue) ? currentValue.subOptions : undefined,
    );
    options.onSelected(getMentionOption(currentValue));
    return null;
  };

  // Handles any kind of change of ContentState.
  // whereas Editor.onChange() is only fired when
  // a change is done following keyboard or mouse action on the editor,
  // and does not fire during external changes of the editor state.
  useOnContentChange(state.editorState, (newContent) => {
    const rawContent = convertToRaw(newContent);

    onChange?.({
      // If richText is enabled, we want to export content as markdown,
      // otherwise we use a custom conversion to avoid espace characters
      // and weird empty line handling from draft-js-export-markdown
      text:
        withRichText || state.forceBulletPoints
          ? trimZeroWidthSpacesFromDraftJsExportToMarkdown(
              stateToMarkdown(newContent),
            )
          : customContentToText(rawContent),
      mentions: getMentions(rawContent),
    });
  });

  useEffect(() => {
    setSelectedIndex(0);
  }, [state.editorState]);

  const trigger = state.suggestionsInput?.trigger;
  useEffect(() => {
    if (!trigger) setSubMenu(undefined);
  }, [trigger]);

  const currentSuggestion = suggestions.find((s) =>
    triggerEquality(s.trigger, state.suggestionsInput?.trigger),
  );
  const options = (
    currentSuggestion ? subMenu ?? currentSuggestion.options : undefined
  )?.let((it) => ({
    ...it,
    data: it.data.filter((o) => {
      const option = getMentionOption(o);
      return (
        (it.skipFuzzyMatchFiltering ||
          it
            .getOptionLabel(option)
            .fuzzyMatch(state.suggestionsInput?.currentInput ?? "")) &&
        (!it.filter || it.filter(option))
      );
    }),
  }));

  const currentBlock = getCurrentBlock(state.editorState);

  const onEditorChanged = (newEditorStateWithAutocompletion: EditorState) => {
    const newEditorState = removeAutocompletion(
      removeAutocompletion(
        newEditorStateWithAutocompletion,
        currentBlock.getKey(),
      ),
      getCurrentBlock(newEditorStateWithAutocompletion).getKey(),
    );

    const newContent = newEditorState.getCurrentContent();
    const currentContent = state.editorState.getCurrentContent();
    let correctedEditorState = newEditorState;

    if (
      newContent !== currentContent &&
      state.forceBulletPoints &&
      someBlocksAreNotBulletPoints(newEditorState)
    ) {
      correctedEditorState = convertAllBlocksToBulletPoints(newEditorState);
    }
    state.setEditorState(() => correctedEditorState);
    afterEditorStateChange?.(correctedEditorState);
  };

  const coords =
    state.editorState.getSelection().getHasFocus() &&
    document.querySelector(
      `[data-offset-key^="${state.editorState.getSelection().getAnchorKey()}"]`,
    )
      ? getVisibleSelectionRect(window) ??
        getHackedCursorPositionToWorkAroundDraftJsFirstCharBug()
      : undefined;

  return (
    <Editor
      wrapperClassName="RichEditor-editor RichEditor-hidePlaceholder"
      editorState={addAutocompletion(state.editorState, autocompletion)}
      containerRef={containerRef}
      // Without richText, the rich format will be lost on export,
      // so it's better to only allow paste as plain text
      disableHtmlPaste={!withRichText}
      autoFocus={autoFocus}
      onKeyStroke={(e) => {
        if (e.key === "Enter" && forceSuggestions && options?.data.isEmpty()) {
          // if "forceSuggestion" we must select an option
          return null;
        }
        if (
          e.key === "Tab" &&
          canAcceptAutocompletion(state.editorState) &&
          autocompletion
        ) {
          onEditorChanged(insertText(state.editorState, autocompletion));
          return "handled";
        }

        if (state.mutableInput) {
          if (e.key === "Enter") {
            state.submitMutableEntityInput(false);
            if (state.forceBulletPoints) {
              state.setEditorState((current) =>
                addEmptyBlock(current, "unordered-list-item"),
              );
            }
            return null;
          }
          return onKeyStroke?.(e);
        }
        if (options?.data.isNotEmpty()) {
          if (e.key === "ArrowUp") {
            setSelectedIndex(
              selectedIndex === 0 ? options.data.length - 1 : selectedIndex - 1,
            );
            return null;
          }
          if (e.key === "ArrowDown") {
            setSelectedIndex((selectedIndex + 1) % options.data.length);
            return null;
          }
          if (e.key === "Enter" || e.key === "Tab") {
            return onSelectOptions(options);
          }
        }
        return onKeyStroke?.(e);
      }}
      onChange={onEditorChanged}
      {...otherEditorProps}
    >
      {children}
      {withRichText &&
        coords &&
        !options &&
        !state.editorState.getSelection().isCollapsed() && (
          <RichTextButtons
            editorState={state.editorState}
            setEditorState={state.setEditorState}
            coords={coords}
          />
        )}
      {editorCoords && coords && options && (
        <MentionsOptions
          coords={coords}
          editorCoords={editorCoords}
          options={options}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          onSelected={() => onSelectOptions(options)}
        />
      )}
    </Editor>
  );
};

const customContentToText = (raw: RawDraftContentState) =>
  raw.blocks
    .map((b) =>
      b.entityRanges
        .filter(
          (entityRange) => raw.entityMap[entityRange.key].type === "MENTION",
        )
        .sortAsc((entityRange) => entityRange.offset)
        .reduceRight(
          (text, entityRange) =>
            `${text.slice(
              0,
              entityRange.offset,
            )}[_NABLA_MENTION_](_NABLA_MENTION_:${
              raw.entityMap[entityRange.key].data.mentionIdentifier as number
            })${text.slice(entityRange.offset + entityRange.length)}`,
          b.text,
        ),
    )
    .join("\n")
    .trim();

// This is very hacky, to get around this https://github.com/sstur/draft-js-utils/blob/master/packages/draft-js-export-markdown/src/stateToMarkdown.js#L201-L205
// A proper solution would be to remove this via patch and fix the import to not collapse consecutive line breaks
const trimZeroWidthSpacesFromDraftJsExportToMarkdown = (markdown: string) => {
  const lines = markdown.trim().split("\n");
  const notBlank = (l: string) => l.isNotBlank() && l !== "\u200B";
  const firstNonBlankLine = lines.findIndexOrUndefined(notBlank);
  if (firstNonBlankLine === undefined) return "";
  const lastNonBlankLine = lines.findLastIndex(notBlank);
  return lines
    .slice(firstNonBlankLine, lastNonBlankLine! + 1)
    .filter((l) => l !== "- ​") // Remove empty boulette points (#23451)
    .join("\n");
};

// https://github.com/nabla/health/issues/6593
const getHackedCursorPositionToWorkAroundDraftJsFirstCharBug = () => {
  const selection = window.getSelection();
  if (!selection) return null;
  if (selection.rangeCount === 0) return null;

  const coords = selection
    .getRangeAt(0)
    .commonAncestorContainer.parentElement?.getBoundingClientRect();
  if (!coords) return null;

  // Returning directly DOMRect is buggy :/
  const { height, width, top, bottom, right, left, x, y } = coords;
  return { height, width, top, bottom, right, left, x, y };
};

const getMentions = (rawContent: RawDraftContentState): MentionFragment[] =>
  getEntitiesList<Omit<MentionFragment, "__typename">>(rawContent)
    .filter((v) => v.type === "MENTION")
    .map((e) => ({ ...e.data, __typename: "TextMention" }));

const triggerEquality = (trigger1: Trigger, trigger2: Trigger | undefined) => {
  if (trigger2 === undefined) return false;
  switch (trigger1.type) {
    case "KEYSTROKE":
      return (
        trigger2.type === "KEYSTROKE" &&
        trigger2.keystroke === trigger1.keystroke
      );
    case "SELECTION":
      return trigger2.type === "SELECTION";

    case "START_BLOCK":
      return trigger2.type === "START_BLOCK";
  }
};
