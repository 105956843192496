import { Fragment } from "react";
import classNames from "classnames";

import { getCurrentLanguage } from "i18n";
import stopWords from "utils/stopWords.json";

export const HighlightText = ({
  text,
  search,
  className,
}: {
  text: string;
  search: string | undefined | null;
  className?: string;
}) => {
  if (!search) return <div className={classNames(className)}>{text}</div>;
  // We don't use toSearchValue because removing soft hyphens would breaks indexes
  const searchableText = text.toLowerCase().removeAccents();
  const keys = getSearchKeys(searchableText, search.toSearchValue());
  const matches = keys.isEmpty()
    ? []
    : Array.from(searchableText.matchAll(new RegExp(keys.join("|"), "gu"))).map(
        (m) => ({ start: m.index!, end: m.index! + m[0].length }),
      );
  const chunks = [
    { highlight: false, value: text.slice(0, matches.at(0)?.start) },
  ];
  for (const [index, match] of matches.entries()) {
    chunks.push({
      highlight: true,
      value: text.slice(match.start, match.end),
    });
    chunks.push({
      highlight: false,
      value: text.slice(match.end, matches[index + 1]?.start),
    });
  }

  return (
    <div className={classNames(className)}>
      {chunks
        .filter((p) => p.value !== "")
        .map(({ value, highlight }, index) =>
          highlight ? (
            <mark key={index}>{value}</mark>
          ) : (
            <Fragment key={index}>{value}</Fragment>
          ),
        )}
    </div>
  );
};

const getSearchKeys = (text: string, search: string): string[] => {
  const parts = search.split(" ");
  for (let nbWords = parts.length; nbWords > 1; nbWords--) {
    const combinations = combine(parts, nbWords).map((words) =>
      words.join(" "),
    );
    const matches = combinations.filter(
      (c) => c.isNotBlank() && text.includes(c),
    );
    if (matches.isNotEmpty()) return matches;
  }

  return parts.filter(
    (p) =>
      p.length > 1 &&
      !stopWords[getCurrentLanguage()].includes(p) &&
      text.includes(p),
  );
};

/**
 * Some stackoverflow code, not fully tested but seems to work.
 * The goal is to get all the combinations of k elements inside list
 */
const combine = (list: string[], k: number): string[][] => {
  if (list.length === k) return [list];
  if (k === 0) return [[]];
  return [
    ...combine(list.slice(1), k - 1).map((c) => [list[0], ...c]),
    ...combine(list.slice(1), k),
  ];
};
