import { Capacitor } from "@capacitor/core";
import gql from "graphql-tag";

import { useLivekitRoom } from "contexts/LivekitRoom/LivekitRoomContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  AppEventsSubscription,
  TranscriptFragmentProps,
} from "generated/provider";
import { useSubscription } from "graphql-client/useSubscription";
import {
  addDoctorConversationInCache,
  addExperienceTagTypeInCache,
  addGroupConversationItemInCache,
  addPatientInCache,
  addPatientTimelineItemIntoCache,
  addQAInboxItemInCache,
  addTemplateInCache,
} from "utils/apollo";
import { removeDeliveredNotifications } from "utils/ios-push-notifications";

import { useOnNewTimelineItem } from "./useOnNewTimelineItem/useOnNewTimelineItem";

gql`
  subscription AppEventsSubscription {
    appEvents {
      event {
        ... on DoctorUpdateEvent {
          doctor {
            ...DoctorSummary
          }
        }
        ... on PatientUpdateEvent {
          patient {
            ...PatientManagementPatient
            ...PatientPrescriptionInfos
          }
        }
        ... on TemplateCreatedOrUpdatedEvent {
          template {
            ...Template
          }
        }
        ... on TemplateDeletedEvent {
          templateUuid
        }
        ... on TranscriptCreatedOrUpdatedEvent {
          transcript {
            ...Transcript
          }
        }
        ... on TranscriptItemCreatedOrUpdatedEvent {
          item {
            ...TranscriptItem
            transcript {
              uuid
            }
          }
        }
        ... on AppointmentCreatedEvent {
          appointment {
            ...Appointment
            doctor {
              ...AllUpcomingAppointments
            }
          }
        }
        ... on AppointmentUpdatedEvent {
          appointment {
            ...AppointmentSummary
          }
        }
        ... on AppointmentCancelledEvent {
          appointment {
            ...AppointmentSummary
            doctor {
              ...AllUpcomingAppointments
            }
          }
        }
        ... on NewTimelineItemEvent {
          item {
            ...NewTimelineItem
          }
        }
        ... on ScheduledMessageCreatedEvent {
          experience {
            ...ScheduleMessages
          }
        }
        ... on ScheduledMessageUpdatedEvent {
          experience {
            ...ScheduleMessages
          }
        }
        ... on ScheduledMessageDeletedEvent {
          experience {
            ...ScheduleMessages
          }
        }
        ... on AddedToExperienceEvent {
          experience {
            ...ExperienceSummary
          }
        }
        ... on RemovedFromExperienceEvent {
          experienceUuid
        }
        ... on TypingEvent {
          experience {
            ...Typing
          }
        }
        ... on ExperienceSeenStatusUpdateEvent {
          experience {
            ...SeenState
          }
        }
        ... on RenamedExperienceEvent {
          experience {
            uuid
            title
          }
        }
        ... on QAExperienceCreatedOrUpdated {
          experience {
            ...QAExperience
          }
        }
        ... on MessageContentUpdatedEvent {
          message {
            uuid
            content {
              ...MessageContentSummary
            }
            reactions {
              type
              doctor {
                ...DoctorSummary
              }
            }
          }
        }
        ... on AssignedExperienceEvent {
          experience {
            ...QAExperience
          }
        }
        ... on CreatedOrUpdatedExperienceTagEvent {
          experience {
            uuid
            tags {
              ...ExperienceTag
            }
          }
        }
        ... on DoctorAddressCreatedOrUpdatedEvent {
          doctor {
            uuid
            appointmentAddress {
              ...Address
            }
            prescriptionAddress {
              ...Address
            }
          }
        }
        ... on MessageEHRSuggestionsExtractedEvent {
          message {
            uuid
            extractedEHRSuggestions {
              ...EHRSuggestion
            }
          }
        }
        ... on PatientTimelineEventCreatedOrUpdatedEvent {
          timelineEvent {
            patient {
              uuid
            }
            ...PatientTimelineEvent
          }
        }
        ... on PatientTimelineEventDeletedEvent {
          eventUuid
          patient {
            uuid
          }
        }
        ... on MLModelUpdatedEvent {
          mlModel {
            ...MLModel
          }
        }
        ... on AvailabilityDeletedEvent {
          availabilityUuid
        }
        ... on AvailabilityCreatedOrUpdatedEvent {
          availability {
            ...Availability
          }
        }
        ... on PatientDocumentCreatedOrUpdatedEvent {
          patientDocument {
            ...PatientDocument
          }
        }
        ... on CopilotUpdatedEvent {
          experience {
            ...Copilot
          }
        }
        ... on ExperienceTagTypeCreatedOrUpdatedEvent {
          tag {
            ...Tag
          }
        }
        ... on TaskCreatedOrUpdatedEvent {
          task {
            ...Task
            assignedDoctor {
              uuid
              unreadTasksCount
            }
          }
        }
        ... on TaskDeletedEvent {
          taskUuid
        }
        ... on MedicalOrderCreatedOrUpdatedEvent {
          medicalOrder {
            ...MedicalOrder
          }
        }
        ... on MedicalOrderDeletedEvent {
          medicalOrderUuid
        }
        ... on LivekitRoomUpdatedEvent {
          livekitRoom {
            ...LivekitRoom
          }
        }
        ... on PatientNoteSuggestionsUpdatedEvent {
          patientNote {
            uuid
            suggestions {
              ...PatientNoteSuggestion
            }
          }
        }
        ... on SentFaxUpdatedEvent {
          sentFax {
            ...SentFax
          }
        }
        ... on NablaPrescriptionCreatedEvent {
          prescription {
            ...NablaPrescription
          }
        }
        ... on NablaPrescriptionUpdatedEvent {
          prescription {
            ...NablaPrescription
          }
        }
        ... on BravadoPrescriptionCreatedEvent {
          prescription {
            ...BravadoPrescription
          }
        }
        ... on BravadoPrescriptionUpdatedEvent {
          prescription {
            ...BravadoPrescription
          }
        }
        ... on PhotonPrescriptionCreatedEvent {
          prescription {
            ...PhotonPrescription
          }
        }
        ... on PhotonPrescriptionUpdatedEvent {
          prescription {
            ...PhotonPrescription
          }
        }
      }
    }
  }
`;

export const useAppEvents = () => {
  const onNewMessage = useOnNewTimelineItem();
  const { user, hasPermission } = useDoctor();
  const { currentRoom, dismissCurrentRoom } = useLivekitRoom();
  useSubscription(AppEventsSubscription, {
    skip: !hasPermission("VIEW_EXPERIENCES"),
    onData: ({ event }, client) => {
      if (event.__typename === "QAExperienceCreatedOrUpdated") {
        if (event.experience.type === "SINGLE_PATIENT_CONVERSATION") {
          addQAInboxItemInCache(client, [event.experience]);
        }
        if (event.experience.type === "MULTI_PATIENTS_CONVERSATION") {
          addGroupConversationItemInCache(client, event.experience);
        }
      }
      if (event.__typename === "TemplateCreatedOrUpdatedEvent") {
        addTemplateInCache(client, event.template);
      }
      if (event.__typename === "TemplateDeletedEvent") {
        client.remove("Template", event.templateUuid);
      }
      if (
        event.__typename === "AddedToExperienceEvent" &&
        event.experience.type === "PROVIDER_ONLY_CONVERSATION"
      ) {
        addDoctorConversationInCache(client, event.experience);
      }
      if (event.__typename === "RemovedFromExperienceEvent") {
        client.remove("Experience", event.experienceUuid);
      }
      if (event.__typename === "NewTimelineItemEvent") onNewMessage(event.item);
      if (
        event.__typename === "MessageContentUpdatedEvent" &&
        event.message.content.__typename === "DeletedMessageContent"
      ) {
        if (!Capacitor.isNativePlatform()) return;
        removeDeliveredNotifications((n) => n.id === event.message.uuid);
      }
      if (event.__typename === "PatientTimelineEventCreatedOrUpdatedEvent") {
        addPatientTimelineItemIntoCache(
          client,
          event.timelineEvent.patient.uuid,
          event.timelineEvent,
        );
      }
      if (event.__typename === "PatientTimelineEventDeletedEvent") {
        client.remove("PatientTimelineEvent", event.eventUuid);
      }
      if (event.__typename === "TranscriptItemCreatedOrUpdatedEvent") {
        // Update the transcript.items list. Apollo takes care of updating the
        // actual item.
        client.update({
          uuid: event.item.transcript.uuid,
          fragment: TranscriptFragmentProps,
          write: (current) => {
            if (!current.items.map((it) => it.uuid).includes(event.item.uuid)) {
              current.items.push(event.item);
            }
          },
        });
      }
      if (event.__typename === "AvailabilityDeletedEvent") {
        client.remove("Availability", event.availabilityUuid);
      }
      if (event.__typename === "AvailabilityCreatedOrUpdatedEvent") {
        // TODO(@ruben): Handle properly cache update for availability occurrences
        client.evictQuery("availabilityOccurrencesInTimeRange", "filter");
      }
      if (event.__typename === "ExperienceTagTypeCreatedOrUpdatedEvent") {
        addExperienceTagTypeInCache(client, event.tag);
      }
      if (event.__typename === "PatientUpdateEvent") {
        addPatientInCache(client, event.patient);
      }
      if (event.__typename === "TaskDeletedEvent") {
        client.remove("Task", event.taskUuid);
      }
      if (event.__typename === "MedicalOrderDeletedEvent") {
        client.remove("MedicalOrder", event.medicalOrderUuid);
      }
      if (event.__typename === "TaskCreatedOrUpdatedEvent") {
        client.evictQuery("taskSearch", "filter");
      }

      // Evict PatientInbox items
      if (
        ((event.__typename === "AppointmentCancelledEvent" ||
          event.__typename === "AppointmentCreatedEvent") &&
          event.appointment.doctor.uuid === user.uuid) ||
        event.__typename === "AssignedExperienceEvent" ||
        event.__typename === "QAExperienceCreatedOrUpdated" ||
        event.__typename === "TaskCreatedOrUpdatedEvent" ||
        event.__typename === "TaskDeletedEvent" ||
        event.__typename === "MedicalOrderCreatedOrUpdatedEvent" ||
        event.__typename === "MedicalOrderDeletedEvent" ||
        event.__typename === "NablaPrescriptionCreatedEvent" ||
        event.__typename === "NablaPrescriptionUpdatedEvent" ||
        event.__typename === "BravadoPrescriptionCreatedEvent" ||
        event.__typename === "BravadoPrescriptionUpdatedEvent"
      ) {
        client.evictQuery("allTodoTimelineItems", "ALL");
        client.evictQuery("allDoneTimelineItems", "ALL");
      }
      if (
        event.__typename === "LivekitRoomUpdatedEvent" &&
        event.livekitRoom.status.__typename === "LivekitRoomClosedStatus" &&
        event.livekitRoom.uuid === currentRoom?.uuid
      ) {
        // in case another provider did close the room.
        dismissCurrentRoom();
      }
    },
  });
};
