import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Maybe } from "base-types";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { useChatWidgets } from "contexts/ChatWidgetsContext/ChatWidgetsContext";
import { useDoctor } from "contexts/User/UserContext";
import { CustomizeExperience } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { getQAInboxRoute } from "utils";
import { notifier } from "utils/notifier";

import { PatientTimelineItem } from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";

type FormData = {
  title: string;
  description: Maybe<string>;
};

export const ExperienceItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"Experience">;
}) => {
  const t = useTranslation();
  const { openChatWidget } = useChatWidgets();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const { user } = useDoctor();

  const initialTitle =
    item.optionalTitle?.trimOrNull() ??
    t("patient_view.default_title.experience");
  const [customizeExperience] = useMutation(CustomizeExperience);

  return (
    <PatientTimelineItem<"Experience", FormData, FormData>
      item={item}
      assignedDoctors={item.assignedDoctors}
      actionButton={{
        label: t("patient_view.experience.open_conversation"),
        leftIcon: "doubleChatBubble",
        secondary: true,
        mode: "HOVER",
        onClick: () => {
          if (isDesktop) {
            openChatWidget(item);
          } else {
            navigate(getQAInboxRoute(item, user.uuid));
          }
        },
      }}
      initialValues={{
        title: initialTitle,
        description: item.description,
      }}
      validationSchema={{ title: Yup.string().required() }}
      onSubmit={async ({ title, description }) => {
        await customizeExperience({
          uuid: item.uuid,
          /* We skip the title if it hasn't changed as it may
           * cause unnecessary "ExperienceRenamed" events */
          input:
            title === initialTitle ? { description } : { title, description },
        });
        notifier.success(t("patient_view.experience.update_success"));
      }}
    >
      <PatientTimelineItemContentWithPadding className="space-y-20">
        {/* FIXME(@liautaud): Turn into a rich text composer once #22543 is fixed. */}
        <FormTextArea
          name="description"
          label={t("patient_view.experience.description")}
          placeholder={t("patient_view.experience.add_description")}
          className="flex-fill justify-between h-full"
        />
      </PatientTimelineItemContentWithPadding>
    </PatientTimelineItem>
  );
};
