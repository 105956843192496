import { useEffect, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { Input } from "components/Form/Input/Input";
import { HighlightText } from "components/HighlightTrext/HighlightText";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { ControlledConfirmationModal } from "components/Modal/ControlledConfirmationModal";
import { Spinner } from "components/Spinner/Spinner";
import { SortStatus, Table } from "components/Table/Table";
import {
  AppEventsSubscription,
  DeleteTask,
  MarkTasksAsSeen,
  SetTaskStatus,
  TaskFragment,
  TaskSearch,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useSubscription } from "graphql-client/useSubscription";
import { useDebounceSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { getPatientViewRoute } from "utils";
import {
  displayDoctor,
  displayPatient,
  displayTaskColor,
  displayTaskPriority,
} from "utils/display";
import { notifier } from "utils/notifier";

import { TaskComposer } from "./TaskComposer";
import { TaskPanel } from "./TaskPanel";
import { TasksFilterMenu } from "./TasksFilterMenu";
import {
  FormTaskFilterValues,
  makeTaskSearchVariables,
  TaskConfirmationModalState,
  taskFilterCount,
  TaskHeader,
  updateTaskStatusInCache,
} from "./utils";

gql`
  query TaskSearch(
    $search: String
    $isCompleted: Boolean
    $priority: TaskPriority
    $patientUuid: UUID
    $assignedDoctorUuid: UUID
    $cursor: String
    $orderField: TaskSearchOrder!
  ) {
    taskSearch(
      filter: {
        freeTextSearch: $search
        isCompleted: $isCompleted
        priority: $priority
        patientUuid: $patientUuid
        assignedDoctorUuid: $assignedDoctorUuid
      }
    ) {
      totalCount
      tasks(
        page: { cursor: $cursor, numberOfItems: 20 }
        orderField: $orderField
      ) {
        data {
          ...Task
        }
        hasMore
        nextCursor
      }
    }
  }

  query GetUnreadTaskCount {
    me {
      doctor {
        uuid
        unreadTasksCount
      }
    }
  }

  mutation SetTaskStatus($taskUuid: UUID!, $isCompleted: Boolean!) {
    setTaskStatus(
      setTaskStatusInput: { taskUuid: $taskUuid, isCompleted: $isCompleted }
    ) {
      task {
        ...Task
      }
    }
  }

  mutation MarkTasksAsSeen {
    markTasksAsSeen {
      doctor {
        uuid
        unreadTasksCount
      }
    }
  }

  mutation DeleteTask($taskUuid: UUID!) {
    deleteTask(deleteTaskInput: { taskUuid: $taskUuid }) {
      taskUuid
    }
  }
`;

type ComposerState = { mode: "create" } | { mode: "edit"; task: TaskFragment };

export const Tasks = () => {
  const t = useTranslation();
  const { inputProps, search } = useDebounceSearch();
  const [composerState, setComposerState] = useState<ComposerState>();
  const [confirmationModalState, setConfirmationModalState] =
    useState<TaskConfirmationModalState>();
  const [showingDetails, setShowingDetails] = useState(false);
  const [selectedTask, setSelectedTask] = useState<TaskFragment>();

  const [filter, setFilter] = useState<FormTaskFilterValues>({
    status: "TODO",
    priority: undefined,
    patient: undefined,
    assignedDoctor: undefined,
  });

  const filterCount = taskFilterCount(filter);

  const [sortStatus, setSortStatus] = useState<SortStatus<TaskHeader>>({
    id: "CREATED_AT",
    isAscending: false,
  });

  const { data, previousData, loading, fetchingMore, nextPage } =
    usePaginatedQuery(TaskSearch, {
      variables: makeTaskSearchVariables(filter, search, sortStatus),
      selector: (d) => d.tasks,
    });

  const tasksData = data ?? previousData;

  const [setTaskStatus] = useMutation(SetTaskStatus, {
    onSuccess: (output, client) => {
      updateTaskStatusInCache(client, output.task, filter, search, sortStatus);
    },
  });
  const [deleteTask] = useMutation(DeleteTask);
  const [markTasksAsSeen] = useMutation(MarkTasksAsSeen);

  useEffect(() => {
    markTasksAsSeen();
  }, [markTasksAsSeen]);

  useSubscription(AppEventsSubscription, {
    onData: ({ event }) => {
      if (event.__typename === "TaskCreatedOrUpdatedEvent") {
        const timeoutId = setTimeout(() => {
          markTasksAsSeen();
        }, 3000);
        return () => clearTimeout(timeoutId);
      }
      return () => null;
    },
  });

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44">
      {selectedTask && (
        <TaskPanel
          task={selectedTask}
          filter={filter}
          search={search}
          sortStatus={sortStatus}
          onClose={() => setSelectedTask(undefined)}
        />
      )}
      {composerState && (
        <TaskComposer
          task={composerState.mode === "edit" ? composerState.task : undefined}
          onClose={() => setComposerState(undefined)}
        />
      )}
      {confirmationModalState && (
        <ControlledConfirmationModal
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <div className="lg:flex sm:flex-col sm:space-y-6 items-center">
        <div className="flex-col">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("tasks.tasks.tasks_title")}
          </h1>
          <div className="flex items-center">
            {tasksData && !showingDetails && (
              <span>
                {tasksData.totalCount} {t("tasks.tasks.tasks")}
              </span>
            )}
            {filterCount > 0 && (
              <>
                <Icon className="mx-6 text-body" name="checkCircle" size={10} />
                <button
                  onClick={() => {
                    setFilter({
                      status: "TODO",
                      patient: undefined,
                      assignedDoctor: undefined,
                      priority: undefined,
                    });
                  }}
                >
                  <span className="text-body text-14 font-normal underline">
                    {t("tasks.tasks.reset_filters")}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>
        {!showingDetails && (
          <>
            <Input
              {...inputProps}
              placeholder={t("tasks.tasks.search")}
              wrapperClassName="w-auto lg:ml-auto lg:mr-12 sm:mt-12"
              style={{ width: 288 }}
              leftInnerElement="search"
              rightInnerElement={
                search ? (
                  <ClickableIcon
                    name="closeCircle"
                    onClick={() => {
                      inputProps.onChange({ currentTarget: { value: "" } });
                    }}
                  />
                ) : null
              }
            />
            <TasksFilterMenu
              initialFilter={filter}
              onFilterSelected={(newFilter) => {
                setFilter(newFilter);
              }}
            />
            <Button
              label={t("tasks.tasks.add_a_task")}
              onClick={() => setComposerState({ mode: "create" })}
              className="sm:mt-12"
            />
          </>
        )}
      </div>
      <div className="mt-24">
        {tasksData ? (
          <Table<TaskHeader, TaskFragment>
            elements={tasksData.tasks.data.filterNotNull()}
            onShowDetails={(show) => setShowingDetails(show)}
            fieldHeaders={[
              {
                id: "TITLE",
                name: t("tasks.tasks.tasks_title"),
                sortable: false,
              },
              {
                id: "PATIENT",
                name: t("tasks.tasks.patient"),
                sortable: false,
                noWrap: true,
              },
              {
                id: "CREATED_AT",
                name: t("tasks.tasks.created_at"),
                sortable: true,
                noWrap: true,
              },
              {
                id: "CREATED_BY",
                name: t("tasks.tasks.created_by"),
                sortable: false,
                noWrap: true,
              },
              {
                id: "ASSIGNED_DOCTOR",
                name: t("tasks.tasks.assignee"),
                sortable: false,
                noWrap: true,
              },
              {
                id: "PRIORITY",
                name: t("tasks.tasks.priority"),
                sortable: true,
                noWrap: true,
              },
            ]}
            fields={(task) => [
              <div key={task.uuid} className="flex items-center">
                <TaskIcon
                  task={task}
                  onClick={() =>
                    setTaskStatus({
                      taskUuid: task.uuid,
                      isCompleted: !task.isCompleted,
                    })
                  }
                />
                <button
                  key={task.uuid}
                  className={classNames(
                    "max-w-330 flex-fill",
                    task.isCompleted && "line-through text-body",
                  )}
                  onClick={() => setSelectedTask(task)}
                >
                  <HighlightText
                    text={task.title}
                    search={search}
                    className="line-clamp-2 hover:underline"
                  />
                </button>
              </div>,
              task.patient ? (
                <Link
                  className="hover:underline"
                  to={getPatientViewRoute(task.patient, [task])}
                >
                  <HighlightText
                    key={task.uuid}
                    text={displayPatient(task.patient)}
                    search={search}
                  />
                </Link>
              ) : null,
              task.createdAt.format({ exception: "d MMM yyyy" }),
              <HighlightText
                key={task.uuid}
                text={displayDoctor(task.author)}
                search={search}
              />,
              <HighlightText
                key={task.uuid}
                text={
                  task.assignedDoctor ? displayDoctor(task.assignedDoctor) : ""
                }
                search={search}
              />,
              <span
                key={task.uuid}
                className={classNames(
                  task.priority ? displayTaskColor(task.priority) : "",
                )}
              >
                {task.priority && displayTaskPriority(task.priority)}
              </span>,
            ]}
            menuItems={(task) => [
              {
                icon: "edit",
                text: t("tasks.tasks.edit"),
                onClick: (close: () => void) => {
                  setComposerState({ mode: "edit", task });
                  close();
                },
              },
              {
                icon: "trash",
                text: t("tasks.tasks.delete"),
                className: "text-danger",
                onClick: (close: () => void) => {
                  close();
                  setConfirmationModalState({
                    cta: {
                      label: t("tasks.tasks.delete_this_task"),
                      danger: true,
                    },
                    children: t("tasks.tasks.delete_this_task_confirmation", {
                      title: task.title,
                    }),
                    onConfirm: () =>
                      deleteTask(
                        { taskUuid: task.uuid },
                        {
                          onSuccess: (_, client) => {
                            setConfirmationModalState(undefined);
                            client.remove("Task", task.uuid);
                            notifier.success(t("tasks.tasks.task_deleted"));
                          },
                        },
                      ),
                  });
                },
              },
            ]}
            sortStatus={sortStatus}
            onClickSortableHeader={(id) => {
              if (sortStatus.id === id) {
                setSortStatus({ id, isAscending: !sortStatus.isAscending });
              } else {
                setSortStatus({ id, isAscending: false });
              }
            }}
            emptyPlaceholder={<TasksEmptyPlaceholder search={search} />}
          />
        ) : loading ? (
          <Spinner />
        ) : null}
      </div>
      <NextPageButton nextPage={nextPage} fetchingMore={fetchingMore} />
    </Background>
  );
};

const TaskIcon = ({
  task,
  onClick,
}: {
  task: TaskFragment;
  onClick: () => void;
}) => (
  <button className="relative flex text-body group p-6" onClick={onClick}>
    <Icon size={20} name={task.isCompleted ? "checkCircle" : "radioOff"} />
    <div className="absolute z-1 mt-6 ml-6">
      {task.isCompleted ? (
        <Icon size={8} name="checkMark" className="text-white" />
      ) : (
        <Icon
          size={8}
          name="checkMark"
          className="text-body hidden group-hover:flex"
        />
      )}
    </div>
  </button>
);

const TasksEmptyPlaceholder = ({ search }: { search?: string }) => {
  const t = useTranslation();

  return (
    <div className="flex-col w-full flex-center space-y-4 my-80">
      <div className="text-primary-dark font-bold text-24">
        {search ? t("tasks.tasks.no_results") : t("tasks.tasks.no_task_yet")}
      </div>
      <div className="text-body font-regular text-14">
        {search
          ? t("tasks.please_try_another_search")
          : t("tasks.dont_hesitate_to_create_a_new_task")}
      </div>
    </div>
  );
};
