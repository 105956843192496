import { Route, Routes } from "react-router-dom";

import { routes } from "routes";

import { NoteAnnotation } from "./NoteAnnotation";
import { NoteAnnotationsComparison } from "./NoteAnnotationsComparison";
import { NoteAnnotationTable } from "./NoteAnnotationTable";
import { RecordedConversationWithAnnotatedNotes } from "./RecordedConversationWithAnnotatedNotes";

export const NoteAnnotationHome = () => (
  <Routes>
    <Route path="/" element={<NoteAnnotationTable />} />
    <Route path="/:uuid" element={<RecordedConversationWithAnnotatedNotes />} />
    <Route
      path={`/:recordedConversationUuid/${routes.ANNOTATED_NOTE}/:noteUuid`}
      element={<NoteAnnotation />}
    />
    <Route path="/:recordedConversationUuid/new" element={<NoteAnnotation />} />
    <Route
      path="/:recordedConversationUuid/comparison"
      element={<NoteAnnotationsComparison />}
    />
  </Routes>
);
