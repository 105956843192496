import { useLayoutEffect, useState } from "react";
import classNames from "classnames";
import { Resizable } from "re-resizable";

import { AudioMessageRecorder } from "components/composer-parts/AudioMessageRecorder";
import { AutocompleteInput } from "components/composer-parts/AutocompleteInput/AutocompleteInput";
import { ExperienceComposerBottomSafeArea } from "components/composer-parts/ExperienceComposerBottomSafeArea";
import { ExperienceEmojiPicker } from "components/composer-parts/ExperienceEmojiPicker";
import { Gallery } from "components/composer-parts/Gallery";
import { MediaPicker } from "components/composer-parts/MediaPicker";
import { ReplyMessageBanner } from "components/composer-parts/ReplyMessageBanner";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import {
  MenuItemProps,
  MultiLevelMenuItemProps,
} from "components/Menu/MenuItem";
import { MultilevelPopoverMenu } from "components/Menu/MultilevelMenu";
import { useAddPrescription } from "components/Patient/Timeline/Item/useAddPrescription";
import { QuestionsSetReader } from "components/QuestionsSet/QuestionsSetReader";
import { SidePanel } from "components/SidePanel/SidePanel";
import { Spinner } from "components/Spinner/Spinner";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import {
  CreateQAExperienceWithMessagesData,
  PrescriptionTemplates,
  UpdateExperienceLockStatus,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useDocumentUploadMenuSubItems } from "hooks/useDocumentUploadMenuSubItems";
import { useTranslation } from "i18n";
import { insertText, run } from "utils";
import { getDefaultMessageIntro } from "utils/display";

import { useQACopilotPanel } from "../../QACopilotContext/QACopilotPanelContext";
import { useMaybeQAExperience } from "../../QAExperienceContext/QAExperienceContext";
import { replaceUsername } from "../../utils";
import { QAQuestionsSetComposer } from "../QAQuestionsSetPanel/QAQuestionsSetComposer";
import { CreateAndSendButton } from "./CreateAndSendButton";
import { SendButton } from "./SendButton";
import {
  useSendExistingExperienceButtonsUtils,
  useSendNewExperienceButtonUtils,
} from "./utils";

const QA_COMPOSER_ID = "qa-composer"; // re-resizable doesn't support ref override

export const QAComposer = ({
  compact,
  autofocus,
  setIsTimelineOpen,
  onCreateExperienceSuccess,
}: {
  compact?: boolean;
  autofocus?: boolean;
  setIsTimelineOpen?: (isOpen: boolean) => void;
  onCreateExperienceSuccess?: (
    result: CreateQAExperienceWithMessagesData,
  ) => void;
}) => {
  const t = useTranslation();
  const { experience, patient, replyMessage, messagesContainerRef } =
    useMaybeQAExperience();
  const { isCopilotPanelOpened, openCopilotPanel, closeCopilotPanel } =
    useQACopilotPanel();
  const {
    setLivekitRoomMessageInput,
    mediaMessages,
    setQuestionsSet,
    questionsSet,
  } = useExperienceDraft();
  const { hasPermission, hasAccessToGatekeeperFeature } = useDoctor();
  const [headerHeight, setHeaderHeight] = useState(0);
  const { isAddingPrescription } = useAddPrescription(patient.uuid);

  const canSendMessage = hasPermission("ANSWER_QA_EXPERIENCE");

  const [updateExperienceLockStatus, updatingLockStatus] = useMutation(
    UpdateExperienceLockStatus,
  );

  const lockFeatureAvailable = patient.devices.some(
    (d) => d.codeVersion && d.codeVersion >= 478,
  );

  useLayoutEffect(() => {
    setHeaderHeight(messagesContainerRef.current!.getBoundingClientRect().top);
  }, [messagesContainerRef]);

  const documentItems = useDocumentItems(setIsTimelineOpen);
  const prescriptionItems = usePrescriptionItems(setIsTimelineOpen);
  const questionsSetItem = useQuestionsSetItem(() => {
    setQuestionsSet([]);
    setIsQuestionsSetComposerPanelOpened(true);
  });
  const [
    isQuestionsSetComposerPanelOpened,
    setIsQuestionsSetComposerPanelOpened,
  ] = useState(false);
  const [isQuestionsSetReaderPanelOpened, setIsQuestionsSetReaderPanelOpened] =
    useState(false);

  const [askToClose, setAskToClose] = useState(false);

  return (
    <div className="w-full flex-col">
      <SidePanel
        opened={isQuestionsSetComposerPanelOpened}
        width={660}
        onClickOutside={() => setAskToClose(true)}
      >
        <QAQuestionsSetComposer
          onRequestClose={() => {
            setAskToClose(false);
            setIsQuestionsSetComposerPanelOpened(false);
          }}
          onCancelClose={() => setAskToClose(false)}
          askToClose={askToClose}
        />
      </SidePanel>
      <SidePanel
        opened={isQuestionsSetReaderPanelOpened}
        width={660}
        onClickOutside={() => setIsQuestionsSetReaderPanelOpened(false)}
      >
        <QuestionsSetReader
          onRequestClose={() => setIsQuestionsSetReaderPanelOpened(false)}
          form={questionsSet}
        />
      </SidePanel>
      <Gallery
        onClickQuestionsSet={() => setIsQuestionsSetReaderPanelOpened(true)}
      />
      <Resizable
        // @ts-ignore
        id={
          // Comment to avoid prettier putting the constant on the ignored line
          QA_COMPOSER_ID
        }
        className="flex-fill bg-white lg:rounded flex-col relative p-16"
        minHeight={113 + (replyMessage ? 50 : 0)}
        maxHeight={
          window.innerHeight -
          headerHeight -
          15 - // Keep a small margin between composer and header
          (mediaMessages.isNotEmpty() ? 120 : 0)
        }
        handleStyles={{ top: { top: 0 } }}
        enable={{
          top: true,
          bottom: false,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: false,
          topLeft: false,
          topRight: false,
        }}
      >
        {!compact && (
          <Icon name="minus" className="absolute self-center -top-2" />
        )}
        {experience && <ReplyMessageBanner className="mb-12" />}
        <div className="flex-fill flex items-end">
          <div className="h-full flex-fill flex-col relative">
            {experience ? (
              <QAComposerAutocompleteExistingExperienceInput
                autofocus={autofocus}
              />
            ) : (
              <QAComposerAutocompleteNewExperienceInput
                onSuccess={onCreateExperienceSuccess}
                autofocus={autofocus}
              />
            )}
            <ExperienceEmojiPicker className="absolute inset-br-6" />
          </div>
        </div>
        <div className="flex items-center mt-8 justify-between">
          <div className="flex items-center sm:space-x-10">
            <ClickableIcon
              name="wand"
              size={20}
              className={classNames("p-4 rounded hover:bg-grey-100", {
                "text-primary": isCopilotPanelOpened,
              })}
              onClick={() => {
                if (isCopilotPanelOpened) {
                  closeCopilotPanel();
                } else {
                  openCopilotPanel();
                }
              }}
            />
            <MediaPicker
              className="p-4 hover:bg-grey-100 rounded"
              disabled={!canSendMessage}
            />
            <AudioMessageRecorder
              buttonClassName="p-4 hover:bg-grey-100 rounded"
              disabled={!canSendMessage}
            />
            <MultilevelPopoverMenu
              position="top-left"
              className="mt-10 min-w-[180px]"
              items={[
                documentItems,
                prescriptionItems,
                ...(hasAccessToGatekeeperFeature("QUESTIONS_SET")
                  ? [questionsSetItem]
                  : []),
              ]}
            >
              {({ setTarget, opened }) => (
                <TooltipWrapper
                  show={!opened}
                  label={t("inboxes.qa_experience.new_document")}
                  position="top"
                >
                  {isAddingPrescription ? (
                    <div className="p-4 flex items-center justify-center">
                      <Spinner className="w-20 h-20" />
                    </div>
                  ) : (
                    <ClickableIcon
                      className="p-4 hover:bg-grey-100 rounded"
                      onClick={setTarget}
                      name="fileText"
                      size={20}
                    />
                  )}
                </TooltipWrapper>
              )}
            </MultilevelPopoverMenu>
            <ClickableIcon
              className="p-4 hover:bg-grey-100 rounded"
              onClick={() => {
                setLivekitRoomMessageInput(true);
              }}
              disabled={{
                if: !patient.devices.some(
                  (d) =>
                    d.sdkModules.includes("MESSAGING") &&
                    d.sdkModules.includes("VIDEO_CALL"),
                ),
                tooltip: t("inboxes.qa_experience.feature_not_supported"),
                position: "right",
              }}
              name="videoOn"
              size={20}
            />
          </div>

          <div className="flex items-center space-x-8">
            {experience && (
              <ClickableIcon
                name="lock"
                className="rounded border h-32"
                disabled={run(() => {
                  if (!canSendMessage || updatingLockStatus) return true;
                  return {
                    if: !lockFeatureAvailable,
                    tooltip: t(
                      "inboxes.qa_experience.lock_feature_not_supported",
                    ),
                    position: "top",
                  };
                })}
                onClick={() =>
                  updateExperienceLockStatus({
                    experienceUuid: experience.uuid,
                    input: { isLocked: true },
                  })
                }
              />
            )}

            {experience ? (
              <SendButton disabled={!canSendMessage} />
            ) : (
              <CreateAndSendButton
                onSuccess={onCreateExperienceSuccess}
                disabled={!canSendMessage}
              />
            )}
          </div>
        </div>

        <ExperienceComposerBottomSafeArea />
      </Resizable>
    </div>
  );
};

const QAComposerAutocompleteExistingExperienceInput = ({
  autofocus,
}: {
  autofocus?: boolean;
}) => {
  const { sendMessageAndAddDoctorIfNeeded } =
    useSendExistingExperienceButtonsUtils();

  return (
    <QAComposerAutocompleteInput
      autofocus={autofocus}
      onPressCmdEnter={sendMessageAndAddDoctorIfNeeded}
    />
  );
};

const QAComposerAutocompleteNewExperienceInput = ({
  onSuccess,
  autofocus,
}: {
  onSuccess?: (result: CreateQAExperienceWithMessagesData) => void;
  autofocus?: boolean;
}) => {
  const { createExperienceAndSendMessages } = useSendNewExperienceButtonUtils();

  return (
    <QAComposerAutocompleteInput
      autofocus={autofocus}
      onPressCmdEnter={() => createExperienceAndSendMessages(onSuccess)}
    />
  );
};

const QAComposerAutocompleteInput = ({
  autofocus,
  onPressCmdEnter,
}: {
  autofocus?: boolean;
  onPressCmdEnter?: () => void;
}) => {
  const { uuid, patient, items, hasMoreItems, textAreaRef, isInExperience } =
    useMaybeQAExperience();
  const { hasPermission } = useDoctor();
  const canSendMessage = hasPermission("ANSWER_QA_EXPERIENCE");

  return (
    <AutocompleteInput
      autoFocus={autofocus}
      className="pr-32"
      patient={patient}
      disabled={!canSendMessage}
      prefillValue={
        !uuid ||
        (!hasMoreItems &&
          items &&
          items.none(
            (i) => i.__typename === "Message" && i.senderType === "DOCTOR",
          ))
          ? getDefaultMessageIntro(patient)
          : undefined
      }
      onPaste={(e) => {
        e.preventDefault();
        insertText(replaceUsername(e.clipboardData.getData("text"), patient));
      }}
      skipSetTyping={!isInExperience}
      onHeightChange={(height) => {
        if (height > textAreaRef.current!.clientHeight) {
          document
            .getElementById(QA_COMPOSER_ID)!
            .style.setProperty("height", null);
        }
      }}
      onPressCmdEnter={onPressCmdEnter}
    />
  );
};

const useDocumentItems = (
  setIsTimelineOpen?: (isOpen: boolean) => void,
): MultiLevelMenuItemProps => {
  const t = useTranslation();

  const documentSubItems = useDocumentUploadMenuSubItems({
    onClose: () => setIsTimelineOpen?.(true),
  });

  return {
    icon: "fileText" as const,
    text: t("inboxes.qa_experience.new_document"),
    subItemsPosition: "top",
    subItems: documentSubItems,
  };
};

const usePrescriptionItems = (
  setIsTimelineOpen?: (isOpen: boolean) => void,
): MultiLevelMenuItemProps => {
  const t = useTranslation();
  const { patient } = useMaybeQAExperience();
  const { data } = useQuery(PrescriptionTemplates);
  const { addPrescription } = useAddPrescription(patient.uuid);

  if (!data || data.isEmpty()) {
    return {
      icon: "pill" as const,
      text: t("inboxes.qa_experience.new_prescription"),
      onClick: (closeMenu: () => void) => {
        addPrescription().then(() => {
          setIsTimelineOpen?.(true);
        });
        closeMenu();
      },
    };
  }

  const templatesSubItems: MenuItemProps[] = data.map((template) => ({
    icon: "fileText" as const,
    text: template.title,
    onClick: (closeMenu: () => void) => {
      closeMenu();
      setIsTimelineOpen?.(true);
      addPrescription(template).then(() => {
        setIsTimelineOpen?.(true);
      });
    },
  }));

  return {
    icon: "pill" as const,
    text: t("inboxes.qa_experience.new_prescription"),
    subItems: [
      templatesSubItems,
      {
        icon: "add" as const,
        text: t("patient_view.prescription.blank"),
        topSeparator: true,
        onClick: (closeMenu: () => void) => {
          addPrescription().then(() => {
            setIsTimelineOpen?.(true);
          });
          closeMenu();
        },
      },
    ].flat(),
  };
};

const useQuestionsSetItem = (onClick: () => void): MenuItemProps => {
  const t = useTranslation();
  return {
    icon: "questionnaire" as const,
    text: t("inboxes.qa_experience.new_questions_set"),
    onClick: (closeMenu: () => void) => {
      closeMenu();
      onClick();
    },
  };
};
