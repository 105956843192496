import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { useDoctor } from "contexts/User/UserContext";
import { CreateOrUpdateAddress } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

type FormValues = {
  address: string;
  zipCode: string;
  city: string;
  state: string | undefined;
  country: string;
  extraDetails: string | undefined;
};

export const PersonalInformationAppointments = () => {
  const t = useTranslation();
  const { user } = useDoctor();
  const [createOrUpdateAddress] = useMutation(CreateOrUpdateAddress);

  return (
    <Form<FormValues>
      className="flex-fill flex-col flex-col overflow-y-auto space-y-8 pt-8 lg:max-w-440"
      initialValues={{
        address: user.appointmentAddress?.address ?? "",
        zipCode: user.appointmentAddress?.zipCode ?? "",
        city: user.appointmentAddress?.city ?? "",
        state: user.appointmentAddress?.state ?? undefined,
        country: user.appointmentAddress?.country ?? "",
        extraDetails: user.appointmentAddress?.extraDetails ?? undefined,
      }}
      validationSchema={{
        address: "required",
        zipCode: "required",
        city: "required",
        country: "required",
      }}
      onSubmit={async ({
        address,
        city,
        zipCode,
        state,
        country,
        extraDetails,
      }) => {
        const addressPayload = {
          address: address.trim(),
          zipCode: zipCode.trim(),
          city: city.trim(),
          state: state?.trimOrNull(),
          country: country.trim(),
          extraDetails: extraDetails?.trimOrNull(),
        };

        return createOrUpdateAddress(
          {
            doctorUuid: user.uuid,
            input: {
              purpose: "APPOINTMENTS",
              ...addressPayload,
            },
          },
          {
            onSuccess: () => {
              notifier.success(t("preferences.info.changes_saved"));
            },
          },
        );
      }}
    >
      <>
        <FormInput
          name="address"
          label={t("preferences.info.appointments.address.label")}
          placeholder={t("preferences.info.appointments.address")}
          wrapperClassName="flex-fill w-full"
        />
        <div className="flex items-end">
          <FormInput
            name="zipCode"
            placeholder={t("preferences.info.appointments.postal_code")}
            wrapperClassName="w-1/3 pr-8"
          />
          <FormInput
            name="city"
            placeholder={t("preferences.info.appointments.city")}
            wrapperClassName="flex-fill"
          />
        </div>
        <div className="flex items-end">
          <FormInput
            name="state"
            placeholder={t("preferences.info.appointments.state")}
            wrapperClassName="w-1/2 pr-8"
          />
          <FormInput
            name="country"
            placeholder={t("preferences.info.appointments.country")}
            wrapperClassName="flex-fill"
          />
        </div>
        <FormTextArea
          autoComplete="street-2"
          name="extraDetails"
          label={t("preferences.info.appointments.extra_details_label")}
          placeholder={t(
            "preferences.info.appointments.extra_details_placeholder",
          )}
          wrapperClassName="mt-24"
        />
        <Submit
          label={t("preferences.preferences_form.save")}
          className="mt-44 mr-auto"
          requiresDirty
        />
      </>
    </Form>
  );
};
