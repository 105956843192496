import classNames from "classnames";

import styles from "./notSeenDot.module.css";

export const NotSeenDot = ({
  notSeen,
  className,
}: {
  notSeen: boolean;
  className?: string;
}) => (
  <div
    className={classNames(styles.dot, className, { "bg-primary": notSeen })}
  />
);
