import { ReactNode, useRef, useState } from "react";
import classNames from "classnames";
import Draggable from "react-draggable";

export const DraggableToEdges = ({
  snapMode,
  initialEdge,
  className,
  children,
}: {
  snapMode: "nearestEdge" | "intendedEdge";
  initialEdge: ScreenEdge;
  className?: string;
  children: ReactNode;
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  // We want the container to "stick" to the current edge, even when resizing
  // the browser window, so it needs to be anchored to the edge using absolute
  // positioning and both (`left-0` or `right-0`) and (`top-0` or `bottom-0`).
  //
  // We also want a CSS transition when snapping to the closest edge, but
  // unfortunately transitions don't work when `left-0` turns into `right-0`
  // or `top-0` turns into `bottom-0`.
  //
  // Fortunately, the `Draggable` component uses a CSS `translate` transform
  // to move the container around (which can be animated with CSS transitions),
  // the offset of which can be controlled via the `position` prop.
  //
  // Note that we can't just rely on the `position` prop to snap the container
  // to the closest edge, because the container wouldn't be properly anchored
  // if e.g. the window size changed. However, we can find a middle ground:
  // 1. When dragging stops, immediately snap the container to the closest
  //    edge by changing the `translate` offset via the `position` prop.
  //    At the same time, enable CSS transitions of the `translate` transform.
  // 2. When the `translate` transition ends, disable all CSS transitions and
  //    anchor back the container to the new edge by changing its absolute
  //    positioning and resetting the `position` offset to (0, 0).

  const [currentEdge, setCurrentEdge] = useState<ScreenEdge>(initialEdge);
  const [nextEdge, setNextEdge] = useState<ScreenEdge | null>(null);

  const offsetFromCurrentEdge =
    nodeRef.current && nextEdge
      ? computeOffset(nodeRef.current, currentEdge, nextEdge)
      : { x: 0, y: 0 };

  return (
    // @ts-ignore Incorrect type for `children`.
    <Draggable
      bounds="parent"
      nodeRef={nodeRef}
      position={offsetFromCurrentEdge}
      onStart={() => setIsDragging(true)}
      onStop={(_, { node, x, y }) => {
        setIsDragging(false);
        setNextEdge(
          snapMode === "nearestEdge"
            ? computeNearestEdge(node)
            : computeIntendedEdge({ x, y }, currentEdge),
        );
      }}
    >
      <div
        ref={nodeRef}
        className={classNames(
          className,
          currentEdge.x === "left" ? "left-0" : "right-0",
          currentEdge.y === "top" ? "top-0" : "bottom-0",
          {
            "transition-transform duration-300":
              !isDragging && nextEdge !== null,
          },
        )}
        onTransitionEnd={() => {
          if (!nextEdge) return;
          setNextEdge(null);
          setCurrentEdge(nextEdge);
        }}
      >
        {children}
      </div>
    </Draggable>
  );
};

type Offset = { x: number; y: number };

export type ScreenEdge = {
  y: "top" | "bottom";
  x: "left" | "right";
};

const computeNearestEdge = (node: HTMLElement): ScreenEdge => {
  const { nodeRect, parentRect } = getBoundingBoxes(node);
  const distanceFromLeft = nodeRect.left - parentRect.left;
  const distanceFromRight = parentRect.right - nodeRect.right;
  const distanceFromTop = nodeRect.top - parentRect.top;
  const distanceFromBottom = parentRect.bottom - nodeRect.bottom;

  return {
    x: distanceFromLeft < distanceFromRight ? "left" : "right",
    y: distanceFromTop < distanceFromBottom ? "top" : "bottom",
  };
};

const DELTA_THRESHOLD = 100;

const computeIntendedEdge = (
  delta: Offset,
  currentEdge: ScreenEdge,
): ScreenEdge => {
  const oppositeEdge: ScreenEdge = {
    x: currentEdge.x === "left" ? "right" : "left",
    y: currentEdge.y === "top" ? "bottom" : "top",
  };

  const dx = Math.abs(delta.x);
  const dy = Math.abs(delta.y);

  if (dx < DELTA_THRESHOLD && dy < DELTA_THRESHOLD) return currentEdge;
  if (dx > 2 * dy) return { x: oppositeEdge.x, y: currentEdge.y };
  if (dy > 2 * dx) return { x: currentEdge.x, y: oppositeEdge.y };
  return oppositeEdge;
};

const computeOffset = (
  node: HTMLElement,
  fromEdge: ScreenEdge,
  toEdge: ScreenEdge,
): Offset => {
  const { nodeRect, parentRect } = getBoundingBoxes(node);
  const offsetFromLeftToRight = parentRect.width - nodeRect.width;
  const offsetFromTopToBottom = parentRect.height - nodeRect.height;
  const sourceFactors = unitRectanglePosition(fromEdge);
  const targetFactors = unitRectanglePosition(toEdge);

  return {
    x: (targetFactors.x - sourceFactors.x) * offsetFromLeftToRight,
    y: (targetFactors.y - sourceFactors.y) * offsetFromTopToBottom,
  };
};

/**
 * Compute the bounding box of the node and its offset parent.
 * See https://developer.mozilla.org/fr/docs/Web/API/HTMLElement/offsetParent.
 *
 * If there is no parent, we assume a full-page parent.
 */
const getBoundingBoxes = (node: HTMLElement) => ({
  // Note that we use `getBoundingClientRect()` instead of `offsetWidth` etc.
  // because we must account for the CSS transform applied by `<Draggable>`.
  nodeRect: node.getBoundingClientRect(),
  parentRect:
    node.offsetParent?.getBoundingClientRect() ??
    new DOMRect(0, 0, window.innerWidth, window.innerHeight),
});

const unitRectanglePosition = ({ x, y }: ScreenEdge): Offset => ({
  x: x === "left" ? 0 : 1,
  y: y === "top" ? 0 : 1,
});
