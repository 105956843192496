import gql from "graphql-tag";

import {
  DataPointFragment,
  HealthDataPointFragment,
  SimpleHealthDataPointTypeKnownValue,
  SimpleHealthDataPointTypeKnownValues,
} from "generated/provider";
import { isKnownUnionValue } from "utils/apollo";
import { isKnownValue } from "utils/enum";

import { KnownUnionValue } from "./apollo";

gql`
  fragment HealthDataPoint on HealthDataPoint {
    uuid
    value {
      ... on SimpleHealthDataPointValue {
        type
        quantity
      }
      ... on BloodPressureValue {
        systolicBloodPressure
        diastolicBloodPressure
      }
    }
    createdAt
    updatedAt
    updatedBy {
      ...DoctorSummary
    }
  }
`;

export type HealthDataPointFragmentWithKnownUnionValue = DataPointFragment & {
  value:
    | Exclude<
        KnownUnionValue<DataPointFragment["value"]>,
        { __typename: "SimpleHealthDataPointValue" }
      >
    | (Extract<
        KnownUnionValue<DataPointFragment["value"]>,
        { __typename: "SimpleHealthDataPointValue" }
      > & { type: SimpleHealthDataPointTypeKnownValue });
};

export const isHealthDataPointWithKnownUnionValue = (
  dataPoint: HealthDataPointFragment,
): dataPoint is HealthDataPointFragmentWithKnownUnionValue => {
  if (!isKnownUnionValue("HealthDataPointValue", dataPoint.value)) return false;
  if (dataPoint.value.__typename === "BloodPressureValue") return true;

  if (
    !isKnownValue(dataPoint.value.type, SimpleHealthDataPointTypeKnownValues)
  ) {
    return false;
  }

  return true;
};

export const HealthDataPointKnownValues = [
  ...SimpleHealthDataPointTypeKnownValues,
  "BLOOD_PRESSURE_MMHG",
] as const;

export type HealthDataPointKnownValue =
  typeof HealthDataPointKnownValues[number];
