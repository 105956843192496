import { useState } from "react";
import { gql } from "@apollo/client";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { Query } from "components/Query/Query";
import { RecordedConversationWithAnnotatedNote } from "generated/provider";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { NoteAnnotationComposer } from "./NoteAnnotationComposer";

gql`
  mutation CreateNoteAnnotation(
    $recordedConversationUuid: UUID!
    $input: NoteAnnotationInput!
  ) {
    createNoteAnnotation(
      annotationInput: $input
      recordedConversationUuid: $recordedConversationUuid
    ) {
      noteAnnotation {
        ...NoteAnnotation
      }
    }
  }

  mutation UpdateNoteAnnotation($uuid: UUID!, $input: NoteAnnotationInput!) {
    updateNoteAnnotation(annotationInput: $input, uuid: $uuid) {
      noteAnnotation {
        ...NoteAnnotation
      }
    }
  }
`;

export const NoteAnnotation = () => {
  const navigate = useNavigate();

  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const { recordedConversationUuid, noteUuid } = useParams();

  const onHideModal = () => {
    setShowThankYouModal(false);
    navigate(`/${routes.NOTE_ANNOTATION}/${recordedConversationUuid}`);
  };

  const t = useTranslation();

  return (
    <div className="bg-light-blue flex items-start justify-between flex-fill overflow-auto">
      <Modal
        show={showThankYouModal}
        onHide={onHideModal}
        title={t("recorded_conversations.thank_you")}
      >
        <div className="my-32">{t("recorded_conversations.thanks")}</div>
        <Button
          label={t("recorded_conversation.close")}
          onClick={onHideModal}
        />
      </Modal>
      <div className="flex-col flex-fill p-20 space-y-20">
        <Query
          query={RecordedConversationWithAnnotatedNote}
          variables={{ uuid: recordedConversationUuid }}
        >
          {(data) => {
            const audios = data.recordedConversation.audios;
            const currentNote = data.recordedConversation.annotatedNotes.find(
              (note) => note.uuid === noteUuid,
            );

            return (
              <NoteAnnotationComposer
                recordedConversationUuid={recordedConversationUuid}
                currentNote={currentNote}
                audios={audios}
                showThankYou={() => setShowThankYouModal(true)}
              />
            );
          }}
        </Query>
      </div>
    </div>
  );
};
