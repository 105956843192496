import gql from "graphql-tag";

import { AsyncPatientFormSelect } from "components/Form/Select/AsyncPatientFormSelect";
import { FormModal } from "components/Modal/FormModal";
import { AppErrorCode } from "errors/generated";
import {
  AddPatientToGroupConversation,
  PatientSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayPatient } from "utils/display";
import { notifier } from "utils/notifier";

type FormValues = {
  patient?: PatientSummaryFragment;
};

gql`
  mutation AddPatientToGroupConversation(
    $experienceUuid: UUID!
    $patientUuid: UUID!
  ) {
    addPatientToExperience(
      experienceUuid: $experienceUuid
      patientUuid: $patientUuid
    ) {
      experience {
        uuid
      }
    }
  }
`;
export const AddPatientComposer = ({
  experienceUuid,
  onClose,
}: {
  experienceUuid: UUID;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const [addPatient] = useMutation(AddPatientToGroupConversation);
  return (
    <FormModal<FormValues>
      title={t("view.community.group_conversation.add_patient_title")}
      initialValues={{
        patient: undefined,
      }}
      onHide={onClose}
      submitLabel={t("view.community.group_conversation.add_patient_cta")}
      className="flex-col w-full pb-20 px-44"
      onSubmit={({ patient }) => {
        if (!patient) return;
        addPatient(
          {
            experienceUuid,
            patientUuid: patient.uuid,
          },
          {
            refetchQueries: ["GetGroupConversationPaginatedPatient"],
            onSuccess: () => {
              notifier.success(
                t("view.community.group_conversation.patient_added"),
              );
            },
            onError: (error) => {
              if (error.code === AppErrorCode.ENTITY_IS_NOT_UNIQUE) {
                notifier.error({
                  user: t(
                    "view.community.group_conversation.add_patient_error",
                    { patient: displayPatient(patient) },
                  ),
                });
              } else {
                notifier.error({ user: error.display });
              }
            },
          },
        ).then(() => onClose());
      }}
    >
      <AsyncPatientFormSelect
        name="patient"
        label={t("view.community.group_conversation.add_patient_label")}
        wrapperClassName="flex-fill justify-between h-full"
      />
    </FormModal>
  );
};
