import { FamilyMemberDegreeKnownValues } from "generated/provider";
import { staticT as t } from "i18n";
import { isHealthDataPointWithKnownUnionValue, MentionedItem } from "types";
import { isKnownUnionValue } from "utils/apollo";
import {
  displayHealthDataPoint,
  getHealthDataPointEnumValue,
  getHealthDataPointNameMap,
} from "utils/data-points";
import {
  displayFamilyMemberDegree,
  displayUser,
  displayUsername,
} from "utils/display";
import { isKnownValue } from "utils/enum";

export const getMentionedItemLabel = (item: MentionedItem | null): string => {
  if (!item) {
    return t("form.editor.mention_editor.display.hashtag_unknown_mention");
  }
  if (item.__typename === "DraftMentionedItem") {
    return getMentionedItemLabel(item.draftedItem);
  }
  if (!isKnownUnionValue("MentionedItem", item)) {
    return t("form.editor.mention_editor.display.hashtag_unknown_mention");
  }
  switch (item.__typename) {
    case "MentionedPatientDocument":
      if (
        !isKnownUnionValue("MaybePatientDocument", item.maybePatientDocument)
      ) {
        return t("form.editor.mention_editor.display.hashtag_unknown_mention");
      }
      switch (item.maybePatientDocument.__typename) {
        case "DeletedPatientDocument":
          return t("form.editor.mention_editor.display.deleted_document");
        case "InaccessiblePatientDocument":
          return t("form.editor.mention_editor.display.inaccessible_document");
        case "PatientDocument":
          return `$${item.maybePatientDocument.patientDocumentTitle}`;
      }
    case "HealthDataPoint":
      if (!isHealthDataPointWithKnownUnionValue(item)) {
        return t("form.editor.mention_editor.display.hashtag_unknown_mention");
      }
      return `${getHealthDataPointEnumValue(
        item,
        getHealthDataPointNameMap(),
      ).toLowerCase()}${t("utils.colon")}${displayHealthDataPoint(item)}`;
    case "Symptom":
    case "MedicationStatement":
    case "PubliclyListedDrug":
      return item.name;
    case "VaccinationStatement":
      return item.type;
    case "Condition":
      if (
        item.familyMember &&
        isKnownValue(item.familyMember, FamilyMemberDegreeKnownValues)
      ) {
        return `${item.type.upperFirst()} (${displayFamilyMemberDegree(
          item.familyMember,
        )})`;
      }
      return item.type;
    case "Procedure":
      return item.name;
    case "PatientAllergy":
      return item.type;
    case "MentionedDoctor":
      if (!isKnownUnionValue("MaybeDoctor", item.maybeDoctor)) {
        return t("form.editor.mention_editor.display.hashtag_unknown_mention");
      }
      switch (item.maybeDoctor.__typename) {
        case "DeletedDoctor":
          return t("form.editor.mention_editor.display.deleted_doctor");
        case "InaccessibleDoctor":
          return t("form.editor.mention_editor.display.inaccessible_doctor");
        case "Doctor":
          return `@${displayUser(item.maybeDoctor)}`;
      }
    case "PatientNote":
      return `#${item.title}`;
    case "MentionedPatient":
      if (!isKnownUnionValue("MaybePublicPatient", item.maybePublicPatient)) {
        return t("form.editor.mention_editor.display.hashtag_unknown_mention");
      }
      switch (item.maybePublicPatient.__typename) {
        case "DeletedPatient":
          return `@${displayUsername(null)}`;
        case "PublicPatient":
          return `@${displayUsername(item.maybePublicPatient)}`;
      }
    case "Experience":
    case "DeletedProcedure":
    case "DeletedAllergy":
    case "DeletedCondition":
    case "DeletedDatapoint":
    case "DeletedMedication":
    case "DeletedSymptom":
    case "DeletedVaccination":
      return t("form.editor.mention_editor.display.dollar_unknown_mention");
  }
};
