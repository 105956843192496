import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

const CARD_WIDTH = 380;

export const SlidingCard = ({
  children,
  cardStyle,
  cardClassName,
}: {
  children?: ReactNode;
  cardStyle?: Omit<CSSProperties, "width">;
  cardClassName?: string;
}) => (
  <aside
    className="flex-col overflow-hidden transition duration-300"
    style={{ width: children ? CARD_WIDTH : 0 }}
  >
    {children ? (
      <div
        style={{ width: CARD_WIDTH, ...cardStyle }}
        className={classNames("h-full overflow-auto", cardClassName)}
      >
        {children}
      </div>
    ) : null}
  </aside>
);
