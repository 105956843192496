import { Capacitor } from "@capacitor/core";
import { withScope } from "@sentry/browser";
import axios, { AxiosError, AxiosResponse, CancelToken } from "axios";
import { z } from "zod";

import {
  NablaRegionKnownValue,
  NablaRegionKnownValues,
} from "generated/account";
import { staticT as t } from "i18n";
import { getKnownValue } from "utils/enum";
import { apiUrl } from "utils/environment";
import { notifier } from "utils/notifier";
import versions from "versions.json";

import { jwtRegion } from "./jwt";
import {
  AnyEndpointDefinition,
  API_KIND_BASE_PATH,
  EndpointAuthenticationKind,
  RequestContext,
  RequestPayload,
} from "./types";

/**
 * Queries an API endpoint and returns the validated response.
 * Throws if:
 * - the request fails,
 * - the request data doesn't match the expected schema,
 * - the response data doesn't match the expected schema.
 */
export const queryEndpoint = <D extends AnyEndpointDefinition>(
  definition: D,
  payload: RequestPayload<D>,
): Promise<ApiResponse<z.infer<D["responseSchema"]>>> =>
  queryEndpointCancellable(
    definition,
    payload,
    axios.CancelToken.source().token,
  );

export const queryEndpointCancellable = async <D extends AnyEndpointDefinition>(
  definition: D,
  payload: RequestPayload<D>,
  cancelToken: CancelToken,
  forceRefresh = false,
): Promise<ApiResponse<z.infer<D["responseSchema"]>>> => {
  const {
    clientName = getDefaultClientName(),
    headers: extraHeaders,
    params,
    data,
    requestContext,
    onUploadProgress,
  } = payload;

  const path = `${API_KIND_BASE_PATH[definition.apiKind]}/${definition.path}`;
  const acceptLanguage = t("utils.network.accept_language");
  const codeVersion = versions.code_version.toString();

  // Even though the TypeScript compiler should prevent invalid shapes for the
  // request params and data, we still validate them at runtime to check rules
  // that the compiler can't enforce–e.g. the length of strings.
  const parsedParams = params
    ? definition.requestParamsSchema?.parse(params)
    : undefined;
  const parsedData = data
    ? definition.requestDataSchema?.parse(data)
    : undefined;

  const {
    token,
    explicitRegion,
    organizationId,
    subOrganizationId,
    impersonationUuid,
    currentIdentityUuid,
    regionByAccountEmail,
  } = await getRawRequestContext(requestContext, forceRefresh);

  const headers = {
    "Accept-Language": acceptLanguage,
    "X-Nabla-Code-Version": codeVersion,
    "X-Nabla-Client-Name": clientName,
    ...(token ? { "X-Nabla-Authorization": `Bearer ${token}` } : {}),
    ...(organizationId ? { "X-Nabla-Organization-ID": organizationId } : {}),
    ...(subOrganizationId
      ? { "X-Nabla-SubOrganization-ID": subOrganizationId }
      : {}),
    ...(impersonationUuid
      ? { "X-Nabla-Impersonation": impersonationUuid }
      : {}),
    ...(currentIdentityUuid
      ? { "X-Nabla-Identity-Id": currentIdentityUuid }
      : {}),
    ...(regionByAccountEmail
      ? { "X-Nabla-Account-Email": regionByAccountEmail }
      : {}),
    ...extraHeaders,
  };

  return axios
    .request({
      url: `${apiUrl(explicitRegion)}/${path}`,
      withCredentials: true,
      method: parsedData ? "POST" : "GET",
      params: parsedParams,
      data: parsedData,
      cancelToken,
      headers,
      onUploadProgress: onUploadProgress
        ? (e: ProgressEvent) =>
            e.lengthComputable &&
            onUploadProgress(Math.round((e.loaded * 100) / e.total))
        : undefined,
    })
    .catch((error) => {
      // Rethrow errors which didn't come from Axios.
      if (!error || typeof error !== "object" || !error.isAxiosError) {
        throw error;
      }

      const apiError = new ApiError(error);

      // Retry the request once if the error is a 401.
      if (apiError.isUnauthorized && !forceRefresh) {
        return queryEndpointCancellable(definition, payload, cancelToken, true);
      }

      if (!apiError.isUnauthorized) {
        withScope((scope) => {
          if (apiError.requestId) {
            scope.setTag("request-id", apiError.requestId);
          }
          notifier.error({ sentry: { exception: error } });
        });
      }

      throw apiError;
    })
    .then((response) => ({
      ...response,
      ...parseResponseMetadata(response.headers),
      data: definition.responseSchema.parse(response.data),
    }));
};

export const getRawRequestContext = async (
  requestContext: RequestContext<EndpointAuthenticationKind>,
  forceRefresh = false,
) => {
  const doctorAccessToken =
    "doctorAccessToken" in requestContext
      ? await requestContext.doctorAccessToken(forceRefresh)
      : undefined;
  const accountAccessToken =
    "accountAccessToken" in requestContext
      ? await requestContext.accountAccessToken(forceRefresh)
      : undefined;
  const serverToken =
    "serverToken" in requestContext
      ? await requestContext.serverToken(forceRefresh)
      : undefined;

  const token = doctorAccessToken ?? accountAccessToken ?? serverToken;

  const explicitRegion: NablaRegionKnownValue | undefined =
    token?.let(jwtRegion) ??
    ("region" in requestContext && // UNAUTHENTICATED_ALMIGHTY case.
    requestContext.region !== "CLOSEST_REGION"
      ? requestContext.region
      : undefined);

  const organizationId =
    "organizationId" in requestContext &&
    requestContext.organizationId.isNotBlank()
      ? requestContext.organizationId
      : "regionByOrganizationId" in requestContext &&
        requestContext.regionByOrganizationId.isNotBlank()
      ? requestContext.regionByOrganizationId
      : undefined;

  const subOrganizationId =
    "subOrganizationId" in requestContext &&
    requestContext.subOrganizationId?.isNotBlank()
      ? requestContext.subOrganizationId
      : undefined;

  const impersonationUuid =
    "impersonationUuid" in requestContext &&
    requestContext.impersonationUuid?.isNotBlank()
      ? requestContext.impersonationUuid
      : undefined;

  const currentIdentityUuid =
    "currentIdentityUuid" in requestContext &&
    requestContext.currentIdentityUuid.isNotBlank()
      ? requestContext.currentIdentityUuid
      : undefined;

  const regionByAccountEmail =
    "regionByAccountEmail" in requestContext &&
    requestContext.regionByAccountEmail.isNotBlank()
      ? requestContext.regionByAccountEmail
      : undefined;

  return {
    token: token?.token,
    explicitRegion,
    organizationId,
    subOrganizationId,
    impersonationUuid,
    currentIdentityUuid,
    regionByAccountEmail,
  };
};

export type ApiResponse<Data> = AxiosResponse<Data> & ApiResponseMetadata;

/**
 * Errors coming from the backend or from the network layer.
 *
 * We use a class instead of a plain object because it makes it easier to
 * check the type of the exception in a `catch` block using `instanceof`.
 */
// prettier-ignore
export class ApiError
  extends Error
  implements AxiosError<ApiErrorDetails>, ApiResponseMetadata
{
  public requestId?: string;
  public region?: NablaRegionKnownValue;
  public minimumCodeVersion?: number;

  constructor(private axiosError: AxiosError<ApiErrorDetails>) {
    super(axiosError.message);
    if (axiosError.response) {
      const metadata = parseResponseMetadata(axiosError.response.headers);
      this.requestId = metadata.requestId;
      this.region = metadata.region;
      this.minimumCodeVersion = metadata.minimumCodeVersion;
    }
  }

  get isAxiosError() { return true; }
  get config() { return this.axiosError.config; }
  get code() { return this.axiosError.code; }
  get request() { return this.axiosError.request; }
  get response() { return this.axiosError.response; }
  get isUnauthorized() { return this.response?.status === 401; }
  get isCanceled() { return axios.isCancel(this.axiosError); }

  get errorMessage() { return this.axiosError.response?.data.message };
  get errorCode() { return this.axiosError.response?.data.code };
  get errorName() { return this.axiosError.response?.data.name };

  toJSON() {
    const { ...members } = this;
    return members;
  }
}

// Keep in sync with `RequestExceptionHandling.kt`.
type ApiErrorDetails = {
  message?: string;
  code?: number;
  name?: string;
};

type ApiResponseMetadata = {
  requestId?: string;
  region?: NablaRegionKnownValue;
  minimumCodeVersion?: number;
};

const parseResponseMetadata = (rawHeaders: any): ApiResponseMetadata => {
  const metadata: ApiResponseMetadata = {};
  const headers = new Headers(rawHeaders);

  const minCodeVersion = headers.get("X-Nabla-Minimum-Code-Version");
  if (minCodeVersion) metadata.minimumCodeVersion = parseInt(minCodeVersion);

  const requestId = headers.get("X-Request-Id");
  if (requestId) metadata.requestId = requestId;

  const region = getKnownValue(
    headers.get("X-Nabla-Region"),
    NablaRegionKnownValues,
  );
  if (region) metadata.region = region;

  return metadata;
};

const getDefaultClientName = () =>
  Capacitor.isNativePlatform()
    ? "web-ios"
    : window.matchMedia("(display-mode: standalone)").matches
    ? "web-android"
    : window.matchMedia("(max-width: 1024px)").matches
    ? "web-mobile"
    : "web";
