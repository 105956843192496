import { createContext, useContext } from "react";

import {
  GetPatientViewDoneItemsData,
  GetPatientViewPatientDataData,
  GetPatientViewTodoItemsData,
} from "generated/provider";

export const PatientViewContext = createContext<{
  patientData: GetPatientViewPatientDataData;
  todoItems: GetPatientViewTodoItemsData;
  doneItems: GetPatientViewDoneItemsData;
} | null>(null);
PatientViewContext.displayName = "PatientViewContext";

export const usePatientViewData = () => {
  const context = useContext(PatientViewContext);
  if (!context) throw new Error("PatientViewContext not available");
  return context;
};
