import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Input } from "components/Form/Input/Input";
import { FormRow } from "components/FormRow/FormRow";
import { useCopilotApiUser } from "contexts/User/UserContext";
import { UpdateSettings } from "generated/copilot-api-user";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = COPILOT_API_USER
  mutation UpdateSettings($input: UpdateSettingsCopilotApiInput!) {
    updateSettings(input: $input) {
      user {
        ...CopilotApiUserSummary
      }
    }
  }
`;

type FormValues = {
  firstName: string;
  lastName: string;
};

export const PersonalInformation = () => {
  const t = useTranslation();
  const { user, email } = useCopilotApiUser();
  const [updateSettings] = useMutation(UpdateSettings);

  return (
    <div className="flex-col flex-fill bg-white overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold pt-16 lg:pt-24 mb-28">
          {t("preferences.info.personal_information")}
        </h1>

        <Form<FormValues>
          className="flex-fill flex-col max-w-[1080px] overflow-y-auto space-y-24"
          initialValues={{
            firstName: user.firstName ?? "",
            lastName: user.lastName ?? "",
          }}
          onSubmit={async ({ firstName, lastName }) =>
            updateSettings(
              {
                input: {
                  firstName: firstName.trimOrNull(),
                  lastName: lastName.trimOrNull(),
                },
              },
              {
                onSuccess: () => {
                  notifier.success(t("preferences.info.changes_saved"));
                },
              },
            )
          }
        >
          <FormRow>
            <FormInput
              name="firstName"
              label={t("preferences.info.first_name")}
              placeholder={t("preferences.info.first_name")}
            />
            <FormInput
              name="lastName"
              label={t("preferences.info.last_name")}
              placeholder={t("preferences.info.last_name")}
            />
          </FormRow>
          <FormRow>
            <Input
              disabled
              value={email ?? ""}
              name="email"
              label={t("preferences.info.email")}
            />
          </FormRow>
          <Submit
            label={t("preferences.preferences_form.save")}
            className="mt-44 mr-auto"
          />
        </Form>
      </div>
    </div>
  );
};
