import { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { Props as RNDPropos, Rnd } from "react-rnd";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { IconName } from "icon-library";

const MIN_NUMBER_OF_PIXELS_TO_BORDER = 40;

const computeNewHeight = (
  stateHeight: number,
  maxHeight: string | number | undefined,
  minHeight: string | number | undefined,
) => {
  let newHeight = stateHeight;
  if (maxHeight) newHeight = Math.min(newHeight, +maxHeight);
  if (minHeight) newHeight = Math.max(newHeight, +minHeight);
  return newHeight;
};

const computeNewY = (
  currentY: number,
  newHeight: number,
  currentHeight: number,
) => {
  let newY = currentY - (newHeight - currentHeight) / 2;
  newY = Math.min(
    newY,
    window.innerHeight - MIN_NUMBER_OF_PIXELS_TO_BORDER - newHeight,
  );
  newY = Math.max(newY, MIN_NUMBER_OF_PIXELS_TO_BORDER);
  return newY;
};

export const DraggableWindow = ({
  initialHeight,
  initialWidth,
  title,
  onHide,
  scroll,
  leftAction,
  className,
  children,
  minHeight,
  maxHeight,
  style,
  ...props
}: Omit<RNDPropos, "default" | "cancel"> & {
  onHide: (() => void) | null;
  initialHeight: number;
  initialWidth: number;
  title?: ReactNode;
  scroll?: boolean;
  leftAction?: { icon: IconName; onClick: () => void };
}) => {
  const rectifiedInitialWidth = Math.min(
    initialWidth,
    window.innerWidth - MIN_NUMBER_OF_PIXELS_TO_BORDER,
  );
  const rectifiedInitialHeight = Math.min(
    initialHeight,
    window.innerHeight - MIN_NUMBER_OF_PIXELS_TO_BORDER,
  );

  const [isDraggedOrResized, setIsDraggedOrResized] = useState(false);

  const deactivateTransitions = () => {
    setIsDraggedOrResized(true);
  };

  const activateTransitions = () => {
    setIsDraggedOrResized(false);
  };

  const rnd = useRef<Rnd | null>(null);
  useEffect(() => {
    if (!rnd.current) return;
    const currentPosition = rnd.current.getDraggablePosition();
    const stateHeight = +rnd.current.resizable.state.height;
    const newHeight = computeNewHeight(stateHeight, maxHeight, minHeight);
    const currentHeight = rnd.current.resizable.size.height;
    const newY = computeNewY(currentPosition.y, newHeight, currentHeight);
    rnd.current.updatePosition({
      x: currentPosition.x,
      y: newY,
    });
  }, [minHeight, maxHeight]);

  return ReactDOM.createPortal(
    <Rnd
      ref={rnd}
      default={{
        width: rectifiedInitialWidth,
        height: rectifiedInitialHeight,
        x: 0.5 * window.innerWidth - 0.5 * rectifiedInitialWidth,
        y: 0.5 * window.innerHeight - 0.5 * rectifiedInitialHeight,
      }}
      className={classNames(
        "shadow-light border rounded bg-white flex-col",
        className,
        isDraggedOrResized ? "!transition-none" : "",
      )}
      cancel=".cancel-drag"
      minHeight={minHeight}
      maxHeight={maxHeight}
      onDragStart={deactivateTransitions}
      onDragStop={activateTransitions}
      onResizeStart={deactivateTransitions}
      onResizeStop={activateTransitions}
      style={
        isDraggedOrResized ? { ...style, transitionProperty: "none" } : style
      }
      {...props}
    >
      <div className={classNames("h-full p-36", { "overflow-y-auto": scroll })}>
        {onHide && (
          <ClickableIcon
            name="close"
            onClick={() => onHide()}
            className="absolute inset-tr-0 h-36 w-36 flex-center hover:text-primary"
          />
        )}
        <div
          className={classNames(
            "cancel-drag cursor-default flex-col items-center",
            scroll ? "min-h-full" : "h-full",
          )}
        >
          {title ? (
            <div className="mb-12 flex items-center">
              {leftAction && (
                <ClickableIcon
                  name={leftAction.icon}
                  onClick={leftAction.onClick}
                  className="absolute left-36 hover:text-primary"
                />
              )}
              <div className="text-primary-dark font-medium text-center text-24 flex-fill">
                {title}
              </div>
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </Rnd>,
    document.getElementById("observation-root")!,
  );
};
