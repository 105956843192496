import { useEffect, useState } from "react";
import gql from "graphql-tag";

import { MarkAsConnected } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useWindowHasFocus } from "hooks";

gql`
  mutation MarkAsConnected {
    markAsConnected {
      doctor {
        uuid
      }
    }
  }
`;

export const useSetConnected = () => {
  const [isAFK, setIsAFK] = useState(false);
  const [markAsConnected] = useMutation(MarkAsConnected, {
    onError: () => null,
    skipIfImpersonation: true,
  });

  const hasFocus = useWindowHasFocus();
  useEffect(() => {
    if (hasFocus) {
      setIsAFK(false);
      return;
    }
    const timeoutId = setTimeout(() => {
      setIsAFK(true);
    }, 3600_000);
    return () => clearTimeout(timeoutId);
  }, [hasFocus]);

  useEffect(() => {
    if (isAFK) return;
    markAsConnected();
    const intervalId = setInterval(() => {
      markAsConnected();
    }, 30_000);
    return () => clearInterval(intervalId);
  }, [isAFK, markAsConnected]);
};
