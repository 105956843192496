import { Route, Routes } from "react-router-dom";

import { NoteNormalizationPage } from "./NoteNormalizationPage";
import { NoteNormalizationTable } from "./NoteNormalizationTable";

export const NoteNormalizationHome = () => (
  <Routes>
    <Route path="/" element={<NoteNormalizationTable />} />
    <Route path="/:noteSectionUuid" element={<NoteNormalizationPage />} />
  </Routes>
);
