import { useState } from "react";
import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { NotFound } from "components/ErrorPage/NotFound";
import { FormDatePicker } from "components/Form/DatePicker/FormDatePicker";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormSelect } from "components/Form/Select/FormSelect";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Query } from "components/Query/Query";
import { useUser } from "contexts/User/UserContext";
import {
  CreateMedicalLicense,
  DeleteMedicalLicense,
  MedicalLicenseFragment,
  MedicalLicenses,
  UpdateMedicalLicense,
  UsStateCode,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import {
  addMedicalLicenseInCache,
  removeMedicalLicenseFromCache,
} from "utils/apollo";
import { notifier } from "utils/notifier";
import { US_STATES_CODE, US_STATES_NAME_BY_CODE } from "utils/us-states";

gql`
  query MedicalLicenses {
    me {
      doctor {
        medicalLicenses {
          ...MedicalLicense
        }
      }
    }
  }

  mutation CreateMedicalLicense($input: CreateMedicalLicenseInput!) {
    createMedicalLicense(input: $input) {
      medicalLicense {
        ...MedicalLicense
      }
    }
  }

  mutation UpdateMedicalLicense(
    $uuid: UUID!
    $input: UpdateMedicalLicenseInput!
  ) {
    updateMedicalLicense(uuid: $uuid, input: $input) {
      medicalLicense {
        ...MedicalLicense
      }
    }
  }

  mutation DeleteMedicalLicense($uuid: UUID!) {
    deleteMedicalLicense(uuid: $uuid) {
      medicalLicenseUuid
    }
  }
`;

type FormValues = {
  state: UsStateCode;
  expiresAt: ISOString;
};

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

const now = new Date().toISOString();

export const PersonalInformationMedicalLicenses = ({
  newMedicalLicense,
  onHideNewMedicalLicense,
}: {
  newMedicalLicense: boolean;
  onHideNewMedicalLicense: () => void;
}) => {
  const { hasAccessToGatekeeperFeature } = useUser();
  if (!hasAccessToGatekeeperFeature("IS_US_COUNTRY")) return <NotFound />;
  return (
    <div className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
      {newMedicalLicense && (
        <MedicalLicenseForm
          medicalLicense={undefined}
          onHide={onHideNewMedicalLicense}
        />
      )}
      <Query query={MedicalLicenses}>
        {(response) =>
          response.doctor.medicalLicenses.isEmpty() && !newMedicalLicense ? (
            <EmptyMedicalLicensesPlaceholder />
          ) : (
            <>
              {response.doctor.medicalLicenses.map((medicalLicense) => (
                <MedicalLicenseForm
                  key={medicalLicense.uuid}
                  medicalLicense={medicalLicense}
                  onHide={onHideNewMedicalLicense}
                />
              ))}
            </>
          )
        }
      </Query>
    </div>
  );
};

const EmptyMedicalLicensesPlaceholder = () => {
  const t = useTranslation();

  return (
    <div className="flex-col w-full flex-center space-y-4 my-80">
      <div className="text-primary-dark font-bold text-18">
        {t("preferences.info.medical_licenses.empty_state.title")}
      </div>
      <div>{t("preferences.info.medical_licenses.empty_state.subtitle")}</div>
    </div>
  );
};

const MedicalLicenseForm = ({
  medicalLicense,
  onHide,
}: {
  medicalLicense?: MedicalLicenseFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();

  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>(undefined);

  const [createMedicalLicense, createMedicalLicenseLoading] = useMutation(
    CreateMedicalLicense,
    {
      onSuccess: (output, client) => {
        addMedicalLicenseInCache(client, output.medicalLicense);
        onHide();
        notifier.success(
          t("preferences.info.medical_licenses.notifier.created"),
        );
      },
    },
  );

  const [updateMedicalLicense, updateMedicalLicenseLoading] = useMutation(
    UpdateMedicalLicense,
    {
      onSuccess: () =>
        notifier.success(
          t("preferences.info.medical_licenses.notifier.updated"),
        ),
    },
  );

  const { refetch } = useQuery(MedicalLicenses);

  const [deleteMedicalLicense] = useMutation(DeleteMedicalLicense, {
    onSuccess: (output, client) => {
      removeMedicalLicenseFromCache(client, output.medicalLicenseUuid);
      refetch() // in case there's a cascade delete of a category
        .then(() =>
          notifier.success(
            t("preferences.info.medical_licenses.notifier.deleted"),
          ),
        );
    },
  });

  return (
    <>
      {confirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("preferences.info.medical_licenses.deletion_modal.title")}
          suffix={t("preferences.info.medical_licenses.deletion_modal.suffix")}
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      <Form<FormValues>
        className="flex-fill bg-white rounded border"
        initialValues={{
          state: medicalLicense?.state ?? "AL",
          expiresAt: medicalLicense?.expiresAt ?? now,
        }}
        validationSchema={{
          state: "required",
          expiresAt: "required",
        }}
        onSubmit={(values) => {
          medicalLicense === undefined
            ? createMedicalLicense({
                input: values,
              })
            : updateMedicalLicense({
                uuid: medicalLicense.uuid,
                input: values,
              });
        }}
      >
        <div className="flex-col space-y-20 p-32">
          <div className="flex items-center space-x-24">
            <FormSelect
              name="state"
              label={t("preferences.info.medical_licenses.region.label")}
              options={US_STATES_CODE}
              getOptionLabel={(code) => US_STATES_NAME_BY_CODE[code]}
              wrapperClassName="w-1/2 pr-8"
            />
            <FormDatePicker
              name="expiresAt"
              label={t("preferences.info.medical_licenses.expiration.label")}
              wrapperClassName="flex-fill"
            />
          </div>
          <div className="flex justify-between">
            <Button
              label={t("preferences.info.medical_licenses.delete.button")}
              danger
              secondary
              onClick={() => {
                medicalLicense
                  ? setConfirmationModalState({
                      onConfirm: (close) => {
                        close();
                        return deleteMedicalLicense({
                          uuid: medicalLicense.uuid,
                        });
                      },
                    })
                  : onHide();
              }}
            />
            <FormState<FormValues>>
              {({ values }) => (
                <Submit
                  label={t("preferences.info.medical_licenses.save.button")}
                  loading={
                    createMedicalLicenseLoading || updateMedicalLicenseLoading
                  }
                  disabled={
                    medicalLicense !== undefined &&
                    medicalLicense.state === values.state &&
                    medicalLicense.expiresAt === values.expiresAt
                  }
                />
              )}
            </FormState>
          </div>
        </div>
      </Form>
    </>
  );
};
