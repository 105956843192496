import { useRef } from "react";

import { useOn } from "hooks/useOn";
import { getConfig } from "utils/environment";

type NablaForChromeSection = {
  type: string;
  title: string;
  value: string;
};

const nablaForChromeIntegrationEnabled = getConfig({
  dev: false,
  pr: false,
  staging: true,
  preprod: true,
  prod: false,
});

export const useNablaForChromeIntegration = (
  onNablaForChromeInput: (newContent: NablaForChromeSection[]) => void,
) => {
  const alreadyImportedRef = useRef<boolean>(false);
  useOn("message", (e: MessageEvent) => {
    if (
      nablaForChromeIntegrationEnabled &&
      "nablaForChromeNote" in e.data &&
      !alreadyImportedRef.current
    ) {
      onNablaForChromeInput(
        e.data.nablaForChromeNote.content as NablaForChromeSection[],
      );
      alreadyImportedRef.current = true;
    }
  });
};
