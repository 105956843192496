import { useMediaWindows } from "atoms/useMediaWindows";
import { Modal } from "components/Modal/Modal";
import "singletons/react-zoom-pan-pinch.override.css";

import { MediaContent } from "./MediaContent/MediaContent";
import { MediaHeader } from "./MediaHeader";

export const MediaWindows = () => {
  const { media, closeMedia } = useMediaWindows();

  if (!media) return null;

  return (
    <Modal
      xl
      noPadding
      closeButton={null}
      onHide={closeMedia}
      className="w-full h-full"
    >
      <div className="flex-col flex-fill w-full h-full rounded overflow-hidden">
        <MediaHeader media={media} />
        <MediaContent media={media} />
      </div>
    </Modal>
  );
};
