import gql from "graphql-tag";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { FormDatePicker } from "components/Form/DatePicker/FormDatePicker";
import { FieldArray } from "components/Form/FieldArray/FieldArray";
import { ArrayHelpers } from "components/Form/FieldArray/getArrayHelpers";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { AsyncDoctorFormSelect } from "components/Form/Select/AsyncDoctorFormSelect";
import { AsyncPatientFormSelect } from "components/Form/Select/AsyncPatientFormSelect";
import { FormMultiSelect } from "components/Form/Select/FormMultiSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { Query } from "components/Query/Query";
import {
  AperoInvoiceFragment,
  AperoInvoiceStatus,
  AperoInvoiceStatusKnownValues,
  CreateOrUpdateAperoInvoice,
  DoctorSummaryFragment,
  GetAperoInvoice,
  PatientSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  fragment AperoInvoice on AperoInvoice {
    uuid: id
    id
    lineItems {
      id
      serviceDate
      code
      amountCharged
      claimDescription
      emergencyIndicator
      diagnosisCodes
    }
    status
    visitDate
    patient {
      ...PatientSummary
    }
    provider {
      ...DoctorSummary
    }
    diagnosisCodes
  }

  query GetAperoInvoice($id: String!) {
    aperoInvoice(invoiceId: $id) {
      invoice {
        ...AperoInvoice
      }
    }
  }

  mutation CreateOrUpdateAperoInvoice($id: String, $input: AperoInvoiceInput!) {
    createOrUpdateAperoInvoice(invoiceId: $id, input: $input) {
      invoice {
        ...AperoInvoice
      }
    }
  }
`;

type InvoiceLineItemFormValues = {
  id?: string;
  serviceDate?: LocalDate;
  code?: string;
  amountCharged?: BigDecimal;
  claimDescription?: string;
  emergencyIndicator?: boolean;
  diagnosisCodes: string[];
};

type FormValues = {
  status: AperoInvoiceStatus;
  visitDate?: LocalDate;
  patient?: PatientSummaryFragment;
  provider?: DoctorSummaryFragment;
  diagnosisCodes: string[];
  lineItems: InvoiceLineItemFormValues[];
};

export const AperoInvoiceLab = () => {
  const t = useTranslation();
  const id = useParams().id as string | undefined;

  return (
    <div className="flex-col flex-fill bg-blue-overlay overflow-y-auto">
      <div className="flex-col p-18 space-y-32 items-center w-full">
        <div className="text-primary-dark title">
          {t("apero_invoice_lab.apero_invoice_id___facture_apero_id__", {
            id: id ?? "",
          })}
        </div>
        {id && (
          <Query query={GetAperoInvoice} variables={{ id }}>
            {({ invoice }) => (
              <FormAperoInvoice id={invoice?.id} invoice={invoice} />
            )}
          </Query>
        )}
        {!id && <FormAperoInvoice />}
      </div>
    </div>
  );
};

const FormAperoInvoice = ({
  invoice,
  id,
}: {
  id?: string | null;
  invoice?: AperoInvoiceFragment | null;
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const [createOrUpdate, loading] = useMutation(CreateOrUpdateAperoInvoice, {
    onSuccess: (data) => {
      if (!id && data.invoice?.id) navigate(data.invoice.id);
      notifier.success(t("labs.apero.apero_invoice_lab.invoice_saved"));
    },
  });

  return (
    <Form<FormValues>
      className="bg-white p-16 rounded-item space-y-16 w-full"
      initialValues={{
        ...invoice,
        diagnosisCodes: invoice?.diagnosisCodes ?? [],
        lineItems: invoice?.lineItems ?? [],
        status: invoice?.status ?? "Draft",
      }}
      validationSchema={{
        visitDate: "required",
        patient: "required",
        provider: "required",
        lineItems: Yup.array().of(
          Yup.object().shape({
            serviceDate: Yup.date().required(),
            code: Yup.string().required(),
            amountCharged: Yup.number().required(),
            claimDescription: Yup.string().required(),
          }),
        ),
      }}
      onSubmit={(v) => {
        if (
          v.visitDate === undefined ||
          v.patient === undefined ||
          v.provider === undefined
        ) {
          return Promise.resolve();
        }
        return createOrUpdate({
          id: id ?? null,
          input: {
            lineItems: v.lineItems.map((lineItem) => ({
              id: lineItem.id,
              // FIXME(@liautaud): This should be using `LocalDate` correctly.
              serviceDate: (lineItem.serviceDate?.split("T")[0] ??
                "") as unknown as LocalDate,
              code: lineItem.code ?? "",
              amountCharged: lineItem.amountCharged ?? 0.0,
              claimDescription: lineItem.claimDescription ?? "",
              emergencyIndicator: lineItem.emergencyIndicator ?? false,
              diagnosisCodes: lineItem.diagnosisCodes,
            })),
            status: v.status,
            // FIXME(@liautaud): This should be using `LocalDate` correctly.
            visitDate: v.visitDate.split("T")[0] as unknown as LocalDate,
            patientUuid: v.patient.uuid,
            providerUuid: v.provider.uuid,
            diagnosisCodes: v.diagnosisCodes,
          },
        });
      }}
    >
      <div className="flex space-x-4">
        <AsyncPatientFormSelect
          name="patient"
          getVariables={(s) => ({
            search: s,
            withAperoId: true,
          })}
          wrapperClassName="flex-1"
        />
        <FormDatePicker
          label={t("labs.apero.apero_invoice_lab.visit_date")}
          name="visitDate"
          wrapperClassName="flex-1"
        />
        <FormSelect
          label={t("labs.apero.apero_invoice_lab.status")}
          name="status"
          wrapperClassName="flex-1"
          options={AperoInvoiceStatusKnownValues}
        />
      </div>
      <AsyncDoctorFormSelect
        getVariables={(s) => ({
          filter: {
            withAperoId: true,
            freeTextSearch: s,
          },
        })}
        name="provider"
      />
      <FormMultiSelect
        label={t("labs.apero.apero_invoice_lab.icd_codes")}
        name="diagnosisCodes"
        options={[] as string[]}
        creatable
      />
      <FieldArray<FormValues, InvoiceLineItemFormValues> name="lineItems">
        {(arrayHelpers, values) => (
          <>
            <div className="pl-16 border-l-2">
              <div className="mb-8">
                {values.lineItems.map((_, index) => (
                  <FormLineItem
                    key={index}
                    index={index}
                    arrayHelpers={arrayHelpers}
                  />
                ))}
              </div>
            </div>
            <Button
              label={t("labs.apero.apero_invoice_lab.add_item")}
              className="bg-grey-100 text-primary-dark text-14"
              onClick={() => arrayHelpers.push({ diagnosisCodes: [] })}
            />
          </>
        )}
      </FieldArray>
      <Submit
        loading={loading}
        className="px-56"
        label={t("labs.apero.apero_invoice_lab.save")}
      />
    </Form>
  );
};

const FormLineItem = ({
  index,
  arrayHelpers,
}: {
  index: number;
  arrayHelpers: ArrayHelpers<InvoiceLineItemFormValues>;
}) => {
  const t = useTranslation();

  return (
    <FormState>
      {({ getFieldProps }) => (
        <div className="flex-col space-y-8">
          <div>{t("apero.line_number", { number: index + 1 })}</div>
          <div className="flex space-x-4">
            <FormDatePicker
              wrapperClassName="flex-1"
              label={t("labs.apero.apero_invoice_lab.service_date")}
              name={`lineItems.${index}.serviceDate`}
            />
            <FormInput
              wrapperClassName="flex-1"
              label={t("apero.amount_charged_dollar")}
              type="number"
              name={`lineItems.${index}.amountCharged`}
            />
          </div>
          <FormInput
            label={t("labs.apero.apero_invoice_lab.claim_description")}
            name={`lineItems.${index}.claimDescription`}
          />
          <FormCheckbox
            label={t("labs.apero.apero_invoice_lab.emergency_indicator")}
            name={`lineItems.${index}.emergencyIndicator`}
          />
          <div className="flex space-x-4">
            <FormInput
              wrapperClassName="flex-1"
              label={t("labs.apero.apero_invoice_lab.cpt_code")}
              name={`lineItems.${index}.code`}
            />
            <FormMultiSelect
              wrapperClassName="flex-1"
              label={t("labs.apero.apero_invoice_lab.linked_icd_codes")}
              name={`lineItems.${index}.diagnosisCodes`}
              options={getFieldProps("diagnosisCodes").value ?? []}
            />
          </div>

          <Button
            label={t("labs.apero.apero_invoice_lab.delete")}
            danger
            onClick={() => arrayHelpers.remove(index)}
          />
        </div>
      )}
    </FormState>
  );
};
