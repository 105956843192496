import classNames from "classnames";
import gql from "graphql-tag";

import { Icon } from "components/Icon/Icon";
import { UpdateExperienceLockStatus } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

import { useQAExperience } from "../../QAExperienceContext/QAExperienceContext";
import { QAComposer } from "./QAComposer";

gql`
  mutation UpdateExperienceLockStatus(
    $experienceUuid: UUID!
    $input: UpdateExperienceLockStatusInput!
  ) {
    updateExperienceLockStatus(experienceUuid: $experienceUuid, input: $input) {
      experience {
        uuid
        isLocked
        itemsV2(pagination: { numberOfItems: 3 }) {
          data {
            ...TimelineItem
          }
        }
      }
    }
  }
`;

export const QAFooter = ({
  compact,
  setIsTimelineOpen,
}: {
  compact?: boolean;
  setIsTimelineOpen?: (isOpen: boolean) => void;
}) => {
  const { experience } = useQAExperience();

  return experience.isLocked ? (
    <QAFooterLocked />
  ) : (
    <QAComposer compact={compact} setIsTimelineOpen={setIsTimelineOpen} />
  );
};

const QAFooterLocked = ({ compact }: { compact?: boolean }) => {
  const t = useTranslation();
  const { experience } = useQAExperience();

  const [updateExperienceLockStatus, updating] = useMutation(
    UpdateExperienceLockStatus,
  );

  return (
    <div
      className={classNames(
        "w-full flex-col bg-white items-center space-y-12 border-t",
        compact ? "py-14" : "py-16",
      )}
    >
      <button
        className="flex items-center space-x-8 pr-12 pl-8 py-4 border rounded text-primary"
        onClick={() =>
          updateExperienceLockStatus({
            experienceUuid: experience.uuid,
            input: { isLocked: false },
          })
        }
        disabled={updating}
      >
        <Icon name="lock" />
        <div className="font-medium">{t("qa_footer_locked.unlock_button")}</div>
      </button>
      <div className="text-12 max-w-330 text-center">
        {t("qa_footer_locked.description")}
      </div>
    </div>
  );
};
