import { Maybe } from "base-types";
import { FileUploadFragment } from "generated/provider";

import { createAtom } from "./createAtom";

export type BlobMedia = {
  blob: Blob;
  title: string;
  highlights?: PDFHighlight[];
};

export type RemoteMedia = {
  uuid: UUID;
  url: string;
  mimeType: string;
  link?: string;
  highlights?: PDFHighlight[];
} & ({ title: string } | { fileName: string });

export type Media = RemoteMedia | BlobMedia;

export type PDFHighlight = {
  top: number;
  left: number;
  height: number;
  width: number;
  page: number;
};

export const useMediaWindows = createAtom(
  { media: null as Maybe<Media> },
  ({ set }) => ({
    showMedia: (
      media: Media | (FileUploadFragment & { title?: string | null }),
    ) => {
      if ("urlV2" in media || "url" in media) {
        set({
          media: {
            ...media,
            url: "urlV2" in media ? media.urlV2.url : media.url,
          },
        });
      } else {
        set({ media });
      }
    },
    closeMedia: () => set({ media: null }),
  }),
);
