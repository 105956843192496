import { EditorState, Modifier, SelectionState } from "draft-js";

import { getBlockEntities, getCurrentBlock } from "../core/utils";

const AUTOCOMPLETION_ENTITY_TYPE = "AUTOCOMPLETION";

export const canAcceptAutocompletion = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const entityBlock = getCurrentBlock(editorState);
  return (
    entityBlock.getLength() !== 0 &&
    selection.isCollapsed() &&
    selection.getEndOffset() === entityBlock.getLength()
  );
};

export const addAutocompletion = (
  editorState: EditorState,
  autocompletion?: string,
) => {
  // Note: suggestion is necessarily added to the current block.

  const content = editorState.getCurrentContent();
  const entityBlock = getCurrentBlock(editorState);
  if (!autocompletion || !canAcceptAutocompletion(editorState)) {
    return editorState;
  }

  const stateWithAutocompletion = content.createEntity(
    AUTOCOMPLETION_ENTITY_TYPE,
    "IMMUTABLE",
    undefined,
  );
  const newEntityKey = stateWithAutocompletion.getLastCreatedEntityKey();
  const newContent = Modifier.insertText(
    content,
    new SelectionState({
      focusKey: entityBlock.getKey(),
      anchorKey: entityBlock.getKey(),
      anchorOffset: entityBlock.getLength(),
      focusOffset: entityBlock.getLength(),
      isBackward: false,
      hasFocus: false,
    }),
    autocompletion,
    undefined,
    newEntityKey,
  );

  return EditorState.forceSelection(
    EditorState.push(editorState, newContent, "insert-characters"),
    editorState.getSelection(),
  );
};

export const removeAutocompletion = (
  editorState: EditorState,
  blockKey: string,
) => {
  const entityBlock = editorState.getCurrentContent().getBlockForKey(blockKey);
  // Draft-js typing is a lie in this case...
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entityBlock === undefined || entityBlock.getText().isEmpty()) {
    return editorState;
  }
  const entities = getBlockEntities(
    entityBlock,
    editorState.getCurrentContent(),
  );

  const autoCompleteEntity = entities.find(
    (it) => it.type === "AUTOCOMPLETION",
  );
  if (!autoCompleteEntity) return editorState;

  const stateWithoutAutocompletion = EditorState.push(
    editorState,
    Modifier.replaceText(
      editorState.getCurrentContent(),
      new SelectionState({
        focusKey: blockKey,
        anchorKey: blockKey,
        anchorOffset: autoCompleteEntity.start,
        focusOffset: autoCompleteEntity.end,
        isBackward: false,
        hasFocus: false,
      }),
      "",
    ),
    "remove-range",
  );

  return EditorState.acceptSelection(
    stateWithoutAutocompletion,
    editorState.getSelection(),
  );
};
