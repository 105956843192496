import { useNavigate } from "react-router-dom";

import { PillButton } from "components/Button/PillButton";
import { useTranslation } from "i18n";
import {
  PatientTimelineKnownItemTypes,
  PatientTimelineValidItemFragmentOfType,
} from "types/patient-timeline";
import { getPatientViewRoute } from "utils";

import {
  appearanceForExistingItem,
  appearanceForNewItem,
  PatientTimelineCreatableItemTypes,
  PatientTimelineNewItemPayload,
} from "./Item/types";
import { usePatientTimelineLocalState } from "./PatientTimelineLocalStateContext";

/**
 * Generic pill button to link to existing patient view items.
 *
 * When passing a fragment containing only `__typename` and `uuid`, the item's
 * patient is assumed to be the same as the one from the current patient view.
 */
export const ExistingItemPillButton = <
  T extends PatientTimelineKnownItemTypes,
>({
  item,
  onClick,
}: {
  item:
    | PatientTimelineValidItemFragmentOfType<T>
    | Pick<PatientTimelineValidItemFragmentOfType<T>, "__typename" | "uuid">;
  onClick?: () => void;
}) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { patientUuid: currentPatientUuid, expandItem } =
    usePatientTimelineLocalState();

  const isCompleteFragment = "patientTimelineItemMetadata" in item;
  const { title, iconName, iconColor, iconBackgroundColor } = isCompleteFragment
    ? appearanceForExistingItem(t, item)
    : appearanceForNewItem(t, item.__typename);

  const patient = isCompleteFragment
    ? item.patientTimelineItemMetadata.patient
    : { __typename: "Patient" as const, uuid: currentPatientUuid };

  return (
    <PillButton
      label={title}
      style={{ color: iconColor, backgroundColor: iconBackgroundColor }}
      leftIcon={{ name: iconName, color: iconColor, className: "mr-2 -ml-2" }}
      onClick={
        onClick ??
        (() =>
          patient.uuid === currentPatientUuid
            ? expandItem(item.uuid)
            : navigate(getPatientViewRoute(patient, [item])))
      }
    />
  );
};

/**
 * Generic pill button to add new patient view items.
 */
export const NewItemPillButton = <T extends PatientTimelineCreatableItemTypes>({
  type,
  payload,
}: {
  type: T;
  payload: PatientTimelineNewItemPayload[T];
}) => {
  const t = useTranslation();
  const { addNewItem } = usePatientTimelineLocalState();
  const { entityName } = appearanceForNewItem(t, type);
  return (
    <PillButton
      label={entityName}
      leftIcon="add"
      onClick={() => addNewItem(type, payload)}
    />
  );
};
