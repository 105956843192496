export const LivekitStatusMessage = ({ label }: { label: string }) => (
  <div
    style={{
      color: "#ffffff",
      backgroundColor: "rgba(0,0,0,0.3)",
      fontWeight: 500,
      fontSize: 14,
      padding: "6px 14px",
      borderRadius: 10,
      userSelect: "none",
    }}
  >
    {label}
  </div>
);
