import gql from "graphql-tag";
import * as Yup from "yup";

import { FormInput } from "components/Form/Input/FormInput";
import { FormModal } from "components/Modal/FormModal";
import {
  CustomizeExperience,
  ExperienceSummaryFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { staticT, useTranslation } from "i18n";

gql`
  mutation CustomizeExperience(
    $uuid: UUID!
    $input: CustomizeExperienceInput!
  ) {
    customizeExperience(experienceUuid: $uuid, input: $input) {
      experience {
        uuid
        title
        subtitle
        description
      }
    }
  }
`;

type FormValues = { title: string; description: string; subtitle: string };
const MAX_TITLE_LENGTH = 25;
const MAX_SUBTITLE_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 140;
const validateMaxLength = (max: number) =>
  Yup.string().max(
    max,
    `${max} ${staticT(
      "doctors_conversation.information_panel.rename_experience.maximum_characters",
    )}`,
  );

export const RenameExperienceModal = ({
  experience,
  close,
  withDescription,
  withSubtitle,
}: {
  experience: ExperienceSummaryFragment;
  close: () => void;
  withDescription?: boolean;
  withSubtitle?: boolean;
}) => {
  const t = useTranslation();
  const [customizeExperience] = useMutation(CustomizeExperience, {
    onSuccess: () => close(),
  });
  const title = t(
    "doctors_conversation.information_panel.rename_experience.rename_conversation",
  );

  return (
    <FormModal<FormValues>
      title={title}
      onHide={close}
      initialValues={{
        title: experience.title ?? "",
        description: experience.description ?? "",
        subtitle: experience.subtitle ?? "",
      }}
      validationSchema={{
        title: validateMaxLength(MAX_TITLE_LENGTH).required(
          t(
            "doctors_conversation.information_panel.rename_experience.this_field_is_required",
          ),
        ),
        description: validateMaxLength(MAX_DESCRIPTION_LENGTH),
        subtitle: validateMaxLength(MAX_SUBTITLE_LENGTH),
      }}
      submitLabel={t(
        "doctors_conversation.information_panel.rename_experience.edit",
      )}
      onSubmit={(input) =>
        customizeExperience({ uuid: experience.uuid, input })
      }
    >
      <FormInput
        name="title"
        label={t(
          "doctors_conversation.information_panel.rename_experience.title",
        )}
        placeholder={t(
          "doctors_conversation.information_panel.rename_experience.conversation_title",
        )}
        maxLength={MAX_TITLE_LENGTH}
      />
      {withDescription && (
        <FormInput
          name="description"
          label={t("rename_experience.description")}
          placeholder={t("rename_experience.add_summary")}
          maxLength={MAX_DESCRIPTION_LENGTH}
        />
      )}
      {withSubtitle && (
        <FormInput
          name="subtitle"
          label={t(
            "doctors_conversation.information_panel.rename_experience.subtitle",
          )}
          placeholder={t("rename_experience.add_additional_information")}
          maxLength={MAX_SUBTITLE_LENGTH}
        />
      )}
    </FormModal>
  );
};
