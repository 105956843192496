import gql from "graphql-tag";

import {
  BackOfficePatientFragment,
  PatientSearch,
  PatientSearchVariables,
} from "generated/provider";
import { useTranslation } from "i18n";
import { displayPatient } from "utils/display";

import { AsyncFormSelect } from "./AsyncFormSelect";
import { PatientRowOption } from "./PatientRowOption";

gql`
  query PatientSearch($search: String, $cursor: DateTime) {
    patientSearch(filter: { search: $search }) {
      totalCount
      patients(page: { numberOfItems: 50, from: $cursor }) {
        data {
          ...BackOfficePatient
        }
        hasMore
        nextCursor
      }
    }
  }
`;

export const AsyncPatientFormSelect = ({
  placeholder,
  label,
  getVariables = (s) => ({ search: s }),
  onChange,
  isClearable = false,
  wrapperClassName,
  position,
  name,
  withoutLabel = false,
}: {
  name: string;
  wrapperClassName?: string;
  position?: "bottom" | "top";
  placeholder?: string;
  label?: string;
  withoutLabel?: boolean;
  getVariables?: (s: string) => PatientSearchVariables;
  onChange?: (patient: BackOfficePatientFragment | undefined) => void;
  isClearable?: boolean;
}) => {
  const t = useTranslation();

  return (
    <AsyncFormSelect
      selectProps={{
        name,
        isClearable,
        position: position ? position : "top",
        wrapperClassName,
        placeholder:
          placeholder ?? t("form.select.async_patient_form_select.select"),
        CustomOption: PatientRowOption,
        getOptionLabel: displayPatient,
        onChange,
        label:
          label ??
          (withoutLabel
            ? undefined
            : t("form.select.async_patient_form_select.patient")),
      }}
      queryProps={{
        query: PatientSearch,
      }}
      getOptions={(data) => data?.patients.data ?? []}
      getVariables={getVariables}
    />
  );
};
