import { TimeZone } from "@vvo/tzdb";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTimezonePicker } from "components/TimezonePicker/TimezonePicker";
import { findTimeZone } from "components/TimezonePicker/utils";
import { useDoctor } from "contexts/User/UserContext";
import {
  SupportedLocaleKnownValue,
  SupportedLocaleKnownValues,
  UpdateDoctorTimezoneAndLocale,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayLocale } from "utils/display";
import { getKnownValue } from "utils/enum";
import { notifier } from "utils/notifier";

gql`
  mutation UpdateDoctorTimezoneAndLocale(
    $timezone: TimeZone!
    $locale: SupportedLocale!
  ) {
    updateDoctorProfile(payload: { timezone: $timezone, locale: $locale }) {
      doctor {
        ...DoctorSummary
        ...UserPersonalInformation
      }
    }
  }
`;

type FormState = {
  timezone: TimeZone | undefined;
  locale: SupportedLocaleKnownValue | undefined;
};

export const DoctorTimezoneAndLanguage = () => {
  const t = useTranslation();
  const { timezone: rawTimezone, user } = useDoctor();
  const initialTimezone = findTimeZone(rawTimezone);
  const initialLocale =
    getKnownValue(user.locale, SupportedLocaleKnownValues) ?? undefined;

  const [updateTimezoneAndLocale] = useMutation(UpdateDoctorTimezoneAndLocale, {
    onSuccess: () =>
      notifier.success(t("doctor_timezone_and_language.success")),
  });

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <h1 className="text-primary-dark text-24 font-bold">
        {t("doctor_timezone_and_language.timezone_page.title")}
      </h1>
      <Form<FormState>
        className="flex-col space-y-24"
        initialValues={{ timezone: initialTimezone, locale: initialLocale }}
        validationSchema={{ timezone: "required", locale: "required" }}
        onSubmit={({ timezone, locale }) =>
          updateTimezoneAndLocale({
            timezone: timezone!.name,
            locale: locale!,
          })
        }
      >
        <div className="flex-col gap-20 bg-white rounded border p-32">
          <div className="flex-col">
            <div className="font-bold text-14 text-black mb-4">
              {t("doctor_timezone_and_language.timezone_of_your_account")}
            </div>
            <FormTimezonePicker name="timezone" />
          </div>
          <div className="flex-col">
            <div className="font-bold text-14 text-black mb-4">
              {t("doctor_timezone_and_language.language_of_your_account")}
            </div>
            <FormSelect
              name="locale"
              options={SupportedLocaleKnownValues}
              getOptionLabel={displayLocale}
            />
          </div>
          <Submit
            label={t("settings.timezone.timezone_page.save")}
            className="self-start"
          />
        </div>
      </Form>
    </Background>
  );
};
