import { ReactNode, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { addDays, addMonths, addWeeks, isToday } from "date-fns";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { Calendar as RCalendar, luxonLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import {
  DoctorFilter,
  DoctorSelect,
} from "components/DoctorSelect/DoctorSelect";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { ButtonGroup } from "components/Form/ButtonGroup/ButtonGroup";
import { DatePicker } from "components/Form/DatePicker/DatePicker";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { PopoverMenu } from "components/Menu/PopoverMenu";
import { BottomSafeArea } from "components/Mobile/SafeArea";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { CreateScheduledWebCallModal } from "components/Patient/CreateScheduledWebCallModal";
import { Spinner } from "components/Spinner/Spinner";
import { useDoctor } from "contexts/User/UserContext";
import {
  AppointmentFragment,
  AvailabilityOccurrenceFragment,
  CalendarAppointmentFragment,
  CancelAppointment,
  DoctorWithAppointmentAddressFragment,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useMountDate } from "hooks";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useStorageState } from "hooks/useStorageState";
import { staticT, useTranslation } from "i18n";
import { routes } from "routes";
import { mapEnumValue } from "utils/enum";
import { supportsGoogleCalendarSync } from "utils/google-calendar-sync";
import { notifier } from "utils/notifier";

import { CalendarEvent } from "../types";
import { useAvailableFilters } from "../utils";
import { AppointmentEvent } from "./AppointmentEvent";
import { AppointmentPopover } from "./AppointmentPopover";
import { AvailabilityCreationModal } from "./AvailabilityCreationModal";
import { AvailabilityDeletionModal } from "./AvailabilityDeletionModal";
import { AvailabilityOccurrenceDeletionModal } from "./AvailabilityOccurrenceDeletionModal";
import { AvailabilityOccurrenceEvent } from "./AvailabilityOccurrenceEvent";
import { AvailabilityPopover } from "./AvailabilityPopover";
import { GoogleCalendarModal } from "./GoogleCalendarSyncModal";
import { useAppointmentsInTimeRange } from "./useAppointmentsInTimeRange";
import { useAvailabilitiesInTimeRange } from "./useAvailabilitiesInTimeRange";
import "./reactBigCalendarOverrides.css";

gql`
  mutation CancelAppointment($appointmentUuid: UUID!) {
    updateAppointmentState(
      appointmentUuid: $appointmentUuid
      input: { cancelled: {} }
    ) {
      appointment {
        ...Appointment
        patient {
          ...PatientSummary
          upcomingAppointments {
            ...Appointment
          }
          pastAppointments {
            ...Appointment
          }
        }
        doctor {
          ...AllUpcomingAppointments
        }
      }
    }
  }
`;

const Granularities = ["day", "week", "month"] as const;
type Granularity = typeof Granularities[number];
const GranularityParams: {
  [key in Granularity]: {
    buttonText: () => string;
    add: (d: Date, i: number) => Date;
    start: (date: Date) => Date;
    end: (date: Date) => Date;
    format: Parameters<ISOString["format"]>[0];
  };
} = {
  day: {
    buttonText: () => staticT("scheduling.calendar.calendar.day"),
    add: addDays,
    start: (d) => d.startOfDay(),
    end: (d) => d.endOfDay(),
    format: { exception: "EEE d MMMM yyyy" },
  },
  week: {
    buttonText: () => staticT("scheduling.calendar.calendar.week"),
    add: addWeeks,
    start: (d) => d.startOfWeek(),
    end: (d) => d.endOfWeek(),
    format: { exception: "MMMM yyyy" },
  },
  month: {
    buttonText: () => staticT("scheduling.calendar.calendar.month"),
    add: addMonths,
    start: (d) => d.startOfMonth(),
    end: (d) => d.endOfMonth(),
    format: { exception: "MMMM yyyy" },
  },
};

export type SchedulingAction =
  | { action: "create"; event: CalendarEvent }
  | {
      action: "availabilityOccurrenceDetails";
      target: Element;
      availabilityOccurrence: AvailabilityOccurrenceFragment;
    }
  | {
      action: "appointmentDetails";
      target: Element;
      appointment: CalendarAppointmentFragment;
    }
  | {
      action: "deleteAvailabilityOccurrence";
      availabilityOccurrence: AvailabilityOccurrenceFragment;
    }
  | {
      action: "deleteAvailability";
      availabilityUuid: UUID;
    }
  | { action: "deleteAppointment"; appointmentUuid: UUID };

export const Calendar = ({
  selectedDoctor,
  setSelectedFilter,
  selectedAppointment,
  showBottomSheet,
  resetSelectedAppointment,
}: {
  selectedDoctor: DoctorWithAppointmentAddressFragment;
  setSelectedFilter: (filter: DoctorFilter) => void;
  selectedAppointment: AppointmentFragment | undefined;
  resetSelectedAppointment: () => void;
  showBottomSheet: () => void;
}) => {
  const t = useTranslation();
  const { hasPermission, timezone, user } = useDoctor();
  const isDesktop = useIsDesktop();
  const canEditAvailabilities = hasPermission("EDIT_DOCTOR_AVAILABILITY");

  const now = useMountDate();
  const [selectedAction, setSelectedAction] = useState<SchedulingAction>();
  const [centerDate, setCenterDate] = useState<Date>(now.getDate());
  const [granularity, setGranularity] = useState<Granularity>(
    isDesktop ? "week" : "day",
  );

  const firstDayOfWeek = () =>
    mapEnumValue(user.subOrganization.calendarStartOfWeek, {
      default: 1,
      MONDAY: 1,
      SUNDAY: 7,
    });

  const [showScheduledWebCallModal, setShowScheduledWebCallModal] =
    useState(false);

  // @ts-ignore
  const localizer = luxonLocalizer(DateTime, {
    firstDayOfWeek: firstDayOfWeek(),
  });

  const [cancelAppointment] = useMutation(CancelAppointment);

  const {
    filters,
    loading: filtersLoading,
    error: filtersError,
  } = useAvailableFilters();

  const page = useMemo(
    () => ({
      from: GranularityParams[granularity].start(centerDate).toISOString(),
      to: GranularityParams[granularity].end(centerDate).toISOString(),
    }),
    [granularity, centerDate],
  );
  const {
    events,
    error: eventsError,
    loading: eventsLoading,
  } = useAvailabilitiesInTimeRange(page, selectedDoctor);

  const {
    appointments,
    error: appointmentsError,
    loading: appointmentsLoading,
  } = useAppointmentsInTimeRange(page, selectedDoctor);

  useEffect(() => {
    if (selectedAppointment) {
      setCenterDate(selectedAppointment.startAt.getDate());
    }
  }, [selectedAppointment]);

  const [
    everDismissedGoogleCalendarModal,
    setEverDismissedGoogleCalendarModal,
  ] = useStorageState<boolean>("ever-dismissed-google-calendar-modal", false);

  const showGoogleCalendarModal =
    !everDismissedGoogleCalendarModal &&
    supportsGoogleCalendarSync(user) &&
    user.googleCalendarSyncEnabled === null;

  const allErrors = eventsError ?? filtersError ?? appointmentsError;
  if (allErrors) return <ErrorPage error={allErrors} />;

  const prevMonday = centerDate.minusDays(centerDate.getDay() - 1);

  return (
    <Background
      className={classNames("flex-fill flex-col", {
        "p-30": isDesktop,
      })}
    >
      {showGoogleCalendarModal && (
        <GoogleCalendarModal
          onHide={() => setEverDismissedGoogleCalendarModal(true)}
        />
      )}
      {selectedAction?.action === "create" && (
        <AvailabilityCreationModal
          doctor={selectedDoctor}
          calendarEvent={selectedAction.event}
          onHide={() => setSelectedAction(undefined)}
          onCreated={(availability) =>
            setCenterDate(availability.startAt.getDate())
          }
        />
      )}
      {showScheduledWebCallModal && (
        <CreateScheduledWebCallModal
          onHide={() => setShowScheduledWebCallModal(false)}
          onScheduled={(appointment) =>
            setCenterDate(appointment.startAt.getDate())
          }
          startDate={
            now.getTime() > prevMonday.getTime()
              ? now
              : granularity === "day"
              ? centerDate.toISOString()
              : prevMonday.toISOString()
          }
          initialDoctor={selectedDoctor}
        />
      )}
      {selectedAction?.action === "availabilityOccurrenceDetails" && (
        <AvailabilityPopover
          availabilityOccurrence={selectedAction.availabilityOccurrence}
          target={selectedAction.target}
          onClose={() => setSelectedAction(undefined)}
          onDeleteAvailability={() => {
            selectedAction.availabilityOccurrence.parent.recurrence === null
              ? setSelectedAction({
                  action: "deleteAvailability",
                  availabilityUuid:
                    selectedAction.availabilityOccurrence.parent.uuid,
                })
              : setSelectedAction({
                  action: "deleteAvailabilityOccurrence",
                  availabilityOccurrence: selectedAction.availabilityOccurrence,
                });
          }}
        />
      )}
      {selectedAction?.action === "appointmentDetails" && (
        <AppointmentPopover
          appointment={selectedAction.appointment}
          target={selectedAction.target}
          onClose={() => setSelectedAction(undefined)}
          onCancelAppointment={(appointmentUuid) =>
            setSelectedAction({
              action: "deleteAppointment",
              appointmentUuid,
            })
          }
        />
      )}
      {selectedAction?.action === "deleteAvailability" && (
        <AvailabilityDeletionModal
          availabilityUuid={selectedAction.availabilityUuid}
          onHide={() => setSelectedAction(undefined)}
        />
      )}
      {selectedAction?.action === "deleteAvailabilityOccurrence" && (
        <AvailabilityOccurrenceDeletionModal
          availabilityOccurrence={selectedAction.availabilityOccurrence}
          onHide={() => setSelectedAction(undefined)}
        />
      )}
      {selectedAction?.action === "deleteAppointment" && (
        <ControlledDeletionConfirmationModal
          ctaLabel={t("scheduling.calendar.calendar.cancel_appointment_cta")}
          title={t("scheduling.calendar.calendar.cancel_appointment")}
          suffix={t("scheduling.calendar.calendar.to_cancel_this_appointment")}
          onConfirm={(close) =>
            cancelAppointment(
              { appointmentUuid: selectedAction.appointmentUuid },
              {
                onSuccess: (_, client) => {
                  client.remove("Appointment", selectedAction.appointmentUuid);
                  notifier.success(
                    t("scheduling.calendar.calendar.appointment_cancelled"),
                  );
                  close();
                },
              },
            )
          }
          onHide={() => setSelectedAction(undefined)}
        />
      )}
      {isDesktop ? (
        <DesktopHeader
          centerDate={centerDate}
          setCenterDate={(date) => {
            setCenterDate(date);
            resetSelectedAppointment();
          }}
          granularity={granularity}
          setGranularity={setGranularity}
          loading={eventsLoading || filtersLoading || appointmentsLoading}
          filtersDropDown={
            <DoctorSelect
              className="ml-auto"
              selectableDoctors={filters}
              selectedDoctor={selectedDoctor}
              setSelectedDoctor={setSelectedFilter}
            />
          }
          onClickAddAvailabilityButton={() =>
            setSelectedAction({
              action: "create",
              event: {
                start: DateTime.now()
                  .set({ hour: DateTime.now().hour + 1, minute: 0, second: 0 })
                  .toJSDate(),
                end: DateTime.now()
                  .set({ hour: DateTime.now().hour + 1, minute: 30, second: 0 })
                  .toJSDate(),
                resources: {},
              },
            })
          }
          onClickAddAppointmentButton={() => setShowScheduledWebCallModal(true)}
        />
      ) : (
        <MobileHeader
          centerDate={centerDate}
          setCenterDate={(date) => {
            setCenterDate(date);
            resetSelectedAppointment();
          }}
          loading={eventsLoading || filtersLoading || appointmentsLoading}
          showBottomSheet={showBottomSheet}
          filtersDropDown={
            <DoctorSelect
              className="flex-fill ml-auto"
              selectableDoctors={filters}
              selectedDoctor={selectedDoctor}
              setSelectedDoctor={setSelectedFilter}
            />
          }
        />
      )}
      <RCalendar<CalendarEvent>
        className="bg-white rounded flex-fill"
        culture={t("scheduling.calendar.calendar.gbgb")}
        date={centerDate}
        formats={{
          selectRangeFormat: () =>
            t("scheduling.calendar.calendar.availability"),
        }}
        showMultiDayTimes
        components={{
          header: ({ date }) => (
            <div className="flex-col items-center">
              <span
                className="label font-semibold text-10"
                style={{ height: "19px" }}
              >
                {date.toISOString().format({ exception: "EEE" })}
              </span>
              {granularity !== "month" && (
                <div
                  className={classNames("flex-center", {
                    "text-primary-dark": !isToday(date),
                    "text-white": isToday(date),
                  })}
                  style={{ height: 25, width: 25 }}
                >
                  {date.toISOString().format("monthDay")}
                </div>
              )}
            </div>
          ),
          month: {
            // @ts-ignore
            dateHeader: ({ date }: { date: Date }) =>
              date.toISOString().format("monthDay"),
          },
          event: ({ event }) =>
            event.resources.availabilityOccurrence ? (
              <AvailabilityOccurrenceEvent
                availabilityOccurrence={event.resources.availabilityOccurrence}
              />
            ) : event.resources.appointment ? (
              <AppointmentEvent appointment={event.resources.appointment} />
            ) : null,
        }}
        selectable={!selectedAction && canEditAvailabilities}
        scrollToTime={
          DateTime.now().get("hour") >= 15
            ? DateTime.now().set({ hour: 10 }).toJSDate()
            : DateTime.now().set({ hour: 6 }).toJSDate()
        }
        onSelectSlot={(slotInfo) => {
          const start = new Date(slotInfo.start);
          start.setMilliseconds(0);
          const end = new Date(slotInfo.end);
          end.setMilliseconds(0);
          // Disable 30min slots selection to prevent miss clicks
          if (start.toISOString().plusMinutes(31).isBefore(end.toISOString())) {
            setSelectedAction({
              action: "create",
              event: {
                start,
                end,
                resources: {},
              },
            });
          }
        }}
        onSelectEvent={(calendarEvent, e) => {
          // This fixes a bug where the click outside event was closing the modal
          // before it appeared. Most likely because the calendar library is triggering
          // this select event too early
          e.stopPropagation();

          if (calendarEvent.resources.availabilityOccurrence) {
            setSelectedAction({
              action: "availabilityOccurrenceDetails",
              availabilityOccurrence:
                calendarEvent.resources.availabilityOccurrence,
              target: e.currentTarget,
            });
          }

          if (calendarEvent.resources.appointment) {
            setSelectedAction({
              action: "appointmentDetails",
              appointment: calendarEvent.resources.appointment,
              target: e.currentTarget,
            });
          }
        }}
        view={granularity}
        localizer={localizer}
        events={events.concat(appointments)}
        // Remove warnings because we are not using the default toolbar
        onView={() => undefined}
        onNavigate={() => undefined}
        onShowMore={(_, date) => {
          setCenterDate(date);
          setGranularity("day");
        }}
      />
      <div className="flex items-center mt-4">
        <Link
          to={`${routes.PREFERENCES}/${routes.TIMEZONE}`}
          className="underline"
        >
          {timezone}
        </Link>
      </div>
      <BottomSafeArea />
    </Background>
  );
};

const DesktopHeader = ({
  centerDate,
  setCenterDate,
  granularity,
  setGranularity,
  loading,
  filtersDropDown,
  onClickAddAvailabilityButton,
  onClickAddAppointmentButton,
}: {
  centerDate: Date;
  setCenterDate: (val: Date) => void;
  granularity: Granularity;
  setGranularity: (val: Granularity) => void;
  loading: boolean;
  filtersDropDown: ReactNode;
  onClickAddAvailabilityButton: () => void;
  onClickAddAppointmentButton: () => void;
}) => {
  const t = useTranslation();
  const now = useMountDate();

  return (
    <div className="flex-col space-y-8 mb-16">
      <div className="flex items-center">
        <span className="text-18 text-primary-dark text-20 font-medium">
          {centerDate
            .toISOString()
            .format(GranularityParams[granularity].format)
            .upperFirst()}
        </span>
        {loading && <Spinner inline small className="ml-12" />}
        {filtersDropDown}
      </div>
      <div className="flex items-center h-44">
        <div className="flex border bg-white rounded font-medium text-primary-dark">
          <ClickableIcon
            name="chevron"
            className="rounded-l hover:bg-grey-100 py-12"
            rotate={180}
            onClick={() =>
              setCenterDate(GranularityParams[granularity].add(centerDate, -1))
            }
          />
          <button
            className="hover:bg-grey-100 px-16 text-15 border-l border-r"
            onClick={() => setCenterDate(now.getDate())}
          >
            {t("scheduling.calendar.calendar.today")}
          </button>
          <ClickableIcon
            name="chevron"
            className="rounded-r hover:bg-grey-100 py-12"
            onClick={() =>
              setCenterDate(GranularityParams[granularity].add(centerDate, 1))
            }
          />
        </div>
        <PopoverMenu
          position="bottom-right"
          className="mt-10 min-w-[180px]"
          noArrow
          items={[
            {
              text: t("scheduling.calendar.calendar.appointment"),
              onClick: (closeMenu: () => void) => {
                closeMenu();
                onClickAddAppointmentButton();
              },
            },
            {
              text: t("scheduling.calendar.calendar.availability"),
              onClick: (closeMenu: () => void) => {
                closeMenu();
                onClickAddAvailabilityButton();
              },
            },
          ].filterNotNull()}
        >
          {({ setTarget }) => (
            <ClickableIcon
              className="bg-white rounded ml-12 h-44 w-44 border-primary border hover:bg-grey-100"
              name="add"
              iconClassName="text-primary"
              onClick={setTarget}
            />
          )}
        </PopoverMenu>
        <ButtonGroup
          wrapperClassName="ml-auto w-auto bg-white"
          buttonClassName="h-44"
          value={granularity}
          options={Granularities}
          onChange={(o) => setGranularity(o)}
          getOptionLabel={(o) => GranularityParams[o].buttonText()}
        />
      </div>
    </div>
  );
};

const MobileHeader = ({
  centerDate,
  setCenterDate,
  loading,
  filtersDropDown,
  showBottomSheet,
}: {
  centerDate: Date;
  setCenterDate: (val: Date) => void;
  loading: boolean;
  filtersDropDown: ReactNode;
  showBottomSheet: () => void;
}) => (
  <div className="flex items-center bg-white p-10">
    <ClickableIcon
      name="menu"
      size={18}
      className="text-primary-dark"
      onClick={() => showBottomSheet()}
    />
    <DatePicker
      onChange={(value) => {
        if (value) setCenterDate(value.getDate());
      }}
      wrapperClassName="w-1/3 mx-10"
      value={centerDate.toISOString()}
    />
    {loading && <Spinner inline small className="ml-12" />}
    {filtersDropDown}
  </div>
);
