import LZString from "lz-string";

import { createAtom } from "atoms/createAtom";
import { AutocompleteExpressionUseCase } from "types";

export const AUTOCOMPLETE_MODEL_KEY = "autocomplete-model-expressions-v3";
const oldKey = "autocomplete-model-expressions-v2";
storage.removeItem(oldKey); // Make sure we get rid of 2Mo of data

export type ParsedAutoCompleteExpressionSetModel = {
  uuid: UUID;
  expressions: {
    text: string;
    minMatchingPrefixLength: Int;
    useCase: AutocompleteExpressionUseCase;
  }[];
};

const serializeAutocompleteData = (
  autocompleteData: ParsedAutoCompleteExpressionSetModel,
): string => LZString.compressToUTF16(JSON.stringify(autocompleteData));

const parseAutocompleteData = (
  serializedAutocompleteData: string,
): ParsedAutoCompleteExpressionSetModel => {
  const decompressed = LZString.decompressFromUTF16(serializedAutocompleteData);
  if (decompressed === null) {
    throw new Error("Could not decompress from localstorage, invalid state");
  }
  return JSON.parse(decompressed);
};

export const useAutoCompleteModel = createAtom(
  {
    data: storage.getItem(AUTOCOMPLETE_MODEL_KEY)?.let(parseAutocompleteData),
  },
  ({ get, set }) => ({
    currentExpressionSetUuid: () => get().data?.uuid,
    updateAutocompleteData: (newData: ParsedAutoCompleteExpressionSetModel) => {
      set({ data: newData });
      storage.setItem(
        AUTOCOMPLETE_MODEL_KEY,
        serializeAutocompleteData(newData),
      );
    },
    removeAutoCompleteData: () => {
      set({ data: undefined });
      storage.removeItem(AUTOCOMPLETE_MODEL_KEY);
    },
  }),
);
