import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";

import { PatientViewSideSection } from "components/Patient/PatientViewSideSection";
import { PatientTimeline } from "components/Patient/Timeline/PatientTimeline";
import { usePatientTimelineLocalState } from "components/Patient/Timeline/PatientTimelineLocalStateContext";
import { PatientViewNavigationState } from "components/Patient/utils";
import { SidePanel } from "components/SidePanel/SidePanel";
import {
  ChatWidgets,
  ChatWidgetsContext,
} from "contexts/ChatWidgetsContext/ChatWidgetsContext";
import { PatientViewContext } from "contexts/PatientViewContext/PatientViewContext";
import { PatientViewProvider } from "contexts/PatientViewContext/PatientViewProvider";
import { useDoctor } from "contexts/User/UserContext";
import { PatientSummaryFragment } from "generated/provider";
import { useIsDesktop } from "hooks/useMediaQuery";
import { getPatientViewRoute, getQAInboxRoute } from "utils";

import { useQASidePanel } from "../../QASidePanelContext/QASidePanelContext";

gql`
  query GetPatientInformation(
    $patientUuid: UUID!
    $cursor: String
    $filter: TimelineEventFilter!
  ) {
    patient(patientUuid: $patientUuid) {
      patient {
        ...PatientSummary
        ...PatientPrescriptionInfos
        externalId
        weightKG {
          ...HealthDataPoint
        }
        imc
        phoneV2
        hasExperiences
        ...Devices
        patientTimeline(filter: $filter) {
          totalCount
          pinnedEvents {
            ...PatientTimelineEvent
          }
          data(pagination: { cursor: $cursor, numberOfItems: 10 }) {
            hasMore
            nextCursor
            data {
              ...PatientTimelineEvent
            }
          }
        }
        tags {
          ...PatientTag
        }
        questionnaireResponses(page: { numberOfItems: 1 }) {
          data {
            ...QuestionnaireResponse
          }
        }
      }
    }
  }

  fragment PatientTag on PatientTag {
    uuid
    type {
      ...Tag
    }
    updatedAt
  }

  fragment PatientTimelineEvent on PatientTimelineEvent {
    uuid
    eventTime
    isPinned
    author {
      ...UserSummary
    }
    content {
      ... on DocumentCreated {
        patientDocument {
          ...PatientDocument
        }
      }
      ... on MedicationCreatedOrAccepted {
        medicationStatement {
          ...MedicationStatement
          ...MentionableInNote
        }
      }
      ... on NoteCreated {
        patientNote {
          ...PatientNote
        }
      }
      ... on ExperienceCreated {
        experience {
          uuid
          createdAt
          title
          patient {
            uuid
            username
          }
          lastHumanItem {
            ...TimelineItem
          }
          isClosed
          assignedDoctors {
            uuid
          }
        }
      }
      ... on VaccineCreated {
        vaccinationStatement {
          ...VaccinationStatement
          ...MentionableInNote
        }
      }
      ... on SymptomCreated {
        symptom {
          ...Symptom
        }
      }
      ... on ConditionCreatedOrAccepted {
        condition {
          ...Condition
          ...MentionableInNote
        }
      }
      ... on PatientAllergyCreatedOrAccepted {
        patientAllergy {
          ...PatientAllergy
          ...MentionableInNote
        }
      }
      ... on ProcedureCreatedOrAccepted {
        procedure {
          ...Procedure
          ...MentionableInNote
        }
      }
      ... on DataPointCreated {
        dataPoint {
          ...HealthDataPoint
          ...MentionableInNote
        }
      }
      ... on ContraceptionCreatedOrApproved {
        contraception {
          ...Contraception
        }
      }
      ... on TaskCreated {
        task {
          ...Task
        }
      }
    }
  }

  fragment MedicationStatement on MedicationStatement {
    uuid
    name
    display
    description
    extractionStatus
    activeIngredients
    status
    updatedAt
    createdAt
    updatedBy {
      ...DoctorSummary
    }
  }

  fragment VaccinationStatement on VaccinationStatement {
    uuid
    description
    type
    extractionStatus
    createdAt
    updatedAt
    updatedBy {
      ...DoctorSummary
    }
  }

  fragment Symptom on Symptom {
    uuid
    name
    comment
    extractionStatus
    createdAt
  }

  fragment PatientAllergy on PatientAllergy {
    uuid
    type
    comment
    extractionStatus
    createdAt
    updatedAt
    updatedBy {
      ...DoctorSummary
    }
  }

  fragment Procedure on Procedure {
    uuid
    name
    comment
    createdAt
    updatedAt
    updatedBy {
      ...DoctorSummary
    }
    extractionStatus
  }

  fragment Condition on Condition {
    uuid
    type
    description
    familyMember
    extractionStatus
    createdAt
    updatedAt
    updatedBy {
      ...DoctorSummary
    }
    icd10Normalization {
      code
      description
    }
  }
`;

// The rule doesn't apply to `Mentionable` since it's a fragment on an interface.
/* eslint-disable nabla/fragment-has-uuid */
gql`
  fragment MentionableInNote on MentionableInNote {
    sourceNote {
      uuid
      parentAppointment {
        uuid
      }
    }
  }
`;
/* eslint-enable nabla/fragment-has-uuid */

export const PatientInformation = ({
  patient,
}: {
  patient: Pick<PatientSummaryFragment, "uuid" | "__typename">;
}) => {
  const { setSidePanelState, isTimelineOpen, setIsTimelineOpen } =
    useQASidePanel();
  const navigate = useNavigate();
  const { user } = useDoctor();
  const isDesktop = useIsDesktop();
  const { setScrollToActivityDisabled } = usePatientTimelineLocalState();

  const chatWidgetActions: ChatWidgets = {
    openChatWidget: (targetExperience) =>
      navigate(getQAInboxRoute(targetExperience, user.uuid)),
    openNewChatWidget: () =>
      navigate(getPatientViewRoute(patient), {
        state: {
          openNewChatWidget: true,
        } satisfies PatientViewNavigationState,
      }),
    closeNewChatWidget: () => undefined,
  };

  const openActivityAndEnableFocus = () => {
    setScrollToActivityDisabled(true);
    setIsTimelineOpen(true);
    setTimeout(() => {
      setScrollToActivityDisabled(false);
    }, 800);
  };

  return (
    <div className="flex-col h-full bg-grey-100">
      <PatientViewProvider patientUuid={patient.uuid}>
        <PatientViewSideSection
          onOpenActivity={openActivityAndEnableFocus}
          onClose={!isDesktop ? () => setSidePanelState(null) : undefined}
        />
        <PatientViewContext.Consumer>
          {(patientViewData) => (
            <SidePanel
              opened={isTimelineOpen}
              width={800}
              onClickOutside={() => setIsTimelineOpen(false)}
            >
              <ChatWidgetsContext.Provider value={chatWidgetActions}>
                {patientViewData && (
                  <PatientTimeline
                    patient={patientViewData.patientData.patient}
                    todoItems={patientViewData.todoItems.data}
                    doneItems={patientViewData.doneItems.data}
                    backIcon={{
                      name: "doubleChevron",
                      onClick: () => setIsTimelineOpen(false),
                    }}
                  />
                )}
              </ChatWidgetsContext.Provider>
            </SidePanel>
          )}
        </PatientViewContext.Consumer>
      </PatientViewProvider>
    </div>
  );
};
