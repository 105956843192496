import { ReactNode, useState } from "react";
import classNames from "classnames";

export const CollapsableCell = ({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className="max-w-440">
      <div className="cursor-pointer" onClick={() => setCollapsed(!collapsed)}>
        {title}
      </div>
      <div
        className={classNames(
          "transition overflow-hidden duration-300",
          { "max-h-0": collapsed },
          collapsed ? "opacity-0" : "opacity-100",
        )}
      >
        {children}
      </div>
    </div>
  );
};
