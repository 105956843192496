import { ReactNode } from "react";
import classNames from "classnames";

import { MentionedItem } from "types";
import { isKnownUnionValue } from "utils/apollo";

import { MentionProps } from "../RichText/RichText";

const mentionColorBackground = (type: MentionedItem["__typename"]) => {
  switch (type) {
    case "Symptom":
    case "Condition":
      return "#F4DF6D";
    case "MedicationStatement":
    case "VaccinationStatement":
      return "#A9E6B1";
    case "PatientAllergy":
      return "#ff77d0";
    case "HealthDataPoint":
      return "#00A8FF4C";
    default:
      return "rgba(194,194,194,0.3)";
  }
};

export const MentionPill = ({
  type,
  children,
  status,
  className,
}: {
  type: MentionedItem["__typename"];
  children: ReactNode;
  status: "BEING_TYPED" | "DRAFT" | "PUBLISHED";
  className?: string;
}) => (
  <span
    className={classNames("py-2", className, {
      "rounded-sm px-4": status !== "PUBLISHED",
      "pb-0": status === "PUBLISHED",
      border: status === "BEING_TYPED",
    })}
    style={
      status === "BEING_TYPED"
        ? {
            borderColor: mentionColorBackground(type),
          }
        : status === "DRAFT"
        ? { backgroundColor: mentionColorBackground(type) }
        : {
            borderBottom: "2px solid",
            borderColor: mentionColorBackground(type),
          }
    }
  >
    {children}
  </span>
);

export const NoteMentionPill = ({ item, children, Fallback }: MentionProps) => {
  if (item.__typename === "DraftMentionedItem") {
    return (
      <NoteMentionPill item={item.draftedItem} Fallback={Fallback}>
        {children}
      </NoteMentionPill>
    );
  }

  if (!isKnownUnionValue("MentionedItem", item)) {
    return <Fallback item={item}>{children}</Fallback>;
  }

  switch (item.__typename) {
    case "Symptom":
    case "Condition":
    case "PatientAllergy":
    case "MedicationStatement":
    case "VaccinationStatement":
    case "Procedure":
    case "HealthDataPoint":
      return (
        <MentionPill type={item.__typename} status="PUBLISHED">
          {children}
        </MentionPill>
      );
    case "MentionedPatient":
    case "PatientNote":
    case "PubliclyListedDrug":
    case "MentionedPatientDocument":
    case "MentionedDoctor":
    case "Experience":
    case "DeletedSymptom":
    case "DeletedAllergy":
    case "DeletedCondition":
    case "DeletedProcedure":
    case "DeletedDatapoint":
    case "DeletedMedication":
    case "DeletedVaccination":
      return <Fallback item={item}>{children}</Fallback>;
  }
};
