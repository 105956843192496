import { useState } from "react";
import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import {
  DoctorSummaryFragment,
  SubOrganizationSummaryFragment,
  UploadInput,
} from "generated/provider";
import { useUploadFile } from "graphql-client/useUploadFile";
import { useField } from "hooks/useField";
import { useTranslation } from "i18n";
import { compressImage, localURL } from "utils/file";

import { CommonFormDropzoneProps, Dropzone } from "./Dropzone";

export type FormAvatarValue = {
  avatar: string | File | undefined | null;
  upload?: () => Promise<UploadInput | null>;
};

export const FormAvatar = ({
  name,
  disabled,
  className,
  forUser,
  isImage,
  ...props
}: Omit<
  CommonFormDropzoneProps,
  "label" | "accept" | "maxSize" | "children"
> & {
  forUser: DoctorSummaryFragment | SubOrganizationSummaryFragment;
  isImage?: boolean;
}) => {
  const t = useTranslation();
  const [{ value, disabled: disabledField }, { error }, { setValue }] =
    useField<FormAvatarValue>({ name, disabled });
  const uploadFile = useUploadFile();
  const [progress, setProgress] = useState<number | null>(null);
  const label = value.avatar
    ? isImage
      ? t("form.dropzone.form_avatar.change_image")
      : t("form.dropzone.form_avatar.change_picture")
    : isImage
    ? t("form.dropzone.form_avatar.add_image")
    : t("form.dropzone.form_avatar.add_picture");

  return (
    <Dropzone
      multiple={false}
      name={name}
      error={error}
      disabled={disabledField}
      accept="image/*"
      maxSize={5_000_000}
      className={classNames("w-auto", className)}
      {...props}
      onDrop={(files) => {
        setValue({
          avatar: files[0],
          upload: async () => {
            setProgress(0);
            return uploadFile({
              purpose: "AVATAR",
              file: await compressImage(files[0]),
              onProgress: setProgress,
            }).finally(() => setProgress(null));
          },
        });
      }}
    >
      {(isDragActive) => (
        <div className="flex items-center">
          <Avatar
            size={60}
            user={{
              ...forUser,
              avatarUrl: value.avatar
                ? typeof value.avatar === "string"
                  ? value.avatar
                  : localURL(value.avatar)
                : null,
            }}
          />
          <div className="ml-12 border rounded px-12 py-10 flex items-center font-semibold">
            {isDragActive
              ? t("form.dropzone.form_avatar.release")
              : progress === null
              ? label
              : `${t("form.dropzone.form_avatar.upload")} ${progress}%`}
          </div>
        </div>
      )}
    </Dropzone>
  );
};
