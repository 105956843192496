import classNames from "classnames";

import { Popover } from "components/Popover/Popover";
import { FakeClientRect } from "types";

import { getMentionOption, MentionsOptionsProps } from "./utils";

export const MentionsOptions = <Option extends any>({
  coords,
  editorCoords,
  options: {
    header,
    data,
    getOptionId,
    getOptionLabel,
    display,
    minWidth = 250,
    emptyLabel,
  },
  selectedIndex,
  setSelectedIndex,
  onSelected,
}: {
  coords: FakeClientRect;
  editorCoords: FakeClientRect;
  options: MentionsOptionsProps<Option>;
  selectedIndex: number;
  setSelectedIndex: (value: number) => void;
  onSelected: () => void;
}) => {
  if (data.isEmpty() && !emptyLabel) return null;
  return (
    <Popover
      target={{
        getBoundingClientRect: () => ({
          ...coords,
          right: editorCoords.right,
        }),
      }}
      onClose={undefined}
      role="listbox"
      className="m-4 text-primary-dark flex-col"
      style={{ minWidth }}
      position={
        editorCoords.right - minWidth > coords.left
          ? ["top-left", "bottom-left"]
          : ["top-right", "bottom-right"]
      }
    >
      {header && (
        <div className="px-16 py-10 text-center font-medium border-b">
          {header}
        </div>
      )}
      {data.isEmpty() ? (
        <div>{emptyLabel}</div>
      ) : (
        <div
          className="flex-col overflow-auto rounded-b"
          style={{ maxHeight: 286 }}
        >
          {data.map((optionOrSubOptions, index) => {
            const option = getMentionOption(optionOrSubOptions);

            return (
              <button
                key={getOptionId ? getOptionId(option) : index}
                type="button"
                className={classNames(
                  "py-10 px-16 flex items-center justify-between",
                  index === selectedIndex ? "bg-blue-overlay" : "bg-white",
                )}
                onMouseEnter={() => setSelectedIndex(index)}
                onMouseDown={(e) => {
                  onSelected();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {display?.({
                  option,
                  label: getOptionLabel(option),
                  index,
                  selected: index === selectedIndex,
                }) ?? getOptionLabel(option)}
              </button>
            );
          })}{" "}
        </div>
      )}
    </Popover>
  );
};
