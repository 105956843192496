import gql from "graphql-tag";

import { UpdateQuestionnaireResponse } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import { notifier } from "utils/notifier";

import { PatientTimelineItem } from "../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../PatientTimelineItemContentWithPadding";

type FormData = {
  title: string;
};

gql`
  mutation UpdateQuestionnaireResponse(
    $questionnaireResponseUuid: UUID!
    $title: String!
  ) {
    updateQuestionnaireResponse(
      questionnaireResponseUuid: $questionnaireResponseUuid
      title: $title
    ) {
      questionnaireResponse {
        ...QuestionnaireResponse
      }
    }
  }
`;

export const QuestionnaireResponseItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"QuestionnaireResponse">;
}) => {
  const t = useTranslation();
  const [updateQuestionnaireResponse] = useMutation(
    UpdateQuestionnaireResponse,
  );
  return (
    <PatientTimelineItem<"QuestionnaireResponse", FormData, FormData>
      item={item}
      initialValues={{
        title:
          item.optionalTitle ??
          t("patient_view.default_title.questionnaire_response"),
      }}
      validationSchema={{
        title: "required",
      }}
      onSubmit={async ({ title }) => {
        await updateQuestionnaireResponse({
          questionnaireResponseUuid: item.uuid,
          title,
        });
        notifier.success(
          t("patient_view.questionnaire_response.update_success"),
        );
      }}
    >
      <PatientTimelineItemContentWithPadding className="space-y-20">
        {item.items.map(({ question, answer }) => (
          <div className="text-grey-400" key={question}>
            <h4 className="font-medium">{question}</h4>
            <p>{answer}</p>
          </div>
        ))}
      </PatientTimelineItemContentWithPadding>
    </PatientTimelineItem>
  );
};
