import { useState } from "react";

import { Button } from "components/Button/Button";
import { Input } from "components/Form/Input/Input";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { useDebounceSearch } from "hooks/useDebounce";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";

import { ProviderPatientsList } from "./ProviderPatientsList";

export const Patients = () => {
  const t = useTranslation();
  const { inputProps, search } = useDebounceSearch();
  const [addAccount, setAddAccount] = useState(false);
  const isDesktop = useIsDesktop();

  return (
    <div className="flex-col flex-fill overflow-auto">
      <div className="flex items-center bg-white p-16 lg:px-44 lg:pt-24 pb-0">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("patients.patients.title")}
        </h1>
        <div className="flex space-x-8 flex-shrink ml-auto py-4 pl-12">
          <Input
            {...inputProps}
            placeholder={t("patients.patients.search")}
            wrapperClassName="w-auto mt-0 flex-shrink"
            leftInnerElement="search"
            rightInnerElement={
              search ? (
                <ClickableIcon
                  name="closeCircle"
                  onClick={() => {
                    inputProps.onChange({ currentTarget: { value: "" } });
                  }}
                />
              ) : null
            }
          />
          {isDesktop ? (
            <Button
              label={t("patients.patients.add_patient")}
              onClick={() => {
                setAddAccount(true);
              }}
            />
          ) : (
            <ClickableIcon
              name="add"
              onClick={() => setAddAccount(true)}
              className="border rounded"
            />
          )}
        </div>
      </div>

      <ProviderPatientsList
        isPatientAdded={addAccount}
        onPatientAdded={() => setAddAccount(false)}
        search={search}
      />
    </div>
  );
};
