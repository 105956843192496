import { getTimeZones, TimeZone } from "@vvo/tzdb";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormSelect } from "components/Form/Select/FormSelect";
import { displayTimeZone, findTimeZone } from "components/TimezonePicker/utils";
import { useUser } from "contexts/User/UserContext";
import {
  CalendarStartOfWeek,
  CalendarStartOfWeekKnownValues,
  MeasurementSystem,
  MeasurementSystemKnownValues,
  UpdateOrganizationSettings,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import {
  displayCalendarStartOfWeekLabel,
  displayMeasurementSystemLabel,
} from "./display";

gql`
  mutation UpdateOrganizationSettings($input: OrganizationSettingsInput!) {
    updateOrganizationSettings(input: $input) {
      doctor {
        subOrganization {
          ...SubOrganizationSummary
        }
      }
    }
  }
`;

type FormValues = {
  timezone?: TimeZone;
  measurementSystem?: MeasurementSystem;
  calendarStartOfWeek?: CalendarStartOfWeek;
};

export const RegionPreferences = () => {
  const t = useTranslation();
  const { subOrganizationTimezone, user } = useUser();
  const [updateRegionPreferences, loading] = useMutation(
    UpdateOrganizationSettings,
    {
      onSuccess: () =>
        notifier.success(t("settings.region_preferences.update_success")),
    },
  );

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <h1 className="text-primary-dark text-24 font-bold">
        {t("settings.region_preferences.title")}
      </h1>
      <Form<FormValues>
        className="flex-col items-start w-full bg-white rounded border p-32"
        initialValues={{
          timezone: findTimeZone(subOrganizationTimezone),
          measurementSystem: user?.subOrganization.measurementSystem,
          calendarStartOfWeek: user?.subOrganization.calendarStartOfWeek,
        }}
        onSubmit={async ({
          timezone,
          measurementSystem,
          calendarStartOfWeek,
        }) => {
          await updateRegionPreferences({
            input: {
              timezone: timezone?.name,
              measurementSystem,
              calendarStartOfWeek,
            },
          });
        }}
      >
        <div className="font-bold text-18 text-primary-dark">
          {t("settings.region_preferences.timezone_title")}
        </div>
        <FormSelect
          wrapperClassName="mt-10"
          label={t("organization_timezone.timezone_of_your_organization")}
          name="timezone"
          options={getTimeZones()}
          getOptionLabel={displayTimeZone}
          getOptionId={displayTimeZone}
        />
        <div className="font-bold text-18 text-primary-dark mt-20">
          {t("settings.region_preferences.units_title")}
        </div>
        <FormSelect
          wrapperClassName="mt-10"
          label={t("settings.region_preferences.measurement_system")}
          name="measurementSystem"
          options={MeasurementSystemKnownValues}
          getOptionLabel={displayMeasurementSystemLabel}
        />
        <div className="font-bold text-18 text-primary-dark mt-20">
          {t("settings.region_preferences.calendar_title")}
        </div>
        <FormSelect
          wrapperClassName="mt-10"
          label={t("settings.region_preferences.start_of_week")}
          name="calendarStartOfWeek"
          options={CalendarStartOfWeekKnownValues}
          getOptionLabel={displayCalendarStartOfWeekLabel}
        />
        <Submit
          className="mt-32"
          label={t("settings.region_preferences.submit")}
          loading={loading}
        />
      </Form>
    </Background>
  );
};
