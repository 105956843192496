import classNames from "classnames";

import { useMedicalCodes } from "atoms/medicalCodes/useMedicalCodes";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import {
  FamilyMemberDegree,
  FamilyMemberDegreeKnownValues,
  GranularDateTimeInput,
  MedicalHistoryStatus,
  MedicalHistoryStatusKnownValues,
  SupportedLocale,
} from "generated/provider";
import { useTranslation } from "i18n";
import { ICD10Code } from "types";
import { displayFamilyMemberDegree } from "utils/display";
import { isKnownValue } from "utils/enum";
import { getLanguageFromLocale, language } from "utils/intl";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { GranularDateInput } from "../GranularDate/FormGranularDate";
import { LabelWrapper, UnnamedLabelWrapperProps } from "../Label/LabelWrapper";
import { Select } from "../Select/Select";
import { MedicalCodesExplorer } from "./MedicalCodesExplorer";

export type IcdCodePickerWithDatesValue = {
  uuid: UUID;
  icdCode: ICD10Code;
  startDate: GranularDateTimeInput | null;
  endDate: GranularDateTimeInput | null;
  status: MedicalHistoryStatus;
  familyMember: FamilyMemberDegree | null;
}[];

export const IcdCodePickerWithDatesAndStatus = ({
  value,
  setValue,
  label,
  disabled,
  error,
  hint,
  locale,
  includeFamilyMembers,
}: {
  value: IcdCodePickerWithDatesValue;
  setValue: (value: IcdCodePickerWithDatesValue) => void;
  disabled?: boolean;
  locale?: SupportedLocale;
  includeFamilyMembers?: boolean;
} & UnnamedLabelWrapperProps) => {
  const { getICD10WHOByCode, getICD10CMByCode } = useMedicalCodes();
  const t = useTranslation();

  const medicalConditionStatusDisplayName = (
    name: MedicalHistoryStatus,
  ): string => {
    if (isKnownValue(name, MedicalHistoryStatusKnownValues)) {
      switch (name) {
        case "EXPLICIT_PRESENCE":
          return t("form.medical_history_status.explicit_presence");
        case "EXPLICIT_ABSENCE":
          return t("form.medical_history_status.explicit_absence");
        case "FEAR_OF":
          return t("form.medical_history_status.fear_of");
      }
    } else {
      return name;
    }
  };

  return (
    <LabelWrapper
      label={label ?? t("form.icd_codes.title")}
      error={error}
      hint={hint}
      useDiv
      wrapperClassName="items-stretch"
    >
      <ul className="flex-col items-start w-1/2">
        {value.map((it) => {
          const item =
            locale === "FRENCH"
              ? getICD10WHOByCode(it.icdCode)
              : getICD10CMByCode(it.icdCode);
          return (
            <div key={it.uuid}>
              <li key={it.uuid} className="text-primary-dark flex mb-4 h-40">
                <div
                  className={classNames(
                    "rounded-full px-8 flex items-center",
                    item ? "bg-grey-200" : "bg-danger text-white",
                  )}
                >
                  {item
                    ? item[
                        locale
                          ? getLanguageFromLocale(locale)
                          : (language as "fr" | "en" | "pt")
                      ]
                    : t("form.icd_codes.deprecated_code")}
                  <span
                    className={classNames(
                      "ml-4",
                      item ? "text-body" : "text-white",
                    )}
                  >
                    {item?.code ?? it.icdCode}
                  </span>
                  {!disabled && (
                    <ClickableIcon
                      name="close"
                      size={12}
                      className="h-12 w-12"
                      onClick={() =>
                        setValue(
                          value.mapNotNull((valueItem) => {
                            if (valueItem.uuid === it.uuid) return null;
                            return valueItem;
                          }),
                        )
                      }
                    />
                  )}
                </div>
              </li>
              <li className="flex flex-row space-x-4 mb-12">
                <Select
                  required
                  wrapperClassName="max-w-[150px]"
                  name="status"
                  onChange={(v) => {
                    setValue(
                      value.map((valueItem) => {
                        if (valueItem.uuid !== it.uuid) return valueItem;
                        return {
                          ...valueItem,
                          status: v,
                        };
                      }),
                    );
                  }}
                  value={it.status}
                  options={MedicalHistoryStatusKnownValues}
                  getOptionLabel={(option) =>
                    medicalConditionStatusDisplayName(option)
                  }
                  disabled={disabled}
                />
                {includeFamilyMembers && (
                  <Select<FamilyMemberDegree, true>
                    isClearable
                    wrapperClassName="max-w-[150px]"
                    name="familyMember"
                    onChange={(v) => {
                      setValue(
                        value.map((valueItem) => {
                          if (valueItem.uuid !== it.uuid) return valueItem;
                          return {
                            ...valueItem,
                            familyMember: v ?? null,
                          };
                        }),
                      );
                    }}
                    value={it.familyMember ?? undefined}
                    options={FamilyMemberDegreeKnownValues}
                    getOptionLabel={(option) =>
                      isKnownValue(option, FamilyMemberDegreeKnownValues)
                        ? displayFamilyMemberDegree(option)
                        : ""
                    }
                    disabled={disabled}
                  />
                )}
                <GranularDateInput
                  setValue={(v) => {
                    setValue(
                      value.map((valueItem) => {
                        if (valueItem.uuid !== it.uuid) return valueItem;
                        return {
                          ...valueItem,
                          startDate: v ?? null,
                        };
                      }),
                    );
                  }}
                  initialValue={it.startDate ?? undefined}
                  name="start"
                  wrapperClassName="w-[150px]"
                  inlineError="left"
                  placeholder={t("form.icd_codes.start_date")}
                  disabled={disabled}
                />
                <GranularDateInput
                  setValue={(v) => {
                    setValue(
                      value.map((valueItem) => {
                        if (valueItem.uuid !== it.uuid) return valueItem;
                        return {
                          ...valueItem,
                          endDate: v ?? null,
                        };
                      }),
                    );
                  }}
                  initialValue={it.endDate ?? undefined}
                  name="end"
                  wrapperClassName="w-[150px]"
                  inlineError="left"
                  placeholder={t("form.icd_codes.end_date")}
                  disabled={disabled}
                />
              </li>
            </div>
          );
        })}
      </ul>
      {!disabled && (
        <UncontrolledPopover
          position={["bottom-left", "top-left"]}
          className="flex-col my-6 overflow-hidden"
          style={{ width: 300, maxHeight: 303 }}
          content={(close) => (
            <MedicalCodesExplorer
              codeType={locale === "FRENCH" ? "ICD10WHO" : "ICD10CM"}
              onSelect={(it) =>
                setValue([
                  ...value,
                  {
                    icdCode: it.code,
                    startDate: null,
                    endDate: null,
                    status: "EXPLICIT_PRESENCE",
                    familyMember: null,
                    uuid: ephemeralUuidV4(),
                  },
                ])
              }
              close={close}
              pickedCodes={[]}
              locale={locale}
            />
          )}
        >
          {({ setTarget }) => (
            <button
              className="rounded-sm py-2 px-8 hover:bg-grey-100"
              type="button"
              onClick={setTarget}
            >
              {t("form.icd_codes.add")}
            </button>
          )}
        </UncontrolledPopover>
      )}
    </LabelWrapper>
  );
};
