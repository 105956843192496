import { CSSProperties } from "react";
import classNames from "classnames";

export const ProgressBar = ({
  percentage,
  className,
  style,
}: {
  percentage: number;
  className?: string;
  style?: CSSProperties;
}) => (
  <div
    className={classNames("h-6 rounded-full bg-grey-200", className)}
    style={style}
  >
    <div
      className="h-full bg-primary rounded-full"
      style={{ width: `${percentage}%` }}
    />
  </div>
);
