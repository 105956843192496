import classNames from "classnames";

import { AvatarList } from "components/Avatar/AvatarList";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { BackMobile } from "components/Mobile/BackMobile";
import { useDoctor } from "contexts/User/UserContext";
import { displayExperienceParticipants } from "utils/display";

import { useLoadedDoctorExperience } from "../useLoadedDoctorExperience";
import { CallLauncher } from "./CallLauncher";

export const Header = ({
  panelOpened,
  togglePanel,
}: {
  panelOpened: boolean;
  togglePanel?: () => void;
}) => {
  const { user } = useDoctor();
  const { experience, title, otherDoctors } = useLoadedDoctorExperience();

  return (
    <header className="w-full py-10 bg-white border-b pl-20 pr-12 sm:pl-10 flex items-center overflow-hidden">
      <BackMobile fallback="/" className="mr-8" />
      <div className="flex-fill flex items-center">
        <AvatarList users={otherDoctors} />
        <div className="flex-fill">
          <div className="text-10">{title}</div>
          <div className="text-14 text-primary-dark truncate">
            {displayExperienceParticipants(experience, user.uuid)}
          </div>
        </div>
      </div>
      <CallLauncher />
      {togglePanel && (
        <ClickableIcon
          name="info"
          className={classNames("ml-4", { "text-primary": panelOpened })}
          onClick={() => togglePanel()}
        />
      )}
    </header>
  );
};
