import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { SortStatus } from "components/Table/Table";
import {
  DoctorSummaryFragment,
  PatientSummaryFragment,
  TaskFragment,
  TaskPriority,
  TaskSearch,
  TaskSearchOrder,
  TaskSearchVariables,
} from "generated/provider";
import { GraphQLClient } from "types";

export type TaskStatus = "TODO" | "COMPLETED" | "ALL";

export type TaskHeader =
  | "TITLE"
  | "PATIENT"
  | "CREATED_AT"
  | "CREATED_BY"
  | "ASSIGNED_DOCTOR"
  | "PRIORITY";

export type FormTaskFilterValues = {
  status: TaskStatus;
  patient: PatientSummaryFragment | undefined;
  priority: TaskPriority | undefined;
  assignedDoctor: DoctorSummaryFragment | undefined;
};

export type TaskConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide">
  | undefined;

export const makeTaskSearchVariables = (
  filter: FormTaskFilterValues,
  search: string | undefined,
  sortStatus: SortStatus<TaskHeader>,
): TaskSearchVariables => ({
  orderField: makeTaskSearchOrder(sortStatus),
  search,
  isCompleted:
    filter.status === "TODO"
      ? false
      : filter.status === "COMPLETED"
      ? true
      : undefined,
  priority: filter.priority,
  patientUuid: filter.patient?.uuid,
  assignedDoctorUuid: filter.assignedDoctor?.uuid,
});

const makeTaskSearchOrder = (
  sortStatus?: SortStatus<TaskHeader>,
): TaskSearchOrder => {
  if (sortStatus?.id === "PRIORITY") {
    return sortStatus.isAscending ? "LEAST_PRIORITY" : "MOST_PRIORITY";
  } else if (sortStatus?.id === "CREATED_AT") {
    return sortStatus.isAscending ? "ANCIENTLY_CREATED" : "RECENTLY_CREATED";
  }
  return "RECENTLY_CREATED";
};

export const taskFilterCount = (filter: FormTaskFilterValues) =>
  [
    filter.status !== "TODO",
    filter.priority !== undefined,
    filter.patient !== undefined,
    filter.assignedDoctor !== undefined,
  ]
    .map(Number)
    .reduce((a, b) => a + b, 0);

export const updateTaskStatusInCache = (
  client: GraphQLClient,
  task: TaskFragment,
  filter: FormTaskFilterValues,
  search: string | undefined,
  sortStatus: SortStatus<TaskHeader>,
) => {
  client.update({
    query: TaskSearch,
    variables: makeTaskSearchVariables(filter, search, sortStatus),
    write: (draft) => {
      if (
        (task.isCompleted && filter.status === "TODO") ||
        (!task.isCompleted && filter.status === "COMPLETED")
      ) {
        draft.tasks.data = draft.tasks.data.filter(
          (t) => t?.uuid !== task.uuid,
        );
      }
    },
  });
};
