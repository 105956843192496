import { Fragment, useState } from "react";
import classNames from "classnames";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Maybe } from "base-types";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Modal } from "components/Modal/Modal";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { KnownTimelineItem } from "contexts/Experience/ExperienceContext";
import {
  MessageFragment,
  MessageSenderType,
  UserSummaryFragment,
} from "generated/provider";
import { useDownload } from "hooks/useDownload";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useOn } from "hooks/useOn";
import { useTranslation } from "i18n";

export type BatchOrTimelineItem = KnownTimelineItem | ImageBatch;

export type MessageFragmentWithImageContent = MessageFragment & {
  content: Extract<
    MessageFragment["content"],
    { __typename: "ImageMessageContent" }
  >;
};

export type ImageBatch = {
  __typename: "ImageBatch";
  messages: MessageFragmentWithImageContent[];
  uuid: UUID;
  eventTime: ISOString;
  batchIdentifier: UUID;
  senderType: MessageSenderType;
  sender: Maybe<UserSummaryFragment>;
};

export const ImageBatchPreview = ({
  batch,
  isMine,
  onWhiteBackground,
  onClick,
}: {
  batch: ImageBatch;
  isMine: boolean;
  onWhiteBackground: boolean;
  onClick: (index: number) => void;
}) => {
  const images = batch.messages;

  return (
    <div
      className={classNames(
        "p-6 rounded flex-col space-y-4",
        isMine ? "bg-primary" : onWhiteBackground ? "bg-grey-100" : "bg-white",
      )}
    >
      <div className="flex items-center space-x-4">
        <ImagePreview imageMessage={images[0]} onClick={() => onClick(0)} />
        {images.length > 1 && (
          <ImagePreview imageMessage={images[1]} onClick={() => onClick(1)} />
        )}
      </div>
      <div className="flex items-center space-x-4">
        {images.length > 2 && (
          <ImagePreview imageMessage={images[2]} onClick={() => onClick(2)} />
        )}
        {images.length > 3 && (
          <button className="relative" onClick={() => onClick(3)}>
            {images.length > 4 && (
              <div className="absolute top-0 left-0 w-full h-full bg-grey-200 opacity-70 text-24 z-1 flex-center">
                + {batch.messages.length - 4}
              </div>
            )}
            <ImagePreview imageMessage={images[3]} />
          </button>
        )}
      </div>
    </div>
  );
};

const ImagePreview = ({
  imageMessage,
  onClick,
}: {
  imageMessage: MessageFragmentWithImageContent;
  onClick?: () => void;
}) => (
  <img
    key={imageMessage.uuid}
    src={imageMessage.content.fileUpload.urlV2.url}
    style={{ width: "100px", height: "100px" }}
    className="cursor-pointer object-cover"
    alt=""
    onClick={onClick}
  />
);

export const ImageBatchModal = ({
  batch,
  onHide,
  selectedIndex,
}: {
  batch: ImageBatch;
  onHide: () => void;
  selectedIndex: number;
}) => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const [webSelectedIndex, setWebSelectedIndex] = useState(selectedIndex);
  const [download, downloading] = useDownload();

  const images = isDesktop
    ? [batch.messages[webSelectedIndex]]
    : batch.messages;

  useOn("keydown", (e) => {
    if (e.key === "ArrowLeft" && webSelectedIndex > 0) {
      setWebSelectedIndex(webSelectedIndex - 1);
    }
    if (
      e.key === "ArrowRight" &&
      webSelectedIndex < batch.messages.length - 1
    ) {
      setWebSelectedIndex(webSelectedIndex + 1);
    }
  });

  return (
    <Modal
      xl
      onHide={onHide}
      alignTopOnMobile
      title={
        isDesktop
          ? `${(
              webSelectedIndex + 1
            ).toString()} / ${batch.messages.length.toString()}`
          : undefined
      }
      className={isDesktop ? "flex-fill" : "flex-col space-y-30 overflow-auto"}
    >
      {images.map((mess) => (
        <Fragment key={mess.uuid}>
          <TooltipWrapper
            className="absolute inset-tl-0"
            label={t(
              "conversation_content.timeline.message_container.image_batch_preview.download_the_document",
            )}
          >
            <ClickableIcon
              name={downloading ? "change" : "download"}
              className="h-36 lg:h-44 w-36 lg:w-44 flex-center hover:text-primary"
              iconClassName={downloading ? "animate-spin" : ""}
              onClick={() =>
                download(
                  mess.content.fileUpload.urlV2.url,
                  mess.content.fileUpload.fileName,
                )
              }
            />
          </TooltipWrapper>
          <TransformWrapper wheel={{ step: 0.2 }}>
            <TransformComponent
              wrapperClass="flex-shrink"
              contentClass="h-full"
            >
              <img
                src={mess.content.fileUpload.urlV2.url}
                alt=""
                className="h-full w-full object-contain"
              />
            </TransformComponent>
          </TransformWrapper>
        </Fragment>
      ))}
      {isDesktop && (
        <div className="flex items-center space-x-30 mt-30">
          <ClickableIcon
            onClick={() => setWebSelectedIndex(webSelectedIndex - 1)}
            name="chevron"
            size={36}
            rotate={180}
            disabled={webSelectedIndex === 0}
          />
          <ClickableIcon
            onClick={() => setWebSelectedIndex(webSelectedIndex + 1)}
            size={36}
            name="chevron"
            disabled={webSelectedIndex === batch.messages.length - 1}
          />
        </div>
      )}
    </Modal>
  );
};
