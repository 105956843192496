import { RefObject } from "react";

import {
  DocumentPreview,
  DocumentPreviewSection,
} from "components/Document/DocumentPreview";
import {
  MentionWithPubliclyListedDrug,
  RichText,
} from "components/Form/Editor/RichText/RichText";
import { useTranslation } from "i18n";
import { TextWithMentions } from "types";

import { useEHRInput } from "../useEHRInput";

export const NablaPrescriptionPreview = ({
  textWithMentions,
  previewRef,
  className,
}: {
  textWithMentions?: TextWithMentions;
  previewRef: RefObject<HTMLDivElement> | null;
  className?: string;
}) => {
  const t = useTranslation();
  const { ehrInput } = useEHRInput();

  return (
    <DocumentPreview
      previewRef={previewRef}
      className={className}
      defaultSignature={t("pdf_preview.default_signature.prescription")}
    >
      <DocumentPreviewSection
        className="mt-24"
        title={t("pdf_preview.prescription")}
      >
        <div className="whitespace-pre-wrap text-12">
          <RichText
            source={textWithMentions ?? ehrInput.content}
            hasMarkdown={false}
            Mention={MentionWithPubliclyListedDrug}
          />
        </div>
      </DocumentPreviewSection>
    </DocumentPreview>
  );
};
