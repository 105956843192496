import { useState } from "react";
import classNames from "classnames";
import { Link, useMatch } from "react-router-dom";

import { Button } from "components/Button/Button";
import { NotFound } from "components/ErrorPage/NotFound";
import { Input } from "components/Form/Input/Input";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Redirect } from "components/Routes/Redirect";
import { Separator } from "components/Separator/Separator";
import { useUser } from "contexts/User/UserContext";
import { useDebounceSearch } from "hooks/useDebounce";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { AdminPatientsList } from "../Patients/AdminPatientsList";
import { Providers } from "./Providers/Providers";

export const Accounts = () => {
  const t = useTranslation();
  const { hasPermission } = useUser();
  const [addAccount, setAddAccount] = useState(false);
  const { inputProps, search } = useDebounceSearch();
  const canShowPatients = hasPermission("EDIT_PATIENTS_ADMIN_DATA");

  const queryParam = useMatch(
    `${routes.ACCOUNTS}/:accountType*`,
  )?.params.accountType.toUpperCase();
  const accountType =
    queryParam && ["PATIENTS", "TEAM"].includes(queryParam)
      ? queryParam
      : "UNKNOWN";

  if (!hasPermission("EDIT_DOCTORS")) return <NotFound />;

  if (accountType === "UNKNOWN") return <Redirect to={routes.TEAM_ACCOUNTS} />;

  if (accountType === "PATIENTS" && !canShowPatients) {
    return <Redirect to={routes.TEAM_ACCOUNTS} />;
  }

  return (
    <div className="flex-col flex-fill bg-light-blue overflow-auto">
      <div className="flex-col bg-white px-16 lg:px-44">
        <h1 className="text-primary-dark text-24 font-bold pt-16 lg:pt-24 ">
          {t("accounts.accounts_title")}
        </h1>

        <div className="flex justify-between">
          <div className="flex">
            <Link
              className={classNames("mr-24 hover:cursor-pointer py-16", {
                "text-primary font-semibold border-b-2 border-primary":
                  accountType === "TEAM",
              })}
              to={routes.TEAM_ACCOUNTS}
            >
              {t("accounts.providers")}
            </Link>
            {canShowPatients && (
              <Link
                className={classNames("mr-24 hover:cursor-pointer py-16", {
                  "text-primary font-semibold border-b-2 border-primary":
                    accountType === "PATIENTS",
                })}
                to={routes.PATIENT_ACCOUNTS}
              >
                {t("accounts.patients")}
              </Link>
            )}
          </div>
          <div>
            <div className="flex space-x-8 flex-shrink ml-auto py-4">
              <Input
                {...inputProps}
                placeholder={t("providers.providers.search")}
                wrapperClassName="w-auto mt-0 flex-shrink hidden lg:block"
                leftInnerElement="search"
                rightInnerElement={
                  search ? (
                    <ClickableIcon
                      name="closeCircle"
                      onClick={() => {
                        inputProps.onChange({ currentTarget: { value: "" } });
                      }}
                    />
                  ) : null
                }
              />
              <Button
                label={
                  accountType === "TEAM"
                    ? t("providers.providers.add_account")
                    : t("patients.patients.add_patient")
                }
                onClick={() => {
                  setAddAccount(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="flex-col p-16 lg:px-44">
        {accountType === "PATIENTS" && (
          <AdminPatientsList
            isPatientAdded={addAccount}
            onPatientAdded={() => setAddAccount(false)}
            search={search}
          />
        )}
        {accountType === "TEAM" && (
          <Providers
            isProviderAdded={addAccount}
            onProviderAdded={() => setAddAccount(false)}
            search={search}
          />
        )}
      </div>
    </div>
  );
};
