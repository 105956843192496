import { ReactNode, useMemo, useState } from "react";
import classNames from "classnames";
import { ContentState, convertFromRaw, Editor, EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

import { DoctorsListPopover } from "components/Doctor/DoctorsListPopover";
import { HighlightText } from "components/HighlightTrext/HighlightText";
import { DoctorSummaryFragment } from "generated/provider";
import { useTargetState } from "hooks";
import { staticT as t } from "i18n";
import { MentionedItem, TextWithMentions } from "types";

import { Decorator } from "../core/types";
import { getDecorators } from "../core/utils";
import styles from "./richText.module.css";

export type MentionProps = {
  item: MentionedItem;
  Fallback: (props: FallbackProps) => JSX.Element;
  children: ReactNode;
};
type FallbackProps = Omit<MentionProps, "Fallback">;

export const RichText = ({
  source,
  reducedLength,
  Mention,
  hasMarkdown,
  highlightSearch,
}: {
  source: TextWithMentions;
  reducedLength?: number;
  Mention: ((props: MentionProps) => JSX.Element) | null;
  hasMarkdown: boolean;
  highlightSearch?: string;
}) => {
  const canBeReduced = reducedLength
    ? source.text.length > reducedLength
    : false;
  const [isReduced, setIsReduced] = useState(canBeReduced);

  const editorState = useMemo(() => {
    const getContent = hasMarkdown ? stateFromMarkdown : contentFromRawText;

    const RichTextDecorator: Decorator = {
      entityStrategy: (entity) => entity.type === "MENTION",
      component: ({ entityKey, contentState }) => {
        const mention =
          entityKey
            ?.let((it) => contentState.getEntity(it))
            ?.let((entity) =>
              source.mentions.find(
                (m) =>
                  m.mentionIdentifier ===
                  Number(entity.getData().url.split(":")[1]),
              ),
            ) ?? null;
        const item = mention?.mentionedItem;
        const label = mention?.displayString
          ? mention.displayString
          : t("form.editor.mention_editor.display.hashtag_unknown_mention");

        if (!item) return <span>{label}</span>;

        return Mention ? (
          <Mention item={item} Fallback={FallbackMention}>
            {label}
          </Mention>
        ) : (
          <FallbackMention item={item}>{label}</FallbackMention>
        );
      },
    };

    const HighlightSearchDecorator: Decorator = {
      textStrategy: (text: string) =>
        highlightSearch && text ? text.includes(highlightSearch) : false,
      component: ({ blockKey, contentState, start, end }) => (
        <HighlightText
          text={contentState
            .getBlockForKey(blockKey)
            .getText()
            .slice(start, end)}
          search={highlightSearch}
        />
      ),
    };

    return EditorState.createWithContent(
      getContent(
        isReduced && reducedLength
          ? sliceTextOnWhitespaces(source.text, reducedLength)
          : source.text,
      ),
      getDecorators([HighlightSearchDecorator, RichTextDecorator]),
    );
  }, [
    Mention,
    hasMarkdown,
    highlightSearch,
    isReduced,
    reducedLength,
    source.mentions,
    source.text,
  ]);

  return (
    <div className={classNames(styles.markdownStyling, "overflow-hidden")}>
      <Editor editorState={editorState} onChange={() => undefined} readOnly />
      {canBeReduced && (
        <button
          className="button-link p-0"
          onClick={(e) => {
            setIsReduced(!isReduced);
            e.stopPropagation();
          }}
        >
          {isReduced ? "Voir plus" : "Voir moins"}
        </button>
      )}
    </div>
  );
};

export const MentionWithPubliclyListedDrug = ({
  item,
  children,
  Fallback,
}: MentionProps) =>
  item.__typename === "PubliclyListedDrug" ? (
    <span className="font-medium">{children}</span>
  ) : (
    <Fallback item={item}>{children}</Fallback>
  );

const FallbackMention = ({ item, children }: FallbackProps) => {
  if (
    item.__typename === "MentionedDoctor" &&
    item.maybeDoctor.__typename === "Doctor"
  ) {
    return <DoctorMention doctor={item.maybeDoctor}>{children}</DoctorMention>;
  }
  return <span>{children}</span>;
};

const DoctorMention = ({
  doctor,
  children,
}: {
  doctor: DoctorSummaryFragment;
  children: ReactNode;
}) => {
  const [previewDoctorTarget, setPreviewDoctorTarget] = useTargetState();

  return (
    <button className="text-primary" onClick={setPreviewDoctorTarget}>
      {children}
      {previewDoctorTarget && (
        <DoctorsListPopover
          target={previewDoctorTarget}
          position="bottom-right"
          doctors={[doctor]}
          onClose={() => setPreviewDoctorTarget(undefined)}
        />
      )}
    </button>
  );
};

const contentFromRawText = (text: string): ContentState => {
  const entityRanges = Array.from(
    text.matchAll(/\[_NABLA_MENTION_\]\(_NABLA_MENTION_:([0-9]{1,6})\)/gu),
    (match) => ({
      offset: match.index!,
      length: match[0].length,
      key: `_NABLA_MENTION:${match[1]}`,
    }),
  );
  return convertFromRaw({
    blocks: [
      {
        key: "raw",
        inlineStyleRanges: [],
        type: "unstyled",
        text,
        depth: 0,
        entityRanges,
      },
    ],
    entityMap: Object.fromEntries(
      entityRanges.map(({ key }) => [
        key,
        // Keeps compatibility with draft-js-import-markdown which parses the mention as markdown links
        { type: "MENTION", mutability: "IMMUTABLE", data: { url: key } },
      ]),
    ),
  });
};

const sliceTextOnWhitespaces = (text: string, maxLength: number) =>
  text.slice(
    0,
    Array.from(text.matchAll(/\s/gu), (match) => match.index!).findLast(
      (index) => index <= maxLength,
    ),
  );
