import { ReactNode, useState } from "react";
import classNames from "classnames";

import { Label } from "components/Form/Label/Label";
import { Icon } from "components/Icon/Icon";

type Span = { start: number; end: number };
type SpannedText = { text: string; spans: Span[] };
type SpanTypeAndSpans = { spanType: string; spannedText: SpannedText };
type Edge = {
  subject: SpannedText;
  predicate: string;
  object: SpannedText;
  score: number;
};
type BlockProperty = { name: string; value: string };
type BlockType =
  | "Procedure"
  | "Vaccination"
  | "Medication"
  | "Contraception"
  | "Condition"
  | "Allergy";
type Block = { properties: BlockProperty[]; type?: BlockType };
type ReportTiming = { name: string; durationMs: number };
export type ReportType = {
  spans: SpanTypeAndSpans[];
  acceptedEdges: Edge[];
  rejectedEdges: Edge[];
  rawBlocks: Block[];
  blocks: Block[];
  timings?: ReportTiming[];
};

const getBgColor = (blockType: BlockType) => {
  switch (blockType) {
    case "Contraception":
      return "rgba(27,137,199,0.58)";
    case "Procedure":
      return "rgba(164,125,109,0.58)";
    case "Condition":
      return "rgba(244,223,109,0.75)";
    case "Allergy":
      return "rgba(255,162,69,0.3)";
    case "Medication":
      return "#a9e4b1";
    case "Vaccination":
      return "rgba(255,119,208,0.6)";
  }
};

const SpannedText = ({ spannedText }: { spannedText: SpannedText }) => {
  if (spannedText.spans.isEmpty()) return <div>{spannedText.text}</div>;
  const sortedSpans = spannedText.spans.sortAsc((it) => it.start);

  const independentSpans = [];
  let currentSpan = [sortedSpans[0].start, sortedSpans[0].end];
  for (const source of sortedSpans.slice(1)) {
    if (source.start <= currentSpan[1]) {
      currentSpan = [currentSpan[0], Math.max(currentSpan[1], source.end)];
    } else {
      independentSpans.push(currentSpan);
      currentSpan = [source.start, source.end];
    }
  }
  independentSpans.push(currentSpan);

  const pieces: { text: string; isHighlighted: boolean }[] = [];
  let lastIndex = 0;
  independentSpans.forEach((span) => {
    if (span[0] !== lastIndex) {
      pieces.push({
        text: spannedText.text.slice(lastIndex, span[0]),
        isHighlighted: false,
      });
    }
    pieces.push({
      text: spannedText.text.slice(span[0], span[1]),
      isHighlighted: true,
    });
    lastIndex = span[1];
  });

  if (lastIndex < spannedText.text.length) {
    pieces.push({
      text: spannedText.text.slice(lastIndex),
      isHighlighted: false,
    });
  }

  return (
    <div>
      {pieces.map((piece, index) => (
        <span
          key={`text-${index}`}
          className={classNames(
            {
              "bg-coral rounded p-2": piece.isHighlighted,
            },
            "leading-3",
          )}
        >
          {piece.text}
        </span>
      ))}
    </div>
  );
};

const ReportTable = ({
  headers,
  rows,
}: {
  headers: ReactNode[];
  rows: ReactNode[][];
}) => (
  <table className="w-full border">
    <tr className="border">
      {headers.map((it, index) => (
        <th
          className="border text-primary-dark font-medium p-6 text-left"
          key={index}
        >
          {it}
        </th>
      ))}
    </tr>
    {rows.map((rowData, indexRow) => (
      <tr key={indexRow} className="border">
        {rowData.map((data, indexData) => (
          <td className="border p-6 text-left text-body" key={indexData}>
            {data}
          </td>
        ))}
      </tr>
    ))}
  </table>
);

export const ReportBlock = ({ block }: { block: Block }) => (
  <div className="flex-col border rounded overflow-hidden pb-8">
    {block.type && (
      <div
        className="font-medium text-primary-dark mb-4 p-4 px-10 "
        style={{ backgroundColor: getBgColor(block.type) }}
      >
        {block.type}
      </div>
    )}
    {block.properties.map((it, index) => (
      <div key={index} className="px-8">
        <span className="text-primary-dark mr-8">{it.name}:</span>
        {it.value}
      </div>
    ))}
  </div>
);

const Section = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => (
  <div>
    <Label label={label} useDiv />
    {children}
  </div>
);

const LESS_IMPORTANT_SPANS = [
  "Integer",
  "IndefiniteQuantity",
  "QuasidefiniteQuantity",
  "Cardinals",
  "DateGranularity",
  "TimeDuration",
  "PotentialDay",
  "PotentialMonth",
  "PotentialYear",
  "WeekDay",
  "RelativeWeekDay",
  "PillActiveSubstance",
  "IUDActiveSubstance",
  "ContraceptionDetail",
  "Allergen",
  "PotentialMonthAlphabeticForm",
  "PotentialMonthNumericForm",
  "PotentialYearLongForm",
  "PotentialYearShortForm",
  "PillBrand",
  "IUDBrand",
  "VaginalRingBrand",
  "ContraceptiveImplantBrand",
  "ContraceptivePatchBrand",
  "MaleCondomBrand",
  "FemaleSterilizationBrand",
  "PotentialVaccinationDisease",
];

export const Report = ({
  wrapperClassName,
  report,
}: {
  wrapperClassName: string;
  report: ReportType;
}) => {
  const [detailsOpened, setDetailsOpened] = useState(false);
  const [showAllSpans, setShowAllSpans] = useState(false);
  const allEdges = report.acceptedEdges
    .map((it) => ({
      ...it,
      accepted: true,
    }))
    .concat(
      report.rejectedEdges.map((it) => ({
        ...it,
        accepted: false,
      })),
    );
  return (
    <div className={classNames(wrapperClassName, "flex-col space-y-24")}>
      <div className="flex-fill text-primary-dark font-bold text-18 truncate">
        Results
      </div>
      <Section label="Blocks">
        <div className="flex-wrap gap-12">
          {report.blocks.map((it, index) => (
            <ReportBlock block={it} key={index} />
          ))}
        </div>
      </Section>
      <button
        className="flex items-center text-primary-dark"
        onClick={() => setDetailsOpened(!detailsOpened)}
      >
        <Icon name="chevron" rotate={detailsOpened ? 90 : 0} />
        <div className="font-medium">Details</div>
      </button>
      {detailsOpened && (
        <div className="flex-col space-y-24 mx-20">
          <Section label="Spans">
            <ReportTable
              headers={["Span Type", "Spans"]}
              rows={report.spans
                .filter(
                  (it) =>
                    showAllSpans || !LESS_IMPORTANT_SPANS.includes(it.spanType),
                )
                .map((it) => [
                  <div key={`spanType-${it.spanType}`} style={{ width: 180 }}>
                    {it.spanType}
                  </div>,
                  <SpannedText
                    spannedText={it.spannedText}
                    key={`spans-${it.spanType}`}
                  />,
                ])}
            />
            <button
              className="mt-6 ml-4 underline"
              onClick={() => setShowAllSpans(!showAllSpans)}
            >
              Show remaining ({LESS_IMPORTANT_SPANS.length}) span type.
            </button>
          </Section>
          <Section label="Edges">
            <ReportTable
              headers={["Subject", "Predicate", "Object", "Score", "Accepted?"]}
              rows={allEdges
                .sortDesc((it) => it.score)
                .map((it, index) => [
                  <SpannedText
                    spannedText={it.subject}
                    key={`edge-${index}-subject`}
                  />,
                  <div style={{ width: 80 }} key={`edge-${index}-predicate`}>
                    {it.predicate}
                  </div>,
                  <SpannedText
                    spannedText={it.object}
                    key={`edge-${index}-object`}
                  />,
                  it.score.toFixed(2),
                  <div
                    key={`edge-${index}-status`}
                    className={classNames({ "text-success": it.accepted })}
                    style={{ width: 80 }}
                  >
                    {it.accepted ? "ACCEPTED" : "REJECTED"}
                  </div>,
                ])}
            />
          </Section>
          <Section label="All generated blocks">
            <div className="flex-wrap gap-12">
              {report.rawBlocks.map((it, index) => (
                <ReportBlock block={it} key={index} />
              ))}
            </div>
          </Section>

          {report.timings && (
            <Section label="Timings">
              <ReportTable
                headers={["step", "duration (ms)"]}
                rows={report.timings.map((it) => [
                  it.name,
                  it.durationMs.toString(),
                ])}
              />
            </Section>
          )}
        </div>
      )}
    </div>
  );
};
