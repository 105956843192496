import classNames from "classnames";

import { Avatar } from "components/Avatar/Avatar";
import { useLoadedExperience } from "contexts/Experience/ExperienceContext";
import { UserSummaryFragment } from "generated/provider";

import { useTypingIndicators } from "./useTypingIndicators";
import styles from "./typingIndicators.module.css";

export const TypingIndicators = () => {
  const { experience, scrollIfCloseToBottom } = useLoadedExperience();
  const { doctorsIndicators, patientsIndicators } = useTypingIndicators(
    experience,
    scrollIfCloseToBottom,
  );

  return (
    <>
      {doctorsIndicators.map(({ doctor }) => (
        <TypingIndicator
          key={doctor.uuid}
          user={doctor}
          isRight={
            experience.type === "PROVIDER_ONLY_CONVERSATION" ||
            experience.type === "MULTI_PATIENTS_CONVERSATION"
          }
        />
      ))}
      {patientsIndicators.map(({ patient }) => (
        <TypingIndicator key={patient.uuid} user={patient} />
      ))}
    </>
  );
};

const TypingIndicator = ({
  user,
  isRight,
}: {
  user: UserSummaryFragment;
  isRight?: boolean;
}) => (
  <div
    className={classNames("mt-8 flex items-center", {
      "self-end flex-row-reverse": isRight,
    })}
  >
    <Avatar user={user} size={28} className={isRight ? "ml-8" : "mr-8"} />
    <div className="bg-white rounded shadow p-14 flex items-center">
      <span className={styles.animatedDot} />
      <span className={styles.animatedDot} />
      <span className={styles.animatedDot} />
    </div>
  </div>
);
