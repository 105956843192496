import { ReactNode, RefObject, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { Flipped } from "react-flip-toolkit";

import { Maybe } from "base-types";
import { AvatarList } from "components/Avatar/AvatarList";
import { Button, ButtonProps } from "components/Button/Button";
import { Reset } from "components/Button/Reset";
import { Submit } from "components/Button/Submit";
import {
  SubmitItemProps,
  SubmitWithMenu,
} from "components/Button/SubmitWithMenu";
import { FaxListStatus } from "components/Fax/FaxListStatus";
import { FaxModal, SupportedFaxDocumentType } from "components/Fax/FaxModal";
import { Form, FormProps } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { MenuItemProps } from "components/Menu/MenuItem";
import { separator } from "components/Menu/MenuItemSeparator";
import { PopoverMenu } from "components/Menu/PopoverMenu";
import { RelativeTime } from "components/RelativeTime/RelativeTime";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { Wrapper } from "components/Wrapper/Wrapper";
import { useDoctor } from "contexts/User/UserContext";
import { DoctorSummaryFragment } from "generated/provider";
import { useDebounce } from "hooks/useDebounce";
import { useScrollIntoView } from "hooks/useScrollIntoView";
import { useStableCallback } from "hooks/useStableCallback";
import { useTranslation } from "i18n";
import {
  DistributiveOmit,
  Equals,
  FileMessageInput,
  SerializableRecord,
} from "types";
import {
  PatientTimelineKnownItemTypes,
  PatientTimelineValidItemFragmentOfType,
} from "types/patient-timeline";
import { run } from "utils";
import { displayDoctor } from "utils/display";

import { PatientViewTagPill } from "../PatientViewTagPill";
import {
  appearanceForExistingItem,
  appearanceForNewItem,
  PatientTimelineCreatableItemTypes,
} from "./Item/types";
import { usePatientTimelineLocalItemState } from "./PatientTimelineLocalStateContext";

/**
 * Base component for all items in the patient timeline.
 *
 * Don't use this component directly; instead, create a component per timeline
 * item type, for instance `PatientNoteItem`, which will wrap this component.
 * See https://reactjs.org/docs/composition-vs-inheritance.html.
 */
export type PatientTimelineItemProps<
  T extends PatientTimelineKnownItemTypes,
  Values extends Record<string, unknown> & { title: string },
  Draft extends SerializableRecord,
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
> = {
  item: PatientTimelineValidOrNewItemFragmentOfType<T>;
  assignedDoctors?: Maybe<DoctorSummaryFragment[]>;
  actionButton?: (
    | DistributiveOmit<ButtonProps, "sm" | "inline">
    | { node: ReactNode; loading?: false }
  ) & {
    mode:
      | "ALWAYS" // Button is always visible.
      | "HOVER"; // Button is only visible when hovering the card and in view mode.
  };
  additionalHeaderChildren?: ReactNode;
  additionalMenuItems?: Maybe<PatientTimelineMenuItem>[];
  withoutEditMenuItem?: boolean;
  footerChildren?: ReactNode;
  children?: ReactNode | ((editCount: number) => ReactNode);
  fixedSize?: boolean;
  sendFileAttachmentWarning?: boolean;
  getFileAttachment?: () => Promise<FileMessageInput> | FileMessageInput;
  withFaxOptions?: {
    documentType: SupportedFaxDocumentType;
    fileUuid?: UUID;
    getPreview?: (
      previewRef: RefObject<HTMLDivElement>,
      comment: string,
    ) => ReactNode;
  };
  submitButton?: { label: string };
} & ( // eslint-disable-next-line @typescript-eslint/ban-types
  | {} // Non-editable timeline items.
  | (Pick<
      // Editable timeline items.
      FormProps<Values, SubmitContext>,
      | "initialValues"
      | "onSubmit"
      | "validationSchema"
      | "validate"
      | "disabled"
    > & {
      additionalSubmitButtons?: SubmitItemProps<SubmitContext>[];
    } & (Equals<Values, Draft> extends true
        ? {
            toDraft?: (values: Values) => Draft;
            fromDraft?: (draft: Draft) => Values;
          }
        : {
            toDraft: (values: Values) => Draft;
            fromDraft: (draft: Draft) => Values;
          }))
);

type PatientTimelineValidOrNewItemFragmentOfType<
  T extends PatientTimelineKnownItemTypes,
> =
  | PatientTimelineValidItemFragmentOfType<T>
  | (T extends PatientTimelineCreatableItemTypes
      ? { temporaryUuid: UUID; __typename: T }
      : never);

export type PatientTimelineMenuItem =
  | (MenuItemProps & { disableWhenEditing?: string })
  | typeof separator;

export const PatientTimelineItem = <
  T extends PatientTimelineKnownItemTypes,
  Values extends Record<string, unknown> & { title: string },
  Draft extends SerializableRecord,
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  item,
  assignedDoctors,
  actionButton,
  additionalHeaderChildren,
  additionalMenuItems,
  withoutEditMenuItem,
  footerChildren,
  children,
  fixedSize,
  getFileAttachment,
  withFaxOptions,
  sendFileAttachmentWarning,
  ...otherProps
}: PatientTimelineItemProps<T, Values, Draft, SubmitContext>) => {
  const t = useTranslation();

  const isExisting = "uuid" in item;
  const uuid = isExisting ? item.uuid : item.temporaryUuid;
  const {
    iconName,
    iconColor,
    iconBackgroundColor,
    title,
    tag,
    timePrefix,
    statusLabel,
  } = isExisting
    ? appearanceForExistingItem(t, item)
    : appearanceForNewItem(t, item.__typename);

  const {
    itemState,
    closeItem,
    expandItem,
    startEditingItem,
    stopEditingItem,
    attachItemToMessage,
  } = usePatientTimelineLocalItemState(uuid);

  const isExpanded = !fixedSize && itemState.mode === "EXPANDED";
  const isEditable = "initialValues" in otherProps;
  const isEditing =
    isEditable && isExpanded && itemState.isEditing && !otherProps.disabled;
  const hasSentFaxes = "sentFaxes" in item;

  const initialDraft = useMemo(
    () => (isEditing ? (itemState.draft as Maybe<Draft>) : null),
    [isEditing], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const toDraft: (values: Values) => Draft =
    "toDraft" in otherProps && !!otherProps.toDraft
      ? otherProps.toDraft
      : (it) => it as unknown as Draft;
  const fromDraft: (draft: Draft) => Values =
    "fromDraft" in otherProps && !!otherProps.fromDraft
      ? otherProps.fromDraft
      : (it) => it as unknown as Values;

  const editMenuItem: Maybe<MenuItemProps> =
    !withoutEditMenuItem && isEditable && !isEditing && !otherProps.disabled
      ? {
          text: t("patient_view.item.edit"),
          icon: "edit",
          onClick: (closeMenu: () => void) => {
            closeMenu();
            expandItem();
            startEditingItem();
          },
        }
      : null;

  const sendMenuItem: Maybe<MenuItemProps> =
    !isEditing && getFileAttachment && attachItemToMessage
      ? {
          text: t("patient_view.item.send_attachment"),
          icon: "send",
          disable: {
            if: !!sendFileAttachmentWarning,
            tooltip: t("patient_view.item.send_attachment.disabled_tooltip"),
          },
          onClick: (closeMenu: () => void) => {
            closeMenu();
            Promise.resolve(getFileAttachment()).then((file) =>
              attachItemToMessage(file),
            );
          },
        }
      : null;
  const [showSendByFaxModal, setShowSendByFaxModal] = useState(false);
  const { user } = useDoctor();
  const sendByFaxMenuItem: Maybe<MenuItemProps> =
    !isEditing &&
    isExisting &&
    withFaxOptions &&
    user.subOrganization.organization.faxMonthlyThreshold > 0
      ? {
          text: t("patient_view.item.send_by_fax"),
          icon: "fax",
          onClick: (closeMenu: () => void) => {
            closeMenu();
            setShowSendByFaxModal(true);
          },
        }
      : null;

  const allMenuItems = [
    editMenuItem,
    sendMenuItem,
    sendByFaxMenuItem,
    ...(additionalMenuItems?.map((menuItem) => {
      if (!menuItem || menuItem === separator) return menuItem;
      const { disableWhenEditing, ...itemProps } = menuItem;
      return {
        ...itemProps,
        disable:
          isEditing && disableWhenEditing
            ? { if: true, tooltip: disableWhenEditing }
            : itemProps.disable,
      };
    }) ?? []),
  ].filterNotNull();

  // FIXME(@samhumeau): This is required because some form components currently
  //  have internal state, so we must re-render them every time the form content
  //  changes. This is ugly, but fixing the `FormNoteSectionsComposer` is going
  //  to take a bit of time in particular, so we'll live with it until then.
  const [editCount, setEditCount] = useState(0);
  const stopEditing = () => {
    stopEditingItem();
    setEditCount((i) => i + 1);
  };

  // Automatically scroll the item into the viewport if it is focused.
  const containerRef = useScrollIntoView<HTMLDivElement>(
    itemState.mode === "EXPANDED" && itemState.isFocused,
  );

  const actionButtonVisibility: "ALWAYS" | "ONLY_ON_HOVER" | "NEVER" = run(
    () => {
      if (!actionButton) return "NEVER";
      if (actionButton.loading) return "ALWAYS";
      if (actionButton.mode === "ALWAYS") return "ALWAYS";
      if (isEditing) return "NEVER";
      if (isExpanded) return "ALWAYS";
      return "ONLY_ON_HOVER";
    },
  );

  return (
    <Wrapper
      wrapper={(wrappedChildren) =>
        // For performance, only create a Formik state at the last minute.
        isEditable && isExpanded ? (
          <Form<Values, SubmitContext>
            onSubmit={otherProps.onSubmit}
            onSubmitEnd={stopEditing}
            onReset={stopEditing}
            validationSchema={otherProps.validationSchema}
            validate={otherProps.validate}
            disabled={!isEditing}
            enableReinitialize={!isEditing} // We don't want cache updates to change the form state while editing.
            initialValues={
              isEditing && initialDraft
                ? fromDraft(initialDraft)
                : otherProps.initialValues
            }
          >
            {isEditing && initialDraft && <DraftDirtySetter />}
            {isEditing && (
              <DraftSaver<Values, Draft> itemUuid={uuid} toDraft={toDraft} />
            )}

            {wrappedChildren}
          </Form>
        ) : (
          wrappedChildren
        )
      }
    >
      {showSendByFaxModal && withFaxOptions && isExisting && (
        <FaxModal
          onHide={() => setShowSendByFaxModal(false)}
          documentType={withFaxOptions.documentType}
          itemUuid={item.uuid}
          getPreview={withFaxOptions.getPreview}
          fileUuid={withFaxOptions.fileUuid}
        />
      )}
      <Flipped flipId={uuid} translate>
        {/* Compensates the margin on the container to have a full-width hover color. */}
        <div ref={containerRef} className="-mx-timeline-margin">
          <div
            className={classNames(
              "relative transition-[border-color,box-shadow] duration-100 ease-in-out",
              isExpanded
                ? [
                    "mx-timeline-opened-item-margin",
                    "rounded border shadow-light",
                    "-top-1 -mb-1", // Compensates the border size.
                  ]
                : [
                    "px-timeline-margin",
                    "hover:bg-[#F7F9FB]", // Transition would be too brutal with grey-100.
                    "border-transparent", // Makes the border-color transition possible.
                  ],
            )}
          >
            <div
              className={classNames(
                "group border-b pt-1 select-none",
                !isEditing && `${fixedSize ? "" : "cursor-pointer"}`,
                isExpanded &&
                  // Contents of the card header must not move when switching between the closed and expanded
                  // states, so we compensate the `timeline-opened-item-margin` margin and 1px for the border.
                  "px-[calc(theme(spacing.timeline-margin)-theme(spacing.timeline-opened-item-margin)-1px)]",
              )}
              onClick={() => {
                if (isEditing || fixedSize) return;
                isExpanded ? closeItem() : expandItem();
              }}
            >
              <div className="flex items-center h-64 space-x-16">
                <div
                  className="rounded-[11px] w-32 h-32 flex-center"
                  style={{
                    color: iconColor,
                    backgroundColor: iconBackgroundColor,
                  }}
                >
                  <Icon size={20} name={iconName} />
                </div>

                <div className="flex-col mr-auto flex-fill leading-[20px]">
                  <div className="flex items-center">
                    <h4
                      className={classNames(
                        "text-grey-400 truncate",
                        isEditing ? "flex-fill" : "flex-shrink",
                      )}
                    >
                      {isEditable ? (
                        isEditing ? (
                          <FormInput
                            inlineError="bottom"
                            name="title"
                            className="bg-transparent w-full !text-primary-dark"
                            onClick={(e) => e.stopPropagation()}
                          />
                        ) : (
                          otherProps.initialValues.title // FIXME after #24087.
                        )
                      ) : (
                        title
                      )}
                    </h4>
                    {tag && <PatientViewTagPill tag={tag} />}
                    {additionalHeaderChildren}
                  </div>
                  {isExisting && !isEditing && (
                    <time
                      className="text-12 truncate"
                      dateTime={item.patientTimelineItemMetadata.time.asString()}
                    >
                      {timePrefix !== undefined ? `${timePrefix} ` : ""}
                      <RelativeTime
                        time={item.patientTimelineItemMetadata.time}
                        format="timeOrDateWithTime"
                        withDatePrefix={timePrefix !== undefined}
                        upperFirst={timePrefix === undefined}
                      />
                      {statusLabel !== undefined ? `・${statusLabel} ` : ""}
                    </time>
                  )}
                </div>

                {actionButton && actionButtonVisibility !== "NEVER" && (
                  <div
                    className={classNames("flex ml-6 z-2", {
                      "hidden group-hover:flex":
                        actionButtonVisibility === "ONLY_ON_HOVER",
                    })}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {"node" in actionButton ? (
                      actionButton.node
                    ) : (
                      <Button
                        {...actionButton}
                        small
                        className={classNames(
                          actionButton.className,
                          "font-normal",
                        )}
                      />
                    )}
                  </div>
                )}

                <div className="flex-center">
                  {assignedDoctors?.isNotEmpty() && !isEditing && (
                    <TooltipWrapper
                      position="top"
                      className={classNames("mr-12", {
                        // Hide the avatar when the action button is shown on top.
                        "group-hover:hidden":
                          actionButtonVisibility === "ONLY_ON_HOVER",
                        hidden: actionButtonVisibility === "ALWAYS",
                      })}
                      label={`${t("patient_view.assigned_to")} ${assignedDoctors
                        .map((doctor) => displayDoctor(doctor))
                        .join(", ")}`}
                    >
                      <AvatarList users={assignedDoctors} size={26} />
                    </TooltipWrapper>
                  )}

                  {!isExisting || allMenuItems.isEmpty() ? (
                    <div className="m-6 w-20" />
                  ) : (
                    <PopoverMenu
                      position="bottom-right"
                      className="min-w-[180px]"
                      noArrow
                      items={allMenuItems}
                    >
                      {({ setTarget }) => (
                        <ClickableIcon
                          size={20}
                          name="more"
                          className="hover:opacity-80 p-4 hover:bg-grey-150 rounded"
                          tabIndex={isEditing ? -1 : 0}
                          onClick={setTarget}
                        />
                      )}
                    </PopoverMenu>
                  )}
                </div>
              </div>
            </div>

            {isExpanded && (
              <div>
                <div
                  className={classNames(
                    "overflow-x-auto animate-fade-in",
                    !isEditing && "cursor-default",
                  )}
                >
                  {typeof children === "function"
                    ? children(editCount)
                    : children}
                </div>

                {/* Footer */}
                {hasSentFaxes && item.sentFaxes.isNotEmpty() && (
                  <div
                    className={classNames(
                      "py-20 border-t -bottom-32 -mt-1 bg-white",

                      // Left of the card contents must be aligned with the title, so we need to account for the padding,
                      // the 32 pixels of the card icon, and the 16px of spacing between the icon and the title.
                      "px-[calc(theme(spacing.timeline-margin)-theme(spacing.timeline-opened-item-margin)-1px+32px+16px)]",
                      {
                        "rounded-b": !footerChildren && !isEditing,
                      },
                    )}
                  >
                    <FaxListStatus sentFaxes={item.sentFaxes} />
                  </div>
                )}
                {(isEditing || !!footerChildren) && (
                  <div
                    className={classNames(
                      "flex py-20 border-t sticky z-3 -bottom-32 -mt-1 bg-white rounded-b",

                      // Left of the card contents must be aligned with the title, so we need to account for the padding,
                      // the 32 pixels of the card icon, and the 16px of spacing between the icon and the title.
                      "px-[calc(theme(spacing.timeline-margin)-theme(spacing.timeline-opened-item-margin)-1px+32px+16px)]",
                    )}
                  >
                    {!!footerChildren && (
                      <div className="flex h-32 items-center flex-fill overflow-x-auto space-x-10">
                        {footerChildren}
                      </div>
                    )}

                    {isEditing && (
                      <div className="flex h-32 ml-auto space-x-10">
                        <Reset
                          small
                          label={t("patient_view.cancel")}
                          secondary
                          warnOnDirty
                        />
                        {otherProps.additionalSubmitButtons &&
                        otherProps.additionalSubmitButtons.length > 0 ? (
                          <SubmitWithMenu
                            label={
                              otherProps.submitButton?.label ??
                              t("patient_view.save")
                            }
                            requiresDirty={isExisting}
                            submitItems={otherProps.additionalSubmitButtons}
                          />
                        ) : (
                          <Submit
                            small
                            label={
                              otherProps.submitButton?.label ??
                              t("patient_view.save")
                            }
                            requiresDirty={isExisting}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Flipped>
    </Wrapper>
  );
};

const DraftSaver = <
  Values extends Record<string, unknown> & { title: string },
  Draft extends SerializableRecord,
>({
  itemUuid,
  toDraft,
}: {
  itemUuid: UUID;
  toDraft: (values: Values) => Draft;
}) => {
  const { updateItemDraft } = usePatientTimelineLocalItemState(itemUuid);
  const updateItemDraftStable = useStableCallback(updateItemDraft);
  const toDraftStable = useStableCallback(toDraft);

  const { values } = useFormikContext<Values>();
  const debouncedValues = useDebounce(values, 200);

  // Update the stored draft whenever the form state changes.
  useEffect(() => {
    updateItemDraftStable(
      toDraftStable(debouncedValues.withoutKey("__dirty") as Values),
    );
  }, [updateItemDraftStable, toDraftStable, debouncedValues]);

  return null;
};

// FIXME(@liautaud): This hack is used to flag the form as "dirty" whenever we
//  restore the initial values from a draft, so that the "Cancel" and "Save"
//  behave as expected. This would not be necessary if we didn't restore the
//  draft into `initialValues`, but used a call to `setValues` instead; but
//  this is not doable at the moment because `FormNoteSectionsComposer` has
//  some internal state, and thus behaves weirdly when `setValues` is called.
const DraftDirtySetter = () => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setFieldValue("__dirty", true);
  }, [setFieldValue]);

  return null;
};
