import { InputHTMLAttributes } from "react";
import ReactPhoneNumberInput, { Props } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useMaybeUser } from "contexts/User/UserContext";
import { run } from "utils";

import { ExposedInputWrapperProps, InputWrapper } from "./InputWrapper";

export type PhoneInputProps = ExposedInputWrapperProps &
  Props<InputHTMLAttributes<HTMLInputElement>>;

export const PhoneInput = ({
  wrapperClassName,
  label,
  error,
  hint,
  name,
  required,
  className,
  gray,
  thinGray,
  leftInnerElement,
  innerElement,
  rightInnerElement,
  rightInnerElementClassName,
  inlineError,
  ...props
}: PhoneInputProps) => {
  const maybeUserContext = useMaybeUser();
  return (
    <InputWrapper
      wrapperClassName={wrapperClassName}
      label={label}
      error={error}
      hint={hint}
      name={name}
      required={required}
      className={className}
      gray={gray}
      thinGray={thinGray}
      leftInnerElement={leftInnerElement}
      innerElement={innerElement}
      rightInnerElement={rightInnerElement}
      rightInnerElementClassName={rightInnerElementClassName}
      inlineError={inlineError}
    >
      {(fullClassName) => (
        <ReactPhoneNumberInput
          {...props}
          international={false}
          name={name}
          defaultCountry={run(() => {
            switch (maybeUserContext?.user?.locale) {
              case "ENGLISH":
                return "US";
              case "FRENCH":
                return "FR";
              case "PORTUGUESE":
                return "BR";
              default:
                return undefined;
            }
          })}
          className={fullClassName}
        />
      )}
    </InputWrapper>
  );
};
