import { OperationDefinitionNode } from "graphql/language/ast";

import { Mutation, Query, SchemaType } from "base-types";
import {
  AllTags,
  AppointmentCategories,
  AppointmentCategoryFragment,
  AppointmentTypeFragment,
  AppointmentTypes,
  CachedQAInbox,
  DoctorSearchFilter,
  DocumentTemplateFragment,
  DocumentTemplates,
  ExperienceSummaryFragment,
  GetDoctorsConversations,
  GetGroupConversations,
  GetMLModels,
  GetNoteTemplate,
  GetPatientInformation,
  GetPatientViewDoneItems,
  GetPatientViewTodoItems,
  GetTemplates,
  MedicalLicenseFragment,
  MedicalLicenses,
  MLModelFragment,
  NoteSectionNormalizationFragment,
  NoteSectionNormalizations,
  NoteSectionNormalizationsVariables,
  PatientManagementPatientFragment,
  PatientManagementSearch,
  PatientNoteTemplateFragment,
  PatientTimelineEventFragment,
  PatientTimelineItemFragment,
  PatientTimelinePillType,
  PrescriptionTemplateFragment,
  PrescriptionTemplates,
  QAExperienceFragment,
  SentFaxFragment,
  TagFragment,
  TemplateFragment,
} from "generated/provider";
import {
  GraphQLClient,
  KnownUnionValue,
  possibleTypes,
  PossibleTypes,
} from "types/apollo";
import { Nullable } from "types/utils";

export const isKnownUnionValue = <
  T extends keyof PossibleTypes,
  U extends Nullable<{ __typename: PossibleTypes[T] | "FutureValue" }>,
>(
  unionName: T,
  object: U,
): object is KnownUnionValue<U> =>
  object
    ? (possibleTypes[unionName] as string[]).includes(object.__typename)
    : false;

export const getKnownUnionValue = <
  Union extends keyof PossibleTypes,
  Element extends Nullable<{
    __typename: PossibleTypes[Union] | "FutureValue";
  }>,
>(
  unionName: Union,
  o: Element,
): KnownUnionValue<Element> | null =>
  isKnownUnionValue(unionName, o) ? o : null;

export const knownUnionValueFilter =
  <
    Union extends keyof PossibleTypes,
    Element extends Nullable<{
      __typename: PossibleTypes[Union] | "FutureValue";
    }>,
  >(
    unionName: Union,
  ) =>
  (o: Element): o is KnownUnionValue<Element> =>
    isKnownUnionValue(unionName, o);

export const getOperationName = (
  queryOrMutation:
    | Query<unknown, unknown, unknown, SchemaType>
    | Mutation<unknown, unknown, SchemaType>,
) =>
  queryOrMutation.document.definitions.find(
    (definition): definition is OperationDefinitionNode =>
      definition.kind === "OperationDefinition",
  )?.name?.value;

export const doctorsToChatWithFilter: DoctorSearchFilter = {
  permissions: ["CHAT_WITH_DOCTORS"],
};

export const addQAInboxItemInCache = (
  client: GraphQLClient,
  items: QAExperienceFragment[],
) => {
  client.update({
    query: CachedQAInbox,
    // Important check because the `updateCache -> apolloClient.writeQuery` is
    // very slow even when there is no new experiences to add to the cache
    skip: (current) => {
      const currentUuids = new Set(current.mapNotNull((item) => item.uuid));
      return items.every((item) => currentUuids.has(item.uuid));
    },
    write: (draft) => items.concat(draft).distinctBy((it) => it.uuid),
  });
};

export const addGroupConversationItemInCache = (
  client: GraphQLClient,
  experience: QAExperienceFragment,
) => {
  client.update({
    query: GetGroupConversations,
    skip: ({ data: experiences }) =>
      experiences.some((e) => e?.uuid === experience.uuid),
    write: ({ data: experiences }) => {
      experiences.unshift(experience);
    },
  });
};

export const addDoctorConversationInCache = (
  client: GraphQLClient,
  experience: ExperienceSummaryFragment,
) => {
  client.update({
    query: GetDoctorsConversations,
    skip: ({ experiences }) =>
      experiences.data.some((e) => e?.uuid === experience.uuid),
    write: ({ experiences }) => {
      experiences.data.unshift(experience);
    },
  });
  client.evictQuery("doctorExperienceSearch", "freeTextSearch");
};

export const addTemplateInCache = (
  client: GraphQLClient,
  template: TemplateFragment,
) => {
  client.update({
    query: GetTemplates,
    variables: { type: template.type },
    skip: ({ templates }) => templates.some((r) => r.uuid === template.uuid),
    write: (draft) => {
      draft.templates.push(template);
    },
  });
  client.evictQuery("mixedQaInboxSearch", "ALL");
};

export const addAppointmentCategoryInCache = (
  client: GraphQLClient,
  appointmentCategory: AppointmentCategoryFragment,
) => {
  client.update({
    query: AppointmentCategories,
    skip: (appointmentCategories) =>
      appointmentCategories.some((a) => a.uuid === appointmentCategory.uuid),
    write: (draft) => {
      draft.unshift(appointmentCategory);
    },
  });
};

export const removeAppointmentCategoryInCache = (
  client: GraphQLClient,
  appointmentCategoryUuid: UUID,
) => {
  client.update({
    query: AppointmentCategories,
    write: (draft) => draft.filter((a) => a.uuid !== appointmentCategoryUuid),
  });
};

export const addNoteTemplateInCache = (
  client: GraphQLClient,
  noteTemplate: PatientNoteTemplateFragment,
) => {
  client.update({
    query: GetNoteTemplate,
    write: (draft) => {
      draft.template = noteTemplate;
    },
  });
};

export const addAppointmentTypeInCache = (
  client: GraphQLClient,
  appointmentType: AppointmentTypeFragment,
) => {
  client.update({
    query: AppointmentTypes,
    skip: (appointmentTypes) =>
      appointmentTypes.some((a) => a.uuid === appointmentType.uuid),
    write: (draft) => {
      draft.unshift(appointmentType);
    },
  });
};

export const removeAppointmentTypeInCache = (
  client: GraphQLClient,
  appointmentTypeUuid: UUID,
) => {
  client.update({
    query: AppointmentTypes,
    write: (draft) => draft.filter((a) => a.uuid !== appointmentTypeUuid),
  });
};

export const addPrescriptionTemplateInCache = (
  client: GraphQLClient,
  prescriptionTemplate: PrescriptionTemplateFragment,
) => {
  client.update({
    query: PrescriptionTemplates,
    skip: (templates) =>
      templates.some((a) => a.uuid === prescriptionTemplate.uuid),
    write: (draft) => {
      draft.unshift(prescriptionTemplate);
    },
  });
};

export const removePrescriptionTemplateInCache = (
  client: GraphQLClient,
  prescriptionTemplateUuid: UUID,
) => {
  client.update({
    query: PrescriptionTemplates,
    write: (draft) => draft.filter((a) => a.uuid !== prescriptionTemplateUuid),
  });
};

export const addDocumentTemplateInCache = (
  client: GraphQLClient,
  documentTemplate: DocumentTemplateFragment,
) => {
  client.update({
    query: DocumentTemplates,
    skip: (templates) =>
      templates.some((a) => a.uuid === documentTemplate.uuid),
    write: (draft) => {
      draft.unshift(documentTemplate);
    },
  });
};

export const removeDocumentTemplateInCache = (
  client: GraphQLClient,
  documentTemplateUuid: UUID,
) => {
  client.update({
    query: DocumentTemplates,
    write: (draft) => draft.filter((a) => a.uuid !== documentTemplateUuid),
  });
};

export const addMedicalLicenseInCache = (
  client: GraphQLClient,
  medicalLicense: MedicalLicenseFragment,
) => {
  client.update({
    query: MedicalLicenses,
    skip: (me) =>
      me.doctor.medicalLicenses.some((a) => a.uuid === medicalLicense.uuid),
    write: (draft) => {
      draft.doctor.medicalLicenses.unshift(medicalLicense);
    },
  });
};

export const removeMedicalLicenseFromCache = (
  client: GraphQLClient,
  medicalLicenseUuid: UUID,
) => {
  client.update({
    query: MedicalLicenses,
    write: (draft) => {
      draft.doctor.medicalLicenses = draft.doctor.medicalLicenses.filter(
        (l) => l.uuid !== medicalLicenseUuid,
      );
    },
  });
};

export const addMLModelInCache = (
  client: GraphQLClient,
  model: MLModelFragment,
  addAtTheBeginning: boolean,
) => {
  client.update({
    query: GetMLModels,
    variables: {},
    skip: ({ data: models }) => models.some((m) => m.uuid === model.uuid),
    write: (draft) => {
      if (addAtTheBeginning) {
        draft.data.unshift(model);
      } else {
        draft.data.push(model);
      }
    },
  });
};

export const addPatientTimelineItemIntoCache = (
  client: GraphQLClient,
  patientUuid: UUID,
  item: PatientTimelineEventFragment,
) => {
  client.update({
    query: GetPatientInformation,
    variables: { patientUuid, filter: {} },
    skip: ({ patient }) =>
      patient.patientTimeline.data.data.some(
        (event) => event?.uuid === item.uuid,
      ),
    write: ({ patient }) => {
      patient.patientTimeline.data.data.unshift(item);
    },
  });
  client.update({
    query: GetPatientInformation,
    variables: {
      patientUuid,
      filter: { itemTypesToShow: getRelevantTimelineFilter(item) },
    },
    skip: ({ patient }) =>
      patient.patientTimeline.data.data.some(
        (event) => event?.uuid === item.uuid,
      ),
    write: ({ patient }) => {
      patient.patientTimeline.data.data.unshift(item);
    },
  });
};

const getRelevantTimelineFilter = (
  item: PatientTimelineEventFragment,
): PatientTimelinePillType | null => {
  if (!isKnownUnionValue("EhrEventContent", item.content)) return null;

  switch (item.content.__typename) {
    case "DocumentCreated":
      return "DOCUMENTS";
    case "NoteCreated":
      return "NOTES";
    case "ExperienceCreated":
      return "HISTORY_OR_COMMENTS";
    case "VaccineCreated":
      return "PREVENTION";
    case "SymptomCreated":
      return null;
    case "ConditionCreatedOrAccepted":
    case "PatientAllergyCreatedOrAccepted":
      return "PMH";
    case "DataPointCreated":
      return "OBSERVATIONS";
    case "ContraceptionCreatedOrApproved":
    case "MedicationCreatedOrAccepted":
      return "MEDICATION";
    case "ProcedureCreatedOrAccepted":
      return "PMH";
    case "TaskCreated":
      return "TASK";
  }
};

export const removeExperienceTagTypeFromCache = (
  client: GraphQLClient,
  experienceTagTypeUuid: UUID,
) => {
  const experienceTagUuidsToRemove: UUID[] = [];
  client.update({
    query: CachedQAInbox,
    write: (draft) => {
      draft.mapNotNull((item) => {
        item.tags = item.tags.mapNotNull((experienceTag) => {
          if (experienceTag.type.uuid === experienceTagTypeUuid) {
            experienceTagUuidsToRemove.push(experienceTag.uuid);
            return null;
          }
          return experienceTag;
        });
        return item;
      });
    },
  });
  experienceTagUuidsToRemove.forEach((uuid) =>
    client.remove("ExperienceTag", uuid),
  );
  client.remove("TagType", experienceTagTypeUuid);
};

export const removePatientFromCache = (
  client: GraphQLClient,
  patientUuid: UUID,
) => {
  client.update({
    query: CachedQAInbox,
    write: (draft) =>
      draft.mapNotNull((item) => {
        if (item.patient?.uuid === patientUuid) return null;
        return item;
      }),
  });
  client.remove("Patient", patientUuid);
};

export const addExperienceTagTypeInCache = (
  client: GraphQLClient,
  tag: TagFragment,
) => {
  client.update({
    query: AllTags,
    skip: ({ tags }) => tags.some((t) => t.uuid === tag.uuid),
    write: ({ tags }) => {
      tags.unshift(tag);
    },
  });
};

export const addPatientInCache = (
  client: GraphQLClient,
  patient: PatientManagementPatientFragment,
) => {
  client.update({
    query: PatientManagementSearch,
    variables: {
      search: undefined,
      withExperiences: false,
      withAppointments: false,
    },
    skip: ({ patients }) => patients.data.some((p) => p.uuid === patient.uuid),
    write: ({ patients }) => {
      patients.data.unshift({
        ...patient,
        qaExperiences: { __typename: "ExperienceSearchData", data: [] },
        upcomingAppointments: [],
        pastAppointments: [],
      });
    },
  });
};

const addSentFaxInTimelineItemCache = (
  timelineItemUuid: UUID,
  fax: SentFaxFragment,
  data: PatientTimelineItemFragment[],
) => {
  const updatedItem = data.find(
    (element) =>
      [
        "Appointment",
        "PatientDocument",
        "PatientNote",
        "NablaPrescription",
        "MedicalOrder",
      ].includes(element.__typename) &&
      "uuid" in element &&
      element.uuid === timelineItemUuid,
  );
  if (updatedItem && "sentFaxes" in updatedItem) {
    updatedItem.sentFaxes.push(fax);
  }
};

export const addSentFaxInTodoAndDoneTimelineItemCache = (
  client: GraphQLClient,
  patientUuid: UUID,
  timelineItemUuid: UUID,
  fax: SentFaxFragment,
) => {
  client.update({
    query: GetPatientViewTodoItems,
    variables: {
      patientUuid,
    },
    write: ({ data }) => {
      addSentFaxInTimelineItemCache(timelineItemUuid, fax, data);
    },
  });
  client.update({
    query: GetPatientViewDoneItems,
    variables: {
      patientUuid,
    },
    write: ({ data }) => {
      addSentFaxInTimelineItemCache(timelineItemUuid, fax, data);
    },
  });
};

export const addCreatedNoteNormalizationInCache = (
  client: GraphQLClient,
  noteSectionNormalization: NoteSectionNormalizationFragment,
  queryVariables: NoteSectionNormalizationsVariables,
) => {
  client.update({
    query: NoteSectionNormalizations,
    variables: queryVariables,
    skip: ({ noteSectionNormalizations: { data } }) =>
      data.some((n) => n.uuid === noteSectionNormalization.uuid),
    write: ({ noteSectionNormalizations: { data } }) => {
      data.unshift(noteSectionNormalization);
    },
  });
};
