import { createContext, RefObject, useContext } from "react";

import { useDraft } from "atoms/useDraft";
import {
  MessageContentInput,
  SendingItem,
} from "contexts/Messages/MessagesContext";
import {
  ExperienceContentFragment,
  ExperienceType,
  MessageFragment,
  ScheduleMessagesFragment,
  TimelineItemFragment,
} from "generated/provider";
import {
  FileMessageInput,
  KnownUnionValue,
  ReplyMessage,
  UndefinedFields,
} from "types";

import { useMessagesContainerUtils } from "./useMessagesContainerUtils";

export type DoctorMessageActionsType = (props: {
  message: MessageFragment;
}) => JSX.Element | null;

export type KnownTimelineItem = KnownUnionValue<TimelineItemFragment>;

type LoadedExperienceContextValue<
  Experience extends ExperienceContentFragment,
> = {
  items: KnownTimelineItem[];
  hasMoreItems: boolean;
  scheduledMessages: ScheduleMessagesFragment["scheduledMessages"];
  experience: Omit<Experience, "itemsV3" | "scheduledMessages">;
};

type ExistingExperienceBase = {
  sendMessage: (content: MessageContentInput, batchId?: UUID) => void;
  sendingItems: SendingItem[];
  retrySendMessage: (localUuid: UUID) => void;
  deleteFailedMessage: (localUuid: UUID) => void;
};

type ExperienceContextValue<Experience extends ExperienceContentFragment> = {
  draftKey: string;
  type: ExperienceType;
  DoctorMessageActions: DoctorMessageActionsType | undefined;
  textAreaRef: RefObject<HTMLTextAreaElement>;
  readonly: boolean;
  isInExperience: boolean;
  loadingMoreItems: boolean;
  loadMoreItemsIfPossible: () => void;
  replyMessage: ReplyMessage | undefined;
  setReplyMessage: (replyMessage: ReplyMessage | undefined) => void;
} & (
  | ({
      // Loaded experiences.
      uuid: UUID;
      isLoaded: true;
    } & ExistingExperienceBase &
      LoadedExperienceContextValue<Experience>)
  | ({
      // Loading experiences.
      uuid: UUID;
      isLoaded: false;
    } & ExistingExperienceBase &
      UndefinedFields<LoadedExperienceContextValue<Experience>>)
  | ({
      // "Lazy" experiences.
      uuid: undefined;
      isLoaded: false;
    } & UndefinedFields<LoadedExperienceContextValue<Experience>>)
) &
  ReturnType<typeof useMessagesContainerUtils>;

type ExistingExperienceContextValue<
  Experience extends ExperienceContentFragment,
> = Extract<ExperienceContextValue<Experience>, { uuid: UUID }>;

export const ExperienceContext =
  createContext<ExperienceContextValue<ExperienceContentFragment> | null>(null);
ExperienceContext.displayName = "ExperienceContext";

export const useMaybeExperience = <
  Experience extends ExperienceContentFragment,
>() => {
  const context = useContext(ExperienceContext);
  if (!context) throw new Error("ExperienceProvider not available");
  return context as ExperienceContextValue<Experience>;
};

export const useExperience = <
  Experience extends ExperienceContentFragment,
>() => {
  const context = useContext(ExperienceContext);
  if (!context) throw new Error("ExperienceProvider not available");
  if (!("uuid" in context)) throw new Error("Experience is not yet created");
  return context as ExistingExperienceContextValue<Experience>;
};

export const useLoadedExperience = <
  Experience extends ExperienceContentFragment,
>() => {
  const context = useExperience<Experience>();
  if (!context.isLoaded) throw new Error("Experience not loaded");
  return context;
};

export const useExperienceDraft = (defaultMessageText?: string) => {
  const { draftKey } = useMaybeExperience();
  const { store, setDraft } = useDraft();

  const messageText = store[draftKey]?.messageText ?? defaultMessageText ?? "";

  return {
    messageText,
    clearDraft: () =>
      setDraft(draftKey, {
        messageText: "",
        medias: [],
        livekitMessageInput: false,
        questionsSet: [],
      }),
    setMessageText: (text: string) => setDraft(draftKey, { messageText: text }),
    mediaMessages: store[draftKey]?.medias ?? [],
    setMediaMessages: (medias: FileMessageInput[]) =>
      setDraft(draftKey, { medias }),
    livekitRoomInput: store[draftKey]?.livekitMessageInput ?? false,
    setLivekitRoomMessageInput: (b: boolean) =>
      setDraft(draftKey, { livekitMessageInput: b }),
    questionsSet: store[draftKey]?.questionsSet ?? [],
    setQuestionsSet: (questionsSet: string[]) =>
      setDraft(draftKey, { questionsSet }),
  };
};
