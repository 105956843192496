/**
 * Automatically re-rendering relative time string.
 * See the documentation for `ISOString.format`.
 */
import { RelativeTimeFormat } from "extensions/date";
import { useRerenderAt } from "hooks/useRerender";
import { now } from "utils/date";

export const RelativeTime = ({
  time,
  format,
  withDatePrefix,
  upperFirst,
}: {
  time: ISOString;
  format: RelativeTimeFormat;
  withDatePrefix?: boolean; // Adds "le", "dia" (in pt) before non-relative dates.
  upperFirst?: boolean;
}) => {
  const signedOffsetSeconds = (new Date().getTime() - time.getTime()) / 1000;
  useRerenderAt(now().plusSeconds(nextChangeIn(signedOffsetSeconds) + 1));
  const formattedTime = time.format({ relative: format, withDatePrefix });
  return <>{upperFirst ? formattedTime.upperFirst() : formattedTime}</>;
};

// Returns in how many seconds the formatted text will change.
const nextChangeIn = (signedOffset: number): number => {
  if (signedOffset < -3600) return Math.abs(signedOffset) % 3600;
  if (signedOffset < 0) return Math.abs(signedOffset) % 60;
  if (signedOffset < 3600) return 60 - (signedOffset % 60);
  return 3600 - (signedOffset % 3600);
};
