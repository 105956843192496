import { useState } from "react";
import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Button } from "components/Button/Button";
import { NextPageButton } from "components/Button/NextPageButton";
import { MenuItemProps } from "components/Menu/MenuItem";
import {
  ControlledConfirmationModal,
  ControlledConfirmationModalProps,
} from "components/Modal/ControlledConfirmationModal";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Spinner } from "components/Spinner/Spinner";
import { Table } from "components/Table/Table";
import { useDoctor } from "contexts/User/UserContext";
import {
  DeleteDemoPatients,
  DeletePatient,
  HasDemoPatients,
  PatientManagementPatientFragment,
  PatientManagementSearch,
  RevokeAllPatientSessions,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { usePaginatedQuery } from "graphql-client/usePaginatedQuery";
import { useQuery } from "graphql-client/useQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { copyToClipBoard } from "utils";
import { removePatientFromCache } from "utils/apollo";
import { displayPatient } from "utils/display";
import {
  canOpenOrganizationExternalConsole,
  tryToOpenOrganizationExternalConsole,
} from "utils/externalNavigation";
import { notifier } from "utils/notifier";

import { PatientAperoForm } from "./PatientAperoForm";
import { PatientComposer } from "./PatientComposer";

gql`
  query PatientManagementSearch(
    $search: String
    $cursor: DateTime
    $withExperiences: Boolean!
    $withAppointments: Boolean!
  ) {
    patientSearch(filter: { search: $search }) {
      totalCount
      patients(page: { numberOfItems: 20, from: $cursor }) {
        data {
          ...PatientManagementPatient
          qaExperiences @include(if: $withExperiences) {
            data {
              ...QAExperience
            }
          }
          upcomingAppointments(pagination: { numberOfItems: 1 })
            @include(if: $withAppointments) {
            uuid
          }
          pastAppointments(pagination: { numberOfItems: 1 })
            @include(if: $withAppointments) {
            uuid
          }
        }
        hasMore
        nextCursor
      }
    }
  }

  query HasDemoPatients {
    me {
      doctor {
        uuid
        subOrganization {
          uuid
          hasDemoPatients
        }
      }
    }
  }

  mutation DeleteDemoPatients {
    deleteDemoPatients {
      subOrganization {
        uuid
        hasDemoPatients
      }
      deletedPatientUuids
    }
  }

  fragment PatientManagementPatient on Patient {
    ...PatientSummary
    birthDate
    phoneV2
    email
    createdAt
    sex
    healthcareSystemIdentifier
    address
    postcode
    country
    city
    state
    externalId
    devices {
      uuid
      sdkModules
      os
    }
  }

  mutation DeletePatient($uuid: UUID!) {
    deletePatient(input: { uuid: $uuid }) {
      status
    }
  }

  mutation RevokeAllPatientSessions($patientUuid: UUID!) {
    revokeAllPatientSessions(patientUuid: $patientUuid) {
      patient {
        uuid
      }
    }
  }
`;

type ComposerState = { mode: "create" } | { mode: "edit"; patientUuid: UUID };
type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide">
  | undefined;

type AperoDetailsModalState =
  | { patient: PatientManagementPatientFragment }
  | undefined;

export const AdminPatientsList = ({
  isPatientAdded,
  onPatientAdded,
  search,
}: {
  isPatientAdded: boolean;
  onPatientAdded: () => void;
  search: string | undefined;
}) => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const { user } = useDoctor();
  const {
    data: patientsData,
    loading,
    nextPage,
    fetchingMore,
  } = usePaginatedQuery(PatientManagementSearch, {
    variables: {
      search,
      withExperiences: false,
      withAppointments: false,
    },
    selector: (data) => data.patients,
  });
  const [composerState, setComposerState] = useState<ComposerState>();
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>();

  const [aperoDetailsModalState, setAperoDetailsModalState] =
    useState<AperoDetailsModalState>(undefined);

  const [showingDetails, setShowingDetails] = useState(false);
  const [deletePatient] = useMutation(DeletePatient);
  const [revokePatientSessions] = useMutation(RevokeAllPatientSessions);

  const { data: hasDemoPatientsData } = useQuery(HasDemoPatients);
  const [deleteDemoPatients] = useMutation(DeleteDemoPatients);

  return (
    <div>
      {composerState && (
        <PatientComposer
          patient={
            composerState.mode === "edit"
              ? patientsData?.patients.data.find(
                  (patient) => patient.uuid === composerState.patientUuid,
                )
              : undefined
          }
          onHide={() => setComposerState(undefined)}
        />
      )}
      {isPatientAdded && (
        <PatientComposer patient={undefined} onHide={onPatientAdded} />
      )}
      {confirmationModalState && (
        <ControlledConfirmationModal
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      {aperoDetailsModalState && (
        <PatientAperoForm
          patient={aperoDetailsModalState.patient}
          onHide={() => setAperoDetailsModalState(undefined)}
        />
      )}
      <div className="flex items-center">
        {!showingDetails && (
          <>
            {hasDemoPatientsData?.doctor.subOrganization.hasDemoPatients && (
              <DeletionConfirmationModal
                suffix={t("patients.delete_demo_patients")}
                onConfirm={(close: () => void) =>
                  deleteDemoPatients(
                    {},
                    {
                      onSuccess: (output, client) => {
                        notifier.success(t("patients.demo_patients_deleted"));
                        output.deletedPatientUuids.forEach((uuid) =>
                          removePatientFromCache(client, uuid),
                        );
                        close();
                      },
                    },
                  )
                }
              >
                {(show) => (
                  <Button
                    label={t("patients.delete_demo_patients")}
                    onClick={() => show()}
                    className={isDesktop ? "ml-12" : "mt-12"}
                  />
                )}
              </DeletionConfirmationModal>
            )}
          </>
        )}
      </div>
      <div className="mt-12">
        {loading ? (
          <Spinner />
        ) : patientsData ? (
          <Table
            elements={patientsData.patients.data}
            onShowDetails={(show) => setShowingDetails(show)}
            fieldHeaders={[
              t("patients.patients.last_name"),
              t("patients.patients.first_name"),
              t("patients.patients.date_of_birth"),
              t("patients.patients.phone"),
              t("patients.patients.username"),
              t("patients.patients.created_at"),
            ]}
            fields={(p) => [
              <div key="gender" className="min-w-[120px]">
                {p.lastName}
              </div>,
              <div key="gender" className="min-w-[120px]">
                {p.firstName}
              </div>,
              <div key="birthdate" className="min-w-[140px]">
                {p.birthDate !== null
                  ? p.birthDate.formatDateInLocale()
                  : t("patients.patients.unknown")}
              </div>,
              <div key="phone" className="min-w-[120px]">
                {p.phoneV2}
              </div>,
              <div key="gender" className="min-w-[120px]">
                {p.username}
              </div>,
              <div key="createdAt" className="min-w-[140px]">
                {p.createdAt.format("date")}
              </div>,
            ]}
            menuItems={(p) => {
              const editOption: MenuItemProps = {
                icon: "edit",
                text: t("patients.patients.edit"),
                onClick: (close: () => void) => {
                  close();
                  setComposerState({ mode: "edit", patientUuid: p.uuid });
                },
              };
              const externalConsoleUrl =
                user.subOrganization.externalConsoleUrl;
              const linkToOrgBackOffice: Maybe<MenuItemProps> =
                externalConsoleUrl
                  ? {
                      icon: "shareLight",
                      text: t("patients.open_back_office", {
                        name: user.subOrganization.organization.displayName,
                      }),
                      disable: {
                        if: !canOpenOrganizationExternalConsole(
                          externalConsoleUrl,
                          p,
                        ),
                        tooltip: t("patients.missing_external_id"),
                      },
                      onClick: () =>
                        tryToOpenOrganizationExternalConsole(
                          externalConsoleUrl,
                          p,
                        ),
                    }
                  : null;
              const copyOption: Maybe<MenuItemProps> = {
                icon: "clipboard",
                text: t("patients.patients.copy_id"),
                onClick: (close: () => void) => {
                  close();
                  copyToClipBoard(p.uuid, t("patients.patients.id_copied"));
                },
              };
              const deleteOption: Maybe<MenuItemProps> = {
                icon: "trash",
                text: t("patients.patients.harddelete"),
                className: "text-danger",
                onClick: (close: () => void) => {
                  close();
                  setConfirmationModalState({
                    cta: {
                      label: t("patients.harddelete"),
                      danger: true,
                    },
                    children: t("patients.patients.haddelete_confirmation", {
                      patient: displayPatient(p),
                    }),
                    onConfirm: () =>
                      deletePatient(
                        { uuid: p.uuid },
                        {
                          onSuccess(_, client) {
                            removePatientFromCache(client, p.uuid);
                            setConfirmationModalState(undefined);
                            notifier.success(
                              t("patients.patients.patient_deleted"),
                            );
                          },
                        },
                      ),
                  });
                },
              };
              const revokeSessionsOption: Maybe<MenuItemProps> = {
                icon: "logout",
                text: t("patients.patients.revoke_sessions"),
                className: "text-danger",
                onClick: (close: () => void) => {
                  close();
                  setConfirmationModalState({
                    cta: {
                      label: t("patients.patients.revoke_sessions"),
                      danger: true,
                    },
                    children: t(
                      "patients.patients.revoke_sessions_confirmation",
                      {
                        patient: displayPatient(p),
                      },
                    ),
                    onConfirm: () =>
                      revokePatientSessions(
                        { patientUuid: p.uuid },
                        {
                          onSuccess() {
                            setConfirmationModalState(undefined);
                            notifier.success(
                              t("patients.patients.sessions_revoked"),
                            );
                          },
                        },
                      ),
                  });
                },
              };
              const addAperoDetails: Maybe<MenuItemProps> = user.subOrganization
                .supportsApero
                ? {
                    icon: "dollar",
                    text: t("patients.add_apero_details"),
                    onClick: (close: () => void) => {
                      close();
                      const maybePatient = patientsData.patients.data.find(
                        (patient) => patient.uuid === p.uuid,
                      );
                      maybePatient &&
                        setAperoDetailsModalState({ patient: maybePatient });
                    },
                  }
                : null;

              return [
                linkToOrgBackOffice,
                editOption,
                copyOption,
                revokeSessionsOption,
                deleteOption,
                addAperoDetails,
              ].filterNotNull();
            }}
          />
        ) : null}
      </div>
      <div className="flex">
        <NextPageButton nextPage={nextPage} fetchingMore={fetchingMore} />
        {patientsData && !showingDetails && (
          <div className="ml-auto my-16">
            {patientsData.totalCount} {t("patients.patients.patients")}
          </div>
        )}
      </div>
    </div>
  );
};
