import { useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import SignaturePad from "signature_pad";

import { Button } from "components/Button/Button";
import {
  FormDropzone,
  FormDropzoneValue,
} from "components/Form/Dropzone/FormDropzone";
import { Label } from "components/Form/Label/Label";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Modal } from "components/Modal/Modal";
import { useField } from "hooks/useField";
import { useTranslation } from "i18n";
import { localURL } from "utils/file";

export const FormSignature = ({ label }: { label: string }) => {
  const t = useTranslation();
  const [
    { value: valueSvg, disabled: disabledField },
    { error: errorSvg },
    { setValue: setValueSvg },
  ] = useField<string | undefined>({
    name: "signatureSvg",
  });

  const [
    { value: valueFile },
    { error: errorFile },
    { setValue: setValueFile },
  ] = useField<FormDropzoneValue>({
    name: "signatureFile",
  });

  const fileUrl = valueFile.upload?.let((it) =>
    "state" in it ? localURL(it.file) : it.urlV2.url,
  );

  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  return (
    <>
      {openSignatureModal && (
        <ModalSignature
          hide={() => setOpenSignatureModal(false)}
          onSignatureSvgChanged={(svg) => {
            if (!svg && !valueFile.upload) {
              return Error("Either an svg or a file is required");
            }
            if (svg) {
              setValueSvg(svg);
              // In that case, we reset the uploaded file
              setValueFile({ purpose: "SIGNATURE", upload: undefined });
            } else {
              setValueSvg(undefined);
            }
          }}
        />
      )}
      <div className="flex">
        <div className="flex-col">
          <div className="flex justify-between">
            {label && <Label name="signatureSvg" label={`${label}*`} />}
            {errorSvg && <span className="text-danger">{errorSvg}</span>}
            {errorFile && <span className="text-danger">{errorFile}</span>}
          </div>
          <div className="flex items-center space-x-10">
            <div
              className={classNames("border rounded-full flex items-center", {
                "border-danger": errorFile ?? errorSvg,
              })}
              style={{ height: 80, width: 80 }}
            >
              {valueSvg ? (
                <img
                  src={valueSvg}
                  alt="signature"
                  className="object-contain"
                />
              ) : fileUrl ? (
                <img
                  src={fileUrl}
                  alt="signature"
                  className="overflow-hidden h-full w-full object-contain rounded-full"
                />
              ) : null}
            </div>
            <button
              type="button"
              className="rounded border font-medium p-8"
              disabled={disabledField}
              onClick={() => {
                setOpenSignatureModal(true);
              }}
            >
              {valueSvg || valueFile.upload
                ? t("preferences.info.form_signature.change_your_signature")
                : t("preferences.info.form_signature.add_a_signature")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalSignature = ({
  onSignatureSvgChanged,
  hide,
}: {
  onSignatureSvgChanged: (svg?: string) => Error | undefined;
  hide: () => void;
}) => {
  const t = useTranslation();
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const signaturePadRef = useRef<HTMLCanvasElement>(null);
  const [currentTab, setCurrentTab] = useState<"SVG" | "FILE">("SVG");
  const [showError, setShowError] = useState(false);
  useLayoutEffect(() => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    signaturePadRef.current!.width =
      signaturePadRef.current!.offsetWidth * ratio;
    signaturePadRef.current!.height =
      signaturePadRef.current!.offsetHeight * ratio;
    signaturePadRef.current!.getContext("2d")?.scale(ratio, ratio);
    setSignaturePad(new SignaturePad(signaturePadRef.current!));
  }, []);

  return (
    <Modal
      title={t("preferences.info.form_signature.set_your_signature")}
      onHide={hide}
    >
      <div className="flex w-[400px] mb-8">
        <div
          className={classNames("w-1/2 text-center hover:cursor-pointer", {
            "border-b-2 border-primary font-semibold": currentTab === "SVG",
          })}
          onClick={() => {
            setCurrentTab("SVG");
          }}
        >
          {t("preferences.info.form_signature.draw")}
        </div>
        <div
          className={classNames("w-1/2 text-center hover:cursor-pointer", {
            "border-b-2 border-primary font-semibold": currentTab === "FILE",
          })}
          onClick={() => {
            setCurrentTab("FILE");
          }}
        >
          {t("preferences.info.form_signature.upload")}
        </div>
      </div>
      <div className="relative">
        <div
          className={classNames("bg-white border rounded", {
            visible: currentTab === "SVG",
            invisible: currentTab !== "SVG",
          })}
        >
          <ClickableIcon
            name="close"
            className="ml-auto absolute right-0 z-2"
            onClick={() => signaturePad?.clear()}
          />
          <canvas ref={signaturePadRef} style={{ height: 200, width: 400 }} />
        </div>
        {/*  The Dropzone tab is just shown above the draw one so that it does
        not remove a drawing that is still in progress */}
        {currentTab === "FILE" && (
          <FormDropzone
            name="signatureFile"
            accept="image/*"
            maxSize={10_000_000}
            className="absolute top-0 left-0 w-[402px] h-[200]px z-3"
            height={200}
            isPreviewContained
          />
        )}
      </div>
      {showError && (
        <div className="text-danger mt-4">
          {t("preferences.info.form_signature.error")}
        </div>
      )}
      <Button
        onClick={() => {
          const svg = signaturePad!.isEmpty()
            ? undefined
            : signaturePad!.toDataURL("image/svg+xml");
          const err = onSignatureSvgChanged(svg);
          if (err) {
            setShowError(true);
            return;
          }
          setShowError(false);
          hide();
        }}
        className="mt-20"
        label={t("preferences.info.form_signature.add_the_signature")}
      />
    </Modal>
  );
};
