import classNames from "classnames";

import { UserSummaryFragment } from "generated/provider";
import { displayUser, pluralize } from "utils/display";

import { Avatar } from "./Avatar";
import { getFontSize } from "./utils";

const NB_DISPLAYED_AVATARS = 3;

export const AvatarList = ({
  users,
  totalCount = users.length,
  size = 24,
  word,
  extra,
  className,
  withBrowserTitle,
}: {
  users: UserSummaryFragment[];
  totalCount?: number;
  size?: number;
  word?: string;
  extra?: string;
  className?: string;
  withBrowserTitle?: boolean;
}) => (
  <div
    className={classNames("flex items-center", className)}
    title={
      withBrowserTitle ? users.map((u) => displayUser(u)).join("\n") : undefined
    }
  >
    {users.slice(0, NB_DISPLAYED_AVATARS).map((d, i) => (
      <Avatar
        key={"uuid" in d ? d.uuid : i}
        user={d}
        size={size}
        className="-ml-12 first:m-0"
        style={{ zIndex: i }}
      />
    ))}
    {totalCount > NB_DISPLAYED_AVATARS && (
      <div
        className="-ml-12 h-24 w-24 bg-grey-200 border border-white rounded-full text-white flex-center"
        style={{ zIndex: NB_DISPLAYED_AVATARS, fontSize: getFontSize(size) }}
      >
        +{totalCount - NB_DISPLAYED_AVATARS}
      </div>
    )}
    {word && (
      <div className="ml-10">
        {pluralize(totalCount, word)} {extra}
      </div>
    )}
  </div>
);
