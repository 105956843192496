import gql from "graphql-tag";

import { Button } from "components/Button/Button";
import { CreateQAExperienceWithMessagesData } from "generated/provider";
import { useTranslation } from "i18n";

import { useSendNewExperienceButtonUtils } from "./utils";

gql`
  mutation CreateQAExperienceWithMessages(
    $patientUuid: UUID!
    $initialMessages: [SendMessageInput!]!
  ) {
    createQAExperience(
      patientUuid: $patientUuid
      initialMessages: $initialMessages
    ) {
      experience {
        ...QAExperience
        patient {
          ...PatientSummary
          ...Devices
        }
      }
    }
  }
`;

export const CreateAndSendButton = ({
  disabled,
  onSuccess,
}: {
  disabled?: boolean;
  onSuccess?: (result: CreateQAExperienceWithMessagesData) => void;
}) => {
  const t = useTranslation();
  const {
    canSendMessage,
    createExperienceAndSendMessages,
    uploadLoading,
    createExperienceLoading,
  } = useSendNewExperienceButtonUtils();

  return (
    <Button
      className="h-32"
      label={t("inboxes.qa_experience.qa_footer.send_button.send")}
      onClick={() => createExperienceAndSendMessages(onSuccess)}
      loading={createExperienceLoading || uploadLoading}
      disabled={!canSendMessage || disabled}
    />
  );
};
