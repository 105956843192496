import { ReactNode } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { MenuItemProps } from "components/Menu/MenuItem";
import { MoreMenu } from "components/Menu/MoreMenu";

export type RowActionMode<T extends { uuid: UUID }> =
  | { mode: "expand"; expand: (e: T) => ReactNode }
  | { mode: "navigate"; to: string }
  | { mode: "none" };

export const RowList = <T extends { uuid: UUID }>({
  elements,
  menuItems,
  field,
  emptyPlaceholder,
  navigateTo,
}: {
  elements: T[];
  menuItems?: (user: T) => MenuItemProps[];
  field: (user: T) => ReactNode;
  emptyPlaceholder?: ReactNode;
  navigateTo?: (element: T) => string | null;
}) => (
  <div className="inline-block bg-white w-full overflow-hidden">
    <div className="w-full">
      {elements.map((e) => {
        const fieldValue = field(e);
        const targetLink = navigateTo?.(e);
        return (
          fieldValue &&
          (targetLink ? (
            <Link
              key={e.uuid}
              className="cursor-pointer hover:bg-grey-100 flex justify-between border-b items-center"
              to={targetLink}
            >
              <RowElement fieldValue={fieldValue} menuItems={menuItems?.(e)} />
            </Link>
          ) : (
            <div
              key={e.uuid}
              className="flex justify-between border-b items-center"
            >
              <RowElement fieldValue={fieldValue} menuItems={menuItems?.(e)} />
            </div>
          ))
        );
      })}
    </div>
    {elements.isEmpty() && emptyPlaceholder}
  </div>
);

const RowElement = ({
  fieldValue,
  menuItems,
}: {
  fieldValue: ReactNode;
  menuItems?: MenuItemProps[];
}) => (
  <>
    <div className="flex-fill truncate">{fieldValue}</div>
    {menuItems && (
      <div className="py-14 pr-16 lg:pr-44 text-primary-dark text-14">
        <MoreMenu
          items={menuItems}
          position="left"
          className={classNames("rounded p-8", "hover:bg-grey-200")}
        />
      </div>
    )}
  </>
);
