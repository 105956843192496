import { useCallback } from "react";
import {
  ApolloError,
  useLazyQuery as useApolloLazyQuery,
} from "@apollo/client";

import { useAuth } from "auth/AuthContext";
import { Query, SchemaType } from "base-types";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";
import { useSyncRef } from "hooks/useSyncRef";

import {
  notifyGraphQLError,
  parseApolloError,
  ParsedGraphQLError,
} from "./errors";
import { useGraphQLClient } from "./GraphQLClientContext";
import { RequestContextOptions } from "./request-context-utils";
import { ErrorHandler, SuccessCallback, WithOperation } from "./types";
import { getOutput } from "./utils";

export type LazyQueryOptions<
  Data,
  Schema extends SchemaType,
> = RequestContextOptions<Schema> & {
  onSuccess?: SuccessCallback<Data>;
  onError?: ErrorHandler;
};

export const useLazyQuery = <
  Data,
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
>(
  query: Query<Data, Variables, VariablesRequired, Schema>,

  // No need to pass an options object if none of the options are required.
  ...options: Partial<LazyQueryOptions<Data, Schema>> extends LazyQueryOptions<
    Data,
    Schema
  >
    ? [options?: LazyQueryOptions<Data, Schema>]
    : [options: LazyQueryOptions<Data, Schema>]
): [
  VariablesRequired extends true
    ? (variables: Variables) => void
    : (variables?: Variables) => void,
  {
    data: Data | undefined;
    error: ParsedGraphQLError | undefined;
    loading: boolean;
  },
] => {
  const { onSuccess, onError, requestContext } = options[0] ?? {};

  const client = useGraphQLClient().graphQLClients[query.schemaType];
  const optionsRef = useSyncRef({ onSuccess, onError });
  const { state: appVersionState } = useAppVersion();
  const { state: authState } = useAuth();

  // See `useQuery` for an in-depth explanation.
  const shouldBypassCache = requestContext !== undefined;
  const apolloContext = {
    requestContext,
    queryDeduplication: !shouldBypassCache,
  };

  const [execute, { data, error, loading }] = useApolloLazyQuery<
    WithOperation<Data>,
    Variables
  >(query.document, {
    client,
    fetchPolicy: shouldBypassCache ? "no-cache" : "network-only",
    context: apolloContext,
    onCompleted: useCallback(
      (rawData: WithOperation<Data>) => {
        const output = getOutput(rawData);
        if (output) optionsRef.current.onSuccess?.(output, client);
      },
      [optionsRef, client],
    ),
    onError: useCallback(
      (e: ApolloError) => {
        const parsedError = parseApolloError(e);
        optionsRef.current.onError
          ? optionsRef.current.onError(parsedError, () =>
              notifyGraphQLError(appVersionState, authState, e),
            )
          : notifyGraphQLError(appVersionState, authState, e);
      },
      [optionsRef, authState, appVersionState],
    ),
  });

  return [
    useCallback(
      (variables: Variables | undefined) => execute({ variables }),
      [execute],
    ),
    {
      data: data ? getOutput(data) : undefined,
      error: error && parseApolloError(error),
      loading,
    },
  ];
};
