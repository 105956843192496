import classNames from "classnames";

export const PatientViewTagPill = ({
  tag,
  className,
}: {
  tag: string;
  className?: string;
}) => (
  <div
    className={classNames(
      "flex-center ml-8 h-20 text-10 text-grey-300 rounded-sm bg-grey-100 px-8 py-4 my-2",
      className,
    )}
  >
    {tag}
  </div>
);
