import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { NotFound } from "components/ErrorPage/NotFound";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { Label } from "components/Form/Label/Label";
import { FormSelect } from "components/Form/Select/FormSelect";
import { Secret } from "components/Secret/Secret";
import { Spinner } from "components/Spinner/Spinner";
import { useUser } from "contexts/User/UserContext";
import {
  AppointmentPaymentSettings,
  TestAppointmentPriceEvaluationWebhook,
  UpdateAppointmentPriceEvaluationMode,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { possibleTypes } from "types";
import { getKnownValue } from "utils/enum";
import { notifier } from "utils/notifier";

gql`
  query AppointmentPaymentSettings {
    me {
      doctor {
        subOrganization {
          uuid
          appointmentPriceEvaluationMode {
            ...AppointmentPriceEvaluationMode
          }
        }
      }
    }
  }

  mutation UpdateAppointmentPriceEvaluationMode(
    $input: AppointmentPriceEvaluationModeInput!
  ) {
    updateOrganizationSettings(
      input: { appointmentPriceEvaluationMode: $input }
    ) {
      doctor {
        subOrganization {
          uuid
          appointmentPriceEvaluationMode {
            ...AppointmentPriceEvaluationMode
          }
        }
      }
    }
  }

  mutation TestAppointmentPriceEvaluationWebhook(
    $input: TestAppointmentPriceEvaluationWebhookInput!
  ) {
    testAppointmentPriceEvaluationWebhook(input: $input) {
      rawResult
      success
      url
      signatureSecret
    }
  }
`;

const modeTypes = possibleTypes.AppointmentPriceEvaluationMode;
type ModeType = typeof modeTypes[number];

type FormPriceEvaluatorValues = {
  mode: ModeType | null;
  webhookUrl: string | undefined;
};

export const SchedulingSettingsPayment = () => {
  const t = useTranslation();
  const { hasPermission, hasAccessToGatekeeperFeature } = useUser();
  const [testWebhookRawResult, setTestWebhookRawResult] = useState<string>();
  const [testWebhookSignatureSecret, setTestWebhookSignatureSecret] = useState<
    string | undefined
  >(undefined);

  const { data, loading } = useQuery(AppointmentPaymentSettings);

  // When enabling the webhook mode, testing webhook allow dev to get a signature secret to correctly setup his environment
  // This secret is used to save the webhook mode configuration.
  const webhookSignatureSecret =
    data &&
    data.doctor.subOrganization.appointmentPriceEvaluationMode.__typename ===
      "AppointmentPriceEvaluationModeWebhook"
      ? data.doctor.subOrganization.appointmentPriceEvaluationMode
          .signatureSecret
      : testWebhookSignatureSecret;

  const [updatePriceEvaluationMode] = useMutation(
    UpdateAppointmentPriceEvaluationMode,
    {
      onSuccess: () => {
        setTestWebhookRawResult(undefined);
        setTestWebhookSignatureSecret(undefined);
        notifier.success(t("settings.scheduling.price.evaluation.success"));
      },
    },
  );

  const [
    testAppointmentPriceEvaluationWebhook,
    testAppointmentPriceEvaluationWebhookLoading,
  ] = useMutation(TestAppointmentPriceEvaluationWebhook, {
    onSuccess: (output) => {
      setTestWebhookRawResult(output.rawResult);
      setTestWebhookSignatureSecret(output.signatureSecret);
      if (output.success) {
        notifier.success(
          t("settings.scheduling.price.evaluation.webhook.test_succeeded"),
        );
      } else {
        notifier.error({
          user: t("settings.scheduling.price.evaluation.webhook.test_failed"),
        });
      }
    },
  });

  if (!hasPermission("EDIT_SUB_ORGANIZATION_SETTINGS")) return <NotFound />;

  return (
    <div className="flex-col space-y-12">
      {hasAccessToGatekeeperFeature("SCHEDULING_PAYMENT") && (
        <Background className="flex-col flex-fill overflow-auto mt-16 p-16 lg:p-0 space-y-24">
          {loading && <Spinner small inline className="mx-auto" />}
          {data && (
            <Form<FormPriceEvaluatorValues>
              className="flex-col w-full items-start space-y-20 p-32 bg-white border rounded shadow-sm-outlined"
              initialValues={{
                mode: getKnownValue(
                  data.doctor.subOrganization.appointmentPriceEvaluationMode
                    .__typename,
                  modeTypes,
                ),
                webhookUrl:
                  data.doctor.subOrganization.appointmentPriceEvaluationMode
                    .__typename === "AppointmentPriceEvaluationModeWebhook"
                    ? data.doctor.subOrganization.appointmentPriceEvaluationMode
                        .url
                    : undefined,
              }}
              validate={({ mode, webhookUrl }) => {
                const webhookUrlIsInvalid =
                  mode === "AppointmentPriceEvaluationModeWebhook" &&
                  !webhookUrl?.trimOrNull();
                return {
                  ...(webhookUrlIsInvalid && {
                    webhookUrl: t(
                      "settings.scheduling.price.evaluation.webhook_required",
                    ),
                  }),
                };
              }}
              onSubmit={(values) =>
                updatePriceEvaluationMode({
                  input: {
                    ...(values.mode ===
                      "AppointmentPriceEvaluationModeWebhook" &&
                    values.webhookUrl
                      ? {
                          webhook: {
                            url: values.webhookUrl,
                            signatureSecret: webhookSignatureSecret,
                          },
                        }
                      : undefined),
                    ...(values.mode === "AppointmentPriceEvaluationModeNone"
                      ? { none: "EMPTY" }
                      : undefined),
                  },
                })
              }
            >
              <div className="flex-col w-auto items-start space-y-12">
                <div className="flex items-center space-x-4">
                  <h1 className="flex-fill text-primary-dark text-24 font-bold">
                    {t("settings.scheduling.price.evaluation.title")}
                  </h1>
                </div>

                {t("settings.scheduling.price.evaluation.description")}

                <FormSelect
                  wrapperClassName="flex-fill"
                  name="mode"
                  options={modeTypes}
                  getOptionLabel={(o) => {
                    switch (o) {
                      case "AppointmentPriceEvaluationModeNone":
                        return t(
                          "settings.scheduling.price.evaluation.mode.none",
                        );
                      case "AppointmentPriceEvaluationModeWebhook":
                        return t(
                          "settings.scheduling.price.evaluation.mode.webhook",
                        );
                    }
                  }}
                  label={t("settings.scheduling.price.evaluation.mode.title")}
                />

                <FormState<FormPriceEvaluatorValues>>
                  {({ values }) => (
                    <>
                      {values.mode ===
                        "AppointmentPriceEvaluationModeWebhook" && (
                        <>
                          <a
                            className="link"
                            href="https://docs.nabla.com/reference/appointment-price/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t(
                              "settings.scheduling.price.evaluation.api_reference",
                            )}
                          </a>
                          <div className="flex w-full items-end gap-12">
                            <FormInput
                              wrapperClassName="flex-fill"
                              name="webhookUrl"
                              label={t(
                                "settings.scheduling.price.evaluation.webhook_url",
                              )}
                              placeholder="https://example.com/webhook"
                              onChange={() =>
                                setTestWebhookRawResult(undefined)
                              }
                            />
                            <Button
                              secondary
                              label={t(
                                "settings.scheduling.price.evaluation.webhook.test",
                              )}
                              loading={
                                testAppointmentPriceEvaluationWebhookLoading
                              }
                              onClick={() =>
                                testAppointmentPriceEvaluationWebhook({
                                  input: {
                                    url: values.webhookUrl ?? "",
                                    signatureSecret:
                                      data.doctor.subOrganization
                                        .appointmentPriceEvaluationMode
                                        .__typename ===
                                        "AppointmentPriceEvaluationModeWebhook" &&
                                      values.webhookUrl ===
                                        data.doctor.subOrganization
                                          .appointmentPriceEvaluationMode.url
                                        ? data.doctor.subOrganization
                                            .appointmentPriceEvaluationMode
                                            .signatureSecret
                                        : undefined,
                                  },
                                })
                              }
                            />
                          </div>
                          {testWebhookRawResult && (
                            <div className="flex-col p-18 space-y-4 bg-grey-100 rounded sm-outlined outlined border">
                              {testWebhookRawResult}
                            </div>
                          )}
                        </>
                      )}

                      {values.mode ===
                        "AppointmentPriceEvaluationModeWebhook" &&
                        webhookSignatureSecret && (
                          <div>
                            <Label
                              label={t("developers.webhooks.webhooks.secret")}
                              useDiv
                            />
                            <Secret secret={webhookSignatureSecret} />
                          </div>
                        )}

                      <Submit
                        label={t("settings.scheduling.categories.form_save")}
                        requiresDirty
                      />
                    </>
                  )}
                </FormState>
              </div>
            </Form>
          )}
        </Background>
      )}
    </div>
  );
};
