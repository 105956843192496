import { Maybe } from "base-types";
import { TranscriptItemFragment } from "generated/provider";
import { staticT as t } from "i18n";
import { IconName } from "icon-library";

export const TranscriptItemTags = [
  "Inaudible",
  "Incomprehensible",
  "RequiresMedicalOpinion",
  "HasOverlappingIssues",
  "VerifiedByDoctor",
] as const;
export type TranscriptItemTag = typeof TranscriptItemTags[number];

export const getTranscriptItemTags = (
  transcriptItem: TranscriptItemFragment,
): TranscriptItemTag[] => {
  const maybeTags: Maybe<TranscriptItemTag>[] = [
    transcriptItem.isInaudible ? "Inaudible" : null,
    transcriptItem.isIncomprehensible ? "Incomprehensible" : null,
    transcriptItem.requiresMedicalOpinion ? "RequiresMedicalOpinion" : null,
    transcriptItem.hasOverlappingIssues ? "HasOverlappingIssues" : null,
    transcriptItem.verifiedByDoctor ? "VerifiedByDoctor" : null,
  ];
  return maybeTags.filterNotNull();
};

export const displayTranscriptItemTag = (tag: TranscriptItemTag) => {
  switch (tag) {
    case "Inaudible":
      return t("transcript_item.tag.inaudible");
    case "Incomprehensible":
      return t("transcript_item.tag.incomprehensible");
    case "RequiresMedicalOpinion":
      return t("transcript_item.tag.requires_medical_opinion");
    case "HasOverlappingIssues":
      return t("transcript_item.tag.has_overlapping_issues");
    case "VerifiedByDoctor":
      return t("transcript_item.tag.verified_by_doctor");
  }
};

export const tagToIconName = (tag: TranscriptItemTag): IconName => {
  switch (tag) {
    case "Inaudible":
      return "speaker";
    case "Incomprehensible":
      return "questions";
    case "RequiresMedicalOpinion":
      return "stethoscope";
    case "HasOverlappingIssues":
      return "scissors";
    case "VerifiedByDoctor":
      return "check";
  }
};
