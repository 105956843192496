import { useDraft, useDraftSetter } from "atoms/useDraft";
import { insertText } from "utils";

import { useMaybeQAExperience } from "../QAExperienceContext/QAExperienceContext";
import { replaceUsername } from "../utils";

export const useAppendToQAComposer = () => {
  const setDraft = useDraftSetter();
  const { experience, patient, draftKey, textAreaRef } = useMaybeQAExperience();

  // Should be synced with QAFooter. We can't directly use textAreaRef
  // because it does not rerender when set
  return experience?.isClosed
    ? null
    : (value: string) => {
        const textToInset = replaceUsername(value, patient);
        if (
          textAreaRef.current!.selectionStart === 0 ||
          textAreaRef.current!.selectionStart ===
            textAreaRef.current!.value.length
        ) {
          const current =
            useDraft.getState().store[draftKey]?.messageText ?? "";
          setDraft(draftKey, {
            messageText: current.isBlank()
              ? textToInset
              : `${current}\n${textToInset}`,
          });
          textAreaRef.current!.focus();
        } else {
          // If the cursor was in the middle of the textArea, insert the text after it
          textAreaRef.current!.focus();
          insertText(textToInset);
        }
      };
};
