import { ReactNode, RefObject } from "react";
import gql from "graphql-tag";

import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { FormModal } from "components/Modal/FormModal";
import { usePDFPreview } from "components/PDFPreview/usePDFPreview";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { SendFileByFax } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useUploadFile } from "graphql-client/useUploadFile";
import { useTranslation } from "i18n";
import { run } from "utils";
import { addSentFaxInTodoAndDoneTimelineItemCache } from "utils/apollo";
import { now } from "utils/date";
import { notifier } from "utils/notifier";

gql`
  mutation SendFileByFax(
    $recipientPhone: PhoneNumber!
    $fileUploadUuid: UUID!
    $comment: String
    $appointmentUuid: UUID
    $patientDocumentUuid: UUID
    $patientNoteUuid: UUID
    $nablaPrescriptionUuid: UUID
    $medicalOrderUuid: UUID
  ) {
    sendFileByFax(
      recipientPhone: $recipientPhone
      fileUploadUuid: $fileUploadUuid
      comment: $comment
      attachTo: {
        appointmentUuid: $appointmentUuid
        patientDocumentUuid: $patientDocumentUuid
        patientNoteUuid: $patientNoteUuid
        nablaPrescriptionUuid: $nablaPrescriptionUuid
        medicalOrderUuid: $medicalOrderUuid
      }
    ) {
      fax {
        ...SentFax
      }
    }
  }
`;

export type SupportedFaxDocumentType =
  | "APPOINTMENT"
  | "NOTE"
  | "DOCUMENT"
  | "NABLA_PRESCRIPTION"
  | "MEDICAL_ORDER";

export const FaxModal = ({
  onHide,
  documentType,
  itemUuid,
  getPreview,
  fileUuid,
}: {
  onHide: () => void;
  documentType: SupportedFaxDocumentType;
  itemUuid: UUID;
  getPreview?: (
    previewRef: RefObject<HTMLDivElement>,
    comment: string,
  ) => ReactNode;
  fileUuid?: UUID;
}) => {
  const t = useTranslation();
  const { patient } = usePatient();
  const { previewRef: faxPreviewRef, generatePdf: generateFax } = usePDFPreview(
    { margin: [50, 50, 30, 50] },
  );
  const uploadFile = useUploadFile();
  const [sendFileByFax] = useMutation(SendFileByFax);

  return (
    <FormModal<{ faxNumber: string; comment: string }>
      title={t("patient_view.fax.form_title")}
      submitLabel={t("patient_view.fax.form_submit")}
      onHide={onHide}
      className="flex-col w-full"
      initialValues={{ faxNumber: "", comment: "" }}
      validationSchema={{
        faxNumber: "required",
      }}
      validate={({ faxNumber }) =>
        // eslint-disable-next-line require-unicode-regexp
        faxNumber.match(/^\+?([\d()-]|\s)+$/g)
          ? undefined
          : {
              faxNumber: t("patient_view.fax.invalid_number"),
            }
      }
      onSubmit={async ({ faxNumber, comment }) => {
        const fileToSendUuid =
          fileUuid ??
          (await run(async () => {
            const file = await generateFax();
            const upload = await uploadFile({
              purpose: "PATIENT_DOCUMENT",
              file: new File(
                [file],
                `fax_${patient.uuid}_${faxNumber
                  // eslint-disable-next-line require-unicode-regexp
                  .replaceAll(/[^\w- ]/g, "")
                  .trim()}_${now().format("file")}.pdf`,
                {
                  type: "application/pdf",
                },
              ),
            });
            return upload?.uuid;
          }));

        if (fileToSendUuid) {
          await sendFileByFax(
            {
              recipientPhone: faxNumber,
              fileUploadUuid: fileToSendUuid,
              comment,
              appointmentUuid: documentType === "APPOINTMENT" ? itemUuid : null,
              patientDocumentUuid:
                documentType === "DOCUMENT" ? itemUuid : null,
              patientNoteUuid: documentType === "NOTE" ? itemUuid : null,
              nablaPrescriptionUuid:
                documentType === "NABLA_PRESCRIPTION" ? itemUuid : null,
              medicalOrderUuid:
                documentType === "MEDICAL_ORDER" ? itemUuid : null,
            },
            {
              onSuccess: (output, client) => {
                switch (documentType) {
                  case "APPOINTMENT":
                    notifier.success(
                      t("patient_view.appointment_fax.successfully_sent"),
                    );
                    break;
                  case "DOCUMENT":
                    notifier.success(
                      t("patient_view.document_fax.successfully_sent"),
                    );
                    break;
                  case "NOTE":
                    notifier.success(
                      t("patient_view.note_fax.successfully_sent"),
                    );
                    break;
                  case "NABLA_PRESCRIPTION":
                    notifier.success(
                      t("patient_view.prescription_fax.successfully_sent"),
                    );
                    break;
                  case "MEDICAL_ORDER":
                    notifier.success(
                      t("patient_view.order_fax.successfully_sent"),
                    );
                    break;
                }
                addSentFaxInTodoAndDoneTimelineItemCache(
                  client,
                  patient.uuid,
                  itemUuid,
                  output.fax,
                );
              },
            },
          );
        }
      }}
      onSubmitEnd={onHide}
    >
      <FormInput
        name="faxNumber"
        label={t("patient_view.fax.form_recipient")}
      />
      <FormTextArea
        name="comment"
        disabled={!!fileUuid}
        label={t("patient_view.fax.form_comment")}
        placeholder={fileUuid ? t("patient_view.fax.disabled_comment") : ""}
      />
      {getPreview && (
        <FormState<{ faxNumber: string; comment: string }>>
          {({ values }) => (
            <div className="hidden">
              {getPreview(faxPreviewRef, values.comment)}
            </div>
          )}
        </FormState>
      )}
    </FormModal>
  );
};
