import { MouseEventHandler } from "react";
import classNames from "classnames";

export const Backdrop = ({
  visible,
  onClick,
  className,
}: {
  visible: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  className?: string;
}) => (
  <div
    className={classNames(
      className,
      "inset-0 z-1 bg-primary-dark transition duration-200 pointer-events-none",
      visible ? "opacity-50 pointer-events-auto" : "opacity-0",
    )}
    onClick={onClick}
  />
);
