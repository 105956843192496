import { differenceInMinutes } from "date-fns";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { Button } from "components/Button/Button";
import { DoctorFilter } from "components/DoctorSelect/DoctorSelect";
import { Icon } from "components/Icon/Icon";
import { Query } from "components/Query/Query";
import { AppointmentFragment, UpcomingAppointments } from "generated/provider";
import { useMountDate } from "hooks";
import { useRerenderAt } from "hooks/useRerender";
import { useTranslation } from "i18n";
import { getPatientViewRoute } from "utils";
import { appointmentImminentAt } from "utils/appointment";
import { displayPatient } from "utils/display";

gql`
  query UpcomingAppointments($doctorUuid: UUID!, $cursor: DateTime) {
    doctor(uuid: $doctorUuid) {
      doctor {
        uuid
        upcomingAppointments(pagination: { from: $cursor, numberOfItems: 30 }) {
          ...Appointment
        }
        pastAppointments(pagination: { from: $cursor, numberOfItems: 30 }) {
          ...Appointment
        }
      }
    }
  }
`;

export const AppointmentList = ({
  selectedFilter,
  onAppointmentSelected,
}: {
  selectedFilter: DoctorFilter;
  onAppointmentSelected: (appointment: AppointmentFragment) => void;
}) => {
  const initialCursor = useMountDate();
  const t = useTranslation();

  return (
    <div className="flex-col flex-fill">
      <Query
        query={UpcomingAppointments}
        variables={{ doctorUuid: selectedFilter.uuid, cursor: initialCursor }}
      >
        {({ doctor }) => {
          const appointments = [
            ...[...doctor.pastAppointments].reverse(),
            ...doctor.upcomingAppointments,
          ].filter(
            (appointment) =>
              appointment.state.__typename === "AppointmentStateScheduled",
          );
          return (
            <>
              {appointments.isEmpty() ? (
                <div className="p-20 flex-fill flex-center">
                  {t("scheduling.list.appointment_list.no_appointment")}
                </div>
              ) : (
                appointments.map((appointment, index) => (
                  <div key={appointment.uuid}>
                    <AppointmentCard
                      appointment={appointment}
                      onClick={() => onAppointmentSelected(appointment)}
                    />
                    {index !== appointments.length - 1 && (
                      <div className="bg-grey-200" style={{ height: 1 }} />
                    )}
                  </div>
                ))
              )}
            </>
          );
        }}
      </Query>
    </div>
  );
};

const AppointmentCard = ({
  appointment,
  onClick,
}: {
  appointment: AppointmentFragment;
  onClick: () => void;
}) => {
  const t = useTranslation();

  const durationInMin = differenceInMinutes(
    appointment.endAt.getDate(),
    appointment.startAt.getDate(),
  );

  useRerenderAt(appointmentImminentAt(appointment));

  return (
    <div className="w-full flex-col space-y-4 list-element" onClick={onClick}>
      <div className="flex w-full">
        <Link
          className="text-primary-dark text-14 font-medium flex-fill truncate mr-auto hover:underline"
          to={getPatientViewRoute(appointment.patient)}
        >
          {displayPatient(appointment.patient)}
        </Link>
        <div className="text-body text-14 font-normal">{durationInMin} min</div>
      </div>
      <div className="flex w-full">
        <div className="text-primary-dark text-14 font-regular flex-fill truncate mr-auto">
          {appointment.startAt.format({ exception: "dd MMMM yyyy" })}{" "}
          {t("scheduling.list.appointment_list.at")}{" "}
          {appointment.startAt.format("time")}
        </div>
        <Icon className="text-body" name="videoOn" size={20} />
      </div>
      <Button
        label={t("scheduling.list.see_appointment")}
        secondary={appointmentImminentAt(appointment).isFuture()}
        className="h-32 text-14 mt-16"
        to={getPatientViewRoute(appointment.patient, [appointment])}
      />
    </div>
  );
};
