import classNames from "classnames";

import styles from "./slider.module.css";

export const Slider = ({
  min,
  max,
  step,
  value,
  onChange,
  className,
}: {
  min: number;
  max: number;
  step: number | "any";
  value: number;
  onChange: (value: number) => void;
  className?: string;
}) => {
  const remainingRatio = (max - value) / (max - min);

  return (
    <div className={classNames("flex-col relative", className)}>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className={styles.slider}
        onChange={(e) => onChange(Number(e.target.value))}
        step={step}
      />
      <div
        className="absolute right-0 bg-grey-200 pointer-events-none"
        style={{
          height: 1,
          width: `calc(${100 * remainingRatio}% - ${remainingRatio * 7}px)`,
        }}
      />
    </div>
  );
};
