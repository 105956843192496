import { useMedicalCodes } from "atoms/medicalCodes/useMedicalCodes";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { useTranslation } from "i18n";
import { ICD10Code } from "types";
import { language } from "utils/intl";

import { LabelWrapper, UnnamedLabelWrapperProps } from "../Label/LabelWrapper";
import { MedicalCodesExplorer } from "./MedicalCodesExplorer";

export const IcdCodePicker = ({
  value,
  label,
  disabled,
  error,
  hint,
  onChange,
}: {
  value: ICD10Code[];
  disabled?: boolean;
  onChange: (value: ICD10Code[]) => void;
} & UnnamedLabelWrapperProps) => {
  const { getICD10CMByCode } = useMedicalCodes();
  const t = useTranslation();

  return (
    <LabelWrapper
      label={label ?? t("form.icd_codes.title")}
      error={error}
      hint={hint}
      useDiv
      wrapperClassName="items-start"
    >
      <ul className="list-disc ml-20 flex-col items-start">
        {value.map((it, index) => {
          const item = getICD10CMByCode(it);
          if (!item) return null;
          return (
            <li key={index} className="text-primary-dark mb-4">
              <div className="bg-grey-200 rounded-full px-8 flex items-center">
                {item[language as "fr" | "en" | "pt"]}
                <span className="text-body ml-4">{item.code}</span>
                {!disabled && (
                  <ClickableIcon
                    name="close"
                    size={12}
                    className="h-12 w-12"
                    onClick={() =>
                      onChange(
                        value.mapNotNull((code, indexCode) => {
                          if (indexCode === index) return null;
                          return code;
                        }),
                      )
                    }
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {!disabled && (
        <UncontrolledPopover
          position={["bottom-left", "top-left"]}
          className="flex-col my-6 overflow-hidden"
          style={{ width: 300, maxHeight: 303 }}
          content={(close) => (
            <MedicalCodesExplorer
              codeType="ICD10CM"
              onSelect={(it) => onChange([...value, it.code])}
              close={close}
              pickedCodes={value}
            />
          )}
        >
          {({ setTarget }) => (
            <button
              className="rounded-sm py-2 px-8 hover:bg-grey-100"
              type="button"
              onClick={setTarget}
            >
              {t("form.icd_codes.add")}
            </button>
          )}
        </UncontrolledPopover>
      )}
    </LabelWrapper>
  );
};
