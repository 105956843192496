import { ReactNode, useState } from "react";
import gql from "graphql-tag";

import {
  ControlledConfirmationModal,
  ControlledConfirmationModalProps,
} from "components/Modal/ControlledConfirmationModal";
import { QueryResult } from "components/Query/QueryResult";
import { FieldHeader, Table } from "components/Table/Table";
import { useDoctor, useUser } from "contexts/User/UserContext";
import {
  AllDoctorsManagement,
  DeactivateDoctor,
  DeleteDoctor,
  DoctorManagementDoctorFragment,
  ReactivateDoctor,
  RegisterDoctor,
  RevokeAllDoctorSessions,
  UpdateDoctor,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { copyToClipBoard } from "utils";
import { displayDoctorRole } from "utils/display";
import { notifier } from "utils/notifier";

import { ProviderAperoForm } from "./ProviderAperoForm";
import { ProviderComposer } from "./ProviderComposer";

gql`
  fragment DoctorManagementDoctor on Doctor {
    ...DoctorSummary
    phone
    title
    gender
    roles
    passwordLoginEnabled
    permissions
    gatekeeperFeatures
    signatureRxPrescriberId
  }

  query AllDoctorsManagement($search: String, $permissions: [Permission!]) {
    doctorSearch(
      filter: {
        freeTextSearch: $search
        permissions: $permissions
        includeInactiveAccounts: true
      }
    ) {
      doctors(pagination: { numberOfItems: -1 }) {
        ...DoctorManagementDoctor
      }
      totalCount
    }
  }

  mutation RegisterDoctor(
    $email: String!
    $registerDoctorInput: RegisterDoctorInput!
  ) {
    registerDoctor(email: $email, payload: $registerDoctorInput) {
      doctor {
        ...DoctorManagementDoctor
      }
    }
  }

  mutation UpdateDoctor(
    $doctorUuid: UUID!
    $profilePayload: UpdateDoctorProfileInput!
    $adminPayload: UpdateDoctorAdminInput!
  ) {
    updateDoctor(
      doctorUuid: $doctorUuid
      profilePayload: $profilePayload
      adminPayload: $adminPayload
    ) {
      doctor {
        ...DoctorManagementDoctor
      }
    }
  }

  mutation DeactivateDoctor($uuid: UUID!) {
    deactivateDoctor(doctorUuid: $uuid) {
      doctor {
        uuid
        deactivated
      }
    }
  }

  mutation ReactivateDoctor($uuid: UUID!) {
    reactivateDoctor(doctorUuid: $uuid) {
      doctor {
        uuid
        deactivated
      }
    }
  }

  mutation DeleteDoctor($uuid: UUID!) {
    deleteDoctor(doctorUuid: $uuid) {
      status
    }
  }

  mutation RevokeAllDoctorSessions($doctorUuid: UUID!) {
    revokeAllDoctorSessions(doctorUuid: $doctorUuid) {
      doctor {
        uuid
      }
    }
  }
`;

type ComposerState = { mode: "create" } | { mode: "edit"; doctorUuid: UUID };
type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide">
  | undefined;
type AperoDetailsModalState =
  | { doctor: DoctorManagementDoctorFragment }
  | undefined;

export const Providers = ({
  isProviderAdded,
  onProviderAdded,
  search,
}: {
  isProviderAdded: boolean;
  onProviderAdded: () => void;
  search: string | undefined;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const { hasPermission } = useUser();

  const allDoctorsQuery = useQuery(AllDoctorsManagement, {
    variables: {
      search,
      permissions: null,
    },
  });
  const [composerState, setComposerState] = useState<ComposerState>();
  const [confirmationModalState, setConfirmationModalState] =
    useState<ConfirmationModalState>();
  const [showingDetails, setShowingDetails] = useState(false);
  const [aperoDetailsModalState, setAperoDetailsModalState] =
    useState<AperoDetailsModalState>(undefined);

  const [registerDoctor] = useMutation(RegisterDoctor, {
    onSuccess: (output, client) => {
      client.update({
        query: AllDoctorsManagement,
        variables: {
          search: undefined,
          permissions: hasPermission("EDIT_DOCTORS")
            ? null
            : ["ANSWER_QA_EXPERIENCE"],
        },
        skip: ({ doctors }) =>
          doctors.some((d) => d.uuid === output.doctor.uuid),
        write: (draft) => {
          draft.doctors.push(output.doctor);
        },
      });
      notifier.success(t("providers.providers.account_created"));
    },
  });
  const [updateDoctor] = useMutation(UpdateDoctor, {
    onSuccess: () => {
      notifier.success(t("providers.providers.account_updated"));
    },
  });
  const [deactivateDoctor] = useMutation(DeactivateDoctor, {
    onSuccess: () => {
      setConfirmationModalState(undefined);
      notifier.success(t("providers.providers.account_deactivated"));
    },
  });
  const [reactivateDoctor] = useMutation(ReactivateDoctor, {
    onSuccess: () => {
      setConfirmationModalState(undefined);
      notifier.success(t("providers.providers.account_reactivated"));
    },
  });
  const [deleteDoctor] = useMutation(DeleteDoctor, {
    onSuccess: () => {
      setConfirmationModalState(undefined);
      notifier.success(t("providers.providers.account_deleted"));
    },
  });
  const [revokeDoctorSessions] = useMutation(RevokeAllDoctorSessions, {
    onSuccess: () => {
      setConfirmationModalState(undefined);
      notifier.success(t("providers.providers.sessions_revoked"));
    },
  });

  const registrationFieldHeader: FieldHeader<string> = {
    id: "registration",
    name: t("providers.providers.registration"),
    sortable: false,
  };
  const lastNameFieldHeader: FieldHeader<string> = {
    id: "lastName",
    name: t("providers.providers.last_name"),
    sortable: false,
  };
  const firstNameFieldHeader: FieldHeader<string> = {
    id: "firstName",
    name: t("providers.providers.first_name"),
    sortable: false,
  };
  const rolesFieldHeader: FieldHeader<string> = {
    id: "roles",
    name: t("providers.providers.roles"),
    sortable: false,
  };
  const emailFieldHeader: FieldHeader<string> = {
    id: "email",
    name: t("providers.providers.email"),
    sortable: false,
  };
  const phoneFieldHeader: FieldHeader<string> = {
    id: "phone",
    name: t("providers.providers.phone"),
    sortable: false,
    noWrap: true,
  };
  const titleFieldHeader: FieldHeader<string> = {
    id: "title",
    name: t("providers.providers.title"),
    sortable: false,
  };
  const fieldHeaders = [
    lastNameFieldHeader,
    firstNameFieldHeader,
    rolesFieldHeader,
    emailFieldHeader,
    phoneFieldHeader,
    titleFieldHeader,
    registrationFieldHeader,
  ];

  const fields = (d: DoctorManagementDoctorFragment): ReactNode[] => {
    const lastName = d.lastName;
    const firstName = d.firstName;
    const roles = d.roles.map((r) => displayDoctorRole(r)).join(", ");
    const phone = d.phone;
    const title = d.title;
    const status = d.deactivated
      ? t("providers.providers.deactivated")
      : t("providers.providers.activated");
    return [
      lastName,
      firstName,
      roles,
      <div key="email" className="min-w-[180px]">
        {d.email ?? ""}
      </div>,
      <div key="phone" className="min-w-[140px]">
        {phone}
      </div>,
      title,
      status,
    ];
  };

  return (
    <div>
      <div className="leading-snug mt-10 mb-20">
        <p>
          {t("providers.list.roles_explanation")}
          <a
            className="link"
            href="https://docs.nabla.com/docs/roles-and-permissions"
            target="_blank"
            rel="noreferrer"
          >
            {t("providers.list.roles_explanation_more_info")}
          </a>
        </p>
      </div>

      {(isProviderAdded || composerState?.mode === "create") && (
        <ProviderComposer
          onSubmit={async ({ email, avatar: _, ...rest }) => {
            await registerDoctor({ email, registerDoctorInput: rest });
          }}
          onClose={() => {
            if (isProviderAdded) {
              onProviderAdded();
            } else {
              setComposerState(undefined);
            }
          }}
        />
      )}
      {composerState?.mode === "edit" &&
        allDoctorsQuery.data?.doctors
          .find((doctor) => doctor.uuid === composerState.doctorUuid)
          ?.let((doctor) => (
            <ProviderComposer
              doctor={doctor}
              onSubmit={async ({ email: _, avatar, phone, roles, ...rest }) => {
                await updateDoctor({
                  doctorUuid: doctor.uuid,
                  profilePayload: { ...rest, avatarPicture: avatar },
                  adminPayload: { phone: phone?.trimOrNull(), roles },
                });
              }}
              onClose={() => setComposerState(undefined)}
            />
          ))}
      {confirmationModalState && (
        <ControlledConfirmationModal
          {...confirmationModalState}
          onHide={() => setConfirmationModalState(undefined)}
        />
      )}
      {aperoDetailsModalState && (
        <ProviderAperoForm
          provider={aperoDetailsModalState.doctor}
          onHide={() => setAperoDetailsModalState(undefined)}
        />
      )}

      <QueryResult {...allDoctorsQuery}>
        {(data) => (
          <>
            <Table
              elements={data.doctors}
              onShowDetails={(show) => setShowingDetails(show)}
              fieldHeaders={fieldHeaders}
              fields={fields}
              menuItems={(d) => [
                {
                  icon: "edit",
                  text: t("providers.providers.edit"),
                  onClick: (close: () => void) => {
                    close();
                    setComposerState({ mode: "edit", doctorUuid: d.uuid });
                  },
                },
                {
                  icon: "clipboard",
                  text: t("providers.providers.copy_id"),
                  onClick: (close: () => void) => {
                    close();
                    copyToClipBoard(d.uuid, t("providers.providers.id_copied"));
                  },
                },
                {
                  icon: "logout",
                  text: t("providers.providers.revoke_sessions"),
                  className: "text-danger",
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      cta: {
                        label: t("providers.providers.revoke_sessions"),
                        danger: true,
                      },
                      children: t(
                        "providers.providers.revoke_sessions_confirmation",
                        {
                          firstName: d.firstName,
                          lastName: d.lastName,
                        },
                      ),
                      onConfirm: () =>
                        revokeDoctorSessions({ doctorUuid: d.uuid }),
                    });
                  },
                },
                {
                  icon: "lock",
                  text: t("providers.providers.deactivate"),
                  className: "text-danger",
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      cta: {
                        label: t("providers.providers.deactivate"),
                        danger: true,
                      },
                      children: t("providers.deactivate_confirmation", {
                        firstName: d.firstName,
                        lastName: d.lastName,
                      }),
                      onConfirm: () => deactivateDoctor({ uuid: d.uuid }),
                    });
                  },
                  hidden: d.deactivated,
                },
                {
                  icon: "power",
                  text: t("providers.providers.reactivate"),
                  hidden: !d.deactivated,
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      cta: { label: t("providers.providers.reactivate") },
                      children: t(
                        "providers.providers.reactivate_confirmation",
                        {
                          firstName: d.firstName,
                          lastName: d.lastName,
                        },
                      ),
                      onConfirm: () => reactivateDoctor({ uuid: d.uuid }),
                    });
                  },
                },
                {
                  icon: "trash",
                  text: t("providers.providers.harddelete"),
                  className: "text-danger",
                  hidden: !d.deactivated,
                  onClick: (close: () => void) => {
                    close();
                    setConfirmationModalState({
                      cta: {
                        label: t("providers.providers.harddelete"),
                        danger: true,
                      },
                      children: t(
                        "providers.providers.hard_delete_confirmation",
                        { firstName: d.firstName, lastName: d.lastName },
                      ),
                      onConfirm: () =>
                        deleteDoctor(
                          { uuid: d.uuid },
                          {
                            onSuccess: (_, client) =>
                              client.remove("Doctor", d.uuid),
                          },
                        ),
                    });
                  },
                },
                {
                  icon: "dollar",
                  text: t("providers.add_apero_details"),
                  hidden: !user.subOrganization.supportsApero,
                  onClick: (close: () => void) => {
                    close();
                    setAperoDetailsModalState({ doctor: d });
                  },
                },
              ]}
            />
            {!showingDetails && (
              <div className="float-right my-16">
                {data.totalCount} {t("providers.providers.accounts")}
              </div>
            )}
          </>
        )}
      </QueryResult>
    </div>
  );
};
