import { useLoadedExperience } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import { useTranslation } from "i18n";

export const useLoadedDoctorExperience = () => {
  const t = useTranslation();
  const { user } = useDoctor();
  const context = useLoadedExperience();

  const otherDoctors = context.experience.allDoctors.filter(
    (d) => d.uuid !== user.uuid,
  );

  return {
    ...context,
    otherDoctors,
    title:
      context.experience.title ??
      (otherDoctors.length <= 1
        ? "Conversation"
        : t(
            "doctors_conversation.use_loaded_doctor_experience.group_conversation",
          )),
  };
};
