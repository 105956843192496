import { ClickableIcon } from "components/Icon/ClickableIcon";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useTranslation } from "i18n";

export const DeleteMessageButton = ({
  onDelete,
  isScheduled,
}: {
  onDelete: () => void;
  isScheduled?: boolean;
}) => {
  const t = useTranslation();

  return (
    <DeletionConfirmationModal
      suffix={t(
        "conversation_content.timeline.message_container.delete_message_button.to_delete_this_message",
      )}
      onConfirm={onDelete}
    >
      {(show) => (
        <TooltipWrapper
          label={t(
            "conversation_content.timeline.message_container.delete_message_button.tooltip_button",
          )}
          position={isScheduled ? "top" : "bottom"}
        >
          <ClickableIcon
            name="trash"
            className="invisible group-hover:visible p-4 hover:bg-grey-100 rounded"
            onClick={() => show()}
          />
        </TooltipWrapper>
      )}
    </DeletionConfirmationModal>
  );
};
