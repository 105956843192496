import { useState } from "react";
import { parse } from "date-fns";
import gql from "graphql-tag";

import { Maybe } from "base-types";
import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { FormDateInput } from "components/Form/DatePicker/FormDateInput";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { AsyncFormSelect } from "components/Form/Select/AsyncFormSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormModal } from "components/Modal/FormModal";
import { Query } from "components/Query/Query";
import {
  AperoPatientSubscriberRelationship,
  AperoPatientSubscriberRelationshipKnownValues,
  AperoPayer,
  AperoPayerFragment,
  GetAperoPayers,
  GetPatientWithApero,
  PatientManagementPatientFragment,
  PatientWithAperoFragment,
  Sex,
  SexKnownValues,
  UpdatePatientAdministrativeData,
  UpdatePatientCoverage,
  UpdatePatientGuarantor,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { parseLocalDate } from "utils/date";
import { displaySex } from "utils/display";
import { notifier } from "utils/notifier";
import { USStateCode, US_STATES_CODE } from "utils/us-states";

gql`
  query AperoPayers($search: String) {
    aperoPayers(search: $search) {
      results {
        ...AperoPayer
      }
    }
  }

  query AperoPayer($payerId: String!) {
    aperoPayer(payerId: $payerId) {
      aperoPayer {
        ...AperoPayer
      }
    }
  }

  mutation UpdatePatientCoverage(
    $patientUuid: UUID!
    $coverageInput: UpdatePatientCoverageInput!
  ) {
    updatePatientCoverages(
      input: { patientUuid: $patientUuid, coverages: [$coverageInput] }
    ) {
      patient {
        ...PatientWithApero
      }
    }
  }

  mutation UpdatePatientGuarantor(
    $patientUuid: UUID!
    $guarantors: [UpdatePatientGuarantorInput!]!
  ) {
    updatePatientGuarantors(
      input: { patientUuid: $patientUuid, guarantors: $guarantors }
    ) {
      patient {
        ...PatientWithApero
      }
    }
  }
`;

// FIXME(@liautaud): The dates here should be using `LocalDate`.
type FormValues = {
  sex: Sex | undefined;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: USStateCode | undefined;
  postcode: string;

  guarantorFirstName: string;
  guarantorLastName: string;
  guarantorEmail: string;
  guarantorPhone: string;
  guarantorAddress: string;
  guarantorCity: string;
  guarantorZipCode: string;

  subscriberPatientRelationship: AperoPatientSubscriberRelationship;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberDateOfBirth: string;
  subscriberMembershipId: string;
  subscriberPlan: string;
  subscriberInsurancePayer: AperoPayerFragment | undefined;
};

export const PatientAperoForm = ({
  patient,
  onHide,
}: {
  patient: PatientManagementPatientFragment;
  onHide: () => void;
}) => (
  <Query query={GetPatientWithApero} variables={{ patientUuid: patient.uuid }}>
    {(aperoPatientData) => (
      <FormContent
        patient={patient}
        aperoPatient={aperoPatientData.patient}
        onHide={onHide}
      />
    )}
  </Query>
);

const FormContent = ({
  patient,
  aperoPatient,
  onHide,
}: {
  patient: PatientManagementPatientFragment;
  aperoPatient: PatientWithAperoFragment;
  onHide: () => void;
}) => {
  const aperoPayerId =
    aperoPatient.aperoPatient?.coverages.at(0)?.tradingPartnerId;

  return aperoPayerId !== undefined && aperoPayerId !== null ? (
    <Query query={AperoPayer} variables={{ payerId: aperoPayerId }}>
      {(aperoPayerData) => (
        <PatientAperoFormContent
          patient={patient}
          onHide={onHide}
          aperoPayerPatient={aperoPayerData.aperoPayer}
          patientWithApero={aperoPatient}
        />
      )}
    </Query>
  ) : (
    <PatientAperoFormContent
      patient={patient}
      onHide={onHide}
      aperoPayerPatient={null}
      patientWithApero={aperoPatient}
    />
  );
};

export const PatientAperoFormContent = ({
  patient,
  aperoPayerPatient,
  patientWithApero,
  onHide,
}: {
  patient: PatientManagementPatientFragment;
  aperoPayerPatient: Maybe<AperoPayerFragment>;
  patientWithApero: PatientWithAperoFragment;
  onHide: () => void;
}) => {
  const t = useTranslation();
  const coverage = patientWithApero.aperoPatient?.coverages.at(0);
  const guarantor = patientWithApero.aperoPatient?.guarantors.at(0);

  const subscriberDateOfBirthParsed = coverage?.subscriberDateOfBirth
    ? new Date(coverage.subscriberDateOfBirth).toISOString().format("date")
    : null;

  const [updatePatient] = useMutation(UpdatePatientAdministrativeData);
  const [updatePatientCoverage] = useMutation(UpdatePatientCoverage);
  const [updatePatientGuarantor] = useMutation(UpdatePatientGuarantor);

  const [isPatientSameAsGuarantor, setIsPatientSameAsGuarantor] = useState(
    guarantor === undefined,
  );
  const isPatientSameAsSubscriberInitialValue =
    coverage === undefined ||
    (coverage.subscriberFirstName === patient.firstName &&
      coverage.subscriberLastName === patient.lastName &&
      subscriberDateOfBirthParsed === patient.birthDate);
  const [isPatientSameAsSubscriber, setIsPatientSameAsSubscriber] = useState(
    isPatientSameAsSubscriberInitialValue,
  );

  return (
    <FormModal<FormValues>
      title={t("patients.patient_apero_form.apero_details")}
      initialValues={{
        firstName: patient.firstName,
        lastName: patient.lastName,
        sex: patient.sex ?? undefined,
        dateOfBirth: patient.birthDate?.formatDateInLocale() ?? "",
        email: patient.email ?? "",
        phone: patient.phoneV2 ?? "",
        address: patient.address ?? "",
        city: patient.city ?? "",
        state: (patient.state as USStateCode | undefined) ?? undefined,
        postcode: patient.postcode ?? "",
        guarantorFirstName: guarantor?.firstName ?? "",
        guarantorLastName: guarantor?.lastName ?? "",
        guarantorEmail: guarantor?.email ?? "",
        guarantorPhone: guarantor?.phone ?? "",
        guarantorAddress: guarantor?.address ?? "",
        guarantorCity: guarantor?.city ?? "",
        guarantorZipCode: guarantor?.city ?? "",
        subscriberFirstName: isPatientSameAsSubscriberInitialValue
          ? ""
          : coverage.subscriberFirstName ?? "",
        subscriberLastName: isPatientSameAsSubscriberInitialValue
          ? ""
          : coverage.subscriberLastName ?? "",
        subscriberDateOfBirth: isPatientSameAsSubscriberInitialValue
          ? ""
          : subscriberDateOfBirthParsed ?? "",
        subscriberPatientRelationship:
          coverage?.subscriberRelationship ?? "SELF",
        subscriberMembershipId: coverage?.subscriberId ?? "",
        subscriberPlan: coverage?.subscriberPlan ?? "",
        subscriberInsurancePayer: aperoPayerPatient ?? undefined,
      }}
      validationSchema={{
        firstName: "required",
        lastName: "required",
        dateOfBirth: "required",
        address: "required",
        postcode: "required",
        city: "required",
        state: "required",
        subscriberInsurancePayer: "required",
        subscriberMembershipId: "required",
      }}
      onSubmit={(values) =>
        updatePatient({
          input: {
            patientUuid: patient.uuid,
            firstName: values.firstName.trimOrNull(),
            lastName: values.lastName.trimOrNull(),
            sex: values.sex,
            birthDate: values.dateOfBirth
              ? parseLocalDate(values.dateOfBirth)
              : null,
            email: values.email.trimOrNull(),
            phone: values.phone.trimOrNull(),
            address: values.address.trimOrNull(),
            postcode: values.postcode.trimOrNull(),
            city: values.city.trimOrNull(),
            state: values.state?.trimOrNull(),
          },
        })
          .then(() =>
            updatePatientGuarantor({
              patientUuid: patient.uuid,
              guarantors: isPatientSameAsGuarantor
                ? []
                : [
                    {
                      firstName: values.guarantorFirstName.trim(),
                      lastName: values.guarantorLastName.trim(),
                      email: values.guarantorEmail.trimOrNull(),
                      phone: values.guarantorPhone.trimOrNull(),
                      address: values.guarantorAddress.trimOrNull(),
                      city: values.guarantorCity.trimOrNull(),
                      zipCode: values.guarantorZipCode.trimOrNull(),
                    },
                  ],
            }),
          )
          .then(() =>
            updatePatientCoverage(
              {
                patientUuid: patient.uuid,
                coverageInput: {
                  tradingPartnerId:
                    values.subscriberInsurancePayer?.tradingPartnerId ?? "",
                  subscriberId: values.subscriberMembershipId,
                  subscriberFirstName: isPatientSameAsSubscriber
                    ? values.firstName
                    : values.subscriberFirstName,
                  subscriberLastName: isPatientSameAsSubscriber
                    ? values.lastName
                    : values.subscriberLastName,
                  subscriberDateOfBirth: parse(
                    isPatientSameAsSubscriber
                      ? values.dateOfBirth
                      : values.subscriberDateOfBirth,
                    t("common.date_format"),
                    new Date(),
                  )
                    .toISOString()
                    .format({ exception: "yyyy-MM-dd" }),
                  subscriberPlan: values.subscriberPlan,
                },
              },
              {
                onSuccess: (_, client) => {
                  notifier.success(
                    t("patient_apero_form.apero_details_updated"),
                  );
                  client.evictQuery("patient", "patientUuid");
                  onHide();
                },
              },
            ),
          )
      }
      onHide={onHide}
      showSubmitButton={false}
      submitLabel={t("patients.patient_apero_form.submit")}
    >
      <FormState<FormValues>>
        {({ setFieldValue }) => (
          <>
            <div className="flex items-center space-x-6">
              <FormInput
                name="firstName"
                label={t("patients.patient_apero_form.first_name")}
                wrapperClassName="flex-fill"
              />
              <FormInput
                name="lastName"
                label={t("patients.patient_apero_form.last_name")}
                wrapperClassName="flex-fill"
              />
            </div>
            <div className="flex items-center space-x-6">
              <FormSelect
                name="sex"
                options={SexKnownValues}
                placeholder={t("patients.patient_apero_form.chose")}
                label={t("patients.patient_apero_form.gender")}
                getOptionLabel={displaySex}
                wrapperClassName="flex-fill"
              />
              <FormDateInput
                name="dateOfBirth"
                label={t("patients.patient_apero_form.date_of_birth")}
                wrapperClassName="flex-fill"
              />
            </div>
            <div className="flex items-center space-x-6">
              <FormInput
                name="email"
                label={t("patients.patient_apero_form.email_address")}
                placeholder={t("patients.patient_apero_form.emailexamplecom")}
                wrapperClassName="flex-fill"
              />
              <FormInput
                name="phone"
                label={t("patients.patient_apero_form.phone")}
                placeholder={t("patients.patient_apero_form.phone")}
                wrapperClassName="flex-fill"
              />
            </div>
            <div className="flex items-center space-x-6">
              <FormInput
                name="address"
                label={t("patients.patient_apero_form.address")}
                placeholder={t("patients.patient_apero_form.address")}
                wrapperClassName="flex-fill"
              />
              <FormInput
                name="city"
                label={t("patients.patient_apero_form.city")}
                wrapperClassName="flex-fill"
              />
            </div>
            <div className="flex items-center space-x-6">
              <FormSelect
                name="state"
                label={t("patients.patient_apero_form.state")}
                options={US_STATES_CODE}
                wrapperClassName="flex-fill"
              />
              <FormInput
                name="postcode"
                label={t("patients.patient_apero_form.zip_code")}
                wrapperClassName="flex-fill"
              />
            </div>
            <div className="bg-body" style={{ height: 1 }} />
            <div className="text-16 font-bold text-primary-dark">Guarantor</div>
            <CheckBox
              name="isPatientSameAsGuarantor"
              label={t("patient_apero_form.guarantor_is_the_patient")}
              checked={isPatientSameAsGuarantor}
              onChange={setIsPatientSameAsGuarantor}
            />
            {!isPatientSameAsGuarantor && (
              <>
                <div className="flex items-center space-x-6">
                  <FormInput
                    name="guarantorFirstName"
                    label={t("patients.patient_apero_form.first_name")}
                    wrapperClassName="flex-fill"
                  />
                  <FormInput
                    name="guarantorLastName"
                    label={t("patients.patient_apero_form.last_name")}
                    wrapperClassName="flex-fill"
                  />
                </div>
                <div className="flex items-center space-x-6">
                  <FormInput
                    name="guarantorEmail"
                    label={t("patients.patient_apero_form.email_address")}
                    placeholder={t(
                      "patients.patient_apero_form.emailexamplecom",
                    )}
                    wrapperClassName="flex-fill"
                  />
                  <FormInput
                    name="guarantorPhone"
                    label={t("patients.patient_apero_form.phone")}
                    placeholder={t("patients.patient_apero_form.phone")}
                    wrapperClassName="flex-fill"
                  />
                </div>
                <FormInput
                  name="guarantorAddress"
                  label={t("patients.patient_apero_form.address")}
                  wrapperClassName="flex-fill"
                />
                <div className="flex items-center space-x-6">
                  <FormInput
                    name="guarantorCity"
                    label={t("patients.patient_apero_form.city")}
                    wrapperClassName="flex-fill"
                  />
                  <FormInput
                    name="guarantorZipCode"
                    label={t("patients.patient_apero_form.postcode")}
                    wrapperClassName="flex-fill"
                  />
                </div>
              </>
            )}
            <div className="bg-body" style={{ height: 1 }} />
            <div className="text-16 font-bold text-primary-dark">
              Subscriber
            </div>
            <CheckBox
              name="isPatientSameAsSubscriber"
              label={t("patient_apero_form.subscriber_is_the_patient")}
              checked={isPatientSameAsSubscriber}
              onChange={(value) => {
                setIsPatientSameAsSubscriber(value);
                setFieldValue("subscriberPatientRelationship", "SELF");
              }}
            />
            <FormSelect
              name="subscriberPatientRelationship"
              label={t("patient_apero_form.subscriber_patient_relationship")}
              options={AperoPatientSubscriberRelationshipKnownValues}
              disabled={isPatientSameAsSubscriber}
            />
            {!isPatientSameAsSubscriber && (
              <>
                <div className="flex items-center space-x-6">
                  <FormInput
                    name="subscriberFirstName"
                    label={t("patients.patient_apero_form.first_name")}
                    placeholder={t("patients.patient_apero_form.first_name")}
                    wrapperClassName="flex-fill"
                  />
                  <FormInput
                    name="subscriberLastName"
                    label={t("patients.patient_apero_form.last_name")}
                    placeholder={t("patients.patient_apero_form.last_name")}
                    wrapperClassName="flex-fill"
                  />
                </div>
                <div className="flex items-center space-x-6">
                  <FormDateInput
                    name="subscriberDateOfBirth"
                    label={t("patients.patient_apero_form.date_of_birth")}
                    wrapperClassName="flex-fill"
                  />
                </div>
              </>
            )}
            <AsyncFormSelect
              selectProps={{
                name: "subscriberInsurancePayer",
                label: t("patients.patient_apero_form.payer"),
                getOptionLabel: (o: AperoPayerFragment) => o.name,
              }}
              queryProps={{ query: GetAperoPayers }}
              getVariables={(s) => ({ search: s })}
              getOptions={(data) => data?.results ?? []}
            />
            <div className="flex items-center space-x-6">
              <FormInput
                name="subscriberMembershipId"
                label={t("patients.patient_apero_form.membership_id")}
                wrapperClassName="flex-fill"
              />
              <FormInput
                name="subscriberPlan"
                label={t("patients.patient_apero_form.plan")}
                wrapperClassName="flex-fill"
              />
            </div>
            <div className="mt-36 flex items-center space-x-12">
              <Button
                label="See in Apero"
                className="flex-fill"
                secondary
                disabled={patientWithApero.aperoPatient === null}
                onClick={() => {
                  if (patientWithApero.aperoPatient !== null) {
                    window.open(
                      `https://app.aperohealth.com/dash/patients/${patientWithApero.aperoPatient.id}`,
                    );
                  }
                }}
              />

              <Submit
                className=" flex-fill"
                label={t("patients.patient_apero_form.submit")}
              />
            </div>
          </>
        )}
      </FormState>
    </FormModal>
  );
};
