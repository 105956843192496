import { CSSProperties, ReactElement } from "react";
import classNames from "classnames";

import { Icon, IconProps } from "components/Icon/Icon";
import { IconName } from "icon-library";

export type PillButtonProps = {
  label: string;
  leftIcon?: IconName | Omit<IconProps, "size"> | ReactElement;
  rightIcon?: IconName | Omit<IconProps, "size"> | ReactElement;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
};

const PillButtonIcon = ({
  icon,
}: {
  icon: PillButtonProps["leftIcon"] | PillButtonProps["rightIcon"];
}) => {
  if (!icon) return null;

  return typeof icon === "string" ? (
    <Icon size={16} name={icon} className="mr-2 -ml-2" />
  ) : "name" in icon ? (
    <Icon size={16} {...icon} />
  ) : (
    icon
  );
};

export const PillButton = ({
  label,
  leftIcon,
  rightIcon,
  onClick,
  className,
  style,
}: PillButtonProps) => (
  <div
    className={classNames(
      "flex items-center px-10 py-6 bg-grey-100 hover:brightness-95 transition rounded-full leading-none font-medium text-12",
      !!onClick && "cursor-pointer",
      className,
    )}
    style={style}
    onClick={onClick}
  >
    <PillButtonIcon icon={leftIcon} />
    {label}
    <PillButtonIcon icon={rightIcon} />
  </div>
);
