import { useEffect } from "react";

import { staticT as t } from "i18n";

let temporaryStatus: {
  title: string;
  showTitle: boolean;
  showFaviconBadge: boolean;
  interval: NodeJS.Timeout;
} | null;

let pageTitle: string | null = null;
let faviconUrl: string | null = null;

export const flashTemporaryStatus = (
  title: string,
  showFaviconBadge = true,
) => {
  if (temporaryStatus) clearInterval(temporaryStatus.interval);
  const interval = setInterval(() => {
    if (!temporaryStatus) {
      clearInterval(interval);
      return;
    }

    temporaryStatus.showTitle = !temporaryStatus.showTitle;
    updatePageTitle();
  }, 1000);

  temporaryStatus = {
    title,
    showFaviconBadge,
    showTitle: true,
    interval,
  };

  updatePageTitle();
  updateFavicon();
};

export const clearTemporaryStatus = () => {
  if (!temporaryStatus) return;
  clearInterval(temporaryStatus.interval);
  temporaryStatus = null;
  updatePageTitle();
  updateFavicon();
};

export const setWhiteLabelPageTitle = (title: string) => {
  pageTitle = title;
  updatePageTitle();
};

export const setNablaPageTitle = () => {
  pageTitle = t("utils.intl.nabla_console");
  updatePageTitle();
};

export const clearPageTitle = () => {
  pageTitle = null;
  updatePageTitle();
};

export const updatePageTitle = () => {
  document.title =
    (temporaryStatus?.showTitle ? temporaryStatus.title : null) ??
    pageTitle ??
    "";
};

export const setWhiteLabelFaviconUrl = (url: string) => {
  faviconUrl = url;
  updateFavicon();
};

export const setNablaFaviconUrl = () => {
  faviconUrl = "/favicon.png";
  updateFavicon();
};

export const clearFaviconUrl = () => {
  faviconUrl = null;
  updateFavicon();
};

export const updateFavicon = () => {
  const link = document.getElementById("favicon") as HTMLLinkElement;

  if (faviconUrl && temporaryStatus?.showFaviconBadge) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return;
    const image = new Image();
    image.crossOrigin = "anonymous";

    // Load the favicon into a canvas and add a red badge to it dynamically.
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      context.fillStyle = "red";
      context.beginPath();
      const diameter = Math.min(image.width, image.height) / 5;
      context.arc(image.width - diameter, diameter, diameter, 0, 2 * Math.PI);
      context.fill();
      link.href = canvas.toDataURL();
    };
    image.src = faviconUrl;
  } else {
    link.href = faviconUrl ?? "";
  }
};

export const usePageTitleAndFavicon = ({
  whiteLabelTitle,
  whiteLabelFaviconUrl,
}: {
  whiteLabelTitle: string | null;
  whiteLabelFaviconUrl: string | null;
}) => {
  useEffect(() => {
    whiteLabelTitle
      ? setWhiteLabelPageTitle(whiteLabelTitle)
      : setNablaPageTitle();
    whiteLabelFaviconUrl
      ? setWhiteLabelFaviconUrl(whiteLabelFaviconUrl)
      : setNablaFaviconUrl();
    return () => {
      clearPageTitle();
      clearFaviconUrl();
    };
  }, [whiteLabelTitle, whiteLabelFaviconUrl]);
};
