import { Route, Routes } from "react-router-dom";

import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";

import { MedicalVerificationInbox } from "./MedicalVerificationInbox";
import {
  NewRecordedConversation,
  RecordedConversation,
} from "./RecordedConversation";
import { RecordedConversationsTable } from "./RecordedConversationsTable";
import { FileUploadTranscriptionAnnotation } from "./TranscriptAnnotation";
import { TranscriptInbox } from "./TranscriptInbox";

export const RecordedConversations = () => {
  const t = useTranslation();
  return (
    <div className="flex-col bg-white h-full flex-fill bg-light-blue">
      <div className="bg-light-black flex py-16 px-24 items-center space-x-20">
        <Icon name="logo" size={24} color="white" />
        <div className="flex-fill text-white">
          {t("recorded_conversations.main_header")}
        </div>
      </div>
      <div className="flex-fill flex-col w-full overflow-y-auto">
        <Routes>
          <Route path="/" element={<RecordedConversationsTable />} />
          <Route path="/transcript-inbox" element={<TranscriptInbox />} />
          <Route
            path="/medical-verification"
            element={<MedicalVerificationInbox />}
          />
          <Route path="/new" element={<NewRecordedConversation />} />
          <Route path="/:uuid" element={<RecordedConversation />} />
          <Route
            path="/file-upload/:uuid/transcript"
            element={<FileUploadTranscriptionAnnotation />}
          />
        </Routes>
      </div>
    </div>
  );
};
