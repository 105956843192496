import { RefObject } from "react";

import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { AsyncDoctorFormSelect } from "components/Form/Select/AsyncDoctorFormSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { usePatient } from "contexts/PatientContext/PatientContext";
import {
  CreateTask,
  DoctorSummaryFragment,
  TaskPriority,
  TaskPriorityKnownValues,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayTaskPriorityKnownValue } from "utils/display";
import { notifier } from "utils/notifier";

import { FooterWrapper } from "../FooterWrapper";

type FormValues = {
  name: string;
  description: string | null;
  priority: TaskPriority | undefined;
  assignedDoctor: DoctorSummaryFragment | undefined;
};

export const EHRTaskComposer = ({
  inputRef,
}: {
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const t = useTranslation();
  const { patient, setEHRComposerState } = usePatient();

  const [createTask] = useMutation(CreateTask, {
    onSuccess: (_, client) => {
      setEHRComposerState(undefined);
      client.evictQuery("taskSearch", "filter");
      notifier.success(
        t(
          "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.task_added",
        ),
      );
    },
  });

  return (
    <Form<FormValues>
      initialValues={{
        name: "",
        description: "",
        priority: undefined,
        assignedDoctor: undefined,
      }}
      onSubmit={async ({ name, description, priority, assignedDoctor }) => {
        await createTask({
          input: {
            title: name,
            description,
            priority,
            patientUuid: patient.uuid,
            assignedDoctorUuid: assignedDoctor?.uuid,
          },
        });
      }}
      className="w-full flex-col flex-fill space-y-8"
    >
      <FormInput
        name="name"
        inputRef={inputRef}
        label={t(
          "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.task_name",
        )}
        placeholder={t(
          "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.enter_a_name",
        )}
      />
      <FormTextArea
        name="description"
        label={t(
          "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.description",
        )}
        placeholder={t(
          "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.add_a_description",
        )}
        className="flex-fill"
        wrapperClassName="flex-fill"
      />
      <div className="flex items-start space-x-6">
        <FormSelect
          name="priority"
          isClearable
          wrapperClassName="flex-fill"
          options={TaskPriorityKnownValues}
          placeholder={t(
            "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.select",
          )}
          label={t(
            "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.priority",
          )}
          getOptionLabel={(s) => displayTaskPriorityKnownValue(s)}
        />
        <AsyncDoctorFormSelect
          name="assignedDoctor"
          getVariables={(s) => ({
            filter: { permissions: ["VIEW_TASKS"], freeTextSearch: s },
          })}
          wrapperClassName="flex-fill"
          label={t(
            "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.assignee",
          )}
          isClearable
        />
      </div>
      <FooterWrapper
        submitLabel={t(
          "inboxes.qa_experience.ehr_composer.task_composer.ehr_task_composer.create",
        )}
        inputRef={inputRef}
      />
    </Form>
  );
};
