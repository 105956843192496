import gql from "graphql-tag";

import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { FormState } from "components/Form/Form/FormState";
import { FormInput } from "components/Form/Input/FormInput";
import { AsyncDoctorFormSelect } from "components/Form/Select/AsyncDoctorFormSelect";
import { AsyncPatientFormSelect } from "components/Form/Select/AsyncPatientFormSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { FormModal } from "components/Modal/FormModal";
import { InfoTooltip } from "components/Tooltip/InfoTooltip";
import {
  CreateTask,
  DoctorSummaryFragment,
  PatientSummaryFragment,
  TaskFragment,
  TaskPriority,
  TaskPriorityKnownValues,
  UpdateTask,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { displayTaskPriorityKnownValue } from "utils/display";
import { notifier } from "utils/notifier";

gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(createTaskInput: $input) {
      task {
        ...Task
      }
    }
  }

  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(updateTaskInput: $input) {
      task {
        ...Task
      }
    }
  }
`;

type FormValues = {
  name: string;
  description: string | null;
  priority: TaskPriority | undefined;
  patient: PatientSummaryFragment | undefined;
  assignedDoctor: DoctorSummaryFragment | undefined;
  isPatientTask: boolean;
};

export const TaskComposer = ({
  task,
  onClose,
  onSuccess,
}: {
  task?: TaskFragment;
  onClose: () => void;
  onSuccess?: () => void;
}) => {
  const t = useTranslation();
  const [createTask] = useMutation(CreateTask, {
    onSuccess: (_, client) => {
      client.evictQuery("taskSearch", "filter");
      notifier.success(t("tasks.task_composer.task_created"));
      onClose();
      onSuccess?.();
    },
  });

  const [updateTask] = useMutation(UpdateTask, {
    onSuccess: () => {
      notifier.success(t("tasks.task_composer.task_edited"));
      onClose();
      onSuccess?.();
    },
  });

  return (
    <FormModal<FormValues>
      title={
        task
          ? t("tasks.task_composer.edit_a_task")
          : t("tasks.task_composer.add_a_task")
      }
      submitLabel={
        task ? t("tasks.task_composer.save") : t("tasks.task_composer.create")
      }
      onHide={onClose}
      className="flex-col w-full"
      initialValues={{
        name: task?.title ?? "",
        description: task?.description ?? "",
        priority: task?.priority ?? undefined,
        patient: task?.patient ?? undefined,
        assignedDoctor: task?.assignedDoctor ?? undefined,
        isPatientTask: task?.isPatientTask ?? false,
      }}
      validationSchema={{
        name: "required",
      }}
      onSubmit={async ({
        name,
        description,
        priority,
        patient,
        assignedDoctor,
        isPatientTask,
      }) => {
        if (task) {
          await updateTask({
            input: {
              taskUuid: task.uuid,
              title: name,
              description,
              priority: priority ?? null,
              patientUuid: patient?.uuid ?? null,
              assignedDoctorUuid: isPatientTask
                ? null
                : assignedDoctor?.uuid ?? null,
              isPatientTask,
            },
          });
        } else {
          await createTask({
            input: {
              title: name,
              description,
              priority,
              patientUuid: patient?.uuid,
              assignedDoctorUuid: isPatientTask ? null : assignedDoctor?.uuid,
              isPatientTask,
            },
          });
        }
      }}
    >
      <>
        <FormInput
          name="name"
          label={t("tasks.task_composer.task_name")}
          placeholder={t("tasks.task_composer.enter_a_name")}
          wrapperClassName="flex-fill justify-between h-full"
        />
        <FormTextArea
          name="description"
          label={t("tasks.task_composer.description")}
          placeholder={t("task_composer.add_a_description")}
          className="flex-fill justify-between h-full"
        />
        <div>
          <FormSelect
            name="priority"
            isClearable
            wrapperClassName="flex-fill"
            options={TaskPriorityKnownValues}
            placeholder={t("tasks.task_composer.select")}
            label={t("tasks.task_composer.priority")}
            getOptionLabel={(s) => displayTaskPriorityKnownValue(s)}
          />
        </div>
        <AsyncPatientFormSelect
          name="patient"
          wrapperClassName="flex-fill justify-between h-full"
        />
        <div className="flex items-start space-x-6">
          <FormState<FormValues>>
            {({ values }) => (
              <AsyncDoctorFormSelect
                name="assignedDoctor"
                isClearable
                wrapperClassName="flex-fill"
                label={t("tasks.task_composer.assignee")}
                getVariables={(s) => ({
                  filter: { permissions: ["VIEW_TASKS"], freeTextSearch: s },
                })}
                disabled={values.isPatientTask}
              />
            )}
          </FormState>
        </div>
        <FormState<FormValues>>
          {({ values }) => (
            <div className="flex items-start space-x-6">
              <FormCheckbox
                name="isPatientTask"
                label={t("tasks.task_composer.patient_task")}
                disabled={!values.patient}
              />
              <InfoTooltip
                label={t("tasks.task_composer.patient_task_tooltip")}
                position="top"
              />
            </div>
          )}
        </FormState>
      </>
    </FormModal>
  );
};
