import { useMemo, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { ConversationContent } from "components/ConversationContent/ConversationContent";
import { PatientNoDeviceWarning } from "components/ConversationHeader/PatientNoDeviceWarning";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { MoreMenu } from "components/Menu/MoreMenu";
import { useChatWidgets } from "contexts/ChatWidgetsContext/ChatWidgetsContext";
import { useExperienceDraft } from "contexts/Experience/ExperienceContext";
import { useDoctor } from "contexts/User/UserContext";
import { GetPatientViewPatientDataData } from "generated/provider";
import { useTranslation } from "i18n";
import { getPatientViewRoute, getQAInboxRoute } from "utils";

import { QACopilotPanelContext } from "../../../Inboxes/QACopilotContext/QACopilotPanelContext";
import { QAExperienceName } from "../../../Inboxes/QAExperience/QAExperienceHeader/QAExperienceName";
import { QAComposer } from "../../../Inboxes/QAExperience/QAFooter/QAComposer";
import { QAFooter } from "../../../Inboxes/QAExperience/QAFooter/QAFooter";
import { useAppendToQAComposer } from "../../../Inboxes/QAExperience/useAppendToQAComposer";
import { useMaybeQAExperience } from "../../../Inboxes/QAExperienceContext/QAExperienceContext";
import {
  NewQAExperienceProvider,
  QAExperienceProvider,
} from "../../../Inboxes/QAExperienceContext/QAExperienceProvider";
import { Search } from "../../../Inboxes/QASidePanel/SearchPanel/Search";

export const ChatWidget = ({
  patient,
  experienceUuid,
  minimized,
  onToggleMinimize,
  onClose,
}: {
  patient: GetPatientViewPatientDataData["patient"];
  onClose: () => void;
} & (
  | {
      // New experiences.
      experienceUuid: undefined;
      minimized?: false;
      onToggleMinimize?: undefined;
    }
  | {
      // Existing experiences.
      experienceUuid: UUID;
      minimized: boolean;
      onToggleMinimize: () => void;
    }
)) =>
  experienceUuid === undefined ? (
    <NewQAExperienceProvider patientUuid={patient.uuid}>
      <ChatWidgetContent onClose={onClose} minimized={false} />
    </NewQAExperienceProvider>
  ) : (
    <QAExperienceProvider patient={patient} uuid={experienceUuid} noSpinner>
      <ChatWidgetContent
        onClose={onClose}
        minimized={minimized}
        onToggleMinimize={onToggleMinimize}
      />
    </QAExperienceProvider>
  );

const ChatWidgetContent = ({
  minimized,
  onToggleMinimize,
  onClose,
}: {
  minimized: boolean;
  onToggleMinimize?: () => void;
  onClose: () => void;
}) => {
  const { uuid, experience, patient } = useMaybeQAExperience();
  const { clearDraft } = useExperienceDraft();
  const appendToQAComposer = useAppendToQAComposer();
  const { openChatWidget, closeNewChatWidget } = useChatWidgets();
  const navigate = useNavigate();
  const { user } = useDoctor();
  const t = useTranslation();

  const [isCopilotPanelOpened, setIsCopilotPanelOpened] = useState(false);
  const onContentSelected = useMemo(
    () =>
      appendToQAComposer
        ? (value: string) => {
            appendToQAComposer(value);
            setIsCopilotPanelOpened(false);
          }
        : null,
    [appendToQAComposer, setIsCopilotPanelOpened],
  );

  return (
    <QACopilotPanelContext.Provider
      value={{
        isCopilotPanelOpened,
        openCopilotPanel: () => setIsCopilotPanelOpened(true),
        closeCopilotPanel: () => setIsCopilotPanelOpened(false),
      }}
    >
      <div
        className={classNames(
          "flex bg-white border rounded-t shadow-widget relative z-chat-widget pointer-events-auto",
          { "h-[600px]": !minimized },
        )}
      >
        {isCopilotPanelOpened && !minimized && (
          <div className="flex w-chat-widget border-r">
            <Search
              experienceUuid={experience?.uuid ?? null}
              onContentSelected={onContentSelected}
            />
          </div>
        )}

        <div className="flex-col w-chat-widget">
          <div
            className={classNames(
              "flex items-center px-16 space-x-6 border-b min-h-[56px]",
              { "cursor-pointer": !!onToggleMinimize },
            )}
            onClick={onToggleMinimize}
          >
            <Icon name="doubleChatBubble" className="text-grey-400" />
            <div className="font-medium leading-normal text-grey-400 flex-fill mt-[0.5px]">
              {experience ? (
                <QAExperienceName {...experience} />
              ) : uuid ? (
                <div className="animate-pulse rounded bg-grey-200/50 h-10 ml-10 max-w-[150px]" />
              ) : (
                t("chat_widget.new_conversation")
              )}
            </div>
            <div className="flex">
              {experience && !minimized && (
                <MoreMenu
                  items={[
                    {
                      text: t("chat_widget.show_associated_note"),
                      to: getPatientViewRoute(patient, [experience]),
                    },
                    {
                      text: t("chat_widget.see_in_conversations"),
                      to: getQAInboxRoute(experience, user.uuid),
                    },
                  ]}
                  className="p-2"
                />
              )}
              {onToggleMinimize && (
                <ClickableIcon
                  name="chevron"
                  className="py-2"
                  size={24}
                  rotate={minimized ? -90 : 90}
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleMinimize();
                  }}
                />
              )}
              <ClickableIcon
                name="close"
                className="p-2"
                onClick={(e) => {
                  e.stopPropagation();
                  clearDraft();
                  onClose();
                }}
              />
            </div>
          </div>

          {!minimized && (
            <>
              {patient.devices.isEmpty() &&
                (!experience ||
                  experience.patientSeenUntil.getTime() === 0) && (
                  <PatientNoDeviceWarning />
                )}

              <ConversationContent compact />

              <div className="border-t">
                {experience ? (
                  <QAFooter compact />
                ) : (
                  <QAComposer
                    onCreateExperienceSuccess={(result) => {
                      openChatWidget(result.experience);
                      closeNewChatWidget();
                      navigate(
                        getPatientViewRoute(patient, [result.experience]),
                      );
                    }}
                    compact
                    autofocus
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </QACopilotPanelContext.Provider>
  );
};
