import gql from "graphql-tag";

import { Avatar } from "components/Avatar/Avatar";
import { Icon } from "components/Icon/Icon";
import { Query } from "components/Query/Query";
import { LiveDashboardQuery } from "generated/provider";
import { useTranslation } from "i18n";
import { displayDoctor } from "utils/display";

gql`
  query LiveDashboardQuery {
    liveDashboard {
      practitionerCurrentlyOpenedConversations {
        doctorsConversationCount {
          doctor {
            ...DoctorSummary
          }
          conversationCount
        }
        unassignedConversationCount
      }
    }
  }
`;

export const StatsDashboardLive = () => {
  const t = useTranslation();
  return (
    <div className="bg-white border rounded shadow-sm-outlined p-20 flex-col space-y-4 max-w-[600px] mt-8">
      <span className="font-bold text-12 flex-shrink">
        {t("stats_dashboard.live.title").toUpperCase()}
      </span>

      <Query query={LiveDashboardQuery}>
        {({ practitionerCurrentlyOpenedConversations }) => (
          <div className="flex-col space-y-12 mt-16">
            <div className="flex justify-between space-x-8 h-24 items-center">
              <Icon name="drawer" />
              <span className="text-black flex-grow">Unassigned</span>
              <span>
                {
                  practitionerCurrentlyOpenedConversations.unassignedConversationCount
                }
              </span>
            </div>
            {practitionerCurrentlyOpenedConversations.doctorsConversationCount.map(
              ({ conversationCount, doctor }) => (
                <div
                  className="flex justify-between space-x-8 h-24 items-center"
                  key={doctor.uuid}
                >
                  <Avatar user={doctor} size={20} />
                  <span className="text-black flex-grow">
                    {displayDoctor(doctor)}
                  </span>
                  <span className="">{conversationCount}</span>
                </div>
              ),
            )}
          </div>
        )}
      </Query>
    </div>
  );
};
