import { ReactNode, useEffect } from "react";
import gql from "graphql-tag";

import { MESSAGES_KEY } from "consts";
import { useDoctor } from "contexts/User/UserContext";
import {
  DoctorSummaryFragment,
  ExperienceContentFragmentProps,
  MessageFragment,
  SendMessage,
  TimelineItemFragment,
} from "generated/provider";
import { useGraphQLClient } from "graphql-client/GraphQLClientContext";
import { useMutation } from "graphql-client/useMutation";
import { addDoctorConversationInCache } from "utils/apollo";
import { now } from "utils/date";
import {
  fileUploadFromFile,
  getFileNameFromUpload,
  getFileTypeFromUpload,
} from "utils/file";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import {
  AddItemInCache,
  ExperienceStore,
  MessageContentInput,
  MessageInput,
  MessagesContext,
  SendingItem,
  Store,
} from "./MessagesContext";
import { useMessagesStore } from "./useMessagesStore";
import { useGetMessageContent } from "./utils";

gql`
  mutation SendMessage($experienceUuid: UUID!, $input: SendMessageInput!) {
    sendMessage(experienceUuid: $experienceUuid, input: $input) {
      message {
        ...NewMessage
      }
    }
  }
`;

export const MessagesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useDoctor();
  const client = useGraphQLClient().graphQLClients.PROVIDER;
  const [sendMessageMutation] = useMutation(SendMessage);
  const getMessageContent = useGetMessageContent();

  const [store, setStore] = useMessagesStore();

  useEffect(() => {
    const textOnlyStore = Object.entries(store).reduce<Store>(
      (acc, [uuid, value]) => {
        if (!value) return acc;
        acc[uuid] = {
          sendingItems: value.sendingItems.filter(
            (item) => "text" in item.content,
          ),
        };
        return acc;
      },
      {},
    );
    storage.setItem(MESSAGES_KEY, JSON.stringify(textOnlyStore));
  }, [store]);

  const setExperienceStore = (
    experienceUuid: UUID,
    callback: (current: ExperienceStore) => ExperienceStore,
  ) =>
    setStore((current) => ({
      ...current,
      [experienceUuid]: callback(
        current[experienceUuid] ?? {
          sendingItems: [],
        },
      ),
    }));

  const setSendingItems = (
    experienceUuid: UUID,
    callback: (current: SendingItem[]) => SendingItem[],
  ) =>
    setExperienceStore(experienceUuid, (current) => ({
      ...current,
      sendingItems: callback(current.sendingItems),
    }));

  const addItemInCache = ({ experienceUuid, item }: AddItemInCache) => {
    client.update({
      fragment: ExperienceContentFragmentProps,
      uuid: experienceUuid,
      skip: ({ itemsV3 }) =>
        "uuid" in item &&
        itemsV3.data.some((i) => i && "uuid" in i && i.uuid === item.uuid),
      write: ({ itemsV3 }) => {
        itemsV3.data = [item as TimelineItemFragment | null].concat(
          itemsV3.data,
        );
      },
    });

    if ("experience" in item && !item.experience.patient) {
      addDoctorConversationInCache(client, item.experience);
    }

    if (item.__typename === "Message") {
      setSendingItems(experienceUuid, (current) =>
        current.filter(({ uuid }) => uuid !== item.clientIdentifier),
      );
    }
  };

  const sendMessage = async (
    experienceUuid: UUID,
    localUuid: UUID,
    batchId: UUID | undefined,
    input: MessageContentInput,
    replyMessageUuid?: UUID,
    isForwarded?: boolean,
  ) => {
    const setFailed = () =>
      setSendingItems(experienceUuid, (current) =>
        current.map((i) => (i.uuid === localUuid ? { ...i, failed: true } : i)),
      );
    const messageContent = await getMessageContent({
      content: input,
      onUploadProgress: (percentage: number) => {
        setSendingItems(experienceUuid, (current) =>
          current.map((item) =>
            item.uuid === localUuid
              ? { ...item, percentageProgress: percentage }
              : item,
          ),
        );
      },
    });
    if (!messageContent) {
      setFailed();
      return;
    }
    sendMessageMutation(
      {
        experienceUuid,
        input: {
          content: messageContent,
          clientIdentifier: localUuid,
          batchIdentifier: batchId,
          replyMessageUuid,
          isForwarded: isForwarded ?? false,
        },
      },
      {
        onSuccess: ({ message }) => {
          addItemInCache({ experienceUuid, item: message });
        },
        onError: (_, fallback) => {
          setFailed();
          fallback(); // TODO: improve error message
        },
      },
    );
  };

  return (
    <MessagesContext.Provider
      value={{
        store,
        addItemInCache,
        sendMessage: (
          experienceUuid,
          { content, isForwarded, replyMessage },
          batchId,
        ) => {
          const localUuid = ephemeralUuidV4();
          setSendingItems(experienceUuid, (current) => [
            ...current,
            {
              ...getOptimisticMessage(
                localUuid,
                { content, isForwarded, replyMessage },
                {
                  ...user,
                  __typename: "Doctor",
                },
              ),
              replyMessage: replyMessage ?? null,
              inputContent: content,
              batchId,
            },
          ]);
          sendMessage(
            experienceUuid,
            localUuid,
            batchId,
            content,
            replyMessage?.uuid,
            isForwarded,
          );
        },
        retrySendMessage: (experienceUuid, localUuid) => {
          setSendingItems(experienceUuid, (current) =>
            current.map((i) =>
              i.uuid === localUuid ? { ...i, failed: false } : i,
            ),
          );
          const sendingItem = store[experienceUuid]?.sendingItems.find(
            (value) => value.uuid === localUuid,
          );
          if (!sendingItem) return; // Bad call
          sendMessage(
            experienceUuid,
            localUuid,
            sendingItem.batchId,
            sendingItem.inputContent,
            sendingItem.replyMessage?.uuid,
            sendingItem.isForwarded,
          );
        },
        deleteFailedMessage: (experienceUuid, localUuid) => {
          setSendingItems(experienceUuid, (current) =>
            current.filter((i) => i.uuid !== localUuid),
          );
        },
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

const getOptimisticMessage = (
  localUuid: UUID,
  { content, replyMessage, isForwarded, batchIdentifier }: MessageInput,
  user: DoctorSummaryFragment,
): MessageFragment => ({
  __typename: "Message",
  uuid: localUuid,
  eventTime: now(),
  clientIdentifier: localUuid,
  isForwarded: isForwarded ?? false,
  sender: user,
  senderType: "DOCTOR",
  replyMessage: replyMessage ?? null,
  batchIdentifier: batchIdentifier ?? null,
  reactions: [],
  extractedEHRSuggestions: [],
  assignedDoctor: null,
  handledAt: null,
  content:
    "text" in content
      ? {
          __typename: "TextMessageContent",
          text: content.text,
        }
      : "livekitRoom" in content
      ? {
          __typename: "LivekitRoomMessageContent",
          livekitRoom: {
            __typename: "LivekitRoom",
            uuid: ephemeralUuidV4(),
            status: {
              __typename: "LivekitRoomPendingStatus",
            },
            transcript: null,
          },
        }
      : "questionsSet" in content
      ? {
          __typename: "QuestionsSetFormMessageContent",
          form: {
            __typename: "QuestionsSetForm",
            id: ephemeralUuidV4(),
            state: "NOT_STARTED",
            questions: content.questionsSet.map((q) => ({
              __typename: "QuestionsSetQuestionWithAnswer",
              id: ephemeralUuidV4(),
              question: q,
              answer: null,
            })),
          },
        }
      : "type" in content && content.type === "FILE"
      ? {
          __typename: "FileMessageContent",
          fileUpload:
            "file" in content
              ? fileUploadFromFile(ephemeralUuidV4(), content.file)
              : content,
          fileTitle: getFileNameFromUpload(content),
        }
      : {
          __typename: (
            {
              FILE: "ImageMessageContent", // TODO before PR merge : What should I do here ?
              IMAGE: "ImageMessageContent",
              AUDIO: "AudioMessageContent",
              VIDEO: "VideoMessageContent",
            } as const
          )[getFileTypeFromUpload(content)],
          fileUpload:
            "file" in content
              ? fileUploadFromFile(ephemeralUuidV4(), content.file)
              : content,
        },
});
