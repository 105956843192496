import { useField } from "hooks/useField";
import { ICD10Code } from "types";

import { IcdCodePicker } from "./IcdCodePicker";

export const FormIcdCodePicker = ({
  name,
  label,
  disabled: disabledProp,
}: {
  name: string;
  label?: string;
  disabled?: boolean;
}) => {
  const [{ value, disabled }, { error }, { setValue }] = useField<ICD10Code[]>({
    name,
    disabled: disabledProp,
  });

  return (
    <IcdCodePicker
      value={value}
      label={label}
      disabled={disabled}
      error={error}
      onChange={setValue}
    />
  );
};
