import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMatch, useNavigate } from "react-router-dom";

import { DoctorFilter } from "components/DoctorSelect/DoctorSelect";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { BottomActionSheet } from "components/Menu/BottomActionSheet";
import { Spinner } from "components/Spinner/Spinner";
import { useDoctor } from "contexts/User/UserContext";
import {
  AppointmentFragment,
  DoctorWithAppointmentAddressFragment,
} from "generated/provider";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { getCalendarRoute } from "utils";

import { Calendar } from "./Calendar/Calendar";
import { AppointmentList } from "./List/AppointmentList";
import { useAvailableFilters } from "./utils";

gql`
  query Availabilities(
    $cursor: TimeAndUuidCursorInput
    $filter: AvailabilitiesFilter
  ) {
    availabilities(
      page: { cursor: $cursor, numberOfItems: 15 }
      filter: $filter
    ) {
      hasMore
      nextCursor {
        fromTime
        fromUuid
      }
      data {
        ...Availability
      }
    }
  }
`;

const useSelectedFilterWithDefault = (
  defaultDoctor: DoctorWithAppointmentAddressFragment,
) => {
  const navigate = useNavigate();
  const selectedDoctorUuid = useMatch(
    `${routes.SCHEDULING}/:selectedDoctorUuid`,
  )?.params.selectedDoctorUuid.trimOrNull();

  const { filters, loading } = useAvailableFilters();
  const selectedFilter = useMemo(
    () =>
      selectedDoctorUuid
        ? filters.find((doctor) => doctor.uuid === selectedDoctorUuid)
        : defaultDoctor,
    [defaultDoctor, filters, selectedDoctorUuid],
  );

  // Clear the UUID in the URL if it doesn't exist in the list of filters.
  useEffect(() => {
    if (!loading && !selectedFilter) navigate(getCalendarRoute(null));
  }, [loading, selectedFilter, navigate]);

  return {
    selectedFilter,
    setSelectedFilter: (doctor: DoctorFilter) =>
      navigate(getCalendarRoute(doctor)),
  };
};

export const Scheduling = () => {
  const t = useTranslation();
  const [isCalendarSelected, setIsCalendarSelected] = useState(false);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const isDesktop = useIsDesktop();

  const { user } = useDoctor();
  const { selectedFilter, setSelectedFilter } =
    useSelectedFilterWithDefault(user);

  const [selectedAppointment, setSelectedAppointment] =
    useState<AppointmentFragment>();

  if (!selectedFilter) return <Spinner />;

  return (
    <div className="flex flex-fill overflow-hidden">
      {showBottomSheet && (
        <BottomActionSheet
          items={[
            {
              icon: "calendar",
              text: t("scheduling.scheduling.calendar"),
              onClick: () => {
                setIsCalendarSelected(true);
                setShowBottomSheet(false);
              },
              className: classNames("py-36 text-primary-dark font-normal", {
                "bg-primary-50 ": isCalendarSelected,
              }),
            },
            {
              icon: "list",
              text: t("scheduling.scheduling.list"),
              onClick: () => {
                setIsCalendarSelected(false);
                setShowBottomSheet(false);
              },
              className: classNames("py-36 text-primary-dark font-normal", {
                "bg-primary-50 ": !isCalendarSelected,
              }),
            },
          ]}
          close={() => setShowBottomSheet(false)}
        />
      )}
      <div
        className={classNames("flex-col lg:border-r", {
          "sm:ml-[-100vw]": !isDesktop && isCalendarSelected,
        })}
        style={{ width: "var(--sidebar-width)" }}
      >
        <div className="py-16 px-20 flex items-center">
          <div className="flex items-center flex-fill text-primary-dark font-bold text-18 truncate">
            {!isDesktop && (
              <ClickableIcon
                name="menu"
                className="mr-12"
                size={20}
                onClick={() => setShowBottomSheet(true)}
              />
            )}
            {isDesktop
              ? t("scheduling.scheduling.calendar")
              : t("scheduling.scheduling.list")}
          </div>
        </div>
        <div className="flex-col flex-fill overflow-auto">
          <AppointmentList
            selectedFilter={selectedFilter}
            onAppointmentSelected={setSelectedAppointment}
          />
        </div>
      </div>
      <Calendar
        selectedDoctor={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedAppointment={selectedAppointment}
        resetSelectedAppointment={() => setSelectedAppointment(undefined)}
        showBottomSheet={() => setShowBottomSheet(true)}
      />
    </div>
  );
};
