import { useMemo } from "react";

import {
  autocomplete,
  preprocessExpressions,
} from "atoms/useAutoCompleteModel/autocomplete";
import { useAutoCompleteModel } from "atoms/useAutoCompleteModel/useAutoCompleteModel";
import { PatientSummaryFragment } from "generated/provider";
import { AutocompleteExpressionUseCase } from "types";

import { useDoctor } from "./UserContext";

export const useCustomizedAutocompleteModel = ({
  patient,
  useCase,
}: {
  patient?: PatientSummaryFragment;
  useCase: AutocompleteExpressionUseCase;
}) => {
  const { user } = useDoctor();
  const { data } = useAutoCompleteModel();

  const customizedData = useMemo(
    () =>
      data?.let(() =>
        preprocessExpressions(
          data.expressions.map((expression) => {
            const withPatientReplaced = patient
              ? expression.text
                  .replaceAll("@patient_username", patient.username)
                  .replaceAll("@patient_firstname", patient.firstName)
                  .replaceAll("@patient_lastname", patient.lastName)
              : expression.text;
            return {
              ...expression,
              text: withPatientReplaced
                .replaceAll("@doctor_firstname", user.firstName)
                .replaceAll("@doctor_lastname", user.lastName),
            };
          }),
        ),
      ),
    [data, patient, user.firstName, user.lastName],
  );
  return (query: string) => autocomplete(query, customizedData ?? [], useCase);
};
