import classNames from "classnames";
import { NavLink, useNavigate } from "react-router-dom";

import { useShadowMode } from "atoms/useShadowMode";
import { useLoggedInAuth } from "auth/AuthContext";
import { Avatar } from "components/Avatar/Avatar";
import { Icon } from "components/Icon/Icon";
import { Popover } from "components/Popover/Popover";
import { useLogout } from "contexts/User/useLogout";
import { useUser } from "contexts/User/UserContext";
import { useTargetState } from "hooks";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useDebugMode } from "hooks/useStorageState";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { AccountMenuItem, resolveSidebarLabel } from "types";

export const AccountMenu = ({ items }: { items: AccountMenuItem[] }) => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();

  const accessProps = useUser();
  const { user, hasPermission, hasAccessToGatekeeperFeature } = accessProps;

  const [target, setTarget] = useTargetState();
  const navigate = useNavigate();
  const [logout] = useLogout();
  const { currentImpersonationUuid, setCurrentImpersonationUuid } =
    useLoggedInAuth();
  const { shadowMode, setShadowMode } = useShadowMode();
  const [debugMode, setDebugMode] = useDebugMode();

  return (
    <>
      <button
        id="account-menu-toggle" // Using this for E2E tests.
        onClick={setTarget}
        className={classNames("w-52 lg:w-80 h-56 flex-center", {
          "bg-grey-200": shadowMode || currentImpersonationUuid,
        })}
      >
        {shadowMode || currentImpersonationUuid ? (
          <Icon name={shadowMode ? "people" : "incognito"} />
        ) : (
          <Avatar user={user} size={24} className="border-none" />
        )}
      </button>
      {target && (
        <Popover
          target={target}
          position={isDesktop ? "top-left" : "bottom-right"}
          className={classNames(
            "flex-col divide-y",
            isDesktop ? "mt-4 ml-52" : "mt-4 mr-10",
          )}
          allowScrolling
          noArrow
          onClose={() => setTarget(undefined)}
        >
          <div className="py-8">
            {items.map(({ to, icon, label }) => (
              <NavLink
                key={to}
                onClick={() => setTarget(undefined)}
                to={to}
                className={classNames(
                  "h-40 p-8 pr-10 flex items-center text-14 list-element border-none",
                )}
                activeClassName="text-primary bg-primary-100 font-medium"
              >
                <Icon name={icon} size={20} className="mx-8" />
                <div className="mr-8">
                  {resolveSidebarLabel(label, accessProps)}
                </div>
              </NavLink>
            ))}
          </div>
          {hasPermission("SEND_HELP_MESSAGES") && (
            <div className="py-8">
              <NavLink
                key={routes.HELP}
                onClick={() => setTarget(undefined)}
                to={routes.HELP}
                className={classNames(
                  "h-40 p-8 pr-10 flex items-center text-14 list-element border-none",
                )}
                activeClassName="text-primary bg-primary-100 font-medium"
              >
                <Icon name="email" size={20} className="mx-8" />
                <div className="mr-8">
                  {t("main_sidebar.account_menu.help")}
                </div>
              </NavLink>
            </div>
          )}
          {hasAccessToGatekeeperFeature("DEBUG_MODE") && (
            <div className="py-8">
              {hasAccessToGatekeeperFeature("DEBUG_MODE") && (
                <button
                  onClick={() => {
                    setDebugMode(!debugMode);
                    setTarget(undefined);
                  }}
                  className={classNames(
                    "h-40 p-8 pr-10 flex items-center text-14 list-element border-none",
                  )}
                >
                  <Icon name="bug" size={20} className="mx-8" />
                  <div className="mr-8">
                    {" "}
                    {debugMode
                      ? t("main_sidebar.account_menu.exit_debug_mode")
                      : t("main_sidebar.account_menu.debug_mode")}
                  </div>
                </button>
              )}
              {hasAccessToGatekeeperFeature("SHADOW_MODE") &&
                currentImpersonationUuid === undefined && (
                  <button
                    onClick={() => {
                      setShadowMode(!shadowMode);
                      setTarget(undefined);
                    }}
                    className={classNames(
                      "h-40 p-8 pr-10 flex items-center text-14 list-element border-none",
                    )}
                  >
                    <Icon name="people" size={20} className="mx-8" />
                    <div className="mr-8">
                      {shadowMode
                        ? t("main_sidebar.account_menu.exit_shadow_mode")
                        : t("main_sidebar.account_menu.shadow_mode")}
                    </div>
                  </button>
                )}
              {hasAccessToGatekeeperFeature("INCOGNITO_MODE") && (
                <button
                  onClick={() => {
                    navigate("/");
                    setTarget(undefined);
                    setCurrentImpersonationUuid(
                      currentImpersonationUuid ? undefined : user?.uuid,
                    );
                  }}
                  className={classNames(
                    "h-40 p-8 pr-10 flex items-center text-14 list-element border-none",
                  )}
                >
                  <Icon name="incognito" size={20} className="mx-8" />
                  <div className="mr-8">
                    {currentImpersonationUuid
                      ? t("main_sidebar.account_menu.exit_incognito_mode")
                      : t("main_sidebar.account_menu.incognito_mode")}
                  </div>
                </button>
              )}
            </div>
          )}
          {!currentImpersonationUuid && (
            <div className="py-8">
              <button
                onClick={logout}
                className={classNames(
                  "h-40 p-8 pr-10 flex items-center text-14 list-element border-none",
                )}
              >
                <Icon name="logout" size={20} className="mx-8" />
                <div className="mr-8">
                  {t("main_sidebar.account_menu.log_out")}
                </div>
              </button>
            </div>
          )}
        </Popover>
      )}
    </>
  );
};
