import { useEffect, useState } from "react";
import classNames from "classnames";

import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { Label } from "components/Form/Label/Label";
import { useTranslation } from "i18n";

import styles from "./consentPreview.module.css";

export const ConsentsPreview = ({ consents }: { consents: string[] }) => {
  const t = useTranslation();

  const [markdownToHtml, setMarkdownToHtml] = useState<
    ((input: string) => string) | undefined
  >(undefined);

  useEffect(() => {
    import("commonmark").then(({ Parser, HtmlRenderer }) => {
      const parser = new Parser(undefined);
      const renderer = new HtmlRenderer({ safe: true });

      setMarkdownToHtml(
        () => (input: string) => renderer.render(parser.parse(input)),
      );
    });
  }, [setMarkdownToHtml]);

  if (markdownToHtml === undefined) return null;

  return consents.isNotEmpty() ? (
    <div>
      <Label label={t("settings.scheduling.consents_preview")} useDiv />
      <div className="flex-col p-18 pr-64 space-y-4 bg-grey-100 rounded sm-outlined outlined border">
        {consents.map((consent, index) => (
          <CheckBox
            key={index}
            name={`previewCheckbox${index}`}
            className="flex-fill text-14 flex items-center flex-fill cursor-default"
            checked={false}
            onChange={() => undefined}
            label={
              <div
                className={classNames("flex-fill", styles.richText)}
                dangerouslySetInnerHTML={{
                  __html: markdownToHtml(consent),
                }}
              />
            }
          />
        ))}
      </div>
    </div>
  ) : null;
};
