import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Maybe } from "base-types";
import { PillButton } from "components/Button/PillButton";
import { CopiableText } from "components/CopiableText/CopiableText";
import { useUserForPrescriptions } from "components/EHRComposer/Prescriptions/useUserForPrescriptions";
import { FormNoteSectionsComposer } from "components/Form/Editor/NoteSections/FormNoteSectionsComposer";
import { NoteSectionsComposer } from "components/Form/Editor/NoteSections/NoteSectionsComposer";
import { NoteSectionWithComposerFields } from "components/Form/Editor/NoteSections/types";
import { createComposerSections } from "components/Form/Editor/NoteSections/utils";
import { FormState } from "components/Form/Form/FormState";
import { Icon } from "components/Icon/Icon";
import { MenuItemProps } from "components/Menu/MenuItem";
import { separator } from "components/Menu/MenuItemSeparator";
import { MultilevelPopoverMenu } from "components/Menu/MultilevelMenu";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { DeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import { Modal } from "components/Modal/Modal";
import { useEHR } from "components/Patient/useEHR";
import {
  AutoPagesOptions,
  usePDFPreview,
} from "components/PDFPreview/usePDFPreview";
import { Spinner } from "components/Spinner/Spinner";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useLivekitRoom } from "contexts/LivekitRoom/LivekitRoomContext";
import { usePatient } from "contexts/PatientContext/PatientContext";
import { usePatientViewData } from "contexts/PatientViewContext/PatientViewContext";
import { useCustomizedAutocompleteModel } from "contexts/User/useCustomizedAutocompleteModel";
import { useDoctor, useUser } from "contexts/User/UserContext";
import {
  AmendAppointmentNote,
  AppointmentNoteLockStatusFragment,
  BravadoPrescriptionFragment,
  LockAppointmentNote,
  NablaPrescriptionFragment,
  PrescriptionTemplates,
  UpdateAppointmentState,
  UpdateAppointmentTitle,
  UpdateNote,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useDocumentUploadMenuSubItems } from "hooks/useDocumentUploadMenuSubItems";
import { useRerenderAt } from "hooks/useRerender";
import { getCurrentLanguage, useTranslation } from "i18n";
import { DiagnoseSectionContent } from "types";
import { PatientTimelineValidItemFragmentOfType } from "types/patient-timeline";
import {
  appointmentImminentAt,
  canJoinAppointmentRoom,
} from "utils/appointment";
import { displayDoctor } from "utils/display";
import { downloadFile } from "utils/file";
import {
  getSectionInput,
  noteSectionsFromPatientNoteSection,
  notifyNoteCreationOrUpdate,
} from "utils/notes";
import { notifier } from "utils/notifier";

import {
  ExistingItemPillButton,
  NewItemPillButton,
} from "../../ItemPillButton";
import {
  PatientTimelineItem,
  PatientTimelineMenuItem,
} from "../../PatientTimelineItem";
import { PatientTimelineItemContentWithPadding } from "../../PatientTimelineItemContentWithPadding";
import { usePatientTimelineLocalItemState } from "../../PatientTimelineLocalStateContext";
import { NotesExportPreview } from "../NotesExportPreview";
import { useAddPrescription } from "../useAddPrescription";
import { AppointmentCallEndScreen } from "./AppointmentCallEndScreen";
import { CerfaGenerationModal } from "./CerfaGenerationModal/CerfaGenerationModal";
import { SuggestionSwitch } from "./SuggestionSwitch";
import { useSuggestions } from "./useSuggestions";

gql`
  mutation UpdateAppointmentState(
    $appointmentUuid: UUID!
    $input: AppointmentStateInput!
  ) {
    updateAppointmentState(appointmentUuid: $appointmentUuid, input: $input) {
      appointment {
        ...AppointmentWithNote
        patient {
          ...PatientSummary
          upcomingAppointments {
            ...Appointment
          }
          pastAppointments {
            ...Appointment
          }
        }
        doctor {
          ...AllUpcomingAppointments
        }
      }
    }
  }

  mutation UpdateAppointmentTitle($appointmentUuid: UUID!, $title: String!) {
    updateAppointmentTitle(appointmentUuid: $appointmentUuid, title: $title) {
      appointment {
        ...AppointmentWithNote
      }
    }
  }

  mutation LockAppointmentNote($appointmentUuid: UUID!) {
    lockAppointmentNote(appointmentUuid: $appointmentUuid) {
      appointment {
        ...AppointmentWithNote
      }
    }
  }

  mutation AmendAppointmentNote($appointmentUuid: UUID!) {
    amendAppointmentNote(appointmentUuid: $appointmentUuid) {
      appointment {
        ...AppointmentWithNote
      }
    }
  }
`;

type FormData = {
  title: string;
  sections: NoteSectionWithComposerFields[];
};

export const AppointmentItem = ({
  item,
}: {
  item: PatientTimelineValidItemFragmentOfType<"Appointment">;
}) => {
  const t = useTranslation();
  const { user } = useDoctor();
  const { joinRoom, currentRoom } = useLivekitRoom();

  const {
    startEditingItem,
    stopEditingItem,
    expandItem,
    closeItem,
    itemState,
  } = usePatientTimelineLocalItemState(item.uuid);

  const [showWebCallLinkModal, setShowWebCallLinkModal] = useState(false);
  const [showCerfaGenerationModal, setShowCerfaGenerationModal] =
    useState(false);
  const {
    doctorSupportsApero,
    subOrganizationSupportsApero,
    hasAccessToGatekeeperFeature,
  } = useUser();
  const { patient } = usePatient();
  const patientSupportsApero = !!item.patient.aperoId;
  const userForPrescription = useUserForPrescriptions();
  const autoPagesOptions: AutoPagesOptions = { margin: [50, 50, 30, 50] };
  const { previewRef: pdfPreviewRef, generatePdf } =
    usePDFPreview(autoPagesOptions);

  const { todoItems, doneItems } = usePatientViewData();
  const linkedPrescriptionItems = [...doneItems.data, ...todoItems.data]
    .filter<NablaPrescriptionFragment | BravadoPrescriptionFragment>(
      (
        otherItem,
      ): otherItem is NablaPrescriptionFragment | BravadoPrescriptionFragment =>
        otherItem.__typename === "NablaPrescription" ||
        otherItem.__typename === "BravadoPrescription",
    )
    .filter((prescription) => prescription.appointment?.uuid === item.uuid);

  const [updateNote] = useMutation(UpdateNote);
  const [lockAppointmentNote] = useMutation(LockAppointmentNote);
  const [amendAppointmentNote] = useMutation(AmendAppointmentNote);
  const [updateAppointmentTitle] = useMutation(UpdateAppointmentTitle);
  const [updateAppointmentState] = useMutation(UpdateAppointmentState);

  const { refetchAll } = useEHR(patient.uuid);
  const autocomplete = useCustomizedAutocompleteModel({
    patient,
    useCase: "EHR",
  });

  const livekitRoom =
    item.location.__typename === "RemoteAppointmentLocation"
      ? item.location.livekitRoom
      : undefined;

  const externalCallUrl =
    item.location.__typename === "RemoteAppointmentLocation"
      ? item.location.externalCallUrl
      : undefined;

  const getPdfName = () =>
    `${patient.firstName}_${patient.lastName}_${appointmentTitle}.pdf`;
  const joinRoomIfPossible = () => {
    if (livekitRoom?.status.__typename !== "LivekitRoomOpenStatus") return;

    startEditingItem();
    joinRoom({
      room: {
        uuid: livekitRoom.uuid,
        url: livekitRoom.status.url,
        token: livekitRoom.status.token,
        expectedParticipants: [user, patient],
      },
      endScreen: (
        <AppointmentCallEndScreen
          rejoin={joinRoomIfPossible}
          appointmentUuid={item.uuid}
        />
      ),
    });
  };

  // Ensures the "Join call" button is shown at the right time.
  useRerenderAt(appointmentImminentAt(item));

  const state = item.state.__typename;

  const patientsInCallCount =
    livekitRoom?.status.__typename !== "LivekitRoomOpenStatus"
      ? 0
      : livekitRoom.status.participants.count(
          (it) => it.__typename === "Patient",
        );

  // FIXME(@liautaud): clean once #22543 is fixed.
  const lastNoteOrAmendment = item.notes.at(-1)!;
  const appointmentTitle =
    item.title.trimOrNull() ?? t("patient_view.default_title.appointment");
  const hasAmendments = item.notes.length > 1;
  const isLocked = item.notes.every((it) => it.lockStatus !== null);

  const suggestions = useSuggestions(lastNoteOrAmendment.note.uuid);

  // Fix for #25195 - Sometimes, the localstorage is in a weird state and
  // item is marked as editing but is locked with no amendment.
  // This cause the item to be stuck expanded mode
  useEffect(
    () => {
      if (isLocked && itemState.mode === "EXPANDED" && itemState.isEditing) {
        stopEditingItem();
        closeItem();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const additionalMenuItems = ({
    openLockingModal,
    openCancellationModal,
  }: {
    openLockingModal: () => void;
    openCancellationModal: () => void;
  }) => {
    const editAction: Maybe<PatientTimelineMenuItem> =
      itemState.mode === "EXPANDED" && itemState.isEditing
        ? null
        : {
            text: hasAmendments
              ? t("patient_view.appointment.edit_amendment")
              : t("patient_view.item.edit"),
            icon: "edit",
            disable: {
              if: isLocked,
              tooltip: hasAmendments
                ? t("patient_view.appointment.cannot_edit_locked_amendment")
                : t("patient_view.appointment.cannot_edit_locked_note"),
            },
            onClick: (closeMenu: () => void) => {
              closeMenu();
              expandItem();
              startEditingItem();
            },
          };
    const lockOrAmendAction: PatientTimelineMenuItem = isLocked
      ? {
          text: t("patient_view.appointment.amend"),
          icon: "menuPlus",
          disableWhenEditing: t(
            "patient_view.appointment.save_before_amending",
          ),
          onClick: async (closeMenu: () => void) => {
            closeMenu();
            await amendAppointmentNote({ appointmentUuid: item.uuid });
            expandItem();
            startEditingItem();
          },
        }
      : {
          text: hasAmendments
            ? t("patient_view.appointment.lock_amendment")
            : t("patient_view.appointment.lock_note"),
          icon: "lock",
          disableWhenEditing: t("patient_view.appointment.save_before_locking"),
          onClick: (closeMenu: () => void) => {
            closeMenu();
            openLockingModal();
          },
        };
    const webCallLinkAction: Maybe<PatientTimelineMenuItem> =
      item.webCallInvitationUrl && state !== "AppointmentStateCancelled"
        ? {
            text: t("patient_view.web_call_invitation_link.button"),
            icon: "hyperlink",
            onClick: (closeMenu: () => void) => {
              closeMenu();
              setShowWebCallLinkModal(true);
            },
          }
        : null;
    const exportAction: PatientTimelineMenuItem = {
      text: t("patient_view.export_note"),
      icon: "document",
      disable: {
        if: !userForPrescription.hasAllRequiredFields,
        tooltip: t("patient_view.export_note_disable"),
      },
      onClick: async (close) => {
        downloadFile(await generatePdf(), getPdfName());
        close();
      },
    };
    const markAsScheduledAction: PatientTimelineMenuItem = {
      text: t("patient_view.appointment.mark_as_to_be_done"),
      icon: "checklist",
      onClick: async (closeMenu: () => void) => {
        closeMenu();
        await updateAppointmentState({
          appointmentUuid: item.uuid,
          input: { scheduled: {} },
        });
      },
    };
    const markAsNoShowAction: PatientTimelineMenuItem = {
      text: t("patient_view.appointment.mark_as_no_show"),
      icon: "profileCross",
      onClick: async (closeMenu: () => void) => {
        closeMenu();
        await updateAppointmentState({
          appointmentUuid: item.uuid,
          input: { noShow: {} },
        });
      },
    };
    const finalizeAction: PatientTimelineMenuItem = {
      text: t("patient_view.appointment.mark_as_complete"),
      icon: "check",
      onClick: async (closeMenu: () => void) => {
        closeMenu();
        await updateAppointmentState({
          appointmentUuid: item.uuid,
          input: { finalized: {} },
        });
      },
    };
    const cancelAction: PatientTimelineMenuItem = {
      text: t("patient_view.appointment.cancel_button"),
      className: "text-danger",
      icon: "calendarCancel",
      onClick: (closeMenu: () => void) => {
        closeMenu();
        openCancellationModal();
      },
    };

    return [
      ...(editAction ? [editAction] : []),
      lockOrAmendAction,
      separator,
      exportAction,
      ...(webCallLinkAction ? [separator, webCallLinkAction] : []),
    ].concat(
      state === "AppointmentStateScheduled"
        ? [separator, markAsNoShowAction, finalizeAction, cancelAction]
        : state === "AppointmentStateFinalized"
        ? [separator, markAsScheduledAction, markAsNoShowAction, cancelAction]
        : state === "AppointmentStateNoShow"
        ? [separator, markAsScheduledAction, finalizeAction, cancelAction]
        : [],
    );
  };

  const WithNoEmailDisclaimer = ({ children }: { children: ReactNode }) => (
    <>
      {item.webCallInvitationUrl || !!patient.email?.trimOrNull() ? (
        children
      ) : (
        <TooltipWrapper
          label={t("patient_view.appointment.no_email_disclaimer")}
          position="top"
          tooltipStyle={{ width: 400 }}
          children={children}
        />
      )}
    </>
  );

  const canAddNewAttachments =
    item.state.__typename === "AppointmentStateFinalized" ||
    (item.state.__typename === "AppointmentStateScheduled" &&
      appointmentImminentAt(item).isPast());
  const canAddNewAperoBills =
    subOrganizationSupportsApero && doctorSupportsApero && patientSupportsApero;
  const canAddNewMedicalOrders =
    user.subOrganization.locale === "ENGLISH" ||
    hasAccessToGatekeeperFeature("IS_US_COUNTRY");

  const onConfirmLocking = async (closeModal: () => void) => {
    await lockAppointmentNote({ appointmentUuid: item.uuid });
    notifier.success(
      hasAmendments
        ? t("patient_view.appointment.lock_amendment_success")
        : t("patient_view.appointment.lock_note_success"),
    );
    closeModal();
  };

  const onConfirmCancellation = async (closeModal: () => void) => {
    await updateAppointmentState({
      appointmentUuid: item.uuid,
      input: { cancelled: {} },
    });
    notifier.success(t("patient_view.appointment.cancel_success"));
    closeModal();
  };

  const documentSubItems = useDocumentUploadMenuSubItems({
    appointmentUuid: item.uuid,
  });

  return (
    <ConfirmationModal
      body={
        <>
          <h3 className="title text-20 text-left leading-snug mb-10">
            {hasAmendments
              ? t("patient_view.appointment.lock_amendment_modal.title")
              : t("patient_view.appointment.lock_note_modal.title")}
          </h3>
          <p className="text-left">
            {hasAmendments
              ? t("patient_view.appointment.lock_amendment_modal.subtitle")
              : t("patient_view.appointment.lock_note_modal.subtitle")}
          </p>
        </>
      }
      cta={{
        label: hasAmendments
          ? t("patient_view.appointment.lock_amendment_modal.button")
          : t("patient_view.appointment.lock_note_modal.button"),
        leftIcon: "lock",
        danger: true,
      }}
      onConfirm={onConfirmLocking}
    >
      {(openLockingModal) => (
        <DeletionConfirmationModal
          suffix={t("patient_view.appointment.cancel_suffix")}
          ctaLabel={t("patient_view.appointment.cancel_button")}
          onConfirm={onConfirmCancellation}
        >
          {(openCancellationModal) => (
            <>
              {showWebCallLinkModal && (
                <Modal
                  onHide={() => setShowWebCallLinkModal(false)}
                  title={t("patient_view.web_call_invitation_link.modal_title")}
                  className="items-stretch"
                >
                  <p className="mt-20 mb-10">
                    {t("patient_view.web_call_invitation_link.modal_body")}
                  </p>
                  <CopiableText text={item.webCallInvitationUrl!} />
                </Modal>
              )}
              {showCerfaGenerationModal && (
                <CerfaGenerationModal
                  appointment={item}
                  onHide={() => setShowCerfaGenerationModal(false)}
                />
              )}
              {userForPrescription.hasAllRequiredFields && (
                <div className="hidden">
                  <NotesExportPreview
                    theme="PDF"
                    notes={item.notes.map((it) => it.note)}
                    user={userForPrescription}
                    previewRef={pdfPreviewRef}
                    autoPagesOptions={autoPagesOptions}
                  />
                </div>
              )}
              <PatientTimelineItem<"Appointment", FormData, FormData>
                // Important: forces the form to re-render when a new amendment
                // is added, which ensures there are no visual glitches due to
                // the 1-render-cycle delay between updates to initialValues and
                // the corresponding updates to the form state.
                key={lastNoteOrAmendment.note.uuid}
                item={item}
                withFaxOptions={{
                  documentType: "APPOINTMENT",
                  getPreview: (previewRef, comment) => (
                    <NotesExportPreview
                      theme="FAX"
                      notes={item.notes.map((it) => it.note)}
                      user={user}
                      previewRef={previewRef}
                      autoPagesOptions={autoPagesOptions}
                      comment={comment}
                    />
                  ),
                }}
                getFileAttachment={
                  userForPrescription.hasAllRequiredFields
                    ? () =>
                        generatePdf().then((file) => ({
                          file: new File([file], getPdfName(), {
                            type: "application/pdf",
                          }),
                        }))
                    : undefined
                }
                assignedDoctors={[item.doctor]}
                actionButton={
                  externalCallUrl
                    ? {
                        mode: "ALWAYS",
                        leftIcon: "shareLight",
                        label: t("patient_view.appointment.join_call"),
                        external: externalCallUrl,
                      }
                    : canJoinAppointmentRoom(item) &&
                      livekitRoom?.status.__typename ===
                        "LivekitRoomOpenStatus" &&
                      livekitRoom.uuid !== currentRoom?.uuid
                    ? {
                        mode: "ALWAYS",
                        node: (
                          <JoinButton
                            patientsInCallCount={patientsInCallCount}
                            onClick={joinRoomIfPossible}
                          />
                        ),
                      }
                    : livekitRoom &&
                      livekitRoom.uuid === currentRoom?.uuid &&
                      hasAccessToGatekeeperFeature("VIDEO_CALL_SPEECH_TO_TEXT")
                    ? {
                        mode: "ALWAYS",
                        node: <SuggestionSwitch appointment={item} />,
                      }
                    : undefined
                }
                additionalHeaderChildren={
                  <>
                    {lastNoteOrAmendment.lockStatus && (
                      <LockedIcon lockStatus={lastNoteOrAmendment.lockStatus} />
                    )}
                  </>
                }
                additionalMenuItems={additionalMenuItems({
                  openLockingModal,
                  openCancellationModal,
                })}
                withoutEditMenuItem
                footerChildren={
                  <FormState<FormData>>
                    {({ values }) => (
                      <>
                        {canAddNewMedicalOrders && (
                          <NewItemPillButton
                            type="MedicalOrder"
                            payload={{
                              appointmentUuid: item.uuid,
                              initialIcd10Codes: extractIcd10CodesFromSections(
                                values.sections,
                              ),
                            }}
                          />
                        )}
                        {canAddNewAperoBills && (
                          <NewItemPillButton
                            type="AperoBill"
                            payload={{
                              appointmentUuid: item.uuid,
                              icd10Codes: extractIcd10CodesFromSections(
                                values.sections,
                              ),
                            }}
                          />
                        )}
                        {canAddNewAttachments && (
                          <>
                            <WithNoEmailDisclaimer>
                              <AddPrescription
                                patientUuid={patient.uuid}
                                appointmentUuid={item.uuid}
                                label={t(
                                  "views.patients.patient_view.patient_view_appointment_note.add_prescription",
                                )}
                              />
                            </WithNoEmailDisclaimer>
                            <WithNoEmailDisclaimer>
                              <MultilevelPopoverMenu
                                position="bottom-left"
                                className="mt-10 min-w-[180px]"
                                items={documentSubItems}
                              >
                                {({ setTarget }) => (
                                  <div onClick={setTarget}>
                                    <PillButton
                                      label={t(
                                        "patient_view.default_title.patient_document",
                                      )}
                                      leftIcon="add"
                                      onClick={() => undefined}
                                    />
                                  </div>
                                )}
                              </MultilevelPopoverMenu>
                            </WithNoEmailDisclaimer>
                            {hasAccessToGatekeeperFeature("CERFA") &&
                              user.locale === "FRENCH" && (
                                <PillButton
                                  leftIcon="add"
                                  label="Feuille de soin"
                                  onClick={() =>
                                    setShowCerfaGenerationModal(true)
                                  }
                                />
                              )}
                          </>
                        )}
                        {linkedPrescriptionItems.map((prescriptionItem) => (
                          <ExistingItemPillButton
                            key={prescriptionItem.uuid}
                            item={prescriptionItem}
                            onClick={
                              "bravadoEncounterUrl" in prescriptionItem
                                ? () =>
                                    prescriptionItem.bravadoEncounterUrl.let(
                                      (url) =>
                                        window.open(url, "_blank")?.focus(),
                                    )
                                : undefined
                            }
                          />
                        ))}
                        {item.medicalOrders.map((medicalOrder) => (
                          <ExistingItemPillButton
                            key={medicalOrder.uuid}
                            item={medicalOrder}
                          />
                        ))}
                        {item.attachments.map((document) => (
                          <ExistingItemPillButton
                            key={document.uuid}
                            item={document}
                          />
                        ))}
                        {item.aperoBills.map((bill) => (
                          <ExistingItemPillButton key={bill.uuid} item={bill} />
                        ))}
                      </>
                    )}
                  </FormState>
                }
                initialValues={{
                  title: appointmentTitle,
                  sections: createComposerSections(
                    noteSectionsFromPatientNoteSection(
                      lastNoteOrAmendment.note.sections,
                    ),
                  ),
                }}
                disabled={isLocked}
                onSubmit={async ({ title, sections }) => {
                  await Promise.all([
                    updateAppointmentTitle({
                      appointmentUuid: item.uuid,
                      title,
                    }),
                    updateNote({
                      patientNoteUuid: lastNoteOrAmendment.note.uuid,
                      sections: sections.map((it) => getSectionInput(it)),
                      title,
                    }),
                  ]);

                  // TODO(@liautaud): Clean up this legacy from the appointment view.
                  //  Notably, the wording of the success message is not coherent
                  //  with all the other success messages in the patient view.
                  notifyNoteCreationOrUpdate(t, sections, true);
                  refetchAll();
                }}
              >
                {(editCount) => (
                  <PatientTimelineItemContentWithPadding>
                    <div
                      className={classNames(
                        "flex-col divide-y",
                        // To compensate the margin from [PatientTimelineItem].
                        "-mx-[calc(theme(spacing.timeline-margin)-theme(spacing.timeline-opened-item-margin)-1px+32px+16px)]",
                      )}
                    >
                      {item.notes.map((note, index) => (
                        <div
                          className="px-12 py-[22px] first:pt-0 last:pb-0"
                          key={note.note.uuid}
                        >
                          {hasAmendments && (
                            <h4 className="flex text-grey-300 font-bold uppercase text-12 mx-[56px] mb-10">
                              <span className="mr-4">
                                {note.note.createdAt.format("date")}
                              </span>
                              <span>
                                {index === 0
                                  ? t("patient_view.appointment.initial_note")
                                  : t("patient_view.appointment.amendment_by", {
                                      doctor: displayDoctor(note.note.author),
                                    })}
                              </span>
                              {note.lockStatus && (
                                <LockedIcon lockStatus={note.lockStatus} />
                              )}
                            </h4>
                          )}
                          {index < item.notes.length - 1 ? (
                            <NoteSectionsComposer
                              value={createComposerSections(
                                noteSectionsFromPatientNoteSection(
                                  note.note.sections,
                                ),
                              )}
                              onChange={() => void 0}
                              readOnly
                              keepToolsMarginsInReadOnly
                            />
                          ) : (
                            <FormNoteSectionsComposer
                              // FIXME(@samhumeau): the `FormNoteSectionsComposer` currently
                              //  has an internal state, so it needs to be re-rendered every
                              //  time the form contents change.
                              key={`sections-${editCount}`}
                              name="sections"
                              keepToolsMarginsInReadOnly
                              autocomplete={autocomplete}
                              suggestions={suggestions}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </PatientTimelineItemContentWithPadding>
                )}
              </PatientTimelineItem>
            </>
          )}
        </DeletionConfirmationModal>
      )}
    </ConfirmationModal>
  );
};

const AddPrescription = ({
  label,
  patientUuid,
  appointmentUuid,
}: {
  label: string;
  patientUuid: UUID;
  appointmentUuid: UUID;
}) => {
  const { addPrescription, isAddingPrescription } = useAddPrescription(
    patientUuid,
    appointmentUuid,
  );

  const t = useTranslation();
  const { data } = useQuery(PrescriptionTemplates);

  if (!data || data.isEmpty()) {
    return isAddingPrescription ? (
      <PillButton
        leftIcon={<Spinner small className="mr-2 -ml-2" />}
        label={label}
      />
    ) : (
      <PillButton
        leftIcon="add"
        label={label}
        onClick={() => addPrescription()}
      />
    );
  }

  const templatesItems: MenuItemProps[] = data.map((template) => ({
    icon: "fileText" as const,
    text: template.title,
    onClick: (closeMenu: () => void) => {
      closeMenu();
      return addPrescription(template);
    },
  }));

  return (
    <MultilevelPopoverMenu
      position="bottom-left"
      className="mt-10 min-w-[180px]"
      items={[
        templatesItems,
        {
          icon: "add" as const,
          text: t("patient_view.prescription.blank"),
          topSeparator: true,
          onClick: (closeMenu: () => void) => {
            closeMenu();
            return addPrescription();
          },
        },
      ].flat()}
    >
      {({ setTarget }) => (
        <div onClick={setTarget}>
          <PillButton label={label} leftIcon="add" />
        </div>
      )}
    </MultilevelPopoverMenu>
  );
};

const JoinButton = ({
  patientsInCallCount,
  onClick,
}: {
  patientsInCallCount: number;
  onClick: () => void;
}) => {
  const t = useTranslation();
  const language = getCurrentLanguage();
  const minWidth = (() => {
    switch (language) {
      case "en":
        return "min-w-[146px]";
      case "fr":
      default:
        return "min-w-[162px]";
    }
  })();

  return (
    <button
      className={classNames(
        `text-white leading-tight ${minWidth} text-[13-px] px-12 py-4 flex items-center rounded bg-primary hover:bg-primary/80 active:bg-primary/70 font-normal transition-opacity duration-100`,
      )}
      onClick={onClick}
    >
      <Icon name="videoOn" className="mr-8" />
      <span className="flex-col">
        <span>{t("patient_view.appointment.join_call")}</span>
        <span className="flex items-center text-10">
          {patientsInCallCount > 0 ? (
            <>
              <Icon name="dot" size={6} className="mr-4 text-[#00DFD3]" />
              {t("patient_view.appointment.patients_in_call_count", {
                count: patientsInCallCount,
              })}
            </>
          ) : (
            t("patient_view.appointment.empty_waiting_room")
          )}
        </span>
      </span>
    </button>
  );
};

const LockedIcon = ({
  lockStatus,
}: {
  lockStatus: AppointmentNoteLockStatusFragment;
}) => {
  const t = useTranslation();
  return (
    <TooltipWrapper
      label={t("patient_view.appointment.locked_by", {
        doctor: displayDoctor(lockStatus.lockedBy),
        date: lockStatus.lockedAt.format({
          relative: "timeOrDateWithTime",
        }),
      })}
    >
      <Icon name="lock" size={18} className="ml-4" />
    </TooltipWrapper>
  );
};

const extractIcd10CodesFromSections = (
  sections: NoteSectionWithComposerFields[],
) =>
  sections
    .map((section) => section.content)
    .find(
      (content): content is DiagnoseSectionContent =>
        content.__typename === "DiagnoseSectionContent",
    )?.icd10Codes ?? [];
