import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";
import { useParams, useSearchParams } from "react-router-dom";

import { Button } from "components/Button/Button";
import { ButtonGroup } from "components/Form/ButtonGroup/ButtonGroup";
import { Select } from "components/Form/Select/Select";
import { TextArea } from "components/Form/TextArea/TextArea";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Modal } from "components/Modal/Modal";
import { Popover } from "components/Popover/Popover";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { Query } from "components/Query/Query";
import { Spinner } from "components/Spinner/Spinner";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import {
  FileUploadFragment,
  GetFileUploadTranscript,
  TranscriptFragment,
  TranscriptItemFragment,
  UpdateTranscriptItems,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useOn } from "hooks/useOn";
import { useRerender } from "hooks/useRerender";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { downloadFile } from "utils/file";
import { notifier } from "utils/notifier";

import {
  displayTranscriptItemTag,
  getTranscriptItemTags,
  tagToIconName,
  TranscriptItemTag,
  TranscriptItemTags,
} from "./utils";

gql`
  mutation UpdateTranscriptItems(
    $transcriptUuid: UUID!
    $input: UpdateTranscriptItemsInput!
  ) {
    updateTranscriptItems(transcriptUuid: $transcriptUuid, input: $input) {
      transcript {
        ...Transcript
      }
    }
  }

  query GetFileUploadTranscript($fileUploadUuid: UUID!) {
    fileUpload(uuid: $fileUploadUuid) {
      ...FileUpload
      transcript {
        ...Transcript
      }
    }
  }
`;

type TranscriptItemInput = {
  uuid: UUID;
  text: string;
  speaker: string | null;
  final: boolean;
  verified: boolean;
  start: number;
  end: number;
  tags: TranscriptItemTag[];
};

export const FileUploadTranscriptionAnnotation = () => {
  const { uuid } = useParams();

  return (
    <Query query={GetFileUploadTranscript} variables={{ fileUploadUuid: uuid }}>
      {(data) =>
        data.transcript === null ? (
          <div className="flex-col flex-fill bg-white">
            Cette consultation audio ne contient pas encore de transcript.
          </div>
        ) : (
          <TranscriptAnnotation
            fileUpload={data}
            transcript={data.transcript}
          />
        )
      }
    </Query>
  );
};

const SPEAKERS = ["Doctor", "Patient", "Doctor2", "Patient2"] as const;

export const AudioSpeeds = ["Slow", "Normal", "Fast"] as const;
export type AudioSpeed = typeof AudioSpeeds[number];

const TranscriptAnnotation = ({
  fileUpload,
  transcript,
}: {
  fileUpload: FileUploadFragment;
  transcript: TranscriptFragment;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const rerender = useRerender();

  return (
    <>
      <audio
        ref={audioRef}
        onPlay={rerender}
        onPause={rerender}
        onLoadedData={rerender}
        onCanPlay={rerender}
        onTimeUpdate={rerender}
        src={fileUpload.urlV2.url}
      />
      {/* https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState */}
      {audioRef.current && audioRef.current.readyState >= 1 ? (
        <TranscriptAnnotationWithAudio
          audio={audioRef.current}
          transcript={transcript}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export const TranscriptAnnotationWithAudio = ({
  audio,
  transcript,
}: {
  audio: HTMLAudioElement;
  transcript: TranscriptFragment;
}) => {
  const t = useTranslation();
  const items = transcript.items;
  const [updateTranscriptItems, updating] = useMutation(UpdateTranscriptItems, {
    onSuccess: () =>
      notifier.success(t("transcript_annotation.update_success")),
  });

  const initialDraftItems: TranscriptItemInput[] = items.mapNotNull((item) => {
    if (item.start !== null && item.end !== null) {
      return {
        uuid: item.uuid,
        text: item.text,
        speaker: item.speakerTag,
        final: item.final,
        verified: item.verified,
        start: item.start,
        end: item.end,
        tags: getTranscriptItemTags(item),
      };
    }
    return null;
  });

  const [draftItems, setDraftItems] =
    useState<TranscriptItemInput[]>(initialDraftItems);

  const [showCommentModal, setShowCommentModal] = useState(false);

  const [searchParams] = useSearchParams();
  const [showOnlyMedical, setShowOnlyMedical] = useState(
    searchParams.get("showOnlyMedicalInitially") !== null,
  );
  const verificationPhase = searchParams.get("verification") !== null;

  const [comment, setComment] = useState(transcript.comment ?? "");

  const [audioSpeed, setAudioSpeed] = useState<AudioSpeed>("Normal");

  useEffect(() => {
    switch (audioSpeed) {
      case "Slow":
        audio.playbackRate = 0.75;
        break;
      case "Normal":
        audio.playbackRate = 1.0;
        break;
      case "Fast":
        audio.playbackRate = 1.25;
        break;
    }
  }, [audioSpeed, audio]);

  const updateDraftItem = (newItem: TranscriptItemInput) => {
    setDraftItems(
      draftItems.map((item) => (item.uuid === newItem.uuid ? newItem : item)),
    );
  };

  const previous = () => {
    const currentChunkIndex = getCurrentTranscriptItemIndex(
      items,
      audio.currentTime,
    );
    if (currentChunkIndex === undefined) return;
    if (currentChunkIndex - 1 >= 0) {
      const newTime = items[currentChunkIndex - 1].start;
      if (newTime !== null) audio.currentTime = newTime;
    } else {
      const newTime = items[currentChunkIndex].start;
      if (newTime !== null) audio.currentTime = newTime;
    }
  };
  const playPause = () => (audio.paused ? audio.play() : audio.pause());
  const next = () => {
    const currentChunkIndex = getCurrentTranscriptItemIndex(
      items,
      audio.currentTime,
    );
    if (currentChunkIndex === undefined) return;
    if (currentChunkIndex + 1 < items.length) {
      const newTime = items[currentChunkIndex + 1].start;
      if (newTime !== null) audio.currentTime = newTime;
      if (audio.paused) audio.play();
    }
  };
  const saveTranscript = () =>
    updateTranscriptItems({
      transcriptUuid: transcript.uuid,
      input: {
        transcriptComment: comment.trimOrNull(),
        transcriptItems: draftItems.map((item) => ({
          uuid: item.uuid,
          text: item.text,
          final: item.final,
          verified: item.verified,
          speakerTag: item.speaker,
          isInaudible: item.tags.includes("Inaudible"),
          isIncomprehensible: item.tags.includes("Incomprehensible"),
          requiresMedicalOpinion: item.tags.includes("RequiresMedicalOpinion"),
          hasOverlappingIssues: item.tags.includes("HasOverlappingIssues"),
          verifiedByDoctor: item.tags.includes("VerifiedByDoctor"),
        })),
      },
    });

  useOn("keydown", (e) => {
    if (e.altKey && e.code === "Space") playPause();
    if (e.altKey && e.code === "KeyS") saveTranscript();
    if (e.altKey && e.code === "Digit1") setAudioSpeed("Slow");
    if (e.altKey && e.code === "Digit2") setAudioSpeed("Normal");
    if (e.altKey && e.code === "Digit3") setAudioSpeed("Fast");
    if (e.code === "ArrowUp") previous();
    if (e.code === "ArrowDown") next();
  });

  const progressInPercentage =
    100 *
    (draftItems.count((it) => (verificationPhase ? it.verified : it.final)) /
      draftItems.length);

  const saveTranscriptRef = useSyncRef(saveTranscript);
  const draftItemsRef = useSyncRef(draftItems);
  const initialDraftItemsRef = useSyncRef(initialDraftItems);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        JSON.stringify(initialDraftItemsRef.current) !==
        JSON.stringify(draftItemsRef.current)
      ) {
        saveTranscriptRef.current();
      }
    }, 15 * 1000);
    return () => clearInterval(intervalId);
  }, [saveTranscriptRef, initialDraftItemsRef, draftItemsRef]);

  const numItemsNotRead = draftItems.count((it) =>
    verificationPhase ? !it.verified : !it.final,
  );

  return (
    <>
      {showCommentModal && (
        <Modal
          onHide={() => setShowCommentModal(false)}
          className="flex-col space-y-12"
        >
          <div className="text-20 font-medium text-primary-dark">
            {t("transcript_annotation.comment_header")}
          </div>
          <TextArea
            minRows={5}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            autoFocus
            placeholder={t("transcript_annotation.comment_placeholder")}
          />
        </Modal>
      )}
      <div className="flex-col flex-fill bg-white">
        <div className="flex items-center px-30 py-24 border-b">
          {transcript.recordedConversation && (
            <ClickableIcon
              name="arrow"
              to={`/${routes.RECORDED_CONVERSATIONS}/${transcript.recordedConversation.uuid}`}
            />
          )}
          <div className="text-24 font-bold text-primary-dark">
            {t("transcript_annotation.title")}
          </div>
          <div className="flex items-center space-x-12 ml-auto">
            <div className="flex mr-24">
              {numItemsNotRead === 0 ? (
                <>
                  <Icon name="check" className="text-success mr-4" />
                  {t("transcript_annotation.all_items_read")}
                </>
              ) : (
                <>
                  <Icon name="refresh" className="text-orange mr-4" />
                  {t("transcript_annotation.unread_items", {
                    count: numItemsNotRead,
                  })}
                </>
              )}
            </div>
            <TooltipWrapper
              label={
                <div className="flex-col">
                  <div>{t("transcript_annotation.shortcuts")}</div>
                  <div>
                    <span className="text-yellow">Option ⎇ + Space</span>
                    {t("transcript_annotation.shortcut_play_pause")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + S</span>
                    {t("transcript_annotation.shortcut_save")}
                  </div>
                  <div>
                    <span className="text-yellow">↑</span>
                    {t("transcript_annotation.shortcut_previous")}
                  </div>
                  <div>
                    <span className="text-yellow">↓</span>
                    {t("transcript_annotation.shortcut_next")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + ←</span>
                    {t("transcript_annotation.shortcut_start_row")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + P</span>
                    {t("transcript_annotation.shortcut_set_patient")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + D</span>
                    {t("transcript_annotation.shortcut_set_doctor")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + 1</span>
                    {t("transcript_annotation.shortcut_slow")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + 2</span>
                    {t("transcript_annotation.shortcut_normal")}
                  </div>
                  <div>
                    <span className="text-yellow">Option ⎇ + 3</span>
                    {t("transcript_annotation.shortcut_fast")}
                  </div>
                </div>
              }
              className="px-8"
            >
              <Icon name="info" />
            </TooltipWrapper>
            <ButtonGroup
              wrapperClassName="w-auto"
              buttonClassName="h-44"
              options={AudioSpeeds}
              getOptionLabel={(o) => o}
              value={audioSpeed}
              onChange={(newValue) => setAudioSpeed(newValue)}
            />
            <div className="flex space-x-4 px-4 py-[5px] rounded border text-primary">
              <ClickableIcon
                name="doubleChevron"
                rotate={180}
                onClick={previous}
              />
              <ClickableIcon
                name={audio.paused ? "player" : "pause"}
                onClick={playPause}
              />
              <ClickableIcon name="doubleChevron" onClick={next} />
            </div>
            <TooltipWrapper label={t("transcript_annotation.leave_comment")}>
              <ClickableIcon
                name="questionMark"
                className="flex px-12 py-[11px] rounded border"
                onClick={() => setShowCommentModal(true)}
              />
            </TooltipWrapper>
            <TooltipWrapper
              label={t("transcript_annotation.download_anonymized_version")}
            >
              <ClickableIcon
                name="download"
                className="flex px-12 py-[11px] rounded border"
                onClick={() => {
                  const rawTextTranscript = draftItems
                    .map((it) => `${it.speaker ?? "UNKNOWN"}:${it.text}`)
                    .join("\n");
                  const pseudonymizedTranscript = rawTextTranscript.replaceAll(
                    /\]\([^()]*\)/gu,
                    "]",
                  );
                  downloadFile(
                    new Blob([pseudonymizedTranscript], {
                      type: "text/plain",
                    }),
                    "transcript.txt",
                  );
                }}
              />
            </TooltipWrapper>
            <TooltipWrapper
              label={t("recorded_conversations.medical_verification")}
            >
              <ClickableIcon
                name="stethoscope"
                className={classNames("flex px-12 py-[11px] rounded border", {
                  "bg-primary": showOnlyMedical,
                })}
                onClick={() => setShowOnlyMedical(!showOnlyMedical)}
              />
            </TooltipWrapper>

            <Button
              label={t("transcript_annotation.save_button")}
              loading={updating}
              onClick={saveTranscript}
            />
          </div>
        </div>
        <div className="relative w-full h-4 bg-grey-200">
          <div
            className="absolute h-4 left-0 bg-primary"
            style={{ width: `${progressInPercentage}%` }}
          />
        </div>
        <div className="flex-col flex-fill overflow-auto pb-[220px]">
          {draftItems.map((item, index) => {
            if (showOnlyMedical) {
              let medicalNearby = false;
              for (let i = index - 1; i <= index + 1; i++) {
                if (i < 0 || i >= draftItems.length) continue;
                if (draftItems[i].tags.includes("RequiresMedicalOpinion")) {
                  medicalNearby = true;
                }
              }
              if (!medicalNearby) {
                if (
                  index > 2 &&
                  draftItems[index - 2].tags.includes("RequiresMedicalOpinion")
                ) {
                  return (
                    <div
                      className="bg-grey-100 flex items-center justify-center w-full h-64"
                      key={index}
                    >
                      ...
                    </div>
                  );
                }
                return null;
              }
            }
            return (
              <AnnotationItem
                audio={audio}
                index={index}
                draftItem={item}
                updateDraftItem={updateDraftItem}
                key={index}
                verificationPhase={verificationPhase}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const AnnotationItem = ({
  audio,
  index,
  draftItem,
  updateDraftItem,
  verificationPhase,
}: {
  verificationPhase: boolean;
  audio: HTMLAudioElement;
  index: number;
  draftItem: TranscriptItemInput;
  updateDraftItem: (newItem: TranscriptItemInput) => void;
}) => {
  const [isInChunk, setIsInChunk] = useState(false);
  const [isPlayingInChunk, setIsPlayingInChunk] = useState(false);

  const updatePartialDraftItem = useCallback(
    (partial: Partial<TranscriptItemInput>) =>
      updateDraftItem({ ...draftItem, ...partial }),
    [draftItem, updateDraftItem],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIsInChunk =
        audio.currentTime >= draftItem.start &&
        audio.currentTime < draftItem.end;
      setIsInChunk(newIsInChunk);
      const newIsPlayingInChunk = newIsInChunk && !audio.paused;
      setIsPlayingInChunk(newIsPlayingInChunk);
      if (newIsPlayingInChunk) {
        updatePartialDraftItem(
          verificationPhase ? { verified: true } : { final: true },
        );
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [
    audio,
    draftItem.start,
    verificationPhase,
    draftItem.end,
    updatePartialDraftItem,
  ]);

  useOn("keydown", (e) => {
    if (isInChunk) {
      if (e.altKey && e.code === "KeyP") {
        updatePartialDraftItem({ speaker: "Patient" });
      }
      if (e.altKey && e.code === "KeyD") {
        updatePartialDraftItem({ speaker: "Doctor" });
      }
      if (e.altKey && e.code === "ArrowLeft") {
        audio.currentTime = draftItem.start;
      }
    }
  });

  return (
    <div
      className={classNames(
        "flex items-center px-32 py-6 space-x-12 border-b group",
        isInChunk ? "bg-primary-100" : " hover:bg-grey-100",
      )}
    >
      <div className="w-40">{index}</div>
      <ClickableIcon
        name={isPlayingInChunk ? "pause" : "player"}
        className={isInChunk ? "visible" : "invisible group-hover:visible"}
        onClick={() => {
          if (isPlayingInChunk) {
            audio.pause();
          } else {
            if (!isInChunk) audio.currentTime = draftItem.start;
            audio.play();
          }
        }}
      />
      <Select
        name="speaker"
        value={draftItem.speaker ?? undefined}
        wrapperClassName="w-[135px]"
        className={classNames(
          "font-medium",
          draftItem.speaker === "Doctor"
            ? "text-primary"
            : draftItem.speaker === "Patient"
            ? "text-danger"
            : draftItem.speaker === "Patient2"
            ? "text-green"
            : "text-body",
        )}
        options={SPEAKERS}
        onChange={(newValue) =>
          updatePartialDraftItem({ speaker: newValue ?? null })
        }
        isClearable
        creatable
      />
      <HighlightableTextInput
        inputRef={inputRef}
        value={draftItem.text}
        onChange={(newValue) => {
          updatePartialDraftItem({ text: newValue });
        }}
      />
      <TagsPopover
        tags={draftItem.tags}
        updateDraftTags={(tags) => updatePartialDraftItem({ tags })}
      />
      <ValidationCheckBox
        validated={verificationPhase ? draftItem.verified : draftItem.final}
        onClick={() =>
          updatePartialDraftItem(
            verificationPhase
              ? { verified: !draftItem.verified }
              : { final: !draftItem.final },
          )
        }
      />
    </div>
  );
};

const ValidationCheckBox = ({
  validated,
  onClick,
}: {
  validated: boolean;
  onClick: () => void;
}) => (
  <button className="relative flex text-body group p-6" onClick={onClick}>
    <Icon size={20} name={validated ? "checkCircle" : "radioOff"} />
    <div className="absolute z-1 mt-6 ml-6">
      {validated ? (
        <Icon size={8} name="checkMark" className="text-white" />
      ) : (
        <Icon
          size={8}
          name="checkMark"
          className="text-body hidden group-hover:flex"
        />
      )}
    </div>
  </button>
);

const getCurrentTranscriptItemIndex = (
  items: TranscriptItemFragment[],
  currentTime: number,
) =>
  items.findIndexOrUndefined(
    (item) =>
      item.start !== null &&
      item.end !== null &&
      item.start <= currentTime &&
      currentTime < item.end,
  );

const TagsPopover = ({
  tags,
  updateDraftTags,
}: {
  tags: TranscriptItemTag[];
  updateDraftTags: (tags: TranscriptItemTag[]) => void;
}) => (
  <UncontrolledPopover
    position="bottom-right"
    noArrow
    content={
      <div className="flex-col w-[260px]">
        {TranscriptItemTags.map((tag) => (
          <button
            key={tag}
            className={classNames(
              "flex items-center hover:bg-grey-100 px-8 py-12 space-x-4",
              { "text-primary": tags.includes(tag) },
            )}
            onClick={() =>
              tags.includes(tag)
                ? updateDraftTags(tags.filter((t) => t !== tag))
                : tag === "VerifiedByDoctor"
                ? updateDraftTags(
                    tags
                      .filter((it) => it !== "RequiresMedicalOpinion")
                      .concat([tag]),
                  )
                : updateDraftTags(tags.concat([tag]))
            }
          >
            <Icon name={tagToIconName(tag)} size={18} />
            <div>{displayTranscriptItemTag(tag)}</div>
          </button>
        ))}
      </div>
    }
  >
    {({ setTarget }) => (
      <div className="flex items-center space-x-2">
        <ClickableIcon name="add" onClick={setTarget} size={16} />
        <TranscriptItemsTagsList tags={tags} />
      </div>
    )}
  </UncontrolledPopover>
);

export const TranscriptItemsTagsList = ({
  tags,
}: {
  tags: TranscriptItemTag[];
}) => (
  <div className="flex items-center space-x-2 w-[72px]">
    {TranscriptItemTags.map((tag) => (
      <TooltipWrapper
        key={tag}
        label={displayTranscriptItemTag(tag)}
        className={tags.includes(tag) ? "" : "invisible"}
      >
        <Icon className="text-primary" name={tagToIconName(tag)} size={14} />
      </TooltipWrapper>
    ))}
  </div>
);

const SENSITIVE_DATA_CLASSES = ["Name", "FirstName", "Place", "Date", "Other"];

// This seems similar to the mention editor, but in wayyyy simpler,
// It seemed unreasonable to touch the mention editor in this case.
const HighlightableTextInput = ({
  inputRef,
  value,
  onChange,
}: {
  inputRef: RefObject<HTMLInputElement>;
  value: string;
  onChange: (newText: string) => void;
}) => {
  const [selection, setSelection] = useState<{
    coords: DOMRect;
    text: string;
    start: number;
    end: number;
  }>();
  const t = useTranslation();
  return (
    <>
      <input
        ref={inputRef}
        name="text"
        className="bg-transparent flex items-center flex-fill"
        value={value}
        onKeyDown={(e) => {
          if (
            e.altKey &&
            [
              "Space",
              "KeyP",
              "KeyD",
              "KeyS",
              "Digit1",
              "Digit2",
              "Digit3",
            ].includes(e.code)
          ) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          onChange(e.target.value);
          setSelection(undefined);
        }}
        onBlur={() => {
          setSelection(undefined);
        }}
        onMouseUp={(event) => {
          const coords = {
            top: event.clientY,
            bottom: event.clientY,
            left: event.clientX,
            right: event.clientX,
            width: 1,
            height: 1,
            x: event.clientX,
            y: event.clientY,
            toJSON: () => null,
          };
          const currentInput = inputRef.current;
          const selectionStart = inputRef.current?.selectionStart;
          const selectionEnd = inputRef.current?.selectionEnd;

          if (
            currentInput &&
            selectionStart !== undefined &&
            selectionStart !== null &&
            selectionEnd &&
            selectionEnd - selectionStart > 0
          ) {
            setSelection({
              coords,
              text: currentInput.value.slice(selectionStart, selectionEnd),
              start: selectionStart,
              end: selectionEnd,
            });
          } else {
            setSelection(undefined);
          }
        }}
      />
      {selection && (
        <Popover
          target={{
            getBoundingClientRect: () => selection.coords,
          }}
          onClose={undefined}
          role="listbox"
          className="m-4 text-primary-dark flex-col bg-white rounded border"
          position="bottom-left"
        >
          <div className="px-16 py-10 text-center font-medium border-b mb-4">
            {t("transcript_annotation.mark_as")}
          </div>
          <div className="flex-col overflow-auto rounded-b py-8">
            {SENSITIVE_DATA_CLASSES.map((it) => (
              <button
                key={it}
                className="py-4 px-12 hover:bg-grey-100"
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  const newText = `${value.slice(0, selection.start)}[${it}](${
                    selection.text
                  })${value.slice(selection.end)}`;
                  onChange(newText);
                  setSelection(undefined);
                }}
              >
                {it}
              </button>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
};
