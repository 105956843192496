import { useState } from "react";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { NextPageButton } from "components/Button/NextPageButton";
import { DatePicker } from "components/Form/DatePicker/DatePicker";
import { Select } from "components/Form/Select/Select";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { CollapsableCell } from "components/Table/CollapsableCell";
import { Table } from "components/Table/Table";
import {
  ActivityLogCategory,
  ActivityLogCategoryKnownValues,
  GetActivityLogs,
} from "generated/provider";
import { useTranslation } from "i18n";

import { activityLogToDisplayString, ACTIVITY_LOG_TYPES } from "./types";

gql`
  query GetActivityLogs($filters: ActivityLogsFilter, $cursor: String) {
    activityLogs(
      page: { numberOfItems: 20, cursor: $cursor }
      filters: $filters
    ) {
      nextCursor
      hasMore
      data {
        ...ActivityLog
      }
    }
  }

  fragment ActivityLog on ActivityLog {
    uuid
    type
    createdAt
    category
    data
  }
`;

export const ActivityLogs = () => {
  const t = useTranslation();
  const [from, setFrom] = useState<ISOString>();
  const [to, setTo] = useState<ISOString>();
  const [type, setType] = useState<string>();
  const [category, setCategory] = useState<ActivityLogCategory>();

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-16">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("settings.activity_log_events.activity_logs.activity_logs")}
        </h1>
        <div>
          {t(
            "activity_logs.events_logged_for_your_organization_we_register_the_logs_up_to__days",
          )}
        </div>
      </div>
      <div className="flex space-x-16 rounded shadow-sm-outlined border bg-white p-16">
        <DatePicker<true>
          wrapperClassName="flex-1"
          label={t("settings.activity_log_events.activity_logs.from")}
          value={from}
          onChange={(v) => {
            setFrom(v);
          }}
        />
        <DatePicker<true>
          wrapperClassName="flex-1"
          label={t("settings.activity_log_events.activity_logs.to")}
          value={to}
          onChange={(v) => {
            setTo(v);
          }}
        />
        <div className="flex-1">
          <Select
            value={type}
            name="type"
            label={t("settings.activity_log_events.activity_logs.type")}
            options={ACTIVITY_LOG_TYPES}
            onChange={(v) => setType(v)}
          />
        </div>
        <div className="flex-1">
          <Select
            value={category}
            name="category"
            label={t("settings.activity_log_events.activity_logs.category")}
            options={ActivityLogCategoryKnownValues}
            onChange={(v) => setCategory(v)}
          />
        </div>
      </div>
      <div className="flex items-center" />
      <PaginatedQuery
        query={GetActivityLogs}
        variables={{
          filters: {
            createdAtAfter: from,
            createdAtBefore: to,
            type,
            category,
          },
        }}
        pollInterval={60_000}
      >
        {({ data }, utils) => (
          <>
            <Table
              elements={data}
              fieldHeaders={[
                "",
                t("settings.activity_log_events.activity_logs.date"),
                t("settings.activity_log_events.activity_logs.type"),
                t("settings.activity_log_events.activity_logs.category"),
              ]}
              fields={(log) => [
                <CollapsableCell
                  title={activityLogToDisplayString(log)}
                  key={`data_${log.uuid}`}
                >
                  <pre className="text-grey text-12">
                    {JSON.stringify(JSON.parse(log.data), null, 4)}
                  </pre>
                </CollapsableCell>,
                `${log.createdAt.format("date")} ${log.createdAt.format(
                  "time",
                )}`,
                log.type,
                <div className="flex items-center" key={`category_${log.uuid}`}>
                  <div className="bg-light-blue border rounded p-8">
                    {log.category.replace("_", " ")}
                  </div>
                </div>,
              ]}
            />
            <NextPageButton {...utils} />
          </>
        )}
      </PaginatedQuery>
    </Background>
  );
};
