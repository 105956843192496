import { changeLanguage, staticT as t } from "i18n";

import { updatePageTitle } from "./title-and-favicon";

const defaultLanguage = navigator.language.startsWith("fr")
  ? "fr"
  : navigator.language.startsWith("pt")
  ? "pt"
  : "en";

export let language = defaultLanguage;

export const setLanguage = (value: string) => {
  language = value;
  document.documentElement.lang = t("utils.intl.en");
  changeLanguage(value);
  updatePageTitle();
};

export const resetLanguage = () => setLanguage(defaultLanguage);

export const intl = <T>(en: T, fr: T, pt: T) => {
  switch (language) {
    case "fr":
      return fr;
    case "pt":
      return pt;
    case "en":
      return en;
  }
};
export const optionalIntl = <T>(value: [T, T, T] | T) =>
  Array.isArray(value) ? intl(...value) : value;

export const getLocale = (value: string) => {
  switch (value) {
    case "fr":
      return "FRENCH";
    case "pt":
      return "PORTUGUESE";
    case "en":
    default:
      return "ENGLISH";
  }
};

export const getLanguageFromLocale = (value: string) => {
  switch (value) {
    case "FRENCH":
      return "fr";
    case "PORTUGUESE":
      return "pt";
    case "ENGLISH":
    default:
      return "en";
  }
};

export const getLanguageCode = (value: string) => {
  switch (value) {
    case "fr":
      return "fr-FR";
    case "pt":
      return "pt-BR";
    case "en":
    default:
      return "en-US";
  }
};

// To init html lang attribute
setLanguage(defaultLanguage);
