import { routes } from "routes";
import { AccountMenuItem, MainSidebarItem } from "types";

import { SecuritySettings } from "../shared/Preferences/SecuritySettings";
import { Developers } from "./Developers/Developers";
import { PersonalInformation } from "./Preferences/PersonalInformation";

const developersMainSidebarItem: MainSidebarItem = {
  to: routes.DEVELOPERS,
  icon: "code",
  label: ["Developers", "Développeurs", "Dev"],
  Component: Developers,
  hasAccess: ({ hasRole }) => hasRole("ADMINISTRATOR"),
  organizationUserOnly: true,
};

const personalInfoAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.PERSONAL_INFO}`,
  icon: "profile",
  label: [
    "Personal information",
    "Informations personnelles",
    "Informações pessoais",
  ],
  Component: PersonalInformation,
};

const securityAccountMenuItem: AccountMenuItem = {
  to: `${routes.PREFERENCES}/${routes.SECURITY}`,
  icon: "key",
  label: [
    "Password & Security",
    "Sécurité & Mot de passe",
    "Segurança & Senha",
  ],
  Component: SecuritySettings,
};

export const MAIN_SIDEBAR_ITEMS: MainSidebarItem[] = [
  developersMainSidebarItem,
];

export const ACCOUNT_MENU_ITEMS: AccountMenuItem[] = [
  personalInfoAccountMenuItem,
  securityAccountMenuItem,
];
