import {
  AutocompleteExpressionUseCase,
  PreProcessedAutoCompleteModelExpression,
} from "types";

import { ParsedAutoCompleteExpressionSetModel } from "./useAutoCompleteModel";

const ATTENTION_WINDOW = 50;
const HARD_PROCESSING_CAP = 50;

const normalize = (text: string) =>
  text
    .normalize("NFD")
    .replaceAll(/[\u0300-\u036f]/gu, "")
    .toLowerCase();

export const preprocessExpressions = (
  expressions: ParsedAutoCompleteExpressionSetModel["expressions"],
): PreProcessedAutoCompleteModelExpression[] =>
  expressions
    .map((it) => ({
      ...it,
      normalizedText: normalize(it.text),
      useCase: it.useCase,
    }))
    .immutableSort((a, b) =>
      a.normalizedText < b.normalizedText
        ? -1
        : a.normalizedText > b.normalizedText
        ? 1
        : 0,
    );

export const autocomplete = (
  query: string,
  sortedExpressions: PreProcessedAutoCompleteModelExpression[], // Assumed result of preprocessExpressions above
  useCase: AutocompleteExpressionUseCase,
): string | undefined => {
  if (sortedExpressions.isEmpty()) return undefined;
  const normalizedQuery = normalize(query);

  const lastChars = normalizedQuery.slice(
    Math.max(0, normalizedQuery.length - ATTENTION_WINDOW),
  );
  const tokens = lastChars.split(/\s|#/u);
  for (let i = 0; i < tokens.length; i++) {
    const autoCompletion = autocompleteStrictlyFrom(
      tokens.slice(i).join(" "),
      sortedExpressions,
      useCase,
    );
    if (autoCompletion !== undefined) return autoCompletion;
  }
  return undefined;
};

// Autocomplete by finding matching expressions
// That start with query.
export const autocompleteStrictlyFrom = (
  normalizedQuery: string,
  sortedExpressions: PreProcessedAutoCompleteModelExpression[],
  useCase: AutocompleteExpressionUseCase,
): string | undefined => {
  const indexFirstMatch = firstIndexMatchingQuery(
    normalizedQuery,
    sortedExpressions,
    0,
    sortedExpressions.length,
  );
  for (let i = indexFirstMatch; i < sortedExpressions.length; i++) {
    if (i - indexFirstMatch > HARD_PROCESSING_CAP) {
      // Hard cap to limit the number of computation, this is probably heading nowhere.
      break;
    }
    const expression = sortedExpressions[i];
    if (!expression.normalizedText.startsWith(normalizedQuery)) break;
    if (
      normalizedQuery.length >= expression.minMatchingPrefixLength &&
      expression.useCase === useCase
    ) {
      return expression.text.slice(normalizedQuery.length);
    }
  }
  return undefined;
};

// From a list of AutoCompleteModelExpression sorted by their text,
// return the index where the FIRST expression for which query is
// a substring of expression.text should be (we don't check if
// there actually exist one, since we'll run a second pass anyway)
// This is not EXACTLY a binary search, so the best way to understand it is:
// startIndex is always STRICTLY BEFORE what we're searching)
const firstIndexMatchingQuery = (
  normalizedQuery: string,
  sortedExpressions: PreProcessedAutoCompleteModelExpression[],
  start: number,
  end: number,
): number => {
  // Special cases
  if (sortedExpressions.isEmpty()) return 0;
  if (start === 0 && normalizedQuery < sortedExpressions[0].normalizedText) {
    // Special case where the query precedes every expression
    // (Couldn't find an easy way to fit it)
    return 0;
  }

  // Regular cases
  if (end <= start + 1) return start + 1;
  const middle = Math.floor((end + start) / 2);
  if (sortedExpressions[middle].normalizedText < normalizedQuery) {
    return firstIndexMatchingQuery(
      normalizedQuery,
      sortedExpressions,
      middle,
      end,
    );
  }
  return firstIndexMatchingQuery(
    normalizedQuery,
    sortedExpressions,
    start,
    middle,
  );
};
