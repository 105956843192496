import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { LocalUpload } from "components/Form/Dropzone/FormDropzone";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormRow } from "components/FormRow/FormRow";
import { useDoctor } from "contexts/User/UserContext";
import {
  CreateOrUpdateAddress,
  FileUploadFragment,
  UpdateDoctorProfile,
} from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { isValidNumericalInput } from "utils/form";
import { notifier } from "utils/notifier";

import { FormSignature } from "./FormSignature";

gql`
  mutation CreateOrUpdateAddress(
    $doctorUuid: UUID!
    $input: CreateAddressInput!
  ) {
    createOrUpdateAddress(doctorUuid: $doctorUuid, input: $input) {
      address {
        doctor {
          uuid
          appointmentAddress {
            ...Address
          }
          prescriptionAddress {
            ...Address
          }
        }
      }
    }
  }
`;

type FormValues = {
  rpps: string;
  adeli: string;
  amNumber: string;
  nationalProviderIdentifier: string;
  bioAnalysesEmail: string;
  prescriptionAddressAddress: string;
  prescriptionAddressCity: string;
  prescriptionAddressZipCode: string;
  prescriptionAddressState: string;
  prescriptionAddressCountry: string;
  signatureSvg: string | undefined;
  signatureFile: {
    purpose: "SIGNATURE";
    upload: LocalUpload | FileUploadFragment | undefined;
  };
};

export const PersonalInformationPrescriptions = () => {
  const t = useTranslation();
  const { user } = useDoctor();
  const [updateDoctorProfile] = useMutation(UpdateDoctorProfile);
  const [createOrUpdateAddress] = useMutation(CreateOrUpdateAddress);

  return (
    <Form<FormValues>
      className="flex-fill flex-col overflow-y-auto space-y-24"
      initialValues={{
        rpps: user.rpps ?? "",
        adeli: user.adeli ?? "",
        amNumber: user.amNumber ?? "",
        nationalProviderIdentifier: user.nationalProviderIdentifier ?? "",
        bioAnalysesEmail: user.bioAnalysesEmail ?? "",
        prescriptionAddressAddress: user.prescriptionAddress?.address ?? "",
        prescriptionAddressCity: user.prescriptionAddress?.city ?? "",
        prescriptionAddressZipCode: user.prescriptionAddress?.zipCode ?? "",
        prescriptionAddressState: user.prescriptionAddress?.state ?? "",
        prescriptionAddressCountry: user.prescriptionAddress?.country ?? "",
        signatureSvg:
          user.signature?.__typename === "SignatureSVG"
            ? user.signature.svg ?? undefined
            : undefined,
        signatureFile: {
          purpose: "SIGNATURE",
          upload:
            user.signature?.__typename === "SignatureFile"
              ? user.signature.file ?? undefined
              : undefined,
        },
      }}
      validate={({
        prescriptionAddressAddress,
        prescriptionAddressCity,
        prescriptionAddressCountry,
        rpps,
        adeli,
        amNumber,
        signatureSvg,
        signatureFile,
      }) => {
        if (
          rpps &&
          (!isValidNumericalInput(rpps) || rpps.trim().length !== 11)
        ) {
          return { rpps: "Veuillez entrer un numéro à 11 chiffres" };
        }
        if (
          adeli &&
          (!isValidNumericalInput(adeli) || adeli.trim().length !== 9)
        ) {
          return { adeli: "Veuillez entrer un numéro à 9 chiffres" };
        }
        if (
          amNumber &&
          (!isValidNumericalInput(amNumber) || amNumber.trim().length !== 9)
        ) {
          return { amNumber: "Veuillez entrer un numéro à 9 chiffres" };
        }
        if (
          user.prescriptionAddress ||
          prescriptionAddressAddress.isNotBlank()
        ) {
          if (prescriptionAddressAddress.isBlank()) {
            return {
              prescriptionAddressAddress: t("preferences.info.required"),
            };
          }
          if (prescriptionAddressCity.isBlank()) {
            return {
              prescriptionAddressCity: t("preferences.info.required"),
            };
          }
          if (prescriptionAddressCountry.isBlank()) {
            return {
              prescriptionAddressCountry: t("preferences.info.required"),
            };
          }
        }
        if (
          (!signatureSvg || signatureSvg.isEmpty()) &&
          !signatureFile.upload
        ) {
          return { signatureSvg: t("preferences.info.required") }; // It could be signatureFile as well, it doesn't matter here.
        }
        return {};
      }}
      validationSchema={
        user.subOrganization.locale === "FRENCH"
          ? {
              rpps: "required",
              bioAnalysesEmail: "requiredEmail",
              prescriptionAddressAddress: "required",
              prescriptionAddressCity: "required",
              prescriptionAddressCountry: "required",
            }
          : {
              nationalProviderIdentifier: "required",
              prescriptionAddressAddress: "required",
            }
      }
      onSubmit={async ({
        prescriptionAddressAddress,
        prescriptionAddressCity,
        prescriptionAddressZipCode,
        prescriptionAddressState,
        prescriptionAddressCountry,
        signatureSvg,
        signatureFile,
        rpps,
        adeli,
        amNumber,
        nationalProviderIdentifier,
        bioAnalysesEmail,
        ...values
      }) => {
        if (prescriptionAddressAddress.isNotBlank()) {
          const addressPayload = {
            address: prescriptionAddressAddress.trim(),
            zipCode: prescriptionAddressZipCode.trim(),
            city: prescriptionAddressCity.trim(),
            state: prescriptionAddressState.trimOrNull(),
            country: prescriptionAddressCountry.trim(),
          };

          if (
            !user.prescriptionAddress ||
            JSON.stringify(addressPayload) !==
              JSON.stringify({
                address: user.prescriptionAddress.address,
                zipCode: user.prescriptionAddress.zipCode,
                city: user.prescriptionAddress.city,
                state: user.prescriptionAddress.state,
                country: user.prescriptionAddress.country,
              })
          ) {
            await createOrUpdateAddress(
              {
                doctorUuid: user.uuid,
                input: {
                  purpose: "PRESCRIPTIONS",
                  ...addressPayload,
                },
              },
              { throwOnError: true },
            );
          }
        }

        const uploadInput =
          signatureFile.upload && "getInput" in signatureFile.upload
            ? await signatureFile.upload.getInput()
            : null;

        return updateDoctorProfile(
          {
            payload: {
              signature: {
                svg: signatureSvg ?? null,
                file: uploadInput,
              },
              rpps: rpps.trimOrNull(),
              adeli: adeli.trimOrNull(),
              amNumber: amNumber.trimOrNull(),
              nationalProviderIdentifier:
                nationalProviderIdentifier.trimOrNull(),
              bioAnalysesEmail: bioAnalysesEmail.trimOrNull(),
              ...values,
            },
          },
          {
            onSuccess: () => {
              notifier.success(t("preferences.info.changes_saved"));
            },
          },
        );
      }}
    >
      <div className="mt-26">
        <span>
          {t(
            "personal_information.all_these_informations_are_required_to_generate_prescriptions",
          )}
        </span>
      </div>
      {
        // TODO(clement) use organization administrative country
        user.subOrganization.locale === "FRENCH" ? (
          <>
            <FormRow>
              <FormInput
                name="rpps"
                label="Rpps"
                placeholder="Numéro à 11 chiffres"
              />
              <FormInput
                name="adeli"
                label="Adeli"
                placeholder="Numéro à 9 chiffres"
              />
            </FormRow>
            <FormRow>
              <FormInput
                name="amNumber"
                label="Numéro Assurance Maladie (AM)"
                placeholder="Numéro à 9 chiffres"
              />
              <FormInput
                name="bioAnalysesEmail"
                label="Email réception analyses bio"
              />
            </FormRow>
          </>
        ) : (
          <FormRow>
            <FormInput
              name="nationalProviderIdentifier"
              label="National Provider Identifier"
            />
          </FormRow>
        )
      }
      <FormRow>
        <FormInput
          name="prescriptionAddressAddress"
          label={t("provider.address.placeholder")}
          placeholder={t("preferences.info.address")}
        />
      </FormRow>
      <FormRow>
        <FormInput
          name="prescriptionAddressZipCode"
          placeholder={t("preferences.info.postal_code")}
        />
        <FormInput
          name="prescriptionAddressCity"
          placeholder={t("preferences.info.city")}
        />
      </FormRow>
      <FormRow>
        <FormInput
          name="prescriptionAddressState"
          placeholder={t("preferences.info.state")}
        />
        <FormInput
          name="prescriptionAddressCountry"
          placeholder={t("preferences.info.country")}
        />
      </FormRow>
      <FormSignature label={t("preferences.info.form_signature.title")} />
      <Submit
        label={t("preferences.preferences_form.save")}
        className="mt-44 mr-auto"
      />
    </Form>
  );
};
