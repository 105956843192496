import { MouseEventHandler } from "react";
import classNames from "classnames";
import { Range } from "react-date-range";

import { DateRangeSelector } from "components/DateRangeSelector/DateRangeSelector";
import { Popover } from "components/Popover/Popover";
import { TimeRangeInput } from "generated/provider";
import { useMountDate, useTargetState } from "hooks";
import { useTranslation } from "i18n";

import { Filter, FILTERS } from "./filterType";

export const StatsTimeRange = ({
  selectedPill,
  setSelectedPill,
  customRange,
  setCustomRange,
  setSelectedRange,
}: {
  selectedPill: Filter;
  setSelectedPill: (filter: Filter) => void;
  customRange: Range;
  setCustomRange: (range: Range) => void;
  setSelectedRange: (range: TimeRangeInput | undefined) => void;
}) => {
  const t = useTranslation();
  const now = useMountDate();

  return (
    <div className="flex space-x-8 mt-8 mb-16">
      {FILTERS.map((filter) =>
        filter === "CUSTOM" ? (
          <DateFilterPillCustom
            key={filter}
            range={customRange}
            setRange={setCustomRange}
            label={
              customRange.startDate && customRange.endDate
                ? `${customRange.startDate.toLocaleDateString()} - ${customRange.endDate.toLocaleDateString()}`
                : t("stats_dashboard.stats_dashboard.custom")
            }
            isSelected={filter === selectedPill}
            onSelected={() => setSelectedPill(filter)}
            onClose={() => {
              if (customRange.startDate && customRange.endDate) {
                setSelectedRange({
                  from: customRange.startDate.toISOString(),
                  to: customRange.endDate
                    .toISOString()
                    .plusDays(1)
                    .minusMinutes(1),
                });
              }
            }}
          />
        ) : (
          <DateFilterPillSimple
            key={filter}
            label={
              {
                ALL: t("stats_dashboard.stats_dashboard.all"),
                WEEK: t("stats_dashboard.stats_dashboard.this_week"),
                MONTH: t("stats_dashboard.stats_dashboard.this_month"),
                LAST3MONTHS: t("stats_dashboard.stats_dashboard.last__months"),
              }[filter]
            }
            isSelected={filter === selectedPill}
            onClick={() => {
              setSelectedPill(filter);
              setSelectedRange(
                {
                  ALL: undefined,
                  WEEK: { from: now.minusDays(7), to: now },
                  MONTH: { from: now.minusMonths(1), to: now },
                  LAST3MONTHS: { from: now.minusMonths(3), to: now },
                }[filter],
              );
            }}
          />
        ),
      )}
    </div>
  );
};

const DateFilterPillSimple = ({
  label,
  isSelected,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onClick: MouseEventHandler;
}) => (
  <div className="rounded-full h-32 flex group items-center bg-secondary bg-grey-100 border overflow-hidden">
    <button
      className={classNames(
        "p-12 font-medium text-center",
        isSelected ? "text-white bg-primary" : "text-body bg-white",
      )}
      onClick={onClick}
    >
      {label}
    </button>
  </div>
);

const DateFilterPillCustom = ({
  label,
  isSelected,
  onSelected,
  range,
  setRange,
  onClose,
}: {
  label: string;
  isSelected: boolean;
  onSelected: () => void;
  range: Range;
  setRange: (range: Range) => void;
  onClose: () => void;
}) => {
  const [target, setTarget] = useTargetState();

  return (
    <>
      <DateFilterPillSimple
        label={label}
        isSelected={isSelected}
        onClick={(element) => {
          onSelected();
          setTarget(element);
        }}
      />
      {target && (
        <Popover
          target={target}
          position="bottom"
          onClose={() => {
            setTarget(undefined);
            onClose();
          }}
          noArrow
        >
          <DateRangeSelector value={range} onChange={setRange} />
        </Popover>
      )}
    </>
  );
};
