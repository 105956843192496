import gql from "graphql-tag";

import { FormCheckbox } from "components/Form/CheckBox/FormCheckbox";
import { FieldArray } from "components/Form/FieldArray/FieldArray";
import { FormInput } from "components/Form/Input/FormInput";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { FormModal } from "components/Modal/FormModal";
import { useDoctor } from "contexts/User/UserContext";
import { PollFragment, VoteForPoll } from "generated/provider";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

gql`
  mutation VoteForPoll($pollUuid: UUID!, $input: VoteForPollInput!) {
    voteForPoll(pollUuid: $pollUuid, input: $input) {
      poll {
        ...Poll
      }
    }
  }
`;

type FormValues = {
  newPropositions: string[];
  pollItemBools: boolean[];
};

export const VoteForPollModal = ({
  poll,
  onHide,
  submitLabel,
}: {
  poll: PollFragment;
  onHide: () => void;
  submitLabel: string;
}) => {
  const t = useTranslation();
  const [voteForPoll, votingForPoll] = useMutation(VoteForPoll, {
    onSuccess: () => onHide(),
  });
  const { user } = useDoctor();

  return (
    <FormModal<FormValues>
      title={poll.title}
      initialValues={{
        newPropositions: [],
        pollItemBools: poll.items.map((i) =>
          i.voters.map((v) => v.uuid).includes(user.uuid),
        ),
      }}
      disabled={votingForPoll}
      onSubmit={({ newPropositions, pollItemBools }) =>
        voteForPoll({
          pollUuid: poll.uuid,
          input: {
            newPropositions: newPropositions.mapNotNull((it) =>
              it.trimOrNull(),
            ),
            pollItemUuids: poll.items
              .filter((_, i) => pollItemBools[i])
              .map((i) => i.uuid),
          },
        })
      }
      onHide={() => onHide()}
      submitLabel={submitLabel}
    >
      <FieldArray<FormValues, boolean> name="pollItemBools">
        {(sourcesHelper) => (
          <div className="space-y-8">
            {poll.items.map((item, index) => (
              <FormCheckbox
                key={item.uuid}
                label={item.title}
                name={`pollItemBools[${index}]`}
                onChange={(v) => sourcesHelper.update(index, v)}
              />
            ))}
          </div>
        )}
      </FieldArray>
      <FieldArray<FormValues, string> name="newPropositions">
        {(sourcesHelper, values) => (
          <div className="space-y-8">
            {values.newPropositions.map((_, index) => (
              <div key={index} className="flex items-center space-x-12">
                <FormInput
                  label={`${t(
                    "composer_parts.vote_for_poll_modal.new_proposal",
                  )} ${index + 1}`}
                  name={`newPropositions[${index}]`}
                  style={{ height: 40 }}
                  onPaste={(e) => {
                    const value = e.clipboardData.getData("text");
                    if (e.currentTarget.value) return; // Avoid overrides
                    e.preventDefault();
                    const [actual, ...propositionsToAdd] = value
                      .split("\n")
                      .mapNotNull((s) => s.trimOrNull());
                    sourcesHelper.update(index, actual);
                    propositionsToAdd.forEach((p) => sourcesHelper.push(p));
                  }}
                />
                <ClickableIcon
                  name="trash"
                  onClick={() => sourcesHelper.remove(index)}
                />
              </div>
            ))}
            <button
              type="button"
              className="flex items-center mt-20"
              onClick={() => sourcesHelper.push("")}
            >
              <Icon name="add" />
              <div className="ml-8">
                {t("composer_parts.vote_for_poll_modal.add_a_proposal")}
              </div>
            </button>
          </div>
        )}
      </FieldArray>
    </FormModal>
  );
};
